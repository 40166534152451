import { BlockBuilder } from '../..';
import { newBlankPage } from '../../pageUtils';
import { WeakTask } from '../../types';

export function newSagaBasicsPagePt(task: WeakTask, collectionIds: string[]) {
    return newBlankPage({
        title: 'Noções básicas do Saga',
        icon: { type: 'emoji', colons: ':wave:' },
        collections: collectionIds,
        blocks: [
            BlockBuilder.title('Noções básicas do Saga'),
            BlockBuilder.callout([
                BlockBuilder.paragraph([BlockBuilder.text('Digite em qualquer lugar desta página para editá-la!')]),
            ]),
            BlockBuilder.paragraph(),
            BlockBuilder.image('https://saga-public.s3.amazonaws.com/1-block-selection-menu.webp'),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Pressione '),
                BlockBuilder.text('@', { code: true }),
                BlockBuilder.text(' ou '),
                BlockBuilder.text('/', { code: true }),
                BlockBuilder.text(
                    ' para abrir o menu de autocompletar e adicionar novos conteúdos como títulos, tarefas, imagens, arquivos, tabelas e mais.',
                ),
            ]),
            BlockBuilder.paragraph(),
            BlockBuilder.paragraph(),
            BlockBuilder.paragraph(),
            BlockBuilder.image('https://saga-public.s3.amazonaws.com/2-text-styling-menu.webp'),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Selecione texto', { bold: true }),
                BlockBuilder.text(' para '),
                BlockBuilder.text('estilizá-lo,', { italic: true }),
                BlockBuilder.text(' transformá-lo em outro tipo de conteúdo, '),
                BlockBuilder.text('destacá-lo', { highlight: true }),
                // todo highlight in red
                BlockBuilder.text(', ou aprimorá-lo com IA.'),
            ]),
            BlockBuilder.paragraph(),
            BlockBuilder.paragraph(),
            BlockBuilder.image('https://saga-public.s3.amazonaws.com/3-drag-and-drop-images-and-files.webp'),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Adicione imagens e arquivos', { bold: true }),
                BlockBuilder.text(' arrastando-os ou colando-os.'),
            ]),
            BlockBuilder.paragraph(),
            BlockBuilder.paragraph(),
            BlockBuilder.image('https://saga-public.s3.amazonaws.com/4-drag-and-drop-blocks-of-content.webp'),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Arraste e solte', { bold: true }),
                BlockBuilder.text(' qualquer bloco selecionando e arrastando o ícone ☰ para o lado esquerdo do bloco.'),
            ]),
            BlockBuilder.paragraph(),
            BlockBuilder.paragraph(),
            BlockBuilder.image('https://saga-public.s3.amazonaws.com/5-unified-search-for-fast-navigation.webp'),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Pressione '),
                BlockBuilder.text('⌘ + K', { code: true }),
                BlockBuilder.text(' (macOS) ou '),
                BlockBuilder.text('Ctrl + K', { code: true }),
                BlockBuilder.text(' (Windows) para '),
                BlockBuilder.text('encontrar qualquer coisa', { bold: true }),
                BlockBuilder.text(' e navegar pelo seu espaço de trabalho.'),
            ]),
            BlockBuilder.paragraph(),
            BlockBuilder.paragraph(),
            BlockBuilder.image('https://saga-public.s3.amazonaws.com/6-creating-and-managing-tasks.webp'),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Crie tarefas', { bold: true }),
                BlockBuilder.text(' abrindo o menu de autocompletar e selecionando '),
                BlockBuilder.text('Tarefa', { code: true }),
                BlockBuilder.text(
                    '. Tarefas são tipos especiais de páginas com um responsável, data de vencimento e prioridade. Abra a tarefa abaixo para aprender mais 👇.',
                ),
            ]),
            BlockBuilder.taskBlock(task.id),
            BlockBuilder.paragraph(),
            BlockBuilder.paragraph(),
            BlockBuilder.image('https://saga-public.s3.amazonaws.com/7-adding-to-pinned.webp'),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Favoritar ', { bold: true }),
                BlockBuilder.text('qualquer página para adicioná-la à barra lateral clicando no ícone ☆ no canto superior direito.'),
            ]),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Acesse a página do 😍 Saga avançado para explorar funcionalidades mais avançadas e otimizar seu uso do Saga.'),
            ]),
            BlockBuilder.paragraph(),
            BlockBuilder.paragraph(),
            BlockBuilder.divider(),
            BlockBuilder.headingThree('Tem uma pergunta?'),
            BlockBuilder.paragraph([
                BlockBuilder.text('Pressione o '),
                BlockBuilder.text('?', { bold: true }),
                BlockBuilder.text(
                    ' botão no canto inferior direito para nos enviar um e-mail, enviar solicitações de recursos ou juntar-se às nossas comunidades de usuários no ',
                ),
                BlockBuilder.link('https://discord.com/invite/cgz2mUEq7P', 'Discord'),
                BlockBuilder.text(', '),
                BlockBuilder.link(
                    'https://sagacommunity.slack.com/join/shared_invite/zt-13m3lrrdt-1x6~l6sLuR8CX~4c3fWwHA#/shared-invite/email',
                    'Slack',
                ),
                BlockBuilder.text(', ou '),
                BlockBuilder.link('https://twitter.com/saga_HQ', 'X'),
                BlockBuilder.text(
                    '. Além disso, visite os guias de ajuda em nosso site e veja como aproveitar ao máximo o aplicativo.',
                ),
            ]),
            BlockBuilder.rawPrettyLink('https://saga.so/guides', {
                _tag: 'loaded',
                description: "Este guia ajudará você a entender os blocos de construção do Saga e a se atualizar.",
                title: '📍 Guias do Saga',
                logo: 'https://saga.so/thumbnail.png',
            }),
            BlockBuilder.paragraph(),
        ],
    });
}
