import { WeakTask } from '@saga/shared';

import useWorkspaceSettings from './useWorkspaceSettings';

export function useTaskFilters() {
    const [settings] = useWorkspaceSettings();

    const taskSettings: Partial<WeakTask> = {};

    const priorityFilter = settings?.allTasksPriority ?? 'all';
    const assigneeFilter = settings?.allTasksAssignee ?? { kind: 'all' };
    const labelsFilter = settings?.allTasksLabels ?? 'all';
    const collectionsFilter = settings?.allTasksCollections ?? 'all';

    if (
        priorityFilter !== 'all' &&
        priorityFilter !== 'unprioritized' &&
        ['HIGH', 'MEDIUM', 'LOW'].includes(priorityFilter) &&
        !taskSettings.priority
    ) {
        taskSettings.priority = priorityFilter as 'HIGH' | 'MEDIUM' | 'LOW';
    }

    if (assigneeFilter.kind === 'assigned' && !taskSettings.assignee) {
        taskSettings.assignee = assigneeFilter.memberId;
    }

    if (labelsFilter !== 'all' && Array.isArray(labelsFilter) && !taskSettings.labels) {
        taskSettings.labels = labelsFilter;
    }

    if (collectionsFilter !== 'all' && Array.isArray(collectionsFilter) && !taskSettings.collections) {
        taskSettings.collections = collectionsFilter;
    }

    return taskSettings;
}
