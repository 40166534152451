import {
    CustomTemplate,
    newBlankPage,
    PredefinedTemplate,
    SagaElement,
    SagaLocation,
    SpaceOperations,
} from '@/../../shared/src';
import { track } from '@/analytics';
import { PageIcon } from '@/components/icons';
import Modal from '@/components/Modal';
import SidebarItemButton from '@/components/navigation/SidebarItemButton';
import { useOpenPage } from '@/components/PageNavigationProvider';
import { useSpace } from '@/components/SpaceProvider';
import Button from '@/components/styled/Button';
import TemplatePreview from '@/components/templates/TemplatePreview';
import { useCurrentWorkspace } from '@/components/WorkspaceContext';
import { usePartialPages } from '@/hooks/SpaceHooks';
import * as api from '@saga/api';
import React from 'react';
import { Plus } from 'react-feather';
import { useBlockPlugins } from './BlockPluginProvider';
import { useTranslation } from 'react-i18next';
import { createPage } from '@/utils/documentUtils';
import { usePerformActionWithYBlocks } from '@/components/RealtimeDocumentProvider';

const keys = ['id', 'title', 'icon', 'aliases', 'collections', 'createdAt'] as const;

function mapTemplateFragmentToPredefinedTemplate(template: api.TemplateFragment): PredefinedTemplate {
    return {
        ...template,
        icon: template.icon
            ? {
                  colons: template.icon,
                  type: 'emoji',
              }
            : undefined,
    };
}

const TemplatePicker = ({ onClose }: { onClose: () => void }) => {
    const [visibleTemplate, setVisibleTemplate] = React.useState<
        CustomTemplate | (PredefinedTemplate & { collections: string[] }) | null
    >(null);
    const { data } = api.useTemplatesQuery();
    const predefinedTemplates = (data ?? { templates: [] }).templates;
    const ownTemplates = usePartialPages(keys, 'flat', 'templates');
    const { space, provider } = useSpace();
    const blockPlugins = useBlockPlugins();
    const { title } = useCurrentWorkspace();
    const openPage = useOpenPage();
    const { t } = useTranslation();
    const performActionWithBlocks = usePerformActionWithYBlocks();

    React.useEffect(() => {
        if (data == null) return;

        if (ownTemplates.length > 0) {
            setVisibleTemplate(ownTemplates[0]);
        } else if (data.templates.length > 0) {
            setVisibleTemplate({ ...mapTemplateFragmentToPredefinedTemplate(data.templates[0]), collections: [] });
        }
    }, [data, ownTemplates]);

    const onNewTemplateClick = (event: React.MouseEvent) => {
        onClose();

        const templatePage = createPage(space, newBlankPage({ isTemplate: true }), provider);
        track('create-new-template', { source: 'template-picker' });

        openPage(templatePage.id, event);
    };

    function useTemplate(event: React.MouseEvent) {
        if (visibleTemplate == null) return;

        track('use-template', { templateId: visibleTemplate.id, source: 'template-picker' });

        function applyTemplate(template: CustomTemplate | PredefinedTemplate, action: (blocks: SagaElement[]) => void) {
            if (template.blocks) {
                action(template.blocks);
            } else {
                performActionWithBlocks(SagaLocation.pageLocationFromId(template.id), (templateBlocks) => {
                    action(templateBlocks.toJSON());
                });
            }
        }

        applyTemplate(visibleTemplate, (blocks) => {
            const newPage = createPage(space, newBlankPage({ blocks }), provider);
            SpaceOperations.applyTemplateMetaToLocation(space, {
                template: {
                    id: visibleTemplate.id,
                    title: visibleTemplate.title,
                    icon: visibleTemplate.icon,
                    collections: visibleTemplate.collections,
                    aliases: visibleTemplate.aliases,
                },
                location: SagaLocation.pageLocationFromId(newPage.id),
            });

            onClose();
            openPage(newPage.id, event);
        });
    }

    return (
        <Modal isOpen={true} maxWidth="68rem" height="80%" onClose={onClose}>
            <div className="bg-white dark:bg-saga-gray-1000 flex flex-row divide-x divide-saga-gray-150 dark:divide-saga-gray-800 h-full w-full">
                <div className="overflow-y-auto max-w-56 w-full p-4 divide-y divide-saga-gray-150 dark:divide-saga-gray-800">
                    <div className="pb-2 space-y-2">
                        <SectionLabel>{title} Templates</SectionLabel>
                        <div className="flex flex-col space-y-0.5">
                            <NewTemplateButton onClick={onNewTemplateClick} />
                            {ownTemplates.map((template) => (
                                <TemplateButton
                                    isSelected={visibleTemplate?.id === template.id}
                                    key={template.id}
                                    template={template}
                                    onClick={() => {
                                        track('view-template', { templateId: template.id, source: 'template-picker' });
                                        setVisibleTemplate(template);
                                    }}
                                />
                            ))}
                        </div>
                    </div>
                    {predefinedTemplates.length > 0 && (
                        <div className="pt-2 space-y-0.5">
                            <SectionLabel>Suggested Templates</SectionLabel>
                            {predefinedTemplates.map(mapTemplateFragmentToPredefinedTemplate).map((template) => (
                                <TemplateButton
                                    isSelected={visibleTemplate?.id === template.id}
                                    key={template.id}
                                    template={template}
                                    onClick={() => {
                                        track('view-template', { templateId: template.id, source: 'template-picker' });
                                        setVisibleTemplate({ ...template, collections: [] });
                                    }}
                                />
                            ))}
                        </div>
                    )}
                </div>
                <div className="flex-1 flex relative">
                    {visibleTemplate && (
                        <div className="overflow-y-auto w-full">
                            <TemplatePreview
                                blockPlugins={blockPlugins}
                                template={{
                                    ...visibleTemplate,
                                    isTemplate: true,
                                    archivedAt: undefined,
                                    settings: { fullWidth: false },
                                }}
                            />
                        </div>
                    )}
                    {!visibleTemplate && ownTemplates.length === 0 && (
                        <div className="w-full flex flex-col items-center justify-center p-10 text-saga-text-gray space-y-2">
                            <div>{"You don't have any templates yet."}</div>
                        </div>
                    )}
                    <div className="absolute top-3 right-3 flex flex-row space-x-2 items-center">
                        {visibleTemplate && ownTemplates.map((t) => t.id).includes(visibleTemplate.id) ? (
                            <Button.Plain
                                onClick={(event) => {
                                    onClose();
                                    openPage(visibleTemplate.id, event);
                                }}
                            >
                                <Button.BasePadding>
                                    <p className="px-2 font-medium">{t('common.edit_template')}</p>
                                </Button.BasePadding>
                            </Button.Plain>
                        ) : (
                            <div />
                        )}

                        {visibleTemplate && (
                            <Button onClick={useTemplate}>
                                <p className="font-medium">{t('common.use_template')}</p>
                            </Button>
                        )}
                        <Button.XButton onClick={onClose} />
                    </div>
                </div>
            </div>
        </Modal>
    );
};

const SectionLabel = ({ children }: { children: React.ReactNode }) => (
    <p className="text-saga-gray-500 dark:text-zinc-400 text-sm font-normal px-1.5 pb-1">{children}</p>
);

const TemplateButton = ({
    template,
    isSelected,
    onClick,
}: {
    isSelected: boolean;
    template: CustomTemplate | PredefinedTemplate;
    onClick: () => void;
}) => {
    return (
        <SidebarItemButton isSelected={isSelected} onClick={onClick}>
            <div className="py-1">
                <PageIcon icon={template.icon} isTemplate={true} />
            </div>
            <div className="min-w-0">
                <div className="truncate leading-normal font-normal text-sm">{template.title}</div>
            </div>
        </SidebarItemButton>
    );
};

const NewTemplateButton = ({ onClick }: { onClick: (evt: React.MouseEvent) => void }) => {
    const { t } = useTranslation();
    return (
        <SidebarItemButton onClick={onClick}>
            <Plus className="flex-none w-5 -ml-1 shrink-0 stroke-saga-green" />
            <div className="min-w-0">
                <div className="truncate leading-normal text-sm">{t('common.new_template')}</div>
            </div>
        </SidebarItemButton>
    );
};

export default TemplatePicker;
