import React from 'react';
import * as api from '@saga/api';
import { assertNonNull } from '@/../../shared/src';
import { useCurrentWorkspace } from '@/components/WorkspaceContext';
import { useUserContext } from '@/components/UserContext';
import LoadingScreen from '@/components/loading/LoadingScreen';
import { useSpaceAccess } from '@/hooks/useSpaceAccess';

const PagesPermissionsContext = React.createContext<{
    permissions: api.UserPagePermissionFragmentFragment[];
    getPagePermission(pageId: string): api.UserPagePermissionFragmentFragment | null;
    hasAccess(pageId: string): boolean;
} | null>(null);

export const usePagesPermissions = () => {
    const context = React.useContext(PagesPermissionsContext);
    assertNonNull(context, 'usePagesPermissions must be used inside PagePermissionsContext');
    return context;
};

export const usePageAccess = (pageId: string) => {
    const { getPagePermission } = usePagesPermissions();
    const { isOwner, isMember, isEditor } = useSpaceAccess();

    return React.useMemo(() => {
        const userPagePermissions = getPagePermission(pageId);
        const permission = userPagePermissions?.permission;

        return {
            isPageAdmin: permission ? permission === api.PagePermission.Admin : isOwner || isMember,
            canEdit: permission
                ? [api.PagePermission.Admin, api.PagePermission.Writer].includes(permission) //TODO: Check this
                : isOwner || isMember || isEditor,
            canView: permission ? permission !== api.PagePermission.Hidden : true,
        };
    }, [pageId, getPagePermission, isOwner, isEditor, isMember]);
};

const defaultContext = {
    getPagePermission() {
        return null;
    },
    hasAccess() {
        return true;
    },
    permissions: [],
};

export function NoopPagePermissionsBySpaceProvider({ children }: { children: React.ReactNode }) {
    return <PagesPermissionsContext.Provider value={defaultContext}>{children}</PagesPermissionsContext.Provider>;
}

export default function PagePermissionsBySpaceProvider({ children }: { children: React.ReactNode }) {
    const space = useCurrentWorkspace();
    const { user } = useUserContext();

    const [getPermissions, { data, loading, variables, subscribeToMore }] =
        api.useUserPagePermissionsBySpaceIdLazyQuery();

    const permissionsLoading = React.useMemo(() => {
        if (!user) {
            return loading;
        }

        return loading || variables?.input?.spaceId !== space.id;
    }, [loading, variables, space, user]);

    React.useEffect(() => {
        if (!user) return;

        getPermissions({
            variables: { input: { userId: user.id, spaceId: space.id } },
        });

        const unsubscribe = subscribeToMore({
            document: api.UserPagePermissionsBySpaceIdUpdatedDocument,
            variables: { input: { userId: user.id, spaceId: space.id } },
            updateQuery: (_, { subscriptionData }) => subscriptionData.data,
        });

        return () => {
            unsubscribe();
        };
    }, [user, getPermissions, space, subscribeToMore]);

    const permissions = React.useMemo(() => {
        return data?.userPagePermissionsBySpaceId ?? [];
    }, [data]);

    const getPagePermission = React.useCallback(
        (pageId: string) => {
            const pagePermissions = permissions.find((permission) => permission.pageId === pageId);
            return pagePermissions ?? null;
        },
        [permissions],
    );

    const hasAccess = React.useCallback(
        (pageId: string) => {
            const permission = getPagePermission(pageId);
            return !loading && permission?.permission !== api.PagePermission.Hidden;
        },
        [getPagePermission, loading],
    );

    const context = React.useMemo(
        () => ({ getPagePermission, permissions, hasAccess }),
        [getPagePermission, permissions, hasAccess],
    );

    return permissionsLoading ? (
        <LoadingScreen />
    ) : (
        <PagesPermissionsContext.Provider value={context}>{children}</PagesPermissionsContext.Provider>
    );
}
