import { useCallback, useMemo } from 'react';
import { SlateYjs } from '@saga/shared';
import { Awareness } from 'y-protocols/awareness';

export default function useAwarenessLocalState(
    awareness: Awareness,
): [Record<string, any> | null, (state: Partial<SlateYjs.AwarenessStateData>) => void] {
    const localState = useMemo(() => awareness.getLocalState(), [awareness]);

    const setLocalState = useCallback(
        (nextLocalState: Record<string, unknown>) => {
            const keys = Object.keys(nextLocalState);

            keys.forEach((key) => {
                awareness.setLocalStateField(key, nextLocalState[key]);
            });
        },
        [awareness],
    );

    return [localState, setLocalState];
}
