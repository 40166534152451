type Options = {
    darkOverlay?: boolean;
    position?: string;
    zIndex?: number;
    css?: React.CSSProperties;
};

const modalStyle = (options: Options = {}) => {
    let verticalAlignment = {};

    const position = options.position ?? 'center';
    switch (position) {
        case 'center':
            verticalAlignment = {
                top: '50%',
                bottom: 'auto',
                transform: 'translate(-50%, -50%)',
            };
            break;
        case 'top':
            verticalAlignment = {
                top: '0',
                bottom: 'auto',
                transform: 'translate(-50%, 50%)',
            };
            break;
        case 'bottom':
            verticalAlignment = {
                top: 'auto',
                transform: 'translate(-50%, -50%)',
                bottom: '0',
            };
            break;
    }

    const css = options.css ?? {};

    return {
        content: {
            ...verticalAlignment,
            left: '50%',
            right: 'auto',
            marginRight: '-50%',
            borderRadius: '5px',
            border: '1px solid #EFEFEF',
            boxShadow: undefined,
            padding: 0,
            background: undefined,
            ...css,
        },
        overlay: {
            zIndex: options.zIndex ?? 60,
            background: options.darkOverlay ? 'rgba(0, 0, 0, 0.5)' : 'transparent',
        },
    };
};

export default modalStyle;
