import React, { useCallback } from 'react';
import * as Y from 'yjs';
import { safeMap, SafeMapOutput, Space, spaceD } from '@saga/shared';
import { isDebug } from '@/utils';
import { useRealtimeStateWithDocument } from './RealtimeProvider';
import LoadingScreen from '@/components/loading/LoadingScreen';
import { HocuspocusProvider } from '@hocuspocus/provider';

const SpaceContext = React.createContext<{
    space: SafeMapOutput<Space>;
    yDoc: Y.Doc;
    isRemoteSynced: () => boolean;
    provider: HocuspocusProvider;
    searchMap: Y.Map<any> | null;
} | null>(null);

export function useSpace() {
    const spaceContext = React.useContext(SpaceContext);

    if (spaceContext == null) {
        throw new Error('Space is null, hook needs to be inside of SpaceProvider');
    }

    return spaceContext;
}

export function useCurrentSearchMap() {
    const { searchMap } = useSpace();

    return searchMap;
}

export function useCurrentSearchIndex() {
    const indexMap = useCurrentSearchMap();
    const [trigger, forceUpdate] = React.useReducer(() => ({}), {});

    React.useEffect(() => {
        if (!indexMap) return;

        indexMap.observe(forceUpdate);

        return () => {
            indexMap.unobserve(forceUpdate);
        };
    }, [indexMap]);

    return React.useMemo(() => {
        trigger;

        if (!indexMap) return;

        return new Proxy(indexMap, {
            get(target, prop, receiver) {
                return Reflect.get(target, prop, receiver);
            },
        });
    }, [trigger, indexMap]);
}

export function SpaceProvider({ children }: { children: React.ReactNode }) {
    const {
        state: { document, remoteSynced, provider },
        searchMap,
    } = useRealtimeStateWithDocument();

    const map = document.getMap('space');

    const remoteSyncedRef = React.useRef(remoteSynced);

    React.useEffect(() => {
        remoteSyncedRef.current = remoteSynced;
    }, [remoteSynced]);

    const isRemoteSynced = useCallback(() => remoteSyncedRef.current, [remoteSyncedRef]);

    const deps = React.useMemo(() => {
        const space = safeMap({
            definition: spaceD,
            map,
        });

        if (isDebug()) {
            //@ts-ignore
            window.space = space;

            //@ts-ignore
            window.Y = Y;
        }

        return {
            space,
            yDoc: document,
            provider,
            searchMap,
            isRemoteSynced,
        };
    }, [document, map, remoteSyncedRef, provider, searchMap]);

    // we need to show the loading screen in case the map is null, because it means that the
    // space has not been populated yet but we also don't have any offline state yet
    if (map.size === 0) {
        return <LoadingScreen />;
    }

    return <SpaceContext.Provider value={deps}>{children}</SpaceContext.Provider>;
}
