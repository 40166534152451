import React from 'react';
import { PopOver } from '@/components/popover/PopOver';
import { Copy, Rewind, Trash } from 'react-feather';
import { track } from '@/analytics';
import ContextMenuButton from '@/components/styled/ContextMenuButton';
import { OpenLocationSideBySideButton, UndoChangesButton } from './PageContextMenu';
import { RealtimeSagaEditor, SagaLocation, SpaceOperations, WeakTask } from '@/../../shared/src';
import { useTranslation } from 'react-i18next';
import useMobile from '@/hooks/useMobile';
import { usePageAccess } from '@/components/PagesPermissionsBySpaceProvider';
import { DocumentHistoryButton } from '@/components/DocumentHistoryButton';
import {
    AddToFavoritePageButton,
    AddToPinnedPageButton,
    RemoveFromFavoriteButton,
    RemoveFromPinnedButton,
} from './common';
import { useOpenTask } from '../PageNavigationProvider';
import { useSpace } from '../SpaceProvider';
import { duplicateTask } from '@/utils/documentUtils';
import { usePerformActionWithYBlocks } from '../RealtimeDocumentProvider';
import { useWorkspaces } from '../WorkspacesContext';
import MoveEntityButton from './MoveEntityButton';
import useExport from '@/hooks/useExport';
import ExportButton from './ExportButton';
import { useHistory } from 'react-router-dom';
import { useCurrentWorkspace } from '../WorkspaceContext';
import { useArchiveTask, useDeleteTaskPermanently } from '@/hooks/deleteTaskHooks';
import { useFavoriteButton } from '@/hooks/useFavoriteButton';
import Dropdown from './Dropdown';
import { usePinnedButton } from '@/hooks/usePinnedButton';

export function DuplicateTaskButton({ taskId }: { taskId: string }) {
    const openTask = useOpenTask();
    const { space, provider } = useSpace();
    const { t } = useTranslation();
    const performActionWithYBlocks = usePerformActionWithYBlocks();

    function onDuplicatePage(event: React.MouseEvent) {
        track('duplicate-task');
        performActionWithYBlocks(SagaLocation.taskLocationFromId(taskId), (blocks) => {
            const duplicatedPage = duplicateTask(space, provider, taskId, blocks.toJSON());
            openTask(duplicatedPage.id, event);
        });
    }

    return (
        <PopOver.RoundedButton onClick={onDuplicatePage} aria-label={t('common.duplicate_task')}>
            <Copy className="stroke-gray-dark mr-2 my-auto" size={14} />
            {t('common.duplicate')}
        </PopOver.RoundedButton>
    );
}

export function ExportTaskButton(props: { onAfterClick: () => void; task: WeakTask | null }) {
    const exportTask = useExport();
    return (
        props.task && (
            <ExportButton
                {...props}
                entity={props.task}
                exportFunction={exportTask}
                label="Export Task"
                tooltipContent="Export as Markdown & JSON"
            />
        )
    );
}

export function RestoreTaskButton({ onAfterClick, taskId }: { onAfterClick(): void; taskId: string }) {
    const { space } = useSpace();
    const { t } = useTranslation();
    const onRestore = () => {
        SpaceOperations.restoreTask(space, taskId);
    };

    return (
        <PopOver.RoundedButton
            onClick={() => {
                onRestore();
                onAfterClick();
            }}
            aria-label="Restore Task"
            data-testid="restore-button-task"
        >
            <Rewind className="stroke-gray-dark mr-2 my-auto" size={14} />
            {t('pages.restore')}
        </PopOver.RoundedButton>
    );
}

export function DeleteTaskForeverButton({ onAfterClick, taskId }: { onAfterClick(): void; taskId: string }) {
    const history = useHistory();
    const { urlKey } = useCurrentWorkspace();
    const deleteTaskPermanently = useDeleteTaskPermanently();
    const { t } = useTranslation();

    const onDeletePermanently = () => {
        deleteTaskPermanently(taskId);

        history.replace(`/s/${urlKey}`);
    };

    return (
        <PopOver.RoundedButton
            onClick={() => {
                onDeletePermanently();
                onAfterClick();
            }}
            aria-label="Delete forever"
            data-testid="delete-forever-button-task"
        >
            <Trash className="stroke-gray-dark mr-2 my-auto" size={14} />
            {t('pages.delete_forever')}
        </PopOver.RoundedButton>
    );
}

export function TaskContextMenuPopOver({
    isOpen,
    onClose,
    attachToRef,
    task,
    align,
    children,
    editor,
}: {
    isOpen: boolean;
    onClose(): void;
    attachToRef: React.MutableRefObject<HTMLElement | null>;
    task: Pick<WeakTask, 'id' | 'title' | 'archivedAt'>;
    align?: 'left' | 'right' | 'center';
    children?: React.ReactNode;
    editor: RealtimeSagaEditor | null;
}) {
    const taskId = task.id;
    const isArchived = task.archivedAt != null;
    const { isFavorite, toggleFavorite } = useFavoriteButton(taskId);
    const { isPinned, togglePinned } = usePinnedButton(taskId);
    const { isPageAdmin } = usePageAccess(taskId);
    const { workspaces } = useWorkspaces();
    const isMobile = useMobile();

    const archiveTask = useArchiveTask();
    const { space } = useSpace();
    const { t } = useTranslation();

    return (
        <Dropdown
            testId={isOpen ? 'context-menu' : undefined}
            isOpen={isOpen}
            onClose={onClose}
            attachToRef={attachToRef}
            align={align}
        >
            <div>
                {children && children}
                {!isMobile && (
                    <OpenLocationSideBySideButton
                        location={SagaLocation.taskLocationFromId(taskId)}
                        onAfterClick={onClose}
                    />
                )}
                {!isFavorite ? (
                    <AddToFavoritePageButton onAfterClick={onClose} onClick={toggleFavorite} />
                ) : (
                    <RemoveFromFavoriteButton onAfterClick={onClose} onClick={toggleFavorite} />
                )}
                {!isPinned ? (
                    <AddToPinnedPageButton onAfterClick={onClose} onClick={togglePinned} />
                ) : (
                    <RemoveFromPinnedButton onAfterClick={onClose} onClick={togglePinned} />
                )}

                {<DuplicateTaskButton taskId={taskId} />}

                <MoveEntityButton disabled={workspaces.length === 1} entityId={taskId} entityType="task" />

                {!!editor && <UndoChangesButton onAfterClick={onClose} editor={editor} />}
                <ExportTaskButton task={SpaceOperations.findTask(space, taskId)} onAfterClick={onClose} />
                <DocumentHistoryButton pageId={taskId} onAfterClose={onClose} />
                {isPageAdmin && (
                    <div className="pt-0.5 space-y-0.5">
                        <PopOver.Divider />
                        {isArchived ? (
                            <>
                                <RestoreTaskButton onAfterClick={onClose} taskId={taskId} />
                                <DeleteTaskForeverButton onAfterClick={onClose} taskId={taskId} />
                            </>
                        ) : (
                            <PopOver.RoundedButton
                                onClick={() => {
                                    archiveTask(taskId);
                                    onClose();
                                }}
                                data-testid="delete-button-task"
                            >
                                <Trash className="stroke-gray-dark mr-2 my-auto" size={14} />
                                {t('tasks.delete_task')}
                            </PopOver.RoundedButton>
                        )}
                    </div>
                )}
            </div>
        </Dropdown>
    );
}

export function TaskContextMenuButton({
    align,
    isButtonSmall,
    task,
    children,
    editor,
    onOpened,
}: {
    align?: 'left' | 'right' | 'center';
    isButtonSmall?: boolean;
    task: Pick<WeakTask, 'id' | 'title' | 'archivedAt'>;
    children?: (setIsOpen: (open: boolean) => void) => React.ReactNode;
    editor: RealtimeSagaEditor | null;
    onOpened?: (opened: boolean) => void;
}) {
    const buttonRef = React.useRef<HTMLButtonElement>(null);
    const [isOpen, setIsOpen] = React.useState(false);

    React.useEffect(() => {
        onOpened?.(isOpen);
    }, [isOpen, onOpened]);

    return (
        <>
            <ContextMenuButton
                ref={buttonRef}
                isOpen={isOpen}
                onClick={() => {
                    setIsOpen((isOpen) => !isOpen);
                    if (isOpen) track('open-context-menu');
                    else track('close-context-menu');
                }}
                isButtonSmall={Boolean(isButtonSmall)}
            />

            <TaskContextMenuPopOver
                isOpen={isOpen}
                attachToRef={buttonRef}
                align={align}
                onClose={() => setIsOpen(false)}
                task={task}
                editor={editor}
            >
                {children && children(setIsOpen)}
            </TaskContextMenuPopOver>
        </>
    );
}

export default TaskContextMenuButton;
