import React from 'react';
import {
    Align,
    allowedIframeAttributes,
    BlockBuilder,
    SagaEditor,
    EditorOperations,
    Embed as EmbedType,
    isEmbed,
    isGoogleDriveEmbeddableUrl,
} from '@saga/shared';
import { ReactEditor, useSlateStatic } from 'slate-react';
import VoidSelectionShadow from '@/components/editor/VoidSelectionShadow';
import classNames from 'classnames';
import { AlertTriangle, ExternalLink, Link2, Loader, Trash } from 'react-feather';
import { Range, Transforms } from 'slate';
import { PopOver } from '@/components/popover/PopOver';
import Button from '@/components/styled/Button';
import AlignBlockSelect from '@/components/AlignBlockSelect';
import { track } from '@/analytics';
import sanitize from 'sanitize-html';
import * as Sentry from '@sentry/browser';
import * as Popover from '@radix-ui/react-popover';
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';

function Container({
    id,
    metadata,
    onClick,
    children,
}: {
    id: string;
    metadata: EmbedType['metadata'];
    onClick: () => void;
    children: React.ReactNode;
}) {
    const align = metadata._tag === 'loaded' ? metadata.align : 'center';

    return (
        <div
            className={classNames('relative flex flex-row w-full', {
                'justify-start': !align || align === 'left',
                'justify-center': align === 'center',
                'justify-end': align === 'right',
            })}
            onClick={onClick}
            contentEditable={false}
            id={id}
        >
            {children}
        </div>
    );
}

const ContentEmbed = (props: {
    errorState?: JSX.Element;
    loadedState: JSX.Element | null;
    element: EmbedType;
    isSelected: boolean;
}) => {
    const { errorState, element, loadedState } = props;
    const { metadata } = element;

    switch (metadata._tag) {
        case 'loaded':
            return loadedState;
        case 'failed':
            return errorState ?? <LoadingState />;
        case 'loading':
        case 'not-loaded':
            return <LoadingState />;
    }
};

const StaticErrorState = ({ selected }: { selected: boolean }) => {
    return (
        <div
            className={classNames(
                'w-full h-20 border-dashed border rounded border-red-700 flex items-center justify-center text-red-700',
                {
                    'shadow-lightblue': selected,
                },
            )}
        >
            <div className="flex justify-center items-center space-x-1 ">
                <div className="flex space-x-2">
                    <AlertTriangle />
                    <div>The embed is not available.</div>
                </div>
            </div>
        </div>
    );
};

type DragInfo = {
    isDragging: boolean;
    from: [number, number];
    initialWidth: number;
    initialHeight: number;
};

const LoadedState = ({
    iframe,
    size,
    element,
    isSelected,
    url,
    align,
}: {
    iframe: string;
    size: [number, number];
    align: Align;
    title: string;
    url: string;
    element: EmbedType;
    isSelected: boolean;
}) => {
    const ref = React.useRef<HTMLDivElement>(null);
    const editor = useSlateStatic();
    const [dragInfo, setDragInfo] = React.useState<DragInfo>({
        isDragging: false,
        from: [0, 0],
        initialWidth: 0,
        initialHeight: 0,
    });
    const { canEdit } = SagaEditor.useEditorContext();
    const [value, setValue]: any = React.useState();

    React.useLayoutEffect(() => {
        const image = ref.current;

        if (image) {
            function adjustRatio() {
                if (image) {
                    const rect = image.getBoundingClientRect();
                    if (rect.height === 0 || rect.width === 0) {
                        return;
                    }
                    const currentRatio = rect.width / rect.height;
                    if (Math.abs(currentRatio - 1.6) > 0.05) {
                        image.style.height = `${rect.width / 1.6}px`;
                    }
                }
            }

            adjustRatio();

            const resizeObserver = new ResizeObserver(() => {
                adjustRatio();
            });

            resizeObserver.observe(image);

            return () => {
                resizeObserver.unobserve(image);
            };
        }

        return;
    }, []);

    function onResize(size: [number, number]) {
        const path = ReactEditor.findPath(editor, element);
        const windowWidth = window.innerWidth;
        const metadata = { ...element.metadata, size, windowWidth };

        Transforms.setNodes(editor, { metadata } as Partial<Node>, {
            at: path,
        });
    }

    function onAlign(align: Align) {
        const path = ReactEditor.findPath(editor, element);

        const metadata = { ...element.metadata, align };

        Transforms.setNodes(editor, { metadata } as Partial<Node>, {
            at: path,
        });

        track('align-embed');
    }

    function onRemove() {
        const path = ReactEditor.findPath(editor, element);
        Transforms.select(editor, path);
        setTimeout(() => {
            Transforms.removeNodes(editor, { at: path });
        });
        track('remove-embed');
    }

    function turnIntoLink() {
        const path = ReactEditor.findPath(editor, element);
        Transforms.select(editor, path);
        setTimeout(() => {
            Transforms.removeNodes(editor, { at: path });
        });
        track('remove-embed');
        let url = element.url;
        if (!element.url.includes('youtube')) {
            url = element.url.split('?')[0].split('/embed')[0];
        }
        const link = BlockBuilder.paragraph([BlockBuilder.link(url, [BlockBuilder.text(url)])]);

        setTimeout(() => {
            Transforms.insertNodes(editor, link, {
                at: path,
            });
        });
    }
    React.useEffect(() => {
        const loadData = async () => {
            const editorContainerWidth: number = await Number(
                document.querySelector('#editor-container [role="textbox"]')?.clientWidth,
            );
            let calculatedWidth = 0;
            if (size[0] > editorContainerWidth) {
                calculatedWidth = editorContainerWidth;
            } else {
                calculatedWidth = size[0];
            }
            setValue(calculatedWidth);
        };
        loadData();
    }, [size]);

    return (
        <div className="relative">
            <WithSanitizedIframe iframe={iframe}>
                {(sanitizedIframe) => (
                    <div
                        data-testid={`embed-${url}`}
                        ref={ref}
                        className={classNames('max-w-full flex justify-center iframe-container', {
                            'pointer-events-none select-none': dragInfo.isDragging,
                        })}
                        dangerouslySetInnerHTML={{ __html: sanitizedIframe }}
                        style={size ? { width: value, height: Number.isNaN(value / 1.6) ? 0 : value / 1.6 } : undefined}
                    />
                )}
            </WithSanitizedIframe>
            {canEdit && (
                <>
                    <LoadedState.ContextMenu
                        url={url}
                        isSelected={isSelected}
                        onAlign={onAlign}
                        align={align}
                        onRemove={onRemove}
                        turnIntoLink={turnIntoLink}
                    />
                    <div className="absolute right-0 top-0 bottom-0">
                        <LoadedState.Resizer
                            dragInfo={dragInfo}
                            onSetDragInfo={setDragInfo}
                            elementRef={ref}
                            onResize={onResize}
                            ratio={1.6}
                        />
                    </div>
                </>
            )}
        </div>
    );
};

type PopoverRefs = {
    general: React.MutableRefObject<HTMLButtonElement | null>;
    turnIntoPreview: React.MutableRefObject<HTMLButtonElement | null>;
};

const usePopoversController = () => {
    const refsMap = React.useRef<PopoverRefs>({
        general: React.useRef(null),
        turnIntoPreview: React.useRef(null),
    });

    const [popoverVisible, setPopoverVisible] = React.useState<keyof PopoverRefs | null>(null);

    return { refsMap, popoverVisible, setPopoverVisible };
};

LoadedState.ContextMenu = function ContextMenu({
    isSelected,
    onRemove,
    align,
    url,
    onAlign,
    turnIntoLink,
}: {
    isSelected: boolean;
    onRemove: () => void;
    align: Align;
    url: string;
    onAlign(align: Align): void;
    turnIntoLink(): void;
}) {
    const { refsMap, popoverVisible, setPopoverVisible } = usePopoversController();
    const { t } = useTranslation();

    const togglePopover = React.useCallback(
        (tag: Parameters<typeof setPopoverVisible>[0]) => () => {
            const isAlreadyOpen = popoverVisible === tag;

            setPopoverVisible(isAlreadyOpen ? null : tag);
        },
        [popoverVisible, setPopoverVisible],
    );

    return (
        <div
            className={classNames('absolute top-0 right-2 flex group-hover:flex opacity-100 flex-row', {
                hidden: !isSelected,
            })}
        >
            <Popover.Root>
                <Popover.Trigger asChild>
                    <Button.MenuButton
                        aria-label={t('editor.embed_context_menu') as string}
                        aria-haspopup="true"
                        ref={refsMap.current.general}
                        onClick={togglePopover('general')}
                    >
                        <span className="sr-only">{t('editor.embed_context_menu')}</span>
                    </Button.MenuButton>
                </Popover.Trigger>
                <a
                    href={url}
                    target="_blank"
                    className="p-1.5 m-1 bg-white dark:bg-zinc-700 shadow-popupSmall inset-0 rounded focus:outline-none active:shadow-xs"
                    rel="noreferrer"
                >
                    <ExternalLink size={16} />
                </a>
                <Popover.Portal>
                    <Popover.Content onOpenAutoFocus={(e) => e.preventDefault()}>
                        <PopOver.PaddedCard>
                            <AlignBlockSelect align={align} onAlign={onAlign} />
                            <PopOver.RoundedButton onClick={turnIntoLink}>
                                <span className="flex items-center space-x-2">
                                    <Link2 size={16} />
                                    <p>{t('editor.turn_into_link')}</p>
                                </span>
                            </PopOver.RoundedButton>
                            <PopOver.RoundedButton onClick={onRemove}>
                                <span className="flex items-center space-x-2">
                                    <Trash size={16} />
                                    <p>{t('editor.remove_embed')}</p>
                                </span>
                            </PopOver.RoundedButton>
                        </PopOver.PaddedCard>
                    </Popover.Content>
                </Popover.Portal>
            </Popover.Root>
        </div>
    );
};

LoadedState.Resizer = function ImageResizer({
    elementRef,
    onResize,
    ratio,
    dragInfo,
    onSetDragInfo,
}: {
    elementRef: React.RefObject<HTMLElement | null>;
    onResize(size: [number, number]): void;
    ratio: number;
    dragInfo: DragInfo;
    onSetDragInfo: (v: DragInfo) => void;
}) {
    const maxWidth = Number(document.querySelector('.relative.flex.flex-row.w-full.justify-start')?.clientWidth);
    React.useEffect(() => {
        function onPointerUp(e: PointerEvent) {
            if (dragInfo.isDragging && elementRef.current) {
                const from = dragInfo.from;
                const to = [e.clientX, e.clientY];
                const delta = to[0] - from[0];
                const width = dragInfo.initialWidth;
                let newWidth = width + delta;
                const rect = elementRef.current.getBoundingClientRect();
                if (newWidth > maxWidth) {
                    newWidth = maxWidth;
                }
                onResize([newWidth, rect.width / ratio]);
            }
            onSetDragInfo({ ...dragInfo, isDragging: false });
        }

        function onPointerMove(e: PointerEvent) {
            if (dragInfo.isDragging && elementRef.current) {
                const from = dragInfo.from;
                const to = [e.clientX, e.clientY];
                const delta = to[0] - from[0];
                const width = dragInfo.initialWidth;
                let newWidth = width + delta;
                if (newWidth > maxWidth) {
                    newWidth = maxWidth;
                }
                elementRef.current.style.width = `${newWidth}px`;
                const rect = elementRef.current.getBoundingClientRect();
                elementRef.current.style.height = `${rect.width / ratio}px`;
            }
        }

        window.addEventListener('pointermove', onPointerMove);
        window.addEventListener('pointerup', onPointerUp);

        return () => {
            window.removeEventListener('pointermove', onPointerMove);
            window.removeEventListener('pointerup', onPointerUp);
        };
    }, [dragInfo, elementRef, onResize, onSetDragInfo, ratio, maxWidth]);

    return (
        <div
            onPointerDown={(e) => {
                if (elementRef.current) {
                    const from = [e.clientX, e.clientY] as [number, number];
                    const rect = elementRef.current.getBoundingClientRect();

                    onSetDragInfo({
                        isDragging: true,
                        from,
                        initialWidth: rect.width,
                        initialHeight: rect.height,
                    });
                }
            }}
            className="h-full w-3 flex justify-center items-center"
            style={{ cursor: 'ew-resize' }}
        >
            <div
                className="h-full border border-solid border-gray-100 bg-gray-700 rounded-full group-hover:opacity-100 opacity-0 transition-opacity"
                style={{ maxHeight: 40, width: 6 }}
            ></div>
        </div>
    );
};

const LoadingState = () => (
    <div className="flex justify-center h-16 items-center rounded border space-x-2">
        <Loader className="animate-spin" />
        <div>{t('editor.loading_embed')}</div>
    </div>
);

const WithSanitizedIframe = ({
    children,
    iframe,
}: {
    children: (sanitizedIframe: string) => JSX.Element;
    iframe: string;
}) => {
    const sanitized = sanitize(iframe, {
        allowedTags: ['iframe'],
        allowedAttributes: { iframe: allowedIframeAttributes },
    });

    React.useEffect(() => {
        if (sanitized.length === 0) {
            Sentry.captureMessage('Embeddable iframe string does not contain an iframe', {
                level: 'fatal',
                extra: {
                    iframe: iframe,
                },
            });
        }
    }, [iframe, sanitized.length]);

    return children(sanitized);
};

export const embedBlockPlugin = SagaEditor.Plugins.createBlockPlugin({
    match: isEmbed,
    Component({ element, selected, children, path }) {
        const editor = useSlateStatic();

        React.useEffect(() => {
            /* Hack for focusing editor when loading a google drive embed,
            since google sheet autofocuses 1st cell by default and steals user's input */
            const domNode = ReactEditor.toDOMNode(editor, element);
            const IFrame = domNode.querySelector('iframe');

            if (!IFrame || !IFrame?.contentDocument) {
                return;
            }

            const isSpreadSheet = (src: any) => src && isGoogleDriveEmbeddableUrl(src) && src.includes('spreadsheet');
            if (isSpreadSheet(element.url)) {
                IFrame.style.display = 'none';
            }

            const handleIframeLoad = (src: any) => {
                if (src === IFrame.src) {
                    IFrame.style.display = 'inline';
                }
            };

            IFrame?.addEventListener('load', (e: any) => handleIframeLoad(e.target?.src));

            return () => {
                domNode;
                IFrame?.querySelector('iframe')?.removeEventListener('load', (e: any) =>
                    handleIframeLoad(e.target?.src),
                );
            };
        }, [editor, element]);

        React.useEffect(() => {
            if (element.metadata._tag === 'failed') {
                setTimeout(() => {
                    const nodeEntry = EditorOperations.Selection.getNode(editor, element.id);

                    if (nodeEntry == null) return;

                    Transforms.delete(editor, { at: nodeEntry[1] });

                    Transforms.insertNodes(editor, [BlockBuilder.prettyLink(element.url)], {
                        at: nodeEntry[1],
                    });
                });
            }
        }, [editor, element]);

        const isSelectedAlone = Boolean(editor.selection && selected && Range.isCollapsed(editor.selection));

        return (
            <Container
                id={element.id}
                metadata={element.metadata}
                onClick={() => {
                    const path = ReactEditor.findPath(editor, element);
                    EditorOperations.Selection.safeSelectByLocation(editor, {
                        anchor: { path: [...path, 0], offset: 0 },
                        focus: { path: [...path, 0], offset: 0 },
                    });
                }}
            >
                <div
                    style={{
                        maxWidth: '100%',
                    }}
                    className={classNames('group rounded shadow-sm hover:shadow-refHover w-full', {
                        'shadow-lightblue': selected,
                        'w-min': element.metadata._tag === 'loaded',
                    })}
                    contentEditable={false}
                >
                    <VoidSelectionShadow isBlock={true} path={path} plain={true}>
                        <ContentEmbed
                            isSelected={isSelectedAlone}
                            element={element}
                            errorState={<StaticErrorState selected={isSelectedAlone} />}
                            loadedState={
                                element.metadata._tag === 'loaded' ? (
                                    <LoadedState
                                        url={element.url}
                                        element={element}
                                        align={element.metadata.align}
                                        isSelected={isSelectedAlone}
                                        title={element.metadata.title}
                                        size={element.metadata.size}
                                        iframe={element.metadata.iframe}
                                    />
                                ) : null
                            }
                        />
                        <div className="hidden select-none">{children}</div>
                    </VoidSelectionShadow>
                </div>
            </Container>
        );
    },
});
