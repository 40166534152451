import { track } from '@/analytics';
import { useDesktopContext } from '@/components/DesktopContext';
import { SearchBar as HeaderSearchBar } from '@/components/navigation/HeaderBar';
import { useSideBySideIndex, useSideBySideState } from '@/components/SideBySide';
import { useSpace } from '@/components/SpaceProvider';
import SearchBar from '@/components/table/TableSearchBar';
import SortButton from '@/components/table/TableSortButton';
import { useBatchDeletePagesPermanently } from '@/hooks/deletePageHooks';
import { useCollectionsSnapshot, usePageViews } from '@/hooks/SpaceHooks';
import useMobile from '@/hooks/useMobile';
import { useSpaceAccess } from '@/hooks/useSpaceAccess';
import useWorkspaceSettings, { DEFAULT_SETTINGS } from '@/hooks/useWorkspaceSettings';
import type { CreatePageSuggestion } from '@/types';
import { newBlankPage, PageView, SagaLocation, Sorting, SpaceOperations } from '@saga/shared';
import classNames from 'classnames';
import React, { useState } from 'react';
import { Plus, Trash } from 'react-feather';
import DesktopNavigationButtons from '../DesktopNavigationButtons';
import { PageContextMenuButton } from '../popover/PageContextMenu';
import Button from '../styled/Button';
import NavigationTable from './NavigationTable';
import PageTable from './PageTable';
import Table from './Table';
import { TableContext } from './TableContext';
import { useTranslation } from 'react-i18next';
import { useOpenPage, useOpenLocation, locationToUrl } from '../PageNavigationProvider';
import CreateButtonWithSelect from '../popover/CreateButtonWithSelect';
import ActionBar from './ActionBar';
import { createPage } from '@/utils/documentUtils';
import SharedPagesTable from '@/components/table/SharedPagesTable';
import { getColumns as GetSpacePageColumns, getSortOptions as GetSpacePageSortOptions } from './PageTable';
import { getColumns as GetSharedPageColumns, getSortOptions as GetSharedPageSortOptions } from './SharedPagesTable';
import CollectionsFilterSelect from './CollectionsFilterSelect';
import TableSaveViewButton from './TableSaveViewButton';
import PagesTableTitle from './PagesTableTitle';
import PageTableViewDropdown from '@/components/table/PageTableViewDropdown';
import { isEqual } from 'lodash';
import FavoriteButton from '../styled/FavoriteButton';
import { useFavoriteButton } from '@/hooks/useFavoriteButton';
import { ViewContextButton } from './ViewContextButton';
import { useHistory } from 'react-router-dom';
import { useCurrentWorkspace } from '../WorkspaceContext';
import { useMembers } from '../MembersProvider';
import CreatorsFilterSelect from './CreatorsFilterSelect';
import useSortablePages from '@/hooks/useSortablePages';
import { useAllSharedPagesStatusesByUrlKeyLazyQuery } from '@saga/api';
import { usePagesTableColumns, useSharedPagesTableColumns } from '@/hooks/TableHooks';
import { useUserContext } from '../UserContext';

type Props = {
    selectedView: PageView;
    views: PageView[];
    onClose?: () => void;
};

const AllPagesTable = ({ selectedView, views, onClose }: Props) => {
    const { space, provider } = useSpace();
    const [settings, updateUserWorkspaceSettings] = useWorkspaceSettings();

    const [currentView, setCurrentView] = useState<
        PageView & {
            allPagesCollections: NonNullable<PageView['allPagesCollections']>;
            allPagesCreators: NonNullable<PageView['allPagesCreators']>;
            search: NonNullable<PageView['search']>;
        }
    >({
        ...selectedView,
        search: selectedView.search ?? '',
        allPagesSorting:
            (selectedView.isDefaultView ? settings?.allPagesSorting : selectedView.allPagesSorting) ?? null,
        allPagesCollections:
            (selectedView.isDefaultView ? settings?.allPagesCollections : selectedView.allPagesCollections) ?? 'all',
        allPagesCreators:
            (selectedView.isDefaultView ? settings?.allPagesCreators : selectedView.allPagesCreators) ?? 'all',
    });

    const { isOwner, isMember, canEdit } = useSpaceAccess();
    const items = useSortablePages(currentView);
    const { isDesktop } = useDesktopContext();
    const deletePagesPermanently = useBatchDeletePagesPermanently();
    const sideIndex = useSideBySideIndex();
    const { panes } = useSideBySideState();
    const isMobile = useMobile();
    const { t } = useTranslation();
    const openPage = useOpenPage();
    const collections = useCollectionsSnapshot();

    const openLocation = useOpenLocation();
    const { isFavorite, toggleFavorite } = useFavoriteButton(selectedView.id);

    const { user } = useUserContext();
    const [columnOrder, setColumnOrder] = usePagesTableColumns();
    const [sharedColumnOrder, setSharedColumnOrder] = useSharedPagesTableColumns();

    const data = React.useMemo(() => {
        switch (selectedView.mode) {
            case 'shared':
                return {
                    columns: GetSharedPageColumns(sharedColumnOrder),
                    sortOptions: GetSharedPageSortOptions(sharedColumnOrder),
                };
            default:
                return {
                    columns: GetSpacePageColumns(columnOrder),
                    sortOptions: GetSpacePageSortOptions(columnOrder),
                };
        }
    }, [selectedView.mode, columnOrder, sharedColumnOrder]);

    const setSearch = React.useCallback(
        (search: string) => {
            setCurrentView({ ...currentView, search });
        },
        [currentView],
    );

    const setSorting = React.useCallback(
        (sorting: Sorting | null) => {
            setCurrentView({ ...currentView, allPagesSorting: sorting });

            if (currentView.isDefaultView) {
                updateUserWorkspaceSettings({ allPagesSorting: sorting });
            }
            track('sort-table', { source: 'all-pages-table' });
        },
        [currentView, updateUserWorkspaceSettings],
    );

    const SELECT_OPTIONS: CreatePageSuggestion[] = React.useMemo(
        () => [
            { type: 'create', kind: 'page', title: t('pages.new.label_page') },
            { type: 'create', kind: 'page', title: t('pages.new.label_template'), isTemplate: true },
        ],
        [t],
    );
    const { members } = useMembers();

    const CreateButton = React.useMemo(() => {
        return (
            canEdit && (
                <CreateButtonWithSelect
                    onCreate={(event) => {
                        const page = createPage(space, newBlankPage({}), provider);
                        openPage(page.id, event);
                    }}
                    placeholder={t('pages.new.placeholder_page')}
                    selectOptions={SELECT_OPTIONS}
                    icon={<Plus className="flex-none mx-1" size={14} />}
                >
                    {t('pages.new.label_page')}
                </CreateButtonWithSelect>
            )
        );
    }, [canEdit, t, provider, space, openPage, SELECT_OPTIONS]);

    return (
        <TableContext.Provider
            value={{
                search: currentView.search,
                setSearch,
                sorting: currentView.allPagesSorting,
                setSorting,
                columns: data.columns,
            }}
        >
            <NavigationTable>
                <NavigationTable.FixedPaneContainer variant="page-header">
                    <>
                        {isDesktop && sideIndex === 0 && <DesktopNavigationButtons />}
                        <div className="flex flex-grow justify-center">{panes.length === 1 && <HeaderSearchBar />}</div>
                        <div className="flex z-20">
                            <NavigationTable.SearchButton />
                            {canEdit && selectedView.id !== 'non-deleted' && (
                                <>
                                    <FavoriteButton type="page-view" isFavorite={isFavorite} onClick={toggleFavorite} />
                                    <ViewContextButton view={selectedView} type="page-view" />
                                </>
                            )}

                            {onClose && (
                                <NavigationTable.CloseButton
                                    label={t('pages.close_pages') as string}
                                    onClick={onClose}
                                />
                            )}
                            {!onClose && <div style={{ height: 36 }} />}
                        </div>
                    </>
                </NavigationTable.FixedPaneContainer>

                <NavigationTable.FixedPaneContainer variant="page-title">
                    <NavigationTable.EditableHeader
                        renderTitle={() => (
                            <PagesTableTitle
                                // If the page view is one of the default view, we don't want to allow editing the title
                                disabled={!canEdit || selectedView.isDefaultView}
                                title={selectedView.title}
                                onUpdateTitle={(title) => {
                                    SpaceOperations.editPageView(space, selectedView.id, { title });
                                }}
                                autofocus={false}
                            />
                        )}
                        button={CreateButton}
                    />
                </NavigationTable.FixedPaneContainer>

                <ActionBar
                    search={
                        <div
                            className={classNames('w-full', {
                                'mr-2': !isMobile,
                            })}
                        >
                            <SearchBar />
                        </div>
                    }
                    sort={
                        <div className="flex items-center">
                            {(isOwner || isMember) && (
                                <div>
                                    {selectedView.mode === 'deleted' && (
                                        <Button
                                            onClick={() => {
                                                if (window.confirm(t('pages.delete_all_page'))) {
                                                    deletePagesPermanently(items.spacePages.map((page) => page.id));
                                                }
                                            }}
                                            variant="alert"
                                            size="action"
                                            disabled={items.spacePages.length === 0}
                                            className="mr-2"
                                        >
                                            <div className="text-sm space-x-2 flex items-center">
                                                <Trash size={16} />
                                                <span>{t('pages.delete_all_forever')}</span>
                                            </div>
                                        </Button>
                                    )}
                                </div>
                            )}
                            <SortButton sortOptions={data.sortOptions} popoverTestId="page-sort-popover" />
                        </div>
                    }
                    filter={
                        <div className="flex items-center space-x-2">
                            <PageTableViewDropdown
                                pageViews={views}
                                selectedView={selectedView}
                                onSelectView={(view, e) => {
                                    openLocation({ type: 'allPages', viewId: view.id }, e);
                                }}
                            />

                            <CollectionsFilterSelect
                                availableCollections={collections}
                                selectedIds={currentView.allPagesCollections}
                                onUpdateSelectedIds={(ids) => {
                                    track('change-collections-table-filter-mode');
                                    setCurrentView({ ...currentView, allPagesCollections: ids });
                                    currentView.isDefaultView &&
                                        updateUserWorkspaceSettings({ allPagesCollections: ids });
                                }}
                            />

                            <CreatorsFilterSelect
                                availableCreators={members}
                                selectedIds={currentView.allPagesCreators}
                                onUpdateSelectedIds={(ids) => {
                                    track('change-creators-table-filter-mode');
                                    setCurrentView({ ...currentView, allPagesCreators: ids });
                                    currentView.isDefaultView && updateUserWorkspaceSettings({ allPagesCreators: ids });
                                }}
                            />
                        </div>
                    }
                    saveView={
                        !isEqual(currentView, selectedView) &&
                        canEdit &&
                        user && (
                            <TableSaveViewButton
                                showDropdown={!selectedView.isDefaultView}
                                onCreateNewClick={(name, e) => {
                                    const id = SpaceOperations.addPageView(space, {
                                        ...currentView,
                                        title: name,
                                        createdBy: user.id,
                                    });
                                    openLocation({ type: 'allPages', viewId: id }, e);
                                    updateUserWorkspaceSettings({
                                        allPagesCollections: DEFAULT_SETTINGS.allPagesCollections,
                                        allPagesSorting: DEFAULT_SETTINGS.allPagesSorting,
                                        allPagesCreators: DEFAULT_SETTINGS.allPagesCreators,
                                    });
                                    setSearch('');
                                }}
                                onUpdateClick={() => {
                                    SpaceOperations.editPageView(space, selectedView.id, currentView);
                                }}
                            />
                        )
                    }
                />

                {selectedView.mode === 'shared' ? (
                    <>
                        {items.sharedPages.length > 0 ? (
                            <SharedPagesTable
                                items={items.sharedPages}
                                testId="shared-pages-table"
                                columnKeys={sharedColumnOrder}
                                onColumnOrderChange={setSharedColumnOrder}
                            />
                        ) : (
                            <Table.EmptyState>{t('pages.empty_state_shared')}</Table.EmptyState>
                        )}
                    </>
                ) : items.spacePages.length > 0 ? (
                    <PageTable
                        onColumnOrderChange={setColumnOrder}
                        columnKeys={columnOrder}
                        testId="all-pages-table"
                        items={items.spacePages}
                        renderContextMenuButton={(item) => {
                            return <PageContextMenuButton align="left" isButtonSmall page={item.data} editor={null} />;
                        }}
                    />
                ) : (
                    <Table.EmptyState>
                        {selectedView.mode === 'deleted' && t('pages.empty_state_deleted')}
                        {selectedView.mode === 'non-deleted' && t('pages.empty_state_non_deleted')}
                        {selectedView.mode === 'templates' && t('pages.empty_state_templates')}
                        {selectedView.mode === 'private' && t('pages.empty_state_private')}
                        {selectedView.mode === 'public' && t('pages.empty_state_public')}
                    </Table.EmptyState>
                )}
            </NavigationTable>
        </TableContext.Provider>
    );
};

const AllPagesTableWithLocation = ({
    location,
    onClose,
}: {
    location: SagaLocation.AllPagesLocation;
    onClose?: () => void;
}) => {
    const { urlKey } = useCurrentWorkspace();
    const history = useHistory();

    const pageViews = usePageViews();
    const pageView = React.useMemo(() => {
        return pageViews.find((view) => view.id === location.viewId) ?? null;
    }, [location.viewId, pageViews]);

    const [getSharedPagesStatuses] = useAllSharedPagesStatusesByUrlKeyLazyQuery();

    React.useEffect(() => {
        if (!pageView) {
            history.replace(locationToUrl({ type: 'allPages', viewId: 'non-deleted' }, urlKey));
        }
    }, [pageView, urlKey, history]);

    React.useEffect(() => {
        switch (pageView?.mode) {
            case 'public':
            case 'private':
                getSharedPagesStatuses({ variables: { input: { urlKey } }, fetchPolicy: 'cache-and-network' });
                break;
            default:
                break;
        }
    }, [pageView?.mode, urlKey, getSharedPagesStatuses]);

    return pageView ? <AllPagesTable selectedView={pageView} views={pageViews} onClose={onClose} /> : null;
};

export default AllPagesTableWithLocation;
