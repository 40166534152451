import React from 'react';

export type SettingsType =
    | 'account'
    | 'appearance'
    | 'editor'
    | 'invites'
    | 'space'
    | 'members'
    | 'integrations'
    | 'import-export'
    | 'plans'
    | 'billing'
    | 'sagaai'
    | 'language'
    | 'labels';

const SettingsContext = React.createContext<SettingsType | null>(null);
const CloseSettingsContext = React.createContext<() => void>(() => {});
const SetSettingsContext = React.createContext<(settings: SettingsType | null) => void>(() => {});

export const useSettings = () => React.useContext(SettingsContext);
export const useCloseSettings = () => React.useContext(CloseSettingsContext);
export const useSetSettings = () => React.useContext(SetSettingsContext);

export default function SettingsProvider({ children }: { children: React.ReactNode }) {
    const [settings, setSettings] = React.useState<SettingsType | null>(null);
    const onClose = React.useCallback(() => setSettings(null), []);

    return (
        <SettingsContext.Provider value={settings}>
            <CloseSettingsContext.Provider value={onClose}>
                <SetSettingsContext.Provider value={setSettings}>{children}</SetSettingsContext.Provider>
            </CloseSettingsContext.Provider>
        </SettingsContext.Provider>
    );
}
