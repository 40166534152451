import React from 'react';
import DeleteUser from './DeleteUser';
import ListUsers from './ListUsers';
import ListWorkspaces from './ListWorkspaces';
import { Link, Route, Switch, useLocation } from 'react-router-dom';
import classnames from 'classnames';

export default function Admin() {
    const location = useLocation();

    return (
        <div className="flex h-full">
            <div className=" w-60  border-r border-saga-gray-200 dark:border-zinc-700">
                <div className="p-2 border-b border-saga-gray-200 dark:border-zinc-700">Saga Admin</div>
                <div className="p-2">
                    <Link
                        to="/admin/list-users"
                        className={classnames('block', { underline: location.pathname === '/admin/list-users' })}
                    >
                        List Users
                    </Link>
                    <Link
                        to="/admin/delete-users"
                        className={classnames('block', { underline: location.pathname === '/admin/delete-users' })}
                    >
                        Delete Users
                    </Link>
                    <Link
                        to="/admin/list-workspaces"
                        className={classnames('block', { underline: location.pathname === '/admin/list-workspaces' })}
                    >
                        List Workspaces
                    </Link>
                </div>
            </div>
            <div className="w-full p-2 overflow-auto">
                <Switch>
                    <Route path="/admin/list-users">
                        <ListUsers />
                    </Route>
                    <Route path="/admin/delete-users">
                        <DeleteUser />
                    </Route>
                    <Route path="/admin/list-workspaces">
                        <ListWorkspaces />
                    </Route>
                </Switch>
            </div>
        </div>
    );
}
