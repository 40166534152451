import { track } from '@/analytics';
import * as Select from '@radix-ui/react-select';
import * as api from '@saga/api';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { ChevronDown } from 'react-feather';
import { useUserContext } from '../UserContext';
import { useToastContext } from '../toast/ToastContext';
import { SimpleTitleToast } from '../toast/ToastLibrary';
import SettingsPane from './SettingsPane';
import Modal from '../Modal';
import Button from '../styled/Button';
import { useTranslation } from 'react-i18next';
import { SettingsToggle } from './SettingsToggle';
import { ConfirmationModal } from '../modal/ConfirmationModal';

export function LanguageSettings() {
    const { user, userSettings } = useUserContext();
    const [updateUserSettings] = api.useUpdateUserSettingsMutation({ refetchQueries: [api.UserDataDocument] });
    const [language, setLanguage] = useState<api.Language>();
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const { showToast } = useToastContext();
    const { t } = useTranslation();
    const [updateUserLanguage, { loading }] = api.useUpdateUserLanguageMutation({
        refetchQueries: [api.UserDataDocument],
    });

    useEffect(() => {
        if (user) {
            setLanguage(user.data.language);
        }
    }, [user]);

    if (!user) return null;

    const onConfirm = async () => {
        if (!language) return;
        await updateUserLanguage({
            variables: { input: { language } },
            onCompleted: () => {
                setShowConfirmModal(false);
                showToast(() => (
                    <SimpleTitleToast>{t('settings.language.language_changed_notification')}</SimpleTitleToast>
                ));
                setTimeout(() => window.location.reload(), 1000);
            },
        });

        track('update-user-language-settings', { language });
    };

    return (
        <>
            <SettingsPane>
                <div>
                    <SettingsPane.Title>{t('settings.language.title')}</SettingsPane.Title>
                    <SettingsPane.Small>{t('settings.language.sub_title')}</SettingsPane.Small>
                </div>
                <SettingsPane.Content>
                    <SettingsPane.SectionFlex>
                        <SettingsToggle
                            title={t('settings.appearance.start_week_on_monday')}
                            checked={Boolean(userSettings?.weekStartOnMonday)}
                            onChange={(weekStartOnMonday) => {
                                if (weekStartOnMonday) {
                                    track('enable-week-start-on-monday');
                                } else {
                                    track('disable-week-start-on-monday');
                                }
                                updateUserSettings({ variables: { input: { weekStartOnMonday } } });
                            }}
                        />
                    </SettingsPane.SectionFlex>
                    <SettingsPane.SectionFlex>
                        <h2>{t('settings.language.language')}</h2>

                        <Select.Root
                            value={user.data.language}
                            onValueChange={(value: api.Language) => {
                                setShowConfirmModal(true);
                                setLanguage(value);
                            }}
                        >
                            <Select.Trigger
                                disabled={loading}
                                className="flex flex-row space-x-1 items-center focus:outline-none cursor-pointer px-2 h-8 text-xs border font-semibold rounded hover:bg-saga-gray-200 dark:hover:bg-zinc-600 border-saga-gray-200 dark:border-zinc-600 "
                            >
                                <Select.Value />
                                <Select.Icon>
                                    <ChevronDown size={18} />
                                </Select.Icon>
                            </Select.Trigger>

                            <Select.Content className="z-10 bg-white dark:bg-zinc-700 border border-saga-gray-200 dark:border-zinc-600 rounded shadow-popoverSmall text-xs">
                                <Select.ScrollUpButton />
                                <Select.Viewport className="rounded">
                                    <ViewModeSelectItem value={api.Language.En} selected={language === api.Language.En}>
                                        <span>English (US)</span>
                                    </ViewModeSelectItem>
                                    <ViewModeSelectItem value={api.Language.Fr} selected={language === api.Language.Fr}>
                                        <span>Français (France)</span>
                                    </ViewModeSelectItem>
                                    <ViewModeSelectItem value={api.Language.Es} selected={language === api.Language.Es}>
                                        <span>Español (España)</span>
                                    </ViewModeSelectItem>
                                    <ViewModeSelectItem value={api.Language.Pt} selected={language === api.Language.Pt}>
                                        <span>Português (Brasil)</span>
                                    </ViewModeSelectItem>
                                    <ViewModeSelectItem value={api.Language.De} selected={language === api.Language.De}>
                                        <span>Deutsch (Deutschland)</span>
                                    </ViewModeSelectItem>
                                    <ViewModeSelectItem value={api.Language.It} selected={language === api.Language.It}>
                                        <span>Italiano (Italia)</span>
                                    </ViewModeSelectItem>
                                </Select.Viewport>
                                <Select.ScrollDownButton />
                            </Select.Content>
                        </Select.Root>
                    </SettingsPane.SectionFlex>
                </SettingsPane.Content>
            </SettingsPane>

            {showConfirmModal && (
                <ConfirmationModal
                    confirmText={t('common.update')}
                    onConfirm={onConfirm}
                    onCancel={() => setShowConfirmModal(false)}
                    disabled={loading}
                    title={t('settings.language.language_change_title')}
                    description={t('settings.language.language_change_confirmation')}
                />
            )}
        </>
    );
}

function ViewModeSelectItem({
    value,
    children,
    selected,
}: {
    value: string;
    children: React.ReactNode;
    selected: boolean;
}) {
    return (
        <Select.Item
            value={value}
            className={classNames('focus:outline-none font-semibold cursor-pointer px-2 py-2', {
                'bg-saga-gray-200 dark:text-zinc-800': selected,
                'hover:bg-saga-gray-200 dark:hover:bg-zinc-600': !selected,
            })}
        >
            <Select.ItemText>{children}</Select.ItemText>
        </Select.Item>
    );
}
