import {
    SagaElement,
    isVoid as isVoidGuard,
    EditorOperations,
    isLink,
    isInline as isInlineGuard,
    isSagaElement,
    isSagaText,
    PATTERNS,
    BlockType,
    BlockBuilder,
    isBlockType,
    isListItem,
    isNumberedListItem,
    isCheckListItem,
    isTableCell,
    isIndentContainer,
    isParagraph,
    isNumberedList,
    isHeading,
    isBlockquote,
    isTable,
    isTitle,
    isLiveBlockSource,
    isBlockContainer,
    isImage,
    isDivider,
    isKatexBlock,
    isKatexInline,
    isTableRow,
    SagaText,
    MarkType,
    BlockContainer,
    isAISuggestedText,
    isCallout,
} from '..';
import { Transforms, Range, Editor, Node, NodeEntry, NodeMatch, Point, Location, BaseRange } from 'slate';
import { Path } from 'slate';
import * as Y from 'yjs';
import { v4 as uuid } from 'uuid';
import { Plugins } from '../Editor';

export const insertNode = (editor: Editor) => {
    const { insertNode } = editor;
    return (node: any) => {
        if (Editor.isBlock(editor, node)) {
            return insertNode({ ...node, id: uuid() });
        }
        return insertNode(node);
    };
};

function transformLastWord({
    path,
    lastWord,
    offset,
    editor,
}: {
    path: Path;
    editor: Editor;
    lastWord: string;
    offset: number;
}) {
    const isLink = lastWord.match(PATTERNS.link);
    if (isLink) {
        const startIndex = offset - lastWord.length;
        const range = {
            anchor: {
                path,
                offset: startIndex,
            },
            focus: {
                path,
                offset: offset,
            },
        };
        wrapLink(editor, range, lastWord);
    }
}

export const insertBreak = (editor: Editor, undoManager?: Y.UndoManager) => {
    const { insertBreak } = editor;

    return () => {
        const { selection } = editor;
        if (!selection) return;

        const [node, path] = Editor.node(editor, selection);
        const closestBlockElement = Editor.above(editor, {
            at: selection,
            match(node) {
                return isSagaElement(node) && !isInlineGuard(node);
            },
            mode: 'lowest',
        });

        if (!closestBlockElement) return;

        const [parentNode, parentPath] = closestBlockElement;

        // if cursor is at the beginning of a block and it's not a paragraph, a list item or an inline item
        if (
            Range.isCollapsed(selection) &&
            Editor.isStart(editor, selection.anchor, parentPath) &&
            !isBlockType(parentNode, [isParagraph, isListItem, isCheckListItem, isNumberedListItem, isInlineGuard])
        ) {
            if (Node.string(parentNode).length > 0) {
                // if the block is full, add one paragraph before
                Transforms.insertNodes(editor, [BlockBuilder.paragraph()], { at: selection });
            } else {
                // else transform it into a paragraph
                Transforms.setNodes(editor, { type: BlockType.PARAGRAPH });
            }
            return;
        }

        // edge case: first block in a blockcontainer, before a void node
        const blockContainerParent = Editor.above(editor, { match: isBlockContainer });
        const nextElement = EditorOperations.SagaElement.findNext(editor, parentPath);

        if (Range.isCollapsed(selection) && blockContainerParent && blockContainerParent[0].collapsed) {
            const listItem = Editor.above(editor, {
                at: selection,
                match: isListItem,
                mode: 'lowest',
            });
            if (listItem) {
                const [, path] = listItem;

                if (Editor.isEnd(editor, selection.focus, path)) {
                    const nextPath = Path.next(blockContainerParent[1]);
                    Transforms.insertNodes(editor, BlockBuilder.listItem([]), {
                        at: nextPath,
                    });
                    Transforms.select(editor, nextPath);
                    return;
                }
            }
        }

        if (blockContainerParent && nextElement && Editor.isVoid(editor, nextElement[0])) {
            insertBreak();
            return;
        }

        if (Range.isCollapsed(selection)) {
            // if inline is at the end of the block, we need to move the offset by one
            if (isLink(parentNode) && Editor.isEnd(editor, selection.focus, path)) {
                Transforms.move(editor, { distance: 1, unit: 'offset' });
            }

            if (isCheckListItem(parentNode) && Editor.isStart(editor, selection.focus, parentPath)) {
                Editor.withoutNormalizing(editor, () => {
                    Transforms.insertNodes(editor, [BlockBuilder.checkListItem('')]);
                    Transforms.select(editor, Editor.start(editor, Path.next(parentPath)));
                });

                return;
            }

            // first we insert the break itself by calling editor's insertBreak, which splits the nodes
            if (isSagaText(node)) {
                const path = selection.focus.path;
                const offset = selection.focus.offset;
                const lastWord = node.text.slice(0, offset).split(' ').reverse()[0];
                undoManager?.stopCapturing();

                insertBreak();
                // we need to call onChange here to flush any sync operations so that they don't get merged with the transform
                // and we can capture the text insertion atomically
                editor.onChange();

                undoManager?.stopCapturing();
                transformLastWord({ path, lastWord, offset, editor });
            }
        } else {
            insertBreak();
        }

        Transforms.setNodes(editor, { id: uuid() });

        // check if the current parent node is a list
        // otherwise turn it into a paragraph
        if (!isBlockType(parentNode, [isListItem, isNumberedListItem, isCheckListItem])) {
            Transforms.setNodes(editor, { type: BlockType.PARAGRAPH });
        } else if (isCheckListItem(parentNode)) {
            // set new checkboxes to unchecked by default
            Transforms.setNodes(editor, { checked: false });
        }
    };
};

// runs everytime a new line break is inserted while hitting the shift key
export const insertBreakWithShift = (editor: Editor) => {
    const { selection } = editor;

    if (selection && Range.isCollapsed(selection)) {
        const voidNodeEntry = Editor.above(editor, {
            at: selection,
            voids: true,
            match: isVoidGuard,
            mode: 'lowest',
        });

        if (voidNodeEntry) {
            const after = Path.next(voidNodeEntry[1]);
            Transforms.insertNodes(editor, [BlockBuilder.paragraph()], { at: after });
            Transforms.select(editor, after);
            return;
        }

        const tableCellEntry = Editor.above(editor, {
            at: selection,
            match: isTableCell,
            mode: 'lowest',
        });

        if (tableCellEntry) {
            if (Editor.isEnd(editor, selection.focus, tableCellEntry[1])) {
                EditorOperations.Tables.insertRowAfterTableCell(editor, tableCellEntry);
                return;
            }
        }

        const entry = Editor.above(editor, {
            at: selection,
            match: isTitle,
            mode: 'lowest',
        });
        if (entry) {
            editor.insertBreak();
        } else {
            editor.insertText(`\n`);
        }
    }
};

export const insertBreakIfMatch = (editor: Editor, match: NodeMatch<SagaElement>): void | 'continue' => {
    const { selection } = editor;

    if (selection == null) return;

    const entry = Editor.above(editor, {
        at: selection,
        voids: true,
        match,
        mode: 'lowest',
    });

    if (entry) {
        const [node, path] = entry;
        if (isInlineGuard(node)) {
            Editor.insertBreak(editor);
            return;
        } else {
            Editor.withoutNormalizing(editor, () => {
                const after = Path.next(path);
                Transforms.insertNodes(editor, [BlockBuilder.paragraph()], { at: after });
                Transforms.select(editor, after);
            });

            return;
        }
    } else {
        return 'continue';
    }
};

export const insertText = (editor: Editor, undoManager?: Y.UndoManager) => {
    const { insertText } = editor;
    return (text: string) => {
        if (editor.selection) {
            if (Range.isCollapsed(editor.selection)) {
                const isEdge = Editor.isEdge(editor, editor.selection.focus, editor.selection.focus.path);

                if (isEdge) {
                    const node = Node.parent(editor, editor.selection.focus.path);
                    const isWithinLink = isSagaElement(node) && isLink(node);
                    if (isWithinLink && text === ' ' && isEdge) {
                        // This moves the cursor out of the current link element so that the writing will continue outside
                        Transforms.move(editor, { distance: 1, unit: 'offset' });
                    }

                    const haveColorHighlighter = [...Node.texts(node)].some((text) => text[0].colorHighlighter);

                    if (haveColorHighlighter) {
                        EditorOperations.Marks.removeMark(editor, 'colorHighlighter');
                    }
                }
            }
        }

        if (editor.selection && Range.isCollapsed(editor.selection) && text === ' ') {
            const [node] = Editor.node(editor, editor.selection);
            if (isSagaText(node)) {
                const path = editor.selection.focus.path;
                const offset = editor.selection.focus.offset;
                const lastWord = node.text.slice(0, offset).split(' ').reverse()[0];
                undoManager?.stopCapturing();

                insertText(text);
                // we need to call onChange here to flush any sync operations so that they don't get merged with the transform
                // and we can capture the text insertion atomically
                editor.onChange();

                undoManager?.stopCapturing();
                transformLastWord({ path, lastWord, offset, editor });
            }
        } else {
            insertText(text);
        }
    };
};

export const deleteForward = (editor: Editor) => {
    const { deleteForward } = editor;

    return (unit: 'character' | 'word' | 'line' | 'block') => {
        const { selection } = editor;

        if (unit === 'character') {
            // if the caret is on the text and it's not expanded
            if (selection && Range.isCollapsed(selection)) {
                // find the parent node from the current selection
                const match: NodeEntry<SagaElement> | undefined = Editor.above(editor, {
                    match: (n) => Editor.isBlock(editor, n as any),
                });

                if (match) {
                    const [node, path] = match;

                    // if the next node is void, delete the void node
                    if (Editor.isEnd(editor, selection.focus, path)) {
                        const after = EditorOperations.SagaElement.findNext(editor, path);
                        if (after) {
                            if (Editor.isVoid(editor, after[0])) {
                                Transforms.removeNodes(editor, { at: after[1] });
                                return;
                            }
                        }
                    }

                    // if the parent is a list element, and text is empty, merge this block with next one
                    if (
                        isBlockType(node, [isListItem, isNumberedListItem, isCheckListItem]) &&
                        Editor.isStart(editor, selection.focus, path) &&
                        Node.string(node).length === 0
                    ) {
                        const next = Editor.next(editor, { at: path });
                        if (next) {
                            Transforms.setNodes(editor, { type: node.type }, { at: next[1] });
                            Transforms.delete(editor, { at: path });
                            Transforms.move(editor, { distance: 1, unit: 'character' });
                        }
                        return;
                    }
                }
            }
        }

        return deleteForward(unit);
    };
};

function unwrapNodeWithBlockId(editor: Editor, nodeId: string) {
    const nodes = Editor.nodes(editor, { match: (n) => isSagaElement(n) && n.id === nodeId });
    const first = nodes.next().value;
    if (first) {
        const [, at] = first;
        Transforms.unwrapNodes(editor, { at });
    }
}

function findCollapsedBlockContainer(editor: Editor, at: Location) {
    return Editor.above(editor, {
        match(node): node is BlockContainer {
            return isBlockContainer(node) && node.collapsed === true;
        },
        at,
    });
}

function findPrevious(editor: Editor, path: Path, nested = true): NodeEntry<SagaElement> | undefined | null {
    try {
        let before = Editor.previous<SagaElement>(editor, { at: path });

        // if previous node is a BlockType.BLOCK_CONTAINER
        // if 'nested' is true, get the last child at the lowest depth
        // otherwise, get the first child at the highest depth
        if (before && isBlockType(before[0], isBlockContainer)) {
            if (nested) {
                const previousChildren = [
                    ...Editor.nodes<SagaElement>(editor, {
                        at: before[1],
                        mode: 'lowest',
                        match: (n) => Editor.isBlock(editor, n as any),
                    }),
                ];

                if (previousChildren.length > 0) {
                    before = previousChildren[previousChildren.length - 1];
                }
            } else {
                const previousChildren = [...Node.children(editor, before[1])];

                if (previousChildren.length > 0) {
                    before = previousChildren[0] as NodeEntry<SagaElement>;
                }
            }
        }

        const match = Editor.above<SagaElement>(editor, {
            at: path,
            match: (n) => Editor.isBlock(editor, n as any),
            mode: 'lowest',
        });

        // if there is a level up and the parent is a BlockType.BLOCK_CONTAINER, get the previous node recursively
        if (match && match[0].type === BlockType.BLOCK_CONTAINER) {
            return findPrevious(editor, match[1], nested);
        }

        // if there is a level up and the parent is a BlockType.INDENT, get the first node of the parent
        if (!before && nested && match && match[0].type === BlockType.INDENT) {
            before = Editor.previous(editor, { at: match[1] });
        }
        return before;
    } catch {
        return null;
    }
}

// runs anytime a character is deleted
export const deleteBackward = (editor: Editor, blockPlugins: Plugins.BlockPlugin[]) => {
    const { deleteBackward } = editor;

    return (unit: 'character' | 'word' | 'line' | 'block') => {
        const { selection } = editor;

        // Forbid deletion of AISuggestedText
        if (Editor.nodes(editor, { match: isAISuggestedText }).next().value) {
            return;
        }

        if (unit === 'character') {
            // if the caret is on the text and it's not expanded
            if (selection && Range.isCollapsed(selection)) {
                const tableCellEntry = Editor.above(editor, {
                    at: selection,
                    match: isTableCell,
                    mode: 'lowest',
                });

                if (tableCellEntry) {
                    const [tableCell, tableCellPath] = tableCellEntry;

                    if (Editor.isStart(editor, selection.focus, tableCellPath)) {
                        const firstChild = tableCell.children[0];
                        if (
                            !isBlockType(firstChild, [
                                isCheckListItem,
                                isListItem,
                                isNumberedListItem,
                                isNumberedList,
                                isBlockquote,
                                isImage,
                                isDivider,
                                isKatexBlock,
                                isKatexInline,
                            ])
                        ) {
                            const tableRow = Editor.above(editor, {
                                at: selection,
                                match: isTableRow,
                                mode: 'lowest',
                            });

                            if (tableRow) {
                                const [tableRowNode] = tableRow;
                                const isFirstCellInRow = tableCellPath[tableCellPath.length - 1] === 0;
                                const isEmpty =
                                    EditorOperations.SagaElement.toString(tableRowNode, blockPlugins) === '';

                                if (isFirstCellInRow && isEmpty) {
                                    EditorOperations.Tables.deleteRow(editor, tableRow);
                                } else {
                                    EditorOperations.Tables.jumpToPreviousTableCell(editor, tableCellEntry);
                                }

                                return;
                            }
                        }
                    }
                }

                const [topLevelIndex] = selection.focus.path;
                const [topLevelNode] = Editor.node(editor, [topLevelIndex]);

                // check if the selection is at the start of the live block source
                if (isLiveBlockSource(topLevelNode) && Editor.isStart(editor, selection.focus, [topLevelIndex])) {
                    const before = Editor.before(editor, [topLevelIndex]);

                    if (before) {
                        const [nodeBefore] = Editor.node(editor, before);
                        const isEmpty = Node.string(nodeBefore).length === 0;
                        if (isEmpty) {
                            Transforms.removeNodes(editor, { at: [topLevelIndex - 1] });
                            return;
                        }
                    }

                    if (
                        window?.confirm(
                            "By moving this content up the source of live blocks will be removed and live blocks won't sync anymore with this block.\nDo you want to continue?",
                        )
                    ) {
                        unwrapNodeWithBlockId(editor, topLevelNode.id);
                    }
                    return;
                }

                // This makes sure that deleting backward after certain blocks will not delete the entire block,
                // but will step back into the block
                // e.g. if you delete after an empty task, you will just move into the task
                if (isParagraph(topLevelNode) && Editor.isStart(editor, selection.focus, [topLevelIndex])) {
                    const before = Editor.before(editor, [topLevelIndex]);

                    if (before) {
                        const [nodeBefore] = Editor.node(editor, before, { depth: 1 });

                        if (
                            isBlockType(nodeBefore, [
                                isListItem,
                                isCheckListItem,
                                isNumberedList,
                                isHeading,
                                isBlockquote,
                                isTable,
                            ])
                        ) {
                            Transforms.move(editor, { distance: 1, unit: 'character', reverse: true });
                            Transforms.removeNodes(editor, { at: [topLevelIndex] });
                            const endOfBefore = Editor.end(editor, before);
                            Transforms.insertFragment(editor, topLevelNode.children, { at: endOfBefore });
                            Transforms.select(editor, endOfBefore);
                            return;
                        }
                    }
                }

                const parentBlockEntry = Editor.above(editor, {
                    at: selection,
                    match: (node) => isSagaElement(node) && !isInlineGuard(node),
                    mode: 'lowest',
                });

                if (parentBlockEntry) {
                    const [parentNode, parentPath] = parentBlockEntry;
                    const [calloutParrent] =
                        Editor.above(editor, {
                            at: parentPath,
                            match: isCallout,
                        }) ?? [];

                    // this code fixes a bug where if we void block and after it we have callout
                    // this code makes sure that we delete the void block correctly
                    if (selection && Range.isCollapsed(selection) && calloutParrent) {
                        const { anchor } = selection;

                        //This checks if the cursor is at the start of the parent block.
                        if (Point.equals(anchor, Editor.start(editor, parentPath))) {
                            //This finds the point before the current block, considering line breaks and void nodes.
                            const previousPoint = Editor.before(editor, parentPath, {
                                unit: 'line',
                                distance: 1,
                                voids: true,
                            });

                            //If a previous point is found, it looks for a void node above this point.
                            //A void node is a node that cannot contain editable content (e.g., an image, file, divider)
                            if (previousPoint) {
                                const voidNodeEntry = Editor.above(editor, {
                                    at: previousPoint,
                                    voids: true,
                                    match(node) {
                                        return isVoidGuard(node) && !isInlineGuard(node);
                                    },
                                });
                                const isEmtpy = EditorOperations.SagaElement.toString(parentNode, blockPlugins) === '';

                                if (voidNodeEntry && isEmtpy) {
                                    //If there is a void node entry and the parent node is empty,
                                    //it removes the parent node and moves the selection to the previous point.
                                    Transforms.removeNodes(editor, { at: parentPath });
                                    Transforms.select(editor, previousPoint);
                                    return;
                                } else if (voidNodeEntry && !isEmtpy) {
                                    //If there is a void node entry and the parent node is not empty,
                                    // it just moves the selection to the previous point.
                                    Transforms.select(editor, previousPoint);
                                    return;
                                }
                            }
                        }
                    }

                    // in case we are at the very start of the parent block, we always want to make sure
                    // that we select the actual end afterwards. this way, inline void nodes will not be selected
                    // but the cursor will move at the very end of the block
                    if (Editor.isStart(editor, selection.focus, parentPath)) {
                        if (!isBlockType(parentNode, [isTitle, isParagraph])) {
                            const collapsedBlockContainer = findCollapsedBlockContainer(editor, selection.focus);

                            if (collapsedBlockContainer) {
                                Transforms.setNodes(editor, { collapsed: false }, { at: collapsedBlockContainer[1] });
                            }
                            Transforms.setNodes(editor, BlockBuilder.paragraph());
                            return;
                        }

                        // we unwrap any indents
                        const indent = Editor.above(editor, {
                            at: selection,
                            match: isIndentContainer,
                            mode: 'lowest',
                        });

                        if (indent) {
                            if (calloutParrent) {
                                // check if whole callout is indented and delete only if deleting from the first element
                                if (
                                    !indent[0].children.includes(calloutParrent) ||
                                    parentNode === calloutParrent?.children[0]
                                ) {
                                    Transforms.unwrapNodes(editor, { match: isIndentContainer, split: true });
                                    return;
                                }
                            } else {
                                Transforms.unwrapNodes(editor, { match: isIndentContainer, split: true });
                                return;
                            }
                        }

                        // if the previous node is void, and the current node is not empty, do not remove the void node but select it
                        const previous = findPrevious(editor, parentPath);

                        if (previous) {
                            const [previousNode, previousPath] = previous;

                            if (Editor.isVoid(editor, previousNode)) {
                                const isEmtpy = EditorOperations.SagaElement.toString(parentNode, blockPlugins) === '';
                                if (isEmtpy) {
                                    Transforms.delete(editor, { at: parentPath });
                                }
                                Transforms.select(editor, previousPath);
                                return;
                            }

                            if (isListItem(previousNode)) {
                                const previousCollapsedBlockContainer = findCollapsedBlockContainer(
                                    editor,
                                    previousPath,
                                );

                                if (previousCollapsedBlockContainer) {
                                    const endOfCollapsedBlock = Editor.end(editor, [
                                        ...previousCollapsedBlockContainer[1],
                                        0,
                                    ]);
                                    deleteBackward(unit);
                                    Transforms.select(editor, endOfCollapsedBlock);
                                    return;
                                }
                            }

                            // we get the end point before deletion
                            const endOfBefore = Editor.end(editor, previousPath);

                            if (isCallout(topLevelNode) && isNumberedList(previousNode)) {
                                Editor.withoutNormalizing(editor, () => {
                                    Editor.deleteForward(editor, { unit: 'block' });
                                    Transforms.select(editor, endOfBefore);
                                });

                                return;
                            } else if (isNumberedList(previousNode)) {
                                Editor.withoutNormalizing(editor, () => {
                                    deleteBackward(unit);
                                    Transforms.select(editor, endOfBefore);
                                });
                                return;
                            }

                            // we execute the deletion
                            deleteBackward(unit);
                            // now we can select the previous endpoint,
                            // this makes sure that the cursor is in between the previous and the next content which might have moved up
                            Transforms.select(editor, endOfBefore);
                            return;
                        }
                    }
                }
            }
        }
        deleteBackward(unit);
    };
};

export function indent(editor: Editor, indentForward: boolean, event?: React.KeyboardEvent) {
    const { selection } = editor;
    if (!selection) return;

    const parentIndentContainer = Editor.above(editor, { match: isIndentContainer, mode: 'lowest' }) || [];
    const previousContainer = Editor.previous(editor, { match: isSagaElement, at: parentIndentContainer[1] });

    // First we find the closest block element above the current selection
    // that is NOT an inline element
    const closestBlockElement = Editor.above(editor, {
        at: selection,
        match(node) {
            return isSagaElement(node) && !isInlineGuard(node);
        },
        mode: 'lowest',
    });

    // it could be that we don't have closest block element, it that case, we would be top level
    if (closestBlockElement) {
        const [node, path] = closestBlockElement;
        const isFirstChild = path[path.length - 1] === 0;

        const [parentNode, parentPath] = Editor.parent(editor, path);

        // we opt out if we are within a table
        if (isBlockType(node, [isTable])) {
            return;
        }

        // within a table cell, we will instead default to move between cells in a table
        const tableCell = EditorOperations.Tables.getEnclosingTableCellForCurrentSelection(editor);

        if (tableCell) {
            if (event && event.shiftKey) {
                EditorOperations.Tables.jumpToPreviousTableCell(editor, tableCell);
                return;
            }

            EditorOperations.Tables.jumpToNextTableCell(editor, tableCell);
            return;
        }

        const previous = findPrevious(editor, path);

        if (previous) {
            const collapsedBlockContainer = findCollapsedBlockContainer(editor, previous[1]);
            if (collapsedBlockContainer) {
                Transforms.setNodes(editor, { collapsed: false }, { at: collapsedBlockContainer[1] });
            }
        }

        // otherwise, we need to check if we are within the first child of a block container
        // and apply slightly different logic
        if (isBlockContainer(parentNode)) {
            if (isFirstChild) {
                if (indentForward) {
                    Editor.withoutNormalizing(editor, () => {
                        if (isNumberedListItem(node)) {
                            Transforms.wrapNodes(editor, BlockBuilder.numberedList([]), { at: path });
                        }

                        Transforms.wrapNodes(editor, BlockBuilder.indent([]), { at: parentPath });
                    });

                    return;
                } else {
                    Editor.withoutNormalizing(editor, () => {
                        // in case we are in a nested numbered list, we need to unwrap that, otherwise not
                        const [grandParent, grandParentPath] = Editor.parent(editor, parentPath);
                        if (
                            isNumberedListItem(node) &&
                            isNumberedList(grandParent) &&
                            Editor.above(editor, { match: isNumberedList, at: grandParentPath })
                        ) {
                            Transforms.unwrapNodes(editor, { match: isNumberedList, mode: 'lowest', split: true });
                        }

                        Transforms.unwrapNodes(editor, { match: isIndentContainer, mode: 'lowest', split: true });
                    });

                    return;
                }
            } else if (isIndentContainer(node)) {
                if (indentForward) {
                    // already indented, ignore
                    return;
                } else {
                    Editor.withoutNormalizing(editor, () => {
                        Transforms.unwrapNodes(editor, { at: path, split: true });
                        Transforms.unwrapNodes(editor, { at: parentPath, split: true });
                    });
                    return;
                }
            }
        }

        // special case for nested numbered list items when we de-indent them
        if (
            isNumberedList(parentNode) &&
            isNumberedListItem(node) &&
            Editor.above(editor, { match: isIndentContainer, at: parentPath }) &&
            !indentForward
        ) {
            previousContainer && Transforms.setNodes(editor, { type: previousContainer[0].type as any });

            Editor.withoutNormalizing(editor, () => {
                // in case we are in a nested numbered list, we need to unwrap that, otherwise not
                Transforms.unwrapNodes(editor, { match: isNumberedList, mode: 'lowest', split: true });
                Transforms.unwrapNodes(editor, { match: isIndentContainer, mode: 'lowest', split: true });
            });
            return;
        }

        // This allows indenting the first child of a numbered list
        if (indentForward && isFirstChild && isNumberedList(parentNode) && isNumberedListItem(node)) {
            if (parentPath.length === 1) {
                Editor.withoutNormalizing(editor, () => {
                    // first lift up the numbered list item out of the numbered list
                    Transforms.liftNodes(editor);
                    // indent the numbered list item now
                    Transforms.wrapNodes(editor, BlockBuilder.indent([]));
                });
            }

            return;
        }

        if (isIndentContainer(parentNode) && !indentForward && isFirstChild) {
            Transforms.unwrapNodes(editor, { at: parentPath });
            return;
        }
    }

    if (indentForward) {
        Transforms.wrapNodes(editor, BlockBuilder.indent([]));
    } else {
        previousContainer &&
            isVoidGuard(previousContainer) &&
            Transforms.setNodes(editor, { type: previousContainer[0].type as any });

        Transforms.unwrapNodes(editor, { match: isIndentContainer, split: true, mode: 'lowest' });
    }
}

export function applyMarkToRegexMatch(
    editor: Editor,
    [node, path]: NodeEntry<SagaElement | SagaText>,
    format: MarkType,
    match: RegExpMatchArray,
) {
    if (match.index === undefined) return;
    const matchStart: Point = { path, offset: match.index };
    const matchEnd: Point = { path, offset: match.index + match[0].length };
    const replaceEnd: Point = { path, offset: match.index + match[1].length };
    const matchRange: Range = { anchor: matchStart, focus: matchEnd };
    const nodes: SagaText[] = [{ ...node, text: match[1], [format]: true }];
    if (replaceEnd.offset === (node as SagaText).text.length - 1) nodes.push({ text: '' });
    Transforms.insertNodes(editor, nodes, { at: matchRange, select: true });
    Transforms.collapse(editor, { edge: 'end' });
}

export function wrapLink(editor: Editor, selection: Range, url: string) {
    selection = Editor.unhangRange(editor, selection);
    const [linkEntry] = Editor.nodes<SagaElement>(editor, {
        at: selection,
        match: isLink,
    });
    if (linkEntry) {
        Transforms.setNodes(editor, { url }, { at: linkEntry[1] });
        return;
    }

    const isCollapsed = selection && Range.isCollapsed(selection);
    const link = BlockBuilder.link(url, isCollapsed ? [{ text: url }] : []);

    if (isCollapsed) {
        Transforms.insertNodes(editor, link, { at: selection });
    } else {
        Transforms.wrapNodes(editor, link, { at: selection, split: true });
    }
}

export function onArrowLeft(editor: Editor): 'continue' | void {
    const { selection } = editor;

    if (selection && Range.isCollapsed(selection)) {
        const parentBlockEntry = Editor.above(editor, {
            at: selection,
            match: (node) => isSagaElement(node) && !isInlineGuard(node),
            mode: 'lowest',
        });

        if (parentBlockEntry) {
            const [, parentPath] = parentBlockEntry;

            const previous = findPrevious(editor, parentPath);

            if (previous && Editor.isStart(editor, selection.focus, parentPath)) {
                const [previousNode, previousPath] = previous;
                if (isListItem(previousNode)) {
                    const collapsedBlockContainer = findCollapsedBlockContainer(editor, previousPath);

                    if (collapsedBlockContainer) {
                        const endOfCollapsedBlock = Editor.end(editor, [...collapsedBlockContainer[1], 0]);
                        Transforms.select(editor, endOfCollapsedBlock);
                        return;
                    }
                }
            }
        }
    }

    return 'continue';
}

export function onArrowRight(editor: Editor): 'continue' | void {
    const { selection } = editor;

    if (selection && Range.isCollapsed(selection)) {
        const collapsedBlockContainer = findCollapsedBlockContainer(editor, selection);
        if (collapsedBlockContainer) {
            const [, path] = collapsedBlockContainer;

            if (Editor.isEnd(editor, selection.focus, [...path, 0])) {
                Transforms.select(editor, Editor.start(editor, Path.next(path)));
                return;
            }
        }
    }

    return 'continue';
}

function isAtParagraphEndLine(editor: Editor, range: BaseRange, direction: 'up' | 'down') {
    const textNodeEntry = Editor.above(editor, { at: range, match: isParagraph, mode: 'lowest' });

    if (!textNodeEntry) {
        return true;
    }

    const domRange = EditorOperations.Selection.safeToDOMRange(editor, range);
    const domNode = EditorOperations.Selection.safeToDOMNode(editor, textNodeEntry[0]);

    const cursorRects = domRange?.getClientRects();
    const domNodeCoords = domNode?.getBoundingClientRect();
    const cursorCoords = cursorRects?.[cursorRects.length - 1];

    if (domNode && cursorCoords && domNodeCoords) {
        const divHeight = domNode.offsetHeight;
        const lineHeight = parseInt(window.getComputedStyle(domNode, null).getPropertyValue('line-height'));
        const numberOfLines = Math.floor(divHeight / lineHeight);
        const currentLine = Math.floor((cursorCoords.top - domNodeCoords.y) / lineHeight) + 1;
        return currentLine === (direction === 'up' ? 1 : numberOfLines);
    }

    return false;
}

export function onArrowDown(editor: Editor): 'continue' | void {
    const { selection } = editor;
    if (selection && Range.isCollapsed(selection)) {
        const tableCellEntry = Editor.above(editor, {
            at: selection,
            match: isTableCell,
            mode: 'lowest',
        });

        if (tableCellEntry) {
            const lastChild = tableCellEntry[0].children[tableCellEntry[0].children.length - 1];
            const [currentNode] = Editor.above(editor, { at: selection, match: isParagraph, mode: 'lowest' }) ?? [];

            if (currentNode !== lastChild || !isAtParagraphEndLine(editor, selection, 'down')) {
                return 'continue';
            }

            const nextPoint = EditorOperations.Tables.getPointBelowTableCell(editor, tableCellEntry);
            if (nextPoint) {
                Transforms.select(editor, nextPoint);
                return;
            }
        }

        const voidNodeEntry = Editor.above(editor, {
            at: selection,
            voids: true,
            match: isVoidGuard,
            mode: 'lowest',
        });

        if (voidNodeEntry) {
            Transforms.move(editor, { distance: 1, unit: 'line' });

            return;
        }

        const parentPath = Path.parent(selection.focus.path);

        // When the next block is a void block, we want to manually move to the next line to select the whole block
        // (it works better than the default behaviour from slate)
        const nextPoint = Editor.after(editor, parentPath, { distance: 1, unit: 'line', voids: true });

        if (nextPoint && isAtParagraphEndLine(editor, selection, 'down')) {
            const voidBlockNodeEntry = Editor.above(editor, {
                at: nextPoint,
                voids: true,
                match(node) {
                    return isVoidGuard(node) && !isInlineGuard(node);
                },
            });

            if (voidBlockNodeEntry) {
                Transforms.select(editor, nextPoint);
                return;
            }
        }
    }

    return 'continue';
}

export function onArrowUp(editor: Editor): 'continue' | void {
    const { selection } = editor;

    if (selection && Range.isCollapsed(selection)) {
        const tableCellEntry = Editor.above(editor, {
            at: selection,
            match: isTableCell,
            mode: 'lowest',
        });

        if (tableCellEntry) {
            const firstChild = tableCellEntry[0].children[0];
            const [currentNode] = Editor.above(editor, { at: selection, match: isParagraph, mode: 'lowest' }) ?? [];

            if (currentNode !== firstChild || !isAtParagraphEndLine(editor, selection, 'up')) {
                return 'continue';
            }

            const prevPoint = EditorOperations.Tables.getPointAboveTableCell(editor, tableCellEntry);
            if (prevPoint) {
                Transforms.select(editor, prevPoint);
                return;
            }
        }

        const voidNodeEntry = Editor.above(editor, {
            at: selection,
            voids: true,
            match: isVoidGuard,
            mode: 'lowest',
        });

        if (voidNodeEntry) {
            Transforms.move(editor, { distance: 1, unit: 'line', reverse: true });

            return;
        }

        const parentPath = Path.parent(selection.focus.path);

        // When the previous block is a void block, we want to manually move to the previous line to select the whole block
        const previousPoint = Editor.before(editor, parentPath, { unit: 'line', distance: 1, voids: true });

        if (previousPoint && isAtParagraphEndLine(editor, selection, 'up')) {
            const voidNodeEntry = Editor.above(editor, {
                at: previousPoint,
                voids: true,
                match(node) {
                    return isVoidGuard(node) && !isInlineGuard(node);
                },
            });
            if (voidNodeEntry) {
                Transforms.select(editor, previousPoint);
                return;
            }
        }
    }

    return 'continue';
}

export function isInline(editor: Editor) {
    const { isInline: originalIsInline } = editor;
    return (element: SagaElement) => {
        return isInlineGuard(element) || originalIsInline(element);
    };
}

export function isVoid(editor: Editor) {
    const { isVoid } = editor;
    return (element: SagaElement) => {
        return isVoidGuard(element) ? true : isVoid(element);
    };
}
