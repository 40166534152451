import { useRef, useLayoutEffect, MutableRefObject } from 'react';

type RefType<T> = ((instance: T | null) => void) | MutableRefObject<T | null> | null;

export function useCombinedRefs<T>(...refs: (RefType<T> | null | undefined)[]) {
    const targetRef = useRef<T>(null);

    useLayoutEffect(() => {
        refs.forEach((ref) => {
            if (!ref) return;

            if (typeof ref === 'function') {
                ref(targetRef.current);
            } else {
                ref.current = targetRef.current;
            }
        });
    }, [refs]);

    return targetRef;
}
