import { useSpace } from '@/components/SpaceProvider';
import LoadingScreen from '@/components/loading/LoadingScreen';
import { CONTENT_URL } from '@/constants';
import { debugLog } from '@/utils';
import { createPage } from '@/utils/documentUtils';
import { useDuplicatePageAssetsMutation } from '@saga/api';
import { newBlankPage, until } from '@saga/shared';
import { captureException } from '@sentry/react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import ChooseWorkspaceModal from './ChooseWorkspaceModal';
import { useCurrentWorkspace } from './WorkspaceContext';
import { useWorkspaces } from './WorkspacesContext';
import Toast from './toast/Toast';
import { useToastContext } from './toast/ToastContext';

type AssetKeys = {
    sourceSpaceUrlKey: string;
    targetSpaceUrlKey: string;
    assetType: string;
    assetKey: string;
};

export default function DuplicatePublicPage() {
    const { space, isRemoteSynced, provider } = useSpace();
    const { t } = useTranslation();
    const { urlKey } = useCurrentWorkspace();
    const { showToast } = useToastContext();
    const history = useHistory();
    const [duplicateAssets] = useDuplicatePageAssetsMutation();
    const [isWorkspacesModalOpen, setIsWorkspacesModalOpen] = useState(false);
    const { workspaces } = useWorkspaces();

    useEffect(() => {
        const duplicateOnSpace = localStorage.getItem('duplicateOnSpace');
        if (workspaces.length > 1 && !duplicateOnSpace) {
            setIsWorkspacesModalOpen(true);
            return;
        }

        const duplicatePage = async () => {
            try {
                await until(isRemoteSynced, 16000); // Wait for the space to be synced. 12s timeout because of the bigger spaces

                const newPageData = localStorage.getItem('duplicatePageData');

                if (!newPageData) {
                    history.push(`/s/${urlKey}`);
                    return;
                }
                const { title, icon, blocks } = JSON.parse(newPageData);

                const regex = /spaces\/(.*)/;
                let assetKeys: AssetKeys[] = [];

                blocks.map((block: { [key: string]: any }) => {
                    let blockUrl = block.url;
                    if (blockUrl) {
                        blockUrl = new URL(blockUrl);
                        blockUrl.search = '';
                        blockUrl = blockUrl.toString();
                        const match = blockUrl.match(regex);
                        if (match && blockUrl.includes(CONTENT_URL)) {
                            block.url = blockUrl.replace(/spaces\/([^\/]*)/, `spaces/${urlKey}`);
                            const keys = match[1].split('/');
                            assetKeys.push({
                                sourceSpaceUrlKey: keys[0], // workspace url key
                                targetSpaceUrlKey: urlKey,
                                assetType: keys[1], // `files` or `images`
                                assetKey: keys[2], // `foo.jpg` or `bar.png`
                            });
                        }
                    }
                });

                await duplicateAssets({ variables: { input: { assetKeys } } });

                const newPage = newBlankPage({ title, icon, blocks });

                createPage(space, newPage, provider);
                localStorage.removeItem('duplicatePageData');
                localStorage.removeItem('duplicateOnSpace');

                showToast(() => (
                    <Toast>
                        <Toast.Title>🎉</Toast.Title>
                        <Toast.Subtitle>{t('top_menu.share.copying_page_toast_success')}</Toast.Subtitle>
                    </Toast>
                ));

                history.push(`/s/${urlKey}/${newPage.id}`);
            } catch (error) {
                debugLog('Error duplicating page', error);
                captureException(error);
                history.push(`/s/${urlKey}`);
            }
        };
        duplicatePage();
    }, [duplicateAssets, history, isRemoteSynced, showToast, space, t, urlKey, workspaces.length, provider]);

    return (
        <>
            <LoadingScreen />
            {isWorkspacesModalOpen && (
                <ChooseWorkspaceModal
                    title={t('top_menu.share.copying_page_title')}
                    subTitle={t('top_menu.share.copying_page_sub_title')}
                    onClose={() => setIsWorkspacesModalOpen(!setIsWorkspacesModalOpen)}
                    onSelect={(urlKey) => {
                        localStorage.setItem('duplicateOnSpace', urlKey);
                        history.push(`/s/${urlKey}/duplicatePage`);
                    }}
                />
            )}
        </>
    );
}
