import React from 'react';
import { ArrowUpCircle } from 'react-feather';
import { useSetAIPopoverClose } from '../popover/AIPopoverProvider';
import { useSpaceAccess } from '@/hooks/useSpaceAccess';
import { useSetSettings } from '@/components/settings/SettingsProvider';
import { track } from '@/analytics';
import { useTranslation } from 'react-i18next';
import { useAIEnabled } from '@/components/editor/ai/AIPermissionsProvider';

export default function UpgradePlanBanner() {
    const { isOwner } = useSpaceAccess();
    const setSettings = useSetSettings();
    const { setShowUpgradePlanModal } = useAIEnabled();
    const { closeAIPopover } = useSetAIPopoverClose();
    const { t } = useTranslation();

    function onUpgradePlanClick() {
        track('click-on-ai-popover-button', { button: 'Upgrade' });

        if (isOwner) {
            setSettings('plans');
        } else {
            setShowUpgradePlanModal(true);
        }
        closeAIPopover();
    }
    return (
        <div className="text-sm min-h-7 flex items-center justify-between text-saga-gray-500 pl-1.5 pr-2.5 my-2.5 font-normal">
            <div className="flex items-center">
                <ArrowUpCircle size="14" className="ml-0.5 mr-2.5" />
                {t('billing.ai_upgrade_plan_banner.description')}
            </div>
            <span
                className="cursor-pointer text-saga-new-blue hover:text-saga-new-light-blue"
                onClick={onUpgradePlanClick}
            >
                {t('billing.ai_upgrade_plan_banner.primary_action')}
            </span>
        </div>
    );
}
