import React, { useRef, useState } from 'react';
import { PopOver } from '@/components/popover/PopOver';
import { track } from '@/analytics';
import { HelpCircle, Slack, Twitter, Mail, Info, Youtube, BookOpen, MessageCircle, Globe } from 'react-feather';
import Tooltip from '@/components/popover/Tooltip';
import { useUserContext } from '@/components/UserContext';
import { formatDateOptimized } from '@/utils/dateUtils';
import { useHistory } from 'react-router-dom';
import Button from '../styled/Button';
import { openWindow } from '@/utils';
import * as Popover from '@radix-ui/react-popover';
import * as Portal from '@radix-ui/react-portal';
import { useTranslation } from 'react-i18next';

function DiscordSvg({ className }: { className?: string }) {
    return (
        <svg role="img" className={className} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <title>Discord icon</title>
            <path
                fill="currentColor"
                d="M20.222 0c1.406 0 2.54 1.137 2.607 2.475V24l-2.677-2.273-1.47-1.338-1.604-1.398.67 2.205H3.71c-1.402 0-2.54-1.065-2.54-2.476V2.48C1.17 1.142 2.31.003 3.715.003h16.5L20.222 0zm-6.118 5.683h-.03l-.202.2c2.073.6 3.076 1.537 3.076 1.537-1.336-.668-2.54-1.002-3.744-1.137-.87-.135-1.74-.064-2.475 0h-.2c-.47 0-1.47.2-2.81.735-.467.203-.735.336-.735.336s1.002-1.002 3.21-1.537l-.135-.135s-1.672-.064-3.477 1.27c0 0-1.805 3.144-1.805 7.02 0 0 1 1.74 3.743 1.806 0 0 .4-.533.805-1.002-1.54-.468-2.14-1.404-2.14-1.404s.134.066.335.2h.06c.03 0 .044.015.06.03v.006c.016.016.03.03.06.03.33.136.66.27.93.4.466.202 1.065.403 1.8.536.93.135 1.996.2 3.21 0 .6-.135 1.2-.267 1.8-.535.39-.2.87-.4 1.397-.737 0 0-.6.936-2.205 1.404.33.466.795 1 .795 1 2.744-.06 3.81-1.8 3.87-1.726 0-3.87-1.815-7.02-1.815-7.02-1.635-1.214-3.165-1.26-3.435-1.26l.056-.02zm.168 4.413c.703 0 1.27.6 1.27 1.335 0 .74-.57 1.34-1.27 1.34-.7 0-1.27-.6-1.27-1.334.002-.74.573-1.338 1.27-1.338zm-4.543 0c.7 0 1.266.6 1.266 1.335 0 .74-.57 1.34-1.27 1.34-.7 0-1.27-.6-1.27-1.334 0-.74.57-1.338 1.27-1.338z"
            />
        </svg>
    );
}

function HelpMenu(props: { urlKey: string }) {
    const buttonRef = useRef<HTMLButtonElement>(null);
    const [isOpen, setIsOpen] = useState(false);
    const { user } = useUserContext();
    const history = useHistory();
    const { t } = useTranslation();
    const userCreatedDate = user && formatDateOptimized(new Date(user.createdAt).toString(), user.data.language);

    return (
        <div className="relative flex items-center">
            <Popover.Root onOpenChange={setIsOpen}>
                <Popover.Trigger asChild>
                    <button
                        ref={buttonRef}
                        onClick={() => {
                            setIsOpen((isOpen) => !isOpen);
                        }}
                    >
                        <Button.Circle isOpen={isOpen}>
                            <HelpCircle size={20} />
                        </Button.Circle>
                    </button>
                </Popover.Trigger>
                <Portal.Root className="text-sm">
                    <Popover.Content sideOffset={5} align="end" side="top">
                        {user && (
                            <div className="absolute -top-8 inset-x-0 transform -rotate-12 z-60">
                                <Tooltip placement="top" content={t('help.give_feedback_tooltip', { userCreatedDate })}>
                                    <button
                                        onClick={() => {
                                            setIsOpen((isOpen) => !isOpen);
                                        }}
                                    >
                                        👑
                                    </button>
                                </Tooltip>
                            </div>
                        )}
                        <PopOver.PaddedCard>
                            <div className="space-y-1">
                                <PopOver.RoundedButton
                                    onClick={() => {
                                        openWindow('https://sagahq.canny.io', '_blank');
                                    }}
                                >
                                    <MessageCircle className="mr-2" size={16} />
                                    {t('help.give_feedback')}
                                </PopOver.RoundedButton>

                                <PopOver.RoundedButton
                                    onClick={() => {
                                        openWindow('https://saga.so/contact', '_blank');
                                    }}
                                >
                                    <Mail className="mr-2" size={16} />
                                    {t('help.contact_us')}
                                </PopOver.RoundedButton>

                                <PopOver.RoundedButton
                                    onClick={() => {
                                        track('open-slack-community-invite');
                                        openWindow(
                                            'https://join.slack.com/t/sagacommunity/shared_invite/zt-13m3lrrdt-1x6~l6sLuR8CX~4c3fWwHA',
                                            '_blank',
                                        );
                                    }}
                                >
                                    <Slack className="mr-2" size={16} />
                                    {t('help.join_slack')}
                                </PopOver.RoundedButton>
                                <PopOver.RoundedButton
                                    onClick={() => {
                                        track('open-discord-community-invite');
                                        openWindow('https://discord.com/invite/cgz2mUEq7P', '_blank');
                                    }}
                                >
                                    <DiscordSvg className="h-4 mr-2" />
                                    {t('help.join_discord')}
                                </PopOver.RoundedButton>
                                <PopOver.RoundedButton
                                    onClick={() => {
                                        track('open-youtube');
                                        openWindow(
                                            'https://www.youtube.com/channel/UCczWBoQVZjtFUZVgDQ7qFjQ',
                                            '_blank',
                                        );
                                    }}
                                >
                                    <Youtube className="mr-2" size={16} />
                                    {t('help.youtube_channel')}
                                </PopOver.RoundedButton>
                                <PopOver.RoundedButton
                                    onClick={() => {
                                        track('open-twitter');
                                        openWindow('https://twitter.com/saga_hq', '_blank');
                                    }}
                                >
                                    <Twitter className="mr-2" size={16} />
                                    {t('help.tweet_us')}
                                </PopOver.RoundedButton>
                                <PopOver.RoundedButton
                                    onClick={() => {
                                        track('open-website');
                                        openWindow('https://saga.so/?r=0', '_blank');
                                    }}
                                >
                                    <Globe className="mr-2" size={16} />
                                    {t('help.open_website')}
                                </PopOver.RoundedButton>
                                <PopOver.RoundedButton
                                    onClick={() => {
                                        track('open-help-space');
                                        openWindow('https://saga.so/guides', '_blank');
                                    }}
                                >
                                    <Info className="mr-2" size={16} />
                                    {t('help.help_guide')}
                                </PopOver.RoundedButton>
                                <PopOver.RoundedButton
                                    onClick={() => {
                                        track('start-tutorial');
                                        history.push(`/tutorial/${props.urlKey}`);
                                    }}
                                >
                                    <BookOpen className="mr-2" size={16} />
                                    {t('help.start_tutorial')}
                                </PopOver.RoundedButton>
                            </div>
                        </PopOver.PaddedCard>
                    </Popover.Content>
                </Portal.Root>
            </Popover.Root>
        </div>
    );
}

export default HelpMenu;
