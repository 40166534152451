import { BlockPlugin } from '@/../../shared/src/Editor/Plugins';
import { FindInLocationsResult } from '@/../../shared/src/SpaceOperations/findInLocations';
import { track } from '@/analytics';
import { useBlockPlugins } from '@/components/BlockPluginProvider';
import { useDesktopContext } from '@/components/DesktopContext';
import { SearchBar as HeaderSearchBar } from '@/components/navigation/HeaderBar';
import { useSideBySideIndex, useSideBySideState } from '@/components/SideBySide';
import { useCurrentSearchIndex, useSpace } from '@/components/SpaceProvider';
import SearchBar from '@/components/table/TableSearchBar';
import useMobile from '@/hooks/useMobile';
import * as Select from '@radix-ui/react-select';
import { useSpaceAccess } from '@/hooks/useSpaceAccess';
import useWorkspaceSettings, { DEFAULT_SETTINGS } from '@/hooks/useWorkspaceSettings';
import { makeTaskCreateSuggestion } from '@/lib/Suggestions';
import type { Member, TableColumn } from '@/types';
import {
    isTaskBlock,
    SagaLocation,
    Search,
    Sorting,
    SpaceOperations,
    TaskLabel,
    TaskView,
    unsafeRight,
    WeakTask,
} from '@saga/shared';
import classNames from 'classnames';
import * as R from 'ramda';
import React, { useState } from 'react';
import { Archive, CheckCircle, Circle, Trash } from 'react-feather';
import DesktopNavigationButtons from '../DesktopNavigationButtons';
import { useMembers } from '../MembersProvider';
import { useOpenLocation, useOpenTask, locationToUrl } from '../PageNavigationProvider';
import PopoverCreateButton from '../popover/CreateButton';
import { useTasks } from '../tasks/hooks';
import NavigationTable from './NavigationTable';
import { TableContext, useTableContext } from './TableContext';
import SortButton, { fieldsToSortOptions, SortOption } from './TableSortButton';
import { useTranslation } from 'react-i18next';
import TaskAssigneeSelect, { AssigneeFilterMode } from './TaskAssigneeSelect';
import ActionBar from './ActionBar';
import TaskPrioritySelect, { PriorityFilterMode } from './TaskPrioritySelect';
import TaskTableContent from '@/components/tasks/TaskTableContent';
import TaskBoardContent from '@/components/tasks/TaskBoardContent';
import TaskContentModeButton, { TaskContentMode } from '@/components/tasks/TaskContentModeButton';
import { GetTasksMode } from '@saga/shared/src/SpaceOperations/getTasks';
import Button from '@/components/styled/Button';
import { useBatchDeleteTasksPermanently } from '@/hooks/deleteTaskHooks';
import TaskLabelsFilterSelect from './TaskLabelsFilterSelect';
import { useCollectionsSnapshot, useTaskLabels, useTaskViews } from '@/hooks/SpaceHooks';
import { ItemsFilterMode } from './TableMultipleFilterSelect';
import CollectionsFilterSelect from './CollectionsFilterSelect';
import TaskTableViewDropdown from './TaskTableViewDropdown';
import TableSaveViewButton from './TableSaveViewButton';
import { useUserContext } from '../UserContext';
import { isEqual } from 'lodash';
import TasksTableTitle from './TasksTableTitle';
import FavoriteButton from '../styled/FavoriteButton';
import { useFavoriteButton } from '@/hooks/useFavoriteButton';
import { ViewContextButton } from './ViewContextButton';
import { useCurrentWorkspace } from '../WorkspaceContext';
import { useHistory } from 'react-router-dom';
import CreatorsFilterSelect from './CreatorsFilterSelect';
import { useTaskTableColumns } from '@/hooks/TableHooks';
import { useTaskFilters } from '@/hooks/useTaskFilters';
import { createTask } from '@/utils/documentUtils';

const columns: { [key: string]: TableColumn } = {
    title: { asc: 'filters.az', desc: 'filters.za', label: 'filters.title', type: 'title', value: 'title' },
    collection: {
        asc: 'pages.fewest_items_first',
        desc: 'pages.most_items_first',
        label: 'common.collections',
        type: 'collection',
        value: 'collection',
    },
    assignee: {
        asc: 'filters.az',
        desc: 'filters.za',
        label: 'tasks.assignee',
        type: 'assignee',
        value: 'assignee',
    },
    dueDate: {
        asc: 'tasks.sort_asc',
        desc: 'tasks.sort_desc',
        label: 'tasks.due_date',
        type: 'dueDate',
        value: 'dueDate',
    },
    priority: {
        asc: 'tasks.sort_asc',
        desc: 'tasks.sort_desc',
        label: 'tasks.priority',
        type: 'priority',
        value: 'priority',
    },
    references: {
        asc: 'tasks.sort_asc',
        desc: 'tasks.sort_desc',
        label: 'tasks.referenced',
        type: 'references',
        value: 'references',
    },
    labels: {
        asc: 'tasks.sort_asc',
        desc: 'tasks.sort_desc',
        label: 'settings.labels.title',
        type: 'labels',
        value: 'labels',
    },
    createdBy: {
        asc: 'filters.az',
        desc: 'filters.za',
        label: 'pages.headers.creator',
        type: 'createdBy',
        value: 'createdBy',
    },
    createdAt: {
        asc: 'tasks.sort_asc',
        desc: 'tasks.sort_desc',
        label: 'tasks.createdAt',
        type: 'createdAt',
        value: 'createdAt',
    },
    updatedAt: {
        asc: 'tasks.sort_asc',
        desc: 'tasks.sort_desc',
        label: 'tasks.updatedAt',
        type: 'updatedAt',
        value: 'updatedAt',
    },
    completedDate: {
        asc: 'tasks.sort_asc',
        desc: 'tasks.sort_desc',
        label: 'tasks.completedDate',
        type: 'completedDate',
        value: 'completedDate',
    },
};

const sortOptions: SortOption[] = fieldsToSortOptions(
    [
        'title',
        'collection',
        'references',
        'assignee',
        'dueDate',
        'priority',
        'labels',
        'createdAt',
        'updatedAt',
        'completedDate',
        'createdBy',
    ],
    columns,
);

export type IndexedTaskSearchResults = {
    [key: string]: FindInLocationsResult[];
};

function addTitleToTasks(tasks: WeakTask[]): (WeakTask & { title: string })[] {
    return tasks.map((task) => ({
        ...task,
        title: task.title ?? '',
    }));
}

function addCreatedByToTasks(tasks: WeakTask[], members: Member[]): (WeakTask & { createdByName: string })[] {
    return tasks.map((task) => ({
        ...task,
        createdByName: task.createdBy ? members.find((member) => member.id === task.createdBy)?.firstName ?? '' : '',
    }));
}

function addAssigneeToTasks(tasks: WeakTask[], members: Member[]): (WeakTask & { assigneeName: string })[] {
    return tasks.map((task) => {
        const assignee = members.find((member) => member.id === task.assignee);
        return {
            ...task,
            assigneeName: assignee ? assignee.firstName : '',
        };
    });
}

function addReferencesCountToTasks(
    tasks: WeakTask[],
    refferences: IndexedTaskSearchResults,
): (WeakTask & { referencesCount: number })[] {
    return tasks.map((task) => ({
        ...task,
        referencesCount: refferences[task.id] ? refferences[task.id].length : 0,
    }));
}

function addCollectionsCountToTasks(tasks: WeakTask[]): (WeakTask & { collectionsCount: number })[] {
    return tasks.map((task) => ({
        ...task,
        collectionsCount: task.collections ? task.collections.length : 0,
    }));
}

function addPriorityTasks(tasks: WeakTask[]): (WeakTask & { priorityNumber: number })[] {
    const order = { LOW: 1, MEDIUM: 2, HIGH: 3 };
    return tasks.map((task) => ({ ...task, priorityNumber: task.priority ? order[task.priority] : 0 }));
}

const sortDateDesc = (a: string | null | undefined, b: string | null | undefined) => {
    if (a && b) return new Date(b).valueOf() - new Date(a).valueOf();
    if (a) return -1;
    return 1;
};

const sortDateAsc = (a: string | null | undefined, b: string | null | undefined) => {
    if (a && b) return new Date(a).valueOf() - new Date(b).valueOf();
    if (a) return -1;
    return 1;
};

const sorters: {
    [k in keyof typeof columns]: {
        desc(
            tasks: WeakTask[],
            blockPlugins: BlockPlugin[],
            members: Member[],
            refferences: IndexedTaskSearchResults,
            labels: TaskLabel[],
        ): WeakTask[];
        asc(
            tasks: WeakTask[],
            blockPlugins: BlockPlugin[],
            members: Member[],
            refferences: IndexedTaskSearchResults,
            labels: TaskLabel[],
        ): WeakTask[];
    };
} = {
    dueDate: {
        desc(tasks) {
            return R.sortWith([(a, b) => sortDateDesc(a.dueDate, b.dueDate)], tasks);
        },
        asc(tasks) {
            return R.sortWith([(a, b) => sortDateAsc(a.dueDate, b.dueDate)], tasks);
        },
    },
    createdAt: {
        desc(tasks) {
            return R.sortWith([(a, b) => sortDateDesc(a.createdAt, b.createdAt)], tasks);
        },
        asc(tasks) {
            return R.sortWith([(a, b) => sortDateAsc(a.createdAt, b.createdAt)], tasks);
        },
    },
    updatedAt: {
        desc(tasks) {
            return R.sortWith([(a, b) => sortDateDesc(a.updatedAt, b.updatedAt)], tasks);
        },
        asc(tasks) {
            return R.sortWith([(a, b) => sortDateAsc(a.updatedAt, b.updatedAt)], tasks);
        },
    },
    completedDate: {
        desc(tasks) {
            return R.sortWith([(a, b) => sortDateDesc(a.completedDate, b.completedDate)], tasks);
        },
        asc(tasks) {
            return R.sortWith([(a, b) => sortDateAsc(a.completedDate, b.completedDate)], tasks);
        },
    },
    title: {
        desc(tasks) {
            return R.sortWith([R.descend(R.compose(R.toLower, R.prop('title')))], addTitleToTasks(tasks));
        },
        asc(tasks) {
            return R.sortWith([R.ascend(R.compose(R.toLower, R.prop('title')))], addTitleToTasks(tasks));
        },
    },
    createdBy: {
        desc(tasks, _blockPlugins, members) {
            return R.sortWith(
                [R.descend(R.compose(R.toLower, R.prop('createdByName')))],
                addCreatedByToTasks(tasks, members),
            );
        },
        asc(tasks, _blockPlugins, members) {
            return R.sortWith(
                [R.ascend(R.compose(R.toLower, R.prop('createdByName')))],
                addCreatedByToTasks(tasks, members),
            );
        },
    },
    assignee: {
        desc(tasks, _blockPlugins, members) {
            return R.sortWith(
                [R.descend(R.compose(R.toLower, R.prop('assigneeName')))],
                addAssigneeToTasks(tasks, members),
            );
        },
        asc(tasks, _blockPlugins, members) {
            return R.sortWith(
                [
                    (a, b) => {
                        if (a.assigneeName === '' && b.assigneeName === '') {
                            return 0;
                        } else if (a.assigneeName === '') {
                            return 1;
                        } else if (b.assigneeName === '') {
                            return -1;
                        }
                        return a.assigneeName.localeCompare(b.assigneeName);
                    },
                ],
                addAssigneeToTasks(tasks, members),
            );
        },
    },
    collection: {
        desc(tasks) {
            return R.sortWith([R.descend(R.prop('collectionsCount'))], addCollectionsCountToTasks(tasks));
        },
        asc(tasks) {
            return R.sortWith([R.ascend(R.prop('collectionsCount'))], addCollectionsCountToTasks(tasks));
        },
    },
    references: {
        desc(tasks, _blockPlugins, _members, refferences) {
            return R.sortWith([R.descend(R.prop('referencesCount'))], addReferencesCountToTasks(tasks, refferences));
        },
        asc(tasks, _blockPlugins, _members, refferences) {
            return R.sortWith(
                [
                    (a, b) => {
                        if (a.referencesCount === 0 && b.referencesCount === 0) {
                            return 0;
                        } else if (a.referencesCount === 0) {
                            return 1;
                        } else if (b.referencesCount === 0) {
                            return -1;
                        }

                        return a.referencesCount - b.referencesCount;
                    },
                ],
                addReferencesCountToTasks(tasks, refferences),
            );
        },
    },
    priority: {
        desc(tasks) {
            return R.sortWith([R.descend(R.prop('priorityNumber'))], addPriorityTasks(tasks));
        },
        asc(tasks) {
            return R.sortWith(
                [
                    (a, b) => {
                        if (a.priorityNumber === 0 && b.priorityNumber === 0) {
                            return 0;
                        } else if (a.priorityNumber === 0) {
                            return 1;
                        } else if (b.priorityNumber === 0) {
                            return -1;
                        }

                        return a.priorityNumber - b.priorityNumber;
                    },
                ],
                addPriorityTasks(tasks),
            );
        },
    },
    labels: {
        desc(tasks, _blockPlugins, _members, _refferences, labels) {
            return R.sortWith(
                [
                    (a, b) => {
                        const aLabels = a.labels?.map((label) => labels.find((l) => l.id === label)?.title).join(',');
                        const bLabels = b.labels?.map((label) => labels.find((l) => l.id === label)?.title).join(',');
                        return (bLabels ?? '').localeCompare(aLabels ?? '');
                    },
                ],
                tasks,
            );
        },
        asc(tasks, _blockPlugins, _members, _refferences, labels) {
            return R.sortWith(
                [
                    (a, b) => {
                        const aLabels = a.labels?.map((label) => labels.find((l) => l.id === label)?.title).join(',');
                        const bLabels = b.labels?.map((label) => labels.find((l) => l.id === label)?.title).join(',');
                        return (aLabels ?? '').localeCompare(bLabels ?? '');
                    },
                ],
                tasks,
            );
        },
    },
};

function sortTasks(
    tasks: WeakTask[],
    sorting: Sorting | null,
    blockPlugins: BlockPlugin[],
    members: Member[],
    refferences: IndexedTaskSearchResults,
    labels: TaskLabel[],
): WeakTask[] {
    if (sorting) {
        const sorter = sorters[sorting.by];
        if (sorter) {
            const sorterByOrder = sorter[sorting.order];
            return sorterByOrder(tasks, blockPlugins, members, refferences, labels);
        }
    }

    return R.sortWith([(a, b) => new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf()], tasks);
}

function Content({ tasks, contentMode }: { tasks: WeakTask[]; contentMode: TaskContentMode }) {
    const blockPlugins = useBlockPlugins();
    const { sorting } = useTableContext();
    const { members } = useMembers();
    const { space } = useSpace();
    const spaceblocks = useCurrentSearchIndex();
    const labels = useTaskLabels();

    const [orderedKeys, setOrderedKeys] = useTaskTableColumns();

    const results = React.useMemo(() => {
        const results = SpaceOperations.findInLocations(unsafeRight(space.decode()), spaceblocks?.toJSON(), [
            Search.blockQuery({ match: isTaskBlock }),
        ]);

        return results.reduce((acc, value) => {
            if (!isTaskBlock(value.result.block)) return acc;

            const taskId = value.result.block.taskId;

            if (acc[taskId]) {
                return { ...acc, [taskId]: [...acc[taskId], value] };
            }

            return { ...acc, [taskId]: [value] };
        }, {} as IndexedTaskSearchResults);
    }, [space, spaceblocks]);

    const sortedTasks = sortTasks(tasks, sorting, blockPlugins, members, results, labels);

    return contentMode === 'board' ? (
        <TaskBoardContent sortedTasks={sortedTasks} />
    ) : (
        <TaskTableContent
            sortedTasks={sortedTasks}
            results={results}
            columnKeys={orderedKeys}
            onColumnOrderChange={setOrderedKeys}
        />
    );
}

function useSorting(taskView: TaskView) {
    const [settings, updateUserWorkspaceSettings] = useWorkspaceSettings();

    const [currentSorting, setCurrentSorting] = useState<Sorting | null>(
        taskView.isDefaultView ? settings?.allTasksSorting : taskView.allTasksSorting ?? null,
    );

    const setSorting = React.useCallback(
        (allTasksSorting: Sorting | null) => {
            setCurrentSorting(allTasksSorting);

            if (taskView.isDefaultView) {
                updateUserWorkspaceSettings({ allTasksSorting });
            }
            track('sort-table', { source: 'all-tasks-table' });
        },
        [updateUserWorkspaceSettings, taskView.isDefaultView],
    );

    return React.useMemo(() => ({ sorting: currentSorting, setSorting }), [currentSorting, setSorting]);
}

type TaskFilterMode = 'incomplete' | 'completed' | 'all' | 'archived';

function TaskFilterModeSelectItem({
    value,
    children,
    selected,
}: {
    value: TaskFilterMode;
    children: React.ReactNode;
    selected: boolean;
}) {
    return (
        <Select.Item
            value={value}
            className={classNames('focus:outline-none font-semibold cursor-pointer px-2 py-2 select-none', {
                'bg-saga-gray-200 dark:text-zinc-800': selected,
                'hover:bg-saga-gray-200 dark:hover:bg-zinc-600': !selected,
            })}
        >
            <Select.ItemText>{children}</Select.ItemText>
        </Select.Item>
    );
}

function TaskFilterModeSelect({ value, onChange }: { value: TaskFilterMode; onChange(value: TaskFilterMode): void }) {
    const { t } = useTranslation();
    const isMobile = useMobile();
    return (
        <Select.Root value={value} onValueChange={onChange}>
            <Select.Trigger className="whitespace-nowrap focus:outline-none cursor-pointer px-2 h-8 text-xs border font-semibold rounded hover:bg-saga-gray-200 dark:hover:bg-saga-gray-700 border-saga-gray-200 dark:border-saga-gray-700 shadow-button">
                <Select.Value />
            </Select.Trigger>

            <Select.Content className="bg-white dark:bg-zinc-700 border border-saga-gray-200 dark:border-saga-gray-700 rounded shadow-popoverSmall  text-xs">
                <Select.ScrollUpButton />
                <Select.Viewport className="rounded">
                    <TaskFilterModeSelectItem value="incomplete" selected={value === 'incomplete'}>
                        <span className="flex items-center space-x-2">
                            <Circle size={14} />
                            <span>{t(`tasks.${isMobile ? 'incomplete_tasks_short' : 'incomplete_tasks'}`)}</span>
                        </span>
                    </TaskFilterModeSelectItem>

                    <TaskFilterModeSelectItem value="completed" selected={value === 'completed'}>
                        <span className="flex items-center space-x-2">
                            <CheckCircle size={14} />
                            <span>{t(`tasks.${isMobile ? 'completed_tasks_short' : 'completed_tasks'}`)}</span>
                        </span>
                    </TaskFilterModeSelectItem>

                    <TaskFilterModeSelectItem value="all" selected={value === 'all'}>
                        <span className="flex items-center space-x-2">
                            <CheckCircle size={14} />
                            <span>{t(`tasks.${isMobile ? 'all_tasks_short' : 'all_tasks'}`)}</span>
                        </span>
                    </TaskFilterModeSelectItem>
                    <TaskFilterModeSelectItem value="archived" selected={value === 'archived'}>
                        <span className="flex items-center space-x-2">
                            <Archive size={14} />
                            <span>{t(`tasks.archived`)}</span>
                        </span>
                    </TaskFilterModeSelectItem>
                </Select.Viewport>
                <Select.ScrollDownButton />
            </Select.Content>
        </Select.Root>
    );
}

const INCOMPLETE_FILTER: WeakTask['state'][] = ['OPEN', 'IN_PROGRESS'];
const COMPLETED_FILTER: WeakTask['state'][] = ['DONE'];
const ALL_FILTER: WeakTask['state'][] = [];

function getStateFilterForTaskFilterMode(mode: TaskFilterMode) {
    if (mode === 'incomplete') {
        return INCOMPLETE_FILTER;
    }

    if (mode === 'completed') {
        return COMPLETED_FILTER;
    }

    return ALL_FILTER;
}

function getArhiveFilterForTaskFilterMode(mode: TaskFilterMode): GetTasksMode {
    if (mode === 'archived') {
        return 'deleted';
    }

    return 'non-deleted';
}

type Props = {
    selectedView: TaskView;
    views: TaskView[];
    onClose?: () => void;
};

const AllTasksTable = ({ selectedView, views, onClose }: Props) => {
    const [settings, updateUserWorkspaceSettings] = useWorkspaceSettings();

    const initialFilters = React.useMemo(() => {
        return {
            allTasksMode: (selectedView.isDefaultView ? settings?.allTasksMode : selectedView.allTasksMode) ?? 'all',
            allTasksAssignee: (selectedView.isDefaultView
                ? settings?.allTasksAssignee
                : selectedView.allTasksAssignee) ?? {
                kind: 'all',
            },
            allTasksCollections:
                (selectedView.isDefaultView ? settings?.allTasksCollections : selectedView.allTasksCollections) ??
                'all',
            allTasksLabels:
                (selectedView.isDefaultView ? settings?.allTasksLabels : selectedView.allTasksLabels) ?? 'all',
            allTasksPriority:
                (selectedView.isDefaultView ? settings?.allTasksPriority : selectedView.allTasksPriority) ?? 'all',
            allTasksCreators:
                (selectedView.isDefaultView ? settings?.allTasksCreators : selectedView.allTasksCreators) ?? 'all',
            allTasksSorting:
                (selectedView.isDefaultView ? settings?.allTasksSorting : selectedView.allTasksSorting) ?? null,
            search: selectedView.search ?? '',
        };
    }, [selectedView, settings]);

    const [contentMode, setContentMode] = useState<TaskContentMode>(settings?.taskContentMode ?? 'list');

    const [taskFilterMode, setTaskFilterMode] = useState<TaskFilterMode>(initialFilters.allTasksMode);
    const [assigneeFilter, setAssigneeFilter] = useState<AssigneeFilterMode>(initialFilters.allTasksAssignee);
    const [priorityFilter, setPriorityFilter] = useState<PriorityFilterMode>(initialFilters.allTasksPriority);
    const [labelsFilter, setLabelsFilter] = useState<ItemsFilterMode>(initialFilters.allTasksLabels);
    const [collectionsFilter, setCollectionsFilter] = useState<ItemsFilterMode>(initialFilters.allTasksCollections);
    const [creatorsFilter, setCreatorsFilter] = useState<ItemsFilterMode>(initialFilters.allTasksCreators);

    const [search, setSearch] = useState<string>(initialFilters.search);
    const { sorting, setSorting } = useSorting(selectedView);

    const { isDesktop } = useDesktopContext();
    const { canEdit } = useSpaceAccess();
    const { space, provider } = useSpace();
    const { members } = useMembers();
    const sideIndex = useSideBySideIndex();
    const { panes } = useSideBySideState();
    const { t } = useTranslation();
    const deleteTasksPermanently = useBatchDeleteTasksPermanently();
    const goToTask = useOpenTask();
    const labels = useTaskLabels();
    const collections = useCollectionsSnapshot();
    const openLocation = useOpenLocation();
    const { user } = useUserContext();
    const taskFilters = useTaskFilters();

    const { isFavorite, toggleFavorite } = useFavoriteButton(selectedView.id);

    const tasks = useTasks({
        mode: 'deep',
        stateFilter: getStateFilterForTaskFilterMode(taskFilterMode),
        assigneeFilter,
        priorityFilter,
        labelsFilter,
        collectionsFilter,
        creatorsFilter,
        search,
        archivedFilter: contentMode === 'board' ? 'all' : getArhiveFilterForTaskFilterMode(taskFilterMode),
    });

    const currentFilters = React.useMemo(() => {
        return {
            allTasksMode: taskFilterMode,
            allTasksAssignee: assigneeFilter,
            allTasksPriority: priorityFilter,
            allTasksLabels: labelsFilter,
            allTasksCollections: collectionsFilter,
            allTasksCreators: creatorsFilter,
            allTasksSorting: sorting,
            search: search ?? '',
        };
    }, [
        taskFilterMode,
        assigneeFilter,
        priorityFilter,
        labelsFilter,
        collectionsFilter,
        creatorsFilter,
        sorting,
        search,
    ]);

    const taskViewFilters = React.useMemo(() => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { id, createdAt, isDefaultView, title, createdBy, ...rest } = selectedView;
        return rest;
    }, [selectedView]);

    const CreateButton = React.useMemo(
        () => (
            <PopoverCreateButton
                placeholder={t('tasks.new_task_placeholder')}
                onCreate={(event) => {
                    track('create-task', { source: 'task-table' });

                    const task = createTask(space, { title: '', ...taskFilters }, provider);

                    goToTask(task.id, event);
                }}
                onSuggest={makeTaskCreateSuggestion}
            >
                {t('tasks.new_task')}
            </PopoverCreateButton>
        ),
        [t, goToTask, taskFilters],
    );

    return (
        <TableContext.Provider
            value={{
                search: currentFilters.search,
                setSearch,
                sorting: currentFilters.allTasksSorting,
                setSorting,
                columns,
            }}
        >
            <NavigationTable>
                <NavigationTable.FixedPaneContainer variant="page-header">
                    <>
                        {isDesktop && sideIndex === 0 && <DesktopNavigationButtons />}
                        <div className="flex flex-grow justify-center">{panes.length === 1 && <HeaderSearchBar />}</div>

                        <div className="flex z-20">
                            <NavigationTable.SearchButton />
                            {canEdit && !selectedView.isDefaultView && (
                                <>
                                    <FavoriteButton type="task-view" isFavorite={isFavorite} onClick={toggleFavorite} />
                                    <ViewContextButton view={selectedView} type="task-view" />
                                </>
                            )}
                            {onClose && (
                                <NavigationTable.CloseButton
                                    label={t('tasks.close_task', { count: 0 }) as string}
                                    onClick={onClose}
                                />
                            )}
                            {!onClose && <div style={{ height: 36 }} />}
                        </div>
                    </>
                </NavigationTable.FixedPaneContainer>
                <NavigationTable.FixedPaneContainer variant="page-title">
                    <NavigationTable.EditableHeader
                        renderTitle={() => (
                            <TasksTableTitle
                                disabled={!canEdit || selectedView.isDefaultView}
                                title={selectedView.title}
                                onUpdateTitle={(title) => {
                                    SpaceOperations.editTaskView(space, selectedView.id, { title });
                                }}
                                autofocus={false}
                            />
                        )}
                        button={CreateButton}
                    />
                </NavigationTable.FixedPaneContainer>

                <>
                    <ActionBar
                        filter={
                            <div className="flex items-center space-x-2">
                                {views.length > 1 && (
                                    <TaskTableViewDropdown
                                        selectedView={selectedView}
                                        onSelectView={(view, event) => {
                                            openLocation({ type: 'allTasks', viewId: view.id }, event);
                                        }}
                                        taskViews={views}
                                    />
                                )}
                                {contentMode === 'list' && (
                                    <TaskFilterModeSelect
                                        value={taskFilterMode}
                                        onChange={(mode) => {
                                            track('change-task-table-filter-mode', { mode });

                                            setTaskFilterMode(mode);
                                            selectedView.isDefaultView &&
                                                updateUserWorkspaceSettings({ allTasksMode: mode });
                                        }}
                                    />
                                )}
                                {taskFilterMode !== 'archived' && (
                                    <>
                                        <TaskAssigneeSelect
                                            value={assigneeFilter}
                                            onChange={(id) => {
                                                track('change-assignee-table-filter-mode');
                                                setAssigneeFilter(id);
                                                selectedView.isDefaultView &&
                                                    updateUserWorkspaceSettings({ allTasksAssignee: id });
                                            }}
                                            members={members}
                                        />
                                        <TaskPrioritySelect
                                            value={priorityFilter}
                                            onChange={(priority) => {
                                                track('change-priority-table-filter-mode', { priority });
                                                setPriorityFilter(priority);
                                                selectedView.isDefaultView &&
                                                    updateUserWorkspaceSettings({ allTasksPriority: priority });
                                            }}
                                        />
                                        <TaskLabelsFilterSelect
                                            availableLabels={labels}
                                            selectedIds={labelsFilter}
                                            onUpdateSelectedIds={(ids) => {
                                                track('change-labels-table-filter-mode');
                                                setLabelsFilter(ids);
                                                selectedView.isDefaultView &&
                                                    updateUserWorkspaceSettings({ allTasksLabels: ids });
                                            }}
                                        />
                                        <CollectionsFilterSelect
                                            availableCollections={collections}
                                            selectedIds={collectionsFilter}
                                            onUpdateSelectedIds={(ids) => {
                                                track('change-collections-table-filter-mode');
                                                setCollectionsFilter(ids);
                                                selectedView.isDefaultView &&
                                                    updateUserWorkspaceSettings({ allTasksCollections: ids });
                                            }}
                                        />
                                        <CreatorsFilterSelect
                                            availableCreators={members}
                                            selectedIds={creatorsFilter}
                                            onUpdateSelectedIds={(ids) => {
                                                track('change-creators-table-filter-mode');
                                                setCreatorsFilter(ids);
                                                selectedView.isDefaultView &&
                                                    updateUserWorkspaceSettings({ allTasksCreators: ids });
                                            }}
                                        />
                                    </>
                                )}
                                {taskFilterMode === 'archived' && (
                                    <Button
                                        onClick={() => {
                                            if (window.confirm(t('tasks.delete_all_tasks'))) {
                                                deleteTasksPermanently(tasks.map((task) => task.id));
                                            }
                                        }}
                                        variant="alert"
                                        size="action"
                                    >
                                        <div className="text-sm space-x-2 flex items-center">
                                            <Trash size={16} />
                                            <span>{t('pages.delete_all_forever')}</span>
                                        </div>
                                    </Button>
                                )}
                            </div>
                        }
                        search={<SearchBar />}
                        sort={<SortButton sortOptions={sortOptions} popoverTestId="task-sort-popover" />}
                        mode={
                            <TaskContentModeButton
                                value={contentMode}
                                onChange={(value) => {
                                    track('change-task-table-content-mode', { mode: value });
                                    setContentMode(value);
                                    selectedView.isDefaultView &&
                                        updateUserWorkspaceSettings({ taskContentMode: value });

                                    if (value === 'board') {
                                        setTaskFilterMode('all');
                                        selectedView.isDefaultView &&
                                            updateUserWorkspaceSettings({ allTasksMode: 'all' });
                                    }
                                }}
                            />
                        }
                        saveView={
                            !isEqual(taskViewFilters, currentFilters) &&
                            canEdit &&
                            user && (
                                <TableSaveViewButton
                                    showDropdown={!selectedView.isDefaultView}
                                    onCreateNewClick={(name, e) => {
                                        const id = SpaceOperations.addTaskView(space, {
                                            title: name,
                                            createdBy: user.id,
                                            ...currentFilters,
                                        });
                                        openLocation({ type: 'allTasks', viewId: id }, e);
                                        updateUserWorkspaceSettings({
                                            allTasksAssignee: DEFAULT_SETTINGS.allTasksAssignee,
                                            allTasksLabels: DEFAULT_SETTINGS.allTasksLabels,
                                            allTasksCollections: DEFAULT_SETTINGS.allTasksCollections,
                                            allTasksPriority: DEFAULT_SETTINGS.allTasksPriority,
                                            allTasksMode: DEFAULT_SETTINGS.allTasksMode,
                                            allTasksSorting: DEFAULT_SETTINGS.allTasksSorting,
                                            allTasksCreators: DEFAULT_SETTINGS.allTasksCreators,
                                        });
                                        setSearch('');
                                    }}
                                    onUpdateClick={() => {
                                        SpaceOperations.editTaskView(space, selectedView.id, currentFilters);
                                    }}
                                />
                            )
                        }
                    />
                    <Content contentMode={contentMode} tasks={tasks} />
                </>
            </NavigationTable>
        </TableContext.Provider>
    );
};

const AllTasksTableWithLocation = ({
    location,
    onClose,
}: {
    location: SagaLocation.AllTasksLocation;
    onClose?: () => void;
}) => {
    const { urlKey } = useCurrentWorkspace();
    const history = useHistory();
    const taskViews = useTaskViews();
    const taskView = React.useMemo(() => {
        return taskViews.find((view) => view.id === location.viewId) ?? null;
    }, [location.viewId, taskViews]);

    React.useEffect(() => {
        if (!taskView) {
            history.replace(locationToUrl({ type: 'allTasks', viewId: 'all' }, urlKey));
        }
    }, [taskView, urlKey, history]);

    return taskView ? <AllTasksTable selectedView={taskView} views={taskViews} onClose={onClose} /> : null;
};

export default AllTasksTableWithLocation;
