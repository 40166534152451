import React from 'react';
import { Star, Sidebar } from 'react-feather';
import { PopOver } from './PopOver';
import { useTranslation } from 'react-i18next';

export function RemoveFromPinnedButton({ onAfterClick, onClick }: { onAfterClick?(): void; onClick?: () => void }) {
    const { t } = useTranslation();
    return (
        <PopOver.RoundedButton
            aria-label="RemoveFromPinnedButton"
            onClick={() => {
                onClick?.();
                onAfterClick && onAfterClick();
            }}
        >
            <Sidebar className="stroke-gray-dark mr-2 my-auto" size={14} />
            {t('common.remove_from_pinned')}
        </PopOver.RoundedButton>
    );
}

export function RemoveFromFavoriteButton({ onAfterClick, onClick }: { onAfterClick?(): void; onClick?: () => void }) {
    const { t } = useTranslation();
    return (
        <PopOver.RoundedButton
            aria-label="RemoveFromFavoriteButton"
            onClick={() => {
                onClick?.();
                onAfterClick && onAfterClick();
            }}
        >
            <Star className="stroke-gray-dark mr-2 my-auto" size={14} />
            {t('common.remove_from_favorites')}
        </PopOver.RoundedButton>
    );
}

export function AddToFavoritePageButton({ onAfterClick, onClick }: { onAfterClick(): void; onClick?: () => void }) {
    const { t } = useTranslation();
    return (
        <PopOver.RoundedButton
            onClick={() => {
                onClick?.();
                onAfterClick();
            }}
            aria-label={t('common.add_to_favorites') as string}
        >
            <Star className="sroke-gray-dark mr-2 my-auto" size={14} />
            {t('common.add_to_favorites')}
        </PopOver.RoundedButton>
    );
}

export function AddToPinnedPageButton({ onAfterClick, onClick }: { onAfterClick(): void; onClick?: () => void }) {
    const { t } = useTranslation();

    return (
        <PopOver.RoundedButton
            onClick={() => {
                onClick?.();
                onAfterClick();
            }}
            aria-label={t('common.add_to_pinned') as string}
        >
            <Sidebar className="sroke-gray-dark mr-2 my-auto" size={14} />
            {t('common.add_to_pinned')}
        </PopOver.RoundedButton>
    );
}
