import React from 'react';
import SpacePageEditor from '@/components/editor/SpacePageEditor';
import { usePageSnapshot } from '@/hooks/SpaceHooks';
import { useLocation } from 'react-router-dom';
import HeaderBar, { SearchBar } from '@/components/navigation/HeaderBar';
import ReferenceResults from '@/components/editor/ReferenceResults';
import RightPanel from '@/components/rightpanel/RightPanel';
import useInterfaceSettings, { RightMenu } from '@/hooks/useInterfaceSettings';
import { SideBySideSize } from './SideBySidePanes';
import { RealtimeSagaEditor, SagaLocation, SpaceOperations, WeakPage } from '@saga/shared';
import DocumentTitle from '@/components/DocumentTitle';
import useMobile from '@/hooks/useMobile';
import WorkspaceAvatar from './navigation/WorkspaceAvatar';
import MemberAvatar from './MemberAvatar';
import { useCurrentSearchMap, useSpace } from './SpaceProvider';
import * as Y from 'yjs';
import ContextMenuButton from './styled/ContextMenuButton';
import Dropdown from './popover/Dropdown';
import {
    CreateTemplateFromPageButton,
    DeletePageButton,
    DeletePageForeverButton,
    DuplicatePageButton,
    ExportPageButton,
    OpenSideBySideButton,
    RestorePageButton,
    UndoChangesButton,
} from './popover/PageContextMenu';
import { useDesktopContext } from './DesktopContext';
import { useFavoriteButton } from '@/hooks/useFavoriteButton';
import { useSetSearchOpen } from './search/SearchContext';
import useJumpToMemberCursor from '@/hooks/useJumpToMemberCursor';
import Tooltip from './popover/Tooltip';
import Button from './styled/Button';
import { Loader, Search } from 'react-feather';
import FavoriteButton from './styled/FavoriteButton';
import { track } from '@/analytics';
import DesktopNavigationButtons from './DesktopNavigationButtons';
import { useSideBySideState } from '@/components/SideBySide';
import { useDeletePageIfEmpty } from '@/hooks/deletePageHooks';
import { useTranslation } from 'react-i18next';
import { PopOver } from './popover/PopOver';
import { useDocumentYBlocks } from '@/components/RealtimeDocumentProvider';
import EmptyBlocksPlaceholder from '@/components/EmptyBlocksPlaceholder';
import { useWorkspaces } from './WorkspacesContext';
import { usePageAccess } from '@/components/PagesPermissionsBySpaceProvider';
import useDocumentCollaborators from '@/hooks/useDocumentCollaborators';
import { useMountedEditors } from '@/../../shared/src/Editor';
import { useArrowDownSelect } from '@/hooks/useArrowDownSelect';
import { DocumentHistoryButton } from '@/components/DocumentHistoryButton';
import MoveEntityButton from './popover/MoveEntityButton';

function PageContextMenuButton({
    align,
    isButtonSmall,
    page,
    editor,
}: {
    align?: 'left' | 'right' | 'center';
    isButtonSmall?: boolean;
    page: Pick<WeakPage, 'id' | 'title' | 'archivedAt' | 'isTemplate'>;
    editor: RealtimeSagaEditor | null;
}) {
    const buttonRef = React.useRef<HTMLButtonElement>(null);
    const [isOpen, setIsOpen] = React.useState(false);
    const pageId = page.id;
    const isArchived = page.archivedAt != null;
    const onClose = () => setIsOpen(false);
    const { workspaces } = useWorkspaces();

    return (
        <>
            <ContextMenuButton
                ref={buttonRef}
                isOpen={isOpen}
                onClick={() => {
                    setIsOpen((isOpen) => !isOpen);
                    if (isOpen) track('open-context-menu');
                    else track('close-context-menu');
                }}
                isButtonSmall={Boolean(isButtonSmall)}
            />

            <Dropdown
                testId={isOpen ? 'context-menu' : undefined}
                isOpen={isOpen}
                onClose={onClose}
                attachToRef={buttonRef}
                align={align}
            >
                <div>
                    <span className="hidden sm:flex">
                        <OpenSideBySideButton
                            onAfterClick={onClose}
                            location={SagaLocation.pageLocationFromId(pageId)}
                        />
                    </span>
                    {!page.isTemplate && <CreateTemplateFromPageButton pageId={pageId} />}
                    {!isArchived && <DuplicatePageButton pageId={pageId} />}
                    {!isArchived && (
                        <MoveEntityButton
                            disabled={workspaces.length === 1}
                            entityId={pageId}
                            entityType="page"
                            onAfterClick={onClose}
                        />
                    )}
                    {!!editor && <UndoChangesButton onAfterClick={onClose} editor={editor} />}
                    <ExportPageButton page={page} onAfterClick={onClose} />
                    <div className="py-0.5">
                        <PopOver.Divider />
                    </div>
                    <DocumentHistoryButton pageId={pageId} onAfterClose={onClose} />
                    {!isArchived && <DeletePageButton onAfterClick={onClose} pageId={pageId} />}
                    {isArchived && <RestorePageButton onAfterClick={onClose} pageId={pageId} />}
                    {isArchived && <DeletePageForeverButton onAfterClick={onClose} pageId={pageId} />}
                </div>
            </Dropdown>
        </>
    );
}

function useOnPageDeleted(pageId: string, callback: () => void) {
    const { space } = useSpace();
    const cbRef = React.useRef(callback);
    cbRef.current = callback;

    React.useEffect(() => {
        const yPages = space.map.get('pages');

        if (!(yPages instanceof Y.Array)) {
            throw new Error('cannot setup observer for yPages');
        }

        function checkIfPageStillExists() {
            const pageMap = SpaceOperations.getPageMapById(space, pageId);

            if (pageMap == null) {
                cbRef.current();
            }
        }
        yPages.observe(checkIfPageStillExists);

        return () => {
            yPages.unobserve(checkIfPageStillExists);
        };
    }, [pageId, space]);
}

const pageHeaderKeys = ['id', 'title', 'archivedAt', 'collections', 'isTemplate'] as const;

function PagePaneHeader({
    onClose,
    pageLocation,
    onSearchOpened,
    sideBySideSize,
    isFirst,
    editor,
    loading,
}: {
    onClose(event: React.MouseEvent): void;
    pageLocation: SagaLocation.PageLocation;
    onSearchOpened(): void;
    sideBySideSize: SideBySideSize;
    isFirst: boolean;
    editor: RealtimeSagaEditor | null;
    loading?: boolean;
}) {
    const pageId = pageLocation.pageId;
    const page = usePageSnapshot(pageId, pageHeaderKeys);
    const { canEdit } = usePageAccess(pageId);
    const { useMacOsLayout, isDesktop } = useDesktopContext();
    const [{ fixedSidebar }] = useInterfaceSettings();
    const { panes } = useSideBySideState();
    const { t } = useTranslation();

    const { isFavorite, toggleFavorite } = useFavoriteButton(pageId);
    const setSearchOpen = useSetSearchOpen();

    const jumpToCursor = useJumpToMemberCursor();

    const collaborators = useDocumentCollaborators(pageId);

    if (page == null) {
        return null;
    }

    return (
        <div className="flex justify-between items-center w-full py-2 pl-2">
            <div className="min-w-0 flex flex-row justify-center space-x-3">
                {isFirst && useMacOsLayout && !fixedSidebar && (
                    <div className="pl-3 mb-1">
                        <WorkspaceAvatar.SideBySide />
                    </div>
                )}
                {panes.length === 1 && <SearchBar />}
                <div className="pt-0.5">{isFirst && isDesktop && <DesktopNavigationButtons />}</div>

                {page.archivedAt == null && (
                    <HeaderBar.PageCollectionsInput maxWidth={sideBySideSize < 50 ? 100 : 400} pageId={page.id} />
                )}
            </div>
            <div className="flex space-x-1 pr-1.5">
                {canEdit && sideBySideSize >= 50 && (
                    <div className="flex space-x-1 items-center">
                        {collaborators
                            .filter((c) => SagaLocation.areLocationsEqual(c.cursor.location, pageLocation))
                            .map(({ user: { name, color }, cursor }, i) => (
                                <MemberAvatar
                                    key={i}
                                    name={name}
                                    color={color}
                                    onClick={(event) => jumpToCursor(cursor, event)}
                                />
                            ))}
                    </div>
                )}

                {loading && (
                    <div className="animate-fadeIn self-center">
                        <Loader className="animate-spin" />
                    </div>
                )}

                <Tooltip content={t('top_menu.search_shortcut')} placement="bottom">
                    <Button.Plain
                        onClick={() => {
                            track('open-search-bar', { source: 'header-bar-search-button' });
                            setSearchOpen(true);
                            onSearchOpened();
                        }}
                    >
                        <Button.BasePadding>
                            <span className="sr-only">Search</span>
                            <Search size={20} />
                        </Button.BasePadding>
                    </Button.Plain>
                </Tooltip>

                {canEdit && page.archivedAt == null && !page.isTemplate && (
                    <FavoriteButton type="page" isFavorite={isFavorite} onClick={toggleFavorite} />
                )}

                <PageContextMenuButton align="right" page={page} editor={editor} />

                <Tooltip content={t('common.close_entity', { entity: page.title })} placement="bottom">
                    <Button.XButton
                        onClick={onClose}
                        label={t('common.close_entity', { entity: page.title }) as string}
                    />
                </Tooltip>
            </div>
        </div>
    );
}

const pageKeys = [
    'id',
    'title',
    'collections',
    'aliases',
    'icon',
    'archivedAt',
    'isTemplate',
    'settings',
    'isPublicDuplicatable',
    'updatedAt',
    'createdAt',
] as const;

export default function PagePane({
    size,
    isSingle,
    onClose,
    onHighlight,
    paneIndex,
    location: pageLocation,
}: {
    size: SideBySideSize;
    isSingle: boolean;
    paneIndex: number;
    onClose(): void;
    onHighlight(): void;
    location: SagaLocation.PageLocation;
}) {
    const isFirst = paneIndex === 0;
    const onCloseHandler = React.useRef(onClose);
    onCloseHandler.current = onClose;
    const [{ rightMenu: singleRightMenu }, setInterfaceSettings] = useInterfaceSettings();
    const singleSetRightMenu = (rightMenu: RightMenu) => setInterfaceSettings({ rightMenu });
    const [rightMenu, setRightMenu] = React.useState<RightMenu>(null);
    const container = React.useRef<HTMLDivElement>(null);
    const isMobile = useMobile();
    const editors = useMountedEditors();

    const page = usePageSnapshot(pageLocation.pageId, pageKeys);

    const { data: yBlocks, type } = useDocumentYBlocks(pageLocation.pageId);

    const blocksRef = React.useRef(yBlocks);
    const pageRef = React.useRef(page);

    const searchMap = useCurrentSearchMap();
    const cachedBlocks = React.useMemo(
        () => (page ? (searchMap?.get(page.id) as Y.Array<any>) : null),
        [page, searchMap],
    );
    const editorBlocks = React.useMemo(() => yBlocks || cachedBlocks, [yBlocks, cachedBlocks]);

    const { isRemoteSynced } = useSpace();
    const location = useLocation();
    const [focus, setFocus] = React.useState<{ blockId: string; offset?: number } | undefined>(() =>
        pageLocation.blockId ? { blockId: pageLocation.blockId, offset: pageLocation.blockOffset } : undefined,
    );

    const editorRef = React.useRef<RealtimeSagaEditor | null>(null);

    useArrowDownSelect({ editorRef, paneIndex, editors });

    React.useEffect(() => {
        setFocus(
            pageLocation.blockId ? { blockId: pageLocation.blockId, offset: pageLocation.blockOffset } : undefined,
        );
    }, [pageLocation.blockId, pageLocation.blockOffset]);

    React.useEffect(() => {
        blocksRef.current = yBlocks;
    }, [yBlocks]);

    React.useEffect(() => {
        if (isFirst) {
            const blockId = location.hash.replace('#', '');
            if (blockId !== '') {
                React.startTransition(() => {
                    setFocus({ blockId });
                    history.replaceState({}, document.title, window.location.href.split('#')[0]);
                });
            }
        }
    }, [location.hash, isFirst]);

    // in case the page gets deleted, we need to close the pane
    useOnPageDeleted(pageLocation.pageId, () => {
        onCloseHandler.current();
    });

    React.useEffect(() => {
        if (page == null && isRemoteSynced()) {
            onCloseHandler.current();
        }
    }, [page, isRemoteSynced]);

    const deletePageIfEmpty = useDeletePageIfEmpty();

    React.useEffect(() => {
        const currentPage = pageRef.current;
        return () => {
            const blocks = blocksRef.current?.toJSON();

            if (blocks && currentPage?.createdAt === currentPage?.updatedAt) {
                setTimeout(() => {
                    deletePageIfEmpty({ id: pageLocation.pageId, blocks });
                }, 0);
            }
        };
    }, [deletePageIfEmpty, blocksRef, pageRef, pageLocation]);

    if (page == null) {
        return <EmptyBlocksPlaceholder state={type} type="page" />;
    }

    return (
        <ReferenceResults location={pageLocation}>
            <div ref={container} className="h-full flex flex-col relative">
                {isSingle ? (
                    <>
                        <DocumentTitle>{page.title}</DocumentTitle>
                        <HeaderBar
                            editor={editorRef.current}
                            currentPage={page}
                            location={pageLocation}
                            loading={!yBlocks && !!cachedBlocks}
                        />
                    </>
                ) : (
                    <PagePaneHeader
                        editor={editorRef.current}
                        onClose={onClose}
                        pageLocation={pageLocation}
                        onSearchOpened={onHighlight}
                        sideBySideSize={size}
                        isFirst={isFirst}
                        loading={!yBlocks && !!cachedBlocks}
                    />
                )}

                <div className="h-full flex">
                    <div className="hide-scrollbar overflow-x-hidden overflow-y-auto flex-1">
                        {editorBlocks ? (
                            <SpacePageEditor
                                ref={editorRef}
                                yBlocks={editorBlocks}
                                disableInteraction={!yBlocks}
                                location={pageLocation}
                                fullWidth={page.settings.fullWidth ?? false}
                                focus={focus}
                            />
                        ) : (
                            <EmptyBlocksPlaceholder state={type} type="page" />
                        )}
                    </div>
                    <div className="hide-scrollbar overflow-y-auto">
                        <RightPanel
                            rightMenu={isSingle ? singleRightMenu : rightMenu}
                            onRightMenuChange={isSingle ? singleSetRightMenu : setRightMenu}
                            onFocusBlockId={(blockId) => setFocus({ blockId })}
                            location={pageLocation}
                            container={container}
                            floating={size < 50 || isMobile}
                        />
                    </div>
                </div>
            </div>
        </ReferenceResults>
    );
}
