import React from 'react';

export function MembersIllustration() {
    return (
        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_2280_6705)">
                <path
                    d="M58.9488 1.46387L62.4856 5.00063C63.0638 5.57881 63.3836 6.35997 63.3836 7.17804V60.3094C63.3836 62.0071 62.0058 63.3849 60.3082 63.3849H7.18293C6.36486 63.3849 5.58369 63.0589 5.00551 62.4868L1.46875 58.9501"
                    fill="#FBFD71"
                />
                <path
                    d="M58.9488 1.46387L62.4856 5.00063C63.0638 5.57881 63.3836 6.35997 63.3836 7.17804V60.3094C63.3836 62.0071 62.0058 63.3849 60.3082 63.3849H7.18293C6.36486 63.3849 5.58369 63.0589 5.00551 62.4868L1.46875 58.9501"
                    stroke="#101010"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M56.824 59.8974H3.69263C1.99499 59.8974 0.617188 58.5196 0.617188 56.8219V3.69056C0.617188 1.99291 1.99499 0.615112 3.69263 0.615112H56.824C58.5217 0.615112 59.8995 1.99291 59.8995 3.69056V56.8219C59.8995 58.5196 58.5217 59.8974 56.824 59.8974Z"
                    fill="#FBFD71"
                    stroke="#101010"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M63.386 60.3094L59.8984 56.8218"
                    stroke="#101010"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M60.3118 63.3848L56.8242 59.8973"
                    stroke="#101010"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M30.2549 44.2065C22.554 44.2065 16.3047 37.9633 16.3047 30.2562C16.3047 22.5553 22.5478 16.306 30.2549 16.306C37.9558 16.306 44.2051 22.5492 44.2051 30.2562C44.2051 37.9572 37.9558 44.2065 30.2549 44.2065Z"
                    stroke="#101010"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M44.2095 37.2313C48.0617 37.2313 51.1846 34.1085 51.1846 30.2562C51.1846 26.404 48.0617 23.2811 44.2095 23.2811C40.3572 23.2811 37.2344 26.404 37.2344 30.2562C37.2344 34.1085 40.3572 37.2313 44.2095 37.2313Z"
                    fill="white"
                    stroke="#101010"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M30.2564 51.1815C34.1086 51.1815 37.2315 48.0586 37.2315 44.2064C37.2315 40.3541 34.1086 37.2313 30.2564 37.2313C26.4041 37.2313 23.2812 40.3541 23.2812 44.2064C23.2812 48.0586 26.4041 51.1815 30.2564 51.1815Z"
                    fill="white"
                    stroke="#101010"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M16.3071 37.2314C20.1594 37.2314 23.2822 34.1085 23.2822 30.2563C23.2822 26.404 20.1594 23.2812 16.3071 23.2812C12.4549 23.2812 9.33203 26.404 9.33203 30.2563C9.33203 34.1085 12.4549 37.2314 16.3071 37.2314Z"
                    fill="white"
                    stroke="#101010"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M30.2564 23.2811C34.1086 23.2811 37.2315 20.1583 37.2315 16.306C37.2315 12.4538 34.1086 9.33093 30.2564 9.33093C26.4041 9.33093 23.2812 12.4538 23.2812 16.306C23.2812 20.1583 26.4041 23.2811 30.2564 23.2811Z"
                    fill="white"
                    stroke="#101010"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_2280_6705">
                    <rect width="64" height="64" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
