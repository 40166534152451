import WhooopsModal from '@/components/WhooopsModal';
import { wsClient } from '@/graphql';
import React from 'react';

export default function VersionErrorBoundry({ children }: { children: React.ReactNode }) {
    const [showModal, setShowModal] = React.useState(false);

    React.useEffect(() => {
        wsClient.on('closed', (event) => {
            if (event instanceof CloseEvent && event.code === 4403) {
                setShowModal(true);
            }
        });
    }, []);

    return showModal ? (
        <WhooopsModal
            onButtonClick={() => window.location.reload()}
            buttonLabel="Reload Page"
            subheading="A new version of Saga is available"
            heading="Heads up! We've got an update"
        />
    ) : (
        <>{children}</>
    );
}
