import React from 'react';
import SagaLogo from '@/assets/images/saga-logo-white-background.svg';
import Button from '@/components/styled/Button';
import classNames from 'classnames';
import Card from '../styled/Card';
import googleGLogo from '@/assets/images/google_G_Logo.svg';

function Onboarding({ children }: { children: React.ReactNode }) {
    return (
        <Card.WithShadow>
            <Card.Background>
                <div className="z-50 flex flex-col items-center justify-center">{children}</div>
            </Card.Background>
        </Card.WithShadow>
    );
}

Onboarding.MainContainer = function OnboardingMainContainer({ children }: { children: React.ReactNode }) {
    return (
        <div className="relative space-y-5 text-center p-8 md:px-32 md:py-16 lg:py-20 max-w-3xl w-full">{children}</div>
    );
};

Onboarding.SecondaryContainer = function OnboardingSecondaryContainer({ children }: { children: React.ReactNode }) {
    return <div className="py-10 space-y-5 px-4 w-full text-center mx-auto">{children}</div>;
};

Onboarding.SurveyContainer = function OnboardingSurveyContainer({ children }: { children: React.ReactNode }) {
    return <div className="pt-12 pb-8 px-8 md:px-32 md:pt-20 md:pb-16 max-w-3xl text-center mx-auto">{children}</div>;
};

Onboarding.Logo = function OnboardingLogo() {
    return <img className="h-14 mx-auto" alt="Saga Logo" src={SagaLogo} />;
};

Onboarding.Heading = function OnboardingHeading({ children }: { children: React.ReactNode }) {
    return <h1 className="text-2xl md:text-4xl font-bold text-saga-black dark:text-zinc-200">{children}</h1>;
};

Onboarding.Paragraph = function OnboardingParagraph({
    children,
    small,
    semibold,
}: {
    children: React.ReactNode;
    small?: boolean;
    semibold?: boolean;
}) {
    return (
        <h2
            className={classNames('text-saga-gray-500 dark:text-zinc-200', {
                'text-base sm:text-xl': !small,
                'text-lg': small,
                'font-semibold': semibold,
            })}
        >
            {children}
        </h2>
    );
};

Onboarding.Button = function OnboardingButton({
    children,
    ...props
}: React.ButtonHTMLAttributes<HTMLButtonElement> & { children: React.ReactNode }) {
    return (
        <Button type="submit" className={classNames('w-full text-base h-12', props.className)} {...props}>
            {children}
        </Button>
    );
};

Onboarding.GoogleButton = function OnboardingGoogleButton({
    children,
    ...props
}: React.ButtonHTMLAttributes<HTMLButtonElement> & { children: React.ReactNode }) {
    return (
        <Button variant="secondary" className={classNames('w-full text-base h-12', props.className)} {...props}>
            <div className="flex flex-row justify-center items-center">
                <img className="mr-4 h-5" src={googleGLogo} alt="google" />
                {children}
            </div>
        </Button>
    );
};

Onboarding.SecondaryButton = function OnboardingSecondaryButton({
    children,
    ...props
}: React.ButtonHTMLAttributes<HTMLButtonElement> & { children: React.ReactNode }) {
    return (
        <Button className="w-20 text-base" variant="secondary" {...props}>
            {children}
        </Button>
    );
};

Onboarding.InputContainer = function OnboardingInputContainer({ children }: { children: React.ReactNode }) {
    return <div className="flex space-x-2">{children}</div>;
};

Onboarding.Form = function OnboardingForm({
    children,
    onSubmit,
}: { children: React.ReactNode } & React.FormHTMLAttributes<HTMLFormElement>) {
    return (
        <form className="space-y-4 md:w-96 text-xl text-center mx-auto" onSubmit={onSubmit}>
            {children}
        </form>
    );
};

Onboarding.Body = function OnboardingForm({
    children,
}: { children: React.ReactNode } & React.FormHTMLAttributes<HTMLDivElement>) {
    return <div className="space-y-4 md:w-96 text-xl text-center mx-auto">{children}</div>;
};

Onboarding.HalfWidthContainer = function OnboardingHalfWidthContainer({ children }: { children: React.ReactNode }) {
    return <div className="space-y-5 sm:w-96 text-center mx-auto w-full">{children}</div>;
};

Onboarding.Container = function OnboardingContainer({ children }: { children: React.ReactNode }) {
    return (
        <div className="h-full mx-auto space-y-4 text-center flex flex-col items-center justify-between">
            {children}
        </div>
    );
};

Onboarding.HorizontalContainer = function OnboardingHorizontalContainer({ children }: { children: React.ReactNode }) {
    return <div className="mx-auto flex flex-col sm:flex-row sm:space-x-8 text-left lg:px-8">{children}</div>;
};

Onboarding.VerticalContainer = function OnboardingVerticalContainer({ children }: { children: React.ReactNode }) {
    return <div className="mx-auto flex flex-col text-left lg:px-8">{children}</div>;
};

Onboarding.ParagraphContainer = function ParagraphContainer({ children }: { children: React.ReactNode }) {
    return <div className="pt-4">{children}</div>;
};

Onboarding.ButtonContainer = function OnboardingButtonContainer({ children }: { children: React.ReactNode }) {
    return <div className="mx-auto flex flex-row space-x-4 justify-items-center py-2 sm:p-4 w-full">{children}</div>;
};

Onboarding.Dots = function OnboardingDots({
    index,
    dots,
    onGoToIndex,
}: {
    index: number;
    dots: number;
    onGoToIndex(index: number): void;
}) {
    return (
        <div className="fixed bottom-0 inset-x-0 flex w-full p-4">
            <div className="flex flex-row mx-auto space-x-4">
                {[...Array(dots)].map((e, i) => {
                    return (
                        <button
                            key={i}
                            onClick={() => onGoToIndex(i)}
                            className={classNames('focus:outline-none w-3.5 h-3.5 rounded-full', {
                                'bg-saga-gray-300': i < index,
                                'bg-saga-black dark:bg-zinc-500': i === index,
                                'bg-saga-gray-200': i > index,
                            })}
                        />
                    );
                })}
            </div>
        </div>
    );
};

type OnboardingColor = 'green' | 'yellow' | 'purple' | 'orange' | 'blue' | 'gray' | 'lightblue';
Onboarding.ImageContainer = function OnboardingImageContainer({
    children,
    color,
}: {
    children: React.ReactNode;
    color: OnboardingColor;
}) {
    return (
        <div
            className={classNames('flex flex-row justify-center w-full h-full px-4 py-6 ', {
                'bg-saga-new-green-light': color === 'green',
                'bg-saga-new-yellow-light': color === 'yellow',
                'bg-saga-new-purple-light': color === 'purple',
                'bg-saga-new-orange-light': color === 'orange',
                'bg-saga-blue': color === 'blue',
                'bg-saga-gray-200': color === 'gray',
                'bg-saga-selection-blue': color === 'lightblue',
            })}
        >
            {children}
        </div>
    );
};

Onboarding.Image = function OnboardingImage({ ...props }: React.ImgHTMLAttributes<HTMLImageElement>) {
    return <img className="object-contain min-h-0 h-full" {...props} />;
};

Onboarding.Frame = function OnboardingFrame({
    imageSrc,
    children,
    color,
    testId,
}: {
    imageSrc: string;
    children: React.ReactNode;
    color: OnboardingColor;
    testId: string;
}) {
    return (
        <div data-testid={testId} className="mx-auto p-4 w-full h-full [max-height:600px] md:[max-height:700px]">
            <div className="mx-auto flex flex-col h-full justify-center w-full md:max-w-3xl shadow-popupSmall bg-white dark:bg-zinc-700 rounded overflow-hidden relative">
                <div className="h-1/2 md:h-3/5">
                    <Onboarding.ImageContainer color={color}>
                        <Onboarding.Image src={imageSrc} />
                    </Onboarding.ImageContainer>
                </div>
                <div className="h-1/2 md:h-2/5 pt-8 pb-4 px-4 md:px-16">{children}</div>
            </div>
        </div>
    );
};

export default Onboarding;
