import { SafeSpace, WeakPage, WeakTask } from '../types';
import { isPageLocation, isTaskLocation, BlocksLocation, PageLocation, TaskLocation } from '../SagaLocation';
import getPageById from '../SpaceOperations/getPageById';
import findTask from '../SpaceOperations/findTask';

type Result<L extends BlocksLocation> = L extends PageLocation ? WeakPage : L extends TaskLocation ? WeakTask : never;

function findLocation<L extends BlocksLocation>(space: SafeSpace, location: L): Result<L> | null {
    if (isPageLocation(location)) {
        return getPageById(space, location.pageId) as Result<L> | null;
    }

    if (isTaskLocation(location)) {
        return findTask(space, location.taskId) as Result<L> | null;
    }

    return null;
}

export default findLocation;
