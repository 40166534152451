import * as Select from '@radix-ui/react-select';
import * as api from '@saga/api';
import classNames from 'classnames';
import React from 'react';
import { ChevronDown } from 'react-feather';
import { Trans, useTranslation } from 'react-i18next';
import Tooltip from './popover/Tooltip';
import Button from './styled/Button';

function RoleTooltipContent({ role }: { role?: api.Role }) {
    const EmphasizedText: React.FC = ({ children }) => <span className="font-bold text-white">{children}</span>;

    const Container: React.FC = ({ children }) => <span className="text-saga-gray-200">{children}</span>;

    switch (role) {
        case api.Role.Viewer:
            return (
                <Container>
                    <Trans i18nKey={'settings.members.role_viewer_tooltip'} components={{ 1: <EmphasizedText /> }} />
                </Container>
            );
        case api.Role.Writer:
            return (
                <Container>
                    <Trans i18nKey={'settings.members.role_editor_tooltip'} components={{ 1: <EmphasizedText /> }} />
                </Container>
            );
        case api.Role.Owner:
            return (
                <Container>
                    <Trans i18nKey={'settings.members.role_admin_tooltip'} components={{ 1: <EmphasizedText /> }} />
                </Container>
            );
        case api.Role.Member:
            return (
                <Container>
                    <Trans i18nKey={'settings.members.role_member_tooltip'} components={{ 1: <EmphasizedText /> }} />
                </Container>
            );

        default:
            return (
                <Container>
                    <Trans i18nKey={'settings.members.role_blocked_tooltip'} components={{ 1: <EmphasizedText /> }} />
                </Container>
            );
    }
}

function RoleSelectItem({
    value,
    disabled = false,
    children,
}: {
    value: string;
    disabled?: boolean;
    children: React.ReactNode;
}) {
    return (
        <Select.Item
            disabled={disabled}
            className={classNames(
                'cursor-pointer focus:outline-none focus:bg-saga-gray-200 dark:focus:bg-saga-gray-700 rounded',
                {
                    'text-saga-gray-500 cursor-default': disabled,
                },
            )}
            value={value}
        >
            <Select.ItemText>{children}</Select.ItemText>
        </Select.Item>
    );
}

RoleSelectItem.Label = function RoleSelectItemLabel({
    text,
    tooltip,
    isPaidFeature,
}: {
    text: string;
    tooltip: React.ReactNode;
    isPaidFeature?: boolean;
    role?: api.Role;
}) {
    const { t } = useTranslation();

    return (
        <Tooltip className="ml-6" content={tooltip} placement="right">
            <span className={'flex items-center space-x-2 py-1.5 pl-2 pr-1.5'}>
                <span className="whitespace-nowrap">{text}</span>
                {isPaidFeature && <Button.Pill disabled>{t('settings.members.paid_feature')}</Button.Pill>}
            </span>
        </Tooltip>
    );
};

export function RoleSelect({
    canInviteAdmins,
    role,
    onChange,
    disabled,
    isPaidFeature,
}: {
    canInviteAdmins: boolean;
    role: api.Role;
    onChange(role: api.Role): void;
    disabled?: boolean;
    isPaidFeature: boolean;
}) {
    const { t } = useTranslation();
    return (
        <Select.Root value={role} onValueChange={onChange}>
            <Select.Trigger
                disabled={disabled}
                className={classNames(
                    'flex justify-between rounded items-center font-normal text-saga-gray-500 focus:outline-none focus-visible:outline-dotted focus-visible:outline-saga-gray-500',
                    {
                        'hover:bg-saga-gray-250 dark:hover:bg-saga-gray-700 hover:text-saga-black dark:hover:text-white':
                            role !== api.Role.Owner && !disabled,
                    },
                )}
            >
                <Select.Value />
                {!disabled && (
                    <Select.Icon>
                        <ChevronDown size={14} className="mr-2" />
                    </Select.Icon>
                )}
            </Select.Trigger>

            <Select.Content>
                <Select.ScrollUpButton />
                <Select.Viewport className="bg-white dark:bg-saga-gray-900 shadow-popupSmall rounded-md border dark:border-saga-gray-800 px-2 py-1.5">
                    <RoleSelectItem value={api.Role.Owner} disabled={!canInviteAdmins}>
                        <RoleSelectItem.Label
                            tooltip={<RoleTooltipContent role={api.Role.Owner} />}
                            text={t('settings.members.role_admin')}
                            role={role}
                        />
                    </RoleSelectItem>
                    <RoleSelectItem value={api.Role.Member}>
                        <RoleSelectItem.Label
                            tooltip={<RoleTooltipContent role={api.Role.Member} />}
                            text={t('settings.members.role_member')}
                            isPaidFeature={isPaidFeature}
                        />
                    </RoleSelectItem>
                    <RoleSelectItem value={api.Role.Writer}>
                        <RoleSelectItem.Label
                            tooltip={<RoleTooltipContent role={api.Role.Writer} />}
                            text={t('settings.members.role_editor')}
                            isPaidFeature={isPaidFeature}
                        />
                    </RoleSelectItem>
                    <RoleSelectItem value={api.Role.Viewer}>
                        <RoleSelectItem.Label
                            tooltip={<RoleTooltipContent role={api.Role.Viewer} />}
                            text={t('settings.members.role_viewer')}
                            isPaidFeature={isPaidFeature}
                        />
                    </RoleSelectItem>
                    <Select.Separator />
                </Select.Viewport>
                <Select.ScrollDownButton />
            </Select.Content>
        </Select.Root>
    );
}
