import { useSpace, useCurrentSearchIndex } from '@/components/SpaceProvider';
import { SagaLocation, unsafeRight } from '@saga/shared';
import { FindInLocationsResult } from '@saga/shared/src/SpaceOperations/findInLocations';
import React from 'react';

type OnResultsCallback = (results: FindInLocationsResult[]) => void;

type FindWithBlocksQuery = { type: 'blocks'; location: SagaLocation.BlocksLocation };
type FindWithStringQuery = { type: 'text'; text: string };
export type FindInLocationsSearchInput = FindWithBlocksQuery | FindWithStringQuery;

const useFindInLocationsWorker = () => {
    const [worker, setWorker] = React.useState<Worker>();
    const { space } = useSpace();
    const spaceblocks = useCurrentSearchIndex();
    const callBack = React.useRef<OnResultsCallback>();

    const search = React.useCallback(
        (data: FindInLocationsSearchInput, onComplete: OnResultsCallback) => {
            callBack.current = onComplete;

            if (worker && space) {
                worker.postMessage({
                    space: unsafeRight(space.decode()),
                    spaceblocks: spaceblocks?.toJSON(),
                    data,
                });
            }
        },
        [space, spaceblocks, worker],
    );

    React.useEffect(() => {
        const findInLocationsWorker = new Worker(new URL('../workers/findInLocationsWorker.ts', import.meta.url), {
            type: 'module',
        });

        findInLocationsWorker.onmessage = function (event: MessageEvent<FindInLocationsResult[]>) {
            callBack.current?.(event.data);
        };

        setWorker(findInLocationsWorker);

        return () => {
            findInLocationsWorker.terminate();
        };
    }, []);

    return React.useMemo(() => ({ search }), [search]);
};

export default useFindInLocationsWorker;
