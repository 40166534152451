import { SagaEditor, SlateYjs } from '@saga/shared';
import { useUserContext } from '@/components/UserContext';
import { useAwareness } from '../components/RealtimeProvider';

export type Collaborator = {
    cursor: SlateYjs.Cursor;
    user: SlateYjs.AwarenessStateData['user'];
};

export default function useCollaborators(): Collaborator[] {
    const { user: loggedUser } = useUserContext();
    const awareness = useAwareness();
    const awarenessStates = SagaEditor.useAwarenessStates(awareness);

    const collaborators = awarenessStates
        .filter(([, { user }]) => user.userId !== loggedUser?.id)
        .map(([, state]) => state)
        .map((state) => state.cursors?.map((cursor) => ({ cursor, user: state.user })) ?? [])
        .flat();

    return collaborators;
}
