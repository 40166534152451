import React from 'react';
import UpdatedTitleNotice from './UpdatedTitleNotice';
import { ArchivedPageNotice } from './ArchivedNotice';
import {
    EditorOperations,
    SagaLocation,
    SpaceOperations,
    Title,
    WeakPage,
    RealtimeSagaEditor,
    SagaEditor,
    BaseSagaEditor,
    SafeSpace,
    AnyBlockItem,
} from '@saga/shared';
import * as Y from 'yjs';
import { useSpace } from '@/components/SpaceProvider';
import { useSpaceAccess } from '@/hooks/useSpaceAccess';
import * as api from '@saga/api';
import { useMembers } from '../MembersProvider';
import { useCollectionsSnapshot, useShallowTasks } from '@/hooks/SpaceHooks';
import classNames from 'classnames';
import useMobile from '@/hooks/useMobile';
import useInterfaceSettings from '@/hooks/useInterfaceSettings';
import { useBlockPlugins } from '@/components/BlockPluginProvider';
import { useRecents } from '../RecentsContext';
import { searchIntegrations } from '../search/useSearchQuery';
import { useIsOnline } from '@/components/BrowserContextProvider';
import { debounce } from 'lodash';
import { useAutolinkIndex } from '../AutolinkIndex';
import {
    BlocksPastedSuggestions,
    InsertIntegrationSuggestionsComponent,
    Suggestions,
    usePagesForSuggestion,
} from './Suggestions';
import { HoveringToolbarContainer } from './popover/HoveringToolbar';
import { TurnIntoSuggestions } from '../TurnIntoSuggestions';
import SpaceEditable from './SpaceEditable';
import { EditorAwareness } from './EditorAwareness';
import { track } from '@/analytics';
import { handleBlockDrop } from '@/lib/handleBlockDrop';
import { useUploadImage } from '@/lib/Image';
import { useUploadFile } from '@/lib/File';
import BottomDropArea from './BottomDropArea';
import DragAndDrop from './DragAndDrop';
import { Editor, Transforms } from 'slate';
import { useSlateStatic } from 'slate-react';
import AIPopoverProvider from '@/components/editor/popover/AIPopoverProvider';
import useOnTitleChanged from '@/hooks/useOnTitleChanged';
import { useDocumentAwareness, usePerformBlockChangeWithEditor } from '@/components/RealtimeDocumentProvider';
import { BlocksLocation } from '../../../../shared/src/SagaLocation';
import { usePageAccess } from '@/components/PagesPermissionsBySpaceProvider';
import { useCurrentWorkspace } from '@/components/WorkspaceContext';

export type PageForEditor = Pick<
    WeakPage,
    'id' | 'title' | 'aliases' | 'icon' | 'archivedAt' | 'isTemplate' | 'settings'
>;

declare global {
    interface Window {
        editor?: BaseSagaEditor;
        printEditor?: () => void;
        Transforms?: typeof Transforms;
    }
}

function WindowBinding() {
    const editor = useSlateStatic();
    window.editor = editor;
    window.printEditor = () => {
        console.log(EditorOperations.SagaElement.prettify(editor));
    };
    window.Transforms = Transforms;
    return null;
}

type Props = {
    location: SagaLocation.PageLocation;
    yBlocks: Y.Array<any>;
    focus?: { blockId: string; offset?: number };
    zIndex?: number;
    fullWidth: boolean;
    disableInteraction?: boolean;
};

const SpacePageEditor = React.forwardRef<any, Props>(
    ({ location, yBlocks, focus, zIndex, fullWidth, disableInteraction = false }, ref) => {
        const isOnline = useIsOnline();
        const blockPlugins = useBlockPlugins();
        const editorRef = React.useRef<RealtimeSagaEditor | null>(null);
        const { space, provider } = useSpace();
        const { permissions } = useSpaceAccess();
        const { canEdit } = usePageAccess(location.pageId);
        const isMobile = useMobile();
        const [{ spellCheck }] = useInterfaceSettings();
        const awareness = useDocumentAwareness(location.pageId);
        const { recentLocations } = useRecents();
        const { members } = useMembers();
        const { data } = api.useLinearTeamsQuery();
        const linearTeams = React.useMemo(() => data?.linearTeams ?? [], [data]);
        const tasks = useShallowTasks();
        const pages = usePagesForSuggestion();
        const { id: spaceId } = useCurrentWorkspace();

        const collections = useCollectionsSnapshot();
        const onUploadImage = useUploadImage(location);
        const onUploadFile = useUploadFile(location);

        const changeBlocksWithEditor = usePerformBlockChangeWithEditor();

        const onChangeBlocks = React.useCallback(
            (space: SafeSpace, location: BlocksLocation, change: (editor: Editor, blocks: AnyBlockItem[]) => void) => {
                changeBlocksWithEditor(space, location, (editor, blocks) => {
                    if (!blocks) return;
                    change(editor, blocks.toJSON());
                });
            },
            [changeBlocksWithEditor],
        );

        const { index } = useAutolinkIndex();

        const debounceUpdateTitle = React.useMemo(
            () =>
                debounce((pageId: string, title: string) => {
                    const page = SpaceOperations.getPageById(space, pageId, ['title', 'aliases', 'isTemplate']);

                    if (page) {
                        if (page.title !== title) {
                            SpaceOperations.updatePartialPage(space, pageId, { title });
                        }
                        if (!page.isTemplate) {
                            index.add({
                                id: `page:${pageId}`,
                                names: [title, ...page.aliases],
                                type: 'page',
                                location: SagaLocation.pageLocationFromId(pageId),
                            });
                            index.callObservers();
                        }
                    }
                }, 500),
            [space, index],
        );

        useOnTitleChanged(yBlocks, (title: Title) => {
            debounceUpdateTitle(location.pageId, EditorOperations.SagaElement.toString(title, blockPlugins));
        });

        React.useImperativeHandle(ref, () => editorRef.current);

        if (!awareness) return null;

        return (
            <SagaEditor.RealtimeEditor
                fullWidth={fullWidth}
                editorRef={editorRef}
                location={location}
                yBlocks={yBlocks}
                canEdit={canEdit && isOnline}
                zIndex={zIndex}
                blockPlugins={blockPlugins}
                awareness={awareness}
                allowCollapsingListItems={true}
                disableUserInteraction={disableInteraction}
            >
                <WindowBinding />
                <DragAndDrop
                    enabled={!isMobile}
                    onDrop={({ item, editor, path }) => {
                        if ('type' in item) {
                            track('block-drag-and-drop', { type: item.type });
                        } else {
                            track('block-drag-and-drop', { type: 'image' });
                        }

                        handleBlockDrop({
                            editor,
                            item,
                            location,
                            path,
                            space,
                            onUploadImage,
                            onUploadFile,
                            onChangeBlocks,
                            fileUploadLimit: permissions.fileUpload.limitFile,
                        });
                    }}
                >
                    <SagaEditor.Container
                        focus={focus}
                        yBlocks={yBlocks}
                        className={classNames('relative mx-auto flex flex-col w-full sm:pl-8 sm:pr-6', {
                            'px-1': isMobile,
                        })}
                    >
                        <InsertIntegrationSuggestionsComponent>
                            <AIPopoverProvider>
                                <Suggestions
                                    location={location}
                                    tasks={tasks}
                                    recentLocations={recentLocations}
                                    members={members}
                                    collections={collections}
                                    linearTeams={linearTeams}
                                    onSearch={searchIntegrations}
                                    space={space}
                                    provider={provider}
                                    pages={pages}
                                    spaceId={spaceId}
                                >
                                    <BlocksPastedSuggestions>
                                        <TurnIntoSuggestions>
                                            <UpdatedTitleNotice location={location} blockPlugins={blockPlugins} />
                                            <ArchivedPageNotice id={location.pageId} />
                                            <EditorAwareness>
                                                <div
                                                    className={classNames({
                                                        'pointer-events-none': disableInteraction,
                                                    })}
                                                >
                                                    <SpaceEditable spellCheck={spellCheck} />
                                                </div>
                                            </EditorAwareness>
                                            <BottomDropArea className="w-full flex-grow cursor-text">
                                                {(overArea) =>
                                                    overArea != null && (
                                                        <div className="mx-auto max-w-700 bg-saga-blue-light opacity-25 h-1 rounded-sm"></div>
                                                    )
                                                }
                                            </BottomDropArea>
                                            <HoveringToolbarContainer canSearch />
                                        </TurnIntoSuggestions>
                                    </BlocksPastedSuggestions>
                                </Suggestions>
                            </AIPopoverProvider>
                        </InsertIntegrationSuggestionsComponent>
                    </SagaEditor.Container>
                </DragAndDrop>
            </SagaEditor.RealtimeEditor>
        );
    },
);

SpacePageEditor.displayName = 'SpacePageEditor';

export default SpacePageEditor;
