import { Collection } from '../types';
import { ymapFromRecord } from '../yjs-utils';
import findCollectionById from './findCollectionById';
import transactInSpace from './transactInSpace';
import { SafeSpace } from '../types';

export default function setCollectionSorting(space: SafeSpace, id: string, sorting: Collection['sorting']) {
    const collection = findCollectionById(space, id);
    transactInSpace(space, () => {
        collection.set('sorting', sorting ? ymapFromRecord(sorting) : null);
    });
}
