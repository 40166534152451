import * as Y from 'yjs';
import * as E from 'fp-ts/Either';
import * as A from 'fp-ts/Array';
import { pipe } from 'fp-ts/lib/function';
import Queue from '../utils/Queue';

export function assertYArray(value: unknown): asserts value is Y.Array<unknown> {
    if (!(value instanceof Y.Array)) {
        throw new Error('Expected Y.Array');
    }
}

export function assertYMap(value: unknown): asserts value is Y.Map<unknown> {
    if (!(value instanceof Y.Map)) {
        throw new Error('Expected Y.Map');
    }
}

export function ensureYArray(value: unknown): Y.Array<unknown> {
    assertYArray(value);
    return value;
}

export const ysYArrayOfRecord = (yarray: Y.Array<any>): yarray is Y.Array<Record<string, unknown>> => {
    let condition = false;

    yarray.forEach((item) => {
        if (item instanceof Y.Map) {
            condition = true;
        }
    });

    return condition;
};

/**
 * Creates a Y.Map and fills it with the fields of the given record.
 *
 * @param r The record that shall be turned into a Y.Map
 * @param initial The initial Y.Map, useful for testing.
 * @returns The final Y.Map instance with all the record fields.
 */
export const ymapFromRecord = <T extends Record<string, unknown>>(
    record: T,
    initial?: Y.Map<unknown>,
    transform?: (key: string, value: unknown, record: unknown) => unknown | null,
): Y.Map<unknown> => {
    const map = initial ?? new Y.Map();

    Object.entries(record).forEach(([key, value]) => {
        const transformedValue = transform ? transform(key, value, record) : null;
        if (transformedValue) {
            map.set(key, transformedValue);
            return;
        }

        if (!Array.isArray(value) && isObject(value)) {
            const submap = ymapFromRecord(value as Record<string, unknown>, new Y.Map(), transform);
            map.set(key, submap);
        } else if (Array.isArray(value)) {
            const array = yarrayFromArray(value, new Y.Array(), transform);

            map.set(key, array);
            // we allow null values, but not undefined, because this can cause problems in firebase
        } else {
            map.set(key, value);
        }
    });

    return map as any;
};

export const yarrayFromArray = <A>(
    array: Array<A>,
    initial?: Y.Array<A extends object ? Y.Map<unknown> : A>,
    transformMapValue?: (key: string, value: unknown, record: unknown) => unknown | null,
): Y.Array<A extends object ? Y.Map<unknown> : A> => {
    const yarray = initial ?? new Y.Array();

    if (array.some(isObject)) {
        //@ts-expect-error
        yarray.push(array.filter(isObject).map((a) => ymapFromRecord(a, new Y.Map(), transformMapValue)));
    } else {
        //@ts-expect-error
        yarray.push(array);
    }

    return yarray;
};

export const getInsertedItems = (
    changes: Y.YArrayEvent<unknown>['changes'] | Y.YMapEvent<unknown>['changes'],
): unknown =>
    changes.delta
        .filter((a) => a.insert)
        .map(({ insert }) => insert)
        .flat();

export function observePreservedOnNestedType(
    map: Y.Map<any>,
    key: string,
    observe: (event: Y.YMapEvent<any>, transaction: Y.Transaction) => void,
) {
    const abstractType = map.get(key);

    if (!(abstractType instanceof Y.AbstractType)) {
        throw new Error(`The value for field ${key} is not an instance of Y.AbstractType`);
    }

    abstractType.observe(observe);

    map.observe((e, t) => {
        const changed = e.changes.keys.get(key);
        if (
            changed &&
            changed.action === 'update' &&
            changed.oldValue === abstractType &&
            changed.oldValue instanceof Y.AbstractType
        ) {
            abstractType.unobserve(observe);
            const newValue = map.get(key);
            if (newValue instanceof Y.AbstractType) {
                newValue.observe(observe);
            }
        }
    });
}

function isObject(value: unknown) {
    const type = typeof value;
    return value != null && (type === 'object' || type === 'function');
}

export const findYArrayIndexOrThrow = <A>(yarray: Y.Array<A>, predicate: (a: A) => boolean): number => {
    let index = -1;

    yarray.forEach((a: A, i) => {
        if (predicate(a)) {
            index = i;
        }
    });

    if (index === -1) {
        throw new Error(`Item not found`);
    }

    return index;
};

export const findYArrayIndex = <A>(yarray: Y.Array<A>, predicate: (a: A) => boolean): number => {
    let index = -1;

    yarray.forEach((a: A, i) => {
        if (predicate(a)) {
            index = i;
        }
    });

    return index;
};

export function deleteFromYArray<A, B>(
    yarray: Y.Array<A>,
    items: B[],
    predicate: (a: B) => boolean,
    logCtx: string,
): E.Either<Error, number> {
    return pipe(
        items,
        A.findIndex(predicate),
        E.fromOption(() => new Error(`${logCtx}: item not found in ${JSON.stringify(items, null, 2)}`)),
        E.chain((index) =>
            pipe(
                E.tryCatch(() => yarray.delete(index), E.toError),
                // eslint-disable-next-line
                E.map((_) => index),
            ),
        ),
    );
}

export function visitYBlocksBreadthFirst(
    yBlocks: Y.Array<unknown>,
    callback: (item: Y.Map<unknown>) => 'stop-tree' | 'continue',
) {
    const queue = new Queue<Y.Array<any>>();
    queue.enqueue(yBlocks);

    while (!queue.isEmpty()) {
        const array = queue.dequeue();

        if (array) {
            array.forEach((item) => {
                if (item instanceof Y.Map) {
                    const next = callback(item);

                    if (next === 'continue') {
                        const children = item.get('children');

                        if (children instanceof Y.Array) {
                            queue.enqueue(children);
                        }
                    }
                }
            });
        }
    }
}

// TODO: Would be awesome to have a predefined function that we can feed the path
// and it would decode all of this properly

/**
 *
 * @param yBlocks The yBlocks array to get the blockf rom
 * @param path The path to the block
 *
 * @returns the block found by the path
 */
export function getBlockByPath(yBlocks: Y.Array<any>, path: number[]): Y.Map<unknown> | null {
    if (path.length === 0) return null;

    const [index, ...restPath] = path;

    const yBlock = yBlocks.get(index);

    if (!(yBlock instanceof Y.Map)) return null;

    if (restPath.length > 0 && yBlock.has('children')) {
        const children = yBlock.get('children');
        assertYArray(children);
        return getBlockByPath(children, restPath);
    }

    return yBlock;
}

export function isYArray(value: unknown): value is Y.Array<unknown> {
    return value instanceof Y.Array;
}
