import React from 'react';
import { Image, File as FileItem } from '@saga/shared';

type Observer = () => void;
function createFileMap() {
    const map = new Map<string, File>();
    let observers: Observer[] = [];

    function notifyAll() {
        observers.forEach((observer) => {
            observer();
        });
    }

    return {
        has(id: string) {
            return map.has(id);
        },
        add(id: string, file: File) {
            map.set(id, file);
            notifyAll();
        },
        get(id: string): File {
            const file = map.get(id);
            if (file == null) throw new Error(`File with id ${id} does not exist in FileMap.`);
            return file;
        },
        remove(id: string) {
            map.delete(id);
            notifyAll();
        },
        observe(observer: Observer) {
            observers.push(observer);

            function unsubscribe() {
                observers = observers.filter((o) => o === observer);
            }

            return unsubscribe;
        },
    };
}

// This is just a context without a provider to use it as a singleton
// and to make it easier to turn it into a real context later on
const PendingImagesContext = React.createContext(createFileMap());

export function usePendingUploads() {
    return React.useContext(PendingImagesContext);
}

export async function getFileAsDataUrl(file: File) {
    const reader = new FileReader();

    const previewUrl = await new Promise<string | null>((resolve) => {
        reader.addEventListener(
            'load',
            () => {
                resolve(reader.result?.toString() ?? null);
            },
            false,
        );

        reader.readAsDataURL(file);
    });

    return previewUrl;
}

export function usePendingUpload({
    element,
    onPreview,
}: {
    element: Image | FileItem;
    onPreview: (url: string | null) => void;
}) {
    const pendingImages = usePendingUploads();
    const previewHandler = React.useRef(onPreview);
    previewHandler.current = onPreview;
    const [isPending, setIsPending] = React.useState(pendingImages.has(element.id));

    React.useEffect(() => {
        function checkPendingImage() {
            const imageIsPending = pendingImages.has(element.id);
            if (imageIsPending) {
                const file = pendingImages.get(element.id);
                getFileAsDataUrl(file).then(previewHandler.current);
            }
            setIsPending(imageIsPending);
        }

        checkPendingImage();

        return pendingImages.observe(checkPendingImage);
    }, [element.id, pendingImages]);

    return { isPending };
}
