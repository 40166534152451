import { SagaElement, SagaText, isAnyBlockItem, isBlockType, isInline, isSagaElement, isSagaText } from '..';
import { getPageById } from '../SpaceOperations';
import { SafeSpace, WeakBlocks } from '../types';
import { escapeXMLSymbols } from './xmlToSaga';

export function sagaToXml(space: SafeSpace, blocks: WeakBlocks): string | undefined {
    if (!blocks.length) return;

    let document = `<root>`;
    blocks.forEach((block) => {
        document += xmlTagFromBlock(space, block);
    });

    return `${document}</root>`;
}

function xmlTagFromBlock(space: SafeSpace, block: SagaElement | SagaText): string {
    if (isBlockType(block, [isSagaElement, isSagaText])) {
        let content = '';
        let attributes = {};

        if (isInline(block)) {
            if (isSagaText(block)) {
                const attributes = {
                    ...(block.bold ? { bold: 'true' } : {}),
                    ...(block.italic ? { italic: 'true' } : {}),
                    ...(block.underline ? { underline: 'true' } : {}),
                    ...(block.code ? { code: 'true' } : {}),
                    ...(block.highlight ? { highlight: 'true' } : {}),
                };

                return `<text${getAttributesString(attributes)}>${
                    block.text ? escapeXMLSymbols(block.text).replace(/\0/g, '') : ''
                }</text>`;
            }

            switch (block.type) {
                case 'ai-suggested-text':
                    break;
                case 'date-block':
                    attributes = { date: block.date };
                    break;
                case 'google-drive-link':
                    content = block.children[0].text;
                    attributes =
                        block.state.type === 'loaded'
                            ? {
                                  name: block.state.file.name,
                                  id: block.state.file.id,
                                  webViewLink: block.state.file.webViewLink,
                                  iconLink: block.state.file.iconLink,
                              }
                            : {};

                    break;
                case 'inline-collection':
                    attributes = { collectionId: block.collectionId };
                    break;
                case 'inline-page-link':
                    const page = getPageById(space, block.pageId, ['title', 'icon', 'isTemplate']);
                    content = page?.title ?? block.staticPage?.title ?? '';
                    attributes = { pageId: block.pageId };
                    break;
                case 'katex-inline':
                    attributes = { value: block.value };
                    break;
                case 'linear-issue':
                    content = block.children[0].text;
                    attributes =
                        block.state.type === 'loaded'
                            ? {
                                  id: block.state.issue.id,
                                  name: block.state.issue.title,
                                  identifier: block.state.issue.identifier,
                                  url: block.state.issue.url,
                              }
                            : {};
                    break;
                case 'link':
                    content = block.children[0].text;
                    attributes = { url: block.url };
                    break;
                case 'mention':
                    content = block.children[0].text;
                    attributes = { memberId: block.memberId };
                    break;
            }

            return `<${block.type}${getAttributesString(attributes)}>${escapeXMLSymbols(content).replace(/\0/g, '')}</${
                block.type
            }>`;
        } else {
            const children = block.children as (SagaElement | SagaText)[];

            if (isAnyBlockItem(block)) {
                switch (block.type) {
                    case 'code':
                        attributes = { content: escapeXMLSymbols(block.content), language: block.language };
                        break;
                    case 'check-list-item':
                        attributes = { checked: String(block.checked ?? false) };
                        break;
                    case 'embed':
                        attributes = { url: block.url };
                        break;
                    case 'image':
                        attributes = {
                            url: block.url,
                            width: block.size?.[0],
                            height: block.size?.[1],
                            align: block.align,
                            ratio: block.ratio,
                        };
                        break;
                    case 'katex':
                        attributes = { value: block.value };
                        break;
                    case 'live-reference-source':
                    case 'reference':
                        attributes = { id: block.id };
                        break;
                    case 'pretty-link':
                        attributes = { url: block.url };
                        break;
                    case 'table':
                        attributes = { width: block.dimensions[0], height: block.dimensions[1] };
                        break;
                    case 'table-row':
                    case 'table-cell':
                        break;
                    case 'task-block':
                        attributes = { id: block.taskId };
                        break;
                    case 'file':
                        attributes = {
                            url: block.url,
                            size: block.size,
                            title: block.title,
                        };
                        break;
                }
            }

            return `<${block.type}${getAttributesString(attributes)}>${children
                .map((block: any) => xmlTagFromBlock(space, block))
                .join('')}</${block.type}>`;
        }
    }

    return '';
}

function getAttributesString(attributes: Record<string, unknown>): string {
    const attributeString = Object.entries(attributes)
        .map((a) => `${a[0]}="${a[1]}"`)
        .join(' ');

    return attributeString.length ? ` ${attributeString}` : '';
}
