import Spinner from '@/components/loading/Spinner';
import Button from '@/components/styled/Button';
import { useCurrentWorkspace } from '@/components/WorkspaceContext';
import { getPromptText } from '@/utils/aiUtils';
import { formatTimeAgo } from '@/utils/dateUtils';
import { AiChatThread, useChatThreadsQuery, useHideAiThreadMutation } from '@saga/api';
import { groupBy, orderBy } from 'lodash';
import { DateTime } from 'luxon';
import React from 'react';
import { Trash } from 'react-feather';
import * as api from '@saga/api';
import { useTranslation } from 'react-i18next';

type Props = {
    onSelected: (event: React.MouseEvent, thread: AiChatThread) => void;
};

function AIChatThreadsList({ onSelected }: Props) {
    const { urlKey } = useCurrentWorkspace();
    const { t } = useTranslation();
    const { data, loading: loadingThreads } = useChatThreadsQuery({
        variables: { input: { urlKey } },
        fetchPolicy: 'cache-and-network',
    });
    const [hideThread] = useHideAiThreadMutation();

    const groupedThreads = React.useMemo(
        () =>
            data?.chatThreads
                ? groupBy(data.chatThreads, (thread) => formatTimeAgo(DateTime.fromISO(thread.messages[0].createdAt)))
                : {},
        [data?.chatThreads],
    );

    const onHideThread = React.useCallback(
        async (id: string) => {
            try {
                await hideThread({
                    variables: { input: { threadId: id } },
                    optimisticResponse: { hideAIThread: true },
                    update(cache, { data }) {
                        if (!data?.hideAIThread) return;

                        const cachedData = cache.readQuery<api.ChatThreadsQuery>({
                            query: api.ChatThreadsDocument,
                            variables: { input: { urlKey } },
                        });

                        cache.writeQuery({
                            query: api.ChatThreadsDocument,
                            data: {
                                chatThreads: cachedData?.chatThreads.filter((thread) => thread.id !== id) || [],
                            },
                            variables: { input: { urlKey } },
                        });
                    },
                });
            } catch {}
        },
        [hideThread, urlKey],
    );

    return loadingThreads && !data?.chatThreads.length ? (
        <div className="flex flex-1 justify-center items-center">
            <Spinner size={20} />
        </div>
    ) : !data?.chatThreads.length ? (
        <div className="flex flex-1 justify-center items-center">
            <p className="text-saga-gray-500">{t('ai.chat.no_threads')}</p>
        </div>
    ) : (
        <div>
            {Object.keys(groupedThreads).map((time, index) => (
                <div key={index}>
                    <div className="sticky top-0 bg-white dark:bg-saga-gray-900">
                        <div className="border-b border-saga-gray-200 dark:border-saga-gray-800" />
                        <div className="mx-3 my-2 font-semibold">{time}</div>
                        <div className="border-b border-saga-gray-200 dark:border-saga-gray-800" />
                    </div>

                    {orderBy(groupedThreads[time], (thread) => thread.messages[0].createdAt, 'desc').map(
                        (thread, index) => {
                            const firstUserMessage = thread.messages.findLast((m) => m.role === 'user');
                            const prompt = firstUserMessage ? getPromptText(firstUserMessage) : null;

                            return (
                                <div
                                    key={index}
                                    onClick={(event) => onSelected(event, thread)}
                                    className="flex flex-1 flex-row items-center cursor-pointer hover:bg-saga-gray-200 dark:hover:bg-saga-gray-700 m-1 px-2 py-1 rounded group gap-x-3"
                                >
                                    <div className="flex-1 truncate">{prompt ?? '-'}</div>
                                    <div className="opacity-0 group-hover:opacity-100">
                                        <Button.Circle
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                onHideThread(thread.id);
                                            }}
                                        >
                                            <Trash size={16} />
                                        </Button.Circle>
                                    </div>
                                </div>
                            );
                        },
                    )}
                </div>
            ))}
        </div>
    );
}

export default AIChatThreadsList;
