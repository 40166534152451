import {
    Editor,
    Range as SlateRange,
    Path as SlatePath,
    Transforms,
    Node,
    Point,
    NodeEntry,
    Location,
    BaseRange,
    Path,
    Range,
} from 'slate';
import { ReactEditor } from 'slate-react';
import * as Sentry from '@sentry/react';
import {
    SagaElement,
    isBlockType,
    isBlockContainer,
    isIndentContainer,
    isNumberedList,
    isTitle,
    isSagaElement,
    EditorOperations,
    SagaEditor as SharedEditor,
} from '..';

export function getNode(editor: Editor, id: string): NodeEntry<SagaElement> | null {
    const nodeEntry = Editor.nodes(editor, {
        match: (n): n is SagaElement => {
            return isSagaElement(n) && n.id === id;
        },
        at: [],
    }).next().value;

    if (nodeEntry) {
        return nodeEntry;
    }

    return null;
}

export function safeSelectBlockById(editor: Editor, id: string) {
    try {
        const nodeEntry = getNode(editor, id);

        if (nodeEntry) {
            const [, path] = nodeEntry;
            safeSelectByLocation(editor, path);
        }
    } catch (e) {
        console.warn(e);
        Sentry.captureException(e);
    }
}

export function safeSelectByLocation(editor: Editor, at: Location) {
    try {
        Transforms.select(editor, at);
    } catch (e) {
        console.warn(e);
        Sentry.captureException(e);
    }
}

export function safeSelectEdge(editor: Editor, edge: 'start' | 'end', at?: Location) {
    try {
        if (editor.children.length === 0) {
            throw 'Editor is not ready';
        }
        let slateRange: Location | null = at ?? null;
        let location;
        if (edge === 'start') {
            location = Editor.start(editor, slateRange ?? [0]);
        }
        if (edge === 'end') {
            location = Editor.end(editor, slateRange ?? []);
        }
        if (location) {
            Transforms.select(editor, location);
        } else {
            throw new Error('safe selecting edge failed, range does not exist');
        }
    } catch (e) {
        console.warn(e);
        Sentry.captureException(e);
    }
}

export function buildSlateRange(path: SlatePath, anchorOffset: number, focusOffset: number): SlateRange {
    return {
        anchor: { path, offset: anchorOffset },
        focus: { path, offset: focusOffset },
    };
}

export function reorderSlateRange(range: SlateRange, backward = false) {
    if (backward) {
        return SlateRange.isBackward(range) ? range : { anchor: range.focus, focus: range.anchor };
    } else {
        return SlateRange.isForward(range) ? range : { anchor: range.focus, focus: range.anchor };
    }
}

export function getFormat(editor: Editor, slateRange: Range) {
    try {
        const nodes = [];
        const forwardSelection = reorderSlateRange(slateRange);
        for (const [node] of Node.nodes(editor, {
            from: forwardSelection.anchor.path,
            to: forwardSelection.focus.path,
        })) {
            if (isSagaElement(node)) {
                nodes.push(node);
            }
        }
        const types = nodes
            .filter((n) => !isBlockType(n, [isTitle, isBlockContainer, isIndentContainer, isNumberedList]))
            .map((n) => n.type)
            .filter((v, i, a) => a.indexOf(v) === i);
        if (types.length === 0) return 'none';
        if (types.length > 1) return 'mixed';
        else return types[0] as string;
    } catch {
        return null;
    }
}

export function safeToDOMRange(editor: ReactEditor, range: BaseRange) {
    try {
        return ReactEditor.toDOMRange(editor, range);
    } catch (e) {}

    return null;
}

export function safeToDOMNode(editor: ReactEditor, node: Node) {
    try {
        return ReactEditor.toDOMNode(editor, node);
    } catch (e) {}

    return null;
}

export function shiftPathEnd(path: number[], by: number) {
    const copy = [...path];
    const pathEnd = copy[copy.length - 1];
    copy[copy.length - 1] = Math.max(0, pathEnd + by);
    return copy;
}

export function initialSelect(editor: Editor, blockPlugins: SharedEditor.Plugins.BlockPlugin[]) {
    const titleElement = editor.children[0];
    if (titleElement == null) return;
    const title = EditorOperations.SagaElement.toString(titleElement, blockPlugins);
    const content = EditorOperations.SagaElement.toString(editor, blockPlugins);

    /**
     * We only select if the title is the same as the text
     * There are two cases that we are interested in
     *
     * 1. The title is empty and there is no content (also no void node as second child)
     * 2. The title is set, but the rest of the content is empty (also no void node as second child)
     *
     * In both cases, title and content are actually the same string.
     */
    if (title.trim() === content.trim()) {
        if (title.trim().length === 0) {
            // We know at this point that the content is empty
            // because we know that the title and the content is the same
            // Therefore we put the selection at the very beginning
            safeSelectEdge(editor, 'end', [0]);
        } else {
            // Since the title is not empty, we now we want to set the cursor at the end
            // In this case, this is in the first paragraph
            safeSelectEdge(editor, 'end', [1]);
        }
    }
}

/**
 * This returns the range from start to the end in the current path
 */
export function getRangeFromStartToEndInCurrentPath(editor: Editor): BaseRange | null {
    if (editor.selection == null) return null;

    const parent = Path.parent(editor.selection.focus.path);

    const range = {
        anchor: Editor.start(editor, parent),
        focus: Editor.end(editor, parent),
    };

    return range;
}

/**
 * This is a kludge to avoid formatting the next line of the content
 * if the selection include the 'focus' with offset 0.
 *
 * Use it with care.
 */
export const excludeLastPathIfOffsetZero = (editor: Editor, range: Range): Range => {
    const [start, end] = Range.edges(range);

    if (end.offset === 0) {
        const previousPoint = Editor.before(editor, end);

        // If there is no previous point, we can't do anything about it so we just return the incoming range
        if (!previousPoint) return range;

        // We just want to make sure that we return retain the anchor and focus
        if (Point.equals(start, range.anchor)) {
            return {
                anchor: start,
                focus: previousPoint,
            };
        } else {
            return {
                focus: start,
                anchor: previousPoint,
            };
        }
    }

    return range;
};
