import React from 'react';
import * as api from '@saga/api';
import {
    BlockD,
    assertNonNull,
    isBlockType,
    isFile,
    isImage,
    isInlineCollection,
    isInlinePageLink,
    isLiveBlock,
    isSagaElement,
    isTaskBlock,
} from '@/../../shared/src';
import { track } from '@/analytics';
import NotFound from '@/components/NotFound';
import EmptyBlocksPlaceholder from '@/components/EmptyBlocksPlaceholder';

const PublicPageContext = React.createContext<{
    page: api.Page;
    permission: api.PagePermission;
} | null>(null);

export function usePageStaticElement<T>(element: T) {
    const context = React.useContext(PublicPageContext);
    assertNonNull(context, 'usePageStaticElements must be used inside PublicPageStaticBlocksContext');

    if (!isBlockType(element, [isInlineCollection, isInlinePageLink, isTaskBlock, isLiveBlock, isImage, isFile]))
        return null;
    const staticBlock = context.page.staticBlocks.find(
        (block: BlockD) => isSagaElement(block) && block.id === element.id,
    );
    if (!staticBlock) return null;

    if (isInlinePageLink(staticBlock)) return staticBlock as T;
    if (isTaskBlock(staticBlock)) return staticBlock as T;
    if (isInlineCollection(staticBlock)) return staticBlock as T;
    if (isLiveBlock(staticBlock)) return staticBlock as T;
    if (isImage(staticBlock)) return staticBlock as T;
    if (isFile(staticBlock)) return staticBlock as T;

    return null;
}

export function useCurrentPublicPage() {
    const context = React.useContext(PublicPageContext);
    assertNonNull(context, 'useCurrentPublicPage must be used inside PublicPageStaticBlocksContext');
    return React.useMemo(
        () => ({
            page: context.page,
            canEdit: [api.PagePermission.Admin, api.PagePermission.Writer].includes(context.permission),
        }),
        [context],
    );
}

export function PublicPageProvider({ children, pageId }: { children: React.ReactNode; pageId: string }) {
    const { loading, error, data } = api.usePageQuery({
        variables: { input: { pageId } },
        fetchPolicy: 'network-only',
        onCompleted() {
            track('visit-public-page', { pageId });
        },
    });

    const [subscribe, setSubscribe] = React.useState(false);

    const { data: subscriptionData } = api.useUserSharedPagesUpdatedSubscription({
        skip: !subscribe,
    });

    React.useEffect(() => {
        setTimeout(() => setSubscribe(true), 0);
    }, []);

    const permission = React.useMemo(() => {
        return (
            subscriptionData?.userSharedPages?.find((page) => page.page.id === pageId)?.permission ||
            data?.page?.permission ||
            api.PagePermission.Hidden
        );
    }, [data, pageId, subscriptionData]);

    if (loading) {
        return <EmptyBlocksPlaceholder type={'page'} state={'LOADING'} />;
    }

    if (error || !data?.page) {
        return <NotFound />;
    }

    return (
        <PublicPageContext.Provider value={{ page: data.page.page, permission }}>{children}</PublicPageContext.Provider>
    );
}
