import * as Y from 'yjs';
import { unsafeRight } from '../io-ts-js';
import { findYArrayIndexOrThrow } from '../yjs-utils';
import { SafeSpace } from '../types';

export default function findCollectionById(space: SafeSpace, id: string): Y.Map<unknown> {
    const safeArray = unsafeRight(space.get('collections'));
    const index = findYArrayIndexOrThrow(
        safeArray.array as Y.Array<Y.Map<unknown>>,
        (map: Y.Map<unknown>) => map.get('id') === id,
    );
    return safeArray.array.get(index) as Y.Map<unknown>;
}
