import { DateUtils, WeakTask } from '@saga/shared';
import classNames from 'classnames';
import React from 'react';
import Button from '../styled/Button';
import Dropdown from '../popover/Dropdown';
import { DayPicker } from 'react-day-picker';
import { Calendar } from 'react-feather';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useUserContext } from '../UserContext';
import useMobile from '@/hooks/useMobile';
import { formatTaskDate } from '../../../../shared/src/utils/DateUtils';

const mobileStyles = {
    root: { margin: '8px' },
    cell: { width: '35px', height: '35px' },
    day: { width: '35px', height: '35px' },
};

type DueDateSelectVariant = 'default' | 'minimal';

function DueDateDropdown({
    isOpen,
    attachToRef,
    onClose,
    value,
    onChange,
}: {
    isOpen: boolean;
    onClose(): void;
    attachToRef: React.RefObject<HTMLElement | null>;
    value: Date | null;
    onChange(value: Date | null): void;
}) {
    const { userSettings } = useUserContext();
    const isMobile = useMobile();

    return (
        <Dropdown
            testId="date-block-picker"
            isOpen={isOpen}
            attachToRef={attachToRef}
            onClose={onClose}
            align={isMobile ? 'left' : 'right'}
        >
            <div className={classNames('flex flex-col', { 'pb-3': value == null })}>
                <DayPicker
                    weekStartsOn={userSettings?.weekStartOnMonday ? 1 : 0}
                    mode="single"
                    selected={value ? value : undefined}
                    onSelect={(date: any) => {
                        onChange(date ? date : null);
                        onClose();
                    }}
                    styles={isMobile ? mobileStyles : undefined}
                />

                {value && (
                    <div className="p-2 px-2">
                        <Button.Plain
                            onClick={() => {
                                onChange(null);
                                onClose();
                            }}
                            widthFull
                        >
                            <Button.BasePadding>Remove Due Date</Button.BasePadding>
                        </Button.Plain>
                    </div>
                )}
            </div>
        </Dropdown>
    );
}

export function DueDateSelect({
    task,
    onChange,
    disabled,
    showEmptyState = true,
    variant = 'default',
}: {
    task: WeakTask;
    onChange(newValue: Date | null): void;
    disabled: boolean;
    showEmptyState?: boolean;
    variant?: DueDateSelectVariant;
}) {
    const { dueDate, state } = task;
    const date = dueDate ? new Date(dueDate) : null;
    const [isDueDatePickerOpen, setIsDueDatePickerOpen] = React.useState(false);
    const dueDatePickerButtonRef = React.useRef<HTMLButtonElement>(null);
    const isPastDue = state === 'OPEN' && date ? DateUtils.isPast(date) : false;
    const isToday = state === 'OPEN' && date ? DateUtils.isToday(date) : false;
    const { t } = useTranslation();

    return (
        <>
            {date && (
                <div className="flex">
                    <Button.RemovableOption
                        disabled={disabled || variant === 'minimal'}
                        onRemove={() => onChange(null)}
                        label="Remove Due Date"
                    >
                        <button
                            className={classnames(
                                'focus:outline-none whitespace-nowrap space-x-1 cursor-pointer flex flex-row items-center my-[1px]',
                                {
                                    'text-saga-red': isPastDue,
                                    'text-saga-green': isToday,
                                    'h-[18px]': variant === 'minimal',
                                    'h-6': variant === 'default',
                                },
                            )}
                            ref={dueDatePickerButtonRef}
                            onClick={(e) => {
                                if (disabled) return;
                                e.stopPropagation();
                                setIsDueDatePickerOpen(!isDueDatePickerOpen);
                            }}
                            data-testid="due-date"
                        >
                            <Calendar className="inline-block align-text-bottom select-none h-3.5 w-3.5" />
                            <span>{formatTaskDate(date, false)}</span>
                        </button>
                    </Button.RemovableOption>
                </div>
            )}
            {!date &&
                showEmptyState &&
                (variant === 'default' ? (
                    <Button.DashedPill
                        ref={dueDatePickerButtonRef}
                        onClick={() => {
                            if (disabled) return;
                            setIsDueDatePickerOpen(true);
                        }}
                        data-testid="due-date"
                    >
                        <div className="flex space-x-1 items-center h-5">
                            <span className="whitespace-nowrap">{t('tasks.no_due_date')}</span>
                        </div>
                    </Button.DashedPill>
                ) : (
                    <Button.Dashed
                        className="flex items-center justify-center rounded-5 h-[22px] w-[22px] shrink-0"
                        data-testid="due-date"
                        onClick={(e) => {
                            if (disabled) return;

                            e.stopPropagation();
                            setIsDueDatePickerOpen(!isDueDatePickerOpen);
                        }}
                        ref={dueDatePickerButtonRef}
                    >
                        <span className="sr-only">Add Due Date</span>
                        <Calendar className="h-3.5 w-3.5" />
                    </Button.Dashed>
                ))}

            {isDueDatePickerOpen && (
                <DueDateDropdown
                    isOpen={isDueDatePickerOpen}
                    attachToRef={dueDatePickerButtonRef}
                    onClose={() => setIsDueDatePickerOpen(false)}
                    value={date ? date : null}
                    onChange={onChange}
                />
            )}
        </>
    );
}
