import React, { useCallback } from 'react';
import * as t from 'io-ts';
import { getStoredOrDefault } from '@/utils/localStorageUtils';
import useMobile from './useMobile';

const rightMenuD = t.union([
    t.literal('references'),
    t.literal('properties'),
    t.literal('toc'),
    t.literal('settings'),
    t.null,
]);

export type RightMenu = t.TypeOf<typeof rightMenuD>;

const collectionTableViewModeD = t.union([t.literal('folder'), t.literal('tag')]);

export type CollectionTableViewMode = t.TypeOf<typeof collectionTableViewModeD>;

const decoder = t.type({
    rightMenu: rightMenuD,
    fixedSidebar: t.boolean,
    darkMode: t.union([t.boolean, t.undefined]),
    autoColorScheme: t.boolean,
    spellCheck: t.union([t.boolean, t.undefined]),
    sidebarWidth: t.union([t.number, t.undefined, t.null]),
    sidePanelWidth: t.union([t.number, t.undefined, t.null]),
    collectionTableViewMode: t.union([collectionTableViewModeD, t.undefined, t.null]),
});

type InterfaceSettings = t.TypeOf<typeof decoder>;

const DEFAULT_SETTINGS: InterfaceSettings = {
    sidebarWidth: null,
    sidePanelWidth: null,
    rightMenu: null,
    collectionTableViewMode: null,
    fixedSidebar: false,
    darkMode: false,
    spellCheck: false,
    autoColorScheme: true,
};

const LOCAL_STORAGE_KEY = 'interface-settings';

export function getInterfaceSettings({ defaultFixedSidebar = false }: { defaultFixedSidebar?: boolean } = {}) {
    return getStoredOrDefault(LOCAL_STORAGE_KEY, decoder.decode, {
        ...DEFAULT_SETTINGS,
        fixedSidebar: defaultFixedSidebar,
    });
}

function storeSettings(settings: InterfaceSettings) {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(settings));
}

const InterfaceSettingsContext = React.createContext<InterfaceSettings>(DEFAULT_SETTINGS);
const UpdateInterfaceSettingsContext = React.createContext<(newSettings: Partial<InterfaceSettings>) => void>(() => {});

export function InterfaceSettingsProvider({ children }: { children: React.ReactNode }) {
    const isMobile = useMobile();
    const [settings, setSettings] = React.useState(() => getInterfaceSettings({ defaultFixedSidebar: !isMobile }));

    const updateSettings = useCallback((newSettings: Partial<InterfaceSettings>) => {
        setSettings((settings) => {
            const nextSettings = { ...settings, ...newSettings };
            storeSettings(nextSettings);
            return nextSettings;
        });
    }, []);

    return (
        <InterfaceSettingsContext.Provider value={settings}>
            <UpdateInterfaceSettingsContext.Provider value={updateSettings}>
                {children}
            </UpdateInterfaceSettingsContext.Provider>
        </InterfaceSettingsContext.Provider>
    );
}

const useInterfaceSettings = (): [InterfaceSettings, (newSettings: Partial<InterfaceSettings>) => void] => {
    const settings = React.useContext(InterfaceSettingsContext);
    const updateSettings = React.useContext(UpdateInterfaceSettingsContext);
    return [settings, updateSettings];
};

export default useInterfaceSettings;
