import React from 'react';
import Modal from '../Modal';
import Button from '../styled/Button';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import Spinner from '../loading/Spinner';
import BasicButton from '../styled/BasicButton';

export function ConfirmationModal({
    confirmText,
    onConfirm,
    onCancel,
    isOpen = true,
    onLoading = false,
    disabled,
    title,
    description,
}: {
    confirmText: string;
    onConfirm: () => void;
    onCancel: () => void;
    isOpen?: boolean;
    disabled?: boolean;
    onLoading?: boolean;
    title: string;
    description?: string;
}) {
    const { t } = useTranslation();
    return (
        <Modal.Medium isOpen={isOpen}>
            <div
                data-testid="confirmation-modal"
                className={`relative p-6 rounded max-w-xl bg-saga-gray-100 dark:bg-zinc-700 dark:text-zinc-200 text-saga-text`}
            >
                <div
                    className={classNames('font-bold text-base leading-6', {
                        'mb-3': description,
                        'mb-5': !description,
                    })}
                >
                    {title}
                </div>
                {description && <p className="mb-6 text-saga-gray-500">{description}</p>}
                <div className="flex justify-end">
                    <BasicButton onClick={onCancel} variant="secondary">
                        <Button.XsPadding>{t('common.cancel')}</Button.XsPadding>
                    </BasicButton>
                    <BasicButton onClick={onConfirm} className="ml-3" disabled={disabled}>
                        <Button.XsPadding>
                            <div className="flex space-x-2">
                                {onLoading && <Spinner size={16} />}
                                <span>{confirmText}</span>
                            </div>
                        </Button.XsPadding>
                    </BasicButton>
                </div>
            </div>
        </Modal.Medium>
    );
}
