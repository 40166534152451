import React from 'react';
import AIActionsSuggest from '@/components/editor/ai/AIActionsSuggest';
import UpgradePlanBanner from './UpgradePlanBanner';
import { CustomPromptType } from '@/../../api/src';
import RestrictedAIBanner from '@/components/editor/ai/RestrictedAIBanner';
import { AISourceItem } from '@saga/shared';
import AISourcesList from '@/components/editor/ai/AISourcesList';
import AIPromptInput from '@/components/editor/ai/AIPromptInput';
import { useAIEnabled } from '@/components/editor/ai/AIPermissionsProvider';
import { useTranslation } from 'react-i18next';
import { track } from '@/analytics';

interface InputProps {
    onSubmit: (text: string, sources?: AISourceItem[]) => void;
    type: CustomPromptType;
    onSuggestedPrompt: (prompt: string) => void;
}

const AIQuestionContent = ({ onSubmit, type, onSuggestedPrompt }: InputProps) => {
    const [text, setText] = React.useState<string | undefined>();
    const { aiStatus } = useAIEnabled();
    const textAreaRef = React.useRef<HTMLTextAreaElement>(null);
    const { t } = useTranslation();

    const [suggestionsOpened, setSuggestionsOpened] = React.useState(true);
    const [sources, setSources] = React.useState<AISourceItem[]>([]);

    const focusInputField = () => {
        const element = document.getElementById('popup-input');
        if (element instanceof HTMLElement) {
            setTimeout(() => element.focus());
        }
    };

    React.useLayoutEffect(() => {
        focusInputField();
    }, []);

    React.useEffect(() => {
        if (textAreaRef.current) {
            // 288px is pb-96 of BottomDropArea
            textAreaRef.current.style.height = 'inherit';
            textAreaRef.current.style.height = text ? `${Math.min(textAreaRef.current.scrollHeight, 288)}px` : '40px';
        }
    }, [text]);

    return (
        <>
            <div
                className="overflow-auto p-1.5 mb-1 rounded-md bg-white dark:bg-saga-gray-900 shadow-small"
                data-testid="ai-popover"
            >
                <div className="flex flex-col w-full">
                    <AISourcesList
                        sources={sources}
                        onItemRemoved={(id) => {
                            setSources(sources.filter((source) => source.id !== id));
                            track('click-on-ai-popover-button', { source: 'ai-popover' });
                        }}
                    />
                    <AIPromptInput
                        placeholder={t('ai.ask_ai_anything_mentions_dotted')}
                        id="popup-input"
                        sources={sources}
                        text={text}
                        setText={setText}
                        status={'idle'}
                        onUpdateSources={setSources}
                        onSubmit={(text) => onSubmit(text, sources)}
                        onCancel={() => {}}
                        onRetry={() => {}}
                        onSourcesVisible={(visible) => {
                            setSuggestionsOpened(!visible);
                            if (!visible) focusInputField();
                        }}
                    />
                </div>
                {aiStatus === 'disabled' && (
                    <>
                        <div className="bg-saga-gray-200 dark:bg-saga-gray-700 h-[1px] w-full my-1" />
                        <UpgradePlanBanner />
                    </>
                )}
                {aiStatus === 'restricted' && <RestrictedAIBanner />}
            </div>

            {suggestionsOpened && (
                <AIActionsSuggest
                    type={type}
                    filter={text}
                    onSuggestedPrompt={(prompt) => {
                        if (type === CustomPromptType.Command) {
                            onSuggestedPrompt(prompt);
                        } else {
                            setText(prompt);
                        }
                        focusInputField();
                    }}
                />
            )}
        </>
    );
};
export default AIQuestionContent;
