type PaddingOptions = {
    paddingX?: number;
    paddingY?: number;
};

const DEFAULT_OPTIONS = {
    alignment: 'left',
    paddingX: 0,
    paddingY: 0,
    overflowOffsetY: 100,
    overflowOffsetX: 100,
};

type HorizontalAlignOptions = {
    alignment?: 'left' | 'right' | 'center';
};
type VerticalAlignOptions = {
    alignment?: 'top' | 'bottom';
};
type AlignOptions = {
    alignment?: 'top' | 'bottom' | 'left' | 'right' | 'center';
};
type OverflowXOptions = {
    overflowOffsetX?: number;
};
type OverflowYOptions = {
    overflowOffsetY?: number;
};

export function moveElementBelowRect(
    el: HTMLElement,
    rect: DOMRect,
    options?: PaddingOptions & HorizontalAlignOptions & OverflowYOptions,
) {
    const { alignment, paddingX, paddingY, overflowOffsetY } = { ...DEFAULT_OPTIONS, ...options };
    if (rect.bottom > window.scrollY + window.innerHeight && rect.top > window.scrollY + window.innerHeight) {
        el.style.top = `${window.scrollY - overflowOffsetY + paddingY}px`;
    } else {
        el.style.top = `${rect.top + window.scrollY + rect.height + paddingY}px`;
    }

    if (alignment === 'left') {
        el.style.left = `${rect.left + window.scrollX + paddingX}px`;
    }
    if (alignment === 'right') {
        const elRect = el.getBoundingClientRect();
        el.style.left = `${rect.right + window.scrollX - elRect.width + paddingX}px`;
    }
    if (alignment === 'center') {
        el.style.left = `${rect.left + window.scrollX - el.offsetWidth / 2 + rect.width / 2 + paddingX}px`;
    }
}

export function moveElementAboveRect(
    el: HTMLElement,
    rect: DOMRect,
    options?: PaddingOptions & HorizontalAlignOptions & OverflowYOptions,
) {
    const { alignment, paddingX, paddingY, overflowOffsetY } = { ...DEFAULT_OPTIONS, ...options };
    if (rect.top < window.scrollY && rect.bottom > window.scrollY) {
        el.style.top = `${window.scrollY + overflowOffsetY + paddingY}px`;
    } else {
        el.style.top = `${rect.top + window.scrollY - el.offsetHeight + paddingY}px`;
    }
    if (alignment === 'left') {
        el.style.left = `${rect.left + window.scrollX + paddingX}px`;
    }
    if (alignment === 'right') {
        const elRect = el.getBoundingClientRect();
        el.style.left = `${rect.right + window.scrollX - elRect.width + paddingX}px`;
    }
    if (alignment === 'center') {
        el.style.left = `${rect.left + window.scrollX - el.offsetWidth / 2 + rect.width / 2 + paddingX}px`;
    }
}

export function moveElementOnRect(el: HTMLElement, rect: DOMRect, options?: PaddingOptions) {
    const { paddingX, paddingY } = { ...DEFAULT_OPTIONS, ...options };
    el.style.top = `${rect.top + window.scrollY + paddingY}px`;
    el.style.left = `${rect.left + window.scrollX + paddingX}px`;
}

export function moveElementToRightOfRect(
    el: HTMLElement,
    rect: DOMRect,
    options?: PaddingOptions & VerticalAlignOptions,
) {
    const { alignment, paddingX, paddingY } = { ...DEFAULT_OPTIONS, ...options };
    el.style.left = `${rect.right + window.scrollX + paddingX}px`;

    if (alignment === 'top') {
        el.style.top = `${rect.top + window.scrollY + paddingY}px`;
    }
    if (alignment === 'bottom') {
        el.style.bottom = `${rect.top + window.scrollY + rect.height + paddingY}px`;
    }
}

export function moveElementComparedToWindow(el: HTMLElement, options?: PaddingOptions & AlignOptions) {
    const { alignment, paddingX, paddingY } = { ...DEFAULT_OPTIONS, ...options };
    const rect = el.getBoundingClientRect();
    if (alignment === 'center') {
        el.style.left = `${window.scrollX + window.innerWidth / 2 - rect.width / 2 + paddingX}px`;
        el.style.top = `${window.scrollY + window.innerHeight / 2 - rect.height / 2 + paddingY}px`;
    }
    if (alignment === 'top') {
        el.style.left = `${window.scrollX + window.innerWidth / 2 - rect.width / 2 + paddingX}px`;
        el.style.top = `${window.scrollY + paddingY}px`;
    }
    if (alignment === 'bottom') {
        el.style.left = `${window.scrollX + window.innerWidth / 2 - rect.width / 2 + paddingX}px`;
        el.style.top = `${window.scrollY + window.innerHeight - rect.height + paddingY}px`;
    }
    if (alignment === 'left') {
        el.style.top = `${window.scrollY + window.innerHeight / 2 - rect.height / 2 + paddingX}px`;
        el.style.left = `${window.scrollX + paddingX}px`;
    }
    if (alignment === 'right') {
        el.style.top = `${window.scrollY + window.innerHeight / 2 - rect.height / 2 + paddingY}px`;
        el.style.left = `${window.scrollX + window.innerWidth - rect.width / 2 + paddingX}px`;
    }
}
