import {
    assertNonNull,
    SagaEditor as SharedEditor,
    EditorOperations,
    isTitle,
    RealtimeSagaEditor,
    SagaLocation,
    SagaEditor,
    until,
    BlockBuilder,
} from '@saga/shared';
import React from 'react';
import { useTaskMap } from '@/components/tasks/hooks';
import { BlockPlugin } from '@/../../shared/src/Editor/Plugins';
import { ReactEditor, useFocused, useSlateStatic } from 'slate-react';
import { track } from '@/analytics';
import { PlaceholderContainer } from '@/components/editor/popover/Placeholder';
import { Node } from 'slate';
import BaseRenderElement from '../editor/BaseRenderElement';
import { Leaf } from '../editor/leaf/Leaf';
import classNames from 'classnames';
import useCheckTaskShortcut from './useCheckTaskShortcut';
import { useTranslation } from 'react-i18next';
import { usePerformActionWithYBlocks } from '@/components/RealtimeDocumentProvider';

import * as Y from 'yjs';
import { debounce } from 'lodash';
import { toSyncElement } from '../../../../shared/src/slateYjs';
import { useArchiveTask } from '@/hooks/deleteTaskHooks';

const TaskTitleEditorContext = React.createContext<{ id: string } | null>(null);

export function useTaskTitleEditorContext() {
    return React.useContext(TaskTitleEditorContext);
}

export const taskTitleEditors = new Map<string, { focus(): void; isFocused: boolean }>();

function TaskTitleEditorEditable({
    taskId,
    editorRef,
}: {
    taskId: string;
    editorRef: React.MutableRefObject<ReactEditor | null>;
}) {
    const focused = useFocused();
    const editor = useSlateStatic();
    editorRef.current = editor;

    useCheckTaskShortcut({ active: focused, taskId });

    return (
        <div
            className={classNames('rounded select-none cursor-text border border-transparent px-1', {
                'transition-colors hover:border group-hover:bg-white dark:group-hover:bg-saga-gray-1000 group-hover:border-zinc-400':
                    !focused,
                'bg-white dark:bg-saga-gray-1000 border-zinc-400': focused,
            })}
            onPointerLeave={(e) => e.preventDefault()}
            onBlur={() => {
                const taskEditor = taskTitleEditors.get(taskId);
                if (taskEditor) taskEditor.isFocused = false;
            }}
            onFocus={() => {
                const taskEditor = taskTitleEditors.get(taskId);
                if (taskEditor) taskEditor.isFocused = true;
            }}
        >
            <SagaEditor.BlockEditable renderElement={BaseRenderElement} renderLeaf={Leaf} />
        </div>
    );
}

export function TaskTitleEditor({
    taskId,
    blockPlugins,
    onAddTaskBelow,
    onDelete,
}: {
    taskId: string;
    blockPlugins: BlockPlugin[];
    onAddTaskBelow?: () => void;
    onDelete?: () => void;
}) {
    const editorRef = React.useRef<RealtimeSagaEditor | null>(null);
    const taskMap = useTaskMap(taskId);

    const { t } = useTranslation();
    const archiveTask = useArchiveTask();
    assertNonNull(taskMap);

    const titleMap = React.useMemo(() => {
        // Create local map in order to pass it to the editor
        const map = toSyncElement(BlockBuilder.title(taskMap.get('title')));
        const titleDoc = new Y.Doc();
        titleDoc.getMap().set('title', map);
        return map;
    }, [taskMap]);

    const performActionWithBlocks = usePerformActionWithYBlocks();
    const updateTitleBlockText = React.useCallback(
        (text: string) => {
            taskMap.set('title', text);
            performActionWithBlocks(SagaLocation.taskLocationFromId(taskId), (blocks) => {
                updateTitleMap(text, blocks.get(0), false);
            });
        },
        [taskId, performActionWithBlocks, taskMap],
    );
    const debouncedUpdateTitle = React.useMemo(() => debounce(updateTitleBlockText, 1000), [updateTitleBlockText]);

    const updateTitleMap = (text: string, map: Y.Map<any>, shouldUpdateBlock: boolean) => {
        const title = map.get('children').get(0) as Y.Map<any>;
        const textnode = title.get('text') as Y.Text;

        if (text !== textnode.toJSON()) {
            textnode.doc?.transact(() => {
                textnode.delete(0, textnode.length);
                textnode.insert(0, text);
            }, shouldUpdateBlock);
        }
    };

    React.useEffect(() => {
        async function focus() {
            try {
                await until(() => editorRef.current, 3000);
                const editor = editorRef.current;

                if (editor) {
                    ReactEditor.focus(editor);
                    EditorOperations.Selection.initialSelect(editor, blockPlugins);
                }
            } catch {}
        }

        taskTitleEditors.set(taskId, { focus, isFocused: false });

        return () => {
            taskTitleEditors.delete(taskId);
        };
    }, [taskId, blockPlugins]);

    React.useEffect(() => {
        if (!titleMap) return;

        const handleTitleChange = (events: Y.YEvent<any>[]) => {
            events.forEach((event) => {
                if (event instanceof Y.YTextEvent && event.transaction.origin) {
                    const target = event.target as Y.Text;
                    debouncedUpdateTitle(target.toString());
                }
            });
        };

        titleMap.observeDeep(handleTitleChange);

        return () => titleMap.unobserveDeep(handleTitleChange);
    }, [titleMap, debouncedUpdateTitle]);

    React.useEffect(() => {
        if (!taskMap) return;

        const handleTitleChange = (event: Y.YMapEvent<any>) => {
            if (!event.keysChanged.has('title')) return;
            // Use skip origin so we can skip the event later
            updateTitleMap(event.target.get('title'), titleMap, event.transaction.local && event.transaction.origin);
        };

        taskMap.observe(handleTitleChange);

        return () => taskMap.unobserve(handleTitleChange);
    }, [taskMap, titleMap]);

    const location = React.useMemo(() => SagaLocation.taskLocationFromId(taskId), [taskId]);
    const plugins = React.useMemo(
        () => [
            SharedEditor.Plugins.createBlockPlugin({
                match: isTitle,
                Component({ element, children }) {
                    const titleIsEmpty = EditorOperations.SagaElement.toString(element).length === 0;
                    return (
                        <h1 className="break-words min-w-0">
                            {titleIsEmpty && (
                                <PlaceholderContainer>{t('tasks.new_task_editor_placeholder')}</PlaceholderContainer>
                            )}
                            {children}
                        </h1>
                    );
                },
            }),
        ],
        [t],
    );
    const context = React.useMemo(() => ({ id: taskId }), [taskId]);

    if (!titleMap) {
        return null;
    }

    return (
        <TaskTitleEditorContext.Provider value={context}>
            <SagaEditor.BlockEditor
                sharedType={titleMap}
                blockPlugins={plugins}
                location={location}
                onInsertSoftBreak={onAddTaskBelow}
                onDeleteBackward={(taskEditor) => {
                    if (Node.string(taskEditor) === '' && onDelete) {
                        track('archive-task', { source: 'task-page-context-menu' });
                        archiveTask(taskId);
                        onDelete();
                    }
                }}
            >
                <TaskTitleEditorEditable taskId={taskId} editorRef={editorRef} />
            </SagaEditor.BlockEditor>
        </TaskTitleEditorContext.Provider>
    );
}
