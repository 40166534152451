import Tooltip from '@/components/popover/Tooltip';
import React from 'react';
import { Check, Copy } from 'react-feather';

type Props = {
    onCopy: () => void;
    text: {
        before: string;
        after: string;
    };
    id: string;
};

const AICopyButton = ({ onCopy, text, id }: Props) => {
    const [copied, setCopied] = React.useState(false);

    return (
        <Tooltip zIndex={100} content={copied ? text.after : text.before} placement="top">
            <button
                className="focus:outline-none"
                data-testid={id}
                onPointerDown={(e) => {
                    e.preventDefault();
                }}
                onClick={() => {
                    setTimeout(() => {
                        setCopied(true);
                    }, 0);
                    setTimeout(() => {
                        setCopied(false);
                    }, 1000);

                    onCopy();
                }}
            >
                {copied && (
                    <>
                        <span className="sr-only">{text.after}</span>
                        <Check className="select-none flex-none w-[14px] mx-1 text-green-500 " />
                    </>
                )}
                {!copied && (
                    <>
                        <span className="sr-only">{text.before}</span>
                        <Copy className="select-none flex-none w-[14px] mx-1 text-saga-gray-500 dark:text-saga-gray-500 hover:text-saga-black dark:hover:text-white" />
                    </>
                )}
            </button>
        </Tooltip>
    );
};

export default AICopyButton;
