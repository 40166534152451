import { useEffect, useState } from 'react';
import { useRef } from 'react';
import * as E from 'fp-ts/Either';
import reporter from 'io-ts-reporters';
import { Awareness } from 'y-protocols/awareness';
import { removeNullable, SlateYjs } from '..';

export const useAwarenessStates = (awareness: Awareness | undefined | null): SlateYjs.AwarenessState[] => {
    const ref = useRef<SlateYjs.AwarenessState[]>([]);
    const [awarenessStates, setAwarenessStates] = useState<SlateYjs.AwarenessState[]>(() => {
        if (awareness == null) return [];

        return getAwarenessStates(awareness, awareness.clientID, ref.current) ?? [];
    });

    useEffect(() => {
        if (awareness) {
            let _awareness = awareness;
            function onUpdate() {
                const newAwerenessStates = getAwarenessStates(_awareness, _awareness.clientID, ref.current);

                if (newAwerenessStates) {
                    setAwarenessStates(newAwerenessStates);
                    ref.current = newAwerenessStates;
                }
            }

            _awareness.on('update', onUpdate);

            return () => _awareness.off('update', onUpdate);
        }

        return;
    }, [awareness]);

    return awarenessStates;
};

const getAwarenessStates = (
    awareness: Awareness,
    clientId: number,
    prevAwarenessState: SlateYjs.AwarenessState[] | undefined,
): SlateYjs.AwarenessState[] | undefined => {
    const nextAwarenessStates = Array.from(awareness.getStates()).filter(([_clientId]) => _clientId !== clientId);

    if (prevAwarenessState && JSON.stringify(prevAwarenessState) === JSON.stringify(nextAwarenessStates))
        return undefined;

    return nextAwarenessStates
        .map((awareness) => {
            const decoded = SlateYjs.awarenessStateD.decode(awareness);
            if (E.isLeft(decoded)) {
                console.warn(reporter.report(decoded).join(';'));
                return null;
            }
            return decoded.right;
        })
        .filter(removeNullable);
};

export default useAwarenessStates;
