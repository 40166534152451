import { SharedPageItem } from '@/types';
import { useMemo } from 'react';
import { useMembers } from '@/components/MembersProvider';
import { PageItem } from '@/types';
import { useSharedPages } from '@/components/SharedPagesProvider';
import { PageView } from '@saga/shared';
import { usePartialPages } from './SpaceHooks';
import { preparePageItems, prepareSharedPageItems } from '@/lib/Table';

const keysMap = [
    'id',
    'title',
    'collections',
    'wordCount',
    'updatedAt',
    'createdAt',
    'archivedAt',
    'properties',
    'icon',
    'isTemplate',
    'createdBy',
] as const;

const useSortablePages = (pageView: PageView): { spacePages: PageItem[]; sharedPages: SharedPageItem[] } => {
    const pages = usePartialPages(keysMap, 'deep', pageView.mode);

    const shared = useSharedPages();
    const { getMember } = useMembers();

    const spacePages = useMemo(() => {
        const items = pages.map((p): PageItem => {
            const author = p.createdBy ? getMember(p.createdBy) : null;
            const authorFullName = author ? `${author.firstName} ${author.lastName}` : undefined;

            return {
                id: p.id,
                type: 'page',
                title: p.title || 'Untitled',
                data: p,
                computed: { creatorName: authorFullName },
            };
        });

        return preparePageItems(items, {
            sorting: pageView.allPagesSorting,
            search: pageView.search,
            collectionsFilter: pageView.allPagesCollections ?? undefined,
            creatorsFilter: pageView.allPagesCreators ?? undefined,
        });
    }, [pages, pageView, getMember]);

    const sharedPages = useMemo(() => {
        const items = shared.map((p): SharedPageItem => {
            return {
                id: p.id,
                type: 'shared-page',
                title: p.title || 'Untitled',
                data: {
                    title: p.title,
                    id: p.id,
                    createdAt: p.createdAt,
                    updatedAt: p.updatedAt,
                    sharedBy: p.sharedBy,
                    icon: p.icon,
                    role: p.role,
                },
                computed: {},
            };
        });

        return prepareSharedPageItems(items, {
            sorting: pageView.allPagesSorting,
            search: pageView.search,
        });
    }, [shared, pageView]);

    return useMemo(() => ({ spacePages, sharedPages }), [spacePages, sharedPages]);
};

export default useSortablePages;
