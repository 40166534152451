import React from 'react';
import EditableTableTitle, { EditableTableTitleProps } from './EditableTableTitle';
import { useTranslation } from 'react-i18next';

const TasksTableTitle = (props: Omit<EditableTableTitleProps, 'source' | 'texts' | 'testId'>) => {
    const { t } = useTranslation();
    return (
        <div className="flex items-center">
            <EditableTableTitle
                {...props}
                testId="tasks-table-title"
                source="tasks"
                showIcon={false}
                texts={{
                    title: t('common.tasks'),
                    placeholder: t('common.tasks'),
                    edit: t('common.edit'),
                    removeIcon: t('common.remove_icon'),
                    changeIcon: t('common.change_icon'),
                }}
            />
        </div>
    );
};

export default TasksTableTitle;
