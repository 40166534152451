import { stringToColor } from '@/../../shared/src/utils/Colors';
import { track } from '@/analytics';
import ChipInputField from '@/components/ChipInputField';
import { PagePermissionSelect } from '@/components/PagePermissionSelect';
import { usePageAccess } from '@/components/PagesPermissionsBySpaceProvider';
import { useUserContext } from '@/components/UserContext';
import { AVAILABLE_COLORS } from '@/constants';
import useDocumentCollaborators from '@/hooks/useDocumentCollaborators';
import useJumpToMemberCursor from '@/hooks/useJumpToMemberCursor';
import useMobile from '@/hooks/useMobile';
import { ShareSuggestion } from '@/types';
import * as api from '@saga/api';
import { UrlUtils } from '@saga/shared';
import classNames from 'classnames';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { Send, Users } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { ShareablePage } from '.';
import { useCurrentWorkspace, useWorkspaceContext } from '../WorkspaceContext';
import Spinner from '../loading/Spinner';
import PopOverModal from '../popover/PopOverModal';
import SingleCreateButtonWithSelect, { SelectedItemType } from '../popover/SingleCreateButtonWithSelect';
import { useSetSettings } from '../settings/SettingsProvider';
import Button from '../styled/Button';
import { PublishPageSection } from './PublishSection';
import { useSpaceAccess } from '@/hooks/useSpaceAccess';

const MappedSpaceRoleToPagePermissions = {
    [api.Role.Owner]: api.PagePermission.Admin,
    [api.Role.Member]: api.PagePermission.Admin,
    [api.Role.Viewer]: api.PagePermission.Viewer,
    [api.Role.Writer]: api.PagePermission.Writer,
    [api.PagePermission.Admin]: api.PagePermission.Admin,
};

function MemberAvatar({
    name,
    email,
    color,
    onClick,
    size = 'lg',
    showBorder = true,
}: {
    name?: string;
    email?: string;
    color: string;
    size?: 'xs' | 'sm' | 'md' | 'lg' | '5' | '2xlg';
    onClick?: (event: React.MouseEvent) => void;
    showBorder?: boolean;
    absolute?: boolean;
}) {
    const commonProps = {
        'data-testid': `shareModal:${name}`,
        className: classNames('not-sr-only relative uppercase font-bold select-none text-white shrink-0', {
            'h-6 w-6 text-xs rounded-md': size === 'lg',
            'h-[34px] w-[34px] text-xs rounded-md': size === '2xlg',
            'h-5 w-5 text-xs rounded-5': size === '5',
            'h-4 w-4 text-xxs rounded-md': size === 'sm',
            'h-[14px] w-[14px] text-xxs rounded-md': size === 'xs',
            '[height:1.15rem] [width:1.15rem] text-xxs rounded-md': size === 'md',
            'border border-white dark:border-saga-gray-800': showBorder,
        }),
        style: { backgroundColor: color },
    };

    const children = (
        <span className="text-center flex items-center justify-center inset-0 absolute">
            {name && <span className="leading-none">{(name as string)?.slice(0, 1)}</span>}
            {email && <span className="leading-none">{(email as string)?.slice(0, 1)}</span>}
        </span>
    );

    return (
        <div>
            {onClick ? (
                <button {...commonProps} onClick={onClick}>
                    {children}
                </button>
            ) : (
                <span {...commonProps}>{children}</span>
            )}
        </div>
    );
}

function PageMemberRow({
    name,
    email,
    id,
    permission,
    onAvatarClick,
    canEditPermission,
    isSpaceMember,
    options,
    isSelf = false,
    isPending = false,
    onPermissionChange,
    onRevokeInvite,
    closePopover,
}: {
    name?: string | null;
    id: string;
    canEditPermission: boolean;
    isSelf?: boolean;
    email?: string | null;
    isPending?: boolean;
    permission: api.PagePermission;
    isSpaceMember: boolean;
    options: api.PagePermission[];
    onAvatarClick: (memberId: string, event: React.MouseEvent<Element, MouseEvent>) => void;
    onPermissionChange: (permission: api.PagePermission) => void;
    onRevokeInvite?: (id: string) => void;
    closePopover: () => void;
}) {
    const isMobile = useMobile();
    const { t } = useTranslation();

    return (
        <div className="flex flex-col" key={id}>
            <div className="flex justify-between items-center py-2">
                <div
                    className={classNames('flex items-center justify-center', {
                        'pr-10': isMobile,
                    })}
                >
                    <div className="flex flex-col pr-3 pl-0">
                        <MemberAvatar
                            size="2xlg"
                            name={name ?? email ?? ''}
                            color={stringToColor(id, AVAILABLE_COLORS)}
                            onClick={(event) => onAvatarClick(id, event)}
                        />
                    </div>
                    <div className="flex flex-col items-start justify-center h-8">
                        <div className="flex">
                            <span className="text-sm font-normal">{name}</span>
                            {isSelf && (
                                <span className="text-saga-gray-500 dark:text-saga-gray-600 text-sm first-letter:uppercase pl-1">{`(${t('you')})`}</span>
                            )}
                        </div>
                        <span className="text-sm font-normal text-saga-gray-500 dark:text-saga-gray-600">{email}</span>
                        {isPending && (
                            <span className="text-saga-gray-500 dark:text-saga-gray-600 text-sm">
                                {t('settings.members.pending_invite')}
                            </span>
                        )}
                    </div>
                </div>

                {onRevokeInvite && (
                    <span
                        className="text-saga-gray-500 dark:text-saga-gray-600 text-sm cursor-pointer mx-2"
                        onClick={() => onRevokeInvite(id)}
                    >
                        {t('settings.members.revoke_invite')}
                    </span>
                )}
                <div
                    role="cell"
                    className={classNames('flex items-center text-saga-text-gray', {
                        'text-saga-gray-500 dark:text-saga-gray-600': permission === api.PagePermission.Admin,
                    })}
                >
                    {permission && (
                        <PagePermissionSelect
                            disabled={!canEditPermission || isSelf}
                            permission={permission}
                            isGuest={!isSpaceMember}
                            isSelf={isSelf}
                            onChange={onPermissionChange}
                            options={options}
                            closePopover={closePopover}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}

function InvitePageMembers({
    pageId,
    spaceId,
    members,
    invites,
    onExpanded,
    closePopover,
}: {
    pageId: string;
    spaceId: string;
    members: api.PageMember[];
    invites: api.PageInvite[];
    onExpanded: (isExpanded: boolean) => void;
    closePopover: () => void;
}) {
    const [permission, setPermission] = React.useState(api.PagePermission.Writer);
    const [emails, setEmails] = React.useState<string[]>([]);
    const [input, setInput] = React.useState<string>('');

    const { t } = useTranslation();

    const [inviteMembers, { loading }] = api.useInvitePageMembersMutation();

    React.useEffect(() => {
        onExpanded(!!emails?.length || !!input.length);
    }, [emails, onExpanded, input]);

    const handleInvitePageMembers = React.useCallback(
        (emails: string[]) => {
            inviteMembers({
                variables: {
                    input: { pageId, spaceId, newMembers: emails.map((email) => ({ email, role: permission })) },
                },
                update(cache, { data }) {
                    cache.writeQuery({
                        query: api.PageInvitesAndMembersDocument,
                        data: {
                            pageInvites: [...invites, ...(data?.invitePageMembers || [])],
                            pageMembers: members,
                        },
                        variables: { pageId, spaceId },
                    });
                },
                onCompleted() {
                    setEmails([]);
                },
            });
        },
        [inviteMembers, permission, pageId, spaceId, invites, members],
    );

    return (
        <div className="flex flex-1 flex-col">
            <div className="flex flex-row space-x-2 items-start">
                <div className="flex flex-1">
                    <ChipInputField
                        placeholder={t('top_menu.share.invite_guest')}
                        focusedPlaceholder={t('top_menu.share.type_email')}
                        onChange={setEmails}
                        validate={(value) => {
                            return (
                                !emails.some((email) => email === value) &&
                                !members.some((member) => member.userEmail === value) &&
                                !invites.some((invite) => invite.userEmail === value) &&
                                UrlUtils.validateEmail(value)
                            );
                        }}
                        items={emails}
                        onInputChange={setInput}
                    />
                </div>
                {emails.length > 0 && (
                    <PagePermissionSelect
                        permission={permission}
                        isGuest
                        isSelf={false}
                        onChange={setPermission}
                        options={[api.PagePermission.Writer, api.PagePermission.Viewer]}
                        closePopover={closePopover}
                    />
                )}
            </div>
            {(emails.length > 0 || input.length > 0) && (
                <div className="flex self-end my-2 shadow-button">
                    <Button.ShareModalPlain
                        disabled={loading || !emails.length}
                        onClick={() => {
                            handleInvitePageMembers(emails);
                            track('send-page-invites');
                        }}
                        withBorder
                    >
                        <Button.WithIconPadding>
                            <div className="flex justify-center whitespace-nowrap">
                                <div className="flex items-center space-x-1.5">
                                    {loading ? <Spinner size={14} /> : <Send size={14} />}
                                    <span className="text-sm">{t('settings.members.send_invites')}</span>
                                </div>
                            </div>
                        </Button.WithIconPadding>
                    </Button.ShareModalPlain>
                </div>
            )}
        </div>
    );
}

function MembersSection({
    isSpaceMember,
    members,
    invites,
    onAvatarClick,
    canEdit,
    handlePermissionChange,
    currentUser,
    isPrivate,
    loading,
    title,
    pageId,
    filter = '',
    closePopover,
}: {
    isSpaceMember: boolean;
    members: api.PageMember[];
    invites: api.Invite[] | api.PageInvite[];
    onAvatarClick: (memberId: string, event: React.MouseEvent<Element, MouseEvent>) => void;
    canEdit: boolean;
    handlePermissionChange: (permission: api.PagePermission, userId: string) => void;
    currentUser?: api.UserFragmentFragment;
    isPrivate?: boolean;
    filter?: string;
    loading: boolean;
    title: string;
    pageId: string;
    closePopover: () => void;
}) {
    const space = useCurrentWorkspace();
    const [revokeInvite] = api.useRevokePageInviteMutation();

    const filteredMembers = React.useMemo(
        () =>
            members.filter(
                (member) =>
                    member.firstName.includes(filter) ||
                    member.lastName.includes(filter) ||
                    (member.userEmail ? member.userEmail?.includes(filter) : true),
            ),
        [filter, members],
    );

    const filteredInvites = React.useMemo(
        () => invites.filter((member) => member.userEmail.includes(filter)),
        [filter, invites],
    );

    const handleRevokeInvite = React.useCallback(
        (id: string) => {
            const invite = invites.find((invite) => invite.id === id);

            if (!invite) return;

            revokeInvite({
                variables: { input: { id } },
                optimisticResponse: {
                    revokePageInvite: {
                        id,
                        role: api.PagePermission.Hidden,
                        userEmail: invite.userEmail,
                    },
                },
                update(cache) {
                    const cachedPageMembers = cache.readQuery<api.PageInvitesAndMembersQuery>({
                        query: api.PageInvitesAndMembersDocument,
                        variables: {
                            pageId: pageId,
                            spaceId: space.id,
                        },
                    });
                    cache.writeQuery({
                        query: api.PageInvitesAndMembersDocument,
                        data: {
                            pageMembers: cachedPageMembers?.pageMembers ?? [],
                            pageInvites: invites.filter((invite) => invite.id !== id),
                        },
                        variables: {
                            spaceId: space.id,
                            pageId,
                        },
                    });
                },
            });
        },
        [revokeInvite, invites, space.id, pageId],
    );

    return (
        <div>
            {(filteredInvites.length > 0 || filteredMembers.length > 0) && (
                <div className="flex flex-row items-center mt-3 mb-1">
                    <div className="font-medium text-xs uppercase text-saga-gray-500 pr-2">{title}</div>
                    {loading && <Spinner size={15} />}
                </div>
            )}
            {filteredMembers.map((member) => (
                <PageMemberRow
                    key={member.userId}
                    id={member.userId}
                    name={`${member.firstName} ${member.lastName}`}
                    email={member.userEmail}
                    onAvatarClick={onAvatarClick}
                    permission={member.permission}
                    canEditPermission={canEdit}
                    isSelf={member.userId === currentUser?.id}
                    onPermissionChange={(permission) => handlePermissionChange(permission, member.userId)}
                    isSpaceMember={isSpaceMember}
                    closePopover={closePopover}
                    options={[
                        ...(isSpaceMember ? [api.PagePermission.Admin] : []),
                        api.PagePermission.Writer,
                        api.PagePermission.Viewer,
                        api.PagePermission.Hidden,
                    ]}
                />
            ))}

            {filteredInvites?.map((member) => (
                <PageMemberRow
                    key={member.id}
                    id={member.id}
                    email={member.userEmail}
                    onAvatarClick={onAvatarClick}
                    permission={
                        isPrivate
                            ? api.PagePermission.Hidden
                            : MappedSpaceRoleToPagePermissions[member.role as api.Role]
                    }
                    canEditPermission={false}
                    isSelf={false}
                    isPending
                    onPermissionChange={(permission) => handlePermissionChange(permission, member.id)}
                    onRevokeInvite={canEdit && member.__typename === 'PageInvite' ? handleRevokeInvite : undefined}
                    isSpaceMember={false}
                    closePopover={closePopover}
                    options={[]}
                />
            ))}
        </div>
    );
}

export function ShareSection({
    page,
    isPageShared,
    setOpen,
}: {
    page: ShareablePage;
    isPageShared: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
}) {
    const [isPageInviteExpanded, setPageInviteExpanded] = useState(false);
    const collaborators = useDocumentCollaborators(page.id);
    const { currentUrlKey, currentWorkspace } = useWorkspaceContext();
    const setSettings = useSetSettings();
    const { t } = useTranslation();
    const jumpToCursor = useJumpToMemberCursor();
    const isMobile = useMobile();
    const { isOwner, isMember } = useSpaceAccess();
    const { isPageAdmin } = usePageAccess(page.id);
    const { user } = useUserContext();

    const { data } = api.useInvitesAndMembersQuery({
        variables: { urlKey: currentUrlKey },
        fetchPolicy: 'cache-and-network',
    });

    const { data: membersData, loading } = api.usePageInvitesAndMembersQuery({
        variables: { pageId: page.id, spaceId: currentWorkspace.id },
        fetchPolicy: 'cache-and-network',
    });

    const { data: sharedPageStatusData } = api.useSharedPageStatusQuery({
        variables: { input: { pageId: page.id } },
        fetchPolicy: 'cache-and-network',
    });

    const [updatePageSharedToSpace] = api.useUpdatePageSharedToSpaceMutation({
        refetchQueries: [api.PageInvitesAndMembersDocument],
    });
    const [updateUserPermission] = api.useUpdateUserPagePermissionMutation();

    const spaceInvites = data?.invites;
    const spaceMembers = data?.members;

    const pageMembers = membersData?.pageMembers;
    const pageInvites = membersData?.pageInvites;

    const spacePageMembers = React.useMemo(
        () =>
            pageMembers?.filter((member) => spaceMembers?.some((spaceMember) => member.userId === spaceMember.id)) ??
            [],
        [pageMembers, spaceMembers],
    );

    const guestPageMembers = React.useMemo(
        () =>
            pageMembers?.filter((member) => !spaceMembers?.some((spaceMember) => member.userId === spaceMember.id)) ??
            [],
        [pageMembers, spaceMembers],
    );

    const onAvatarClick = (memberId: string, event: React.MouseEvent<Element, MouseEvent>) => {
        const collaborator = collaborators.find((collaborator) => collaborator.user.userId === memberId);
        if (collaborator) {
            jumpToCursor(collaborator.cursor, event);
        }
    };

    const accessText = React.useMemo(() => {
        const isPrivateInSpace = sharedPageStatusData?.sharedPageStatus?.isPrivateInSpace ?? false;
        const memberIds = pageMembers
            ?.filter((pageMember) => pageMember.permission !== api.PagePermission.Hidden)
            .map((member) => member.userId);

        if (!memberIds) return '';

        if (isPrivateInSpace) {
            return memberIds?.length === 1
                ? t('top_menu.share.private_access')
                : t('top_menu.share.share_count', { count: memberIds.length - 1 });
        }

        if (spaceMembers?.some((spaceMember) => !memberIds.includes(spaceMember.id))) {
            // Not shared with all space members
            return t('top_menu.share.share_count', { count: memberIds.length - 1 });
        } else {
            return t('top_menu.share.share_with');
        }
    }, [pageMembers, spaceMembers, sharedPageStatusData, t]);

    const handlePermissionChange = React.useCallback(
        (permission: api.PagePermission, userId: string, userType: api.UserTypePagePermission) => {
            updateUserPermission({
                variables: {
                    input: {
                        userId: userId,
                        userType,
                        pageId: page.id,
                        spaceId: currentWorkspace.id,
                        permission,
                    },
                },
                optimisticResponse: {
                    updateUserPagePermission:
                        pageMembers?.map((member) => ({
                            ...member,
                            permission: member.userId === userId ? permission : member.permission,
                        })) ?? [],
                },
                update: (cache, { data }) => {
                    cache.writeQuery({
                        query: api.PageInvitesAndMembersDocument,
                        data: { pageMembers: data?.updateUserPagePermission, pageInvites },
                        variables: {
                            spaceId: currentWorkspace.id,
                            pageId: page.id,
                        },
                    });
                },
            });
        },
        [currentWorkspace, page, updateUserPermission, pageMembers, pageInvites],
    );

    const SELECT_OPTIONS: ShareSuggestion[] = [
        { type: 'share', kind: 'share', title: t('top_menu.share.share_with_members') },
        { type: 'share', kind: 'access', title: t('top_menu.share.private_access') },
    ];

    const onChangePagePermissions = (option: SelectedItemType) => {
        track(option.kind === 'access' ? 'set-page-private' : 'set-page-shared');

        updatePageSharedToSpace({
            variables: {
                input: {
                    isPrivateInSpace: option.kind === 'access',
                    urlKey: currentUrlKey,
                    pageId: page.id,
                },
            },
            ...(sharedPageStatusData?.sharedPageStatus && {
                optimisticResponse: {
                    updatePageSharedToSpace: {
                        ...sharedPageStatusData.sharedPageStatus,
                        isPrivateInSpace: option.kind === 'access',
                    },
                },
            }),
            update(cache) {
                const cachedPageMembers = cache.readQuery<api.PageInvitesAndMembersQuery>({
                    query: api.PageInvitesAndMembersDocument,
                    variables: {
                        pageId: page.id,
                        spaceId: currentWorkspace.id,
                    },
                });

                if (cachedPageMembers?.pageMembers) {
                    cache.writeQuery({
                        query: api.PageInvitesAndMembersDocument,
                        variables: {
                            pageId: page.id,
                            spaceId: currentWorkspace.id,
                        },
                        data: {
                            pageInvites: cachedPageMembers.pageInvites,
                            pageMembers: cachedPageMembers.pageMembers.map((pageMember) => ({
                                ...pageMember,
                                permission:
                                    option.kind === 'access' && pageMember.userId !== user?.id
                                        ? api.PagePermission.Hidden
                                        : MappedSpaceRoleToPagePermissions[
                                              spaceMembers?.find((spaceMember) => spaceMember.id === pageMember.userId)
                                                  ?.role ?? api.Role.Viewer
                                          ],
                            })),
                        },
                    });
                }
            },
        });
    };

    return (
        <div
            data-testid="share-modal-members-section"
            className={classNames('max-h-96 flex flex-col overflow-hidden', {
                'w-96': isMobile,
                'min-w-96': !isMobile,
            })}
        >
            <PopOverModal.ShareModalContent>
                <PublishPageSection page={page} isPageShared={isPageShared} />
                {(isOwner || isMember) && (
                    <InvitePageMembers
                        spaceId={currentWorkspace.id}
                        pageId={page.id}
                        members={pageMembers || []}
                        invites={pageInvites || []}
                        onExpanded={setPageInviteExpanded}
                        closePopover={() => setOpen(false)}
                    />
                )}
                {!isPageInviteExpanded && (
                    <>
                        <MembersSection
                            title={t('top_menu.share.members_with_access')}
                            pageId={page.id}
                            loading={loading}
                            members={spacePageMembers}
                            invites={spaceInvites ?? []}
                            onAvatarClick={onAvatarClick}
                            canEdit={isPageAdmin}
                            currentUser={user}
                            handlePermissionChange={(permission: api.PagePermission, userId: string) =>
                                handlePermissionChange(permission, userId, api.UserTypePagePermission.Member)
                            }
                            isPrivate={sharedPageStatusData?.sharedPageStatus?.isPrivateInSpace}
                            isSpaceMember
                            closePopover={() => setOpen(false)}
                        />

                        <MembersSection
                            title={t('top_menu.share.guests_with_access')}
                            pageId={page.id}
                            loading={loading}
                            members={guestPageMembers}
                            invites={pageInvites ?? []}
                            onAvatarClick={onAvatarClick}
                            canEdit={isPageAdmin}
                            currentUser={user}
                            handlePermissionChange={(permission: api.PagePermission, userId: string) =>
                                handlePermissionChange(permission, userId, api.UserTypePagePermission.Guest)
                            }
                            isPrivate={false}
                            isSpaceMember={false}
                            closePopover={() => setOpen(false)}
                        />

                        <div
                            className={classNames('flex py-3', {
                                'flex-col items-center': isMobile,
                                'justify-between': !isMobile,
                            })}
                        >
                            <div
                                className={classNames('flex items-center justify-center text-sm', {
                                    'mr-2.5 mb-3': isMobile,
                                    'shadow-button rounded': !loading,
                                })}
                            >
                                {loading && !pageMembers ? (
                                    <Spinner size={15} />
                                ) : (
                                    <SingleCreateButtonWithSelect
                                        disabled={!isPageAdmin}
                                        onSelect={onChangePagePermissions}
                                        placeholder={t('pages.new.placeholder_page')}
                                        selectOptions={SELECT_OPTIONS}
                                        icon={<Users className="flex-none" size={14} />}
                                        widthLg
                                    >
                                        {accessText}
                                    </SingleCreateButtonWithSelect>
                                )}
                            </div>
                            <button
                                className="flex items-center justify-center px-2 py-1.5 rounded hover:bg-saga-gray-250 dark:hover:bg-saga-gray-700 font-normal text-sm"
                                onClick={() => {
                                    setSettings('members');
                                    setOpen(false);
                                }}
                            >
                                {isPageAdmin ? t('top_menu.share.manage_members') : t('top_menu.share.view_members')}
                            </button>
                        </div>
                    </>
                )}
            </PopOverModal.ShareModalContent>
        </div>
    );
}
