import React from 'react';
import SettingsPane from './SettingsPane';
import Toggle from '../styled/Toggle';

export function SettingsToggle({
    title,
    description,
    checked,
    onChange,
    icon,
}: {
    title: string;
    description?: React.ReactNode;
    checked: boolean;
    onChange(value: boolean): void;
    icon?: React.ReactNode;
}) {
    return (
        <div className="flex items-center space-x-4 w-full">
            {icon && <div>{icon}</div>}
            <div className="truncate flex-auto">
                <h2>{title}</h2>
                {description && <SettingsPane.Small>{description}</SettingsPane.Small>}
            </div>
            <div>
                <Toggle accessibleLabel={title} checked={checked} onChange={onChange} />
            </div>
        </div>
    );
}
