import { CustomPromptType } from '@/../../api/src';
import { Bulb, AI, Minimize, Maximize, Pencil, Magic, Translate } from '@/assets/icons';
import { useUserContext } from '@/components/UserContext';
import { useCallback, useMemo } from 'react';
import { ArrowRight, BookOpen } from 'react-feather';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import aiPrompts from '../../../shared/src/aiPrompts.json';

export type AIActionSuggestion = {
    icon: React.ElementType;
    title: string;
    key:
        | 'rewrite'
        | 'continue'
        | 'summarize'
        | 'minimize'
        | 'maximize'
        | 'explain'
        | 'fix'
        | 'tone-concise'
        | 'tone-professional'
        | 'tone-casual'
        | 'tone-funny'
        | 'translate_en'
        | 'translate_de'
        | 'translate_es'
        | 'translate_zh'
        | 'translate_ua'
        | 'translate_ru'
        | 'translate_fr'
        | 'translate_pt'
        | 'translate_it'
        | 'translate_nl'
        | 'translate_ko'
        | 'translate_ja'
        | 'brainstorm'
        | 'outline'
        | 'email'
        | 'meeting'
        | 'blog'
        | 'social'
        | 'website'
        | 'cover-letter'
        | 'press'
        | 'job'
        | 'essay'
        | string;
};

export type AIActionCategory = {
    title: string;
    suggestions: (AIActionSuggestion & { hidden?: boolean })[];
};

type AIActionsData = { [key in CustomPromptType]: AIActionCategory[] };

const staticPromptSuggestions: AIActionsData = {
    COMMAND: [
        {
            title: 'ai.edit_selection',
            suggestions: [
                { title: i18next.t('ai.suggestion.rewrite'), icon: AI, key: 'rewrite' },
                { title: i18next.t('ai.suggestion.continue'), icon: ArrowRight, key: 'continue' },
                { title: i18next.t('ai.suggestion.fix'), icon: Pencil, key: 'fix' },
                { title: i18next.t('ai.suggestion.summarize'), icon: Bulb, key: 'summarize' },
                { title: i18next.t('ai.suggestion.minimize'), icon: Minimize, key: 'minimize' },
                { title: i18next.t('ai.suggestion.maximize'), icon: Maximize, key: 'maximize' },
                { title: i18next.t('ai.suggestion.explain'), icon: BookOpen, key: 'explain' },
            ],
        },
        {
            title: 'ai.change_tone',
            suggestions: [
                { title: i18next.t('ai.suggestion.tone_concise'), icon: AI, key: 'tone-concise' },
                { title: i18next.t('ai.suggestion.tone_professional'), icon: AI, key: 'tone-professional' },
                { title: i18next.t('ai.suggestion.tone_casual'), icon: AI, key: 'tone-casual' },
                { title: i18next.t('ai.suggestion.tone_funny'), icon: AI, key: 'tone-funny' },
            ],
        },
        {
            title: 'ai.translate',
            suggestions: [
                { title: i18next.t('ai.suggestion.translate_en'), icon: Translate, key: 'translate_en' },
                { title: i18next.t('ai.suggestion.translate_es'), icon: Translate, key: 'translate_es' },
                { title: i18next.t('ai.suggestion.translate_fr'), icon: Translate, key: 'translate_fr' },
                { title: i18next.t('ai.suggestion.translate_de'), icon: Translate, key: 'translate_de' },
                { title: i18next.t('ai.suggestion.translate_pt'), icon: Translate, key: 'translate_pt' },
                { title: i18next.t('ai.suggestion.translate_it'), icon: Translate, key: 'translate_it' },
                { title: i18next.t('ai.suggestion.translate_nl'), icon: Translate, key: 'translate_nl' },
                { title: i18next.t('ai.suggestion.translate_zh'), icon: Translate, key: 'translate_zh' },
                { title: i18next.t('ai.suggestion.translate_ko'), icon: Translate, key: 'translate_ko' },
                { title: i18next.t('ai.suggestion.translate_ja'), icon: Translate, key: 'translate_ja' },
                { title: i18next.t('ai.suggestion.translate_ua'), icon: Translate, key: 'translate_ua' },
                { title: i18next.t('ai.suggestion.translate_ru'), icon: Translate, key: 'translate_ru' },
            ],
        },
    ],
    PROMPT: [
        {
            title: 'ai.draft',
            suggestions: [
                { title: i18next.t('ai.suggestion.brainstorm'), icon: Magic, key: 'brainstorm' },
                { title: i18next.t('ai.suggestion.outline'), icon: Magic, key: 'outline' },
                { title: i18next.t('ai.suggestion.email'), icon: Magic, key: 'email' },
                { title: i18next.t('ai.suggestion.meeting'), icon: Magic, key: 'meeting' },
                { title: i18next.t('ai.suggestion.blog'), icon: Magic, key: 'blog' },
                { title: i18next.t('ai.suggestion.social'), icon: Magic, key: 'social' },
                { title: i18next.t('ai.suggestion.website'), icon: Magic, key: 'website' },
                { title: i18next.t('ai.suggestion.cover_letter'), icon: Magic, key: 'cover-letter' },
                { title: i18next.t('ai.suggestion.press'), icon: Magic, key: 'press' },
                { title: i18next.t('ai.suggestion.job'), icon: Magic, key: 'job' },
                { title: i18next.t('ai.suggestion.essay'), icon: Magic, key: 'essay' },
            ],
        },
    ],
};

const useAISuggestedPrompts = () => {
    const { userSettings } = useUserContext();
    const { t } = useTranslation();

    const generateAIPrompt = useCallback(
        (suggestion: AIActionSuggestion, type: CustomPromptType): string | undefined => {
            if (type === CustomPromptType.Command) {
                switch (suggestion.key) {
                    case 'rewrite':
                        return aiPrompts.command.rewrite;
                    case 'continue':
                        return aiPrompts.command.continue;
                    case 'summarize':
                        return aiPrompts.command.summarize;
                    case 'minimize':
                        return aiPrompts.command.minimize;
                    case 'maximize':
                        return aiPrompts.command.maximize;
                    case 'explain':
                        return aiPrompts.command.explain;
                    //TODO import user language and include it in the command.
                    case 'fix':
                        return aiPrompts.command.fix;
                    case 'tone-concise':
                        return aiPrompts.command.tone_concise;
                    case 'tone-professional':
                        return aiPrompts.command.tone_professional;
                    case 'tone-casual':
                        return aiPrompts.command.tone_casual;
                    case 'tone-funny':
                        return aiPrompts.command.tone_funny;
                    case 'translate_en':
                        return aiPrompts.command.translate_en;
                    case 'translate_de':
                        return aiPrompts.command.translate_de;
                    case 'translate_es':
                        return aiPrompts.command.translate_es;
                    case 'translate_zh':
                        return aiPrompts.command.translate_zh;
                    case 'translate_ua':
                        return aiPrompts.command.translate_ua;
                    case 'translate_ru':
                        return aiPrompts.command.translate_ru;
                    case 'translate_fr':
                        return aiPrompts.command.translate_fr;
                    case 'translate_pt':
                        return aiPrompts.command.translate_pt;
                    case 'translate_it':
                        return aiPrompts.command.translate_it;
                    case 'translate_nl':
                        return aiPrompts.command.translate_nl;
                    case 'translate_ko':
                        return aiPrompts.command.translate_ko;
                    case 'translate_ja':
                        return aiPrompts.command.translate_ja;
                    default:
                        return userSettings?.customAIPrompts.find((userCommand) => userCommand.id === suggestion.key)
                            ?.prompt;
                }
            }

            if (type === CustomPromptType.Prompt) {
                switch (suggestion.key) {
                    case 'brainstorm':
                        return t('ai.suggestion.brainstorm_about') as string;
                    case 'outline':
                        return t('ai.suggestion.outline_about') as string;
                    case 'email':
                        return t('ai.suggestion.email_about') as string;
                    case 'meeting':
                        return t('ai.suggestion.meeting_about') as string;
                    case 'blog':
                        return t('ai.suggestion.blog_about') as string;
                    case 'social':
                        return t('ai.suggestion.social_about') as string;
                    case 'website':
                        return t('ai.suggestion.website_about') as string;
                    case 'cover-letter':
                        return t('ai.suggestion.cover_letter_about') as string;
                    case 'press':
                        return t('ai.suggestion.press_about') as string;
                    case 'job':
                        return t('ai.suggestion.job_about') as string;
                    case 'essay':
                        return t('ai.suggestion.essay_about') as string;
                    default:
                        const prompt = userSettings?.customAIPrompts.find(
                            (userPrompt) => userPrompt.id === suggestion.key,
                        )?.prompt;

                        if (prompt) {
                            return `${prompt} `;
                        }

                        return undefined;
                }
            }

            return undefined;
        },
        [userSettings, t],
    );

    return useMemo(
        () => ({
            generateAIPrompt,
            staticPromptSuggestions: Object.fromEntries(
                Object.entries(staticPromptSuggestions).map(([k, v]) => [
                    k,
                    v.map((category) => ({
                        title: category.title,
                        suggestions: category.suggestions.map((suggestion) => ({
                            ...suggestion,
                            hidden: userSettings?.hiddenDefaultPrompts?.includes(suggestion.key) ?? false,
                        })),
                    })),
                ]),
            ),
            userPromptSuggestions: userSettings?.customAIPrompts,
        }),
        [generateAIPrompt, userSettings],
    );
};

export default useAISuggestedPrompts;
