import React from 'react';
import { SpaceOperations } from '@/../../shared/src';
import { useSpace } from '@/components/SpaceProvider';
import { useUserContext } from '@/components/UserContext';

export function useDeleteCollectionIfEmpty() {
    const { user } = useUserContext();
    const { space } = useSpace();

    return React.useCallback(
        (id: string) => {
            if (!user) {
                return;
            }

            SpaceOperations.deleteCollectionIfEmpty(space, id);
        },
        [space, user],
    );
}
