import { track } from '@/analytics';
import { useUserContext } from '@/components/UserContext';
import Input from '@/components/styled/Input';
import { useToastContext } from '@/components/toast/ToastContext';
import { SimpleTitleToast } from '@/components/toast/ToastLibrary';
import * as api from '@saga/api';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SettingsPane from './SettingsPane';

export function AccountSettings() {
    const { showToast } = useToastContext();
    const { user } = useUserContext();
    const [updateUser, { loading }] = api.useUpdateUserNameMutation({
        refetchQueries: [api.UserDataDocument],
    });
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [touched, setTouched] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        if (user) {
            setFirstName(user.data.firstName);
            setLastName(user.data.lastName);
        }
    }, [user]);

    if (!user) return null;

    return (
        <SettingsPane>
            <div>
                <SettingsPane.Title>{t('settings.profile.title')}</SettingsPane.Title>
                <SettingsPane.Small>{t('settings.profile.sub_title')}</SettingsPane.Small>
            </div>
            <SettingsPane.Content>
                <div>
                    <form
                        onSubmit={async (e) => {
                            e.preventDefault();
                            const res = await updateUser({
                                variables: {
                                    input: {
                                        firstName: firstName.trim(),
                                        lastName: lastName.trim(),
                                    },
                                },
                            });
                            if (res.data != null) {
                                showToast(() => <SimpleTitleToast>Profile updated</SimpleTitleToast>);
                            }
                            setTouched(false);
                            track('update-user-settings');
                        }}
                    >
                        <div className="pb-10">
                            <SettingsPane.Section>
                                <fieldset className="max-w-sm">
                                    <label>
                                        <div>{t('common.first_name')}</div>
                                        <Input
                                            type="text"
                                            placeholder={t('common.first_name') as string}
                                            value={firstName}
                                            onChange={(e) => {
                                                setFirstName(e.target.value);
                                                if (e.target.value.length > 0) {
                                                    setTouched(true);
                                                } else {
                                                    setTouched(false);
                                                }
                                            }}
                                        />
                                    </label>
                                </fieldset>
                            </SettingsPane.Section>
                            <SettingsPane.Section>
                                <fieldset className="max-w-sm">
                                    <label>
                                        <div>{t('common.last_name')}</div>
                                        <Input
                                            type="text"
                                            placeholder={t('common.last_name') as string}
                                            value={lastName}
                                            onChange={(e) => {
                                                setLastName(e.target.value);
                                                if (e.target.value.length > 0) {
                                                    setTouched(true);
                                                } else {
                                                    setTouched(false);
                                                }
                                            }}
                                        />
                                    </label>
                                </fieldset>
                            </SettingsPane.Section>
                            <SettingsPane.Section>
                                <div className="text-saga-gray-500">{t('settings.profile.your_email')}</div>
                                <div className="">{user.data.email || 'Not available'}</div>
                            </SettingsPane.Section>
                            <SettingsPane.Section>
                                <SettingsPane.Button.Default
                                    disabled={!touched || loading}
                                    type="submit"
                                    className="text-sm"
                                >
                                    {t('common.update')}
                                </SettingsPane.Button.Default>
                            </SettingsPane.Section>
                        </div>
                    </form>
                </div>
            </SettingsPane.Content>
        </SettingsPane>
    );
}
