import { track } from '@/analytics';
import { Lightning } from '@/assets/icons';
import { useAIChatOpen } from '@/components/aichat/AIChatContext';
import Tooltip from '@/components/popover/Tooltip';
import { getOS } from '@/lib/helpers';
import React from 'react';
import { useTranslation } from 'react-i18next';

function AIToggleChatButton() {
    const { setChatOpen, chatOpen } = useAIChatOpen();
    const { t } = useTranslation();
    const openChatShortcut = getOS() === 'Mac OS' ? '⌘ + L' : 'Ctrl + L';

    return (
        <Tooltip content={`${t('ai.chat.tooltip')} ${openChatShortcut}`} placement="top">
            <button
                onClick={() => {
                    track('ai-chat-open', { source: 'floating-button' });
                    setChatOpen(!chatOpen);
                }}
            >
                <div className="h-9 pl-2 pr-4 flex flex-row items-center justify-center shadow-button rounded-2xl bg-white dark:bg-saga-gray-1000 dark:border border-zinc-600">
                    <Lightning className="w-[18px] h-[18px]" color="#A57CFA" />
                    <div className={'text-sm font-medium text-saga-text dark:text-saga-text-darkmode'}>
                        {t('ai.chat.button')}
                    </div>
                </div>
            </button>
        </Tooltip>
    );
}

export default AIToggleChatButton;
