import React, { useState, useRef } from 'react';
import Onboarding from './Onboarding';
import * as api from '@saga/api';
import Input from '../styled/Input';
import useInterfaceSettings from '@/hooks/useInterfaceSettings';
import useMobile from '@/hooks/useMobile';
import { useCurrentWorkspace } from '../WorkspaceContext';
import { useTranslation } from 'react-i18next';

function NewWorkspaceModal() {
    const { t } = useTranslation();
    const { urlKey } = useCurrentWorkspace();
    const [updateSpace, updateSpaceResult] = api.useUpdateSpaceMetadataMutation({
        refetchQueries: [api.SpacesDocument],
        awaitRefetchQueries: true,
    });

    const [name, setName] = useState('');
    const nameRef = useRef<HTMLInputElement>(null);
    const isMobile = useMobile();
    const [, setSettings] = useInterfaceSettings();

    const onSubmit = async (e: React.FormEvent) => {
        if (isMobile) {
            setSettings({ fixedSidebar: false });
        }

        e.preventDefault();

        await updateSpace({ variables: { input: { urlKey, title: name } } });
    };

    const loading = updateSpaceResult.loading;

    React.useEffect(() => {
        nameRef.current?.focus();
    }, []);

    return (
        <Onboarding>
            <Onboarding.MainContainer>
                <Onboarding.Container>
                    <Onboarding.Logo />
                    <Onboarding.Heading>{t('common.create_workspace_title')}</Onboarding.Heading>
                    <Onboarding.Paragraph>{t('common.create_workspace_description')}</Onboarding.Paragraph>
                </Onboarding.Container>
                <Onboarding.Form onSubmit={onSubmit}>
                    <Input
                        id="name"
                        innerRef={nameRef}
                        type="text"
                        placeholder={t('common.create_workspace_placeholder')}
                        aria-label={t('common.create_workspace_placeholder')}
                        autoComplete="off"
                        autoFocus
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        disabled={loading}
                    />
                    <div className="space-y-2">
                        <Onboarding.Button disabled={loading || name.length === 0} type="submit">
                            {t('common.create')}
                        </Onboarding.Button>
                    </div>
                </Onboarding.Form>
            </Onboarding.MainContainer>
        </Onboarding>
    );
}

export default NewWorkspaceModal;
