import React from 'react';

function buildDocumentTitle(pageTitle: string) {
    if (pageTitle.length > 0) {
        return `${pageTitle} | Saga`;
    } else {
        return 'Untitled | Saga';
    }
}

export default function DocumentTitle({ children }: { children: string }) {
    React.useEffect(() => {
        const title = buildDocumentTitle(children);

        if (document.title !== title) {
            document.title = title;
        }
    }, [children]);

    return null;
}
