interface ChecklistItem {
    type: string;
    value?: string;
    checked?: boolean;
    children?: ChecklistItem[];
}

export function configureChecklistFromNotion(obj: any): ChecklistItem {
    if (obj.children && Array.isArray(obj.children)) {
        let newChildren: ChecklistItem[] = [];

        for (let i = 0; i < obj.children.length; i++) {
            const child = obj.children[i];

            if (child.type === 'text') {
                // Check and filter out '[ ] ' or '[x] '
                if (child.value === '[ ] ' || child.value === '[x] ') {
                    continue;
                }

                // Remove the prefix '[ ] ' or '[x] ' and retain the rest of the text
                if (child.value.startsWith('[ ] ') || child.value.startsWith('[x] ')) {
                    child.value = child.value.slice(4); // Assuming '[ ] ' and '[x] ' are 4 characters long
                }
            }

            // Recursively process children
            if (child.children) {
                newChildren.push({ ...child, children: configureChecklistFromNotion(child).children });
            } else {
                newChildren.push(child);
            }
        }

        return { ...obj, children: newChildren };
    }

    return obj;
}

export const isChecklistItemFromNotion = (value: string) =>
    ['[x] ', '[ ] '].some((prefix) => value === prefix || value.startsWith(prefix));

export const getCheckedStateFromNotion = (value: string) => value.startsWith('[x] ');
