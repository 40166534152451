import React from 'react';
import classNames from 'classnames';
import { CornerDownLeft, Mail } from 'react-feather';
export default function ChipInputField({
    placeholder,
    focusedPlaceholder,
    onChange,
    onInputChange,
    validate,
    items,
}: {
    placeholder: string;
    focusedPlaceholder: string;
    onChange: (items: string[]) => void;
    onInputChange?: (input: string) => void;
    items: string[];
    validate?: (value: string) => boolean;
}) {
    const [value, setValue] = React.useState<string>('');

    React.useEffect(() => {
        onInputChange?.(value);
    }, [value, onInputChange]);

    const isValid = React.useMemo(() => {
        if (!validate) return true;
        return validate(value);
    }, [validate, value]);

    const addChip = React.useCallback(
        (value: string) => {
            const newItems = [...items, value];
            if (isValid) {
                onChange(newItems);
                setValue('');
            }
        },
        [isValid, items, onChange],
    );

    const handleKeyDown = React.useCallback(
        (e: React.KeyboardEvent<HTMLButtonElement>) => {
            if (e.key === 'Enter' || e.key === ' ') {
                e.preventDefault();
                addChip(value);
            }
        },
        [addChip, value],
    );

    return (
        <div className="flex flex-row flex-wrap items-center border border-saga-gray-200 dark:border-saga-gray-700 rounded-md px-2 w-0 min-w-full">
            {items.map((item) => (
                <Chip key={item} value={item} />
            ))}
            <div className="relative flex flex-1">
                <input
                    className="flex flex-1 bg-transparent min-w-40"
                    onChange={({ currentTarget }) => {
                        setValue(currentTarget.value);
                    }}
                    placeholder={items.length ? focusedPlaceholder : placeholder}
                    value={value}
                    type="email"
                    onPaste={(e) => {
                        e.preventDefault();
                        const plainText = e.clipboardData.getData('text');
                        const filtered = plainText
                            .split(/\s+/)
                            .map((item) => item.trim())
                            .filter((item) => (validate ? validate(item) : true));

                        const newItems = [...items, ...filtered];

                        onChange(newItems);
                    }}
                    onKeyDown={(e) => {
                        if (e.key === 'Backspace' && !value?.length) {
                            const newItems = items.splice(0, items.length - 1);

                            onChange(newItems);
                            e.preventDefault();
                        }

                        handleKeyDown(e as any);
                    }}
                />
                {value.length > 0 && (
                    <div className="flex flex-row items-center justify-between w-52 absolute -bottom-[46px] left-0 text-sm shadow rounded p-1 h-10 bg-white dark:bg-saga-gray-900">
                        <button
                            onKeyDown={handleKeyDown}
                            onClick={() => addChip(value)}
                            className={classNames(
                                'flex flex-1 px-2 max-w-full h-8 items-center rounded dark:bg-inherit border-saga-gray-200 dark:border-saga-gray-700 space-x-2 cursor-default',
                                {
                                    'hover-trigger hover:bg-saga-gray-200 dark:hover:bg-saga-gray-700 cursor-pointer':
                                        isValid,
                                    'text-saga-gray-400': !isValid,
                                },
                            )}
                        >
                            <div className="flex flex-1 flex-row items-center space-x-2 truncate">
                                <Mail size={14} />
                                <div className="flex-1 text-left truncate">{isValid ? value : `${value}...`}</div>
                            </div>

                            <CornerDownLeft size={14} />
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
}

function Chip({ value }: { value: string }) {
    return (
        <div className="my-auto flex px-1.5 items-center bg-transparent border border-saga-gray-200 dark:border-saga-gray-700 dark:hover:bg-saga-gray-1000 rounded-md focus:outline-none text-sm mr-2 mt-1.5">
            {value}
        </div>
    );
}
