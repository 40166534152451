import { SagaEditor, ensureNonNull } from '@saga/shared';
import React from 'react';

const BlockPluginContext = React.createContext<SagaEditor.Plugins.BlockPlugin[]>([]);

export const useBlockPlugins = () =>
    ensureNonNull(React.useContext(BlockPluginContext), 'useBlockPlugins must be used inside BlockPluginProvider');

export function BlockPluginProvider({
    children,
    plugins,
}: {
    children: React.ReactNode;
    plugins: SagaEditor.Plugins.BlockPlugin[];
}) {
    return <BlockPluginContext.Provider value={plugins}>{children}</BlockPluginContext.Provider>;
}
