import React from 'react';
import { VariableSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';

type Props<Item extends { type: string }> = {
    items: Item[];
    renderItem(item: Item, index: number): React.ReactNode;
    divider?: React.ReactNode;
    labels: Record<Item['type'], React.ReactNode>;
    itemClassName?: string;
    maxHeight: number;
};

export default React.forwardRef<VariableSizeList, Props<any>>(function ItemGroups<Item extends { type: string }>(
    { items, renderItem, divider, labels, itemClassName, maxHeight }: Props<Item>,
    ref: React.Ref<VariableSizeList>,
) {
    const rowHeights = React.useRef<{ [key: number]: number }>({});
    const internalRef = React.useRef<VariableSizeList>(null);

    // Combine the refs to ensure we always have a reference
    const listRef = (ref || internalRef) as React.RefObject<VariableSizeList>;

    const [totalHeight, setTotalHeight] = React.useState<number>(0);

    const getRowHeight = React.useCallback((index: number) => rowHeights.current[index] ?? 36, [rowHeights]);
    const setRowHeight = React.useCallback(
        (index: number, height: number) => {
            if (height !== rowHeights.current[index]) {
                listRef.current?.resetAfterIndex(index);
            }

            rowHeights.current[index] = height;
            setTotalHeight(items.reduce((acc, _, index) => acc + getRowHeight(index), 0) + 8);
        },
        [rowHeights, items, getRowHeight, listRef],
    );

    return (
        <AutoSizer disableHeight>
            {({ width }) => (
                <VariableSizeList
                    ref={listRef}
                    itemSize={getRowHeight}
                    itemCount={items.length}
                    width={width}
                    height={Math.min(maxHeight, totalHeight)}
                >
                    {({ index, style }) => {
                        const typesAreDifferent = items[index - 1]?.type !== items[index]?.type;
                        const label = labels[items[index]?.type as Item['type']];
                        const divide = index != 0 && typesAreDifferent;

                        return (
                            <Row
                                className={itemClassName}
                                index={index}
                                style={style}
                                renderItem={(index) => renderItem(items[index], index)}
                                onHeightChange={(height) => setRowHeight(index, height)}
                                divider={divide ? divider : undefined}
                                label={typesAreDifferent ? label : undefined}
                            />
                        );
                    }}
                </VariableSizeList>
            )}
        </AutoSizer>
    );
});

function Row({
    index,
    style,
    divider,
    label,
    renderItem,
    onHeightChange,
    className,
}: {
    index: number;
    style: React.CSSProperties;
    divider?: React.ReactNode;
    label?: React.ReactNode;
    renderItem(index: number): React.ReactNode;
    onHeightChange: (height: number) => void;
    className?: string;
}) {
    const ref = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        if (ref.current?.clientHeight) {
            onHeightChange(ref.current.clientHeight);
        }
    }, [ref, onHeightChange]);

    return (
        <div key={index} style={style} className={className}>
            <div ref={ref}>
                {divider}
                {label}
                {renderItem(index)}
            </div>
        </div>
    );
}
