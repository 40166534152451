import ManagePageCollectionsPopOver from '@/components/popover/ManageCollectionsPopOver';
import classNames from 'classnames';
import React, { useRef, useState } from 'react';
import { Edit } from 'react-feather';

import { CollectionIcon } from '@/components/icons';
import useMobile from '@/hooks/useMobile';
import { Collection } from '@saga/shared';
import Button from '../styled/Button';

const ManageCollectionsInput = ({
    currentCollections,
    availableCollections,
    excludeCollections,
    onCollectionClick,
    onCreate,
    onSelect,
    onRemove,
    canEdit,
    canWrap,
    placeholder,
    canHighlight,
    zIndex,
    maxWidth = 800,
    disabled,
    variant,
}: {
    currentCollections: Collection[];
    availableCollections: Collection[];
    onCollectionClick: (id: string, event: React.MouseEvent) => void;
    onCreate: (title: string) => void;
    onSelect: (id: string) => void;
    onRemove: (id: string) => void;
    canEdit: boolean;
    excludeCollections?: string[];
    placeholder?: string;
    canHighlight?: boolean;
    zIndex?: number;
    maxWidth?: number;
    disabled?: boolean;
    canWrap?: boolean;
    variant?: string;
}) => {
    const ref = useRef<HTMLDivElement | null>(null);
    const buttonContainer = useRef<HTMLDivElement | null>(null);

    const [showMenu, setShowMenu] = useState<boolean>(false);
    const [maxIndex, setMaxIndex] = React.useState(Infinity);
    const isMobile = useMobile();

    React.useLayoutEffect(() => {
        setMaxIndex(Infinity);

        if (buttonContainer.current) {
            let width = 0;
            let index = 0;
            buttonContainer.current.childNodes.forEach((node) => {
                if (node instanceof HTMLElement) {
                    const rect = node.getBoundingClientRect();

                    width += rect.width;
                    if (width <= maxWidth) {
                        index++;
                    }
                }
            });

            setMaxIndex(index);
        }
    }, [currentCollections.length, maxWidth]);

    return (
        <>
            <div
                ref={ref}
                data-testid="manage-collections-input"
                className={classNames('min-h-[26px] hover-trigger leading-normal flex items-center', {
                    'cursor-pointer': canEdit,
                    'hover:bg-saga-gray-200 dark:hover:bg-zinc-200/10 px-1 truncate rounded':
                        canEdit && currentCollections.length > 0,
                    'hover:bg-saga-gray-200 dark:hover:bg-zinc-200/10 rounded':
                        canEdit && currentCollections.length === 0 && !placeholder,
                    'shadow-lightblue': canHighlight && showMenu && (!placeholder || currentCollections.length > 0),
                })}
                onClick={() => canEdit && setShowMenu(!showMenu)}
            >
                <div
                    ref={buttonContainer}
                    className={classNames('flex items-center gap-2', {
                        'flex-wrap': canWrap,
                    })}
                >
                    {currentCollections.map((collection, i) => (
                        <div key={collection.id} className={classNames({ 'opacity-0 absolute': i >= maxIndex })}>
                            <Button.Pill
                                onClick={(e) => {
                                    if (disabled) return;
                                    e.stopPropagation();
                                    setShowMenu(false);
                                    onCollectionClick(collection.id, e);
                                }}
                            >
                                <CollectionIcon icon={collection.icon} size={12} badgeSize={10} />
                                <span className="truncate leading-normal pl-2">{collection.title}</span>
                            </Button.Pill>
                        </div>
                    ))}
                </div>
                {maxIndex < currentCollections.length && (
                    <div className="px-1 rounded-md border border-transparent text-sm">
                        +{currentCollections.length - maxIndex}
                    </div>
                )}
                {!disabled && canEdit && (!showMenu || canWrap) && currentCollections.length > 0 && (
                    <div
                        className={classNames(
                            'px-1 my-1 rounded-md border border-transparent flex items-center text-sm self-start',
                            {
                                'hover-target': !isMobile,
                            },
                        )}
                    >
                        <button className="cursor-pointer" onClick={() => setShowMenu(true)}>
                            <Edit className="stroke-saga-gray-500 rounded focus:outline-none w-4 h-4" />
                        </button>
                    </div>
                )}
                {!disabled && canEdit && currentCollections.length === 0 && (
                    <div className="inline-block w-full">
                        {placeholder && (
                            <Button.DashedPill
                                onClick={() => {
                                    setShowMenu(true);
                                }}
                                variant={variant}
                            >
                                <span className="my-auto whitespace-nowrap">{placeholder}</span>
                            </Button.DashedPill>
                        )}
                        {!placeholder && ' '}
                    </div>
                )}
            </div>
            {!disabled && showMenu && (
                <ManagePageCollectionsPopOver
                    isOpen={showMenu}
                    setIsOpen={setShowMenu}
                    attachToRef={ref}
                    currentCollections={currentCollections}
                    availableCollections={availableCollections}
                    excludeCollections={excludeCollections}
                    onCollectionClick={onCollectionClick}
                    onCreate={onCreate}
                    onSelect={onSelect}
                    onRemove={onRemove}
                    zIndex={zIndex}
                ></ManagePageCollectionsPopOver>
            )}
        </>
    );
};

export default ManageCollectionsInput;
