import * as Y from 'yjs';
import { SafeSpace } from '../types';
import { unsafeRight } from '../io-ts-js';
import { findYArrayIndex } from '../yjs-utils';
import { removePinnedById, transactInSpace } from '.';

export default function deleteTask(space: SafeSpace, id: string) {
    const yTasks = unsafeRight(space.get('tasks'));

    const index = findYArrayIndex(yTasks.array as Y.Array<any>, (a) => {
        return a.get('id') === id;
    });

    if (index === -1) {
        throw new Error(`Cannot delete Task: Task with id ${id} not found`);
    }

    transactInSpace(space, () => {
        const yTask = yTasks.array.get(index) as Y.Map<unknown>;
        yTask.set('archivedAt', new Date().toISOString());
        removePinnedById(space, id);
    });
}
