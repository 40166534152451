import { isLiveBlockSource, isSagaElement, mapBlock, Page, SagaElement, isFile, isImage, Task } from '../types';

import { PAGE_SCHEMA_VERSION } from '../constants';
import IdMap from '../utils/IdMap';

export function transformMoveEntity(
    entityType: string,
    entity: Page | Task,
    sourceSpaceUrlKey: string,
    targetSpaceUrlKey: string,
): Page | Task {
    const idMap = new IdMap();
    const mappedBlocks = entity.blocks.map((block) => {
        return mapBlock(block as SagaElement, (node) => {
            if (isLiveBlockSource(node)) {
                return node.children;
            }
            if (isSagaElement(node) && (isFile(node) || isImage(node))) {
                const transformedNode = { ...node, id: idMap.swapId(node.id) };
                if (node.url) {
                    transformedNode.url = node.url.replace(sourceSpaceUrlKey, targetSpaceUrlKey);
                }
                return transformedNode;
            }
            if (isSagaElement(node)) {
                return { ...node, id: idMap.swapId(node.id) };
            }
            return node;
        });
    });

    return {
        ...entity,
        ...(entityType === 'page' ? { version: PAGE_SCHEMA_VERSION } : {}),
        createdAt: entity.createdAt,
        updatedAt: entity.updatedAt,
        id: idMap.swapId(entity.id),
        blocks: mappedBlocks.flat(),
    };
}
