import { BlockBuilder } from '../..';
import { newBlankPage } from '../../pageUtils';

export function newSagaAiPagePt(collectionIds: string[]) {
    return newBlankPage({
        title: 'Saga IA',
        icon: {
            type: 'emoji',
            colons: ':sparkles:',
        },
        collections: collectionIds,
        blocks: [
            BlockBuilder.title('Saga IA'),
            BlockBuilder.headingThree('Gere conteúdo e obtenha respostas com a IA'),
            BlockBuilder.image('https://saga-public.s3.amazonaws.com/1-ai-assistant-generating-popover-launch.webp'),
            BlockBuilder.checkListItem([BlockBuilder.text('Crie um rascunho usando a IA.')]),
            BlockBuilder.listItem([
                BlockBuilder.text('Clique no '),
                BlockBuilder.text('botão de IA', { code: true }),
                BlockBuilder.text(' dentro do editor.'),
            ]),
            BlockBuilder.listItem([
                BlockBuilder.text('Alternativamente, pressione '),
                BlockBuilder.text('@', { code: true }),
                BlockBuilder.text(' ou '),
                BlockBuilder.text('/', { code: true }),
                BlockBuilder.text(' e selecione Saga IA no menu de autocompletar.'),
            ]),
            BlockBuilder.paragraph(),
            BlockBuilder.paragraph(),
            BlockBuilder.image('https://saga-public.s3.amazonaws.com/2-ai-assistant-select-or-type-prompt.webp'),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Selecione uma opção da lista, ou escreva seu próprio comando.'),
            ]),
            BlockBuilder.paragraph(),
            BlockBuilder.paragraph(),
            BlockBuilder.image('https://saga-public.s3.amazonaws.com/3-ai-assistant-blog-post-generation.webp'),
            BlockBuilder.checkListItem([
                BlockBuilder.text(
                    'Itere sobre o resultado, adicione mais detalhes e veja o histórico da sua conversa com a IA.',
                ),
            ]),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Salve seu comando para reutilizá-lo mais tarde, pressionando o botão ☆.'),
            ]),
            BlockBuilder.paragraph(),
            BlockBuilder.paragraph(),
            BlockBuilder.headingThree('Edite com a IA'),
            BlockBuilder.image('https://saga-public.s3.amazonaws.com/4-ai-assistant-commands-pallette.webp'),
            BlockBuilder.checkListItem([
                BlockBuilder.text(
                    'Peça à Saga IA para reescrever seu texto, corrigir gramática, torná-lo mais curto, mudar o tom de voz, traduzir e mais.',
                ),
            ]),
            // todo - indent
            BlockBuilder.listItem([
                BlockBuilder.text('Selecione qualquer texto e clique em "Saga IA" na barra de ferramentas para abrir o menu da IA.'),
            ]),
            BlockBuilder.listItem([BlockBuilder.text('Selecione uma opção da lista, ou escreva seu próprio comando.')]),
            BlockBuilder.paragraph(),
            BlockBuilder.paragraph(),
            BlockBuilder.image('https://saga-public.s3.amazonaws.com/5-ai-assistant-edit-the-text.webp'),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Pergunte à Saga IA o que fazer a seguir com a resposta, adicione detalhes e itere.'),
            ]),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Substitua o conteúdo inicial com a resposta da IA ou insira-a abaixo.'),
            ]),
            BlockBuilder.paragraph(),
            BlockBuilder.paragraph(),
            BlockBuilder.divider(),
            BlockBuilder.headingThree('Guias e FAQs'),
            BlockBuilder.paragraph([
                BlockBuilder.text(
                    'Visite os guias de Saga IA para mais dicas e truques sobre como usar a IA dentro do seu espaço de trabalho, ou explore as 👋 Noções básicas do Saga para continuar aprendendo e aprimorando suas habilidades.',
                ),
            ]),
            BlockBuilder.rawPrettyLink('https://saga.so/guides-category/saga-ai', {
                _tag: 'loaded',
                description:
                    'Aprenda a usar a Saga IA - o assistente alimentado por IA dentro do seu espaço de trabalho. Seja criativo e mais produtivo com um assistente de IA.',
                title: '📍 Saga IA – Guias da Saga',
                logo: 'https://saga.so/thumbnail.png',
            }),
            BlockBuilder.paragraph(),
        ],
    });
}
