import React, { useRef, useState, useEffect } from 'react';
import classNames from 'classnames';
import { FilePlus } from 'react-feather';
import {
    isTitle,
    stringifyInline,
    Page,
    PageContent,
    pageD,
    unsafeRight,
    newBlankPage,
    isSagaText,
    Normalization,
    mapBlocks,
    isLiveBlockSource,
    Converter,
    SagaLocation,
    isSagaElement,
    SagaEditor,
    transformBlocksToSharedType,
    Stateless,
    Task,
    taskD,
    newBlankTask,
    SpaceOperations,
} from '@saga/shared';
import { v4 as uuid } from 'uuid';
import { track } from '@/analytics';
import Button from './styled/Button';
import { useCurrentSearchMap, useSpace } from '@/components/SpaceProvider';
import { locationToUrl } from './PageNavigationProvider';
import { useHistory } from 'react-router';
import { useCurrentWorkspace } from './WorkspaceContext';
import { createPage, createTask } from '@/utils/documentUtils';
import { useTranslation } from 'react-i18next';
import Modal from './Modal';
import { LoadingSpinner } from '@/assets/icons';
import { partition } from 'lodash';
import ManageCollectionsInput from './popover/ManageCollectionsInput';
import { useCollectionsSnapshot } from '@/hooks/SpaceHooks';
import { useTaskFilters } from '@/hooks/useTaskFilters';

const MAXIMUM_ALLOWED_FILESIZE = 2_097_152;
const MAXIMUM_ALLOWED_TOTALSIZE = 154_194_304;
const SUPPORTED_EXTENSIONS = ['json', 'md'];

function readFileAsString(file: File): Promise<string | null> {
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.addEventListener('load', (event) => {
            if (event.target?.result) {
                resolve(event.target.result.toString());
            } else {
                resolve(null);
            }
        });
        reader.readAsText(file);
    });
}

function isImportableFileType(type: string) {
    return type === '' || type.startsWith('text') || type === 'application/json';
}

function filterFiles(files: File[]) {
    return partition(
        files,
        (file) =>
            isImportableFileType(file.type) && file.size <= MAXIMUM_ALLOWED_FILESIZE && !file.name.startsWith('.'),
    );
}

const OpenImportModalContext = React.createContext<() => void>(() => {});

function preparePageBlocks(blocks: any[]) {
    const cleanBlocksWithNewIds = mapBlocks(blocks, (block) => {
        if (isSagaText(block)) {
            return block;
        }

        if (isLiveBlockSource(block)) {
            return block.children;
        }

        return { ...block, id: uuid() };
    }).filter(isSagaElement);

    return Normalization.normalizeBlocks(cleanBlocksWithNewIds, SagaEditor.Normalizers.spaceNormalizers)
        .children as PageContent;
}

const CollectionsSelector = ({
    initialSelected,
    onChange,
}: {
    initialSelected: string[];
    onChange: (collections: string[]) => void;
}) => {
    const { space } = useSpace();
    const [selectedCollections, setSelectedCollections] = useState<string[]>(initialSelected || []);
    const collections = useCollectionsSnapshot();
    const { t } = useTranslation();

    const currentCollections = collections.filter((c) => selectedCollections.includes(c.id));
    const availableCollections = collections.filter((c) => !selectedCollections.includes(c.id));

    useEffect(() => {
        onChange(selectedCollections);
    }, [selectedCollections, onChange]);

    return (
        <ManageCollectionsInput
            canEdit={true}
            currentCollections={currentCollections}
            availableCollections={availableCollections}
            onCollectionClick={() => {}}
            onCreate={(title) => {
                const newCollection = SpaceOperations.createCollection(space, title);
                setSelectedCollections((prev) => [...prev, newCollection.id]);
            }}
            onSelect={(id) => {
                setSelectedCollections((prev) => [...prev, id]);
            }}
            onRemove={(id) => {
                setSelectedCollections((prev) => prev.filter((collectionId) => collectionId !== id));
            }}
            placeholder={t('common.plus_collection') as string}
            maxWidth={500}
            zIndex={1000}
            variant="import-button"
        />
    );
};

const ImportTypeSelector = ({
    selectedType,
    onChange,
}: {
    selectedType: 'page' | 'task';
    onChange: (type: 'page' | 'task') => void;
}) => {
    const [type, setType] = useState(selectedType);
    const { t } = useTranslation();

    useEffect(() => {
        onChange(type);
    }, [type, onChange]);

    return (
        <div className="flex flex-col gap-2 mt-2">
            <div className="flex w-full bg-white p-1 rounded text-xs leading-5 text-saga-gray-600 dark:bg-saga-gray-1000 dark:text-saga-gray-500 mb-2">
                <span
                    onClick={() => setType('page')}
                    className={classNames('w-6/12 text-center cursor-pointer rounded px-4 py-1', {
                        'text-saga-black bg-saga-gray-200 dark:bg-saga-gray-800 dark:text-white': type === 'page',
                    })}
                >
                    {t('settings.export_import.import_modal.page')}
                </span>
                <span
                    onClick={() => setType('task')}
                    className={classNames('w-6/12 text-center cursor-pointer rounded px-4 py-1', {
                        'text-saga-black bg-saga-gray-200 dark:bg-saga-gray-800 dark:text-white': type === 'task',
                    })}
                >
                    {t('settings.export_import.import_modal.task')}
                </span>
            </div>
        </div>
    );
};

const ImportModalDescription = ({
    importType,
    setImportType,
    selectedCollections,
    setSelectedCollections,
}: {
    importType: 'page' | 'task';
    setImportType: (type: 'page' | 'task') => void;
    selectedCollections: string[];
    setSelectedCollections: (collections: string[]) => void;
}) => {
    const { t } = useTranslation();

    return (
        <>
            <div className="text-saga-text dark:text-saga-gray-200 w-fit mb-4">
                <ImportTypeSelector selectedType={importType} onChange={setImportType} />
            </div>
            <div className="text-saga-text dark:text-saga-gray-200 w-fit">
                <div className="font-medium mb-4">{t('settings.export_import.import_modal.add_to_collection')}</div>
                <CollectionsSelector initialSelected={selectedCollections} onChange={setSelectedCollections} />
            </div>
        </>
    );
};

function ImportFileDropzone({ children }: { children: React.ReactNode }) {
    const fileUploadRef = useRef<HTMLInputElement>(null);
    const modalRef = useRef<HTMLDivElement>(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [isDragging, setIsDragging] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [showImportModal, setShowImportModal] = useState<{
        title: string;
        description?: string | React.ReactNode;
        type?: 'pre-import' | 'results';
        filesToProcess?: File[];
    } | null>(null);
    const { space, provider } = useSpace();
    const searchMap = useCurrentSearchMap();
    const history = useHistory();
    const { urlKey } = useCurrentWorkspace();
    const { t } = useTranslation();
    const taskFilters = useTaskFilters();

    const [selectedCollections, setSelectedCollections] = useState<string[]>([]);
    const [importType, setImportType] = useState<'page' | 'task'>('page');

    const onDragEnter = (e: React.DragEvent) => {
        const items = Array.from(e.dataTransfer.items).filter((item) => item.kind === 'file');

        if (items.length > 0 && items.every((item) => isImportableFileType(item.type))) {
            setIsDragging(true);
            e.preventDefault();
        }
    };

    const onDragLeave = (e: React.DragEvent) => {
        const target = e.target as HTMLElement;
        if (modalRef.current?.contains(target)) {
            setIsDragging(false);
        }
    };

    const processMarkdown = async (fileTitle: string, content: string): Promise<Page | Task> => {
        const type = content.includes('-{{type:task}}') ? 'task' : 'page';

        const result = await Converter.mdToSaga(content.replace(`-{{type:${type}}}`, ''), {
            addTitle: true,
            fileTitle,
            transform(node) {
                return { ...node, id: uuid() };
            },
        });
        const title = (() => {
            const firstChild = result.children[0];
            if (isTitle(firstChild)) {
                return stringifyInline(firstChild.children);
            }

            return '';
        })();

        return type === 'task'
            ? newBlankTask({ title, blocks: result.children as PageContent })
            : newBlankPage({ title, blocks: result.children as PageContent });
    };

    const processJSON = (content: string): Page | Task => {
        const { blocks, type, ...entity } = JSON.parse(content);
        const preparedBlocks = preparePageBlocks(blocks);

        if (type === 'task') {
            const decoded = unsafeRight(taskD.decode(entity));
            return newBlankTask({
                assignee: decoded.assignee,
                title: decoded.title,
                state: decoded.state,
                dueDate: decoded.dueDate,
                completedDate: decoded.completedDate,
                priority: decoded.priority,
                labels: decoded.labels,
                blocks: preparedBlocks,
            });
        } else {
            const pageDecoded = unsafeRight(pageD.decode(entity));
            return newBlankPage({
                title: pageDecoded.title,
                aliases: pageDecoded.aliases,
                isTemplate: pageDecoded.isTemplate,
                settings: pageDecoded.settings,
                collections: pageDecoded.collections,
                createdFromTemplate: pageDecoded.createdFromTemplate,
                icon: pageDecoded.icon,
                wordCount: pageDecoded.wordCount,
                version: pageDecoded.version,
                blocks: preparedBlocks,
            });
        }
    };

    const processFiles = async (files: File[], collections: string[], importType: 'page' | 'task') => {
        const totalSize = files.reduce((acc, file) => acc + file.size, 0);

        const [suitableFiles, notSuitableFiles] =
            totalSize < MAXIMUM_ALLOWED_TOTALSIZE ? filterFiles(files) : [[], files];

        const successfulyImportedFiles: string[] = [];
        const unsuccessfulyImportedFiles: string[] = [...notSuitableFiles.map((file) => file.name)];

        let documentsToCreate: (Page | Task)[] = [];

        for (let i = 0; i < suitableFiles.length; i++) {
            const file = suitableFiles[i];
            const content = await readFileAsString(file);
            const fileTitle = file.name.split('.')[0];

            if (content) {
                if (file.type === 'application/json') {
                    try {
                        const document = processJSON(content);
                        successfulyImportedFiles.push(file.name);
                        documentsToCreate.push(document);
                        searchMap?.set(document.id, transformBlocksToSharedType(document.blocks));
                    } catch (e) {
                        alert('Importing this file was not successful: Are you sure this is a valid Saga JSON file?');
                        unsuccessfulyImportedFiles.push(file.name);
                        track('error-importing-file', { source: 'import-modal', file: file.name });
                        console.warn(e);
                    }
                } else {
                    try {
                        const document = await processMarkdown(fileTitle, content);
                        documentsToCreate.push(document);
                        successfulyImportedFiles.push(file.name);
                        searchMap?.set(document.id, transformBlocksToSharedType(document.blocks));
                    } catch (e) {
                        unsuccessfulyImportedFiles.push(file.name);
                        track('error-importing-file', { source: 'import-modal', file: file.name });
                        console.warn(e);
                    }
                }
            } else {
                successfulyImportedFiles.push(file.name);
                const newPage = newBlankPage({ title: fileTitle });
                documentsToCreate.push(newPage);
                searchMap?.set(newPage.id, transformBlocksToSharedType(newPage.blocks));
            }
        }

        space.map.doc?.transact(() => {
            documentsToCreate.forEach((document) => {
                document.collections?.push(...collections);

                importType === 'task'
                    ? createTask(space, { ...document, ...taskFilters }, provider)
                    : createPage(space, document, provider);
            });

            provider.sendStateless(
                Stateless.encodeStateless(
                    'spaceblocksReceive',
                    documentsToCreate.reduce((a, document) => ({ ...a, [document.id]: document.blocks }), {}),
                ),
            );
        });

        setShowImportModal({
            title: t('settings.export_import.import_modal.successfuly_imported_files', {
                count: successfulyImportedFiles.length,
            }),
            description: (
                <>
                    {successfulyImportedFiles.map((file) => (
                        <p key={file}>{file}</p>
                    ))}
                    {unsuccessfulyImportedFiles.length > 0 && (
                        <div className="mt-4">
                            <p className="text-saga-text">{"Files which can't be imported:"}</p>
                            {unsuccessfulyImportedFiles.map((file) => (
                                <p key={file}>{file}</p>
                            ))}
                        </div>
                    )}
                </>
            ),
            type: 'results',
        });

        if (documentsToCreate.length) {
            const lastDocument = documentsToCreate[documentsToCreate.length - 1];

            history.push(
                locationToUrl(
                    importType === 'task'
                        ? SagaLocation.taskLocationFromId(lastDocument.id)
                        : SagaLocation.pageLocationFromId(lastDocument.id),
                    urlKey,
                ),
            );
        }
    };

    const onUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target?.files) {
            setShowImportModal({
                title: t('settings.export_import.import_modal.confirm_title'),
                description: (
                    <ImportModalDescription
                        importType={importType}
                        setImportType={setImportType}
                        selectedCollections={selectedCollections}
                        setSelectedCollections={setSelectedCollections}
                    />
                ),
                type: 'pre-import',
                filesToProcess: Array.from(event.target.files),
            });
            track('open-import-confirmation', { filesNumber: event.target.files.length });
        }
    };

    const onDrop = async (event: React.DragEvent) => {
        if ((modalOpen || isDragging) && event.dataTransfer && event.dataTransfer.files.length > 0) {
            event.preventDefault();

            const { files, unsupportedFiles } = await readFilesAndDirectories(event.dataTransfer.items);

            if (unsupportedFiles.length > 0) {
                setShowImportModal({
                    title: t('settings.export_import.import_modal.unsupported_error_title'),
                    description: t('settings.export_import.import_modal.unsupported_error_description'),
                    type: 'results',
                });
                setIsDragging(false);
                return;
            }

            setShowImportModal({
                title: t('settings.export_import.import_modal.confirm_title'),
                description: (
                    <ImportModalDescription
                        importType={importType}
                        setImportType={setImportType}
                        selectedCollections={selectedCollections}
                        setSelectedCollections={setSelectedCollections}
                    />
                ),
                type: 'pre-import',
                filesToProcess: files,
            });

            setIsDragging(false);
        }
    };

    const handlePreImportConfirm = async () => {
        if (showImportModal?.filesToProcess) {
            setIsUploading(true);
            setShowImportModal(null);

            const files = showImportModal.filesToProcess;

            await processFiles(files, selectedCollections, importType);

            setModalOpen(false);
            setIsUploading(false);
            setSelectedCollections([]);
            setImportType('page');
            track('import-markdown', { filesNumber: files.length, importType });
        }
    };

    const openModal = React.useCallback(() => {
        setModalOpen(true);
        track('open-markdown-modal');
    }, []);

    const onClose = React.useCallback(() => {
        setShowImportModal(null);
        setModalOpen(false);
        setImportType('page');
        track('close-markdown-modal');
    }, []);

    const showModal = modalOpen || isDragging;

    return (
        <OpenImportModalContext.Provider value={openModal}>
            <div
                id="file-dropzone"
                className="h-full"
                onDragEnter={onDragEnter}
                onDragLeave={onDragLeave}
                onDrop={onDrop}
            >
                {children}
                <div
                    ref={modalRef}
                    className={classNames('fixed inset-0 z-50 flex items-center justify-center w-screen h-screen', {
                        'pointer-events-auto': showModal,
                        'pointer-events-none': !showModal,
                    })}
                >
                    <div
                        onClick={() => setModalOpen(false)}
                        className={classNames(
                            'absolute z-10 inset-0 h-full transition-opacity duration-100 w-full bg-black',
                            {
                                'pointer-events-auto opacity-50': showModal,
                                'pointer-events-none opacity-0': !showModal,
                            },
                        )}
                    />
                    <div
                        className={classNames('bg-white dark:bg-zinc-800 rounded relative z-20', {
                            'opacity-100': showModal,
                            'opacity-0': !showModal,
                            'pointer-events-none': !modalOpen,
                        })}
                    >
                        <div className="p-5">
                            <div className="p-10 space-y-8 border-8 border-dashed border-saga-gray-200 dark:border-zinc-600">
                                <div className="relative flex justify-center">
                                    {isUploading && (
                                        <div className="absolute h-32 w-32 flex justify-center items-center">
                                            <LoadingSpinner />
                                        </div>
                                    )}
                                    <FilePlus
                                        className={classNames('h-32 w-32 text-saga-gray-500 dark:text-zinc-200 z-10', {
                                            'opacity-0': isUploading,
                                        })}
                                    />
                                </div>
                                <div className="space-y-2">
                                    <p className="text-xl font-semibold text-center text-saga-gray-dark dark:text-zinc-200">
                                        {t('settings.export_import.import_modal.title')}
                                    </p>
                                    {modalOpen && (
                                        <>
                                            <p className="text-center text-sm">{t('common.or')}</p>
                                            <div className="w-full flex justify-center">
                                                <Button
                                                    onClick={() => {
                                                        if (fileUploadRef.current) {
                                                            fileUploadRef.current.click();
                                                        }
                                                    }}
                                                    className="not-sr-only"
                                                    variant="primary"
                                                >
                                                    {t('settings.export_import.import_modal.upload_button')}
                                                </Button>
                                                <label htmlFor="fileUpload"></label>
                                                <input
                                                    id="fileUpload"
                                                    ref={fileUploadRef}
                                                    className="sr-only"
                                                    type="file"
                                                    accept=".md,text/*,application/json"
                                                    onChange={onUpload}
                                                    multiple
                                                />
                                            </div>
                                        </>
                                    )}

                                    <p className="text-center text-sm">
                                        {t('settings.export_import.import_modal.max_file_size', {
                                            size: Math.round(MAXIMUM_ALLOWED_FILESIZE / 1000 / 1000),
                                        })}
                                    </p>
                                    <p className="text-center text-sm">
                                        {t('settings.export_import.import_modal.supported_file_types')}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {showImportModal && (
                <ImportModal
                    title={showImportModal.title}
                    description={showImportModal.description}
                    type={showImportModal.type}
                    onConfirm={
                        showImportModal.type === 'pre-import' ? handlePreImportConfirm : () => setShowImportModal(null)
                    }
                    onClose={onClose}
                    buttonText={
                        showImportModal.type === 'pre-import'
                            ? t('settings.export_import.import_modal.confirm_button')
                            : 'Okay'
                    }
                />
            )}
        </OpenImportModalContext.Provider>
    );
}

function ImportModal({
    title,
    description,
    onConfirm,
    onClose,
    buttonText,
    type = 'results',
}: {
    title: string;
    description?: string | React.ReactNode;
    onConfirm: () => void;
    onClose: () => void;
    buttonText: string;
    type?: 'pre-import' | 'results';
}) {
    return (
        <Modal.Small isOpen>
            <div
                data-testid="import-modal"
                className={`relative p-6 rounded max-w-xl bg-saga-gray-100 dark:bg-zinc-800 dark:text-zinc-200 text-saga-text`}
            >
                <div className="flex justify-between items-center">
                    <div className="font-medium text-base leading-6">{title}</div>
                    <Button.XButton onClick={onClose} />
                </div>
                {description && <div className="mb-6 text-saga-gray-500 overflow-auto max-h-96">{description}</div>}
                <div className="flex justify-end">
                    <Button
                        onClick={onConfirm}
                        className="ml-3"
                        variant={type === 'pre-import' ? 'primary' : 'secondary'}
                    >
                        {buttonText}
                    </Button>
                </div>
            </div>
        </Modal.Small>
    );
}

function readDirectory(directory: FileSystemDirectoryEntry): Promise<FileSystemEntry[]> {
    return new Promise((resolve, reject) => {
        const dirReader = directory.createReader();
        let entries: FileSystemEntry[] = [];
        const getEntries = function () {
            dirReader.readEntries(
                function (results) {
                    const endOfDirectoryReached = !results.length;
                    if (endOfDirectoryReached) {
                        const directories = entries.filter((entry) => entry.isDirectory) as FileSystemDirectoryEntry[];
                        const rootFiles = entries.filter((entry) => !entry.isDirectory);
                        const hasDirectories = directories.length;

                        if (hasDirectories) {
                            const promises = directories.map(readDirectory);
                            Promise.all(promises).then((files) => {
                                const allFiles = [...rootFiles, ...files.flat()];
                                resolve(allFiles);
                            });
                        } else {
                            resolve(entries);
                        }
                    } else {
                        entries = [...entries, ...results];
                        getEntries();
                    }
                },
                function (error) {
                    reject(error);
                },
            );
        };

        getEntries();
    });
}

async function readFilesAndDirectories(
    items: DataTransferItemList,
): Promise<{ files: File[]; unsupportedFiles: string[] }> {
    const promises: Promise<FileSystemEntry>[] = [];
    const unsupportedFiles: string[] = [];

    for (let i = 0; i < items.length; i++) {
        const entry = items[i].webkitGetAsEntry();
        if (entry) {
            const fileExtension = entry.name.split('.').pop()?.toLowerCase();
            if (entry.isDirectory) {
                const entries = await readDirectory(entry as FileSystemDirectoryEntry);
                entries.forEach((entry) => promises.push(Promise.resolve(entry)));
            } else if (entry.isFile) {
                if (fileExtension && !SUPPORTED_EXTENSIONS.includes(fileExtension)) {
                    unsupportedFiles.push(entry.name);
                } else {
                    promises.push(Promise.resolve(entry as FileSystemFileEntry));
                }
            }
        }
    }

    const files = await Promise.all(promises).then((entities) => {
        const promises = entities.map(
            (entity) =>
                new Promise((resolve, reject) =>
                    (entity as FileSystemFileEntry).file(resolve, reject),
                ) as Promise<File>,
        );
        return Promise.all(promises);
    });

    return { files, unsupportedFiles };
}

ImportFileDropzone.useOpenModal = () => React.useContext(OpenImportModalContext);

export default ImportFileDropzone;
