import React, { useCallback, createContext, useState, useContext, ReactNode } from 'react';
import { BaseRange, Point, Transforms } from 'slate';
import { ReactEditor, useSlateStatic } from 'slate-react';
import AIPopover from './AIPopover';
import isHotkey from 'is-hotkey';
import { useMountedEditors } from '@/../../shared/src/Editor';
import { BlockBuilder, EditorOperations } from '@/../../shared/src';

const AIPopoverContext = createContext<{
    open: boolean;
    setOpen(selection: BaseRange | undefined | null): void;
    setClose(): void;
}>({
    open: false,
    setOpen: () => {},
    setClose: () => {},
});

const AI_POPOVER_SHORTCUT = 'mod+j';

export default function AIPopoverProvider({ children }: { children: ReactNode }) {
    const [open, setOpen] = useState<boolean>(false);
    const [selection, setSelection] = useState<BaseRange>();
    const editor = useSlateStatic();
    const mountedEditors = useMountedEditors();

    const onClose = useCallback(() => {
        ReactEditor.focus(editor);
        setOpen(false);
    }, [editor]);

    const setPopoverOpen = useCallback((selection: BaseRange) => {
        setOpen(true);
        setSelection(selection);
    }, []);

    React.useEffect(() => {
        const shortcutOpen = (event: KeyboardEvent) => {
            if (isHotkey(AI_POPOVER_SHORTCUT, event)) {
                event.preventDefault();

                const range = EditorOperations.Selection.getRangeFromStartToEndInCurrentPath(editor);

                if (range && editor.selection) {
                    if (
                        !open &&
                        Point.compare(range.anchor, range.focus) !== 0 &&
                        Point.compare(editor.selection.anchor, editor.selection.focus) === 0
                    ) {
                        // there is another content on the same line and we haven't selected any text
                        Transforms.insertNodes(editor, BlockBuilder.paragraph(''));
                    }
                }

                setSelection(editor.selection ?? undefined);

                if ([...mountedEditors].length > 1) {
                    if (ReactEditor.isFocused(editor)) {
                        setOpen((open) => !open);
                        event.stopImmediatePropagation();
                    }
                } else {
                    setOpen((open) => !open);
                    event.stopImmediatePropagation();
                }
            }
        };
        document.addEventListener('keydown', shortcutOpen);
        return () => {
            document.removeEventListener('keydown', shortcutOpen);
        };
    }, [editor, mountedEditors, open]);

    return (
        <AIPopoverContext.Provider value={{ open, setOpen: setPopoverOpen, setClose: onClose }}>
            {children}
            {open && <AIPopover onClose={onClose} editor={editor} selection={selection} />}
        </AIPopoverContext.Provider>
    );
}

export function useSetAIPopoverOpen() {
    const { setOpen, open } = useContext(AIPopoverContext);

    return {
        openAIPopover: (selection: BaseRange | undefined | null = undefined) => {
            setOpen(selection);
        },
        isOpen: open,
    };
}

export function useSetAIPopoverClose() {
    const { setClose } = useContext(AIPopoverContext);
    return {
        closeAIPopover: setClose,
    };
}
