import React, { useEffect } from 'react';

import { useSlateStatic } from 'slate-react';
import { Editor, Range } from 'slate';
import { AIMessage, EditorOperations } from '@/../../shared/src';

import * as Popover from '@radix-ui/react-popover';

type RangeAnchorAlignY = 'top' | 'bottom';
type RangeAnchorAlignX = 'range' | 'leading';

export default function RangeAnchor({
    range,
    containerRef,
    contentRef,
    alignY = 'bottom',
    alignX = 'range',
    history,
}: {
    range: Range | null;
    containerRef: React.RefObject<HTMLElement> | null;
    contentRef?: HTMLElement | null;
    alignY?: RangeAnchorAlignY;
    alignX?: RangeAnchorAlignX;
    history?: AIMessage[];
}) {
    const editor = useSlateStatic();
    const anchorRef = React.useRef<HTMLSpanElement>(null);

    const [position, setPosition] = React.useState<{
        marginLeft: number | undefined;
        top: number | undefined;
    }>({
        marginLeft: undefined,
        top: undefined,
    });

    React.useLayoutEffect(() => {
        if (range) {
            const startPoint = { ...Editor.start(editor, range), ...(alignX === 'leading' && { offset: 0 }) };
            const startRange = { anchor: startPoint, focus: startPoint };

            const domRange = EditorOperations.Selection.safeToDOMRange(editor, startRange);
            const rect = domRange?.getBoundingClientRect();
            const containerRect = containerRef?.current?.getBoundingClientRect();
            const top = alignY === 'bottom' ? rect?.bottom : rect?.top;

            let marginLeft = 0;

            if (containerRect && rect) {
                // there is sm:pl-8 responsive padding set on the editor (pl-8 = 32px and sm is >= 640)
                marginLeft = rect.left - containerRect.left - (window.innerWidth <= 640 ? 0 : 32);
            }

            setPosition({ marginLeft, top });
        }
    }, [range, editor, alignY, alignX, containerRef, containerRef?.current?.clientWidth, history]);

    React.useLayoutEffect(() => {
        const containerEl = containerRef?.current?.parentElement;

        function updateModalPosition() {
            if (!range) {
                return;
            }

            const domRange = EditorOperations.Selection.safeToDOMRange(editor, range);
            const anchorEl = anchorRef.current;
            const rangeRect = domRange?.getBoundingClientRect();

            if (anchorEl && rangeRect) {
                anchorEl.style.top =
                    alignY === 'bottom' ? `${rangeRect.top + rangeRect.height + 5}px` : `${rangeRect.top}px`;
            }
        }

        if (containerEl && anchorRef && range) {
            updateModalPosition();

            function onScroll() {
                requestAnimationFrame(updateModalPosition);
            }
            containerEl.addEventListener('scroll', onScroll);
            return () => containerEl.removeEventListener('scroll', onScroll);
        }

        return;
    }, [containerRef, position, range, editor, alignY, history]);

    useEffect(() => {
        const scrollElement = containerRef?.current?.parentElement;

        if (!scrollElement || !contentRef || !position.top || !range) {
            return;
        }

        const domRange = EditorOperations.Selection.safeToDOMRange(editor, range);
        const bottom = domRange?.getBoundingClientRect().bottom || 0;

        const containerMaxY = scrollElement.scrollTop + scrollElement.offsetHeight;
        const contentMaxY = bottom + scrollElement.scrollTop + contentRef.offsetHeight;

        const delta = contentMaxY - containerMaxY;

        if (delta > 0) {
            scrollElement?.scrollTo({
                behavior: 'smooth',
                top: scrollElement.scrollTop + delta + 30,
            });
        }
    }, [position, contentRef, containerRef, editor, range, history]);

    return (
        <Popover.Anchor asChild>
            <span ref={anchorRef} className="fixed" style={position} />
        </Popover.Anchor>
    );
}
