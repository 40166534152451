import React from 'react';
import { animated, useTrail, config, to } from 'react-spring';

const letterS = (
    <>
        <path
            d="M195.801 155.9V156C195.801 176.4 186.301 195.7 170.101 208.1C154.601 220.1 133.601 226.4 109.201 226.4C84.2006 226.4 61.6006 219.2 45.2006 207.3C37.5006 201.7 -4.09938 174.6 1.10062 113.5C6.20062 113.5 21.9006 113.7 21.9006 113.7C9.10062 102.4 3.40062 87.2999 3.40062 68.8999C3.40062 48.4999 12.2006 30.3999 28.2006 18.0999C43.2006 6.4999 63.6006 0.399902 87.3006 0.399902C110.801 0.399902 131.101 6.7999 146.101 18.7999C153.301 24.5999 189.701 50.8999 192.801 109.7C184.601 109.7 176.401 109.7 176.401 109.7C189.301 120.7 195.801 136.2 195.801 155.9Z"
            fill="#101010"
        />
        <path
            d="M87.6008 202.4C62.6008 202.4 41.2008 195.8 26.0008 183C-6.69922 155.4 1.10078 113.5 1.10078 113.5L21.9008 113.7C9.10078 102.4 3.40078 87.2999 3.40078 68.8999C3.40078 48.3999 12.2008 30.3999 28.2008 18.0999C43.2008 6.4999 63.6008 0.399902 87.3008 0.399902C110.801 0.399902 131.101 6.7999 146.101 18.7999C161.701 31.2999 170.501 49.3999 170.901 69.8999L171.201 85.6999H154.801C167.601 96.6999 174.101 112.2 174.101 131.9V132C174.101 152.4 164.601 171.7 148.401 184.1C133.001 196.1 111.901 202.4 87.6008 202.4Z"
            fill="#101010"
        />
        <path
            d="M87.6008 186.9C43.8008 186.9 17.1008 163.9 16.8008 129H70.1008C70.1008 140.6 78.4008 146.2 88.8008 146.2C96.8008 146.2 104.401 142.2 104.401 133.6C104.401 124.4 92.1008 121.6 76.8008 119.2C51.7008 115.2 18.9008 107.9 18.9008 68.8999C18.9008 36.6999 46.2008 15.8999 87.3008 15.8999C128.101 15.8999 154.701 37.6999 155.401 70.1999H103.501C103.501 60.0999 96.8008 55.1999 86.6008 55.1999C77.7008 55.1999 71.9008 59.4999 71.9008 67.1999C71.9008 76.3999 84.2008 78.4999 99.5008 81.2999C124.901 85.8999 158.701 91.0999 158.701 131.9C158.401 165.1 130.201 186.9 87.6008 186.9Z"
            fill="#101010"
        />
        <path
            d="M87.6008 186.9C43.8008 186.9 17.1008 163.9 16.8008 129H70.1008C70.1008 140.6 78.4008 146.2 88.8008 146.2C96.8008 146.2 104.401 142.2 104.401 133.6C104.401 124.4 92.1008 121.6 76.8008 119.2C51.7008 115.2 18.9008 107.9 18.9008 68.8999C18.9008 36.6999 46.2008 15.8999 87.3008 15.8999C128.101 15.8999 154.701 37.6999 155.401 70.1999H103.501C103.501 60.0999 96.8008 55.1999 86.6008 55.1999C77.7008 55.1999 71.9008 59.4999 71.9008 67.1999C71.9008 76.3999 84.2008 78.4999 99.5008 81.2999C124.901 85.8999 158.701 91.0999 158.701 131.9C158.401 165.1 130.201 186.9 87.6008 186.9Z"
            fill="#FBFD71"
        />
    </>
);

const letterA1 = (
    <>
        <path
            d="M392.401 29.4L392.301 220.8H323.701C323.701 220.8 295.301 226.4 281.101 226.4C219.401 226.4 159.701 182.3 159.701 101.1C159.701 42.8 201.701 0.5 259.501 0.5C274.201 0.5 288.601 4 301.701 10.6L304.301 5.4H370.801L392.401 29.4Z"
            fill="#101010"
        />
        <path
            d="M259.601 202.4C231.701 202.4 206.201 192.2 188.001 173.6C169.801 155 159.801 129.4 159.801 101.1C159.801 42.8 201.801 0.5 259.601 0.5C274.301 0.5 288.701 4 301.801 10.6L304.301 5.4H370.801L370.701 196.8H302.101L300.601 193C287.801 199.3 273.801 202.5 259.601 202.4ZM266.901 80.4C254.701 80.4 246.501 88.8 246.501 101.4C246.501 113.8 254.901 122.4 266.901 122.4C278.901 122.4 287.301 113.7 287.301 101.4C287.301 89.1 278.901 80.4 266.901 80.4Z"
            fill="#101010"
        />
        <path
            d="M355.201 20.9998V181.3H312.601L308.001 170C294.301 181.1 277.201 187.1 259.601 186.9C210.501 186.9 175.301 151 175.301 101.1C175.301 51.3998 210.201 15.8998 259.601 15.8998C277.501 15.6998 294.801 21.7998 308.701 33.0998L314.201 20.4998L355.201 20.9998ZM302.801 101.4C302.801 80.5998 287.501 64.8998 266.901 64.8998C246.101 64.8998 231.001 80.4998 231.001 101.4C231.001 122.3 246.301 137.9 266.901 137.9C287.501 137.9 302.801 122.2 302.801 101.4Z"
            fill="#101010"
        />
        <path
            d="M355.201 20.9998V181.3H312.601L308.001 170C294.301 181.1 277.201 187.1 259.601 186.9C210.501 186.9 175.301 151 175.301 101.1C175.301 51.3998 210.201 15.8998 259.601 15.8998C277.501 15.6998 294.801 21.7998 308.701 33.0998L314.201 20.4998L355.201 20.9998ZM302.801 101.4C302.801 80.5998 287.501 64.8998 266.901 64.8998C246.101 64.8998 231.001 80.4998 231.001 101.4C231.001 122.3 246.301 137.9 266.901 137.9C287.501 137.9 302.801 122.2 302.801 101.4Z"
            fill="#FBFD71"
        />
    </>
);

const letterG = (
    <>
        <path
            d="M593.401 188C593.401 247.1 553.501 285.3 491.801 285.3C451.301 285.3 382.901 263.5 366.501 169.3C378.901 169.3 396.601 169.3 396.601 169.3C377.101 152 365.101 126.4 365.101 96.9001C365.101 70.6001 374.801 46.4001 392.501 28.6001C410.201 10.8001 434.501 0.600098 460.901 0.600098C476.601 0.600098 492.101 4.6001 505.901 12.2001L509.301 5.8001H571.301L592.901 29.8001L593.401 188Z"
            fill="#101010"
        />
        <path
            d="M470.201 261.3C417.201 261.3 377.401 231.1 366.501 169.3C380.701 169.3 396.601 169.3 396.601 169.3C377.101 152 365.101 126.4 365.101 96.9001C365.101 70.6001 374.801 46.4001 392.501 28.6001C410.201 10.8001 434.501 0.600098 460.901 0.600098C476.601 0.600098 492.101 4.6001 505.901 12.2001L509.301 5.8001H571.301L571.801 164C571.801 223.1 531.901 261.3 470.201 261.3ZM470.501 77.3001C459.301 77.3001 451.701 85.1001 451.701 96.7001C451.701 102.9 453.501 107.9 457.001 111.5C460.301 114.8 465.001 116.5 470.501 116.5C481.701 116.5 489.001 108.7 489.001 96.7001C489.001 84.9001 481.701 77.3001 470.501 77.3001Z"
            fill="#101010"
        />
        <path
            d="M556.3 21.0003V163.9C556.3 214.5 523.2 245.8 470.2 245.8C424.5 245.8 392.6 222.8 385.3 184.8H438.6C442.3 195.5 456.1 201.4 469.6 201.4C488 201.4 503 189.8 503 170.4V165.5C490.4 173.5 475.9 177.6 461 177.5C415 177.5 380.7 143.2 380.7 96.9003C380.7 51.2003 415 16.3003 461 16.3003C479.6 16.3003 497.5 22.9003 511.6 35.0003L518.7 21.5003L556.3 21.0003ZM470.5 132C490.4 132 504.5 117.3 504.5 96.7003C504.5 76.5003 490.4 61.8003 470.5 61.8003C450.6 61.8003 436.2 76.5003 436.2 96.7003C436.1 117.3 450.2 132 470.5 132Z"
            fill="#101010"
        />
        <path
            d="M556.3 21.0003V163.9C556.3 214.5 523.2 245.8 470.2 245.8C424.5 245.8 392.6 222.8 385.3 184.8H438.6C442.3 195.5 456.1 201.4 469.6 201.4C488 201.4 503 189.8 503 170.4V165.5C490.4 173.5 475.9 177.6 461 177.5C415 177.5 380.7 143.2 380.7 96.9003C380.7 51.2003 415 16.3003 461 16.3003C479.6 16.3003 497.5 22.9003 511.6 35.0003L518.7 21.5003L556.3 21.0003ZM470.5 132C490.4 132 504.5 117.3 504.5 96.7003C504.5 76.5003 490.4 61.8003 470.5 61.8003C450.6 61.8003 436.2 76.5003 436.2 96.7003C436.1 117.3 450.2 132 470.5 132Z"
            fill="#FBFD71"
        />
    </>
);

const letterA2 = (
    <>
        <path
            d="M799.201 29.4L799.101 220.8H730.501C730.501 220.8 702.101 226.4 687.901 226.4C626.201 226.4 566.501 182.3 566.501 101.1C566.501 42.8 608.501 0.5 666.301 0.5C681.001 0.5 695.401 4 708.501 10.6L711.001 5.4H777.501L799.201 29.4Z"
            fill="#101010"
        />
        <path
            d="M666.401 202.4C638.501 202.4 613.001 192.2 594.801 173.6C576.601 155 566.601 129.4 566.601 101.1C566.601 42.7999 608.601 0.399902 666.401 0.399902C681.101 0.399902 695.501 3.8999 708.601 10.5999L711.101 5.3999H777.601L777.501 196.8H709.001L707.501 193C694.601 199.3 680.601 202.5 666.401 202.4ZM673.701 80.3999C661.501 80.3999 653.301 88.7999 653.301 101.4C653.301 113.8 661.701 122.4 673.701 122.4C685.701 122.4 694.101 113.7 694.101 101.4C694.101 89.0999 685.701 80.3999 673.701 80.3999Z"
            fill="#101010"
        />
        <path
            d="M762.001 20.9998V181.3H719.401L714.801 170C701.101 181.1 684.001 187.1 666.401 186.9C617.301 186.9 582.101 151 582.101 101.1C582.101 51.3998 617.001 15.8998 666.401 15.8998C684.301 15.6998 701.601 21.7998 715.501 33.0998L721.001 20.4998L762.001 20.9998ZM709.601 101.4C709.601 80.5998 694.301 64.8998 673.701 64.8998C652.901 64.8998 637.801 80.4998 637.801 101.4C637.801 122.3 653.101 137.9 673.701 137.9C694.301 137.9 709.601 122.2 709.601 101.4Z"
            fill="#101010"
        />
        <path
            d="M762.001 20.9998V181.3H719.401L714.801 170C701.101 181.1 684.001 187.1 666.401 186.9C617.301 186.9 582.101 151 582.101 101.1C582.101 51.3998 617.001 15.8998 666.401 15.8998C684.301 15.6998 701.601 21.7998 715.501 33.0998L721.001 20.4998L762.001 20.9998ZM709.601 101.4C709.601 80.5998 694.301 64.8998 673.701 64.8998C652.901 64.8998 637.801 80.4998 637.801 101.4C637.801 122.3 653.101 137.9 673.701 137.9C694.301 137.9 709.601 122.2 709.601 101.4Z"
            fill="#FBFD71"
        />
    </>
);

export default function LogoAnimatedSvg({ className }: { className?: string }) {
    const n = React.useRef(0);

    const [springs] = useTrail(4, () => {
        return {
            from: {
                progress: 1,
            },
            to: {
                progress: 0,
            },
            loop: () => {
                n.current++;
                return { reverse: true, delay: n.current >= 1 ? 2000 : 0 };
            },
            transformOrigin: 'center left',
            config: config.stiff,
        };
    });

    const letters = [letterS, letterA1, letterG, letterA2];

    return (
        <svg className={className} viewBox="0 0 1000 400" fill="none">
            {letters.map((letter, i) => (
                <animated.g
                    key={i}
                    style={{
                        position: 'relative',
                        x: to(springs[i].progress, (progress) => {
                            return -i * (180 * progress) + 90;
                        }),
                        opacity: to(springs[i].progress, (progress) => {
                            return 1 - progress;
                        }),
                    }}
                >
                    {letter}
                </animated.g>
            ))}
        </svg>
    );
}
