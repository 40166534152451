import React, { useRef, useState, useEffect } from 'react';
import * as Popover from '@radix-ui/react-popover';
import { XCircle } from 'react-feather';
import Button from '@/components/styled/Button';
import Input from '../styled/Input';
import { OpacityTransition } from '../Transitions';
import ReactDOM from 'react-dom';

type SetLinkPopOverProps = {
    attachToRef: React.MutableRefObject<HTMLElement | null>;
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    currentURL: string | null;
    onChange: (url: string) => void;
    onRemove: () => void;
    canRemove: boolean;
};

function RefAnchor({ attachToRef }: { attachToRef: React.RefObject<HTMLElement> }) {
    const rect = attachToRef.current?.getBoundingClientRect();
    const left = rect?.left;
    const top = rect?.bottom;

    return ReactDOM.createPortal(
        <Popover.Anchor asChild>
            <span className="fixed" style={{ left, top }} />
        </Popover.Anchor>,
        window.document.body,
    );
}

function SetLinkPopOver({
    attachToRef,
    isOpen,
    setIsOpen,
    currentURL,
    onChange,
    onRemove,
    canRemove,
}: SetLinkPopOverProps) {
    const contentRef = React.useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const [value, setValue] = useState<string>(currentURL ?? '');

    useEffect(() => {
        if (!isOpen && currentURL && currentURL !== value) {
            setValue(currentURL);
        }
    }, [currentURL, isOpen, value]);

    const submit = () => {
        setIsOpen(false);
        onChange(value);
    };

    return (
        <Popover.Root open={isOpen} onOpenChange={setIsOpen}>
            <RefAnchor attachToRef={attachToRef} />
            <Popover.Portal>
                <Popover.Content
                    onFocusOutside={(e) => {
                        e.preventDefault();
                    }}
                    align="start"
                >
                    <OpacityTransition tag="span" duration={200} isOpen={isOpen}>
                        <div ref={contentRef} className="mt-1 outline-none w-highlight" data-testid="set-link-popover">
                            <div className="relative shadow-popupSmall dark:border dark:border-solid dark:border-zinc-600 bg-white dark:bg-zinc-700 max-w-highlight rounded-md py-1 pointer-events-auto space-y-1">
                                <form
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        submit();
                                    }}
                                >
                                    <div className="relative w-full flex flex-row px-1 space-x-1">
                                        <Input.Small
                                            innerRef={inputRef}
                                            type="url"
                                            required
                                            placeholder="Paste link..."
                                            value={value}
                                            onChange={(e) => setValue(e.target.value)}
                                            onKeyDown={(event: any) => {
                                                switch (event.key) {
                                                    case 'Enter':
                                                        event.preventDefault();
                                                        submit();
                                                        return;
                                                }
                                            }}
                                        />
                                        <Button.Plain type="submit" disabled={value.length === 0}>
                                            <Button.SmallPadding>
                                                <span className="my-auto font-medium text-sm">Save</span>
                                            </Button.SmallPadding>
                                        </Button.Plain>
                                    </div>
                                    {canRemove && currentURL && currentURL.length > 0 && (
                                        <div className="pt-2 w-full">
                                            <Button.Plain type="button" widthFull onClick={onRemove}>
                                                <Button.BasePadding>
                                                    <span className="flex w-full">
                                                        <XCircle className="h-4 w-4 flex-none text-saga-gray-500 mr-2" />
                                                        <span>Remove Link</span>
                                                    </span>
                                                </Button.BasePadding>
                                            </Button.Plain>
                                        </div>
                                    )}
                                </form>
                            </div>
                        </div>
                    </OpacityTransition>
                </Popover.Content>
            </Popover.Portal>
        </Popover.Root>
    );
}

export default SetLinkPopOver;
