import Modal from '@/components/Modal';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useWorkspaces } from './WorkspacesContext';
import WorkspaceAvatar from './navigation/WorkspaceAvatar';
import { ButtonNew } from './styled/Button';
import { getCurrentUser } from '@/firebase';
import { SpaceWithMembersFragment, Role } from '@saga/api';
import { assertNonNull } from '@saga/shared';
import Tooltip from './popover/Tooltip';
import classNames from 'classnames';
import useMobile from '@/hooks/useMobile';

export default function ChooseWorkspaceModal({
    title,
    subTitle,
    onSelect,
    excludeSpaceUrlKey,
    showOnlyFreeSpaces = false,
    spaceCtaLabel = 'common.select',
    onClose,
}: Readonly<{
    title: string;
    subTitle: string;
    onSelect: (urlKey: string) => void;
    excludeSpaceUrlKey?: string;
    showOnlyFreeSpaces?: boolean;
    spaceCtaLabel?: string;
    onClose: () => void;
}>) {
    const { t } = useTranslation();
    const { workspaces } = useWorkspaces();
    const currentUser = getCurrentUser();
    const elementRef = useRef<HTMLDivElement>(null);
    const isMobile = useMobile();

    const spaces = excludeSpaceUrlKey ? workspaces.filter((w) => w.urlKey !== excludeSpaceUrlKey) : workspaces;
    const filteredSpaces = showOnlyFreeSpaces
        ? spaces.filter((w) => w.activeSubscription === null || w.activeSubscription?.status !== 'active')
        : spaces;

    const isDisabled = (workspace: SpaceWithMembersFragment) => {
        assertNonNull(currentUser);
        return workspace.members.find((m) => m.id === currentUser.uid)?.role === Role.Viewer;
    };

    return (
        <Modal.Large isOpen onClose={onClose}>
            <Modal.CloseButton />
            <Modal.Content dataTestId="choose-workspace-modal">
                <Modal.Title>{title}</Modal.Title>
                <p className="text-saga-gray-500 text-center">{subTitle}</p>
                <div
                    className={classNames('space-y-3 overflow-y-auto max-h-[250px]', {
                        'px-2': !isMobile,
                    })}
                >
                    {filteredSpaces.map((workspace) => {
                        const disabled = isDisabled(workspace);
                        return (
                            <div
                                data-testid={`Select-${workspace.title}`}
                                key={workspace.id}
                                className={classNames('flex justify-between space-x-3 p-0 items-center text-sm', {
                                    '!space-x-6': !isMobile,
                                })}
                            >
                                <div className="flex items-center">
                                    <WorkspaceAvatar workspace={{ title: workspace.title }} />
                                    <span
                                        className={classNames(
                                            'w-32 ml-3 font-medium text-ellipsis overflow-hidden whitespace-nowrap',
                                            {
                                                '!w-48': !isMobile,
                                            },
                                        )}
                                    >
                                        {workspace.title}
                                    </span>
                                </div>
                                <span className="w-30 text-saga-gray-500 whitespace-nowrap">
                                    {t('top_menu.share.members', { count: workspace.members.length })}
                                </span>
                                <Tooltip
                                    content={t('top_menu.share.copying_page_disabled_tooltip')}
                                    placement="top"
                                    disabled={!disabled}
                                >
                                    <div ref={elementRef}>
                                        <ButtonNew
                                            variant="secondary"
                                            onClick={() => onSelect(workspace.urlKey)}
                                            disabled={disabled}
                                            testId={`selectButton-${workspace.urlKey}`}
                                        >
                                            {t(spaceCtaLabel)}
                                        </ButtonNew>
                                    </div>
                                </Tooltip>
                            </div>
                        );
                    })}
                </div>
            </Modal.Content>
        </Modal.Large>
    );
}
