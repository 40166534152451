import findIndexByPageId from './findIndexByPageId';

import { SafeSpace } from '../types';

const mustFindIndexByPageId = (space: SafeSpace, id: string): number => {
    const pageIndex = findIndexByPageId(space, id);

    if (pageIndex === -1) {
        throw new Error(`mustFindIndexByPageId: Page not found with id ${id}`);
    }

    return pageIndex;
};

export default mustFindIndexByPageId;
