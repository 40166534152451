import { Node, NodeEntry, Path, Range, Transforms } from 'slate';
import { Editor } from 'slate';
import { isSagaText, MarkType, SagaText } from '..';
import * as R from 'ramda';
import { Point } from 'slate';

function unhangAnchor(
    editor: Editor,
    range: Range,
    options: {
        voids?: boolean;
    } = {},
): Point {
    const { voids = false } = options;
    let [start] = Range.edges(range);

    const [startNode]: NodeEntry = Editor.node(editor, start);

    // PERF: exit early if we can guarantee that the range isn't hanging.
    if (start.offset !== Node.string(startNode).length || Range.isCollapsed(range)) {
        return start;
    }

    const startBlock = Editor.above(editor, {
        at: start,
        match: (n) => Editor.isBlock(editor, n as any),
    });
    const blockPath = startBlock ? startBlock[1] : [];
    const last = Editor.end(editor, []);
    const after = { anchor: start, focus: last };
    let skip = true;

    for (const [node, path] of Editor.nodes(editor, {
        at: after,
        match: isSagaText,
        voids,
    })) {
        if (skip) {
            skip = false;
            continue;
        }

        if (node.text !== '' || Path.isAfter(path, blockPath)) {
            start = { path, offset: 0 };
            break;
        }
    }

    return start;
}

function unhangFocus(
    editor: Editor,
    range: Range,
    options: {
        voids?: boolean;
    } = {},
): Point {
    const { voids = false } = options;
    let [start, end] = Range.edges(range);

    // PERF: exit early if we can guarantee that the range isn't hanging.
    if (start.offset !== 0 || end.offset !== 0 || Range.isCollapsed(range)) {
        return end;
    }

    const endBlock = Editor.above(editor, {
        at: end,
        match: (n) => Editor.isBlock(editor, n as any),
    });
    const blockPath = endBlock ? endBlock[1] : [];
    const first = Editor.start(editor, []);
    const before = { anchor: first, focus: end };
    let skip = true;
    for (const [node, path] of Editor.nodes(editor, {
        at: before,
        match: isSagaText,
        reverse: true,
        voids,
    })) {
        if (skip) {
            skip = false;
            continue;
        }
        if (node.text !== '' || Path.isBefore(path, blockPath)) {
            end = { path, offset: node.text.length };
            break;
        }
    }

    return end;
}

export function getMarks(editor: Editor): Record<string, any> | null {
    const { marks, selection } = editor;

    if (!selection || editor.children.length === 0) {
        return null;
    }

    if (marks) {
        return marks;
    }

    if (Range.isExpanded(selection)) {
        const [match] = Editor.nodes(editor, {
            match: isSagaText,
            at: {
                anchor: unhangAnchor(editor, selection),
                focus: unhangFocus(editor, selection),
            },
        });

        if (match) {
            const [node] = match as NodeEntry<SagaText>;
            return R.omit(['text'], node);
        } else {
            return {};
        }
    }

    const { anchor } = selection;
    const { path } = anchor;
    let [node] = Editor.leaf(editor, path);

    if (anchor.offset === 0) {
        const prev = Editor.previous(editor, { at: path, match: isSagaText });
        const block = Editor.above(editor, {
            match: (n) => Editor.isBlock(editor, n as any),
        });

        if (prev && block) {
            const [prevNode, prevPath] = prev;
            const [, blockPath] = block;

            if (Path.isAncestor(blockPath, prevPath)) {
                node = prevNode;
            }
        }
    }

    return R.omit(['text'], node);
}

export function removeMark(editor: Editor, key: MarkType) {
    const { selection } = editor;

    if (selection) {
        if (Range.isExpanded(selection)) {
            Transforms.unsetNodes(editor, key, {
                match: isSagaText,
                at: {
                    anchor: unhangAnchor(editor, selection),
                    focus: unhangFocus(editor, selection),
                },
                split: true,
            });
        } else {
            const marks: Omit<SagaText, 'text'> = { ...(Editor.marks(editor) || {}) };
            delete marks[key];
            editor.marks = marks;
        }
    }
}

function setMark(editor: Editor, key: string, value: any) {
    const { selection } = editor;

    if (selection) {
        if (Range.isExpanded(selection)) {
            Transforms.setNodes(
                editor,
                { [key]: value },
                {
                    match: isSagaText,
                    at: {
                        anchor: unhangAnchor(editor, selection),
                        focus: unhangFocus(editor, selection),
                    },
                    split: true,
                },
            );
        } else {
            const marks = {
                ...(Editor.marks(editor) || {}),
                [key]: value,
            };

            // @ts-ignore for some reason TS doesn't like it
            editor.marks = marks;
            editor.onChange();
        }
    }
}

// check is mark (bold, italic, etc.) is applied to a node
export function isMarkActive(editor: Editor, format: MarkType, value?: boolean | string) {
    const activeMarks = getMarks(editor);

    if (value) {
        return activeMarks ? activeMarks[format] === value : false;
    }
    return activeMarks ? activeMarks[format] === true : false;
}

export function toggleMark(editor: Editor, format: MarkType, value?: boolean | string) {
    const isActive = isMarkActive(editor, format);

    if (isActive) {
        removeMark(editor, format);
    } else {
        setMark(editor, format, value ?? true);
    }
}
