import * as Y from 'yjs';
import mustFindReferenceRegistryEntry from './mustFindReferenceRegistryEntry';
import { ReferenceRegistryEntry } from '../types';
import { SafeSpace } from '../types';
import { logError } from '../utils';
import transactInSpace from './transactInSpace';

export default function updateReferenceRegistryEntry(
    space: SafeSpace,
    id: ReferenceRegistryEntry['liveReferenceSourceId'],
    data: Partial<Pick<ReferenceRegistryEntry, 'liveBlocks' | 'pageId' | 'isArchived'>>,
) {
    try {
        const yentry = mustFindReferenceRegistryEntry(space, id) as Y.Map<unknown>;

        transactInSpace(space, () => {
            if (data.liveBlocks != null) yentry.set('liveBlocks', data.liveBlocks);
            if (typeof data.pageId === 'string') yentry.set('pageId', data.pageId);
            if (data.isArchived != null) yentry.set('isArchived', data.isArchived);
        });
    } catch (error) {
        logError(error as Error);
    }
}
