import { useSafeArray } from '@/io-ts-react';
import { assertNonNull, SpaceOperations, taskD, unsafeRight, WeakTask } from '@saga/shared';
import React, { useMemo } from 'react';
import { useSpace } from '../SpaceProvider';
import * as E from 'fp-ts/Either';
import * as Y from 'yjs';
import { AssigneeFilterMode } from '../table/TaskAssigneeSelect';
import { PriorityFilterMode } from '../table/TaskPrioritySelect';
import { GetTasksMode } from '@saga/shared/src/SpaceOperations/getTasks';
import { ItemsFilterMode } from '../table/TableMultipleFilterSelect';

export function useTasks({
    mode,
    stateFilter = [],
    assigneeFilter,
    priorityFilter,
    labelsFilter,
    collectionsFilter,
    creatorsFilter,
    archivedFilter = 'all',
    search,
}: {
    mode: 'deep' | 'shallow';
    stateFilter?: WeakTask['state'][];
    assigneeFilter: AssigneeFilterMode;
    priorityFilter: PriorityFilterMode;
    labelsFilter?: ItemsFilterMode;
    collectionsFilter?: ItemsFilterMode;
    creatorsFilter?: ItemsFilterMode;
    archivedFilter?: GetTasksMode;
    search?: string;
}) {
    const { space } = useSpace();

    const yTasks = useMemo(() => unsafeRight(space.get('tasks')), [space]);
    const tasks = useSafeArray(yTasks, mode === 'deep');

    return useMemo(() => {
        const filteredTasks: WeakTask[] = [];

        tasks.array.forEach((task: Y.Map<any>) => {
            const stateFilterMatch = stateFilter.length === 0 || stateFilter.includes(task.get('state'));
            const assigneeFilterMatch = (() => {
                switch (assigneeFilter.kind) {
                    case 'all':
                        return true;
                    case 'unassigned':
                        return task.get('assignee') == null;
                    case 'assigned':
                        return task.get('assignee') === assigneeFilter.memberId;
                }
            })();
            const priorityFilterMatch = (() => {
                switch (priorityFilter) {
                    case 'all':
                        return true;
                    case 'unprioritized':
                        return task.get('priority') == null;
                    default:
                        return task.get('priority') === priorityFilter;
                }
            })();

            const labelsFilterMatch = (() => {
                switch (labelsFilter) {
                    case undefined:
                    case null:
                    case 'all':
                        return true;
                    case 'none':
                        return !task.get('labels')?.length;
                    default:
                        return task
                            .get('labels')
                            ?.toJSON()
                            .some((label: string) => labelsFilter.includes(label));
                }
            })();

            const collectionsFilterMatch = (() => {
                switch (collectionsFilter) {
                    case undefined:
                    case null:
                    case 'all':
                        return true;
                    case 'none':
                        return !task.get('collections')?.length;
                    default:
                        return task
                            .get('collections')
                            ?.toJSON()
                            .some((collection: string) => collectionsFilter.includes(collection));
                }
            })();

            const creatorsFilterMatch = (() => {
                switch (creatorsFilter) {
                    case undefined:
                    case null:
                    case 'all':
                        return true;
                    case 'none':
                        return !task.get('createdBy');
                    default:
                        return creatorsFilter.includes(task.get('createdBy'));
                }
            })();

            if (
                stateFilterMatch &&
                assigneeFilterMatch &&
                priorityFilterMatch &&
                labelsFilterMatch &&
                collectionsFilterMatch &&
                creatorsFilterMatch
            ) {
                const decoded = taskD.decode(task.toJSON());
                if (E.isRight(decoded)) {
                    switch (archivedFilter) {
                        case 'all':
                            filteredTasks.push(decoded.right);
                            break;
                        case 'non-deleted':
                            if (decoded.right.archivedAt == null) filteredTasks.push(decoded.right);
                            break;
                        case 'deleted':
                            if (decoded.right.archivedAt != null) filteredTasks.push(decoded.right);
                            break;
                    }
                }
            }
        });

        if (!search || search.trim() === '') return filteredTasks;

        return filteredTasks.filter((task) => task.title?.toLowerCase().includes(search.toLocaleLowerCase()));
    }, [
        tasks,
        stateFilter,
        assigneeFilter,
        search,
        priorityFilter,
        archivedFilter,
        labelsFilter,
        collectionsFilter,
        creatorsFilter,
    ]);
}

export function useNullableTask(id: string) {
    const { space } = useSpace();

    const [trigger, reload] = React.useReducer(() => ({}), {});
    const task = React.useMemo(() => {
        trigger;
        return SpaceOperations.findTask(space, id);
    }, [id, trigger, space]);

    React.useEffect(() => {
        if (task) {
            const yTasks = unsafeRight(space.get('tasks'));
            yTasks.array.observe(reload);
            const map = SpaceOperations.findTaskMap(space, task.id);
            assertNonNull(map);
            map.observeDeep(reload);
            return () => {
                map.unobserveDeep(reload);
                yTasks.array.unobserve(reload);
            };
        }

        return;
    }, [task, space]);

    return task;
}

export function useTaskMap(id: string) {
    const { space } = useSpace();
    return React.useMemo(() => SpaceOperations.findTaskMap(space, id), [id, space]);
}

export function useTask(id: string) {
    const taskResult = useNullableTask(id);
    assertNonNull(taskResult);
    return taskResult;
}
