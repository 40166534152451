import { Range } from 'slate';
import { Point } from 'slate';
import invariant from 'tiny-invariant';
import * as Y from 'yjs';
import { Cursor } from '..';
import { SharedType, SyncElement, SyncNode } from '../model';
import { getSyncNodePath, getTarget } from '../path';

export function absolutePositionToRelativePosition(sharedType: SharedType, point: Point): Y.RelativePosition {
    const target = getTarget(sharedType, point.path);
    const text = SyncElement.getText(target as SyncElement);
    invariant(text, 'Slate point should point to Text node');
    return Y.createRelativePositionFromTypeIndex(text, point.offset);
}

export function relativePositionToAbsolutePosition(
    sharedType: SharedType,
    relativePosition: Y.RelativePosition,
): Point | null {
    invariant(sharedType.doc, 'Shared type should be bound to a document');

    const pos = Y.createAbsolutePositionFromRelativePosition(relativePosition, sharedType.doc);

    if (!pos) {
        return null;
    }

    const parent = pos.type.parent as SyncNode;
    const path = getSyncNodePath(parent, sharedType);

    return {
        path,
        offset: pos.index,
    };
}

export function getRangeFromCursor(yBlocks: Y.Array<any>, cursor: Cursor): Range | null {
    let anchor = null;
    let focus = null;

    if (cursor.anchor) {
        anchor = relativePositionToAbsolutePosition(yBlocks, cursor.anchor);
    }

    if (cursor.focus) {
        focus = relativePositionToAbsolutePosition(yBlocks, cursor.focus);
    }

    if (!anchor || !focus) {
        return null;
    }

    return { anchor, focus };
}
