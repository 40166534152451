import { FeatureFlag } from '@/../../shared/src/utils/FeatureFlags';
import { isFeatureEnabled } from '@/analytics';
import { useOpenCopyWorkspaceModal } from '@/components/CopyWorkspaceModalProvider';
import { useCurrentWorkspace } from '@/components/WorkspaceContext';
import WorkspaceAvatar from '@/components/navigation/WorkspaceAvatar';
import Button from '@/components/styled/Button';
import Input from '@/components/styled/Input';
import useDeleteWorkspace from '@/hooks/useDeleteWorkspace';
import useLeaveWorkspace from '@/hooks/useLeaveSpace';
import { useSpaceAccess } from '@/hooks/useSpaceAccess';
import * as Popover from '@radix-ui/react-popover';
import * as api from '@saga/api';
import React, { useState } from 'react';
import { HexColorPicker } from 'react-colorful';
import { useTranslation } from 'react-i18next';
import SettingsPane from './SettingsPane';
import { useCloseSettings } from './SettingsProvider';
import { SettingsToggle } from './SettingsToggle';
import { useAutolinkEnabled } from '@/hooks/SpaceHooks';

function ColorPopover({
    color: defaultColor,
    onChangeColor,
    children,
}: {
    color?: string;
    onChangeColor(color?: string): void;
    children(color?: string): React.ReactNode;
}) {
    const [isOpen, setIsOpen] = React.useState(false);
    const [color, setColor] = React.useState(defaultColor);
    return (
        <Popover.Root
            open={isOpen}
            onOpenChange={(open) => {
                setIsOpen(open);
                if (!open) {
                    setColor(defaultColor);
                }
            }}
        >
            <Popover.Trigger>{children(color)}</Popover.Trigger>
            <Popover.Content align="start">
                <div className="p-2 relative shadow-popupSmall max-w-highlight rounded-md bg-white dark:bg-zinc-700 dark:border dark:border-zinc-600 pointer-events-auto">
                    <form
                        onSubmit={() => {
                            onChangeColor(color);
                            setIsOpen(false);
                        }}
                        className="space-y-2 flex flex-col items-center"
                    >
                        <HexColorPicker color={color} onChange={setColor} />
                        <Input
                            value={color}
                            type="text"
                            onChange={(e) => setColor(e.target.value)}
                            pattern="^#+([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$"
                        />
                        <Button.Plain type="submit" widthFull>
                            <Button.BasePadding>Save</Button.BasePadding>
                        </Button.Plain>
                    </form>
                </div>
            </Popover.Content>
        </Popover.Root>
    );
}

export function WorkspaceSettings() {
    const space = useCurrentWorkspace();
    const deleteSpace = useDeleteWorkspace();
    const leaveSpace = useLeaveWorkspace();
    const { isOwner } = useSpaceAccess();
    const { t } = useTranslation();

    const closeSettings = useCloseSettings();
    const [newTitle, setNewTitle] = useState(space.title);
    const openCopyWorkspaceModal = useOpenCopyWorkspaceModal();
    const [updateSpaceMetadata] = api.useUpdateSpaceMetadataMutation();

    const isOwnerCanLeaveSpace = space.members.filter((member) => member.role === api.Role.Owner).length > 1;

    const [autolinkEnabled, setAutolinkEnabled] = useAutolinkEnabled();

    return (
        <SettingsPane>
            <div>
                <SettingsPane.Title>{t('settings.general.title')}</SettingsPane.Title>
                <SettingsPane.Small>{t('settings.general.sub_title')}</SettingsPane.Small>
            </div>
            <SettingsPane.Content>
                {isOwner && (
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                            updateSpaceMetadata({ variables: { input: { title: newTitle, urlKey: space.urlKey } } });
                        }}
                    >
                        <SettingsPane.Section>
                            <fieldset className="max-w-sm">
                                <label>
                                    <div>{t('settings.general.workspace_name')}</div>
                                    <Input
                                        onChange={(e) => {
                                            const title = e.target.value;
                                            setNewTitle(title);
                                        }}
                                        type="text"
                                        placeholder={space.title}
                                        value={newTitle}
                                    />
                                </label>
                            </fieldset>

                            <SettingsPane.Button.Default
                                disabled={space.title === newTitle}
                                type="submit"
                                className="text-sm mt-2"
                            >
                                {t('common.update')}
                            </SettingsPane.Button.Default>
                        </SettingsPane.Section>
                    </form>
                )}
                {isOwner ? (
                    <>
                        <SettingsPane.SectionFlex className="items-center">
                            <div>
                                <h2>{t('settings.general.icon')}</h2>
                                <SettingsPane.Small>{t('settings.general.icon_description')}</SettingsPane.Small>
                            </div>
                            <div className="p-4">
                                <ColorPopover
                                    color={space.color ?? undefined}
                                    onChangeColor={(color) => {
                                        updateSpaceMetadata({
                                            variables: { input: { urlKey: space.urlKey, color } },
                                            refetchQueries: [api.UserDataDocument],
                                        });
                                    }}
                                >
                                    {(color) => <WorkspaceAvatar workspace={{ ...space, color }} />}
                                </ColorPopover>
                            </div>
                        </SettingsPane.SectionFlex>
                        <SettingsPane.SectionFlex>
                            <SettingsToggle
                                title={t('settings.general.autolink')}
                                description={t('settings.general.autolink_description')}
                                checked={autolinkEnabled}
                                onChange={setAutolinkEnabled}
                            />
                        </SettingsPane.SectionFlex>
                        {isFeatureEnabled(FeatureFlag.allowEmbeddings) ? (
                            <SettingsPane.SectionFlex>
                                <SettingsToggle
                                    title={t('settings.general.embeddings')}
                                    description={t('settings.general.embeddings_description')}
                                    checked={space.isEmbeddingEnabled}
                                    onChange={(isEmbeddingEnabled) => {
                                        updateSpaceMetadata({
                                            variables: {
                                                input: { isEmbeddingEnabled, urlKey: space.urlKey },
                                            },
                                        });
                                    }}
                                />
                            </SettingsPane.SectionFlex>
                        ) : null}
                        <SettingsPane.SectionFlex>
                            <div>
                                <h2>{t('settings.general.copy_workspace')}</h2>
                                <SettingsPane.Small>
                                    {t('settings.general.copy_workspace_description')}
                                </SettingsPane.Small>
                            </div>
                            <SettingsPane.Button onClick={openCopyWorkspaceModal} className="text-sm mt-2">
                                {t('settings.general.copy_workspace')}
                            </SettingsPane.Button>
                        </SettingsPane.SectionFlex>
                        {isOwnerCanLeaveSpace && (
                            <SettingsPane.SectionFlex>
                                <h2>{t('settings.general.leave_workspace')}</h2>
                                <SettingsPane.Button.Alert
                                    onClick={async () => {
                                        if (window.confirm(t('settings.general.leave_workspace_confirmation'))) {
                                            leaveSpace();
                                            closeSettings();
                                        }
                                    }}
                                    className="text-sm my-2.5"
                                >
                                    {t('settings.general.leave_workspace')}
                                </SettingsPane.Button.Alert>
                            </SettingsPane.SectionFlex>
                        )}
                        <SettingsPane.SectionFlex>
                            <div>
                                <h2>{t('settings.general.delete_workspace')}</h2>
                                <SettingsPane.Small>
                                    {t('settings.general.delete_workspace_description')}
                                </SettingsPane.Small>
                            </div>
                            <SettingsPane.Button.Alert
                                onClick={async () => {
                                    if (window.confirm(t('settings.general.delete_workspace_confirmation'))) {
                                        deleteSpace();
                                        closeSettings();
                                    }
                                }}
                                className="text-sm my-2.5"
                            >
                                {t('settings.general.delete_workspace')}
                            </SettingsPane.Button.Alert>
                        </SettingsPane.SectionFlex>
                    </>
                ) : (
                    <>
                        <SettingsPane.SectionFlex>
                            <h2>{t('settings.general.leave_workspace')}</h2>
                            <SettingsPane.Button.Alert
                                onClick={async () => {
                                    if (window.confirm(t('settings.general.leave_workspace_confirmation'))) {
                                        leaveSpace();
                                        closeSettings();
                                    }
                                }}
                                className="text-sm mt-2"
                            >
                                {t('settings.general.leave_workspace')}
                            </SettingsPane.Button.Alert>
                        </SettingsPane.SectionFlex>
                    </>
                )}
            </SettingsPane.Content>
        </SettingsPane>
    );
}
