import SidebarItemButton from '@/components/navigation/SidebarItemButton';
import Button from '@/components/styled/Button';
import useSidebarAnimation from '@/hooks/useSidebarAnimation';
import classNames from 'classnames';
import React, { forwardRef, useState } from 'react';
import { ChevronRight, Plus } from 'react-feather';
import { animated } from 'react-spring';

type ExpandableSidebarButtonProps = {
    isSelected: boolean;
    isExpandable: boolean;
    isExpanded: boolean;
    children: React.ReactNode;
    onClick(event: React.MouseEvent): void;
    onExpandClick(): void;
    expandButtonTestId?: string;
    isMobile: boolean;
    Icon: JSX.Element;
};

const chevron = <ChevronRight size={14} className="inline-block w-6 transform flex-none" />;

export default function SidebarExpandableMenu({ children }: { children: JSX.Element }) {
    return children;
}

SidebarExpandableMenu.Button = forwardRef<HTMLDivElement, ExpandableSidebarButtonProps>(
    function CollectionsExpandableCollectionsButton(
        { Icon, isSelected, isExpanded, isExpandable, children, onClick, onExpandClick, expandButtonTestId, isMobile },
        ref,
    ) {
        return (
            <SidebarItemButton.Container ref={ref}>
                {!isMobile && (
                    <div
                        className={classNames('absolute z-20', {
                            'group-hover:flex left-0': !isExpanded && isExpandable,
                            flex: isExpanded && isExpandable,
                            hidden: !isExpanded || !isExpandable,
                            'dark:hover:text-zinc-50 text-saga-blue-dark dark:text-zinc-200': isSelected,
                        })}
                    >
                        <SidebarExpandableMenu.ChevronButton
                            selected={isSelected}
                            onClick={onExpandClick}
                            isOpen={isExpanded}
                            testId={expandButtonTestId}
                        />
                    </div>
                )}
                <div className="max-h-8 w-full">
                    <SidebarItemButton isSelected={isSelected} onClick={onClick}>
                        <div className="h-6 flex items-center truncate">
                            <div
                                className={classNames('w-3.5 h-3.5 shrink-0 mr-2.5', {
                                    'group-hover:opacity-0': isExpandable,
                                    'opacity-0': isExpanded && isExpandable,
                                })}
                            >
                                {Icon}
                            </div>

                            <div className="min-w-0 font-medium text-sm">{children}</div>
                        </div>
                    </SidebarItemButton>
                </div>
            </SidebarItemButton.Container>
        );
    },
);

SidebarExpandableMenu.ChevronButton = function ChevronButton({
    isOpen,
    onClick,
    selected,
    testId,
}: {
    isOpen: boolean;
    onClick(): void;
    selected: boolean;
    testId?: string;
}) {
    return (
        <button
            className={classNames('h-6 py-1 pl-[3px] cursor-pointer outline-none flex items-center', {
                'text-saga-text-gray dark:text-zinc-400 hover:text-saga-gray-dark dark:hover:text-zinc-200': !selected,
            })}
            onClick={onClick}
            data-testid={testId}
        >
            <div
                className={classNames('flex items-center justify-center', {
                    'rotate-90': isOpen,
                })}
            >
                {chevron}
            </div>
        </button>
    );
};

SidebarExpandableMenu.Gap = function SidebarGap({
    children,
    active = true,
}: {
    children: React.ReactNode;
    active?: boolean;
}) {
    return <div className={classNames({ 'space-y-0.5': active })}>{children}</div>;
};

SidebarExpandableMenu.Indent = function SidebarIndent({ children }: { children: React.ReactNode }) {
    return <div className="pl-4">{children}</div>;
};

SidebarExpandableMenu.OpenCloseAnimation = React.memo(function SidebarMenuOpenCloseAnimation({
    isOpen,
    children,
}: {
    isOpen: boolean;
    children: React.ReactNode;
}) {
    const [show, setShow] = useState(false);
    const [childrenRef, { height, opacity, y }] = useSidebarAnimation(isOpen, () => {
        if (!isOpen) {
            setShow(false);
        }
    });

    React.useEffect(() => {
        if (isOpen) {
            setShow(true);
        }
    }, [isOpen]);

    return (
        <animated.div className="overflow-y-hidden" style={{ height, opacity }}>
            <animated.div ref={childrenRef} style={{ y }}>
                {show && children}
            </animated.div>
        </animated.div>
    );
});

SidebarExpandableMenu.HoverGroup = function SidebarExpandableMenuHoverGroup({
    children,
}: {
    children: React.ReactNode;
}) {
    return <div className="group">{children}</div>;
};

SidebarExpandableMenu.ShowOnHover = forwardRef<
    HTMLDivElement,
    {
        children: React.ReactNode;
        forceVisible?: boolean;
    }
>(function SidebarMenuShowOnHover({ children, forceVisible }, ref) {
    return (
        <div
            ref={ref}
            className={classNames({
                'opacity-0 group-hover:opacity-100 pointer-events-none group-hover:pointer-events-auto': !forceVisible,
            })}
        >
            {children}
        </div>
    );
});

SidebarExpandableMenu.PlusButton = forwardRef<HTMLButtonElement, { onClick(e: React.MouseEvent): void }>(
    function SidebarPlusButton({ onClick }, ref) {
        return (
            <Button.NestedButton ref={ref} onClick={onClick}>
                <Plus className="flex-none" size={12} />
            </Button.NestedButton>
        );
    },
);
