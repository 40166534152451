import findAllReferenceRegistryEntriesByPageId from './findAllReferenceRegistryEntriesByPageId';
import transactInSpace from './transactInSpace';
import { SafeSpace } from '../types';

export default function archiveAllReferenceRegistryEntriesByPageId(space: SafeSpace, pageId: string) {
    const entries = findAllReferenceRegistryEntriesByPageId(space, pageId);

    transactInSpace(space, () => {
        entries.forEach((yEntry) => {
            yEntry.set('isArchived', true);
        });
    });
}
