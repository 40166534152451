import { track } from '@/analytics';
import { useNullableSideBySideApi } from '@/components/SideBySide';
import { useSpace } from '@/components/SpaceProvider';
import { useCollectionsSnapshot, usePinned } from '@/hooks/SpaceHooks';
import useInterfaceSettings from '@/hooks/useInterfaceSettings';
import useMobile from '@/hooks/useMobile';
import { useSpaceAccess } from '@/hooks/useSpaceAccess';
import useWorkspaceSettings from '@/hooks/useWorkspaceSettings';
import * as Popover from '@radix-ui/react-popover';
import { Collection, removeNullable, SpaceOperations, WeakPage, newBlankPage, WeakTask } from '@saga/shared';
import * as R from 'ramda';
import React, { useEffect, useState } from 'react';
import { Plus } from 'react-feather';
import { useHistory } from 'react-router-dom';
import { useOpenCollection, useOpenPage, useOpenTask } from '../PageNavigationProvider';
import Tooltip from '../popover/Tooltip';
import { makeCollectionTableItems, sortCollectionTableItems } from '../table/CollectionTable';
import { useCurrentWorkspace } from '../WorkspaceContext';
import SidebarItemButton from './SidebarItemButton';
import { useTranslation } from 'react-i18next';
import { createPage } from '@/utils/documentUtils';
import SidebarExpandableMenu from '@/components/navigation/SidebarExpandableMenu';
import { CollectionIcon } from '@/components/icons';
import { dndTypes } from '@/constants';
import { useUserContext } from '../UserContext';
import { DndOverArea } from '@/types';
import CollectionContextMenuButton from '../popover/CollectionContextMenuButton';

type PageForCollection = Pick<
    WeakPage,
    'id' | 'title' | 'collections' | 'archivedAt' | 'icon' | 'isTemplate' | 'createdAt' | 'updatedAt'
>;

function Collections({
    selectedId,
    pages,
    tasks,
}: {
    selectedId?: string;
    pages: PageForCollection[];
    tasks: WeakTask[];
}) {
    const [isExpanded, setIsExpanded] = useState(localStorage.getItem('collectionsExpanded') === 'true' || false);
    const collections = useCollectionsSnapshot();
    const indexedCollections = R.indexBy(R.prop('id'), collections);
    const allSubCollectionIds = collections.map((collection) => collection.subCollections).flat();
    const topLevelCollections = collections.filter((collection) => !allSubCollectionIds.includes(collection.id));
    const isExpandable = topLevelCollections.length > 0;
    const goToCollection = useOpenCollection();
    const { canEdit } = useSpaceAccess();
    const isSelected = selectedId === 'collections';
    const isMobile = useMobile();
    const history = useHistory();
    const { urlKey } = useCurrentWorkspace();
    const sideBySideApi = useNullableSideBySideApi();
    const [, setInterfaceSettings] = useInterfaceSettings();
    const pinned = usePinned();
    const { user } = useUserContext();
    const { space } = useSpace();

    useEffect(() => {
        localStorage.setItem('collectionsExpanded', String(isExpanded));
    }, [isExpanded]);

    const onDrop = React.useCallback(
        (item: Collection, dropArea: DndOverArea, targetId?: string) => {
            if (item.id === targetId) return;
            if (!user) return;
            if (!targetId) {
                return SpaceOperations.addCollection(space, item);
            }
            SpaceOperations.moveCollection(space, item.id, {
                targetId,
                position: dropArea === 'bottom' ? 'after' : 'before',
            });
        },
        [space, user],
    );

    return (
        <SidebarExpandableMenu.Gap active={isExpanded}>
            <Collections.TopLevelCollectionsButton
                isExpandable={isExpandable}
                isExpanded={isExpanded}
                isSelected={isSelected}
                onClick={(event) => {
                    if (event.shiftKey) {
                        sideBySideApi?.openLocationOnIndex({ type: 'allCollections' }, 1);
                    } else {
                        history.push(`/s/${urlKey}/collections`);
                    }

                    if (isMobile) {
                        setInterfaceSettings({ fixedSidebar: false });
                    }
                }}
                onToggleExpansion={() => setIsExpanded(!isExpanded)}
                canEdit={canEdit}
            />
            <div
                className="overflow-y-auto hide-scrollbar"
                style={{ maxHeight: pinned.length === 0 ? 'calc(100vh - 320px)' : '24rem' }}
            >
                <SidebarExpandableMenu.OpenCloseAnimation isOpen={isExpanded}>
                    <SidebarExpandableMenu.Indent>
                        <SidebarExpandableMenu.Gap>
                            {canEdit && (
                                <SidebarItemButton.Draggable
                                    id="top-drop-area"
                                    enabled={true}
                                    dndType={dndTypes.SIDEBAR_COLLECTION}
                                    onDrop={(item: Collection) => onDrop(item, 'top', topLevelCollections[0]?.id)}
                                >
                                    <div className="h-1" />
                                </SidebarItemButton.Draggable>
                            )}
                            {topLevelCollections.map((collection) => {
                                return (
                                    <SidebarItemButton.Draggable
                                        dndType={dndTypes.SIDEBAR_COLLECTION}
                                        id={collection.id}
                                        key={collection.id}
                                        enabled={canEdit}
                                        onDrop={(item: Collection, dropArea: DndOverArea) => {
                                            onDrop(item, dropArea, collection.id);
                                        }}
                                    >
                                        <Collections.Tree
                                            key={collection.id}
                                            collection={collection}
                                            indexedCollections={indexedCollections}
                                            selectedId={selectedId}
                                            onCollectionClick={(collectionId, event) =>
                                                goToCollection(collectionId, event)
                                            }
                                            canEdit={canEdit}
                                            path={[collection.id]}
                                            openBlocksPrefix="collections"
                                            pages={pages}
                                            tasks={tasks}
                                            isMobile={isMobile}
                                        />
                                    </SidebarItemButton.Draggable>
                                );
                            })}
                            {canEdit && (
                                <SidebarItemButton.Draggable
                                    id="bottom-drop-area"
                                    enabled={true}
                                    dndType={dndTypes.SIDEBAR_COLLECTION}
                                    onDrop={(item: Collection) =>
                                        onDrop(item, 'bottom', topLevelCollections[topLevelCollections.length - 1]?.id)
                                    }
                                >
                                    <div className="h-1" />
                                </SidebarItemButton.Draggable>
                            )}
                        </SidebarExpandableMenu.Gap>
                    </SidebarExpandableMenu.Indent>
                </SidebarExpandableMenu.OpenCloseAnimation>
            </div>
        </SidebarExpandableMenu.Gap>
    );
}

Collections.TopLevelCollectionsButton = function CollectionsTopLevelCollectionsButton({
    isExpandable,
    isExpanded,
    isSelected,
    canEdit,
    onToggleExpansion,
    onClick,
}: {
    isExpandable: boolean;
    isExpanded: boolean;
    isSelected: boolean;
    canEdit: boolean;
    onToggleExpansion(): void;
    onClick(event: React.MouseEvent): void;
}) {
    const goToCollection = useOpenCollection();
    const [isOpen, setIsOpen] = useState(false);
    const isMobile = useMobile();
    const { space } = useSpace();
    const { t } = useTranslation();

    return (
        <SidebarExpandableMenu.HoverGroup>
            <Popover.Root open={isOpen} onOpenChange={setIsOpen}>
                <div className="relative flex items-center">
                    <>
                        <SidebarExpandableMenu.Button
                            Icon={<CollectionIcon icon={undefined} />}
                            isExpandable={isExpandable}
                            isExpanded={isExpanded}
                            isSelected={isSelected}
                            onClick={onClick}
                            onExpandClick={onToggleExpansion}
                            expandButtonTestId="expand-collections-button"
                            isMobile={isMobile}
                        >
                            {t('sidebar.collections')}
                        </SidebarExpandableMenu.Button>
                        {canEdit && !isMobile && (
                            <Popover.Trigger asChild>
                                <SidebarExpandableMenu.ShowOnHover>
                                    <div
                                        className="absolute z-20 right-0 top-0 bottom-0 flex items-center px-1"
                                        onClick={() => setIsOpen(true)}
                                    >
                                        <Tooltip placement="right" content={t('sidebar.create_collection')}>
                                            <div className="h-6 px-1 bg-white dark:bg-saga-gray-1000 flex flex-row cursor-pointer items-center text-xs leading-normal border-saga-gray-200 dark:border-zinc-500 border-2 font-semibold focus:outline-none active:shadow-xs rounded">
                                                <Plus
                                                    className="flex-none"
                                                    size={12}
                                                    onClick={(event) => {
                                                        const collection = SpaceOperations.createCollection(space, '');
                                                        track('new-collection', { source: 'sidebar-button' });
                                                        goToCollection(collection.id, event);
                                                    }}
                                                />
                                            </div>
                                        </Tooltip>
                                    </div>
                                </SidebarExpandableMenu.ShowOnHover>
                            </Popover.Trigger>
                        )}
                    </>
                </div>
            </Popover.Root>
        </SidebarExpandableMenu.HoverGroup>
    );
};

Collections.Tree = function CollectionsTree({
    collection,
    indexedCollections,
    selectedId,
    onCollectionClick,
    canEdit,
    path,
    openBlocksPrefix,
    pages,
    tasks,
    isMobile,
    showContextMenu = false,
    autoExpand = false,
}: {
    collection: Collection;
    indexedCollections: Record<string, Collection>;
    selectedId?: string;
    onCollectionClick(collectionId: string, event: React.MouseEvent): void;
    canEdit: boolean;
    path: string[];
    openBlocksPrefix?: string;
    pages: Pick<
        WeakPage,
        'collections' | 'title' | 'id' | 'icon' | 'archivedAt' | 'createdAt' | 'updatedAt' | 'isTemplate'
    >[];
    tasks: WeakTask[];
    isMobile: boolean;
    showContextMenu?: boolean;
    autoExpand?: boolean;
}) {
    const targetRef = React.useRef<HTMLDivElement>(null);

    const isSelected = selectedId === collection.id;
    const [{ openBlocks, defaultExpandedCollection }, updateUserWorkspaceSettings] = useWorkspaceSettings();

    const openBlockId = openBlocksPrefix ? `${openBlocksPrefix}:${collection.id}` : collection.id;
    const { space, provider } = useSpace();
    const openPage = useOpenPage();
    const openTask = useOpenTask();

    const { t } = useTranslation();

    const subCollections = collection.subCollections ?? [];

    const [isExpanded, setIsExpanded] = useState(
        autoExpand &&
            (openBlocks.includes(openBlockId) ||
                (collection.defaultExpanded && !defaultExpandedCollection.find((c) => c.id && !c.expanded)) ||
                defaultExpandedCollection.some((c) => c.id === collection.id && c.expanded)),
    );

    const filteredPages = React.useMemo(
        () => pages.filter((page) => page.collections.includes(collection.id)),
        [pages, collection.id],
    );
    const filteredTasks = React.useMemo(
        () => tasks.filter((task) => task.collections?.includes(collection.id)),
        [tasks, collection.id],
    );

    const previousPath = path.slice(0, path.length - 1);
    const isExpandable =
        (filteredPages.length > 0 || filteredTasks.length > 0 || subCollections.length > 0) &&
        !previousPath.includes(collection.id);

    const items = React.useMemo(() => {
        const collections = collection.subCollections?.map((id) => indexedCollections[id]).filter(removeNullable) ?? [];

        return sortCollectionTableItems(
            collection.sorting ?? null,
            makeCollectionTableItems(filteredPages, collections, filteredTasks),
            collection.id,
        );
    }, [filteredPages, filteredTasks, collection, indexedCollections]);

    const toggle = () => {
        const expanded = !isExpanded;
        updateUserWorkspaceSettings({
            openBlocks: isExpanded ? openBlocks.filter((id) => id !== openBlockId) : [...openBlocks, openBlockId],
            defaultExpandedCollection: defaultExpandedCollection.some((c) => c.id === collection.id)
                ? defaultExpandedCollection.map((c) => (c.id === collection.id ? { ...c, expanded } : c))
                : [...defaultExpandedCollection, { id: collection.id, expanded }],
        });
        setIsExpanded(expanded);
    };

    return (
        <SidebarExpandableMenu.Gap active={isExpanded}>
            <Tooltip content={collection.title} placement="right" disabled={!collection.title}>
                <div className="flex items-center group relative">
                    <SidebarExpandableMenu.Button
                        Icon={<CollectionIcon icon={collection.icon} />}
                        isExpandable={isExpandable}
                        isExpanded={isExpanded}
                        isSelected={isSelected}
                        onExpandClick={toggle}
                        onClick={(event) => (!isSelected ? onCollectionClick(collection.id, event) : null)}
                        isMobile={isMobile}
                    >
                        <div className="truncate leading-normal text-sm">{collection.title}</div>
                    </SidebarExpandableMenu.Button>

                    {!isMobile && canEdit && collection.title && (
                        <SidebarExpandableMenu.ShowOnHover ref={targetRef}>
                            <div className="absolute z-20 right-0 top-0 bottom-0 flex items-center px-1 space-x-1">
                                {showContextMenu && (
                                    <CollectionContextMenuButton
                                        collectionId={collection.id}
                                        isButtonSmall
                                        label="Open Collection Context Menu"
                                    />
                                )}

                                <Tooltip placement="right" content={t('collections.add_page_to_collection')}>
                                    <SidebarExpandableMenu.PlusButton
                                        onClick={(event) => {
                                            const page = createPage(space, newBlankPage({}), provider);
                                            SpaceOperations.addPageToCollection(space, page.id, collection.id);
                                            track('create-page', { source: 'sidebar-inner-collection-plus-button' });
                                            openPage(page.id, event);
                                        }}
                                    />
                                </Tooltip>
                            </div>
                        </SidebarExpandableMenu.ShowOnHover>
                    )}
                </div>
            </Tooltip>

            <SidebarExpandableMenu.OpenCloseAnimation isOpen={isExpanded}>
                <SidebarExpandableMenu.Indent>
                    <SidebarExpandableMenu.Gap>
                        {isExpanded &&
                            items.map((item) => {
                                switch (item.type) {
                                    case 'collection':
                                        return (
                                            <Collections.Tree
                                                key={item.collection.id}
                                                selectedId={selectedId}
                                                collection={item.collection}
                                                indexedCollections={indexedCollections}
                                                onCollectionClick={onCollectionClick}
                                                canEdit={canEdit}
                                                path={[...path, item.collection.id]}
                                                openBlocksPrefix={`${path.join(':')}:${openBlocksPrefix}`}
                                                pages={pages}
                                                tasks={tasks}
                                                isMobile={isMobile}
                                            />
                                        );
                                    case 'page': {
                                        const isSelected = selectedId === item.id;

                                        return (
                                            <Tooltip
                                                placement="right"
                                                content={item.page.title}
                                                key={item.id}
                                                disabled={!item.page.title}
                                            >
                                                <SidebarItemButton.PageButton
                                                    isSelected={isSelected}
                                                    page={item.page}
                                                    onClick={(event) => {
                                                        openPage(item.id, event);
                                                    }}
                                                    showContextMenu={canEdit}
                                                >
                                                    <div className="truncate leading-normal text-sm">{item.title}</div>
                                                </SidebarItemButton.PageButton>
                                            </Tooltip>
                                        );
                                    }
                                    case 'task': {
                                        const isSelected = selectedId === item.id;

                                        return (
                                            <Tooltip
                                                placement="right"
                                                content={item.task.title}
                                                key={item.id}
                                                disabled={!item.task.title}
                                            >
                                                <SidebarItemButton.TaskButton
                                                    isSelected={isSelected}
                                                    task={item.task}
                                                    onClick={(event) => {
                                                        openTask(item.id, event);
                                                    }}
                                                    showContextMenu={canEdit}
                                                >
                                                    <div className="truncate leading-normal text-sm">{item.title}</div>
                                                </SidebarItemButton.TaskButton>
                                            </Tooltip>
                                        );
                                    }
                                }
                            })}
                    </SidebarExpandableMenu.Gap>
                </SidebarExpandableMenu.Indent>
            </SidebarExpandableMenu.OpenCloseAnimation>
        </SidebarExpandableMenu.Gap>
    );
};

export default Collections;
