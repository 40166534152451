import useMeasure from 'react-use-measure';
import { useSpring } from 'react-spring';
import { ResizeObserver } from '@juggle/resize-observer';

const useSidebarAnimation = (isOpen: boolean, onRest?: () => void): any => {
    const [ref, { height: viewHeight }] = useMeasure({ polyfill: ResizeObserver });
    const { height, opacity, y } = useSpring({
        from: { height: 0, opacity: 0, y: 0 },
        to: {
            height: isOpen ? viewHeight : 0,
            opacity: isOpen ? 1 : 0,
            y: isOpen ? 0 : 20,
        },
        onRest,
    });

    return [
        ref,
        {
            height: isOpen ? 'auto' : height,
            opacity,
            y,
            display: isOpen ? 'block' : 'none',
        },
    ];
};

export default useSidebarAnimation;
