import useOnEscape from '@/hooks/useOnEscape';
import * as api from '@saga/api';
import React, { useState } from 'react';
import { Loader } from 'react-feather';
import { useUserContext } from '../UserContext';
import Button from '../styled/Button';
import { PlanCard, PlanCardActions } from './PlanCard';
import { Trans, useTranslation } from 'react-i18next';
import classNames from 'classnames';
import useMobile from '@/hooks/useMobile';
import { FREE_SPACES_LIMIT } from '@/constants';

export function NewWorkspaceModal({ onClose, action }: { onClose?: () => void; action: PlanCardActions }) {
    useOnEscape(() => onClose?.(), true);

    const { data, loading: loadingSubscriptionPlans } = api.useSubscriptionPlansQuery();
    const [isDisabled, setIsDisabled] = useState(false);
    const { t } = useTranslation();
    const isMobile = useMobile();

    const { permissions } = useUserContext();
    useOnEscape(() => onClose?.(), true);

    return (
        <div className="overflow-auto max-h-screen relative p-7 rounded border border-saga-gray-200 bg-white dark:bg-zinc-700 dark:text-zinc-200 text-saga-text w-full">
            <div
                className={classNames('font-bold text-4xl mb-2', {
                    'pt-5': isMobile,
                })}
            >
                {t('billing.modal_plans.title')}
            </div>
            {!permissions.canCreateFreeSpace && (
                <p className="mb-2 font-sm text-saga-gray-500">
                    <Trans
                        i18nKey="billing.modal_plans.sub_title"
                        count={FREE_SPACES_LIMIT}
                        components={{ bold: <span className="font-bold" /> }}
                    />
                </p>
            )}

            {loadingSubscriptionPlans || !data ? (
                <div className="flex justify-center h-16 items-center rounded space-x-2">
                    <Loader className="animate-spin" />
                    <div>{t('billing.modal_plans.loading_plans')}</div>
                </div>
            ) : (
                <>
                    <div
                        className={classNames('flex flex-wrap space-x-6 py-4', {
                            'flex-col items-center space-x-0': isMobile,
                        })}
                    >
                        <PlanCard.FreePlan
                            subscriptionPlan={data.subscriptionPlans[0]}
                            disabled={isDisabled}
                            setDisabled={setIsDisabled}
                            action={action}
                        />
                        <PlanCard.StandardPlan
                            subscriptionPlan={data.subscriptionPlans[1]}
                            disabled={isDisabled}
                            setDisabled={setIsDisabled}
                            action={action}
                        />
                        <PlanCard.BusinessPlan subscriptionPlan={data.subscriptionPlans[2]} />
                    </div>
                    <p className="w-full text-saga-gray-500 text-center">
                        {t('billing.modal_plans.footer_disclaimer')}
                        <br />
                        <Trans
                            dangerouslySetInnerHTML
                            i18nKey="billing.modal_plans.sagaAi_disclaimer"
                            components={{
                                fairUsageLink: (
                                    <a
                                        href="https://saga.so/guides/saga-ai-faqs"
                                        rel="noreferrer noopener"
                                        target="_blank"
                                        className="text-saga-new-blue"
                                    >
                                        {t('billing.modal_plans.fair_usage')}
                                    </a>
                                ),
                            }}
                        />
                        <br />
                        <Trans
                            dangerouslySetInnerHTML
                            i18nKey="billing.modal_plans.footer_contacts"
                            components={{
                                link: (
                                    <a href="mailto:billing@saga.so" className="text-saga-new-blue">
                                        billing@saga.so
                                    </a>
                                ),
                            }}
                        />
                    </p>
                </>
            )}

            {onClose && (
                <div
                    className={classNames('absolute top-0 right-0 m-2', {
                        'top-10': isMobile,
                    })}
                >
                    <Button.XButton onClick={onClose} />
                </div>
            )}
        </div>
    );
}
