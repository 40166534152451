import { prompt } from '../../../shared/src/aiPrompts.json';

export default {
    translation: {
        common: {
            open_saga: 'Open Saga',
            popover_placeholder: 'Enter text or @ for',
            ai: 'AI',
            commands: 'commands',
            and: 'and',
            or: 'or',
            in: 'in',
            add_dotted: 'Add...',
            turn_into: 'Turn into',
            plus_template: '+ Template',
            plus_collection: '+ Collection',
            plus_alias: '+ Alias',
            new_alias_placeholder: 'Type a new Alias...',
            create_alias: '&nbsp;— Create Alias',
            remove_alias: 'Remove {{alias}}',
            add_template_title: 'Select a template',
            add_template_placeholder: 'Search for a template...',
            open: 'Open',
            mark_as_complete: 'Mark as complete',
            open_in_new_tab: 'Open in new Tab',
            add_to_pinned: 'Add to Pinned',
            add_to_favorites: 'Add to Favorites',
            remove_from_pinned: 'Remove from Pinned',
            remove_from_favorites: 'Remove from Favorites',
            create: 'Create',
            create_workspace_title: 'Create a workspace',
            create_workspace_description:
                'A workspace is where your pages, ideas, and knowledge connect and get organized.',
            create_workspace_placeholder: 'My Workspace',
            save: 'Save',
            edit: 'Edit',
            cancel: 'Cancel',
            reload: 'Reload',
            reset: 'Reset',
            logout: 'Logout',
            login: 'Login',
            learn_more: 'Learn more',
            free_plan: 'Free plan',
            upgrade: 'Upgrade',
            show_me: 'Show me',
            got_it: 'Ok, got it.',
            dismiss: 'Dismiss',
            do_this_later: 'Do this later',
            get_started: 'Get Started',
            dates: 'Dates',
            link_to_collection: 'Link to a Collection',
            insert: 'Insert',
            recents: 'Recents',
            google_drive: 'Google Drive',
            linear_issues: 'Linear Issues',
            tasks: 'Tasks',
            table_commands: 'Table Commands',
            mention_members: 'Mention Members',
            insert_page_link_to: 'Insert Page Link to',
            link_to_page: 'Link to Page',
            gdrive_file: 'Google Drive File',
            linear_issue: 'Linear Issue',
            new_template: 'New Template',
            create_template: 'Create Template',
            create_template_placeholder: 'Enter a template name...',
            edit_template: 'Edit Template',
            use_template: 'Use Template',
            recent_templates: 'Recent Templates',
            create_page_from_template: 'Create page from template',
            page_created: 'Page Created',
            has_been_created: 'has been created',
            blocks_moved: 'Blocks moved',
            selected_blocks_have_been_moved: 'Selected blocks have been moved to:',
            selected_blocks_have_been_turned: 'Selected blocks have been turned into a new page:',
            live_block_details: 'A Live Block of the selected blocks has been created in:',
            live_block_created: 'Live Block Created',
            duplicate: 'Duplicate',
            move_to: 'Move to',
            duplicate_page: 'Duplicate Page',
            duplicate_task: 'Duplicate Task',
            copy_page: 'Copy Page',
            undo: 'Undo',
            is_mac: '⌘ + Z',
            is_windows: 'Ctrl + Z',
            export: 'Export',
            delete: 'Delete',
            update: 'Update',
            continue: 'Continue',
            upgrade_plan: 'Upgrade plan',
            see_plans: 'Manage plan',
            delete_page: 'Delete Page',
            remove_from_page: 'Remove from page',
            first_name: 'First Name',
            last_name: 'Last Name',
            open_workspace: 'Open Workspace',
            open_page: 'Open Page',
            quick_edit: 'Quick edit',
            open_full_page: 'Open full page',
            close: 'Close',
            close_popup: 'Close Popup',
            close_entity: 'Close {{entity}}',
            close_modal: 'Close modal',
            close_quick_edit_modal: 'Close quick edit modal',
            open_notifications: 'Open Notifications',
            close_notifications: 'Close Notifications',
            close_sidebar: 'Close Sidebar',
            close_task_context_menu: 'Close Task Context Menu',
            close_context_menu: 'Close Context Menu',
            open_context_menu: 'Open Context Menu',
            open_task_context_menu: 'Open Task Context Menu',
            close_sidebar_context_menu: 'Close Sidebar Context Menu',
            open_sidebar: 'Open Sidebar',
            open_sidebar_context_menu: 'Open Sidebar Context Menu',
            open_page_context_menu: 'Open Page Context Menu',
            close_page_context_menu: 'Close Page Context Menu',
            open_image_context_menu: 'Open Image Context Menu',
            close_property_menu: 'Close Property Menu',
            open_property_menu: 'Open Property Menu',
            close_pages_context_menu: 'Close Pages Context Menu',
            open_pages_context_menu: 'Open Pages Context Menu',
            open_in_codeSandbox: 'Open in CodeSandbox',
            open_context_menu_for_column: 'Open Context Menu for Column {{column}}',
            open_context_menu_for_row: 'Open Context Menu for Row {{row}}',
            back_to_my_saga: 'Back to my Saga',
            referenced_in_pages: 'Referenced in Pages',
            see_pages_that_reference: 'See pages that reference the block:',
            remove_icon: 'Remove Icon',
            add_icon: 'Add Icon',
            change_icon: 'Change Icon',
            remove_callout: 'Remove Callout',
            click_add_image: 'Click to add an image',
            click_add_image_or_embed: 'Click to add an image or <1>embed from URL</1>',
            image_could_not_be_loaded: 'Image could not be loaded.',
            select_or_search: 'Select or search',
            text_copied: 'Text copied',
            copy_text: 'Copy text',
            prompt_copied: 'Prompt copied',
            copy_prompt: 'Copy prompt',
            stop: 'Stop',
            select: 'Select',
            search_in_pages: 'Search in pages',
            navigation: 'Navigation',
            members: 'Members',
            pages: 'Pages',
            collections: 'Collections',
            actions: 'Actions',
            create_new_page: 'Create new page',
            create_live_block: 'Create Live Block',
            create_live_block_description: 'Reference this selection in another page',
            create_live_block_disabled: 'Creating a Live Block is not possible with this selection.',
            remove_image: 'Remove Image',
            is_in: 'is in',
            template: 'Template',
            view_references: 'View References',
            try_saga: 'Try Saga',
            search: 'Search',
            report_page: 'Report Page',
            paste_as_link: 'Paste as Link',
            paste_as_preview: 'Paste as Link Preview',
            paste_as_google_drive: 'Paste as Google Drive Link',
            paste_as_linear_link: 'Paste as Linear Link',
            paste_as_text: 'Paste as text',
            paste_as_live_block: 'Paste as Live Block',
            logged_in_with: 'You are logged in with',
            log_in: 'Log in',
            gain_access: 'in with a different email, or contact an owner of this workspace to gain access.',
            highlighted_content: {
                text: 'Font Color',
                background: 'Text Highlight',
            },
            properties_tooltip:
                'Properties are customizable fields, such as text, tags and numbers which you can add to your pages in order to provide more detailed context.',
            confirm_changes: 'Confirm changes',
            you: 'You',
            mentioned_by: 'Mentioned by',
            mentioned_on: 'Mentioned on',
            copy: 'Copy',
        },
        inapp_notifications: {
            title: 'Saga 1.0',
            description:
                'We are officially out of beta! Read the release for more information on what we are building next.',
        },
        invite_onboarding: {
            create_button: 'Create new workspace',
            join_button: 'Join Workspace',
            join_title: 'Join or create a workspace',
            invited_description: 'You have not been invited to any workspaces.',
            create_space_title: 'Create your own workspace instead.',
            invited_description_one: 'You are invited to:',
            invited_description_other: 'You are invited to:',
            member_one: 'member',
            member_other: 'members',
        },
        editor: {
            loading_preview_dotted: 'Loading the preview...',
            edit_link: 'Edit Link',
            link_to: 'Link to',
            link_copied: 'Link copied',
            copy_Link: 'Copy Link',
            turn_into: 'Turn into',
            link_into_pretty_link: 'Turn link into pretty-link',
            link_into_embed: 'Turn link into embed',
            move_to_page: 'Move to page',
            create_live_block: 'Create Live Block',
            add_live_block: 'Add as Live Block to page',
            create_template: 'Create Template',
            edit_template: 'You are editing a template.',
            view_templates: 'View All Templates',
            create_page: 'Create Page',
            create_collection: 'Create Collection',
            create_task: 'Create Task',
            all_templates: 'All templates',
            create_issue_in: 'Issue in {{teamName}}',
            turn_into_link: 'Turn into link',
            turn_into_link_preview: 'Turn into Link Preview',
            turn_into_embed: 'Turn into Embed',
            embed_context_menu: 'Embed Context Menu',
            loading_embed: 'Loading the embed...',
            remove_link: 'Remove Link',
            remove_embed: 'Remove Embed',
            link_preview_not_available: 'The link preview is not available.',
            turn_into_page: 'Page <1>{{value}}</1>',
            turn_into_page_link: 'Turn into Page Link',
            turn_into_task: 'Task <1>{{value}}</1>',
            turn_into_liner_issue: 'Linear Issue <1>{{value}} in {{team}}</1>',
            turn_into_liner_issue_tooltip: 'Linear Issue {{value}} in {{team}}',
            copy_link_to_block: 'Copy link to block',
            delete_block: 'Delete block',
            block_deleted: 'Block deleted',
            quick_edit: 'Quick edit',
            empty_page: 'Empty Page',
            format_text: 'Text',
            format_todo: 'To-Do List',
            format_task: 'Task',
            format_heading_l: 'Heading — Large',
            format_heading_m: 'Heading — Medium',
            format_heading_s: 'Heading — Small',
            format_ul: 'Bullet Point',
            format_ol: 'Numbered List',
            format_img: 'Image',
            format_file: 'Upload File',
            format_table: 'Table',
            format_callout: 'Callout',
            format_quote: 'Quote',
            format_divider: 'Divider',
            format_code: 'Code',
            format_equation: 'Equation',
            format_inline_equation: 'Inline Equation',
            suffix_create_task: '— Create Task',
            suffix_create_issue_in: '— Issue in {{team}}',
            insert_page_link_to: 'Insert Page Link to {{title}}',
            insert_recent_page_link_to: 'Insert Recent Page Link to {{title}}',
            insert_column_left: 'Insert Column Left',
            insert_column_right: 'Insert Column Right',
            insert_row_above: 'Insert Row Above',
            insert_row_below: 'Insert Row Below',
            placeholder: {
                h1: 'Heading 1',
                h2: 'Heading 2',
                h3: 'Heading 3',
                task: 'Task',
                list: 'List',
                quote: 'Quote',
                enter_text: 'Enter text ',
                enter_text_dotted: 'Enter text...',
            },
            confirm_table_delete: 'Are you sure you want to delete the selected table?',
            file_upload: {
                click_to_upload_file: 'Click to upload a file',
                uploading: 'Uploading...',
                download_file: 'Download File',
                remove_file: 'Remove File',
                close_file_context_menu: 'Close File Context Menu',
                open_file_context_menu: 'Open File Context Menu',
                max_file_size_error: 'The maximum file size is {{size}}.',
            },
            actions: 'Actions',
        },
        update_references_modal: {
            title: 'Update links to this page?',
            description_one:
                'Renaming this page from <span_red>{{-previousTitle}}</span_red> to <span_yellow>{{-newTitle}}</span_yellow> will break <span_bold>{{count}}</span_bold> link to this page. Do you want to update them?',
            description_other:
                'Renaming this page from <span_red>{{-previousTitle}}</span_red> to <span_yellow>{{-newTitle}}</span_yellow> will break <span_bold>{{count}}</span_bold> links to this page. Do you want to update them?',
            select_all: 'Select All',
            deselect_all: 'Deselect All',
            update_links: 'Update Links',
            update_link_one: 'Update {{count}} link',
            update_link_other: 'Update {{count}} links',
            dont_update: "Don't update",
        },
        updated_title_notice: {
            outdated_link_one: 'There is <bold>{{count}}</bold> outdated link to this page.',
            outdated_link_other: 'There are <bold>{{count}}</bold> outdated links to this pages.',
            review: 'Review',
            update_all: 'Update all',
        },
        ai: {
            title: 'Saga AI',
            ask_ai: 'Ask AI',
            ask_ai_anything_mentions_dotted: 'Ask AI anything or @ for mentions...',
            ask_ai_anything_dotted: 'Ask AI anything...',
            rewrite: 'Rewrite',
            discard: 'Discard',
            rerun: 'Re-run',
            keep: 'Keep',
            insert_below: 'Insert below',
            replace: 'Replace',
            generating_dotted: 'Generating...',
            searching_space_dotted: 'Searching in space...',
            searching_page_dotted: 'Searching in page...',
            too_many_requests: "You've sent too many requests. Try again in a few seconds.",
            too_many_prompt_tokens:
                "You've reached the maximum conversation length for this AI response. <1>Learn more</1>",
            reduce_prompt_error: 'Please shorten the prompt to a maximum of {{max_length}} characters',
            restricted: "You've reached the fair usage monthly quota for Saga AI. <1>Learn more</1>",
            issue_with_generating: 'There was an issue with generating your text.',
            edit_selection: 'Edit selection',
            change_tone: 'Change tone',
            translate: 'Translate',
            draft: 'Draft',
            discard_response: 'Do you want to discard the AI response?',
            my_prompts: 'My Prompts',
            my_commands: 'My Commands',
            search_or_select: 'Search or select',
            mention: 'Mention a page or task',
            suggestion: {
                rewrite: 'Rewrite',
                continue: 'Continue writing',
                summarize: 'Summarize',
                minimize: 'Make shorter',
                maximize: 'Make longer',
                explain: 'Explain',
                fix: 'Fix spelling & grammar',
                tone_concise: 'Concise',
                tone_professional: 'Professional',
                tone_casual: 'Casual',
                tone_funny: 'Funny',
                translate_en: 'English',
                translate_es: 'Spanish',
                translate_fr: 'French',
                translate_de: 'German',
                translate_pt: 'Portuguese',
                translate_it: 'Italian',
                translate_nl: 'Dutch',
                translate_zh: 'Chinese',
                translate_ko: 'Korean',
                translate_ja: 'Japanese',
                translate_ua: 'Ukranian',
                translate_ru: 'Russian',
                brainstorm: 'Brainstorm ideas',
                brainstorm_about: prompt.brainstorm_about.en,
                outline: 'Outline',
                outline_about: prompt.outline_about.en,
                email: 'Email',
                email_about: prompt.email_about.en,
                meeting: 'Meeting agenda',
                meeting_about: prompt.meeting_about.en,
                blog: 'Blog post',
                blog_about: prompt.blog_about.en,
                social: 'Social media post',
                social_about: prompt.social_about.en,
                website: 'Website copy',
                website_about: prompt.website_about.en,
                cover_letter: 'Cover letter',
                cover_letter_about: prompt.cover_letter_about.en,
                press: 'Press release',
                press_about: prompt.press_about.en,
                job: 'Job description',
                job_about: prompt.job_about.en,
                essay: 'Essay',
                essay_about: prompt.essay_about.en,
            },
            chat: {
                tooltip: 'Open AI chat',
                button: 'AI Chat',
                initial: {
                    greeting: 'Hey {{name}}, what can I help with?',
                    bullet1:
                        'Chat with me to explore topics, answer questions, or create content from your Saga notes.',
                    bullet2: "Ask anything and I'll use your notes for answers.",
                    bullet3: 'The more you add to Saga, the more I can help!',
                },
                new_thread: 'New thread',
                history: 'History',
                no_threads: "You don't have any threads yet. Start a new one to get started.",
            },
        },
        errors: {
            cant_open_page: 'It seems like we are having troubles opening this page within Saga.',
            something_went_wrong: 'Something went Wrong',
            something_went_wrong_description: 'Welp, this was not expected.\nPlease try again.',
            something_went_wrong_try_again:
                '🤔 Something went wrong, Please try again\nIf the problem persist, contact us',
            connection_lost_title: 'Connection Lost',
            connection_lost_description:
                'Your connection was closed due to inactivity.\nPlease reload Saga to reconnect.',
        },
        side_by_side: {
            open: 'Open Side-by-Side',
            tooltip: 'Open the page next to the current page',
            open_in_new_tab: 'Open in a new tab',
            copy_link: 'Copy link to clipboard',
        },
        time: {
            years_ago: '{{years}} years ago',
            months_ago: '{{months}} months ago',
            weeks_ago: '{{weeks}} weeks ago',
            days_ago: '{{days}} days ago',
            date: 'Date',
            today: 'Today',
            yesterday: 'Yesterday',
            tomorrow: 'Tomorrow',
            last_year: 'Last year',
            last_week: 'Last week',
            last_month: 'Last month',
            next_period: 'Next {{label}} - {{date}}',
            last_period: 'Last {{label}} - {{date}}',
        },
        tutorial: {
            finishButton: 'Start your Saga',
            writeWthEase: {
                title: 'Write with ease',
                description: "Saga's fast and simple editor helps you craft beautiful notes and documents.",
            },
            autolink_pages: {
                title: 'Autolink Pages',
                description: 'Saga recognizes when you mention a page title and automatically creates a link.',
            },
            autocomplete_menu: {
                title: 'The Autocomplete Menu',
                description:
                    '<1>Use the autocomplete menu to insert blocks and link to pages.</1><2>Type @ or / in the editor to try it out.</2>',
            },
            saga_ai: {
                title: 'Saga AI',
                description:
                    'The intelligent assistant answers questions, generates content, and helps you get work done faster.',
            },
            unified_search: {
                title: 'Unified Search',
                description:
                    '<1>Quickly jump between pages or search across your workspace.</1><2>Press {{searchHeading}} to try it out.</2>',
            },
        },
        onboarding: {
            complete_signup: 'Complete the sign-up',
            start_your_saga: "Let's start your Saga",
            i_agree_with_toc: "I agree to Saga's <1>Privacy Policy</1> and <2>Terms of Service</2>",
            create_first_workspace: 'Create your first workspace',
            create_workspace: 'Create a workspace',
            create_workspace_description:
                'A workspace is where your pages, ideas, and knowledge connect and get organized.',
            what_is_this_workspace_for: 'What is this workspace for?',
            usecase_personalNotes: 'Personal notes',
            usecase_teamWork: 'Team work',
            open_workspace: 'Open Workspace',
            what_is_this_workspace: 'What is this workspace for?',
            login: {
                title: "Let's start your Saga",
                with_google: 'Continue with Google',
                with_email: 'Continue with Email',
                link_has_expired:
                    '🤔 Your link has expired, or you are trying to login from a different device.\n\nPlease sign in again 👇',
                toc: 'By clicking "Continue with Google/Email" above, you agree to our <1>Privacy Policy</1> and <2>Terms of Service</2>.',
                info: "We'll sign you in or create an account if you don't have one yet.",
                desktop_app_button: 'Download the desktop app',
            },
            magic_link_login: {
                title: '📨 Check your inbox',
                subtitle: 'We just sent you a sign in link at this email address:',
                enter_email: 'Enter your email address...',
                subtitle_email_for_join: 'We just sent you a sign in link at this email address to join the workspace:',
                welcome_to: 'Welcome to {{-workspace}}',
                welcome_to_page: 'Welcome to {{-page}}',
                close_this_tab: 'You can now close this tab.',
                invite_for_join: 'This is an invite for <1>{{email}}</1>.\nLog in with the same address to accept it.',
                invite_not_active: 'This invite is not active anymore',
                request_new_invite_short: 'Contact an owner of this workspace and request a new invite.',
                request_new_page_invite_short: 'Contact an admin of this page and request a new invite.',
                not_have_access: 'You do not have access to this workspace',
                not_have_page_access: 'You do not have access to this page',
                logged_in_with_different_email:
                    'You are logged in with <1>{{email}}</1>. <2>Log in</2> with a different email or contact an owner of this workspace to request access.',
                logged_in_with_different_email_page:
                    'You are logged in with <1>{{email}}</1>. <2>Log in</2> with a different email or contact an admin of this page to request access.',
                request_new_invite:
                    'Contact an owner of this workspace and request a new invite.\nGo back to the <1>Home Page</1> and create your own workspace instead',
            },
        },
        help: {
            give_feedback: 'Give Feedback',
            give_feedback_tooltip:
                '🚀 You are one of the Saga pioneers (joined {{userCreatedDate}}). We are working hard to ship improvements and new features at lightspeed, if you have feedback for us, send us an email at feedback@saga.so',
            contact_us: 'Contact us',
            join_slack: 'Join on Slack',
            join_discord: 'Join on Discord',
            youtube_channel: 'Youtube Channel',
            tweet_us: 'Tweet us',
            open_website: 'Open Website',
            help_guide: 'Help Guide',
            start_tutorial: 'Start tutorial',
        },
        top_menu: {
            search: 'Search...',
            searching: 'Searching...',
            searching_for: 'Searching for:',
            search_dotted: 'Search...',
            search_shortcut: 'Search Ctrl/Cmd + K',
            add_page_to_favorites: 'Add Page to Favorites',
            add_collection_to_favorites: 'Add Collection to Favorites',
            add_task_to_favorites: 'Add Task to Favorites',
            add_task_view_to_favorites: 'Add Task View to Favorites',
            add_page_view_to_favorites: 'Add Page View to Favorites',
            add_page_to_pinned: 'Add Page to Pinned',
            add_collection_to_pinned: 'Add Collection to Pinned',
            add_task_to_pinned: 'Add Task to Pinned',
            add_task_view_to_pinned: 'Add Task View to Pinned',
            add_page_view_to_pinned: 'Add Page View to Pinned',
            search_for_or_create_new: 'Search or create new',
            search_for_collection_label: 'Select or create collection',
            create_collection: '— Create Collection',
            create_page: '— Create new page',
            close_search_results: 'Close Search Results',
            share: {
                label: 'Share',
                share_with: 'Shared with workspace members',
                copy_link: 'Copy link',
                copy_link_tooltip: 'By using public link, the page will be accessed by anyone.',
                copy_public_link: 'Copy public link',
                copy_public_link_tooltip:
                    'By using private link, the page can only be accessed by you or other workspace members.',
                link_copied: 'Link Copied!',
                members_count: '{{-workspaceTitle}} members',
                share_count_zero: 'Shared only with you',
                share_count_one: 'Shared with 1 person',
                share_count_other: 'Shared with {{count}} people.',
                manage: 'Manage',
                manage_members: 'Manage members',
                view_members: 'View members',
                publish: 'Publish',
                page_not_published: 'Publish this Page, then anyone with the link can <1>view</1> it.',
                page_published: 'Anyone with the link can <1>view this Page</1>.',
                workspace_not_published: 'Publish the whole Workspace, then anyone can <1>view all pages</1> inside it',
                workspace_published: 'Anyone with the link can <1>view all pages</1> inside this workspace.',
                copying_page: 'Allow Duplicating Page',
                members_one: '1 member',
                members_other: '{{count}} members',
                copying_page_title: 'Choose a workspace',
                copying_page_sub_title: 'Page will be duplicated in:',
                copying_page_toast_success: 'Page duplicated successfully!',
                copying_workspace: 'Allow Copying Workspace',
                copying_workspace_toggle: 'Anyone with the link can copy and duplicate this workspace.',
                copying_page_disabled_tooltip:
                    "You are a Viewer in this workspace, and you can't copy this page inside it.",
                invite_members: 'Invite new members',
                share_with_members: 'Shared with workspace members',
                private_access: 'Private access',
                members_with_access: 'Members with access to page',
                guests_with_access: 'Guests with access to page',
                permission_admin_description: 'Can edit, delete page, and manage permissions',
                permission_viewer_description: 'Can view page content',
                permission_editor_description: 'Can edit page content',
                invite_guest: 'Type email to invite guest...',
                type_email: 'Type email...',
            },
        },
        move_page: {
            move_modal_title: 'Choose a workspace',
            move_modal_sub_title: 'Move page to:',
            toast_success: 'Page moved successfully!',
            confirm_modal_title:
                'Some content and settings, like links and permissions, may not work on the moved page.',
            confirm_modal_content_left: 'The original page will be archived unchanged.',
            confirm_modal_content_right: 'A duplicate will be created in the selected workspace.',
            confirm_changes: 'Confirm move',
        },
        move_task: {
            move_modal_title: 'Choose a workspace',
            move_modal_sub_title: 'Move task to:',
            toast_success: 'Task moved successfully!',
            confirm_modal_title:
                'Some content and settings, like links and permissions, may not work on the moved task.',
            confirm_modal_content_left: 'The original task will be archived unchanged.',
            confirm_modal_content_right: 'A duplicate will be created in the selected workspace.',
            confirm_changes: 'Confirm move',
        },
        sidebar: {
            new_page: 'New page',
            create_page: 'Create Page',
            create_collection: 'Create Collection',
            new_workspace: 'New Workspace',
            open_template_menu: 'Open Template Menu',
            pages: 'Pages',
            tasks: 'Tasks',
            settings: 'Settings',
            pinned: 'Pinned',
            favorites: 'Favorites',
            logout: 'Log Out',
            collections: 'Collections',
            shared: 'Shared with me',
            notifications: {
                dont_have_notifications: "You don't have notifications yet.",
                accept: 'Accept',
                manage: 'Manage',
                invite_message: '{{name}} invited you to join the workspace',
                user_joined: '{{name}} joined your workspace',
                user_left: '{{name}} left your workspace',
                user_removed: '{{name}} removed you from the workspace',
                go_to_workspace: 'Go to workspace',
                go_to_page: 'Go to Page',
                go_to_task: 'Go to Task',
                load_more: 'Load more',
                mentioned_you: 'mentioned you in',
                assigned_you: 'assigned you to',
                unassigned_you: 'unassigned you from',
                invite_page_message: 'invited you to the page',
            },
            upgrade_plan_modal: {
                title: 'Upgrade your plan',
                description:
                    'To unlock unlimited workspaces and gain access to advanced collaboration tools and additional super features that will take your work to the next level.',
            },
        },
        filters: {
            title: 'Title',
            az: 'A → Z',
            za: 'Z → A',
            created: 'Created',
            updated: 'Updated',
            creators: {
                all_creators: 'All Creators',
                no_creators: 'No Creators',
                n_creators_other: '{{count}} Creators',
                n_creators_one: '{{count}} Creator',
                search_creator_placeholder: 'Search Members...',
            },
        },
        pages: {
            title_pages: 'Pages',
            title_deleted_pages: 'Deleted Pages',
            title_deleted: 'Deleted',
            title_templates: 'Templates',
            title_shared: 'Shared',
            title_private: 'Private',
            title_public: 'Public',
            fewest_items_first: 'Fewest items first',
            most_items_first: 'Most items first',
            collection: 'Collection',
            fewest_words_first: 'Fewest words first',
            most_words_first: 'Most words first',
            word_count: 'Word count',
            oldest_first: 'Oldest first',
            newest_first: 'Newest first',
            close_pages: 'Close Pages',
            empty_state_deleted: 'You have no deleted pages.',
            empty_state_non_deleted: 'Add pages to see them here.',
            empty_state_templates: 'Add templates to see them here.',
            empty_state_shared: "You don't have any pages shared with you.",
            empty_state_private: 'You have no private pages.',
            empty_state_public: 'You have no public pages.',
            enter_page_name: 'Enter a page name',
            favourite_untitled: 'Untitled',
            delete_all_forever: 'Delete all forever',
            delete_all_page: 'Are you sure you want to delete all pages? This action cannot be undone.',
            history: {
                history: 'History',
                current: 'Current',
                versions_count: '({{number}} versions)',
                versions: 'Versions',
                upgrade_required: 'Upgrade required',
                upgrade_modal: {
                    title: 'Upgrade plan',
                    description:
                        'Upgrade to a paid plan to access version history older than 7 days and unlock other premium features.',
                },
            },
            mode_dropdown: {
                pages: 'Pages',
                templates: 'Templates',
                deleted_pages: 'Deleted Pages',
            },
            new_page_placeholder: 'New page',
            new: {
                create: 'Create',
                label_page: 'New Page',
                placeholder_page: 'Type a new page title...',
                label_template: 'New Template',
                placeholder_template: 'Type a new page title...',
            },
            headers: {
                title: 'Title',
                collection: 'Collection',
                word_count: 'Word Count',
                created: 'Created',
                updated: 'Updated',
                shared_by: 'Shared by',
                role: 'Role',
                creator: 'Creator',
            },
            deleted_page: 'This page is deleted',
            restore: 'Restore',
            restore_page: 'Restore page',
            delete_forever: 'Delete forever',
            delete_page_toast_title: 'Page is deleted',
            delete_page_toast_description: 'Deleted pages are stored in Archived until they are permanently deleted.',
        },
        references: {
            label: 'References',
            tooltip: 'References show where a page has been mentioned across your workspace.',
            result_one: '1 result',
            result_other: '{{count}} results',
            no_results: 'No references could be found for this page.',
            no_results_search_panel: 'No results could be found. Try another search.',
        },
        table_of_content: {
            label: 'Table of Content',
        },
        properties: {
            label: 'Properties',
        },
        page_info: {
            label: 'Page Info',
            creator: 'Creator',
            created: 'Created',
            updated: 'Updated',
            word_count: 'Word Count',
            page_settings: 'Page Settings',
            autolink_pages_label: 'Auto-link pages',
            autolink_pages_description: 'Auto-link this page when its title is mentioned in other pages',
            autolink_headings_label: 'Auto-link headings',
            autolink_headings_description: 'Auto-link headings from this page when they are mentioned in other pages',
            full_width_label: 'Full-Width page',
            full_width_description: 'Make page display in full width.',
        },
        tasks: {
            title: 'Tasks',
            new_task: 'New Task',
            new_task_placeholder: 'Enter a Task Title',
            new_task_title_placeholder: 'New task',
            new_task_editor_placeholder: 'Enter Task Title',
            createdAt: 'Created',
            updatedAt: 'Updated',
            completedDate: 'Completed',
            due_date: 'Due Date',
            no_due_date: 'No due date',
            sort_asc: 'Ascending',
            sort_desc: 'Descending',
            sorted_by: 'Sorted by',
            task_title: 'Task Title',
            referenced: 'Referenced in',
            assignee: 'Assignee',
            no_assignee: 'No assignee',
            priority: 'Priority',
            no_priority: 'No priority',
            priority_all: 'All Priorities',
            priority_high: 'High',
            priority_medium: 'Medium',
            priority_low: 'Low',
            priority_unprioritized: 'No priority',
            add_assignee_placeholder: 'Enter a name...',
            incomplete_tasks: 'Incomplete Tasks',
            incomplete_tasks_short: 'Incompleted',
            completed_tasks: 'Completed Tasks',
            completed_tasks_short: 'Completed',
            all_tasks: 'All Tasks',
            all_tasks_short: 'All',
            all_assignees: 'All Assignees',
            not_assigned: 'Not assigned',
            close_task_one: 'Close Task',
            close_task_other: 'Close Tasks',
            add_task_below: 'Add task below',
            delete_task: 'Delete Task',
            confirm_delete_task:
                'Are you sure you want to delete this task? It will be deleted from the whole workspace.',
            create_task_to_see_them: 'Create Tasks to see them here.',
            status_open: 'To Do',
            status_in_progress: 'In Progress',
            status_done: 'Done',
            status: 'Status',
            archived: 'Archived',
            deleted_task: 'This task is deleted',
            restore_task: 'Restore task',
            delete_all_tasks: 'Are you sure you want to delete all tasks? This action cannot be undone.',
            delete_task_toast_title: 'Task is deleted',
            delete_task_toast_description: 'Deleted tasks are stored in Archived until they are permanently deleted.',
            new_label_placeholder: 'Enter a label name...',
            select_or_create_label: 'Create new or select',
            add_new_label: 'Add new label',
            no_label: 'No label',
            no_labels: 'No labels',
            n_labels_one: '{{count}} label',
            n_labels_other: '{{count}} labels',
            all_labels: 'All labels',
            table_view: 'Table View',
            kanban_view: 'Kanban View',
        },
        collections: {
            page_title: 'Collections',
            filter_placeholder: 'Filter by keyword',
            add_to_collection: 'Add to Collection',
            add_page_to_collection: 'Add Page to Inner Collection',
            delete_collection: 'Delete Collection',
            add_parent_collection: 'Add parent collection',
            type_page_task_or_collection: 'Type a Page/Task or Collection...',
            select_create_new: 'Select or create new',
            new_collection: 'New Collection',
            new_collection_placeholder: 'Type a new Collection...',
            empty_state_message: 'Create Collections to see them here.',
            sort: 'Sort',
            folder_view: 'Folder View',
            tag_view: 'Tag View',
            pages: 'Pages',
            sub_collections: 'Sub Collections',
            oldest_first: 'Oldest first',
            newest_first: 'Newest first',
            fewest_first: 'Fewest pages first',
            most_first: 'Most pages first',
            sub_fewest_first: 'Fewest first',
            sub_most_first: 'Most first',
            close_collections: 'Close Collections',
            empty_state: 'Add Pages or Collections to this Collection to see them here.',
            all_collections: 'All Collections',
            no_collections: 'No Collections',
            n_collections_other: '{{count}} Collections',
            n_collections_one: '{{count}} Collection',
            search_collection_placeholder: 'Search Collection...',
        },
        views: {
            save_view: 'Save',
            save_view_name_placeholder: 'View name ...',
            create_new_button: 'Create new view',
            update_button: 'Update view',
            delete_view: 'Delete view',
        },
        settings: {
            sidebar: {
                account: 'Account',
                title: 'Settings',
                profile: 'Profile',
                language: 'Language',
                appearance: 'Appearance',
                editor: 'Editor',
                invites: 'Invites',
                integrations: 'Integrations',
                general: 'General',
                members: 'Members',
                plans: 'Plans',
                billing: 'Billing',
                import_export: 'Import & Export',
            },
            profile: {
                title: 'Profile',
                sub_title: 'Manage your Saga profile',
                your_email: 'Your Email',
            },
            language: {
                title: 'Language',
                sub_title: 'Change the language used in the user interface.',
                language: 'Language',
                language_change_title: 'Update language',
                language_change_confirmation: 'Are you sure you want to update the language? Saga will reload!',
                language_changed_notification: 'Language updated',
            },
            appearance: {
                title: 'Appearance',
                sub_title: 'Personalize the appearance of Saga.',
                theme: 'Theme',
                show_icons: 'Show Icons in Inline Page Links',
                mode_auto: 'Auto Mode',
                mode_light: 'Light Mode',
                mode_dark: 'Dark Mode',
                start_week_on_monday: 'Start week on Monday',
                font_default: 'Default',
                interface_font: 'Interface Font',
                text_font: 'Text Font',
                example_text: 'Example text to test your font',
            },
            editor: {
                title: 'Editor',
                sub_title: 'Personalize your editing experience.',
                spellcheck: 'Spellcheck',
            },
            invite: {
                title: 'Workspace invites',
                sub_title: 'View and accept all your workspace invites',
                no_invites: "You don't have workspace invites at the moment.",
                invite_members_to: 'Invite members',
                max_invites_reached:
                    '<bold>{{maxInvitedMembers}}/{{totalMembers}}</bold> free invites remaining. <upgrade>Upgrade</upgrade> plan for unlimited invites',
                accept_invite: 'Accept Invite',
                invite_accepted: 'Invite Accepted',
                owned_by: 'owned by <1>{{ownerEmail}}</1>',
            },
            page_invite: {
                title: 'Page invites',
                sub_title: 'View and accept all your Page invites',
                no_invites: "You don't have page invites at the moment.",
            },
            integrations: {
                title: 'Integrations',
                sub_title: 'Integrate other tools with Saga',
                remove_integration: 'Are you sure you want to remove this integration?',
                google_drive: {
                    title: 'Google Drive',
                    description: 'Search your drive and mention files in pages and tasks. <1>Learn more</1>',
                    button_connect: 'Connect to Google Drive',
                    button_disconnect: 'Remove Integration',
                    last_updated: 'Last Updated {{date}}',
                },
                linear: {
                    title: 'Linear',
                    description: 'Search Linear and mention issues from your Linear workspace. <1>Learn more</1>',
                    button_connect: 'Connect to Linear',
                    button_disconnect: 'Remove Integration',
                },
            },
            general: {
                title: 'General',
                sub_title: 'Manage your general workspace settings',
                workspace_name: 'Workspace Name',
                icon: 'Icon',
                icon_description: 'Change the color of the workspace icon.',
                embeddings: 'Workspace Embeddings',
                embeddings_description: 'Generate embeddings for this Workspace.',
                copy_workspace: 'Copy Workspace',
                copy_workspace_description:
                    'All pages, collections and settings of this Workspace will be copied into a new Workspace.',
                delete_workspace: 'Delete Workspace',
                delete_workspace_description:
                    'Deleting a Workspace will delete all of its data. This cannot be undone.',
                delete_workspace_confirmation: 'Are you sure you want to delete this Workspace?',
                leave_workspace: 'Leave Workspace',
                leave_workspace_confirmation: 'Are you sure you want to leave this Workspace?',
                autolink: 'Automatic Linking',
                autolink_description:
                    'When enabled Saga automatically creates links to page titles inside pages and tasks.',
            },
            members: {
                title: 'Members',
                sub_title: 'Invite others to this Workspace',
                add_member: 'Add a Member',
                remove_member: 'Remove Member',
                remove_member_confirmation: 'Are you sure about removing {{name}}?',
                remove_confirm: 'Confirm remove',
                remove_member_toast_success: 'Member removed',
                pending_invite: 'Pending Invite',
                revoke_invite: 'Revoke Invite',
                revoke_invite_confirmation: 'Are you sure about revoking the invite?',
                revoke_confirm: 'Confirm revoke',
                revoke_invite_toast_success: 'Invite revoked',
                paid_feature: 'Paid Feature',
                role_admin: 'Admin',
                role_page_admin: 'Page Admin',
                role_no_access: 'No access',
                role_admin_tooltip:
                    'This member can <1>create, edit and delete content</1> in the workspace and <1>invite others</1>. They can also <1>manage the settings</1> and <1>delete the workspace</1>.',
                role_editor: 'Editor',
                role_editor_tooltip: 'This member can <1>create, edit and delete content</1> in the workspace.',
                role_viewer: 'Viewer',
                role_viewer_tooltip: 'This member can <1>view content</1> in the workspace.',
                role_member: 'Member',
                role_member_tooltip:
                    'This member can <1>create, edit and delete content</1> in the workspace and <1>invite others. They can also change pages/workspaces public</1> in the workspace.',
                role_blocked_tooltip: 'This member has no access to the workspace',
                invite_members: 'Invite Members to {{-workspaceName}}',
                plus_add_more: '+ Add more',
                send_invites: 'Send invites',
                invites_sent: 'Invites Sent',
                upgrade_plan: 'Upgrade your plan',
                upgrade_plan_details:
                    "You've reached the limit of {{count}} free users. Upgrade your plan to invite more users and unlock additional features to level up.",
            },
            plans: {
                title: 'Workspace Plans',
                loading: 'Loading ...',
                sub_title_free:
                    'Use Saga for free, <bold>upgrade for greater collaboration</bold>, <bold>task management</bold>, and <bold>unlimited Saga AI</bold>.',
                sub_title_monthly_one:
                    'Your current subscription is the Standard Monthly plan. Your workspace of <bold>{{membersCount}} member</bold> costs <bold>{{amount}}</bold> per month, and will renew on <bold>{{date}}</bold>.',
                sub_title_monthly_other:
                    'Your current subscription is the Standard Monthly plan. Your workspace of <bold>{{membersCount}} members</bold> costs <bold>{{amount}}</bold> per month, and will renew on <bold>{{date}}</bold>.',
                sub_title_yearly_one:
                    'Your current subscription is the Standard Annual plan. Your workspace of <bold>{{membersCount}} member</bold> costs <bold>{{amount_month}}</bold> per month / <bold>{{amount_year}}</bold> per year, and will renew on <bold>{{date}}</bold>.',
                sub_title_yearly_other:
                    'Your current subscription is the Standard Annual plan. Your workspace of <bold>{{membersCount}} members</bold> costs <bold>{{amount_month}}</bold> per month / <bold>{{amount_year}}</bold> per year, and will renew on <bold>{{date}}</bold>.',
                sub_title_monthly_canceled_one:
                    'Your workspace plan of <bold>{{membersCount}} member</bold> costs <bold>{{amount}}</bold> per month. You canceled this plan, but it will remain active until <bold>{{date}}</bold>. After that, you will be transitioned to a Free plan.',
                sub_title_monthly_canceled_other:
                    'Your workspace plan of <bold>{{membersCount}} members</bold> costs <bold>{{amount}}</bold> per month. You canceled this plan, but it will remain active until <bold>{{date}}</bold>. After that, you will be transitioned to a Free plan.',
                sub_title_yearly_canceled_one:
                    'Your workspace plan of <bold>{{membersCount}} member</bold> costs <bold>{{amount_month}}</bold> per month or <bold>{{amount_year}}</bold> per year. You canceled this plan, but it will remain active until <bold>{{date}}</bold>. After that, you will be transitioned to a Free plan.',
                sub_title_yearly_canceled_other:
                    'Your workspace plan of <bold>{{membersCount}} members</bold> costs <bold>{{amount_month}}</bold> per month or <bold>{{amount_year}}</bold> per year. You canceled this plan, but it will remain active until <bold>{{date}}</bold>. After that, you will be transitioned to a Free plan.',
            },
            billing: {
                title: 'Billing',
                sub_title: 'Manage your billing information and invoices.',
                per_month: 'per workspace member / month',
                free_plan: 'Free plan',
                standard_annual_plan: 'Standard annual plan',
                standard_monthly_plan: 'Standard monthly plan',
                billing_portal_label: 'See and edit billing details',
                invoices: {
                    title: 'Invoices',
                    loading: 'Loading the invoices...',
                    no_invoices: 'There are no invoices to show.',
                    no_upcoming_invoices: 'No upcoming invoices',
                    next_billing_on_one:
                        'You will be billed <1>${{amount}}</1> on <1>{{date}}</1> for <1>{{billableMembersCount}} member</1>',
                    next_billing_on_other:
                        'You will be billed <1>${{amount}}</1> on <1>{{date}}</1> for <1>{{billableMembersCount}} members</1>',
                    see_invoice: 'See invoice',
                    invoice_not_ready: 'Invoice not ready',
                    paid: 'Paid',
                    not_paid: 'Not paid',
                },
            },
            export_import: {
                title: 'Import & Export',
                sub_title: 'Import and export workspace and manage export settings.',
                import_workspace: 'Import Data',
                import_workspace_description:
                    'Import pages, tasks, or Saga workspace from markdown, text, or JSON files.',
                import_workspace_cta: 'Import Data',
                export_workspace: 'Export Workspace',
                export_workspace_description: 'Export Workspace as Markdown and JSON files.',
                export_workspace_cta: 'Export Workspace',
                import_modal: {
                    title: 'Drop your files to import',
                    upload_button: 'Upload from your Computer',
                    max_file_size: 'max. {{size}} MB per File',
                    supported_file_types: 'Supported: Markdown, JSON',
                    unexpected_error_message:
                        'Unexpected error occurred.<br/>Please try again or contact us at <email/>',
                    unsupported_error_title: 'Unsupported file format',
                    unsupported_error_description: 'Please drag and drop either folder, Markdown or JSON files.',
                    successfuly_imported_files_one: 'Successfully imported {{count}} file',
                    successfuly_imported_files_other: 'Successfully imported {{count}} files',
                    confirm_title: 'Import files as',
                    add_to_collection: 'Add to collection (optional)',
                    confirm_button: 'Import',
                    page: 'Pages',
                    task: 'Tasks',
                },
            },
            ai: {
                title: 'Saga AI',
                sub_title:
                    'Get creative, write faster, and do better work directly in Saga with the help of a digital AI assistant.',
                confirm_delete_command: 'Do you want to delete this command?',
                confirm_delete_prompt: 'Do you want to delete this prompt?',

                commands: {
                    title: 'Commands',
                    add_command: 'Add Command',
                    customize_prompts: 'Customize your AI prompts and commands. <1>See how it works</1>.',
                    add_prompt: 'Add Prompt',
                },
                new: {
                    title_create_prompt: 'Create a custom prompt',
                    title_edit_prompt: 'Edit your prompt',
                    title_create_command: 'Create a custom command',
                    title_edit_command: 'Edit your command',
                    custom_command_tooltip: 'Selected text will be placed at the end of your prompt.',
                    command_name_placeholder: 'Command name',
                    prompt_name_placeholder: 'Prompt name',
                    command_placeholder: 'Write your command here...',
                    prompt_placeholder: 'Write your prompt here...',
                },
            },
            labels: {
                title: 'Labels',
                sub_title: 'Add and edit your labels',
                add_label: 'Add label',
                name: 'Name',
                usage: 'Usage',
                options: 'Options',
                edit_modal: {
                    title_create: 'Create label',
                    title_edit: 'Edit label',
                    select_color: 'Select a label color',
                    label_name: 'Label name',
                },
                delete_label_confirmation_title: 'Delete the label "{{title}}"',
                delete_label_confirmation_description:
                    'The label will be removed from {{count}} tasks.\n\nThe action can not be undone.',
            },
        },
        billing: {
            free: 'Free',
            free_title: 'Free plan',
            free_description: 'For personal use and small teams',
            standard_title: 'Standard plan',
            standard_description: 'Best for mid-sized teams',
            business_title: 'Business plan',
            business_description: 'Best for larger organizations',
            monthly: 'Monthly',
            annually: 'Annually',
            downgrade: 'Downgrade',
            upgrade_plan: 'Upgrade plan',
            current_plan: 'Current plan',
            planned: 'Planned',
            join_waitlist: 'Join the waitlist',
            per_editor_month: 'per member / month',
            per_space_editor_month: 'per workspace member / month',
            desktopModal: {
                title: 'Change Billing Plan',
                description:
                    'Changing your billing plan requires a web browser. By proceeding, a new browser tab will open, redirecting you to the billing page of our application. Please complete your billing changes in the browser.',
                cta: 'Open in browser',
            },
            change_plan_modal_title: 'Are you sure about changing the billing interval?',
            plan_upgrade_success_toast: {
                title: 'The plan has been upgraded',
                description: 'You now have unlimited access to all Saga super features!',
            },
            downgrade_toast: {
                title: 'Your request to downgrade the plan is accepted',
                description: 'You will be able to use all the paid features until your subscription expires.',
            },
            upgrade_to_yearly_toast: {
                title: 'Billing interval changed',
            },
            change_plan_modal_description_one:
                'Your account billing will change from monthly to yearly right now, credits from your current plan will transfer to a new plan and you will be charged <bold>{{price}}</bold> for <bold>one</bold> user.',
            change_plan_modal_description_other:
                'Your account billing will change from monthly to yearly right now, credits from your current plan will transfer to a new plan and you will be charged <bold>{{price}}</bold> for <bold>{{members}}</bold> users.',
            free_plan_info_banner: {
                title: "We're glad to see you teaming up!",
                description:
                    'You are on the free plan that includes <bold>{{count}} members</bold> in this workspace. <1>Learn more</1>',
            },
            upgrade_plan_banner: {
                title: 'Task manager',
                description:
                    'Get an overview of all your tasks across all your pages. Search and sort, find the tasks you need and complete.',
                primary_button: 'Upgrade plan',
                primary_button_default: 'View plans',
                no_access_desciption: 'Please contact the workspace owner.',
            },
            feedback_modal: {
                title: "We're sad to see you downgrade:(",
                description:
                    "We created Saga to help you organize your knowledge. We'd really like to know if there's anything we're missing or could do better. Your thoughts would be very helpful to us.",
                placeholder:
                    "Please share why you're downgrading. We will make our best to make Saga better for you...",
                primary_button: 'Send feedback',
            },
            downgrade_to_free_modal: {
                title: 'Are you sure about downgrading the plan to Free?',
                description:
                    "Your workspace has <span class='font-bold'>{{membersCount}} $t(billing.downgrade_to_free_modal.member, {\"count\": {{membersCount}} })</span>. On the free plan you can have <span class='font-bold'>up to {{allowedMembers}}</span>. At the end of the subscription, <span class='font-bold'>{{membersLooseAccess}} $t(billing.downgrade_to_free_modal.member, {\"count\": {{membersLooseAccess}} }) will lose access</span> to the workspace, and the remaining <span class='font-bold'>{{allowedMembers}} will become $t(billing.downgrade_to_free_modal.admin, {\"count\": {{allowedMembers}} }).</span>",
                member: 'member',
                member_other: 'members',
                admin: 'admin',
                admin_other: 'admins',
                features_title: 'You will loose these super features:',
                feature_1: 'Unlimited Saga AI with GPT-4o',
                feature_2: 'Task manager',
                feature_3: 'User permissions',
                feature_4: 'Unlimited workspace members',
                primary_button: 'Keep plan',
                secondary_button: 'Downgrade',
            },
            ai_upgrade_plan_banner: {
                description: 'Your free AI requests have been used',
                primary_action: 'Upgrade plan',
            },
            ai_popover_modal_upgrade: {
                title: 'Upgrade your plan',
                description:
                    'Your free AI requests have been used. Upgrade for unlimited AI responses and additional features to boost your productivity.',
            },
            modal_plans: {
                title: 'Select workspace plan',
                sub_title:
                    "<bold>You've reached the limit of {{count}} free</bold> workspaces. Upgrade to gain access to additional super features that will take your work to the next level.",
                loading_plans: 'Loading the plans...',
                footer_disclaimer: 'All prices are in USD and charged per workspace. VAT may apply.',
                fair_usage: 'fair usage',
                sagaAi_disclaimer: '*Saga AI subject to <fairUsageLink/>.',
                footer_contacts: 'Have questions about billing? Ask us <link/>.',
            },
            plan_features: {
                unlimited_pages_tasks: 'Unlimited pages & tasks',
                limited_members: '<bold>Up to {{count}}</bold> members / workspace',
                unlimited_members: '<bold>Unlimited</bold> workspace members',
                limited_saga_requests: '<sagaAi /> with <bold>{{count}} words</bold> / month',
                unlimited_saga_requests: '<bold>Unlimited</bold> <sagaAi /> with GPT-4o',
                limited_integrations: '<bold>{{count}}</bold> integration: <google /> or <linear />',
                unlimited_integrations: '<google /> and <linear /> integrations',
                tooltip_integrations: {
                    google_drive: 'Open, search, and mention Google Drive files directly in Saga',
                    linear: 'Mention, search, or paste live links to Linear issues directly in Saga.',
                },
                apps: 'Mac and Windows <apps />',
                tooltip_sagaai: 'Saga AI is the intelligent assistant inside your workspace.',
                tooltip_apps: {
                    content: 'Download the Saga desktop apps for even better experience.',
                    apps: 'apps',
                },
                task_manager: 'Task manager',
                tooltip_task_manager: 'Manage all your tasks in one place.',
                user_permissions: 'User permissions',
                advanced_user_permissions: 'Advanced user permissions',
                search_with_ai: 'Use <sagaAi /> with tools and the web',
                version_history: 'Version history',
                version_history_free: '7 day version history',
                version_history_standard: '30 day version history',
                version_history_business: 'Unlimited version history',
                offline_mode: 'Offline mode',
                business_features_title: 'Everything in Standard plus:',
                free_limitations: '*Up to {{count}} Free workspaces per account',
                public_access: 'Public pages and workspaces',
                custom_ai_commands: 'Custom <sagaAi /> commands',
                ai_talk_to_your_page: '<sagaAi /> talk to your pages',
                limited_upload_file: '{{count}} MB max file size',
                limited_upload_total_mb: '{{count}} MB file storage',
                limited_upload_total_gb: '{{count}} GB file storage',
                limited_guests: '{{count}} guests',
            },
            choose_workspace_modal: {
                title: 'Upgrade Needed',
                description:
                    "You've reached the maximum number of free workspaces. To accept the invitation to join the new workspace, please upgrade one of your existing free workspaces.",
                go_to_plans: 'Go to Plans',
            },
        },
        table: {
            move_left: 'Move Left',
            move_right: 'Move Right',
            insert_column_left: 'Insert Column Left',
            insert_column_right: 'Insert Column Right',
            remove_column: 'Remove Column',
            append_column: 'Append Column',
            append_row: 'Append Row',
            remove_row: 'Remove Row',
        },
    },
};
