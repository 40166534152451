import React from 'react';

class ErrorBoundary extends React.Component<{ fallback(error: Error): React.ReactNode }, { error: Error | null }> {
    constructor(props: any) {
        super(props);
        this.state = { error: null };
    }

    static getDerivedStateFromError(error: Error) {
        return { error };
    }

    render() {
        if (this.state.error != null) {
            return this.props.fallback(this.state.error);
        }
        return this.props.children;
    }
}

export default ErrorBoundary;
