import { RemoveTextOperation } from 'slate';
import { SharedType, SyncElement } from '../../model';
import { getTarget } from '../../path';
import * as Sentry from '@sentry/react';

/**
 * Applies a remove text operation to a SharedType.
 *
 * @param doc
 * @param op
 */
export default function removeText(doc: SharedType, op: RemoveTextOperation): SharedType {
    const node = getTarget(doc, op.path) as SyncElement;
    const nodeText = SyncElement.getText(node)!;

    // it happened to one user that in this operation
    // yjs throws an exception. This should at least catch and report it.
    try {
        nodeText.delete(op.offset, op.text.length);
    } catch (error) {
        Sentry.captureException(error, { extra: { op } });
    }
    return doc;
}
