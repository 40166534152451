import { track } from '@/analytics';
import useMobile from '@/hooks/useMobile';
import { useSpaceAccess } from '@/hooks/useSpaceAccess';
import * as api from '@saga/api';
import { SpaceWithMembersFragmentDoc } from '@saga/api';
import { SpaceOperations } from '@saga/shared';
import classNames from 'classnames';
import React, { useState } from 'react';
import { Check, Globe, Link } from 'react-feather';
import { Trans, useTranslation } from 'react-i18next';
import { useCurrentWorkspace } from '../WorkspaceContext';
import { PageIcon } from '../icons';
import Spinner from '../loading/Spinner';
import WorkspaceAvatar from '../navigation/WorkspaceAvatar';
import PopOverModal from '../popover/PopOverModal';
import Tooltip from '../popover/Tooltip';
import Button from '../styled/Button';
import Toggle from '../styled/Toggle';
import { useToastContext } from '../toast/ToastContext';
import { SomethingWentWrongToast } from '../toast/ToastLibrary';

import { ShareablePage } from '.';
import { useSpace } from '../SpaceProvider';

function CopyLink({ url, source, dataTestId }: { url: string; source: string; dataTestId?: string }) {
    const [copied, setCopied] = useState(false);
    const { t } = useTranslation();

    function copyToClipboard() {
        navigator.clipboard.writeText(url);

        track('copy-link', { source });

        setTimeout(() => {
            setCopied(true);
        }, 0);
        setTimeout(() => {
            setCopied(false);
        }, 1000);
    }

    return (
        <div className="flex items-center mx-auto max-h-8 rounded shadow-button  hover:bg-saga-gray-250 dark:hover:bg-saga-gray-700">
            <Tooltip
                content={t('top_menu.share.copy_public_link_tooltip')}
                placement="bottom"
                className="w-[180px] text-xs"
            >
                <div>
                    <Button.ShareModalPlain data-testid={dataTestId} onClick={copyToClipboard} withBorder>
                        <Button.WithIconPadding>
                            <div
                                className={classNames('flex justify-center whitespace-nowrap', {
                                    'text-saga-green': copied,
                                })}
                            >
                                {copied ? (
                                    <div className="flex items-center">
                                        <Check size={14} className="mr-1.5" />
                                        <span className="text-sm">{t('top_menu.share.link_copied')}</span>
                                    </div>
                                ) : (
                                    <div className="flex items-center">
                                        <Link className="h-3.5 w-3.5 flex-none mr-1.5 my-auto" />
                                        <span className="text-sm">{t('top_menu.share.copy_link')}</span>
                                    </div>
                                )}
                            </div>
                        </Button.WithIconPadding>
                    </Button.ShareModalPlain>
                </div>
            </Tooltip>
        </div>
    );
}

function CopyPublishLink({ url, source, dataTestId }: { url: string; source: string; dataTestId?: string }) {
    const [copied, setCopied] = useState(false);
    const { t } = useTranslation();

    function copyToClipboard() {
        navigator.clipboard.writeText(url);

        track('copy-link', { source });

        setTimeout(() => {
            setCopied(true);
        }, 0);
        setTimeout(() => {
            setCopied(false);
        }, 1000);
    }

    return (
        <div className="flex items-center mx-auto bg-saga-gray-150 dark:bg-saga-gray-800 rounded shadow-button ">
            <Tooltip content={t('top_menu.share.copy_link_tooltip')} placement="bottom" className="w-[180px] text-xs">
                <Button.Plain data-testid={dataTestId} onClick={copyToClipboard}>
                    <Button.WithIconPadding>
                        <div
                            className={classNames('flex justify-center whitespace-nowrap', {
                                'text-saga-green': copied,
                            })}
                        >
                            <div className="flex items-center">
                                {copied ? (
                                    <Check size={14} className="mr-1.5" />
                                ) : (
                                    <Globe className="h-3.5 w-3.5 flex-none mr-1.5 my-auto" />
                                )}
                                <span className="text-sm">
                                    {t(copied ? 'top_menu.share.link_copied' : 'top_menu.share.copy_public_link')}
                                </span>
                            </div>
                        </div>
                    </Button.WithIconPadding>
                </Button.Plain>
            </Tooltip>
        </div>
    );
}

export function PublishPageSection({
    page,
    isPageShared,
    showPublish = false,
}: Readonly<{
    page: ShareablePage;
    isPageShared: boolean;
    showPublish?: boolean;
}>) {
    const workspace = useCurrentWorkspace();
    const { showToast } = useToastContext();
    const { isOwner, isMember } = useSpaceAccess();
    const [sharePage, { loading: sharingPage }] = api.useSharePageMutation();
    const [removeSharedPage, { loading: removingSharedPage }] = api.useRemoveSharedPageMutation();

    const handleOnPublish = React.useCallback(
        (isPublic) => {
            if (isPublic) {
                track('set-page-public');
                sharePage({
                    variables: { input: { urlKey: workspace.urlKey, pageId: page.id } },
                    optimisticResponse: { sharePage: { id: page.id } },
                    update(cache) {
                        const cachedData = cache.readQuery<api.SharedPageStatusQuery>({
                            query: api.SharedPageStatusDocument,
                        });

                        const sharedPageStatus = {
                            __typename: 'SharedPage' as const,
                            id: page.id,
                            spaceId: workspace.id,
                            isPrivateInSpace: false,
                            createdAt: new Date(),
                            ...(cachedData?.sharedPageStatus || {}),
                            updatedAt: new Date(),
                            isPublic: true,
                        };

                        cache.writeQuery({
                            query: api.SharedPageStatusDocument,
                            data: {
                                sharedPageStatus,
                            },
                            variables: { input: { pageId: page.id } },
                        });
                    },
                    onError() {
                        showToast(SomethingWentWrongToast);
                    },
                });
            } else {
                track('unset-page-public');
                removeSharedPage({
                    variables: { input: { urlKey: workspace.urlKey, pageId: page.id } },
                    optimisticResponse: { removeSharedPage: true },
                    update(cache) {
                        const cachedData = cache.readQuery<api.SharedPageStatusQuery>({
                            query: api.SharedPageStatusDocument,
                        });

                        cache.writeQuery({
                            query: api.SharedPageStatusDocument,
                            data: {
                                sharedPageStatus: {
                                    __typename: 'SharedPage',
                                    id: page.id,
                                    spaceId: workspace.id,
                                    isPrivateInSpace: false,
                                    createdAt: new Date(),
                                    ...(cachedData?.sharedPageStatus || {}),
                                    updatedAt: new Date(),
                                    isPublic: false,
                                },
                            },
                            variables: { input: { pageId: page.id } },
                        });
                    },
                    onError() {
                        showToast(SomethingWentWrongToast);
                    },
                });
            }
        },
        [page.id, sharePage, removeSharedPage, showToast, workspace],
    );

    return (
        <div className={classNames('flex', { 'flex-col mt-2': showPublish, 'items-center pt-1': !showPublish })}>
            <div className="flex flex-col flex-1">
                <div className="flex flex-1 items-center space-x-2">
                    <div className="w-[24px] h-[24px] pl-1">
                        <div className="w-5 h-5 flex items-center">
                            <PageIcon icon={page.icon} isTemplate={page.isTemplate} />
                        </div>
                    </div>
                    <div className="truncate flex-1">
                        <PopOverModal.H2>{page.title || 'Untitled'}</PopOverModal.H2>
                    </div>
                    {showPublish && (
                        <div>
                            <Toggle
                                accessibleLabel="Publish Page"
                                checked={isPageShared}
                                disabled={sharingPage || removingSharedPage || (!isOwner && !isMember)}
                                onChange={handleOnPublish}
                            />
                        </div>
                    )}
                </div>
                {showPublish && (
                    <p className="text-xs leading-normal text-saga-gray-500 dark:text-zinc-400 py-2">
                        <span className="whitespace-normal">
                            <Trans
                                i18nKey={`top_menu.share.${isPageShared ? 'page_published' : 'page_not_published'}`}
                                components={{ 1: <span className="font-bold" /> }}
                            />
                        </span>
                    </p>
                )}
            </div>

            <div className="flex flex-row items-center max-w-fit space-x-3">
                <CopyLink
                    dataTestId="share-copy"
                    source="share-page"
                    url={`${window.location.protocol}//${window.location.host}/s/${workspace.urlKey}/${page.id}`}
                />
                {isPageShared && showPublish && (
                    <CopyPublishLink
                        dataTestId="public-share-copy"
                        source="share-page"
                        url={`${window.location.protocol}//${window.location.host}/${page.id}`}
                    />
                )}
            </div>
        </div>
    );
}

function PublishWorkspaceSection() {
    const { t } = useTranslation();
    const isMobile = useMobile();
    const workspace = useCurrentWorkspace();
    const isWorkspacePublic = workspace.isPublic;
    const { canEdit, isOwner, isMember } = useSpaceAccess();
    const [updateSpacePublicMetaData] = api.useUpdateSpacePublicMetadataMutation();

    return (
        <section className="flex flex-col">
            <div className="flex items-start space-x-4 py-3">
                <div>
                    <WorkspaceAvatar workspace={workspace} />
                </div>
                <div
                    className={classNames('', {
                        'truncate flex-1': !isMobile,
                    })}
                >
                    <PopOverModal.H2>{workspace.title}</PopOverModal.H2>
                </div>
                {canEdit && (
                    <div>
                        <Toggle
                            accessibleLabel="Publish Space"
                            testId="publish-space-toggle"
                            checked={isWorkspacePublic}
                            disabled={!isOwner && !isMember}
                            onChange={async (isPublic) => {
                                await updateSpacePublicMetaData({
                                    variables: { input: { urlKey: workspace.urlKey, isPublic } },
                                    update(cache) {
                                        const id = `Space:${workspace.id}`;
                                        const fragment = cache.readFragment({
                                            fragment: SpaceWithMembersFragmentDoc,
                                            id,
                                            fragmentName: 'SpaceWithMembers',
                                        }) as any;
                                        cache.writeFragment({
                                            id,
                                            data: { ...fragment, isPublic },
                                            fragment: SpaceWithMembersFragmentDoc,
                                            fragmentName: 'SpaceWithMembers',
                                        });
                                    },
                                });

                                if (isPublic) {
                                    track('set-space-public');
                                } else {
                                    track('set-space-private');
                                }
                            }}
                        />
                    </div>
                )}
            </div>

            <p className="text-xs leading-normal text-saga-gray-500 dark:text-zinc-400">
                <span className="whitespace-normal">
                    <Trans
                        i18nKey={`top_menu.share.${
                            isWorkspacePublic ? 'workspace_published' : 'workspace_not_published'
                        }`}
                        components={{ 1: <span className="font-bold" /> }}
                    />
                </span>
            </p>

            {isWorkspacePublic && (
                <div className="flex flex-row pt-3">
                    <div className="flex flex-1 flex-col items-start">
                        <PopOverModal.H2>{t('top_menu.share.copying_workspace')}</PopOverModal.H2>

                        <div className="flex items-center space-x-2 pb-1">
                            <p className="text-xs leading-normal text-saga-gray-500 dark:text-zinc-400">
                                <span className="whitespace-normal">
                                    {t('top_menu.share.copying_workspace_toggle')}
                                </span>
                            </p>
                        </div>
                    </div>

                    <div className="pl-4">
                        <Toggle
                            accessibleLabel={t('top_menu.share.copying_workspace_toggle')}
                            checked={workspace.isCopyWorkspaceEnabled}
                            disabled={!isOwner && !isMember}
                            onChange={(isCopyWorkspaceEnabled) => {
                                updateSpacePublicMetaData({
                                    variables: { input: { urlKey: workspace.urlKey, isCopyWorkspaceEnabled } },
                                });
                                if (isCopyWorkspaceEnabled) {
                                    track('set-copy-workspace-enabled');
                                } else {
                                    track('set-copy-workspace-disabled');
                                }
                            }}
                        />
                    </div>
                </div>
            )}
        </section>
    );
}

function CopyPublicPageSection({ page }: Readonly<{ page: ShareablePage }>) {
    const { t } = useTranslation();
    const { space } = useSpace();
    return (
        <div className="flex items-start space-x-4 py-4">
            <div className="truncate flex-1">
                <PopOverModal.H2>{t('top_menu.share.copying_page')}</PopOverModal.H2>
            </div>

            <div>
                <Toggle
                    accessibleLabel={t('top_menu.share.copying_page')}
                    checked={page.isPublicDuplicatable || false}
                    onChange={(isPublicDuplicatable) => {
                        track(
                            isPublicDuplicatable
                                ? 'set-duplicate-public-page-enabled'
                                : 'set-duplicate-public-page-disabled',
                        );
                        SpaceOperations.updatePartialPage(space, page.id, { isPublicDuplicatable });
                    }}
                />
            </div>
        </div>
    );
}

export function PublishSection({
    page,
    isPageShared,
    loading,
}: Readonly<{
    page: ShareablePage;
    loading: boolean;
    isPageShared: boolean;
}>) {
    const isMobile = useMobile();
    return (
        <div
            className={classNames('', {
                'w-96': isMobile,
            })}
        >
            {loading && (
                <PopOverModal.Content>
                    <div className="p-4 flex justify-center items-center">
                        <Spinner />
                    </div>
                </PopOverModal.Content>
            )}

            {!loading && (
                <PopOverModal.Content>
                    <PublishPageSection page={page} isPageShared={isPageShared} showPublish />
                    {isPageShared && (
                        <>
                            <CopyPublicPageSection page={page} />
                            <PopOverModal.Hr />
                        </>
                    )}
                    <PublishWorkspaceSection />
                </PopOverModal.Content>
            )}
        </div>
    );
}
