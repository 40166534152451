import { SafeSpace } from '../types';
import transactInSpace from './transactInSpace';
import * as Y from 'yjs';

export default function removeUsersAllFavorites(space: SafeSpace, userId: string) {
    transactInSpace(space, () => {
        const userFavorites = space.map.get('userFavorites') as Y.Map<Y.Array<string>> | undefined;

        if (!userFavorites) {
            return;
        }

        userFavorites.delete(userId);
    });
}
