import React, { useRef } from 'react';
import Modal from 'react-modal';
import { X, Maximize2 } from 'react-feather';
import ManageCollectionsInput from '@/components/popover/ManageCollectionsInput';
import Tooltip from '@/components/popover/Tooltip';
import { PageForSearch } from '@/components/search/SearchContext';
import { Page, SagaLocation, SpaceOperations } from '@saga/shared';
import { useCollectionsSnapshot, usePageSnapshot } from '@/hooks/SpaceHooks';
import { useCallback } from 'react';
import QuickPageEditor from '@/components/editor/QuickPageEditor';
import { NavigationContext, NavigationFn, useOpenCollection, useOpenLocation } from '../PageNavigationProvider';
import { useBlockPlugins } from '@/components/BlockPluginProvider';
import { track } from '@/analytics';
import { useSpace } from '@/components/SpaceProvider';
import { useTranslation } from 'react-i18next';
import { useDocumentYBlocks } from '@/components/RealtimeDocumentProvider';
import { usePageAccess } from '@/components/PagesPermissionsBySpaceProvider';

const Z_INDEX = 70;

function QuickEditPageModalWithPage({
    page,
    onClose,
}: {
    page: PageForSearch & { collections: Page['collections'] };
    onClose(): void;
}) {
    const { t } = useTranslation();
    const blockPlugins = useBlockPlugins();
    const { canEdit } = usePageAccess(page.id);
    const { space } = useSpace();
    const handlers = useRef({ onClose });
    handlers.current = { onClose };
    const pageId = page.id;
    const location: SagaLocation.PageLocation = React.useMemo(
        () => ({ type: 'page' as const, pageId, blockId: undefined }),
        [pageId],
    );

    const openLocation = useOpenLocation();

    const collections = useCollectionsSnapshot();
    const goToCollection = useOpenCollection();
    const currentCollections = React.useMemo(
        () => collections.filter((c) => page.collections.includes(c.id)),
        [collections, page],
    );
    const availableCollections = React.useMemo(
        () => collections.filter((c) => !page.collections.includes(c.id)),
        [collections, page],
    );

    function onAddPageToCollection(id: string) {
        SpaceOperations.addPageToCollection(space, page.id, id);
        track('add-page-to-collection', { source: 'header-bar' });
    }

    function onRemoveCollection(id: string) {
        SpaceOperations.removePageFromCollection(space, page.id, id);
        track('remove-page-from-collection', { source: 'header-bar' });
    }

    const openLocationForward = useCallback<NavigationFn>(
        (location, event) => {
            handlers.current.onClose();
            openLocation(location, event);
        },
        [openLocation],
    );

    function onCreateCollection(title: string) {
        const newCollection = SpaceOperations.createCollection(space, title);
        SpaceOperations.addPageToCollection(space, page.id, newCollection.id);
        track('create-collection-from-popover', { source: 'quick-edit-page-modal' });
    }

    return (
        <Modal
            isOpen={true}
            contentLabel={t('common.quick_edit')}
            onRequestClose={onClose}
            shouldCloseOnEsc
            shouldCloseOnOverlayClick
            style={{
                overlay: {
                    zIndex: Z_INDEX,
                    background: 'transparent',
                    display: 'flex',
                    justifyContent: 'center',
                },
                content: {
                    border: undefined,
                    position: 'relative',
                    width: '80%',
                    background: 'transparent',
                    pointerEvents: 'none',
                },
            }}
        >
            <NavigationContext.Provider value={openLocationForward}>
                <div className="pointer-events-auto [max-width:700px] [max-height:70%] [top:15%] bottom-auto mx-auto absolute overflow-hidden rounded w-full space-y-2 border border-solid border-saga-gray-200/50 dark:border-zinc-600 shadow-popupSmall dark:shadow-zinc-900 inset-0 flex flex-col bg-white dark:bg-saga-gray-1000">
                    <div
                        id="create-page-collections-topbar"
                        className="pl-3 pr-1 h-12 leading-normal flex items-center justify-between flex-none"
                    >
                        {canEdit && (
                            <div className="flex-shrink-0">
                                <ManageCollectionsInput
                                    canEdit={true}
                                    currentCollections={currentCollections ?? []}
                                    availableCollections={availableCollections ?? []}
                                    onCollectionClick={(id, event) => {
                                        handlers.current.onClose();
                                        goToCollection(id, event);
                                    }}
                                    onCreate={onCreateCollection}
                                    onSelect={onAddPageToCollection}
                                    onRemove={onRemoveCollection}
                                    placeholder={t('common.plus_collection') as string}
                                    maxWidth={400}
                                    zIndex={300}
                                />
                            </div>
                        )}

                        <div className="flex w-full items-end justify-end flex-row space-x-2">
                            <Tooltip content={t('common.open_full_page')} placement="top">
                                <button
                                    className="cursor-pointer p-2 rounded hover:bg-saga-gray-200 dark:hover:bg-zinc-600 focus:outline-none active:bg-saga-gray-200 dark:active:bg-zinc-600 active:shadow-xs"
                                    onClick={(event) => {
                                        pageId &&
                                            openLocationForward({ type: 'page', pageId, blockId: undefined }, event);
                                    }}
                                >
                                    <span className="sr-only">{t('common.open_full_page')}</span>
                                    <Maximize2 className="text-saga-gray-dark dark:text-zinc-200 flex-none" size={20} />
                                </button>
                            </Tooltip>
                            <button
                                onClick={onClose}
                                className="cursor-pointer p-2 rounded hover:bg-saga-gray-200 dark:hover:bg-zinc-600 focus:outline-none active:bg-saga-gray-200 dark:active:bg-zinc-600 active:shadow-xs"
                            >
                                <span className="sr-only">{t('common.close_quick_edit_modal')}</span>
                                <X size={20} className="text-saga-gray-dark dark:text-zinc-200 flex-none" />
                            </button>
                        </div>
                    </div>
                    <QuickPageEditor location={location} zIndex={Z_INDEX} blockPlugins={blockPlugins} />
                </div>
            </NavigationContext.Provider>
        </Modal>
    );
}

const keysMap = ['id', 'title', 'collections', 'settings', 'aliases'] as const;

function QuickEditPageModal({ pageId, onClose }: { pageId: string; onClose(): void }) {
    const page = usePageSnapshot(pageId, keysMap);

    const { data: yBlocks } = useDocumentYBlocks(pageId);

    if (page && yBlocks) {
        return <QuickEditPageModalWithPage page={{ ...page, blocks: yBlocks.toJSON() }} onClose={onClose} />;
    }

    return null;
}

export default QuickEditPageModal;
