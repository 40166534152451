import { isSagaText, isTitle, SagaElement, SagaLocation, BlockType, blocksD, BlockD } from '..';
import * as E from 'fp-ts/Either';
import * as J from 'fp-ts/Json';
import { pipe } from 'fp-ts/lib/function';
import { map as unistMap } from 'unist-util-map';
import { Node as UnistNode, Parent } from 'unist';
import { v4 as uuid } from 'uuid';
import * as t from 'io-ts';
import * as R from 'ramda';
import { blocksLocationD } from '../SagaLocation';

function mapPastedNodes(nodes: BlockD[]) {
    const root = { type: 'root', children: nodes } as UnistNode;
    const result = unistMap(root, (node) => {
        if (isSagaText(node)) {
            return R.omit(['id'], node);
        }

        const nodeWithNewId = {
            ...node,
            id: uuid(),
            //@ts-ignore
            originBlockId: node.id,
        };

        // We asume that any incoming title is supposed to be a heading
        if (isTitle(node)) {
            return { ...nodeWithNewId, type: BlockType.HEADING_1 };
        }

        return nodeWithNewId;
    }) as Parent;

    return result.children as Array<SagaElement & { originBlockId: string }>;
}

const sagaContentParser = t.type({
    location: blocksLocationD,
    fragment: blocksD,
    action: t.union([t.literal('cut'), t.literal('copy')]),
    spaceUrlKey: t.string,
});

type ContentParsed = {
    location: SagaLocation.BlocksLocation;
    blocks: Array<SagaElement & { originBlockId: string }>;
    action: 'cut' | 'copy';
    spaceUrlKey: string;
};

export default function parseSagaContent(sagaContent: string): ContentParsed | null {
    return pipe(
        J.parse(sagaContent),
        E.chainW(sagaContentParser.decode),
        E.map((content) => ({
            location: content.location,
            blocks: mapPastedNodes(content.fragment),
            action: content.action,
            spaceUrlKey: content.spaceUrlKey,
        })),
        E.getOrElseW(() => null),
    );
}
