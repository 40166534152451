import { isFeatureEnabled } from '@/analytics';
import * as api from '@saga/api';
import React from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { useUserContext } from '../UserContext';
import WhooopsModal from '../WhooopsModal';
import LoadingScreen from '../loading/LoadingScreen';
import Onboarding from '../onboarding/Onboarding';
import OnboardingScreen from '../onboarding/OnboardingScreen';
import { useToastContext } from '../toast/ToastContext';
import { SimpleTitleToast } from '../toast/ToastLibrary';
import { FreePlanInfoBanner } from './FreePlanInfoBanner';
import InviteMemberForm from './InviteMemberForm';
import Button from '../styled/Button';
import { useTranslation } from 'react-i18next';
import { FeatureFlag } from '@/../../shared/src/utils/FeatureFlags';
import { getCurrentUser } from '@/firebase';

export function InviteMemberOnboarding({ urlKey }: { urlKey: string }) {
    const { t } = useTranslation();
    const { user, isLoading: userLoading } = useUserContext();
    const [showFreePlanInfoBanner, setShowFreePlanInfoBanner] = React.useState(false);
    const history = useHistory();
    const currentUser = getCurrentUser();
    const { showToast } = useToastContext();
    const { data, error, loading } = api.useSpaceByUrlKeyQuery({ variables: { input: { urlKey } } });

    if (loading || userLoading) {
        return <LoadingScreen />;
    }

    if (error) {
        return (
            <WhooopsModal
                onButtonClick={() => window.location.reload()}
                buttonLabel="Reload Page"
                subheading="There was a problem with this page, please reload the page and try again."
            />
        );
    }

    if (data?.spaceByUrlKey == null) {
        return <Redirect to="/not-found" />;
    }

    const { spaceByUrlKey } = data;

    if (user == null || spaceByUrlKey.access.write === false) {
        return <Redirect to={`/s/${urlKey}`} />;
    }

    const activeSubscription = isFeatureEnabled(FeatureFlag.allowBilling)
        ? spaceByUrlKey.activeSubscription !== null && spaceByUrlKey.activeSubscription !== undefined
        : true;

    const maxInvitedMembers = isFeatureEnabled(FeatureFlag.allowBilling)
        ? spaceByUrlKey.permissions.editors.limit
            ? spaceByUrlKey.permissions.editors.limit - spaceByUrlKey.members.length
            : null
        : null;

    const totalAllowedMembersForSpace = isFeatureEnabled(FeatureFlag.allowBilling)
        ? spaceByUrlKey.permissions.editors.limit
            ? spaceByUrlKey.permissions.editors.limit - 1
            : null
        : null;

    const closeInviteModal = () => {
        const duplicatePageData = localStorage.getItem('duplicatePageData');
        history.push(`/s/${urlKey}${duplicatePageData ? '/duplicatePage' : ''}`);
    };

    const canInviteAdmins = spaceByUrlKey.members.find((u) => u.id === currentUser?.uid)?.role === api.Role.Owner;

    return (
        <OnboardingScreen>
            <div className="overflow-auto max-h-full max-w-3xl w-full rounded border border-saga-gray-200 dark:border-zinc-600 shadow-popupSmall bg-white dark:bg-zinc-700 ">
                <div className="relative flex flex-col items-center justify-center pt-14 pb-8 px-2">
                    <div className="absolute top-0 right-0 m-2 dark:text-zinc-500 dark:hover:text-saga-gray-200">
                        <Button.XButton onClick={closeInviteModal} />
                    </div>
                    <Onboarding.Container>
                        <Onboarding.Logo />
                        <Onboarding.Heading>
                            {t('settings.invite.invite_members_to', { workspaceName: spaceByUrlKey.title })}
                        </Onboarding.Heading>
                    </Onboarding.Container>
                    <InviteMemberForm
                        canInviteAdmins={canInviteAdmins}
                        maxInvitedMembers={maxInvitedMembers}
                        totalAllowedMembersForSpace={totalAllowedMembersForSpace}
                        isSubscriptionActive={activeSubscription}
                        urlKey={urlKey}
                        onInviteCompleted={() => {
                            showToast(() => <SimpleTitleToast>Invites Sent</SimpleTitleToast>);
                            closeInviteModal();
                        }}
                        onMemberInvitesChanged={(memberInvites) => {
                            if (memberInvites.length >= 5) {
                                setShowFreePlanInfoBanner(true);
                            }
                        }}
                    />
                    <div>
                        <Button variant="secondary" onClick={closeInviteModal}>
                            {t('common.do_this_later')}
                        </Button>
                    </div>
                </div>
                {showFreePlanInfoBanner && <FreePlanInfoBanner />}
            </div>
        </OnboardingScreen>
    );
}
