import React, { useState, useRef, useEffect } from 'react';
import { Layers, Edit } from 'react-feather';
import classNames from 'classnames';
import { useSpaceAccess } from '@/hooks/useSpaceAccess';
import { IconPicker } from '../IconPicker';
import useInterfaceSettings from '@/hooks/useInterfaceSettings';

import useMobile from '@/hooks/useMobile';
import { track } from '@/analytics';

export type EditableTableTitleProps = {
    title: string | undefined;
    showIcon?: boolean;
    icon?: {
        type: 'emoji';
        colons: string;
    };
    onUpdateTitle: (title: string) => void;
    onChangeIcon?: (iconCollons: string) => void;
    onRemoveIcon?: () => void;
    disabled?: boolean;
    autofocus: boolean;
    testId: string;
    texts: {
        title: string;
        placeholder: string;
        edit: string;
        removeIcon: string;
        changeIcon: string;
    };
    source: string;
};

const EditableTableTitle = ({
    title,
    icon,
    onUpdateTitle,
    onChangeIcon,
    onRemoveIcon,
    autofocus = false,
    disabled = false,
    showIcon = true,
    testId,
    texts,
    source,
}: EditableTableTitleProps) => {
    const [editTitle, setEditTitle] = useState<boolean>(autofocus);
    const titleInputRef = useRef<HTMLInputElement>(null);
    const [newTitle, setNewTitle] = useState<string>(title || '');
    const { canEdit } = useSpaceAccess();
    const [{ darkMode }] = useInterfaceSettings();
    const isMobile = useMobile();

    useEffect(() => {
        if (editTitle) {
            titleInputRef.current?.focus();
        }
    }, [editTitle]);

    useEffect(() => {
        if (title) {
            setNewTitle(title);
        }
    }, [title]);

    const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        switch (event.key) {
            case 'Enter':
                submit();
                return;
            case 'Escape':
                setEditTitle(false);
                setNewTitle(title || '');
                return;
        }
    };

    const submit = () => {
        onUpdateTitle(newTitle);
        setEditTitle(false);
        setNewTitle(title || '');
    };

    const iconPicker = showIcon && (
        <IconPicker
            darkMode={darkMode}
            icon={icon}
            onChange={(emoji) => {
                if (emoji.colons) {
                    track('add-emoji', { source });
                    onChangeIcon?.(emoji.colons);
                }
            }}
            onRemove={() => {
                track('remove-emoji', { source });
                onRemoveIcon?.();
            }}
            top={100}
        >
            {(props) => (
                <IconPicker.Button
                    DefaultIcon={Layers}
                    disabled={!canEdit || disabled}
                    icon={icon}
                    label={texts.changeIcon}
                    {...props}
                />
            )}
        </IconPicker>
    );

    return (
        <>
            {!editTitle && (
                <div
                    data-testid={testId}
                    className={classNames('flex items-center space-x-2 font-semibold', { 'hover-trigger': canEdit })}
                >
                    {!isMobile && iconPicker}
                    <h1
                        onClick={() => {
                            if (canEdit && !disabled) {
                                setEditTitle(true);
                            }
                        }}
                        className="min-w-0 inline text-3xl sm:text-4xl font-semibold leading-snug sm:leading-normal truncate"
                    >
                        {title}
                    </h1>
                    {!isMobile && canEdit && !disabled && (
                        <button
                            className="hover-target focus:outline-none cursor-pointer"
                            onClick={() => setEditTitle(true)}
                        >
                            <span className="sr-only">{texts.edit}</span>
                            <Edit
                                className="text-saga-gray-500 dark:text-zinc-200 rounded hover:bg-saga-gray-200 dark:hover:bg-zinc-600 focus:outline-none active:bg-saga-gray-200 dark:active:bg-zinc-600 active:shadow-xs"
                                size={18}
                            />
                        </button>
                    )}
                </div>
            )}
            {editTitle && (
                <div data-testid={testId} className="flex items-center w-full space-x-2">
                    {!isMobile && iconPicker}
                    <input
                        ref={titleInputRef}
                        className="transition-colors dark:bg-zinc-900 py-0 duration-100 ease-in-out text-3xl sm:text-4xl leading-snug sm:leading-normal font-semibold w-full appearance-none"
                        type="text"
                        title={texts.title}
                        placeholder={texts.placeholder}
                        value={newTitle}
                        onChange={(e) => setNewTitle(e.target.value)}
                        onKeyDown={onKeyDown}
                        onBlur={submit}
                    />
                </div>
            )}
        </>
    );
};

export default EditableTableTitle;
