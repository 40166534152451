import { FindInLocationsResult } from '@/../../shared/src/SpaceOperations/findInLocations';
import { PageIcon } from '@/components/icons';
import { useOpenLocation } from '@/components/PageNavigationProvider';
import Dropdown from '@/components/popover/Dropdown';
import { useSpace, useCurrentSearchIndex } from '@/components/SpaceProvider';
import Button from '@/components/styled/Button';
import Card from '@/components/styled/Card';
import { SagaEditor, isLiveBlock, isLiveBlockSource, Search, SpaceOperations, unsafeRight } from '@saga/shared';
import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFocused } from 'slate-react';

function ReferencedPages({ referenceResults }: { referenceResults: FindInLocationsResult[] }) {
    const openLocation = useOpenLocation();
    const { t } = useTranslation();

    return (
        <Card>
            <div data-testid="referenced-pages-popover" className="max-h-suggestion overflow-y-auto">
                <div className="text-xs leading-normal px-2 py-1 text-saga-text-gray whitespace-nowrap">
                    {t('common.referenced_in_pages')}
                </div>
                {referenceResults.map((result, i) => {
                    const page = result.type === 'page' ? result.page : null;
                    return (
                        <Button.PopOverButton
                            onMouseDown={(e) => {
                                e.preventDefault();
                                openLocation(result.location, e);
                            }}
                            key={i}
                        >
                            <div className="flex-none text-saga-gray-500 mr-2 w-4">
                                <PageIcon icon={page?.icon} isTemplate={page?.isTemplate} />
                            </div>
                            <span>{page?.title}</span>
                        </Button.PopOverButton>
                    );
                })}
            </div>
        </Card>
    );
}

function ReferencedPagesMenu({
    isEditing,
    referenceResults,
}: {
    isEditing: boolean;
    referenceResults: FindInLocationsResult[];
}) {
    const [menuOpen, setMenuOpen] = React.useState(false);
    const menuRef = React.useRef<HTMLDivElement>(null);
    const { t } = useTranslation();

    React.useEffect(() => {
        if (!isEditing) {
            setMenuOpen(false);
        }
    }, [isEditing]);

    if (referenceResults.length === 0) {
        return null;
    }

    return (
        <>
            <div
                data-testid="live-reference-counter"
                ref={menuRef}
                contentEditable={false}
                className="absolute my-auto top-0.5 right-1 text-saga-gray-dark"
            >
                <FloatingButton isEditing={isEditing} onClick={() => setMenuOpen((open) => !open)}>
                    <span className="sr-only">{t('common.see_pages_that_reference')}</span>
                    <div
                        className="flex items-center justify-center text-xs px-1 py-1 rounded-full"
                        style={{ minWidth: '1.75rem' }}
                    >
                        {referenceResults.length}
                    </div>
                </FloatingButton>
            </div>

            <Dropdown isOpen={menuOpen} onClose={() => setMenuOpen(false)} attachToRef={menuRef}>
                {menuOpen && <ReferencedPages referenceResults={referenceResults} />}
            </Dropdown>
        </>
    );
}

function FloatingButton({
    onClick,
    children,
    isEditing,
}: {
    onClick: () => void;
    children: React.ReactNode;
    isEditing: boolean;
}) {
    return (
        <button
            className={classNames(
                'cursor-pointer rounded z-10 text-saga-gray-500 hover:bg-saga-gray-200 focus:outline-none active:bg-saga-gray-200 active:shadow-xs ',
                {
                    'shadow-popupSmall bg-white dark:bg-zinc-700 dark:text-zinc-200': isEditing,
                    'group-hover:bg-white dark:group-hover:bg-zinc-700 group-hover:shadow-popupSmall dark:group-hover:text-zinc-200':
                        !isEditing,
                },
            )}
            onClick={onClick}
        >
            {children}
        </button>
    );
}

export const spaceLiveBlockSourcePlugin = SagaEditor.Plugins.createBlockPlugin({
    match: isLiveBlockSource,
    normalizers: [SagaEditor.Normalizers.liveBlockSourceNormalizer],
    Component({ children, element, selected }) {
        const focused = useFocused();
        const isEditing = focused && selected;
        const { space } = useSpace();

        const [referenceResults, setReferenceResults] = React.useState<FindInLocationsResult[]>([]);
        const spaceblocks = useCurrentSearchIndex();

        React.useEffect(() => {
            function refreshReferences() {
                const referenceResults = SpaceOperations.findInLocations(
                    unsafeRight(space.decode()),
                    spaceblocks?.toJSON(),
                    [
                        Search.blockQuery({
                            match: isLiveBlock,
                            predicate(reference) {
                                return reference.reference.liveReferenceSourceId === element.liveReferenceSourceId;
                            },
                        }),
                    ],
                );

                setReferenceResults(referenceResults);
            }

            refreshReferences();
        }, [space, element, spaceblocks]);

        return (
            <div
                data-testid="live-reference-source"
                className={classNames('pr-8 relative rounded-md group', {
                    'bg-saga-blue-light bg-opacity-10': isEditing && referenceResults.length,
                    'hover:bg-saga-blue-light hover:bg-opacity-10': !isEditing && referenceResults.length,
                })}
            >
                {children}
                <ReferencedPagesMenu isEditing={isEditing} referenceResults={referenceResults} />
            </div>
        );
    },
});
