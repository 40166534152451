import { useSpaceAccess } from '@/hooks/useSpaceAccess';
import * as api from '@saga/api';
import { DateTime } from 'luxon';
import React, { useEffect, useMemo, useState } from 'react';
import { Loader } from 'react-feather';
import { useCurrentWorkspace, useSubscriptions } from '../WorkspaceContext';
import { PlanCard } from '../billing/PlanCard';
import SettingsPane from './SettingsPane';
import { useSetSettings } from './SettingsProvider';
import { Trans, useTranslation } from 'react-i18next';

export function PlansSettings() {
    const { data, loading } = api.useSubscriptionPlansQuery();
    const { currentSubscription } = useSubscriptions();
    const { members } = useCurrentWorkspace();
    const { data: dataUpcomingSubscription } = api.useSubscriptionUpcomingQuery({
        variables: { input: { subscriptionId: currentSubscription ? currentSubscription.subscriptionId : '' } },
        skip: !currentSubscription || currentSubscription.canceled,
    });

    const { isOwner } = useSpaceAccess();
    const setSettings = useSetSettings();

    const [isDisabled, setIsDisabled] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        if (!isOwner) setSettings('space');
    }, [isOwner, setSettings]);

    const subTitle = useMemo(() => {
        if (currentSubscription) {
            if (currentSubscription.canceled) {
                if (currentSubscription.price.interval === api.SubscriptionPriceInterval.Month) {
                    const { price, currentPeriodEnd } = currentSubscription;
                    const date = DateTime.fromJSDate(new Date(currentPeriodEnd)).toFormat('MMMM dd, yyyy');
                    return (
                        <Trans
                            i18nKey="settings.plans.sub_title_monthly_canceled"
                            count={members.length}
                            components={{ bold: <span className="font-bold" /> }}
                            values={{ amount: `$${price.amount / 100}`, date, membersCount: members.length }}
                        />
                    );
                }
                if (currentSubscription.price.interval === api.SubscriptionPriceInterval.Year) {
                    const { price, currentPeriodEnd } = currentSubscription;
                    const date = DateTime.fromJSDate(new Date(currentPeriodEnd)).toFormat('MMMM dd, yyyy');
                    return (
                        <Trans
                            i18nKey="settings.plans.sub_title_yearly_canceled"
                            count={members.length}
                            components={{ bold: <span className="font-bold" /> }}
                            values={{
                                amount_month: `$${price.amount / 100 / 12}`,
                                amount_year: `$${price.amount / 100}`,
                                date,
                                membersCount: members.length,
                            }}
                        />
                    );
                }
            }

            if (
                currentSubscription.price.interval === api.SubscriptionPriceInterval.Month &&
                dataUpcomingSubscription?.subscriptionUpcoming
            ) {
                const subscriptionUpcoming = dataUpcomingSubscription.subscriptionUpcoming;
                const date = DateTime.fromJSDate(new Date(subscriptionUpcoming.periodEnd)).toFormat('MMMM dd, yyyy');
                return (
                    <Trans
                        i18nKey="settings.plans.sub_title_monthly"
                        count={members.length}
                        components={{ bold: <span className="font-bold" /> }}
                        values={{ amount: `$${subscriptionUpcoming.total / 100}`, date, membersCount: members.length }}
                    />
                );
            }

            if (
                currentSubscription.price.interval === api.SubscriptionPriceInterval.Year &&
                dataUpcomingSubscription?.subscriptionUpcoming
            ) {
                const subscriptionUpcoming = dataUpcomingSubscription.subscriptionUpcoming;
                const date = DateTime.fromJSDate(new Date(subscriptionUpcoming.periodEnd)).toFormat('MMMM dd, yyyy');
                return (
                    <Trans
                        i18nKey="settings.plans.sub_title_yearly"
                        count={members.length}
                        components={{ bold: <span className="font-bold" /> }}
                        values={{
                            amount_month: `$${subscriptionUpcoming.total / 100 / 12}`,
                            amount_year: `$${subscriptionUpcoming.total / 100}`,
                            date,
                            membersCount: members.length,
                        }}
                    />
                );
            }

            return t('settings.plans.loading');
        }

        return <Trans i18nKey="settings.plans.sub_title_free" components={{ bold: <span className="font-bold" /> }} />;
    }, [currentSubscription, dataUpcomingSubscription, members.length]);

    return (
        <SettingsPane>
            <div>
                <SettingsPane.Title>{t('settings.plans.title')}</SettingsPane.Title>
                <SettingsPane.Small>{subTitle}</SettingsPane.Small>
            </div>
            <SettingsPane.Content>
                <div className="flex flex-col w-fit">
                    <div className="flex flex-wrap py-6 w-fit space-x-6">
                        {loading || !data ? (
                            <div className="flex justify-center h-16 items-center rounded space-x-2">
                                <Loader className="animate-spin" />
                                <div>Loading the plans...</div>
                            </div>
                        ) : (
                            <>
                                <PlanCard.FreePlan
                                    subscriptionPlan={data.subscriptionPlans[0]}
                                    disabled={isDisabled}
                                    setDisabled={setIsDisabled}
                                />
                                <PlanCard.StandardPlan
                                    subscriptionPlan={data.subscriptionPlans[1]}
                                    disabled={isDisabled}
                                    setDisabled={setIsDisabled}
                                />
                                <PlanCard.BusinessPlan subscriptionPlan={data.subscriptionPlans[2]} />
                            </>
                        )}
                    </div>
                    <p className="text-saga-gray-500 ml-0 grow text-center">
                        {t('billing.modal_plans.footer_disclaimer')}
                        <br />
                        <Trans
                            dangerouslySetInnerHTML
                            i18nKey="billing.modal_plans.sagaAi_disclaimer"
                            components={{
                                fairUsageLink: (
                                    <a
                                        href="https://saga.so/guides/saga-ai-faqs"
                                        rel="noreferrer noopener"
                                        target="_blank"
                                        className="text-saga-new-blue"
                                    >
                                        {t('billing.modal_plans.fair_usage')}
                                    </a>
                                ),
                            }}
                        />
                        <br />
                        <Trans
                            dangerouslySetInnerHTML
                            i18nKey="billing.modal_plans.footer_contacts"
                            components={{
                                link: (
                                    <a href="mailto:billing@saga.so" className="text-saga-new-blue">
                                        billing@saga.so
                                    </a>
                                ),
                            }}
                        />
                    </p>
                </div>
            </SettingsPane.Content>
        </SettingsPane>
    );
}
