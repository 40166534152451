import React from 'react';
import { SagaEditor, WeakPage, isInlinePageLink, InlinePageLink } from '@saga/shared';
import classNames from 'classnames';
import Emoji from '@/components/Emoji';
import { useSelected } from 'slate-react';
import { FileText } from 'react-feather';
import { usePageStaticElement } from '@/components/PublicPageProvider';

function Icon({ page }: { page: Pick<WeakPage, 'icon'> | null }) {
    if (page?.icon) {
        return (
            <span className="relative inline-emoji">
                <React.Suspense fallback={null}>
                    <Emoji emoji={page?.icon.colons} size={0} />
                </React.Suspense>
            </span>
        );
    }

    return (
        <FileText
            className="inline align-middle"
            style={{ height: '0.9em', width: '0.9em', transform: 'translateY(-0.1em)' }}
        />
    );
}

export function InlinePageLinkElement({
    page,
    selected,
    children,
    onClick,
    onMouseOut,
    onMouseOver,
    title,
    element,
    highlightId,
    showIcon,
}: {
    element: InlinePageLink;
    page: Pick<WeakPage, 'icon'> | null;
    selected?: boolean;
    title: string | null;
    onClick?: (evt: React.MouseEvent) => void;
    onMouseOut?: React.MouseEventHandler<HTMLSpanElement>;
    onMouseOver?: React.MouseEventHandler<HTMLSpanElement>;
    highlightId?: string;
    showIcon?: boolean;
} & SagaEditor.Plugins.BlockPluginProps<InlinePageLink>) {
    return (
        <span
            className={classNames(
                'text-saga-text inline-page-link dark:text-neutral-200 font-normal inParagraph:font-normal heading1:font-bold heading2:font-bold title:font-bold within-checked:text-saga-gray-dark dark:within-checked:text-saga-gray-200 relative select-none overflow-hidden',
                {
                    'bg-saga-new-purple-light/20 shadow-lightblue rounded': selected,
                    'cursor-pointer': onClick != null,
                },
            )}
            onMouseDown={onClick}
            role="link"
            onMouseOut={onMouseOut}
            onMouseOver={onMouseOver}
            id={element.id}
            data-highlight-id={highlightId}
        >
            <span className="hidden select-none">{children}</span>
            <span className="space-x-0.5">
                {showIcon && <Icon page={page} />}
                <span>
                    {title}
                    {page == null && ' (deleted)'}
                </span>
            </span>
        </span>
    );
}

export const inlinePageLinkBlockPlugin = SagaEditor.Plugins.createBlockPlugin({
    match: isInlinePageLink,
    Component(props) {
        const staticPage = usePageStaticElement(props.element)?.staticPage ?? props.element.staticPage;
        const selected = useSelected();

        const handlePageClick = React.useCallback(() => {
            const pageUrl = `/${props.element.pageId}`;

            window.open(pageUrl, '_self');
        }, [props.element.pageId]);

        return (
            <InlinePageLinkElement
                {...props}
                page={staticPage ?? null}
                title={staticPage?.title ?? null}
                selected={selected}
                onClick={handlePageClick}
            />
        );
    },
});
