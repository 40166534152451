import React from 'react';
import { DependencyList } from 'react';

export default function useIntersection<T extends HTMLElement>(onIntersect: () => void, deps: DependencyList = []) {
    const elementRef = React.useRef<T | null>(null);

    const handlerRef = React.useRef(onIntersect);
    handlerRef.current = onIntersect;

    React.useEffect(() => {
        const element = elementRef.current;

        if (element) {
            const observer = new IntersectionObserver((entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        handlerRef.current();
                    }
                });
            });

            observer.observe(element);

            return () => {
                observer.unobserve(element);
            };
        }

        return;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);

    return elementRef;
}
