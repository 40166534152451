import { BlockBuilder, EditorOperations, isLinearIssue, SagaElement } from '..';
import { BaseRange, Editor, Transforms } from 'slate';
import { ReactEditor } from 'slate-react';

type InputProps =
    | { type: 'add'; teamId: string; title: string }
    | {
          type: 'get';
          linearIssueId: string;
      };

export default function addLinearIssue(
    getSdk: any,
    editor: Editor,
    inputProps: InputProps,
    target?: BaseRange,
): SagaElement {
    const link = BlockBuilder.linearIssue([BlockBuilder.text('')], { type: 'loading' });
    if (target) {
        EditorOperations.Transforms.insertInlineNode(editor, link, target);
    } else {
        Transforms.insertFragment(editor, [link]);
    }
    getSdk()
        .then((sdk: any) => {
            switch (inputProps.type) {
                case 'add':
                    return sdk.LinearIssueCreate({
                        input: { teamId: inputProps.teamId, title: inputProps.title },
                    });
                case 'get':
                    return sdk.LinearIssue({
                        input: { id: inputProps.linearIssueId },
                    });
            }
        })
        .then((data: any) => {
            let issue;
            if (inputProps.type === 'add') {
                issue = data.linearIssueCreate;
            } else if (inputProps.type === 'get') {
                issue = data.linearIssue;
            }

            const path = ReactEditor.findPath(editor, link);
            Transforms.setNodes(editor, { state: { type: 'loaded', issue } }, { at: path, match: isLinearIssue });
        })
        .catch(() => {
            const path = ReactEditor.findPath(editor, link);
            Transforms.setNodes(editor, { state: { type: 'error' } }, { at: path, match: isLinearIssue });
        });

    return link;
}
