import { prompt } from '../../../shared/src/aiPrompts.json';

export default {
    translation: {
        common: {
            open_saga: 'Abrir Saga',
            popover_placeholder: 'Introducir texto o @ para',
            ai: 'IA',
            commands: 'comandos',
            and: 'y',
            or: 'o',
            in: 'en',
            add_dotted: 'Añadir...',
            turn_into: 'Convertir en',
            plus_template: '+ Plantilla',
            plus_collection: '+ Colección',
            plus_alias: '+ Alias',
            new_alias_placeholder: 'Escribir un nuevo Alias...',
            create_alias: '&nbsp;— Crear Alias',
            remove_alias: 'Eliminar {{alias}}',
            add_template_title: 'Seleccionar una plantilla',
            add_template_placeholder: 'Buscar una plantilla...',
            open: 'Abrir',
            mark_as_complete: 'Marcar como completado',
            open_in_new_tab: 'Abrir en una nueva pestaña',
            add_to_pinned: 'Añadir a fijados',
            add_to_favorites: 'Añadir a favoritos',
            remove_from_pinned: 'Eliminar de fijados',
            remove_from_favorites: 'Eliminar de favoritos',
            create: 'Crear',
            create_workspace_title: 'Vamos a crear tu espacio de trabajo.',
            create_workspace_description:
                'Un Espacio de trabajo es donde tus páginas, ideas y conocimientos se conectan y organizan.',
            create_workspace_placeholder: 'Mi Espacio de trabajo',
            save: 'Guardar',
            edit: 'Editar',
            cancel: 'Cancelar',
            reload: 'Recargar',
            reset: 'Restablecer',
            logout: 'Cerrar sesión',
            login: 'Iniciar sesión',
            learn_more: 'Aprender más',
            free_plan: 'Plan gratuito',
            upgrade: 'Actualizar',
            show_me: 'Muéstrame',
            got_it: 'Ok, entendido.',
            dismiss: 'Descartar',
            do_this_later: 'Hacer esto más tarde',
            get_started: 'Empezar',
            dates: 'Fechas',
            link_to_collection: 'Enlazar a una Colección',
            insert: 'Insertar',
            recents: 'Recientes',
            google_drive: 'Google Drive',
            linear_issues: 'Incidencias de Linear',
            tasks: 'Tareas',
            table_commands: 'Comandos de Tabla',
            mention_members: 'Mencionar Miembros',
            insert_page_link_to: 'Insertar enlace de página a',
            link_to_page: 'Enlazar a Página',
            gdrive_file: 'Archivo de Google Drive',
            linear_issue: 'Incidencia de Linear',
            new_template: 'Nueva Plantilla',
            create_template: 'Crear Plantilla',
            create_template_placeholder: 'Introducir un nombre de plantilla...',
            edit_template: 'Editar Plantilla',
            use_template: 'Usar Plantilla',
            recent_templates: 'Plantillas Recientes',
            create_page_from_template: 'Crear página desde plantilla',
            page_created: 'Página Creada',
            has_been_created: 'ha sido creada',
            blocks_moved: 'Bloques movidos',
            selected_blocks_have_been_moved: 'Los bloques seleccionados han sido movidos a:',
            selected_blocks_have_been_turned: 'Los bloques seleccionados se han convertido en una nueva página:',
            live_block_details: 'Un Bloque en Vivo de los bloques seleccionados ha sido creado en:',
            live_block_created: 'Bloque en Vivo Creado',
            duplicate: 'Duplicar',
            move_to: 'Mover a',
            duplicate_page: 'Duplicar Página',
            duplicate_task: 'Duplicar Tarea',
            copy_page: 'Copiar página',
            undo: 'Deshacer',
            is_mac: '⌘ + Z',
            is_windows: 'Ctrl + Z',
            export: 'Exportar',
            delete: 'Eliminar',
            update: 'Actualizar',
            continue: 'Continuar',
            upgrade_plan: 'Mejorar plan',
            see_plans: 'Gestionar plan',
            delete_page: 'Eliminar Página',
            remove_from_page: 'Eliminar de la página',
            first_name: 'Nombre',
            last_name: 'Apellido',
            open_workspace: 'Abrir Espacio de trabajo',
            open_page: 'Abrir Página',
            quick_edit: 'Edición rápida',
            open_full_page: 'Abrir página completa',
            close: 'Cerrar',
            close_popup: 'Cerrar Popup',
            close_entity: 'Cerrar {{entity}}',
            close_modal: 'Cerrar modal',
            close_quick_edit_modal: 'Cerrar modal de edición rápida',
            open_notifications: 'Abrir Notificaciones',
            close_notifications: 'Cerrar Notificaciones',
            close_sidebar: 'Cerrar Barra lateral',
            close_task_context_menu: 'Cerrar Menú contextual de Tarea',
            close_context_menu: 'Cerrar Menú contextual',
            open_context_menu: 'Abrir Menú contextual',
            open_task_context_menu: 'Abrir Menú contextual de Tarea',
            close_sidebar_context_menu: 'Cerrar Menú contextual de Barra lateral',
            open_sidebar: 'Abrir Barra lateral',
            open_sidebar_context_menu: 'Abrir Menú contextual de Barra lateral',
            open_page_context_menu: 'Abrir Menú contextual de Página',
            close_page_context_menu: 'Cerrar Menú contextual de Página',
            open_image_context_menu: 'Abrir Menú contextual de Imagen',
            close_property_menu: 'Cerrar Menú de Propiedades',
            open_property_menu: 'Abrir Menú de Propiedades',
            close_pages_context_menu: 'Cerrar Menú contextual de Páginas',
            open_pages_context_menu: 'Abrir Menú contextual de Páginas',
            open_in_codeSandbox: 'Abrir en CodeSandbox',
            open_context_menu_for_column: 'Abrir Menú contextual para Columna {{column}}',
            back_to_my_saga: 'Volver a mi Saga',
            referenced_in_pages: 'Referenciado en Páginas',
            see_pages_that_reference: 'Ver páginas que referencian el bloque:',
            remove_icon: 'Eliminar Icono',
            add_icon: 'Añadir Icono',
            change_icon: 'Cambiar Icono',
            remove_callout: 'Eliminar Llamada',
            click_add_image: 'Clic para añadir una imagen',
            click_add_image_or_embed: 'Clic para añadir una imagen o <1>incrustar desde URL</1>',
            image_could_not_be_loaded: 'La imagen no pudo ser cargada.',
            select_or_search: 'Seleccionar o buscar',
            text_copied: 'Texto copiado',
            copy_text: 'Copiar texto',
            prompt_copied: 'Aviso copiado',
            copy_prompt: 'Copiar aviso',
            stop: 'Detener',
            select: 'Seleccionar',
            search_in_pages: 'Buscar en páginas',
            navigation: 'Navegación',
            members: 'Miembros',
            pages: 'Páginas',
            collections: 'Colecciones',
            actions: 'Acciones',
            create_new_page: 'Crear nueva página',
            create_live_block: 'Crear Bloque en Vivo',
            create_live_block_description: 'Referenciar esta selección en otra página',
            create_live_block_disabled: 'Crear un Bloque en Vivo no es posible con esta selección.',
            remove_image: 'Eliminar Imagen',
            is_in: 'está en',
            template: 'Plantilla',
            view_references: 'Ver Referencias',
            try_saga: 'Probar Saga',
            search: 'Buscar',
            report_page: 'Reportar Página',
            paste_as_link: 'Pegar como Enlace',
            paste_as_preview: 'Pegar como Vista Previa de Enlace',
            paste_as_google_drive: 'Pegar como Enlace de Google Drive',
            paste_as_linear_link: 'Pegar como Enlace de Linear',
            paste_as_text: 'Pegar como texto',
            paste_as_live_block: 'Pegar como Bloque en Vivo',
            logged_in_with: 'Has iniciado sesión con',
            log_in: 'Iniciar sesión',
            gain_access:
                'con un correo electrónico diferente, o contacta a un propietario de este espacio de trabajo para obtener acceso.',
            highlighted_content: {
                text: 'Color de Fuente',
                background: 'Resaltar Texto',
            },
            properties_tooltip:
                'Las propiedades son campos personalizables, como texto, etiquetas y números que puedes añadir a tus páginas para proporcionar un contexto más detallado.',
            confirm_changes: 'Confirmar cambios',
            you: 'Tú',
            mentioned_by: 'Mencionado por',
            mentioned_on: 'Mencionado en',
            copy: 'Copiar',
            all_collections: 'Todas las Colecciones',
            no_collections: 'Sin Colecciones',
            n_collections_other: '{{count}} Colecciones',
            n_collections_one: '{{count}} Colección',
            search_collection_placeholder: 'Buscar Colección...',
        },
        inapp_notifications: {
            title: 'Saga 1.0',
            description:
                '¡Estamos oficialmente fuera de la beta! Lea el comunicado para obtener más información sobre lo que sigue.',
        },
        invite_onboarding: {
            create_button: 'Crear Nuevo Espacio de trabajo',
            join_button: 'Unirse al Espacio de trabajo',
            join_title: 'Unirse o crear un espacio de trabajo',
            invited_description: 'No has sido invitado a ningún espacio de trabajo.',
            create_space_title: 'Crea tu propio Espacio de trabajo en su lugar.',
            invited_description_one: 'Has sido invitado a <bold>espacio de trabajo</bold>.',
            invited_description_other: 'Has sido invitado a <bold>espacios de trabajo</bold>.',
            member_one: 'miembro',
            member_other: 'miembros',
        },
        editor: {
            loading_preview_dotted: 'Cargando la vista previa...',
            edit_link: 'Editar Enlace',
            link_to: 'Enlazar a',
            link_copied: 'Enlace copiado',
            copy_Link: 'Copiar Enlace',
            turn_into: 'Convertir en',
            link_into_pretty_link: 'Convertir enlace en enlace bonito',
            link_into_embed: 'Convertir enlace en incrustado',
            move_to_page: 'Mover a página',
            create_live_block: 'Crear Bloque en Vivo',
            add_live_block: 'Añadir como Bloque en Vivo a la página',
            create_template: 'Crear Plantilla',
            edit_template: 'Estás editando una plantilla.',
            view_templates: 'Ver Todas las Plantillas',
            create_page: 'Crear Página',
            create_collection: 'Crear Colección',
            create_task: 'Crear Tarea',
            all_templates: 'Todas las plantillas',
            create_issue_in: 'Incidencia en {{teamName}}',
            turn_into_link: 'Convertir en enlace',
            turn_into_link_preview: 'Convertir en vista previa de enlace',
            turn_into_embed: 'Convertir en incrustación',
            embed_context_menu: 'Menú Contextual de Incrustación',
            loading_embed: 'Cargando la incrustación...',
            remove_embed: 'Eliminar Incrustado',
            remove_link: 'Eliminar enlace',
            link_preview_not_available: 'La vista previa del enlace no está disponible.',
            turn_into_page: 'Página <1>{{value}}</1>',
            turn_into_page_link: 'Convertir en Enlace de Página',
            turn_into_task: 'Tarea <1>{{value}}</1>',
            turn_into_liner_issue: 'Incidencia de Linear <1>{{value}} en {{team}}</1>',
            turn_into_liner_issue_tooltip: 'Problema de Linear {{value}} en {{team}}',
            copy_link_to_block: 'Copiar enlace al bloque',
            delete_block: 'Eliminar bloque',
            block_deleted: 'Bloque eliminado',
            quick_edit: 'Edición rápida',
            empty_page: 'Página Vacía',
            format_text: 'Texto',
            format_todo: 'Lista de Tareas',
            format_task: 'Tarea',
            format_heading_l: 'Encabezado — Grande',
            format_heading_m: 'Encabezado — Mediano',
            format_heading_s: 'Encabezado — Pequeño',
            format_ul: 'Puntos',
            format_ol: 'Lista Numerada',
            format_img: 'Imagen',
            format_file: 'Subir Archivo',
            format_table: 'Tabla',
            format_callout: 'Llamada',
            format_quote: 'Cita',
            format_divider: 'Divisor',
            format_code: 'Código',
            format_equation: 'Ecuación',
            format_inline_equation: 'Ecuación en línea',
            suffix_create_task: '— Crear Tarea',
            suffix_create_issue_in: '— Incidencia en {{team}}',
            insert_page_link_to: 'Insertar Enlace de Página a {{title}}',
            insert_recent_page_link_to: 'Insertar Enlace de Página Reciente a {{title}}',
            insert_column_left: 'Insertar Columna a la Izquierda',
            insert_column_right: 'Insertar Columna a la Derecha',
            insert_row_above: 'Insertar Fila Arriba',
            insert_row_below: 'Insertar Fila Abajo',
            placeholder: {
                h1: 'Encabezado 1',
                h2: 'Encabezado 2',
                h3: 'Encabezado 3',
                task: 'Tarea',
                list: 'Lista',
                quote: 'Cita',
                enter_text: 'Introducir texto',
                enter_text_dotted: 'Introducir texto...',
            },
            confirm_table_delete: '¿Estás seguro de que quieres eliminar la tabla seleccionada?',
            file_upload: {
                click_to_upload_file: 'Clic para subir un archivo',
                uploading: 'Subiendo...',
                download_file: 'Descargar Archivo',
                remove_file: 'Eliminar Archivo',
                close_file_context_menu: 'Cerrar Menú Contextual de Archivo',
                open_file_context_menu: 'Abrir Menú Contextual de Archivo',
                max_file_size_error: 'El tamaño máximo del archivo es {{size}}.',
            },
            actions: 'Acciones',
        },
        update_references_modal: {
            title: '¿Actualizar enlaces a esta página?',
            description_one:
                'Renombrar esta página de <span_red>{{-previousTitle}}</span_red> a <span_yellow>{{-newTitle}}</span_yellow> romperá <span_bold>{{count}}</span_bold> enlace a esta página. ¿Quieres actualizarlos?',
            description_other:
                'Renombrar esta página de <span_red>{{-previousTitle}}</span_red> a <span_yellow>{{-newTitle}}</span_yellow> romperá <span_bold>{{count}}</span_bold> enlaces a esta página. ¿Quieres actualizarlos?',
            select_all: 'Seleccionar Todo',
            deselect_all: 'Deseleccionar Todo',
            update_links: 'Actualizar Enlaces',
            update_link_one: 'Actualizar {{count}} enlace',
            update_link_other: 'Actualizar {{count}} enlaces',
            dont_update: 'No actualizar',
        },
        updated_title_notice: {
            outdated_link_one: 'Hay <bold>{{count}}</bold> enlace desactualizado a esta página.',
            outdated_link_other: 'Hay <bold>{{count}}</bold> enlaces desactualizados a esta página.',
            review: 'Revisar',
            update_all: 'Actualizar todo',
        },
        ai: {
            title: 'Saga IA',
            ask_ai: 'Preguntar a la IA',
            ask_ai_anything_mentions_dotted: 'Preguntar a la IA...',
            ask_ai_anything_dotted: 'Preguntar a la IA...',
            rewrite: 'Reescribir',
            discard: 'Descartar',
            rerun: 'Volver a ejecutar',
            keep: 'Mantener',
            insert_below: 'Insertar debajo',
            replace: 'Reemplazar',
            generating_dotted: 'Generando...',
            searching_space_dotted: 'Buscando en el espacio de trabajo...',
            searching_page_dotted: 'Buscando en la página...',
            too_many_requests: 'Has enviado demasiadas solicitudes. Intenta de nuevo en unos segundos.',
            too_many_prompt_tokens:
                'Has alcanzado la longitud máxima de conversación para esta respuesta de IA. <1>Aprender más</1>',
            reduce_prompt_error: 'Por favor, acorte el aviso a un máximo de {{max_length}} caracteres',
            restricted: 'Has alcanzado la cuota mensual de uso justo para Saga IA. <1>Aprender más</1>',
            issue_with_generating: 'Hubo un problema al generar tu texto.',
            edit_selection: 'Editar selección',
            change_tone: 'Cambiar tono',
            translate: 'Traducir',
            draft: 'Borrador',
            discard_response: '¿Quieres descartar la respuesta de la IA?',
            my_prompts: 'Mis Prompts',
            my_commands: 'Mis Comandos',
            search_or_select: 'Buscar o seleccionar',
            mention: 'Menciona una página o tarea',
            suggestion: {
                rewrite: 'Reescribir',
                continue: 'Continuar escribiendo',
                summarize: 'Resumir',
                minimize: 'Hacer más corto',
                maximize: 'Hacer más largo',
                explain: 'Explicar',
                fix: 'Corregir ortografía y gramática',
                tone_concise: 'Conciso',
                tone_professional: 'Profesional',
                tone_casual: 'Informal',
                tone_funny: 'Divertido',
                translate_en: 'Inglés',
                translate_es: 'Español',
                translate_fr: 'Francés',
                translate_de: 'Alemán',
                translate_pt: 'Portugués',
                translate_it: 'Italiano',
                translate_nl: 'Holandés',
                translate_zh: 'Chino',
                translate_ko: 'Coreano',
                translate_ja: 'Japonés',
                translate_ua: 'Ucraniano',
                translate_ru: 'Ruso',
                brainstorm: 'Generar ideas',
                brainstorm_about: prompt.brainstorm_about.es,
                outline: 'Esquema',
                outline_about: prompt.outline_about.es,
                email: 'Correo electrónico',
                email_about: prompt.email_about.es,
                meeting: 'Agenda de reunión',
                meeting_about: prompt.meeting_about.es,
                blog: 'Entrada de blog',
                blog_about: prompt.blog_about.es,
                social: 'Publicación en redes sociales',
                social_about: prompt.social_about.es,
                website: 'Texto para sitio web',
                website_about: prompt.website_about.es,
                cover_letter: 'Carta de presentación',
                cover_letter_about: prompt.cover_letter_about.es,
                press: 'Comunicado de prensa',
                press_about: prompt.press_about.es,
                job: 'Descripción de trabajo',
                job_about: prompt.job_about.es,
                essay: 'Ensayo',
                essay_about: prompt.essay_about.es,
            },
            chat: {
                tooltip: 'Abrir el chat de IA',
                button: 'Chat de IA',
                initial: {
                    greeting: 'Hey {{name}}, ¿en qué puedo ayudar?',
                    bullet1:
                        'Chatea conmigo para explorar temas, responder preguntas o crear contenido a partir de tus notas de Saga.',
                    bullet2: 'Pregunta cualquier cosa y utilizaré tus notas para responder.',
                    bullet3: '¡Cuanto más agregues a Saga, más podré ayudarte!',
                },
                new_thread: 'Nuevo hilo',
                history: 'Historial',
                no_threads: 'Aún no tienes ningún hilo. Comienza uno nuevo para empezar.',
            },
        },
        errors: {
            cant_open_page: 'Parece que estamos teniendo problemas para abrir esta página dentro de Saga.',
            something_went_wrong: 'Algo salió mal',
            something_went_wrong_description: 'Vaya, esto no se esperaba.\nPor favor, intenta de nuevo.',
            something_went_wrong_try_again:
                '🤔 Algo salió mal, Por favor, intenta de nuevo\nSi el problema persiste, contáctanos',
            connection_lost_title: 'Conexión perdida',
            connection_lost_description:
                'Tu conexión se cerró debido a inactividad.\nPor favor, recarga Saga para reconectar.',
        },
        side_by_side: {
            open: 'Abrir Lado a Lado',
            tooltip: 'Abrir la página al lado de la página actual',
            open_in_new_tab: 'Abrir en una nueva pestaña',
            copy_link: 'Copiar enlace al portapapeles',
        },
        time: {
            years_ago: 'hace {{years}} años',
            months_ago: 'hace {{months}} meses',
            weeks_ago: 'hace {{weeks}} semanas',
            days_ago: 'hace {{days}} días',
            date: 'Fecha',
            today: 'Hoy',
            yesterday: 'Ayer',
            tomorrow: 'Mañana',
            last_year: 'El año pasado',
            last_week: 'La semana pasada',
            last_month: 'El mes pasado',
            next_period: 'Próximo {{label}} - {{date}}',
            last_period: 'Último {{label}} - {{date}}',
        },
        tutorial: {
            finishButton: 'Inicia tu Saga',
            writeWthEase: {
                title: 'Escribe con facilidad',
                description: 'El editor rápido y sencillo de Saga te ayuda a crear hermosas notas y documentos.',
            },
            autolink_pages: {
                title: 'Páginas de Autolink',
                description: 'Saga reconoce cuando mencionas un título de página y crea automáticamente un enlace.',
            },
            autocomplete_menu: {
                title: 'El Menú de Autocompletar',
                description: '<1>Inserta bloques y enlaza páginas.</1><2>Escribe @ o / en el editor para probarlo.</2>',
            },
            saga_ai: {
                title: 'Saga IA',
                description:
                    'El asistente inteligente responde preguntas, genera contenido y te ayuda a trabajar más rápido.',
            },
            unified_search: {
                title: 'Búsqueda Unificada',
                description:
                    '<1>Navega o busca en tu espacio de trabajo.</1><2>Presiona {{searchHeading}} para probarlo.</2>',
            },
        },
        onboarding: {
            complete_signup: 'Completa el registro',
            start_your_saga: 'Comencemos tu Saga',
            i_agree_with_toc: 'Acepto la <1>Política de Privacidad</1> y los <2>Términos de Servicio</2> de Saga',
            create_first_workspace: 'Creemos tu primer espacio de trabajo',
            create_workspace: 'Creemos tu espacio de trabajo.',
            create_workspace_description:
                'Un espacio de trabajo es donde tus páginas, ideas y conocimientos se conectan y organizan.',
            what_is_this_workspace_for: '¿Para qué es este espacio de trabajo?',
            usecase_personalNotes: 'Notas personales',
            usecase_teamWork: 'Trabajo en equipo',
            open_workspace: 'Abrir espacio de trabajo',
            what_is_this_workspace: '¿Para qué es este espacio de trabajo?',
            login: {
                title: 'Comencemos tu Saga',
                with_google: 'Continuar con Google',
                with_email: 'Continuar con Email',
                link_has_expired:
                    '🤔 Tu enlace ha expirado, o estás intentando iniciar sesión desde un dispositivo diferente.\n\nPor favor, inicia sesión de nuevo 👇',
                toc: 'Al hacer clic en "Continuar con Google/Email" arriba, aceptas nuestra <1>Política de Privacidad</1> y los <2>Términos de Servicio</2>.',
                info: 'Te registraremos o crearemos una cuenta si aún no tienes una.',
                desktop_app_button: 'Descarga la aplicación de escritorio',
            },
            magic_link_login: {
                title: '📨 Revisa tu bandeja de entrada',
                subtitle: 'Acabamos de enviarte un enlace de inicio de sesión a esta dirección de correo electrónico:',
                enter_email: 'Ingresa tu dirección de correo electrónico...',
                subtitle_email_for_join:
                    'Acabamos de enviarte un enlace de inicio de sesión a esta dirección de correo electrónico para unirte al espacio de trabajo:',
                welcome_to: 'Bienvenido a {{-workspace}}',
                welcome_to_page: 'Bienvenido a {{-page}}',
                close_this_tab: 'Ahora puedes cerrar esta pestaña.',
                invite_for_join:
                    'Esta es una invitación para <1>{{email}}</1>.\nInicia sesión con la misma dirección para aceptarla.',
                invite_not_active: 'Esta invitación ya no está activa',
                request_new_invite_short:
                    'Contacta a un propietario de este espacio de trabajo y solicita una nueva invitación.',
                request_new_page_invite_short:
                    'Contacta a un administrador de esta página y solicita una nueva invitación.',
                not_have_access: 'No tienes acceso a este espacio de trabajo',
                not_have_page_access: 'No tienes acceso a esta página',
                logged_in_with_different_email:
                    'Has iniciado sesión con <1>{{email}}</1>. <2>Inicia sesión</2> con un correo electrónico diferente o contacta a un propietario de este espacio de trabajo para solicitar acceso.',
                logged_in_with_different_email_page:
                    'Has iniciado sesión con <1>{{email}}</1>. <2>Inicia sesión</2> con un correo electrónico diferente o contacta a un administrador de esta página para solicitar acceso.',
                request_new_invite:
                    'Contacta a un propietario de este espacio de trabajo y solicita una nueva invitación.\nVuelve a la <1>Página Principal</1> y crea tu propio espacio de trabajo en su lugar',
            },
        },
        help: {
            give_feedback: 'Dar Retroalimentación',
            give_feedback_tooltip:
                '🚀 Eres uno de los pioneros de Saga (te uniste {{userCreatedDate}}). Estamos trabajando duro para lanzar mejoras y nuevas características a la velocidad de la luz, si tienes retroalimentación para nosotros, envíanos un correo electrónico a feedback@saga.so',
            contact_us: 'Contáctanos',
            join_slack: 'Únete a Slack',
            join_discord: 'Únete a Discord',
            youtube_channel: 'Canal de Youtube',
            tweet_us: 'Twitéanos',
            open_website: 'Abrir Sitio Web',
            help_guide: 'Guía de Ayuda',
            start_tutorial: 'Iniciar tutorial',
        },
        top_menu: {
            search: 'Buscar...',
            searching: 'Buscando...',
            searching_for: 'Buscando:',
            search_dotted: 'Buscar...',
            search_shortcut: 'Buscar Ctrl/Cmd + K',
            add_page_to_favorites: 'Añadir página a favoritos',
            add_collection_to_favorites: 'Añadir colección a favoritos',
            add_task_to_favorites: 'Añadir tarea a favoritos',
            add_task_view_to_favorites: 'Añadir vista de tarea a favoritos',
            add_page_view_to_favorites: 'Añadir vista de página a favoritos',
            add_page_to_pinned: 'Añadir Página a Fijadas',
            add_collection_to_pinned: 'Añadir Colección a Fijadas',
            add_task_to_pinned: 'Añadir Tarea a Fijadas',
            add_task_view_to_pinned: 'Añadir Vista de Tarea a Fijadas',
            add_page_view_to_pinned: 'Añadir Vista de Página a Fijadas',
            search_for_or_create_new: 'Buscar o crear nuevo',
            search_for_collection_label: 'Seleccionar o crear colección',
            create_collection: '— Crear Colección',
            create_page: '— Crear nueva página',
            close_search_results: 'Cerrar Resultados de Búsqueda',
            share: {
                label: 'Compartir',
                share_with: 'Compartido con los miembros del espacio de trabajo',
                copy_link: 'Copiar enlace',
                copy_link_tooltip: 'Usando enlace público, la página puede ser accedida por cualquiera.',
                copy_public_link: 'Copiar enlace público',
                copy_public_link_tooltip:
                    'Usando enlace privado, la página solo puede ser accedida por ti o otros miembros del espacio de trabajo.',
                link_copied: '¡Enlace Copiado!',
                members_count: 'miembros de {{-workspaceTitle}}',
                share_count_zero: 'Compartido solo contigo',
                share_count_one: 'Compartido solo contigo',
                share_count_other: 'Compartido con <1>{{membersCount}} personas</1>.',
                manage: 'Gestionar',
                manage_members: 'Gestionar miembros',
                view_members: 'Ver miembros',
                publish: 'Publicar',
                page_not_published: 'Publica esta Página, entonces cualquiera con el enlace puede <1>verla</1>.',
                page_published: 'Cualquiera con el enlace puede <1>ver esta Página</1>.',
                workspace_not_published:
                    'Publica todo el Espacio de Trabajo, entonces cualquiera puede <1>ver todas las páginas</1> dentro de él',
                workspace_published:
                    'Cualquiera con el enlace puede <1>ver todas las páginas</1> dentro de este Espacio de Trabajo.',
                copying_page: 'Permitir Copiar Página',
                members_one: '1 miembro',
                members_other: '{{count}} miembros',
                copying_page_title: 'Elige un espacio de trabajo',
                copying_page_sub_title: 'La página será duplicada en:',
                copying_page_toast_success: '¡Página duplicada exitosamente!',
                copying_workspace: 'Permitir Copiar Espacio de Trabajo',
                copying_workspace_toggle: 'Cualquiera con el enlace puede copiar y duplicar este espacio de trabajo.',
                copying_page_disabled_tooltip:
                    'Eres un espectador en este espacio de trabajo y no puedes copiar esta página.',
                invite_members: 'Invitar nuevos miembros',
                share_with_members: 'Compartido con los miembros del espacio de trabajo',
                private_access: 'Acceso privado',
                members_with_access: 'Miembros con acceso a la página',
                guests_with_access: 'Invitados con acceso a la página',
                permission_admin_description: 'Puede eliminar la página o gestionar los permisos de la página',
                permission_viewer_description: 'Puede ver el contenido de la página.',
                permission_editor_description: 'Puede editar y eliminar el contenido de la página',
                invite_guest: 'Escribe el correo electrónico para invitar a un invitado...',
                type_email: 'Escribe el correo electrónico...',
            },
        },
        move_page: {
            move_modal_title: 'Elige un espacio de trabajo',
            move_modal_sub_title: 'Mover página a:',
            toast_success: '¡Página movida con éxito!',
            confirm_modal_title:
                'Algunos contenidos y configuraciones, como enlaces y permisos, pueden no funcionar en la página movida.',
            confirm_modal_content_left: 'La página original se archivará sin cambios.',
            confirm_modal_content_right: 'Se creará un duplicado en el espacio de trabajo seleccionado.',
            confirm_changes: 'Confirmar movimiento',
        },
        move_task: {
            move_modal_title: 'Elige un espacio de trabajo',
            move_modal_sub_title: 'Mover tarea a:',
            toast_success: '¡Tarea movida con éxito!',
            confirm_modal_title:
                'Algunos contenidos y configuraciones, como enlaces y permisos, pueden no funcionar en la tarea movida.',
            confirm_modal_content_left: 'La tarea original se archivará sin cambios.',
            confirm_modal_content_right: 'Se creará un duplicado en el espacio de trabajo seleccionado.',
            confirm_changes: 'Confirmar movimiento',
        },

        sidebar: {
            new_page: 'Nueva página',
            create_page: 'Crear Página',
            create_collection: 'Crear Colección',
            new_workspace: 'Nuevo Espacio de Trabajo',
            open_template_menu: 'Abrir Menú de Plantillas',
            pages: 'Páginas',
            tasks: 'Tareas',
            settings: 'Configuración',
            pinned: 'Fijado',
            favorites: 'Favoritos',
            logout: 'Cerrar Sesión',
            collections: 'Colecciones',
            shared: 'Compartido conmigo',
            notifications: {
                dont_have_notifications: 'Aún no tienes notificaciones.',
                accept: 'Aceptar',
                manage: 'Gestionar',
                invite_message: '{{name}} te ha invitado a unirte al espacio de trabajo',
                user_joined: '{{name}} se ha unido a tu espacio de trabajo',
                user_left: '{{name}} ha dejado tu espacio de trabajo',
                user_removed: '{{name}} te ha eliminado del espacio de trabajo',
                go_to_workspace: 'Ir al espacio de trabajo',
                go_to_page: 'Ir a la Página',
                go_to_task: 'Ir a la Tarea',
                load_more: 'Cargar más',
                mentioned_you: 'te mencionó en',
                assigned_you: 'te asignó a',
                unassigned_you: 'te desasignó de',
                invite_page_message: 'te invitó a su página',
            },
            upgrade_plan_modal: {
                title: 'Mejora tu plan',
                description:
                    'Para desbloquear espacios de trabajo ilimitados y obtener acceso a herramientas avanzadas de colaboración y características super adicionales que llevarán tu trabajo al siguiente nivel.',
            },
        },
        filters: {
            title: 'Título',
            az: 'A → Z',
            za: 'Z → A',
            created: 'Creado',
            updated: 'Actualizado',
            creators: {
                all_creators: 'Todos los creadores',
                no_creators: 'No hay creadores',
                n_creators_other: '{{count}} creadores',
                n_creators_one: '{{count}} creador',
                search_creator_placeholder: 'Buscar miembros...',
            },
        },
        pages: {
            title_pages: 'Páginas',
            title_deleted_pages: 'Páginas Eliminadas',
            title_deleted: 'Eliminado',
            title_templates: 'Plantillas',
            title_shared: 'Compartido',
            title_private: 'Privado',
            title_public: 'Público',
            fewest_items_first: 'Menos elementos primero',
            most_items_first: 'Más elementos primero',
            collection: 'Colección',
            fewest_words_first: 'Menos palabras primero',
            most_words_first: 'Más palabras primero',
            word_count: 'Conteo de Palabras',
            oldest_first: 'Más antiguo primero',
            newest_first: 'Más nuevo primero',
            close_pages: 'Cerrar Páginas',
            empty_state_deleted: 'No tienes páginas eliminadas.',
            empty_state_non_deleted: 'Agrega páginas para verlas aquí.',
            empty_state_templates: 'Agrega plantillas para verlas aquí.',
            empty_state_shared: 'No tienes ninguna página compartida contigo.',
            empty_state_private: 'No tienes páginas privadas.',
            empty_state_public: 'No tienes páginas públicas.',
            enter_page_name: 'Ingresa un nombre de página',
            favourite_untitled: 'Sin título',
            delete_all_forever: 'Eliminar todo para siempre',
            delete_all_page:
                '¿Estás seguro de que quieres eliminar todas las páginas? Esta acción no se puede deshacer.',
            delete_page_toast_title: 'La página está eliminada',
            delete_page_toast_description:
                'Las páginas eliminadas se almacenan en Archivado hasta que se eliminan permanentemente.',
            history: {
                history: 'Historia',
                current: 'Actual',
                versions_count: '({{number}} versiones)',
                versions: 'Versiones',
                upgrade_required: 'Actualización requerida',
                upgrade_modal: {
                    title: 'Actualizar plan',
                    description:
                        'Actualiza a un plan de pago para acceder al historial de versiones de más de 7 días y desbloquear otras funciones premium.',
                },
            },
            mode_dropdown: {
                pages: 'Páginas',
                templates: 'Plantillas',
                deleted_pages: 'Páginas Eliminadas',
            },
            new_page_placeholder: 'Nueva página',
            new: {
                create: 'Crear',
                label_page: 'Nueva Página',
                placeholder_page: 'Escribe un nuevo título de Página...',
                label_template: 'Nueva Plantilla',
                placeholder_template: 'Escribe un nuevo título de Página...',
            },
            headers: {
                title: 'Título',
                collection: 'Colección',
                word_count: 'Conteo de Palabras',
                created: 'Creado',
                updated: 'Actualizado',
                shared_by: 'Compartido por',
                role: 'Rol',
                creator: 'Creador',
            },
            deleted_page: 'Esta página está eliminada',
            restore: 'Restaurar',
            restore_page: 'Restaurar página',
            delete_forever: 'Eliminar para siempre',
        },
        references: {
            label: 'Referencias',
            tooltip: 'Las referencias muestran dónde se ha mencionado una página en todo tu espacio de trabajo.',
            result_one: '1 resultado',
            result_other: '{{count}} resultados',
            no_results: 'No se pudieron encontrar referencias para esta página.',
            no_results_search_panel: 'No se encontraron resultados. Intenta otra búsqueda.',
        },
        table_of_content: {
            label: 'Tabla de Contenido',
        },
        properties: {
            label: 'Propiedades',
        },
        page_info: {
            label: 'Información de la Página',
            creator: 'Creador',
            created: 'Creado',
            updated: 'Actualizado',
            word_count: 'Conteo de Palabras',
            page_settings: 'Configuración de la Página',
            autolink_pages_label: 'Auto-enlazar páginas',
            autolink_pages_description: 'Auto-enlazar esta página cuando su título se mencione en otras páginas',
            autolink_headings_label: 'Auto-enlazar encabezados',
            autolink_headings_description:
                'Auto-enlazar encabezados de esta página cuando se mencionen en otras páginas',
            full_width_label: 'Página de Ancho Completo',
            full_width_description: 'Mostrar página en ancho completo.',
        },
        tasks: {
            title: 'Tareas',
            new_task: 'Nueva Tarea',
            new_task_placeholder: 'Ingresa un título de tarea',
            new_task_title_placeholder: 'Nueva tarea',
            new_task_editor_placeholder: 'Ingresa título de tarea',
            createdAt: 'Creado',
            updatedAt: 'Actualizado',
            completedDate: 'Completado',
            due_date: 'Fecha de Vencimiento',
            no_due_date: 'Sin fecha de vencimiento',
            sort_asc: 'Ascendente',
            sort_desc: 'Descendente',
            sorted_by: 'Ordenado por',
            task_title: 'Título de la Tarea',
            referenced: 'Referenciado en',
            assignee: 'Asignado a',
            no_assignee: 'Sin asignar',
            priority: 'Prioridad',
            no_priority: 'Sin prioridad',
            priority_all: 'Todas las Prioridades',
            priority_high: 'Alta',
            priority_medium: 'Media',
            priority_low: 'Baja',
            priority_unprioritized: 'Sin prioridad',
            add_assignee_placeholder: 'Ingresa un nombre...',
            incomplete_tasks: 'Tareas Incompletas',
            incomplete_tasks_short: 'Incompletas',
            completed_tasks: 'Tareas Completadas',
            completed_tasks_short: 'Completadas',
            all_tasks: 'Todas las Tareas',
            all_tasks_short: 'Todas',
            all_assignees: 'Todos los Asignados',
            not_assigned: 'No asignado',
            close_task_one: 'Cerrar Tarea',
            close_task_other: 'Cerrar Tareas',
            add_task_below: 'Agregar tarea debajo',
            delete_task: 'Eliminar Tarea',
            confirm_delete_task:
                '¿Estás seguro de que quieres eliminar esta tarea? Se eliminará de todo el espacio de trabajo.',
            create_task_to_see_them: 'Crea Tareas para verlas aquí.',
            status_open: 'Por Hacer',
            status_in_progress: 'En Progreso',
            status_done: 'Hecho',
            status: 'Estado',
            archived: 'Archivado',
            deleted_task: 'Esta tarea está eliminada',
            restore_task: 'Restaurar tarea',
            delete_all_tasks: '¿Está seguro de que desea eliminar todas las tareas? Esta acción no se puede deshacer.',
            delete_task_toast_title: 'La tarea está eliminada',
            delete_task_toast_description:
                'Las tareas eliminadas se almacenan en Archivado hasta que se eliminan permanentemente.',
            new_label_placeholder: 'Introducir nombre de etiqueta...',
            select_or_create_label: 'Crear nuevo o seleccionar',
            add_new_label: 'Añadir nueva etiqueta',
            no_label: 'Sin etiqueta',
            no_labels: 'Sin etiquetas',
            n_labels_one: '{{count}} etiqueta',
            n_labels_other: '{{count}} etiquetas',
            all_labels: 'Todas las etiquetas',
            table_view: 'Vista de Tabla',
            kanban_view: 'Vista de Tablero',
        },
        collections: {
            page_title: 'Colecciones',
            filter_placeholder: 'Filtrar por palabra clave',
            add_to_collection: 'Añadir a Colección',
            add_page_to_collection: 'Añadir Página a Colección Interna',
            delete_collection: 'Eliminar Colección',
            add_parent_collection: 'Añadir colección padre',
            type_page_task_or_collection: 'Escribe una Página/Tarea o Colección...',
            select_create_new: 'Seleccionar o crear nuevo',
            new_collection: 'Nueva Colección',
            new_collection_placeholder: 'Escribe una nueva Colección...',
            empty_state_message: 'Crea Colecciones para verlas aquí.',
            sort: 'Ordenar',
            folder_view: 'Vista de Carpeta',
            tag_view: 'Vista de Etiqueta',
            pages: 'Páginas',
            sub_collections: 'Subcolecciones',
            oldest_first: 'El más antiguo primero',
            newest_first: 'El más nuevo primero',
            fewest_first: 'Menos páginas primero',
            most_first: 'Más páginas primero',
            sub_fewest_first: 'Menos primero',
            sub_most_first: 'Más primero',
            close_collections: 'Cerrar Colecciones',
            empty_state: 'Añade Páginas o Colecciones a esta Colección para verlas aquí.',
        },
        views: {
            save_view: 'Guardar',
            save_view_name_placeholder: 'Nombre de la vista ...',
            create_new_button: 'Crear nueva vista',
            update_button: 'Actualizar vista',
            delete_view: 'Eliminar vista',
        },
        settings: {
            sidebar: {
                account: 'Cuenta',
                title: 'Configuraciones',
                profile: 'Perfil',
                language: 'Idioma',
                appearance: 'Apariencia',
                editor: 'Editor',
                invites: 'Invitaciones',
                integrations: 'Integraciones',
                general: 'General',
                members: 'Miembros',
                plans: 'Planes',
                billing: 'Facturación',
                import_export: 'Importar & Exportar',
            },
            profile: {
                title: 'Perfil',
                sub_title: 'Gestiona tu perfil de Saga',
                your_email: 'Tu Correo Electrónico',
            },
            language: {
                title: 'Idioma',
                sub_title: 'Cambia el idioma utilizado en la interfaz de usuario.',
                language: 'Idioma',
                language_change_title: 'Actualizar idioma',
                language_change_confirmation: '¿Estás seguro de que quieres actualizar el idioma? ¡Saga se recargará!',
                language_changed_notification: 'Idioma actualizado',
            },
            appearance: {
                title: 'Apariencia',
                sub_title: 'Personaliza la apariencia de Saga.',
                theme: 'Tema',
                show_icons: 'Mostrar Iconos en Enlaces de Página en Línea',
                mode_auto: 'Modo Automático',
                mode_light: 'Modo Claro',
                mode_dark: 'Modo Oscuro',
                start_week_on_monday: 'Comenzar la semana el Lunes',
                font_default: 'Predeterminado',
                interface_font: 'Fuente de Interfaz',
                text_font: 'Fuente de Texto',
                example_text: 'Texto de ejemplo para probar tu fuente',
            },
            editor: {
                title: 'Editor',
                sub_title: 'Personaliza tu experiencia de edición.',
                spellcheck: 'Revisión ortográfica',
            },
            invite: {
                title: 'Invitaciones de Espacio de Trabajo',
                sub_title: 'Ver y aceptar todas tus invitaciones de espacio de trabajo',
                no_invites: 'No tienes invitaciones de espacio de trabajo en este momento.',
                invite_members_to: 'Invitar miembros',
                max_invites_reached:
                    '<bold>{{maxInvitedMembers}}/{{totalMembers}}</bold> invitaciones gratuitas restantes. <upgrade>Actualizar</upgrade> plan para invitaciones ilimitadas',
                accept_invite: 'Aceptar Invitación',
                invite_accepted: 'Invitación Aceptada',
                owned_by: 'propiedad de <1>{{ownerEmail}}</1>',
            },
            page_invite: {
                title: 'Invitaciones de página',
                sub_title: 'Ver y aceptar todas tus invitaciones de página',
                no_invites: 'No tienes invitaciones de página en este momento.',
            },
            integrations: {
                title: 'Integraciones',
                sub_title: 'Integra otras herramientas con Saga',
                remove_integration: '¿Estás seguro de que quieres eliminar esta integración?',
                google_drive: {
                    title: 'Google Drive',
                    description: 'Busca en tu drive y menciona archivos en páginas y tareas. <1>Aprender más</1>',
                    button_connect: 'Conectar a Google Drive',
                    button_disconnect: 'Eliminar Integración',
                    last_updated: 'Última Actualización {{date}}',
                },
                linear: {
                    title: 'Linear',
                    description:
                        'Busca en Linear y menciona problemas desde tu espacio de trabajo de Linear. <1>Aprender más</1>',
                    button_connect: 'Conectar a Linear',
                    button_disconnect: 'Eliminar Integración',
                },
            },
            general: {
                title: 'General',
                sub_title: 'Gestiona la configuración general de tu espacio de trabajo',
                workspace_name: 'Nombre del Espacio de Trabajo',
                icon: 'Icono',
                icon_description: 'Cambia el color del icono del espacio de trabajo.',
                embeddings: 'Incrustaciones del Espacio de Trabajo',
                embeddings_description: 'Genera incrustaciones para este Espacio de Trabajo.',
                copy_workspace: 'Copiar Espacio de Trabajo',
                copy_workspace_description:
                    'Todas las páginas, colecciones y configuraciones de este Espacio de Trabajo se copiarán en un nuevo Espacio de Trabajo.',
                delete_workspace: 'Eliminar Espacio de Trabajo',
                delete_workspace_description:
                    'Eliminar un Espacio de Trabajo borrará todos sus datos. Esto no se puede deshacer.',
                delete_workspace_confirmation: '¿Estás seguro de que quieres eliminar este Espacio de Trabajo?',
                leave_workspace: 'Dejar Espacio de Trabajo',
                leave_workspace_confirmation: '¿Estás seguro de que quieres dejar este Espacio de Trabajo?',
                autolink: 'Enlace Automático',
                autolink_description:
                    'Cuando está habilitado, Saga crea automáticamente enlaces a los títulos de las páginas dentro de las páginas y tareas.',
            },
            members: {
                title: 'Miembros',
                sub_title: 'Invita a otros a este Espacio de Trabajo',
                add_member: 'Agregar un Miembro',
                remove_member: 'Eliminar Miembro',
                remove_member_confirmation: '¿Estás seguro de eliminar a {{name}}?',
                remove_confirm: 'Confirmar eliminación',
                remove_member_toast_success: 'Miembro eliminado',
                pending_invite: 'Invitación Pendiente',
                revoke_invite: 'Revocar Invitación',
                revoke_invite_confirmation: '¿Estás seguro de revocar la invitación?',
                revoke_confirm: 'Confirmar revocación',
                revoke_invite_toast_success: 'Invitación revocada',
                paid_feature: 'Característica de Pago',
                role_admin: 'Admin',
                role_page_admin: 'Administrador de página',
                role_no_access: 'Sin acceso',
                role_admin_tooltip:
                    'Este miembro puede <1>crear, editar y eliminar contenido</1> en el espacio de trabajo y <1>invitar a otros</1>. También pueden <1>gestionar la configuración</1> y <1>eliminar el espacio de trabajo</1>.',
                role_editor: 'Editor',
                role_editor_tooltip:
                    'Este miembro puede <1>crear, editar y eliminar contenido</1> en el espacio de trabajo.',
                role_viewer: 'Visualizador',
                role_viewer_tooltip: 'Este miembro puede <1>ver contenido</1> en el espacio de trabajo.',
                role_member: 'Miembro',
                role_member_tooltip:
                    'Este miembro puede <1>crear, editar y eliminar contenido</1> en el espacio de trabajo y <1>invitar a otros. También pueden cambiar las páginas/espacios de trabajo a públicos</1> en el espacio de trabajo.',
                role_blocked_tooltip: 'Este miembro no tiene acceso al espacio de trabajo',
                invite_members: 'Invitar Miembros a {{-workspaceName}}',
                plus_add_more: '+ Agregar más',
                send_invites: 'Enviar invitaciones',
                invites_sent: 'Invitaciones enviadas',
                upgrade_plan: 'Actualizar tu plan',
                upgrade_plan_details:
                    'Has alcanzado el límite de {{count}} usuarios gratuitos. Actualiza tu plan para invitar a más usuarios y desbloquear características adicionales para mejorar.',
            },
            plans: {
                title: 'Planes del Espacio de Trabajo',
                loading: 'Cargando ...',
                sub_title_free:
                    'Usa Saga gratis, <bold>actualiza para una mayor colaboración</bold>, <bold>gestión de tareas</bold>, y <bold>Saga IA ilimitado</bold>.',
                sub_title_monthly_one:
                    'Tu suscripción actual es el plan Mensual Estándar. Tu espacio de trabajo de <bold>{{membersCount}} miembro</bold> cuesta <bold>{{amount}}</bold> por mes, y se renovará el <bold>{{date}}</bold>.',
                sub_title_monthly_other:
                    'Tu suscripción actual es el plan Mensual Estándar. Tu espacio de trabajo de <bold>{{membersCount}} miembros</bold> cuesta <bold>{{amount}}</bold> por mes, y se renovará el <bold>{{date}}</bold>.',
                sub_title_yearly_one:
                    'Tu suscripción actual es el plan Anual Estándar. Tu espacio de trabajo de <bold>{{membersCount}} miembro</bold> cuesta <bold>{{amount_month}}</bold> por mes / <bold>{{amount_year}}</bold> por año, y se renovará el <bold>{{date}}</bold>.',
                sub_title_yearly_other:
                    'Tu suscripción actual es el plan Anual Estándar. Tu espacio de trabajo de <bold>{{membersCount}} miembros</bold> cuesta <bold>{{amount_month}}</bold> por mes / <bold>{{amount_year}}</bold> por año, y se renovará el <bold>{{date}}</bold>.',
                sub_title_monthly_canceled_one:
                    'Tu plan de espacio de trabajo de <bold>{{membersCount}} miembro</bold> cuesta <bold>{{amount}}</bold> por mes. Cancelaste este plan, pero permanecerá activo hasta <bold>{{date}}</bold>. Después de eso, serás transferido a un plan Gratis.',
                sub_title_monthly_canceled_other:
                    'Tu plan de espacio de trabajo de <bold>{{membersCount}} miembros</bold> cuesta <bold>{{amount}}</bold> por mes. Cancelaste este plan, pero permanecerá activo hasta <bold>{{date}}</bold>. Después de eso, serás transferido a un plan Gratis.',
                sub_title_yearly_canceled_one:
                    'Tu plan de espacio de trabajo de <bold>{{membersCount}} miembro</bold> cuesta <bold>{{amount_month}}</bold> por mes o <bold>{{amount_year}}</bold> por año. Cancelaste este plan, pero permanecerá activo hasta <bold>{{date}}</bold>. Después de eso, serás transferido a un plan Gratis.',
                sub_title_yearly_canceled_other:
                    'Tu plan de espacio de trabajo de <bold>{{membersCount}} miembros</bold> cuesta <bold>{{amount_month}}</bold> por mes o <bold>{{amount_year}}</bold> por año. Cancelaste este plan, pero permanecerá activo hasta <bold>{{date}}</bold>. Después de eso, serás transferido a un plan Gratis.',
            },
            billing: {
                title: 'Facturación',
                sub_title: 'Gestiona tu información de facturación y facturas.',
                per_month: 'por miembro del espacio de trabajo / mes',
                free_plan: 'Plan Gratis',
                standard_annual_plan: 'Plan anual estándar',
                standard_monthly_plan: 'Plan mensual estándar',
                billing_portal_label: 'Ver y editar detalles de facturación',
                invoices: {
                    title: 'Facturas',
                    loading: 'Cargando las facturas...',
                    no_invoices: 'No hay facturas para mostrar.',
                    no_upcoming_invoices: 'No hay facturas próximas',
                    next_billing_on_one:
                        'Se te facturará <1>${{amount}}</1> el <1>{{date}}</1> por <1>{{billableMembersCount}} miembro</1>',
                    next_billing_on_other:
                        'Se te facturará <1>${{amount}}</1> el <1>{{date}}</1> por <1>{{billableMembersCount}} miembros</1>',
                    see_invoice: 'Ver factura',
                    invoice_not_ready: 'Factura no disponible',
                    paid: 'Pagado',
                    not_paid: 'No pagado',
                },
            },
            export_import: {
                title: 'Importar & Exportar',
                sub_title: 'Importar y exportar espacio de trabajo y gestionar configuraciones de exportación.',
                import_workspace: 'Importar datos',
                import_workspace_description:
                    'Importar páginas, tareas o espacio de trabajo de Saga desde archivos markdown, texto o JSON.',
                import_workspace_cta: 'Importar espacio de trabajo',
                export_workspace: 'Exportar espacio de trabajo',
                export_workspace_description: 'Exportar espacio de trabajo como archivos JSON.',
                export_workspace_cta: 'Exportar espacio de trabajo',
                import_modal: {
                    title: 'Suelta tus archivos para importar',
                    upload_button: 'Subir desde tu computadora',
                    max_file_size: 'máx. {{size}} MB por archivo',
                    supported_file_types: 'Soportado: Markdown, JSON',
                    unexpected_error_message:
                        'Ocurrió un error inesperado.Por favor intente de nuevo o contáctenos en <email/>',
                    unsupported_error_title: 'Formato de archivo no soportado',
                    unsupported_error_description: 'Por favor arrastra y suelta carpetas, archivos Markdown o JSON.',
                    successfuly_imported_files_one: 'Importado exitosamente {{count}} archivo',
                    successfuly_imported_files_other: 'Importado exitosamente {{count}} archivos',
                    confirm_title: 'Importar archivos como',
                    add_to_collection: 'Añadir a colección (opcional)',
                    confirm_button: 'Importar',
                    page: 'Páginas',
                    task: 'Tareas',
                },
            },
            ai: {
                title: 'Saga IA',
                sub_title:
                    'Sé creativo, escribe más rápido y realiza un mejor trabajo directamente en Saga con la ayuda de un asistente digital de IA.',
                confirm_delete_command: '¿Quieres eliminar este comando?',
                confirm_delete_prompt: '¿Quieres eliminar este aviso?',

                commands: {
                    title: 'Comandos',
                    add_command: 'Agregar Comando',
                    customize_prompts: 'Personaliza tus avisos y comandos de IA. <1>Ver cómo funciona</1>.',
                    add_prompt: 'Agregar Aviso',
                },
                new: {
                    title_create_prompt: 'Crear un aviso personalizado',
                    title_edit_prompt: 'Editar tu aviso',
                    title_create_command: 'Crear un comando personalizado',
                    title_edit_command: 'Editar tu comando',
                    custom_command_tooltip: 'El texto seleccionado se colocará al final de tu aviso.',
                    command_name_placeholder: 'Nombre del comando',
                    prompt_name_placeholder: 'Nombre del aviso',
                    command_placeholder: 'Escribe tu comando aquí...',
                    prompt_placeholder: 'Escribe tu aviso aquí...',
                },
            },
            labels: {
                title: 'Etiquetas',
                sub_title: 'Añadir y editar tus etiquetas',
                add_label: 'Añadir etiqueta',
                name: 'Nombre',
                usage: 'Uso',
                options: 'Opciones',
                edit_modal: {
                    title_create: 'Crear etiqueta',
                    title_edit: 'Editar etiqueta',
                    select_color: 'Seleccionar un color de etiqueta',
                    label_name: 'Nombre de etiqueta',
                },
                delete_label_confirmation_title: 'Eliminar la etiqueta "{{title}}"',
                delete_label_confirmation_description:
                    'La etiqueta será eliminada de {{count}} tareas.\n\nEsta acción no se puede deshacer.',
            },
        },
        billing: {
            free: 'Gratis',
            free_title: 'Plan Gratis',
            free_description: 'Para uso personal y equipos pequeños',
            standard_title: 'Plan Estándar',
            standard_description: 'Ideal para equipos medianos',
            business_title: 'Plan de Negocios',
            business_description: 'Ideal para organizaciones más grandes',
            monthly: 'Mensual',
            annually: 'Anualmente',
            downgrade: 'Degradar',
            upgrade_plan: 'Actualizar plan',
            current_plan: 'Plan actual',
            planned: 'Planificado',
            join_waitlist: 'Unirse a la lista de espera',
            per_editor_month: 'por miembro / mes',
            per_space_editor_month: 'por miembro del espacio de trabajo / mes',
            desktopModal: {
                title: 'Cambiar Plan de Facturación',
                description:
                    'Cambiar su plan de facturación requiere un navegador web. Al continuar, se abrirá una nueva pestaña del navegador, redirigiéndolo a la página de facturación de nuestra aplicación. Por favor, complete sus cambios de facturación en el navegador.',
                cta: 'Abrir en el navegador',
            },
            change_plan_modal_title: '¿Estás seguro de cambiar el intervalo de facturación?',
            plan_upgrade_success_toast: {
                title: 'El plan ha sido actualizado',
                description: '¡Ahora tienes acceso ilimitado a todas las super características de Saga!',
            },
            downgrade_toast: {
                title: 'Tu solicitud para degradar el plan ha sido aceptada',
                description: 'Podrás usar todas las características pagadas hasta que tu suscripción expire.',
            },
            upgrade_to_yearly_toast: {
                title: 'Intervalo de facturación cambiado',
            },
            change_plan_modal_description_one:
                'Tu facturación cambiará de mensual a anual ahora mismo, los créditos de tu plan actual se transferirán a un nuevo plan y se te cobrará <bold>{{price}}</bold> por <bold>un</bold> usuario.',
            change_plan_modal_description_other:
                'Tu facturación cambiará de mensual a anual ahora mismo, los créditos de tu plan actual se transferirán a un nuevo plan y se te cobrará <bold>{{price}}</bold> por <bold>{{members}}</bold> usuarios.',
            free_plan_info_banner: {
                title: '¡Nos alegra verte colaborando!',
                description:
                    'Estás en el plan gratuito que incluye <bold>{{count}} miembros</bold> en este espacio de trabajo. <1>Aprender más</1>',
            },
            upgrade_plan_banner: {
                title: 'Gestor de tareas',
                description:
                    'Obtén una visión general de todas tus tareas en todas tus páginas. Busca y ordena, encuentra las tareas que necesitas y completa.',
                primary_button: 'Actualizar plan',
                primary_button_default: 'Ver planes',
                no_access_desciption: 'Por favor, contacta al propietario del espacio de trabajo.',
            },
            feedback_modal: {
                title: 'Nos entristece verte degradar el plan:(',
                description:
                    'Creamos Saga para ayudarte a organizar tu conocimiento. Nos gustaría mucho saber si hay algo que nos falta o podríamos hacer mejor. Tus pensamientos serían muy útiles para nosotros.',
                placeholder:
                    'Por favor comparte por qué estás degradando. Haremos nuestro mejor esfuerzo para mejorar Saga para ti...',
                primary_button: 'Enviar comentarios',
            },
            downgrade_to_free_modal: {
                title: '¿Estás seguro de degradar el plan a Gratis?',
                description:
                    "Tu espacio de trabajo tiene <span class='font-bold'>{{membersCount}} $t(billing.downgrade_to_free_modal.member, {\"count\": {{membersCount}} })</span>. En el plan gratuito puedes tener <span class='font-bold'>hasta {{allowedMembers}}</span>. Al final de la suscripción, <span class='font-bold'>{{membersLooseAccess}} $t(billing.downgrade_to_free_modal.member, {\"count\": {{membersLooseAccess}} }) perderán acceso</span> al espacio de trabajo, y los <span class='font-bold'>{{allowedMembers}} restantes se convertirán en $t(billing.downgrade_to_free_modal.admin, {\"count\": {{allowedMembers}} }).</span>",
                member: 'miembro',
                member_other: 'miembros',
                admin: 'admin',
                admin_other: 'admins',
                features_title: 'Perderás estas super características:',
                feature_1: 'Saga IA ilimitado con GPT-4',
                feature_2: 'Gestor de tareas',
                feature_3: 'Permisos de usuario',
                feature_4: 'Miembros del espacio de trabajo ilimitados',
                primary_button: 'Mantener plan',
                secondary_button: 'Degradar',
            },
            ai_upgrade_plan_banner: {
                description: 'Tus solicitudes de IA gratuitas se han utilizado',
                primary_action: 'Actualizar plan',
            },
            ai_popover_modal_upgrade: {
                title: 'Actualizar tu plan',
                description:
                    'Tus solicitudes de IA gratuitas se han utilizado. Actualiza para obtener respuestas de IA ilimitadas y características adicionales para impulsar tu productividad.',
            },
            modal_plans: {
                title: 'Seleccionar plan de espacio de trabajo',
                sub_title:
                    '<bold>Has alcanzado el límite de {{count}} espacios de trabajo gratuitos</bold>. Actualiza para acceder a características super adicionales que llevarán tu trabajo al siguiente nivel.',
                loading_plans: 'Cargando los planes...',
                footer_disclaimer:
                    'Todos los precios están en USD y se cobran por espacio de trabajo. El IVA puede aplicar.',
                fair_usage: 'uso justo',
                sagaAi_disclaimer: '*Saga AI sujeto a <fairUsageLink/>.',
                footer_contacts: '¿Tienes preguntas sobre la facturación? Pregúntanos <link/>.',
            },
            plan_features: {
                unlimited_pages_tasks: 'Páginas y tareas ilimitadas',
                limited_members: '<bold>Hasta {{count}}</bold> usuarios / espacio de trabajo',
                unlimited_members: '<bold>Miembros del espacio de trabajo ilimitados</bold>',
                limited_saga_requests: 'Saga IA con <bold>{{count}} palabras</bold> / mes',
                unlimited_saga_requests: '<bold>Ilimitado</bold> Saga IA con GPT-4',
                limited_integrations: '<bold>{{count}}</bold> integración: <google /> o <linear />',
                unlimited_integrations: '<google /> y <linear /> integraciones',
                tooltip_integrations: {
                    google_drive: 'Abrir, buscar y mencionar archivos de Google Drive directamente en Saga',
                    linear: 'Mencionar, buscar o pegar enlaces en vivo a problemas de Linear directamente en Saga.',
                },
                apps: 'Mac y Windows',
                tooltip_sagaai: 'Saga AI es el asistente inteligente dentro de su espacio de trabajo.',
                tooltip_apps: {
                    content: 'Descarga las aplicaciones de escritorio de Saga para una mejor experiencia.',
                    apps: 'aplicaciones',
                },
                task_manager: 'Gestor de tareas',
                tooltip_task_manager: 'Administra todas tus tareas en un solo lugar.',
                user_permissions: 'Permisos de usuario',
                advanced_user_permissions: 'Permisos de usuario avanzados',
                search_with_ai: 'Usa <sagaAi /> con herramientas y la web',
                version_history: 'Historial de versiones',
                offline_mode: 'Modo sin conexión',
                business_features_title: 'Todo en Estándar más:',
                free_limitations: '*Hasta {{count}} espacios de trabajo gratuitos por cuenta',
                public_access: 'Páginas y espacios de trabajo públicos',
                custom_ai_commands: 'Comandos personalizados de <sagaAi />',
                ai_talk_to_your_page: '<sagaAi /> habla con tus páginas',
                limited_upload_file: '{{count}} MB tamaño máximo de archivo',
                limited_upload_total_mb: '{{count}} MB almacenamiento de archivos',
                limited_upload_total_gb: '{{count}} GB almacenamiento de archivos',
                limited_guests: '{{count}} invitados',
            },
            choose_workspace_modal: {
                title: 'Actualización Necesaria',
                description:
                    'Has alcanzado el número máximo de espacios de trabajo gratuitos. Para aceptar la invitación a unirte al nuevo espacio de trabajo, por favor actualiza uno de tus espacios de trabajo gratuitos existentes.',
                go_to_plans: 'Ir a Planes',
            },
        },
        table: {
            move_left: 'Mover a la Izquierda',
            move_right: 'Mover a la Derecha',
            insert_column_left: 'Insertar Columna a la Izquierda',
            insert_column_right: 'Insertar Columna a la Derecha',
            remove_column: 'Eliminar Columna',
            append_column: 'Añadir Columna',
            append_row: 'Añadir Fila',
            remove_row: 'Eliminar Fila',
        },
    },
};
