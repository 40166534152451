import { track } from '@/analytics';
import GoogleDriveSvg from '@/assets/GoogleDriveSvg';
import LinearIcon from '@/assets/LinearIcon';
import { useFirebaseContext } from '@/components/FirebaseContext';
import Button from '@/components/styled/Button';
import { BACKEND_URL } from '@/constants';
import * as api from '@saga/api';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { TodesktopLink } from '../Desktop';
import SettingsPane from './SettingsPane';
import { captureException } from '@sentry/react';
import { debugLog } from '@/utils';
import { useCurrentWorkspace } from '../WorkspaceContext';

function Linear({ connected, onRemove, onReload }: { connected: boolean; onRemove(): void; onReload(): void }) {
    const { firebaseUser } = useFirebaseContext();
    const { t } = useTranslation();
    const { urlKey } = useCurrentWorkspace();

    async function setupLinear() {
        const token = await firebaseUser?.getIdToken();
        if (token == null) return;

        const csrfToken = document.head.querySelector('meta[name="x-csrf-token"]')!.getAttribute('content');
        if (!csrfToken) {
            captureException('CSRF Token not found', { extra: { urlKey, user: firebaseUser?.uid } });
            debugLog('CSRF Token not found');
            return;
        }

        const response = await fetch(`${BACKEND_URL}integrations/linear`, {
            method: 'POST',
            headers: { authorization: token, 'Content-type': 'application/json', 'x-csrf-token': csrfToken },
            credentials: 'include',
        });

        const result = await response.json();
        if (result?.redirectUrl) {
            const width = 600;
            const height = 700;
            const left = (screen.width - width) / 2;
            const top = (screen.height - height) / 4;
            const popup = window.open(
                result.redirectUrl,
                'linear-oauth',
                `toolbar=no, menubar=no, width=${width}, height=${height}, top=${top}, left=${left}`,
            );

            if (popup) {
                const timer = setInterval(function () {
                    if (popup.closed) {
                        clearInterval(timer);
                        onReload();
                    }
                }, 1000);
            }
        } else {
            onReload();
        }
    }

    return (
        <div className="flex justify-between items-center">
            <div className="flex space-x-4 items-center">
                <LinearIcon className="h-10 w-10" />

                <div>
                    <h2>{t('settings.integrations.linear.title')}</h2>
                    <SettingsPane.Small>
                        <Trans
                            i18nKey={'settings.integrations.linear.description'}
                            components={{
                                1: (
                                    <TodesktopLink
                                        path="https://saga.so/guides/linear-integration"
                                        className="underline cursor-pointer"
                                    />
                                ),
                            }}
                        />
                    </SettingsPane.Small>
                </div>
            </div>
            <div className="space-x-4 flex items-center">
                {!connected && (
                    <Button.Action onClick={setupLinear} data-testid="linear-connect">
                        <span className="px-2 flex space-x-2 items-center truncate">
                            <LinearIcon className="h-4 w-4" />
                            <span>{t('settings.integrations.linear.button_connect')}</span>
                        </span>
                    </Button.Action>
                )}
                {connected && (
                    <Button.Action onClick={onRemove} data-testid="linear-remove">
                        <span className="px-2 text-red-400">{t('settings.integrations.linear.button_disconnect')}</span>
                    </Button.Action>
                )}
            </div>
        </div>
    );
}

function GoogleDrive({ connected, onRemove, onReload }: { connected: boolean; onRemove(): void; onReload(): void }) {
    const { firebaseUser } = useFirebaseContext();
    const { t } = useTranslation();
    const { urlKey } = useCurrentWorkspace();

    async function setupGoogleDrive() {
        track('connect-to-google-drive');

        const csrfToken = document.head.querySelector('meta[name="x-csrf-token"]')!.getAttribute('content');
        if (!csrfToken) {
            captureException('CSRF Token not found', { extra: { urlKey, user: firebaseUser?.uid } });
            debugLog('CSRF Token not found');
            return;
        }

        const token = await firebaseUser?.getIdToken();
        if (token == null) return;
        const response = await fetch(`${BACKEND_URL}integrations/google-drive`, {
            method: 'POST',
            headers: { authorization: token, 'Content-type': 'application/json', 'x-csrf-token': csrfToken },
            credentials: 'include',
        });

        const result = await response.json();
        if (result?.redirectUrl) {
            window.location.href = result.redirectUrl;
        } else {
            onReload?.();
        }
    }

    return (
        <div className="flex justify-between items-center">
            <div className="flex space-x-4 items-center">
                <GoogleDriveSvg className="h-10 w-10" />

                <div>
                    <h2>{t('settings.integrations.google_drive.title')}</h2>
                    <SettingsPane.Small>
                        <Trans
                            i18nKey={'settings.integrations.google_drive.description'}
                            components={{
                                1: (
                                    <TodesktopLink
                                        path="https://saga.so/guides/google-drive-integration"
                                        className="underline cursor-pointer"
                                    />
                                ),
                            }}
                        />
                    </SettingsPane.Small>
                </div>
            </div>
            <div className="space-x-4 flex items-center">
                {!connected && (
                    <Button.Action onClick={setupGoogleDrive} data-testid="googledrive-connect">
                        <span className="px-2 flex space-x-2 items-center truncate">
                            <GoogleDriveSvg className="h-4 w-4" />
                            <span>{t('settings.integrations.google_drive.button_connect')}</span>
                        </span>
                    </Button.Action>
                )}
                {connected && (
                    <Button.Action onClick={onRemove} data-testid="googledrive-remove">
                        <span className="px-2 text-red-400">
                            {t('settings.integrations.google_drive.button_disconnect')}
                        </span>
                    </Button.Action>
                )}
            </div>
        </div>
    );
}

export function IntegrationsSettings() {
    const { t } = useTranslation();
    const { data, refetch: refetchIntegrations } = api.useUserIntegrationsQuery();
    const { refetch: refetchLinearTeams } = api.useLinearTeamsQuery();

    const [removeUserIntegration] = api.useRemoveUserIntegrationMutation();
    const googleDriveIntegration = data?.userIntegrations.find(
        (integration) => integration.type === api.UserIntegrationType.GoogleDrive,
    );

    const linearIntegration = data?.userIntegrations.find(
        (integration) => integration.type === api.UserIntegrationType.Linear,
    );

    return (
        <SettingsPane>
            <div>
                <SettingsPane.Title>{t('settings.integrations.title')}</SettingsPane.Title>
                <SettingsPane.Small>{t('settings.integrations.sub_title')}</SettingsPane.Small>
            </div>
            <SettingsPane.Content>
                <div className="space-y-3">
                    {data && (
                        <>
                            <SettingsPane.Section>
                                <GoogleDrive
                                    onReload={refetchIntegrations}
                                    connected={googleDriveIntegration != null && googleDriveIntegration.isActive}
                                    onRemove={() => {
                                        if (
                                            googleDriveIntegration &&
                                            window.confirm(t('settings.integrations.remove_integration') as string)
                                        ) {
                                            track('remove-google-drive-integration');
                                            removeUserIntegration({
                                                variables: { input: { id: googleDriveIntegration.id } },
                                            }).then(() => refetchIntegrations());
                                        }
                                    }}
                                />
                            </SettingsPane.Section>

                            <SettingsPane.Section>
                                <Linear
                                    onReload={() => {
                                        refetchIntegrations();
                                        refetchLinearTeams();
                                    }}
                                    connected={linearIntegration != null && linearIntegration.isActive}
                                    onRemove={() => {
                                        if (
                                            linearIntegration &&
                                            window.confirm(t('settings.integrations.remove_integration') as string)
                                        ) {
                                            removeUserIntegration({
                                                variables: { input: { id: linearIntegration.id } },
                                            }).then(() => {
                                                refetchIntegrations();
                                                refetchLinearTeams();
                                            });
                                        }
                                    }}
                                />
                            </SettingsPane.Section>
                        </>
                    )}
                </div>
            </SettingsPane.Content>
        </SettingsPane>
    );
}
