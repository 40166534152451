import Modal from '@/components/Modal';
import Button from '@/components/styled/Button';
import React from 'react';
import { useTranslation } from 'react-i18next';

export default function ConnectionClosedModal() {
    const { t } = useTranslation();
    return (
        <Modal.Small isOpen>
            <Modal.Content>
                <Modal.Title>{t('errors.connection_lost_title')}</Modal.Title>
                <div className="text-center whitespace-pre-line">{t('errors.connection_lost_description')}</div>
                <Button onClick={() => window.location.reload()}>{t('common.reload')}</Button>
            </Modal.Content>
        </Modal.Small>
    );
}
