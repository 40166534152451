import React from 'react';
import Tooltip from '../popover/Tooltip';
import { Check } from 'react-feather';
import { Trans, useTranslation } from 'react-i18next';
import { isFeatureEnabled } from '@/analytics';
import { FeatureFlag } from '@/../../shared/src/utils/FeatureFlags';
import { useSpaceAccess } from '@/hooks/useSpaceAccess';

const FeatureItem = ({ children }: { children: string | React.ReactNode }) => (
    <div className="flex items-center space-x-2 leading-4 mb-3 last:mb-0 tracking-tight">
        <Check size={16} className="shrink-0" /> <span>{children}</span>
    </div>
);

function PlanDetails({ title, children }: { title: string; children: React.ReactNode }) {
    return (
        <>
            <div className="font-bold my-3.5 text-sm">{title}</div>
            {children}
        </>
    );
}

PlanDetails.FreePlan = function FreePlan() {
    const { t } = useTranslation();
    const { permissions } = useSpaceAccess();
    const planAllowedMembers = isFeatureEnabled(FeatureFlag.allowBilling) ? permissions.editors.limit! : 5;
    return (
        <ul className="mt-6 list-inside text-sm">
            <FeatureItem>{t('billing.plan_features.unlimited_pages_tasks')}</FeatureItem>
            <FeatureItem>
                <Trans
                    i18nKey="billing.plan_features.limited_members"
                    count={planAllowedMembers}
                    components={{ bold: <span className="font-bold" /> }}
                />
            </FeatureItem>
            <FeatureItem>
                <Trans
                    i18nKey="billing.plan_features.limited_saga_requests"
                    count={5000}
                    components={{
                        bold: <span className="font-bold" />,
                        sagaAi: (
                            <Tooltip content={t('billing.plan_features.tooltip_sagaai')} placement="top">
                                <a
                                    href="https://saga.so/ai"
                                    rel="noreferrer noopener"
                                    target="_blank"
                                    className="underline"
                                >
                                    Saga AI
                                </a>
                            </Tooltip>
                        ),
                    }}
                />
            </FeatureItem>
            <FeatureItem>{t('billing.plan_features.version_history_free')}</FeatureItem>
            <FeatureItem>
                <Trans
                    i18nKey="billing.plan_features.limited_integrations"
                    count={1}
                    components={{
                        bold: <span className="font-bold" />,
                        google: (
                            <Tooltip
                                content={t('billing.plan_features.tooltip_integrations.google_drive')}
                                placement="top"
                            >
                                <a
                                    href="https://saga.so/guides/google-drive-integration"
                                    rel="noreferrer noopener"
                                    target="_blank"
                                    className="underline"
                                >
                                    Google Drive
                                </a>
                            </Tooltip>
                        ),
                        linear: (
                            <Tooltip content={t('billing.plan_features.tooltip_integrations.linear')} placement="top">
                                <a
                                    href="https://saga.so/guides/linear-integration"
                                    rel="noreferrer noopener"
                                    target="_blank"
                                    className="underline"
                                >
                                    Linear
                                </a>
                            </Tooltip>
                        ),
                    }}
                />
            </FeatureItem>
            <FeatureItem>
                <Trans
                    i18nKey="billing.plan_features.apps"
                    components={{
                        apps: (
                            <Tooltip content={t('billing.plan_features.tooltip_apps.content')} placement="top">
                                <a
                                    href="https://saga.so/desktop"
                                    rel="noreferrer noopener"
                                    target="_blank"
                                    className="underline"
                                >
                                    {t('billing.plan_features.tooltip_apps.apps')}
                                </a>
                            </Tooltip>
                        ),
                    }}
                />
            </FeatureItem>
            <FeatureItem>
                <Tooltip content={t('billing.plan_features.tooltip_task_manager')} placement="top">
                    <a
                        href="https://saga.so/guides/task-management"
                        rel="noreferrer noopener"
                        target="_blank"
                        className="underline"
                    >
                        {t('billing.plan_features.task_manager')}
                    </a>
                </Tooltip>
            </FeatureItem>
            <FeatureItem>{t('billing.plan_features.public_access')}</FeatureItem>
            <FeatureItem>{t('billing.plan_features.limited_guests', { count: 10 })}</FeatureItem>
            <FeatureItem>{t('billing.plan_features.limited_upload_file', { count: 10 })}</FeatureItem>
            <FeatureItem>{t('billing.plan_features.limited_upload_total_mb', { count: 250 })}</FeatureItem>
        </ul>
    );
};

PlanDetails.StandardPlan = function StandardPlan() {
    const { t } = useTranslation();
    return (
        <ul className="mt-6 list-inside text-sm">
            <FeatureItem>{t('billing.plan_features.unlimited_pages_tasks')}</FeatureItem>
            <FeatureItem>
                <Trans
                    i18nKey="billing.plan_features.unlimited_members"
                    components={{ bold: <span className="font-bold" /> }}
                />
            </FeatureItem>
            <FeatureItem>
                <Trans
                    i18nKey="billing.plan_features.unlimited_saga_requests"
                    components={{
                        bold: <span className="font-bold" />,
                        sagaAi: (
                            <Tooltip content={t('billing.plan_features.tooltip_sagaai')} placement="top">
                                <a
                                    href="https://saga.so/ai"
                                    rel="noreferrer noopener"
                                    target="_blank"
                                    className="underline"
                                >
                                    Saga AI
                                </a>
                            </Tooltip>
                        ),
                    }}
                />
            </FeatureItem>
            <FeatureItem>{t('billing.plan_features.version_history_standard')}</FeatureItem>
            <FeatureItem>
                <Trans
                    i18nKey="billing.plan_features.unlimited_integrations"
                    count={1}
                    components={{
                        google: (
                            <Tooltip
                                content={t('billing.plan_features.tooltip_integrations.google_drive')}
                                placement="top"
                            >
                                <a
                                    href="https://saga.so/guides/google-drive-integration"
                                    rel="noreferrer noopener"
                                    target="_blank"
                                    className="underline"
                                >
                                    Google Drive
                                </a>
                            </Tooltip>
                        ),
                        linear: (
                            <Tooltip content={t('billing.plan_features.tooltip_integrations.linear')} placement="top">
                                <a
                                    href="https://saga.so/guides/linear-integration"
                                    rel="noreferrer noopener"
                                    target="_blank"
                                    className="underline"
                                >
                                    Linear
                                </a>
                            </Tooltip>
                        ),
                    }}
                />
            </FeatureItem>
            <FeatureItem>
                <Trans
                    i18nKey="billing.plan_features.apps"
                    components={{
                        apps: (
                            <Tooltip content={t('billing.plan_features.tooltip_apps.content')} placement="top">
                                <a
                                    href="https://saga.so/desktop"
                                    rel="noreferrer noopener"
                                    target="_blank"
                                    className="underline"
                                >
                                    {t('billing.plan_features.tooltip_apps.apps')}
                                </a>
                            </Tooltip>
                        ),
                    }}
                />
            </FeatureItem>
            <FeatureItem>
                <Tooltip content={t('billing.plan_features.tooltip_task_manager')} placement="top">
                    <a
                        href="https://saga.so/guides/task-management"
                        rel="noreferrer noopener"
                        target="_blank"
                        className="underline"
                    >
                        {t('billing.plan_features.task_manager')}
                    </a>
                </Tooltip>
            </FeatureItem>
            <FeatureItem>{t('billing.plan_features.public_access')}</FeatureItem>
            <FeatureItem>{t('billing.plan_features.limited_guests', { count: 100 })}</FeatureItem>
            <FeatureItem>{t('billing.plan_features.limited_upload_file', { count: 250 })}</FeatureItem>
            <FeatureItem>{t('billing.plan_features.limited_upload_total_gb', { count: 5 })}</FeatureItem>
            <FeatureItem>
                <Trans
                    i18nKey="billing.plan_features.custom_ai_commands"
                    components={{
                        bold: <span className="font-bold" />,
                        sagaAi: (
                            <Tooltip content={t('billing.plan_features.tooltip_sagaai')} placement="top">
                                <a
                                    href="https://saga.so/ai"
                                    rel="noreferrer noopener"
                                    target="_blank"
                                    className="underline"
                                >
                                    Saga AI
                                </a>
                            </Tooltip>
                        ),
                    }}
                />
            </FeatureItem>
            <FeatureItem>
                <Trans
                    i18nKey="billing.plan_features.ai_talk_to_your_page"
                    components={{
                        bold: <span className="font-bold" />,
                        sagaAi: (
                            <Tooltip content={t('billing.plan_features.tooltip_sagaai')} placement="top">
                                <a
                                    href="https://saga.so/ai"
                                    rel="noreferrer noopener"
                                    target="_blank"
                                    className="underline"
                                >
                                    Saga AI
                                </a>
                            </Tooltip>
                        ),
                    }}
                />
            </FeatureItem>

            <FeatureItem>{t('billing.plan_features.user_permissions')}</FeatureItem>
        </ul>
    );
};

PlanDetails.BusinessPlan = function BusinessPlan() {
    const { t } = useTranslation();
    return (
        <>
            <p className="font-bold text-sm mt-6">{t('billing.plan_features.business_features_title')}</p>
            <ul className="mt-2 list-inside text-sm">
                <FeatureItem>
                    <Trans
                        i18nKey="billing.plan_features.search_with_ai"
                        components={{
                            bold: <span className="font-bold" />,
                            sagaAi: (
                                <Tooltip content={t('billing.plan_features.tooltip_sagaai')} placement="top">
                                    <a
                                        href="https://saga.so/ai"
                                        rel="noreferrer noopener"
                                        target="_blank"
                                        className="underline"
                                    >
                                        Saga AI
                                    </a>
                                </Tooltip>
                            ),
                        }}
                    />
                </FeatureItem>
                <FeatureItem>{t('billing.plan_features.advanced_user_permissions')}</FeatureItem>
                <FeatureItem>{t('billing.plan_features.version_history_business')}</FeatureItem>
                <FeatureItem>{t('billing.plan_features.limited_guests', { count: 250 })}</FeatureItem>
                <FeatureItem>{t('billing.plan_features.limited_upload_file', { count: 500 })}</FeatureItem>
                <FeatureItem>{t('billing.plan_features.limited_upload_total_gb', { count: 100 })}</FeatureItem>
            </ul>
        </>
    );
};

export default PlanDetails;
