import * as Y from 'yjs';
import { transformBlocksToSharedType } from './pageUtils';
import { WeakDocumentContent } from './types';
import { ymapFromRecord } from './yjs-utils';

/**
 * Creates the the initial Y.Doc for given page/task content.
 * @param {WeakDocumentContent} content Page/Task content for which to create the Y.Doc.
 * @returns {Y.Doc} The resulting Y.Doc
 */
export function createDocumentForContent(content: WeakDocumentContent): Y.Doc {
    const doc = new Y.Doc();

    const contentMap = doc.getMap('content');
    contentMap.set('id', content.id);
    contentMap.set('spaceId', content.spaceId);
    contentMap.set('blocks', transformBlocksToSharedType(content.blocks));
    contentMap.set('icon', content.icon ? ymapFromRecord(content.icon) : undefined);

    return doc;
}
