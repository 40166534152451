import React from 'react';
import { track } from '@/analytics';
import { useEffect } from 'react';
import WhooopsModal from '@/components/WhooopsModal';

type RedirectOption = 'reload-current-page' | 'remove-workspace' | 'member-removed' | 'page-unavailable';

export default function AuthenticationErrorModal({ option }: { option: RedirectOption }) {
    useEffect(() => {
        track('authentication-error-modal-shown', {
            reason: option,
        });
    }, [option]);

    const onLinkClick = () => {
        switch (option) {
            case 'reload-current-page': {
                window.location.reload();
                break;
            }

            case 'remove-workspace':
            case 'member-removed':
            case 'page-unavailable': {
                window.location.href = window.location.origin + '/s';
                break;
            }
        }
    };

    const buttonLabel: string = (() => {
        switch (option) {
            case 'page-unavailable':
                return 'Back to my Saga';
            default:
                return 'Reload Page';
        }
    })();

    const errorHeading: string | null = (() => {
        switch (option) {
            case 'page-unavailable':
                return 'Oops! Page Not Found';
            default:
                return null;
        }
    })();

    const errorInfo: string = (() => {
        switch (option) {
            case 'remove-workspace':
                return 'This space does not exist, reload the page to go back to Saga.';
            case 'reload-current-page':
                return 'Something unexpected happened, reload the page to go back to Saga.';
            case 'member-removed':
                return 'You have been removed from this space, reload the page to go back to Saga.';
            case 'page-unavailable':
                return 'It might have been moved, deleted, or you do not have a permission to view it.';
        }
    })();

    return (
        <WhooopsModal
            heading={errorHeading}
            buttonLabel={buttonLabel}
            onButtonClick={onLinkClick}
            subheading={errorInfo}
        />
    );
}
