import { TaskLabel } from '../types';
import { SafeSpace } from '../types';
import transactInSpace from './transactInSpace';

import * as Y from 'yjs';

export default function updatePartialTaskLabel(space: SafeSpace, labelId: string, partialLabel: Partial<TaskLabel>) {
    const yLabels = space.map.get('taskLabels') as Y.Array<Y.Map<unknown>>;

    const yLabelIndex = yLabels?.toArray().findIndex((label) => label.get('id') === labelId);
    const yLabel = yLabels.get(yLabelIndex);

    const keys = Object.keys(partialLabel);

    transactInSpace(space, () => {
        keys.forEach((key) => {
            // @ts-expect-error
            const value = partialLabel[key];
            yLabel.set(key, value);
        });
    });
}
