import { BlockBuilder } from '../..';
import { newBlankPage } from '../../pageUtils';

export function newSagaAiPageEs(collectionIds: string[]) {
    return newBlankPage({
        title: 'Saga IA',
        icon: {
            type: 'emoji',
            colons: ':sparkles:',
        },
        collections: collectionIds,
        blocks: [
            BlockBuilder.title('Saga IA'),
            BlockBuilder.headingThree('Genera contenido y obtén respuestas con IA'),
            BlockBuilder.image('https://saga-public.s3.amazonaws.com/1-ai-assistant-generating-popover-launch.webp'),
            BlockBuilder.checkListItem([BlockBuilder.text('Crea un borrador usando IA.')]),
            BlockBuilder.listItem([
                BlockBuilder.text('Haz clic en el '),
                BlockBuilder.text('botón de IA', { code: true }),
                BlockBuilder.text(' dentro del editor.'),
            ]),
            BlockBuilder.listItem([
                BlockBuilder.text('Alternativamente, presiona '),
                BlockBuilder.text('@', { code: true }),
                BlockBuilder.text(' o '),
                BlockBuilder.text('/', { code: true }),
                BlockBuilder.text(' y selecciona Saga IA del menú de autocompletar.'),
            ]),
            BlockBuilder.paragraph(),
            BlockBuilder.paragraph(),
            BlockBuilder.image('https://saga-public.s3.amazonaws.com/2-ai-assistant-select-or-type-prompt.webp'),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Selecciona una opción de la lista, o escribe tu propio mensaje.'),
            ]),
            BlockBuilder.paragraph(),
            BlockBuilder.paragraph(),
            BlockBuilder.image('https://saga-public.s3.amazonaws.com/3-ai-assistant-blog-post-generation.webp'),
            BlockBuilder.checkListItem([
                BlockBuilder.text(
                    'Itera sobre el resultado, añade más detalles y ve el historial de tu conversación con IA.',
                ),
            ]),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Guarda tu mensaje para reutilizarlo más tarde presionando el botón ☆.'),
            ]),
            BlockBuilder.paragraph(),
            BlockBuilder.paragraph(),
            BlockBuilder.headingThree('Edita con IA'),
            BlockBuilder.image('https://saga-public.s3.amazonaws.com/4-ai-assistant-commands-pallette.webp'),
            BlockBuilder.checkListItem([
                BlockBuilder.text(
                    'Pide a Saga IA que reescriba tu texto, corrija la gramática, lo haga más corto, cambie el tono de voz, traduzca y más.',
                ),
            ]),
            // todo - indent
            BlockBuilder.listItem([
                BlockBuilder.text('Selecciona cualquier texto y haz clic en "Saga IA" en la barra de herramientas para abrir el menú de IA.'),
            ]),
            BlockBuilder.listItem([BlockBuilder.text('Selecciona una opción de la lista, o escribe tu propio mensaje.')]),
            BlockBuilder.paragraph(),
            BlockBuilder.paragraph(),
            BlockBuilder.image('https://saga-public.s3.amazonaws.com/5-ai-assistant-edit-the-text.webp'),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Pregunta a Saga IA qué hacer a continuación con la respuesta, añade detalles e itera.'),
            ]),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Reemplaza el contenido inicial con la respuesta de la IA o insértala debajo.'),
            ]),
            BlockBuilder.paragraph(),
            BlockBuilder.paragraph(),
            BlockBuilder.divider(),
            BlockBuilder.headingThree('Guías y Preguntas Frecuentes'),
            BlockBuilder.paragraph([
                BlockBuilder.text(
                    'Visita las guías de Saga IA para más consejos y trucos sobre cómo usar la IA dentro de tu espacio de trabajo o regresa a 👋 Fundamentos de Saga para continuar aprendiendo.',
                ),
            ]),
            BlockBuilder.rawPrettyLink('https://saga.so/guides-category/saga-ai', {
                _tag: 'loaded',
                description:
                    'Aprende a usar Saga IA - el asistente potenciado por IA dentro de tu espacio de trabajo. Sé creativo y más productivo con un asistente de IA.',
                title: '📍 Saga IA – Guías de Saga',
                logo: 'https://saga.so/thumbnail.png',
            }),
            BlockBuilder.paragraph(),
        ],
    });
}
