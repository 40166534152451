import React from 'react';
import { SagaEditor } from '@saga/shared';
import useOnTextEvent from './useOnTextEvent';
import * as R from 'ramda';
import { Editor, Range, RangeRef } from 'slate';
import { useSlateSelection, useSlateStatic } from 'slate-react';
import { useEffect } from 'react';

export type Trigger =
    | {
          // Trigger gets active after this string
          after: string;

          // The value of the trigger ends with this substring
          before?: string;
      }
    | symbol;

export const LINE_START_TRIGGER: Trigger = Symbol('line-start');

export const TRIGGERS: Trigger[] = [{ after: '@' }, { after: '/' }, { after: '[[', before: ']]' }];

function calculateNewRange(range: Range, suggestionValue: string, trigger?: Trigger): Range {
    if (typeof trigger === 'symbol') {
        // in case of a symbol trigger, we do not have any additional length to take care of
        return {
            ...range,
            focus: {
                ...range.focus,
                offset: range.anchor.offset + suggestionValue.length,
            },
        };
    }

    return {
        ...range,
        focus: {
            ...range.focus,
            offset:
                range.anchor.offset +
                (trigger?.after.length ?? 0) +
                suggestionValue.length +
                (trigger?.before?.length ?? 0),
        },
    };
}

export function isSymbolTrigger(trigger: Trigger): trigger is symbol & Trigger {
    return typeof trigger === 'symbol';
}

function findApplicableTriggersWithIndex(value: string, currentOffset: number, triggers: Trigger[]) {
    // We search only until the current offset
    const searchString = value.substring(0, currentOffset);

    const foundTriggers = triggers
        .map((trigger) => {
            // First we check if it is a special symbol trigger
            if (typeof trigger === 'symbol') {
                if (trigger === LINE_START_TRIGGER) {
                    return {
                        // Line start trigger always starts at 0 of course
                        index: 0,
                        trigger,
                    };
                }

                return {
                    index: -1,
                    trigger,
                };
            }

            const triggerIndex = searchString.lastIndexOf(trigger.after);

            // the trigger should not be active if we only have non-empty whitespace afterwards, because sometimes
            // you just want to type @<space> and not have the menu open
            const stringAfterwards = value.substring(triggerIndex + 1);

            const stringAfterwardsIsValid = stringAfterwards.length === 0 || stringAfterwards.trim() !== '';

            if (value[triggerIndex - 1] === '\n') {
                const index = stringAfterwardsIsValid ? triggerIndex + 1 : -1;
                return { index, trigger, newLine: true };
            }

            // we only wanna return the trigger if the character before it is either an empty string, a new line
            // or the trigger character is at the start of the string
            const characterBeforeIsValid = triggerIndex === 0 || value[triggerIndex - 1] === ' ';
            const index = characterBeforeIsValid ? triggerIndex : -1;

            return { index, trigger };
        })
        // Now we throw all triggers out that have a negative index
        .filter((trigger) => trigger.index >= 0);

    // because we only search until the current offset, we can just sort the triggers by highest index and take the first one
    const closestTrigger = R.sort(R.descend(R.prop('index')), foundTriggers)[0];

    return closestTrigger as typeof closestTrigger | undefined;
}

function getSuggestionValueEndIndex({
    trigger,
    startIndex,
    currentOffset,
    value,
}: {
    trigger: Trigger;
    startIndex: number;
    value: string;
    currentOffset: number;
}) {
    if (isSymbolTrigger(trigger) || trigger.before == null) return currentOffset;

    // In case of an enclosing trigger, like [[ ]], we need to find the suggestionValue within the boundary

    // First we find the index of the before value, which would be for example ]]
    const beforeIndex = value.substring(startIndex).indexOf(trigger.before);
    const endIndex = beforeIndex < 0 ? -1 : startIndex + beforeIndex;

    // If the current offset is out of bounds, we will return null, as we don't want to allow this case
    if (currentOffset > endIndex + trigger.before.length) {
        return -1;
    }

    return endIndex;
}

export function findTrigger(value: string, currentOffset: number, triggers: Trigger[]) {
    const triggerResult = findApplicableTriggersWithIndex(value, currentOffset, triggers);

    // No triggers found
    if (triggerResult == null) return null;

    const { index, trigger, newLine } = triggerResult;

    // We don't want to include the trigger when obtaining the suggestionValue
    const startIndex = isSymbolTrigger(trigger) ? 0 : index + trigger.after.length;

    // We get the end index for the suggestion value
    const endIndex = getSuggestionValueEndIndex({ trigger, startIndex, currentOffset, value });

    if (endIndex === -1) return null;

    let suggestionValue: string;

    if (newLine) {
        suggestionValue = value.substring(startIndex - 1, endIndex);
    } else {
        // Now we can finally build our suggestionValue
        suggestionValue = value.substring(startIndex, endIndex);
    }

    return {
        suggestionValue,
        trigger,
        index,
    };
}

export type TriggerState = {
    range: Range;
    rangeRef: RangeRef;
    suggestionValue: string;
    trigger?: Trigger;
};

export default function useTrigger() {
    const editor = useSlateStatic();
    const selection = useSlateSelection();
    const { yBlocks } = SagaEditor.useEditorContext();
    const [state, setState] = React.useState<TriggerState | null>(null);

    const close = React.useCallback(() => {
        setState((state) => {
            if (state?.rangeRef) {
                state.rangeRef.unref();
            }
            return null;
        });
    }, []);

    /**
     * this useEffect makes sure that the autocomplete menu is closed or opened
     * relative to the current selection
     */
    useEffect(() => {
        if (selection == null || !Range.isCollapsed(selection)) return;
        const [, blockPath] = Editor.above(editor, { at: selection }) || [];
        if (!blockPath) return;
        const start = Editor.start(editor, blockPath);
        const end = Editor.end(editor, blockPath);
        const fragment = Editor.string(editor, {
            anchor: start,
            focus: selection.focus,
        });
        const wholeBlock = Editor.string(editor, { anchor: start, focus: end });

        const result = findTrigger(wholeBlock, fragment.length, state?.trigger ? [state.trigger] : TRIGGERS);
        if (!result) {
            close();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selection]);

    const open = React.useCallback(
        ({
            trigger,
            range,
            rangeRef,
            suggestionValue = '',
        }: {
            trigger?: Trigger;
            range: Range;
            rangeRef: RangeRef;
            suggestionValue?: string;
        }) => {
            setState((state) => {
                if (state?.rangeRef) {
                    state.rangeRef.unref();
                }
                return {
                    trigger,
                    suggestionValue,
                    range,
                    rangeRef,
                };
            });
        },
        [],
    );

    const updateSuggestionValue = React.useCallback((suggestionValue: string) => {
        setState((state) => {
            if (state) {
                const range = calculateNewRange(state.range, suggestionValue, state.trigger);
                state.rangeRef.current = range;

                return { ...state, suggestionValue, range };
            }

            return state;
        });
    }, []);

    useOnTextEvent(yBlocks, editor.origin, {
        onInsert(data) {
            const selection = editor.selection;
            if (selection == null) return;
            const currentOffset = editor.selection?.focus.offset ?? 0;

            const result = findTrigger(data.value, currentOffset, state?.trigger ? [state.trigger] : TRIGGERS);

            if (result == null) {
                close();
            } else if (state?.range.anchor.offset === result.index) {
                updateSuggestionValue(result.suggestionValue);
            } else {
                const processRange = (anchorOffset: number, focusOffset: number) => {
                    const range = calculateNewRange(
                        {
                            anchor: { offset: anchorOffset, path: selection.focus.path },
                            focus: { offset: focusOffset, path: selection.focus.path },
                        },
                        result.suggestionValue,
                        result.trigger,
                    );

                    const rangeRef = Editor.rangeRef(editor, range, { affinity: 'inward' });

                    open({
                        trigger: result.trigger,
                        suggestionValue: result.suggestionValue,
                        rangeRef,
                        range,
                    });
                };

                if (
                    isSymbolTrigger(result.trigger) ||
                    result.trigger.before != null ||
                    result.index + result.trigger.after.length >= currentOffset
                ) {
                    return processRange(result.index, result.index);
                }
            }
        },
        onDelete(data) {
            const currentFocus = editor.selection?.focus.offset ?? 0;
            const result = findTrigger(data.value, currentFocus, state?.trigger ? [state.trigger] : TRIGGERS);

            if (result == null || result.index !== state?.range.anchor.offset) {
                close();
            } else {
                updateSuggestionValue(result.suggestionValue);
            }
        },
    });

    return { state, close, open };
}
