import { SagaEditor, isFile, File as FileType } from '@saga/shared';
import { RenderElementProps } from 'slate-react';
import React from 'react';
import { convertFileSize, openWindow } from '@/utils';
import { EditableFile, useDisplayFileUrl } from './space/File';
import { Loader, Paperclip } from 'react-feather';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { usePageStaticElement } from '@/components/PublicPageProvider';

export function File({
    element,
    children,
    isPublic,
}: Omit<RenderElementProps, 'children'> & {
    element: FileType;
    isPublic?: boolean;
    children?: RenderElementProps['children'];
}) {
    return (
        <div
            className={classNames(' py-1 relative flex items-center space-x-1 w-full  rounded mb-1', {
                'px-2 dark:hover:bg-saga-gray-900 hover:bg-saga-bg-gray-light': element.url,
            })}
        >
            {element.url ? (
                <File.FileInfo element={element} isPublic={isPublic} />
            ) : (
                <EditableFile.FileInput elementId={element.id} onChange={() => {}} disabled={true} />
            )}
            <File.Void>{children}</File.Void>
        </div>
    );
}

File.FileInfo = function FileInfo({
    element,
    uploading,
    isPublic,
}: {
    element: FileType;
    isPublic?: boolean;
    uploading?: boolean;
}) {
    const { url, title, size } = element;
    const { displayFileUrl, spaceUrl } = useDisplayFileUrl(url);
    const { t } = useTranslation();

    if (!displayFileUrl && !uploading) return null;

    const onClick = () => {
        if (displayFileUrl && spaceUrl && !uploading) {
            const path =
                Boolean(window.todesktop) && !isPublic
                    ? `${window.location.origin}/s/${spaceUrl}/asset?urlKey=${displayFileUrl}`
                    : displayFileUrl;

            openWindow(path, '_blank');
            return;
        }
    };

    return (
        <div
            contentEditable={false}
            className={classNames('relative select-none flex items-center space-x-2', {
                'cursor-pointer': !uploading,
            })}
            onClick={onClick}
        >
            {uploading ? <Loader className="animate-spin" size={20} /> : <Paperclip className="shrink-0" size={20} />}
            <span className="truncate">{uploading ? t('editor.file_upload.uploading') : title}</span>
            <span
                className={classNames(`shrink-0 text-xs text-saga-gray-500`, {
                    hidden: uploading,
                })}
            >
                {convertFileSize(size)}
            </span>
        </div>
    );
};

File.Container = React.forwardRef<
    HTMLDivElement,
    {
        children: React.ReactNode;
        onClick?: () => void;
        id: string;
        onPointerEnter?: React.PointerEventHandler;
        onPointerLeave?: React.PointerEventHandler;
    }
>(function FileContainer({ children, onClick, id, onPointerEnter, onPointerLeave }, ref) {
    return (
        <div
            ref={ref}
            className="mt-2"
            onClick={onClick}
            contentEditable={false}
            id={id}
            onPointerEnter={onPointerEnter}
            onPointerLeave={onPointerLeave}
        >
            {children}
        </div>
    );
});

File.Void = function ImageVoid({ children }: { children: React.ReactNode }) {
    return <div className="hidden select-none">{children}</div>;
};

export const fileBlockPlugin = SagaEditor.Plugins.createBlockPlugin({
    match: isFile,
    Component(props) {
        const staticElement = usePageStaticElement(props.element);
        return <File {...props} element={staticElement ?? props.element} isPublic />;
    },
});
