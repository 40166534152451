import { SagaEditor, isFile, File as FileType, BlockBuilder } from '@saga/shared';
import React, { useEffect, useRef, useState } from 'react';
import { ReactEditor, RenderElementProps, useFocused, useSelected, useSlateStatic } from 'slate-react';
import VoidSelectionShadow from '../../VoidSelectionShadow';
import classNames from 'classnames';
import { useCurrentWorkspace } from '@/components/WorkspaceContext';
import { hasValidCookies } from '@/utils/cookie';
import { useResetCookiesIfNeeded } from '@/components/CloudfrontCookiesProvider';
import { track } from '@/analytics';
import { Paperclip, Trash, MoreHorizontal, Download } from 'react-feather';
import { useUploadFile } from '@/lib/File';
import { File } from '../File';
import { openWindow } from '@/utils';

import * as Popover from '@radix-ui/react-popover';
import { useTranslation } from 'react-i18next';
import { Transforms } from 'slate';
import { PopOver } from '@/components/popover/PopOver';
import { usePendingUpload } from '@/lib/usePendingUpload';
import { WithExplicitFontType } from '@/components/WithExplicitFontType';

type PopoverRefs = {
    contextMenu: React.MutableRefObject<HTMLButtonElement | null>;
};

function useCookies(urlKey: string) {
    const [cookiesValid, setCookiesValid] = React.useState(() => hasValidCookies(urlKey));
    const resetCookiesIfNeeded = useResetCookiesIfNeeded();
    const ensureCookiesArePresent = React.useCallback(async () => {
        await resetCookiesIfNeeded();
        setCookiesValid(hasValidCookies(urlKey));
    }, [resetCookiesIfNeeded, urlKey]);

    return { cookiesValid, ensureCookiesArePresent };
}

export function useDisplayFileUrl(url?: string | null) {
    const [displayFileUrl, setDisplayFileUrl] = useState<string | null>(url ?? null);

    // We need to sync the url to the state again in case it changes from outside
    React.useEffect(() => {
        if (url) {
            setDisplayFileUrl(url);
        }
    }, [url]);

    const spaceUrl = React.useMemo(() => displayFileUrl?.match(/spaces\/(.+)\/files/)?.[1], [displayFileUrl]);

    return { displayFileUrl, setDisplayFileUrl, spaceUrl };
}

const usePopoversController = () => {
    const refsMap = React.useRef<PopoverRefs>({
        contextMenu: React.useRef(null),
    });

    const [popoverVisible, setPopoverVisible] = React.useState<keyof PopoverRefs | null>(null);

    return { refsMap, popoverVisible, setPopoverVisible };
};

EditableFile.FileInput = function FileFileInput({
    elementId,
    selected,
    disabled,
    onChange,
}: {
    elementId: string;
    selected?: boolean;
    disabled?: boolean;

    onChange(e: React.ChangeEvent<HTMLInputElement>): void;
}) {
    const inputRef = useRef<HTMLInputElement>(null);
    const { t } = useTranslation();
    return (
        <label className="w-full relative block text-saga-text-gray">
            <button
                className={classNames(
                    'border border-dashed dark:border-saga-gray-600 hover:border-saga-gray-500 h-20 w-full rounded flex justify-center items-center focus:outline-none bg-white dark:bg-saga-gray-1000',
                    { 'shadow-lightblue': selected },
                    { 'cursor-default': disabled },
                )}
                onClick={() => {
                    if (!disabled) {
                        const input = inputRef.current;
                        if (input) {
                            input.click();
                            track('open-upload-image-menu');
                        }
                    }
                }}
            >
                <span className="sr-only lect-none" contentEditable={false}>
                    {t('editor.file_upload.click_to_upload_file')}
                </span>
            </button>
            <div
                contentEditable={false}
                className="flex justify-center items-center space-x-2 absolute inset-0 pointer-events-none select-none"
            >
                <Paperclip />
                {!disabled && <div>{t('editor.file_upload.click_to_upload_file')}</div>}
            </div>
            <input
                id={`inputfile-${elementId}`}
                className="hidden"
                multiple={false}
                onChange={onChange}
                type="file"
                disabled={disabled}
                ref={inputRef}
            ></input>
        </label>
    );
};

EditableFile.ContextMenu = function FileContextMenu({ element, onRemove }: { element: FileType; onRemove(): void }) {
    const { t } = useTranslation();
    const { displayFileUrl, setDisplayFileUrl, spaceUrl } = useDisplayFileUrl(element.url);
    const { refsMap, popoverVisible, setPopoverVisible } = usePopoversController();
    const { isPending } = usePendingUpload({ element, onPreview: setDisplayFileUrl });

    const togglePopover = React.useCallback(
        (tag: Parameters<typeof setPopoverVisible>[0]) => () => {
            const isAlreadyOpen = popoverVisible === tag;
            setPopoverVisible(isAlreadyOpen ? null : tag);
        },
        [popoverVisible, setPopoverVisible],
    );

    const onOpenFileClick = () => {
        if (displayFileUrl && spaceUrl && !isPending) {
            const path = Boolean(window.todesktop)
                ? `${window.location.origin}/s/${spaceUrl}/asset?urlKey=${displayFileUrl}`
                : displayFileUrl;

            return openWindow(path, '_blank');
        }
    };

    return (
        <div
            contentEditable={false}
            onMouseDown={(e) => {
                e.preventDefault();
                e.stopPropagation();
            }}
            className="absolute top-0 right-0 flex opacity-100 flex-row select-none dark:text-white"
        >
            <Popover.Root>
                <Popover.Trigger asChild>
                    <button
                        ref={refsMap.current.contextMenu}
                        className="w-6 h-6 m-1 text-sm flex justify-center items-center bg-white dark:bg-zinc-700 shadow-popupSmall inset-0 rounded focus:outline-none active:shadow-xs"
                        onClick={togglePopover('contextMenu')}
                    >
                        <MoreHorizontal size={14} />
                    </button>
                </Popover.Trigger>
                <Popover.Portal>
                    <Popover.Content align="end" onOpenAutoFocus={(e) => e.preventDefault()} className="z-100">
                        <PopOver.PaddedCard>
                            <PopOver.RoundedButton
                                onClick={(e) => {
                                    e.stopPropagation();
                                    onOpenFileClick();
                                }}
                                aria-label={t('editor.file_upload.download_file') as string}
                            >
                                <Download className="stroke-gray-dark mr-2 my-auto" size={14} />
                                {t('editor.file_upload.download_file')}
                            </PopOver.RoundedButton>
                            <PopOver.RoundedButton
                                onClick={() => onRemove()}
                                aria-label={t('editor.file_upload.remove_file') as string}
                            >
                                <Trash className="stroke-gray-dark mr-2 my-auto" size={14} />
                                <div className="text-sm">{t('editor.file_upload.remove_file')}</div>
                            </PopOver.RoundedButton>
                        </PopOver.PaddedCard>
                    </Popover.Content>
                </Popover.Portal>
            </Popover.Root>
        </div>
    );
};

export function EditableFile({
    children,
    element,
}: Omit<RenderElementProps, 'children'> & { element: FileType; children?: RenderElementProps['children'] }) {
    const editor = useSlateStatic();
    const { urlKey } = useCurrentWorkspace();
    const { location } = SagaEditor.useEditorContext();
    const { cookiesValid, ensureCookiesArePresent } = useCookies(urlKey);
    const selected = useSelected();
    const uploadFile = useUploadFile(location);
    const { displayFileUrl, setDisplayFileUrl } = useDisplayFileUrl(element.url);
    const [showContextMenu, setShowContextMenu] = useState(false);
    const { isPending } = usePendingUpload({ element, onPreview: setDisplayFileUrl });
    const containerRef = useRef<HTMLDivElement>(null);

    const path = ReactEditor.findPath(editor, element);

    useEffect(() => {
        if (!cookiesValid) {
            ensureCookiesArePresent();
        }
    }, [cookiesValid, ensureCookiesArePresent]);

    async function onRemove() {
        const path = ReactEditor.findPath(editor, element);
        Transforms.select(editor, path);
        setTimeout(() => Transforms.removeNodes(editor, { at: path }));
        ReactEditor.focus(editor);
        Transforms.insertNodes(editor, [BlockBuilder.paragraph()]);
        track('remove-file');
    }

    async function onUpload(e: React.ChangeEvent<HTMLInputElement>) {
        const files = e.target.files;

        if (files) {
            await ensureCookiesArePresent();
            const file = files[0];

            await uploadFile({ file, element });

            e.target.value = '';
            track('upload-file', { type: file.type });
        }
    }

    if (!isPending && cookiesValid && !displayFileUrl) {
        return (
            <File.Container id={element.id} ref={containerRef}>
                <VoidSelectionShadow path={path}>
                    <EditableFile.FileInput selected={selected} onChange={onUpload} elementId={element.id} />
                    <File.Void>{children}</File.Void>
                </VoidSelectionShadow>
            </File.Container>
        );
    }

    if (isPending) {
        return (
            <File.Container id={element.id} ref={containerRef}>
                <div
                    className={classNames(
                        'px-2 py-1 relative flex items-center space-x-1 w-full dark:hover:bg-saga-gray-900 hover:bg-saga-bg-gray-light rounded mb-1',
                        { 'shadow-lightblue': selected },
                    )}
                >
                    <File.FileInfo element={element} uploading />
                </div>
            </File.Container>
        );
    }

    return (
        <File.Container id={element.id} ref={containerRef}>
            <div
                className={classNames(
                    'px-2 py-1 relative flex items-center space-x-1 w-full dark:hover:bg-saga-gray-900 hover:bg-saga-bg-gray-light rounded mb-1 truncate',
                    { 'shadow-lightblue': selected },
                )}
                onPointerEnter={() => setShowContextMenu(true)}
                onPointerLeave={() => setShowContextMenu(false)}
            >
                {displayFileUrl && cookiesValid && (
                    <>
                        <File.FileInfo element={element} />
                        {showContextMenu && <EditableFile.ContextMenu onRemove={onRemove} element={element} />}
                    </>
                )}
                <File.Void>{children}</File.Void>
            </div>
        </File.Container>
    );
}

export const spaceFileBlockPlugin = SagaEditor.Plugins.createBlockPlugin({
    match: isFile,
    Component(props) {
        const { canEdit, location } = SagaEditor.useEditorContext();
        const { urlKey } = useCurrentWorkspace();

        const focused = useFocused();

        const { selected, element, blockPlugins } = props;

        useEffect(() => {
            const onCopy = (event: ClipboardEvent) => {
                event.preventDefault();
                const clipboardData = event.clipboardData?.getData('application/x-saga');
                if (clipboardData && clipboardData.includes(element.id)) return;

                SagaEditor.Clipboard.copyBlocks([element], {
                    location: { ...location, blockId: element.id },
                    spaceUrlKey: urlKey,
                    event,
                    action: 'copy',
                    blockPlugins,
                });
            };

            if (focused && selected) {
                document.addEventListener('copy', onCopy);
            }

            return () => {
                document.removeEventListener('copy', onCopy);
            };
        }, [element, focused, selected, blockPlugins, location, urlKey]);

        return (
            <WithExplicitFontType type="interface">
                {canEdit ? <EditableFile {...props} /> : <File {...props} />}
            </WithExplicitFontType>
        );
    },
});
