import * as Y from 'yjs';
import { WeakTask } from '../types';
import { SafeSpace } from '../types';
import { unsafeRight } from '../io-ts-js';
import { findYArrayIndex, yarrayFromArray, ymapFromRecord } from '../yjs-utils';
import { transactInSpace } from '.';
import { isObject } from 'lodash';

type PartialTask = Partial<Omit<WeakTask, 'blocks' | 'id' | 'createdAt'>>;

export default function updatePartialTask(space: SafeSpace, id: string, partialTask: PartialTask) {
    const yTasks = unsafeRight(space.get('tasks'));

    const index = findYArrayIndex(yTasks.array as Y.Array<any>, (a) => {
        return a.get('id') === id;
    });

    if (index === -1) return null;

    const yTask: Y.Map<any> = yTasks.array.get(index);
    const keys = Object.keys(partialTask);

    /**
     * Use a transaction to fire 1 UpdateMessage.
     */
    transactInSpace(space, () => {
        keys.forEach((key) => {
            // @ts-expect-error
            const value = partialTask[key];

            if (Array.isArray(value)) {
                if (key === 'blocks') {
                    throw new Error('Changing blocks directly on the yPage is not allowed');
                }

                yTask.set(key, yarrayFromArray(value));
            } else if (isObject(value)) {
                yTask.set(key, ymapFromRecord(value as Record<string, any>));
            } else {
                yTask.set(key, value);
            }
        });
    });
}
