import { SafeSpace, TaskView } from '../types';
import transactInSpace from './transactInSpace';
import * as Y from 'yjs';
import { ymapFromRecord } from '../yjs-utils';
import { v4 as uuidv4 } from 'uuid';

export default function addTaskView(space: SafeSpace, view: Omit<TaskView, 'id' | 'createdAt' | 'isDefaultView'>) {
    const id = uuidv4();

    transactInSpace(space, () => {
        let taskViews = space.map.get('taskViews') as Y.Array<Y.Map<unknown>> | undefined;

        if (!taskViews) {
            taskViews = new Y.Array();
            space.map.set('taskViews', taskViews);
        }

        const viewMap = ymapFromRecord(
            { ...view, id, createdAt: new Date().toISOString(), isDefaultView: false },
            new Y.Map(),
        );
        taskViews.push([viewMap]);
    });

    return id;
}
