import { WeakTask } from '../types';
import { unsafeRight } from '../io-ts-js';
import { pipe } from 'fp-ts/function';
import { filterRights } from '../utils';
import * as A from 'fp-ts/Array';
import { SafeSpace } from '../types';

export type GetTasksMode = 'all' | 'deleted' | 'non-deleted';

const getTasks = <K extends keyof WeakTask>(
    space: SafeSpace,
    mode: GetTasksMode = 'non-deleted',
): Pick<WeakTask, K>[] => {
    const safeTasks = unsafeRight(space.get('tasks'));

    return pipe(
        safeTasks.toArray(),
        filterRights,
        A.map((safeMapOutput) => {
            return safeMapOutput.decode();
        }),
        filterRights,
        A.filter((task) => {
            if (mode === 'all') return true;
            const isArchived = task.archivedAt != null;
            return mode === 'deleted' ? isArchived : !isArchived;
        }),
    );
};

export default getTasks;
