import { SagaEditor, isDivider } from '@saga/shared';
import classNames from 'classnames';
import React, { useEffect } from 'react';
import { useFocused } from 'slate-react';

export const dividerPlugin = SagaEditor.Plugins.createBlockPlugin({
    match: isDivider,
    Component({ selected, element, children, blockPlugins }) {
        const { location } = SagaEditor.useEditorContext();
        const focused = useFocused();

        useEffect(() => {
            const handleCopy = (event: ClipboardEvent) => {
                event.preventDefault();
                const clipboardData = event.clipboardData?.getData('application/x-saga');
                if (clipboardData && clipboardData.includes(element.id)) return;

                SagaEditor.Clipboard.copyBlocks([element], {
                    location: { ...location, blockId: element.id },
                    event,
                    action: 'copy',
                    spaceUrlKey: 'public-page',
                    blockPlugins,
                });
            };

            if (focused && selected) {
                document.addEventListener('copy', handleCopy);
            }

            return () => {
                document.removeEventListener('copy', handleCopy);
            };
        }, [element, focused, selected, location, blockPlugins]);

        return (
            <div
                className={classNames('group h-2 md:h-8 flex items-center w-full', {
                    'no-caret': selected && focused,
                })}
                id={element.id}
                data-testid="divider"
                tabIndex={-1}
            >
                <div
                    className={classNames('h-2 md:h-5 rounded flex items-center w-full py-3', {
                        'shadow-lightblue': selected && focused,
                    })}
                >
                    <div className="w-full h-[1px] bg-saga-gray-200 dark:bg-[#444444]" contentEditable={true} />
                    <div className="hidden select-none">{children}</div>
                </div>
            </div>
        );
    },
});
