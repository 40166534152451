import React from 'react';
import { AlignLeft, CheckCircle, ChevronLeft, Flag, Link, Loader, Search } from 'react-feather';
import { PageContextMenuButton } from '@/components/popover/PageContextMenu';
import FavoriteButton from '@/components/styled/FavoriteButton';
import classNames from 'classnames';
import Button from '@/components/styled/Button';
import { useUserContext } from '@/components/UserContext';
import ManageCollectionsInput from '@/components/popover/ManageCollectionsInput';
import Tooltip from '@/components/popover/Tooltip';
import { BlockD, Page, RealtimeSagaEditor, SagaLocation, SpaceOperations } from '@saga/shared';
import { useSearchOpen, useSetSearchOpen } from '@/components/search/SearchContext';
import { useOpenCopyWorkspaceModal } from '../CopyWorkspaceModalProvider';
import { useOpenTrySagaModal } from '../onboarding/TrySagaModalProvider';
import useInterfaceSettings, { RightMenu } from '@/hooks/useInterfaceSettings';
import { useWorkspaceContext } from '../WorkspaceContext';
import { useSpaceAccess } from '@/hooks/useSpaceAccess';
import ContextMenuButton from '../styled/ContextMenuButton';
import { track } from '@/analytics';
import useMobile from '@/hooks/useMobile';
import { useFavoriteButton } from '@/hooks/useFavoriteButton';
import { PopOver } from '../popover/PopOver';
import WorkspaceAvatar from './WorkspaceAvatar';
import { animated } from '@react-spring/web';
import { useDesktopContext } from '@/components/DesktopContext';
import { useHistory, useLocation } from 'react-router-dom';
import Collaborators from './Collaborators';
import { useSpace } from '@/components/SpaceProvider';
import { useCollectionsSnapshot, useNonNullablePageSnapshot } from '@/hooks/SpaceHooks';
import { useOpenCollection, useOpenLocation } from '../PageNavigationProvider';
import DesktopNavigationButtons from '../DesktopNavigationButtons';
import * as Popover from '@radix-ui/react-popover';
import { useTranslation } from 'react-i18next';
import { Search as SearchIcon } from 'react-feather';
import ShareButton from '../ShareButton';
import DuplicatePageButton from './DuplicatePageButton';
import { useDocumentYBlocks } from '../RealtimeDocumentProvider';
import { usePageAccess } from '@/components/PagesPermissionsBySpaceProvider';
import { useTask } from '../tasks/hooks';

function ContextMenuWithButton({ children }: { children: React.ReactNode }) {
    return (
        <>
            <Popover.Root
                onOpenChange={(open) => {
                    if (open) {
                        track('open-context-menu');
                    } else {
                        track('close-context-menu');
                    }
                }}
            >
                <Popover.Trigger asChild>
                    <ContextMenuButton />
                </Popover.Trigger>
                <Popover.Portal>
                    <Popover.Content align="end" onOpenAutoFocus={(e) => e.preventDefault()} className="mt-1">
                        <PopOver.PaddedCard>
                            <div data-testid="context-menu" className="space-y-1">
                                {children}
                            </div>
                        </PopOver.PaddedCard>
                    </Popover.Content>
                </Popover.Portal>
            </Popover.Root>
        </>
    );
}

function PublicSpaceCallToActions({
    currentPage,
    onViewReferences,
    onViewTableOfContent,
    canTrySaga,
    canCopyWorkspace,
}: {
    currentPage: Props['currentPage'];
    onViewReferences(): void;
    onViewTableOfContent(): void;
    canTrySaga: boolean;
    canCopyWorkspace: boolean;
}) {
    const openCopyWorkspaceModal = useOpenCopyWorkspaceModal();
    const { data: yBlocks } = useDocumentYBlocks(currentPage.id);
    const openTrySagaModal = useOpenTrySagaModal();
    const isMobile = useMobile();
    const { t } = useTranslation();

    if (!canTrySaga && !canCopyWorkspace) {
        return null;
    }

    if (isMobile) {
        return (
            <ContextMenuWithButton>
                <>
                    <span className="block sm:hidden space-y-1">
                        {!currentPage.isTemplate && (
                            <PopOver.RoundedButton
                                onClick={() => {
                                    onViewReferences();
                                }}
                                aria-label="View References"
                            >
                                <Link className={'stroke-currentColor mr-2 my-auto flex-none'} size={16} />
                                {t('common.view_references')}
                            </PopOver.RoundedButton>
                        )}

                        <PopOver.RoundedButton
                            onClick={() => {
                                onViewTableOfContent();
                            }}
                            aria-label={t('table_of_content.label')}
                        >
                            <AlignLeft className={'stroke-currentColor mr-2 my-auto flex-none'} size={16} />
                            {t('table_of_content.label')}
                        </PopOver.RoundedButton>
                    </span>

                    {canCopyWorkspace && (
                        <PopOver.RoundedButton onClick={openCopyWorkspaceModal}>
                            <span className="whitespace-nowrap">{t('settings.general.copy_workspace')}</span>
                        </PopOver.RoundedButton>
                    )}

                    {canTrySaga && (
                        <PopOver.RoundedButton onClick={openTrySagaModal}>
                            <span>{t('common.try_saga')}</span>
                        </PopOver.RoundedButton>
                    )}
                </>
            </ContextMenuWithButton>
        );
    }

    return (
        <>
            {canCopyWorkspace && (
                <Button.Plain onClick={openCopyWorkspaceModal}>
                    <Button.BasePadding>
                        <span className="font-medium whitespace-nowrap">{t('settings.general.copy_workspace')}</span>
                    </Button.BasePadding>
                </Button.Plain>
            )}

            {currentPage.isPublicDuplicatable && (
                <DuplicatePageButton currentPage={currentPage} blocks={yBlocks?.toJSON() as BlockD[]} />
            )}

            {canTrySaga && (
                <div className="space-x-2 flex items-stretch">
                    <div className="py-2">
                        <div className="bg-saga-gray-200 dark:bg-zinc-600 w-0.5 h-full" />
                    </div>
                    <div>
                        <Button.Plain onClick={openTrySagaModal}>
                            <Button.BasePadding>
                                <span className="font-medium whitespace-nowrap">{t('common.try_saga')}</span>
                            </Button.BasePadding>
                        </Button.Plain>
                    </div>
                </div>
            )}
        </>
    );
}

export function SearchBar() {
    const [{ fixedSidebar }] = useInterfaceSettings();
    const isSearchOpen = useSearchOpen();
    const setSearchOpen = useSetSearchOpen();
    const { isDesktop } = useDesktopContext();
    const { t } = useTranslation();

    return (
        <div
            data-testid="header-bar-task"
            className={classNames(
                'w-full [max-width:750px] z-40 hover:z-100 lg:flex opacity-0 hover:opacity-100 justify-center items-center pr-2',
                { hidden: !isDesktop },
            )}
        >
            <button
                onClick={() => {
                    track('open-search-bar', { source: 'header-bar-click-on-placeholder' });
                    setSearchOpen(!isSearchOpen);
                }}
                className={classNames(
                    'w-full py-1.5 px-2 flex flex-row items-center',
                    'shadow-popupBorder rounded-md text-base bg-white dark:bg-zinc-700 font-normal text-saga-text-gray focus:outline-none cursor-text text-left',
                    {
                        'mx-2': !fixedSidebar,
                        'mx-1': fixedSidebar,
                    },
                )}
            >
                <SearchIcon size={20} color="#8A8A8A" className="mr-2" />
                {t('top_menu.search_dotted')}
            </button>
        </div>
    );
}

type Props = {
    location: SagaLocation.PageLocation;
    currentPage: Pick<
        Page,
        'id' | 'title' | 'collections' | 'archivedAt' | 'isTemplate' | 'settings' | 'icon' | 'isPublicDuplicatable'
    >;
    editor: RealtimeSagaEditor | null;
    loading?: boolean;
};

const HeaderBar = ({ currentPage, location, editor, loading = false }: Props) => {
    const { t } = useTranslation();
    const isArchived = currentPage.archivedAt != null;
    const { canEdit: canEditSpace, access } = useSpaceAccess();
    const { canEdit: canEditPage, canView: canViewPage } = usePageAccess(location.pageId);
    const isSearchOpen = useSearchOpen();
    const setSearchOpen = useSetSearchOpen();
    const { isFavorite, toggleFavorite } = useFavoriteButton(currentPage.id);
    const isMobile = useMobile();
    const [{ fixedSidebar }, setInterfaceSettings] = useInterfaceSettings();
    const singleSetRightMenu = (rightMenu: RightMenu) => setInterfaceSettings({ rightMenu });
    const { currentWorkspace } = useWorkspaceContext();
    const { user } = useUserContext();
    const { useMacOsLayout, isDesktop } = useDesktopContext();
    const canCopyWorkspace = currentWorkspace.isCopyWorkspaceEnabled;
    const canTrySaga = user == null;

    function openSidebar() {
        setInterfaceSettings({ fixedSidebar: true });
    }

    return (
        <div
            data-testid="header-bar"
            className="z-30 py-2 pl-2 bg-white dark:bg-saga-gray-1000 w-full flex flex-row justify-between items-center sticky top-0 sm:relative sm:top-auto"
            id="topbar"
        >
            {(canViewPage || currentWorkspace.isPublic) && (
                <>
                    <div className="flex flex-row space-x-3 items-center">
                        {fixedSidebar === false && useMacOsLayout && (
                            <animated.button
                                onClick={openSidebar}
                                type="button"
                                className={classNames('focus:outline-none dark:bg-saga-gray-1000', {
                                    'pl-2.5': !isMobile,
                                    'pl-[3.87rem]': isMobile,
                                })}
                            >
                                <span className="sr-only">{t('common.open_sidebar')}</span>

                                <WorkspaceAvatar workspace={currentWorkspace} />
                            </animated.button>
                        )}
                        {isDesktop && (
                            <div
                                className={classNames('pt-0.5', {
                                    'pl-[4.5px]': !fixedSidebar,
                                })}
                            >
                                <DesktopNavigationButtons />
                            </div>
                        )}
                        <div className="z-50">
                            {!isArchived && !isMobile && (
                                <HeaderBar.PageCollectionsInput pageId={currentPage.id} maxWidth={400} />
                            )}
                        </div>
                    </div>

                    <SearchBar />

                    <div
                        className={classNames(
                            'flex justify-end items-center text-saga-gray-dark dark:text-zinc-200 space-x-1 max-h-9',
                            {
                                'pr-0': isMobile,
                                'pr-1.5': !isMobile,
                            },
                        )}
                    >
                        <div className="flex items-center space-x-2">
                            {loading && (
                                <div className="animate-fadeIn">
                                    <Loader className="animate-spin" />
                                </div>
                            )}
                            {canEditSpace && <Collaborators currentLocation={location} />}

                            <div className="flex items-center">
                                <Tooltip content={t('top_menu.search_shortcut')} placement="bottom">
                                    <Button.Plain
                                        onClick={() => {
                                            setSearchOpen(!isSearchOpen);
                                            track('open-search-bar', { source: 'header-bar-search-button' });
                                        }}
                                    >
                                        <Button.BasePadding>
                                            <span className="sr-only">{t('common.search')}</span>
                                            <Search size={20} />
                                        </Button.BasePadding>
                                    </Button.Plain>
                                </Tooltip>
                            </div>

                            {canEditPage && !isArchived && !currentPage.isTemplate && (
                                <FavoriteButton type="page" isFavorite={isFavorite} onClick={toggleFavorite} />
                            )}

                            {canEditSpace && <ShareButton page={currentPage} />}

                            {canEditSpace && (
                                <PageContextMenuButton align="right" page={currentPage} editor={editor}>
                                    {(setIsOpen) => (
                                        <span className="block sm:hidden space-y-1">
                                            {!currentPage.isTemplate && (
                                                <PopOver.RoundedButton
                                                    onClick={() => {
                                                        setIsOpen(false);
                                                        singleSetRightMenu('references');
                                                    }}
                                                    aria-label="View References"
                                                >
                                                    <Link
                                                        className={'stroke-currentColor mr-2 my-auto flex-none'}
                                                        size={16}
                                                    />
                                                    {t('common.view_references')}
                                                </PopOver.RoundedButton>
                                            )}
                                            <PopOver.RoundedButton
                                                onClick={() => {
                                                    setIsOpen(false);
                                                    singleSetRightMenu('toc');
                                                }}
                                                aria-label={t('table_of_content.label')}
                                            >
                                                <AlignLeft
                                                    className={'stroke-currentColor mr-2 my-auto flex-none'}
                                                    size={16}
                                                />
                                                {t('table_of_content.label')}
                                            </PopOver.RoundedButton>
                                        </span>
                                    )}
                                </PageContextMenuButton>
                            )}

                            {!access.write && currentWorkspace.isPublic && (
                                <PublicSpaceCallToActions
                                    currentPage={currentPage}
                                    onViewReferences={() => {
                                        singleSetRightMenu('references');
                                    }}
                                    onViewTableOfContent={() => {
                                        singleSetRightMenu('toc');
                                    }}
                                    canTrySaga={canTrySaga}
                                    canCopyWorkspace={canCopyWorkspace}
                                />
                            )}
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

function ReportPageButton({ onClick }: { onClick(): void }) {
    const { t } = useTranslation();
    return (
        <Button.Plain widthFull onClick={onClick} aria-label="Report">
            <Button.BasePadding>
                <span className="text-saga-red dark:text-red-300 flex items-center space-x-2">
                    <Flag size={16} />
                    <span>{t('common.report_page')}</span>
                </span>
            </Button.BasePadding>
        </Button.Plain>
    );
}

function TrySagaButton({ onClick }: { onClick(): void }) {
    const { t } = useTranslation();
    return (
        <Button.Plain onClick={onClick}>
            <Button.BasePadding>
                <span className="font-medium whitespace-nowrap">{t('common.try_saga')}</span>
            </Button.BasePadding>
        </Button.Plain>
    );
}

function PublicPageContextMenuButton({ pageId }: { pageId: string }) {
    const { t } = useTranslation();
    const isMobile = useMobile();
    const [, setInterfaceSettings] = useInterfaceSettings();
    const singleSetRightMenu = (rightMenu: RightMenu) => setInterfaceSettings({ rightMenu });

    function onReportPage() {
        window.location.href = `mailto:support@saga.so?subject=Report Page: ${pageId}`;
    }

    if (isMobile) {
        return (
            <ContextMenuWithButton>
                <div className="space-y-0.5">
                    <ReportPageButton
                        onClick={() => {
                            onReportPage();
                            track('report-page');
                        }}
                    />
                    <Button.Plain
                        widthFull
                        onClick={() => {
                            singleSetRightMenu('toc');
                        }}
                        aria-label={t('table_of_content.label')}
                    >
                        <Button.BasePadding>
                            <span className="flex items-center space-x-2">
                                <AlignLeft size={16} />
                                <span>{t('table_of_content.label')}</span>
                            </span>
                        </Button.BasePadding>
                    </Button.Plain>
                </div>
            </ContextMenuWithButton>
        );
    }

    return (
        <ContextMenuWithButton>
            <ReportPageButton
                onClick={() => {
                    onReportPage();
                    track('report-page');
                }}
            />
        </ContextMenuWithButton>
    );
}

HeaderBar.PublicPage = function HeaderBarPublicPage({
    currentPage,
}: {
    currentPage: Pick<Page, 'id' | 'title' | 'icon' | 'isPublicDuplicatable' | 'blocks'>;
}) {
    const { t } = useTranslation();
    const isMobile = useMobile();
    const openTrySagaModal = useOpenTrySagaModal();
    const { isLoggedIn, isLoading } = useUserContext();
    const { useMacOsLayout } = useDesktopContext();

    const history = useHistory();
    const location = useLocation();

    return (
        <>
            <div
                data-testid="header-bar"
                className={classNames(
                    'z-30 py-2 pl-2 bg-white dark:bg-saga-gray-1000 flex flex-1 flex-row justify-between items-center sticky top-0 sm:relative sm:top-auto',
                    { 'ml-16 pt-1.5': useMacOsLayout },
                )}
                id="topbar"
            >
                <div className="w-full flex justify-between items-center text-saga-gray-dark dark:text-zinc-200 space-x-1">
                    <div>
                        {isLoggedIn && (
                            <Button.Plain
                                onClick={() => {
                                    if (location.key) {
                                        history.goBack();
                                    } else {
                                        history.push('/s');
                                    }

                                    track('back-to-my-saga');
                                }}
                            >
                                <Button.BasePadding>
                                    <div className="flex space-x-1 items-center text-sm">
                                        <ChevronLeft size={16} />
                                        <span>{t('common.back_to_my_saga')}</span>
                                    </div>
                                </Button.BasePadding>
                            </Button.Plain>
                        )}
                    </div>

                    {!isLoading && (
                        <div
                            className={classNames('flex items-center space-x-2', {
                                'pr-2': isMobile,
                                '[padding-right:14px]': !isMobile,
                            })}
                        >
                            {currentPage.isPublicDuplicatable && (
                                <DuplicatePageButton currentPage={currentPage} blocks={currentPage.blocks} />
                            )}
                            {!isLoggedIn && <TrySagaButton onClick={openTrySagaModal} />}
                            <PublicPageContextMenuButton pageId={currentPage.id} />
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

const keys = ['collections'] as const;

HeaderBar.PageCollectionsInput = function HeaderBarCollectionsInput({
    pageId,
    maxWidth,
}: {
    pageId: string;
    maxWidth?: number;
}) {
    const page = useNonNullablePageSnapshot(pageId, keys, 'deep');
    const { t } = useTranslation();
    const { space } = useSpace();
    const { canEdit: canEditSpace } = useSpaceAccess();
    const { canEdit: canEditPage } = usePageAccess(pageId);

    const collections = useCollectionsSnapshot();
    const goToCollection = useOpenCollection();
    const currentCollections = collections.filter((c) => page.collections.includes(c.id));
    const availableCollections = collections.filter((c) => !page.collections.includes(c.id));

    function onAddPageToCollection(id: string) {
        SpaceOperations.addPageToCollection(space, pageId, id);
        track('add-page-to-collection', { source: 'header-bar' });
    }
    function onRemoveCollection(id: string) {
        SpaceOperations.removePageFromCollection(space, pageId, id);
        track('remove-page-from-collection', { source: 'header-bar' });
    }

    function onCreateCollection(title: string) {
        const newCollection = SpaceOperations.createCollection(space, title);
        SpaceOperations.addPageToCollection(space, pageId, newCollection.id);
        track('create-collection-from-popover', { source: 'header-bar' });
    }

    return (
        <ManageCollectionsInput
            canEdit={canEditSpace && canEditPage}
            currentCollections={currentCollections ?? []}
            availableCollections={availableCollections ?? []}
            onCollectionClick={goToCollection}
            onCreate={onCreateCollection}
            onSelect={onAddPageToCollection}
            onRemove={onRemoveCollection}
            placeholder={t('common.plus_collection') as string}
            maxWidth={maxWidth}
        />
    );
};

HeaderBar.TaskButton = function HeaderBarTaskButton({}) {
    const { t } = useTranslation();
    const openLocation = useOpenLocation();
    const { isDesktop } = useDesktopContext();

    function openTasksTable(event: React.MouseEvent) {
        track('open-allTasks-table');
        openLocation({ type: 'allTasks', viewId: 'all' }, event);
    }

    return (
        <Button.Plain isSelected={false} withBorder={true} isDesktop={isDesktop} roundedMD={true}>
            <div className="w-full flex items-center space-x-1 mx-1 h-[23px]" onClick={openTasksTable}>
                <CheckCircle className="flex-none w-3 h-3 shrink-0 mr-0.5" />
                <div className="min-w-0 text-sm ">{t('sidebar.tasks')}</div>
            </div>
        </Button.Plain>
    );
};

HeaderBar.TaskCollectionsInput = function HeaderBarTaskCollectionsInput({
    taskId,
    maxWidth,
}: {
    taskId: string;
    maxWidth?: number;
}) {
    const task = useTask(taskId);
    const { t } = useTranslation();
    const { space } = useSpace();
    const { canEdit: canEditSpace } = useSpaceAccess();
    const { canEdit: canEditPage } = usePageAccess(task.id);

    const collections = useCollectionsSnapshot();
    const goToCollection = useOpenCollection();
    const currentCollections = collections.filter((c) => task.collections?.includes(c.id));
    const availableCollections = collections.filter((c) => !task.collections?.includes(c.id));

    function onAddTaskToCollection(id: string) {
        SpaceOperations.addTaskToCollection(space, taskId, id);
        track('add-task-to-collection', { source: 'header-bar' });
    }
    function onRemoveCollection(id: string) {
        SpaceOperations.removeTaskFromCollection(space, taskId, id);
        track('remove-task-from-collection', { source: 'header-bar' });
    }

    function onCreateCollection(title: string) {
        const newCollection = SpaceOperations.createCollection(space, title);
        SpaceOperations.addTaskToCollection(space, taskId, newCollection.id);
        track('create-collection-from-popover', { source: 'header-bar' });
    }

    return (
        <ManageCollectionsInput
            canEdit={canEditSpace && canEditPage}
            currentCollections={currentCollections ?? []}
            availableCollections={availableCollections ?? []}
            onCollectionClick={goToCollection}
            onCreate={onCreateCollection}
            onSelect={onAddTaskToCollection}
            onRemove={onRemoveCollection}
            placeholder={t('common.plus_collection') as string}
            maxWidth={maxWidth}
        />
    );
};

export default HeaderBar;
