import React from 'react';

export function OpenSidebarIcon({ className }: { className?: string }) {
    return (
        <svg
            className={className}
            width="14"
            height="20"
            viewBox="0 0 14 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.86128 14.1387C8.60093 13.8784 8.60093 13.4563 8.86128 13.1959L12.3899 9.66732L8.86128 6.13872C8.60093 5.87837 8.60093 5.45626 8.86128 5.19591C9.12163 4.93556 9.54374 4.93556 9.80409 5.19591L13.8041 9.19591C14.0644 9.45626 14.0644 9.87837 13.8041 10.1387L9.80409 14.1387C9.54374 14.3991 9.12163 14.3991 8.86128 14.1387Z"
                fill="currentColor"
            />
            <rect
                x="13"
                y="10.334"
                width="9"
                height="1.5"
                rx="0.75"
                transform="rotate(-180 13 10.334)"
                fill="currentColor"
            />
            <rect x="2" width="20" height="2" rx="1" transform="rotate(90 2 0)" fill="currentColor" />
        </svg>
    );
}
