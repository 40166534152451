import React from 'react';
import QuickEditPageModal from '@/components/popover/QuickEditPageModal';

const SetQuickEditModalPageContext = React.createContext<React.Dispatch<React.SetStateAction<string | null>> | null>(
    null,
);
export const useSetQuickEditModalPage = () => React.useContext(SetQuickEditModalPageContext);

export default function QuickEditPageContainer({ children }: { children: React.ReactNode }) {
    const [pageId, setPageId] = React.useState<string | null>(null);

    return (
        <SetQuickEditModalPageContext.Provider value={setPageId}>
            {children}
            {pageId && <QuickEditPageModal pageId={pageId} onClose={() => setPageId(null)} />}
        </SetQuickEditModalPageContext.Provider>
    );
}
