import { assertYArray } from '../yjs-utils';
import transactInSpace from './transactInSpace';
import { SafeSpace } from '../types';
import getPageMapById from './getPageMapById';
import { assertNonNull } from '../utils';

export default function removePageFromCollection(space: SafeSpace, pageId: string, collectionId: string) {
    transactInSpace(space, () => {
        const pageMap = getPageMapById(space, pageId);
        assertNonNull(pageMap);
        const collections = pageMap.get('collections');
        assertYArray(collections);
        const index = collections.toArray().findIndex((c) => c === collectionId);
        if (index !== -1) {
            collections.delete(index);
        } else {
            throw new Error(
                `Page with id ${pageId} could not be removed: Collection with id ${collectionId} not found`,
            );
        }
    });
}
