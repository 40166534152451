import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import Button from '@/components/styled/Button';

interface PropsWithChildren {
    children: React.ReactNode;
}

interface PropsWithClassName {
    className?: string;
}

function SettingsPane({ children, className }: PropsWithChildren & PropsWithClassName) {
    return <div className={classNames('h-full px-12 pt-6 flex flex-col', className)}>{children}</div>;
}

SettingsPane.Title = function Header({ children }: PropsWithChildren) {
    return <h1 className="text-4xl leading-[3rem] font-bold truncate min-w-0">{children}</h1>;
};

SettingsPane.Small = function Header({ children }: PropsWithChildren) {
    return <p className="text-saga-gray-500 dark:text-zinc-400 text-sm">{children}</p>;
};

SettingsPane.Section = function Section({ children }: PropsWithChildren) {
    return <section className="pt-6">{children}</section>;
};

SettingsPane.SectionFlex = function SectionFlex({ children, className }: PropsWithChildren & PropsWithClassName) {
    return (
        <section className={classNames('pt-6 flex justify-between space-x-2 items-center', className)}>
            {children}
        </section>
    );
};

SettingsPane.Checkbox = function Checkbox({
    className,
    checked,
    onChange,
    ...props
}: React.InputHTMLAttributes<HTMLInputElement>) {
    const [value, setValue] = useState(false);

    useEffect(() => {
        if (checked) {
            setValue(checked);
        }
    }, [checked]);

    return (
        <input
            className={classNames('w-4 my-auto mr-2', className)}
            type="checkbox"
            checked={value}
            onChange={(e) => {
                setValue(e.target.checked);
                if (onChange) {
                    onChange(e);
                }
            }}
            {...props}
        ></input>
    );
};

SettingsPane.Content = function Content({ className, children }: PropsWithClassName & PropsWithChildren) {
    return (
        <div className={classNames('divide-y space-y-4 divide-saga-gray-150 dark:divide-saga-gray-800', className)}>
            {children}
        </div>
    );
};

SettingsPane.Footer = function Footer({ className, children }: PropsWithClassName & PropsWithChildren) {
    return (
        <div className={classNames('pt-4 border-t border-saga-gray-200 dark:border-zinc-600', className)}>
            {children}
        </div>
    );
};

function SettingsButton({ children, className, ...props }: React.ButtonHTMLAttributes<HTMLButtonElement>) {
    return (
        <Button className={classNames('my-4 px-3 py-2 focus:outline-none focus:ring rounded', className)} {...props}>
            {children}
        </Button>
    );
}

SettingsButton.Default = function ButtonDefault({
    children,
    className,
    disabled,
    ...props
}: React.ButtonHTMLAttributes<HTMLButtonElement>) {
    return (
        <Button disabled={disabled} className={className} {...props}>
            {children}
        </Button>
    );
};

SettingsButton.Alert = function ButtonAlert({
    children,
    className,
    ...props
}: React.ButtonHTMLAttributes<HTMLButtonElement>) {
    return (
        <Button className={className} variant="alert" {...props}>
            {children}
        </Button>
    );
};

SettingsPane.Button = SettingsButton;

export default SettingsPane;
