import * as Sentry from '@sentry/browser';
import * as E from 'fp-ts/Either';
import { Text } from 'slate';
import { v4 as uuid } from 'uuid';
import * as Y from 'yjs';
import { SafeMapOutput, safeMap, unsafeRight } from './io-ts-js';

import {
    BlockD,
    PAGE_SCHEMA_VERSION,
    SafeSpace,
    SagaElement,
    SagaText,
    SpaceOperations,
    Task,
    TransformOptions,
    WeakBlocks,
    isBlockType,
    isFile,
    isImage,
    isLiveBlock,
    mapBlocksAsync,
} from '.';
import {
    AnyBlockItem,
    BlockType,
    Collection,
    Page,
    PageContent,
    Space,
    Title,
    WeakPage,
    WeakTask,
    flatMap,
    isSagaText,
    isTitle,
    isVoid,
    mapBlock,
    pageD,
} from './types';
import { removeNullable } from './utils';
import { yarrayFromArray, ymapFromRecord } from './yjs-utils';

export const initialValue = (title?: string): PageContent => {
    return [
        {
            id: uuid(),
            type: BlockType.TITLE,
            children: [{ text: title ? title : '' }],
        },
        {
            id: uuid(),
            type: BlockType.PARAGRAPH,
            children: [{ text: '' }],
        },
    ];
};

export function mapBlockToSharedType(key: unknown, value: unknown): Y.Text | Y.Array<unknown> | unknown {
    // This converts text children content to Y.Text instances
    if (key === 'text' && typeof value === 'string') {
        return new Y.Text(value);
    }

    if (key === 'children' && Array.isArray(value)) {
        // Recursively transform the children of a block
        return yarrayFromArray(value, new Y.Array(), mapBlockToSharedType);
    }

    // everything that is not a text or a children array will be kept as is
    return value;
}

export function transformBlockToSharedType(block: AnyBlockItem | Title) {
    return ymapFromRecord(block as Record<string, unknown>, new Y.Map(), mapBlockToSharedType);
}

export function transformBlocksToSharedType(blocks: any[]) {
    return yarrayFromArray(blocks, new Y.Array(), mapBlockToSharedType);
}

export function transformPageToSharedType(page: WeakPage) {
    return ymapFromRecord(page, new Y.Map());
}

export function transformTaskToSharedType(task: WeakTask) {
    return ymapFromRecord(task, new Y.Map());
}

export function transformPagesToSharedType(pages: WeakPage[]) {
    return yarrayFromArray(pages, new Y.Array());
}

export function transformTasksToSharedType(tasks: WeakTask[]) {
    return yarrayFromArray(tasks, new Y.Array());
}

export const createYPage = (startingFields: Partial<Page>) => {
    return transformPageToSharedType(newBlankPage(startingFields));
};

export const createYTask = (startingFields: Partial<Task>) => {
    return transformTaskToSharedType(newBlankTask(startingFields));
};

//create new blank task
export const newBlankTask = (startingFields: Partial<Task>): Task => {
    const task: Task = {
        id: uuid(),
        assignee: null,
        priority: null,
        title: startingFields.title ?? '',
        blocks: initialValue(startingFields.title ? startingFields.title : ''),
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        state: 'OPEN',
        archivedAt: undefined,
        collections: [],
        createdBy: undefined,
        ...startingFields,
    };

    return task;
};

// create new blank page
export const newBlankPage = (startingFields: Partial<Page>): Page => {
    const page = {
        id: uuid(),
        version: PAGE_SCHEMA_VERSION,
        title: '',
        aliases: [],
        createdAt: new Date().toString(),
        updatedAt: new Date().toString(),
        createdBy: undefined,
        blocks: initialValue(startingFields.title ? startingFields.title : ''),
        collections: [],
        properties: [],
        settings: {
            linkTitle: true,
            linkHeadings: false,
        },
        wordCount: 0,
        icon: undefined,
    };

    return {
        ...page,
        isTemplate: false,
        createdFromTemplate: undefined,
        ...startingFields,
        archivedAt: undefined,
        blocks: startingFields.blocks ? startingFields.blocks : page.blocks,
        wordCount: getWordCount(page.blocks.slice(1)),
        isPublicDuplicatable: false,
    };
};

export const newBlankCollection = (startingFields: Partial<Collection>): Collection => {
    return {
        id: uuid(),
        title: '',
        icon: undefined,
        properties: [],
        subCollections: null,
        sorting: null,
        ...startingFields,
        createdAt: new Date().toString(),
        updatedAt: new Date().toString(),
    };
};

export const getWordCount = (blocks: SagaElement[], count = 0) => {
    for (const b of blocks) {
        if (Text.isText(b)) {
            count += b.text.split(' ').filter((t) => t.length > 0).length;
        }
        if (b.children) {
            count = getWordCount(b.children as SagaElement[], count);
        }
    }
    return count;
};

export function appendStringToTitle(entity: Page | Task, value: string): Page | Task {
    const blocks = entity.blocks.map((block) => {
        return mapBlock(block as SagaElement, (node) => {
            if (isTitle(node)) {
                return { ...node, children: [...node.children, { text: value }] };
            }
            return node;
        });
    });
    return { ...entity, blocks: blocks as BlockD[], title: `${entity.title}${value}` };
}

export function decodeYMapAsPage(map: Y.Map<any>) {
    return safeMap({ map, definition: pageD }).decode();
}

export function getNewPagesFromDelta(delta: Y.YEvent<any>['changes']['delta']): WeakPage[] {
    return delta
        .filter((v) => v.insert != null)
        .map((v) => v.insert)
        .flat()
        .filter((v): v is Y.Map<unknown> => v instanceof Y.Map)
        .map(decodeYMapAsPage)
        .map((v) => {
            if (E.isRight(v)) {
                return v.right;
            }
            console.warn(v.left.error);
            Sentry.captureMessage('getNewPagesFromDelta failed', { extra: { error: v.left.error } });
            return null;
        })
        .filter(removeNullable);
}

export const getCurrentYPageOrEmpty = (space: SafeMapOutput<Space>, pageId?: string) => {
    const ypages = (space.map.get('pages') as Y.Array<Y.Map<unknown>>) ?? [];

    const pageIndex = SpaceOperations.findIndexByPageId(space, pageId ?? '');

    const page = ypages instanceof Y.Array ? ypages.get(pageIndex) : null;

    return page ? page : new Y.Map();
};

export function pageIsEmpty(pageBlocks: WeakBlocks) {
    const blocks = flatMap(pageBlocks, ([n]) => n);

    if (blocks.some(isVoid)) return false;

    return blocks.filter(isSagaText).every((b) => isSagaText(b) && b.text.trim().length === 0);
}

export function unsafeDecodeAsPage(object: any) {
    return unsafeRight(pageD.decode(object));
}

export async function transformSharedPage(page: Page, space: SafeSpace, opts?: TransformOptions): Promise<Page> {
    const staticBlocks = SpaceOperations.prepareBlocksForExport(space, page.blocks);

    async function replaceImageUrlsForBlocks(
        blocks: (SagaElement | SagaText)[],
        replaceImageUrl: (url: string) => Promise<string>,
    ): Promise<any> {
        return mapBlocksAsync(blocks, async (block) => {
            if (isBlockType(block, [isImage, isFile]) && block.url && replaceImageUrl) {
                const url = await replaceImageUrl(block.url);
                return { ...block, url };
            }

            if (isLiveBlock(block) && block.staticBlocks != null) {
                const newStaticBlocks = await replaceImageUrlsForBlocks(block.staticBlocks, replaceImageUrl);
                return { ...block, staticBlocks: newStaticBlocks };
            }

            return block;
        });
    }

    if (opts?.replaceImageUrl) {
        return {
            ...page,
            blocks: await replaceImageUrlsForBlocks(staticBlocks, opts.replaceImageUrl),
        };
    }

    return { ...page, blocks: staticBlocks as BlockD[] };
}
