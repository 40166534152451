import { CollectionIcon } from '@/components/icons';
import { PopOver, usePopover } from '@/components/popover/PopOver';
import useMobile from '@/hooks/useMobile';
import { escapeRegExp } from '@/lib/helpers';
import { Collection } from '@saga/shared';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Button from '../styled/Button';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

type ManagePageCollectionsPopOverProps = {
    attachToRef: React.MutableRefObject<HTMLDivElement | HTMLButtonElement | null>;
    isOpen: boolean;
    setIsOpen: (value: boolean) => void;
    currentCollections: Collection[];
    availableCollections: Collection[];
    onCollectionClick: (id: string, event: React.MouseEvent) => void;
    onCreate: (title: string) => void;
    onSelect: (id: string) => void;
    onRemove: (id: string) => void;
    excludeCollections?: string[];
    zIndex?: number;
};

function ManagePageCollectionsPopOver({
    attachToRef,
    isOpen,
    setIsOpen,
    currentCollections,
    availableCollections,
    excludeCollections,
    onCollectionClick,
    onCreate,
    onSelect,
    onRemove,
    zIndex,
}: ManagePageCollectionsPopOverProps) {
    const inputRef = useRef<HTMLInputElement>(null);
    const [search, setSearch] = useState<string>('');
    const [index, setIndex] = useState<number>(0);
    const isMobile = useMobile();
    const { t } = useTranslation();
    const [popoverRef, contentRef] = usePopover({
        isOpen,
        onClose: () => setIsOpen(false),
        target: useCallback(() => {
            if (attachToRef.current) {
                return attachToRef.current.getBoundingClientRect();
            }
            return null;
        }, [attachToRef]),
        clickOutsideRefs: [attachToRef],
        position: 'below',
        align: isMobile ? 'right' : 'left',
    });

    const suggestions: Collection[] = useMemo(() => {
        if (search.trim().length > 0) {
            const regex = new RegExp(`(?:^|\\s)${escapeRegExp(search.trim())}`, 'gi');
            let result = [];
            if (excludeCollections) {
                result = availableCollections.filter((c) => c.title.match(regex) && !excludeCollections.includes(c.id));
            } else {
                result = availableCollections.filter((c) => c.title.match(regex));
            }
            return result ? result.slice(0, 10) : [];
        }
        if (excludeCollections) {
            return availableCollections.slice(0, 10).filter((c) => !excludeCollections.includes(c.id));
        } else {
            return availableCollections.slice(0, 10);
        }
    }, [search, availableCollections, excludeCollections]);

    const orderedSuggestions = useMemo(
        () => _.orderBy(suggestions, (a) => new Date(a.updatedAt), 'desc'),
        [suggestions],
    );

    const isSearchNew =
        search.trim().length > 0 &&
        availableCollections.filter((c) => c.title.toLowerCase() === search.trim().toLowerCase()).length === 0;

    useEffect(() => {
        if (isOpen) {
            setSearch('');
            setIndex(0);
        }
    }, [isOpen]);

    const remove = (id: string) => {
        inputRef.current?.focus();
        onRemove(id);
    };

    function submit() {
        setSearch('');
        setIndex(0);
        inputRef.current?.focus();
        if (index === orderedSuggestions.length) {
            onCreate(search.trim());
        } else {
            onSelect(orderedSuggestions[index].id);
        }
    }

    return (
        <PopOver
            isOpen={isOpen}
            ref={popoverRef}
            contentRef={contentRef}
            noPointer={true}
            zIndex={zIndex ?? 50}
            onOpenAnimationDone={() => {
                inputRef.current?.focus();
            }}
        >
            <form
                className="shadow-popupSmall rounded-md"
                onSubmit={(e) => {
                    e.preventDefault();
                    submit();
                }}
            >
                <PopOver.Input
                    style={{ width: '269px' }}
                    rounded={
                        currentCollections.length > 0 || search.trim().length > 0 || orderedSuggestions.length > 0
                            ? 'bottom'
                            : 'full'
                    }
                    ref={inputRef}
                    type="text"
                    title={t('top_menu.search_for_or_create_new') as string}
                    placeholder={t('top_menu.search_for_or_create_new') as string}
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    onKeyDown={(event) => {
                        const maxLength = search.trim().length > 0 ? suggestions.length : suggestions.length - 1;
                        switch (event.key) {
                            case 'ArrowDown':
                                event.preventDefault();
                                const prevIndex = index >= maxLength ? 0 : index + 1;
                                setIndex(prevIndex);
                                break;
                            case 'ArrowUp':
                                event.preventDefault();
                                const nextIndex = index <= 0 ? maxLength : index - 1;
                                setIndex(nextIndex);
                                break;
                            case 'Enter':
                                event.preventDefault();
                                submit();
                                return;
                        }
                    }}
                />
                {(currentCollections.length > 0 || search.trim().length > 0 || orderedSuggestions.length > 0) && (
                    <>
                        <PopOver.Card rounded="bottom">
                            <div data-testid="manage-collections-popover">
                                {search.trim().length === 0 && currentCollections && currentCollections.length > 0 && (
                                    <div className="pt-1.5">
                                        <PopOver.SelectedOptionsContainer>
                                            {currentCollections.map((collection, i) => (
                                                <Button.RemovableOption
                                                    key={i}
                                                    label={`Remove ${collection.id}`}
                                                    onRemove={() => remove(collection.id)}
                                                >
                                                    <button
                                                        className="flex items-center px-1"
                                                        type="button"
                                                        onClick={(event) => {
                                                            setIsOpen(false);
                                                            onCollectionClick(collection.id, event);
                                                        }}
                                                    >
                                                        <CollectionIcon
                                                            icon={collection.icon}
                                                            size={12}
                                                            badgeSize={10}
                                                        />
                                                        <p className="pl-2">{collection.title}</p>
                                                    </button>
                                                </Button.RemovableOption>
                                            ))}
                                        </PopOver.SelectedOptionsContainer>
                                    </div>
                                )}
                                {search.trim().length >= 0 && orderedSuggestions.length > 0 && (
                                    <PopOver.Label>{t('top_menu.search_for_collection_label')}</PopOver.Label>
                                )}
                                {orderedSuggestions.map((collection, i) => {
                                    const selected = i === index;

                                    return (
                                        <div
                                            className={classNames('', {
                                                'pt-1.5': search.trim().length === 0 && orderedSuggestions.length === 0,
                                            })}
                                            key={i}
                                        >
                                            <Button.PopOverButton
                                                selected={selected}
                                                onMouseEnter={() => {
                                                    setIndex(i);
                                                }}
                                                type="button"
                                                onClick={submit}
                                            >
                                                <CollectionIcon icon={collection.icon} size={14} badgeSize={12} />
                                                <p className="pl-2 min-h-[24px] pt-0.5"> {collection.title}</p>
                                            </Button.PopOverButton>
                                        </div>
                                    );
                                })}
                                {search.trim().length > 0 && (
                                    <div className="pt-1.5">
                                        <Button.PopOverButton
                                            disabled={!isSearchNew}
                                            selected={suggestions.length === index}
                                            onMouseEnter={() => setIndex(suggestions.length)}
                                            onClick={submit}
                                            type="button"
                                        >
                                            <p className="font-medium mr-1">{search}</p>
                                            <p>{t('top_menu.create_collection')}</p>
                                        </Button.PopOverButton>
                                    </div>
                                )}
                            </div>
                        </PopOver.Card>
                    </>
                )}
            </form>
        </PopOver>
    );
}

export default ManagePageCollectionsPopOver;
