import { EditorOperations, assertNonNull, SagaEditor, isTable, isTableRow, isTableCell, TableRow } from '@saga/shared';
import classNames from 'classnames';
import React from 'react';
import { Node, NodeEntry } from 'slate';
import { ReactEditor, useSelected, useSlate } from 'slate-react';
import { MoreHorizontal, Trash, Plus, ArrowLeft, ArrowRight, MoreVertical } from 'react-feather';
import * as Popover from '@radix-ui/react-popover';
import Button from '@/components/styled/Button';
import { useTranslation } from 'react-i18next';
import { WithExplicitFontType } from '@/components/WithExplicitFontType';
import FormatIcon from '@/components/styled/FormatIcon';

export const useTableContext = () => React.useContext(TableContext);
const useNonNullableTableContext = () => {
    const context = React.useContext(TableContext);
    assertNonNull(context, 'useNonNullableTableContext must be used inside a TableContextProvider');
    return context;
};

function getNodeEntry<T extends Node>(editor: ReactEditor, element: T): NodeEntry<T> {
    const path = ReactEditor.findPath(editor, element);
    return [element, path];
}

type TableFeatures = {
    appendRow(): void;
    appendColumn(): void;
    deleteColumnByIndex(index: number): void;
    deleteRowByIndex(index: number): void;
    moveColumnLeft(index: number): void;
    moveColumnRight(index: number): void;
    onAfterMenuOpen(): void;
};

const TableContext = React.createContext<{
    menuOpenForColumn: number | null;
    menuOpenForRow: number | null;
    setMenuOpenForColumn(index: number | null): void;
    setMenuOpenForRow(index: number | null): void;
    features?: TableFeatures;
    selectedRowIndex: number | null;
    setSelectedRowIndex(index: number | null): void;
} | null>(null);

function AppendButton({
    onMouseDown,
    isVisble,
    name,
    className,
}: {
    onMouseDown: React.MouseEventHandler;
    isVisble?: boolean;
    name: string;
    className?: string;
}) {
    return (
        <button
            onMouseDown={onMouseDown}
            contentEditable={false}
            className={classNames(
                'flex justify-center items-center dark:bg-zinc-600 bg-saga-gray-200 hover:opacity-100 transition-opacity cursor-pointer z-10 pointer-events-auto select-none absolute rounded',
                className,
                {
                    'opacity-40': isVisble,
                    'opacity-0 pointer-events-none sm:pointer-events-auto': !isVisble,
                },
            )}
        >
            <Plus size={12} />
            <span className="sr-only">{name}</span>
        </button>
    );
}

const tablePlugin = SagaEditor.Plugins.createBlockPlugin({
    match: isTable,
    normalizers: [SagaEditor.Normalizers.tableNormalizer],
    Component({ element, children, selected, editor }) {
        const { canEdit } = SagaEditor.useEditorContext();
        const [menuOpenForColumn, setMenuOpenForColumn] = React.useState<number | null>(null);
        const [menuOpenForRow, setMenuOpenForRow] = React.useState<number | null>(null);
        const [selectedRowIndex, setSelectedRowIndex] = React.useState<number | null>(null);
        const tableRef = React.useRef<HTMLTableElement>(null);
        const { t } = useTranslation();

        // Reset selectedRowIndex when table loses selection
        React.useEffect(() => {
            if (!selected) {
                setSelectedRowIndex(null);
            }
        }, [selected]);

        const features = React.useMemo((): TableFeatures | undefined => {
            if (!canEdit) return undefined;

            const nodeEntry = getNodeEntry(editor, element);
            const [table] = nodeEntry;

            return {
                onAfterMenuOpen() {
                    ReactEditor.deselect(editor);
                },
                deleteColumnByIndex(index) {
                    ReactEditor.focus(editor);
                    EditorOperations.Tables.deleteColumnByIndex(editor, nodeEntry, index);
                },
                deleteRowByIndex(index) {
                    ReactEditor.focus(editor);
                    const [table, tablePath] = nodeEntry;

                    // Get row directly from the table's children
                    if (table.children && index < table.children.length) {
                        const rowNode = table.children[index];
                        if (isTableRow(rowNode)) {
                            const rowPath = [...tablePath, index];
                            EditorOperations.Tables.deleteRow(editor, [rowNode, rowPath] as NodeEntry<TableRow>);
                        }
                    }
                },
                appendColumn() {
                    EditorOperations.Tables.appendColumn(editor, nodeEntry);
                },
                appendRow() {
                    EditorOperations.Tables.appendRow(editor, nodeEntry);
                },
                moveColumnLeft(index) {
                    if (index > 0) {
                        ReactEditor.focus(editor);
                        EditorOperations.Tables.moveColumn(editor, nodeEntry, index, index - 1);
                    }
                },
                moveColumnRight(index) {
                    if (index < table.dimensions[0] - 1) {
                        ReactEditor.focus(editor);
                        EditorOperations.Tables.moveColumn(editor, nodeEntry, index, index + 1);
                    }
                },
            };
        }, [editor, element, canEdit]);

        const context = React.useMemo(
            () => ({
                features,
                menuOpenForColumn,
                setMenuOpenForColumn,
                menuOpenForRow,
                setMenuOpenForRow,
                selectedRowIndex,
                setSelectedRowIndex,
            }),
            [features, menuOpenForColumn, menuOpenForRow, selectedRowIndex],
        );

        // Get row elements
        // Note: selectedRowIndex and children dependencies are necessary even though not directly used in the function.
        // They ensure the DOM query is refreshed when table content or selection changes, which is critical for button positioning.
        const rows = React.useMemo(() => {
            if (!tableRef.current) return [];
            return Array.from(tableRef.current.querySelectorAll('tr'));
        }, [tableRef.current, selectedRowIndex, children]); // eslint-disable-line react-hooks/exhaustive-deps

        return (
            <TableContext.Provider value={context}>
                <div className={classNames('w-full relative flex pb-4', { 'pr-4 sm:pr-0': selected })}>
                    <div data-testid="table" className="flex flex-1 overflow-x-auto pt-2">
                        {/* Row menu buttons positioned absolutely */}
                        {selectedRowIndex !== null && features && rows.length > 0 && (
                            <Popover.Root
                                onOpenChange={(open) => {
                                    if (open) {
                                        setMenuOpenForRow(selectedRowIndex);
                                    } else {
                                        setMenuOpenForRow(null);
                                    }
                                }}
                                open={menuOpenForRow === selectedRowIndex}
                            >
                                <div
                                    style={{
                                        position: 'absolute',
                                        left: -20,
                                        top:
                                            selectedRowIndex < rows.length
                                                ? rows[selectedRowIndex].offsetTop +
                                                  rows[selectedRowIndex].offsetHeight / 2 +
                                                  10
                                                : 0,
                                        transform: 'translateY(-50%)',
                                        zIndex: 999,
                                        pointerEvents: 'auto',
                                    }}
                                >
                                    <Popover.Trigger asChild>
                                        <div
                                            onMouseDown={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                            }}
                                            className="opacity-70 hover:opacity-100 transition-opacity cursor-pointer"
                                        >
                                            <Button.Plain>
                                                <div className="shadow-md bg-white dark:bg-zinc-600 rounded border dark:border-zinc-600 border-saga-gray-200">
                                                    <MoreVertical size={16} />
                                                    <span className="sr-only">
                                                        {t('common.open_context_menu_for_row', {
                                                            row: selectedRowIndex + 1,
                                                        })}
                                                    </span>
                                                </div>
                                            </Button.Plain>
                                        </div>
                                    </Popover.Trigger>
                                    <Popover.Content
                                        onOpenAutoFocus={(e) => e.preventDefault()}
                                        side="bottom"
                                        className="z-[1000]"
                                        sideOffset={5}
                                        align="start"
                                    >
                                        <WithExplicitFontType type="interface">
                                            <div className="p-1.5 overflow-hidden relative shadow-popupSmall max-w-highlight rounded-md bg-white dark:bg-saga-gray-900 dark:border dark:border-saga-gray-800 pointer-events-auto">
                                                <div className="flex flex-col w-full">
                                                    <Button.Plain
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            features.deleteRowByIndex(selectedRowIndex);
                                                            setMenuOpenForRow(null);
                                                        }}
                                                    >
                                                        <Button.BasePadding>
                                                            <span className="flex text-sm">
                                                                <Trash
                                                                    className="stroke-gray-dark mr-2 my-auto"
                                                                    size={14}
                                                                />
                                                                <span contentEditable={false}>
                                                                    {t('table.remove_row')}
                                                                </span>
                                                            </span>
                                                        </Button.BasePadding>
                                                    </Button.Plain>
                                                </div>
                                            </div>
                                        </WithExplicitFontType>
                                    </Popover.Content>
                                </div>
                            </Popover.Root>
                        )}
                        <table ref={tableRef} className="flex-1" id={element.id}>
                            <tbody className="w-full relative flex flex-col divide-y dark:divide-zinc-600 border dark:border-zinc-600 rounded">
                                {children}
                            </tbody>
                        </table>
                    </div>
                    {features && (
                        <>
                            <AppendButton
                                name={t('table.append_column')}
                                onMouseDown={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    features.appendColumn();
                                }}
                                isVisble={selected}
                                className="right-0 sm:-right-4 top-2 bottom-4 w-4"
                            />
                            <AppendButton
                                name={t('table.append_row')}
                                onMouseDown={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    features.appendRow();
                                }}
                                isVisble={selected}
                                className="bottom-0 right-4 sm:right-0 left-0 h-4"
                            />
                        </>
                    )}
                </div>
            </TableContext.Provider>
        );
    },
});

const tableRowPlugin = SagaEditor.Plugins.createBlockPlugin({
    match: isTableRow,
    Component({ element, children }) {
        const { setSelectedRowIndex } = useNonNullableTableContext();
        const editor = useSlate();
        const selected = useSelected();
        const nodeEntry = getNodeEntry(editor, element);
        const rowIndex = nodeEntry[1][nodeEntry[1].length - 1];

        // Update selectedRowIndex when this row is selected
        React.useEffect(() => {
            if (selected) {
                setSelectedRowIndex(rowIndex);
            }
        }, [selected, rowIndex, setSelectedRowIndex]);

        return (
            <tr id={element.id} className="flex-1 flex divide-x dark:divide-zinc-600">
                {children}
            </tr>
        );
    },
});

const tableCellPlugin = SagaEditor.Plugins.createBlockPlugin({
    match: isTableCell,

    Component({ element, children }) {
        const { features, menuOpenForColumn, setMenuOpenForColumn } = useNonNullableTableContext();
        const editor = useSlate();
        const selected = useSelected();
        const nodeEntry = getNodeEntry(editor, element);
        const column = EditorOperations.Tables.getColumnIndex(nodeEntry);
        const isWithinFirstRow = EditorOperations.Tables.isWithinFirstRow(nodeEntry);
        const columnSelected = EditorOperations.Tables.isColumnSelected(editor, nodeEntry, column);
        const menuOpen = menuOpenForColumn === column && isWithinFirstRow;
        const { t } = useTranslation();

        return (
            <td
                id={element.id}
                style={{ minWidth: 100 }}
                className={classNames('break-word px-1 py-0.5 flex-1 relative', {
                    'bg-saga-selection-blue/10 dark:bg-saga-selection-blue/10':
                        menuOpenForColumn === column || selected,
                })}
            >
                {children}
                {isWithinFirstRow && (columnSelected || menuOpen) && features && (
                    <Popover.Root
                        onOpenChange={(open) => {
                            if (open) {
                                setMenuOpenForColumn(column);
                            } else {
                                setMenuOpenForColumn(null);
                            }
                        }}
                        open={menuOpen}
                    >
                        <Popover.Trigger asChild>
                            <span
                                onMouseDown={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                }}
                                className="opacity-70 hover:opacity-100 transition-opacity z-[100] cursor-pointer absolute -top-2 h-4  left-0 right-0 flex justify-center items-center"
                            >
                                <div className="flex items-center justify-center bg-white dark:bg-zinc-600 rounded">
                                    <Button.Plain>
                                        <span className="h-full w-full flex justify-center rounded border dark:border-zinc-600 border-saga-gray-200">
                                            <MoreHorizontal size={16} />
                                            <span className="sr-only">
                                                {t('common.open_context_menu_for_column', { column: column + 1 })}
                                            </span>
                                        </span>
                                    </Button.Plain>
                                </div>
                            </span>
                        </Popover.Trigger>
                        <Popover.Content
                            onOpenAutoFocus={(e) => e.preventDefault()}
                            side="top"
                            className="z-[300]"
                            sideOffset={5}
                        >
                            <WithExplicitFontType type="interface">
                                <div className="p-1.5 overflow-hidden relative shadow-popupSmall max-w-highlight rounded-md bg-white dark:bg-saga-gray-900 dark:border dark:border-saga-gray-800 pointer-events-auto">
                                    <div className="flex flex-col w-full">
                                        {column > 0 && (
                                            <Button.Plain
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    features.moveColumnLeft(column);
                                                    setMenuOpenForColumn(null);
                                                }}
                                            >
                                                <Button.BasePadding>
                                                    <span className="flex text-sm">
                                                        <ArrowLeft
                                                            className="stroke-gray-dark mr-2 my-auto"
                                                            size={14}
                                                        />
                                                        <span contentEditable={false}>{t('table.move_left')}</span>
                                                    </span>
                                                </Button.BasePadding>
                                            </Button.Plain>
                                        )}
                                        {column <
                                            EditorOperations.Tables.getTableEntry(editor, nodeEntry)[0].dimensions[0] -
                                                1 && (
                                            <Button.Plain
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    features.moveColumnRight(column);
                                                    setMenuOpenForColumn(null);
                                                }}
                                            >
                                                <Button.BasePadding>
                                                    <span className="flex text-sm">
                                                        <ArrowRight
                                                            className="stroke-gray-dark mr-2 my-auto"
                                                            size={14}
                                                        />
                                                        <span contentEditable={false}>{t('table.move_right')}</span>
                                                    </span>
                                                </Button.BasePadding>
                                            </Button.Plain>
                                        )}
                                        <Button.Plain
                                            onClick={(e) => {
                                                e.preventDefault();
                                                EditorOperations.Tables.insertColumnBeforeTableCell(editor, nodeEntry);
                                                setMenuOpenForColumn(null);
                                            }}
                                        >
                                            <Button.BasePadding>
                                                <span className="flex text-sm">
                                                    <div className="mr-2 my-auto">
                                                        <FormatIcon type="table" />
                                                    </div>
                                                    <span contentEditable={false}>{t('table.insert_column_left')}</span>
                                                </span>
                                            </Button.BasePadding>
                                        </Button.Plain>
                                        <Button.Plain
                                            onClick={(e) => {
                                                e.preventDefault();
                                                EditorOperations.Tables.insertColumnAfterTableCell(editor, nodeEntry);
                                                setMenuOpenForColumn(null);
                                            }}
                                        >
                                            <Button.BasePadding>
                                                <span className="flex text-sm">
                                                    <div className="mr-2 my-auto">
                                                        <FormatIcon type="table" />
                                                    </div>
                                                    <span contentEditable={false}>
                                                        {t('table.insert_column_right')}
                                                    </span>
                                                </span>
                                            </Button.BasePadding>
                                        </Button.Plain>
                                        <Button.Plain
                                            onClick={(e) => {
                                                e.preventDefault();
                                                features.deleteColumnByIndex(column);
                                                setMenuOpenForColumn(null);
                                            }}
                                        >
                                            <Button.BasePadding>
                                                <span className="flex text-sm">
                                                    <Trash className="stroke-gray-dark mr-2 my-auto" size={14} />
                                                    <span contentEditable={false}>{t('table.remove_column')}</span>
                                                </span>
                                            </Button.BasePadding>
                                        </Button.Plain>
                                    </div>
                                </div>
                            </WithExplicitFontType>
                        </Popover.Content>
                    </Popover.Root>
                )}
            </td>
        );
    },
});

export const tablePlugins = SagaEditor.Plugins.combine(tablePlugin, tableRowPlugin, tableCellPlugin);
