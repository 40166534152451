import { unsafeRight } from '../io-ts-js';
import mustFindIndexByPageId from './mustFindIndexByPageId';
import removePinnedById from './removePinnedById';
import transactInSpace from './transactInSpace';
import { SafeSpace } from '../types';
import * as Y from 'yjs';

export default function deletePage(space: SafeSpace, id: string) {
    const yPages = unsafeRight(space.get('pages'));
    const pageIndex = mustFindIndexByPageId(space, id);
    transactInSpace(space, () => {
        const yPage = yPages.array.get(pageIndex) as Y.Map<unknown>;
        yPage.set('archivedAt', new Date().toISOString());
        removePinnedById(space, id);
    });
}
