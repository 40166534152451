import { SafeSpace } from '../types';
import transactInSpace from './transactInSpace';
import * as Y from 'yjs';

export default function addFavorite(space: SafeSpace, favoriteId: string, userId: string) {
    transactInSpace(space, () => {
        let userFavorites = space.map.get('userFavorites') as Y.Map<Y.Array<string>> | undefined;

        if (!userFavorites) {
            userFavorites = new Y.Map();
            space.map.set('userFavorites', userFavorites);
        }

        let favoritesArray = userFavorites.get(userId);

        if (!favoritesArray) {
            favoritesArray = new Y.Array();
            userFavorites.set(userId, favoritesArray);
        }

        if (!favoritesArray.toArray().includes(favoriteId)) {
            favoritesArray.unshift([favoriteId]);
        }
    });
}
