import { pageIsEmpty } from '../pageUtils';
import { SafeSpace, WeakBlocks, WeakPage } from '../types';
import deletePagePermanently from './deletePagePermanently';
import getPageById from './getPageById';
import transactInSpace from './transactInSpace';
import updatePartialPage from './updatePartialPage';

export default function deletePageIfEmpty(
    space: SafeSpace,
    pageWithBlocks: Pick<WeakPage, 'id'> & { blocks: WeakBlocks },
    afterDelete?: (page: WeakPage) => void,
) {
    transactInSpace(space, () => {
        const page = getPageById(space, pageWithBlocks.id);

        if (page == null) return;

        if (pageIsEmpty(pageWithBlocks.blocks)) {
            updatePartialPage(space, page.id, { archivedAt: new Date().toString() });
            deletePagePermanently(space, page.id);
            if (afterDelete) {
                afterDelete(page);
            }
        }
    });
}
