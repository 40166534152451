import {
    BlockBuilder,
    EditorOperations,
    Image,
    File as FileType,
    isLiveBlockSource,
    SafeSpace,
    SpaceOperations,
    SagaLocation,
    AnyBlockItem,
} from '@saga/shared';
import { Path, Transforms, Editor } from 'slate';
import * as Sentry from '@sentry/react';
import { dndTypes } from '@/constants';
import { BlockDroppable, DndBlockItem } from '@/types';
import i18n from 'i18next';
import { convertFileSize } from '@/utils';
import { BlocksLocation } from '../../../shared/src/SagaLocation';

function lastPath(editor: Editor) {
    return [editor.children.length - 1];
}

function isPathOutOfBounds(editor: Editor, path: Path) {
    return Path.isAfter(path, lastPath(editor));
}

function getImagesFromDataTransfer(dataTransfer: DataTransfer): DataTransferItem[] {
    return Array.from(dataTransfer.items).filter((item) => item.type.startsWith('image'));
}

function getFilesFromDataTransfer(dataTransfer: DataTransfer): DataTransferItem[] {
    return Array.from(dataTransfer.items).filter((item) => !item.type.startsWith('image'));
}

export function handleBlockDrop({
    editor: targetEditor,
    space,
    item,
    path,
    location,
    onUploadImage,
    onUploadFile,
    fileUploadLimit,
    onChangeBlocks,
}: {
    editor: Editor;
    space: SafeSpace;
    item: BlockDroppable;
    path: Path;
    location: SagaLocation.BlocksLocation;
    onUploadImage(params: { file: File; image: Image }): void;
    onUploadFile(params: { file: File; element: FileType }): void;
    fileUploadLimit: number;
    onChangeBlocks(
        space: SafeSpace,
        location: BlocksLocation,
        change: (editor: Editor, blocks: AnyBlockItem[]) => void,
    ): void;
}) {
    if ('dataTransfer' in item) {
        const images = getImagesFromDataTransfer(item.dataTransfer);
        const files = getFilesFromDataTransfer(item.dataTransfer);
        const imageBlocks: Image[] = [];
        const fileBlocks: FileType[] = [];

        images.forEach((imageItem) => {
            const file = imageItem.getAsFile();
            if (file) {
                if (file.size > fileUploadLimit) {
                    alert(
                        i18n.t('editor.file_upload.max_file_size_error', {
                            size: convertFileSize(fileUploadLimit),
                        }),
                    );
                    return;
                }
                const imageBlock = BlockBuilder.image('');
                imageBlocks.push(imageBlock);
                onUploadImage({ file, image: imageBlock });
            }
        });

        files.forEach((fileItem) => {
            const fileToUpload = fileItem.getAsFile();
            if (fileToUpload) {
                if (fileToUpload.size > fileUploadLimit) {
                    alert(
                        i18n.t('editor.file_upload.max_file_size_error', {
                            size: convertFileSize(fileUploadLimit),
                        }),
                    );
                    return;
                }
                const fileBlock = BlockBuilder.file('');
                fileBlocks.push(fileBlock);
                onUploadFile({ file: fileToUpload, element: fileBlock });
            }
        });

        onChangeBlocks(space, location, (editor) => {
            Transforms.insertNodes(editor, [...imageBlocks, ...fileBlocks], { at: path });
        });

        return;
    }

    if (item.type === dndTypes.BLOCK) {
        setTimeout(() => {
            if (!item.origin) return;
            // If we drag and drop on the same page, we want to use Slates Transforms.moveNodes,
            // because otherwise we create an insert_node and a delete_node operation in that order
            // since yjs does not support a move operation. The Transforms.moveNodes create a move_nodes operation
            // which makes it easier for us to keep track of the deletion of certain blocks

            if (SagaLocation.areLocationsEqual(item.origin, location)) {
                EditorOperations.Transforms.movePathWithShift(targetEditor, {
                    at: item.location,
                    to: path,
                });
            } else {
                try {
                    function move(item: DndBlockItem, originEditor: Editor) {
                        const nodes = Editor.fragment(originEditor, item.location);

                        const targetLocation = isPathOutOfBounds(targetEditor, path) ? lastPath(targetEditor) : path;

                        Transforms.insertNodes(targetEditor, nodes, {
                            at: targetLocation,
                        });

                        Transforms.unwrapNodes(targetEditor, {
                            at: targetLocation,
                            match: isLiveBlockSource,
                        });

                        Transforms.delete(originEditor, { at: item.location });
                    }

                    onChangeBlocks(space, item.origin, (originEditor) => {
                        move(item, originEditor);
                    });
                } catch (e) {
                    console.warn(e);
                    Sentry.captureException(e, { level: 'warning' });
                }
            }
        });
    } else if (item.type === dndTypes.SIDEBAR_PAGE) {
        setTimeout(() => {
            const page = SpaceOperations.getPageById(space, item.id);
            const title = page?.title;
            if (title && title !== '') {
                onChangeBlocks(space, location, (editor, blocks) => {
                    const inlinePageLink = SpaceOperations.createInlinePageLink(space, item.id, blocks);
                    Transforms.insertNodes(editor, [BlockBuilder.paragraph([inlinePageLink])], { at: path });
                });
            }
        });
    } else if (item.type === dndTypes.SIDEBAR_COLLECTION) {
        const inlineCollection = BlockBuilder.inlineCollection(item.id);

        onChangeBlocks(space, location, (editor) => {
            Transforms.insertNodes(editor, [BlockBuilder.paragraph([inlineCollection])], { at: path });
        });
    } else if (item.type === dndTypes.REFERENCE) {
        onChangeBlocks(space, item.origin, (targetPageEditor) => {
            const nodeEntry = EditorOperations.Selection.getNode(targetPageEditor, item.id);

            if (!nodeEntry) {
                throw new Error('nodeEntry null in Draggable');
            }

            const [, originPath] = nodeEntry;

            const liveBlock = SpaceOperations.createLiveBlock(space, {
                editor: targetPageEditor,
                at: originPath,
                origin: item.origin,
            });

            Transforms.insertNodes(targetEditor, liveBlock, { at: path });
            EditorOperations.Selection.safeSelectBlockById(targetEditor, liveBlock.id);
        });
    } else if (item.type === dndTypes.SIDEBAR_TASK) {
        onChangeBlocks(space, location, (editor) => {
            Transforms.insertNodes(editor, [BlockBuilder.taskBlock(item.id)], { at: path });
        });
    }
}
