import transactInSpace from './transactInSpace';
import { SafeSpace } from '../types';
import * as Y from 'yjs';

export default function moveCollection(
    space: SafeSpace,
    sourceId: string,
    { targetId, position }: { targetId: string; position: 'before' | 'after' },
) {
    const yCollections = space.map.get('collections') as Y.Array<Y.Map<unknown>>;

    transactInSpace(space, () => {
        const collections = yCollections.toArray();

        const set = new Set(collections);
        const dedupedArray = Array.from(set);

        if (dedupedArray.length !== collections.length) {
            yCollections.delete(0, collections.length);
            yCollections.insert(0, dedupedArray);
        }

        const fromIndex = collections.findIndex((collection) => {
            const id = collection.get('id');
            return id === sourceId;
        });

        if (fromIndex === -1) {
            return;
        }

        const toIndex = collections.findIndex((collection) => {
            const id = collection.get('id');
            return id === targetId;
        });

        if (toIndex === -1) {
            return;
        }

        // Get all the collections data
        const collectionsData = collections.map((c) => c.toJSON());

        // Calculate the target position before any modifications
        // When moving an item from above the target to below the target,
        // we need to adjust for the shifting that will happen
        let targetIndex: number;

        if (position === 'before') {
            targetIndex = toIndex > fromIndex ? toIndex - 1 : toIndex;
        } else {
            // 'after'
            targetIndex = toIndex >= fromIndex ? toIndex : toIndex + 1;
        }

        // Remove the item from the array at the source position
        const sourceData = collectionsData.splice(fromIndex, 1)[0];

        // Insert the item at the calculated target position
        collectionsData.splice(targetIndex, 0, sourceData);

        // Replace the entire collections array with the new order
        yCollections.delete(0, yCollections.length);

        // Create new Y.Maps for each collection
        const newCollections = collectionsData.map((data) => {
            const map = new Y.Map();
            Object.entries(data).forEach(([key, value]) => {
                map.set(key, value);
            });
            return map;
        });

        // Insert all the collections back
        yCollections.insert(0, newCollections);
    });
}
