import React, { useCallback, useEffect, useState } from 'react';
import { useWorkspaceContext } from '@/components/WorkspaceContext';
import * as t from 'io-ts';
import { sortingD, taskFiltersD, pageFiltersD, multiSelectD } from '@saga/shared';
import { getStoredOrDefault } from '@/utils/localStorageUtils';

const decoder = t.intersection([
    t.type({
        openBlocks: t.array(t.string),
        favoritesSorting: t.union([sortingD, t.null]),
        allCollectionsSorting: t.union([sortingD, t.null]),
        allCollectionsCollections: multiSelectD,
        taskContentMode: t.union([t.literal('list'), t.literal('board'), t.null]),
        defaultExpandedCollection: t.array(
            t.type({
                id: t.string,
                expanded: t.boolean,
            }),
        ),
    }),
    taskFiltersD,
    pageFiltersD,
]);

type WorkspaceSettings = t.TypeOf<typeof decoder>;

export const DEFAULT_SETTINGS: WorkspaceSettings = {
    openBlocks: [],
    defaultExpandedCollection: [],
    favoritesSorting: null,
    allCollectionsSorting: null,
    allPagesSorting: null,
    allTasksSorting: null,
    allTasksMode: null,
    allTasksAssignee: null,
    allTasksPriority: null,
    allTasksLabels: null,
    allTasksCollections: null,
    allPagesCollections: null,
    allTasksCreators: null,
    allPagesCreators: null,
    allCollectionsCollections: null,
    taskContentMode: null,
};

const LOCAL_STORAGE_PREFIX = 'workspace-settings';
const buildStorageKey = (urlKey: string) => `${LOCAL_STORAGE_PREFIX}:${urlKey}`;

function storeSettings(urlKey: string, settings: WorkspaceSettings) {
    localStorage.setItem(buildStorageKey(urlKey), JSON.stringify(settings));
}

function removeSettings(urlKey: string) {
    localStorage.removeItem(buildStorageKey(urlKey));
}

const WorkspaceSettingsContext = React.createContext<WorkspaceSettings>(DEFAULT_SETTINGS);
const UpdateWorkspaceSettingsContext = React.createContext<(newSettings: Partial<WorkspaceSettings>) => void>(() => {});
const ClearWorkspaceSettingsContext = React.createContext(() => {});

export function WorkspaceSettingsProvider({ children }: { children: React.ReactNode }) {
    const { currentUrlKey } = useWorkspaceContext();

    const [settings, setSettings] = useState<WorkspaceSettings>(DEFAULT_SETTINGS);

    useEffect(() => {
        setSettings(getStoredOrDefault(buildStorageKey(currentUrlKey), decoder.decode, DEFAULT_SETTINGS));
    }, [currentUrlKey]);

    const updateSettings = useCallback(
        (newSettings: Partial<WorkspaceSettings>) => {
            setSettings((settings) => {
                const nextSettings = { ...settings, ...newSettings };
                storeSettings(currentUrlKey, nextSettings);
                return nextSettings;
            });
        },
        [currentUrlKey],
    );

    const clearSettings = useCallback(() => removeSettings(currentUrlKey), [currentUrlKey]);

    return (
        <WorkspaceSettingsContext.Provider value={settings}>
            <UpdateWorkspaceSettingsContext.Provider value={updateSettings}>
                <ClearWorkspaceSettingsContext.Provider value={clearSettings}>
                    {children}
                </ClearWorkspaceSettingsContext.Provider>
            </UpdateWorkspaceSettingsContext.Provider>
        </WorkspaceSettingsContext.Provider>
    );
}

const useWorkspaceSettings = (): [WorkspaceSettings, (newSettings: Partial<WorkspaceSettings>) => void, () => void] => {
    const settings = React.useContext(WorkspaceSettingsContext);
    const updateSettings = React.useContext(UpdateWorkspaceSettingsContext);
    const clearSettings = React.useContext(ClearWorkspaceSettingsContext);
    return [settings, updateSettings, clearSettings];
};

export default useWorkspaceSettings;
