import MemberAvatar from '@/components/MemberAvatar';
import { useUserContext } from '@/components/UserContext';
import { AVAILABLE_COLORS } from '@/constants';
import { getPromptText } from '@/utils/aiUtils';
import { AIMessage, Converter, SagaEditor, SagaElement, SagaLocation } from '@saga/shared';
import { stringToColor } from '@/../../shared/src/utils/Colors';
import React from 'react';
import Tooltip from '@/components/popover/Tooltip';
import { FileText } from 'react-feather';
import classNames from 'classnames';
import AICopyButton from '@/components/editor/ai/AICopyButton';
import { useTranslation } from 'react-i18next';
import { track } from '@/analytics';
import { useCurrentWorkspace } from '@/components/WorkspaceContext';
import { useBlockPlugins } from '@/components/BlockPluginProvider';
import StaticEditor from '@/components/editor/StaticEditor';

type CardProps = {
    message: AIMessage;
    references: string[];
};

function useOnScreen(ref: React.RefObject<HTMLElement>) {
    const [isIntersecting, setIntersecting] = React.useState(true);
    const observerRef = React.useRef<IntersectionObserver>();

    React.useEffect(() => {
        observerRef.current = new IntersectionObserver(([entry]) => {
            entry.rootBounds?.height && setIntersecting(entry.isIntersecting);
        });
    }, [ref]);

    React.useEffect(() => {
        ref.current && observerRef.current?.observe(ref.current);
        return () => observerRef.current?.disconnect();
    }, [ref]);

    return isIntersecting;
}

function AIChatMessageCard({ message, references }: CardProps) {
    const incoming = message.role !== 'user';

    const content = !incoming ? `<text>${getPromptText(message)}</text>` : message.content;
    const { user } = useUserContext();
    const { t } = useTranslation();
    const { urlKey } = useCurrentWorkspace();
    const blockPlugins = useBlockPlugins();

    const userFullname = user?.data ? `${user.data.firstName} ${user.data.lastName}` : '';
    const name = incoming ? 'Saga AI' : userFullname;

    const ref = React.useRef<HTMLDivElement>(null);
    const visible = useOnScreen(ref);

    const expectedHeight = React.useRef<number>(60);

    const [blocks, setBlocks] = React.useState<SagaElement[]>([]);

    React.useEffect(() => {
        if (content) {
            const elements = Converter.xmlToSaga(content ?? '') as SagaElement[];
            if (elements) {
                setBlocks(elements.map((el) => (el.type === 'image' ? { ...el, size: undefined } : el)));
            }
        }
    }, [content]);

    React.useEffect(() => {
        if (visible && ref.current) {
            // memoize last known height to use it later in the dummy component
            expectedHeight.current = ref.current.getBoundingClientRect().height;
        }
    }, [visible, ref]);

    const onCopy = React.useCallback(
        (blocks: SagaElement[] | null) => {
            track('ai-chat-copy-button');
            const copyListener = (event: ClipboardEvent) => {
                SagaEditor.Clipboard.copyBlocks(blocks, {
                    location: SagaLocation.pageLocationFromId('AIChat'),
                    spaceUrlKey: urlKey,
                    blockPlugins,
                    event,
                });
            };

            //@ts-ignore
            document.addEventListener('copy', copyListener);
            document.execCommand('copy');
            //@ts-ignore
            document.removeEventListener('copy', copyListener);
        },
        [blockPlugins, urlKey],
    );

    const renderHeader = React.useCallback(() => {
        return (
            <div className="flex flex-row items-center space-x-1">
                <MemberAvatar
                    name={name}
                    color={incoming ? '#101010' : stringToColor(user?.id ?? '', AVAILABLE_COLORS)}
                    size="lg"
                    showBorder={true}
                />
                <div className="flex-1">{name}</div>

                <div className="hidden group-hover:flex items-center space-x-1">
                    {references.length ? (
                        <Tooltip
                            zIndex={100}
                            content={
                                <div className="flex flex-col space-y-1">
                                    {references.map((source, index) => (
                                        <div key={index}>{source}</div>
                                    ))}
                                </div>
                            }
                            placement="top"
                        >
                            <FileText className="text-saga-gray-500" size={14} />
                        </Tooltip>
                    ) : null}

                    <AICopyButton
                        onCopy={() => content && onCopy(Converter.xmlToSaga(content) as SagaElement[])}
                        text={{ before: t('common.copy_text'), after: t('common.text_copied') }}
                        id="AI-answer-copy-button"
                    />
                </div>
            </div>
        );
    }, [name, user, incoming, references, content, onCopy, t]);

    return (
        <div data-testid="ai-chat-card" ref={ref} className={classNames('relative group')}>
            {blocks.length && visible ? (
                <>
                    {renderHeader()}
                    <StaticEditor
                        blocks={blocks}
                        location={SagaLocation.pageLocationFromId('AIChat')}
                        blockPlugins={blockPlugins}
                    />
                </>
            ) : (
                <div style={{ height: expectedHeight.current }} />
            )}
        </div>
    );
}

export default AIChatMessageCard;
