import { track } from '@/analytics';
import isHotkey from 'is-hotkey';
import React, { useState, useContext, SetStateAction, Dispatch } from 'react';

const OPEN_CHAT = 'mod+l';

type ChatOpenContextType = {
    chatOpen: boolean;
    setChatOpen: Dispatch<SetStateAction<boolean>>;
};

const ChatOpenContext = React.createContext<ChatOpenContextType>({ chatOpen: false, setChatOpen: () => {} });
export const useAIChatOpen = () => useContext(ChatOpenContext);

export const AIChatContextProvider: React.FC = ({ children }) => {
    const [chatOpen, setChatOpen] = useState<boolean>(false);
    const deps = React.useMemo(
        () => ({
            setChatOpen,
            chatOpen,
        }),
        [chatOpen, setChatOpen],
    );

    React.useEffect(() => {
        const shortcutOpen = (event: KeyboardEvent) => {
            if (isHotkey(OPEN_CHAT, event)) {
                event.preventDefault();
                event.stopImmediatePropagation();
                track('ai-chat-open', { source: 'shortcut-mod+l' });
                setChatOpen((isOpen) => !isOpen);
            }
        };
        document.body.addEventListener('keydown', shortcutOpen);
        return () => {
            document.body.removeEventListener('keydown', shortcutOpen);
        };
    }, []);

    return <ChatOpenContext.Provider value={deps}>{children}</ChatOpenContext.Provider>;
};
