import React from 'react';
import Onboarding from '@/components/onboarding/Onboarding';
import OnboardingScreen from '@/components/onboarding/OnboardingScreen';
import Spinner from '@/components/loading/Spinner';
import { DESKTOP_APP_PROTOCOL } from '@/constants';
import { isSignInWithEmailLink } from '@firebase/auth';
import { auth, signInWithGoogleProvider } from '@/firebase';
import { GoogleAuthProvider } from 'firebase/auth';
import { useTranslation } from 'react-i18next';
import { useDesktopContext } from './DesktopContext';
import { handleDesktopLinkOpen } from '@/utils';

type LoginType = 'email' | 'google';

const DesktopEmail = () => {
    const { t } = useTranslation();
    const desktopDeepLink = (() => {
        if (isSignInWithEmailLink(auth, window.location.href)) {
            const search = window.location.search;

            const deepLink = `${DESKTOP_APP_PROTOCOL}://login${search}`;

            return deepLink;
        } else {
            return null;
        }
    })();

    return (
        <OnboardingScreen>
            <div className="w-full max-w-sm md:max-w-2xl">
                <Onboarding>
                    <Onboarding.MainContainer>
                        <div className="text-xl text-center space-y-5">
                            <Onboarding.Body>
                                <Onboarding.Logo />
                                <p className="text-xl md:text-xl font-regular pt-2">{`Signing in...`}</p>

                                <div className="py-4">
                                    {desktopDeepLink ? (
                                        <a href={desktopDeepLink}>
                                            <Onboarding.Button disabled={false}>
                                                {t('common.open_saga')}
                                            </Onboarding.Button>
                                        </a>
                                    ) : (
                                        <p className="whitespace-pre-line">
                                            {t('errors.something_went_wrong_try_again')}
                                        </p>
                                    )}
                                </div>
                            </Onboarding.Body>
                        </div>
                    </Onboarding.MainContainer>
                </Onboarding>
            </div>
        </OnboardingScreen>
    );
};

const DesktopGoogle = () => {
    const [state, setState] = React.useState<
        { kind: 'done'; deepLink: string } | { kind: 'login' } | { kind: 'loading' }
    >({ kind: 'login' });
    const { t } = useTranslation();

    const onSignInWithGoogle = () => {
        signInWithGoogleProvider().then((result) => {
            const credential = GoogleAuthProvider.credentialFromResult(result);
            if (credential?.idToken != null) {
                const oauthIdToken = credential.idToken;
                const search = new URLSearchParams({ oauthIdToken }).toString();
                const deepLink = `${DESKTOP_APP_PROTOCOL}://login?${search}`;
                setState({ kind: 'done', deepLink: deepLink });
            } else {
                setState({ kind: 'login' });
            }
        });
    };

    return (
        <OnboardingScreen>
            <div className="w-full max-w-sm md:max-w-2xl">
                <Onboarding>
                    <Onboarding.MainContainer>
                        <div className="text-xl text-center space-y-5">
                            <Onboarding.Body>
                                <Onboarding.Logo />
                                <p className="text-xl md:text-xl font-regular pt-2">{`Signing in...`}</p>
                                <div className="py-4">
                                    {state.kind === 'login' && (
                                        <Onboarding.GoogleButton onClick={onSignInWithGoogle}>
                                            Continue with Google
                                        </Onboarding.GoogleButton>
                                    )}
                                    {state.kind === 'done' && (
                                        <div>
                                            <a href={state.deepLink}>
                                                <Onboarding.Button disabled={false}>
                                                    {t('common.open_saga')}
                                                </Onboarding.Button>
                                            </a>
                                        </div>
                                    )}
                                    {state.kind === 'loading' && (
                                        <div className="w-full flex justify-center items-center h-12">
                                            <Spinner />
                                        </div>
                                    )}
                                </div>
                            </Onboarding.Body>
                        </div>
                    </Onboarding.MainContainer>
                </Onboarding>
            </div>
        </OnboardingScreen>
    );
};

const Desktop = ({ loginType }: { loginType: LoginType }) => {
    switch (loginType) {
        case 'email':
            return <DesktopEmail />;

        case 'google':
            return <DesktopGoogle />;
    }
};

export default Desktop;

interface TodesktopLinkProps {
    path: string;
    className?: string;
    children?: React.ReactNode;
}

export const TodesktopLink: React.FC<TodesktopLinkProps> = ({ path, className, children }: TodesktopLinkProps) => {
    const { isDesktop } = useDesktopContext();

    return isDesktop ? (
        <a
            className={className}
            onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handleDesktopLinkOpen(path);
            }}
        >
            {children}
        </a>
    ) : (
        <a href={path} target="_blank" rel="noreferrer noopener" className={className}>
            {children}
        </a>
    );
};
