import { SafeSpace, WeakDocumentContent } from '../types';
import getPageMapById from './getPageMapById';
import { assertNonNull } from '../utils';
import * as Y from 'yjs';
import { Node } from 'slate';
import { ymapFromRecord } from '../yjs-utils';
import { v4 as uuid } from 'uuid';
import { mapBlockToSharedType } from '../pageUtils';
import { BlocksLocation } from '../SagaLocation';
import findTaskMap from './findTaskMap';

export default function applyTemplateBlocksToLocation(
    space: SafeSpace,
    {
        templateBlocks,
        location,
        targetYBlocks,
    }: { templateBlocks: WeakDocumentContent['blocks']; location: BlocksLocation; targetYBlocks: Y.Array<any> },
) {
    switch (location.type) {
        case 'page': {
            const targetPageMap = getPageMapById(space, location.pageId);
            assertNonNull(
                targetPageMap,
                `applyTemplateBlocksToLocation: PageMap for target with id ${location.pageId} not found`,
            );

            if (!(targetYBlocks instanceof Y.Array)) {
                throw new Error('yBlocks must be instance of Y.Array');
            }

            targetYBlocks.doc?.transact(() => {
                const firstBlock = targetYBlocks.get(0);
                let isTitleEmpty = false;
                if (firstBlock instanceof Y.Map) {
                    isTitleEmpty = Node.string(firstBlock.toJSON() as any).trim() === '';
                }

                if (isTitleEmpty) {
                    targetYBlocks.delete(0, targetYBlocks.length);
                } else {
                    targetYBlocks.delete(1, targetYBlocks.length - 1);
                }

                const blocksToAdd: Y.Map<unknown>[] = [];
                const blocks = isTitleEmpty ? templateBlocks : templateBlocks.slice(1);

                blocks.forEach((targetBlock) => {
                    const block = ymapFromRecord(targetBlock, new Y.Map(), (key, value) => {
                        if (key === 'id') {
                            return uuid();
                        }

                        return mapBlockToSharedType(key, value);
                    });

                    blocksToAdd.push(block);
                });

                targetYBlocks.push(blocksToAdd);
            });

            break;
        }
        case 'task': {
            const targetTaskMap = findTaskMap(space, location.taskId);

            assertNonNull(
                targetTaskMap,
                `applyTemplateBlocksToLocation: TaskMap for target with id ${location.taskId} not found`,
            );

            if (!(targetYBlocks instanceof Y.Array)) {
                throw new Error('yBlocks must be instance of Y.Array');
            }

            targetYBlocks.doc?.transact(() => {
                const firstBlock = targetYBlocks.get(0);
                let isTitleEmpty = false;
                if (firstBlock instanceof Y.Map) {
                    isTitleEmpty = Node.string(firstBlock.toJSON() as any).trim() === '';
                }

                if (isTitleEmpty) {
                    targetYBlocks.delete(0, targetYBlocks.length);
                } else {
                    targetYBlocks.delete(1, targetYBlocks.length - 1);
                }

                const blocksToAdd: Y.Map<unknown>[] = [];
                const blocks = isTitleEmpty ? templateBlocks : templateBlocks.slice(1);
                blocks.forEach((targetBlock) => {
                    const block = ymapFromRecord(targetBlock, new Y.Map(), (key, value) => {
                        if (key === 'id') {
                            return uuid();
                        }

                        return mapBlockToSharedType(key, value);
                    });

                    blocksToAdd.push(block);
                });

                targetYBlocks.push(blocksToAdd);
            });

            break;
        }
    }
}
