import React from 'react';

function Card({ children, className }: { children: React.ReactNode; className?: string }) {
    return <div className={`rounded border border-saga-gray-200 dark:border-zinc-600 ${className}`}>{children}</div>;
}

Card.WithShadow = function CardWithShadow({ children }: { children: React.ReactNode }) {
    return <div className="rounded border shadow-popupSmall border-saga-gray-200 dark:border-zinc-600">{children}</div>;
};

Card.Background = function CardBackground({ children }: { children: React.ReactNode }) {
    return <div className="bg-white dark:bg-zinc-700 rounded">{children}</div>;
};

export default Card;
