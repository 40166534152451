import React from 'react';
import { SagaEditor, isBlockType, isInline, isTableCell, isTableRow } from '@saga/shared';
import { RenderElementProps } from 'slate-react';
import ElementContainer from './ElementContainer';
import Placeholder from './popover/Placeholder';

export default function BaseRenderElement(props: RenderElementProps) {
    const { showPlaceholder, fullWidth } = SagaEditor.useEditorContext();

    const { element } = props;

    if (isBlockType(element, [isTableCell, isTableRow, isInline])) {
        return <SagaEditor.RenderElement {...props} />;
    }

    return (
        <ElementContainer fullWidth={fullWidth}>
            <SagaEditor.RenderElement {...props}>
                {showPlaceholder && <Placeholder element={props.element} />}
                {props.children}
            </SagaEditor.RenderElement>
        </ElementContainer>
    );
}
