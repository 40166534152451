import React from 'react';
import { PopOver } from '@/components/popover/PopOver';
import { Trash, Download, Delete } from 'react-feather';
import { track } from '@/analytics';
import Tooltip from '@/components/popover/Tooltip';
import ContextMenuButton from '@/components/styled/ContextMenuButton';
import { DuplicatePageButton, OpenSideBySideButton } from './PageContextMenu';
import { SagaLocation } from '@/../../shared/src';
import { useFavoriteButton } from '@/hooks/useFavoriteButton';
import {
    AddToFavoritePageButton,
    RemoveFromFavoriteButton,
    RemoveFromPinnedButton,
    AddToPinnedPageButton,
} from './common';
import * as Popover from '@radix-ui/react-popover';
import { useWorkspaces } from '../WorkspacesContext';
import { useTranslation } from 'react-i18next';
import MoveEntityButton from './MoveEntityButton';
import { DuplicateTaskButton } from './TaskContextMenuButton';
import { usePinnedButton } from '@/hooks/usePinnedButton';

function DocumentInCollectionContextMenuButton({
    onRemove,
    onDelete,
    onExport,
    className,
    align,
    isButtonSmall,
    documentId,
    isPageAdmin = false,
    entityType,
}: Readonly<{
    onRemove: () => void;
    onDelete: () => void;
    onExport: () => void;
    className?: string;
    align?: 'start' | 'end' | 'center';
    isButtonSmall?: boolean;
    documentId: string;
    isPageAdmin: boolean;
    entityType: 'page' | 'task';
}>) {
    const [isOpen, setIsOpen] = React.useState(false);

    const { isFavorite, toggleFavorite } = useFavoriteButton(documentId);
    const { isPinned, togglePinned } = usePinnedButton(documentId);
    const { workspaces } = useWorkspaces();
    const { t } = useTranslation();
    return (
        <div className={'flex items-center ' + className}>
            <Popover.Root
                onOpenChange={(isOpen) => {
                    if (isOpen) track('open-context-menu');
                    else track('close-context-menu');
                    setIsOpen(isOpen);
                }}
                open={isOpen}
            >
                <Popover.Trigger asChild>
                    <ContextMenuButton isButtonSmall={Boolean(isButtonSmall)} />
                </Popover.Trigger>
                <Popover.Portal>
                    <Popover.Content align={align} onOpenAutoFocus={(e) => e.preventDefault()}>
                        <PopOver.PaddedCard>
                            <div data-testid="context-menu" className="text-sm">
                                <PopOver.RoundedButton onClick={onRemove}>
                                    <Delete className="stroke-gray-dark mr-2 my-auto" size={14} />
                                    Remove from Collection
                                </PopOver.RoundedButton>

                                <div className="py-0.5">
                                    <PopOver.Divider />
                                </div>

                                <OpenSideBySideButton
                                    location={
                                        entityType === 'page'
                                            ? SagaLocation.pageLocationFromId(documentId)
                                            : SagaLocation.taskLocationFromId(documentId)
                                    }
                                    onAfterClick={() => {}}
                                />
                                {!isFavorite ? (
                                    <AddToFavoritePageButton
                                        onAfterClick={() => setIsOpen(false)}
                                        onClick={toggleFavorite}
                                    />
                                ) : (
                                    <RemoveFromFavoriteButton
                                        onAfterClick={() => setIsOpen(false)}
                                        onClick={toggleFavorite}
                                    />
                                )}
                                {!isPinned ? (
                                    <AddToPinnedPageButton
                                        onAfterClick={() => setIsOpen(false)}
                                        onClick={togglePinned}
                                    />
                                ) : (
                                    <RemoveFromPinnedButton
                                        onAfterClick={() => setIsOpen(false)}
                                        onClick={togglePinned}
                                    />
                                )}

                                {entityType === 'page' && <DuplicatePageButton pageId={documentId} />}
                                {entityType === 'task' && <DuplicateTaskButton taskId={documentId} />}

                                <MoveEntityButton
                                    disabled={workspaces.length === 1}
                                    entityId={documentId}
                                    entityType={entityType}
                                    onAfterClick={() => {}}
                                />

                                <Tooltip content={'Export as Markdown'} placement="right">
                                    <PopOver.RoundedButton
                                        aria-label={entityType === 'page' ? 'Export Page' : 'Export Task'}
                                        onClick={() => {
                                            onExport();
                                        }}
                                    >
                                        <Download className="stroke-gray-dark mr-2 my-auto" size={14} />
                                        {t('common.export')}
                                    </PopOver.RoundedButton>
                                </Tooltip>

                                {isPageAdmin && (
                                    <div className="pt-0.5 space-y-0.5">
                                        <PopOver.Divider />
                                        <PopOver.RoundedButton
                                            onClick={() => onDelete()}
                                            aria-label={entityType === 'page' ? 'Delete Page' : 'Delete Task'}
                                        >
                                            <Trash className="stroke-gray-dark mr-2 my-auto" size={14} />
                                            {t('common.delete')}
                                        </PopOver.RoundedButton>
                                    </div>
                                )}
                            </div>
                        </PopOver.PaddedCard>
                    </Popover.Content>
                </Popover.Portal>
            </Popover.Root>
        </div>
    );
}

export default DocumentInCollectionContextMenuButton;
