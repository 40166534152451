import { track } from '@/analytics';
import useMobile from '@/hooks/useMobile';
import { useSpaceAccess } from '@/hooks/useSpaceAccess';
import * as Popover from '@radix-ui/react-popover';
import classNames from 'classnames';
import React, { forwardRef, MouseEventHandler, useState } from 'react';
import { File, Plus } from 'react-feather';
import { useTranslation } from 'react-i18next';
import Tooltip from '../popover/Tooltip';
import { useCreatePage } from '@/hooks/SpaceHooks';

type OpenPagesTableFunction = MouseEventHandler<HTMLDivElement>;

interface PagesProps {
    openPagesTable: OpenPagesTableFunction;
}

function Pages({ openPagesTable }: PagesProps) {
    const { t } = useTranslation();

    const [isOpen, setIsOpen] = useState(false);
    const isMobile = useMobile();

    const { canEdit } = useSpaceAccess();
    const createPage = useCreatePage();

    const handleCreatePage = () => {
        track('create-page', { source: 'sidebar-button' });
        createPage({}, { redirect: true });
    };

    return (
        <Pages.HoverGroup onClick={openPagesTable}>
            <Popover.Root open={isOpen} onOpenChange={setIsOpen}>
                <div className="relative flex items-center space-x-2">
                    <>
                        <div className="w-full flex items-center space-x-2 px-2 py-1.5">
                            <File className="flex-none w-3.5 h-3.5 shrink-0 mr-0.5" />
                            <div data-testid="open-pages-table" className="min-w-0 font-medium text-sm">
                                {t('sidebar.pages')}
                            </div>
                        </div>
                        {canEdit && !isMobile && (
                            <Popover.Trigger asChild>
                                <Pages.ShowOnHover>
                                    <div
                                        className="absolute z-20 right-0 top-0 bottom-0 flex items-center px-1"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setIsOpen(true);
                                        }}
                                    >
                                        <Tooltip placement="right" content={t('sidebar.create_page')}>
                                            <div className="h-6 px-1 bg-white dark:bg-saga-gray-1000 flex flex-row cursor-pointer items-center text-xs leading-normal border-saga-gray-200 dark:border-zinc-500 border-2 font-semibold focus:outline-none active:shadow-xs rounded">
                                                <Plus className="flex-none" size={12} onClick={handleCreatePage} />
                                            </div>
                                        </Tooltip>
                                    </div>
                                </Pages.ShowOnHover>
                            </Popover.Trigger>
                        )}
                    </>
                </div>
            </Popover.Root>
        </Pages.HoverGroup>
    );
}

Pages.HoverGroup = function PagesHoverGroup({
    children,
    onClick,
}: {
    children: React.ReactNode;
    onClick: OpenPagesTableFunction;
}) {
    return (
        <div className="group" onClick={onClick}>
            {children}
        </div>
    );
};

Pages.ShowOnHover = forwardRef<
    HTMLDivElement,
    {
        children: React.ReactNode;
        forceVisible?: boolean;
    }
>(function PagesShowOnHover({ children, forceVisible }, ref) {
    return (
        <div
            ref={ref}
            className={classNames({
                'opacity-0 group-hover:opacity-100 pointer-events-none group-hover:pointer-events-auto': !forceVisible,
            })}
        >
            {children}
        </div>
    );
});

export default Pages;
