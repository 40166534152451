import transactInSpace from './transactInSpace';
import { SafeSpace } from '../types';
import * as Y from 'yjs';
import { assertYArray, findYArrayIndexOrThrow } from '../yjs-utils';

export default function deleteTaskLabel(space: SafeSpace, id: string) {
    const labels = space.map.get('taskLabels');

    assertYArray(labels);

    const index = findYArrayIndexOrThrow(
        labels as Y.Array<Y.Map<unknown>>,
        (map: Y.Map<unknown>) => map.get('id') === id,
    );

    transactInSpace(space, () => {
        labels.delete(index);
    });
}
