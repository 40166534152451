import { track } from '@/analytics';
import { useDesktopContext } from '@/components/DesktopContext';
import { CollectionIcon, PageIcon } from '@/components/icons';
import { SearchBar as HeaderSearchBar } from '@/components/navigation/HeaderBar';
import AddToCollectionButton from '@/components/popover/AddToCollectionButton';
import ManageCollectionsInput from '@/components/popover/ManageCollectionsInput';
import DocumentInCollectionContextMenuButton from '@/components/popover/DocumentInCollectionContextMenuButton';
import { useSideBySideIndex, useSideBySideState } from '@/components/SideBySide';
import { useSpace } from '@/components/SpaceProvider';
import CollectionTitle from '@/components/table/CollectionTableTitle';
import SortableTableButton from '@/components/table/SortableTableButton';
import Table from '@/components/table/Table';
import { TableContext, useTableContext } from '@/components/table/TableContext';
import SearchBar from '@/components/table/TableSearchBar';
import SortButton, { fieldsToSortOptions } from '@/components/table/TableSortButton';
import { useCollectionsSnapshot, usePartialPages, useSidebarTasks } from '@/hooks/SpaceHooks';
import { useFavoriteButton } from '@/hooks/useFavoriteButton';
import useInterfaceSettings, { CollectionTableViewMode } from '@/hooks/useInterfaceSettings';
import useMobile from '@/hooks/useMobile';
import { useSpaceAccess } from '@/hooks/useSpaceAccess';
import type { TableColumn } from '@/types';
import { formatDateOptimized } from '@/utils/dateUtils';
import * as Select from '@radix-ui/react-select';
import { Collection, newBlankPage, removeNullable, Sorting, SpaceOperations, WeakPage, WeakTask } from '@saga/shared';
import classNames from 'classnames';
import { escapeRegExp } from 'lodash';
import * as R from 'ramda';
import React, { useCallback, useState } from 'react';
import { CornerLeftDown, Folder, Tag } from 'react-feather';
import { Redirect } from 'react-router-dom';
import DesktopNavigationButtons from '../DesktopNavigationButtons';
import { useOpenCollection, useOpenPage, useOpenTask } from '../PageNavigationProvider';
import CollectionContextMenuButton from '../popover/CollectionContextMenuButton';
import CollectionInCollectionContextMenuButton from '../popover/CollectionInCollectionContextMenuButton';
import Button from '../styled/Button';
import FavoriteButton from '../styled/FavoriteButton';
import { useCurrentWorkspace } from '../WorkspaceContext';
import NavigationTable from './NavigationTable';
import { useTranslation } from 'react-i18next';
import { useUserContext } from '../UserContext';

import { useDeleteCollectionIfEmpty } from '@/hooks/deleteCollectionHooks';
import ActionBar from './ActionBar';
import { createPage } from '@/utils/documentUtils';
import { usePageAccess } from '@/components/PagesPermissionsBySpaceProvider';
import useExport from '@/hooks/useExport';
import { useArchivePage } from '@/hooks/deletePageHooks';
import TaskStatusSelect from '../tasks/TaskStatusSelect';
import { useArchiveTask } from '@/hooks/deleteTaskHooks';
import CollectionsFilterSelect from './CollectionsFilterSelect';
import { ItemsFilterMode } from './TableMultipleFilterSelect';
import useWorkspaceSettings from '@/hooks/useWorkspaceSettings';

const columns: Record<'title' | 'created' | 'subCollections' | 'updated', TableColumn> = {
    title: {
        asc: 'filters.az',
        desc: 'filters.za',
        label: 'filters.title',
        type: 'title',
        value: 'title',
    },
    created: {
        asc: 'collections.oldest_first',
        desc: 'collections.newest_first',
        label: 'filters.created',
        type: 'created',
        value: 'created',
    },
    subCollections: {
        asc: 'collections.sub_fewest_first',
        desc: 'collections.sub_most_first',
        label: 'collections.sub_collections',
        type: 'subCollections',
        value: 'subCollections',
    },
    updated: {
        asc: 'collections.oldest_first',
        desc: 'collections.newest_first',
        label: 'filters.updated',
        type: 'updated',
        value: 'updated',
    },
};

const sortOptions = fieldsToSortOptions(Object.keys(columns), columns);

const useCollection = (collectionId: string): Collection | undefined => {
    const collections = useCollectionsSnapshot();

    return collections.find((c) => c.id === collectionId);
};

function useSorting(collectionId: string) {
    const collection = useCollection(collectionId);
    const sorting = collection?.sorting ?? null;

    const { space } = useSpace();

    const setSorting = (newValue: Sorting | null) => {
        if (collection) {
            SpaceOperations.setCollectionSorting(space, collection.id, newValue);
            track('sort-table', { source: 'collection-table' });
        }
        return newValue;
    };

    return { sorting, setSorting };
}

interface TableHeaderContentProps {
    children: React.ReactNode;
}

const TableHeaderContent: React.FC<TableHeaderContentProps> = ({ children }) => {
    return <p className="my-auto flex-grow truncate py-0.5 px-1 text-saga-gray-500 text-sm font-normal">{children}</p>;
};

function TagViewTableHeader() {
    const { t } = useTranslation();
    return (
        <Table.Header className="sticky top-0 z-50 bg-white dark:bg-saga-gray-1000">
            <Table.Row>
                <Table.Cell className="h-10 pb-3 align-middle w-full min-w-56">
                    <SortableTableButton type="title">
                        <TableHeaderContent>{t(columns.title.label)}</TableHeaderContent>
                    </SortableTableButton>
                </Table.Cell>

                <Table.Cell className="h-10 pb-3 align-middle min-w-40 w-40">
                    <SortableTableButton type="subCollections">
                        <TableHeaderContent>{t(columns.subCollections.label)}</TableHeaderContent>
                    </SortableTableButton>
                </Table.Cell>

                <Table.Cell className="h-10 pb-3 align-middle min-w-40 w-40">
                    <SortableTableButton type="created">
                        <TableHeaderContent>{t(columns.created.label)}</TableHeaderContent>
                    </SortableTableButton>
                </Table.Cell>

                <Table.Cell className="h-10 pb-3 align-middle min-w-40 w-40">
                    <SortableTableButton type="updated">
                        <TableHeaderContent>{t(columns.updated.label)}</TableHeaderContent>
                    </SortableTableButton>
                </Table.Cell>
            </Table.Row>
        </Table.Header>
    );
}

function FolderViewTableHeader() {
    const { t } = useTranslation();
    return (
        <Table.Header className="sticky top-0 z-50 bg-white dark:bg-saga-gray-1000">
            <Table.Row>
                <Table.Cell className="h-10 pb-3 align-middle w-full min-w-56">
                    <SortableTableButton type="title">
                        <TableHeaderContent>{t(columns.title.label)}</TableHeaderContent>
                    </SortableTableButton>
                </Table.Cell>

                <Table.Cell className="h-10 pb-3 align-middle min-w-40 w-40">
                    <SortableTableButton type="created">
                        <TableHeaderContent>{t(columns.created.label)}</TableHeaderContent>
                    </SortableTableButton>
                </Table.Cell>
                <Table.Cell className="h-10 pb-3 align-middle min-w-40 w-40">
                    <SortableTableButton type="updated">
                        <TableHeaderContent>{t(columns.updated.label)}</TableHeaderContent>
                    </SortableTableButton>
                </Table.Cell>
            </Table.Row>
        </Table.Header>
    );
}

function PageOrTaskItemRow({
    item,
    onTitleClick,
    contextMenuEnabled,
    onRemove,
    onDelete,
    onExport,
    index,
}: {
    item: PageTableItem | TaskTableItem;
    onTitleClick: (event: React.MouseEvent) => void;
    contextMenuEnabled: boolean;
    onRemove: () => void;
    onDelete: () => void;
    onExport: () => void;
    index: number;
}) {
    const [showContext, setShowContext] = useState<boolean>(false);
    const { user } = useUserContext();
    const createdAt = formatDateOptimized(new Date(item.createdAt).toISOString(), user?.data.language);
    const updatedAt = formatDateOptimized(new Date(item.updatedAt).toISOString(), user?.data.language);
    const { isPageAdmin } = usePageAccess(item.id);
    const { space } = useSpace();
    return (
        <Table.Row
            onMouseOver={() => setShowContext(true)}
            onMouseLeave={() => setShowContext(false)}
            key={item.id}
            data-testid={`collection-index-${index}`}
            className="group divide-x divide-saga-gray-150 dark:divide-saga-gray-800 py-1"
        >
            <Table.Cell className="relative h-10 align-middle py-0 pr-1 w-full min-w-56 max-w-56">
                <div className="h-8 w-8 absolute -ml-7"></div>
                {contextMenuEnabled && showContext && (
                    <div className="h-10 absolute -ml-7">
                        <DocumentInCollectionContextMenuButton
                            entityType={item.type}
                            onRemove={onRemove}
                            onDelete={onDelete}
                            onExport={onExport}
                            className="my-auto"
                            align="start"
                            isButtonSmall
                            documentId={item.id}
                            isPageAdmin={isPageAdmin}
                        />
                    </div>
                )}
                <Table.Button onClick={onTitleClick}>
                    <div className="flex space-x-2 items-center min-w-0">
                        <div className="w-4 text-saga-gray-500">
                            <div className="flex items-center h-6" style={{ marginLeft: -2 }}>
                                {item.type === 'page' ? (
                                    <PageIcon icon={item.page.icon} isTemplate={false} />
                                ) : (
                                    <TaskStatusSelect
                                        status={item.task.state}
                                        onChange={(state) => {
                                            track('change-task-state', { state, source: 'collection-table' });
                                            SpaceOperations.updatePartialTask(space, item.task.id, {
                                                state,
                                                completedDate: state === 'DONE' ? new Date().toISOString() : null,
                                            });
                                        }}
                                    />
                                )}
                            </div>
                        </div>

                        <div className="truncate">{item.title}</div>
                    </div>
                </Table.Button>
            </Table.Cell>

            <Table.Cell className="h-10 align-middle min-w-40 w-40 no-wrap py-0 px-1">
                <p className="text-sm">{createdAt}</p>
            </Table.Cell>
            <Table.Cell className="h-10 align-middle min-w-40 w-40 no-wrap py-0 px-1">
                <p className="text-sm">{updatedAt}</p>
            </Table.Cell>
        </Table.Row>
    );
}

function TagViewRow({
    item,
    collectionsIndex,
    onTitleClick,
    onCollectionClick,
    contextMenuEnabled,
    onRemove,
    onDelete,
    onExport,
    index,
    canEdit,
    currentCollectionId,
}: {
    item: CollectionTableItem;
    onTitleClick(event: React.MouseEvent): void;
    onCollectionClick(id: string, event: React.MouseEvent): void;
    contextMenuEnabled: boolean;
    onRemove: () => void;
    onDelete: () => void;
    onExport: () => void;
    index: number;
    collectionsIndex: Record<string, Collection>;
    canEdit: boolean;
    currentCollectionId: string;
}) {
    const { space } = useSpace();
    const [showContext, setShowContext] = useState<boolean>(false);
    const { user } = useUserContext();
    const { isPageAdmin } = usePageAccess(item.id);
    const isMobile = useMobile();

    const createdAt = formatDateOptimized(new Date(item.createdAt).toISOString(), user?.data.language);
    const updatedAt = formatDateOptimized(new Date(item.updatedAt).toISOString(), user?.data.language);
    const itemCollections =
        item.collections
            ?.filter((id) => id !== currentCollectionId)
            .map((id) => collectionsIndex[id])
            .filter(removeNullable) ?? [];

    const allCollections = Object.values(collectionsIndex);

    return (
        <Table.Row
            onMouseOver={() => setShowContext(true)}
            onMouseLeave={() => setShowContext(false)}
            key={item.id}
            data-testid={`collection-index-${index}`}
            className="group divide-x divide-saga-gray-150 dark:divide-saga-gray-800 py-1"
        >
            <Table.Cell className="relative h-10 align-middle py-0 pr-1 w-full min-w-56 max-w-56">
                <div className="h-8 w-8 absolute -ml-7"></div>
                {contextMenuEnabled && showContext && (
                    <div className="h-10 absolute -ml-7">
                        <DocumentInCollectionContextMenuButton
                            entityType="page"
                            onRemove={onRemove}
                            onDelete={onDelete}
                            onExport={onExport}
                            className="my-auto"
                            align="start"
                            isButtonSmall
                            documentId={item.id}
                            isPageAdmin={isPageAdmin}
                        />
                    </div>
                )}
                <Table.Button onClick={onTitleClick}>
                    <div className="flex space-x-2 items-center min-w-0">
                        <div className="w-4 text-saga-gray-500">
                            <div className="flex items-center h-6" style={{ marginLeft: -2 }}>
                                {item.type === 'page' && <PageIcon icon={item.page.icon} isTemplate={false} />}
                                {item.type === 'task' && (
                                    <TaskStatusSelect
                                        status={item.task.state}
                                        onChange={(state) => {
                                            track('change-task-state', { state, source: 'collection-table' });
                                            SpaceOperations.updatePartialTask(space, item.task.id, {
                                                state,
                                                completedDate: state === 'DONE' ? new Date().toISOString() : null,
                                            });
                                        }}
                                    />
                                )}
                            </div>
                        </div>

                        <div className="truncate">{item.title}</div>
                    </div>
                </Table.Button>
            </Table.Cell>

            <Table.Cell className="align-middle min-w-40 w-40 no-wrap py-0 px-1">
                <ManageCollectionsInput
                    canEdit={canEdit}
                    currentCollections={itemCollections}
                    availableCollections={allCollections}
                    excludeCollections={[...itemCollections.map(R.prop('id')), currentCollectionId]}
                    onCollectionClick={onCollectionClick}
                    onCreate={(title) => {
                        SpaceOperations.transactInSpace(space, () => {
                            const newCollection = SpaceOperations.createCollection(space, title);
                            if (item.type === 'page') {
                                SpaceOperations.addPageToCollection(space, item.id, newCollection.id);
                            } else {
                                SpaceOperations.addTaskToCollection(space, item.id, newCollection.id);
                            }
                        });
                    }}
                    onSelect={(id) => {
                        if (item.type === 'page') {
                            SpaceOperations.addPageToCollection(space, item.id, id);
                        } else {
                            SpaceOperations.addTaskToCollection(space, item.id, id);
                        }
                    }}
                    onRemove={(id) => {
                        if (item.type === 'page') {
                            SpaceOperations.removePageFromCollection(space, item.id, id);
                        } else {
                            SpaceOperations.removeTaskFromCollection(space, item.id, id);
                        }
                    }}
                    maxWidth={isMobile ? 250 : 400}
                />
            </Table.Cell>

            <Table.Cell className="h-10 align-middle min-w-40 w-40 no-wrap py-0 px-1">
                <p className="text-sm">{createdAt}</p>
            </Table.Cell>
            <Table.Cell className="h-10 align-middle min-w-40 w-40 no-wrap py-0 px-1">
                <p className="text-sm">{updatedAt}</p>
            </Table.Cell>
        </Table.Row>
    );
}

function CollectionRow({
    collection,
    onTitleClick,
    contextMenuEnabled,
    onRemove,
    index,
}: {
    collection: Collection;
    onTitleClick: (event: React.MouseEvent) => void;
    contextMenuEnabled: boolean;
    onRemove: () => void;
    index: number;
}) {
    const { user } = useUserContext();
    const [showContext, setShowContext] = useState<boolean>(false);
    const createdAt = formatDateOptimized(collection.createdAt, user?.data.language);
    const updatedAt = formatDateOptimized(collection.updatedAt, user?.data.language);

    return (
        <Table.Row
            onMouseOver={() => setShowContext(true)}
            onMouseLeave={() => setShowContext(false)}
            key={collection.id}
            data-testid={`collection-index-${index}`}
            className="group divide-x divide-saga-gray-150 dark:divide-saga-gray-800 py-1"
        >
            <Table.Cell className="relative h-10 align-middle py-0 pr-1 w-full min-w-56 max-w-56">
                <div className="h-8 w-8 absolute -ml-7"></div>
                {contextMenuEnabled && showContext && (
                    <div className="h-10 absolute -ml-7">
                        <CollectionInCollectionContextMenuButton
                            onRemove={onRemove}
                            className="my-auto"
                            align="start"
                            isButtonSmall
                            collectionId={collection.id}
                        />
                    </div>
                )}
                <Table.Button onClick={onTitleClick}>
                    <div className="flex space-x-2 items-center min-w-0">
                        <div className="w-4 text-saga-gray-500">
                            <div className="flex items-center h-6" style={{ marginLeft: -2 }}>
                                <CollectionIcon icon={collection.icon} />
                            </div>
                        </div>

                        <div className="truncate">{collection.title}</div>
                    </div>
                </Table.Button>
            </Table.Cell>

            <Table.Cell className="h-10 align-middle min-w-40 w-40 no-wrap py-0 px-1">
                <p className="text-sm">{createdAt}</p>
            </Table.Cell>
            <Table.Cell className="h-10 align-middle min-w-40 w-40 no-wrap py-0 px-1">
                <p className="text-sm">{updatedAt}</p>
            </Table.Cell>
        </Table.Row>
    );
}

const keysMap = ['id', 'title', 'createdAt', 'updatedAt', 'collections', 'icon', 'archivedAt', 'isTemplate'] as const;

type CollectionTablePage = Pick<WeakPage, (typeof keysMap)[number]>;

function useCollectionPages(collection: Collection): CollectionTablePage[] {
    const pages = usePartialPages(keysMap, 'deep');
    const collectionId = collection.id;
    return React.useMemo(() => pages.filter((page) => page.collections?.includes(collectionId)), [collectionId, pages]);
}

function useCollectionTasks(collection: Collection): WeakTask[] {
    const tasks = useSidebarTasks();
    const collectionId = collection.id;
    return React.useMemo(() => tasks.filter((task) => task.collections?.includes(collectionId)), [collectionId, tasks]);
}

function useSubCollections(collection: Collection): Collection[] {
    const collections = useCollectionsSnapshot();
    const collectionIndex = R.indexBy(R.prop('id'), collections);
    const subCollectionIds = collection.subCollections ?? [];
    return subCollectionIds.map((id) => collectionIndex[id]).filter(removeNullable);
}

type BaseTableItem = {
    id: string;
    title: string;
    collections: string[];
    updatedAt: number;
    createdAt: number;
};

type PageTableItem = BaseTableItem & {
    type: 'page';
    page: CollectionTablePage;
};

function toPageTableItem(page: CollectionTablePage): PageTableItem {
    return {
        type: 'page',
        page,
        id: page.id,
        title: page.title,
        collections: page.collections,
        updatedAt: new Date(page.updatedAt).valueOf(),
        createdAt: new Date(page.createdAt).valueOf(),
    };
}

type SubCollectionTableItem = BaseTableItem & {
    type: 'collection';
    collection: Collection;
};

function toCollectionTableItem(collection: Collection): SubCollectionTableItem {
    return {
        type: 'collection',
        collection,
        id: collection.id,
        collections: collection.subCollections ?? [],
        title: collection.title,
        updatedAt: new Date(collection.updatedAt).valueOf(),
        createdAt: new Date(collection.createdAt).valueOf(),
    };
}

type TaskTableItem = BaseTableItem & {
    type: 'task';
    task: WeakTask;
};

function toTaskTableItem(task: WeakTask): TaskTableItem {
    return {
        type: 'task',
        task,
        id: task.id,
        title: task.title ?? 'Untitled',
        collections: task.collections ?? [],
        updatedAt: new Date(task.updatedAt).valueOf(),
        createdAt: new Date(task.createdAt).valueOf(),
    };
}

type CollectionTableItem = PageTableItem | SubCollectionTableItem | TaskTableItem;

const sortingKeyMap: Record<string, 'title' | 'createdAt' | 'updatedAt'> = {
    title: 'title' as const,
    created: 'createdAt' as const,
    updated: 'updatedAt' as const,
};

function getSorter(sorting: Sorting | null) {
    const key: keyof CollectionTableItem = sorting
        ? sortingKeyMap[sorting.by] ?? ('title' as const)
        : ('title' as const);

    return sorting?.order === 'asc'
        ? R.ascend<CollectionTableItem>((item) => item[key])
        : R.descend<CollectionTableItem>((item) => item[key]);
}

export function sortCollectionTableItems<T extends CollectionTableItem>(
    sorting: Sorting | null,
    items: T[],
    currentCollectionId: string,
) {
    if (sorting?.by === 'subCollections') {
        function filterCurrentCollection(collections: string[]) {
            return collections.filter((id) => id !== currentCollectionId);
        }

        return R.sort((a, b) => {
            if (sorting.order === 'asc') {
                return filterCurrentCollection(a.collections).length - filterCurrentCollection(b.collections).length;
            } else {
                return filterCurrentCollection(b.collections).length - filterCurrentCollection(a.collections).length;
            }
        }, items);
    }

    return sorting
        ? R.sort(getSorter(sorting), items)
        : R.sortWith([R.descend((item) => new Date(item.createdAt).getTime())], items);
}

function filterItems<T extends CollectionTableItem>(keyWord: string, items: T[]) {
    const regex = new RegExp(`(?:^|\\s)${escapeRegExp(keyWord)}`, 'gi');
    return items.filter((item) => item.title.match(regex));
}

export function makeCollectionTableItems(
    pages: CollectionTablePage[],
    collections: Collection[],
    tasks: WeakTask[],
): CollectionTableItem[] {
    return [...pages.map(toPageTableItem), ...collections.map(toCollectionTableItem), ...tasks.map(toTaskTableItem)];
}

function useAllCollectionPages(collections: Collection[], collectionId: string) {
    const pages = usePartialPages(keysMap, 'deep');
    const pagesByCollection = React.useMemo(() => {
        const pagesByCollection = collections.reduce(
            (acc, collection) => {
                return { ...acc, [collection.id]: [] };
            },
            {} as Record<string, typeof pages>,
        );

        pages.forEach((page) => {
            page.collections.forEach((collectionId) => {
                pagesByCollection[collectionId]?.push(page);
            });
        });

        return pagesByCollection;
    }, [collections, pages]);

    const collectionPages = React.useMemo(() => {
        const visitedCollectionIds: string[] = [];
        const collectionsIndex = R.indexBy(R.prop('id'), collections);

        // This function is called recursively to walk through all sub collections
        function getPagesForCollectionId(id: string): typeof pages {
            const pages = pagesByCollection[id] ?? [];
            const collection = collectionsIndex[id];

            if (collection && !visitedCollectionIds.includes(collection.id)) {
                visitedCollectionIds.push(collection.id);
                const nestedPages = collection.subCollections?.map(getPagesForCollectionId).flat() ?? [];
                return [...pages, ...nestedPages];
            }

            return pages;
        }

        return R.uniqBy(R.prop('id'), getPagesForCollectionId(collectionId));
    }, [pagesByCollection, collections, collectionId]);

    return collectionPages;
}

function useAllCollectionTasks(collections: Collection[], collectionId: string) {
    const tasks = useSidebarTasks();
    const tasksByCollection = React.useMemo(() => {
        const tasksByCollection = collections.reduce(
            (acc, collection) => {
                return { ...acc, [collection.id]: [] };
            },
            {} as Record<string, typeof tasks>,
        );

        tasks.forEach((task) => {
            task.collections?.forEach((collectionId) => {
                tasksByCollection[collectionId]?.push(task);
            });
        });

        return tasksByCollection;
    }, [collections, tasks]);

    const collectionTasks = React.useMemo(() => {
        const visitedCollectionIds: string[] = [];
        const collectionsIndex = R.indexBy(R.prop('id'), collections);

        // This function is called recursively to walk through all sub collections
        function getTasksForCollectionId(id: string): typeof tasks {
            const tasks = tasksByCollection[id] ?? [];
            const collection = collectionsIndex[id];

            if (collection && !visitedCollectionIds.includes(collection.id)) {
                visitedCollectionIds.push(collection.id);
                const nestedTasks = collection.subCollections?.map(getTasksForCollectionId).flat() ?? [];
                return [...tasks, ...nestedTasks];
            }

            return tasks;
        }

        return R.uniqBy(R.prop('id'), getTasksForCollectionId(collectionId));
    }, [tasksByCollection, collections, collectionId]);

    return collectionTasks;
}

function TagViewTable({
    collection,
    onPageClick,
    onTaskClick,
    collections,
    onCollectionClick,
    collectionsFilter,
}: {
    collection: Collection;
    onPageClick(id: string, event: React.MouseEvent): void;
    onTaskClick(id: string, event: React.MouseEvent): void;
    collections: Collection[];
    onCollectionClick(id: string, event: React.MouseEvent): void;
    collectionsFilter?: ItemsFilterMode;
}) {
    const collectionsIndex = R.indexBy(R.prop('id'), collections);
    const collectionPages = useAllCollectionPages(collections, collection.id);
    const collectionTasks = useAllCollectionTasks(collections, collection.id);

    const isMobile = useMobile();
    const { space } = useSpace();
    const { search, sorting } = useTableContext();
    const { t } = useTranslation();
    const archivePage = useArchivePage();

    const items = React.useMemo(
        () => makeCollectionTableItems(collectionPages, [], collectionTasks),
        [collectionPages, collectionTasks],
    );

    const filteredByCollections = React.useMemo(() => {
        if (!collectionsFilter || collectionsFilter === 'all') {
            return items;
        }

        if (collectionsFilter === 'none') {
            return items.filter((item) => !item.collections || item.collections.length === 0);
        }

        return items.filter(
            (item) =>
                Array.isArray(item.collections) &&
                item.collections.some(
                    (collectionId) => Array.isArray(collectionsFilter) && collectionsFilter.includes(collectionId),
                ),
        );
    }, [items, collectionsFilter]);

    const sortedAndFilteredItems = React.useMemo(() => {
        const filtered = filterItems(search, filteredByCollections);
        return sortCollectionTableItems(sorting, filtered, collection.id);
    }, [filteredByCollections, sorting, search, collection.id]);

    const { canEdit } = useSpaceAccess();
    const exportItem = useExport();

    const onDeletePage = (id: string) => {
        archivePage(id);
    };

    return (
        <NavigationTable.PaneContainer className="pb-12 show-scrollbar-x">
            <Table
                data-testid="collection-table"
                className="table-fixed border-collapse text-left divide-y divide-saga-gray-150 dark:divide-saga-gray-800 border-b border-saga-gray-200 dark:border-saga-gray-800"
            >
                <TagViewTableHeader />
                <Table.Body
                    data-testid="collection-table-body"
                    className="relative divide-y divide-saga-gray-150 dark:divide-zinc-600 h-full "
                >
                    {sortedAndFilteredItems.map((item, i) => {
                        return (
                            <TagViewRow
                                index={i}
                                key={item.id}
                                item={item}
                                onTitleClick={(event) => {
                                    if (item.type === 'page') onPageClick(item.id, event);
                                    if (item.type === 'task') onTaskClick(item.id, event);
                                }}
                                contextMenuEnabled={!isMobile && canEdit}
                                onRemove={() => {
                                    if (item.type === 'page')
                                        SpaceOperations.removePageFromCollection(space, item.id, collection.id);
                                    if (item.type === 'task')
                                        SpaceOperations.removeTaskFromCollection(space, item.id, collection.id);
                                }}
                                onDelete={() => onDeletePage(item.id)}
                                onExport={() => exportItem(item)}
                                collectionsIndex={collectionsIndex}
                                canEdit={canEdit}
                                currentCollectionId={collection.id}
                                onCollectionClick={onCollectionClick}
                            />
                        );
                    })}
                </Table.Body>
            </Table>
            {collectionPages.length === 0 && <Table.EmptyState>{t('collections.empty_state')}</Table.EmptyState>}
        </NavigationTable.PaneContainer>
    );
}

function FolderViewTable({
    collection,
    onPageClick,
    onTaskClick,
    onCollectionClick,
    collectionsFilter,
}: {
    collection: Collection;
    onPageClick(id: string, event: React.MouseEvent): void;
    onTaskClick(id: string, event: React.MouseEvent): void;
    onCollectionClick(id: string, event: React.MouseEvent): void;
    collectionsFilter?: ItemsFilterMode;
}) {
    const isMobile = useMobile();
    const { space } = useSpace();
    const { search, sorting } = useTableContext();
    const pages = useCollectionPages(collection);
    const subCollections = useSubCollections(collection);
    const tasks = useCollectionTasks(collection);

    const items = React.useMemo(
        () => makeCollectionTableItems(pages, subCollections, tasks),
        [pages, tasks, subCollections],
    );

    const filteredByCollections = React.useMemo(() => {
        const subCollectionItems = items.filter((item) => item.type === 'collection');

        const otherItems = items.filter((item) => item.type !== 'collection');

        if (!collectionsFilter || collectionsFilter === 'all') {
            return [...subCollectionItems, ...otherItems];
        }

        if (collectionsFilter === 'none') {
            const filteredOtherItems = otherItems.filter((item) => !item.collections || item.collections.length === 0);
            return [...subCollectionItems, ...filteredOtherItems];
        }

        const filteredOtherItems = otherItems.filter(
            (item) =>
                Array.isArray(item.collections) &&
                item.collections.some(
                    (collectionId) => Array.isArray(collectionsFilter) && collectionsFilter.includes(collectionId),
                ),
        );

        return [...subCollectionItems, ...filteredOtherItems];
    }, [items, collectionsFilter]);

    const sortedAndFilteredItems = React.useMemo(() => {
        const filtered = filterItems(search, filteredByCollections);
        return sortCollectionTableItems(sorting, filtered, collection.id);
    }, [filteredByCollections, sorting, search, collection.id]);

    const { canEdit } = useSpaceAccess();
    const exportItem = useExport();

    const archivePage = useArchivePage();
    const archiveTask = useArchiveTask();

    const onDeletePage = (id: string) => {
        archivePage(id);
    };

    const onDeleteTask = (id: string) => {
        archiveTask(id);
    };

    return (
        <NavigationTable.PaneContainer className="pb-12 show-scrollbar-x">
            <Table
                data-testid="collection-table"
                className="table-fixed border-collapse text-left divide-y divide-saga-gray-150 dark:divide-saga-gray-800 border-b border-saga-gray-200 dark:border-saga-gray-800"
            >
                <FolderViewTableHeader />
                <Table.Body
                    data-testid="collection-table-body"
                    className="relative divide-y divide-saga-gray-150 dark:divide-saga-gray-800 h-full "
                >
                    {sortedAndFilteredItems.map((item, i) => {
                        switch (item.type) {
                            case 'page':
                                return (
                                    <PageOrTaskItemRow
                                        index={i}
                                        key={item.id}
                                        item={item}
                                        onTitleClick={(event) => onPageClick(item.id, event)}
                                        contextMenuEnabled={!isMobile && canEdit}
                                        onRemove={() => {
                                            SpaceOperations.removePageFromCollection(space, item.id, collection.id);
                                        }}
                                        onDelete={() => onDeletePage(item.id)}
                                        onExport={() => exportItem(item.page)}
                                    />
                                );

                            case 'task':
                                return (
                                    <PageOrTaskItemRow
                                        index={i}
                                        key={item.id}
                                        item={item}
                                        onTitleClick={(event) => onTaskClick(item.id, event)}
                                        contextMenuEnabled={!isMobile && canEdit}
                                        onRemove={() => {
                                            SpaceOperations.removeTaskFromCollection(space, item.id, collection.id);
                                        }}
                                        onDelete={() => onDeleteTask(item.id)}
                                        onExport={() => exportItem(item.task)}
                                    />
                                );
                            case 'collection':
                                return (
                                    <CollectionRow
                                        index={i}
                                        key={item.id}
                                        collection={item.collection}
                                        contextMenuEnabled={!isMobile && canEdit}
                                        onRemove={() => {
                                            SpaceOperations.removeCollectionFromCollection(
                                                space,
                                                item.id,
                                                collection.id,
                                            );
                                        }}
                                        onTitleClick={(event) => onCollectionClick(item.collection.id, event)}
                                    />
                                );
                        }
                    })}
                </Table.Body>
            </Table>
            {items.length === 0 && (
                <Table.EmptyState>Add Pages or Collections to this Collection to see them here.</Table.EmptyState>
            )}
        </NavigationTable.PaneContainer>
    );
}

function ViewModeSelectItem({
    value,
    children,
    selected,
}: {
    value: CollectionTableViewMode;
    children: React.ReactNode;
    selected: boolean;
}) {
    return (
        <Select.Item
            value={value}
            className={classNames('focus:outline-none font-semibold cursor-pointer px-2 py-2', {
                'bg-saga-gray-200 dark:text-zinc-800': selected,
                'hover:bg-saga-gray-200 dark:hover:bg-zinc-600': !selected,
            })}
        >
            <Select.ItemText>{children}</Select.ItemText>
        </Select.Item>
    );
}

function ViewModeSelect({
    value,
    onChange,
}: {
    value: CollectionTableViewMode;
    onChange(value: CollectionTableViewMode): void;
}) {
    const { t } = useTranslation();
    return (
        <Select.Root value={value} onValueChange={onChange}>
            <Select.Trigger className="focus:outline-none cursor-pointer px-2 h-8 text-xs border font-semibold rounded hover:bg-saga-gray-200 dark:hover:bg-zinc-600 border-saga-gray-200 dark:border-saga-gray-700 shadow-button">
                <Select.Value />
            </Select.Trigger>

            <Select.Content className="bg-white dark:bg-zinc-700 border border-saga-gray-200 dark:border-zinc-600 rounded shadow-popoverSmall text-xs font-normal">
                <Select.ScrollUpButton />
                <Select.Viewport className="rounded">
                    <ViewModeSelectItem value="folder" selected={value === 'folder'}>
                        <span className="flex items-center space-x-2 whitespace-nowrap">
                            <Folder size={14} />
                            <span>{t('collections.folder_view')}</span>
                        </span>
                    </ViewModeSelectItem>

                    <ViewModeSelectItem value="tag" selected={value === 'tag'}>
                        <span className="flex items-center space-x-2 whitespace-nowrap">
                            <Tag size={14} />
                            <span>{t('collections.tag_view')}</span>
                        </span>
                    </ViewModeSelectItem>
                </Select.Viewport>
                <Select.ScrollDownButton />
            </Select.Content>
        </Select.Root>
    );
}

function TopMenu({
    onClose,
    isFavorite,
    onFavoriteButtonClick,
    collectionId,
}: {
    onClose?: () => void;
    isFavorite: boolean;
    onFavoriteButtonClick: () => void;
    collectionId: string;
}) {
    return (
        <div className="flex z-20 space-x-1">
            <NavigationTable.SearchButton />
            <div className="flex items-center">
                <FavoriteButton type="collection" isFavorite={isFavorite} onClick={onFavoriteButtonClick} />
            </div>
            <div className="flex items-center">
                <CollectionContextMenuButton align="end" collectionId={collectionId} />
            </div>

            {onClose && (
                <div className="flex items-center">
                    <Button.XButton onClick={onClose} />
                </div>
            )}
        </div>
    );
}

function InnerCollectionTable({ collection, onClose }: { collection: Collection; onClose?: () => void }) {
    const { t } = useTranslation();
    const collectionId = collection.id;
    const [{ collectionTableViewMode }, setInterfaceSettings] = useInterfaceSettings();
    const viewMode = collectionTableViewMode ?? 'folder';
    const [search, setSearch] = useState<string>('');
    const { sorting, setSorting } = useSorting(collectionId);
    const { canEdit } = useSpaceAccess();
    const collections = useCollectionsSnapshot();
    const goToCollection = useOpenCollection();
    const deleteCollectionIfEmpty = useDeleteCollectionIfEmpty();
    const { isDesktop } = useDesktopContext();
    const { panes } = useSideBySideState();
    const [settings, updateUserWorkspaceSettings] = useWorkspaceSettings();
    const [collectionsFilter, setCollectionsFilter] = useState<ItemsFilterMode>(
        settings?.allCollectionsCollections ?? 'all',
    );

    const { isFavorite, toggleFavorite } = useFavoriteButton(collectionId ?? '');
    const { space, provider } = useSpace();

    const isMobile = useMobile();

    const sideIndex = useSideBySideIndex();

    function setViewMode(collectionTableViewMode: CollectionTableViewMode) {
        setInterfaceSettings({
            collectionTableViewMode,
        });
    }

    const updateTitle = useCallback(
        (newTitle: string) => {
            if (newTitle.length > 0) {
                SpaceOperations.setCollectionTitle(space, collection.id, newTitle);
                track('update-collection-title');
            }
        },
        [collection, space],
    );

    const onCreateParentCollection = useCallback(
        (title: string) => {
            const newCollection = SpaceOperations.createCollection(space, title);
            SpaceOperations.addSubCollectionById(space, newCollection.id, collection.id);
            track('new-parent-collection');
        },
        [collection.id, space],
    );

    const currentParentCollections = React.useMemo(
        () => collections.filter((c) => c.subCollections?.includes(collection.id)),
        [collection, collections],
    );

    const onCollectionClick = (id: string, event: React.MouseEvent) => goToCollection(id, event);

    const onAddParentCollection = useCallback(
        (id: string) => {
            SpaceOperations.addSubCollectionById(space, id, collection.id);
            track('add-parent-collection-to-collection');
        },
        [collection.id, space],
    );

    const onRemoveParentCollection = useCallback(
        (id: string) => {
            SpaceOperations.removeSubCollectionById(space, id, collection.id);
            track('remove-parent-collection-from-collection');
        },
        [collection.id, space],
    );

    const openPage = useOpenPage();
    const openTask = useOpenTask();

    const onPageClick = React.useCallback(
        (id: string, event: React.MouseEvent) => {
            openPage(id, event);

            if (isMobile) {
                setInterfaceSettings({ fixedSidebar: false });
            }
        },
        [isMobile, openPage, setInterfaceSettings],
    );

    const onTaskClick = React.useCallback(
        (id: string, event: React.MouseEvent) => {
            openTask(id, event);

            if (isMobile) {
                setInterfaceSettings({ fixedSidebar: false });
            }
        },
        [isMobile, openTask, setInterfaceSettings],
    );
    const excludeCollections = React.useMemo(() => {
        return [
            collectionId,
            ...(currentParentCollections.map(({ id }) => id) ?? []),
            ...(collection.subCollections ?? []),
        ];
    }, [collectionId, currentParentCollections, collection.subCollections]);

    React.useEffect(() => {
        return () => {
            setTimeout(() => deleteCollectionIfEmpty(collection.id), 0);
        };
    }, [collection.id, deleteCollectionIfEmpty]);

    return (
        <TableContext.Provider
            value={{
                search,
                setSearch,
                sorting,
                setSorting,
                columns,
            }}
        >
            <NavigationTable>
                <NavigationTable.FixedPaneContainer variant="page-header">
                    <>
                        {isDesktop && sideIndex === 0 && <DesktopNavigationButtons />}
                        <div className="flex flex-grow justify-center">{panes.length === 1 && <HeaderSearchBar />}</div>

                        {!canEdit && onClose && (
                            <div className="flex z-20">
                                {panes.length > 1 && <NavigationTable.SearchButton />}
                                {onClose && (
                                    <NavigationTable.CloseButton
                                        label={t('collections.close_collections') as string}
                                        onClick={onClose}
                                    />
                                )}
                                {!onClose && <div style={{ height: 36 }} />}
                            </div>
                        )}
                        {canEdit && (
                            <TopMenu
                                onClose={onClose}
                                isFavorite={isFavorite}
                                onFavoriteButtonClick={toggleFavorite}
                                collectionId={collection.id}
                            />
                        )}
                    </>
                </NavigationTable.FixedPaneContainer>
                <NavigationTable.FixedPaneContainer variant="page-title-collections">
                    <div className="w-full pl-3 sm:pl-8 sm:pt-[34px] sm:pb-2.5 min-w-0 flex items-center">
                        <div className="-top-3.5 left-0 sm:left-6 sm:top-0 md:[left:38px] absolute h-8 flex flex-row space-x-1">
                            {canEdit && (
                                <CornerLeftDown className="flex-none stroke-saga-gray-500 my-auto w-4 ml-3 mt-2.5" />
                            )}
                            <ManageCollectionsInput
                                canEdit={canEdit}
                                currentCollections={currentParentCollections ?? []}
                                availableCollections={collections}
                                excludeCollections={excludeCollections}
                                onCollectionClick={onCollectionClick}
                                onCreate={onCreateParentCollection}
                                onSelect={onAddParentCollection}
                                onRemove={onRemoveParentCollection}
                                maxWidth={isMobile ? 250 : 400}
                                placeholder={t('collections.add_parent_collection') as string}
                            />
                        </div>
                        <CollectionTitle
                            autofocus={!collection.title}
                            icon={collection.icon}
                            title={collection.title}
                            onUpdateTitle={updateTitle}
                            onChangeIcon={(colons) => {
                                SpaceOperations.setCollectionIcon(space, collection.id, { type: 'emoji', colons });
                            }}
                            onRemoveIcon={() => {
                                SpaceOperations.setCollectionIcon(space, collection.id, undefined);
                            }}
                        />
                        {canEdit && (
                            <div className="flex flex-1 justify-end sm:pr-8 ml-8">
                                <AddToCollectionButton
                                    collectionId={collectionId}
                                    onCreateEmptyPageInCollection={(event) => {
                                        const page = createPage(space, newBlankPage({}), provider);
                                        SpaceOperations.addPageToCollection(space, page.id, collectionId);
                                        track('create-page', { source: 'sidebar-inner-collection-plus-button' });
                                        openPage(page.id, event);
                                    }}
                                >
                                    {t('collections.add_to_collection')}
                                </AddToCollectionButton>
                            </div>
                        )}
                    </div>
                </NavigationTable.FixedPaneContainer>

                <ActionBar
                    filter={
                        <div className="flex items-center space-x-2">
                            {viewMode === 'tag' && (
                                <CollectionsFilterSelect
                                    availableCollections={collections}
                                    selectedIds={collectionsFilter}
                                    onUpdateSelectedIds={(ids) => {
                                        track('change-collections-table-filter-mode');
                                        setCollectionsFilter(ids);
                                        updateUserWorkspaceSettings({ allCollectionsCollections: ids });
                                    }}
                                />
                            )}
                            <ViewModeSelect onChange={setViewMode} value={viewMode} />
                        </div>
                    }
                    search={<SearchBar />}
                    sort={<SortButton sortOptions={sortOptions} popoverTestId="collection-sort-popover" />}
                />
                {viewMode === 'tag' && (
                    <TagViewTable
                        collection={collection}
                        onPageClick={onPageClick}
                        onTaskClick={onTaskClick}
                        collections={collections}
                        onCollectionClick={onCollectionClick}
                        collectionsFilter={collectionsFilter}
                    />
                )}
                {viewMode === 'folder' && (
                    <FolderViewTable
                        collection={collection}
                        onPageClick={onPageClick}
                        onTaskClick={onTaskClick}
                        onCollectionClick={onCollectionClick}
                        collectionsFilter={collectionsFilter}
                    />
                )}
            </NavigationTable>
        </TableContext.Provider>
    );
}

const CollectionTable = ({ collectionId, onClose }: { collectionId: string; onClose?: () => void }) => {
    const collection = useCollection(collectionId);
    const { urlKey } = useCurrentWorkspace();

    if (collection == null) {
        return <Redirect to={`/s/${urlKey}/collections`} />;
    }

    return <InnerCollectionTable collection={collection} onClose={onClose} key={collection.id} />;
};

export default CollectionTable;
