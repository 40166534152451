import React, { useState } from 'react';

const ShareModalContext = React.createContext<{
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}>({ isOpen: false, setIsOpen() {} });

export function useShareModalContext() {
    return React.useContext(ShareModalContext);
}

export function ShareModalContextProvider({ children }: { children: React.ReactNode }) {
    const [isOpen, setIsOpen] = useState(false);

    const context = React.useMemo(() => ({ isOpen, setIsOpen }), [isOpen, setIsOpen]);

    return <ShareModalContext.Provider value={context}>{children}</ShareModalContext.Provider>;
}
