import { PopOver, usePopover } from '@/components/popover/PopOver';
import Tooltip from '@/components/popover/Tooltip';
import { Colors } from '@saga/shared';
import classNames from 'classnames';
import React, { useCallback, useRef } from 'react';
import { ChevronDown, ChevronUp } from 'react-feather';
import { useTranslation } from 'react-i18next';

export const ColorButton = ({
    currentBackgroundColor,
    currentTextColor,
    currentLabel,
    isOpen,
    onMouseDown,
    testId,
}: {
    currentBackgroundColor: string;
    currentTextColor: string;
    currentLabel: string;
    isOpen: boolean | null;
    onMouseDown: React.MouseEventHandler<HTMLDivElement>;
    testId?: string;
}) => {
    const currentBackgroundColorNormalized = Colors.convertOutdatedColor(currentBackgroundColor);
    const currentTextColorNormalized = Colors.convertOutdatedColor(currentTextColor);

    return (
        <div onMouseDown={onMouseDown} role="button" data-testid={testId} className="cursor-pointer">
            <span className="sr-only">{`Highlight the selected text using ${currentLabel}`}</span>
            <div
                className={classNames(
                    'h-8 w-10 flex items-center justify-center dark:text-zinc-200 bg-opacity-50 dark:bg-opacity-50',
                    {
                        'bg-white dark:bg-saga-gray-900 hover:bg-saga-gray-250 dark:hover:bg-saga-gray-800':
                            currentBackgroundColorNormalized === 'blank',
                        [`${currentBackgroundColorNormalized}`]: currentBackgroundColorNormalized !== 'blank',
                    },
                )}
            >
                <div className="flex flex-row items-center justify-center">
                    <div
                        className={classNames('pr-0.5', {
                            [`${currentTextColorNormalized} bg-opacity-50 dark:bg-opacity-30`]:
                                currentTextColorNormalized !== 'blank',
                        })}
                    >
                        {'A'}
                    </div>
                    {isOpen ? (
                        <ChevronUp size={12} className="stroke-saga-text-gray" />
                    ) : (
                        <ChevronDown size={12} style={{ pointerEvents: 'none' }} className="stroke-saga-text-gray" />
                    )}
                </div>
            </div>
        </div>
    );
};

interface SelectedColors {
    background: string;
    text: string;
}

export const ColorPicker = ({
    currentColor,
    currentLabel,
    selectedColor,
    onMouseDown,
    testId,
    type,
}: {
    currentColor: string;
    currentLabel: string;
    selectedColor: string;
    onMouseDown: React.MouseEventHandler<HTMLDivElement>;

    testId?: string;
    type?: string;
}) => {
    const currentColorNormalized = Colors.convertOutdatedColor(currentColor);

    return (
        <div onMouseDown={onMouseDown} role="button" data-testid={testId} className="cursor-pointer flex-wrap">
            <span className="sr-only">
                {type === typesOfHiglights.background
                    ? `Highlight the selected background using ${currentLabel}`
                    : `Highlight the selected text using ${currentLabel}`}
            </span>
            <div
                className={classNames(
                    'relative flex items-center justify-center rounded w-8 h-6 border-2 border-saga-gray-300 dark:border-saga-gray-600',
                    {
                        'bg-white dark:bg-zinc-700': type === 'text' && currentColorNormalized === 'blank',
                        [`${currentColorNormalized}`]: type === 'text' && currentColorNormalized !== 'blank',
                        [`${currentColorNormalized} bg-opacity-50 dark:bg-opacity-30 dark:text-saga-text-darkmode`]:
                            type === 'bg' && currentColorNormalized !== 'blank',
                        'border-saga-text dark:border-saga-gray-400':
                            selectedColor === currentColorNormalized && currentColorNormalized !== 'blank',
                    },
                )}
            >
                {currentColorNormalized === 'blank' ? (
                    <div className="absolute top-[9px] -left-[3px] h-[1px] w-[36px] border-[1px] border-saga-gray-500 -rotate-[35deg]" />
                ) : (
                    'A'
                )}
            </div>
        </div>
    );
};

type ColorPopOver = {
    attachToRef: React.MutableRefObject<HTMLDivElement | HTMLButtonElement | null>;
    isOpen: boolean;
    selectedColors: SelectedColors;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    onCreate: (selected: string, type: string) => void;
    zIndex?: number;
};

const typesOfHiglights = {
    background: 'bg',
    text: 'text',
};

const textSuggestions = [
    ...Colors.textColorSuggestions,
    {
        label: 'Remove color',
        value: 'blank',
    },
];

const backgroundSuggestions = [
    ...Colors.backgroundColorSuggestions,
    {
        label: 'Remove color',
        value: 'blank',
    },
];

export const ColorPopOver = React.forwardRef<HTMLDivElement, ColorPopOver>(function ColorPopOver(
    { attachToRef, isOpen, selectedColors, setIsOpen, onCreate, zIndex },
    ref,
) {
    const keyRef = useRef<HTMLDivElement>(null);
    const { t } = useTranslation();

    const [popoverRef, contentRef] = usePopover({
        isOpen,
        onClose: () => setIsOpen(false),
        target: useCallback(() => {
            if (attachToRef.current) {
                return attachToRef.current.getBoundingClientRect();
            }
            return null;
        }, [attachToRef]),
        clickOutsideRefs: [attachToRef],
        combineRef: ref,
    });

    const submit = (value: string, type: string) => {
        onCreate(value, type);
        setIsOpen(false);
    };

    return (
        <PopOver
            isOpen={isOpen}
            ref={popoverRef}
            contentRef={contentRef}
            noPointer={true}
            zIndex={zIndex ?? 50}
            onOpenAnimationDone={() => keyRef.current?.focus()}
        >
            <PopOver.UnboundCard>
                <div data-testid="color-popover">
                    <div tabIndex={0} className="flex flex-col max-w-[180px]">
                        <div className="pl-3.5 pt-3.5 h-[26px] flex items-center text-saga-gray-500 font-medium text-xs">
                            {t('common.highlighted_content.background')}
                        </div>
                        <div className="flex flex-row flex-wrap py-2 px-2.5">
                            {backgroundSuggestions.map((currentColor, i) => (
                                <Tooltip key={i} placement="top" content={currentColor.label}>
                                    <div className="p-1">
                                        <ColorPicker
                                            currentColor={currentColor.value}
                                            currentLabel={currentColor.label}
                                            selectedColor={selectedColors.text}
                                            type="bg"
                                            onMouseDown={() => {
                                                submit(currentColor.value, typesOfHiglights.background);
                                            }}
                                        />
                                    </div>
                                </Tooltip>
                            ))}
                        </div>
                        <div className="pl-3.5 pt-2 h-[26px] flex items-center text-saga-gray-500 font-medium text-xs">
                            {t('common.highlighted_content.text')}
                        </div>
                        <div className="flex flex-row flex-wrap py-2 px-2.5 ">
                            {textSuggestions.map((currentColor, i) => (
                                <Tooltip key={i} placement="top" content={currentColor.label}>
                                    <div className="p-1">
                                        <ColorPicker
                                            currentColor={currentColor.value}
                                            currentLabel={currentColor.label}
                                            selectedColor={selectedColors.text}
                                            type="text"
                                            onMouseDown={() => {
                                                submit(currentColor.value, typesOfHiglights.text);
                                            }}
                                        />
                                    </div>
                                </Tooltip>
                            ))}
                        </div>
                    </div>
                </div>
            </PopOver.UnboundCard>
        </PopOver>
    );
});
