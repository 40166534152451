import { assertNonNull } from '@/../../shared/src';
import React from 'react';

const FOCUSABLE_ELEMENT_SELECTORS =
    'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, [tabindex="0"], [contenteditable]';

export default function FocusTrap({
    children,
    active,
    nextKeys,
    prevKeys,
    onClose,
    triggerRefs,
}: {
    children: React.ReactNode;
    active?: boolean;
    nextKeys?: string[];
    prevKeys?: string[];
    onClose(): void;
    triggerRefs?: React.RefObject<HTMLElement>[];
}) {
    const triggerRefsRef = React.useRef(triggerRefs);
    triggerRefsRef.current = triggerRefs;

    const ref = React.useRef<HTMLDivElement>(null);
    const onCloseRef = React.useRef(onClose);
    onCloseRef.current = onClose;
    const keysRef = React.useRef<{ nextKeys?: string[]; prevKeys?: string[] }>({ nextKeys, prevKeys });
    keysRef.current = { nextKeys, prevKeys };

    React.useEffect(() => {
        if (!active) return;

        function getFocusableElements() {
            assertNonNull(ref.current);
            const focusableElements = ref.current.querySelectorAll<HTMLElement>(FOCUSABLE_ELEMENT_SELECTORS);
            return Array.from(focusableElements);
        }

        const focusableElements = getFocusableElements();
        const focusEl = focusableElements.find((el) => el.dataset.focus === 'true');

        if (focusEl) {
            focusEl.focus();
        } else {
            focusableElements[0]?.focus();
        }

        function onKeyDown(event: KeyboardEvent) {
            const { code } = event;
            const isTab = code === 'Tab';
            const isNextKey = keysRef.current?.nextKeys?.includes(code);
            const isPrevKey = keysRef.current?.prevKeys?.includes(code);
            const shouldHandle = isTab || isNextKey || isPrevKey;

            if (shouldHandle) {
                const focusableElements = getFocusableElements();
                event.preventDefault();

                const index = focusableElements.findIndex((el) => el === document.activeElement);

                if ((isTab && event.shiftKey) || isPrevKey) {
                    focusableElements[(focusableElements.length + index - 1) % focusableElements.length]?.focus();
                } else if (isTab || isNextKey) {
                    focusableElements[(index + 1) % focusableElements.length]?.focus();
                }
            }
        }

        function onPointerDown(event: PointerEvent) {
            if (
                event.target instanceof Node &&
                !ref.current?.contains(event.target) &&
                !triggerRefsRef.current?.some(
                    (ref) => event.target instanceof Node && ref?.current?.contains(event.target),
                )
            ) {
                onCloseRef.current();
            }
        }

        window.addEventListener('keydown', onKeyDown);
        window.addEventListener('pointerdown', onPointerDown);
        return () => {
            window.removeEventListener('keydown', onKeyDown);
            window.removeEventListener('pointerdown', onPointerDown);
        };
    }, [active]);
    return <div ref={ref}>{children}</div>;
}
