import React, { useEffect, useState, lazy, Suspense, useCallback } from 'react';
import { Route, Switch, useHistory, Redirect, useLocation } from 'react-router-dom';
import { useUserContext } from '@/components/UserContext';
import LoadingScreen from '@/components/loading/LoadingScreen';
import ReactModal from 'react-modal';
import { SurveyContextProvider } from '@/components/onboarding/survey/SurveyContext';
import Space from './Space';
import { useWorkspaces } from '@/components/WorkspacesContext';
import { useFirebaseContext } from '@/components/FirebaseContext';
import queryString from 'query-string';
import { track } from '@/analytics';
import useInterfaceSettings, { getInterfaceSettings } from '@/hooks/useInterfaceSettings';
import { locationToUrl, NoopNavigationProvider } from '@/components/PageNavigationProvider';
import SideBySide from './SideBySide';
import { useRecents } from '@/components/RecentsContext';
import InviteOnboardingScreen from '@/components/onboarding/InviteOnboardingScreen';
import EmbedProvider from '@/components/EmbedProviders';
import NotificationsProvider from './NotificationsProvider';
import { InviteMemberOnboarding } from '@/components/collaboration/InviteMemberOnboarding';
import SettingsProvider from '@/components/settings/SettingsProvider';
import TrySagaModalProvider from '@/components/onboarding/TrySagaModalProvider';
import { DEFAULT_FONT, SagaLocation, SpaceOperations, newBlankPage } from '@saga/shared';
import { useSpace } from '@/components/SpaceProvider';
import { WorkspaceContextProvider, useCurrentWorkspace } from './WorkspaceContext';
import Onboarding from './onboarding/Onboarding';

import * as Sentry from '@sentry/browser';
import * as api from '@saga/api';

import 'tippy.js/dist/tippy.css';
import Billing from './Billing';
import { useTranslation } from 'react-i18next';
import Admin from './admin';
import Logout from './Logout';
import VersionErrorBoundry from '@/components/VersionErrorBoundry';
import { createPage } from '@/utils/documentUtils';
import DuplicatePublicPage from './DuplicatePublicPage';
import { usePagesPermissions } from '@/components/PagesPermissionsBySpaceProvider';
import { PublicPageProvider } from '@/components/PublicPageProvider';
import PublicPageContainer from '@/components/PublicPageContainer';

const OnboardingScreen = lazy(() => import('@/components/onboarding/OnboardingScreen'));
const LoginModal = lazy(() => import('@/components/onboarding/LoginModal'));
const LoginFromInvite = lazy(() => import('@/components/onboarding/LoginFromInvite'));
const SignupModal = lazy(() => import('@/components/onboarding/SignupModal'));
const NewWorkspaceModal = lazy(() => import('@/components/onboarding/NewWorkspaceModal'));
const SpaceSurveyModal = lazy(() => import('@/components/onboarding/survey/SpaceSurveyModal'));
const TutorialModal = lazy(() => import('@/components/onboarding/TutorialModal'));
const CopyWorkspace = lazy(() => import('@/components/CopyWorkspace'));
const NotFound = lazy(() => import('@/components/NotFound'));
const SpaceNotFound = lazy(() => import('@/components/SpaceNotFound'));
const PublicSpace = lazy(() => import('@/components/PublicSpace'));
const AcceptInvite = lazy(() => import('@/components/AcceptInvite'));

ReactModal.setAppElement('#root');

function SpaceSurveyRedirect(props: { urlKey: string }) {
    const history = useHistory();
    const { user } = useUserContext();
    const { useCases, urlKey } = useCurrentWorkspace();
    const { workspaces, loading } = useWorkspaces();
    const { t } = useTranslation();

    useEffect(() => {
        if (useCases && useCases.length > 0) {
            history.push('/s');
        }
    }, [useCases, history, user]);

    return (
        <OnboardingScreen testId="space-survey">
            {!loading && (
                <SpaceSurveyModal
                    onSubmitted={() => {
                        // We only skip the tutorial if we already have more than one workspaces
                        // If it's just one, we always assume it's the first space that was created
                        if (workspaces.length > 1) {
                            history.push(`/s/${urlKey}/invite`);
                        } else {
                            history.push(`/tutorial/${urlKey}`);
                        }
                    }}
                    urlKey={props.urlKey}
                    submitButtonLabel={t('common.get_started')}
                    content={
                        <>
                            <Onboarding.Heading>{t('onboarding.what_is_this_workspace_for')}</Onboarding.Heading>
                        </>
                    }
                />
            )}
        </OnboardingScreen>
    );
}

function WorkspacesFallback() {
    const { loading, workspaces } = useWorkspaces();
    const { data: userInvitesData, loading: loadingUserInvites } = api.useUserInvitesQuery();
    const pageInvitePageId = localStorage.getItem('pageInvite::pageId');

    if (loading || loadingUserInvites || pageInvitePageId) {
        return <LoadingScreen />;
    }

    const firstWorkspace = workspaces[0];

    if (firstWorkspace) {
        return <Redirect to={`/s/${firstWorkspace.urlKey}`} push={false} />;
    } else if (userInvitesData && userInvitesData.userInvites.length > 0) {
        return <Redirect to="/invite-onboarding" />;
    } else {
        return <Redirect to="/new-workspace" />;
    }
}

function TrySaga() {
    const { workspaces, loading } = useWorkspaces();
    const parsed = queryString.parse(location.search);
    const redirect = parsed.redirect;

    React.useEffect(() => {
        track('try-saga-redirect', { redirect });
    }, [redirect]);

    if (loading) {
        return <LoadingScreen />;
    }

    if (workspaces.length > 0) {
        if (typeof redirect === 'string') {
            return <Redirect to={redirect} />;
        }
    }

    return <Redirect to="/new-workspace" />;
}

const settings = getInterfaceSettings();

toggleDarkMode(settings.darkMode ?? false);

function toggleDarkMode(darkMode: boolean) {
    if (darkMode) {
        if (!document.documentElement.classList.contains('dark')) {
            document.documentElement.classList.add('dark');
        }
    } else {
        document.documentElement.classList.remove('dark');
    }
}

function useDarkMode() {
    const [{ darkMode }] = useInterfaceSettings();

    React.useEffect(() => {
        toggleDarkMode(darkMode ?? false);
    }, [darkMode]);
}

const usePrefersColorScheme = () => {
    const getColorScheme = () => {
        try {
            if (
                localStorage.getItem('color-theme') === 'dark' ||
                (!('color-theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)
            ) {
                return 'dark';
            } else {
                return 'light';
            }
        } catch (e) {
            return 'light';
        }
    };

    const [prefersColorScheme, setPrefersColorScheme] = useState<'light' | 'dark'>(() => getColorScheme());

    React.useEffect(() => {
        // Could fail in older browsers
        try {
            window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', (evt) => {
                setPrefersColorScheme(evt.matches ? 'dark' : 'light');
            });
        } catch (e) {}
    }, []);

    return prefersColorScheme;
};

export default function App() {
    const history = useHistory();
    const [localHistory, setLocalHistory] = useState<string[]>([]);
    const [{ autoColorScheme }, setInterfaceSettings] = useInterfaceSettings();
    const { userSettings } = useUserContext();
    const { i18n } = useTranslation();

    useDarkMode();

    const preferColorScheme = usePrefersColorScheme();

    React.useEffect(() => {
        if (autoColorScheme) {
            setInterfaceSettings({ darkMode: preferColorScheme === 'dark' });
        }
        document.documentElement.dir = i18n.dir(i18n.language);
    }, [autoColorScheme, preferColorScheme, setInterfaceSettings, i18n.language, i18n]);

    const handleLocationChange = useCallback(
        function () {
            const { location } = history;
            switch (history.action) {
                case 'PUSH': {
                    if (location.key) {
                        setLocalHistory(localHistory.concat([location.key]));
                    }
                    break;
                }
                case 'POP': {
                    // Pop state changes fire for all navigations (back and forwards). We use the saved
                    // history state to distinguish between backward and forward navigations.
                    const lastHistoryIndex = localHistory.length - 1;
                    const previousLocationKey = localHistory[lastHistoryIndex - 1];
                    const isBack = previousLocationKey === location.key;
                    if (isBack) {
                        const newHistory = localHistory;
                        newHistory.pop();
                        setLocalHistory(newHistory);
                        if (location.pathname.startsWith('/s/') && location.pathname.split('/').length < 4) {
                            history.goBack();
                        }
                    } else {
                        if (location.key) {
                            setLocalHistory(localHistory.concat([location.key]));
                        }
                    }
                    break;
                }
            }
        },
        [history, localHistory],
    );

    useEffect(() => {
        const unsubscribeFromHistory = history.listen(handleLocationChange);

        return () => {
            if (unsubscribeFromHistory) unsubscribeFromHistory();
        };
    }, [handleLocationChange, history]);

    useEffect(() => {
        window.document.body.style.fontFamily = userSettings?.interfaceFont ?? DEFAULT_FONT;
    }, [userSettings?.interfaceFont]);

    useEffect(() => {
        const observer = new MutationObserver(() => {
            const editors: NodeListOf<HTMLDivElement> = document.querySelectorAll('#editor-container [role="textbox"]');
            const font = userSettings?.editorFont ?? DEFAULT_FONT;

            editors.forEach((editor) => {
                editor.style.fontFamily = font;
            });
        });

        observer.observe(document.body, {
            subtree: true,
            childList: true,
        });

        return () => observer.disconnect();
    }, [userSettings?.editorFont]);

    return (
        <main role="main" className="h-full antialiased select-none">
            <Suspense fallback={<LoadingScreen />}>
                <VersionErrorBoundry>
                    <AuthFlow />
                </VersionErrorBoundry>
            </Suspense>
        </main>
    );
}

function PageRedirect() {
    const { recentLocations } = useRecents();
    const history = useHistory();
    const { space, provider } = useSpace();
    const { urlKey } = useCurrentWorkspace();
    const { hasAccess } = usePagesPermissions();

    React.useLayoutEffect(() => {
        const setFirstPageAfterLoading = () => {
            const firstRecentLocation = recentLocations[0];
            const pageOrTask = firstRecentLocation ? SpaceOperations.findLocation(space, firstRecentLocation) : null;

            if (pageOrTask) {
                const url = locationToUrl(firstRecentLocation, urlKey);
                history.replace(url);
            } else {
                const pages = SpaceOperations.getPages(space, ['id', 'updatedAt', 'createdAt'], undefined, hasAccess);

                if (!pages.length) {
                    pages.push(createPage(space, newBlankPage({}), provider));
                }

                const sortedPages = pages.sort((a, b) => {
                    const dateA = new Date(a.updatedAt).getTime();
                    const dateB = new Date(b.updatedAt).getTime();
                    return dateB - dateA;
                });

                if (!sortedPages.length) return;

                const url = locationToUrl(SagaLocation.pageLocationFromId(sortedPages[0].id), urlKey);
                history.replace(url);
            }
        };

        setFirstPageAfterLoading();
    }, [space, recentLocations, urlKey, history, hasAccess, provider]);

    return null;
}

function CannyRedirect() {
    const { cannyToken } = useUserContext();

    React.useEffect(() => {
        const parsed: queryString.ParsedQuery<string> = queryString.parse(location.search);
        const companyID = parsed.companyID;
        const redirect = parsed.redirect;

        if (
            cannyToken &&
            typeof companyID === 'string' &&
            typeof redirect === 'string' &&
            redirect.indexOf('https://') === 0
        ) {
            window.location.href = `https://canny.io/api/redirects/sso?companyID=${companyID}&ssoToken=${cannyToken}&redirect=${redirect}`;
        }
    }, [cannyToken]);

    return null;
}

const OpenAssetFromApp = () => {
    const { search } = useLocation();
    const urlKeyParam = new URLSearchParams(search).get('urlKey');

    useEffect(() => {
        if (urlKeyParam) {
            window.setTimeout(() => (window.location.href = urlKeyParam), 1000);
        }
    }, [urlKeyParam]);

    return <LoadingScreen />;
};

type AuthState =
    | {
          _tag: 'notLoggedIn';
      }
    | {
          _tag: 'profileNotCompleted';
      }
    | {
          _tag: 'ready';
          user: api.UserFragmentFragment;
      }
    | {
          _tag: 'loading';
      }
    | {
          _tag: 'error';
      };

const useCalculateAuthState = (): AuthState => {
    const { firebaseUser, isLoading: isLoadingFirebaseUser } = useFirebaseContext();
    const { user, isLoading: loadingUser } = useUserContext();

    if (isLoadingFirebaseUser || loadingUser) return { _tag: 'loading' };

    if (!firebaseUser) return { _tag: 'notLoggedIn' };

    if (firebaseUser && (user == null || user.data.agreedToTerms === false)) return { _tag: 'profileNotCompleted' };

    if (user) return { _tag: 'ready', user };

    console.warn('Calculated auth state failed', { firebaseUser, user, isLoadingFirebaseUser, loadingUser });
    return { _tag: 'error' };
};

const pageIdPath =
    /^\/((?:[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}|00000000-0000-0000-0000-000000000000))$/i;

function PrivateRoute({
    path,
    children,
    isRouteAccessible = false,
    redirectRoute = '/',
}: {
    path: string;
    children: React.ReactNode;
    isRouteAccessible?: boolean;
    redirectRoute?: string;
}) {
    return (
        <Route
            path={path}
            render={() => (isRouteAccessible ? children : <Redirect to={{ pathname: redirectRoute }} />)}
        />
    );
}

const AuthFlow = () => {
    const authState = useCalculateAuthState();
    const { workspaces } = useWorkspaces();
    const { permissions } = useUserContext();

    React.useEffect(() => {
        if (authState._tag === 'error') {
            Sentry.captureMessage('Impossible state: Unable to calculate the authState');
        }
    }, [authState]);

    const location = useLocation();
    const pageIdMatch = location.pathname.match(pageIdPath)?.[1];

    const PublicPageFlow = React.useCallback(
        ({ pageId }) => (
            <TrySagaModalProvider>
                <NoopNavigationProvider>
                    <PublicPageProvider pageId={pageId}>
                        <PublicPageContainer />
                    </PublicPageProvider>
                </NoopNavigationProvider>
            </TrySagaModalProvider>
        ),
        [],
    );

    switch (authState._tag) {
        case 'loading':
            return <LoadingScreen />;
        case 'notLoggedIn':
            if (pageIdMatch) {
                return <PublicPageFlow pageId={pageIdMatch} />;
            }

            return (
                <Switch>
                    <Route path="/canny-redirect">
                        <Redirect
                            to={{
                                pathname: 'login',
                                search: `loginRedirect=${encodeURIComponent(window.location.href)}`,
                            }}
                        />
                    </Route>
                    <Route
                        path="/s/:urlKey/c/:collectionId"
                        render={({ match: { params } }) => (
                            <PublicSpace urlKey={params.urlKey}>
                                <EmbedProvider>
                                    <SideBySide
                                        location={{
                                            type: 'collection',
                                            collectionId: params.collectionId,
                                        }}
                                    />
                                </EmbedProvider>
                            </PublicSpace>
                        )}
                    />

                    <Route
                        path="/s/:urlKey/embed"
                        render={({ match: { params }, location }) => {
                            const queryParams = new URLSearchParams(location.search);

                            const embedLocation: SagaLocation.EmbedLocation = {
                                type: 'embed',
                                embedUrl: queryParams.get('embedUrl') ?? '/not-found',
                                openUrl: undefined,
                            };

                            return (
                                <PublicSpace urlKey={params.urlKey}>
                                    <EmbedProvider>
                                        <SideBySide location={embedLocation} />
                                    </EmbedProvider>
                                </PublicSpace>
                            );
                        }}
                    />

                    <Route
                        path="/s/:urlKey/collections"
                        render={({ match: { params } }) => (
                            <PublicSpace urlKey={params.urlKey}>
                                <EmbedProvider>
                                    <SideBySide location={{ type: 'allCollections' }} />
                                </EmbedProvider>
                            </PublicSpace>
                        )}
                    />

                    <Route
                        path="/s/:urlKey/pages/:viewId?"
                        render={({ match: { params } }) => (
                            <PublicSpace urlKey={params.urlKey}>
                                <EmbedProvider>
                                    <SideBySide
                                        location={{
                                            type: 'allPages',
                                            viewId: params.viewId ?? 'non-deleted',
                                        }}
                                    />
                                </EmbedProvider>
                            </PublicSpace>
                        )}
                    />

                    <Route
                        path="/s/:urlKey/tasks/:viewId?"
                        render={({ match: { params } }) => (
                            <PublicSpace urlKey={params.urlKey}>
                                <EmbedProvider>
                                    <SideBySide
                                        location={{
                                            type: 'allTasks',
                                            viewId: params.viewId ?? 'all',
                                        }}
                                    />
                                </EmbedProvider>
                            </PublicSpace>
                        )}
                    />

                    <Route
                        path="/s/:urlKey/asset"
                        render={({ match: { params } }) => (
                            <Space urlKey={params.urlKey}>
                                <OpenAssetFromApp />
                            </Space>
                        )}
                    />

                    <Route
                        path="/s/:urlKey/t/:taskId"
                        render={({ match: { params } }) => (
                            <PublicSpace urlKey={params.urlKey}>
                                <SideBySide location={SagaLocation.taskLocationFromId(params.taskId)} />
                            </PublicSpace>
                        )}
                    />

                    <Route
                        path="/s/:urlKey/:pageId"
                        render={({ match: { params } }) => (
                            <PublicSpace urlKey={params.urlKey}>
                                <SideBySide location={SagaLocation.pageLocationFromId(params.pageId)} />
                            </PublicSpace>
                        )}
                    />

                    <Route
                        path="/s/:urlKey"
                        render={({ match: { params } }) => (
                            <PublicSpace urlKey={params.urlKey}>
                                <PageRedirect />
                            </PublicSpace>
                        )}
                    />
                    <Route path="/s">
                        <Redirect to="/login" />
                    </Route>
                    <Route path="/signup">
                        <Redirect to="/login" />
                    </Route>

                    <Route
                        path="/login/invite/:inviteId"
                        render={({ match: { params } }) => {
                            return (
                                <OnboardingScreen>
                                    <LoginFromInvite inviteId={params.inviteId} inviteType="space" />
                                </OnboardingScreen>
                            );
                        }}
                    />

                    <Route
                        path="/login/page-invite/:inviteId"
                        render={({ match: { params } }) => {
                            return (
                                <OnboardingScreen>
                                    <LoginFromInvite inviteId={params.inviteId} inviteType="page" />
                                </OnboardingScreen>
                            );
                        }}
                    />

                    <Route
                        path="/login"
                        render={() => (
                            <OnboardingScreen>
                                <LoginModal />
                            </OnboardingScreen>
                        )}
                    />
                    <Route
                        path="/invite/:inviteId"
                        render={({ match: { params } }) => <Redirect to={`/login/invite/${params.inviteId}`} />}
                    />
                    <Route
                        path="/page-invite/:inviteId"
                        render={({ match: { params } }) => <Redirect to={`/login/page-invite/${params.inviteId}`} />}
                    />

                    <Route>
                        <Redirect to="/login" />
                    </Route>
                </Switch>
            );
        case 'profileNotCompleted':
            return (
                <Switch>
                    <Route path="/signup">
                        <OnboardingScreen>
                            <SignupModal />
                        </OnboardingScreen>
                    </Route>
                    <Route
                        exact
                        path="/s/:urlKey/copy"
                        render={({ match: { params } }) => <CopyWorkspace urlKey={params.urlKey} />}
                    />
                    <Route
                        path="/login/invite/:inviteId"
                        render={({ match: { params } }) => <Redirect to={`/invite/${params.inviteId}`} />}
                    />
                    <Route
                        path="/login/page-invite/:inviteId"
                        render={({ match: { params } }) => <Redirect to={`/page-invite/${params.inviteId}`} />}
                    />
                    <Route
                        path="/invite/:inviteId"
                        render={({ match: { params } }) => (
                            <AcceptInvite authStateTag={authState._tag} inviteId={params.inviteId} inviteType="space" />
                        )}
                    />
                    <Route
                        path="/page-invite/:inviteId"
                        render={({ match: { params } }) => (
                            <AcceptInvite authStateTag={authState._tag} inviteId={params.inviteId} inviteType="page" />
                        )}
                    />
                    <Route>
                        <Redirect to="/signup" />
                    </Route>
                </Switch>
            );
        case 'ready':
            const parsed: queryString.ParsedQuery<string> = queryString.parse(location.search);
            const loginRedirect = parsed.loginRedirect;

            if (typeof loginRedirect === 'string') {
                return <Redirect to={loginRedirect} />;
            }

            if (pageIdMatch) {
                return <PublicPageFlow pageId={pageIdMatch} />;
            }

            return (
                <SettingsProvider>
                    <NotificationsProvider>
                        <Suspense fallback={<LoadingScreen />}>
                            <Switch>
                                <Route path="/logout" render={() => <Logout />} />
                                <Route path="/canny-redirect">
                                    <CannyRedirect />
                                </Route>
                                <Route
                                    exact
                                    path="/s/:urlKey/copy"
                                    render={({ match: { params } }) => <CopyWorkspace urlKey={params.urlKey} />}
                                />
                                <Route
                                    path="/invite/:inviteId"
                                    render={({ match: { params } }) => (
                                        <AcceptInvite
                                            authStateTag={authState._tag}
                                            inviteId={params.inviteId}
                                            inviteType="space"
                                        />
                                    )}
                                />
                                <Route
                                    path="/page-invite/:inviteId"
                                    render={({ match: { params } }) => (
                                        <AcceptInvite
                                            authStateTag={authState._tag}
                                            inviteId={params.inviteId}
                                            inviteType="page"
                                        />
                                    )}
                                />
                                <Route path="/signup">
                                    <Redirect to="/s" />
                                </Route>

                                <Route
                                    path="/invite-onboarding"
                                    render={() => {
                                        return (
                                            <OnboardingScreen>
                                                <InviteOnboardingScreen />
                                            </OnboardingScreen>
                                        );
                                    }}
                                />

                                <Route
                                    path="/new-workspace"
                                    render={() => {
                                        return (
                                            <OnboardingScreen hasLogOutButton={workspaces.length < 1}>
                                                <NewWorkspaceModal />
                                            </OnboardingScreen>
                                        );
                                    }}
                                ></Route>
                                <Route
                                    path="/survey/:urlKey"
                                    render={({ match: { params } }) => (
                                        <Space urlKey={params.urlKey}>
                                            <SpaceSurveyRedirect urlKey={params.urlKey} />
                                        </Space>
                                    )}
                                />
                                <Route path="/try-saga" exact render={() => <TrySaga />} />
                                <Route
                                    path="/tutorial/:urlKey"
                                    render={({ match: { params } }) => (
                                        <WorkspaceContextProvider urlKey={params.urlKey}>
                                            <OnboardingScreen>
                                                <TutorialModal urlKey={params.urlKey} />
                                            </OnboardingScreen>
                                        </WorkspaceContextProvider>
                                    )}
                                />

                                <Route
                                    path="/s/:urlKey/invite"
                                    render={({ match: { params } }) => (
                                        <Space urlKey={params.urlKey}>
                                            <InviteMemberOnboarding urlKey={params.urlKey} />
                                        </Space>
                                    )}
                                />

                                <Route
                                    path="/s/:urlKey/c/:collectionId"
                                    render={({ match: { params } }) => (
                                        <Space urlKey={params.urlKey}>
                                            <SurveyContextProvider urlKey={params.urlKey}>
                                                <EmbedProvider>
                                                    <SideBySide
                                                        location={{
                                                            type: 'collection',
                                                            collectionId: params.collectionId,
                                                        }}
                                                    />
                                                </EmbedProvider>
                                            </SurveyContextProvider>
                                        </Space>
                                    )}
                                />

                                <Route
                                    path="/s/:urlKey/t/:taskId"
                                    render={({ match: { params } }) => (
                                        <Space urlKey={params.urlKey}>
                                            <SurveyContextProvider urlKey={params.urlKey}>
                                                <EmbedProvider>
                                                    <SideBySide
                                                        location={{
                                                            type: 'task',
                                                            taskId: params.taskId,
                                                            blockId: undefined,
                                                        }}
                                                    />
                                                </EmbedProvider>
                                            </SurveyContextProvider>
                                        </Space>
                                    )}
                                />

                                <Route
                                    path="/s/:urlKey/embed"
                                    render={({ match: { params }, location }) => {
                                        const queryParams = new URLSearchParams(location.search);

                                        const embedLocation: SagaLocation.EmbedLocation = {
                                            type: 'embed',
                                            embedUrl: queryParams.get('embedUrl') ?? '/not-found',
                                            openUrl: undefined,
                                        };

                                        return (
                                            <Space urlKey={params.urlKey}>
                                                <SurveyContextProvider urlKey={params.urlKey}>
                                                    <EmbedProvider>
                                                        <SideBySide location={embedLocation} />
                                                    </EmbedProvider>
                                                </SurveyContextProvider>
                                            </Space>
                                        );
                                    }}
                                />

                                <Route
                                    path="/s/:urlKey/collections"
                                    render={({ match: { params } }) => (
                                        <Space urlKey={params.urlKey}>
                                            <SurveyContextProvider urlKey={params.urlKey}>
                                                <EmbedProvider>
                                                    <SideBySide location={{ type: 'allCollections' }} />
                                                </EmbedProvider>
                                            </SurveyContextProvider>
                                        </Space>
                                    )}
                                />

                                <Route
                                    path="/s/:urlKey/pages/:viewId?"
                                    render={({ match: { params } }) => {
                                        return (
                                            <Space urlKey={params.urlKey}>
                                                <SurveyContextProvider urlKey={params.urlKey}>
                                                    <EmbedProvider>
                                                        <SideBySide
                                                            location={{
                                                                type: 'allPages',
                                                                viewId: params.viewId ?? 'non-deleted',
                                                            }}
                                                        />
                                                    </EmbedProvider>
                                                </SurveyContextProvider>
                                            </Space>
                                        );
                                    }}
                                />

                                <Route
                                    path="/s/:urlKey/tasks/:viewId?"
                                    render={({ match: { params } }) => (
                                        <Space urlKey={params.urlKey}>
                                            <SurveyContextProvider urlKey={params.urlKey}>
                                                <EmbedProvider>
                                                    <SideBySide
                                                        location={{
                                                            type: 'allTasks',
                                                            viewId: params.viewId ?? 'all',
                                                        }}
                                                    />
                                                </EmbedProvider>
                                            </SurveyContextProvider>
                                        </Space>
                                    )}
                                />

                                <Route
                                    path="/s/:urlKey/asset"
                                    render={({ match: { params } }) => (
                                        <Space urlKey={params.urlKey}>
                                            <OpenAssetFromApp />
                                        </Space>
                                    )}
                                />

                                <Route
                                    path={`/s/:urlKey/duplicatePage`}
                                    render={({ match: { params } }) => (
                                        <Space urlKey={params.urlKey}>
                                            <DuplicatePublicPage />
                                        </Space>
                                    )}
                                />

                                <Route
                                    path="/s/:urlKey/saga-ai"
                                    render={({ match: { params } }) => (
                                        <Space urlKey={params.urlKey}>
                                            <SurveyContextProvider urlKey={params.urlKey}>
                                                <EmbedProvider>
                                                    <SideBySide location={{ type: 'sagaAI', threadId: undefined }} />
                                                </EmbedProvider>
                                            </SurveyContextProvider>
                                        </Space>
                                    )}
                                />

                                <Route
                                    path="/s/:urlKey/:pageId"
                                    render={({ match: { params } }) => (
                                        <Space urlKey={params.urlKey}>
                                            <SurveyContextProvider urlKey={params.urlKey}>
                                                <EmbedProvider>
                                                    <SideBySide
                                                        location={{
                                                            type: 'page',
                                                            pageId: params.pageId,
                                                            blockId: undefined,
                                                        }}
                                                    />
                                                </EmbedProvider>
                                            </SurveyContextProvider>
                                        </Space>
                                    )}
                                />

                                <Route
                                    path="/s/:urlKey"
                                    render={({ match: { params } }) => (
                                        <Space urlKey={params.urlKey}>
                                            <PageRedirect />
                                        </Space>
                                    )}
                                />

                                <Route path="/s">
                                    <WorkspacesFallback />
                                </Route>

                                <Route
                                    path="/not-found"
                                    render={() => <SpaceNotFound userEmail={authState.user.data.email} />}
                                />

                                <Route path="/billing">
                                    <Billing search={window.location.search} />
                                </Route>

                                <PrivateRoute isRouteAccessible={permissions.isAdmin} redirectRoute="/" path="/admin">
                                    <Admin />
                                </PrivateRoute>

                                <Route>
                                    <WorkspacesFallback />
                                </Route>
                            </Switch>
                        </Suspense>
                    </NotificationsProvider>
                </SettingsProvider>
            );
        case 'error':
            return (
                <Switch>
                    <Route path="/not-found" component={NotFound} />
                    <Route>
                        <Redirect to="/not-found" />
                    </Route>
                </Switch>
            );
    }
};
