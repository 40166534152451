import React from 'react';

import { useUserContext } from '@/components/UserContext';
import { DEFAULT_FONT } from '../../../shared/src';

type FontType = 'editor' | 'interface';

type Props = {
    type: FontType;
    children: React.ReactNode;
};

export function WithExplicitFontType({ type, children }: Props) {
    const ref = React.useRef<HTMLDivElement>(null);
    const { userSettings } = useUserContext();

    React.useLayoutEffect(() => {
        if (ref.current) {
            switch (type) {
                case 'editor':
                    ref.current.style.fontFamily = userSettings?.editorFont ?? DEFAULT_FONT;
                    break;
                case 'interface':
                    ref.current.style.fontFamily = userSettings?.interfaceFont ?? DEFAULT_FONT;
                    break;
                default:
                    break;
            }
        }
    }, [userSettings?.editorFont, userSettings?.interfaceFont, type]);

    return <div ref={ref}>{children}</div>;
}
