import React, { useState } from 'react';
import { useWorkspaceContext } from '@/components/WorkspaceContext';
import { useHistory } from 'react-router-dom';
import Modal from '@/components/Modal';
import Button from '@/components/styled/Button';
import * as api from '@saga/api';
import { useFirebaseContext } from './FirebaseContext';
import CopyWorkspaceSvg from '@/assets/CopyWorkspaceSvg';
import { LoginState } from './onboarding/LoginModal';
import { track } from '@/analytics';
import Input from './styled/Input';
import useRequestSignInLink from '@/hooks/useRequestSignInLink';
import { useSetSettings } from './settings/SettingsProvider';
import { useUserContext } from './UserContext';
import { NewWorkspaceModal } from './billing/NewWorkspaceModal';

type CopyWorkspaceModalProps = {
    isOpen: boolean;
    onClose(): void;
    setIsBillingModalOpen(show: boolean): void;
};

function CopyWorkspaceWithLoginForm() {
    const { currentUrlKey } = useWorkspaceContext();
    const [state, setState] = useState<LoginState>(LoginState.INITIAL);
    const [email, setEmail] = useState('');
    const inputRef = React.useRef<HTMLInputElement>(null);
    const sendEmail = useRequestSignInLink();

    async function onSubmit(e: React.FormEvent) {
        e.preventDefault();
        track('copy-workspace-with-login');
        setState(LoginState.LOADING);
        const currentUrl = new URL(window.location.href);
        await sendEmail({ email, redirectUrl: `${currentUrl.origin}/s/${currentUrlKey}/copy` });
        setState(LoginState.EMAIL_SENT);
    }

    return (
        <form onSubmit={onSubmit}>
            {state === LoginState.EMAIL_SENT && (
                <Modal.Content>
                    <Modal.Title>📨 Check your inbox</Modal.Title>
                    <p>We just sent you a sign in link at this email address:</p>
                    <div className="flex items-center max-w-sm w-full justify-center space-x-2">
                        <Modal.Input type="email" id="email" value={email} disabled={true} />
                        <Button variant="secondary" onClick={() => setState(LoginState.INITIAL)}>
                            Edit
                        </Button>
                    </div>
                    <p>You can now close this modal.</p>
                </Modal.Content>
            )}

            {state !== LoginState.EMAIL_SENT && (
                <Modal.Content>
                    <Modal.Title>Copy Workspace</Modal.Title>

                    <p className="text-lg max-w-sm text-center">
                        All pages, collections and settings of this Workspace will be copied into a new Workspace.
                    </p>

                    <div className="space-y-4 max-w-sm w-full">
                        <Input
                            autoFocus={true}
                            innerRef={inputRef}
                            disabled={state === LoginState.LOADING}
                            required
                            placeholder="Enter your email address..."
                            value={email}
                            type="email"
                            onChange={(e) => setEmail(e.target.value)}
                        />

                        <Button
                            className="w-full"
                            type="submit"
                            disabled={state === LoginState.LOADING || email.trim() === ''}
                            variant="primary"
                        >
                            Continue with email
                        </Button>
                    </div>
                </Modal.Content>
            )}
        </form>
    );
}

function CopyWorkspaceForm({
    onClose,
    setIsBillingModalOpen,
}: {
    onClose(): void;
    setIsBillingModalOpen(show: boolean): void;
}) {
    const history = useHistory();
    const { currentUrlKey } = useWorkspaceContext();
    const { permissions } = useUserContext();
    const setSettings = useSetSettings();
    const [copyWorkspace, { loading }] = api.useCopyWorkspaceMutation({
        refetchQueries: [api.SpacesDocument],
        awaitRefetchQueries: true,
    });

    async function onSubmit(e: React.FormEvent) {
        e.preventDefault();

        if (!permissions.canCreateFreeSpace) {
            setIsBillingModalOpen(true);
            onClose();
            return;
        }

        track('copy-workpace');
        const result = await copyWorkspace({
            variables: { input: { urlKey: currentUrlKey } },
            refetchQueries: [api.UserDataDocument],
        });
        const newSpaceUrlKey = result.data?.copyWorkspace.urlKey;
        if (newSpaceUrlKey) {
            // We want to close the settings in case they are open (copying could be triggered from the settings)
            setSettings(null);
            onClose();
            history.push(`/s/${newSpaceUrlKey}`);
        }
    }

    return (
        <form onSubmit={onSubmit}>
            <Modal.Content>
                <Modal.Title>Copy Workspace</Modal.Title>

                <p className="text-lg max-w-sm text-center">
                    All pages, collections and settings of this Workspace will be copied into a new Workspace.
                </p>

                <div className="space-y-4 max-w-sm w-full">
                    <Button className="w-full" type="submit" disabled={loading} variant="primary">
                        Copy Workspace
                    </Button>
                </div>
            </Modal.Content>
        </form>
    );
}

function CopyWorkspaceModal({ isOpen, onClose, setIsBillingModalOpen }: CopyWorkspaceModalProps) {
    const { firebaseUser } = useFirebaseContext();
    const needsLogin = firebaseUser == null;
    const { currentWorkspace } = useWorkspaceContext();

    return (
        <Modal.Medium isOpen={isOpen} onClose={onClose}>
            <Modal.CloseButton />
            <div className="bg-saga-green bg-opacity-25">
                <Modal.HeroContainer>
                    <div className="py-8 px-12">
                        <CopyWorkspaceSvg className="w-56 md:w-96" title={currentWorkspace.title} />
                    </div>
                </Modal.HeroContainer>
            </div>

            {needsLogin ? (
                <CopyWorkspaceWithLoginForm />
            ) : (
                <CopyWorkspaceForm
                    onClose={() => onClose()}
                    setIsBillingModalOpen={(show) => setIsBillingModalOpen(show)}
                />
            )}
        </Modal.Medium>
    );
}

const OpenCopyWorkspaceModal = React.createContext<() => void>(() => {});

export function useOpenCopyWorkspaceModal() {
    return React.useContext(OpenCopyWorkspaceModal);
}

export default function CopyWorkspaceModalProvider({ children }: { children: React.ReactNode }) {
    const [isCopyWorkspaceModalOpen, setIsCopyWorkspaceModalOpen] = useState(false);
    const [isBillingModalOpen, setIsBillingModalOpen] = useState(false);

    const openModal = React.useCallback(() => {
        track('open-copy-workspace-modal');
        setIsCopyWorkspaceModalOpen(true);
    }, []);

    return (
        <OpenCopyWorkspaceModal.Provider value={openModal}>
            {children}
            <CopyWorkspaceModal
                isOpen={isCopyWorkspaceModalOpen}
                onClose={() => setIsCopyWorkspaceModalOpen(false)}
                setIsBillingModalOpen={(show) => setIsBillingModalOpen(show)}
            />

            <Modal.Jumbo isOpen={isBillingModalOpen}>
                <NewWorkspaceModal onClose={() => setIsBillingModalOpen(false)} action="duplicate" />
            </Modal.Jumbo>
        </OpenCopyWorkspaceModal.Provider>
    );
}
