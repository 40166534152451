import React, { useContext } from 'react';
import * as Y from 'yjs';
import { debugLog, isDebug } from '@/utils';
import LoadingScreen from './loading/LoadingScreen';
import { setupRealtimeProvider } from '@/lib/setupRealtimeProvider';
import AuthenticationErrorModal from './AuthenticationErrorModal';
import { IndexeddbPersistence } from 'y-indexeddb';
import { Awareness } from 'y-protocols/awareness';
import { Unauthorized } from '@hocuspocus/common';
import * as Sentry from '@sentry/browser';
import { PUBLIC_TOKEN, transformBlocksToSharedType } from '@/../../shared/src';
import { getCurrentUser } from '@/firebase';
import { User } from 'firebase/auth';
import { HocuspocusProvider } from '@hocuspocus/provider';
import { Stateless } from '@saga/shared';

export type RealtimeStateSynced = {
    type: 'SYNCED';
    document: Y.Doc;
    awareness: Awareness | null;
    documentName: string;
    remoteSynced: boolean;
    provider: HocuspocusProvider;
};
export type RealtimeStateDisconnected = {
    type: 'DISCONNECTED';
    document: Y.Doc;
    awareness: Awareness | null;
    documentName: string;
    remoteSynced: boolean;
    provider: HocuspocusProvider;
};
export type RealtimeStateConnectionClosed = {
    type: 'CONNECTION_CLOSED';
    document: Y.Doc;
    awareness: Awareness | null;
    documentName: string;
    remoteSynced: boolean;
    provider: HocuspocusProvider;
};

export type RealtimeStateWithDocument = RealtimeStateSynced | RealtimeStateDisconnected | RealtimeStateConnectionClosed;

export type RealtimeState =
    | { type: 'LOADING' }
    | { type: 'AUTHENTICATION_FAILED' }
    | { type: 'MEMBER_REMOVED' }
    | RealtimeStateWithDocument;

export const RealtimeContext = React.createContext<{
    state: RealtimeState;
    searchMap: Y.Map<any> | null;
}>({
    state: { type: 'LOADING' },
    searchMap: null,
});

export function useRealtime() {
    return useContext(RealtimeContext);
}

export function useAwareness() {
    const realtime = useRealtime();

    if (isStateWithDocument(realtime.state)) {
        return realtime.state.awareness;
    }

    return undefined;
}

export function isStateTypeWithDocument(
    stateType: RealtimeState['type'],
): stateType is 'SYNCED' | 'DISCONNECTED' | 'CONNECTION_CLOSED' {
    return stateType === 'SYNCED' || stateType === 'DISCONNECTED' || stateType === 'CONNECTION_CLOSED';
}

export function isStateWithDocument(state: RealtimeState): state is RealtimeStateWithDocument {
    return isStateTypeWithDocument(state.type);
}

function assertWithDocument(state: RealtimeState): asserts state is RealtimeStateWithDocument {
    if (!isStateWithDocument(state)) {
        throw new Error('Not connected');
    }
}

export function useRealtimeStateWithDocument() {
    const data = useRealtime();
    assertWithDocument(data.state);
    return React.useMemo(
        () => ({ state: data.state as RealtimeStateWithDocument, searchMap: data.searchMap }),
        [data.state, data.searchMap],
    );
}

export function setupOfflineProvider(name: string, document: Y.Doc, currentUser: User | null) {
    return new Promise<IndexeddbPersistence>((resolve) => {
        const offline = new IndexeddbPersistence(name, document);

        if (isDebug()) {
            // @ts-expect-error
            window.offline = offline;
        }

        debugLog('Offline provider connected');
        offline.on('synced', () => resolve(offline));
        offline.on('error', (e: Error) => {
            debugLog('Offline provider error', e);
            Sentry.captureException(e, { extra: { errorFormatted: 'Error in Offline provider', currentUser } });
            return () => resolve(offline);
        });
    });
}

export async function getToken(currentUser: User | null, documentName: string) {
    debugLog('setupProvider: getToken called');

    if (currentUser == null) {
        console.warn('RealtimeProvider: Attempting to get token without a current user.');
        Sentry.captureMessage('RealtimeProvider: Attempting to get token without a current user.', {
            extra: { documentName },
        });

        return PUBLIC_TOKEN;
    }

    const token = await currentUser.getIdToken(true).catch((e) => {
        Sentry.captureException(e);
        return null;
    });

    if (token == null) {
        console.warn('RealtimeProvider: Attempting to get token, but token is null.');
        Sentry.captureMessage('RealtimeProvider: Attempting to get token, but token is null.', {
            extra: { documentName, currentUser },
        });
        return PUBLIC_TOKEN;
    }

    return token;
}

export function useRealtimeProvider({
    offlineDocumentName,
    documentName,
    allowOfflineLoadingState,
    onSyncedCallback,
}: {
    offlineDocumentName: string | null;
    documentName: string | null;
    allowOfflineLoadingState?: boolean;
    onSyncedCallback?: (spaceProvider: HocuspocusProvider) => void;
}) {
    const disconnectedTimeoutRef = React.useRef<ReturnType<typeof setTimeout> | null>(null);
    const [state, setState] = React.useState<RealtimeState>({ type: 'LOADING' });
    const searchDoc = React.useMemo(() => new Y.Doc(), []);

    React.useEffect(() => {
        if (!offlineDocumentName || !documentName) return;
        setState({ type: 'LOADING' });

        const run = async () => {
            const document = new Y.Doc();

            const currentUser = getCurrentUser();

            const offlineProvider = await setupOfflineProvider(offlineDocumentName, document, currentUser);

            const { cleanup, spaceProvider } = await setupRealtimeProvider({
                document,
                name: documentName,
                getToken: () => getToken(currentUser, documentName),
                onSynced() {
                    setState({
                        type: 'SYNCED',
                        document,
                        awareness: spaceProvider.awareness,
                        documentName,
                        remoteSynced: spaceProvider.isSynced,
                        provider: spaceProvider,
                    });
                    onSyncedCallback && onSyncedCallback(spaceProvider);
                    if (disconnectedTimeoutRef.current) {
                        clearTimeout(disconnectedTimeoutRef.current);
                    }

                    spaceProvider.sendStateless(Stateless.encodeStateless('spaceblocksRequest'));
                },
                onDisconnect() {
                    setState({
                        type: 'DISCONNECTED',
                        document,
                        awareness: spaceProvider.awareness,
                        documentName,
                        remoteSynced: spaceProvider.isSynced,
                        provider: spaceProvider,
                    });
                },
                onAuthenticationFailed() {
                    setState({ type: 'AUTHENTICATION_FAILED' });
                },
                onClose(event) {
                    if (event.code === 4004 && event.reason === 'Member removed') {
                        setState({ type: 'MEMBER_REMOVED' });
                        // We need to destroy the provider to prevent reconnection and the other hocuspocus states to be emitted
                        spaceProvider.destroy();
                        offlineProvider.destroy();
                    }

                    // Connection was closed by the server, for example if the firebase token expired and couldn't be refreshed for some reason
                    if (event.code === Unauthorized.code) {
                        const currentUser = getCurrentUser();

                        Sentry.captureMessage("RealtimeProvider: Connection closed by server due to 'Unauthorized'", {
                            extra: { event, documentName, currentUser },
                        });

                        setState({
                            type: 'CONNECTION_CLOSED',
                            document,
                            awareness: spaceProvider.awareness,
                            documentName,
                            remoteSynced: spaceProvider.isSynced,
                            provider: spaceProvider,
                        });

                        // We need to destroy the provider to prevent reconnection and the other hocuspocus states to be emitted
                        spaceProvider.destroy();
                    }
                },
                onStateless: async (payload) => {
                    const data = Stateless.decodeStateless(payload);
                    if (data?.type === 'spaceblocksReceive') {
                        Y.transact(searchDoc, () => {
                            const yMap = searchDoc.getMap('spaceblocks');

                            Object.entries(data.data).forEach(([key, value]) => {
                                yMap.set(key, transformBlocksToSharedType(value));
                            });
                        });
                    }
                },
            });

            // This could make loading way faster, but we need to adjust the disconnected UI a bit or find a better loading state
            setState((state) => {
                if (allowOfflineLoadingState) {
                    return {
                        type: 'SYNCED',
                        document,
                        awareness: spaceProvider.awareness,
                        documentName,
                        remoteSynced: spaceProvider.isSynced,
                        provider: spaceProvider,
                    };
                }
                return state;
            });

            if (isDebug()) {
                // @ts-expect-error
                window.provider = spaceProvider;
            }

            return () => {
                offlineProvider.destroy();
                searchDoc.destroy();
                cleanup();
            };
        };

        const promise = run();

        return () => {
            promise.then((cleanup) => cleanup());
        };
    }, [documentName, offlineDocumentName, allowOfflineLoadingState, onSyncedCallback, searchDoc]);

    return { state, searchDoc };
}

export const RealtimeProvider = ({
    children,
    documentName,
    offlineDocumentName,
    allowOfflineLoadingState,
}: {
    children: JSX.Element;
    documentName: string;
    offlineDocumentName: string;
    allowOfflineLoadingState?: boolean;
}) => {
    const { state, searchDoc } = useRealtimeProvider({
        documentName,
        offlineDocumentName,
        allowOfflineLoadingState,
    });

    const searchMap = React.useMemo(() => {
        return searchDoc?.getMap('spaceblocks') ?? null;
    }, [searchDoc]);

    const context = React.useMemo(() => ({ state, searchMap }), [state, searchMap]);

    switch (state.type) {
        case 'AUTHENTICATION_FAILED':
            return <AuthenticationErrorModal option="reload-current-page" />;

        case 'MEMBER_REMOVED':
            return <AuthenticationErrorModal option="member-removed" />;

        case 'LOADING':
            return <LoadingScreen />;

        // We allow the user to continue work even when the provider is disconnected
        case 'SYNCED':
        case 'DISCONNECTED':
        case 'CONNECTION_CLOSED':
            if (documentName !== state.documentName) {
                return <LoadingScreen />;
            }
            if (state.type === 'CONNECTION_CLOSED' || state.type === 'DISCONNECTED') {
                Sentry.captureMessage('RealtimeProvider: Connection closed or Disconnected', {
                    extra: { stateType: state.type },
                });
            }
            return <RealtimeContext.Provider value={context}>{children}</RealtimeContext.Provider>;
    }
};
