import { Editor, Node as SlateNode, Transforms } from 'slate';
import { Node as UnistNode, Parent } from 'unist';
import { v4 as uuid } from 'uuid';
import { map as unistMap } from 'unist-util-map';
import {
    BlockType,
    SagaElement,
    isHeading,
    isSagaElement,
    isBlockContainer,
    nodesHaveSameType,
    isParagraph,
    isBlockquote,
    isBlockType,
    isListItem,
    isCheckListItem,
    isSagaText,
    mapBlock,
    isTable,
    isTableRow,
    isTableCell,
    isInline,
    isTitle,
    isNumberedList,
    isAnyListItem,
    isNumberedListItem,
} from '..';

function addIdsToNodes(nodes: SagaElement[]): SagaElement[] {
    const root = { type: 'root', children: nodes } as UnistNode;
    const result = unistMap(root, (node) => {
        if (isSagaText(node)) return node;

        if ('id' in node && (node as any).id != null) {
            return node;
        }
        return { ...node, id: uuid() };
    }) as Parent;
    return result.children as SagaElement[];
}

export function insertBlocks(editor: Editor, blocks: SagaElement[]): boolean {
    const { selection } = editor;

    if (selection) {
        const [, path] = Editor.first(editor, selection);
        const [currentNode, currentPath] = Editor.above(editor, { at: path, match: isSagaElement }) ?? [];
        const [tableNode, tableNodePath] = Editor.above(editor, { at: path, match: isTable }) ?? [];

        const tableCellEntry = Editor.above(editor, { at: selection, match: isTableCell });
        const isWithinTableCell = isTableCell(currentNode) || tableCellEntry != null;

        const fragment = addIdsToNodes(blocks)
            .map((block) =>
                mapBlock(block, (block) => {
                    // we want to disallow inserting tables into a table cell
                    if (isWithinTableCell && isBlockType(block, [isTable, isTableRow, isTableCell])) {
                        return block.children;
                    }
                    return block;
                }),
            )
            .flat();

        const textLength = currentNode ? SlateNode.string(currentNode).length : 0;
        const isEmpty = textLength === 0;
        const [firstNode, ...restNodes] = fragment;

        const [last, lastPath] = Editor.last(editor, selection);
        const [secondToLast] = Editor.previous(editor, { at: lastPath }) ?? [];
        const isFullSelection =
            editor.selection &&
            Editor.isEdge(editor, selection.focus, selection.focus.path) &&
            Editor.isEdge(editor, selection.anchor, selection.anchor.path);

        // if last element is inline, insert string with whitespace so insertFragment works properly
        if (isSagaText(last) && !last.text.length && isInline(secondToLast)) {
            Transforms.insertText(editor, ' ', { at: lastPath });
        }

        if (tableNode && !isWithinTableCell) {
            Editor.withoutNormalizing(editor, () => {
                Transforms.removeNodes(editor, { at: tableNodePath });
                Transforms.insertNodes(editor, fragment);
            });
            return true;
        }

        if (isNumberedListItem(currentNode) && isNumberedList(firstNode)) {
            Editor.withoutNormalizing(editor, () => {
                Transforms.insertNodes(editor, fragment);

                // delete previous item if selected
                if (isFullSelection) {
                    Transforms.delete(editor, { at: currentPath });
                }
            });

            return true;
        }

        if (isAnyListItem(currentNode) && firstNode.type !== currentNode.type) {
            if (isBlockType(firstNode, [isAnyListItem, isParagraph])) {
                Transforms.insertFragment(
                    editor,
                    fragment.map((block) => ({
                        ...block,
                        checked: isCheckListItem(currentNode) ? currentNode.checked : undefined,
                        type: block.type === firstNode.type ? currentNode.type : block.type,
                    })),
                );
                return true;
            }

            if (isNumberedList(firstNode)) {
                Transforms.insertFragment(editor, [
                    ...firstNode.children.map((child) => ({
                        ...child,
                        checked: isCheckListItem(currentNode) ? currentNode.checked : undefined,
                        type: isNumberedListItem(child) ? currentNode.type : child.type,
                    })),
                    ...restNodes,
                ]);

                return true;
            }

            Transforms.insertFragment(editor, fragment);

            return true;
        }

        if (isSagaElement(firstNode) && (isBlockContainer(firstNode) || isNumberedList(firstNode))) {
            isFullSelection ? Transforms.insertFragment(editor, fragment) : Transforms.insertNodes(editor, fragment);
            return true;
        }

        if (isSagaElement(firstNode) && isBlockContainer(firstNode)) {
            Transforms.insertNodes(editor, fragment);
            return true;
        }

        if (isListItem(firstNode) || isCheckListItem(firstNode) || isBlockquote(firstNode)) {
            Transforms.insertFragment(editor, fragment);
            return true;
        }

        if (currentNode && isEmpty) {
            //this is for inserting nested tables from pasting html in our editor
            if (fragment[fragment.length - 1].type === 'table' || fragment[fragment.length - 1].type === 'table-row') {
                Transforms.insertNodes(editor, fragment, { at: lastPath });
            } else {
                Transforms.insertFragment(editor, fragment);
            }

            // makes sure that the empty node gets the correct formatting from pasting
            if (editor.isVoid(firstNode)) {
                Transforms.setNodes<SagaElement>(editor, firstNode, { at: selection });
            } else if (isHeading(firstNode)) {
                // if the first node is an heading we keep the correct type
                // otherwise it'll be formatted as plain text
                Transforms.setNodes(editor, { ...firstNode }, { at: selection });
            } else if (currentNode.type !== 'paragraph') {
                Transforms.setNodes(editor, { ...firstNode, type: currentNode.type }, { at: selection });
            }

            return true;
        }

        if (restNodes.length === 0) {
            Transforms.insertFragment(editor, fragment);
            return true;
        }

        if (
            currentNode &&
            nodesHaveSameType(currentNode, firstNode) &&
            isBlockType(currentNode, [isParagraph, isBlockquote, isTitle])
        ) {
            Transforms.insertFragment(editor, fragment);
            return true;
        }

        if (
            currentNode &&
            isSagaElement(firstNode) &&
            isHeading(firstNode) &&
            isSagaElement(currentNode) &&
            isHeading(currentNode)
        ) {
            Transforms.insertFragment(editor, [
                ...fragment,
                // This forces the editor to not merge all the other headings if they are present
                { type: BlockType.PARAGRAPH, children: [{ text: '' }], id: uuid() },
            ]);

            //  At this point we need to correct the selection after inserting the extra paragraph
            Transforms.move(editor, { distance: 1, unit: 'offset', reverse: true });

            return true;
        }

        Transforms.insertNodes(editor, fragment);

        return true;
    }
    return false;
}
