import React, { useContext } from 'react';
import { Sorting } from '@saga/shared';
import { TableColumn } from '@/types';

type TableContextType = {
    search: string;
    setSearch: (search: string) => void;
    sorting: Sorting | null;
    setSorting: (sorting: Sorting | null) => void;
    columns: { [key: string]: TableColumn };
};

export const TableContext = React.createContext<TableContextType>({
    search: '',
    setSearch: () => {},
    sorting: null,
    setSorting: () => {},
    columns: {},
});

export const useTableContext = () => useContext(TableContext);
