import { Editor, Element, Node, Path, Text } from 'slate';
import * as Y from 'yjs';
import { SharedType, SyncElement } from '../model';
import { YjsEditor } from '../plugin';

/**
 * Converts a sync element to a slate node
 *
 * @param element
 */
export function toSlateNode(element: SyncElement): Node {
    const text = SyncElement.getText(element);
    const children = SyncElement.getChildren(element);

    const node: Partial<Node> = {};
    if (text !== undefined) {
        // @ts-expect-error
        node.text = text.toString();
    }
    if (children !== undefined) {
        // @ts-expect-error
        node.children = children.map(toSlateNode);
    }

    Array.from(element.entries()).forEach(([key, value]) => {
        if (key !== 'children' && key !== 'text') {
            // @ts-expect-error
            node[key] = value;
        }
    });

    return node as Node;
}

/**
 * Converts a SharedType to a Slate doc
 * @param doc
 */
export function toSlateDoc(doc: SharedType): Node[] {
    if (doc instanceof Y.Array) {
        return doc.map(toSlateNode);
    }

    return [toSlateNode(doc)];
}

/**
 * Converts a slate node to a sync element
 *
 * @param node
 */
export function toSyncElement(node: Node): SyncElement {
    const element: SyncElement = new Y.Map();

    if (Element.isElement(node)) {
        // @ts-expect-error
        const childElements = node.children.map(toSyncElement);
        const childContainer = new Y.Array();
        childContainer.insert(0, childElements);
        element.set('children', childContainer);
    }

    if (Text.isText(node)) {
        const textElement = new Y.Text(node.text);
        element.set('text', textElement);
    }

    Object.entries(node).forEach(([key, value]) => {
        if (key !== 'children' && key !== 'text') {
            element.set(key, value);
        }
    });

    return element;
}

/**
 * Converts a SharedType path the a slate path
 *
 * @param path
 */
export function toSlatePath(editor: Editor, path: (string | number)[]): Path {
    const sharedType = YjsEditor.sharedType(editor);
    const slatePath = path.filter((node) => typeof node === 'number') as Path;
    if (sharedType instanceof Y.Map) {
        return [0, ...slatePath];
    }
    return slatePath;
}
