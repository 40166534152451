import { Collection } from '@saga/shared';
import React from 'react';

import { Layers } from 'react-feather';
const Emoji = React.lazy(() => import('@/components/Emoji'));

export function CollectionIcon({
    icon,
    size = 14,
    badgeSize = 12,
}: {
    icon: Collection['icon'];
    size?: number;
    badgeSize?: number;
}) {
    if (icon) {
        return (
            <div className="flex-none flex items-center relative" style={{ width: size, height: size }}>
                <span style={{ marginLeft: -2 }}>
                    <React.Suspense fallback={null}>
                        <Emoji emoji={icon.colons} size={size - 1} />
                    </React.Suspense>
                </span>
                <Layers
                    style={{ right: -3, bottom: -4 }}
                    size={badgeSize}
                    className="bg-slate-200/80 dark:bg-zinc-700/60 rounded-full absolute"
                />
            </div>
        );
    }

    return <Layers size={size} className="flex-none" />;
}
