import { Editor, Location, Node, NodeEntry, Path, Range, Transforms } from 'slate';
import {
    Inline,
    isMovableContainerElement,
    isSagaElement,
    SagaElement,
    Code,
    isCode,
    isImage,
    isLiveBlock,
    BlockType,
    isBlockType,
    isInline,
    FormatBlockElementTypeDef,
} from '../types';
import { toNodes } from './NodeEntry';
import { v4 as uuid } from 'uuid';
import { BlockBuilder } from '..';
import { Selection } from '.';
import { fromNodeEntryToCodeContent } from './SagaElement';

function shiftPathEnd(path: number[], by: number) {
    const copy = [...path];
    const pathEnd = copy[copy.length - 1];
    copy[copy.length - 1] = Math.max(0, pathEnd + by);
    return copy;
}

function getBasePathFromRange(range: Range) {
    const common = Path.common(range.anchor.path, range.focus.path);
    const basePath = Path.equals(range.anchor.path, range.focus.path) ? Path.parent(range.focus.path) : common;
    return basePath;
}

export function getMovableNodeEntries(editor: Editor, at: Path | Range): NodeEntry<SagaElement>[] {
    return [
        ...Editor.nodes(editor, {
            at,
            mode: 'highest',
            match: (node: Node, currentPath: Path): node is SagaElement => {
                // We return early if the node is not movable anyways
                if (!isMovableContainerElement(node)) return false;

                // if our from path is already a path, we just need to match it to the current path
                if (Path.isPath(at)) {
                    return Path.equals(currentPath, at);
                }

                //  we need to get the common path or the parent path if the range is within the same text block
                const basePath = getBasePathFromRange(at);

                // If our basePath happens to equal the currentPath, we return true
                // This could match too early (e.g. the parent of two paragraphs), but because we will search using "lowest"
                // it will move on to the lower one anyways
                if (Path.equals(basePath, currentPath)) {
                    return true;
                }

                // The same is true for the basePath, if it is on top level. In this case, we are acting at the top level anyways
                if (basePath.length === 0) {
                    return true;
                }

                // This case is important if we have for example have a range that spans two paragraphs
                // E.g. the Range is { offset: 0, anchor: [1, 1, 0], focus: [1, 2, 0] }, the basePath is then [1]
                // so we want to have both paragraphs (which are moveable) within that range to match
                return Path.isChild(currentPath, basePath);
            },
        }),
    ];
}

export function movePathWithShift(
    editor: Editor,
    options: Parameters<typeof Transforms.moveNodes>[1] & { at: Path | Range },
) {
    // this uses the same matcher as the movePathWithShift function, which allows us to get the exact same nodes
    // that we will move later
    const nodeEntries = getMovableNodeEntries(editor, options.at);
    const nodes = toNodes(nodeEntries);

    // We check if there are any matched nodes before the target path, if so, we need to adjust the target path
    const needsShift = nodeEntries.some(
        ([, path]) => Path.isSibling(path, options.to) && Path.isBefore(path, options.to),
    );

    const to = needsShift ? shiftPathEnd(options.to, -1) : options.to;

    Transforms.moveNodes(editor, {
        ...options,
        to,
        // finally we move only the top level nodes that we have in the movable nodes array from above
        match: (node: Node) => isSagaElement(node) && nodes.includes(node),
    });
}

export function selectPathIfExists(editor: Editor, path: Path, edge: 'start' | 'end' = 'start') {
    if (Editor.hasPath(editor, path)) {
        if (edge === 'start') {
            Transforms.select(editor, Editor.start(editor, path));
        } else if (edge === 'end') {
            Transforms.select(editor, Editor.end(editor, path));
        }
    }
}

export function insertInlineNode(editor: Editor, inline: Inline, target: Location) {
    Transforms.insertNodes(editor, [inline], {
        at: target,
        select: true,
    });
    // this makes sure that the start of the next node is selected
    const next = Editor.next(editor);
    if (next) {
        const start = Editor.start(editor, next[1]);
        Transforms.select(editor, start);
    }
}

export function turnIntoBlock({
    blockType,
    editor,
    range,
}: {
    blockType: FormatBlockElementTypeDef;
    editor: Editor;
    range: Range;
}) {
    const fullSelection = Selection.excludeLastPathIfOffsetZero(editor, range);

    Editor.withoutNormalizing(editor, () => {
        if (blockType === BlockType.CALLOUT) {
            Transforms.select(editor, fullSelection);

            const block = BlockBuilder.callout([]);

            Transforms.wrapNodes(editor, block, {
                at: fullSelection,
            });
        } else if (blockType === BlockType.CODE) {
            const nodeEntries: NodeEntry<SagaElement>[] | undefined = [
                ...Editor.nodes<SagaElement>(editor, {
                    at: fullSelection,
                }),
            ];

            Selection.safeSelectByLocation(editor, fullSelection);

            Transforms.removeNodes(editor);
            const block: Code = {
                id: uuid(),
                type: blockType,
                content: fromNodeEntryToCodeContent(nodeEntries),
                children: [{ text: '' }],
                language: 'html',
                isNew: true,
            };
            Transforms.insertNodes(editor, block);
        } else if (blockType === BlockType.KATEX_BLOCK || blockType === BlockType.KATEX_INLINE) {
            const value = editor.selection ? Editor.string(editor, editor.selection) : '';
            Transforms.removeNodes(editor);
            Transforms.insertNodes(
                editor,
                blockType === BlockType.KATEX_BLOCK ? BlockBuilder.katexBlock(value) : BlockBuilder.katexInline(value),
            );
        } else if (blockType === BlockType.NUMBER_LIST_ITEM) {
            const children = Array.from(
                Editor.nodes(editor, {
                    at: range,
                    mode: 'lowest',
                    match: isSagaElement,
                }),
            );

            Editor.withoutNormalizing(editor, () => {
                children.forEach((node) => {
                    Transforms.wrapNodes(editor, BlockBuilder.numberedListItem([]), {
                        at: isInline(node[0]) ? node[1].slice(0, -1) : node[1],
                    });
                });
            });
        } else {
            Transforms.setNodes(
                editor,
                { type: blockType },
                {
                    at: fullSelection,
                    match: (n) =>
                        isSagaElement(n) && !isBlockType(n as SagaElement, [isLiveBlock, isCode, isImage, isInline]),
                },
            );
            Selection.safeSelectEdge(editor, 'end', range);
        }
    });
}
