import React from 'react';
import { useHistory } from 'react-router';
import { openWindow } from '@/utils';
import { useCurrentWorkspace } from '@/components/WorkspaceContext';
import { BlockPluginProvider } from '@/components/BlockPluginProvider';
import { createLinkBlockPlugin } from '../editor/plugins/Link';
import { useSpacePlugin } from './Plugins';

export default function SpaceBlockPluginProvider({ children }: { children: React.ReactNode }) {
    const spacePlugin = useSpacePlugin();

    const { urlKey } = useCurrentWorkspace();
    const history = useHistory();

    const linkBlockPlugin = React.useMemo(() => {
        return createLinkBlockPlugin((url: string) => {
            const base = `${window.location.protocol}//${window.location.host}`;
            if (url.startsWith(`${base}/s/${urlKey}`)) {
                history.push(url.replace(base, ''));
            } else {
                openWindow(url, '_blank');
            }
        });
    }, [urlKey, history]);

    const plugins = React.useMemo(() => {
        return [
            // custom link plugin
            linkBlockPlugin,

            // shared space plugins
            spacePlugin,
        ];
    }, [spacePlugin, linkBlockPlugin]);

    return <BlockPluginProvider plugins={plugins}>{children}</BlockPluginProvider>;
}
