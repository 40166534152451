import { getFullMemberName } from '@/components/MembersProvider';
import { escapeRegExp } from 'lodash';
import * as api from '@saga/api';
import { DateUtils, isBlockTypeCurried, isNodeEntry, NodeCheckFn, SagaLocation, WeakTask } from '@saga/shared';
import { Editor, Range } from 'slate';
import { formatTypes } from '@/constants';
import { DateTime, Info } from 'luxon';
import { CreateSuggestion, DateBlockSuggestion, Member, TaskSuggestion } from '@/types';
import * as chrono from 'chrono-node';
import { GoogleDriveFileFragment, LinearIssueFragment } from '@saga/api';
import {
    CollectionSuggestion,
    GoogleDriveFileSuggestion,
    LinearIssueSuggestion,
    InlinePageLinkSuggestion,
    PageSuggestion,
    RecentPageSuggestion,
    SimplePageForSuggestion,
} from '@/types';
import { Collection } from '@saga/shared';
import { TFunction } from 'i18next';
import editorAliases from '../i18n/editor_aliases';

export function getSearchRegex(search: string) {
    return new RegExp(`(?:^|\\s)${escapeRegExp(search)}`, 'gi');
}

export function filterMembers(members: Member[], { search }: { search: string }) {
    const regex = getSearchRegex(search);
    return members.filter((member) => {
        const fullName = getFullMemberName(member);
        return fullName.match(regex) != null;
    });
}

export function filterTaskSuggestions(suggestions: TaskSuggestion[], { search }: { search: string }) {
    const regex = getSearchRegex(search);
    return suggestions.filter((suggestion) => suggestion.title.match(regex) != null);
}

export function filterPageSuggestions(
    suggestions: PageSuggestion[],
    { search, location }: { search: string; location: SagaLocation.SagaLocation },
) {
    const regex = getSearchRegex(search);
    return suggestions.filter(({ page }) => {
        if (SagaLocation.isPageLocation(location) && page.id === location.pageId) {
            return false;
        }

        return [page.title, ...page.aliases].some((p) => p.match(regex));
    });
}

export function hasParentOrCurrentNode<T extends NodeCheckFn>(editor: Editor, range: Range, check: T | T[]) {
    const [currentNode] = Editor.nodes(editor, { at: range });

    if (!currentNode || !Editor.hasPath(editor, range.anchor.path) || !Editor.hasPath(editor, range.focus.path))
        return false;

    if (isNodeEntry(currentNode, check)) {
        return true;
    }

    const disallowedParentEntry = Editor.above(editor, {
        at: range,
        match: isBlockTypeCurried(check),
    });

    if (disallowedParentEntry) {
        return true;
    }

    return false;
}

export function getFormatTypes(
    editor: Editor,
    {
        range,
        search,
        currentFormat,
        language,
    }: { range: Range | null; search?: string; currentFormat?: string; language: api.Language },
) {
    const enabledTypes = formatTypes(language).filter((type) => {
        if (type.disallowIfParentMatches) {
            if (range) {
                return !hasParentOrCurrentNode(editor, range, type.disallowIfParentMatches);
            }
        }

        if (type.isEnabled) {
            return type.isEnabled(editor, range, search);
        }
        return true;
    });

    if (!search) {
        return enabledTypes.filter((p) => p.value !== currentFormat);
    }

    const regex = getSearchRegex(search);
    return enabledTypes
        .filter((format) => [format.label, ...format.aliases].filter((p) => p.match(regex)).length > 0)
        .filter((p) => p.value !== currentFormat);
}

function parseWithLuxon(date: string, formats: string[]): DateTime | null {
    for (const format of formats) {
        const parsed = DateTime.fromFormat(date, format);
        if (parsed.isValid) {
            return parsed;
        }
    }

    return null;
}

export function getDateSuggestions(search: string, t: TFunction, currentLocale: string): DateBlockSuggestion[] {
    const luxonParsed = parseWithLuxon(search, ['d.M.y', 'dd.M.y', 'd.MM.y', 'dd.MM.y']);

    if (luxonParsed) {
        return [
            {
                type: 'date',
                title: DateUtils.formatDateWithWeekday(luxonParsed.setLocale(currentLocale)),
                date: luxonParsed.toISO(),
            },
        ];
    }

    const parsed = chrono.parseDate(search, new Date());

    if (parsed) {
        return [
            {
                type: 'date',
                title: DateUtils.formatDateWithWeekday(DateTime.fromJSDate(parsed).setLocale(currentLocale)),
                date: DateTime.fromJSDate(parsed).toISO(),
            },
        ];
    }

    const regex = getSearchRegex(search);

    const yesterday: DateBlockSuggestion = {
        type: 'date',
        title: t('time.yesterday'),
        alias: ['time'],
        date: DateTime.local().minus({ days: 1 }).toISO(),
    };

    const dateWithPicker: DateBlockSuggestion = {
        type: 'date',
        title: t('time.date'),
        alias: ['time'],
        date: DateTime.local().toISO(),
        openPicker: true,
    };

    const today: DateBlockSuggestion = {
        type: 'date',
        title: t('time.today'),
        alias: ['date', 'today', 'time'],
        date: DateTime.local().toISO(),
    };

    const tomorrow: DateBlockSuggestion = {
        type: 'date',
        title: t('time.tomorrow'),
        alias: ['date', 'tomorrow', 'time'],
        date: DateTime.local().plus({ days: 1 }).toISO(),
    };

    const weekdays = Info.weekdays(undefined, { locale: currentLocale });
    const days: DateBlockSuggestion[] = weekdays.map((label, weekday) => {
        const date = DateTime.local().set({ weekday: weekday + 1 });

        return {
            type: 'date',
            title: DateUtils.formatDateWithWeekday(date.setLocale(currentLocale)),
            date: date.toISO(),
        };
    });

    const next: DateBlockSuggestion[] = Object.values(editorAliases.dateNext)
        .flat()
        .some((word) => search.toLowerCase().startsWith(word))
        ? weekdays.map((label, weekday) => {
              const date = DateTime.local()
                  .plus({ weeks: 1 })
                  .set({ weekday: weekday + 1 });
              return {
                  type: 'date',
                  title: t('time.next_period', { label, date: date.setLocale(currentLocale).toFormat('LLLL d') }),
                  alias: ['next'],
                  date: date.toISO(),
              };
          })
        : [];

    const last: DateBlockSuggestion[] = Object.values(editorAliases.dateLast)
        .flat()
        .some((word) => search.toLowerCase().startsWith(word))
        ? weekdays.map((label, weekday) => {
              const date = DateTime.local()
                  .minus({ weeks: 1 })
                  .set({ weekday: weekday + 1 });

              return {
                  type: 'date',
                  title: t('time.last_period', { label, date: date.setLocale(currentLocale).toFormat('LLLL d') }),
                  alias: ['last'],
                  date: date.toISO(),
              };
          })
        : [];

    return [dateWithPicker, yesterday, today, tomorrow, ...days, ...next, ...last].filter((item) => {
        return item.title.match(regex) || item.alias?.some((alias) => alias.match(regex));
    });
}

export function googleDriveFileToSuggestion(file: GoogleDriveFileFragment): GoogleDriveFileSuggestion {
    return { type: 'drive' as const, file, title: file.name };
}

export function linearIssueToSuggestion(issue: LinearIssueFragment): LinearIssueSuggestion {
    return { type: 'linear-issue' as const, issue, title: issue.title };
}

export function pageToPageSuggestion(page: SimplePageForSuggestion): PageSuggestion {
    return { title: page.title, page, type: 'page' };
}

export function pageToRecentPageSuggestion(page: SimplePageForSuggestion): RecentPageSuggestion {
    return { title: page.title, page, type: 'recentPage' };
}

export function pageToInlinePageLinkSuggestion(page: SimplePageForSuggestion): InlinePageLinkSuggestion {
    return { title: page.title, page, type: 'inline-page-link' };
}

export function makeCreatePageSuggestion(search: string): CreateSuggestion {
    return { title: search, type: 'create', kind: 'page' };
}

export function makeCreateTemplateSuggestion(search: string): CreateSuggestion {
    return { title: search, type: 'create', kind: 'page', isTemplate: true };
}

export function makeCreateCollectionSuggestion(search: string): CreateSuggestion {
    return { title: search, type: 'create', kind: 'collection' };
}

export function makeTaskCreateSuggestion(search: string): CreateSuggestion {
    return { title: search, type: 'create', kind: 'task' };
}

export function collectionToSuggestion(collection: Collection): CollectionSuggestion {
    return { title: collection.title, collection, type: 'collection' };
}

export function taskToSuggestion(task: WeakTask): TaskSuggestion {
    return { task, type: 'task', title: task.title ?? '' };
}
