import React from 'react';
import { MoreHorizontal, Trash } from 'react-feather';
import * as Popover from '@radix-ui/react-popover';
import { useTranslation } from 'react-i18next';

interface IProps {
    removeProperty: () => void;
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

function PropertyMenuButton(props: IProps) {
    const { removeProperty, isOpen, setIsOpen } = props;
    const { t } = useTranslation();

    return (
        <div className="flex items-center">
            <Popover.Root>
                <Popover.Trigger asChild>
                    <button
                        aria-label={t(isOpen ? 'common.close_property_menu' : 'common.open_property_menu') as string}
                        aria-haspopup="true"
                        onClick={() => {
                            setIsOpen((isOpen) => !isOpen);
                        }}
                        className="px-1 bg-white dark:bg-zinc-700 rounded hover:bg-gray-100 dark:hover:bg-zinc-600 focus:outline-none active:bg-gray-200 dark:active:bg-zinc-600 active:shadow-xs"
                    >
                        <MoreHorizontal />
                    </button>
                </Popover.Trigger>
                <Popover.Portal>
                    <Popover.Content onOpenAutoFocus={(e) => e.preventDefault()}>
                        <div
                            data-testid="sidebar-context-menu"
                            className="bg-white dark:bg-zinc-700 rounded p-2 shadow-popupSmall"
                        >
                            <button
                                onClick={() => {
                                    removeProperty();
                                    setIsOpen(false);
                                }}
                                className="w-full flex items-center pl-2 pr-8 py-1 rounded hover:bg-saga-bg-gray dark:hover:bg-zinc-600 text-left"
                            >
                                <Trash className="mr-2" size={16} />
                                Remove Property
                            </button>
                        </div>
                    </Popover.Content>
                </Popover.Portal>
            </Popover.Root>
        </div>
    );
}

export default PropertyMenuButton;
