import React from 'react';

function Notice({ children }: { children: React.ReactNode }) {
    return <div className="bg-saga-primary-light text-yellow-800 text-sm rounded-lg inline-block">{children}</div>;
}

Notice.Danger = function NoticeDanger({ children }: { children: React.ReactNode }) {
    return <div className="bg-red-100 text-red-800 text-sm rounded-lg  inline-block">{children}</div>;
};

export default Notice;
