import useOnTextChange from './useOnTextChange';
import * as Y from 'yjs';

export default function useOnTextEvent(
    yBlocks: Y.Array<any> | undefined | null,
    origin: any,
    handlers: {
        onInsert?: (
            data: {
                insert: string;
                value: string;
                retain: number;
            },
            target: Y.Text,
        ) => void;
        onDelete?: (data: { retain: number; value: string; delete: number }, target: Y.Text) => void;
    },
) {
    useOnTextChange(yBlocks, origin, (events) => {
        events.forEach((event) => {
            if (event instanceof Y.YTextEvent) {
                const retainDelta = event.delta.find((d) => d.retain != null);
                const insertDelta = event.delta.find((d) => d.insert != null);
                const target = event.target as Y.Text;
                const value = target.toString();

                // The insert delta is always a string in Y.Text, see https://docs.yjs.dev/api/delta-format
                if (handlers.onInsert && insertDelta && typeof insertDelta.insert === 'string') {
                    const retain = retainDelta?.retain ?? 0;
                    handlers.onInsert(
                        {
                            insert: insertDelta.insert,
                            value,
                            retain,
                        },
                        target,
                    );
                }
                const deleteDelta = event.delta.find((d) => d.delete != null);

                if (handlers.onDelete && deleteDelta && deleteDelta.delete) {
                    const retain = retainDelta?.retain ?? 0;
                    handlers.onDelete({ retain, delete: deleteDelta.delete, value }, target);
                }
            }
        });
    });
}
