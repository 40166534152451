import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { BaseRange, Editor, Range, Transforms } from 'slate';
import * as Popover from '@radix-ui/react-popover';
import {
    AIMessage,
    AIPrompt,
    AISourceItem,
    BlockBuilder,
    Converter,
    isBlockType,
    isIndentContainer,
    isTable,
    RealtimeSagaEditor,
    SagaEditor,
    SagaElement,
    SHORTCUTS,
} from '@/../../shared/src';
import RangeAnchor from '@/components/editor/RangeAnchor';

import AIQuestionContent from '@/components/editor/ai/AIQuestionContent';
import { insertBlocks } from '@/../../shared/src/Editor';
import { useUserContext } from '@/components/UserContext';
import Modal from '@/components/Modal';
import BasicButton from '@/components/styled/BasicButton';

import { useSpace } from '@/components/SpaceProvider';
import { useTranslation } from 'react-i18next';
import { CustomPromptType } from '@/../../api/src';
import AIAnswerContent from '@/components/editor/ai/AIAnswerContent';
import { useAIEnabled } from '@/components/editor/ai/AIPermissionsProvider';
import { track } from '@/analytics';

type AIPopoverProps = {
    editor: RealtimeSagaEditor;
    onClose(): void;
    selection: BaseRange | undefined | null;
};

const AIPopover = ({ editor, onClose, selection: preselection }: AIPopoverProps) => {
    const { t } = useTranslation();
    const [editorWidth, setEditorWidth] = useState<number | undefined>();
    const { user } = useUserContext();
    const [history, setHistory] = useState<AIMessage[]>([]);
    const [prompt, setPrompt] = useState<AIPrompt>();

    const [confirmModalOpened, setConfirmModalOpened] = useState(false);
    const [trackSelection, setTrackSelection] = useState(false);

    const { space } = useSpace();

    const container = SagaEditor.useContainer();

    const lastRow = useMemo(() => ({ anchor: Editor.end(editor, []), focus: Editor.end(editor, []) }), [editor]);
    const selection = useMemo(() => preselection || editor.selection || lastRow, [lastRow, preselection, editor]);

    const { aiStatus, setShowUpgradePlanModal } = useAIEnabled();

    const selectedText = useMemo(() => {
        if (selection) {
            const fragments = Range.isExpanded(selection) ? (Editor.fragment(editor, selection) as SagaElement[]) : [];
            return Converter.sagaToXml(space, fragments);
        }

        return undefined;
    }, [selection, editor, space]);

    const promptType: CustomPromptType = useMemo(() => {
        if (selectedText) {
            let trimmed = selectedText;
            for (const shortcut of Object.keys(SHORTCUTS)) {
                trimmed = trimmed.replace(new RegExp(`^[${shortcut}]+`, 'g'), '').trim();
            }

            return trimmed.length ? CustomPromptType.Command : CustomPromptType.Prompt;
        }

        return CustomPromptType.Prompt;
    }, [selectedText]);

    useEffect(() => {
        const loadWidth = async () => {
            const editorContainerWidth: number = await Number(
                container?.current?.querySelector('#editor-container [role="textbox"]')?.clientWidth,
            );

            setEditorWidth(Math.min(700, editorContainerWidth));
        };

        loadWidth();
    }, [container?.current?.clientWidth, container]);

    useEffect(() => {
        if (preselection) {
            Transforms.select(editor, preselection);
        }
    }, [confirmModalOpened, trackSelection, editor, preselection]);

    const insertAIBlock = useCallback(
        (prompt: string | undefined, sources?: AISourceItem[]) => {
            if (!selection || !prompt) {
                return;
            }

            let textContext: string | undefined;

            if (promptType === CustomPromptType.Prompt) {
                const node = BlockBuilder.aiSuggestedText(
                    prompt,
                    [],
                    user?.id,
                    editor.awareness.clientID,
                    textContext,
                    sources,
                );
                Transforms.setNodes(editor, node, { at: selection });
                track('ai-popover-message-sent');
                onClose();
            } else {
                setPrompt({ prompt, textContext: selectedText, references: sources ?? [] });
            }
        },
        [editor, onClose, selectedText, selection, user, promptType],
    );

    const handleStatusChanged = useCallback((status, messages) => {
        if (status === 'done') {
            setHistory(messages);
        }
    }, []);

    const renderQuestionContent = () => {
        const onSubmit = (text: string, sources?: AISourceItem[]) => {
            switch (aiStatus) {
                case 'enabled':
                    insertAIBlock(text, sources);
                    break;
                case 'disabled':
                    setShowUpgradePlanModal(true);
                    break;
                case 'restricted':
                    break;
            }
        };

        return <AIQuestionContent onSubmit={onSubmit} onSuggestedPrompt={insertAIBlock} type={promptType} />;
    };

    const handleDismissEvent = useCallback(
        (e) => {
            if (!prompt || aiStatus !== 'enabled') {
                return;
            }

            e.preventDefault();

            setConfirmModalOpened(true);
        },
        [prompt, aiStatus],
    );

    const handleEscapeDismissEvent = useCallback(
        (e) => {
            if (!prompt || aiStatus !== 'enabled') {
                return;
            }

            e.preventDefault();

            setConfirmModalOpened(true);

            setTrackSelection((selection) => !selection);
        },
        [prompt, aiStatus],
    );

    const renderAnswerContent = (prompt: AIPrompt) => (
        <AIAnswerContent
            history={history}
            prompt={prompt}
            onKeep={(blocks, replace) => {
                if (!selection || !blocks.length) {
                    return;
                }

                if (replace) {
                    insertBlocks(editor, blocks);
                } else {
                    if (!editor.selection) {
                        return;
                    }

                    Transforms.select(editor, Editor.end(editor, editor.selection));

                    // If insert below location is inside Table or Indent container insert new fragment instead of appending
                    if (
                        Editor.above(editor, {
                            at: editor.selection,
                            match: (node) => isBlockType(node, [isTable, isIndentContainer]),
                        })
                    ) {
                        const newPath = [editor.selection.anchor.path[0] + 1];
                        Transforms.insertNodes(editor, BlockBuilder.paragraph(), { at: newPath });
                        Transforms.select(editor, newPath);
                    } else {
                        const nextPath = Editor.next(editor, { at: editor.selection })?.[1];
                        if (nextPath) {
                            Transforms.insertNodes(editor, BlockBuilder.breakParagraph());
                        }
                    }

                    insertBlocks(editor, blocks);
                }

                onClose();
            }}
            onDiscard={onClose}
            onStatusChange={handleStatusChanged}
            setPrompt={setPrompt}
            embedText
        />
    );

    return (
        <>
            <Modal.Small
                isOpen={confirmModalOpened}
                onClose={() => setConfirmModalOpened(false)}
                onAfterOpen={(o) => o?.contentEl.focus()}
            >
                <div className="flex items-center justify-center">
                    <div className="p-4 space-y-2 bg-white dark:bg-zinc-700 flex flex-col rounded">
                        <div className="mb-2 mx-5 text-l font-medium">{t('ai.discard_response')}</div>
                        <div className="flex flex-row space-x-2 self-end">
                            <BasicButton
                                variant="secondary"
                                className="h-8"
                                onClick={() => setConfirmModalOpened(false)}
                            >
                                {t('common.cancel')}
                            </BasicButton>
                            <BasicButton
                                onClick={() => {
                                    console.log(editor.selection);
                                    onClose();
                                }}
                                className="h-8"
                            >
                                {t('ai.discard')}
                            </BasicButton>
                        </div>
                    </div>
                </div>
            </Modal.Small>
            <Popover.Root
                open
                onOpenChange={(open) => {
                    if (!open) {
                        onClose();
                    }
                }}
            >
                <RangeAnchor
                    containerRef={container}
                    range={selection}
                    alignY={promptType === CustomPromptType.Prompt ? 'top' : 'bottom'}
                    alignX="leading"
                    contentRef={document.getElementById('ai-content')}
                    history={history}
                />
                <Popover.Portal>
                    <Popover.Content
                        onOpenAutoFocus={(e) => e.preventDefault()}
                        onFocusOutside={(e) => e.preventDefault()}
                        onInteractOutside={handleDismissEvent}
                        onEscapeKeyDown={handleEscapeDismissEvent}
                        align="start"
                        className="z-100"
                        style={{ width: editorWidth }}
                        avoidCollisions={false}
                        id="ai-content"
                    >
                        {!prompt ? renderQuestionContent() : renderAnswerContent(prompt)}
                    </Popover.Content>
                </Popover.Portal>
            </Popover.Root>
        </>
    );
};

export default AIPopover;
