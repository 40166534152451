import React from 'react';

export function CloudOffline({ className }: { className?: string }) {
    return (
        <svg
            className={className}
            width="25"
            height="14"
            viewBox="0 0 25 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M19.1455 3.72313C18.7616 3.72313 18.3776 3.77413 18.0193 3.85064C16.8675 1.58106 14.4871 0 11.7484 0C8.44654 0 5.68222 2.26958 4.93994 5.32969C4.76078 5.30419 4.58161 5.27869 4.37684 5.27869C1.94526 5.27869 0 7.24226 0 9.63934C0 12.0364 1.97086 14 4.37684 14H19.1455C21.9866 14 24.3158 11.7049 24.3158 8.84882C24.3158 5.99271 22.0122 3.72313 19.1455 3.72313ZM14.6407 9.18033C14.9478 9.48634 14.9478 9.94536 14.6407 10.2514C14.4871 10.4044 14.3079 10.4809 14.1032 10.4809C13.8984 10.4809 13.7192 10.4044 13.5657 10.2514L12.1579 8.84882L10.7501 10.2514C10.5966 10.4044 10.4174 10.4809 10.2126 10.4809C10.0079 10.4809 9.8287 10.4044 9.67513 10.2514C9.36798 9.94536 9.36798 9.48634 9.67513 9.18033L11.0829 7.77778L9.67513 6.37523C9.36798 6.06922 9.36798 5.6102 9.67513 5.30419C9.98227 4.99818 10.443 4.99818 10.7501 5.30419L12.1579 6.70674L13.5657 5.30419C13.8728 4.99818 14.3335 4.99818 14.6407 5.30419C14.9478 5.6102 14.9478 6.06922 14.6407 6.37523L13.2329 7.77778L14.6407 9.18033Z"
                fill="white"
            />
        </svg>
    );
}
