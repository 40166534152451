import { useFirebaseContext } from '@/components/FirebaseContext';
import { useCurrentWorkspace } from '@/components/WorkspaceContext';
import { useRealtimeStateWithDocument } from '../components/RealtimeProvider';
import * as api from '@saga/api';
import { useIsOnline } from '@/components/BrowserContextProvider';

export const useSpaceAccess = () => {
    const { state } = useRealtimeStateWithDocument();
    const { firebaseUser } = useFirebaseContext();
    const { access, isPublic, members, permissions } = useCurrentWorkspace();
    const member = members.find((member) => member.id === firebaseUser?.uid);
    const isOwner = member != null && member.role === api.Role.Owner;
    const isEditor = member != null && member.role === api.Role.Writer;
    const isViewer = member != null && member.role === api.Role.Viewer;
    const isMember = member != null && member.role === api.Role.Member;
    const isOnline = useIsOnline();

    return {
        access,
        permissions,
        canEdit: access.write && isOnline && (state.type === 'SYNCED' || state.type === 'DISCONNECTED'),
        isPublic,
        isOwner,
        isEditor,
        isViewer,
        isMember,
        member,
    };
};
