import { assertYArray } from '../yjs-utils';
import transactInSpace from './transactInSpace';
import { SafeSpace } from '../types';
import { assertNonNull } from '../utils';
import findTaskMap from './findTaskMap';

export default function removeTaskFromCollection(space: SafeSpace, taskId: string, collectionId: string) {
    transactInSpace(space, () => {
        const taskMap = findTaskMap(space, taskId);
        assertNonNull(taskMap);
        console.log('taskMap', taskMap.toJSON());
        const collections = taskMap.get('collections');
        assertYArray(collections);
        const index = collections.toArray().findIndex((c) => c === collectionId);
        if (index !== -1) {
            collections.delete(index);
        } else {
            throw new Error(
                `Task with id ${taskId} could not be removed: Collection with id ${collectionId} not found`,
            );
        }
    });
}
