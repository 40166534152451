import React from 'react';
import {
    ArrowLeft,
    User,
    Users,
    Sliders,
    Icon,
    Inbox,
    PlusSquare,
    Layout,
    Type,
    Download,
    Map,
    CreditCard,
    Globe,
    Tag,
} from 'react-feather';
import Settings from '@/components/settings/Settings';
import { useCurrentWorkspace } from '../WorkspaceContext';
import classNames from 'classnames';
import * as api from '@saga/api';

import { SettingsType, useCloseSettings, useSetSettings, useSettings } from './SettingsProvider';
import { useDesktopContext } from '@/components/DesktopContext';
import { useSpaceAccess } from '@/hooks/useSpaceAccess';
import { isFeatureEnabled } from '@/analytics';
import Button from '../styled/Button';
import { Lightning } from '@/assets/icons';
import { useTranslation } from 'react-i18next';
import { FeatureFlag } from '@/../../shared/src/utils/FeatureFlags';

export default function SettingsMenu() {
    const { t } = useTranslation();
    const { title } = useCurrentWorkspace();
    const { data: spaceInvites } = api.useUserInvitesQuery({
        fetchPolicy: 'cache-and-network',
    });
    const { data: pageInvites } = api.useUserPageInvitesQuery({
        fetchPolicy: 'cache-and-network',
    });

    const settings = useSettings();
    const closeSettings = useCloseSettings();
    const setSettings = useSetSettings();
    const { useMacOsLayout } = useDesktopContext();
    const { isOwner, member } = useSpaceAccess();

    React.useEffect(() => {
        if (!settings) return;

        function onKeyDown(e: KeyboardEvent) {
            // If there is an opened modal, do not handle the event
            if (
                e.code === 'Escape' &&
                !Array.from(document.getElementsByClassName('ReactModalPortal')).some((el) => el.children.length)
            ) {
                e.stopImmediatePropagation();
                closeSettings();
            }
        }

        window.addEventListener('keydown', onKeyDown, { capture: true });
        return () => window.removeEventListener('keydown', onKeyDown, { capture: true });
    }, [settings, closeSettings]);

    return (
        <SettingsMenu.Container>
            <SettingsMenu.Nav>
                <SettingsMenu.Header isDesktop={useMacOsLayout}>
                    <SettingsMenu.BackButton onClick={closeSettings} />
                    <div className="flex items-center">Settings</div>
                </SettingsMenu.Header>
                <SettingsMenu.NavContainer>
                    <SettingsMenu.NavCategory>
                        <SettingsMenu.NavCategoryHeader>{title}</SettingsMenu.NavCategoryHeader>
                        <SettingsMenu.NavButtonContainer>
                            {!!member && (
                                <SettingsMenu.SettingsButton icon={Sliders} value="space">
                                    {t('settings.sidebar.general')}
                                </SettingsMenu.SettingsButton>
                            )}

                            <SettingsMenu.SettingsButton icon={Users} value="members">
                                {t('settings.sidebar.members')}
                            </SettingsMenu.SettingsButton>

                            <SettingsMenu.SettingsButton icon={Tag} value="labels">
                                {t('settings.labels.title')}
                            </SettingsMenu.SettingsButton>

                            {isOwner && isFeatureEnabled(FeatureFlag.allowBilling) && (
                                <>
                                    <SettingsMenu.SettingsButton icon={Map} value="plans">
                                        {t('settings.sidebar.plans')}
                                    </SettingsMenu.SettingsButton>
                                    <SettingsMenu.SettingsButton icon={CreditCard} value="billing">
                                        {t('settings.sidebar.billing')}
                                    </SettingsMenu.SettingsButton>
                                </>
                            )}

                            {!!member && (
                                <SettingsMenu.SettingsButton icon={Download} value="import-export">
                                    {t('settings.sidebar.import_export')}
                                </SettingsMenu.SettingsButton>
                            )}
                        </SettingsMenu.NavButtonContainer>
                    </SettingsMenu.NavCategory>
                    <div className="my-3 border-b border-saga-gray-300 dark:border-saga-gray-800" />
                    <SettingsMenu.NavCategory>
                        <SettingsMenu.NavCategoryHeader>{t('settings.sidebar.account')}</SettingsMenu.NavCategoryHeader>
                        <SettingsMenu.NavButtonContainer>
                            <SettingsMenu.SettingsButton icon={User} value="account">
                                {t('settings.sidebar.profile')}
                            </SettingsMenu.SettingsButton>
                            <SettingsMenu.SettingsButton icon={Globe} value="language">
                                {t('settings.sidebar.language')}
                            </SettingsMenu.SettingsButton>
                            <SettingsMenu.SettingsButton icon={Layout} value="appearance">
                                {t('settings.sidebar.appearance')}
                            </SettingsMenu.SettingsButton>

                            <SettingsMenu.SettingsButton icon={Type} value="editor">
                                {t('settings.sidebar.editor')}
                            </SettingsMenu.SettingsButton>

                            <SettingsMenu.SettingsButton
                                icon={Inbox}
                                value="invites"
                                hasNotification={
                                    ((spaceInvites?.userInvites.length || pageInvites?.userPageInvites.length) ?? 0) > 0
                                }
                            >
                                {t('settings.sidebar.invites')}
                            </SettingsMenu.SettingsButton>
                            <SettingsMenu.SettingsButton
                                icon={() => <Lightning className="fill-saga-text dark:fill-saga-gray-300 w-8" />}
                                value="sagaai"
                            >
                                Saga AI
                            </SettingsMenu.SettingsButton>
                            <SettingsMenu.SettingsButton icon={PlusSquare} value="integrations">
                                {t('settings.sidebar.integrations')}
                            </SettingsMenu.SettingsButton>
                        </SettingsMenu.NavButtonContainer>
                    </SettingsMenu.NavCategory>
                </SettingsMenu.NavContainer>
            </SettingsMenu.Nav>
            <SettingsMenu.Settings isOpen={settings != null} onClose={() => setSettings(null)}>
                {settings && <Settings type={settings} />}
                <div className="absolute top-0 right-0 m-2">
                    <Button.XButton onClick={closeSettings} />
                </div>
            </SettingsMenu.Settings>
        </SettingsMenu.Container>
    );
}

SettingsMenu.SettingsButton = function SettingsMenuSettingsButton({
    value,
    icon,
    children,
    hasNotification,
}: {
    value: SettingsType;
    children: React.ReactNode;
    icon: Icon;
    hasNotification?: boolean;
}) {
    const settings = useSettings();
    const setSettings = useSetSettings();
    return (
        <SettingsMenu.NavButton selected={settings === value} onClick={() => setSettings(value)}>
            <div className="flex items-center">
                <div className="relative">
                    {hasNotification && (
                        <div className="absolute top-1 left-1 w-2 h-2 bg-saga-orange rounded-full"></div>
                    )}
                    <SettingsMenu.NavButton.Icon icon={icon} />
                </div>
                <div className="truncate">{children}</div>
            </div>
        </SettingsMenu.NavButton>
    );
};

SettingsMenu.Settings = function SettingsMenuSettings({
    isOpen,
    children,
    onClose,
}: {
    isOpen: boolean;
    children: React.ReactNode;
    onClose(): void;
}) {
    return (
        <div className="absolute h-full flex-none flex right-0" style={{ width: 'calc(100% - 250px)' }}>
            <div className="bg-white dark:bg-zinc-700 h-full overflow-x-auto w-full overflow-y-auto">{children}</div>
            <div className={'flex-grow' + (!isOpen ? '' : ' bg-black opacity-25')} onClick={onClose}></div>
        </div>
    );
};

SettingsMenu.Container = function SettingsMenuContainer({ children }: { children: React.ReactNode }) {
    return (
        <div className="absolute flex z-100 w-screen h-screen divide-x divide-saga-gray-150 dark:divide-saga-gray-800">
            {children}
        </div>
    );
};

SettingsMenu.Nav = function SettingsMenuNav({ children }: { children: React.ReactNode }) {
    return <nav className="w-250 bg-saga-bg-gray-light dark:bg-saga-gray-1000 overflow-y-auto">{children}</nav>;
};

SettingsMenu.Header = function SettingsMenuHeader({
    children,
    isDesktop,
}: {
    children: React.ReactNode;
    isDesktop: boolean;
}) {
    return (
        <div
            className={classNames(
                'w-250 flex items-center justify-start font-medium h-12 border-b border-saga-gray-200 dark:border-zinc-600',
                {
                    'pl-16': isDesktop,
                    'space-x-4': !isDesktop,
                },
            )}
        >
            {children}
        </div>
    );
};

SettingsMenu.NavContainer = function SettingsMenuNavContainer({ children }: { children: React.ReactNode }) {
    return <div className="p-4 text-sm">{children}</div>;
};

SettingsMenu.BackButton = function SettingsBackButton({ onClick }: { onClick(): void }) {
    return (
        <button onClick={onClick} className="rounded mx-2 my-1 p-1 hover:bg-saga-gray-200 dark:hover:bg-zinc-600">
            <ArrowLeft size={24} />
        </button>
    );
};

SettingsMenu.NavCategory = function SettingsMenuNavCategory({ children }: { children: React.ReactNode }) {
    return <div className="space-y-2">{children}</div>;
};

SettingsMenu.NavCategoryHeader = function SettingsMenuNavCategoryHeader({ children }: { children: React.ReactNode }) {
    return <div className="my-auto text-sm text-saga-text-gray pl-2 break-words">{children}</div>;
};

SettingsMenu.NavButtonContainer = function SettingsMenuNavButtonContainer({ children }: { children: React.ReactNode }) {
    return <div className="space-y-0.5">{children}</div>;
};

function SettingsMenuNavButton({
    selected,
    onClick,
    children,
}: {
    selected: boolean;
    onClick(): void;
    children: React.ReactNode;
}) {
    return (
        <button
            className={
                'w-full py-1 rounded font-medium hover:bg-saga-bg-gray dark:hover:bg-zinc-600 cursor-pointer focus:outline-none ' +
                (selected ? 'bg-saga-bg-gray dark:bg-zinc-600' : '')
            }
            onClick={onClick}
        >
            {children}
        </button>
    );
}

SettingsMenu.NavButton = SettingsMenuNavButton;

SettingsMenuNavButton.Icon = function SettingsMenuNavButtonIcon({ icon: IconClass }: { icon: Icon }) {
    return <IconClass className="mx-1 w-6 p-1" />;
};
