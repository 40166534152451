import { track } from '@/analytics';
import { useSetHighlight } from '@/components/HighlightsProvider';
import { useOpenTurnIntoSuggestions } from '@/components/TurnIntoSuggestions';
import { dndTypes } from '@/constants';
import useBlockDrop from '@/hooks/useBlockDrop';
import { DndBlockItem } from '@/types';
import {
    BlockType,
    SagaEditor,
    EditorOperations,
    isBlockType,
    isHeading,
    isLiveBlock,
    isMovableContainerElement,
    isParagraph,
    isTaskBlock,
    isVoid,
    SagaElement,
    isFile,
    isCallout,
} from '@saga/shared';
import classNames from 'classnames';
import React, { useContext, useRef, useState } from 'react';
import { ConnectDragPreview, ConnectDragSource, useDrag, useDragDropManager } from 'react-dnd';
import { Plus } from 'react-feather';
import { Editor as SlateEditor, Node, Range, Transforms } from 'slate';
import { ReactEditor, useSelected, useSlateStatic } from 'slate-react';
import { DraggableDots } from '../icons/DraggableDots';
import { useIsDragAndDropEnabled, useOnDrop } from './DragAndDrop';
import ElementContainer from './ElementContainer';
import { useOpenSuggestions } from './Suggestions';
import { LINE_START_TRIGGER } from './useTrigger';

const gripIcon = (
    <DraggableDots className="mx-auto h-6 rounded-sm hover:bg-saga-gray-100 dark:hover:bg-saga-gray-800" />
);
const plusIcon = <Plus className="mx-auto opacity-30" />;

const IsDraggingContext = React.createContext(false);

function DropIndicator() {
    return <div className="pointer-events-none opacity-25 h-1 rounded-sm bg-saga-blue-light" />;
}

const DraggableContainer = React.forwardRef<
    HTMLDivElement,
    {
        element: SagaElement;
        showDrag: boolean;
        children: React.ReactNode;
        drag: ConnectDragSource;
        iconRef: React.MutableRefObject<HTMLSpanElement | null>;
        preview: ConnectDragPreview;
        draggingClassName: string;
        previewClassName: string;
        onSelect(e: React.MouseEvent): void;
    }
>(function DraggableContainer(
    { element, showDrag, children, iconRef, drag, preview, onSelect, previewClassName, draggingClassName },
    ref,
) {
    const type = element.type;
    const previewRef = useRef<HTMLDivElement | null>(null);
    const noRightPadding = isBlockType(element, [isLiveBlock, isTaskBlock]);

    preview(previewRef);

    const previewClassNameList = previewClassName.split(' ').map((s) => s.trim());
    const draggingClassNameList = draggingClassName.split(' ').map((s) => s.trim());
    const isEmptyParagraph = React.useMemo(() => isParagraph(element) && Node.string(element).trim() === '', [element]);

    const hasBlockContainerH1 = element.children.some(
        (child) => !isCallout(element) && child.type === BlockType.HEADING_1,
    );
    const hasBlockContainerH2 = element.children.some(
        (child) => !isCallout(element) && child.type === BlockType.HEADING_2,
    );
    const hasBlockContainerH3 = element.children.some(
        (child) => !isCallout(element) && child.type === BlockType.HEADING_3,
    );
    const hasFileTitle = isFile(element) && element.title;

    return (
        <div
            id="dnd-container"
            data-testid={`drop-target:${element.id}`}
            ref={ref}
            className={classNames(
                'relative select-none dnd-container -ml-9 [padding-left:2px] flex border-l-2 rounded-l-sm ',
                {
                    'border-saga-gray-200 dark:border-zinc-600': type === BlockType.BLOCK_CONTAINER && showDrag,
                    'border-transparent': type !== BlockType.BLOCK_CONTAINER || !showDrag,
                    'items-center':
                        type === BlockType.HEADING_1 || type === BlockType.HEADING_2 || type === BlockType.HEADING_3,
                },
            )}
        >
            <div
                contentEditable={false}
                className={classNames('draggable-item select-none rounded-sm', {
                    'pr-2': !noRightPadding,
                    'pr-0': noRightPadding,
                    'pt-9': type === BlockType.HEADING_1,
                    'pt-5': type === BlockType.HEADING_2,
                    'pt-4': type === BlockType.HEADING_3,
                    'pt-11': hasBlockContainerH1,
                    'pt-3': hasBlockContainerH2,
                })}
            >
                <div
                    ref={drag}
                    onDragStart={() => {
                        const el = previewRef.current;
                        if (el) {
                            el.classList.add(...previewClassNameList);
                            el.classList.add(...draggingClassNameList);
                        }
                    }}
                    onDragEnd={() => {
                        const el = previewRef.current;
                        if (el) {
                            el.classList.remove(...draggingClassNameList);
                        }
                    }}
                    id="draggable"
                    data-testid="draggable"
                    className={classNames(
                        'w-6 flex flex-none items-center justify-start cursor-grab rounded select-none',
                        {
                            'h-12': type === BlockType.HEADING_1,
                            'h-10': type === BlockType.HEADING_2,
                            'h-9': type === BlockType.HEADING_3,
                            'h-8': !isHeading(element),
                            'opacity-100': showDrag,
                            'opacity-0': !showDrag,
                            'pb-3.5': type === BlockType.QUOTE,
                            'pt-4': type === BlockType.FILE && hasFileTitle,
                            'pt-6': hasBlockContainerH2,
                            'pt-8': hasBlockContainerH3,
                        },
                    )}
                    contentEditable={false}
                    onClick={onSelect}
                >
                    <span
                        ref={iconRef}
                        className={classNames('w-6 flex flex-none items-center justify-start cursor-grab', {
                            'pt-[9px]': type === BlockType.TASK_BLOCK,
                            'pt-1': type === BlockType.PARAGRAPH,
                        })}
                    >
                        <div className="h-8 flex flex-none items-center justify-start">
                            {isEmptyParagraph ? plusIcon : gripIcon}
                        </div>
                    </span>
                </div>
            </div>

            <div ref={previewRef} className="w-full relative select-text min-w-0">
                {children}
            </div>
        </div>
    );
});

const MemoizedDraggableContainer = React.memo(DraggableContainer);

const DraggableElement = function DraggableElement(props: { element: SagaElement; children: React.ReactNode }) {
    const setHighlight = useSetHighlight();
    const { element } = props;
    const id = props.element.id;
    const iconRef = useRef<HTMLDivElement | null>(null);
    const [mouseOver, setMouseOver] = useState(false);
    const editor = useSlateStatic();
    const isSelected = useSelected();
    const { selection } = editor;
    const isMultilineSelection = selection?.focus.path[0] !== selection?.anchor.path[0];
    const openSuggestions = useOpenSuggestions();
    const openTurnIntoSuggestions = useOpenTurnIntoSuggestions();
    const onDrop = useOnDrop();
    const { fullWidth, location: origin } = SagaEditor.useEditorContext();

    const onSelect = React.useCallback(
        (e: React.MouseEvent) => {
            if (
                Node.string(props.element).trim() === '' &&
                !isVoid(props.element) &&
                (isParagraph(props.element) || !isMovableContainerElement(props.element))
            ) {
                const path = ReactEditor.findPath(editor, props.element);

                EditorOperations.Selection.safeSelectByLocation(editor, path);
                ReactEditor.focus(editor);
                const selection = editor.selection;
                if (selection) {
                    e.stopPropagation();
                    openSuggestions({
                        range: selection,
                        rangeRef: SlateEditor.rangeRef(editor, selection, { affinity: 'inward' }),
                        trigger: LINE_START_TRIGGER,
                    });
                }
            } else {
                if (!isVoid(element)) {
                    openTurnIntoSuggestions({
                        ref: iconRef,
                        blockId: element.id,
                    });
                }

                const path = ReactEditor.findPath(editor, props.element);
                if (path) {
                    Transforms.select(editor, path);
                }
            }
        },
        [props.element, editor, openSuggestions, element, openTurnIntoSuggestions],
    );

    const onMouseOut = (event: React.MouseEvent) => {
        event.stopPropagation();
        setMouseOver(false);
    };

    const nestedDragging = useContext(IsDraggingContext);
    const dragDropManager = useDragDropManager();

    const path = ReactEditor.findPath(editor, element);

    // we want to make sure that the selection includes the current path, otherwise we have this weird
    // ux issue where we have a node selected and drag something completely different but the selection is still being dragged
    const location =
        selection && Range.isExpanded(selection) && isMultilineSelection && Range.includes(selection, path)
            ? selection
            : path;

    const item: DndBlockItem = {
        id,
        type: dndTypes.BLOCK,
        origin,
        location,
    };

    const [{ isDragging }, drag, preview] = useDrag({
        type: dndTypes.BLOCK,
        collect(monitor) {
            return {
                isDragging: isMultilineSelection
                    ? isSelected && (dragDropManager.getMonitor().isDragging() || monitor.isDragging())
                    : monitor.isDragging(),
            };
        },
        item,
    });

    const onMouseOver = (event: React.MouseEvent) => {
        event.stopPropagation();
        if (!isDragging) {
            setMouseOver(true);
        }
        setHighlight(null);
    };
    const isDndEnabled = useIsDragAndDropEnabled();

    const { overArea, dropRef } = useBlockDrop({
        onDrop(item, monitor) {
            // This makes sure that we only perform the action for the nested draggable element
            // and not for any paret that might be a drop target
            const isShallowOver = monitor.isOver({ shallow: true });

            if (isShallowOver && !nestedDragging) {
                if ('type' in item) {
                    track('block-drag-and-drop', { type: item.type, source: 'draggable' });
                } else {
                    track('block-drag-and-drop', { type: 'image', source: 'draggable' });
                }

                let targetPath = ReactEditor.findPath(editor, props.element);

                if (overArea === 'bottom') {
                    targetPath = EditorOperations.Selection.shiftPathEnd(targetPath, 1);
                }

                onDrop({ item, editor, path: targetPath });
            }
        },
        isEnabled: isDndEnabled,
    });

    const showDrag = !isDragging && mouseOver;

    return (
        <IsDraggingContext.Provider value={isDragging || nestedDragging}>
            <ElementContainer onMouseOver={onMouseOver} onMouseOut={onMouseOut} fullWidth={fullWidth}>
                {overArea === 'top' && !nestedDragging && (
                    <div className="absolute -top-0.5 left-0 right-0 select-none" contentEditable={false}>
                        <DropIndicator />
                    </div>
                )}

                {overArea === 'bottom' && !nestedDragging && (
                    <div className="absolute -bottom-0.5 left-0 right-0 select-none" contentEditable={false}>
                        <DropIndicator />
                    </div>
                )}

                <MemoizedDraggableContainer
                    drag={drag}
                    iconRef={iconRef}
                    preview={preview}
                    showDrag={showDrag}
                    element={props.element}
                    onSelect={onSelect}
                    ref={dropRef}
                    previewClassName="dark:bg-zinc-700 bg-saga-bg-blue"
                    draggingClassName="dark:bg-zinc-700 bg-saga-bg-blue opacity-25"
                >
                    {props.children}
                </MemoizedDraggableContainer>
            </ElementContainer>
        </IsDraggingContext.Provider>
    );
};

export default DraggableElement;
