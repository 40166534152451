import { Processor, unified } from 'unified';
import gfm from 'remark-gfm';
import { Root } from '../types';
import { mdastToSagaPlugin, MdastToSagaOpts } from './mdastToSaga';
import parse from 'remark-parse';

export function mdToSagaProcessor(opts?: MdastToSagaOpts) {
    return unified().use(parse).use(gfm).use(mdastToSagaPlugin, opts) as Processor<Root, Root, Root, void>;
}

export async function mdToSaga(input: string, opts?: MdastToSagaOpts): Promise<Root> {
    const processor = mdToSagaProcessor(opts);
    const mdast = processor.parse(input);
    return processor.run(mdast);
}
