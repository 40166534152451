import classNames from 'classnames';
import React, { ButtonHTMLAttributes, forwardRef, HTMLAttributes, ReactNode, RefObject } from 'react';
import { MoreHorizontal, X } from 'react-feather';

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
    variant?: 'primary' | 'secondary' | 'alert';
    size?: 'base' | 'small' | 'action';
    innerRef?: RefObject<HTMLButtonElement>;
};

const Button = function Button({ innerRef, className, variant = 'primary', size = 'base', ...props }: ButtonProps) {
    return (
        <button
            {...props}
            ref={innerRef}
            className={classNames(
                'block outline-none focus:outline-none leading-none border font-semibold rounded whitespace-nowrap disabled:opacity-75',
                {
                    'border-2 dark:border-zinc-40': props.disabled && variant === 'primary',
                    'shadow-md border-2 bg-saga-primary text-saga-text border-saga-black hover:border-saga-black hover:bg-saga-black hover:text-white focus-visible:outline-dotted focus-visible:outline-saga-gray-500':
                        !props.disabled && variant === 'primary',
                    'bg-white border-saga-shade-900 dark:bg-zinc-700 dark:border-zinc-500 hover:bg-saga-black hover:text-white focus-visible:outline-dotted focus-visible:outline-saga-gray-500':
                        !props.disabled && variant === 'secondary',
                    'bg-saga-red active:bg-saga-red text-white border border-transparent hover:border-saga-black hover:bg-saga-black hover:text-white':
                        !props.disabled && variant === 'alert',
                    'px-5 py-3': size === 'base',
                    'px-2 py-1': size === 'small',
                    'px-2 h-8': size === 'action',
                },
                className,
            )}
        >
            {props.children}
        </button>
    );
};

type ButtonNewProps = ButtonHTMLAttributes<HTMLButtonElement> & {
    innerRef?: RefObject<HTMLButtonElement>;
    variant?: 'primary' | 'secondary';
    icon?: ReactNode;
    testId?: string;
};

export const ButtonNew = ({ innerRef, className, variant = 'primary', icon, testId, ...props }: ButtonNewProps) => {
    const buttonStyles = {
        primary: {
            default: `bg-saga-primary text-saga-black border-saga-black
                        hover:text-white hover:bg-saga-black hover:border-saga-black
                        dark:hover:bg-white dark:hover:text-black dark:border-saga-primary dark:hover:border-white`,
            disabled: `bg-white text-saga-gray-400 border-saga-gray-300
                        dark:bg-transparent dark:text-saga-gray-400 dark:border-saga-gray-650`,
        },
        secondary: {
            default: `bg-white text-saga-black border-saga-black
                        hover:bg-saga-black hover:text-white hover:border-saga-black
                        dark:bg-transparent dark:text-white dark:border-white dark:hover:bg-white dark:hover:text-saga-black dark:hover:border-white`,
            disabled: `bg-white text-saga-gray-400 border-saga-gray-300
                        dark:bg-transparent dark:text-saga-gray-550 dark:border-saga-gray-650`,
        },
    };

    return (
        <button
            {...props}
            ref={innerRef}
            data-testid={testId}
            className={classNames(
                'font-semibold text-sm px-4 leading-[30px] rounded border', // text-saga-black border-saga-black
                {
                    [`${buttonStyles.primary.default}`]: !props.disabled && variant === 'primary',
                    [`${buttonStyles.primary.disabled}`]: props.disabled && variant === 'primary',
                    [`${buttonStyles.secondary.default}`]: !props.disabled && variant === 'secondary',
                    [`${buttonStyles.secondary.disabled}`]: props.disabled && variant === 'secondary',
                },
                className,
            )}
        >
            <div className={classNames('flex items-center justify-center', { 'space-x-1': icon })}>
                {icon && icon}
                <span>{props.children}</span>
            </div>
        </button>
    );
};

type SelectButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
    checked: boolean;
    check: () => void;
    ref?: RefObject<HTMLButtonElement>;
};

Button.Plain = forwardRef<
    HTMLButtonElement,
    Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'className'> & {
        isSelected?: boolean;
        widthFull?: boolean;
        withBorder?: boolean;
        isDesktop?: boolean;
        roundedMD?: boolean;
    }
>(function ButtonPlain({ children, isSelected, widthFull, withBorder, isDesktop, roundedMD, ...props }, ref) {
    return (
        <button
            {...props}
            ref={ref}
            className={classNames('outline-none focus:outline-none leading-none', {
                'text-saga-gray-500 border-saga-gray-500 opacity-50 cursor-not-allowed': props.disabled,
                'hover:bg-saga-gray-250 dark:hover:bg-saga-gray-700 focus-visible:bg-saga-gray-150 dark:focus-visible:bg-saga-gray-800':
                    !props.disabled,
                'bg-saga-gray-150 dark:bg-saga-gray-800 hover:bg-saga-gray-250 dark:hover:bg-saga-gray-700 dark:hover:text-zinc-50 dark:text-zinc-200':
                    isSelected,
                'transition-colors duration-200': !isSelected,
                'hover:bg-saga-bg-gray dark:hover:text-zinc-200/85 ': !isSelected && !props.disabled,
                'w-full': widthFull,
                'border-[1px] border-saga-gray-300 dark:border-saga-gray-700': withBorder,
                'mt-1': isDesktop,
                'rounded-md': roundedMD,
                rounded: !roundedMD,
            })}
        >
            {children}
        </button>
    );
});

Button.ShareModalPlain = forwardRef<
    HTMLButtonElement,
    Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'className'> & {
        isSelected?: boolean;
        widthFull?: boolean;
        withBorder?: boolean;
        isDesktop?: boolean;
    }
>(function ShareModalPlain({ children, isSelected, widthFull, withBorder, isDesktop, ...props }, ref) {
    return (
        <button
            {...props}
            ref={ref}
            className={classNames('rounded outline-none focus:outline-none leading-none h-8', {
                'text-saga-gray-500 border-saga-gray-500 opacity-50 cursor-not-allowed': props.disabled,
                'hover:bg-saga-gray-250 dark:hover:bg-saga-gray-700 focus-visible:bg-saga-gray-250 dark:focus-visible:bg-saga-gray-700':
                    !props.disabled,
                'bg-saga-bg-blue dark:bg-saga-dark-gray hover:bg-saga-bg-blue dark:hover:text-zinc-50 text-saga-blue-dark dark:text-zinc-200':
                    isSelected,
                'transition-colors duration-200': !isSelected,
                'w-full': widthFull,
                'border-[1px] border-saga-gray-200 dark:border-saga-gray-700': withBorder && !props.disabled,
                'mt-1': isDesktop,
            })}
        >
            {children}
        </button>
    );
});

Button.Black = forwardRef<
    HTMLButtonElement,
    Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'className'> & { widthFull?: boolean }
>(function ButtonBlack({ children, widthFull, ...props }, ref) {
    return (
        <button
            {...props}
            ref={ref}
            className={classNames(
                'rounded outline-none focus:outline-none leading-none text-saga-shade-100 bg-saga-shade-900 dark:bg-saga-shade-200 dark:text-saga-shade-900 transition-colors duration-200',
                {
                    'text-saga-gray-500 border-saga-gray-500 opacity-50 cursor-not-allowed': props.disabled,
                    'hover:bg-saga-shade-900/70 dark:hover:bg-saga-shade-200/70 focus-visible:bg-saga-shade-900/70 dark:focus-visible:bg-zinc-600 border-transparent':
                        !props.disabled,
                    'w-full': widthFull,
                },
            )}
        >
            {children}
        </button>
    );
});

Button.LargePadding = function ButtonBasePadding({ children }: { children: ReactNode }) {
    return <div className="p-3.5">{children}</div>;
};

Button.BasePadding = function ButtonBasePadding({ children }: { children: ReactNode }) {
    return <div className="p-2">{children}</div>;
};

Button.SmallPadding = function ButtonSmallPadding({ children }: { children: ReactNode }) {
    return <div className="p-1.5">{children}</div>;
};

Button.XsPadding = function ButtonSmallPadding({ children }: { children: ReactNode }) {
    return <div className="p-1">{children}</div>;
};

Button.WithIconPadding = function ButtonWithIconPadding({ children }: { children: ReactNode }) {
    return <div className={classNames('pl-2 pr-2.5 py-1.5')}>{children}</div>;
};

Button.SelectButton = forwardRef<HTMLButtonElement, SelectButtonProps>(function Button(
    { className, checked = false, check, ...props },
    ref,
) {
    return (
        <button
            {...props}
            ref={ref}
            className={classNames(
                'flex flex-row outline-none focus:outline-none focus-visible:outline-dotted focus-visible:outline-saga-gray-500 text-sm border border-saga-gray-500 shadow-md items-center px-2 py-1 md:p-2 space-x-1 rounded',

                {
                    'hover:bg-saga-gray-200 dark:hover:bg-zinc-600': !checked,
                    'bg-saga-bg-blue dark:bg-zinc-600': checked,
                },
                className,
            )}
            onClick={check}
        >
            <input type="checkbox" className="w-6" tabIndex={-1} checked={checked} onChange={check} onClick={check} />
            <div className="whitespace-nowrap">{props.children}</div>
        </button>
    );
});

Button.Circle = function ButtonCircle({
    children,
    isOpen,
    ...props
}: Omit<HTMLAttributes<HTMLDivElement>, 'className'> & { isOpen?: boolean }) {
    return (
        <div
            {...props}
            className={classNames(
                'p-2 w-8 h-8 flex items-center dark:border dark:border-solid dark:border-zinc-700 justify-center rounded-full focus:outline-none active:bg-gray-100 active:shadow-xs shadow-popupBorder bg-white dark:bg-saga-gray-1000',
                {
                    'bg-saga-bg-blue dark:bg-zinc-600 hover:bg-saga-bg-blue dark:hover:text-zinc-50 text-saga-blue-dark dark:text-zinc-200 shadow-saga-blue-dark/15':
                        isOpen,
                    'hover:bg-saga-bg-gray dark:hover:bg-saga-gray-700': !isOpen,
                },
            )}
        >
            {children}
        </div>
    );
};

Button.Selectable = function ButtonSelectable({
    children,
    isSelected,
    onClick,
}: {
    children: ReactNode;
    isSelected: boolean;
    onClick(): void;
}) {
    return (
        <button
            className={classNames(
                'hover-trigger focus:outline-none flex h-8 px-4 py-2 items-center rounded text-xs leading-normal border-saga-gray-200 dark:border-zinc-600 dark:active:bg-zinc-600 border cursor-pointer',
                {
                    'hover:bg-saga-gray-200 dark:hover:bg-zinc-700/50': !isSelected,
                    'bg-saga-gray-200 dark:bg-zinc-600': isSelected,
                },
            )}
            onClick={onClick}
        >
            {children}
        </button>
    );
};

Button.Action = forwardRef<
    HTMLButtonElement,
    { children: ReactNode } & Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'children'>
>(function ButtonAction({ children, className, disabled, ...props }, ref) {
    return (
        <button
            ref={ref}
            {...props}
            className={classNames(
                'flex flex-row shadow-button h-8 items-center text-xs cursor-pointer leading-normal font-semibold bg-saga-gray-150  dark:bg-saga-gray-700 hover:bg-saga-gray-250 dark:hover:bg-saga-gray-700 border-saga-gray-200 dark:border-saga-gray-800 hover:border-saga-gray-500 focus:outline-none active:shadow-xs rounded',
                className,
                {
                    'bg-white dark:bg-saga-gray-900 text-saga-gray-600 border border-saga-gray-200 dark:border-saga-gray-800 shadow-none pointer-events-none':
                        disabled,
                },
            )}
        >
            {children}
        </button>
    );
});

Button.DashedPill = forwardRef<
    HTMLButtonElement,
    { children: ReactNode } & Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'children'> & { variant?: string }
>(function ButtonDashedPill({ children, ...props }, ref) {
    return (
        <button
            ref={ref}
            {...props}
            className={classNames(
                'px-1 min-h-[24px] border border-dashed border-saga-gray-400 text-saga-gray-400 hover:bg-saga-gray-400 hover:text-saga-gray-200 dark:hover:bg-saga-gray-700 dark:hover:text-saga-text-darkmode hover:border-solid rounded-md focus:outline-none text-sm',
                { 'cursor-pointer': props.disabled },
                {
                    'dark:border-saga-gray-800 dark:text-saga-gray-650 dark:hover:border-saga-gray-700': !props.variant,
                },
                {
                    'dark:border-saga-gray-200 dark:text-saga-gray-150 dark:hover:border-saga-gray-400':
                        props.variant === 'import-button',
                },
            )}
        >
            {children}
        </button>
    );
});

Button.Dashed = forwardRef<
    HTMLButtonElement,
    { children: ReactNode } & Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'children'>
>(function ButtonDashedPill({ children, ...props }, ref) {
    return (
        <button
            ref={ref}
            {...props}
            className={classNames(
                'border border-dashed border-saga-gray-350 text-saga-gray-350 hover:bg-saga-gray-500 hover:text-saga-gray-200 dark:border-saga-gray-800 dark:text-saga-gray-650 dark:hover:border-saga-gray-700 dark:hover:bg-saga-gray-700 dark:hover:text-saga-text-darkmode hover:border-solid',
                { 'cursor-pointer': props.disabled },
                props.className,
            )}
        >
            {children}
        </button>
    );
});

Button.Pill = forwardRef<
    HTMLButtonElement,
    { children: ReactNode } & Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'children'>
>(function ButtonPill({ children, ...props }, ref) {
    return (
        <button
            {...props}
            ref={ref}
            className={classNames(
                'my-auto flex px-1.5 items-center bg-white dark:bg-inherit border border-saga-gray-200 dark:border-saga-gray-700 dark:hover:bg-saga-gray-1000 rounded-md focus:outline-none text-sm',
                { 'cursor-pointer': props.disabled },
            )}
        >
            {children}
        </button>
    );
});

Button.XButton = forwardRef<
    HTMLButtonElement,
    Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'className'> & { label?: string }
>(function XButton({ onClick, label, ...rest }, ref) {
    return (
        <Button.Plain ref={ref} onClick={onClick} {...rest}>
            <Button.BasePadding>
                <X size={20} />
                {label && <span className="sr-only">{label}</span>}
            </Button.BasePadding>
        </Button.Plain>
    );
});

Button.NestedButton = forwardRef<HTMLButtonElement, Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'className'>>(
    function ButtonNestedButton({ children, ...props }, ref) {
        return (
            <button
                ref={ref}
                className="h-6 w-6 px-1 bg-white dark:bg-saga-gray-1000 flex flex-row cursor-pointer items-center justify-center text-xs leading-normal hover:bg-saga-gray-250 dark:hover:bg-saga-gray-700 font-semibold focus:outline-none active:shadow-xs rounded"
                {...props}
            >
                {children}
            </button>
        );
    },
);

Button.RemovableOption = function ButtonRemovableOption({
    onRemove,
    children,
    label,
    disabled,
    backgroundColour,
}: {
    onRemove?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    children: ReactNode;
    label?: string;
    disabled?: boolean;
    backgroundColour?: string;
}) {
    return (
        <div
            className={classNames(`flex px-1 rounded-md text-sm items-center max-w-full min-w-0`, {
                [`${backgroundColour}`]: backgroundColour,
                'border border-saga-gray-200 dark:border-saga-gray-800': !backgroundColour,
            })}
        >
            <div className="truncate min-w-0">{children}</div>

            {!disabled && (
                <button type="button" className="cursor-pointer focus:outline-none" onClick={onRemove}>
                    <span className="sr-only">{label}</span>
                    <X className="flex-none text-saga-gray-500 dark:text-zinc-400 my-auto w-4 ml-1 rounded" />
                </button>
            )}
        </div>
    );
};

Button.MenuButton = forwardRef<
    HTMLButtonElement & { children: ReactNode },
    Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'className'>
>(function MenuButton({ children, ...props }, ref) {
    return (
        <button
            ref={ref}
            className="p-1.5 m-1 bg-white dark:bg-zinc-700 shadow-popupSmall inset-0 rounded focus:outline-none active:shadow-xs"
            {...props}
        >
            <MoreHorizontal size={16} />
            {children}
        </button>
    );
});

Button.ColorButton = function ColorButton({
    color,
    label,
    onMouseDown,
    testId,
    isSelected,
}: {
    color: string;
    label: string;
    onMouseDown(): void;
    testId?: string;
    isSelected: boolean;
}) {
    return (
        <div
            onMouseDown={onMouseDown}
            role="button"
            data-testid={testId}
            className={classNames('p-1 rounded', {
                'bg-gray-200 dark:bg-zinc-200/10': isSelected,
            })}
        >
            <span className="sr-only">{`Change the callout background using ${label}`}</span>
            <div className={`rounded-full w-4 h-4 border border-saga-text bg-opacity-50 dark:bg-opacity-70 ${color}`} />
        </div>
    );
};

Button.PopOverButton = forwardRef<
    HTMLButtonElement,
    Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'className'> & {
        selected?: boolean;
        active?: boolean | null;
        hoverable?: boolean;
        height?: number;
    }
>(function PopOverButton({ children, selected, active, hoverable = true, disabled, height = 8, ...props }, ref) {
    const className = classNames(
        'w-full text-sm px-2 py-1 truncate rounded flex focus:bg-saga-gray-150 dark:focus:bg-saga-gray-800 dark:focus:text-zinc-200 focus:outline-none items-center',
        {
            'hover:bg-saga-gray-250 dark:hover:bg-saga-gray-700': (!active && hoverable) || (active && hoverable),
            'bg-saga-gray-150 hover:bg-saga-gray-250 dark:bg-saga-gray-800 dark:hover:bg-saga-gray-700':
                selected && !disabled && !active,
            'bg-saga-gray-200 dark:text-zinc-800': selected && disabled,
            'dark:focus:text-zinc-800': !selected,
            'bg-saga-gray-150 dark:bg-saga-gray-800 text-saga-text dark:text-saga-text-darkmode': active && !disabled,
            'text-saga-text-gray': disabled,
            'hover:bg-saga-gray-150': disabled && hoverable,
            'h-8': height === 8,
            'h-9': height === 9,
        },
    );

    if (disabled) {
        return (
            <span ref={ref} className={classNames(className, 'cursor-default')}>
                {children}
            </span>
        );
    }
    return (
        <button data-selected={selected} ref={ref} {...props} className={className} disabled={disabled}>
            {children}
        </button>
    );
});

Button.PopOverSearchPanelButton = forwardRef<
    HTMLButtonElement,
    Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'className'> & {
        selected?: boolean;
        active?: boolean | null;
        hoverable?: boolean;
        height?: number;
    }
>(function PopOverSearchPanelButton({ children, selected, active, hoverable = true, height, disabled, ...props }, ref) {
    const className = classNames(
        'w-full text-sm px-2 py-1 truncate flex rounded focus:bg-saga-gray-150 dark:focus:bg-saga-gray-800 dark:focus:text-zinc-200 focus:outline-none items-center',
        {
            'hover:bg-saga-gray-250 dark:hover:bg-saga-gray-700': (!active && hoverable) || (active && hoverable),
            'bg-saga-gray-150 hover:bg-saga-gray-250 dark:bg-saga-gray-800 dark:hover:bg-saga-gray-700':
                selected && !disabled && !active,
            'bg-saga-gray-200 dark:text-zinc-800': selected && disabled,
            'dark:focus:text-zinc-800': !selected,
            'bg-saga-gray-150 dark:bg-saga-gray-800 text-saga-text dark:text-saga-text-darkmode': active && !disabled,
            'text-saga-text-gray': disabled,
            'hover:bg-saga-gray-150': disabled && hoverable,
            'h-8': height === 8,
            'h-9': height !== 8,
        },
    );

    if (disabled) {
        return (
            <span ref={ref} className={classNames(className, 'cursor-default')}>
                {children}
            </span>
        );
    }
    return (
        <button data-selected={selected} ref={ref} {...props} className={className} disabled={disabled}>
            {children}
        </button>
    );
});

export default Button;
