import React from 'react';
import { ReactEditor, useSlate, useSlateStatic } from 'slate-react';
import { useEffect } from 'react';
import { useCallback } from 'react';
import UpdatedTitleNotice from './UpdatedTitleNotice';
import ReferenceResults from './ReferenceResults';
import HighlightsProvider from '../HighlightsProvider';
import { useCollectionsSnapshot, useShallowTasks } from '@/hooks/SpaceHooks';
import { SagaLocation, SagaEditor, Title, SpaceOperations, EditorOperations } from '@saga/shared';
import { useRecents } from '../RecentsContext';
import { useMembers } from '../MembersProvider';
import { searchIntegrations } from '../search/useSearchQuery';
import * as api from '@saga/api';
import { BlocksPastedSuggestions, Suggestions, usePagesForSuggestion } from './Suggestions';
import { HoveringToolbarContainer } from './popover/HoveringToolbar';
import { EditorAwareness } from './EditorAwareness';
import SpaceEditable from './SpaceEditable';
import { Editor, Transforms } from 'slate';
import AIPopoverProvider from '@/components/editor/popover/AIPopoverProvider';
import useOnTitleChanged from '@/hooks/useOnTitleChanged';
import { debounce } from 'lodash';
import { useSpace } from '@/components/SpaceProvider';
import { useDocumentAwareness, useDocumentYBlocks } from '@/components/RealtimeDocumentProvider';
import { usePageAccess } from '@/components/PagesPermissionsBySpaceProvider';
import { useCurrentWorkspace } from '@/components/WorkspaceContext';

const { ContainerContext, RealtimeEditor } = SagaEditor;

function checkOverflowing(editor: ReactEditor, container: HTMLDivElement | null) {
    if (!editor.selection || !container) {
        return false;
    }

    const domRange = ReactEditor.toDOMRange(editor, editor.selection);
    const domEl = domRange.startContainer.parentElement;
    if (domEl) {
        const rect = domEl.getBoundingClientRect();
        const containerRect = container.getBoundingClientRect();
        return rect.top < containerRect.top || rect.bottom > containerRect.bottom;
    }

    return false;
}

declare global {
    interface Window {
        quickEditor?: Editor;
        Transforms?: typeof Transforms;
    }
}

function WindowBinding() {
    window.quickEditor = useSlateStatic();
    window.Transforms = Transforms;
    return null;
}

export const QuickEditorSelectionOverflowContext = React.createContext(false);
export const useQuickEditorSelectionOverflow = () => React.useContext(QuickEditorSelectionOverflowContext);

function QuickEditContainer({ children }: { children: React.ReactNode }) {
    const containerRef = React.useRef<HTMLDivElement>(null);
    const editor = useSlate();

    React.useEffect(() => {
        if (containerRef.current) {
            containerRef.current.scrollTop = 0;
        }
    }, []);

    const [isOverflowing, setIsOverflowing] = React.useState(false);

    const onScroll = useCallback(() => {
        const isOverflowing = checkOverflowing(editor, containerRef.current);
        setIsOverflowing(isOverflowing);
    }, [editor]);

    useEffect(() => {
        onScroll();
    }, [onScroll]);

    return (
        <QuickEditorSelectionOverflowContext.Provider value={isOverflowing}>
            <ContainerContext.Provider value={containerRef}>
                <div
                    ref={containerRef}
                    onScroll={onScroll}
                    className="px-3 pb-24 pt-3 overflow-y-auto overflow-x-hidden space-y-2 mx-auto w-full flex-grow min-h-0 relative"
                >
                    {children}
                </div>
            </ContainerContext.Provider>
        </QuickEditorSelectionOverflowContext.Provider>
    );
}

export default function QuickEditPage({
    location,
    zIndex,
    blockPlugins,
}: {
    location: SagaLocation.PageLocation;
    zIndex: number;
    blockPlugins: SagaEditor.Plugins.BlockPlugin[];
}) {
    const { data: yBlocks } = useDocumentYBlocks(location.pageId);
    const { space, provider } = useSpace();
    const { canEdit } = usePageAccess(location.pageId);
    const awareness = useDocumentAwareness(location.pageId);
    const { recentLocations } = useRecents();
    const { members } = useMembers();
    const { data } = api.useLinearTeamsQuery();
    const linearTeams = React.useMemo(() => data?.linearTeams ?? [], [data]);
    const tasks = useShallowTasks();
    const pages = usePagesForSuggestion();
    const { id: spaceId } = useCurrentWorkspace();

    const collections = useCollectionsSnapshot();

    const updateTitleDebounced = debounce((title: Title) => {
        const pageTitle = EditorOperations.SagaElement.toString(title, blockPlugins);
        const page = SpaceOperations.getPageById(space, location.pageId, ['title']);
        if (page && page.title !== pageTitle) {
            SpaceOperations.updatePartialPage(space, location.pageId, { title: pageTitle });
        }
    }, 500);

    useOnTitleChanged(yBlocks, (title: Title) => {
        updateTitleDebounced(title);
    });

    if (yBlocks == null || awareness == null) {
        return null;
    }

    return (
        <RealtimeEditor
            fullWidth={false}
            location={location}
            yBlocks={yBlocks}
            canEdit={canEdit}
            zIndex={zIndex}
            blockPlugins={blockPlugins}
            awareness={awareness}
            allowCollapsingListItems={true}
        >
            <WindowBinding />
            <QuickEditContainer>
                <AIPopoverProvider>
                    <Suggestions
                        location={location}
                        tasks={tasks}
                        recentLocations={recentLocations}
                        members={members}
                        linearTeams={linearTeams}
                        onSearch={searchIntegrations}
                        collections={collections}
                        space={space}
                        provider={provider}
                        pages={pages}
                        spaceId={spaceId}
                    >
                        <BlocksPastedSuggestions>
                            <HighlightsProvider location={location}>
                                <ReferenceResults location={location}>
                                    <div className="absolute px-3 top-6 left-3 right-0">
                                        <UpdatedTitleNotice location={location} blockPlugins={blockPlugins} />
                                    </div>
                                    <EditorAwareness>
                                        <SpaceEditable />
                                    </EditorAwareness>
                                    <HoveringToolbarContainer canSearch />
                                </ReferenceResults>
                            </HighlightsProvider>
                        </BlocksPastedSuggestions>
                    </Suggestions>
                </AIPopoverProvider>
            </QuickEditContainer>
        </RealtimeEditor>
    );
}
