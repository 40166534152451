import React from 'react';

export default function useOnEscape(handler: () => void, active: boolean) {
    const handlerRef = React.useRef(handler);
    handlerRef.current = handler;

    React.useEffect(() => {
        if (!active) return;

        function onKeyDown(e: KeyboardEvent) {
            if (e.code === 'Escape') {
                e.stopImmediatePropagation();
                handlerRef.current();
            }
        }

        window.addEventListener('keydown', onKeyDown, { capture: true });
        return () => window.removeEventListener('keydown', onKeyDown, { capture: true });
    }, [active]);
}
