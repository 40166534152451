import { BlockBuilder } from '../..';
import { newBlankPage } from '../../pageUtils';
import { WeakTask } from '../../types';

export function newSagaBasicsPageIt(task: WeakTask, collectionIds: string[]) {
    return newBlankPage({
        title: 'Nozioni di base su Saga',
        icon: { type: 'emoji', colons: ':wave:' },
        collections: collectionIds,
        blocks: [
            BlockBuilder.title('Nozioni di base su Saga'),
            BlockBuilder.callout([
                BlockBuilder.paragraph([BlockBuilder.text('Digita ovunque su questa pagina per modificarla!')]),
            ]),
            BlockBuilder.paragraph(),
            BlockBuilder.image('https://saga-public.s3.amazonaws.com/1-block-selection-menu.webp'),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Premi '),
                BlockBuilder.text('@', { code: true }),
                BlockBuilder.text(' o '),
                BlockBuilder.text('/', { code: true }),
                BlockBuilder.text(
                    ' per aprire il menu di autocompletamento e aggiungere nuovo contenuto come titoli, attività da fare, immagini, file, tabelle e altro.',
                ),
            ]),
            BlockBuilder.paragraph(),
            BlockBuilder.paragraph(),
            BlockBuilder.paragraph(),
            BlockBuilder.image('https://saga-public.s3.amazonaws.com/2-text-styling-menu.webp'),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Seleziona testo', { bold: true }),
                BlockBuilder.text(' per '),
                BlockBuilder.text('formattarlo,', { italic: true }),
                BlockBuilder.text(' trasformarlo in un altro tipo di contenuto, '),
                BlockBuilder.text('evidenziarlo', { highlight: true }),
                // todo highlight in red
                BlockBuilder.text(', o migliorarlo con l\'AI.'),
            ]),
            BlockBuilder.paragraph(),
            BlockBuilder.paragraph(),
            BlockBuilder.image('https://saga-public.s3.amazonaws.com/3-drag-and-drop-images-and-files.webp'),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Aggiungi immagini e file', { bold: true }),
                BlockBuilder.text(' trascinandoli o incollandoli.'),
            ]),
            BlockBuilder.paragraph(),
            BlockBuilder.paragraph(),
            BlockBuilder.image('https://saga-public.s3.amazonaws.com/4-drag-and-drop-blocks-of-content.webp'),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Trascina e rilascia', { bold: true }),
                BlockBuilder.text(' qualsiasi blocco selezionando e trascinando l\'icona ☰ sul lato sinistro del blocco.'),
            ]),
            BlockBuilder.paragraph(),
            BlockBuilder.paragraph(),
            BlockBuilder.image('https://saga-public.s3.amazonaws.com/5-unified-search-for-fast-navigation.webp'),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Premi '),
                BlockBuilder.text('⌘ + K', { code: true }),
                BlockBuilder.text(' (macOS) o '),
                BlockBuilder.text('Ctrl + K', { code: true }),
                BlockBuilder.text(' (Windows) per '),
                BlockBuilder.text('trovare qualsiasi cosa', { bold: true }),
                BlockBuilder.text(' e navigare nel tuo spazio di lavoro.'),
            ]),
            BlockBuilder.paragraph(),
            BlockBuilder.paragraph(),
            BlockBuilder.image('https://saga-public.s3.amazonaws.com/6-creating-and-managing-tasks.webp'),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Crea attività', { bold: true }),
                BlockBuilder.text(' aprendo il menu di autocompletamento e selezionando '),
                BlockBuilder.text('Attività', { code: true }),
                BlockBuilder.text(
                    '. Le attività sono un tipo speciale di pagine con un assegnatario, una data di scadenza e una priorità. Apri l\'attività qui sotto per saperne di più 👇.',
                ),
            ]),
            BlockBuilder.taskBlock(task.id),
            BlockBuilder.paragraph(),
            BlockBuilder.paragraph(),
            BlockBuilder.image('https://saga-public.s3.amazonaws.com/7-adding-to-pinned.webp'),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Aggiungi ai preferiti ', { bold: true }),
                BlockBuilder.text('qualsiasi pagina per aggiungerla alla barra laterale cliccando sull\'icona ☆ nell\'angolo in alto a destra.'),
            ]),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Vai alla pagina di 😍 Saga avanzato per esplorare modi più avanzati di usare Saga.'),
            ]),
            BlockBuilder.paragraph(),
            BlockBuilder.paragraph(),
            BlockBuilder.divider(),
            BlockBuilder.headingThree('Hai una domanda?'),
            BlockBuilder.paragraph([
                BlockBuilder.text('Premi il '),
                BlockBuilder.text('?', { bold: true }),
                BlockBuilder.text(
                    ' pulsante nell\'angolo in basso a destra per inviarci una email, inviare richieste di funzionalità o unirti alle nostre comunità di utenti su ',
                ),
                BlockBuilder.link('https://discord.com/invite/cgz2mUEq7P', 'Discord'),
                BlockBuilder.text(', '),
                BlockBuilder.link(
                    'https://sagacommunity.slack.com/join/shared_invite/zt-13m3lrrdt-1x6~l6sLuR8CX~4c3fWwHA#/shared-invite/email',
                    'Slack',
                ),
                BlockBuilder.text(', o '),
                BlockBuilder.link('https://twitter.com/saga_HQ', 'X'),
                BlockBuilder.text(
                    '. Inoltre, visita le guide di aiuto sul nostro sito web e scopri come ottenere il massimo dall\'app.',
                ),
            ]),
            BlockBuilder.rawPrettyLink('https://saga.so/guides', {
                _tag: 'loaded',
                description: "Questa guida ti aiuterà a comprendere i blocchi di costruzione di Saga e a metterti in pari.",
                title: '📍 Guide di Saga',
                logo: 'https://saga.so/thumbnail.png',
            }),
            BlockBuilder.paragraph(),
        ],
    });
}
