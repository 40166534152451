import { DateTime } from 'luxon';
import * as api from '@saga/api';
import i18next from 'i18next';
import { isToday, isYesterday } from 'date-fns';

export function formatDateOptimized(rawDate: string, locale: api.Language | undefined): string {
    const date = DateTime.fromJSDate(new Date(rawDate)).setLocale(locale || api.Language.En);
    return date.toFormat('LLL dd, yyyy');
}

export function formatDateTimeOptimized(rawDate: string, locale: api.Language | undefined): string {
    const t = i18next.t;
    const jsDate = new Date(rawDate);
    const date = DateTime.fromJSDate(jsDate).setLocale(locale || api.Language.En);
    return isToday(jsDate) ? `${t('time.today')}, ${date.toFormat('HH:mm')}` : date.toFormat('LLL dd, HH:mm');
}

export function formatTimeAgo(date: DateTime): string {
    const t = i18next.t;

    const now = DateTime.local();

    const weekNumberDiff = now.weekNumber - date.weekNumber;
    const monthDiff = now.month - date.month;
    const yearDiff = now.year - date.year;
    const daysDiff = Math.abs(Math.round(date.diff(now, 'days').days));

    if (isToday(date.toJSDate())) {
        return t('time.today');
    }

    if (isYesterday(date.toJSDate())) {
        return t('time.yesterday');
    }

    if (weekNumberDiff === 0) {
        return t('time.days_ago', { days: daysDiff });
    }

    if (weekNumberDiff === 1) {
        return t('time.last_week');
    }

    if (weekNumberDiff > 1 && weekNumberDiff <= 4) {
        return t('time.weeks_ago', { weeks: weekNumberDiff });
    }

    if (monthDiff === 1) {
        return t('time.last_month');
    }

    if (yearDiff === 1) {
        return t('time.last_year');
    }

    if (yearDiff > 1) {
        return t('time.years_ago', { years: yearDiff });
    }

    return t('time.months_ago', { months: monthDiff });
}
