import React, { useContext, useState, useCallback } from 'react';
import LoadingModal from '@/components/loading/LoadingModal';

type LoadingContextType = {
    showLoadingSpinner: (message?: string) => void;
    hideLoadingSpinner: () => void;
};

const LoadingContext = React.createContext<LoadingContextType>({
    showLoadingSpinner: () => {},
    hideLoadingSpinner: () => {},
});

export const useLoadingContext = () => useContext(LoadingContext);

export const LoadingContextProvider: React.FC = ({ children }) => {
    const [loadingState, setLoadingState] = useState<{ isLoading: true; message?: string } | { isLoading: false }>({
        isLoading: false,
    });

    const showLoadingSpinner = useCallback((message?: string) => {
        setLoadingState({ isLoading: true, message });
    }, []);

    const hideLoadingSpinner = useCallback(() => {
        setLoadingState({ isLoading: false });
    }, []);

    return (
        <LoadingContext.Provider
            value={{
                showLoadingSpinner,
                hideLoadingSpinner,
            }}
        >
            {loadingState.isLoading && <LoadingModal isOpen={loadingState.isLoading} message={loadingState.message} />}
            {children}
        </LoadingContext.Provider>
    );
};
