import React from 'react';
import { Range } from 'slate';
import { SlateYjs, assertNonNull, SagaEditor, SagaLocation, Colors } from '@saga/shared';
import { useUserContext } from '../UserContext';
import useAwarenessLocalState from '@/hooks/useAwarenessLocalState';
import { AVAILABLE_COLORS } from '@/constants';
import { useSlateStatic } from 'slate-react';
import useDocumentCollaborators from '@/hooks/useDocumentCollaborators';

type AwarenessSelection = { user: SlateYjs.UserState; selection: Range };
const EditorAwarenessContext = React.createContext<AwarenessSelection[]>([]);
export const useEditorAwareness = () => React.useContext(EditorAwarenessContext);

export function EditorAwareness({ children }: { children: React.ReactNode }) {
    const { yBlocks, awareness, location } = SagaEditor.useEditorContext();
    const editor = useSlateStatic();
    const { user } = useUserContext();
    const color = React.useMemo(() => Colors.stringToColor(user?.id ?? '', AVAILABLE_COLORS), [user?.id]);

    assertNonNull(yBlocks, 'Editor must have yBlocks if using awareness');
    assertNonNull(awareness, 'You need to use an Editor that supports awareness');
    const [, setLocalState] = useAwarenessLocalState(awareness);

    React.useEffect(() => {
        const registerUserToAwareness = () => {
            if (!user) return;

            setLocalState({
                user: {
                    name: user.data.firstName,
                    userId: user.id,
                    color,
                    alphaColor: color + '33',
                },
            });
        };
        registerUserToAwareness();
    }, [user, color, setLocalState]);

    const collaborators = useDocumentCollaborators(SagaLocation.getIdFromLocation(location));
    const states = React.useMemo(() => {
        return collaborators
            .filter((c) => SagaLocation.areLocationsEqual(c.cursor.location, editor.location))
            .map(({ cursor, user }) => {
                const selection = SlateYjs.getRangeFromCursor(yBlocks, cursor);
                return {
                    user,
                    selection,
                };
            })
            .filter(
                (awarenessSelection): awarenessSelection is AwarenessSelection => awarenessSelection.selection != null,
            );
    }, [collaborators, yBlocks, editor]);

    return <EditorAwarenessContext.Provider value={states}>{children}</EditorAwarenessContext.Provider>;
}
