import React from 'react';
import {
    SagaLocation,
    RealtimeSagaEditor,
    SagaEditor,
    EditorOperations,
    Title,
    SpaceOperations,
    SafeSpace,
    AnyBlockItem,
} from '@saga/shared';
import * as Y from 'yjs';
import { useSpace } from '@/components/SpaceProvider';
import { useSpaceAccess } from '@/hooks/useSpaceAccess';
import * as api from '@saga/api';
import { useMembers } from '../MembersProvider';
import { useCollectionsSnapshot, useShallowTasks } from '@/hooks/SpaceHooks';
import classNames from 'classnames';
import useMobile from '@/hooks/useMobile';
import useInterfaceSettings from '@/hooks/useInterfaceSettings';
import { useRecents } from '../RecentsContext';
import { searchIntegrations } from '../search/useSearchQuery';
import { BlockPlugin } from '@/../../shared/src/Editor/Plugins';
import { useIsOnline } from '@/components/BrowserContextProvider';
import {
    BlocksPastedSuggestions,
    InsertIntegrationSuggestionsComponent,
    Suggestions,
    usePagesForSuggestion,
} from '../editor/Suggestions';
import { HoveringToolbarContainer } from '../editor/popover/HoveringToolbar';
import SpaceEditable from '../editor/SpaceEditable';
import { EditorAwareness } from '../editor/EditorAwareness';
import { track } from '@/analytics';
import { handleBlockDrop } from '@/lib/handleBlockDrop';
import { useUploadImage } from '@/lib/Image';
import { useUploadFile } from '@/lib/File';
import BottomDropArea from '../editor/BottomDropArea';
import DragAndDrop from '../editor/DragAndDrop';
import AIPopoverProvider from '@/components/editor/popover/AIPopoverProvider';
import useOnTitleChanged from '@/hooks/useOnTitleChanged';
import { debounce } from 'lodash';
import { useDocumentAwareness, usePerformBlockChangeWithEditor } from '@/components/RealtimeDocumentProvider';
import { BlocksLocation } from '../../../../shared/src/SagaLocation';
import { Editor } from 'slate';
import { usePageAccess } from '@/components/PagesPermissionsBySpaceProvider';
import { useCurrentWorkspace } from '@/components/WorkspaceContext';
import { ArchivedTaskNotice } from '../editor/ArchivedNotice';

type Props = {
    location: SagaLocation.TaskLocation;
    yBlocks: Y.Array<any>;
    focus?: { blockId: string; offset?: number };
    zIndex?: number;
    blockPlugins: BlockPlugin[];
    disableInteraction?: boolean;
};

const SpaceTaskEditor = React.forwardRef<any, Props>(
    ({ location, yBlocks, focus, zIndex, blockPlugins, disableInteraction = false }, ref) => {
        const isOnline = useIsOnline();
        const { space, provider } = useSpace();
        const editorRef = React.useRef<RealtimeSagaEditor | null>(null);
        const { permissions } = useSpaceAccess();
        const { canEdit } = usePageAccess(location.taskId);
        const isMobile = useMobile();
        const [{ spellCheck }] = useInterfaceSettings();
        const awareness = useDocumentAwareness(location.taskId);
        const { recentLocations } = useRecents();
        const { members } = useMembers();
        const { data } = api.useLinearTeamsQuery();
        const linearTeams = React.useMemo(() => data?.linearTeams ?? [], [data]);
        const tasks = useShallowTasks();
        const pages = usePagesForSuggestion();
        const collections = useCollectionsSnapshot();
        const onUploadImage = useUploadImage(location);
        const onUploadFile = useUploadFile(location);
        const { id: spaceId } = useCurrentWorkspace();

        const changeBlocksWithEditor = usePerformBlockChangeWithEditor();

        const onChangeBlocks = React.useCallback(
            (space: SafeSpace, location: BlocksLocation, change: (editor: Editor, blocks: AnyBlockItem[]) => void) => {
                changeBlocksWithEditor(space, location, (editor, blocks) => {
                    if (!blocks) return;
                    change(editor, blocks.toJSON());
                });
            },
            [changeBlocksWithEditor],
        );

        React.useImperativeHandle(ref, () => editorRef.current);

        const debounceUpdateTitle = React.useMemo(
            () =>
                debounce((taskId: string, title: string) => {
                    const task = SpaceOperations.findTask(space, taskId);

                    if (task) {
                        if (task.title !== title) {
                            SpaceOperations.updatePartialTask(space, taskId, { title });
                        }
                    }
                }, 500),
            [space],
        );

        useOnTitleChanged(yBlocks, (title: Title) => {
            debounceUpdateTitle(location.taskId, EditorOperations.SagaElement.toString(title, blockPlugins));
        });

        if (!awareness) return null;

        return (
            <SagaEditor.RealtimeEditor
                fullWidth={false}
                editorRef={editorRef}
                location={location}
                yBlocks={yBlocks}
                canEdit={canEdit && isOnline}
                zIndex={zIndex}
                blockPlugins={blockPlugins}
                awareness={awareness}
                allowCollapsingListItems={true}
                disableUserInteraction={disableInteraction}
            >
                <DragAndDrop
                    enabled={!isMobile}
                    onDrop={({ item, editor, path }) => {
                        if ('type' in item) {
                            track('block-drag-and-drop', { type: item.type });
                        } else {
                            track('block-drag-and-drop', { type: 'image' });
                        }
                        handleBlockDrop({
                            editor,
                            item,
                            location,
                            path,
                            space,
                            onUploadImage,
                            onUploadFile,
                            fileUploadLimit: permissions.fileUpload.limitFile,
                            onChangeBlocks,
                        });
                    }}
                >
                    <SagaEditor.Container
                        focus={focus}
                        yBlocks={yBlocks}
                        className={classNames('relative mx-auto flex flex-col w-full sm:pl-8 sm:pr-6', {
                            'px-1': isMobile,
                        })}
                    >
                        <InsertIntegrationSuggestionsComponent>
                            <AIPopoverProvider>
                                <Suggestions
                                    location={location}
                                    recentLocations={recentLocations}
                                    members={members}
                                    linearTeams={linearTeams}
                                    onSearch={searchIntegrations}
                                    tasks={tasks}
                                    collections={collections}
                                    space={space}
                                    provider={provider}
                                    pages={pages}
                                    spaceId={spaceId}
                                >
                                    <BlocksPastedSuggestions>
                                        <ArchivedTaskNotice id={location.taskId} />
                                        <EditorAwareness>
                                            <div
                                                className={classNames({
                                                    'pointer-events-none': disableInteraction,
                                                })}
                                            >
                                                <SpaceEditable spellCheck={spellCheck} />
                                            </div>
                                        </EditorAwareness>
                                        <BottomDropArea className="w-full flex-grow cursor-text">
                                            {(overArea) =>
                                                overArea != null && (
                                                    <div className="mx-auto max-w-700 bg-saga-blue-light opacity-25 h-1 rounded-sm"></div>
                                                )
                                            }
                                        </BottomDropArea>
                                        <HoveringToolbarContainer canSearch />
                                    </BlocksPastedSuggestions>
                                </Suggestions>
                            </AIPopoverProvider>
                        </InsertIntegrationSuggestionsComponent>
                    </SagaEditor.Container>
                </DragAndDrop>
            </SagaEditor.RealtimeEditor>
        );
    },
);

SpaceTaskEditor.displayName = 'SpaceTaskEditor';

export default SpaceTaskEditor;
