import React, { forwardRef, useRef, useLayoutEffect } from 'react';
import { useCombinedRefs } from '@/hooks/useCombinedRefs';
import Portal from '@/components/popover/Portal';
import classNames from 'classnames';

type ToastContainerProps = {
    isOpen: boolean;
    children: React.ReactNode;
    zIndex?: number;
    noPointer?: boolean;
    className?: string;
};

const ToastContainer = forwardRef<HTMLDivElement, ToastContainerProps>(function ToastContainer(
    { children, isOpen, zIndex, noPointer, className }: ToastContainerProps,
    ref,
) {
    const innerRef = useRef<HTMLDivElement>(null);
    const combinedRef = useCombinedRefs<HTMLDivElement>(innerRef, ref);

    useLayoutEffect(() => {
        const el = innerRef.current;

        if (el) {
            if (isOpen) {
                el.style.opacity = '1';
                if (noPointer) el.style.pointerEvents = 'none';
                else el.style.pointerEvents = 'auto';
                el.style.visibility = 'visible';
                el.style.zIndex = String(zIndex ?? 301);
            } else {
                el.style.pointerEvents = 'none';
                el.style.opacity = '0';
                el.style.visibility = 'hidden';
                el.style.zIndex = '1';
            }
        }
    }, [isOpen, zIndex, noPointer]);

    return (
        <Portal>
            <div
                className={classNames(
                    'absolute bottom-0 right-0 flex flex-col space-y-2 p-6 w-400',
                    { 'pointer-events-none': !isOpen },
                    className,
                )}
                ref={combinedRef}
            >
                {children}
            </div>
        </Portal>
    );
});

export default ToastContainer;
