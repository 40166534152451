import React, { useContext, useEffect, useState } from 'react';
import { BACKEND_URL } from '@/constants';
import { debugLog } from '@/utils';

const BrowserContext = React.createContext<{ isOnline: boolean; csrfTokenReady: boolean }>({
    isOnline: true,
    csrfTokenReady: false,
});

export function BrowserContextProvider({ children }: Readonly<{ children: React.ReactNode }>) {
    const [state, setState] = useState({ isOnline: navigator.onLine, csrfTokenReady: false });

    useEffect(() => {
        (async () => {
            debugLog('BrowserContextProvider: Geting x-csrf-token');
            await fetch(`${BACKEND_URL}csrf-token`, {
                method: 'GET',
                credentials: 'include',
            })
                .then((res) => res.json())
                .then((res) => {
                    document.head.querySelector('meta[name="x-csrf-token"]')!.setAttribute('content', res.csrfToken);
                    setState((prevState) => ({ ...prevState, csrfTokenReady: true }));
                })
                .catch(console.error);
        })();
        function updateOnlineStatus() {
            setState((prevState) => ({ ...prevState, isOnline: navigator.onLine }));
        }

        window.addEventListener('online', updateOnlineStatus);
        window.addEventListener('offline', updateOnlineStatus);

        return () => {
            window.removeEventListener('online', updateOnlineStatus);
            window.removeEventListener('offline', updateOnlineStatus);
        };
    }, []);

    return <BrowserContext.Provider value={state}>{children}</BrowserContext.Provider>;
}

export function useIsOnline() {
    const state = useContext(BrowserContext);
    return state.isOnline;
}

export function useCsrfTokenReady() {
    const state = useContext(BrowserContext);
    return state.csrfTokenReady;
}
