import { Page } from '@saga/shared';
import React from 'react';
import { FileText } from 'react-feather';
import { TableIcon } from './Table';

const Emoji = React.lazy(() => import('@/components/Emoji'));

const fileText14 = <FileText size={14} className="flex-none" />;
const fileText16 = <FileText size={16} className="flex-none" />;

export function PageIcon({
    icon,
    isTemplate,
    size = 14,
}: {
    icon: Page['icon'];
    isTemplate: Page['isTemplate'];
    size?: 14 | 16;
}) {
    if (icon) {
        return (
            <span className="flex-none inline-flex items-center" style={{ width: size, height: size }}>
                <React.Suspense fallback={null}>
                    <Emoji emoji={icon.colons} size={size} />
                </React.Suspense>
            </span>
        );
    }

    if (isTemplate) {
        return size === 14 ? <TableIcon size={14} /> : <TableIcon size={16} />;
    }

    // this is a perf optimization
    return size === 14 ? fileText14 : fileText16;
}
