import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Clock } from 'react-feather';
import { PopOver } from '@/components/popover/PopOver';
import DocumentHistoryModal from '@/components/DocumentHistoryModal';
import { track } from '@/analytics';

export function DocumentHistoryButton({ pageId, onAfterClose }: { pageId: string; onAfterClose?: () => void }) {
    const { t } = useTranslation();
    const [isPageHistoryModalOpen, setIsPageHistoryModalOpen] = useState(false);

    return (
        <>
            <PopOver.RoundedButton
                onClick={() => {
                    track('document-history-opened');
                    setIsPageHistoryModalOpen(true);
                }}
                aria-label="Page History"
                style={{ textTransform: 'capitalize' }}
            >
                <Clock className="stroke-gray-dark mr-2 my-auto" size={14} />
                {t('billing.plan_features.version_history')}
            </PopOver.RoundedButton>
            {isPageHistoryModalOpen && (
                <DocumentHistoryModal
                    pageId={pageId}
                    onClose={() => {
                        setIsPageHistoryModalOpen(false);
                        onAfterClose?.();
                    }}
                />
            )}
        </>
    );
}
