import React from 'react';
import Modal from '@/components/Modal';
import BasicButton from '@/components/styled/BasicButton';
import { CustomPromptType } from '@/../../api/src';
import { track } from '@/analytics';
import { useTranslation } from 'react-i18next';

interface InputParams {
    open: boolean;
    onClose: () => void;
    onConfirm: () => void;
    type?: CustomPromptType;
}

const DeletePromptModal = ({ open, onClose, onConfirm, type }: InputParams) => {
    const { t } = useTranslation();
    return (
        <Modal.Small isOpen={open} onClose={onClose}>
            <Modal.CloseButton />
            <div className="p-4 md:p-8 space-y-2 md:space-y-5 bg-white dark:bg-zinc-700 flex flex-col divide-y divide-saga-gray-150">
                <div className="pb-5">
                    <Modal.Title>
                        {t(
                            type === CustomPromptType.Command
                                ? 'settings.ai.confirm_delete_command'
                                : 'settings.ai.confirm_delete_prompt',
                        )}
                    </Modal.Title>
                </div>
                <div className="flex flex-row pt-5 justify-end">
                    <BasicButton onClick={onClose} variant="secondary">
                        {t('common.cancel')}
                    </BasicButton>
                    <BasicButton
                        onClick={() => {
                            track('ai-prompt-settings-delete', { type });
                            onConfirm?.();
                            onClose?.();
                        }}
                        className="ml-2"
                    >
                        {t('common.delete')}
                    </BasicButton>
                </div>
            </div>
        </Modal.Small>
    );
};

export default DeletePromptModal;
