import { SafeSpace } from '../types';
import { pipe } from 'fp-ts/function';
import { Collection } from '../types';
import { fromEithersToThese, unsafeRight } from '../io-ts-js';
import { logError, monoidCollection } from '../utils';
import * as E from 'fp-ts/Either';
import * as THS from 'fp-ts/These';
import * as O from 'fp-ts/Option';
import * as Sentry from '@sentry/browser';

export default function getCollections(space: SafeSpace): Collection[] {
    const safeCollections = unsafeRight(space.get('collections'));

    const collections = pipe(
        safeCollections.toArray(),
        (array) => array.filter(E.isRight),
        (array) => array.map((a) => a.right),
        (array) => array.map((a) => a.decode()),
        fromEithersToThese(monoidCollection),
        THS.mapLeft((errors) => {
            if (errors.length === 0) return errors;

            errors.forEach((error) => logError(error));

            Sentry.captureMessage('Malformed collections', { extra: { errors } });

            return errors;
        }),
        THS.getRight,
        O.getOrElseW<readonly Collection[]>(() => []),
    );

    return collections as Collection[];
}
