import React from 'react';

export function LinearIcon({ className }: { className?: string }) {
    return (
        <svg width={16} height={16} viewBox="0 0 50 50" className={className}>
            <g>
                <path
                    d="M41.3,44c0.5-0.4,0.9-0.8,1.3-1.3c9.8-9.8,9.8-25.6,0-35.4c-9.8-9.8-25.6-9.8-35.4,0C6.8,7.8,6.4,8.2,6,8.7 L41.3,44z"
                    fill="#5E6AD2"
                />
                <path
                    d="M38.3,46.2L3.8,11.7c-0.7,1.1-1.3,2.2-1.8,3.4L34.9,48C36.1,47.5,37.2,46.9,38.3,46.2z"
                    fill="#5E6AD2"
                />
                <path
                    d="M31.1,49.3L0.7,18.9c-0.4,1.5-0.6,3-0.7,4.5L26.6,50C28.1,49.9,29.6,49.7,31.1,49.3z"
                    fill="#5E6AD2"
                />
                <path d="M21.1,49.8L0.2,28.9c0.8,5.1,3.1,9.9,7,13.9C11.2,46.6,16.1,49,21.1,49.8z" fill="#5E6AD2" />
            </g>
        </svg>
    );
}
