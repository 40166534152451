import React from 'react';
import { SagaEditor as SharedEditor, isMention, Mention as MentionType } from '@saga/shared';
import { useSelected } from 'slate-react';
import { useMembers } from '@/components/MembersProvider';
import { Node } from 'slate';
import classNames from 'classnames';
import { stringToColor } from '@/../../shared/src/utils/Colors';
import { AVAILABLE_COLORS } from '@/constants';
import * as Popover from '@radix-ui/react-popover';
import { OpacityTransition } from '@/components/Transitions';
import { useTranslation } from 'react-i18next';
import { formatDateOptimized } from '@/utils/dateUtils';
import { useUserContext } from '@/components/UserContext';

function MentionPopover({
    isOpen,
    onOpenChange,
    children,
    element,
}: {
    isOpen: boolean;
    onOpenChange(isOpen: boolean): void;
    children: React.ReactNode;
    element: MentionType;
}) {
    const { getMember } = useMembers();
    const creator = element.creatorId ? getMember(element.creatorId) : null;
    const { t } = useTranslation();
    const { user } = useUserContext();
    const creatorFullName = creator ? `${creator.firstName} ${creator.lastName}` : element.creatorName ?? null;

    return (
        <Popover.Root open={isOpen} onOpenChange={onOpenChange}>
            <Popover.Anchor asChild>{children}</Popover.Anchor>
            <Popover.Portal>
                <Popover.Content
                    align="start"
                    onMouseLeave={() => onOpenChange(false)}
                    onOpenAutoFocus={(e) => e.preventDefault()}
                >
                    {creatorFullName ? (
                        <OpacityTransition tag="span" duration={200} isOpen={isOpen}>
                            <div
                                data-testid="highlight-popover"
                                className="relative flex flex-col text-sm px-4 py-2 space-y-1 shadow-popupSmall text-saga-gray-dark dark:text-zinc-200 rounded bg-white dark:bg-zinc-700 dark:border dark:border-solid dark:border-zinc-600 "
                            >
                                <div className="flex space-x-10 justify-between">
                                    <span className="text-sm text-saga-gray-500">{t('common.mentioned_by')}</span>
                                    <span>{creatorFullName}</span>
                                </div>
                                <div className="flex space-x-10 justify-between">
                                    <span className="text-sm text-saga-gray-500">{t('common.mentioned_on')}</span>
                                    <span>
                                        {element.date ? formatDateOptimized(element.date, user?.data?.language) : 'N/A'}
                                    </span>
                                </div>
                            </div>
                        </OpacityTransition>
                    ) : null}
                </Popover.Content>
            </Popover.Portal>
        </Popover.Root>
    );
}

function Mention({ selected, element }: { selected?: boolean; element: MentionType }) {
    const { getMember } = useMembers();
    const member = getMember(element.memberId);
    const elementText = Node.string(element);
    const name = member ? `${member.firstName} ${member.lastName}` : elementText;
    const backgroundColor = stringToColor(element.memberId, AVAILABLE_COLORS).toString();

    return (
        <span
            className={classNames(
                'whitespace-nowrap text-saga-text dark:text-neutral-200 font-medium heading1:font-bold heading2:font-bold title:font-bold within-checked:text-saga-gray-dark dark:within-checked:text-saga-gray-200 relative select-none rounded space-x-1 cursor-pointer',
                {
                    'bg-saga-new-purple-light/20 shadow-lightblue': selected,
                },
            )}
        >
            <span
                className="select-none text-center align-text-top aspect-square inline-flex items-center justify-center font-bold text-white rounded"
                style={{ backgroundColor, height: '1.2em' }}
            >
                <span className="uppercase" style={{ lineHeight: '80%', fontSize: '80%' }}>
                    {(name as string)?.slice(0, 1)}
                </span>
            </span>
            <span>{name}</span>
        </span>
    );
}

export const mentionBlockPlugin = SharedEditor.Plugins.createBlockPlugin({
    match: isMention,
    Component({ children, element }) {
        const selected = useSelected();

        const [popoverVisible, setPopoverVisible] = React.useState(false);

        let timeoutID: number | undefined;

        const showPopover = React.useCallback((event: React.MouseEvent) => {
            event.stopPropagation();
            setPopoverVisible(true);
        }, []);

        const hidePopover = React.useCallback(() => {
            setPopoverVisible(false);
        }, []);

        const onMouseOver = (event: React.MouseEvent) => {
            event.stopPropagation();
            timeoutID = window.setTimeout(() => showPopover(event), 500);
        };

        const onMouseLeave = () => {
            window.clearTimeout(timeoutID);
            hidePopover();
        };

        return (
            <span id={element.id} contentEditable={false} onMouseLeave={onMouseLeave}>
                <MentionPopover isOpen={popoverVisible} onOpenChange={setPopoverVisible} element={element}>
                    <span onMouseOver={onMouseOver}>
                        <Mention selected={selected} element={element} />
                    </span>
                </MentionPopover>

                <span className="hidden select-none">{children}</span>
            </span>
        );
    },
});
