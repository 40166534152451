import { SagaEditor, isLiveBlockSource } from '@saga/shared';
import React from 'react';

export const liveBlockSourcePlugin = SagaEditor.Plugins.createBlockPlugin({
    match: isLiveBlockSource,
    normalizers: [SagaEditor.Normalizers.liveBlockSourceNormalizer],
    Component({ children }) {
        return <>{children}</>;
    },
});
