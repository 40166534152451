import { v4 as uuid } from 'uuid';
import {
    BlockType,
    Title,
    LiveBlock,
    LiveBlockSource,
    BlockContainer,
    ListItem,
    Paragraph,
    IndentContainer,
    SagaText,
} from './block-types';
import {
    Blockquote,
    CheckListItem,
    DateBlock,
    GoogleDriveLink,
    Heading,
    Image,
    File,
    Link,
    Mention,
    PrettyLink,
    Embed,
    Divider,
    KatexBlock,
    KatexInline,
    Code,
    SagaElement,
    NumberedListItem,
    NumberedList,
    Callout,
    Table,
    TableRow,
    TableCell,
    InlineCollection,
    LinearIssue,
    InlinePageLink,
    Root,
    HelperBlockType,
    TaskBlock,
    AISuggestedText,
    AISourceItem,
} from './types';

function toChildren<C extends SagaElement | SagaText>(childrenOrText: string | C[]): (C | SagaText)[] {
    if (typeof childrenOrText === 'string') {
        return [text(childrenOrText)];
    }

    return childrenOrText;
}

export function title(childrenOrText: string | Title['children'] = '', id = uuid()): Title {
    return { id, type: BlockType.TITLE, children: toChildren(childrenOrText) };
}

export function aiSuggestedText(
    prompt: AISuggestedText['prompt'],
    messages: AISuggestedText['messages'] = [],
    creatorId?: string,
    editorId?: number,
    textContext?: string,
    references?: AISourceItem[],
    id = uuid(),
    children = [text('')],
): AISuggestedText {
    return {
        id,
        type: BlockType.AI_SUGGESTED_TEXT,
        children,
        prompt,
        messages,
        creatorId,
        editorId,
        textContext,
        references,
    };
}

export function heading(childrenOrText: string | Heading['children'], type: Heading['type'], id = uuid()): Heading {
    return { id, type, children: toChildren(childrenOrText) };
}

export function headingOne(childrenOrText: string | Heading['children'], id = uuid()): Heading {
    return heading(childrenOrText, BlockType.HEADING_1, id);
}

export function headingTwo(childrenOrText: string | Heading['children'], id = uuid()): Heading {
    return heading(childrenOrText, BlockType.HEADING_2, id);
}

export function headingThree(childrenOrText: string | Heading['children'], id = uuid()): Heading {
    return heading(childrenOrText, BlockType.HEADING_3, id);
}

export function mention(
    memberId: string,
    name: string,
    creatorId?: string,
    creatorName?: string,
    date = new Date().toISOString(),
    id = uuid(),
): Mention {
    return { id, type: BlockType.MENTION, memberId, creatorId, creatorName, date, children: [{ text: name }] };
}

export function dateBlock(date = new Date().toISOString(), id = uuid()): DateBlock {
    return { id, type: BlockType.DATE_BLOCK, date, children: [text('')] };
}

export function paragraph(childrenOrText: Paragraph['children'] | string = [text('')], id = uuid()): Paragraph {
    return { id, type: BlockType.PARAGRAPH, children: toChildren(childrenOrText) };
}

export function breakParagraph(id = uuid()): Paragraph {
    return { ...paragraph(undefined, id), break: true };
}

export function image(url: string, id = uuid()): Image {
    return { id, url, type: BlockType.IMAGE, children: [{ text: '' }] };
}

export function file(url: string, id = uuid()): File {
    return { id, url, type: BlockType.FILE, children: [text('')] };
}

export function listItem(childrenOrText: string | ListItem['children'], id = uuid()): ListItem {
    return { id, type: BlockType.BULLET_LIST_ITEM, children: toChildren(childrenOrText) };
}

export function numberedListItem(childrenOrText: NumberedListItem['children'] | string, id = uuid()): NumberedListItem {
    return { id, type: BlockType.NUMBER_LIST_ITEM, children: toChildren(childrenOrText) };
}

export function numberedList(children: NumberedList['children'], id = uuid()): NumberedList {
    return { id, type: BlockType.NUMBER_LIST, children };
}

export function table([columns, rows]: [number, number], id = uuid()): Table {
    return { id, type: BlockType.TABLE, children: tableRowsByColumns(columns, rows), dimensions: [columns, rows] };
}

export function tableByChildren(children: Table['children'], id = uuid()): Table {
    const maxColumnsLength = children.reduce((acc, val) => Math.max(acc, val.children.length), 0);
    const dimensions: [number, number] = [maxColumnsLength, children.length];
    return { id, type: BlockType.TABLE, children, dimensions };
}

export function tableByTextMatrix(textMatrix: string[][], id = uuid()): Table {
    const children = textMatrix.map((row) => tableRow(row.map((text) => tableCell([paragraph(text)]))));
    return tableByChildren(children, id);
}

export function tableRow(children: TableRow['children'], id = uuid()): TableRow {
    return { id, type: BlockType.TABLE_ROW, children };
}

export function tableRowsByColumns(columns: number, rows: number): TableRow[] {
    return Array.from(new Array(rows)).map(() => tableRowByColumns(columns));
}

export function tableRowByColumns(columns: number, id = uuid()): TableRow {
    return {
        id,
        type: BlockType.TABLE_ROW,
        children: Array.from(new Array(columns)).map(() => tableCell()),
    };
}

export function tableCell(children?: TableCell['children'], id = uuid()): TableCell {
    return { id, type: BlockType.TABLE_CELL, children: children ?? [paragraph()] };
}

export function tableCells(number: number): TableCell[] {
    return Array.from(new Array(number)).map(() => tableCell());
}

export function checkListItem(
    childrenOrText: CheckListItem['children'] | string,
    checked = false,
    id = uuid(),
): CheckListItem {
    return { id, type: BlockType.CHECK_LIST_ITEM, children: toChildren(childrenOrText), checked };
}

export function blockquote(childrenOrText: Blockquote['children'] | string, id = uuid()): Blockquote {
    return { id, type: BlockType.QUOTE, children: toChildren(childrenOrText) };
}

export function text(value = '', options?: Omit<SagaText, 'text'>): SagaText {
    return { text: value, ...options };
}

export function liveBlock(
    liveReferenceSourceId: string,
    staticBlocks?: LiveBlock['staticBlocks'],
    id = uuid(),
): LiveBlock {
    return {
        id,
        type: BlockType.LIVE_BLOCK,
        children: [{ text: '' }],
        reference: { liveReferenceSourceId },
        staticBlocks,
    };
}

export function blockContainer(children: BlockContainer['children'], id = uuid()): BlockContainer {
    return { id, type: BlockType.BLOCK_CONTAINER, children };
}

export function link(url: string, childrenOrText: Link['children'] | string = url, id = uuid()): Link {
    return { id, type: BlockType.LINK, children: toChildren(childrenOrText), url };
}

export function katexBlock(value: string, id = uuid()): KatexBlock {
    return { id, type: BlockType.KATEX_BLOCK, children: [text('')], value };
}

export function katexInline(value: string, id = uuid()): KatexInline {
    return { id, type: BlockType.KATEX_INLINE, children: [text('')], value };
}

export function googleDriveLink(
    children: GoogleDriveLink['children'],
    state: GoogleDriveLink['state'],
    id = uuid(),
): GoogleDriveLink {
    return { id, type: BlockType.GOOGLE_DRIVE_LINK, children, state };
}

export function linearIssue(children: LinearIssue['children'], state: LinearIssue['state'], id = uuid()): LinearIssue {
    return { id, type: BlockType.LINEAR_ISSUE, children, state };
}

export function taskBlock(taskId: string, staticTask?: TaskBlock['staticTask'], id = uuid()): TaskBlock {
    return { id, type: BlockType.TASK_BLOCK, children: [text('')], taskId, staticTask };
}

export function indent(children: IndentContainer['children'], id = uuid()): IndentContainer {
    return { id, type: BlockType.INDENT, children };
}

export function prettyLink(url: string, id = uuid()): PrettyLink {
    return { id, type: BlockType.PRETTY_LINK, url, metadata: { _tag: 'not-loaded' }, children: [{ text: '' }] };
}

export function code(content: string, language: Code['language'], id = uuid()): Code {
    return { id, type: BlockType.CODE, content, children: [{ text: '' }], language };
}

export function embed(url: string, id = uuid()): Embed {
    return { id, type: BlockType.EMBED, url, metadata: { _tag: 'not-loaded' }, children: [{ text: '' }] };
}

export function rawPrettyLink(url: string, metadata: PrettyLink['metadata'], id = uuid()): PrettyLink {
    return { id, type: BlockType.PRETTY_LINK, url, metadata, children: [{ text: '' }] };
}

export function divider(id = uuid()): Divider {
    return { id, type: BlockType.DIVIDER, children: [{ text: '' }] };
}

export function inlineCollection(
    collectionId: string,
    staticCollection?: InlineCollection['staticCollection'],
    id = uuid(),
): InlineCollection {
    return { id, type: BlockType.INLINE_COLLECTION, collectionId, children: [{ text: '' }], staticCollection };
}

export function inlinePageLink(
    pageId: string,
    liveReferenceSourceId: string,
    staticPage?: InlinePageLink['staticPage'],
    id = uuid(),
): InlinePageLink {
    return {
        id,
        type: BlockType.INLINE_PAGE_LINK,
        pageId,
        liveReferenceSourceId,
        staticPage,
        children: [{ text: '' }],
    };
}

export function callout(children: SagaElement[], id = uuid(), icon = 'bulb'): Callout {
    return {
        id,
        type: BlockType.CALLOUT,
        children,
        backgroundColor: 'bg-callout-default',
        icon: {
            type: 'emoji',
            colons: icon,
        },
    };
}

export function liveBlockSource(
    liveReferenceSourceId: string,
    children: LiveBlockSource['children'],
    id = uuid(),
): LiveBlockSource {
    return {
        id,
        type: BlockType.LIVE_BLOCK_SOURCE,
        children,
        liveReferenceSourceId,
    };
}

export function root(children: Root['children'], id = uuid()): Root {
    return {
        type: HelperBlockType.ROOT,
        children,
        id,
    };
}
