import * as Y from 'yjs';
import { unsafeRight } from '../io-ts-js';
import { assertYArray, assertYMap, findYArrayIndexOrThrow } from '../yjs-utils';
import { SafeSpace } from '../types';
import * as E from 'fp-ts/Either';
import { pipe } from 'fp-ts/lib/function';
import { logError } from '../utils';
import transactInSpace from './transactInSpace';
import removePinnedById from './removePinnedById';
import removeFavorite from './removeFavorite';

export default function deleteCollectionById(space: SafeSpace, id: string) {
    const safeArray = unsafeRight(space.get('collections'));

    const index = findYArrayIndexOrThrow(
        safeArray.array as Y.Array<Y.Map<unknown>>,
        (map: Y.Map<unknown>) => map.get('id') === id,
    );

    transactInSpace(space, () => {
        removePinnedById(space, id);
        removeFavorite(space, id);

        // delete the collection id from all pages and tasks referencing it
        const yPages = space.map.get('pages');
        const yTasks = space.map.get('tasks');

        assertYArray(yPages);
        assertYArray(yTasks);

        yPages.forEach((yPage) => {
            assertYMap(yPage);
            const yCollections = yPage.get('collections');

            if (!yCollections) return;

            assertYArray(yCollections);
            const collections = yCollections.toArray();
            if (collections.includes(id)) {
                const index = collections.findIndex((c) => c === id);
                if (index !== -1) {
                    yCollections.delete(index);
                }
            }
        });

        yTasks.forEach((yTask) => {
            assertYMap(yTask);
            const yCollections = yTask.get('collections');

            if (!yCollections) return;

            assertYArray(yCollections);
            const collections = yCollections.toArray();
            if (collections.includes(id)) {
                const index = collections.findIndex((c) => c === id);
                if (index !== -1) {
                    yCollections.delete(index);
                }
            }
        });

        pipe(
            E.tryCatch(() => safeArray.array.delete(index), E.toError),
            E.map(() => index),
            E.fold(logError, (indexDeleted) => {
                console.log(`deleteCollection deleted with index: ${indexDeleted}`);
            }),
        );
    });
}
