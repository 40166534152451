import React from 'react';
import EditableTableTitle, { EditableTableTitleProps } from './EditableTableTitle';

const CollectionTableTitle = (props: Omit<EditableTableTitleProps, 'source' | 'texts' | 'testId'>) => {
    return (
        <EditableTableTitle
            {...props}
            testId="collection-title"
            source="collection"
            texts={{
                title: 'Collection Title',
                placeholder: 'Collection Title...',
                edit: 'Edit collection title',
                removeIcon: 'Remove collection icon',
                changeIcon: 'Change Collection Icon',
            }}
        />
    );
};

export default CollectionTableTitle;
