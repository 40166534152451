import { BlockBuilder } from '../..';
import { newBlankPage } from '../../pageUtils';
import { WeakTask } from '../../types';

export function newSagaBasicsPageFr(task: WeakTask, collectionIds: string[]) {
    return newBlankPage({
        title: 'Les bases de Saga',
        icon: { type: 'emoji', colons: ':wave:' },
        collections: collectionIds,
        blocks: [
            BlockBuilder.title('Les bases de Saga'),
            BlockBuilder.callout([
                BlockBuilder.paragraph([BlockBuilder.text('Tapez n’importe où sur cette page pour la modifier !')]),
            ]),
            BlockBuilder.paragraph(),
            BlockBuilder.image('https://saga-public.s3.amazonaws.com/1-block-selection-menu.webp'),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Appuyez sur '),
                BlockBuilder.text('@', { code: true }),
                BlockBuilder.text(' ou '),
                BlockBuilder.text('/', { code: true }),
                BlockBuilder.text(
                    ' pour ouvrir le menu d’autocomplétion et ajouter de nouveaux contenus tels que des titres, des tâches à faire, des images, des fichiers, des tableaux, et plus encore.',
                ),
            ]),
            BlockBuilder.paragraph(),
            BlockBuilder.paragraph(),
            BlockBuilder.paragraph(),
            BlockBuilder.image('https://saga-public.s3.amazonaws.com/2-text-styling-menu.webp'),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Sélectionnez du texte', { bold: true }),
                BlockBuilder.text(' pour '),
                BlockBuilder.text('le mettre en forme,', { italic: true }),
                BlockBuilder.text(' le transformer en un autre type de contenu, '),
                BlockBuilder.text('le surligner', { highlight: true }),
                // todo highlight in red
                BlockBuilder.text(', ou l’améliorer avec l’IA.'),
            ]),
            BlockBuilder.paragraph(),
            BlockBuilder.paragraph(),
            BlockBuilder.image('https://saga-public.s3.amazonaws.com/3-drag-and-drop-images-and-files.webp'),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Ajoutez des images et des fichiers', { bold: true }),
                BlockBuilder.text(' en les glissant et déposant ou en les collant.'),
            ]),
            BlockBuilder.paragraph(),
            BlockBuilder.paragraph(),
            BlockBuilder.image('https://saga-public.s3.amazonaws.com/4-drag-and-drop-blocks-of-content.webp'),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Glissez et déposez', { bold: true }),
                BlockBuilder.text(' n’importe quel bloc en sélectionnant et en glissant l’icône ☰ sur le côté gauche du bloc.'),
            ]),
            BlockBuilder.paragraph(),
            BlockBuilder.paragraph(),
            BlockBuilder.image('https://saga-public.s3.amazonaws.com/5-unified-search-for-fast-navigation.webp'),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Appuyez sur '),
                BlockBuilder.text('⌘ + K', { code: true }),
                BlockBuilder.text(' (macOS) ou '),
                BlockBuilder.text('Ctrl + K', { code: true }),
                BlockBuilder.text(' (Windows) pour '),
                BlockBuilder.text('trouver n’importe quoi', { bold: true }),
                BlockBuilder.text(' et naviguer à travers votre espace de travail.'),
            ]),
            BlockBuilder.paragraph(),
            BlockBuilder.paragraph(),
            BlockBuilder.image('https://saga-public.s3.amazonaws.com/6-creating-and-managing-tasks.webp'),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Créez des tâches', { bold: true }),
                BlockBuilder.text(' en ouvrant le menu d’autocomplétion et en sélectionnant '),
                BlockBuilder.text('Tâche', { code: true }),
                BlockBuilder.text(
                    '. Les tâches sont un type spécial de pages avec un assigné, une date d’échéance, et une priorité. Ouvrez la tâche ci-dessous pour en savoir plus 👇.',
                ),
            ]),
            BlockBuilder.taskBlock(task.id),
            BlockBuilder.paragraph(),
            BlockBuilder.paragraph(),
            BlockBuilder.image('https://saga-public.s3.amazonaws.com/7-adding-to-pinned.webp'),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Mettez en favori ', { bold: true }),
                BlockBuilder.text('n’importe quelle page pour l’ajouter à la barre latérale en cliquant sur l’icône ☆ dans le coin supérieur droit.'),
            ]),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Rendez-vous sur la page 😍 Saga avancé pour explorer des manières plus avancées d’utiliser Saga.'),
            ]),
            BlockBuilder.paragraph(),
            BlockBuilder.paragraph(),
            BlockBuilder.divider(),
            BlockBuilder.headingThree('Vous avez une question ?'),
            BlockBuilder.paragraph([
                BlockBuilder.text('Appuyez sur le '),
                BlockBuilder.text('?', { bold: true }),
                BlockBuilder.text(
                    ' bouton dans le coin inférieur droit pour nous envoyer un email, envoyer des demandes de fonctionnalités, ou rejoindre nos communautés d’utilisateurs sur ',
                ),
                BlockBuilder.link('https://discord.com/invite/cgz2mUEq7P', 'Discord'),
                BlockBuilder.text(', '),
                BlockBuilder.link(
                    'https://sagacommunity.slack.com/join/shared_invite/zt-13m3lrrdt-1x6~l6sLuR8CX~4c3fWwHA#/shared-invite/email',
                    'Slack',
                ),
                BlockBuilder.text(', ou '),
                BlockBuilder.link('https://twitter.com/saga_HQ', 'X'),
                BlockBuilder.text(
                    '. Visitez également les guides d’aide sur notre site web et voyez comment tirer le meilleur parti de l’application.',
                ),
            ]),
            BlockBuilder.rawPrettyLink('https://saga.so/guides', {
                _tag: 'loaded',
                description: "Ce guide vous aidera à comprendre les blocs de construction de Saga et à vous mettre à niveau.",
                title: '📍 Guides de Saga',
                logo: 'https://saga.so/thumbnail.png',
            }),
            BlockBuilder.paragraph(),
        ],
    });
}
