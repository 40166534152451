import React, { useRef } from 'react';
import { X } from 'react-feather';
import Tooltip, { InfoTooltip } from '@/components/popover/Tooltip';
import { SagaLocation, Search } from '@saga/shared';
import ReferenceResultsList from './ReferenceResultsList';
import Button from '../styled/Button';
import Panel from './Panel';
import classNames from 'classnames';
import { FindInLocationsResult } from '@/../../shared/src/SpaceOperations/findInLocations';
import { Trans, useTranslation } from 'react-i18next';
import Input from '../styled/Input';
import { EnterIndicator, Suggestion, SuggestionContent, useSearchSuggestions } from '../search/SearchModal';
import { useOpenLocation } from '../PageNavigationProvider';
import ItemGroups from '../ItemGroups';
import { PopOver } from '../popover/PopOver';
import Spinner from '../loading/Spinner';
import { Search as SearchIcon } from 'react-feather';
import { useArrowSelectableIndex } from '../popover/Dropdown';
import { useSharedPages } from '../SharedPagesProvider';
import ReferenceResultCard from './ReferenceResultCard';
import { useHistory } from 'react-router-dom';
import { VariableSizeList } from 'react-window';
const { MatchMode } = Search;

function formatResult(resultNumber: number) {
    return `${resultNumber} ${resultNumber === 0 || resultNumber > 1 ? 'results' : 'result'}`;
}

export function SearchPanel({
    referenceResults,
    isSearching,
    onResetSearch,
    onChangeSearch,
    searchQuery,
    currentLocation,
    isFocused,
}: {
    referenceResults: FindInLocationsResult[] | null;
    isSearching: boolean;
    onResetSearch: () => void;
    onChangeSearch: (text: string) => void;
    searchQuery: string | null;
    isFocused: boolean;
    currentLocation: SagaLocation.SagaLocation;
}) {
    const { t } = useTranslation();
    const { suggestions } = useSearchSuggestions(searchQuery || '', currentLocation);
    const listRef = useRef<VariableSizeList>(null);
    const containerRef = useRef<HTMLDivElement>(null);

    const sharedPages = useSharedPages();
    const history = useHistory();

    const openLocation = useOpenLocation();

    const onSelect = (event: Event | React.SyntheticEvent, suggestion: Suggestion) => {
        if (suggestion.type === 'recent') {
            openLocation(suggestion.location, event);
        }
    };

    const { index: currentIndexSelection, setIndex } = useArrowSelectableIndex({
        onSelect: (index, event) => {
            const item = suggestions[index];
            if (item) {
                onSelect(event, item);
            }
        },
        onUpdate(index) {
            listRef.current?.scrollToItem(index);
        },
        maxIndex: suggestions.length - 1,
        active: searchQuery === '' && !isSearching && isFocused,
        selectWithTab: true,
        initialIndex: 0,
    });

    const sharedPagesResults = searchQuery
        ? sharedPages.filter((page) => page.title.toLowerCase().includes(searchQuery.toLowerCase()))
        : [];

    return (
        <div data-testid="search-panel" className="overflow-hidden flex-grow" ref={containerRef}>
            <div className="flex justify-between items-center w-full pl-2 pt-2 pr-1 space-x-1">
                <div className="flex justify-between items-center w-full pl-3 pr-1 border rounded border-saga-gray-200 dark:border-saga-gray-700 focus-within:border-saga-gray-500 dark:focus-within:border-saga-gray-500">
                    <SearchIcon size={16} className="stroke-saga-gray-500" />
                    <Input.SearchPanel
                        data-testid="search-query"
                        type="text"
                        placeholder={t('top_menu.search_dotted')}
                        value={searchQuery || ''}
                        onChange={(e) => onChangeSearch(e.target.value || '')}
                    />
                </div>
                <Tooltip content={t('top_menu.close_search_results')} placement="bottom">
                    <Button.XButton onClick={onResetSearch} label={t('top_menu.close_search_results') as string} />
                </Tooltip>
            </div>
            <Panel styles={true}>
                <Panel.Header>
                    <div className="flex flex-col items-start space-y-2 p-2">
                        <div className="flex justify-between items-start w-full h-full space-x-2">
                            <p className="flex-none text-saga-gray-500 text-sm block px-2">
                                {referenceResults &&
                                    referenceResults.length > 0 &&
                                    sharedPagesResults.length > 0 &&
                                    formatResult(referenceResults.length + sharedPagesResults.length)}
                            </p>
                        </div>
                    </div>
                </Panel.Header>
                <Panel.Body>
                    <div className="px-2">
                        {isSearching && (
                            <div className="flex flex-row items-center">
                                <Spinner size={14} />
                                <div className="text-sm pl-2">{t('top_menu.searching')}</div>
                            </div>
                        )}

                        {sharedPagesResults.map((page) => (
                            <ReferenceResultCard title={page.title} key={page.id}>
                                <ReferenceResultCard.Header>
                                    <div className="flex flex-row items-start justify-between">
                                        <ReferenceResultCard.PageButton
                                            icon={page.icon}
                                            onClick={() => {
                                                history.push({
                                                    pathname: `/${page.id}`,
                                                    state: { from: location.pathname },
                                                });
                                            }}
                                            isTemplate={false}
                                            isArchived={false}
                                        >
                                            {page.title}
                                        </ReferenceResultCard.PageButton>
                                    </div>
                                </ReferenceResultCard.Header>
                            </ReferenceResultCard>
                        ))}

                        {referenceResults && referenceResults.length > 0 && !isSearching && (
                            <ReferenceResultsList
                                referenceResults={referenceResults}
                                matchMode={MatchMode.ALL}
                                currentLocation={currentLocation}
                                isSearchResults={true}
                            />
                        )}
                        <div>
                            {referenceResults &&
                                referenceResults.length === 0 &&
                                sharedPagesResults.length === 0 &&
                                searchQuery &&
                                !isSearching && (
                                    <div>
                                        <ReferenceResultsList.EmptyStateSearchPanel />
                                    </div>
                                )}
                        </div>

                        <div>
                            {searchQuery === '' && !isSearching && (
                                <ItemGroups
                                    maxHeight={containerRef.current?.clientHeight ?? 0}
                                    ref={listRef}
                                    items={suggestions}
                                    labels={{
                                        navigation: null,
                                        'member-location': null,
                                        recent: <PopOver.SearchPanelLabel>Recents</PopOver.SearchPanelLabel>,
                                        page: null,
                                        'shared-page': null,
                                        collection: null,
                                        drive: null,
                                        'linear-issue': null,
                                        search: null,
                                        task: null,
                                    }}
                                    renderItem={(item, index) => (
                                        <Button.PopOverSearchPanelButton
                                            key={index}
                                            type="button"
                                            onMouseEnter={() => setIndex(index)}
                                            onMouseLeave={() => setIndex(undefined)}
                                            onMouseDown={(event) => onSelect(event, suggestions[index])}
                                            hoverable={false}
                                            selected={index === currentIndexSelection}
                                            height={9}
                                        >
                                            <div
                                                className="w-full flex justify-between"
                                                data-testid={`row-index-${index}`}
                                            >
                                                <div className="flex truncate items-center font-medium">
                                                    <SuggestionContent
                                                        suggestion={item}
                                                        searchValue={searchQuery}
                                                        key={index}
                                                    />
                                                </div>
                                                <div className="flex flex-row space-x-2">
                                                    <EnterIndicator visible={false} />
                                                </div>
                                            </div>
                                        </Button.PopOverSearchPanelButton>
                                    )}
                                />
                            )}
                        </div>
                    </div>
                </Panel.Body>
            </Panel>
        </div>
    );
}

interface ReferencePanelProps {
    floating: boolean;
    onClose: () => void;
    referenceResults: FindInLocationsResult[];
    location: SagaLocation.BlocksLocation;
}
function ReferencePanel(props: ReferencePanelProps) {
    const { floating, referenceResults, location } = props;

    return (
        <Panel testId="reference-panel">
            <ReferencePanel.Header {...props} />
            <Panel.Body>
                {referenceResults.length > 0 && (
                    <ReferenceResultsList
                        referenceResults={referenceResults}
                        matchMode={MatchMode.TERMS}
                        currentLocation={location}
                    />
                )}
                <div className={classNames({ 'px-2': !floating })}>
                    {referenceResults.length === 0 && <ReferenceResultsList.EmptyState />}
                </div>
            </Panel.Body>
        </Panel>
    );
}

ReferencePanel.Header = function ReferencePanelHeader({
    floating,
    onClose,
    referenceResults,
}: {
    floating: boolean;
    onClose: () => void;
    referenceResults: FindInLocationsResult[];
}) {
    const { t } = useTranslation();
    return (
        <Panel.Header padding={!floating}>
            <div className="flex flex-col items-start space-y-2">
                <div className="flex justify-between items-start w-full h-full space-x-2">
                    <div className="flex items-center space-x-2 h-full">
                        {floating && (
                            <Button.Plain onClick={onClose}>
                                <X size={20} />
                            </Button.Plain>
                        )}
                        <Panel.Title>
                            <span>{t('references.label')}</span>
                        </Panel.Title>
                        <div className="my-auto mx-1">
                            <InfoTooltip content={t('references.tooltip')} placement="bottom" />
                        </div>
                    </div>
                    <p className="flex-none text-saga-gray-500 text-base block">
                        <Trans
                            i18nKey="references.result"
                            count={referenceResults.length}
                            values={{ count: referenceResults.length }}
                        />
                    </p>
                </div>
            </div>
        </Panel.Header>
    );
};

export default ReferencePanel;
