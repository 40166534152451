import React, { useEffect, useState } from 'react';
import StaticEditor from '../StaticEditor';
import { Converter, SagaElement, SagaLocation } from '@/../../shared/src';
import { useBlockPlugins } from '@/components/BlockPluginProvider';

interface AIStaticEditorContent {
    content?: string | null;
}

const AIStaticEditorContent = ({ content }: AIStaticEditorContent) => {
    const [blocks, setBlocks] = useState<SagaElement[]>([]);
    const blockPlugins = useBlockPlugins();

    useEffect(() => {
        if (content) {
            const elements = Converter.xmlToSaga(content ?? '');
            if (elements) {
                setBlocks(elements as SagaElement[]);
            }
        }
    }, [content]);
    return (
        <StaticEditor
            blocks={blocks}
            location={SagaLocation.pageLocationFromId('SagaAIPopover')}
            blockPlugins={blockPlugins}
        />
    );
};

export default AIStaticEditorContent;
