import { track } from '@/analytics';
import ImportFileDropzone from '@/components/ImportFileDropzone';
import { AISettings } from '@/components/settings/ai/AISettings';
import useExportWorkspace from '@/hooks/useExportWorkspace';
import useInterfaceSettings from '@/hooks/useInterfaceSettings';
import { useSpaceAccess } from '@/hooks/useSpaceAccess';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { AccountSettings } from './AccountSettings';
import { AppearanceSettings } from './AppearanceSettings';
import { BillingSettings } from './BillingSettings';
import LabelSettings from './LabelSettings';
import { IntegrationsSettings } from './IntegrationsSettings';
import { InviteSettings } from './InviteSettings';
import { LanguageSettings } from './LanguageSettings';
import MemberSettings from './MemberSettings';
import { PlansSettings } from './PlansSettings';
import SettingsPane from './SettingsPane';
import { SettingsType, useCloseSettings } from './SettingsProvider';
import { SettingsToggle } from './SettingsToggle';
import { WorkspaceSettings } from './WorkspaceSettings';

function EditorSettings() {
    const [{ spellCheck }, setInterfaceSettings] = useInterfaceSettings();
    const { t } = useTranslation();

    return (
        <SettingsPane>
            <div>
                <SettingsPane.Title>{t('settings.editor.title')}</SettingsPane.Title>
                <SettingsPane.Small>{t('settings.editor.sub_title')}</SettingsPane.Small>
            </div>
            <SettingsPane.Content>
                <div>
                    <div className="pb-10">
                        <SettingsPane.Section>
                            <SettingsToggle
                                icon={<i className="text-2xl block leading-none ri-check-double-line"></i>}
                                title={t('settings.editor.spellcheck')}
                                checked={Boolean(spellCheck)}
                                onChange={(spellCheck) => {
                                    track(spellCheck ? 'enable-spell-checking' : 'disable-spell-checking');
                                    setInterfaceSettings({ spellCheck });
                                }}
                            />
                        </SettingsPane.Section>
                    </div>
                </div>
            </SettingsPane.Content>
        </SettingsPane>
    );
}

function ImportAndExport() {
    const openImportModal = ImportFileDropzone.useOpenModal();
    const closeSettings = useCloseSettings();
    const { isOwner } = useSpaceAccess();
    const exportWorkspace = useExportWorkspace();
    const { t } = useTranslation();

    return (
        <SettingsPane>
            <div>
                <SettingsPane.Title>{t('settings.export_import.title')}</SettingsPane.Title>
                <SettingsPane.Small>{t('settings.export_import.sub_title')}</SettingsPane.Small>
            </div>
            <SettingsPane.Content>
                {isOwner && (
                    <SettingsPane.SectionFlex>
                        <div>
                            <h2>{t('settings.export_import.import_workspace')}</h2>
                            <SettingsPane.Small>
                                {t('settings.export_import.import_workspace_description')}
                            </SettingsPane.Small>
                        </div>

                        <SettingsPane.Button
                            onClick={() => {
                                closeSettings();
                                openImportModal();
                            }}
                            className="text-sm mt-2"
                        >
                            {t('settings.export_import.import_workspace_cta')}
                        </SettingsPane.Button>
                    </SettingsPane.SectionFlex>
                )}

                <SettingsPane.SectionFlex>
                    <div>
                        <h2>{t('settings.export_import.export_workspace')}</h2>
                        <SettingsPane.Small>
                            {t('settings.export_import.export_workspace_description')}
                        </SettingsPane.Small>
                    </div>
                    <SettingsPane.Button onClick={() => exportWorkspace()} className="text-sm mt-2">
                        {t('settings.export_import.export_workspace_cta')}
                    </SettingsPane.Button>
                </SettingsPane.SectionFlex>
            </SettingsPane.Content>
        </SettingsPane>
    );
}

export default function Settings({ type }: { type: SettingsType }) {
    switch (type) {
        case 'account':
            return <AccountSettings />;
        case 'appearance':
            return <AppearanceSettings />;
        case 'editor':
            return <EditorSettings />;
        case 'space':
            return <WorkspaceSettings />;
        case 'members':
            return <MemberSettings />;
        case 'labels':
            return <LabelSettings />;
        case 'import-export':
            return <ImportAndExport />;
        case 'invites':
            return <InviteSettings />;
        case 'integrations':
            return <IntegrationsSettings />;
        case 'plans':
            return <PlansSettings />;
        case 'billing':
            return <BillingSettings />;
        case 'sagaai':
            return <AISettings />;
        case 'language':
            return <LanguageSettings />;
    }
}
