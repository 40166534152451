import * as Y from 'yjs';
import { transformPageToSharedType } from '../pageUtils';
import { Page, WeakPage } from '../types';
import { SafeSpace } from '../types';
import { unsafeRight } from '../io-ts-js';
import transactInSpace from './transactInSpace';

export default function createPage(space: SafeSpace, page: Page): Page {
    const yPages = unsafeRight(space.get('pages'));
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { blocks: _, ...pageWithoutBlocks } = page;
    transactInSpace(space, () => {
        yPages.array.push([transformPageToSharedType(pageWithoutBlocks) as Y.Map<WeakPage>]);
    });

    return page;
}
