import { useHistory } from 'react-router-dom';
import { track } from '@/analytics';
import { useWorkspaceContext } from '@/components/WorkspaceContext';
import * as api from '@saga/api';
import useWorkspaceSettings from './useWorkspaceSettings';

export default function useDeleteWorkpace() {
    const [, , clearSettings] = useWorkspaceSettings();
    const { currentUrlKey } = useWorkspaceContext();
    const history = useHistory();

    const [deleteWorkspace] = api.useDeleteSpaceMutation({
        refetchQueries: [{ query: api.SpacesDocument }],
        awaitRefetchQueries: true,
        variables: {
            input: {
                urlKey: currentUrlKey,
            },
        },
        onCompleted: (data) => {
            if (!data?.deleteSpace) return;

            clearSettings();
            track('delete-workspace');
            history.push('/s');
        },
    });

    return deleteWorkspace;
}
