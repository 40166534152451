import { v4 as uuid } from 'uuid';
import { BlockBuilder, WeakBlocks } from '../../';
import { WeakTask } from '../../types';
import * as api from '@saga/api';

const task = {
    [api.Language.En]: {
        title: 'Learn more about tasks',
        listBlocks_1: [
            'Tasks are a special kind of pages.',
            'Use tasks instead of simple to-dos when your task requires more details that includes text, images, links, and more.',
        ],
        listBlocks_2: [
            'You can assign a task and add a due date.',
            'You can access all tasks aggregated across your workspace in the Tasks table in the sidebar to the left.',
        ],
        paragraph: ['Visit the Saga guides on task management and see how to make the most out of the app.'],
    },
    [api.Language.De]: {
        title: 'Erfahren Sie mehr über Aufgaben',
        listBlocks_1: [
            'Aufgaben sind eine besondere Art von Seiten.',
            'Verwenden Sie Aufgaben anstelle von einfachen To-dos, wenn Ihre Aufgabe mehr Details erfordert, die Text, Bilder, Links und mehr umfassen.',
        ],
        listBlocks_2: [
            'Sie können eine Aufgabe zuweisen und ein Fälligkeitsdatum hinzufügen.',
            'Sie können auf alle Aufgaben, die in Ihrem Arbeitsbereich aggregiert sind, in der Aufgabentabelle in der Seitenleiste links zugreifen.',
        ],
        paragraph: [
            'Besuchen Sie die Saga-Anleitungen zum Aufgabenmanagement und sehen Sie, wie Sie die App optimal nutzen können.',
        ],
    },
    [api.Language.Fr]: {
        title: 'En savoir plus sur les tâches',
        listBlocks_1: [
            'Les tâches sont un type spécial de pages.',
            'Utilisez les tâches au lieu de simples à-faire lorsque votre tâche nécessite plus de détails incluant du texte, des images, des liens, et plus encore.',
        ],
        listBlocks_2: [
            'Vous pouvez assigner une tâche et ajouter une date d’échéance.',
            'Vous pouvez accéder à toutes les tâches agrégées à travers votre espace de travail dans le tableau des Tâches dans la barre latérale à gauche.',
        ],
        paragraph: [
            'Visitez les guides Saga sur la gestion des tâches et voyez comment tirer le meilleur parti de l’application.',
        ],
    },
    [api.Language.It]: {
        title: 'Scopri di più sulle attività',
        listBlocks_1: [
            'Le attività sono un tipo speciale di pagine.',
            'Usa le attività invece dei semplici to-do quando la tua attività richiede più dettagli che includono testo, immagini, link e altro ancora.',
        ],
        listBlocks_2: [
            "Puoi assegnare un'attività e aggiungere una data di scadenza.",
            'Puoi accedere a tutte le attività aggregate nel tuo spazio di lavoro nella tabella Attività nella barra laterale a sinistra.',
        ],
        paragraph: [
            "Visita le guide di Saga sulla gestione delle attività e scopri come ottenere il massimo dall'app.",
        ],
    },
    [api.Language.Es]: {
        title: 'Aprende más sobre las tareas',
        listBlocks_1: [
            'Las tareas son un tipo especial de páginas.',
            'Usa tareas en lugar de simples quehaceres cuando tu tarea requiera más detalles que incluyan texto, imágenes, enlaces y más.',
        ],
        listBlocks_2: [
            'Puedes asignar una tarea y añadir una fecha de vencimiento.',
            'Puedes acceder a todas las tareas agregadas en tu espacio de trabajo en la tabla de Tareas en la barra lateral izquierda.',
        ],
        paragraph: [
            'Visita las guías de Saga sobre la gestión de tareas y descubre cómo sacar el máximo provecho de la aplicación.',
        ],
    },
    [api.Language.Pt]: {
        title: 'Saiba mais sobre tarefas',
        listBlocks_1: [
            'Tarefas são um tipo especial de páginas.',
            'Use tarefas em vez de simples afazeres quando sua tarefa requer mais detalhes que incluem texto, imagens, links e mais.',
        ],
        listBlocks_2: [
            'Você pode atribuir uma tarefa e adicionar uma data de vencimento.',
            'Você pode acessar todas as tarefas agregadas em seu espaço de trabalho na tabela de Tarefas na barra lateral à esquerda.',
        ],
        paragraph: [
            'Visite os guias da Saga sobre gerenciamento de tarefas e veja como aproveitar ao máximo o aplicativo.',
        ],
    },
};

export function newTask({ language }: { language: api.Language }): WeakTask & { blocks: WeakBlocks } {
    return {
        id: uuid(),
        state: 'OPEN',
        dueDate: new Date().toString(),
        assignee: null,
        priority: 'MEDIUM',
        title: task[language].title,
        blocks: [
            BlockBuilder.title(task[language].title),
            ...task[language].listBlocks_1.map((item) => BlockBuilder.listItem(item)),
            BlockBuilder.paragraph(),
            BlockBuilder.paragraph(),
            BlockBuilder.image('https://saga-public.s3.amazonaws.com/11-tasks-drag-and-drop-images-and-files.webp'),
            ...task[language].listBlocks_2.map((item) => BlockBuilder.listItem(item)),
            BlockBuilder.paragraph(),
            BlockBuilder.paragraph(),
            BlockBuilder.image('https://saga-public.s3.amazonaws.com/12-tasks-aggregation-and-single-task.webp'),
            BlockBuilder.paragraph(),
            BlockBuilder.paragraph(),
            ...task[language].paragraph.map((item) => BlockBuilder.paragraph([BlockBuilder.text(item)])),
            BlockBuilder.rawPrettyLink('https://saga.so/guides/tasks', {
                _tag: 'loaded',
                description:
                    'It’s easy to integrate tasks within your notes and docs in Saga. You can create tasks across different pages in Saga, assign them to your colleagues, and manage all of them from one place.',
                title: '📍 Tasks | Saga Guides',
                logo: 'https://saga.so/thumbnail.png',
            }),
        ],
        archivedAt: undefined,
        createdAt: new Date().toString(),
        updatedAt: new Date().toString(),
        collections: [],
        createdBy: undefined,
    };
}
