import { SlateYjs } from '@saga/shared';
import classNames from 'classnames';
import React from 'react';

interface Caret {
    isForward: boolean;
    data: SlateYjs.AwarenessStateData;
}

const Caret: React.FC<Caret> = ({ data, isForward }) => {
    const [visible, setVisible] = React.useState(true);
    React.useEffect(() => {
        setVisible(true);

        const timeout = setTimeout(() => {
            setVisible(false);
        }, 2000);

        return () => clearTimeout(timeout);
    }, [data.cursors]);

    if (data.user.name == null) {
        return null;
    }

    return (
        <>
            <span
                contentEditable={false}
                style={{
                    background: data.user.color,
                    height: '1.3em',
                    left: isForward ? '100%' : '0%',
                    ...(isForward ? { bottom: 0 } : { top: 0 }),
                }}
                className="absolute pointer-events-none select-none w-0.5 z-10"
            >
                <span
                    style={{ position: 'relative' }}
                    className={classNames('transition-opacity duration-200', {
                        'opacity-0 hover:opacity-100': !visible,
                        'opacity-100': visible,
                    })}
                >
                    <span
                        contentEditable={false}
                        style={{
                            transform: 'translateY(-100%)',
                            background: data.user.color,
                            left: isForward ? '100%' : '0%',
                        }}
                        className={classNames(
                            'px-1 shadow-sm py-px rounded font-normal text-xs whitespace-nowrap top-0 absolute select-none pointer-events-none text-white',
                        )}
                    >
                        {data.user.name}
                    </span>
                </span>
            </span>
        </>
    );
};

export default Caret;
