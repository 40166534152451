import { useAIChatOpen } from '@/components/aichat/AIChatContext';
import { useSearchResults } from '@/components/editor/ReferenceResults';
import { SearchPanel } from '@/components/rightpanel/ReferencePanel';
import { useSearchContext } from '@/components/search/SearchContext';
import useInterfaceSettings from '@/hooks/useInterfaceSettings';
import { SagaLocation } from '@saga/shared';
import { useDrag } from '@use-gesture/react';
import { debounce } from 'lodash';
import React from 'react';
import { AIChatPane } from './aichat/AIChatPane';

type Props = {
    location: SagaLocation.SagaLocation;
};

export const SidePanel = ({ location }: Props) => {
    const { searchQuery, resetSearch, search } = useSearchContext();
    const { chatOpen, setChatOpen } = useAIChatOpen();
    const { results, isSearching } = useSearchResults();
    const [query, setQuery] = React.useState(searchQuery);

    const [{ sidePanelWidth }, setInterfaceSettings] = useInterfaceSettings();
    const openWidth = sidePanelWidth ?? 400;
    const [isFocused, setIsFocused] = React.useState(false);
    const componentRef = React.useRef<HTMLDivElement>(null);

    const checkFocusWithin = () => {
        const isFocusWithin = componentRef.current && componentRef.current.contains(document.activeElement);
        setIsFocused(isFocusWithin || false);
    };

    React.useEffect(() => {
        if (chatOpen) return;

        window.addEventListener('focus', checkFocusWithin, true);
        window.addEventListener('blur', checkFocusWithin, true);

        return () => {
            window.removeEventListener('focus', checkFocusWithin, true);
            window.removeEventListener('blur', checkFocusWithin, true);
        };
    }, [chatOpen]);

    const bindHandle = useDrag(
        (state) => {
            state.event.preventDefault();
            const nextPanelWidth = Math.max(Math.min(window.innerWidth / 2, openWidth + -state.delta[0]), 300);
            setInterfaceSettings({ sidePanelWidth: nextPanelWidth });
        },
        { filterTaps: true, rubberband: false },
    );

    const searchCallback = React.useCallback(
        (value) => {
            search(value);
        },
        [search],
    );

    const debounceSearch = React.useMemo(
        () =>
            debounce((value) => {
                searchCallback(value);
            }, 500),
        [searchCallback],
    );

    React.useEffect(() => {
        if (searchQuery !== null) setChatOpen(false);
        setQuery(searchQuery);
    }, [searchQuery, setChatOpen]);

    React.useEffect(() => {
        if (chatOpen) resetSearch();
    }, [chatOpen, resetSearch]);

    if (searchQuery === null && !chatOpen) return null;

    return (
        <div
            ref={componentRef}
            tabIndex={-1}
            style={{ minWidth: openWidth, maxWidth: openWidth }}
            className="flex z-100 w-full relative focus:outline-none"
        >
            <div {...bindHandle()} className="absolute -left-1 bottom-0 top-0 w-3 cursor-ew-resize z-100 group">
                <div className="absolute top-0 bottom-0 w-0.5 left-1 group-hover:bg-saga-blue-light transition-colors"></div>
            </div>
            <div
                style={{ minWidth: openWidth }}
                className="px-1 bg-white dark:bg-saga-gray-1000 md:border-l border-saga-gray-200 dark:border-zinc-700 inset-0 md:flex flex-col md:w-1/3"
            >
                {chatOpen ? (
                    <AIChatPane onClose={() => setChatOpen(false)} />
                ) : (
                    <SearchPanel
                        searchQuery={query}
                        isSearching={isSearching}
                        onChangeSearch={(value) => {
                            setQuery(value);
                            debounceSearch(value);
                        }}
                        onResetSearch={resetSearch}
                        referenceResults={results}
                        isFocused={isFocused}
                        currentLocation={location}
                    />
                )}
            </div>
        </div>
    );
};
