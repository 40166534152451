import { hashCode } from '.';
import { NonEmptyArray } from '..';

export type Color = { r: number; b: number; g: number };
export type HexColor = `#${string}`;

// Based on this https://stackoverflow.com/questions/5623838/rgb-to-hex-and-hex-to-rgb
export function calcLuma(color: Color) {
    return (0.299 * color.r + 0.587 * color.g + 0.114 * color.g) / 255;
}

export function hexToRgb(hex: string): Color {
    return {
        r: parseInt(hex.substr(-6, 2), 16),
        g: parseInt(hex.substr(-4, 2), 16),
        b: parseInt(hex.substr(-2), 16),
    };
}

/**
 * Takes a string value and converts it to a color based on a given array of colors
 * @param value The string value that should be turned into a color
 * @param colors The colors to choose from
 * @returns The color
 */
export function stringToColor(value: string, colors: NonEmptyArray<HexColor>): HexColor {
    // If the string is emtpy, we can default to the first color
    if (value.length === 0) return colors[0];

    // First we get the hashCode from the value
    const hash = hashCode(value);

    // We turn the hashCode into a valid index of the colors array
    const index = ((hash % colors.length) + colors.length) % colors.length;

    return colors[index];
}

export const colorSuggestions = [
    {
        label: 'Orange',
        value: 'bg-saga-new-orange-light' as const,
    },
    {
        label: 'Yellow',
        value: 'bg-saga-new-yellow-light' as const,
    },
    {
        label: 'Purple',
        value: 'bg-saga-new-purple-light' as const,
    },
    {
        label: 'Green',
        value: 'bg-saga-new-green-light' as const,
    },
];

export const textColorSuggestions = [
    {
        label: 'Dark Red',
        value: 'text-saga-new-red-highlight-color-text' as const,
    },
    {
        label: 'Orange',
        value: 'text-saga-new-orange-highlight-color-text' as const,
    },
    {
        label: 'Brown',
        value: 'text-saga-new-brown' as const,
    },
    {
        label: 'Gray',
        value: 'text-saga-new-gray-highlight-color-text' as const,
    },
    {
        label: 'Green',
        value: 'text-saga-new-green-highlight-color-text' as const,
    },
    {
        label: 'Blue',
        value: 'text-saga-new-blue-highlight-color-text' as const,
    },
    {
        label: 'Purple',
        value: 'text-saga-new-purple-highlight-color-text' as const,
    },
];

export const backgroundColorSuggestions = [
    {
        label: 'Red',
        value: 'bg-saga-new-red-highlight-color-bg' as const,
    },
    {
        label: 'Orange',
        value: 'bg-saga-new-orange-highlight-color-bg' as const,
    },
    {
        label: 'Yellow',
        value: 'bg-saga-new-yellow-highlight-color-bg' as const,
    },
    {
        label: 'Gray',
        value: 'bg-saga-new-gray-highlight-color-bg' as const,
    },
    {
        label: 'Green',
        value: 'bg-saga-new-green-highlight-color-bg' as const,
    },

    {
        label: 'Blue',
        value: 'bg-saga-new-blue-highlight-color-bg' as const,
    },
    {
        label: 'Purple',
        value: 'bg-saga-new-purple-highlight-color-bg' as const,
    },
];

export const convertOutdatedColor = (s: string) => {
    switch (s) {
        case 'yellow':
            return 'bg-saga-new-yellow-light';
        case 'blue':
            return 'bg-saga-new-purple-light';
        case 'red':
            return 'bg-saga-new-orange-light';
        case 'green':
            return 'bg-saga-new-green-light';
        case 'grey':
            return '';
        default:
            return s;
    }
};

export const colorLabelByValue = (s: string): string => {
    const normalizedColor = convertOutdatedColor(s);
    let color;

    switch (true) {
        case normalizedColor.includes('bg'):
            color = backgroundColorSuggestions.find((c) => c.value === normalizedColor);
            break;
        case normalizedColor.includes('text'):
            color = textColorSuggestions.find((c) => c.value === normalizedColor);
            break;
    }

    return color?.label ?? 'blank';
};
