import {
    SagaEditor,
    isImage,
    EditorOperations,
    Image as ImageType,
    SagaLocation,
    SpaceOperations,
    BlockBuilder,
} from '@saga/shared';
import Button from '@/components/styled/Button';
import { track } from '@/analytics';
import classNames from 'classnames';
import React, { RefObject, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { AlertTriangle, Trash, Image as ImageIcon, Loader, ArrowRightCircle, Link } from 'react-feather';
import { Node, Path, Range } from 'slate';
import { Transforms } from 'slate';
import { ReactEditor, RenderElementProps, useFocused, useSelected, useSlateStatic } from 'slate-react';
import { PopOver, usePopover } from '@/components/popover/PopOver';

import VoidSelectionShadow from '../../VoidSelectionShadow';
import Input from '@/components/styled/Input';
import Card from '@/components/styled/Card';
import MoveToPageButton from '@/components/popover/MoveToPageButton';
import SendAsLiveBlockButton from '@/components/popover/SendAsLiveBlockButton';
import { useResetCookiesIfNeeded } from '@/components/CloudfrontCookiesProvider';
import { hasValidCookies } from '@/utils/cookie';
import useMobile from '@/hooks/useMobile';
import ImageAlignSelect from '@/components/AlignBlockSelect';
import { ResizeObserver } from '@juggle/resize-observer';
import { useMoveToPage, useSendAsLiveBlock } from '@/hooks/SpaceHooks';
import { useCurrentWorkspace } from '@/components/WorkspaceContext';
import { useUploadImage } from '@/lib/Image';
import { pageToPageSuggestion } from '@/lib/Suggestions';
import { useSpace } from '@/components/SpaceProvider';
import { Image, useAlign, Align } from '../Image';
import * as Popover from '@radix-ui/react-popover';
import { Trans, useTranslation } from 'react-i18next';
import { usePendingUpload } from '@/lib/usePendingUpload';
import { WithExplicitFontType } from '@/components/WithExplicitFontType';
import i18n from 'i18next';
import { convertFileSize } from '@/utils';
import { usePagesPermissions } from '@/components/PagesPermissionsBySpaceProvider';

function useCookies(urlKey: string) {
    const [cookiesValid, setCookiesValid] = React.useState(() => hasValidCookies(urlKey));
    const resetCookiesIfNeeded = useResetCookiesIfNeeded();
    const ensureCookiesArePresent = React.useCallback(async () => {
        await resetCookiesIfNeeded();
        setCookiesValid(hasValidCookies(urlKey));
    }, [resetCookiesIfNeeded, urlKey]);

    return { cookiesValid, ensureCookiesArePresent };
}

function useDisplayImageUrl(url?: string | null) {
    const [displayImageUrl, setDisplayImageUrl] = useState<string | null>(url ?? null);

    // We need to sync the url to the state again in case it changes from outside
    React.useEffect(() => {
        if (url) {
            setDisplayImageUrl(url);
        }
    }, [url]);

    return { displayImageUrl, setDisplayImageUrl };
}

export function EditableImage({
    children,
    element,
}: Omit<RenderElementProps, 'children'> & { element: ImageType; children?: RenderElementProps['children'] }) {
    const editor = useSlateStatic();
    const { urlKey, permissions } = useCurrentWorkspace();
    const { location } = SagaEditor.useEditorContext();
    const path = ReactEditor.findPath(editor, element);
    const selected = useSelected();
    const isSelectedAlone = editor.selection && selected && Range.isCollapsed(editor.selection);
    const imageElement = element as ImageType;
    const { ratio, size, url, title, alt } = imageElement;
    const { cookiesValid, ensureCookiesArePresent } = useCookies(urlKey);
    const [showContextMenu, setShowContextMenu] = useState(false);

    // This is a inbetween state that we also use for previewed image urls
    const { displayImageUrl, setDisplayImageUrl } = useDisplayImageUrl(url);

    const uploadImage = useUploadImage(location);
    const { isPending } = usePendingUpload({ element, onPreview: setDisplayImageUrl });

    const align = useAlign(imageElement);

    const containerRef = useRef<HTMLDivElement>(null);

    const [hasLoadingError, setHasLoadingError] = useState(false);
    const [embedMenuOpen, setEmbedMenuOpen] = useState(false);
    const [embedUrl, setEmbedUrl] = useState('');

    const { canEdit } = SagaEditor.useEditorContext();

    useEffect(() => {
        if (!cookiesValid) {
            ensureCookiesArePresent();
        }
    }, [cookiesValid, ensureCookiesArePresent]);

    function alignImage(align: Align) {
        const path = ReactEditor.findPath(editor, element);
        Transforms.setNodes(editor, { align } as Partial<Node>, { at: path });
        track('align-image', { align });
    }

    function onLoad(e: React.SyntheticEvent<HTMLImageElement, Event>) {
        setHasLoadingError(false);
        const target = e.target as HTMLImageElement;
        if (size == null || ratio == null) {
            const size: [number, number] = [target.naturalWidth, target.naturalHeight];
            const ratio = target.naturalWidth / target.naturalHeight;
            const path = ReactEditor.findPath(editor, element);
            Transforms.setNodes(editor, { size, ratio, isNew: false } as Partial<Node>, {
                at: path,
            });
        }
    }

    function resizeImage(size: [number, number]) {
        Transforms.setNodes(editor, { size } as Partial<Node>, {
            at: path,
        });
    }

    function resetImage() {
        Transforms.unsetNodes(editor, ['url', 'size', 'ratio', 'alt', 'title', 'align'], {
            at: path,
        });
        setEmbedUrl('');
        setDisplayImageUrl(null);
        setHasLoadingError(false);
        track('reset-image');
    }

    function removeImage() {
        const path = ReactEditor.findPath(editor, element);
        Transforms.select(editor, path);
        setTimeout(() => {
            Transforms.removeNodes(editor, { at: path });
        });
        ReactEditor.focus(editor);
        Transforms.insertNodes(editor, [BlockBuilder.paragraph()]);
        track('remove-image');
    }

    function embedImage() {
        const path = ReactEditor.findPath(editor, element);
        Transforms.setNodes(editor, { url: embedUrl } as Partial<Node>, { at: path });
        setEmbedMenuOpen(false);
        track('embed-image-from-url');
    }

    async function onUpload(e: React.ChangeEvent<HTMLInputElement>) {
        const files = e.target.files;

        if (files) {
            // We need to reset the cookies in case they are expired before we actually start loading the image,
            // This guarantees that we will always have valid cookies before we load the image
            await ensureCookiesArePresent();
            const file = files[0];

            if (file.size > permissions.fileUpload.limitFile) {
                i18n.t('editor.file_upload.max_file_size_error', {
                    size: convertFileSize(permissions.fileUpload.limitFile),
                });
                return;
            }

            await uploadImage({ file, image: element });

            setEmbedMenuOpen(false);
            e.target.value = '';
            track('upload-image');
        }
    }

    // we don't wanna show the file input if it is uploading or pasting or the cookies are not valid => avoids flickering content
    if (!isPending && cookiesValid && !displayImageUrl) {
        return (
            <Image.Container id={element.id} ref={containerRef}>
                <VoidSelectionShadow path={path}>
                    <EditableImage.FileInput
                        selected={selected || embedMenuOpen}
                        disabled={!canEdit}
                        hasError={hasLoadingError}
                        onChange={onUpload}
                        elementId={element.id}
                        onEmbedClick={() => setEmbedMenuOpen(true)}
                    />
                </VoidSelectionShadow>

                <EditableImage.EmbedMenu
                    isOpen={embedMenuOpen}
                    containerRef={containerRef}
                    elementId={element.id}
                    url={embedUrl}
                    onUrlChange={setEmbedUrl}
                    setIsOpen={setEmbedMenuOpen}
                    onEmbed={embedImage}
                />
                <Image.Void>{children}</Image.Void>
            </Image.Container>
        );
    }

    return (
        <Image.Container
            id={element.id}
            align={align}
            ref={containerRef}
            onPointerEnter={() => {
                setShowContextMenu(true);
            }}
            onPointerLeave={() => {
                setShowContextMenu(false);
            }}
        >
            {displayImageUrl && cookiesValid && (
                <EditableImage.LoadedContainer hidden={hasLoadingError}>
                    <VoidSelectionShadow path={path}>
                        <EditableImage.ResizeableImage
                            alt={alt}
                            title={title}
                            src={displayImageUrl}
                            onError={() => {
                                setHasLoadingError(true);
                            }}
                            size={size}
                            onLoad={onLoad}
                            onResize={resizeImage}
                            ratio={ratio}
                            selected={selected}
                            isUploading={isPending}
                            disabled={!canEdit}
                            onClick={() => {
                                ReactEditor.focus(editor);
                                const path = ReactEditor.findPath(editor, element);
                                EditorOperations.Selection.safeSelectByLocation(editor, {
                                    anchor: { path: [...path, 0], offset: 0 },
                                    focus: { path: [...path, 0], offset: 0 },
                                });
                            }}
                        />
                    </VoidSelectionShadow>
                    {canEdit && !isPending && (showContextMenu || isSelectedAlone) && (
                        <EditableImage.ContextMenu
                            align={align}
                            onAlign={alignImage}
                            onRemove={removeImage}
                            path={path}
                        />
                    )}
                </EditableImage.LoadedContainer>
            )}

            {hasLoadingError && (
                <VoidSelectionShadow path={path}>
                    <EditableImage.ErrorState selected={selected} onResetClick={resetImage} canEdit={canEdit} />
                </VoidSelectionShadow>
            )}

            <Image.Void>{children}</Image.Void>
        </Image.Container>
    );
}

EditableImage.LoadedContainer = function ImageLoadedContainer({
    children,
    hidden,
}: {
    children: React.ReactNode;
    hidden: boolean;
}) {
    return <div className={classNames('relative inline-block image-container', { hidden })}>{children}</div>;
};

type ResizeableImageProps = React.ImgHTMLAttributes<HTMLImageElement> & {
    disabled?: boolean;
    ratio?: number;
    selected?: boolean;
    isUploading: boolean;
    size?: [number, number];
    onResize?: (size: [number, number]) => void;
};

EditableImage.ResizeableImage = function ImageResizableImage({
    disabled,
    selected,
    isUploading,
    ratio,
    size,
    onResize,
    ...props
}: ResizeableImageProps) {
    const imageRef = useRef<HTMLImageElement>(null);
    const [showBigImage, setShowBigImage] = useState(false);
    const isMobile = useMobile();

    useLayoutEffect(() => {
        const image = imageRef.current;

        if (image && ratio) {
            function adjustRatio() {
                if (image && ratio) {
                    const rect = image.getBoundingClientRect();
                    if (rect.height === 0 || rect.width === 0) {
                        return;
                    }
                    const currentRatio = rect.width / rect.height;
                    if (Math.abs(currentRatio - ratio) > 0.05) {
                        image.style.height = `${rect.width / ratio}px`;
                    }
                }
            }

            adjustRatio();

            const resizeObserver = new ResizeObserver(() => {
                adjustRatio();
            });

            resizeObserver.observe(image);

            return () => {
                resizeObserver.unobserve(image);
            };
        }

        return;
    }, [ratio]);

    return (
        <div contentEditable={false} className="relative select-none">
            {isUploading && ratio && <EditableImage.UploadingState />}
            {showBigImage && props.src && (
                <Image.BigImage
                    imageRef={imageRef}
                    isOpen={showBigImage}
                    onOpenChange={setShowBigImage}
                    src={props.src}
                    minPadding={50}
                />
            )}
            <img
                className={classNames('object-contain rounded ', {
                    'cursor-pointer': !disabled,
                    'shadow-lightblue': selected && !disabled && !showBigImage,
                    'opacity-50': isUploading,
                })}
                onDoubleClick={() => setShowBigImage(true)}
                onPointerUp={(e) => {
                    if (isMobile) {
                        e.preventDefault();
                        e.stopPropagation();
                        setShowBigImage(true);
                    }
                }}
                // onTouchStart={() => setShowBigImage(true)}
                ref={imageRef}
                style={size ? { width: size[0], height: size[1] } : undefined}
                {...props}
            />
            {!disabled && ratio && onResize && (
                <div className="absolute right-0 top-0 bottom-0">
                    <EditableImage.Resizer imageRef={imageRef} onResize={onResize} ratio={ratio} />
                </div>
            )}
        </div>
    );
};

EditableImage.UploadingState = function ImageUploadingState() {
    return (
        <div className="absolute flex justify-center items-center space-x-2 inset-0">
            <Loader className="animate-spin" />
            <div>Uploading image...</div>
        </div>
    );
};

EditableImage.ErrorState = function ImageErrorState({
    selected,
    canEdit,
    onResetClick,
}: {
    selected?: boolean;
    canEdit?: boolean;
    onResetClick(): void;
}) {
    const { t } = useTranslation();
    return (
        <div
            className={classNames(
                'w-full h-20 rounded border-dashed border border-red-700 flex items-center justify-center text-red-700',
                {
                    'shadow-lightblue': selected,
                },
            )}
        >
            <div className="flex justify-center items-center space-x-1 ">
                <div className="flex space-x-2">
                    <AlertTriangle />
                    <div>{t('common.image_could_not_be_loaded')}</div>
                </div>
                {canEdit && (
                    <button
                        onClick={onResetClick}
                        className="font-semibold underline cursor hover:bg-saga-gray-200 p-1 rounded"
                    >
                        {t('common.reset')}
                    </button>
                )}
            </div>
        </div>
    );
};

EditableImage.FileInput = function ImageFileInput({
    elementId,
    selected,
    disabled,
    hasError,
    onEmbedClick,
    onChange,
}: {
    elementId: string;
    disabled?: boolean;
    hasError?: boolean;
    selected?: boolean;
    onEmbedClick(): void;
    onChange(e: React.ChangeEvent<HTMLInputElement>): void;
}) {
    const inputRef = useRef<HTMLInputElement>(null);
    const { t } = useTranslation();
    return (
        <label htmlFor={`imageInput_${elementId}`} className="w-full relative block text-saga-text-gray">
            <button
                className={classNames(
                    'border border-dashed dark:border-saga-gray-600 h-20 w-full rounded flex justify-center items-center focus:outline-none bg-white dark:bg-saga-gray-1000',
                    {
                        'cursor-default': disabled,
                        'hover:border-saga-gray-500': !disabled,
                        'border-saga-bg-gray': !hasError,
                        'shadow-lightblue': selected,
                    },
                )}
                onClick={() => {
                    if (!disabled) {
                        const input = inputRef.current;
                        if (input) {
                            input.click();
                            track('open-upload-image-menu');
                        }
                    }
                }}
            >
                <span className="sr-only">{t('common.click_add_image')}</span>
            </button>
            <div className="flex justify-center items-center space-x-2 absolute inset-0 pointer-events-none">
                <ImageIcon />
                {!disabled && (
                    <div>
                        <Trans
                            i18nKey="common.click_add_image_or_embed"
                            components={{
                                1: (
                                    <button
                                        className="underline cursor hover:bg-saga-gray-200 p-1 rounded hover:text-saga-black pointer-events-auto focus:outline-none"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            onEmbedClick();
                                            track('open-embed-image-from-url-menu');
                                        }}
                                    />
                                ),
                            }}
                        />
                    </div>
                )}
            </div>
            <input
                id={`inputfile-${elementId}`}
                className="hidden"
                accept="image/*"
                multiple={false}
                onChange={onChange}
                type="file"
                disabled={disabled}
                ref={inputRef}
            ></input>
        </label>
    );
};

type PopoverRefs = {
    contextMenu: React.MutableRefObject<HTMLButtonElement | null>;
    moveToPage: React.MutableRefObject<HTMLButtonElement | null>;
    sendReference: React.MutableRefObject<HTMLButtonElement | null>;
};

const usePopoversController = () => {
    const refsMap = React.useRef<PopoverRefs>({
        contextMenu: React.useRef(null),
        moveToPage: React.useRef(null),
        sendReference: React.useRef(null),
    });

    const [popoverVisible, setPopoverVisible] = React.useState<keyof PopoverRefs | null>(null);

    return { refsMap, popoverVisible, setPopoverVisible };
};

EditableImage.ContextMenu = function ImageContextMenu({
    align,
    onAlign,
    onRemove,
    path,
}: {
    align: Align;
    onAlign(align: Align): void;
    onRemove(): void;
    path: Path;
}) {
    const { space } = useSpace();
    const { refsMap, popoverVisible, setPopoverVisible } = usePopoversController();
    const isMobile = useMobile();
    const { t } = useTranslation();
    const { hasAccess } = usePagesPermissions();

    const { zIndex, location } = SagaEditor.useEditorContext();
    const sendAsLiveBlock = useSendAsLiveBlock(location);

    const moveToPage = useMoveToPage();

    const contextMenuOpen = popoverVisible === 'contextMenu';
    const moveToPageOpen = popoverVisible === 'moveToPage';
    const sendReferenceOpen = popoverVisible === 'sendReference';

    const togglePopover = React.useCallback(
        (tag: Parameters<typeof setPopoverVisible>[0]) => () => {
            const isAlreadyOpen = popoverVisible === tag;

            setPopoverVisible(isAlreadyOpen ? null : tag);
        },
        [popoverVisible, setPopoverVisible],
    );

    const getSuggestions = React.useCallback(() => {
        return SpaceOperations.getPages(
            space,
            ['id', 'title', 'icon', 'aliases', 'isTemplate', 'settings', 'updatedAt'],
            undefined,
            hasAccess,
        ).map(pageToPageSuggestion);
    }, [space, hasAccess]);

    return (
        <div
            contentEditable={false}
            onMouseDown={(e) => {
                e.preventDefault();
                e.stopPropagation();
            }}
            className="absolute top-0 right-0 flex opacity-100 flex-row select-none dark:text-white"
        >
            <Popover.Root>
                {!isMobile && (
                    <>
                        {SagaLocation.isPageLocation(location) && (
                            <MoveToPageButton
                                popoverProps={{
                                    isOpen: moveToPageOpen,
                                    onClose: togglePopover('moveToPage'),
                                    onSubmit: ({ selectedItem }) => {
                                        moveToPage({
                                            selectedItem,
                                            location: path,
                                        });
                                    },
                                    getSuggestions,
                                    dropdownProps: {
                                        align: 'right',
                                    },
                                }}
                                zIndex={zIndex}
                                attachToRef={refsMap.current.moveToPage}
                                onOpen={togglePopover('moveToPage')}
                            >
                                <div className="p-1.5 m-1 bg-white dark:bg-zinc-700 shadow-popupSmall inset-0 rounded focus:outline-none active:shadow-xs">
                                    <span className="sr-only">Move to page</span>
                                    <ArrowRightCircle size={16} />
                                </div>
                            </MoveToPageButton>
                        )}

                        {SagaLocation.isPageLocation(location) && (
                            <SendAsLiveBlockButton
                                popoverProps={{
                                    isOpen: sendReferenceOpen,
                                    onClose: togglePopover('sendReference'),
                                    onSubmit: ({ selectedItem }) => sendAsLiveBlock({ selectedItem, location: path }),
                                    getSuggestions,
                                    dropdownProps: {
                                        align: 'right',
                                    },
                                }}
                                createLiveReferenceDisabled={false}
                                zIndex={zIndex}
                                attachToRef={refsMap.current.sendReference}
                                onOpen={togglePopover('sendReference')}
                            >
                                <div className="p-1.5 m-1 bg-white dark:bg-zinc-700 shadow-popupSmall inset-0 rounded focus:outline-none active:shadow-xs">
                                    <Link size={16} />
                                    <span className="sr-only">{t('common.create_live_block')}</span>
                                </div>
                            </SendAsLiveBlockButton>
                        )}
                    </>
                )}
                <Popover.Trigger asChild>
                    <Button.MenuButton
                        aria-label={
                            t(
                                contextMenuOpen ? 'common.close_page_context_menu' : 'common.open_image_context_menu',
                            ) as string
                        }
                        aria-haspopup="true"
                        ref={refsMap.current.contextMenu}
                        onClick={togglePopover('contextMenu')}
                    />
                </Popover.Trigger>
                <Popover.Portal>
                    <Popover.Content onOpenAutoFocus={(e) => e.preventDefault()} className="z-100">
                        <PopOver.PaddedCard>
                            <ImageAlignSelect align={align} onAlign={onAlign} />
                            <PopOver.RoundedButton onClick={() => onRemove()} aria-label="Remove image">
                                <Trash className="stroke-gray-dark mr-2 my-auto" size={14} />
                                <div className="text-sm">{t('common.remove_image')}</div>
                            </PopOver.RoundedButton>
                        </PopOver.PaddedCard>
                    </Popover.Content>
                </Popover.Portal>
            </Popover.Root>
        </div>
    );
};

EditableImage.EmbedMenu = function ImageEmbedMenu({
    elementId,
    isOpen,
    onEmbed,
    url,
    onUrlChange,
    setIsOpen,
    containerRef,
}: {
    elementId: string;
    isOpen: boolean;
    onEmbed(): void;
    url: string;
    onUrlChange(url: string): void;
    setIsOpen(isOpen: boolean): void;
    containerRef: React.RefObject<HTMLDivElement>;
}) {
    const inputRef = useRef<HTMLInputElement>(null);

    const [embedMenuRef, embedMenuContentRef] = usePopover({
        target: () => {
            if (containerRef.current) {
                return containerRef.current.getBoundingClientRect();
            }
            return null;
        },
        isOpen: isOpen,
        onClose: () => setIsOpen(false),
        align: 'center',
        offset: {
            y: 4,
        },
    });

    return (
        <PopOver
            isOpen={isOpen}
            contentRef={embedMenuContentRef}
            ref={embedMenuRef}
            zIndex={50}
            onOpenAnimationDone={() => {
                const input = inputRef.current;
                if (input) {
                    input.focus();
                }
            }}
        >
            <div
                className={classNames('transition-opacity flex justify-center', {
                    'pointer-events-none opacity-0': !isOpen,
                    'opacity-100': isOpen,
                })}
            >
                <Card.WithShadow>
                    <Card.Background>
                        <div className="px-3 py-3 w-full md:w-500">
                            <form
                                autoComplete="off"
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    onEmbed();
                                }}
                            >
                                <label htmlFor={`imageUrl_${elementId}`} className="space-y-2">
                                    <Input
                                        innerRef={inputRef}
                                        id={`imageUrl_${elementId}`}
                                        name="url"
                                        type="url"
                                        value={url}
                                        placeholder="Enter image URL"
                                        autoFocus
                                        onChange={(e) => onUrlChange(e.target.value)}
                                    />
                                    <div className="flex justify-center">
                                        <Button type="submit">Embed image</Button>
                                    </div>
                                </label>
                            </form>
                        </div>
                    </Card.Background>
                </Card.WithShadow>
            </div>
        </PopOver>
    );
};

EditableImage.Resizer = function ImageResizer({
    imageRef,
    onResize,
    ratio,
}: {
    imageRef: RefObject<HTMLImageElement | null>;
    onResize(size: [number, number]): void;
    ratio: number;
}) {
    const dragInfoRef = useRef({ isDragging: false, from: [0, 0], initialWidth: 0, initialHeight: 0 });
    useEffect(() => {
        function onPointerUp(e: PointerEvent) {
            if (dragInfoRef.current.isDragging && imageRef.current) {
                const from = dragInfoRef.current.from;
                const to = [e.clientX, e.clientY];
                const delta = to[0] - from[0];
                const width = dragInfoRef.current.initialWidth;
                let newWidth = width + delta;
                const rect = imageRef.current.getBoundingClientRect();

                onResize([newWidth, rect.width / ratio]);
            }
            dragInfoRef.current.isDragging = false;
        }

        function onPointerMove(e: PointerEvent) {
            if (dragInfoRef.current.isDragging && imageRef.current) {
                const from = dragInfoRef.current.from;
                const to = [e.clientX, e.clientY];
                const delta = to[0] - from[0];
                const width = dragInfoRef.current.initialWidth;
                let newWidth = width + delta;

                imageRef.current.style.width = `${newWidth}px`;
                const rect = imageRef.current.getBoundingClientRect();
                imageRef.current.style.height = `${rect.width / ratio}px`;
            }
        }

        window.addEventListener('pointermove', onPointerMove);
        window.addEventListener('pointerup', onPointerUp);

        return () => {
            window.removeEventListener('pointermove', onPointerMove);
            window.removeEventListener('pointerup', onPointerUp);
        };
    }, [imageRef, onResize, ratio]);

    return (
        <div
            onPointerDown={(e) => {
                if (imageRef.current) {
                    const from = [e.clientX, e.clientY];
                    const rect = imageRef.current.getBoundingClientRect();
                    dragInfoRef.current = {
                        isDragging: true,
                        from,
                        initialWidth: rect.width,
                        initialHeight: rect.height,
                    };
                }
            }}
            className="h-full w-3 flex justify-center items-center"
            style={{ cursor: 'ew-resize' }}
        >
            <div
                className="h-full border border-solid border-gray-100 bg-gray-700 rounded-full image-container-hover:opacity-100 opacity-0 transition-opacity"
                style={{ maxHeight: 40, width: 6 }}
            ></div>
        </div>
    );
};

export const spaceImageBlockPlugin = SagaEditor.Plugins.createBlockPlugin({
    match: isImage,
    Component(props) {
        const { canEdit, location } = SagaEditor.useEditorContext();
        const { urlKey } = useCurrentWorkspace();
        const focused = useFocused();

        const { selected, element, blockPlugins } = props;

        useEffect(() => {
            const onCopy = (event: ClipboardEvent) => {
                event.preventDefault();
                const clipboardData = event.clipboardData?.getData('application/x-saga');
                if (clipboardData && clipboardData.includes(element.id)) return;

                SagaEditor.Clipboard.copyBlocks([element], {
                    location: { ...location, blockId: element.id },
                    spaceUrlKey: urlKey,
                    event,
                    action: 'copy',
                    blockPlugins,
                });
            };

            if (focused && selected) {
                document.addEventListener('copy', onCopy);
            }

            return () => {
                document.removeEventListener('copy', onCopy);
            };
        }, [element, focused, selected, blockPlugins, location, urlKey]);

        if (canEdit) {
            return (
                <WithExplicitFontType type="interface">
                    <EditableImage {...props} />
                </WithExplicitFontType>
            );
        }

        return (
            <WithExplicitFontType type="interface">
                <Image {...props} />
            </WithExplicitFontType>
        );
    },
});
