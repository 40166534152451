import React from 'react';
import LogoAnimatedSvg from '@/assets/LogoAnimatedSvg';

const LoadingScreenContext = React.createContext<{ start(message?: string): void; stop(): void }>({
    start: () => {},
    stop: () => {},
});

export const LoadingSpinner: React.FC<{ message?: string }> = ({ message }) => {
    return (
        <div className="absolute inset-0 flex flex-col items-center justify-center z-100">
            <LogoAnimatedSvg className="w-96" />
            {message}
        </div>
    );
};

export function LoadingScreenProvider({ children }: { children: React.ReactNode }) {
    const [state, setState] = React.useState<{ loading: boolean; message?: string }>({
        loading: false,
        message: undefined,
    });

    const start = React.useCallback((message?: string) => {
        setState({ loading: true, message });
    }, []);

    const stop = React.useCallback(() => {
        setState({ loading: false });
    }, []);

    const loadingScreenApi = React.useMemo(() => ({ start, stop }), [start, stop]);

    return (
        <LoadingScreenContext.Provider value={loadingScreenApi}>
            {children}
            {state.loading && <LoadingSpinner message={state.message} />}
        </LoadingScreenContext.Provider>
    );
}

const LoadingScreen: React.FC<{ message?: string }> = ({ message }) => {
    const loadingScreenApi = React.useContext(LoadingScreenContext);
    React.useLayoutEffect(() => {
        loadingScreenApi.start(message);
        return () => loadingScreenApi.stop();
    }, [loadingScreenApi, message]);
    return null;
};

export default LoadingScreen;
