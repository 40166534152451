import React, { useState } from 'react';
import { Codesandbox, Copy } from 'react-feather';
import * as R from 'ramda';
import AceEditor from 'react-ace';
import classNames from 'classnames';

import 'ace-builds/src-noconflict/mode-javascript';
import 'ace-builds/src-noconflict/mode-typescript';
import 'ace-builds/src-noconflict/mode-css';
import 'ace-builds/src-noconflict/mode-html';
import 'ace-builds/src-noconflict/mode-xml';
import 'ace-builds/src-noconflict/mode-c_cpp';
import 'ace-builds/src-noconflict/mode-ocaml';
import 'ace-builds/src-noconflict/mode-csharp';
import 'ace-builds/src-noconflict/mode-clojure';
import 'ace-builds/src-noconflict/mode-coffee';
import 'ace-builds/src-noconflict/mode-dart';
import 'ace-builds/src-noconflict/mode-python';
import 'ace-builds/src-noconflict/mode-elixir';
import 'ace-builds/src-noconflict/mode-elm';
import 'ace-builds/src-noconflict/mode-fortran';
import 'ace-builds/src-noconflict/mode-golang';
import 'ace-builds/src-noconflict/mode-graphqlschema';
import 'ace-builds/src-noconflict/mode-haskell';
import 'ace-builds/src-noconflict/mode-java';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/mode-kotlin';
import 'ace-builds/src-noconflict/mode-latex';
import 'ace-builds/src-noconflict/mode-lisp';
import 'ace-builds/src-noconflict/mode-matlab';
import 'ace-builds/src-noconflict/mode-php';
import 'ace-builds/src-noconflict/mode-python';
import 'ace-builds/src-noconflict/mode-ruby';
import 'ace-builds/src-noconflict/mode-rust';
import 'ace-builds/src-noconflict/mode-scss';
import 'ace-builds/src-noconflict/mode-sass';
import 'ace-builds/src-noconflict/mode-sql';
import 'ace-builds/src-noconflict/mode-swift';
import 'ace-builds/src-noconflict/mode-yaml';
import 'ace-builds/src-noconflict/mode-plain_text';
import 'ace-builds/src-noconflict/theme-solarized_light';
import 'ace-builds/src-noconflict/theme-monokai';
import 'ace-builds/src-noconflict/ext-language_tools';

import Tooltip from '@/components/popover/Tooltip';
import { createAndOpenCodeSandbox, supportsCsb } from '@/lib/CodeSandbox';
import { AnyBlockItem } from '@saga/shared';
import useInterfaceSettings from '@/hooks/useInterfaceSettings';
import { useTranslation } from 'react-i18next';

const LANGUAGES = [
    { label: 'JavaScript', value: 'javascript' },
    { label: 'TypeScript', value: 'typescript' },
    { label: 'CSS', value: 'css' },
    { label: 'HTML', value: 'html' },
    { label: 'XML', value: 'xml' },
    { label: 'C/C++', value: 'c_cpp' },
    { label: 'C#', value: 'csharp' },
    { label: 'Clojure', value: 'clojure' },
    { label: 'Coffeescript', value: 'coffee' },
    { label: 'Dart', value: 'dart' },
    { label: 'Elixir', value: 'elixir' },
    { label: 'Elm', value: 'elm' },
    { label: 'Fortran', value: 'fortran' },
    { label: 'Go', value: 'go' },
    { label: 'GraphQL Schema', value: 'graphqlschema' },
    { label: 'Haskell', value: 'haskell' },
    { label: 'Java', value: 'java' },
    { label: 'JSON', value: 'json' },
    { label: 'Kotlin', value: 'kotlin' },
    { label: 'LaTeX', value: 'latex' },
    { label: 'Lisp', value: 'lisp' },
    { label: 'Matlab', value: 'matlab' },
    { label: 'PHP', value: 'php' },
    { label: 'Python', value: 'python' },
    { label: 'Ruby', value: 'ruby' },
    { label: 'Rust', value: 'rust' },
    { label: 'SASS', value: 'sass' },
    { label: 'SCSS', value: 'scss' },
    { label: 'SQL', value: 'sql' },
    { label: 'Swift', value: 'swift' },
    { label: 'YAML', value: 'yaml' },
    { label: 'OCaml', value: 'ocaml' },
    { label: 'Plain Text', value: 'text' },
];

interface IProps {
    content: string | AnyBlockItem[];
    language: string;
    onSetLanguage?: (language: string) => void;
    onChange: (value: string) => void;
    disabled: boolean;
    showCodeSandbox?: boolean;
    isNew?: boolean;
    innerRef?: React.RefObject<AceEditor>;
    disableSelect?: boolean;
}

const languages = R.sortBy(R.prop('label'))(LANGUAGES);

function getText(blocks: AnyBlockItem[]) {
    return blocks
        .map((block) => {
            const textChild = block.children[0];

            if ('text' in textChild) {
                return `${textChild.text}`;
            }
            return '';
        })
        .join('\n');
}

// In the StaticEditor, it could happen that old code content that is not string
// is present because it wasn't normalized yet, therefore we need to make sure we always
// get the string from it
export function getFixedContent(content: string | AnyBlockItem[]) {
    if (typeof content !== 'string') {
        return getText(content);
    }

    return content;
}

const CodeEditor = ({
    content,
    language,
    onSetLanguage,
    onChange,
    disabled,
    showCodeSandbox,
    isNew,
    innerRef,
}: IProps) => {
    const [value, setValue] = useState(() => getFixedContent(content));
    const [isCopied, setIsCopied] = useState(false);

    React.useEffect(() => {
        setValue(getFixedContent(content));
    }, [content]);

    const [{ darkMode }] = useInterfaceSettings();
    const { t } = useTranslation();

    return (
        <div
            className="relative p-3 rounded overflow-x-auto dark:border dark:border-solid dark:border-zinc-800"
            style={{ backgroundColor: darkMode ? '#262626' : '#F5F5F5', minHeight: 50 }}
            contentEditable={false}
        >
            <div
                className="relative overflow-x-auto select-none"
                style={{ minHeight: 50 }}
                contentEditable={false}
                data-testid="ace-editor"
                onMouseDown={(e) => {
                    e.stopPropagation();
                }}
            >
                <AceEditor
                    width="100%"
                    minLines={3}
                    maxLines={Infinity}
                    mode={language}
                    style={{ backgroundColor: darkMode ? '#262626' : '#F5F5F5' }}
                    theme={darkMode ? 'monokai' : 'solarized_light'}
                    value={value}
                    onChange={(value) => {
                        onChange(value);
                        setValue(value);
                    }}
                    readOnly={disabled}
                    editorProps={{ $blockScrolling: true }}
                    setOptions={{
                        showGutter: false,
                        showPrintMargin: false,
                        useWorker: false,
                        enableBasicAutocompletion: true,
                        enableLiveAutocompletion: true,
                        highlightActiveLine: false,
                    }}
                    onBlur={(_, editor) => {
                        editor?.clearSelection();
                    }}
                    ref={innerRef}
                    onLoad={(editor) => {
                        setTimeout(() => {
                            if (isNew) {
                                editor.focus();
                            }
                        }, 1);
                    }}
                />
                <div contentEditable={false} className="z-10 text-xs absolute -top-1 right-0 pr-1 flex items-center">
                    {disabled && onSetLanguage && (
                        <div style={{ minWidth: 90 }} className="rounded bg-white dark:bg-zinc-700">
                            {languages.find((l) => l.value === language)?.label}
                        </div>
                    )}
                    {!disabled && onSetLanguage && (
                        <button
                            onClick={() => {
                                navigator.clipboard.writeText(value);
                                setIsCopied(true);
                                setTimeout(() => setIsCopied(false), 2000);
                            }}
                            className={classNames(
                                'mr-2 rounded bg-saga-gray-100 dark:bg-[#262626] cursor-pointer focus:outline-none flex items-center gap-1 px-2 py-1',
                                {
                                    'text-green-500': isCopied,
                                    'text-saga-gray-600 dark:text-saga-gray-400': !isCopied,
                                },
                            )}
                        >
                            <Copy size={12} />
                            {t('common.copy')}
                        </button>
                    )}
                    {!disabled && onSetLanguage && (
                        <select
                            className={classNames(
                                'rounded bg-saga-gray-100 dark:bg-[#262626] cursor-pointer focus:outline-none text-saga-gray-600 dark:text-saga-gray-400',
                                'w-[88px]',
                            )}
                            value={language}
                            onChange={(e) => onSetLanguage(e.target.value)}
                        >
                            {languages.map((languageOption) => (
                                <option value={languageOption.value} key={languageOption.value}>
                                    {languageOption.label}
                                </option>
                            ))}
                        </select>
                    )}
                </div>
                {showCodeSandbox && supportsCsb(language) && (
                    <Tooltip content={t('common.open_in_codeSandbox')} placement="bottom">
                        <button
                            onClick={() => {
                                createAndOpenCodeSandbox(value, language);
                            }}
                            className="absolute bottom-0 right-0 focus:outline-none"
                        >
                            <Codesandbox className="opacity-10 hover:opacity-50" />
                        </button>
                    </Tooltip>
                )}
            </div>
        </div>
    );
};

export default CodeEditor;
