import * as Y from 'yjs';
import * as t from 'io-ts';
import { either as E } from 'fp-ts';
import reporter from 'io-ts-reporters';
import * as A from 'fp-ts/ReadonlyArray';
import * as THS from 'fp-ts/These';
import * as M from 'fp-ts/Monoid';
import { pipe, flow } from 'fp-ts/lib/function';

interface SafeMapInput<A extends Record<string, unknown>> {
    definition: t.Type<A, unknown>;
    map: Y.Map<unknown>;
}

export type DecodingError = { _tag: 'validationError'; error: string } | { _tag: 'decoderNotFound'; error: string };

type GetArrayValue<A extends Array<unknown>> = A extends Array<infer V> ? V : A;

type OrNull<A, B> = B extends null ? A | null : A;

export type SafeMapOutput<A> = {
    map: Y.Map<unknown>;
    get<K extends keyof A & string>(
        key: K,
    ): E.Either<
        DecodingError,
        A[K] extends Array<unknown> | null
        ? OrNull<SafeArrayOutput<GetArrayValue<NonNullable<A[K]>>>, A[K]>
        : A[K] extends Record<string, unknown> | null
        ? OrNull<SafeMapOutput<NonNullable<A[K]>>, A[K]>
        : A[K]
    >;
    // Probably should be restricted to only works on objects or arrays, for primitive is the same as use `get`
    getDecoded<K extends keyof A & string>(key: K): E.Either<DecodingError, A[K]>;
    decode(): E.Either<DecodingError, A>;
};

const weakYMemoized = new WeakMap();

function isUnionType(decoder: unknown): t.UnionType<any[], unknown> {
    // @ts-expect-error
    return decoder._tag === 'UnionType';
}

export const safeMap = <A extends Record<string, unknown>>(input: SafeMapInput<A>): SafeMapOutput<A> => {
    const { map, definition } = input;

    return {
        map,
        get(key) {
            // this is dangerous, needs to handle error case
            //@ts-expect-error .props will be removed in the next io-ts major version
            const decoder = definition.props[key];

            if (decoder === null) {
                return E.left<DecodingError, never>({
                    _tag: 'decoderNotFound',
                    error: `decoder not found with key: ${key.toString()} in definition: ${definition}`,
                });
            }

            const value = map.get(key);

            if (value instanceof Y.AbstractType && weakYMemoized.get(value)) {
                return weakYMemoized.get(value);
            }

            if (value && value instanceof Y.Map) {
                if (isUnionType(decoder)) {
                    const result = safeMap({
                        // this is dangerous, needs to handle error case
                        //@ts-expect-error
                        definition: decoder.types.find((type) => type._tag === 'InterfaceType'),
                        map: value,
                    });

                    const right = E.right(result);

                    weakYMemoized.set(value, right);

                    return right;
                }

                const result = safeMap({
                    definition: decoder,
                    map: value,
                });

                const right = E.right(result);

                weakYMemoized.set(value, right);

                return right;
            } else if (value && value instanceof Y.Array) {
                const result = safeArray({
                    definition: decoder,
                    array: value,
                });

                const right = E.right(result);

                weakYMemoized.set(value, right);

                return right;
            }

            const result = decoder.decode(value);

            if (result._tag === 'Left') {
                return pipe(definition.decode(map.toJSON()), E.mapLeft(toValidationError));
            }

            return result;
        },
        getDecoded<K extends keyof A & string>(key: K) {
            const value = map.get(key);

            //@ts-ignore
            const decoder = definition?.props[key];

            if (!decoder) {
                return E.left<DecodingError, never>({
                    _tag: 'decoderNotFound',
                    error: `decoder not found with key: ${key.toString()} in definition: ${definition}`,
                });
            }

            const decode = decoder.decode as (a: unknown) => E.Either<t.Errors, A[K]>;

            const unsafeValue = (() => {
                if (value instanceof Y.Map) {
                    return value.toJSON();
                } else if (value instanceof Y.Array) {
                    return value.toJSON();
                } else {
                    return value;
                }
            })();

            return pipe(decode(unsafeValue), E.mapLeft(toValidationError));
        },
        decode() {
            const unsafeValue = map.toJSON();

            return pipe(definition.decode(unsafeValue), E.mapLeft(toValidationError));
        },
    };
};

interface SafeArrayInput<A> {
    definition: t.ArrayC<any>;
    array: Y.Array<A extends Record<string, unknown> ? Y.Map<A> : A>;
}

type SafeArrayItem<A> = E.Either<DecodingError, A extends Record<string, unknown> ? SafeMapOutput<A> : A>;
// TODO: create a smart constructor
export type SafeArrayOutput<A> = {
    array: Y.Array<A extends Record<string, unknown> ? Y.Map<A> : A>;
    toArray(): SafeArrayItem<A>[];
    toDecodedArray(): E.Either<DecodingError, A[]>;
    get(index: number): SafeArrayItem<A>;
};

// TODO: probably needs to override the toJSON function of the proxy otherwise the console.log doesn't receive the new data
export const safeArray = <A>(input: SafeArrayInput<A>): SafeArrayOutput<A> => {
    const { array, definition } = input;

    function get(index: number): SafeArrayItem<any> {
        const value = array.get(index);

        if (value instanceof Y.AbstractType && weakYMemoized.get(value)) {
            return E.right(weakYMemoized.get(value));
        }

        if (value && value instanceof Y.Map) {
            if (isUnionType(definition)) {
                const result = safeMap({
                    // this is dangerous, needs to handle error case
                    //@ts-ignore
                    definition: definition.types.find((type) => type._tag === 'ArrayType').type,
                    map: value,
                });

                weakYMemoized.set(value, result);

                return E.right(result);
            }
            const result = safeMap({
                definition: definition.type,
                map: value,
            });

            weakYMemoized.set(value, result);

            return E.right(result);
        }

        if (isUnionType(definition)) {
            // this is dangerous, needs to handle error case
            //@ts-expect-error
            const result = definition.types.find((type) => type._tag === 'ArrayType').type.decode(value);

            return result;
        }

        const result = definition.type.decode(value);

        return result;
    }

    return {
        array,
        toArray() {
            return array.map((_, i) => {
                return get(i);
            });
        },
        toDecodedArray() {
            const value = array.toJSON();

            return pipe(definition.decode(value), E.mapLeft(toValidationError));
        },
        get,
    };
};

export const observeSafeMap = <A extends Record<string, unknown>>(
    safeMap: SafeMapOutput<A>,
    // TODO: add yjs observer parameters
    observe: (keys: (string | number)[]) => void,
    observeDeep = false,
) => {
    const ymap = safeMap.map;

    if (observeDeep) {
        const fn = (evt: Array<Y.YEvent<Y.AbstractType<unknown>>>) => {
            const keyChanges = evt
                .map((evt) => {
                    if (evt instanceof Y.YMapEvent && evt.target === ymap) {
                        return [...evt.keysChanged.keys()];
                    }

                    return typeof evt.path[0] === 'string' ? [evt.path[0]] : [];
                })
                .flat();

            observe(keyChanges);
        };

        ymap.observeDeep(fn);

        return () => ymap.unobserveDeep(fn);
    } else {
        const fn = (evt: Y.YMapEvent<unknown>) => {
            observe([...evt.keysChanged.keys()]);
        };

        ymap.observe(fn);

        return () => ymap.unobserve(fn);
    }
};

type DeepObserve = (events: Array<Y.YEvent<any>>, transaction: Y.Transaction) => void;
type Observe = (event: Y.YArrayEvent<any>, transaction: Y.Transaction) => void;
type ObserveSafeArrayOpts =
    | {
        observeDeep: true;
        observe: DeepObserve;
    }
    | {
        observeDeep: false;
        observe: Observe;
    };

export const observeSafeArray = <A>(safeArray: SafeArrayOutput<A>, opts: ObserveSafeArrayOpts) => {
    const yarray = safeArray.array;

    if (opts.observeDeep) {
        yarray.observeDeep(opts.observe);

        return () => yarray.unobserveDeep(opts.observe);
    } else {
        yarray.observe(opts.observe);

        return () => yarray.unobserve(opts.observe);
    }
};

type SafeMapWithEither<A> = {
    [K in keyof A]: E.Either<DecodingError, A[K]>;
};

export function safeMapToEither<A>(safeMap: SafeMapOutput<A>, keys: (keyof A & string)[]): SafeMapWithEither<A> {
    const result: any = {};

    keys.forEach((key) => {
        const either = safeMap.get(key);
        result[key] = either;
    });

    return result;
}

const toValidationError = <A>(errors: t.Errors): DecodingError => ({
    _tag: 'validationError',
    error: reporter.report(E.left(errors)).join(),
});

export const unsafeRight = <E, A>(e: E.Either<E, A>): A => {
    if (e._tag === 'Left') {
        throw new Error(`unsafeRight the either is a left ${JSON.stringify(e, null, 2)}`);
    }

    return e.right;
};

const toThese = flow(THS.FromEither.fromEither, THS.bimap(A.of, A.of));

export function fromEithersToThese<A>(monoid: M.Monoid<THS.These<readonly DecodingError[], readonly A[]>>) {
    return (array: E.Either<DecodingError, A>[]) => {
        return pipe(array, A.map(toThese), M.concatAll(monoid));
    };
}
