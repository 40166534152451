import React, { useEffect } from 'react';
import * as api from '@saga/api';
import LoadingScreen from './loading/LoadingScreen';
import { useHistory } from 'react-router-dom';
import { useSetSettings } from './settings/SettingsProvider';
import { useToastContext } from './toast/ToastContext';
import _ from 'lodash';
import Toast from './toast/Toast';
import { useTranslation } from 'react-i18next';

export default function Billing({ search }: { search: string }) {
    const query = new URLSearchParams(search);
    const { showToast } = useToastContext();
    const history = useHistory();
    const setSettings = useSetSettings();
    const { t } = useTranslation();

    const [createSubscriptionMutation] = api.useCreateSubscriptionMutation({
        update: (cache, { data }) => {
            const userData = cache.readQuery<api.UserDataQuery>({ query: api.UserDataDocument });

            if (userData && data?.createSubscription?.space) {
                const { space } = data.createSubscription;
                const isSpaceExists = _.find(userData.spaces, { id: space.id });
                const spaces = isSpaceExists
                    ? userData.spaces.map((currentSpace) => {
                          return currentSpace.id === space.id
                              ? { ...currentSpace, activeSubscription: space.activeSubscription }
                              : currentSpace;
                      })
                    : _.concat(userData.spaces, space);

                cache.writeQuery({
                    query: api.UserDataDocument,
                    data: { ...userData, spaces },
                });

                history.push(`/s/${space.urlKey}`);
                setSettings('billing');
                showToast(() => (
                    <Toast>
                        <Toast.Title>{t('billing.plan_upgrade_success_toast.title')}</Toast.Title>
                        <Toast.Subtitle>{t('billing.plan_upgrade_success_toast.description')}</Toast.Subtitle>
                    </Toast>
                ));
            }
        },
    });

    useEffect(() => {
        const sessionId = query.get('sessionId');
        const sourceUrlKey = query.get('sourceUrlKey');
        const action = query.get('action');

        const createSubscription = async () => {
            if (sessionId && action && sourceUrlKey) {
                await createSubscriptionMutation({
                    variables: {
                        input: { sessionId, action, sourceUrlKey },
                    },
                });
            }
        };
        createSubscription();
    }, []);

    return <LoadingScreen />;
}
