import React from 'react';
import { useOpenBlocksPastedSuggestions } from './Suggestions';
import { useSpace } from '../SpaceProvider';
import { useEmbedContext } from '../EmbedProviders';
import {
    isLiveBlockSource,
    SagaLocation,
    SpaceOperations,
    SagaEditor,
    EditorOperations,
    SagaElement,
    assertNonNull,
    LiveBlockSource,
    flatMap,
    isSagaElement,
    isParagraph,
    isInline,
    isBlockType,
    isTableRow,
    isTableCell,
    isMovableContainerElement,
    isCallout,
    isBlockContainer,
    isGoogleDriveEmbeddableUrl,
    isLinearIssueEmbeddableUrl,
    BlockType,
    BlockBuilder,
} from '@saga/shared';
import { BlocksPastedSuggestion } from '@/types';
import { Editor as SlateEditor, Node, Path, Transforms } from 'slate';
import { useUploadImage } from '@/lib/Image';
import { canBeConvertedToPrettyLink, getBlockToConvert } from './plugins/PrettyLink';
import useDecorator from './decorator/useDecorator';
import { ReactEditor, RenderElementProps, RenderLeafProps, useSlateStatic } from 'slate-react';
import { useIsDragAndDropEnabled } from './DragAndDrop';
import { useTableContext } from './plugins/Table';
import Placeholder from './popover/Placeholder';
import Draggable from '@/components/editor/Draggable';
import BaseRenderElement from './BaseRenderElement';
import { Leaf } from './leaf/Leaf';
import { useTranslation } from 'react-i18next';
import { usePerformBlockChangeWithEditor } from '@/components/RealtimeDocumentProvider';
import { useCurrentWorkspace } from '../WorkspaceContext';

export const ensureThatIsNotWrappedInLiveSource = (
    topLevelBlock: SagaEditor.Clipboard.SagaElementedPasted,
    targetPageEditor: SlateEditor,
    blocks: Array<SagaEditor.Clipboard.SagaElementedPasted>,
): boolean => {
    const blocksToCompare = EditorOperations.Selection.getNode(targetPageEditor, topLevelBlock.originBlockId);

    if (blocksToCompare == null) return false;

    const nodes = SlateEditor.nodes(targetPageEditor, {
        at: blocksToCompare[1],
        mode: 'highest',
        match: (_, path) => path.length === 1,
    });

    const slateNodes = [...nodes].map((n) => n[0]) as SagaElement[];

    const includeLiveRefSource = slateNodes.some(isLiveBlockSource);

    if (includeLiveRefSource) {
        const liveRefSource = slateNodes.find(isLiveBlockSource);
        assertNonNull(liveRefSource);
        return isCopyPastingTheEntireLiveSource(liveRefSource, blocks);
    }

    return true;
};

const isCopyPastingTheEntireLiveSource = (
    liveSource: LiveBlockSource,
    pastedBlocks: Array<SagaEditor.Clipboard.SagaElementedPasted>,
): boolean => {
    const children = flatMap(liveSource.children, ([b]) => b).filter(isSagaElement);
    const pastedBlocksFlat = flatMap(pastedBlocks, ([b]) => b).filter(isSagaElement) as (SagaElement & {
        originBlockId: string;
    })[];

    return children.every((a) => pastedBlocksFlat.some((b) => b.originBlockId === a.id));
};

export const compareStartingFromTopLevelBlocks = (
    topLevelBlock: SagaEditor.Clipboard.SagaElementedPasted,
    targetPageEditor: SlateEditor,
    blocks: Array<SagaEditor.Clipboard.SagaElementedPasted>,
): boolean => {
    const blocksToCompare = EditorOperations.Selection.getNode(targetPageEditor, topLevelBlock.originBlockId);

    if (blocksToCompare == null) return false;

    const originFlatBlocks = flatMap([blocksToCompare[0]], ([n]) => n).filter(isSagaElement);
    const pastedFlatBlocks = flatMap(blocks, ([n]) => n).filter(isSagaElement) as (SagaElement & {
        originBlockId: string;
    })[];

    const originFlatBlocksTrimmed = trimBlocks(originFlatBlocks);

    const entireBlockHasBeenCopied = originFlatBlocksTrimmed.every((a) =>
        pastedFlatBlocks.some((b) => b.originBlockId === a.id),
    );

    return entireBlockHasBeenCopied;
};

const trimBlocks = (blocks: SagaElement[]): SagaElement[] => {
    const lastBlock = blocks[blocks.length - 1];

    if (isParagraph(lastBlock) && Node.string(lastBlock).trim() === '') {
        return trimBlocks(blocks.slice(0, blocks.length - 1));
    }

    return blocks;
};

const isFirstChildOfCallout = (editor: SlateEditor, path: Path, elementId: string) => {
    if (SlateEditor.hasPath(editor, path)) {
        const nodeEntry = SlateEditor.above(editor, {
            match: (n) => isSagaElement(n) && isCallout(n),
            at: path,
        });

        if (nodeEntry == null) return false;

        return nodeEntry[0].children[0].id === elementId;
    }
    return null;
};

interface HeadingsInCallout {
    children: {
        text: string;
    };
    id: string;
    type: string;
}

export const isElementInCalloutHeading = (editor: SlateEditor, path: Path) => {
    if (SlateEditor.hasPath(editor, path)) {
        const nodeEntry = SlateEditor.above(editor, {
            match: (n) => isSagaElement(n) && isCallout(n),
            at: path,
        });

        if (nodeEntry == null) return false;

        return nodeEntry[0].children.some(
            (e: HeadingsInCallout) =>
                e.type === BlockType.HEADING_1 || e.type === BlockType.HEADING_2 || e.type === BlockType.HEADING_3,
        );
    }
    return null;
};

const isFirstChildOfBlockContainer = (editor: SlateEditor, path: Path, elementId: string) => {
    if (SlateEditor.hasPath(editor, path)) {
        const nodeEntry = SlateEditor.above(editor, {
            match: (n) => isSagaElement(n) && isBlockContainer(n),
            at: path,
        });

        if (nodeEntry == null) return false;

        return nodeEntry[0].children[0].id === elementId;
    }
    return null;
};

function DraggableRenderElement(props: RenderElementProps) {
    const { element } = props;
    const editor = useSlateStatic();
    const isWithinTable = useTableContext() != null;
    const dndEnabled = useIsDragAndDropEnabled();
    const { canEdit, showPlaceholder } = SagaEditor.useEditorContext();
    const path = ReactEditor.findPath(editor, element);

    if (isInline(element) || isBlockType(element, [isTableRow, isTableCell])) {
        return <SagaEditor.RenderElement {...props} />;
    }

    const disableDrag =
        !dndEnabled ||
        !canEdit ||
        isWithinTable ||
        !isMovableContainerElement(element) ||
        isFirstChildOfBlockContainer(editor, path, element.id) ||
        isFirstChildOfCallout(editor, path, element.id);

    if (disableDrag) {
        return <BaseRenderElement {...props} />;
    } else {
        return (
            <Draggable element={element}>
                <SagaEditor.RenderElement {...props}>
                    {showPlaceholder && <Placeholder element={element} />}
                    {props.children}
                </SagaEditor.RenderElement>
            </Draggable>
        );
    }
}

function draggableRenderElement(props: RenderElementProps) {
    return <DraggableRenderElement {...props} />;
}

const renderLeaf = (props: RenderLeafProps) => {
    return <Leaf {...props} />;
};

export default function SpaceEditable({ spellCheck }: { spellCheck?: boolean }) {
    const { location } = SagaEditor.useEditorContext();
    const editor = useSlateStatic();
    const openBlocksPastedSuggestions = useOpenBlocksPastedSuggestions();
    const { space } = useSpace();
    const { urlKey } = useCurrentWorkspace();
    const { canBeEmbedded } = useEmbedContext();
    const onPasteImage = useUploadImage(location);
    const decorate = useDecorator();
    const { t } = useTranslation();
    const changeBlocksWithEditor = usePerformBlockChangeWithEditor();

    const onPaste = React.useCallback(
        async (event: React.ClipboardEvent) => {
            const result = await SagaEditor.Clipboard.paste({
                editor,
                event,
                onPasteImage,
                spaceUrlKey: urlKey,
                onBeforePaste: (blocks) => {
                    return blocks
                        .map((block) => {
                            if (
                                space &&
                                isLiveBlockSource(block) &&
                                !SpaceOperations.isLiveBlockSourceArchived(space, block.liveReferenceSourceId)
                            ) {
                                return block.children;
                            }
                            if (!isLiveBlockSource(block) && 'liveReferenceSourceId' in block && 'children' in block) {
                                // Copying the content of a live block source result in a block that is not a liveBlockSource but has the liveReferenceSourceId
                                // in this case, we have return the children to not have invalid blocks
                                return block.children;
                            }

                            return block;
                        })
                        .flat();
                },
            });

            const linkPasted = getBlockToConvert(result.blocks);
            const selection = editor.selection;

            if (selection && linkPasted && canBeConvertedToPrettyLink(linkPasted[0].url)) {
                const suggestions = await (async () => {
                    const base: BlocksPastedSuggestion[] = [{ type: 'keep-as-link', title: t('common.paste_as_link') }];

                    const embeddable = canBeEmbedded(linkPasted[0].url);

                    const isGoogleDriveDocument = isGoogleDriveEmbeddableUrl(linkPasted[0].url);
                    const isLinearIssue = isLinearIssueEmbeddableUrl(linkPasted[0].url);

                    if (embeddable) {
                        base.push({ type: 'turn-into-embed', title: 'Paste as Embed', link: linkPasted[0] });
                    }

                    if (isGoogleDriveDocument) {
                        base.push({
                            type: 'turn-into-google-drive',
                            title: t('common.paste_as_google_drive'),
                            link: linkPasted[0],
                        });
                    }

                    if (isLinearIssue) {
                        base.push({
                            type: 'turn-into-linear-issue',
                            title: t('common.paste_as_linear_link'),
                            link: linkPasted[0],
                        });
                    }

                    base.push({
                        type: 'turn-into-pretty-link',
                        title: t('common.paste_as_preview'),
                        link: linkPasted[0],
                    });

                    Transforms.insertNodes(editor, BlockBuilder.text(' '));
                    Transforms.move(editor, { distance: 1, unit: 'offset' });

                    return base;
                })();

                openBlocksPastedSuggestions({
                    range: selection,
                    suggestions: suggestions,
                });
            } else if (
                space &&
                selection &&
                result.from === 'saga' &&
                SagaLocation.isPageLocation(result.fromLocation) &&
                !SagaLocation.areLocationsEqual(result.fromLocation, location) &&
                result.action === 'copy'
            ) {
                const fromLocation = result.fromLocation;
                const topLevelBlocks = await new Promise<boolean>((res) => {
                    try {
                        changeBlocksWithEditor(space, fromLocation, (targetPageEditor) => {
                            const copyPastingTopLevelBlocksWithAllTheChildren = result.blocks
                                .map((b) => compareStartingFromTopLevelBlocks(b, targetPageEditor, result.blocks))
                                .every((result) => result);

                            const notWrappedInLiveSource = result.blocks
                                .map((b) => ensureThatIsNotWrappedInLiveSource(b, targetPageEditor, result.blocks))
                                .every((a) => a === true);

                            const forbiddenBlockTypes = [
                                BlockType.DIVIDER,
                                BlockType.FILE,
                                BlockType.TASK_BLOCK,
                                BlockType.IMAGE,
                                BlockType.PRETTY_LINK,
                            ];

                            const allBlocksForbidden = !result.blocks.every((block) =>
                                (forbiddenBlockTypes as string[]).includes(block.type),
                            );

                            res(
                                copyPastingTopLevelBlocksWithAllTheChildren &&
                                    notWrappedInLiveSource &&
                                    allBlocksForbidden,
                            );
                        });
                    } catch (error) {
                        res(false);
                    }
                });

                if (topLevelBlocks) {
                    openBlocksPastedSuggestions({
                        range: selection,
                        suggestions: [
                            { type: 'keep-as-text', title: t('common.paste_as_text') },
                            {
                                type: 'turn-into-live-block',
                                title: t('common.paste_as_live_block'),
                                origin: fromLocation,
                                blocks: result.blocks,
                            },
                        ],
                    });
                }
            }
        },
        [
            editor,
            onPasteImage,
            urlKey,
            space,
            location,
            openBlocksPastedSuggestions,
            t,
            canBeEmbedded,
            changeBlocksWithEditor,
        ],
    );

    return (
        <SagaEditor.Editable
            decorate={decorate}
            spaceUrlKey={urlKey}
            onPaste={onPaste}
            spellCheck={spellCheck}
            renderElement={draggableRenderElement}
            renderLeaf={renderLeaf}
            beforeCopy={(fragment) => SpaceOperations.prepareBlocksForExport(space, fragment).filter(isSagaElement)}
        />
    );
}
