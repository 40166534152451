import React from 'react';
import * as R from 'ramda';
import { useCollectionsSnapshot, usePageViews, useTaskViews } from '@/hooks/SpaceHooks';
import { useSpaceAccess } from '@/hooks/useSpaceAccess';
import { NavigationFn, useOpenPage } from '../PageNavigationProvider';
import { useOpenLocation } from '../PageNavigationProvider';
import { useOpenCollection } from '../PageNavigationProvider';
import { useUserContext } from '../UserContext';
import {
    Collection,
    removeNullable,
    SafeSpace,
    SagaLocation,
    WeakPage,
    WeakTask,
    SafeMapOutput,
    Space,
    PageView,
    TaskView,
} from '@saga/shared';
import { useSpace } from '../SpaceProvider';
import useMobile from '@/hooks/useMobile';
import { track } from '@/analytics';
import { useTranslation } from 'react-i18next';
import useInterfaceSettings from '@/hooks/useInterfaceSettings';
import SidebarItemButton, { SidebarItemDroppable } from './SidebarItemButton';
import { DndOverArea } from '@/types';
import Button from '../styled/Button';
import SidebarExpandableMenu from './SidebarExpandableMenu';
import { dndTypes } from '@/constants';
import classNames from 'classnames';
import Collections from './Collections';

import Tooltip from '../popover/Tooltip';

type PageForFavorites = Pick<
    WeakPage,
    'id' | 'title' | 'collections' | 'archivedAt' | 'icon' | 'isTemplate' | 'createdAt' | 'updatedAt'
>;

type FavoriteItem =
    | {
          type: 'page';
          id: string;
          page: Pick<WeakPage, 'id' | 'title' | 'icon' | 'archivedAt' | 'isTemplate'>;
      }
    | {
          type: 'collection';
          id: string;
          collection: Collection;
      }
    | {
          type: 'task';
          id: string;
          task: WeakTask;
      }
    | {
          type: 'page-view';
          id: string;
          pageView: PageView;
      }
    | {
          type: 'task-view';
          id: string;
          taskView: TaskView;
      };

type PinnedItem =
    | {
          type: 'page';
          id: string;
          page: Pick<WeakPage, 'id' | 'title' | 'icon' | 'archivedAt' | 'isTemplate'>;
      }
    | {
          type: 'collection';
          id: string;
          collection: Collection;
      }
    | {
          type: 'task';
          id: string;
          task: WeakTask;
      }
    | {
          type: 'page-view';
          id: string;
          pageView: PageView;
      }
    | {
          type: 'task-view';
          id: string;
          taskView: TaskView;
      };

interface SidebarSectionProps {
    title: string;
    selectedId?: string;
    pages: PageForFavorites[];
    tasks: WeakTask[];
    items: string[];
    addItem: (space: SafeMapOutput<Space>, itemId: string, userId: string) => void;
    moveItem: (
        space: SafeMapOutput<Space>,
        itemId: string,
        options: { targetId: string; position: 'before' | 'after' },
        userId: string,
    ) => void;
}

interface BaseSidebarListProps {
    selectedId?: string;
    canEdit: boolean;
    onCollectionClick: (collectionId: string, event: React.MouseEvent) => void;
    onDrop: (item: SidebarItemDroppable, dropArea: DndOverArea, targetId?: string) => void;
    openPage: (pageId: string, event: React.MouseEvent) => void;
    openLocation: NavigationFn;
    closeSidebar: () => void;
    isMobile: boolean;
    indexedCollections: Record<string, Collection>;
    pages: PageForFavorites[];
    tasks: WeakTask[];
    space: SafeSpace;
}

interface FavoritesListProps extends BaseSidebarListProps {
    items: FavoriteItem[];
}

interface PinnedListProps extends BaseSidebarListProps {
    items: PinnedItem[];
}

const SidebarItemsList = React.memo(function SidebarItemsList({
    items,
    selectedId,
    canEdit,
    onCollectionClick,
    onDrop,
    openPage,
    openLocation,
    closeSidebar,
    isMobile,
    indexedCollections,
    pages,
    tasks,
}: FavoritesListProps | PinnedListProps) {
    const { t } = useTranslation();
    return (
        <>
            {items.map((item) => {
                switch (item.type) {
                    case 'page':
                        const page = item.page;
                        const title = page.title.trim() || t('pages.favourite_untitled');

                        return (
                            <SidebarItemButton.Draggable
                                dndType={dndTypes.SIDEBAR_PAGE}
                                id={page.id}
                                key={page.id}
                                enabled={canEdit}
                                onDrop={(item: SidebarItemDroppable, dropArea) => {
                                    onDrop(item, dropArea, page.id);
                                }}
                            >
                                <Tooltip placement="right" content={title}>
                                    <SidebarItemButton.PageButton
                                        isSelected={page.id === selectedId}
                                        page={page}
                                        onClick={(event) => {
                                            track('open-item-from-quick-access');
                                            openPage(page.id, event);
                                            if (isMobile) {
                                                closeSidebar();
                                            }
                                        }}
                                        showContextMenu={canEdit}
                                    >
                                        <div className="truncate leading-normal text-sm">{title}</div>
                                    </SidebarItemButton.PageButton>
                                </Tooltip>
                            </SidebarItemButton.Draggable>
                        );
                    case 'collection':
                        const collection = item.collection;

                        return (
                            <SidebarItemButton.Draggable
                                dndType={dndTypes.SIDEBAR_COLLECTION}
                                id={collection.id}
                                key={collection.id}
                                enabled={canEdit}
                                onDrop={(item: SidebarItemDroppable, dropArea) => {
                                    onDrop(item, dropArea, collection.id);
                                }}
                            >
                                <Collections.Tree
                                    key={collection.id}
                                    collection={collection}
                                    indexedCollections={indexedCollections}
                                    selectedId={selectedId}
                                    onCollectionClick={onCollectionClick}
                                    canEdit={canEdit}
                                    path={[collection.id]}
                                    pages={pages}
                                    tasks={tasks}
                                    isMobile={isMobile}
                                    showContextMenu
                                    autoExpand
                                />
                            </SidebarItemButton.Draggable>
                        );
                    case 'task':
                        const task = item.task;
                        const isTitleEmpty = task.title ? task.title.trim() === '' : true;
                        return (
                            <SidebarItemButton.Draggable
                                dndType={dndTypes.SIDEBAR_TASK}
                                id={task.id}
                                key={task.id}
                                enabled={canEdit}
                                onDrop={(item: SidebarItemDroppable, dropArea) => {
                                    onDrop(item, dropArea, task.id);
                                }}
                            >
                                <Tooltip placement="right" content={task.title}>
                                    <SidebarItemButton.TaskButton
                                        task={task}
                                        isSelected={selectedId === task.id}
                                        onClick={(event) => {
                                            track('click-on-task', { source: 'sidebar' });
                                            openLocation(SagaLocation.taskLocationFromId(task.id), event);
                                        }}
                                        showContextMenu={canEdit}
                                        source={'sidebar'}
                                    >
                                        <div
                                            className={classNames(
                                                'flex space-x-2 items-center min-w-0 truncate text-sm font-medium',
                                                {
                                                    'text-saga-gray-500': isTitleEmpty,
                                                },
                                            )}
                                        >
                                            {isTitleEmpty ? 'Untitled' : task.title}
                                        </div>
                                    </SidebarItemButton.TaskButton>
                                </Tooltip>
                            </SidebarItemButton.Draggable>
                        );
                    case 'page-view':
                        const pageView = item.pageView;
                        return (
                            <SidebarItemButton.Draggable
                                dndType={dndTypes.SIDEBAR_PAGE_VIEW}
                                id={pageView.id}
                                key={pageView.id}
                                enabled={canEdit}
                                onDrop={(item: SidebarItemDroppable, dropArea) => {
                                    onDrop(item, dropArea, pageView.id);
                                }}
                            >
                                <SidebarItemButton.PageViewButton
                                    showContextMenu={canEdit && !isMobile}
                                    view={pageView}
                                    selectedId={selectedId}
                                    onClick={(event) => {
                                        track('open-page-view', { source: 'sidebar' });
                                        openLocation({ type: 'allPages', viewId: pageView.id }, event);
                                    }}
                                />
                            </SidebarItemButton.Draggable>
                        );
                    case 'task-view':
                        const taskView = item.taskView;
                        return (
                            <SidebarItemButton.Draggable
                                dndType={dndTypes.SIDEBAR_TASK_VIEW}
                                id={taskView.id}
                                key={taskView.id}
                                enabled={canEdit}
                                onDrop={(item: SidebarItemDroppable, dropArea) => {
                                    onDrop(item, dropArea, taskView.id);
                                }}
                            >
                                <SidebarItemButton.TaskViewButton
                                    selectedId={selectedId}
                                    onClick={(event) => {
                                        track('open-task-view', { source: 'sidebar' });
                                        openLocation({ type: 'allTasks', viewId: taskView.id }, event);
                                    }}
                                    showContextMenu={canEdit && !isMobile}
                                    view={taskView}
                                />
                            </SidebarItemButton.Draggable>
                        );
                }
            })}
        </>
    );
});

export function SidebarSection({ title, selectedId, pages, tasks, items, addItem, moveItem }: SidebarSectionProps) {
    const { t } = useTranslation();
    const collections = useCollectionsSnapshot();
    const { canEdit } = useSpaceAccess();
    const openPage = useOpenPage();
    const openLocation = useOpenLocation();
    const goToCollection = useOpenCollection();
    const isMobile = useMobile();
    const [, setInterfaceSettings] = useInterfaceSettings();
    const { space } = useSpace();
    const { user } = useUserContext();

    const pageViews = usePageViews();
    const taskViews = useTaskViews();

    const storageKey = `${title.toLowerCase()}Expanded`;
    const initialExpandedState = React.useMemo(() => localStorage.getItem(storageKey) !== 'false', [storageKey]);
    const [isExpanded, setIsExpanded] = React.useState(initialExpandedState);

    React.useEffect(() => {
        localStorage.setItem(storageKey, String(isExpanded));
    }, [isExpanded, storageKey]);

    const indexedPages = React.useMemo(() => R.indexBy(R.prop('id'), pages), [pages]);
    const indexedTasks = React.useMemo(() => R.indexBy(R.prop('id'), tasks), [tasks]);
    const indexedCollections = React.useMemo(() => R.indexBy(R.prop('id'), collections), [collections]);
    const indexedPageViews = React.useMemo(() => R.indexBy(R.prop('id'), pageViews), [pageViews]);
    const indexedTaskViews = React.useMemo(() => R.indexBy(R.prop('id'), taskViews), [taskViews]);

    const itemsList = React.useMemo(() => {
        function mapIdToItem(id: string): FavoriteItem | null {
            const page = indexedPages[id];

            if (page != null) {
                return {
                    id: page.id,
                    type: 'page' as const,
                    page,
                };
            }

            const collection = indexedCollections[id];
            if (collection != null) {
                return {
                    id: collection.id,
                    type: 'collection' as const,
                    collection,
                };
            }

            const task = indexedTasks[id];
            if (task != null) {
                return {
                    id: task.id,
                    type: 'task' as const,
                    task,
                };
            }

            const pageView = indexedPageViews[id];
            if (pageView != null) {
                return {
                    id: pageView.id,
                    type: 'page-view' as const,
                    pageView,
                };
            }

            const taskView = indexedTaskViews[id];
            if (taskView != null) {
                return {
                    id: taskView.id,
                    type: 'task-view' as const,
                    taskView,
                };
            }

            return null;
        }

        return R.uniq(items).map(mapIdToItem).filter(removeNullable);
    }, [items, indexedPages, indexedCollections, indexedTasks, indexedPageViews, indexedTaskViews]);

    const closeSidebar = React.useCallback(() => {
        setInterfaceSettings({ fixedSidebar: false });
    }, [setInterfaceSettings]);

    const onCollectionClick = React.useCallback(
        (collectionId: string, event: React.MouseEvent) => {
            track('open-item-from-quick-access');
            goToCollection(collectionId, event);

            if (isMobile) {
                closeSidebar();
            }
        },
        [isMobile, closeSidebar, goToCollection],
    );

    const onDrop = React.useCallback(
        (item: SidebarItemDroppable, dropArea: DndOverArea, targetId?: string) => {
            if (item.id === targetId) return;
            if (!user) return;
            if (!targetId) {
                addItem(space, item.id, user.id);
                return;
            }
            moveItem(space, item.id, { targetId, position: dropArea === 'bottom' ? 'after' : 'before' }, user.id);
        },
        [space, user, addItem, moveItem],
    );

    return (
        <div data-testid={`sidebar-${title.toLowerCase()}`} className="pt-1">
            <Button.Plain widthFull onClick={() => setIsExpanded(!isExpanded)}>
                <div className="pl-2 px-1 py-0.5">
                    <div className="h-6 flex items-center z-10 text-left font-medium overflow-x-visible">
                        <div className="text-saga-gray-500 text-sm">{t(`sidebar.${title.toLowerCase()}`)}</div>
                    </div>
                </div>
            </Button.Plain>

            <SidebarExpandableMenu.OpenCloseAnimation isOpen={isExpanded}>
                <SidebarExpandableMenu.Gap>
                    <div className="relative space-y-0.5">
                        {canEdit && (
                            <SidebarItemButton.Draggable
                                id="top-drop-area"
                                enabled={true}
                                dndType={dndTypes.SIDEBAR_PAGE}
                                onDrop={(item: SidebarItemDroppable) => onDrop(item, 'top', itemsList[0]?.id)}
                            >
                                <div className="h-1" />
                            </SidebarItemButton.Draggable>
                        )}
                        <SidebarItemsList
                            items={itemsList}
                            selectedId={selectedId}
                            canEdit={canEdit}
                            onCollectionClick={onCollectionClick}
                            onDrop={onDrop}
                            openPage={openPage}
                            openLocation={openLocation}
                            closeSidebar={closeSidebar}
                            isMobile={isMobile}
                            indexedCollections={indexedCollections}
                            pages={pages}
                            tasks={tasks}
                            space={space}
                        />
                        {canEdit && (
                            <SidebarItemButton.Draggable
                                id="bottom-drop-area"
                                enabled={true}
                                dndType={dndTypes.SIDEBAR_PAGE}
                                onDrop={(item: SidebarItemDroppable) =>
                                    onDrop(item, 'bottom', itemsList[itemsList.length - 1]?.id)
                                }
                            >
                                <div className="h-1" />
                            </SidebarItemButton.Draggable>
                        )}
                    </div>
                </SidebarExpandableMenu.Gap>
            </SidebarExpandableMenu.OpenCloseAnimation>
        </div>
    );
}
