import { BlockType, PatternType } from '.';

// Increment this to force the users to reload the client on deploy
export const CODE_VERSION = '2.5';

export const PAGE_SCHEMA_VERSION = '2021.5';

export const PUBLIC_TOKEN = 'public';

type Patterns = {
    marks: { [key in PatternType]: RegExp };
    link: RegExp;
    email: RegExp;
};

export const PATTERNS: Patterns = {
    marks: {
        bold: /\*\*(.+?)\*\*/g,
        underline: /__(.+?)__/g,
        italic: /\b_([^_].+?)_/g,
        code: /`(.+?)`/g,
    },
    // Source https://urlregex.com/
    link: /\b(?:https?:\/\/|www\.)[^\s/$.?#].[^\s]*/gi,
    email: /\b[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+\b/g,
};

export type Shortcut =
    | typeof BlockType.TITLE
    | typeof BlockType.BULLET_LIST_ITEM
    | typeof BlockType.NUMBER_LIST_ITEM
    | typeof BlockType.CHECK_LIST_ITEM
    | typeof BlockType.QUOTE
    | typeof BlockType.HEADING_1
    | typeof BlockType.HEADING_2
    | typeof BlockType.HEADING_3
    | typeof BlockType.DIVIDER;

export const SHORTCUTS: { [index: string]: Shortcut } = {
    '*': BlockType.BULLET_LIST_ITEM,
    '-': BlockType.BULLET_LIST_ITEM,
    '+': BlockType.BULLET_LIST_ITEM,
    '1.': BlockType.NUMBER_LIST_ITEM,
    '[]': BlockType.CHECK_LIST_ITEM,
    '[x]': BlockType.CHECK_LIST_ITEM,
    '>': BlockType.QUOTE,
    '#': BlockType.HEADING_1,
    '##': BlockType.HEADING_2,
    '###': BlockType.HEADING_3,
    '---': BlockType.DIVIDER,
};

export const DEFAULT_FONT = 'system-ui';
export const EDITOR_FONTS: string[] = ['Roboto', 'Noto Serif', 'Lora', 'Roboto Mono', 'Inter'];
export const INTERFACE_FONTS: string[] = ['Roboto', 'Inter'];
