import React from 'react';
import { useCurrentWorkspace } from '../WorkspaceContext';
import Modal, { ModalLayout } from '../Modal';
import { Check } from 'react-feather';
import { useTranslation } from 'react-i18next';

export function DowngradeToFreePlanModal({ onClose, onConfirm }: { onClose: () => void; onConfirm: () => void }) {
    const { members } = useCurrentWorkspace();
    const { t } = useTranslation();
    const isPossibleToDowngrade = members.length <= 5;

    return (
        <Modal.Medium isOpen={true}>
            <ModalLayout
                title="Are you sure about downgrading the plan to Free?"
                primaryButtonLabel={t('billing.downgrade_to_free_modal.primary_button')}
                primaryButtonAction={onClose}
                secondaryButtonLabel={t('billing.downgrade_to_free_modal.secondary_button')}
                secondaryButtonAction={onConfirm}
                onCloseAction={onClose}
            >
                {!isPossibleToDowngrade && (
                    <p
                        className="mb-6"
                        dangerouslySetInnerHTML={{
                            __html: t('billing.downgrade_to_free_modal.description', {
                                membersCount: members.length,
                                membersLooseAccess: members.length - 5,
                                allowedMembers: 5,
                            }),
                        }}
                    />
                )}
                <p className="mb-2 font-bold">{t('billing.downgrade_to_free_modal.features_title')}</p>
                <ul className="columns-2 space-y-2 list-none text-sm">
                    <li className="flex items-center p-0">
                        <Check width="14" height="14" className="mr-2" />
                        {t('billing.downgrade_to_free_modal.feature_1')}
                    </li>
                    <li className="flex items-center p-0">
                        <Check width="14" height="14" className="mr-2" />
                        {t('billing.downgrade_to_free_modal.feature_2')}
                    </li>
                    <li className="flex items-center p-0">
                        <Check width="14" height="14" className="mr-2" />
                        {t('billing.downgrade_to_free_modal.feature_3')}
                    </li>

                    <li className="flex items-center p-0">
                        <Check width="14" height="14" className="mr-2" />
                        {t('billing.downgrade_to_free_modal.feature_4')}
                    </li>
                </ul>
            </ModalLayout>
        </Modal.Medium>
    );
}
