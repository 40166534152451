import {
    BlockBuilder,
    BlockContainerChildren,
    BlockType,
    Inline,
    SagaElement,
    SagaText,
    isAnyBlockItem,
    isIndentContainer,
    isInline,
    isParagraph,
    isSagaText,
    isTableCell,
    isTableRow,
} from '..';
import { createXML } from './createXML';

type XMLTag = (typeof BlockType)[keyof typeof BlockType] | 'text' | 'parsererror' | 'div' | 'h3';
export function xmlToSaga(xmlString: string): (SagaElement | SagaText)[] | null {
    const xml = createXML(xmlString.replace(/&/g, '&amp;'));

    if (!xml) {
        return null;
    }

    const elements = Array.from(xml.firstElementChild?.children ?? [])
        .map(blockFromXmlTag)
        .flatMap((e) => (e ? (Array.isArray(e) ? e : [e]) : []));

    return elements;
}

function blockFromXmlTag(node: Element): SagaElement | SagaText | (SagaElement | SagaText)[] | null {
    const children = Array.from(node.children).flatMap((e) => {
        if (e) {
            const block = blockFromXmlTag(e);

            return block ? (Array.isArray(block) ? block : [block]) : [];
        } else {
            return [];
        }
    });

    const inlineChildren = children.flatMap((e) => {
        if (isInline(e)) {
            return [e];
        }
        if (isParagraph(e)) {
            return e.children;
        }

        return [];
    });
    const blockChildren = children.flatMap((e) => (isAnyBlockItem(e) ? [e] : []));

    const nonTextChildren = children.flatMap((e) => (!isSagaText(e) ? [e] : []));

    const tag: XMLTag = node.tagName as XMLTag;

    const nodeContent = revertXMLSymbols(node.textContent || '');
    const inlineChildrenOrText = toBuilderInput(inlineChildren, nodeContent);

    switch (tag) {
        case 'parsererror':
            return null;
        case 'text':
            const bold = node.getAttribute('bold') === 'true';
            const italic = node.getAttribute('italic') === 'true';
            const underline = node.getAttribute('underline') === 'true';
            const code = node.getAttribute('code') === 'true';
            const highlight = node.getAttribute('highlight') === 'true';

            return children.length
                ? children
                : BlockBuilder.text(nodeContent, { bold, italic, underline, code, highlight });
        case 'title':
            return inlineChildrenOrText ? BlockBuilder.title(inlineChildrenOrText) : null;
        case 'date-block':
            const date = node.getAttribute('date');
            return date ? BlockBuilder.dateBlock(date) : BlockBuilder.text(nodeContent);
        case 'mention':
            const memberId = node.getAttribute('memberId');
            return memberId ? BlockBuilder.mention(memberId, nodeContent) : null;
        case 'link':
            const linkURL = node.getAttribute('url');
            return linkURL ? BlockBuilder.link(linkURL, nodeContent) : null;
        case 'inline-page-link':
            const pageId = node.getAttribute('pageId');
            return pageId
                ? BlockBuilder.inlinePageLink(pageId, pageId, {
                      title: nodeContent,
                      isTemplate: undefined,
                      icon: undefined,
                  })
                : null;
        case 'paragraph':
            return inlineChildrenOrText ? BlockBuilder.paragraph(inlineChildrenOrText) : null;
        case 'block-container':
            if (children.length === 2) {
                const [first, second] = children;
                if (first && second) {
                    return BlockBuilder.blockContainer([first, second] as BlockContainerChildren);
                }
            } else if (children.length === 1 && isIndentContainer(children[0])) {
                return BlockBuilder.indent(children[0].children);
            }

        case 'indent-container':
            return blockChildren.length
                ? BlockBuilder.indent(blockChildren)
                : children.length === 1 && isIndentContainer(children[0])
                  ? BlockBuilder.indent(children[0].children)
                  : null;
        case 'number-list':
            return children.length ? BlockBuilder.numberedList(nonTextChildren) : null;
        case 'numbered-list-item':
            return inlineChildrenOrText ? BlockBuilder.numberedListItem(inlineChildrenOrText) : null;
        case 'list-item':
            return inlineChildrenOrText ? BlockBuilder.listItem(inlineChildrenOrText) : null;
        case 'check-list-item':
            const isChecked = node.getAttribute('checked') === 'true' || false;
            return inlineChildrenOrText ? BlockBuilder.checkListItem(inlineChildrenOrText, isChecked) : null;
        case 'callout':
            return nonTextChildren.length ? BlockBuilder.callout(nonTextChildren) : null;
        case 'code':
            const content = node.getAttribute('content');
            const language = node.getAttribute('language');
            return content ? BlockBuilder.code(content, language ?? 'html') : null;
        case 'divider':
            return BlockBuilder.divider();
        case 'embed':
            const embedURL = node.getAttribute('url');
            return embedURL ? BlockBuilder.paragraph([BlockBuilder.link(embedURL, embedURL)]) : null;
        case 'google-drive-link':
            const id = node.getAttribute('id');
            const webViewLink = node.getAttribute('webViewLink');
            const iconLink = node.getAttribute('iconLink');
            const name = node.getAttribute('name');

            return id && webViewLink && iconLink && name
                ? BlockBuilder.googleDriveLink([BlockBuilder.text(name)], {
                      type: 'loaded',
                      file: {
                          name,
                          webViewLink,
                          iconLink,
                          id,
                      },
                  })
                : null;
        case 'heading-one':
        case 'heading-two':
        case 'heading-three':
            return inlineChildrenOrText ? BlockBuilder.heading(inlineChildrenOrText, tag) : null;
        case 'image':
            const imageURL = node.getAttribute('url');
            const align = node.getAttribute('align');
            const width = Number(node.getAttribute('width'));
            const height = Number(node.getAttribute('height'));
            const ratio = Number(node.getAttribute('ratio'));

            if (!imageURL) return null;

            const block = BlockBuilder.image(imageURL ?? '');

            if (width && height) {
                block.size = [width, height];
            }

            if (align) {
                block.align = align;
            }

            if (ratio) {
                block.ratio = ratio;
            }

            return block;
        case 'block-quote':
            return inlineChildrenOrText ? BlockBuilder.blockquote(inlineChildrenOrText) : null;
        case 'inline-collection':
            const collectionId = node.getAttribute('collectionId');
            return collectionId ? BlockBuilder.inlineCollection(collectionId) : null;
        case 'katex':
            const katexValue = node.getAttribute('value');
            return katexValue ? BlockBuilder.katexBlock(katexValue) : null;
        case 'katex-inline':
            const inlineKatexValue = node.getAttribute('value');
            return inlineKatexValue ? BlockBuilder.katexInline(inlineKatexValue) : null;
        case 'linear-issue':
            const linearIdentifier = node.getAttribute('identifier');
            const linearUrl = node.getAttribute('url');
            const linearTitle = node.getAttribute('name');
            const linearId = node.getAttribute('id');

            return linearIdentifier && linearUrl && linearTitle && linearId
                ? BlockBuilder.linearIssue([BlockBuilder.text(linearTitle)], {
                      type: 'loaded',
                      issue: {
                          id: linearId,
                          url: linearUrl,
                          title: linearTitle,
                          identifier: linearIdentifier,
                      },
                  })
                : null;
        case 'live-reference-source':
        case 'reference':
            const liveSourceId = node.getAttribute('id');

            if (tag === 'reference') {
                return liveSourceId ? BlockBuilder.liveBlock(liveSourceId) : null;
            } else {
                return liveSourceId && blockChildren.length
                    ? BlockBuilder.liveBlockSource(liveSourceId, blockChildren)
                    : null;
            }
        case 'pretty-link':
            const prettyUrl = node.getAttribute('url');
            return prettyUrl ? BlockBuilder.paragraph([BlockBuilder.link(prettyUrl, prettyUrl)]) : null;
        case 'table':
            const tableRowChildren = children.flatMap((e) => (isTableRow(e) ? [e] : []));
            return tableRowChildren.length ? BlockBuilder.tableByChildren(tableRowChildren) : null;
        case 'table-row':
            const tableCellChildren = children.flatMap((e) => (isTableCell(e) ? [e] : []));
            return tableCellChildren.length ? BlockBuilder.tableRow(tableCellChildren) : null;
        case 'table-cell':
            if (blockChildren.length) return BlockBuilder.tableCell(blockChildren);
            if (inlineChildrenOrText) return BlockBuilder.tableCell([BlockBuilder.paragraph(inlineChildrenOrText)]);
            break;
        case 'task-block':
            const taskId = node.getAttribute('id');
            if (blockChildren.length) return BlockBuilder.paragraph(blockChildren as unknown as Inline[]);
            if (taskId) return BlockBuilder.taskBlock(taskId);
            if (inlineChildrenOrText) return BlockBuilder.checkListItem(inlineChildrenOrText);
            break;
        case 'file':
            const fileUrl = node.getAttribute('url');
            const size = node.getAttribute('size');
            const title = node.getAttribute('title');

            if (!fileUrl) return null;

            const fileBlock = BlockBuilder.file(fileUrl);

            fileBlock.title = title ?? undefined;
            fileBlock.size = size ? Number(size) : undefined;

            return fileBlock;
        default:
            return children.length ? children : null;
    }

    return null;
}

function toBuilderInput<C extends SagaElement | SagaText>(children: C[], text: string): C[] | string | null {
    const trimString = (text: string) =>
        text
            .replace(/\n+$/, '')
            .replace(/^\n+/, '')
            .replace(/\s{2,}$/, '')
            .replace(/^\s{2,}/, '');

    if (children.length) {
        return children.map((element) =>
            isSagaText(element) ? { ...element, text: trimString(element.text) } : element,
        );
    }

    if (text.length) {
        return trimString(text);
    }

    return null;
}

export function escapeXMLSymbols(unsafe: any) {
    return unsafe
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;')
        .replace(/'/g, '&apos;');
}

export function revertXMLSymbols(unsafe?: any) {
    if (!unsafe) return unsafe;

    return unsafe
        .replace(/&amp;/g, '&')
        .replace(/&lt;/g, '<')
        .replace(/&gt;/g, '>')
        .replace(/&quot;/g, '"')
        .replace(/&apos;/g, "'");
}
