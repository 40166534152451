import React from 'react';

type GripIconProps = {
    className?: string;
};

export function GripIcon({ className }: GripIconProps) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className={className}
            style={{ opacity: '0.333' }}
        >
            <rect x="6" y="5" width="12" height="0.2" rx="1" />
            <rect x="6" y="12" width="12" height="0.2" rx="1" />
            <rect x="6" y="19" width="12" height="0.2" rx="1" />
        </svg>
    );
}
