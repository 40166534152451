import * as R from 'ramda';
import {
    BlockBuilder,
    isTableRow,
    isTableCell,
    isInline,
    isParagraph,
    SagaText,
    SagaElement,
    isImage,
    isBlockContainer,
    isNumberedList,
    AnyBlockItem,
    isSagaText,
    isAnyListItem,
    BlockType,
    isSagaElement,
    isNumberedListItem,
    isCheckListItem,
    isLiveBlockSource,
    isTable,
    isCallout,
    isBlockType,
    SimpleElement,
    VoidElement,
} from '..';
import { MdastNode } from './types';

export const codeLanguages: Record<string, string> = {
    js: 'javascript',
};

function trimTextChild(node: SagaText) {
    return { ...node, text: node.text.trim() };
}

type ListItemOpts = { checked?: boolean; ordered?: boolean };

const toListItemNode = (children: (SagaElement | SagaText)[], opts?: { checked?: boolean; ordered?: boolean }) => {
    let listItemChildren = children
        .map((child) => {
            if (isParagraph(child)) {
                return child.children;
            }

            return child;
        })
        .flat()
        .filter(isInline);

    if (opts?.checked != null) {
        return BlockBuilder.checkListItem(listItemChildren, opts.checked);
    }

    if (opts?.ordered) {
        return BlockBuilder.numberedListItem(listItemChildren);
    }

    return BlockBuilder.listItem(listItemChildren);
};

function toListItem(node: SagaElement | SagaText, opts?: ListItemOpts): AnyBlockItem {
    if (isImage(node) || isBlockContainer(node) || isNumberedList(node)) {
        return node;
    } else if (isSagaText(node)) {
        return toListItemNode([trimTextChild(node)], opts);
    } else {
        return toListItemNode(node.children, opts);
    }
}

function shouldIndentNode(node: SagaElement | SagaText) {
    return isAnyListItem(node) || isNumberedList(node);
}

export function transformAndIndentListItemChildren(nodes: (SagaElement | SagaText)[], opts?: ListItemOpts) {
    const result = [];

    // This displays a list item with nested paragraphs
    if (nodes.length >= 2 && isParagraph(nodes[0])) {
        const firstNode = nodes[0];
        const restNodes = nodes.slice(1);

        // If the rest are paragraphs, they should be indented under the list item
        if (restNodes.some(isParagraph)) {
            const listItem = toListItem(firstNode, opts);

            // Convert rest nodes to appropriate block items before creating the indent
            const indentChildren = restNodes
                .map((node) => {
                    if (isParagraph(node)) {
                        return node; // Paragraphs are already block items
                    } else if (isSagaText(node)) {
                        return BlockBuilder.paragraph([node]); // Convert text to paragraph
                    } else if (isSagaElement(node)) {
                        return node; // SagaElements should be block items
                    } else {
                        // If it's not a recognized type, wrap it in a paragraph
                        return BlockBuilder.paragraph([BlockBuilder.text(String(node))]);
                    }
                })
                .filter(Boolean) as AnyBlockItem[];

            if (indentChildren.length > 0) {
                const indent = BlockBuilder.indent(indentChildren);
                const container = BlockBuilder.blockContainer([listItem as SimpleElement | VoidElement, indent]);
                return [container];
            }
        }
    }

    for (let i = 0; i < nodes.length; i++) {
        const node = nodes[i];
        const nextNode = nodes[i + 1];

        if (nextNode != null) {
            const shouldIndent =
                shouldIndentNode(nextNode) || (isBlockContainer(nextNode) && shouldIndentNode(nextNode.children[0]));

            if (shouldIndent) {
                const firstChild = toListItem(node, opts);
                if (
                    !isBlockType(firstChild, [
                        isBlockContainer,
                        isNumberedList,
                        isLiveBlockSource,
                        isTable,
                        isTableCell,
                        isTableRow,
                        isCallout,
                    ])
                ) {
                    const indent = BlockBuilder.indent(
                        nodes.slice(i + 1).map((node) => {
                            if (isCheckListItem(node)) {
                                return toListItem(node, { checked: node.checked });
                            }

                            return toListItem(node, opts);
                        }),
                    );
                    const parent = BlockBuilder.blockContainer([firstChild, indent]);
                    result.push(parent);

                    // we can break the loop here because we will transform the rest of the nodes as the children of the indent block
                    break;
                }
            }
        }

        result.push(toListItem(node, opts));
    }

    return result;
}

export default function deserialize(
    node: MdastNode,
    opts?: { transform?: <N extends SagaElement | SagaText>(node: N) => N },
): (SagaElement | SagaText) | (SagaElement | SagaText)[] {
    let children: (SagaElement | SagaText)[] = [];

    if (node.children && Array.isArray(node.children) && node.children.length > 0) {
        children = R.flatten(
            node.children.map((c: MdastNode) =>
                deserialize(
                    {
                        ...c,
                        ordered: node.ordered || false,
                    },
                    opts,
                ),
            ),
        );
    }

    function getNode(): (SagaElement | SagaText) | (SagaElement | SagaText)[] {
        switch (node.type) {
            case 'heading': {
                const inlineChildren = children.filter(isInline);
                const depthMap = {
                    1: BlockType.HEADING_1,
                    2: BlockType.HEADING_2,
                    3: BlockType.HEADING_3,
                    4: BlockType.HEADING_3,
                    5: BlockType.HEADING_3,
                    6: BlockType.HEADING_3,
                };

                return BlockBuilder.heading(inlineChildren, depthMap[node.depth ?? 1] ?? BlockType.HEADING_1);
            }
            case 'image': {
                let image = BlockBuilder.image(node.url ?? '');

                if (node.alt) {
                    image.alt = node.alt;
                }

                if (node.title) {
                    image.title = node.title;
                }

                return image;
            }
            case 'tableCell': {
                const grouped = R.groupWith(isInline, children);
                const tableCellChildren = grouped
                    .map((children) => {
                        if (children.every(isInline)) {
                            return BlockBuilder.paragraph(children as any[]);
                        }

                        return children;
                    })
                    .flat();
                return BlockBuilder.tableCell(tableCellChildren as any[]);
            }
            case 'tableRow': {
                const tableCells = children.filter(isTableCell);
                return BlockBuilder.tableRow(tableCells);
            }
            case 'table': {
                return BlockBuilder.tableByChildren(children.filter(isTableRow));
            }
            case 'list':
                // in case we have some images in the list, we need to chunk the list items into separate lists (which is important for numbered lists)
                if (children.some(isImage)) {
                    return R.groupWith((a, b) => isSagaElement(a) && isSagaElement(b) && a.type === b.type, children)
                        .map((chunk) => {
                            if (chunk.every(isNumberedListItem)) {
                                return [BlockBuilder.numberedList(chunk)];
                            }
                            return chunk;
                        })
                        .flat();
                }

                const isOrdered = children.some(isNumberedListItem);

                if (isOrdered) {
                    return BlockBuilder.numberedList(children.filter(isSagaElement));
                } else {
                    return children;
                }
            case 'listItem': {
                if (children.every(isImage)) {
                    return children;
                }
                return transformAndIndentListItemChildren(children, node);
            }
            case 'paragraph': {
                const allInline = children.every(isInline);
                if (allInline) {
                    const inlineChildren = children.filter(isInline);
                    return BlockBuilder.paragraph(inlineChildren);
                }
                return children;
            }
            case 'break': {
                return BlockBuilder.breakParagraph();
            }
            case 'link': {
                // TODO we currently don't support image links, for now we put the link url inside of the image alt
                if (children.find(isImage)) {
                    const image = children.find(isImage);
                    if (image) {
                        return BlockBuilder.image(image.url ?? '');
                    }
                }

                const textChildren = children.filter(isSagaText);

                return BlockBuilder.link(
                    node.url ?? '',
                    textChildren.length === 0 ? [BlockBuilder.text('')] : textChildren,
                );
            }
            case 'blockquote': {
                const text = R.pathOr('', [0, 'text'], children);
                if (!text) {
                    const paragraph = children.find(isParagraph);
                    if (paragraph) {
                        const text = R.pathOr('-', ['children', 0, 'text'], paragraph);
                        return BlockBuilder.blockquote([BlockBuilder.text(text)]);
                    }
                }
                return BlockBuilder.blockquote([BlockBuilder.text(text)]);
            }
            case 'code':
                return BlockBuilder.code(
                    (node.value as string) ?? '',
                    node.lang ? codeLanguages[node.lang] ?? 'html' : 'html',
                );
            case 'emphasis': {
                const textChild = R.pathOr(BlockBuilder.text(''), [0], children);
                return {
                    italic: true,
                    ...textChild,
                };
            }
            case 'strong': {
                const textChild = R.pathOr(BlockBuilder.text(''), [0], children);

                return {
                    bold: true,
                    ...textChild,
                };
            }
            case 'inlineCode': {
                const textChild = BlockBuilder.text((node.value as string) || '');

                return {
                    code: true,
                    ...textChild,
                };
            }
            case 'text':
                return BlockBuilder.text((node.value as string) || '');

            case 'thematicBreak':
                return BlockBuilder.divider();

            case 'callout':
                const content = R.flatten((node.value as MdastNode[]).map((v) => deserialize(v))) as SagaElement[];

                return BlockBuilder.callout(content);

            default:
                return children;
        }
    }

    let resultNode = getNode();

    if (Array.isArray(resultNode)) {
        resultNode = R.flatten(resultNode);
    }

    if (opts?.transform) {
        if (Array.isArray(resultNode)) {
            resultNode = resultNode.map(opts.transform);
        } else if (!isSagaText(resultNode)) {
            resultNode = opts.transform(resultNode);
        }
    }

    return resultNode;
}
