import React from 'react';
import {
    User,
    GoogleAuthProvider,
    isSignInWithEmailLink,
    signInWithCredential,
    signInWithEmailLink,
} from 'firebase/auth';
import * as Sentry from '@sentry/browser';
import { auth } from '@/firebase';
import { identifyUser } from '@/analytics';

type FirebaseContextType = {
    firebaseUser: User | null;
    isLoading: boolean;
};

export const FirebaseContext = React.createContext<FirebaseContextType>({
    firebaseUser: null,
    isLoading: false,
});

export function useFirebaseContext() {
    return React.useContext(FirebaseContext);
}

const isSignInWithCredentials = (s: string) => {
    return s.includes('oauthIdToken');
};

export const FirebaseContextProvider: React.FC = ({ children }) => {
    const [{ firebaseUser, isLoading }, setFirebaseAuth] = React.useState<{
        firebaseUser: User | null;
        isLoading: boolean;
    }>({
        firebaseUser: null,
        isLoading: true,
    });
    const [isSignIn, setIsSignIn] = React.useState(true);

    React.useEffect(() => {
        const checkEmailLink = () => {
            //@ts-expect-error
            window.signOut = () => auth.signOut();

            if (isSignInWithEmailLink(auth, window.location.href)) {
                setIsSignIn(true);
                // Additional state parameters can also be passed via URL.
                // This can be used to continue the user's intended action before triggering
                // the sign-in operation.
                // Get the email if available. This should be available if the user completes
                // the flow on the same device where they started it.
                let email = window.localStorage.getItem('emailForSignIn');

                if (!email) {
                    // User opened the link on a different device. To prevent session fixation
                    // attacks, ask the user to provide the associated email again.
                    // TODO: prompt is not supported and the desktop app
                    email = window.prompt('Please enter your email:');

                    if (email == null) {
                        window.location.href = '/login?invalid_link=true';
                        return;
                    }
                }

                // The client SDK will parse the code from the link for you.
                signInWithEmailLink(auth, email, window.location.href)
                    .then(function () {
                        window.localStorage.removeItem('emailForSignIn');

                        auth.onAuthStateChanged((user) => {
                            if (user) {
                                Sentry.setUser({ id: user.uid });
                                if (user.email) {
                                    identifyUser(user.uid, user.email);
                                }
                            }
                            setFirebaseAuth({
                                firebaseUser: user,
                                isLoading: false,
                            });
                            setIsSignIn(false);
                        });
                    })
                    .catch((error) => {
                        Sentry.captureException(error);

                        window.location.href = '/login?invalid_link=true';
                    });
            } else if (isSignInWithCredentials(window.location.href)) {
                const urlParams = new URLSearchParams(window.location.search);

                const credential = GoogleAuthProvider.credential(urlParams.get('oauthIdToken'));

                signInWithCredential(auth, credential);

                auth.onAuthStateChanged((user) => {
                    if (user) {
                        Sentry.setUser({ id: user.uid });
                        if (user.email) {
                            identifyUser(user.uid, user.email);
                        }
                    }
                    setFirebaseAuth({
                        firebaseUser: user,
                        isLoading: false,
                    });
                    setIsSignIn(false);
                });
            } else {
                auth.onAuthStateChanged((user) => {
                    if (user) {
                        Sentry.setUser({ id: user.uid });
                        if (user.email) {
                            identifyUser(user.uid, user.email);
                        }
                    }
                    setFirebaseAuth({
                        firebaseUser: user,
                        isLoading: false,
                    });
                    setIsSignIn(false);
                });
            }
        };

        checkEmailLink();
    }, []);

    return (
        <FirebaseContext.Provider
            value={{
                firebaseUser,
                isLoading: isLoading || isSignIn,
            }}
        >
            {children}
        </FirebaseContext.Provider>
    );
};
