import React from 'react';
import { Collection, SagaEditor, isInlineCollection } from '@saga/shared';
import { useSelected } from 'slate-react';
import * as R from 'ramda';
import { useOpenCollection } from '@/components/PageNavigationProvider';
import { collectionToString, InlineCollectionElement } from '../InlineCollection';

export function useInlineCollectionPlugin(collections: Collection[]) {
    return React.useMemo(() => {
        const collectionIndex = R.indexBy(R.prop('id'), collections);
        return SagaEditor.Plugins.createBlockPlugin({
            match: isInlineCollection,
            Component(props) {
                const openCollection = useOpenCollection();
                const selected = useSelected();
                const collection = collectionIndex[props.element.collectionId];
                return (
                    <InlineCollectionElement
                        {...props}
                        onClick={(event) => collection && openCollection(props.element.collectionId, event)}
                        collection={collection}
                        selected={selected}
                    />
                );
            },
            stringify: (element) => collectionToString(collectionIndex[element.collectionId]),
        });
    }, [collections]);
}
