import { track } from '@/analytics';
import { Lightning } from '@/assets/icons';
import { TodesktopLink } from '@/components/Desktop';
import AISourcesPopover from '@/components/editor/ai/AISourcesPopover';
import Spinner from '@/components/loading/Spinner';
import Tooltip from '@/components/popover/Tooltip';
import BasicButton from '@/components/styled/BasicButton';
import { AIError, AIStatus } from '@/hooks/useAIAssist';
import { AISourceItem } from '@saga/shared';
import classNames from 'classnames';
import React from 'react';
import { AlertCircle, CornerDownLeft, RotateCw, StopCircle } from 'react-feather';
import { Trans, useTranslation } from 'react-i18next';

type Props = {
    id: string;
    sources: AISourceItem[];
    text?: string;
    setText: (text: string) => void;
    onSubmit: (input: string) => void;
    onRetry: () => void;
    onCancel: () => void;
    onUpdateSources: (sources: AISourceItem[]) => void;
    onSourcesVisible: (visible: boolean) => void;
    status: AIStatus;
    error?: AIError;
    placeholder: string;
    showIcon?: boolean;
};

const AIPromptInput = ({
    id,
    sources,
    onSubmit,
    onUpdateSources,
    status,
    error,
    text,
    setText,
    onRetry,
    onCancel,
    onSourcesVisible,
    placeholder,
    showIcon = true,
}: Props) => {
    const sourcesRef = React.useRef<HTMLDivElement>(null);
    const textAreaRef = React.useRef<HTMLTextAreaElement>(null);

    const { t } = useTranslation();
    const [sourcesOpen, setSourcesOpen] = React.useState(false);

    const showSources = React.useCallback(
        (show: boolean) => {
            onSourcesVisible(show);
            setSourcesOpen(show);
        },
        [onSourcesVisible],
    );

    const LeftAccessory = React.useCallback(() => {
        switch (status) {
            case 'loading':
                return (
                    <div className="mr-2.5">
                        <Spinner size={14} />
                    </div>
                );
            case 'error':
                return <AlertCircle size={14} className="ml-0.5 stroke-saga-light-red mr-2.5" />;
            default:
                return showIcon ? (
                    <div className="flex flex-col items-start justify-start mr-2.5">
                        <Lightning className="fill-saga-text dark:fill-saga-gray-300 w-[18px] h-[18px]" />
                    </div>
                ) : null;
        }
    }, [status, showIcon]);

    const RightAccessory = React.useCallback(() => {
        switch (status) {
            case 'idle':
            case 'done':
                return (
                    <div className="flex flex-col items-end justify-end">
                        <span
                            onClick={() => (text ? onSubmit(text) : null)}
                            className={classNames('rounded h-8 w-8 flex items-center justify-center ', {
                                'cursor-pointer hover:bg-saga-gray-250 dark:hover:bg-saga-gray-700 bg-white dark:bg-saga-gray-800 shadow-button':
                                    text?.length,
                            })}
                        >
                            <CornerDownLeft
                                size={14}
                                className={classNames('text-saga-gray-500', {
                                    'dark:stroke-white stroke-saga-black': text?.length,
                                })}
                            />
                        </span>
                    </div>
                );

            case 'error':
                return (
                    <BasicButton
                        className="h-8 pr-4 pl-3"
                        size="small"
                        variant="secondary"
                        onClick={() => (text?.length ? onSubmit(text) : onRetry())}
                    >
                        <div className="flex items-center justify-cetner">
                            <RotateCw size={14} className="stroke-saga-black dark:stroke-saga-gray-200 mr-2" />
                            {t('ai.rerun')}
                        </div>
                    </BasicButton>
                );
            case 'loading':
                return (
                    <span
                        onClick={() => {
                            track('click-on-ai-popover-button', { button: 'stop' });
                            onCancel();
                        }}
                    >
                        <div className="h-8 flex items-center justify-center space-x-1.5 px-2 py-1.5 rounded-md border border-saga-gray-200 dark:border-saga-gray-700 cursor-pointer bg-white dark:bg-saga-gray-900">
                            <StopCircle size={14} />
                            <div className="flex items-end justify-end space-x-1">
                                <span className="font-normal text-sm dark:text-white">{t('common.stop')}</span>
                                <span className="font-bold text-[8px] leading-4 text-saga-gray-500">ESC</span>
                            </div>
                        </div>
                    </span>
                );
            default:
                return null;
        }
    }, [status, t, onCancel, onRetry, onSubmit, text]);

    const renderInfoText = () => {
        let translationKey: string | null = null;

        switch (status) {
            case 'loading':
                translationKey = 'ai.generating_dotted';
                break;
            case 'error':
                switch (error) {
                    case 'promptLength':
                        track('too-many-prompt-tokens');
                        translationKey = 'ai.too_many_prompt_tokens';
                        break;
                    case 'rateLimit':
                        track('too-many-requests');
                        translationKey = 'ai.too_many_requests';
                        break;
                    default:
                        track('issue-with-generating');
                        translationKey = 'ai.issue_with_generating';
                }
                break;
            default:
                break;
        }

        return translationKey ? (
            <div
                className={classNames('text-sm py-1.5', {
                    'text-saga-light-red': status === 'error',
                    'text-saga-text dark:text-saga-gray-500': status !== 'error',
                })}
            >
                <Trans
                    i18nKey={translationKey}
                    components={{
                        1: (
                            <TodesktopLink
                                path="https://saga.so/guides/saga-ai-faqs"
                                className="underline cursor-pointer"
                            />
                        ),
                    }}
                />
            </div>
        ) : null;
    };

    const updateTextAreaHeight = React.useCallback((el: HTMLTextAreaElement) => {
        el.style.height = 'inherit';
        el.style.height = `${el.scrollHeight}px`;
    }, []);

    React.useEffect(() => {
        const onResize = () => {
            if (textAreaRef.current) {
                updateTextAreaHeight(textAreaRef.current);
            }
        };

        setTimeout(onResize, 0);
        const textArea = textAreaRef.current;

        const resizeObserver = new ResizeObserver(onResize);
        textArea && resizeObserver.observe(textArea);

        return () => {
            textArea && resizeObserver.unobserve(textArea);
        };
    }, [updateTextAreaHeight, text]);

    return (
        <div
            className={classNames('flex flex-1 justify-between items-center my-1', {
                'flex-row px-2 my-1': status !== 'loading',
                'px-2.5 ': status === 'loading',
            })}
        >
            <LeftAccessory />
            <div className={classNames('flex flex-1')}>
                {renderInfoText()}
                {['done', 'idle'].includes(status) ? (
                    <div className="flex flex-row flex-1">
                        <textarea
                            id={id}
                            rows={1}
                            ref={textAreaRef}
                            className="text-base text-saga-text dark:text-saga-gray-300 bg-transparent placeholder-saga-gray-500 border-none outline-none w-full resize-none my-1 max-h-[50vh]"
                            placeholder={placeholder}
                            onChange={(e) => setText(e.currentTarget.value)}
                            value={text}
                            onKeyDown={(event: any) => {
                                switch (event.key) {
                                    case '@':
                                        showSources(true);
                                        event.preventDefault();
                                        return;
                                    case 'Enter':
                                        if (event.shiftKey) {
                                            return;
                                        }

                                        event.preventDefault();

                                        if (text?.length) {
                                            onSubmit(text);
                                        }

                                        return;
                                }
                            }}
                        />
                        <div
                            className="flex items-center cursor-pointer text-saga-gray-500 mx-2.5"
                            ref={sourcesRef}
                            onClick={() => showSources(!sourcesOpen)}
                        >
                            <Tooltip zIndex={100} content={t('ai.mention')} placement="top">
                                <div>@</div>
                            </Tooltip>

                            <AISourcesPopover
                                attachToRef={sourcesRef}
                                isOpen={sourcesOpen}
                                onClose={() => showSources(false)}
                                onSubmit={({ selectedItem }) => {
                                    onUpdateSources([...sources, selectedItem]);
                                    setTimeout(() => showSources(false), 0);
                                    track('click-on-ai-popover-button');
                                }}
                                selected={sources.map((source) => source.id)}
                            />
                        </div>
                    </div>
                ) : null}
            </div>
            <RightAccessory />
        </div>
    );
};

export default AIPromptInput;
