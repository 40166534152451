import { SafeMapOutput, DecodingError, safeMap } from '../io-ts-js';
import { contentD, SafeDocumentContent, SafeSpace, spaceD, WeakPage } from '../types';
import { ReadonlyNonEmptyArray } from 'fp-ts/lib/ReadonlyNonEmptyArray';
import * as E from 'fp-ts/Either';
import { sequenceS } from 'fp-ts/lib/Apply';
import { pipe } from 'fp-ts/function';
import * as Y from 'yjs';

export function decodeKeysFromSafePage<K extends keyof WeakPage>(
    safePage: SafeMapOutput<WeakPage>,
    keys: ReadonlyNonEmptyArray<K>,
): E.Either<DecodingError, Pick<WeakPage, K>> {
    const [first, ...rest] = keys;

    const partial = rest.reduce(
        (acc, curr) => ({
            ...acc,
            [curr]: safePage.getDecoded(curr),
        }),
        { [first]: safePage.getDecoded(first) },
    );

    // Typescript is not smart enough to resolve the correct type
    // @ts-expect-error
    const partialPage: E.Either<DecodingError, Pick<WeakPage, K>> = pipe(sequenceS(E.Apply)(partial));

    return partialPage;
}

export function safeSpaceFromYMap(map: Y.Map<unknown>): SafeSpace {
    return safeMap({ map, definition: spaceD });
}

export function safeDocumentContentFromYMap(map: Y.Map<unknown>): SafeDocumentContent {
    return safeMap({ map, definition: contentD });
}
