import { PageSuggestion, CollectionSuggestion, CreatePageSuggestion, CreateCollectionSuggestion } from '@/types';
import React from 'react';
import { SuggestionButton } from '../editor/Suggestions';
import { InputDropdown, InputDropdownProps, RenderItemProps } from './Dropdown';
import { PopOver } from './PopOver';

export type PagesCollectionsSuggestions =
    | PageSuggestion
    | CollectionSuggestion
    | CreatePageSuggestion
    | CreateCollectionSuggestion;

export type Props<In extends PagesCollectionsSuggestions, Out extends PagesCollectionsSuggestions> = {
    onSubmit: (data: {
        search: string;
        selectedItem: Out;
        event: React.SyntheticEvent<Element, Event> | Event;
    }) => void;
    getSuggestions(): In[];
    filterBySearch: (suggestions: In[], search: string) => Out[];
    isOpen: boolean;
    onClose: () => void;
    attachToRef: React.MutableRefObject<HTMLElement | null>;
    dropdownProps?: InputDropdownProps<In>['dropdownProps'];
    inputProps?: InputDropdownProps<In>['inputProps'];
    label: string;
};

function LinkToPageDropDown<In extends PagesCollectionsSuggestions, Out extends PagesCollectionsSuggestions>({
    isOpen,
    onClose,
    onSubmit,
    attachToRef,
    getSuggestions,
    filterBySearch: filterSuggestions,
    inputProps,
    dropdownProps,
    label,
}: Props<In, Out>) {
    return (
        <InputDropdown
            attachToRef={attachToRef}
            isOpen={isOpen}
            onClose={onClose}
            onSubmit={onSubmit}
            inputProps={inputProps}
            dropdownProps={dropdownProps}
            withOutPopOver={true}
            renderItems={({ search }) => {
                const suggestionsItems = filterSuggestions(getSuggestions(), search).map((item) => {
                    const render = ({ isSelected, setSelection, submit, index }: RenderItemProps) => {
                        return (
                            <React.Fragment key={index}>
                                {suggestionsItems.length > 1 && search.trim().length >= 0 && index === 0 && (
                                    <>
                                        <PopOver.Label>{label}</PopOver.Label>
                                    </>
                                )}
                                <SuggestionButton
                                    suggestion={item}
                                    selected={isSelected}
                                    onMouseEnter={setSelection}
                                    onClick={submit}
                                    showIcons={true}
                                >
                                    {item.title.trim().length > 0 ? item.title : 'Untitled'}
                                </SuggestionButton>
                            </React.Fragment>
                        );
                    };

                    return {
                        render,
                        item,
                    };
                });

                return suggestionsItems;
            }}
        />
    );
}

export default LinkToPageDropDown;
