import { CollectionIcon, PageIcon } from '@/components/icons';
import { dndTypes } from '@/constants';
import { Item } from '@/types';
import classNames from 'classnames';
import { useDrag, useDrop } from 'react-dnd';
import React from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { VariableSizeList } from 'react-window';

const Table = ({ children, className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
    return (
        <div className={`table ${className ? className : ''}`} {...props}>
            {children}
        </div>
    );
};

Table.Header = function TableHeader({ children, className, ...props }: React.HTMLAttributes<HTMLDivElement>) {
    return (
        <div className={`table-header-group ${className ? className : ''}`} {...props}>
            {children}
        </div>
    );
};

Table.Body = function TableBody({ children, className, ...props }: React.HTMLAttributes<HTMLDivElement>) {
    return (
        <div data-testid="table-body" className={`table-row-group ${className ? className : ''}`} {...props}>
            {children}
        </div>
    );
};

interface DraggableHeaderProps {
    id: string;
    index: number;
    moveHeader: (dragIndex: number, hoverIndex: number) => void;
    onDrop: (item: { id: string; index: number }) => void;
    children: React.ReactNode;
}

Table.DraggableHeader = function DraggableHeader({
    id,
    index,
    moveHeader,
    onDrop,
    children,
}: DraggableHeaderProps): JSX.Element {
    const ref = React.useRef<HTMLDivElement>(null);

    const [{ isDragging }, drag] = useDrag({
        type: dndTypes.TABLE_HEADER,
        item: { id, index },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const [, drop] = useDrop({
        accept: dndTypes.TABLE_HEADER,
        drop() {
            onDrop({ id, index });
        },
        hover(item: { id: string; index: number }, monitor) {
            if (!ref.current) {
                return;
            }

            const dragIndex = item.index;
            const hoverIndex = index;

            if (dragIndex === hoverIndex) {
                return;
            }

            const hoverBoundingRect = ref.current?.getBoundingClientRect();

            const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2;

            const clientOffset = monitor.getClientOffset();
            if (!clientOffset) {
                return;
            }

            const hoverClientX = clientOffset.x - hoverBoundingRect.left;

            if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
                return;
            }

            if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX) {
                return;
            }

            moveHeader(dragIndex, hoverIndex);

            item.index = hoverIndex;
        },
    });

    drag(drop(ref));

    return (
        <div ref={ref} className={`align-middle ${isDragging ? 'opacity-50' : ''}`}>
            {children}
        </div>
    );
};

Table.VirtualizedRow = function TableVirtualizedRow(props: {
    style?: React.CSSProperties;
    renderItem: () => React.ReactNode;
    onHeightChange: (height: number) => void;
}) {
    const ref = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        if (ref.current) {
            props.onHeightChange(ref.current.clientHeight);
        }
    }, [ref, props]);

    return (
        <div style={props.style}>
            <div ref={ref}>
                <div className="flex">
                    <div className="flex-grow sm:px-8">
                        {props.renderItem()}
                        <div className=" bg-saga-gray-150 dark:bg-saga-gray-800 h-[0.5px]" />
                    </div>
                </div>
            </div>
        </div>
    );
};

interface TableVirtualizedProps extends React.HTMLAttributes<HTMLDivElement> {
    itemsCount: number;
    renderHeader: () => React.ReactNode;
    renderItem: (index: number) => React.ReactNode;
}

Table.Virtualized = function TableVirtualized(props: TableVirtualizedProps) {
    const { itemsCount, renderHeader, renderItem, ...rest } = props;

    const rowHeights = React.useRef<{ [key: number]: number }>({});
    const listRef = React.useRef<VariableSizeList>(null);

    const [headerScroll, setHeaderScroll] = React.useState<HTMLDivElement | null>();
    const [listScroll, setListScroll] = React.useState<HTMLDivElement>();
    const [listWidth, setListWidth] = React.useState<number>();

    const getRowHeight = React.useCallback((index: number) => rowHeights.current[index] ?? 50, [rowHeights]);
    const setRowHeight = React.useCallback(
        (index: number, height: number) => {
            if (height !== rowHeights.current[index]) {
                listRef.current?.resetAfterIndex(index);
            }

            rowHeights.current[index] = height;
        },
        [rowHeights],
    );

    const renderItemRef = React.useRef(renderItem);

    React.useEffect(() => {
        renderItemRef.current = renderItem;
        listRef.current?.resetAfterIndex(0);
    }, [renderItem]);

    React.useEffect(() => {
        if (!listScroll || !headerScroll) return;

        const handleListScroll = () => {
            if (!headerScroll) return;
            headerScroll.scrollLeft = listScroll.scrollLeft;
        };

        const handleHeaderScroll = () => {
            if (!listScroll) return;
            listScroll.scrollLeft = headerScroll.scrollLeft;
        };

        listScroll.addEventListener('scroll', handleListScroll);
        headerScroll.addEventListener('scroll', handleHeaderScroll);

        return () => {
            listScroll.removeEventListener('scroll', handleListScroll);
            headerScroll.removeEventListener('scroll', handleHeaderScroll);
        };
    }, [listScroll, headerScroll]);

    React.useEffect(() => {
        if (!listScroll) return;

        const resizeObserver = new ResizeObserver((entries) => {
            for (const entry of entries) {
                setListWidth(entry.contentRect.width);
            }
        });

        resizeObserver.observe(listScroll);

        return () => {
            resizeObserver.disconnect();
        };
    }, [listScroll]);

    const renderRow = React.useCallback(
        ({ index, style }: { index: number; style: React.CSSProperties }) => {
            return (
                <Table.VirtualizedRow
                    style={{ ...style, opacity: rowHeights.current[index] ? 1 : 0 }}
                    renderItem={() => renderItemRef.current(index)}
                    onHeightChange={(height) => setRowHeight(index, height)}
                />
            );
        },
        [setRowHeight, renderItemRef],
    );

    return (
        <div {...rest}>
            <AutoSizer>
                {({ height, width }) => (
                    <>
                        <div
                            className={classNames(
                                'sticky top-0 z-50 bg-white dark:bg-saga-gray-1000 overflow-x-scroll hide-scrollbar sm:px-8 flex overscroll-x-none',
                            )}
                            style={{ width: listWidth }}
                            ref={setHeaderScroll}
                        >
                            <div className="flex-grow">
                                {renderHeader()}
                                <div className="bg-saga-gray-150 dark:bg-saga-gray-800 h-[0.5px]" />
                            </div>
                        </div>
                        <div data-testid="table-body">
                            <VariableSizeList
                                outerRef={setListScroll}
                                ref={listRef}
                                itemCount={itemsCount}
                                itemSize={getRowHeight}
                                height={height - (headerScroll?.clientHeight ?? 0)}
                                width={width}
                                overscanCount={10}
                                className="pb-12"
                            >
                                {renderRow}
                            </VariableSizeList>
                        </div>
                    </>
                )}
            </AutoSizer>
        </div>
    );
};

Table.Row = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(function TableRow(
    { children, className, ...props },
    ref,
) {
    return (
        <div ref={ref} role="row" className={`table-row ${className ? className : ''}`} {...props}>
            {children}
        </div>
    );
});

Table.Cell = function TableCell({ children, className, ...props }: React.HTMLAttributes<HTMLDivElement>) {
    return (
        <div className={`table-cell ${className ? className : ''}`} {...props}>
            {children}
        </div>
    );
};

Table.Button = React.forwardRef<HTMLButtonElement, React.ButtonHTMLAttributes<HTMLButtonElement>>(
    function TableButton(props, ref) {
        return (
            <button
                ref={ref}
                {...props}
                className="flex px-1 w-full text-left rounded hover:bg-saga-gray-200 dark:hover:bg-zinc-200/10 focus:outline-none active:bg-saga-gray-200 dark:active:bg-zinc-200/10 active:shadow-xs min-w-0"
            >
                {props.children}
            </button>
        );
    },
);

Table.ItemButton = function TableItemButton({ item, onClick }: { item: Item; onClick(event: React.MouseEvent): void }) {
    return (
        <Table.Button onClick={onClick}>
            <div className="flex space-x-2 items-center min-w-0">
                <div className="w-4 text-saga-gray-500">
                    {item.type === 'page' && (
                        <div className="flex items-center h-6">
                            <PageIcon icon={item.data.icon} isTemplate={item.data.isTemplate} />
                        </div>
                    )}
                    {item.type === 'shared-page' && (
                        <div className="flex items-center h-6">
                            <PageIcon icon={item.data.icon} isTemplate={false} />
                        </div>
                    )}
                    {item.type === 'collection' && <CollectionIcon icon={item.data.icon} />}
                </div>

                <div className="truncate max-w-[456px]">{item.title}</div>
            </div>
        </Table.Button>
    );
};

Table.EmptyState = function TableEmptyState({ children }: { children: React.ReactNode }) {
    return (
        <div className="flex h-64 w-full justify-center items-center text-saga-text-gray">
            <span>{children}</span>
        </div>
    );
};

export default Table;
