import { useFavorites } from '@/hooks/SpaceHooks';
import { track } from '@/analytics';
import { SpaceOperations } from '@saga/shared';
import { useSpace } from '@/components/SpaceProvider';
import { useUserContext } from '@/components/UserContext';

export const useFavoriteButton = (pageId?: string) => {
    const userFavorites = useFavorites();
    const { space } = useSpace();
    const { user } = useUserContext();

    const isFavorite = pageId ? userFavorites?.some((favorite) => favorite === pageId) : false;

    const toggleFavorite = async () => {
        if (pageId == null) return;
        if (isFavorite) {
            SpaceOperations.removeFavorite(space, pageId, user?.id ?? '');
            track('remove-from-favorites');
        } else {
            SpaceOperations.addFavorite(space, pageId, user?.id ?? '');
            track('add-to-favorites');
        }
    };

    return { isFavorite, toggleFavorite };
};
