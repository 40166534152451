import { AIFunctionName, AIMessage, SafeSpace, SpaceOperations } from '@saga/shared';

export function getPromptText(message: AIMessage) {
    const matches = message.content?.matchAll(/(?:<instructions>)(.+)(?=<\/instructions>)/gs);
    return [...(matches || [])][0]?.[1];
}

export function getReferencedDocuments(toolCalls: AIMessage[], space: SafeSpace): string[] {
    return [
        ...new Set(
            toolCalls
                .flatMap((m) =>
                    m.tool_calls!.flatMap((call) => ({
                        name: call.function.name as AIFunctionName,
                        arguments: call.function.arguments,
                    })),
                )
                .flatMap((call) => {
                    switch (call.name) {
                        case AIFunctionName.getDocumentContext:
                            try {
                                const args = JSON.parse(call.arguments ?? '');
                                const id: string = args.document_id;
                                const name =
                                    SpaceOperations.getPageById(space, id)?.title ||
                                    SpaceOperations.findTask(space, id)?.title;
                                return name ? [name] : [];
                            } catch (e) {
                                return [];
                            }
                        default:
                            return [];
                    }
                }),
        ),
    ];
}
