import React from 'react';
import { useTranslation } from 'react-i18next';
import Tooltip from './Tooltip';
import { PopOver } from './PopOver';
import { Download } from 'react-feather';

interface ExportButtonProps<T> {
    onAfterClick: () => void;
    entity: T;
    exportFunction: (entity: T) => Promise<void>;
    label: string;
    tooltipContent: string;
}

function ExportButton<T>({ onAfterClick, entity, exportFunction, label, tooltipContent }: ExportButtonProps<T>) {
    const { t } = useTranslation();

    function onExport() {
        exportFunction(entity);
    }

    return (
        <Tooltip content={tooltipContent} placement="right">
            <PopOver.RoundedButton
                aria-label={label}
                onClick={() => {
                    onExport();
                    onAfterClick();
                }}
            >
                <Download className="stroke-gray-dark mr-2 my-auto" size={14} />
                {t('common.export')}
            </PopOver.RoundedButton>
        </Tooltip>
    );
}

export default ExportButton;
