import { Collection } from '../types';
import { SafeSpace } from '../types';
import { v4 as uuid } from 'uuid';
import addCollection from './addCollection';

export const newCollection = (title: string): Collection => {
    const id = uuid();

    return {
        id,
        title,
        properties: [],
        sorting: null,
        createdAt: new Date().toString(),
        updatedAt: new Date().toString(),
        subCollections: [],
        icon: undefined,
    };
};

export default function createCollection(space: SafeSpace, title: string): Collection {
    const collection = newCollection(title);
    addCollection(space, collection);
    return collection;
}
