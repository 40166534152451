import React from 'react';
import { SagaEditor } from '@saga/shared';
import { taskBlockPlugin } from '@/components/editor/plugins/Task';
import { mentionBlockPlugin } from '@/components/editor/plugins/Mention';
import { googleDriveLinkBlockPlugin } from '@/components/editor/plugins/GoogleDriveLink';
import { linearIssueBlockPlugin } from '@/components/editor/plugins/LinearIssue';
import { prettyLinkBlockPlugin } from '@/components/editor/plugins/PrettyLink';
import { embedBlockPlugin } from '@/components/editor/plugins/Embed';
import { katexBlockPlugin, katexInlinePlugin } from '@/components/editor/plugins/Katex';
import { calloutPlugin } from '@/components/editor/plugins/Callout';
import { codePlugin } from '@/components/editor/plugins/Code';
import { imageBlockPlugin } from '@/components/editor/plugins/Image';
import { fileBlockPlugin } from '@/components/editor/plugins/File';
import { dateBlockPlugin } from '@/components/editor/plugins/DateBlock';
import { tablePlugins } from '@/components/editor/plugins/Table';
import { dividerPlugin } from '@/components/editor/plugins/Divider';
import { checkListItemPlugin } from '@/components/editor/plugins/CheckListItem';
import { inlinePageLinkBlockPlugin } from '@/components/editor/plugins/InlinePageLink';
import { liveBlockPlugin } from '@/components/editor/plugins/LiveBlock';
import { inlineCollectionPlugin } from '@/components/editor/plugins/InlineCollection';
import { liveBlockSourcePlugin } from '@/components/editor/plugins/LiveBlockSource';
import { linkBlockPlugin } from '@/components/editor/plugins/Link';
import { titleBlockPlugin } from '../editor/plugins/Title';
import { aiSuggestedTextBlockPlugin } from '../editor/plugins/AISuggestedText';
import { useInlineCollectionPlugin } from '@/components/editor/plugins/space/InlineCollection';
import { useInlinePageLinkBlockPlugin } from '@/components/editor/plugins/space/InlinePageLink';
import { spaceTaskBlockPluginPlugin } from '@/components/editor/plugins/space/Task';
import { spaceLiveBlockSourcePlugin } from '@/components/editor/plugins/space/LiveBlockSource';
import { spaceLiveBlockPlugin } from '@/components/editor/plugins/space/LiveBlock';
import { spaceTitlePlugin } from '@/components/editor/plugins/space/Title';
import { spaceImageBlockPlugin } from '@/components/editor/plugins/space/Image';
import { spaceFileBlockPlugin } from '@/components/editor/plugins/space/File';
import { spaceCodePlugin } from '@/components/editor/plugins/space/Code';
import { spaceKatexBlockPlugin } from '@/components/editor/plugins/space/Katex';
import { spacePrettyLinkBlockPlugin } from '@/components/editor/plugins/space/PrettyLink';
import { useCollectionsSnapshot } from '@/hooks/SpaceHooks';

// These are all the basic plugins that should always work without a space
// e.g. public pages rely on this
export const basePlugin = SagaEditor.Plugins.combine(
    titleBlockPlugin,
    aiSuggestedTextBlockPlugin,
    linkBlockPlugin,
    checkListItemPlugin,
    dividerPlugin,
    liveBlockPlugin,
    inlineCollectionPlugin,
    inlinePageLinkBlockPlugin,
    liveBlockSourcePlugin,
    taskBlockPlugin,
    mentionBlockPlugin,
    googleDriveLinkBlockPlugin,
    linearIssueBlockPlugin,
    prettyLinkBlockPlugin,
    embedBlockPlugin,
    katexBlockPlugin,
    katexInlinePlugin,
    calloutPlugin,
    codePlugin,
    imageBlockPlugin,
    fileBlockPlugin,
    dateBlockPlugin,
    tablePlugins,
);

// This hook is used in the respective space plugin providers (public and private)
export function useSpacePlugin() {
    const collections = useCollectionsSnapshot();
    const spaceInlineCollectionPlugin = useInlineCollectionPlugin(collections);
    const spaceInlinePageLinkBlockPlugin = useInlinePageLinkBlockPlugin();

    return React.useMemo(
        () =>
            SagaEditor.Plugins.combine(
                // space related plugins overwrite the base plugins because they come first
                spaceLiveBlockPlugin,
                spaceLiveBlockSourcePlugin,
                spaceTitlePlugin,
                spaceImageBlockPlugin,
                spaceFileBlockPlugin,
                spaceTaskBlockPluginPlugin,
                spaceInlinePageLinkBlockPlugin,
                spaceInlineCollectionPlugin,
                spacePrettyLinkBlockPlugin,
                spaceCodePlugin,
                spaceKatexBlockPlugin,

                // the base plugin is passed last because some of them are overridden
                basePlugin,
            ),
        [spaceInlinePageLinkBlockPlugin, spaceInlineCollectionPlugin],
    );
}
