import React from 'react';
import { PopOver } from '@/components/popover/PopOver';
import ContextMenuButton from '@/components/styled/ContextMenuButton';
import { OpenCollectionSideBySideButton } from './PageContextMenu';
import { DeleteCollectionButton } from './CollectionContextMenu';
import useMobile from '@/hooks/useMobile';
import { useFavoriteButton } from '@/hooks/useFavoriteButton';
import {
    AddToFavoritePageButton,
    AddToPinnedPageButton,
    RemoveFromFavoriteButton,
    RemoveFromPinnedButton,
} from './common';
import * as Popover from '@radix-ui/react-popover';
import { useSpaceAccess } from '@/hooks/useSpaceAccess';
import { usePinnedButton } from '@/hooks/usePinnedButton';
import { track } from '@/analytics';

function CollectionContextMenuButton({
    className,
    align,
    isButtonSmall,
    collectionId,
    label,
}: {
    className?: string;
    label?: string;
    align?: 'start' | 'end' | 'center';
    isButtonSmall?: boolean;
    collectionId: string;
}) {
    const [isOpen, setIsOpen] = React.useState(false);
    const { isFavorite, toggleFavorite } = useFavoriteButton(collectionId);
    const { isPinned, togglePinned } = usePinnedButton(collectionId);
    const isMobile = useMobile();
    const { isOwner, isMember } = useSpaceAccess();

    return (
        <>
            <div className={'flex items-center ' + className}>
                <Popover.Root
                    onOpenChange={(isOpen) => {
                        if (isOpen) track('open-context-menu');
                        else track('close-context-menu');
                        setIsOpen(isOpen);
                    }}
                    open={isOpen}
                >
                    <Popover.Trigger asChild>
                        <ContextMenuButton isButtonSmall={Boolean(isButtonSmall)} label={label} />
                    </Popover.Trigger>
                    <Popover.Portal>
                        <Popover.Content align={align} onOpenAutoFocus={(e) => e.preventDefault()}>
                            <PopOver.PaddedCard>
                                <div data-testid="context-menu" className="text-sm">
                                    {!isMobile && (
                                        <OpenCollectionSideBySideButton
                                            collectionId={collectionId}
                                            onAfterClick={() => setIsOpen(false)}
                                        />
                                    )}
                                    {!isFavorite ? (
                                        <AddToFavoritePageButton
                                            onAfterClick={() => setIsOpen(false)}
                                            onClick={toggleFavorite}
                                        />
                                    ) : (
                                        <RemoveFromFavoriteButton
                                            onAfterClick={() => setIsOpen(false)}
                                            onClick={toggleFavorite}
                                        />
                                    )}
                                    {!isPinned ? (
                                        <AddToPinnedPageButton
                                            onAfterClick={() => setIsOpen(false)}
                                            onClick={togglePinned}
                                        />
                                    ) : (
                                        <RemoveFromPinnedButton
                                            onAfterClick={() => setIsOpen(false)}
                                            onClick={togglePinned}
                                        />
                                    )}
                                    {(isOwner || isMember) && (
                                        <div className="pt-0.5 space-y-0.5">
                                            <PopOver.Divider />
                                            <DeleteCollectionButton collectionId={collectionId} />
                                        </div>
                                    )}
                                </div>
                            </PopOver.PaddedCard>
                        </Popover.Content>
                    </Popover.Portal>
                </Popover.Root>
            </div>
        </>
    );
}

export default CollectionContextMenuButton;
