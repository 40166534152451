const SEPARATOR = '::';

export const DocumentName = {
    build(type: string, id: string, parentId?: string) {
        return parentId ? `${type}${SEPARATOR}${id}${SEPARATOR}${parentId}` : `${type}${SEPARATOR}${id}`;
    },
    parse(value: string) {
        const [type, id, parentId] = value.split(SEPARATOR);
        return { type, id, parentId };
    },
};
