import { getSdk } from '@/graphql';
import { googleDriveFileToSuggestion, linearIssueToSuggestion } from '@/lib/Suggestions';
import * as api from '@saga/api';
import { debounce } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { SuggestionModalSuggestion } from '../editor/Suggestions';

export async function search(query: string, stopTrim?: boolean): Promise<api.SDK.SearchIntegrationsQuery> {
    if (query.trim() === '' && !stopTrim) {
        return { searchIntegrations: { googleDriveFiles: [], linearIssues: [] } };
    }
    const sdk = await getSdk();
    return sdk.SearchIntegrations({ input: { query } });
}

export async function searchIntegrations(
    searchValue: string,
    stopTrim?: boolean,
): Promise<SuggestionModalSuggestion[]> {
    const result = await search(searchValue, stopTrim);
    return [
        ...result.searchIntegrations.googleDriveFiles.map(googleDriveFileToSuggestion),
        ...result.searchIntegrations.linearIssues.map(linearIssueToSuggestion),
    ];
}

export default function useSearchQuery(searchValue: string, skip?: boolean) {
    const [query, setQuery] = useState(searchValue);

    const { data, previousData, loading } = api.useSearchIntegrationsQuery({
        variables: { input: { query } },

        skip: searchValue.trim() === '' || skip,
    });

    const searchResult = data ?? previousData;

    const debounceSearchValue = useMemo(() => debounce(setQuery, 200), []);

    useEffect(() => {
        debounceSearchValue(searchValue);
    }, [debounceSearchValue, searchValue]);

    return { searchResult, loading };
}
