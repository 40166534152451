import * as R from 'ramda';

type ChildProps = { type?: string; text?: string; children?: unknown[] };

export default function mergeSiblings<Child extends ChildProps = ChildProps>(
    shouldMerge: (a: Child, b: Child) => boolean,
    nodes: Child[],
) {
    const chunks = R.groupWith(shouldMerge, nodes);

    return chunks
        .map((chunk) => {
            return chunk.reduce((acc, value) => {
                const result = { ...acc, ...value };
                if (value.children != null) {
                    result.children = [...('children' in acc ? acc.children ?? [] : []), ...value.children];
                }

                if (value.text != null) {
                    result.text = `${acc.text ?? ''}${value.text}`;
                }

                return R.reject((v) => v == undefined, result) as Child;
            }, {} as Child);
        })
        .flat();
}
