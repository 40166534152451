import React from 'react';
import * as api from '@saga/api';

const NotificationsContext = React.createContext({ unreadNotificationsCounter: 0 });

export const useNotifications = () => React.useContext(NotificationsContext);

export default function NotificationsProvider({ children }: { children: React.ReactNode }) {
    const { data } = api.useUnreadNotificationsCounterUpdatedSubscription();

    const notificationsCounterResult = api.useUnreadNotificationsCounterQuery({
        fetchPolicy: 'cache-and-network',
    });

    const unreadNotificationsCounter =
        data?.unreadNotificationsCounterUpdated ?? notificationsCounterResult.data?.unreadNotificationsCounter ?? 0;

    return (
        <NotificationsContext.Provider value={{ unreadNotificationsCounter }}>{children}</NotificationsContext.Provider>
    );
}
