import { useNullableTask, useTaskMap } from '@/components/tasks/hooks';
import { useSideBySideIndex, useSideBySideState } from '@/components/SideBySide';
import { useBlockPlugins } from '@/components/BlockPluginProvider';
import useMobile from '@/hooks/useMobile';
import { useCurrentSearchMap, useSpace } from '@/components/SpaceProvider';
import { useSpaceAccess } from '@/hooks/useSpaceAccess';
import { assertNonNull, SpaceOperations, SagaLocation, WeakTask, RealtimeSagaEditor, unsafeRight } from '@saga/shared';
import classNames from 'classnames';
import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import ReferenceResults from '../editor/ReferenceResults';
import Collaborators from '../navigation/Collaborators';
import HeaderBar, { SearchBar } from '../navigation/HeaderBar';
import TaskContextMenuButton from '../popover/TaskContextMenuButton';
import Tooltip from '../popover/Tooltip';
import { TaskRightPanel } from '../rightpanel/RightPanel';
import { useCurrentWorkspace } from '../WorkspaceContext';
import Button from '../styled/Button';
import SpaceTaskEditor from './SpaceTaskEditor';
import NavigationTable from '../table/NavigationTable';
import DesktopNavigationButtons from '../DesktopNavigationButtons';
import { useDesktopContext } from '../DesktopContext';
import { useTranslation } from 'react-i18next';
import WorkspaceAvatar from '../navigation/WorkspaceAvatar';
import useInterfaceSettings from '@/hooks/useInterfaceSettings';
import { useDeleteTaskIfEmpty } from '@/hooks/deleteTaskHooks';
import { useDocumentYBlocks } from '@/components/RealtimeDocumentProvider';
import EmptyBlocksPlaceholder from '@/components/EmptyBlocksPlaceholder';
import * as Y from 'yjs';
import { Loader } from 'react-feather';
import { useArrowDownSelect } from '@/hooks/useArrowDownSelect';
import { useMountedEditors } from '@/../../shared/src/Editor';
import FavoriteButton from '../styled/FavoriteButton';
import { useFavoriteButton } from '@/hooks/useFavoriteButton';
import { SideBySideSize } from '../SideBySidePanes';
import { useCollectionsSnapshot } from '@/hooks/SpaceHooks';

function TaskFound({
    task,
    location,
    onClose,
    size,
}: {
    task: WeakTask;
    location: SagaLocation.TaskLocation;
    size: SideBySideSize;
    onClose?: () => void;
}) {
    const onCloseHandler = React.useRef(onClose);
    onCloseHandler.current = onClose;
    const blockPlugins = useBlockPlugins();
    const { canEdit } = useSpaceAccess();
    const taskMap = useTaskMap(location.taskId);

    const [rightMenu, setRightMenu] = React.useState<'references' | 'toc' | null>(null);
    const isMobile = useMobile();
    const { useMacOsLayout, isDesktop } = useDesktopContext();
    const [{ fixedSidebar, rightMenu: singleRightMenu }, setInterfaceSettings] = useInterfaceSettings();
    const singleSetRightMenu = (rightMenu: 'references' | 'toc') => setInterfaceSettings({ rightMenu });
    const deleteTaskIfEmpty = useDeleteTaskIfEmpty();

    assertNonNull(taskMap);

    const { data: yBlocks, type } = useDocumentYBlocks(task.id);
    const blocksRef = React.useRef(yBlocks);
    const searchMap = useCurrentSearchMap();
    const cachedBlocks = React.useMemo(
        () => (task ? (searchMap?.get(task.id) as Y.Array<any>) : null),
        [task, searchMap],
    );
    const editorBlocks = React.useMemo(() => yBlocks || cachedBlocks, [yBlocks, cachedBlocks]);

    const { blockId, blockOffset } = location;
    const [focus, setFocus] = React.useState<{ blockId: string; offset?: number } | undefined>(() =>
        blockId ? { blockId } : undefined,
    );

    React.useEffect(() => {
        blocksRef.current = yBlocks;
    }, [yBlocks]);

    React.useEffect(() => {
        setFocus(blockId ? { blockId, offset: blockOffset } : undefined);
    }, [blockId, blockOffset]);

    const windowLocation = useLocation();
    const editors = useMountedEditors();
    const index = useSideBySideIndex();
    const { panes } = useSideBySideState();
    const { isFavorite, toggleFavorite } = useFavoriteButton(task.id);
    const { t } = useTranslation();
    const editorRef = React.useRef<RealtimeSagaEditor | null>(null);
    const container = React.useRef<HTMLDivElement>(null);

    const collections = useCollectionsSnapshot();
    const currentCollections = collections.filter((c) => task.collections?.includes(c.id));

    const hasCollections = React.useMemo(() => currentCollections.length > 0, [currentCollections]);

    useArrowDownSelect({ editorRef, paneIndex: index, editors });

    React.useEffect(() => {
        if (index === 0) {
            const blockId = windowLocation.hash.replace('#', '');
            if (blockId !== '') {
                React.startTransition(() => {
                    setFocus({ blockId });
                    window.location.hash = '';
                });
            }
        }
    }, [windowLocation.hash, index]);

    function useOnTaskDeleted(taskId: string, callback: () => void) {
        const { space } = useSpace();
        const cbRef = React.useRef(callback);
        cbRef.current = callback;

        React.useEffect(() => {
            const yTasks = unsafeRight(space.get('tasks'));

            function checkIfTaskStillExists() {
                const map = SpaceOperations.findTaskMap(space, taskId);

                if (map == null) {
                    cbRef.current();
                }
            }
            yTasks.array.observe(checkIfTaskStillExists);

            return () => {
                yTasks.array.unobserve(checkIfTaskStillExists);
            };
        }, [taskId, space]);
    }

    // in case the task gets deleted, we need to close the pane
    useOnTaskDeleted(location.taskId, () => {
        onCloseHandler.current?.();
    });

    React.useEffect(() => {
        return () => {
            const blocks = blocksRef.current?.toJSON();
            if (blocks) {
                setTimeout(() => deleteTaskIfEmpty({ id: location.taskId, blocks }), 0);
            }
        };
    }, [deleteTaskIfEmpty, blocksRef, location]);

    return (
        <ReferenceResults location={location}>
            <div ref={container} className="flex flex-col h-full w-full items-stretch relative">
                <div className="flex justify-between items-center w-full py-2 pl-2">
                    <div
                        data-testid="task-header-bar"
                        className={classNames('min-w-0 flex flex-row justify-center', {
                            'pl-[3.1rem]': isMobile,
                            'space-x-3': isDesktop,
                            'space-x-1': !isDesktop,
                        })}
                    >
                        {index === 0 && useMacOsLayout && !fixedSidebar && (
                            <div className="pl-[9.5px] mt-[2.5px]">
                                <WorkspaceAvatar.SideBySide />
                            </div>
                        )}
                        <div
                            className={classNames('pt-0.5', {
                                'pl-[4.5px]': !fixedSidebar,
                            })}
                        >
                            {isDesktop && index === 0 && <DesktopNavigationButtons />}
                        </div>

                        <div className="z-50 flex-row flex items-center">
                            <HeaderBar.TaskButton />
                            {!task.archivedAt && !isMobile && (
                                <>
                                    <div
                                        className={classNames('text-saga-gray-350 dark:text-saga-gray-600 ml-2', {
                                            'mr-2': !hasCollections,
                                            'mr-1': hasCollections,
                                        })}
                                    >
                                        /
                                    </div>
                                    <HeaderBar.TaskCollectionsInput maxWidth={size < 50 ? 100 : 400} taskId={task.id} />
                                </>
                            )}
                        </div>
                    </div>

                    <div className="flex flex-grow justify-center">{panes.length === 1 && <SearchBar />}</div>

                    <div className={classNames('flex space-x-1 items-center', { 'pr-1.5': !onClose && !isMobile })}>
                        {!yBlocks && !!cachedBlocks && (
                            <div className="animate-fadeIn">
                                <Loader className="animate-spin" />
                            </div>
                        )}

                        {canEdit && <Collaborators currentLocation={location} />}

                        <NavigationTable.SearchButton />

                        {canEdit && (
                            <div className="flex items-center">
                                <FavoriteButton type="task" isFavorite={isFavorite} onClick={toggleFavorite} />
                            </div>
                        )}

                        {canEdit && <TaskContextMenuButton align="right" task={task} editor={editorRef.current} />}

                        {onClose && (
                            <Tooltip content={t('tasks.close_task', { count: 1 })} placement="bottom">
                                <Button.XButton
                                    onClick={onClose}
                                    label={t('tasks.close_task', { count: 1 }) as string}
                                />
                            </Tooltip>
                        )}
                    </div>
                </div>

                <div className="flex h-full w-full flex-1">
                    <div className="hide-scrollbar overflow-x-hidden overflow-y-auto flex-1">
                        {editorBlocks ? (
                            <SpaceTaskEditor
                                location={location}
                                yBlocks={editorBlocks}
                                blockPlugins={blockPlugins}
                                focus={focus}
                                ref={editorRef}
                                disableInteraction={!yBlocks}
                            />
                        ) : (
                            <EmptyBlocksPlaceholder state={type} type="task" />
                        )}
                    </div>

                    <div className="flex-shrink-0">
                        <TaskRightPanel
                            location={location}
                            rightMenu={panes.length === 1 ? singleRightMenu : rightMenu}
                            floating={isMobile}
                            container={container}
                            onFocusBlockId={(blockId) => setFocus({ blockId })}
                            onRightMenuChange={panes.length === 1 ? singleSetRightMenu : setRightMenu}
                        />
                    </div>
                </div>
            </div>
        </ReferenceResults>
    );
}

export function TaskPane({
    location,
    onClose,
    size,
}: {
    location: SagaLocation.TaskLocation;
    onClose?: () => void;
    size: SideBySideSize;
}) {
    const { urlKey } = useCurrentWorkspace();
    const task = useNullableTask(location.taskId);

    if (task) {
        return <TaskFound location={location} task={task} onClose={onClose} size={size} />;
    }

    return <Redirect to={`/s/${urlKey}/tasks`} />;
}
