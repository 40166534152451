import TemplatePicker from '@/components/TemplatePicker';
import React from 'react';

const TemplatesContext = React.createContext<(v: boolean) => void>(() => {});

export const useTemplatesContext = () => React.useContext(TemplatesContext);

const TemplateProvider = ({ children }: { children: React.ReactNode }) => {
    const [templatesPickerOpen, setTemplatesPickerOpen] = React.useState(false);

    return (
        <TemplatesContext.Provider value={setTemplatesPickerOpen}>
            {children}
            {templatesPickerOpen && <TemplatePicker onClose={() => setTemplatesPickerOpen(false)} />}
        </TemplatesContext.Provider>
    );
};

export default TemplateProvider;
