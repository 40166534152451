import React, { useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import * as R from 'ramda';
import { Location, Node } from 'slate';
import classNames from 'classnames';
import Tooltip from '@/components/popover/Tooltip';
import { Edit2, Link } from 'react-feather';
import { track } from '@/analytics';
import { Highlight } from '@/types';
import {
    SagaElement,
    isVoid,
    Collection,
    EditorOperations,
    SagaEditor,
    SpaceOperations,
    SagaLocation,
    BaseItem,
    WeakPage,
    isTitle,
} from '@saga/shared';
import * as Popover from '@radix-ui/react-popover';
import { useCollectionsSnapshot } from '@/hooks/SpaceHooks';
import StaticEditor from '@/components/editor/StaticEditor';
import { useOpenCollection, useOpenPage } from '@/components/PageNavigationProvider';
import { useSetQuickEditModalPage } from '@/components/QuickEditPageContainer';
import { useBlockPlugins } from '@/components/BlockPluginProvider';
import { useSpace } from '@/components/SpaceProvider';
import { OpacityTransition } from '@/components/Transitions';
import { compactPrettyLinkBlockPlugin } from '@/components/editor/plugins/PrettyLink';
import Emoji from '@/components/Emoji';
import { isBlocksLocation } from '@/../../shared/src/SagaLocation';
import { useTranslation } from 'react-i18next';
import { usePerformBlockChangeWithEditor, useDocumentYBlocks } from '@/components/RealtimeDocumentProvider';
import { usePageAccess } from '@/components/PagesPermissionsBySpaceProvider';

const noop = () => {};

function HighlightPopoverContent({
    targetBlocks,
    collections = [],
    onClose,
    canEdit,
    onCollectionClick,
    onTurnIntoRefClick,
    onOpenQuickEditClick,
    onContentClick,
    heading,
    canQuickEdit,
    blockPlugins,
    page,
}: {
    targetBlocks: SagaElement[];
    collections?: Collection[];
    onCollectionClick?: (id: string, event: React.MouseEvent) => void;
    onContentClick?: (event: React.MouseEvent) => void;
    onTurnIntoRefClick?: () => void;
    onOpenQuickEditClick?: () => void;
    onClose?: () => void;
    canEdit?: boolean;
    canQuickEdit?: boolean;
    heading?: string;
    blockPlugins: SagaEditor.Plugins.BlockPlugin[];
    page: Pick<WeakPage, 'id' | 'title' | 'icon'>;
}) {
    const [isOverflowing, setIsOverflowing] = useState(false);
    const { t } = useTranslation();

    const staticBlockPlugins = React.useMemo(() => {
        const titleBlockPlugin = SagaEditor.Plugins.createBlockPlugin({
            match: isTitle,
            Component({ element, children }) {
                return (
                    <div
                        contentEditable={false}
                        className={classNames('space-x-1 py-1', { '-ml-2': page.icon != null })}
                    >
                        {page.icon?.colons && (
                            <span contentEditable={false} className="select-none p-1.5">
                                <Emoji emoji={page.icon?.colons} size={18} />
                            </span>
                        )}
                        <h1 id={element.id} className="inline text-xl leading-snug sm:leading-normal font-semibold">
                            {children}
                        </h1>
                    </div>
                );
            },
        });
        return [titleBlockPlugin, compactPrettyLinkBlockPlugin, ...blockPlugins];
    }, [blockPlugins, page]);

    const isEmpty = useMemo(() => {
        for (const block of targetBlocks) {
            if (Node.string(block).length > 0 || isVoid(block)) {
                return false;
            }
        }
        return true;
    }, [targetBlocks]);

    return (
        <div onMouseLeave={onClose}>
            <div
                data-testid="highlight-popover"
                className="highlight-popover relative w-highlight shadow-popupSmall rounded bg-white dark:bg-zinc-700 dark:border dark:border-zinc-600 dark:border-solid"
            >
                <div className="absolute top-0 right-0 z-100">
                    <div className="mt-1 mr-1 flex z-10 text-saga-gray-500 dark:text-zinc-200 bg-white dark:bg-zinc-600 shadow-popupSmall divide-x divide-saga-gray-150 dark:divide-saga-gray-800 rounded">
                        {canQuickEdit && (
                            <Tooltip content={t('editor.quick_edit')} placement="top">
                                <button
                                    className={classNames(
                                        'cursor-pointer p-2 hover:bg-saga-gray-200 dark:hover:bg-zinc-500 focus:outline-none active:bg-saga-gray-200 dark:active:bg-zinc-500 active:shadow-xs',
                                        {
                                            'rounded-l': onTurnIntoRefClick,
                                            rounded: !onTurnIntoRefClick,
                                        },
                                    )}
                                    onClick={onOpenQuickEditClick}
                                >
                                    <Edit2 className="flex-none" size={16} />
                                    <span className="sr-only">{t('editor.quick_edit')}</span>
                                </button>
                            </Tooltip>
                        )}
                        {canEdit && onTurnIntoRefClick && (
                            <Tooltip content={t('editor.turn_into_page_link')} placement="top">
                                <button
                                    className="cursor-pointer p-2 hover:bg-saga-gray-200 dark:hover:bg-zinc-500 focus:outline-none active:bg-saga-gray-200 dark:active:bg-zinc-500 active:shadow-xs rounded-r"
                                    onClick={onTurnIntoRefClick}
                                >
                                    <Link className="flex-none" size={16} />
                                    <span className="sr-only">{t('editor.turn_into_page_link')}</span>
                                </button>
                            </Tooltip>
                        )}
                    </div>
                </div>
                <div className="cursor-pointer space-y-1" onMouseDown={onContentClick}>
                    {heading && (
                        <p className="my-0 pl-4 w-48 pt-3 break-words text-sm font-bold">
                            {page.title} / {heading}
                        </p>
                    )}
                    {isEmpty && <div className="break-words text-sm p-3">{t('editor.empty_page')}</div>}
                    {!isEmpty && (
                        <div
                            style={{ minHeight: '5rem' }}
                            className="overflow-hidden relative py-2 px-4"
                            ref={(el) => {
                                if (el) {
                                    const rect = el.getBoundingClientRect();
                                    setIsOverflowing(rect.height > 178);
                                }
                            }}
                        >
                            <SetHighlightContext.Provider value={noop}>
                                <StaticEditor
                                    blocks={targetBlocks}
                                    className="w-full text-xs max-h-52"
                                    location={SagaLocation.pageLocationFromId(page.id)}
                                    blockPlugins={staticBlockPlugins}
                                />
                            </SetHighlightContext.Provider>

                            {isOverflowing && (
                                <div className="absolute bottom-0 inset-x-0 bg-gradient-to-t from-white dark:from-zinc-700 h-6" />
                            )}
                        </div>
                    )}
                </div>
                {collections.length > 0 && (
                    <div className="px-3 pb-2 bg-gray-50 dark:bg-zinc-600 flex flex-wrap items-center border-t border-saga-gray-200 dark:border-zinc-600 rounded-b">
                        {collections.map((c, index) => {
                            return (
                                <div
                                    key={`highlight-collection-${c.title}-${index}`}
                                    className="px-1 mt-2 mr-1 py-0 border border-saga-gray-200 dark:border-zinc-500 cursor-pointer rounded-md text-sm truncate"
                                    onClick={(event) => onCollectionClick && onCollectionClick(c.id, event)}
                                >
                                    {c.title}
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>
        </div>
    );
}

function HighlightAnchor({ highlight }: { highlight: Highlight }) {
    const { left, top } = React.useMemo(() => {
        const element = document.querySelector(`[data-highlight-id="${highlight.highlightId}"]`);

        if (element) {
            const y = highlight.event.pageY;
            const [first, ...rects] = Array.from(element.getClientRects());
            let closestRect = first;

            rects.forEach((rect) => {
                const rectDeltaY = Math.abs(y - rect.bottom);
                const closestRectDeltaY = Math.abs(y - closestRect.bottom);

                if (rectDeltaY < closestRectDeltaY) {
                    closestRect = rect;
                }
            });

            const left = closestRect.left;
            const top = closestRect.bottom;
            return { left, top };
        }

        return { left: 0, top: 0 };
    }, [highlight.highlightId, highlight.event]);

    return ReactDOM.createPortal(
        <Popover.Anchor asChild>
            <span className="fixed" style={{ left, top }} />
        </Popover.Anchor>,
        window.document.body,
    );
}

function getItemsAtSameDepth<T extends BaseItem>(items: T[], id: string): T[] | null {
    let item = items.find((b) => b.id === id);
    if (item) {
        return items;
    }
    let result = null;
    for (const b of items) {
        if (b.children) {
            result = getItemsAtSameDepth(b.children, id) as T[];
            if (result) {
                return result;
            }
        }
    }
    return result;
}

function HighlightPopover({
    highlight,
    onClose,
    opened,
    location,
}: {
    highlight: Highlight;
    onClose: () => void;
    location: SagaLocation.SagaLocation;
    opened: boolean;
}) {
    const { space } = useSpace();
    const blockPlugins = useBlockPlugins();
    const collections = useCollectionsSnapshot();
    const openPage = useOpenPage();
    const goToCollection = useOpenCollection();
    const setQuickEditPageId = useSetQuickEditModalPage();

    const { canEdit: canEditSource } = usePageAccess(SagaLocation.getIdFromLocation(location));
    const { canEdit: canEditTarget } = usePageAccess(highlight.target.location.pageId);

    const changeBlocksWithEditor = usePerformBlockChangeWithEditor();

    const pageId = highlight.target.location.pageId;

    const page = React.useMemo(
        () => SpaceOperations.getPageById(space, pageId, ['id', 'icon', 'collections', 'title']),
        [space, pageId],
    );

    const { data: yBlocks } = useDocumentYBlocks(pageId);

    const targetBlocks = useMemo(() => {
        if (!yBlocks) return [];
        let blocks: SagaElement[] = [];

        const blockId = highlight.target.heading?.id;

        if (blockId) {
            const blocksAtSameDepth = getItemsAtSameDepth(yBlocks.toJSON(), blockId);
            if (blocksAtSameDepth) {
                let sliceIndex = R.findIndex(R.propEq('id', blockId))(blocksAtSameDepth);
                sliceIndex = sliceIndex !== -1 ? sliceIndex : 1;
                blocks = blocksAtSameDepth.slice(sliceIndex + 1, blocksAtSameDepth.length);
            }
        } else {
            blocks = yBlocks.toJSON();
        }
        // remove pending empty blocks
        let lastIndex = blocks.length;
        for (let i = blocks.length - 1; i >= 0; i--) {
            if (Node.string(blocks[i]).length === 0 && !isVoid(blocks[i])) {
                lastIndex = lastIndex - 1;
            }
        }
        return blocks.slice(0, lastIndex);
    }, [highlight.target, yBlocks]);

    const heading = useMemo(() => {
        if (highlight.target.heading) {
            return highlight.target.heading.title;
        } else {
            return undefined;
        }
    }, [highlight]);

    const onClick = (event: React.MouseEvent) => {
        if (highlight && event.button === 0) {
            let options: any = { source: 'highlight' };
            openPage(pageId, event, options);
            onClose();
        }
    };

    const openQuickEdit = () => {
        if (setQuickEditPageId) {
            setQuickEditPageId(pageId);

            // wait for next render in order for the quick edit modal to be visible
            setTimeout(() => {
                track('open-quick-edit');
                onClose();
            }, 0);
        }
    };

    const currentCollections = collections ? collections.filter(({ id }) => page?.collections.includes(id)) : [];

    function turnIntoPageLink(target: Location, pageId: string) {
        if (isBlocksLocation(location)) {
            changeBlocksWithEditor(space, location, (editor, blocks) => {
                if (!blocks) return;

                const inlinePageLink = SpaceOperations.createInlinePageLink(space, pageId, blocks.toJSON());
                EditorOperations.Transforms.insertInlineNode(editor, inlinePageLink, target);
            });

            onClose();
        }
    }

    if (!page) return null;

    return (
        <Popover.Root
            open={opened}
            onOpenChange={(open) => {
                if (!open) {
                    onClose();
                }
            }}
        >
            <HighlightAnchor highlight={highlight} />
            <Popover.Content
                onFocusOutside={(e) => {
                    e.preventDefault();
                }}
                className="z-50"
                align="start"
                onOpenAutoFocus={(e) => e.preventDefault()}
            >
                <OpacityTransition tag="span" duration={200} isOpen>
                    <HighlightPopoverContent
                        blockPlugins={blockPlugins}
                        onTurnIntoRefClick={
                            highlight.type === 'autolink' && isBlocksLocation(location)
                                ? () => {
                                      turnIntoPageLink(highlight.range, pageId);
                                  }
                                : undefined
                        }
                        onClose={onClose}
                        targetBlocks={targetBlocks}
                        collections={currentCollections}
                        onCollectionClick={goToCollection}
                        onOpenQuickEditClick={openQuickEdit}
                        onContentClick={onClick}
                        canEdit={canEditSource}
                        canQuickEdit={
                            !SagaLocation.areLocationsEqual(location, highlight.target.location) && canEditTarget
                        }
                        heading={heading}
                        page={page}
                    />
                </OpacityTransition>
            </Popover.Content>
        </Popover.Root>
    );
}

export const SetHighlightContext = React.createContext<React.Dispatch<React.SetStateAction<Highlight | null>>>(noop);

export const useSetHighlight = () => {
    const setHighlight = React.useContext(SetHighlightContext);
    return setHighlight;
};

export default function HighlightsProvider({
    children,
    location,
}: {
    children: React.ReactNode;
    location: SagaLocation.SagaLocation;
}) {
    const [highlight, setHighlight] = React.useState<Highlight | null>(null);
    const [popoverOpened, setPopoverOpened] = React.useState(false);

    const onClose = React.useCallback(() => {
        setHighlight(null);
    }, []);

    React.useEffect(() => {
        let timeoutID: number;

        if (highlight) {
            timeoutID = window.setTimeout(() => setPopoverOpened(true), 500);
        } else {
            setPopoverOpened(false);
        }

        return () => {
            window.clearTimeout(timeoutID);
        };
    }, [highlight]);

    return (
        <SetHighlightContext.Provider value={setHighlight}>
            {children}
            {highlight && (
                <HighlightPopover location={location} onClose={onClose} highlight={highlight} opened={popoverOpened} />
            )}
        </SetHighlightContext.Provider>
    );
}
