import React, { useState, useEffect, useMemo, useRef } from 'react';
import { X, Type } from 'react-feather';
import { track } from '@/analytics';
import classNames from 'classnames';
import PropertyMenuButton from '@/components/rightpanel/PropertyMenuButton';
import { InfoTooltip } from '@/components/popover/Tooltip';
import { Property, PageProperty, PageContent, Page, SpaceOperations } from '@saga/shared';
import { usePropertiesSnapshot } from '@/hooks/SpaceHooks';
import Button from '../styled/Button';
import Panel from './Panel';
import { useSpace } from '@/components/SpaceProvider';
import { useTranslation } from 'react-i18next';
import { usePageAccess } from '@/components/PagesPermissionsBySpaceProvider';

type PropertyFieldProps = {
    canEdit: boolean;
    pageProperty: PageProperty;
    propertyLabel: string;
    isNew: boolean;
    setNew: (v: boolean) => void;
    removeProperty: (id: string) => void;
    updateProperty: (id: string, newValue: string) => void;
};

const PropertyField = ({
    canEdit,
    pageProperty,
    propertyLabel,
    isNew,
    setNew,
    removeProperty,
    updateProperty,
}: PropertyFieldProps) => {
    const ref = useRef<HTMLInputElement>(null);
    const [isEditing, setIsEditing] = useState(false);
    const [showButton, setShowButton] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    const onBlur = () => {
        if (!isNew) {
            updateProperty(pageProperty.id, ref.current?.value ?? '');
            setIsEditing(false);
        } else {
            setNew(false);
        }
    };

    const onSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        updateProperty(pageProperty.id, ref.current?.value ?? '');
        setIsEditing(false);
    };

    useEffect(() => {
        if (isNew) {
            setIsEditing(true);
        }
    }, [isNew]);

    useEffect(() => {
        if (isEditing && ref.current) {
            ref.current?.focus();
        }
    }, [isEditing]);

    return (
        <div className="flex items-center">
            <div className="flex pr-2">
                <Type className="stroke-saga-gray-500 my-auto mr-2" size={16} />
                <p className="text-saga-gray-500 dark:text-zinc-200 w-20 text-sm my-auto truncate">{propertyLabel}</p>
            </div>
            {isEditing && (
                <div className="relative w-full">
                    <span className="relative inline">
                        <form onBlur={onBlur} onSubmit={onSubmit}>
                            <input
                                ref={ref}
                                className="h-6 transition-colors duration-100 ease-in-out text-sm leading-normal py-0 focus:outline-0 font-normal border-2 bg-white dark:bg-zinc-900 border-saga-gray-200 dark:border-zinc-600 focus:bg-gray-100 dark:focus:bg-zinc-900/50 focus:border-gray-300 dark:focus:border-zinc-500 placeholder-saga-text-gray rounded px-2 block w-full appearance-none"
                                type="text"
                                placeholder={`Set a value for ${propertyLabel}...`}
                                defaultValue={pageProperty.value}
                            />
                        </form>
                    </span>
                </div>
            )}
            {!isEditing && (
                <div
                    className="flex-grow flex flex-row"
                    onMouseEnter={() => setShowButton(true)}
                    onMouseLeave={() => setShowButton(false)}
                >
                    <div
                        className={classNames(
                            'h-6 flex-grow break-all px-2 rounded border-2 bg-white dark:bg-zinc-900 border-saga-gray-200 dark:border-zinc-600 focus:bg-gray-100 dark:focus:bg-zinc-900/50 focus:border-gray-300 dark:focus:border-zinc-500 placeholder-saga-text-gray flex items-center',
                            { 'hover:bg-saga-gray-200 hover:border-saga-gray-200 cursor-pointer': canEdit },
                        )}
                        onClick={() => {
                            if (canEdit) {
                                setIsEditing(true);
                            }
                        }}
                    >
                        {pageProperty.value && (
                            <span className="text-sm leading-normal truncate">{pageProperty.value}</span>
                        )}
                        {!pageProperty.value && (
                            <span className="text-sm leading-normal text-saga-text-gray">Empty</span>
                        )}
                    </div>
                    {canEdit && (
                        <div className={classNames('flex-none', { invisible: !showButton && !isOpen })}>
                            <PropertyMenuButton
                                removeProperty={() => removeProperty(pageProperty.id)}
                                isOpen={isOpen}
                                setIsOpen={setIsOpen}
                            ></PropertyMenuButton>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

const PropertiesPanel = React.memo(function PropertiesPanel({
    floating,
    currentPage,
    onClose,
}: {
    floating: boolean;
    currentPage: Pick<Page, 'id' | 'properties' | 'createdAt' | 'updatedAt' | 'wordCount'>;
    blocks: PageContent;
    onClose(): void;
}) {
    const { space } = useSpace();
    const { isPageAdmin } = usePageAccess(currentPage.id);
    const properties = usePropertiesSnapshot();
    const [isNewProperty, setIsNewProperty] = useState<boolean>(false);
    const { t } = useTranslation();

    const pageProperties = useMemo(() => {
        if (currentPage) {
            return currentPage.properties.map((pageProperty: PageProperty) => {
                return { ...properties.find((p: Property) => p.id === pageProperty.id), value: pageProperty.value };
            });
        }
        return [];
    }, [properties, currentPage]);

    const updateProperty = (id: string, newValue: string) => {
        const newProperties = currentPage.properties.map((p: PageProperty) => {
            if (p.id === id) return { ...p, value: newValue };
            else return p;
        });
        SpaceOperations.updatePartialPage(space, currentPage.id, { properties: newProperties });
        track('update-property');
    };

    const removeProperty = (id: string) => {
        const newPage = {
            ...currentPage,
            properties: currentPage.properties.filter((p: PageProperty) => p.id !== id),
        };
        SpaceOperations.updatePartialPage(space, newPage.id, { properties: newPage.properties });
        track('remove-property');
    };

    return (
        <Panel testId="properties-panel">
            <Panel.Header padding={!floating}>
                <div className="flex items-center justify-start space-x-2">
                    {floating && (
                        <Button.Plain onClick={onClose}>
                            <X size={20} />
                        </Button.Plain>
                    )}
                    <Panel.Title>
                        <span>Properties</span>
                    </Panel.Title>
                    <div className="my-auto mx-1">
                        <InfoTooltip content={t('common.properties_tooltip')} placement="bottom" />
                    </div>
                </div>
            </Panel.Header>
            <Panel.Body>
                {currentPage.properties.map((property, i) => (
                    <PropertyField
                        canEdit={isPageAdmin}
                        key={`property-${i}`}
                        pageProperty={property}
                        isNew={i === pageProperties.length - 1 && isNewProperty}
                        setNew={setIsNewProperty}
                        updateProperty={updateProperty}
                        removeProperty={removeProperty}
                        propertyLabel={properties.find((p) => p.id === property.id)?.label ?? ''}
                    />
                ))}
            </Panel.Body>
        </Panel>
    );
});

export default PropertiesPanel;
