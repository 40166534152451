import {
    WeakPage,
    mapBlock,
    isLiveBlockSource,
    Space,
    isSagaText,
    isLiveBlock,
    isImage,
    isFile,
    WeakDocumentContent,
    WeakTask,
    isInlinePageLink,
    isTaskBlock,
} from '../types';
import { unsafeRight } from '../io-ts-js';
import { createDocumentForSpace } from '../createDocumentForSpace';
import { pipe } from 'fp-ts/lib/function';
import * as Y from 'yjs';
import * as R from 'ramda';
import IdMap from '../utils/IdMap';
import { SafeSpace } from '../types';
import { SpaceBlocks, WeakBlocks } from '..';

type PageWithBlocks = WeakPage & { blocks: WeakBlocks };
type TaskWithBlocks = WeakTask & { blocks: WeakBlocks };

type TransformSpacePayload = {
    sourceUrlKey: string;
    space: {
        id: string;
        title: string;
        urlKey: string;
        createdAt: number;
        isPublic: boolean;
        pages: PageWithBlocks[];
        referencesRegistry: Space['referencesRegistry'];
        collections: Space['collections'];
        properties: Space['properties'];
        pinned: Space['pinned'];
        tasks: TaskWithBlocks[];
        taskLabels: Space['taskLabels'];
        autolinkEnabled: Space['autolinkEnabled'];
        userFavorites: Space['userFavorites'];
        pageViews: Space['pageViews'];
        taskViews: Space['taskViews'];
    };
    idMap: IdMap;
};

function transformAssetsUrls(payload: TransformSpacePayload): TransformSpacePayload {
    const pages = payload.space.pages.map((page) => {
        const mappedBlocks = page.blocks.map((block) => {
            return mapBlock(block, (node) => {
                if (isImage(node) || isFile(node)) {
                    return { ...node, url: node.url?.replace(payload.sourceUrlKey, payload.space.urlKey) };
                }
                return node;
            });
        });
        return { ...page, blocks: mappedBlocks };
    });

    return { ...payload, space: { ...payload.space, pages } };
}

function transformLiveReferences(payload: TransformSpacePayload): TransformSpacePayload {
    const pages = payload.space.pages
        // We need to transform all live reference sources FIRST, because we
        // need to gather all liveReferenceSourceIds in the idMap so that we
        // can use them when we transform the references
        .map((page) => {
            const mappedBlocks = page.blocks.map((block) => {
                return mapBlock(block, (node) => {
                    if (isLiveBlockSource(node)) {
                        const newLiveReferenceSourceId = payload.idMap.swapId(node.liveReferenceSourceId);
                        return { ...node, liveReferenceSourceId: newLiveReferenceSourceId };
                    }
                    return node;
                });
            });
            return { ...page, blocks: mappedBlocks };
        })
        // Then we transform all references and use what we already have in the idmap
        .map((page) => {
            const mappedBlocks = page.blocks.map((block) => {
                return mapBlock(block, (node) => {
                    if (isLiveBlock(node)) {
                        return {
                            ...node,
                            reference: {
                                liveReferenceSourceId: payload.idMap.tryGetSwappedId(
                                    node.reference.liveReferenceSourceId,
                                ),
                            },
                        };
                    }
                    return node;
                });
            });
            return { ...page, blocks: mappedBlocks };
        });

    // at last, we transform the reference registry, so that we have all live references updatedin there as well
    const referencesRegistry = payload.space.referencesRegistry.map((entry) => {
        return {
            ...entry,
            liveBlocks: entry.liveBlocks.map((block) => {
                return mapBlock(block, (node) => {
                    if (isSagaText(node)) {
                        return node;
                    }

                    if (isLiveBlock(node)) {
                        return {
                            ...node,
                            id: payload.idMap.tryGetSwappedId(node.id),
                            reference: {
                                liveReferenceSourceId: payload.idMap.tryGetSwappedId(
                                    node.reference.liveReferenceSourceId,
                                ),
                            },
                        };
                    }
                    return { ...node, id: payload.idMap.tryGetSwappedId(node.id) };
                });
            }),
            liveReferenceSourceId: payload.idMap.tryGetSwappedId(entry.liveReferenceSourceId),
            pageId: payload.idMap.tryGetSwappedId(entry.pageId),
        };
    });

    return { ...payload, space: { ...payload.space, pages, referencesRegistry } };
}

function transformPageIds(payload: TransformSpacePayload): TransformSpacePayload {
    return {
        ...payload,
        space: {
            ...payload.space,
            pages: payload.space.pages.map((page) => {
                const newId = payload.idMap.swapId(page.id);
                return { ...page, id: newId };
            }),
        },
    };
}

function transformTaskIds(payload: TransformSpacePayload): TransformSpacePayload {
    return {
        ...payload,
        space: {
            ...payload.space,
            tasks: payload.space.tasks.map((task) => {
                const newId = payload.idMap.swapId(task.id);
                return { ...task, id: newId };
            }),
        },
    };
}

function transformPageBlocks(payload: TransformSpacePayload): TransformSpacePayload {
    return {
        ...payload,
        space: {
            ...payload.space,
            pages: payload.space.pages.map((page) => {
                const mappedBlocks = page.blocks.map((block) => {
                    return mapBlock(block, (node) => {
                        if (isSagaText(node)) {
                            return node;
                        }
                        const newId = payload.idMap.swapId(node.id);

                        if (isInlinePageLink(node)) {
                            const newPageId = payload.idMap.tryGetSwappedId(node.pageId);
                            return { ...node, id: newId, pageId: newPageId };
                        }

                        if (isTaskBlock(node)) {
                            const newTaskId = payload.idMap.tryGetSwappedId(node.taskId);
                            return { ...node, id: newId, taskId: newTaskId };
                        }

                        return { ...node, id: newId };
                    });
                });
                return { ...page, blocks: mappedBlocks };
            }),
        },
    };
}

function transformProperties(payload: TransformSpacePayload): TransformSpacePayload {
    const pages = payload.space.pages.map((page) => {
        return {
            ...page,
            properties: [],
        };
    });

    return {
        ...payload,
        space: {
            ...payload.space,
            pages,
            properties: [],
        },
    };
}

function transformCollections(payload: TransformSpacePayload): TransformSpacePayload {
    const collections = payload.space.collections
        .map((collection) => {
            return {
                ...collection,
                properties: payload.idMap.mapSwappedIds(collection.properties),
                id: payload.idMap.swapId(collection.id),
                workspace: payload.space.urlKey,
            };
        })
        .map((collection) => {
            return { ...collection, subCollections: payload.idMap.mapSwappedIds(collection.subCollections ?? []) };
        });

    const pages = payload.space.pages.map((page) => {
        return {
            ...page,
            collections: payload.idMap.mapSwappedIds(page.collections),
        };
    });

    return { ...payload, space: { ...payload.space, collections, pages } };
}

function transformPinned(payload: TransformSpacePayload): TransformSpacePayload {
    return {
        ...payload,
        space: {
            ...payload.space,
            pinned: payload.idMap.mapSwappedIds(payload.space.pinned),
        },
    };
}

function transformUserFavorites(payload: TransformSpacePayload): TransformSpacePayload {
    return {
        ...payload,
        space: {
            ...payload.space,
            userFavorites: {},
        },
    };
}

function transformSpace(
    space: TransformSpacePayload['space'],
    sourceUrlKey: string,
    idMap: IdMap,
): { space: Space; contents: WeakDocumentContent[] } {
    const payload = {
        space: R.pick(
            [
                'pages',
                'tasks',
                'referencesRegistry',
                'properties',
                'collections',
                'pinned',
                'id',
                'title',
                'isPublic',
                'createdAt',
                'urlKey',
                'version',
                'taskLabels',
                'pageViews',
                'taskViews',
                'autolinkEnabled',
                'userFavorites',
            ],
            space,
        ),
        // We need to swap all uuids of the space that we work and we need to keep track of them
        // for this, we create a idMap that we pass down to all transform functions in order to add and retrieve the swapped ids
        idMap,
        sourceUrlKey,
    };

    const { space: transformedSpaceWithBlocks } = pipe(
        payload,
        // pageIds need to be transformed first, because they are used in other transfomers, e.g. the live references
        transformPageIds,

        // We also need to transform all taskIds for the same reason
        transformTaskIds,

        // Next, we need to transform the page blocks before everything else, again the live references depend on the page block ids already being swapped
        transformPageBlocks,

        // From here on, the order does not matter
        transformLiveReferences,
        transformProperties,
        transformCollections,
        transformPinned,
        transformAssetsUrls,
        transformUserFavorites,
    );

    const transformedSpace: Space = {
        version: '2',
        ...transformedSpaceWithBlocks,
        pages: transformedSpaceWithBlocks.pages.map((page) => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { blocks, ...pageWithoutBlocks } = page;
            return pageWithoutBlocks;
        }),
        tasks: transformedSpaceWithBlocks.tasks.map((task) => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { blocks, ...taskWithoutBlocks } = task;
            return taskWithoutBlocks;
        }),
        createdAt: transformedSpaceWithBlocks.createdAt.valueOf(),
    };

    return {
        space: transformedSpace,
        contents: [...transformedSpaceWithBlocks.pages, ...transformedSpaceWithBlocks.tasks].map((document) => ({
            id: document.id,
            spaceId: transformedSpace.id,
            blocks: document.blocks,
            icon: undefined,
        })),
    };
}

export default function copyWorkspace(
    space: SafeSpace,
    searchIndexMap: SpaceBlocks,
    {
        urlKey,
        title,
        id,
        isPublic,
        sourceUrlKey,
    }: { urlKey: string; title: string; id: string; isPublic: boolean; sourceUrlKey: string },
    idMap = new IdMap(),
): { space: Y.Doc; searchMap: SpaceBlocks } {
    const spaceDecoded = unsafeRight(space.decode());

    const spacePayload: TransformSpacePayload['space'] = {
        ...spaceDecoded,
        createdAt: spaceDecoded.createdAt,
        urlKey,
        title,
        id,
        isPublic,
        pages: spaceDecoded.pages.map((page) => ({
            ...page,
            blocks: searchIndexMap.get(page.id) ?? [],
        })),
        tasks: spaceDecoded.tasks.map((task) => ({
            ...task,
            blocks: searchIndexMap.get(task.id) ?? [],
        })),
    };

    const { space: transformedSpace, contents } = transformSpace(spacePayload, sourceUrlKey, idMap);

    const searchMap = new Map();

    contents.forEach((content) => {
        searchMap.set(content.id, content.blocks);
    });

    return {
        space: createDocumentForSpace(transformedSpace),
        searchMap,
    };
}
