import classNames from 'classnames';
import { DateTime } from 'luxon';
import React, { ElementType, HTMLAttributes } from 'react';
import { Editor, Path, Transforms, NodeEntry } from 'slate';
import { ReactEditor, RenderElementProps, useSelected, useSlateStatic } from 'slate-react';
import { EditorContext, useEditorContext } from '.';
import {
    BlockContainer,
    BlockType,
    Blockquote,
    CheckListItem,
    Divider,
    Heading,
    Image,
    IndentContainer,
    Link,
    ListItem,
    NumberedList,
    NumberedListItem,
    Paragraph,
    Table,
    TableCell,
    TableRow,
    isBlockType,
    isEmbed,
    isImage,
    isInlinePageLink,
    isNumberedList,
    isNumberedListItem,
    isTaskBlock,
    isTitle,
    isIndentContainer,
    isListItem,
    RealtimeSagaEditor,
    SagaElement as Node,
    EditorOperations,
    isVoid,
    isTableCell,
    BlockBuilder,
} from '..';
import { formatDateBlockDate } from '../utils/DateUtils';
import { findComponent } from './Plugins';
import { SagaElement } from '../EditorOperations';

export type CoreElement =
    | Paragraph
    | Blockquote
    | Heading
    | ListItem
    | NumberedList
    | NumberedListItem
    | Table
    | TableRow
    | TableCell
    | CheckListItem
    | BlockContainer
    | IndentContainer
    | Link
    | Divider
    | Image;

type CoreElementRendererProps = RenderElementProps & {
    selected: boolean;
    editor: Editor;
    context: EditorContext;
};

const BlockContainerContext = React.createContext<{ collapse(): void; path: Path } | null>(null);

export function useBlockContainer() {
    return React.useContext(BlockContainerContext);
}

function Chevron() {
    return (
        <svg viewBox="0 0 5 10" className="chevron" xmlns="http://www.w3.org/2000/svg">
            <path d="M5 5L-6.09799e-07 9.33013L-1.81419e-06 0.669873L5 5Z" fill="currentColor" />
        </svg>
    );
}

type Props = {
    children: RenderElementProps['children'];
    id: string;
    className: string;
    'data-testid': string;
};

function BlockContainerElement({ children, element, editor }: CoreElementRendererProps & { element: BlockContainer }) {
    const { allowCollapsingListItems } = useEditorContext();

    const props: Props = {
        children,
        id: element.id,
        className: classNames(element.type, {
            collapsed: element.collapsed && allowCollapsingListItems,
        }),
        'data-testid': element.type,
    };

    const context = React.useMemo(() => {
        const path = ReactEditor.findPath(editor, element);
        return {
            collapse() {
                Transforms.setNodes(
                    editor,
                    { collapsed: !element.collapsed },
                    /**
                     * We need to get the path again, because the
                     * element might have been moved
                     */
                    { at: ReactEditor.findPath(editor, element) },
                );
            },
            path,
        };
    }, [element, editor]);

    return (
        <BlockContainerContext.Provider value={context}>
            <div {...props} />
        </BlockContainerContext.Provider>
    );
}

function ListItemElement({ children, element, editor }: CoreElementRendererProps) {
    const blockContainer = useBlockContainer();
    const { allowCollapsingListItems } = useEditorContext();
    const isWindows = /Win/.test(navigator.userAgent);
    const isWindowsListItem = 'list-item-windows';

    // This ensures the first bullet list item always has a chevron
    const isStandaloneBulletItem = React.useMemo(() => {
        return !blockContainer && element.type === BlockType.BULLET_LIST_ITEM;
    }, [blockContainer, element.type]);

    const isFirstBlockContainerChild = React.useMemo(() => {
        if (blockContainer == null) return false;
        const path = ReactEditor.findPath(editor, element);

        return blockContainer && Path.isChild(path, blockContainer.path) && path[path.length - 1] === 0;
    }, [editor, element, blockContainer]);

    // Check if this list item has nested children
    const hasNestedChildren = React.useMemo(() => {
        if (!blockContainer) return false;

        const path = ReactEditor.findPath(editor, element);

        // Check if there are any children in the blockContainer that are direct children of this element
        const childNodes = Array.from(
            Editor.nodes(editor, {
                at: blockContainer.path,
                mode: 'highest',
            }),
        ) as NodeEntry<Node>[];

        // Filter to find descendants of the current path
        const childrenPaths = childNodes
            .map(([_, childPath]) => childPath)
            .filter((childPath) => childPath.length > path.length && Path.isDescendant(childPath, path));

        return childrenPaths.length > 0;
    }, [editor, element, blockContainer]);

    const shouldShowChevron = isStandaloneBulletItem || isFirstBlockContainerChild || hasNestedChildren;

    const isEmpty = !!blockContainer?.path.length && !!blockContainer.path;

    const findIndentLevel = (path: Path, count: number = 0): number => {
        const above = Editor.above(editor, { at: path, match: isIndentContainer, mode: 'lowest' });

        if (!above) return count;
        const prevListItem = Editor.previous(editor, { at: above[1], match: isListItem });
        if (!prevListItem || above[1].length !== prevListItem[1].length) return count;

        return findIndentLevel(above[1], count + 1);
    };

    const decideContentType = () => {
        if (!isEmpty) return '';
        const path = ReactEditor.findPath(editor, element);
        const indentLevel = findIndentLevel(path);
        const dotsPattern = ['content-1', 'content-2', 'content-3'];
        return changeFirstDotForOS(dotsPattern[indentLevel % 3]);
    };

    const detectOperatingSystem = () => {
        const isWindows = /Win/.test(navigator.userAgent);
        const isMac = /Mac/.test(navigator.userAgent);

        if (isWindows) return 'windows';
        if (isMac) return 'macOS';
    };

    const changeFirstDotForOS = (value: string) => {
        if (value === 'content-1' && detectOperatingSystem() === 'macOS') {
            return 'content-1-macOS';
        }
        if (value === 'content-1' && detectOperatingSystem() === 'windows') {
            return 'content-1-windows';
        }

        return value;
    };

    const props: Props = {
        children,
        id: element.id,
        className: `${
            element.type === BlockType.BULLET_LIST_ITEM && isWindows ? isWindowsListItem : element.type
        } ${decideContentType()}`,
        'data-testid': element.type,
    };

    const handleClick = () => {
        if (!blockContainer) {
            // If no block container, create a new list item
            const path = ReactEditor.findPath(editor, element);

            const newListItem = BlockBuilder.listItem('');

            Transforms.insertNodes(editor, newListItem, { at: Path.next(path) });

            const newPath = Path.next(path);

            ReactEditor.focus(editor);
            Transforms.select(editor, Editor.start(editor, newPath));
            EditorOperations.Extensions.indent(editor, true, undefined);
        } else {
            // If there's a block container, toggle collapse
            blockContainer.collapse();
        }
    };

    return (
        <div {...props}>
            {allowCollapsingListItems && shouldShowChevron && (
                <span
                    data-testid={'chevron-container'}
                    onClick={handleClick}
                    className={isWindows ? 'chevron-container-windows' : 'chevron-container'}
                >
                    <Chevron />
                </span>
            )}

            {props.children}
        </div>
    );
}

function renderCoreElement(coreElementRendererProps: CoreElementRendererProps) {
    const { element, children, editor, context } = coreElementRendererProps;

    const props: Props = {
        children,
        id: element.id,
        className: element.type,
        'data-testid': element.type,
    };

    switch (element.type) {
        case BlockType.PARAGRAPH:
            return <p {...props} />;
        case BlockType.QUOTE:
            return <blockquote {...props} />;
        case BlockType.HEADING_1:
            return <h1 {...props} />;
        case BlockType.HEADING_2:
            return <h2 {...props} />;
        case BlockType.HEADING_3:
            return <h2 {...props} />;
        case BlockType.BULLET_LIST_ITEM:
            return <ListItemElement {...coreElementRendererProps} />;
        case BlockType.NUMBER_LIST:
            return <ol {...props} />;
        case BlockType.NUMBER_LIST_ITEM:
            return <li {...props} />;
        case BlockType.TABLE:
            return <table {...props} />;
        case BlockType.TABLE_ROW:
            return <tr {...props} />;
        case BlockType.TABLE_CELL:
            return <td {...props} />;
        case BlockType.CHECK_LIST_ITEM:
            const check = () => {
                const path = ReactEditor.findPath(editor, element);
                Transforms.setNodes(editor, { checked: !element.checked }, { at: path });
            };
            return (
                <input
                    type="checkbox"
                    disabled={!context.canEdit}
                    onChange={check}
                    checked={element.checked}
                    className={props.className}
                    id={props.id}
                    data-testid={props['data-testid']}
                />
            );
        case BlockType.BLOCK_CONTAINER:
            return <BlockContainerElement {...coreElementRendererProps} element={element} />;
        case BlockType.INDENT:
            return <div {...props} />;
        case BlockType.LINK:
            return <a href={element.url} {...props} />;
        case BlockType.DIVIDER:
            return <hr className={props.className} id={props.id} data-testid={props['data-testid']} />;
        case BlockType.IMAGE:
            const { size, ratio } = element;

            return (
                <img
                    {...props}
                    src={element.url}
                    alt={element.alt}
                    style={{
                        ...(size && ratio
                            ? {
                                  maxWidth: size[0],
                                  maxHeight: size[1],
                              }
                            : {}),
                    }}
                />
            );
        case BlockType.DATE_BLOCK:
            return formatDateBlockDate(DateTime.fromISO(element.date));
    }

    return null;
}

const RenderInnerElement = function RenderInnerElement(
    props: RenderElementProps & { context: EditorContext; selected: boolean },
) {
    const editor = useSlateStatic();

    const { element, context, selected } = props;
    const { blockPlugins } = context;

    if (blockPlugins) {
        const Component = findComponent(element, blockPlugins);
        if (Component) {
            const path = ReactEditor.findPath(editor, element);
            return (
                <Component
                    {...props}
                    path={path}
                    blockPlugins={blockPlugins}
                    element={element}
                    selected={selected}
                    editor={editor}
                />
            );
        }
    }

    const coreElement = renderCoreElement({ ...props, selected, context, editor });

    if (coreElement == null) {
        return <span>Not Implemented</span>;
    }

    return <>{coreElement}</>;
};

function useOnScreen(element: Node, editor: RealtimeSagaEditor) {
    const [isIntersecting, setIntersecting] = React.useState(true);
    const [height, setHeight] = React.useState(0);

    const observerRef = React.useRef<IntersectionObserver>();

    React.useEffect(() => {
        observerRef.current = new IntersectionObserver(([entry]) => {
            entry.rootBounds?.height && setIntersecting(entry.isIntersecting);
        });
    }, [element]);

    React.useEffect(() => {
        let domElement: HTMLElement | null = null;
        try {
            domElement = ReactEditor.toDOMNode(editor, element);
            domElement && observerRef.current?.observe(domElement);
            domElement && setHeight(domElement.getBoundingClientRect().height);
        } finally {
            return () => observerRef.current?.disconnect();
        }
    }, [element, editor]);

    return { isIntersecting, height };
}

const RenderElement = function RenderElement(props: RenderElementProps) {
    const editor = useSlateStatic();
    const selected = useSelected();
    const context = useEditorContext();

    const { isIntersecting: isVisible, height: expectedHeight } = useOnScreen(props.element, editor);

    /* 
        We need to render some components no matter if they are visible on the screen,
        in order for the cmd+f search to work or not to break numbered list enumaration
     */
    const shouldRender =
        editor.children.length < 250 ||
        isVisible ||
        selected ||
        isBlockType(props.element, [
            isTitle,
            isInlinePageLink,
            isTaskBlock,
            isNumberedListItem,
            isNumberedList,
            isEmbed,
            isImage,
        ]);

    const renderWithCoreElement = React.useCallback(
        (props: RenderElementProps) => {
            const content = SagaElement.toString(props.element, context.blockPlugins);

            let Tag: ElementType<HTMLAttributes<HTMLElement>>;

            // mimic real component tag and height
            switch (props.element.type) {
                case BlockType.HEADING_1:
                    Tag = 'h1';
                    break;
                case BlockType.HEADING_2:
                case BlockType.HEADING_3:
                    Tag = 'h2';
                    break;
                case BlockType.QUOTE:
                    Tag = 'blockquote';
                    break;
                case BlockType.LINK:
                    Tag = 'a';
                    break;
                case BlockType.TABLE:
                    return (
                        <table style={{ height: expectedHeight }}>
                            <tbody>
                                <tr>
                                    <td>{content}</td>
                                </tr>
                            </tbody>
                        </table>
                    );
                default:
                    Tag = props.attributes['data-slate-inline'] ? 'span' : 'div';
                    break;
            }

            return <Tag style={{ height: expectedHeight }}>{content}</Tag>;
        },
        [context.blockPlugins, expectedHeight],
    );

    switch (props.element.type) {
        case 'table-cell':
        case 'table-row':
            return RenderInnerElement({ ...props, context, selected });
        default:
            return (
                <span {...props.attributes} id={props.element.id} style={{ opacity: shouldRender ? 1 : 0 }}>
                    {shouldRender ? RenderInnerElement({ ...props, context, selected }) : renderWithCoreElement(props)}
                </span>
            );
    }
};

export default RenderElement;
