import React from 'react';
import { useTranslation } from 'react-i18next';
import * as api from '@saga/api';
import classNames from 'classnames';
import { ChevronDown, UserCheck } from 'react-feather';
import Dropdown from '@/components/popover/Dropdown';
import { isFeatureEnabled, track } from '@/analytics';
import { FeatureFlag } from '@/../../shared/src/utils/FeatureFlags';
import { useSubscriptions } from './WorkspaceContext';
import Button from './styled/Button';
import { useSetSettings } from './settings/SettingsProvider';
import { useSpaceAccess } from '@/hooks/useSpaceAccess';
import { usePageAccess } from './PagesPermissionsBySpaceProvider';

const defaultOptions = [
    api.PagePermission.Admin,
    api.PagePermission.Writer,
    api.PagePermission.Viewer,
    api.PagePermission.Hidden,
];

export function PagePermissionSelect({
    permission,
    onChange,
    disabled = false,
    isGuest,
    isSelf,
    options = defaultOptions,
    closePopover,
}: {
    permission: api.PagePermission;
    onChange(permission: api.PagePermission): void;
    disabled?: boolean;
    isGuest: boolean;
    isSelf?: boolean;
    options: api.PagePermission[];
    closePopover: () => void;
}) {
    const { t } = useTranslation();
    const [open, setOpen] = React.useState(false);
    const buttonRef = React.useRef<HTMLDivElement>(null);
    const { currentSubscription } = useSubscriptions();
    const setSettings = useSetSettings();
    const isPaidFeature = isGuest
        ? false
        : isFeatureEnabled(FeatureFlag.allowBilling)
          ? currentSubscription === null
          : false;

    const getPermissionLabel = React.useCallback(
        (permission: api.PagePermission) => {
            switch (permission) {
                case api.PagePermission.Admin:
                    return t('settings.members.role_page_admin');
                case api.PagePermission.Writer:
                    return t('settings.members.role_editor');
                case api.PagePermission.Viewer:
                    return t('settings.members.role_viewer');
                default:
                    return t('settings.members.role_no_access');
            }
        },
        [t],
    );

    const getPermissionDescription = React.useCallback(
        (permission: api.PagePermission) => {
            switch (permission) {
                case api.PagePermission.Admin:
                    return t('top_menu.share.permission_admin_description');
                case api.PagePermission.Writer:
                    return t('top_menu.share.permission_editor_description');
                case api.PagePermission.Viewer:
                    return t('top_menu.share.permission_viewer_description');
                default:
                    return null;
            }
        },
        [t],
    );

    const onPermissionChange = (permission: api.PagePermission) => {
        if (isPaidFeature && permission !== api.PagePermission.Admin) {
            setSettings('plans');
            closePopover();
        } else {
            onChange(permission);
            track('change-page-permission', { value: permission.toLowerCase() });
        }

        setOpen(false);
    };

    return (
        <>
            <div
                ref={buttonRef}
                onClick={() => !disabled && setOpen(!open)}
                className={classNames(
                    'flex justify-between rounded items-center font-normal text-saga-gray-500 focus:outline-none focus-visible:outline-dotted focus-visible:outline-saga-gray-500',
                    {
                        'hover:bg-saga-gray-250 dark:hover:bg-saga-gray-700 hover:text-saga-black dark:hover:text-white cursor-pointer':
                            !disabled,
                    },
                )}
            >
                <div className="flex px-3 py-2 items-center">
                    <div className="truncate">{getPermissionLabel(permission)}</div>
                    {isSelf ? (
                        <UserCheck size={14} className="ml-1.5" />
                    ) : (
                        <ChevronDown size={14} className={classNames('ml-1.5', { 'opacity-0': disabled })} />
                    )}
                </div>
            </div>
            <Dropdown isOpen={open} onClose={() => setOpen(false)} attachToRef={buttonRef}>
                {open && (
                    <>
                        {options.map((permission) => {
                            return (
                                <div
                                    key={permission}
                                    onClick={() => onPermissionChange(permission)}
                                    className="flex flex-col p-2 cursor-pointer focus:outline-none focus:bg-saga-gray-200 dark:focus:bg-saga-gray-700 rounded hover:bg-saga-gray-250 dark:hover:bg-saga-gray-700"
                                >
                                    <div className="flex items-center space-x-2  text-sm font-medium text-saga-text dark:text-saga-text-darkmode">
                                        <span>{getPermissionLabel(permission)}</span>
                                        {isPaidFeature && permission !== api.PagePermission.Admin && (
                                            <Button.Pill disabled>{t('settings.members.paid_feature')}</Button.Pill>
                                        )}
                                    </div>
                                    {getPermissionDescription(permission) && (
                                        <div className="text-sm font-normal text-saga-gray-500 dark:text-saga-gray-600">
                                            {getPermissionDescription(permission)}
                                        </div>
                                    )}
                                </div>
                            );
                        })}
                    </>
                )}
            </Dropdown>
        </>
    );
}
