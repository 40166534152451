import { useTranslation } from 'react-i18next';
import { useSpaceAccess } from '@/hooks/useSpaceAccess';
import { useCurrentWorkspace } from '../WorkspaceContext';
import React, { useState } from 'react';
import { useToastContext } from '../toast/ToastContext';
import { SagaLocation, SpaceOperations } from '@saga/shared';
import { createPage, createTask } from '@/utils/documentUtils';
import Toast from '../toast/Toast';
import { PopOver } from './PopOver';
import { ArrowRight, Check } from 'react-feather';
import ChooseWorkspaceModal from '../ChooseWorkspaceModal';
import Modal, { ModalLayout } from '../Modal';
import { useMoveEntity } from '@/hooks/useMoveEntity';

function MoveEntityButton({
    entityId,
    disabled,
    onAfterClick,
    entityType,
}: Readonly<{
    entityId: string;
    disabled: boolean;
    onAfterClick?: () => void;
    entityType: 'task' | 'page';
}>) {
    const { t } = useTranslation();
    const { isOwner, isMember } = useSpaceAccess();
    const { showToast } = useToastContext();
    const currentWorkspace = useCurrentWorkspace();
    const [isWorkspacesModalOpen, setIsWorkspacesModalOpen] = useState(false);
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<{ visible: boolean; spaceUrlKey: string | null }>({
        visible: false,
        spaceUrlKey: null,
    });

    const entityOperations = {
        task: {
            getEntityById: SpaceOperations.findTask,
            transformEntityForMove: SpaceOperations.transformMoveEntity,
            deleteEntity: SpaceOperations.deleteTask,
            createEntity: createTask,
            locationFromId: SagaLocation.taskLocationFromId,
            modalTitles: {
                move: t('move_task.move_modal_title'),
                subTitle: t('move_task.move_modal_sub_title'),
                confirm: t('move_task.confirm_modal_title'),
                toastSuccess: t('move_task.toast_success'),
                contentLeft: t('move_task.confirm_modal_content_left'),
                contentRight: t('move_task.confirm_modal_content_right'),
            },
        },
        page: {
            getEntityById: SpaceOperations.getPageById,
            transformEntityForMove: SpaceOperations.transformMoveEntity,
            deleteEntity: SpaceOperations.deletePage,
            createEntity: createPage,
            locationFromId: SagaLocation.pageLocationFromId,
            modalTitles: {
                move: t('move_page.move_modal_title'),
                subTitle: t('move_page.move_modal_sub_title'),
                confirm: t('move_page.confirm_modal_title'),
                toastSuccess: t('move_page.toast_success'),
                contentLeft: t('move_page.confirm_modal_content_left'),
                contentRight: t('move_page.confirm_modal_content_right'),
            },
        },
    };

    const operations = entityOperations[entityType];

    const onSelectWorkspace = (urlKey: string) => {
        setIsWorkspacesModalOpen(false);
        setIsConfirmModalOpen({ visible: true, spaceUrlKey: urlKey });
    };

    const { targetSpaceUrlKey, setTargetSpaceUrlKey } = useMoveEntity({
        entityType,
        entityId,
        getEntityById: operations.getEntityById,
        transformEntityForMove: operations.transformEntityForMove,
        deleteEntity: operations.deleteEntity,
        createEntity: operations.createEntity,
        locationFromId: operations.locationFromId,
    });

    return (
        <>
            {(isOwner || isMember) && (
                <PopOver.RoundedButton
                    disabled={disabled}
                    onClick={() => {
                        onAfterClick?.();
                        setIsWorkspacesModalOpen(true);
                    }}
                    aria-label={t('common.move_to')}
                >
                    <ArrowRight className="stroke-gray-dark mr-2 my-auto" size={14} />
                    {t('common.move_to')}
                </PopOver.RoundedButton>
            )}

            {isWorkspacesModalOpen && (
                <ChooseWorkspaceModal
                    title={operations.modalTitles.move}
                    subTitle={operations.modalTitles.subTitle}
                    excludeSpaceUrlKey={currentWorkspace.urlKey}
                    onClose={() => setIsWorkspacesModalOpen(!isWorkspacesModalOpen)}
                    onSelect={onSelectWorkspace}
                />
            )}

            {isConfirmModalOpen.visible && (
                <Modal.Medium isOpen={true}>
                    <ModalLayout
                        dataTestId="confirm-move-modal"
                        title={operations.modalTitles.confirm}
                        primaryButtonLabel={t('move_page.confirm_changes')}
                        primaryButtonAction={() => {
                            setTargetSpaceUrlKey(isConfirmModalOpen.spaceUrlKey);
                            showToast(() => (
                                <Toast>
                                    <Toast.Title>🎉</Toast.Title>
                                    <Toast.Subtitle>{operations.modalTitles.toastSuccess}</Toast.Subtitle>
                                </Toast>
                            ));
                        }}
                        disabledOnLoading={targetSpaceUrlKey !== null}
                        secondaryButtonLabel={t('common.cancel')}
                        secondaryButtonAction={() => setIsConfirmModalOpen({ visible: false, spaceUrlKey: null })}
                        onCloseAction={() => setIsConfirmModalOpen({ visible: false, spaceUrlKey: null })}
                    >
                        <ul className="columns-2 space-y-2 list-none text-sm">
                            <li className="flex items-start p-0">
                                <Check width="14" height="14" className="mr-2 mt-1" />
                                {operations.modalTitles.contentLeft}
                            </li>
                            <li className="flex items-start p-0">
                                <Check width="14" height="14" className="mr-2 mt-1" />
                                {operations.modalTitles.contentRight}
                            </li>
                        </ul>
                    </ModalLayout>
                </Modal.Medium>
            )}
        </>
    );
}

export default MoveEntityButton;
