import { SagaEditor, isLink, BlockBuilder, Link } from '@saga/shared';
import React, { useCallback } from 'react';
import { ReactEditor, useSlateStatic } from 'slate-react';
import { Edit, Image, Copy, Check, Aperture, Trash } from 'react-feather';
import Tooltip from '@/components/popover/Tooltip';
import { Editor as SlateEditor, Node, Transforms } from 'slate';
import { track } from '@/analytics';
import { OpacityTransition } from '@/components/Transitions';
import SetLinkPopOver from '@/components/popover/SetLinkPopOver';
import { useEmbedContext } from '@/components/EmbedProviders';
import * as Popover from '@radix-ui/react-popover';
import { openWindow } from '@/utils';
import { canBeConvertedToPrettyLink } from './PrettyLink';
import classNames from 'classnames';
import { useTaskTitleEditorContext } from '@/components/tasks/TaskTitleEditor';
import { useTranslation } from 'react-i18next';

const MAX_Z_INDEX = 2147483647;

function LinkPopover({
    isOpen,
    onOpenChange,
    onEdit,
    element,
    children,
    onOpenUrl,
}: {
    isOpen: boolean;
    onOpenChange(isOpen: boolean): void;
    onEdit(): void;
    element: Link;
    children: React.ReactNode;
    onOpenUrl(url: string): void;
}) {
    const { canEdit } = SagaEditor.useEditorContext();
    const editor = useSlateStatic();
    const [copied, setCopied] = React.useState(false);
    const { canBeEmbedded } = useEmbedContext();
    const { t } = useTranslation();

    const turnIntoPrettyLink = () => {
        const path = ReactEditor.findPath(editor, element);
        const range = SlateEditor.range(editor, path);

        const prettyLink = BlockBuilder.prettyLink(element.url);
        Transforms.insertFragment(editor, [prettyLink], {
            at: range,
        });

        track('turn-into-pretty-link', { source: 'link-menu' });
    };

    const removeLink = () => {
        const path = ReactEditor.findPath(editor, element);
        const range = SlateEditor.range(editor, path);

        Transforms.unwrapNodes(editor, {
            at: range,
            match: isLink,
            split: true,
        });
        track('remove-link', { source: 'link-menu' });
    };

    const turnIntoEmbed = () => {
        const path = ReactEditor.findPath(editor, element);
        const range = SlateEditor.range(editor, path);

        Transforms.insertFragment(editor, [BlockBuilder.embed(element.url)], {
            at: range,
        });

        track('turn-into-embed', { source: 'link-menu' });
    };

    function onCopy() {
        navigator.clipboard.writeText(element.url);

        setTimeout(() => {
            setCopied(true);
        }, 0);
        setTimeout(() => {
            setCopied(false);
        }, 1000);
    }

    return (
        <Popover.Root open={isOpen} onOpenChange={onOpenChange}>
            <Popover.Anchor asChild>{children}</Popover.Anchor>
            <Popover.Portal>
                <Popover.Content
                    align="start"
                    onMouseLeave={() => onOpenChange(false)}
                    onOpenAutoFocus={(e) => e.preventDefault()}
                >
                    <OpacityTransition tag="span" duration={200} isOpen={isOpen}>
                        <span
                            data-testid="highlight-popover"
                            className="mt-1 relative flex flex-row text-sm px-2 py-0.5 space-x-1 shadow-popupSmall text-saga-gray-dark dark:text-zinc-200 max-w-highlight rounded bg-white dark:bg-zinc-700 dark:border dark:border-solid dark:border-zinc-600"
                        >
                            <span
                                className="cursor-pointer p-1 flex flex-row space-x-1 items-center min-w-0"
                                onMouseDown={(e) => {
                                    e.preventDefault();
                                    if (e.button === 0) {
                                        onOpenUrl(element.url);
                                    }
                                }}
                            >
                                <span className="leading-normal truncate min-w-0">{element.url}</span>
                            </span>
                            {canEdit && (
                                <Tooltip zIndex={MAX_Z_INDEX} content={t('editor.edit_link')} placement="top">
                                    <button
                                        className="focus:outline-none"
                                        onClick={onEdit}
                                        onPointerDown={(e) => {
                                            e.preventDefault();
                                        }}
                                    >
                                        <span className="sr-only">{t('editor.edit_link')}</span>
                                        <Edit className="select-none flex-none w-4 mx-1 text-saga-gray-dark dark:text-zinc-200" />
                                    </button>
                                </Tooltip>
                            )}

                            <Tooltip
                                zIndex={MAX_Z_INDEX}
                                content={t(copied ? 'editor.link_copied' : 'editor.copy_Link')}
                                placement="top"
                            >
                                <button
                                    className="focus:outline-none"
                                    onClick={onCopy}
                                    onPointerDown={(e) => {
                                        e.preventDefault();
                                    }}
                                >
                                    {copied && (
                                        <>
                                            <span className="sr-only">{t('editor.link_copied')}</span>
                                            <Check className="select-none flex-none w-4 mx-1 text-green-500 " />
                                        </>
                                    )}
                                    {!copied && (
                                        <>
                                            <span className="sr-only">{t('editor.copy_Link')}</span>
                                            <Copy className="select-none flex-none w-4 mx-1 text-saga-gray-dark dark:text-zinc-200" />
                                        </>
                                    )}
                                </button>
                            </Tooltip>
                            {isOpen && canBeConvertedToPrettyLink(element.url) && canEdit && (
                                <Tooltip
                                    zIndex={MAX_Z_INDEX}
                                    content={t('editor.turn_into_link_preview')}
                                    placement="top"
                                >
                                    <button
                                        className="focus:outline-none"
                                        onClick={turnIntoPrettyLink}
                                        onPointerDown={(e) => {
                                            e.preventDefault();
                                        }}
                                    >
                                        <span className="sr-only">{t('editor.link_into_pretty_link')}</span>
                                        <Image className="select-none flex-none w-4 mx-1 text-saga-gray-dark dark:text-zinc-200" />
                                    </button>
                                </Tooltip>
                            )}
                            {isOpen && canBeEmbedded(element.url) && (
                                <Tooltip zIndex={MAX_Z_INDEX} content={t('editor.turn_into_embed')} placement="top">
                                    <button
                                        className="focus:outline-none"
                                        onClick={turnIntoEmbed}
                                        onPointerDown={(e) => {
                                            e.preventDefault();
                                        }}
                                    >
                                        <span className="sr-only">{t('editor.link_into_embed')}</span>
                                        <Aperture className="select-none flex-none w-4 mx-1 text-saga-gray-dark dark:text-zinc-200" />
                                    </button>
                                </Tooltip>
                            )}
                            {isOpen && canEdit && (
                                <Tooltip zIndex={MAX_Z_INDEX} content={t('editor.remove_link')} placement="top">
                                    <button
                                        className="focus:outline-none"
                                        onClick={removeLink}
                                        onPointerDown={(e) => e.preventDefault()}
                                    >
                                        <span className="sr-only">{t('editor.remove_link')}</span>
                                        <Trash className="stroke-gray-dark mx-1 my-auto" size={16} />
                                    </button>
                                </Tooltip>
                            )}
                        </span>
                    </OpacityTransition>
                </Popover.Content>
            </Popover.Portal>
        </Popover.Root>
    );
}

function EditLinkPopover({
    setIsEditing,
    attachToRef,
    element,
}: {
    setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
    attachToRef: React.RefObject<HTMLSpanElement>;
    element: Link;
}) {
    const editor = useSlateStatic();
    const urlMatchesText = Node.string(element) === element.url;

    return (
        <SetLinkPopOver
            isOpen={true}
            setIsOpen={setIsEditing}
            attachToRef={attachToRef}
            currentURL={element.url}
            onChange={(url) => {
                SlateEditor.withoutNormalizing(editor, () => {
                    const path = ReactEditor.findPath(editor, element);

                    if (urlMatchesText) {
                        Transforms.delete(editor, {
                            at: path,
                        });
                        Transforms.insertNodes(editor, BlockBuilder.link(url, url), {
                            at: path,
                        });
                    }

                    Transforms.setNodes(
                        editor,
                        { url },
                        {
                            at: path,
                            match: isLink,
                        },
                    );
                });
            }}
            onRemove={() => {
                const path = ReactEditor.findPath(editor, element);
                Transforms.unwrapNodes(editor, {
                    at: path,
                    match: isLink,
                    split: true,
                });
            }}
            canRemove={!urlMatchesText}
        />
    );
}

type OpenUrl = (url: string) => void;

function defaultOpenUrl(url: string) {
    openWindow(url, '_blank');
}

export function createLinkBlockPlugin(openUrl: OpenUrl = defaultOpenUrl) {
    return SagaEditor.Plugins.createBlockPlugin({
        match: isLink,
        Component({ element, children, selected, editor }) {
            const anchor = React.useRef<HTMLSpanElement>(null);
            const [isEditing, setIsEditing] = React.useState(false);
            const [popoverVisible, setPopoverVisible] = React.useState(false);

            const isWithinTaskTitleEditor = useTaskTitleEditorContext() !== null;

            let timeoutID: number | undefined;

            const showPopover = useCallback(
                (event: React.MouseEvent) => {
                    if (event.buttons === 0 && !isWithinTaskTitleEditor) {
                        event.stopPropagation();
                        setPopoverVisible(true);
                    }
                },
                [isWithinTaskTitleEditor],
            );

            const hidePopover = useCallback(() => {
                setPopoverVisible(false);
            }, []);

            const onMouseOver = (event: React.MouseEvent) => {
                if (event.buttons === 0 && !isWithinTaskTitleEditor) {
                    event.stopPropagation();
                    timeoutID = window.setTimeout(() => showPopover(event), 500);
                }
            };

            const onMouseLeave = () => {
                window.clearTimeout(timeoutID);
                hidePopover();
            };

            return (
                <span onMouseLeave={onMouseLeave}>
                    <LinkPopover
                        isOpen={!isEditing && popoverVisible}
                        element={element}
                        onOpenChange={setPopoverVisible}
                        onEdit={() => {
                            Transforms.deselect(editor);
                            setIsEditing(true);
                            hidePopover();
                        }}
                        onOpenUrl={openUrl}
                    >
                        <span ref={anchor}>
                            <span
                                id={element.id}
                                className={classNames(
                                    'relative hover:underline text-saga-text-link dark:text-saga-text-link-dark cursor-pointer',
                                    {
                                        'text-saga-text-link bg-opacity-10': selected,
                                    },
                                )}
                                onMouseOver={onMouseOver}
                            >
                                <span
                                    role="link"
                                    data-testid="node-link"
                                    onClick={() => {
                                        openUrl(element.url);
                                    }}
                                >
                                    {children}
                                </span>
                            </span>
                        </span>
                    </LinkPopover>
                    {isEditing && (
                        <EditLinkPopover attachToRef={anchor} setIsEditing={setIsEditing} element={element} />
                    )}
                </span>
            );
        },
    });
}

export const linkBlockPlugin = createLinkBlockPlugin();
