import classNames from 'classnames';
import React from 'react';

export default function ElementContainer({
    children,
    onMouseOver,
    onMouseOut,
    fullWidth,
}: {
    children: React.ReactNode;
    onMouseOver?: React.MouseEventHandler<HTMLDivElement>;
    onMouseOut?: React.MouseEventHandler<HTMLDivElement>;
    fullWidth?: boolean;
}) {
    return (
        <div className="w-full flex justify-center" onMouseOver={onMouseOver} onMouseOut={onMouseOut}>
            <div
                className={classNames('select-text border-transparent w-full relative', {
                    'max-w-700': !fullWidth,
                })}
            >
                {children}
            </div>
        </div>
    );
}
