import { SafeSpace } from '../types';
import { createEditor, Editor } from 'slate';

import { applySlateOps } from '../slateYjs';
import * as Y from 'yjs';
import { spaceNormalizers } from '../Normalization/Normalizers';
import extendEditorForNormalization from '../Normalization/extendEditorForNormalization';

// ideally we can make use of this somehow in the undo manager. unfortunately,
// right now, it does not work when trying to use it directly in the realtime editor
export const changeWithEditorSymbol = Symbol('change-with-editor');

export default function changeWithEditor(space: SafeSpace, yArray: Y.Array<any>, change: (editor: Editor) => void) {
    yArray.doc?.transact(() => {
        const editor = extendEditorForNormalization(
            createEditor(),
            [],
            // even though we don't have the plugins, we still want to have the same normalizers that we also use on the client
            spaceNormalizers,
        );
        editor.children = yArray.toJSON();
        change(editor);
        Editor.normalize(editor, { force: true });
        applySlateOps(yArray, editor.operations, changeWithEditorSymbol);
    });
}
