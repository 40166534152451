import React, { useMemo } from 'react';
import { NodeEntry, Path } from 'slate';
import classNames from 'classnames';
import { X } from 'react-feather';
import {
    EditorOperations,
    PageContent,
    SagaElement,
    visit,
    SagaEditor as SharedEditor,
    isBlockType,
    isTitle,
    isHeading,
} from '@saga/shared';
import Button from '../styled/Button';
import Panel from './Panel';
import { useTranslation } from 'react-i18next';

type Heading = {
    id: string;
    type: string;
    text: string;
    path: Path;
};

export function buildHeadings(blocks: PageContent, blockPlugins: SharedEditor.Plugins.BlockPlugin[]): Heading[] {
    const headings: Heading[] = [];

    visit(
        { type: 'root', children: blocks },
        ([node, path]: NodeEntry<SagaElement>) => {
            if (isBlockType(node, [isTitle, isHeading])) {
                headings.push({
                    id: node.id,
                    type: node.type,
                    text: EditorOperations.SagaElement.toString(node, blockPlugins) || 'Untitled',
                    path,
                });
            }
        },
        [],
    );

    return headings;
}

function findHeadingIndex(headings: Heading[], id: string) {
    const idx = headings.findIndex((heading) => heading.id === id);
    return Math.max(0, idx);
}

function useFirstVisibleHeadingId(container: React.RefObject<HTMLElement | null>) {
    const [firstVisibleHeadingId, setFirstVisibleHeadingId] = React.useState<string | null>(null);

    React.useEffect(() => {
        function handleScroll() {
            const containerEl = container?.current;

            if (containerEl == null) return;

            let firstEl: HTMLElement | null = null;
            const els = Array.from(containerEl.querySelectorAll('h1[id],h2[id],h3[id]'));

            for (let el of els) {
                if (el instanceof HTMLElement) {
                    const rect = el.getBoundingClientRect();

                    if (rect.top >= 0) {
                        firstEl = el;
                        break;
                    }
                }
            }

            if (firstEl != null && firstEl.id != null) {
                setFirstVisibleHeadingId(firstEl.id);
            } else {
                setFirstVisibleHeadingId(els[els.length - 1]?.id ?? null);
            }
        }

        window.addEventListener('scroll', handleScroll, true);
        return () => window.removeEventListener('scroll', handleScroll, true);
    }, [container]);

    return firstVisibleHeadingId;
}

const TOCPanel = ({
    floating,
    container,
    onClose,
    onFocusBlockId,
    blockPlugins,
    entityBlocks: pageBlocks,
}: {
    floating: boolean;
    container: React.RefObject<HTMLElement | null>;
    onClose(): void;
    onFocusBlockId(blockId: string): void;
    blockPlugins: SharedEditor.Plugins.BlockPlugin[];
    entityBlocks: PageContent;
}) => {
    const { t } = useTranslation();
    const firstVisibleHeadingId = useFirstVisibleHeadingId(container);

    const headings: Heading[] = useMemo(() => {
        return buildHeadings(pageBlocks, blockPlugins);
    }, [pageBlocks, blockPlugins]);

    const currentIndex = React.useMemo(() => {
        if (firstVisibleHeadingId) {
            return findHeadingIndex(headings, firstVisibleHeadingId);
        }
        return 0;
    }, [headings, firstVisibleHeadingId]);

    return (
        <Panel testId="toc-panel">
            <div className="space-y-4">
                <Panel.Header padding={!floating}>
                    <div className="flex items-center justify-start space-x-2">
                        {floating && (
                            <Button.Plain onClick={onClose}>
                                <X size={20} />
                            </Button.Plain>
                        )}
                        <Panel.Title>
                            <span>{t('table_of_content.label')}</span>
                        </Panel.Title>
                    </div>
                </Panel.Header>
                <Panel.Body>
                    {headings.map((heading, i) => {
                        return (
                            <button
                                key={`heading-${i}`}
                                className={classNames(
                                    'block px-3 py-1 truncate cursor-pointer hover:bg-saga-bg-gray dark:hover:bg-zinc-600 mx-1',
                                    {
                                        'font-medium border-l-2 border-saga-gray-500 rounded-r': currentIndex === i,
                                        'border-l-2 border-transparent rounded': currentIndex !== i,
                                    },
                                )}
                                onClick={(e) => {
                                    e.preventDefault();
                                    onFocusBlockId(heading.id);
                                    if (floating) {
                                        onClose();
                                    }
                                }}
                            >
                                {heading.type === 'title' && <h1 className="font-semibold">{heading.text}</h1>}
                                {heading.type === 'heading-one' && <h1>{heading.text}</h1>}
                                {heading.type === 'heading-two' && <h2 className="pl-4">{heading.text}</h2>}
                                {heading.type === 'heading-three' && <h3 className="pl-8">{heading.text}</h3>}
                            </button>
                        );
                    })}
                </Panel.Body>
            </div>
        </Panel>
    );
};

export default TOCPanel;
