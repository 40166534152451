import React, { useState } from 'react';
import Button from '@/components/styled/Button';
import { UrlUtils } from '@saga/shared';
import { track } from '@/analytics';
import Modal from '@/components/Modal';
import TeamHandbookSvg from '@/assets/TeamHandbookSvg';
import { LoginState } from './LoginModal';
import Input from '../styled/Input';
import useRequestSignInLink from '@/hooks/useRequestSignInLink';

function TrySagaModal({ isOpen, onClose }: { isOpen: boolean; onClose(): void }) {
    const [email, setEmail] = useState('');
    const [state, setState] = useState<LoginState>(LoginState.INITIAL);
    const sendEmail = useRequestSignInLink();

    const onSubmit = async (event: any) => {
        event.preventDefault();
        setState(LoginState.LOADING);
        const { origin } = new URL(window.location.href);
        await sendEmail({ email, redirectUrl: `${origin}/try-saga?redirect=${window.location.pathname}` });
        setState(LoginState.EMAIL_SENT);
        track('try-saga-login-email-sent');
    };

    return (
        <Modal.Large isOpen={isOpen} onClose={onClose}>
            <Modal.CloseButton />
            <div className="bg-saga-primary-light">
                <Modal.HeroContainer>
                    <TeamHandbookSvg className="w-48 md:w-250 lg:w-400" />
                </Modal.HeroContainer>
            </div>

            {state === LoginState.EMAIL_SENT && (
                <Modal.Content>
                    <Modal.Title>📨 Check your inbox</Modal.Title>
                    <p>We just sent you a sign in link at this email address:</p>
                    <div className="flex items-center max-w-sm w-full justify-center space-x-2">
                        <Input type="email" id="email" value={email} disabled={true} />
                        <Button variant="secondary" onClick={() => setState(LoginState.INITIAL)}>
                            Edit
                        </Button>
                    </div>
                    <p>You can now close this modal.</p>
                </Modal.Content>
            )}

            {state !== LoginState.EMAIL_SENT && (
                <form onSubmit={onSubmit}>
                    <Modal.Content>
                        <Modal.Title>Make sense of your Knowledge</Modal.Title>
                        <p className="text-lg max-w-sm text-center">
                            Saga brings your docs and notes together to unleash their full potential.
                        </p>
                        <div className="space-y-4 max-w-sm w-full">
                            <Input
                                autoFocus={true}
                                type="email"
                                id="email"
                                value={email}
                                disabled={state === LoginState.LOADING}
                                onChange={(event) => setEmail(event.target.value)}
                                placeholder="Enter your email address..."
                            />
                            <Button
                                type="submit"
                                variant="primary"
                                className="w-full"
                                disabled={
                                    state === LoginState.LOADING || email.length === 0 || !UrlUtils.validateEmail(email)
                                }
                            >
                                Get Started
                            </Button>
                        </div>
                    </Modal.Content>
                </form>
            )}
        </Modal.Large>
    );
}

const OpenTrySagaModal = React.createContext(() => {});

export function useOpenTrySagaModal() {
    return React.useContext(OpenTrySagaModal);
}

export default function TrySagaModalProvider({ children }: { children: React.ReactNode }) {
    const [isLoginModalOpen, setLoginModalOpen] = React.useState(false);

    const open = React.useCallback(() => {
        track('try-saga-modal-opened');
        setLoginModalOpen(true);
    }, []);

    return (
        <OpenTrySagaModal.Provider value={open}>
            {children}
            <TrySagaModal isOpen={isLoginModalOpen} onClose={() => setLoginModalOpen(false)} />
        </OpenTrySagaModal.Provider>
    );
}
