import React from 'react';
import Tooltip from '@/components/popover/Tooltip';
import classNames from 'classnames';

export default function MemberAvatar({
    name,
    color,
    onClick,
    size = 'lg',
    showBorder = true,
}: {
    name: string;
    color: string;
    size?: 'xs' | 'sm' | 'md' | 'lg' | 'l' | '5';
    onClick?: (event: React.MouseEvent) => void;
    showBorder?: boolean;
    absolute?: boolean;
}) {
    const commonProps = {
        'data-testid': `collaborator:${name}`,
        className: classNames('not-sr-only relative uppercase font-bold select-none text-white shrink-0', {
            'h-6 w-6 text-xs rounded-md': size === 'lg',
            'h-5 w-5 text-xs rounded-5': size === '5',
            'h-[22px] w-[22px] text-xs rounded-5': size === 'l',
            'h-4 w-4 text-xxs rounded-md': size === 'sm',
            'h-[16px] w-[16px] text-xxs rounded-md': size === 'xs',
            '[height:1.15rem] [width:1.15rem] text-xxs rounded-md': size === 'md',
            'border border-white dark:border-saga-gray-800': showBorder,
        }),
        style: { backgroundColor: color },
    };

    const children = (
        <span className="text-center flex items-center justify-center inset-0 absolute">
            <span className="leading-none">{(name as string)?.slice(0, 1)}</span>
        </span>
    );

    return (
        <Tooltip content={name} placement="bottom">
            {onClick ? (
                <button {...commonProps} onClick={onClick}>
                    {children}
                </button>
            ) : (
                <span {...commonProps}>{children}</span>
            )}
        </Tooltip>
    );
}
