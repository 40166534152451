import { PageIcon } from '@/components/icons';
import Table from '@/components/table/Table';
import { TasksTableColumnKey, WeakTask } from '@saga/shared';
import React, { useState } from 'react';
import TaskContextMenuButton from '../popover/TaskContextMenuButton';
import AssigneeSelect from '../tasks/AssigneeSelect';
import { DueDateSelect } from '../tasks/DueDateSelect';
import { BlockPlugin } from '@/../../shared/src/Editor/Plugins';
import { FindInLocationsResult } from '@/../../shared/src/SpaceOperations/findInLocations';
import { useOpenLocation } from '../PageNavigationProvider';
import classNames from 'classnames';
import PrioritySelect from '../tasks/PrioritySelect';
import { formatTaskDate } from '@/../../shared/src/utils/DateUtils';
import { usePageAccess } from '@/components/PagesPermissionsBySpaceProvider';
import * as api from '@saga/api';
import TaskStatusSelect from '@/components/tasks/TaskStatusSelect';
import LabelSelect from '../tasks/LabelSelect';
import { useCollectionsSnapshot, useDocumentCreator, useTaskLabels } from '@/hooks/SpaceHooks';
import ManageCollectionsInput from '../popover/ManageCollectionsInput';
import { AVAILABLE_COLORS } from '@/constants';
import MemberAvatar from '../MemberAvatar';
import { stringToColor } from '../../../../shared/src/utils/Colors';

type RowProps = {
    columnKeys: TasksTableColumnKey[];
    task: WeakTask;
    onClick: (event: React.MouseEvent) => void;
    onChangeState(state: WeakTask['state']): void;
    onChangeAssignee({ id, action }: { id: string | null; action: api.TaskAssignAction }): void;
    onChangeDueDate(date: Date | null): void;
    onChangePriority(priority: WeakTask['priority']): void;
    blockPlugins: BlockPlugin[];
    referenceResults: FindInLocationsResult[];
    isMobile: boolean;
    onSelectLabel(id: string): void;
    onRemoveLabel(id: string): void;
    onCreateLabel(title: string): void;
    onChangeLabelColor(id: string, color: string): void;
    onCollectionClick(id: string, event: React.MouseEvent): void;
    onAddToCollection(id: string): void;
    onRemoveFromCollection(id: string): void;
    onCreateCollection(title: string): void;
    hiddenColumns: TasksTableColumnKey[];
};

function ReferenceResults({
    results,
    isMobile,
}: {
    results: FindInLocationsResult[];
    isMobile: boolean;
    blockPlugins: BlockPlugin[];
}) {
    const openLocation = useOpenLocation();

    return (
        <span className="inline-flex flex-wrap items-center gap-2 p-2 w-full">
            {results.map((result, i) => {
                if (result.type === 'page') {
                    const page = result.page;

                    return (
                        <span
                            onClick={(event) => openLocation(result.location, event)}
                            className="cursor-pointer space-x-2 flex items-center max-w-full"
                            key={i}
                        >
                            <span className="inline-block">
                                <PageIcon icon={page.icon} isTemplate={page.isTemplate} />
                            </span>
                            <span className="truncate">{page.title}</span>
                        </span>
                    );
                }

                const task = result.task;
                return (
                    <span
                        onClick={(event) => openLocation(result.location, event, { forceOtherIndex: !isMobile })}
                        className="cursor-pointer space-x-2 flex items-center max-w-full"
                        key={i}
                    >
                        <span className="inline-block">
                            <TaskStatusSelect status={task.state} disabled />
                        </span>
                        <span className="truncate">{task.title}</span>
                    </span>
                );
            })}
        </span>
    );
}

const TaskRow = ({
    columnKeys,
    hiddenColumns,
    task,
    onChangeAssignee,
    onChangeDueDate,
    onChangePriority,
    onSelectLabel,
    onCreateLabel,
    onRemoveLabel,
    onChangeLabelColor,
    onClick,
    onCollectionClick,
    onAddToCollection,
    onRemoveFromCollection,
    onCreateCollection,
    blockPlugins,
    onChangeState,
    referenceResults,
    isMobile,
}: RowProps) => {
    const [showContext, setShowContext] = useState<boolean>(false);
    const isTitleEmpty = task.title ? task.title.trim() === '' : true;
    const { canEdit } = usePageAccess(task.id);

    const labels = useTaskLabels();
    const collections = useCollectionsSnapshot();

    const creator = useDocumentCreator(task);

    return (
        <Table.Row
            key={task.id}
            onMouseOver={() => setShowContext(true)}
            onMouseLeave={() => setShowContext(false)}
            className="divide-x divide-saga-gray-150 dark:divide-saga-gray-800 py-1"
        >
            {columnKeys.map((key, index) => {
                const renderButtonIfFirstColumn = () =>
                    index === 0 && (
                        <>
                            <div className="h-8 w-8 absolute -ml-7"></div>
                            {canEdit && showContext && (
                                <div className="absolute -ml-7">
                                    <TaskContextMenuButton align="left" isButtonSmall editor={null} task={task} />
                                </div>
                            )}
                        </>
                    );

                switch (key) {
                    case 'title':
                        return hiddenColumns.includes(key) ? null : (
                            <Table.Cell
                                key={key}
                                className={classNames(
                                    'relative align-top py-2 px-2 min-w-[456px] max-w-[456px] w-full',
                                    {
                                        'pl-0': index === 0,
                                    },
                                )}
                            >
                                {renderButtonIfFirstColumn()}
                                <div className="flex items-center space-x-2 pl-1">
                                    <TaskStatusSelect status={task.state} onChange={onChangeState} />
                                    <Table.Button onClick={onClick}>
                                        <div
                                            className={classNames('flex space-x-2 items-center min-w-0', {
                                                'text-saga-gray-500': isTitleEmpty,
                                            })}
                                        >
                                            <div className="min-h-[28px] max-w-[456px] truncate flex items-center">
                                                {isTitleEmpty ? 'Untitled' : task.title}
                                            </div>
                                        </div>
                                    </Table.Button>
                                </div>
                            </Table.Cell>
                        );
                    case 'collection':
                        return hiddenColumns.includes(key) ? null : (
                            <Table.Cell
                                key={key}
                                className={classNames('align-top min-w-48 max-w-48 p-2', {
                                    'pl-0': index === 0,
                                })}
                            >
                                {renderButtonIfFirstColumn()}
                                <ManageCollectionsInput
                                    canEdit={canEdit}
                                    currentCollections={collections.filter((collection) =>
                                        task.collections?.includes(collection.id),
                                    )}
                                    availableCollections={collections.filter(
                                        (collection) => !task.collections?.includes(collection.id),
                                    )}
                                    onCollectionClick={onCollectionClick}
                                    onCreate={onCreateCollection}
                                    onSelect={onAddToCollection}
                                    onRemove={onRemoveFromCollection}
                                    canHighlight
                                    maxWidth={isMobile ? 250 : 400}
                                    canWrap
                                />
                            </Table.Cell>
                        );
                    case 'assignee':
                        return hiddenColumns.includes(key) ? null : (
                            <Table.Cell
                                key={key}
                                className={classNames('relative align-top p-2 w-full min-w-40 max-w-40', {
                                    'pl-0': index === 0,
                                })}
                            >
                                {renderButtonIfFirstColumn()}
                                <AssigneeSelect
                                    assignee={task.assignee}
                                    onChange={onChangeAssignee}
                                    disabled={!canEdit}
                                    showEmptyState={showContext}
                                />
                            </Table.Cell>
                        );
                    case 'dueDate':
                        return hiddenColumns.includes(key) ? null : (
                            <Table.Cell
                                key={key}
                                className={classNames('align-top no-wrap p-2 min-w-40 max-w-40', {
                                    'pl-0': index === 0,
                                })}
                            >
                                {renderButtonIfFirstColumn()}
                                <DueDateSelect
                                    task={task}
                                    onChange={onChangeDueDate}
                                    disabled={!canEdit}
                                    showEmptyState={showContext}
                                />
                            </Table.Cell>
                        );
                    case 'priority':
                        return hiddenColumns.includes(key) ? null : (
                            <Table.Cell
                                key={key}
                                className={classNames('align-top no-wrap p-2 min-w-40 max-w-40', {
                                    'pl-0': index === 0,
                                })}
                            >
                                {renderButtonIfFirstColumn()}
                                <PrioritySelect
                                    onChange={onChangePriority}
                                    assignedPriority={task.priority}
                                    showEmptyState={showContext}
                                />
                            </Table.Cell>
                        );
                    case 'references':
                        return hiddenColumns.includes(key) ? null : (
                            <Table.Cell
                                key={key}
                                className={classNames('min-w-48 max-w-48 align-top', {
                                    'pl-0': index === 0,
                                })}
                            >
                                {renderButtonIfFirstColumn()}
                                <ReferenceResults
                                    results={referenceResults}
                                    blockPlugins={blockPlugins}
                                    isMobile={isMobile}
                                />
                            </Table.Cell>
                        );
                    case 'labels':
                        return hiddenColumns.includes(key) ? null : (
                            <Table.Cell
                                key={key}
                                className={classNames('align-top no-wrap p-2 min-w-40 max-w-40', {
                                    'pl-0': index === 0,
                                })}
                            >
                                {renderButtonIfFirstColumn()}
                                <LabelSelect
                                    availableLabels={labels ?? []}
                                    selectedIds={task.labels ?? []}
                                    onSelectLabel={onSelectLabel}
                                    onCreateLabel={onCreateLabel}
                                    onRemoveLabel={onRemoveLabel}
                                    onSelectColor={onChangeLabelColor}
                                    disabled={!canEdit}
                                    showEmptyState={showContext}
                                />
                            </Table.Cell>
                        );
                    case 'createdBy':
                        return hiddenColumns.includes(key) ? null : (
                            <Table.Cell
                                key={key}
                                className={classNames('align-top min-w-40 max-w-40 no-wrap p-2', {
                                    'pl-0': index === 0,
                                })}
                            >
                                {renderButtonIfFirstColumn()}
                                {creator && (
                                    <div className="flex flex-row px-1.5 py-0.5">
                                        <div className="flex flex-row space-x-1">
                                            <MemberAvatar
                                                size="md"
                                                name={creator.name}
                                                color={stringToColor(creator.id, AVAILABLE_COLORS)}
                                            />
                                            <p className="text-sm truncate">{creator.name}</p>
                                        </div>
                                    </div>
                                )}
                            </Table.Cell>
                        );
                    case 'created':
                        return hiddenColumns.includes(key) ? null : (
                            <Table.Cell
                                key={key}
                                className={classNames('align-top no-wrap p-2 min-w-40 max-w-40', {
                                    'pl-0': index === 0,
                                })}
                            >
                                {renderButtonIfFirstColumn()}
                                <span className="text-sm">{formatTaskDate(task.createdAt)}</span>
                            </Table.Cell>
                        );
                    case 'updated':
                        return hiddenColumns.includes(key) ? null : (
                            <Table.Cell
                                key={key}
                                className={classNames('align-top no-wrap p-2 min-w-40 max-w-40', {
                                    'pl-0': index === 0,
                                })}
                            >
                                {renderButtonIfFirstColumn()}
                                <span className="text-sm">{formatTaskDate(task.updatedAt)}</span>
                            </Table.Cell>
                        );
                    case 'completedDate':
                        return hiddenColumns.includes(key) ? null : (
                            <Table.Cell
                                key={key}
                                className={classNames('align-top no-wrap p-2 min-w-40 max-w-40', {
                                    'pl-0': index === 0,
                                })}
                            >
                                {renderButtonIfFirstColumn()}
                                {task.state === 'DONE' && task.completedDate && (
                                    <span className="text-sm">{formatTaskDate(task.completedDate)}</span>
                                )}
                            </Table.Cell>
                        );
                }
            })}
        </Table.Row>
    );
};

export default TaskRow;
