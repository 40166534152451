import * as api from '@saga/api';
import { v4 as uuid } from 'uuid';
import { BlockBuilder, newBlankPage, Page } from '../..';
import { data } from './data';

function splitString(input: string, delimiters: string[]): string[] {
    // Create a regular expression pattern from the delimiters array
    const pattern = new RegExp(`(${delimiters.map((d) => d.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')).join('|')})`, 'g');

    // Split the input string using the pattern
    return input.split(pattern).filter(Boolean);
}

export function myFirstPage(language: api.Language): Page {
    const { pageTitle, callout, section1, section2, codeHighlight } = data;
    return newBlankPage({
        title: pageTitle[language],
        icon: { type: 'emoji', colons: ':wave:' },
        blocks: [
            BlockBuilder.title(pageTitle[language]),
            BlockBuilder.divider(),
            BlockBuilder.callout([BlockBuilder.paragraph(callout[language])], uuid(), 'pencil2'),
            BlockBuilder.heading(section1.title[language], 'heading-three'),
            ...section1.checkList.map((item) => {
                const delimiters = Object.values(codeHighlight);
                const hasDelimiter = delimiters.some((symbol) => item[language].includes(symbol));
                if (hasDelimiter) {
                    const result = splitString(item[language], delimiters);
                    return BlockBuilder.checkListItem(
                        result.map((string) => {
                            const options = delimiters.includes(string) ? { code: true } : {};
                            return BlockBuilder.text(string, options);
                        }),
                    );
                }
                return BlockBuilder.checkListItem([BlockBuilder.text(item[language])]);
            }),
            BlockBuilder.heading(section2.title[language], 'heading-three'),
            ...section2.checkList.map((item) => BlockBuilder.checkListItem(item[language])),
        ],
    });
}
