import React from 'react';
import { ReactEditor, useSlate } from 'slate-react';
import { PopOver, usePopover } from '@/components/popover/PopOver';
import classNames from 'classnames';
import { Bold, Italic, Underline, ChevronDown, Code, Link2, Link, ArrowRightCircle } from 'react-feather';
import SetLinkPopOver from '@/components/popover/SetLinkPopOver';
import FormatIcon from '@/components/styled/FormatIcon';
import isHotkey from 'is-hotkey';
import Tooltip from '@/components/popover/Tooltip';
import { track } from '@/analytics';

import {
    MarkType,
    SagaElement,
    isLiveBlockSource,
    isTitle,
    PATTERNS,
    UrlUtils,
    isSagaElement,
    isLink,
    EditorOperations,
    BlockBuilder,
    SpaceOperations,
    RealtimeSagaEditor,
    SagaLocation,
    SagaEditor,
    SafeSpace,
    Colors,
} from '@saga/shared';
import TurnIntoPopOver from '@/components/popover/TurnIntoPopOver';
import { Editor as SlateEditor, Path, Range, Transforms } from 'slate';
import MoveToPageButton from '@/components/popover/MoveToPageButton';
import SendAsLiveBlockButton from '@/components/popover/SendAsLiveBlockButton';
import useMobile from '@/hooks/useMobile';
import { useSpace } from '@/components/SpaceProvider';
import { useCreatePage, useMoveToPage, useSendAsLiveBlock } from '@/hooks/SpaceHooks';
import { useTurnIntoSuggestionsContext } from '@/components/TurnIntoSuggestions';
import { useQuickEditorSelectionOverflow } from '../QuickPageEditor';
import { useRecents } from '@/components/RecentsContext';
import { pageToPageSuggestion } from '@/lib/Suggestions';
import { getSdk } from '@/graphql';

import { useSetAIPopoverOpen } from '@/components/editor/popover/AIPopoverProvider';
import { Lightning } from '@/assets/icons';
import { useSettings } from '@/components/settings/SettingsProvider';
import { useTranslation } from 'react-i18next';
import { ColorButton, ColorPopOver } from '@/components/popover/ColorPopOver';
import { getOS } from '@/lib/helpers';
import { createTask } from '@/utils/documentUtils';
import { usePagesPermissions } from '@/components/PagesPermissionsBySpaceProvider';
import { useTaskFilters } from '@/hooks/useTaskFilters';

type FormatButtonProps = {
    editor: RealtimeSagaEditor;
    format: MarkType;
    children: React.ReactChild;
    value?: boolean | string;
};

const FormatButton = React.forwardRef<HTMLButtonElement, FormatButtonProps>(function FormatButton(
    { editor, format, value, children },
    ref,
) {
    const isActive = () => {
        try {
            return EditorOperations.Marks.isMarkActive(editor, format);
        } catch {
            return false;
        }
    };

    return (
        <button
            ref={ref}
            onMouseDown={(event: React.MouseEvent) => {
                event.preventDefault();
                EditorOperations.Marks.toggleMark(editor, format, value);
                track(`format-text-${format}`, { source: 'hovering-toolbar' });
            }}
            className={classNames(
                'h-8 px-2.5 cursor-pointer unselectable hover:bg-saga-gray-250 dark:hover:bg-saga-gray-800',
                {
                    'bg-saga-gray-150 stroke-saga-gray-150 dark:bg-saga-gray-700 dark:stroke-saga-gray-700': isActive(),
                },
            )}
            data-testid={`format-${format}`}
        >
            {children}
        </button>
    );
});

function isLinkActive(editor: SlateEditor, selection: Range) {
    const [link] = SlateEditor.nodes(editor, {
        at: selection,
        match: isLink,
    });
    return !!link;
}

const LinkButton = React.forwardRef<HTMLButtonElement, { editor: RealtimeSagaEditor; onClick: () => void }>(
    function LinkButton({ editor, onClick }, ref) {
        const { t } = useTranslation();
        const isActive = React.useMemo(() => {
            try {
                const slateRange = editor.selection;
                if (!slateRange) {
                    return;
                }
                return isLinkActive(editor, slateRange);
            } catch {
                return false;
            }
        }, [editor]);

        if (isActive) {
            return null;
        }

        return (
            <Tooltip content={isActive ? t('editor.edit_link') : t('editor.link_to')} placement={'top'}>
                <div className="flex items-center">
                    <button
                        data-testid="create-link"
                        ref={ref}
                        onClick={onClick}
                        className={classNames(
                            'w-9 h-8 flex items-center justify-center cursor-pointer unselectable focus:outline-none hover:bg-saga-gray-250 dark:hover:bg-saga-gray-800',
                            {
                                'bg-saga-bg-blue hover:bg-saga-bg-blue stroke-saga-blue text-saga-blue': isActive,
                            },
                        )}
                    >
                        <Link2
                            className={classNames('w-4', {
                                'text-saga-gray-dark dark:text-zinc-200': !isActive,
                                'text-saga-blue': isActive,
                            })}
                        ></Link2>
                    </button>
                </div>
            </Tooltip>
        );
    },
);

const CurrentColorButton = React.forwardRef<
    HTMLDivElement,
    {
        currentBackgroundColor: string;
        currentTextColor: string;
        isOpen: boolean | null;
        onMouseDown: React.MouseEventHandler<HTMLDivElement>;
    }
>(function CurrentColorButton({ currentBackgroundColor, currentTextColor, isOpen, onMouseDown }, ref) {
    const backgroundLabel = Colors.colorLabelByValue(currentBackgroundColor);
    const textLabel = Colors.colorLabelByValue(currentTextColor);

    return (
        <Tooltip content={'Highlight the selected text'} placement={'top'}>
            <div ref={ref} className="flex items-center">
                <ColorButton
                    testId="set-color"
                    isOpen={isOpen}
                    currentLabel={backgroundLabel || textLabel}
                    currentBackgroundColor={currentBackgroundColor}
                    currentTextColor={currentTextColor}
                    onMouseDown={onMouseDown}
                />
            </div>
        </Tooltip>
    );
});

type IProps = {
    editor: RealtimeSagaEditor;
    zIndex?: number;
    onClose(): void;
    canSearch: boolean;
};

// We need to check if the selected nodes contain a live block source or if only the title is selected,
// in which case we need to disable creating live blocks, because it's not possible
// to have nested live block sources or live block sources within the title
function isCreateLiveBlockSourceDisabled(selectedNodes: SagaElement[]) {
    return (
        (selectedNodes.length > 1 && selectedNodes.some((node) => isLiveBlockSource(node))) ||
        (selectedNodes.length === 1 && selectedNodes.some(isTitle))
    );
}

type PopoverRefs = {
    moveToPage: React.MutableRefObject<HTMLButtonElement | null>;
    sendReference: React.MutableRefObject<HTMLButtonElement | null>;
    linkPopover: React.MutableRefObject<HTMLButtonElement | null>;
    turnIntoPopover: React.MutableRefObject<HTMLButtonElement | null>;
    colorPopover: React.MutableRefObject<HTMLDivElement | null>;
    aiAssistPopover: React.MutableRefObject<HTMLButtonElement | null>;
};

const usePopoversController = () => {
    const refsMap = React.useRef<PopoverRefs>({
        sendReference: React.useRef(null),
        moveToPage: React.useRef(null),
        colorPopover: React.useRef(null),
        turnIntoPopover: React.useRef(null),
        linkPopover: React.useRef(null),
        aiAssistPopover: React.useRef(null),
    });

    const [popoverVisible, setPopoverVisible] = React.useState<keyof PopoverRefs | null>(null);

    return { refsMap, popoverVisible, setPopoverVisible };
};

function allowLinksInCurrentSelection(editor: SlateEditor) {
    if (editor.selection == null) return false;

    const { anchor, focus } = editor.selection;
    return (
        // check if paths are in the same block
        (Path.equals(anchor.path, focus.path) || Path.isSibling(anchor.path, focus.path)) &&
        // We check if there is at least one link inside, if not then we allow it
        SlateEditor.nodes(editor, { match: isLink, at: editor.selection }).next().value == null
    );
}

function getActiveLinkURL(editor: SlateEditor, selection: Range) {
    try {
        const [node] = SlateEditor.nodes(editor, {
            at: selection,
            match: isLink,
        });
        return node && node[0].url;
    } catch (e) {
        // ignore it
    }
    return null;
}

export function getRecentPageSuggestions(
    space: SafeSpace,
    currentLocation: SagaLocation.SagaLocation,
    recentLocations: SagaLocation.SagaLocation[],
    filter: (pageId: string) => boolean,
) {
    const pageSuggestions = SpaceOperations.getPages(
        space,
        ['id', 'title', 'icon', 'aliases', 'isTemplate', 'settings', 'updatedAt'],
        undefined,
        filter,
    ).map(pageToPageSuggestion);

    const recentsWithoutCurrentPage = recentLocations
        .filter(SagaLocation.isPageLocation)
        .filter((recentLocation) => !SagaLocation.areLocationsEqual(currentLocation, recentLocation))
        .map((l) => l.pageId);

    const pageSuggestionsWithoutRecents = pageSuggestions.filter((p) => !recentsWithoutCurrentPage.includes(p.page.id));

    const sortedPageSuggestions = pageSuggestions
        .filter((p) => recentsWithoutCurrentPage.includes(p.page.id))
        .sort((a, b) => recentsWithoutCurrentPage.indexOf(a.page.id) - recentsWithoutCurrentPage.indexOf(b.page.id));

    return [...sortedPageSuggestions, ...pageSuggestionsWithoutRecents];
}

const HoveringToolbar = ({ editor, zIndex, onClose }: IProps) => {
    const { space, provider } = useSpace();
    const container = SagaEditor.useContainer();
    const { refsMap, popoverVisible, setPopoverVisible } = usePopoversController();
    const isMobile = useMobile();
    const { selection } = editor;
    const { location } = SagaEditor.useEditorContext();
    const sendAsLiveBlock = useSendAsLiveBlock(location);
    const moveToPage = useMoveToPage();
    const { openAIPopover } = useSetAIPopoverOpen();
    const { recentLocations } = useRecents();
    const { t } = useTranslation();
    const { hasAccess } = usePagesPermissions();
    const taskFilters = useTaskFilters();

    const currentBakcgroundColor = EditorOperations.Marks.getMarks(editor)?.colorHighlighter ?? 'blank';
    const currentTextColor = EditorOperations.Marks.getMarks(editor)?.colorHighlighterText ?? 'blank';

    const getSuggestions = React.useCallback(
        () => getRecentPageSuggestions(space, location, recentLocations, hasAccess),
        [space, location, recentLocations, hasAccess],
    );

    const getPositionStyle = () => {
        if (getOS() === 'iOS' || getOS() === 'Android') {
            return 'below';
        } else {
            return 'above';
        }
    };

    const getOffsetStyle = () => {
        if (getOS() === 'iOS' || getOS() === 'Android') {
            return 8;
        } else {
            return -8;
        }
    };

    const value = selection ? SlateEditor.string(editor, selection) : '';

    const [popoverRef, contentRef] = usePopover({
        isOpen: true,
        position: getPositionStyle(),
        align: 'center',
        offset: {
            y: getOffsetStyle(),
        },
        target: React.useCallback(() => {
            if (!selection) {
                return null;
            }

            if (!container?.current) {
                return null;
            }
            const editorRect = container.current.getBoundingClientRect();
            const range = EditorOperations.Selection.safeToDOMRange(editor, selection);
            if (range == null) {
                return null;
            }
            let rangeRect = range.getBoundingClientRect();

            return {
                top: rangeRect.top,
                bottom: rangeRect.bottom,
                height: rangeRect.height,
                left: editorRect.left,
                right: editorRect.right,
                width: editorRect.width,
                x: editorRect.x,
                y: rangeRect.y,
                toJSON: () => {},
            };
        }, [container, editor, selection]),
        clickOutsideRefs: Object.values(refsMap),
        renderDeps: [selection],
    });

    const onSelectHighestBlocksWithoutTitle = () => {
        if (!selection) return;

        const reorderedSelection = EditorOperations.Selection.reorderSlateRange(selection);

        const [topLevelAnchor] = reorderedSelection.anchor.path;
        const [topLevelFocus] = reorderedSelection.focus.path;

        const anchor = SlateEditor.start(editor, [Math.max(topLevelAnchor, 1)]);
        const focus = SlateEditor.end(editor, [topLevelFocus]);

        const location = {
            anchor,
            focus,
        };

        Transforms.select(editor, location);
    };

    const onColorText = (value: string, type: string) => {
        switch (type) {
            case 'bg':
                if (value === 'blank') {
                    EditorOperations.Marks.removeMark(editor, 'colorHighlighter');
                } else {
                    EditorOperations.Marks.toggleMark(editor, 'colorHighlighter', value);
                }
                track(`color-background-${value}`, { source: 'hovering-toolbar' });

                break;

            case 'text':
                if (value === 'blank') {
                    EditorOperations.Marks.removeMark(editor, 'colorHighlighterText');
                } else {
                    EditorOperations.Marks.toggleMark(editor, 'colorHighlighterText', value);
                }
                track(`color-text-${value}`, { source: 'hovering-toolbar' });
                break;
        }
    };

    const currentURL = React.useMemo(() => {
        if (!selection) {
            return null;
        }
        return getActiveLinkURL(editor, selection);
    }, [editor, selection]);

    const togglePopover = React.useCallback(
        (tag: Parameters<typeof setPopoverVisible>[0]) => () => {
            const isAlreadyOpen = popoverVisible === tag;
            if (!isAlreadyOpen) {
                // Tracking section
                switch (tag) {
                    case 'turnIntoPopover':
                        track('open-turn-into-menu', { source: 'hovering-toolbar' });
                        break;
                    case 'colorPopover':
                        track('open-color-menu', { source: 'hovering-toolbar' });
                        break;
                    case 'linkPopover':
                        track('open-link-menu', { source: 'hovering-toolbar' });
                        break;
                    case 'moveToPage':
                        track('open-move-to-page-menu', { source: 'hovering-toolbar' });
                        break;
                    case 'sendReference':
                        track('open-send-reference-menu', { source: 'hovering-toolbar' });
                        break;
                }
            }

            setPopoverVisible(isAlreadyOpen ? null : tag);
        },
        [popoverVisible, setPopoverVisible],
    );

    const currentFormat = React.useMemo(() => {
        if (selection) {
            return EditorOperations.Selection.getFormat(editor, selection) ?? 'text';
        }
        return 'text';
    }, [editor, selection]);

    React.useEffect(() => {
        const shortcutOpen = (event: KeyboardEvent) => {
            if (editor.selection && Range.isExpanded(editor.selection)) {
                if (isHotkey('mod+k', event)) {
                    event.preventDefault();
                    event.stopImmediatePropagation();
                    togglePopover('linkPopover')();
                }

                if (isHotkey('esc', event)) {
                    event.preventDefault();
                    event.stopImmediatePropagation();
                    onClose();
                }
            }
        };

        window.addEventListener('keydown', shortcutOpen, { capture: true });
        return () => {
            window.removeEventListener('keydown', shortcutOpen, { capture: true });
        };
    }, [editor, onClose, togglePopover]);

    const allowLinks = allowLinksInCurrentSelection(editor);

    const nodes = selection
        ? [...SlateEditor.nodes(editor, { mode: 'highest', match: isSagaElement })].map(([node]) => node)
        : null;

    const createLiveReferenceDisabled = !nodes || isCreateLiveBlockSourceDisabled(nodes);

    const createPage = useCreatePage();
    const createPageFromValue = React.useCallback(
        (value: string, selection: Range, source: 'hovering-toolbar' | 'keyboard-shortcut') => {
            track('create-page', { source });

            const title = value.trim();
            Transforms.collapse(editor, { edge: 'end' });

            const page = createPage({ title }, { showToast: true });
            const inlinePageLink = SpaceOperations.createInlinePageLink(space, page.id, page.blocks);

            EditorOperations.Transforms.insertInlineNode(editor, inlinePageLink, selection);
            onClose();
        },
        [editor, onClose, space, createPage],
    );

    const createTaskHandle = ({ title }: { title: string }) => {
        track('create-task', { source: 'page-editor' });
        return createTask(space, { title, ...taskFilters }, provider);
    };

    React.useEffect(() => {
        const listener = (ev: KeyboardEvent) => {
            if (isHotkey('mod+shift+k', ev) && editor.selection && Range.isExpanded(editor.selection)) {
                ev.preventDefault();
                ev.stopPropagation();

                createPageFromValue(value, editor.selection, 'keyboard-shortcut');
            }
        };

        document.addEventListener('keydown', listener);

        return () => document.removeEventListener('keydown', listener);
    }, [createPageFromValue, editor.selection, value]);

    return (
        <PopOver isOpen={true} ref={popoverRef} contentRef={contentRef} zIndex={zIndex ?? 40}>
            <div
                data-testid="toolbar"
                onMouseDown={(e) => e.preventDefault()}
                className="h-8 relative flex bg-white dark:bg-saga-gray-900 rounded-lg shadow-popupSmall dark:border dark:border-solid dark:border-saga-gray-800 divide-x divide-saga-gray-150 dark:divide-saga-gray-800"
            >
                <Tooltip content={t('ai.ask_ai')} placement="top">
                    <button
                        ref={refsMap.current.aiAssistPopover}
                        onClick={() => {
                            if (!selection) {
                                return;
                            }

                            track('open-ai-popover', { source: 'hovering-toolbar' });
                            openAIPopover(editor.selection);
                        }}
                        className="flex items-center space-x-1 cursor-pointer unselectable text-saga-text dark:text-zinc-200"
                    >
                        <div className="h-8 rounded-l-lg flex flex-row items-center pl-1 pr-2 justify-center hover:bg-saga-gray-250 dark:hover:bg-saga-gray-800">
                            <Lightning className="fill-saga-text dark:fill-saga-gray-300 w-5" />
                            <span className="text-sm">{isMobile ? 'AI' : 'Saga AI'}</span>
                        </div>
                    </button>
                </Tooltip>

                {currentFormat !== 'none' && (
                    <Tooltip content={t('editor.turn_into')} placement="top">
                        <button
                            className="items-center flex cursor-pointer unselectable focus:outline-none "
                            ref={refsMap.current.turnIntoPopover}
                            onClick={togglePopover('turnIntoPopover')}
                            data-testid="turn-into"
                        >
                            <div className="h-8 flex flex-row items-center justify-center px-2 hover:bg-saga-gray-250 dark:hover:bg-saga-gray-800">
                                <FormatIcon type={currentFormat}></FormatIcon>
                                <span className="sr-only">{t('editor.turn_into')}</span>
                                <span className="mx-1 text-sm">{t('editor.turn_into')}</span>
                                <ChevronDown className="stroke-saga-text-gray dark:text-zinc-200 w-3 ml-1 py-1 inline-block" />
                            </div>
                        </button>
                    </Tooltip>
                )}
                <div className="flex text-saga-text dark:text-zinc-200">
                    <Tooltip content="Bold" placement="top">
                        <FormatButton editor={editor} format="bold">
                            <Bold className="w-4" />
                        </FormatButton>
                    </Tooltip>

                    <Tooltip content="Italic" placement="top">
                        <FormatButton editor={editor} format="italic">
                            <Italic className="w-4" />
                        </FormatButton>
                    </Tooltip>

                    <Tooltip content="Underline" placement="top">
                        <FormatButton editor={editor} format="underline">
                            <Underline className="w-4" />
                        </FormatButton>
                    </Tooltip>

                    <Tooltip content="Code Block" placement="top">
                        <FormatButton editor={editor} format="code">
                            <Code className="w-4" />
                        </FormatButton>
                    </Tooltip>
                </div>

                {!isMobile && (
                    <div className="flex items-center">
                        <CurrentColorButton
                            isOpen={popoverVisible === 'colorPopover'}
                            ref={refsMap.current.colorPopover}
                            currentBackgroundColor={currentBakcgroundColor}
                            currentTextColor={currentTextColor}
                            onMouseDown={(e) => {
                                e.preventDefault();
                                togglePopover('colorPopover')();
                            }}
                        />
                    </div>
                )}

                {allowLinks && (
                    <LinkButton
                        ref={refsMap.current.linkPopover}
                        editor={editor}
                        onClick={togglePopover('linkPopover')}
                    />
                )}

                {!isMobile && (
                    <>
                        {SagaLocation.isPageLocation(location) && (
                            <MoveToPageButton
                                popoverProps={{
                                    isOpen: popoverVisible === 'moveToPage',
                                    onClose: togglePopover('moveToPage'),
                                    onSubmit({ selectedItem }) {
                                        if (!selection) {
                                            return;
                                        }

                                        moveToPage({ location: selection, selectedItem });
                                        onClose();
                                    },
                                    getSuggestions,
                                }}
                                attachToRef={refsMap.current.moveToPage}
                                onOpen={(e) => {
                                    e.preventDefault();
                                    togglePopover('moveToPage')();
                                }}
                                zIndex={zIndex}
                            >
                                <div className="w-9 h-8 flex items-center justify-center cursor-pointer unselectable text-saga-text dark:text-zinc-200 hover:bg-saga-gray-250 dark:hover:bg-saga-gray-800">
                                    <span className="sr-only">{t('editor.move_to_page')}</span>
                                    <ArrowRightCircle className="w-4" />
                                </div>
                            </MoveToPageButton>
                        )}

                        {SagaLocation.isPageLocation(location) && (
                            <SendAsLiveBlockButton
                                attachToRef={refsMap.current.sendReference}
                                onOpen={(e) => {
                                    e.preventDefault();
                                    onSelectHighestBlocksWithoutTitle();
                                    setTimeout(() => {
                                        togglePopover('sendReference')();
                                    });
                                }}
                                createLiveReferenceDisabled={createLiveReferenceDisabled}
                                zIndex={zIndex}
                                popoverProps={{
                                    isOpen: popoverVisible === 'sendReference',
                                    onClose: togglePopover('sendReference'),
                                    onSubmit({ selectedItem }) {
                                        if (!selection) {
                                            return;
                                        }

                                        sendAsLiveBlock({
                                            selectedItem,
                                            location: selection,
                                        });

                                        onClose();
                                    },
                                    getSuggestions,
                                }}
                            >
                                <div className="w-9 h-8 rounded-r-lg flex items-center justify-center space-x-1 unselectable text-saga-text dark:text-zinc-200 hover:bg-saga-gray-250 dark:hover:bg-saga-gray-800">
                                    <Link size={16} />
                                    <span className="sr-only">{t('editor.create_live_block')}</span>
                                </div>
                            </SendAsLiveBlockButton>
                        )}
                    </>
                )}

                {/* {canSearch && (
                    <Tooltip content={'Search References'} placement="top">
                        <button
                            className="h-6 flex items-center px-3 space-x-1 cursor-pointer my-auto unselectable text-saga-gray-dark dark:text-zinc-200"
                            onClick={searchReferences}
                        >
                            <Search className="w-4"></Search>
                            <span>Search</span>
                        </button>
                    </Tooltip>
                )} */}
            </div>

            {popoverVisible === 'turnIntoPopover' && (
                <TurnIntoPopOver
                    currentFormat={currentFormat}
                    isOpen={true}
                    onClose={togglePopover('turnIntoPopover')}
                    attachToRef={refsMap.current.turnIntoPopover}
                    onTurnInto={(result) => {
                        if (!selection) {
                            return;
                        }

                        if (result.type === 'page') {
                            createPageFromValue(result.value, selection, 'hovering-toolbar');
                        } else if (result.type === 'block') {
                            track(`turn-into-${result.blockType}`, { source: 'hovering-toolbar' });
                            EditorOperations.Transforms.turnIntoBlock({
                                editor,
                                blockType: result.blockType,
                                range: selection,
                            });
                        } else if (result.type === 'task') {
                            track('create-task', { source: 'hovering-toolbar' });

                            const title = result.value.trim();
                            Transforms.collapse(editor, { edge: 'end' });

                            const task = createTaskHandle({ title });
                            const taskBlock = BlockBuilder.taskBlock(task.id, task);
                            Transforms.insertFragment(editor, [taskBlock], { at: selection });
                            onClose();
                        } else if (result.type === 'linear-issue') {
                            track(`turn-into-linear-issue`, { source: 'hovering-toolbar' });

                            const title = result.value.trim();
                            SpaceOperations.addLinearIssue(getSdk, editor, {
                                type: 'add',
                                teamId: result.team.id,
                                title,
                            });
                        }
                    }}
                    zIndex={zIndex ? zIndex + 10 : undefined}
                    selectedValue={value}
                    canTurnIntoPage
                    canTurnIntoTask
                    canTurnIntoLinearIssue
                />
            )}

            {popoverVisible === 'colorPopover' && (
                <ColorPopOver
                    isOpen={true}
                    setIsOpen={togglePopover('colorPopover')}
                    attachToRef={refsMap.current.colorPopover}
                    selectedColors={{ background: currentBakcgroundColor, text: currentTextColor }}
                    onCreate={onColorText}
                    zIndex={zIndex ? zIndex + 10 : undefined}
                />
            )}

            {allowLinks && popoverVisible === 'linkPopover' && (
                <SetLinkPopOver
                    isOpen={true}
                    setIsOpen={togglePopover('linkPopover')}
                    attachToRef={refsMap.current.linkPopover}
                    currentURL={currentURL}
                    onChange={(value) => {
                        if (!selection) {
                            return;
                        }

                        let url = value;
                        if (PATTERNS.email.test(url)) {
                            PATTERNS.email.lastIndex = 0;
                            url = UrlUtils.addMailTo(url);
                        } else {
                            url = UrlUtils.addHttps(url);
                        }
                        EditorOperations.Extensions.wrapLink(editor, selection, url);
                        ReactEditor.focus(editor);
                    }}
                    onRemove={() => {
                        if (!selection) {
                            return;
                        }
                        // if element is an empty link, remove it
                        Transforms.unwrapNodes(editor, {
                            at: selection,
                            match: isLink,
                            split: true,
                        });
                        onClose();
                    }}
                    canRemove={false}
                />
            )}
        </PopOver>
    );
};

export function HoveringToolbarContainer({ canSearch }: Readonly<{ canSearch: boolean }>) {
    const { canEdit, zIndex } = SagaEditor.useEditorContext();
    const { isOpen: isTurnIntoSuggestionsOpen } = useTurnIntoSuggestionsContext();
    const { isOpen: isAIPopoverOpen } = useSetAIPopoverOpen();

    const editor = useSlate();
    const isSelectionOverflowing = useQuickEditorSelectionOverflow();

    const settings = useSettings();

    const isOpen = Boolean(
        canEdit &&
            editor.selection != null &&
            SlateEditor.string(editor, editor.selection).length &&
            Range.isExpanded(editor.selection) &&
            !settings &&
            isTurnIntoSuggestionsOpen === false &&
            !isAIPopoverOpen,
    );

    if (isSelectionOverflowing || !isOpen) {
        return null;
    }

    return (
        <HoveringToolbar
            onClose={() => {
                const selection = editor.savedSelection ?? editor.selection;
                if (selection) {
                    EditorOperations.Selection.safeSelectEdge(editor, 'end', selection);
                }
            }}
            editor={editor}
            zIndex={zIndex}
            canSearch={canSearch}
        />
    );
}
