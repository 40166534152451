import React from 'react';
import { RenderLeafProps } from 'slate-react';
import { Colors } from '../utils';

function RenderLeaf(props: RenderLeafProps) {
    let { attributes, children, leaf } = props;
    const isSelected = leaf.selected;

    if (leaf.bold) {
        children = <strong>{children}</strong>;
    }

    if (leaf.code && leaf.text !== '') {
        children = <code className="text-base px-1 py-0.5 rounded">{children}</code>;
    }

    if (leaf.italic) {
        children = <em>{children}</em>;
    }

    if (leaf.underline) {
        children = <u>{children}</u>;
    }

    // autolink or url decoration
    if (leaf.highlight) {
        children = <span style={{ backgroundColor: 'rgba(254, 224, 2, 0.25)' }}>{children}</span>;
    }

    if (leaf.colorHighlighter) {
        const colorNormalized = Colors.convertOutdatedColor(leaf.colorHighlighter as unknown as string);
        const colorLabel = Colors.colorLabelByValue(colorNormalized);

        children = (
            <span
                data-testid={`background-color-highlighter-${colorLabel}`}
                className={`bg-opacity-50 dark:bg-opacity-30 ${colorNormalized}`}
            >
                {children}
            </span>
        );
    }

    if (leaf.colorHighlighterText) {
        const colorNormalized = Colors.convertOutdatedColor(leaf.colorHighlighterText);
        const colorLabel = Colors.colorLabelByValue(colorNormalized);

        children = (
            <span data-testid={`text-color-highlighter-${colorLabel}`} className={`${colorNormalized}`}>
                {children}
            </span>
        );
    }

    if (isSelected && leaf.text !== '') {
        children = (
            <span
                data-selection="true"
                className={leaf.code ? '' : 'bg-saga-selection-blue dark:text-saga-text'}
                style={{ padding: '0.20em 0' }}
            >
                {children}
            </span>
        );
    }

    return (
        <span
            {...attributes}
            style={{
                position: 'relative',
            }}
        >
            {children}
        </span>
    );
}

export default RenderLeaf;
