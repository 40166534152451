import modalStyle from '@/modalStyle';
import classNames from 'classnames';
import React from 'react';
import { X } from 'react-feather';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';
import Spinner from './loading/Spinner';
import Button, { ButtonNew } from './styled/Button';

type ModalProps = {
    children: React.ReactNode;
    onClose?: () => void;
    isOpen: boolean;
    onAfterOpen?: ReactModal.OnAfterOpenCallback;
    onAfterClose?: () => void;
    maxWidth?: string;
    ariaHideApp?: boolean;
    maxHeight?: string;
    width?: string;
    height?: string;
    zIndex?: number;
    shouldCloseOnOverlayClick?: boolean;
    shouldCloseOnEsc?: boolean;
};

Modal.Context = {
    CloseModalContext: React.createContext(() => {}),
};

function Modal({
    children,
    maxWidth,
    maxHeight,
    width,
    height,
    zIndex = 300,
    shouldCloseOnOverlayClick = true,
    shouldCloseOnEsc = true,
    ...props
}: ModalProps) {
    return (
        <ReactModal
            shouldCloseOnEsc={shouldCloseOnEsc}
            shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
            onRequestClose={props.onClose}
            style={modalStyle({
                position: 'center',
                darkOverlay: true,
                zIndex,
                css: {
                    maxWidth,
                    maxHeight,
                    height,
                    width: width || '100%',
                    border: undefined,
                },
            })}
            {...props}
        >
            {props.onClose && (
                <Modal.Context.CloseModalContext.Provider value={props.onClose}>
                    {children}
                </Modal.Context.CloseModalContext.Provider>
            )}
            {!props.onClose && children}
        </ReactModal>
    );
}

Modal.Jumbo = function ModalLarge(props: Omit<ModalProps, 'maxWidth'>) {
    return <Modal {...props} width="auto" />;
};

Modal.Large = function ModalLarge(props: Omit<ModalProps, 'maxWidth'>) {
    return <Modal {...props} maxWidth="48rem" />;
};

Modal.Medium = function ModalLarge(props: Omit<ModalProps, 'maxWidth'>) {
    return <Modal {...props} maxWidth="38rem" />;
};

Modal.Small = function ModalLarge(props: Omit<ModalProps, 'maxWidth'>) {
    return <Modal {...props} maxWidth="32rem" />;
};

Modal.HeroContainer = function ModalHeroContainer({ children }: { children: React.ReactNode }) {
    return <div className="py-2 w-full flex justify-center items-center">{children}</div>;
};

Modal.HeroContainerWithoutPadding = function ModalHeroContainer({ children }: { children: React.ReactNode }) {
    return <div className="w-full flex justify-center items-center">{children}</div>;
};

Modal.CloseButton = function ModalCloseButton({ dark = true }: { dark?: boolean }) {
    const onClose = React.useContext(Modal.Context.CloseModalContext);
    const { t } = useTranslation();
    return (
        <div className="absolute top-0 right-0 m-2">
            <button
                onClick={onClose}
                className={classNames(
                    'p-2 rounded focus-visible:bg-saga-gray-dark/10 hover:bg-saga-gray-dark/10 focus:outline-none active:bg-saga-gray-200 active:shadow-xs',
                    {
                        dark: 'dark:hover:bg-zinc-600',
                    },
                )}
            >
                <span className="sr-only">{t('common.close_modal')}</span>
                <X size={20} className={classNames('text-saga-gray-dark', { 'dark:text-zinc-200': dark })} />
            </button>
        </div>
    );
};

Modal.Content = function ModalContent({ children, dataTestId }: { children: React.ReactNode; dataTestId?: string }) {
    return (
        <div
            className="p-4 md:p-8 space-y-2 md:space-y-5 bg-white dark:bg-zinc-700 flex flex-col items-center"
            data-testid={dataTestId}
        >
            {children}
        </div>
    );
};

Modal.Title = function ModalTitle({ children, center = true }: { children: React.ReactNode; center?: boolean }) {
    return (
        <h1
            className={classNames('text-xl md:text-3xl font-semibold text-saga-black dark:text-zinc-200', {
                'text-center': center,
            })}
        >
            {children}
        </h1>
    );
};

Modal.Input = function ModalInput({
    inputRef,
    ...props
}: {
    inputRef?: React.RefObject<HTMLInputElement>;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'className'>) {
    return <input {...props} ref={inputRef} className="px-4 py-2 border rounded text-base"></input>;
};

export function ModalLayout({
    children,
    title,
    disabledOnLoading,
    primaryButtonLabel,
    primaryButtonAction,
    primaryButtonDisabled,
    secondaryButtonLabel,
    secondaryButtonAction,
    onCloseAction,
    dataTestId,
}: {
    children: React.ReactNode;
    title: string;
    disabledOnLoading?: boolean;
    primaryButtonLabel: string;
    primaryButtonAction: () => void;
    primaryButtonDisabled?: boolean;
    secondaryButtonLabel?: string;
    secondaryButtonAction?: () => void;
    onCloseAction: () => void;
    dataTestId?: string;
}) {
    return (
        <div
            className={`relative p-7 rounded-lg max-w-xl bg-saga-gray-100 dark:bg-zinc-700 dark:text-zinc-200 text-saga-gray-600`}
            data-testid={dataTestId}
        >
            <div className="font-bold text-base mb-3 text-saga-text dark:text-saga-text-darkmode">{title}</div>
            {children}
            <div className="flex items-center space-x-3 justify-end mt-6">
                {secondaryButtonLabel && secondaryButtonAction && (
                    <ButtonNew
                        variant="secondary"
                        value={secondaryButtonLabel}
                        onClick={secondaryButtonAction}
                        disabled={disabledOnLoading}
                    >
                        {secondaryButtonLabel}
                    </ButtonNew>
                )}
                <ButtonNew
                    variant="primary"
                    disabled={primaryButtonDisabled || disabledOnLoading}
                    value={primaryButtonLabel}
                    onClick={primaryButtonAction}
                    icon={disabledOnLoading && <Spinner size={16} />}
                >
                    {primaryButtonLabel}
                </ButtonNew>
            </div>

            <div className="absolute top-0 right-0 m-2">
                <Button.XButton disabled={disabledOnLoading} onClick={onCloseAction} />
            </div>
        </div>
    );
}

export default Modal;
