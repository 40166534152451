import React, { ButtonHTMLAttributes } from 'react';
import classNames from 'classnames';
import { CheckCircle } from 'react-feather';
import { useSingleToastContext } from '@/components/toast/ToastContext';
import { useSpring, animated } from 'react-spring';
import Button from '../styled/Button';
import { useTranslation } from 'react-i18next';

interface PropsWithClassName {
    className?: string;
}

interface PropsWithChildren {
    children: React.ReactNode;
}

const Toast = ({
    children,
    className,
    icon = <CheckCircle className="stroke-saga-blue w-5 flex-none mr-2" />,
    ...props
}: React.HTMLAttributes<HTMLDivElement> & { icon?: React.ReactNode }) => {
    const { onClose } = useSingleToastContext();
    const { t } = useTranslation();
    const transitionSpring: any = useSpring({
        from: { opacity: 0, y: 20, pointerEvents: 'none' },
        to: { opacity: 1, y: 0, pointerEvents: 'auto' },
        delay: 250,
    });
    return (
        <animated.div
            style={{ ...transitionSpring }}
            className={`flex flex-row pl-4 pr-6 py-4 text-left bg-white dark:bg-saga-gray-900 rounded-md border border-saga-gray-200 dark:border-saga-gray-800 w-full shadow-popupSmall justify-start space-x-2 ${
                className ? className : ''
            }`}
            {...props}
        >
            {icon}
            <div className="flex flex-col space-y-2 min-w-0 w-full divide-y divide-saga-gray-150 dark:divide-saga-gray-800">
                {children}
            </div>
            <div className="absolute top-3 right-2">
                <Button.XButton
                    onClick={onClose}
                    aria-label={t('common.close_popup') as string}
                    data-testid="close-popup"
                />
            </div>
        </animated.div>
    );
};

Toast.Title = function Title({ children, className }: PropsWithClassName & PropsWithChildren) {
    return <h1 className={classNames('text-base font-semi-bold pr-8', className)}>{children}</h1>;
};

Toast.Subtitle = function Subtitle({ children, className }: PropsWithClassName & PropsWithChildren) {
    return <div className={classNames('text-saga-gray-700 dark:text-zinc-200 pt-4 pb-2', className)}>{children}</div>;
};

Toast.Description = function Description({ children, className }: PropsWithClassName & PropsWithChildren) {
    return (
        <div className={classNames('text-saga-gray-700 dark:text-zinc-200 pt-4 pb-2 font-normal text-sm', className)}>
            {children}
        </div>
    );
};

Toast.CTA = function CTA({
    children,
    className,
    onClick,
    variant = 'primary',
}: PropsWithClassName &
    PropsWithChildren &
    ButtonHTMLAttributes<HTMLButtonElement> & { variant: 'primary' | 'secondary' }) {
    const { onClose } = useSingleToastContext();

    return (
        <button
            className={classNames(
                'text-saga-blue dark:text-zinc-200 text-center py-1 px-2 hover:text-opacity-75',
                {
                    'rounded-md border border-saga-gray-200 dark:border-zinc-600 shadow-md hover:bg-saga-gray-200 dark:hover:bg-zinc-600':
                        variant === 'primary',
                },
                className,
            )}
            onClick={(e) => {
                if (onClick) {
                    onClick(e);
                    onClose();
                }
            }}
        >
            {children}
        </button>
    );
};

export default Toast;
