import React, { RefObject, useRef, useState } from 'react';
import Button from '../styled/Button';
import { useTranslation } from 'react-i18next';
import Dropdown from '../popover/Dropdown';

import classNames from 'classnames';
import { InProgress } from '@/assets/icons';

type Status = 'OPEN' | 'IN_PROGRESS' | 'DONE';
type IconSize = 'xs' | 'small' | 'medium';

const Check = ({ className }: { className?: string }) => (
    <svg className={className} fill="none" viewBox="0 0 12 9" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.914.293a1 1 0 0 1 0 1.414l-6.207 6.5a1 1 0 0 1-1.414 0l-3-3a1 1 0 1 1 1.414-1.414L4 6.086 9.5.293a1 1 0 0 1 1.414 0Z"
            fill="#fff"
        />
    </svg>
);

export function StatusIcon({ status, size = 'small' }: { status: Status; size?: IconSize }) {
    return (
        <div
            className={classNames('relative', {
                'w-5': size === 'small',
                'w-8': size === 'medium',
            })}
        >
            <div
                className={classNames(
                    'task-checkmark select-none border hover:bg-saga-gray-dark/10 dark:hover:bg-zinc-400/20',
                    {
                        'dark:border-zinc-200 border-saga-gray-500/50 ': status === 'OPEN',
                        'hover:bg-saga-new-purple-dark dark:hover:bg-saga-new-purple/40 bg-saga-new-purple dark:bg-saga-new-purple/50 border-0':
                            status === 'DONE',
                        'border-saga-new-yellow-medium': status === 'IN_PROGRESS',
                        '[height:32px] [width:32px]': size === 'medium',
                        '[height:18px] [width:18px]': size === 'small',
                        '[height:14px] [width:14px]': size === 'xs',
                    },
                )}
            />
            <div
                className="absolute inset-0 text-white pointer-events-none"
                style={{
                    height: size === 'medium' ? 32 : size === 'xs' ? 14 : 18,
                    width: size === 'medium' ? 32 : size === 'xs' ? 14 : 18,
                }}
            >
                <div className="flex items-center justify-center absolute h-full w-full">
                    <Check
                        className={classNames(
                            'absolute m-auto left-0 right-0 transition-transform origin-bottom-left duration-150',
                            {
                                'scale-100 rotate-0': status === 'DONE',
                                'scale-0 -rotate-45': status === 'OPEN' || status === 'IN_PROGRESS',
                                'h-5 w-5': size === 'medium',
                                'h-3 w-3': size === 'small',
                                'h-2 w-2': size === 'xs',
                            },
                        )}
                    />
                    <InProgress
                        className={classNames(
                            'absolute m-auto left-0 right-0 transition-transform origin-bottom-left duration-150',
                            {
                                'scale-100 rotate-0 text-saga-new-yellow-medium': status === 'IN_PROGRESS',
                                'scale-0 -rotate-45': status === 'OPEN' || status === 'DONE',
                                'h-8 w-8': size === 'medium',
                                'h-[18px] w-[18px]': size === 'small',
                                'h-[14px] w-[14px]': size === 'xs',
                            },
                        )}
                    />
                </div>
            </div>
        </div>
    );
}

function StatusDropdown({
    attachToRef,
    isOpen,
    onClose,
    onChange,
}: {
    attachToRef: RefObject<HTMLElement>;
    isOpen: boolean;
    onClose(): void;
    onChange(status: Status): void;
}) {
    const { t } = useTranslation();

    return (
        <Dropdown attachToRef={attachToRef} isOpen={isOpen} onClose={onClose}>
            {(['DONE', 'IN_PROGRESS', 'OPEN'] as Status[]).map((status, index) => (
                <Button.PopOverButton
                    key={index}
                    selected={false}
                    onClick={(e) => {
                        e.stopPropagation();
                        onChange(status);
                        onClose();
                    }}
                    hoverable
                >
                    <div className="space-x-2 flex items-center">
                        <StatusIcon status={status} />
                        <div data-testid={`task-status-select-${status.toLowerCase()}`}>
                            {t(`tasks.status_${status.toLowerCase()}`)}
                        </div>
                    </div>
                </Button.PopOverButton>
            ))}
        </Dropdown>
    );
}

export default function TaskStatusSelect({
    onChange,
    status,
    disabled,
    size = 'small',
    showLabel = false,
}: {
    onChange?: (status: Status) => void;
    status: Status;
    showLabel?: boolean;
    size?: IconSize;
    disabled?: boolean;
}) {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const divRef = useRef<HTMLDivElement>(null);
    const { t } = useTranslation();
    return (
        <div ref={divRef}>
            <div
                onClick={(e) => {
                    if (disabled) return;
                    setIsDropdownOpen(!isDropdownOpen);
                    e.stopPropagation();
                }}
                data-testid="task-status-select"
                className={classNames('flex flex-row gap-1 items-center cursor-pointer', {
                    'px-1 min-h-[24px] py-0.5 border border-saga-gray-200 dark:border-saga-gray-900 rounded-md focus:outline-none':
                        showLabel,
                })}
            >
                <StatusIcon status={status} size={size} />
                {showLabel && <div className="text-sm">{t(`tasks.status_${status.toLowerCase()}`)}</div>}
            </div>
            {isDropdownOpen && (
                <StatusDropdown
                    attachToRef={divRef}
                    isOpen={isDropdownOpen}
                    onClose={() => setIsDropdownOpen(false)}
                    onChange={(status) => onChange?.(status)}
                />
            )}
        </div>
    );
}
