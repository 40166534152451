import { track } from '@/analytics';
import * as api from '@saga/api';
import React, { FormEvent, useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useSetSettings } from '../settings/SettingsProvider';
import Button from '../styled/Button';
import InviteMemberInput from './InviteMemberInput';

type MemberInvite = {
    email: string;
    role: api.Role;
    isPaidFeature: boolean;
};

function newMember(isSubscriptionActive: boolean): MemberInvite {
    return {
        email: '',
        role: isSubscriptionActive ? api.Role.Member : api.Role.Owner,
        isPaidFeature: !isSubscriptionActive,
    };
}

function initialMemberInvites(isSubscriptionActive: boolean, count: number): MemberInvite[] {
    const invites = [];
    for (let i = 0; i < count; i++) {
        invites.push(newMember(isSubscriptionActive));
    }
    return invites;
}

const MEMBER_INVITES_ON_PAID_PLAN = 3;

export default function InviteMemberForm({
    urlKey,
    onInviteCompleted,
    onMemberInvitesChanged,
    isSubscriptionActive,
    maxInvitedMembers,
    totalAllowedMembersForSpace,
    canInviteAdmins,
}: {
    urlKey: string;
    onInviteCompleted(): void;
    onMemberInvitesChanged?: (memberInvites: MemberInvite[]) => void;
    isSubscriptionActive: boolean;
    maxInvitedMembers: number | null;
    totalAllowedMembersForSpace: number | null;
    canInviteAdmins: boolean;
}) {
    const onMemberInvitesChangedHandler = useRef(onMemberInvitesChanged);
    onMemberInvitesChangedHandler.current = onMemberInvitesChanged;
    const history = useHistory();
    const { t } = useTranslation();
    const setSettings = useSetSettings();
    const [inviteMembers, { loading }] = api.useInviteMembersMutation({
        onCompleted: onInviteCompleted,
        refetchQueries: [api.InvitesAndMembersDocument],
    });
    const [memberInvites, setMemberInvites] = useState<MemberInvite[]>(
        initialMemberInvites(
            isSubscriptionActive,
            maxInvitedMembers === null ? MEMBER_INVITES_ON_PAID_PLAN : maxInvitedMembers,
        ),
    );

    useEffect(() => {
        onMemberInvitesChangedHandler.current && onMemberInvitesChangedHandler.current(memberInvites);
    }, [memberInvites]);

    const onSubmit = (evt: FormEvent<HTMLFormElement>) => {
        evt.preventDefault();
        const newMembers = memberInvites
            .filter(({ email }) => email.trim() !== '')
            .map(({ email, role }) => ({ email, role }));

        inviteMembers({
            variables: {
                input: { urlKey, newMembers },
            },
        });

        track('send-invites', { invitesNumbers: memberInvites.length });
    };

    const isMemberInviteAllowed = maxInvitedMembers === null || maxInvitedMembers > memberInvites.length;

    const onUpgradeClick = () => {
        setSettings('plans');
        history.push(`/s/${urlKey}`);
    };

    return (
        <form onSubmit={onSubmit} className="pt-5 pb-4 sm:pt-20 sm:pb-16 space-y-8 max-w-lg w-full" autoComplete="off">
            <div className="space-y-4 flex flex-col w-full" data-testid="invite-member-form">
                {memberInvites.map((memberInvite, index) => {
                    return (
                        <div key={index} className="flex w-full">
                            <InviteMemberInput
                                canInviteAdmins={canInviteAdmins}
                                inputProps={{
                                    id: `email-${index}`,
                                    name: `email-${index}`,
                                    type: 'email',
                                    autoComplete: `email-${index}`,
                                    autoFocus: index == 0,
                                    placeholder: 'name@email.com',
                                }}
                                onChange={(memberInvite) => {
                                    if (memberInvite.isPaidFeature && memberInvite.role !== api.Role.Owner) {
                                        onUpgradeClick();
                                        return;
                                    }

                                    setMemberInvites((members) =>
                                        members.map((member, i) => {
                                            if (i === index) {
                                                return memberInvite;
                                            }
                                            return member;
                                        }),
                                    );
                                }}
                                value={memberInvite}
                            />
                        </div>
                    );
                })}
            </div>

            {!isMemberInviteAllowed && (
                <p className="text-saga-gray-600 pl-4">
                    <Trans
                        i18nKey="settings.invite.max_invites_reached"
                        values={{ maxInvitedMembers: maxInvitedMembers, totalMembers: totalAllowedMembersForSpace }}
                        components={{
                            bold: <span className="font-bold" />,
                            upgrade: (
                                <span
                                    onClick={onUpgradeClick}
                                    className="cursor-pointer text-saga-new-blue hover:text-saga-gray-500/70 dark:text-zinc-400 dark:hover:text-zinc-300 transition-colors"
                                ></span>
                            ),
                        }}
                    />
                </p>
            )}

            <div className="flex justify-between w-full">
                <Button
                    type="button"
                    disabled={loading || !isMemberInviteAllowed}
                    onClick={() => setMemberInvites((members) => [...members, newMember(isSubscriptionActive)])}
                    variant="secondary"
                >
                    {t('settings.members.plus_add_more')}
                </Button>

                <div className="flex space-x-2">
                    <Button disabled={loading || memberInvites.every(({ email }) => email.trim() === '')} type="submit">
                        {t('settings.members.send_invites')}
                    </Button>
                </div>
            </div>
        </form>
    );
}
