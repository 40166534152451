import React from 'react';
import { useFirebaseContext } from '@/components/FirebaseContext';
import { hasValidCookies, updateCloudfrontMetadata } from '@/utils/cookie';
import { BACKEND_URL } from '@/constants';
import { useLocation } from 'react-router-dom';
import { Location } from 'history';
import { useContext } from 'react';
import { captureException } from '@sentry/react';
import { isLeft } from 'fp-ts/lib/Either';
import { CookieUtils } from '@saga/shared';
import { User } from '@firebase/auth';
import { debugLog } from '@/utils';

async function requestCloudfrontSignedCookies(urlKey: string, type: CookieUtils.CookieType, user?: User | null) {
    const csrfToken = document.head.querySelector('meta[name="x-csrf-token"]')!.getAttribute('content');
    if (!csrfToken) {
        captureException('CSRF Token not found', { extra: { urlKey, user: user?.uid } });
        debugLog('CSRF Token not found');
        return;
    }

    const token = await user?.getIdToken();
    const body: CookieUtils.SignedCookiesRequestInputV2 = { token, urlKey, type };
    const result = await fetch(`${BACKEND_URL}signed-cookies-v2`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'x-csrf-token': csrfToken },
        credentials: 'include',
        body: JSON.stringify(body),
    });

    const jsonResult = await result.json();
    const decoded = CookieUtils.cloudfrontMetadataDV2.decode(jsonResult);

    if (result.status !== 200) {
        captureException(jsonResult);
        debugLog('Requesting Cloudfront signed cookies failed.');
        return null;
    }

    if (isLeft(decoded)) {
        captureException(decoded.left);
        debugLog('Decoding Cloudfront signed cookies result failed.');
        return null;
    }

    return decoded.right;
}

function useOnRouteChange(onChange: (location: Location<unknown>) => void) {
    const location = useLocation();
    const handlers = React.useRef({ onChange });

    React.useEffect(() => {
        handlers.current.onChange(location);
    }, [location]);
}

const ResetCookiesIfNeededContext = React.createContext<() => Promise<void>>(() => Promise.resolve());
export const useResetCookiesIfNeeded = () => useContext(ResetCookiesIfNeededContext);

async function resetCookies(urlKey: string, type: CookieUtils.CookieType, firebaseUser?: User | null) {
    debugLog('Resetting Cloudfront Cookies');

    const result = await requestCloudfrontSignedCookies(urlKey, type, firebaseUser);

    if (result) {
        updateCloudfrontMetadata(result);
    }
}

export default function CloudfrontCookiesProvider({
    children,
    cookieType,
    urlKey,
}: {
    children: React.ReactNode;
    cookieType: CookieUtils.CookieType;
    urlKey: string;
}) {
    const { firebaseUser } = useFirebaseContext();

    const resetCookiesIfNeeded = React.useCallback(async () => {
        if (hasValidCookies(urlKey)) return;
        await resetCookies(urlKey, cookieType, firebaseUser);
    }, [firebaseUser, cookieType, urlKey]);

    useOnRouteChange(resetCookiesIfNeeded);

    React.useEffect(() => {
        // Initially we always reset the cookies to make sure that a page load fixes any inconsistent state
        // (e.g. cookies have been deleted but not local storage)
        resetCookies(urlKey, cookieType, firebaseUser);
    }, [firebaseUser, cookieType, urlKey]);

    return (
        <ResetCookiesIfNeededContext.Provider value={resetCookiesIfNeeded}>
            {children}
        </ResetCookiesIfNeededContext.Provider>
    );
}
