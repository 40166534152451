import * as t from 'io-ts';
import * as E from 'fp-ts/Either';
import reporter from 'io-ts-reporters';
import * as Sentry from '@sentry/browser';
import { identity, pipe } from 'fp-ts/lib/function';
import React from 'react';

export function getStoredOrDefault<A>(key: string, decoder: (i: unknown) => t.Validation<A>, defaultValue: A): A {
    return pipe(
        E.tryCatch(() => {
            const stored = localStorage.getItem(key);

            return stored ? JSON.parse(stored) : defaultValue;
        }, E.toError),
        E.chainW(decoder),
        E.fold((error) => {
            console.warn(error);

            if (error instanceof Error) {
                Sentry.captureException(error);
            } else {
                const errorMessage = reporter.report(E.left(error)).join();

                Sentry.captureMessage(`getStoredOrDefault decoding fail`, { extra: { message: errorMessage, key } });
            }

            return defaultValue;
        }, identity),
    );
}

export function useLocalStorageValue<A>(
    key: string,
    decoder: (i: unknown) => t.Validation<A>,
    defaultValue: A,
): [A, (value: A) => void] {
    const [trigger, reload] = React.useReducer(() => ({}), {});
    const value = React.useMemo(() => {
        trigger;
        return getStoredOrDefault(key, decoder, defaultValue);
    }, [key, decoder, defaultValue, trigger]);

    const update = React.useCallback(
        (nextValue: A) => {
            const result = decoder(nextValue);
            if (E.isRight(result)) {
                localStorage.setItem(key, JSON.stringify(result.right));
                reload();
            } else {
                throw new Error('updating not possible');
            }
        },
        [key, decoder],
    );

    return [value, update];
}
