import React, { useRef, MouseEventHandler } from 'react';
import { Plus } from 'react-feather';
import { CreateSuggestion } from '@/types';
import Button from '../styled/Button';

const CreateButton = ({
    children,
    onCreate,
}: {
    children: string;
    placeholder: string;
    onSuggest(search: string): CreateSuggestion | CreateSuggestion[];
    onCreate: MouseEventHandler<HTMLButtonElement> | undefined;
}) => {
    const ref = useRef<HTMLButtonElement>(null);

    return (
        <>
            <Button.Action ref={ref} onClick={onCreate}>
                <Button.WithIconPadding>
                    <div className="flex items-center h-5">
                        <Plus className="flex-none mr-1.5" size={14} />
                        <div className="h-8 flex items-center">
                            <p className="truncate">{children}</p>
                        </div>
                    </div>
                </Button.WithIconPadding>
            </Button.Action>
        </>
    );
};

export default CreateButton;
