import { getStoredOrDefault } from '@/utils/localStorageUtils';
import { CookieUtils } from '@saga/shared';
import * as t from 'io-ts';

function removeCookie(key: string, path: string) {
    const d = new Date();
    d.setTime(d.getTime() + -1 * 24 * 60 * 60 * 1000);
    const expires = 'expires=' + d.toUTCString();
    const cookie = `${key}=; ${expires}; path=${path}; Domain=.saga.so; SameSite=None; Secure`;
    document.cookie = cookie;
}

const CF_META_KEY = 'cf-meta';

const cloudfrontMetadataCacheD = t.record(t.string, t.union([t.number, t.undefined]));

type CloudfrontMetadataCache = t.TypeOf<typeof cloudfrontMetadataCacheD>;

export function getCloudfrontMetadataCache(): CloudfrontMetadataCache {
    return getStoredOrDefault(CF_META_KEY, cloudfrontMetadataCacheD.decode, {});
}

export function hasValidCookies(urlKey: string) {
    const cache = getCloudfrontMetadataCache();
    const expirationMs = cache[urlKey];
    if (expirationMs == null) return null;
    const isExpired = Number.isNaN(expirationMs) || Date.now() >= expirationMs;
    return !isExpired;
}

export function updateCloudfrontMetadata(meta: CookieUtils.CloudfrontMetadata) {
    const cache = getCloudfrontMetadataCache();
    localStorage.setItem(CF_META_KEY, JSON.stringify({ ...cache, [meta.urlKey]: meta.expirationMs }));
}

export const resetCloudfrontCookies = () => {
    const cache = getCloudfrontMetadataCache();

    Object.keys(cache).forEach((key) => {
        removeCookie('CloudFront-Key-Pair-Id', CookieUtils.buildPathForSpace(key));
        removeCookie('CloudFront-Signature', CookieUtils.buildPathForSpace(key));
        removeCookie('CloudFront-Policy', CookieUtils.buildPathForSpace(key));
    });

    localStorage.removeItem(CF_META_KEY);
};
