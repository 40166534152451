import { FilledStar } from '@/components/icons';
import React from 'react';
import { Star } from 'react-feather';
import Tooltip from '../popover/Tooltip';
import Button from './Button';
import { useTranslation } from 'react-i18next';

type Props = {
    isFavorite: boolean;
    onClick: () => void;
    type: 'page' | 'collection' | 'task' | 'task-view' | 'page-view';
};

export function FavoriteIcon({ isFavorite }: { isFavorite: boolean }) {
    return isFavorite ? (
        <FilledStar size="20" className="inline stroke-saga-gray-dark dark:stroke-zinc-200 dark:text-zinc-200" />
    ) : (
        <Star className="inline" size={20} />
    );
}

export default function FavoriteButton({ isFavorite, onClick, type }: Props) {
    const { t } = useTranslation();

    let ariaLabel = '';

    switch (type) {
        case 'page':
            ariaLabel = 'top_menu.add_page_to_favorites';
            break;
        case 'collection':
            ariaLabel = 'top_menu.add_collection_to_favorites';
            break;
        case 'task':
            ariaLabel = 'top_menu.add_task_to_favorites';
            break;
        case 'task-view':
            ariaLabel = 'top_menu.add_task_view_to_favorites';
            break;
        case 'page-view':
            ariaLabel = 'top_menu.add_page_view_to_favorites';
            break;
    }
    return (
        <Tooltip content={t(`common.${isFavorite ? 'remove_from_favorites' : 'add_to_favorites'}`)} placement="bottom">
            <Button.Plain onClick={onClick} aria-label={t(ariaLabel) as string}>
                <Button.BasePadding>
                    <FavoriteIcon isFavorite={isFavorite} />
                </Button.BasePadding>
            </Button.Plain>
        </Tooltip>
    );
}
