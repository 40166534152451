import { BlockDroppable } from '@/types';
import React from 'react';
import { Editor, Path } from 'slate';

const DragAndDropEnabledContext = React.createContext(false);
export const useIsDragAndDropEnabled = () => React.useContext(DragAndDropEnabledContext);

type OnDrop = (params: { item: BlockDroppable; editor: Editor; path: Path }) => void;
const OnDropContext = React.createContext<OnDrop>(() => {
    throw new Error('Dropping an item is not supported');
});

export const useOnDrop = () => React.useContext(OnDropContext);

export default function DragAndDrop({
    children,
    onDrop,
    enabled,
}: {
    children: React.ReactNode;
    onDrop: OnDrop;
    enabled: boolean;
}) {
    return (
        <DragAndDropEnabledContext.Provider value={enabled}>
            <OnDropContext.Provider value={onDrop}>{children}</OnDropContext.Provider>
        </DragAndDropEnabledContext.Provider>
    );
}
