import { track } from '@/analytics';
import { useBlockPlugins } from '@/components/BlockPluginProvider';
import { useSpace } from '@/components/SpaceProvider';
import Table from '@/components/table/Table';
import useMobile from '@/hooks/useMobile';

import { SagaLocation, SpaceOperations, WeakTask, TasksTableColumnKey } from '@saga/shared';
import classNames from 'classnames';
import React from 'react';
import { useOpenCollection, useOpenLocation } from '../PageNavigationProvider';

import * as api from '@saga/api';
import { useCurrentWorkspace } from '../WorkspaceContext';
import { getCurrentUser } from '@/firebase';
import { useTranslation } from 'react-i18next';
import SortableTableButton from '@/components/table/SortableTableButton';
import TaskRow from '@/components/table/TaskRow';
import { IndexedTaskSearchResults } from '@/components/table/AllTasksTable';
import { TaskLabelColors } from './LabelSelect';
import { useMembers } from '../MembersProvider';

interface TableHeaderContentProps {
    children: React.ReactNode;
}

const TableHeaderContent: React.FC<TableHeaderContentProps> = ({ children }) => {
    return (
        <div className="px-1 py-0.5 flex">
            <p className="my-auto flex-grow truncate text-saga-gray-500 text-sm font-normal">{children}</p>
        </div>
    );
};

function TaskTableContent({
    sortedTasks,
    results,
    columnKeys,
    onColumnOrderChange,
}: {
    sortedTasks: WeakTask[];
    results: IndexedTaskSearchResults;
    columnKeys: TasksTableColumnKey[];
    onColumnOrderChange: (columnKeys: TasksTableColumnKey[]) => void;
}) {
    const [sendTaskAssignmentNotification] = api.useSendTaskAssignmentNotificationMutation();
    const { urlKey } = useCurrentWorkspace();
    const currentUser = getCurrentUser();
    const openLocation = useOpenLocation();
    const { t } = useTranslation();
    const isMobile = useMobile();
    const { space } = useSpace();
    const blockPlugins = useBlockPlugins();
    const openCollection = useOpenCollection();

    const { members } = useMembers();
    const hiddenColumns: TasksTableColumnKey[] = React.useMemo(() => {
        return members.length === 1 ? ['createdBy'] : [];
    }, [members]);

    const [orderedKeys, setOrderedKeys] = React.useState(columnKeys);

    const moveHeader = React.useCallback((dragIndex: number, hoverIndex: number) => {
        setOrderedKeys((prevKeys) => {
            const newKeys = [...prevKeys];
            const [removed] = newKeys.splice(dragIndex, 1);
            newKeys.splice(hoverIndex, 0, removed);
            return newKeys;
        });
    }, []);

    const onDrop = React.useCallback(() => {
        onColumnOrderChange(orderedKeys);
    }, [orderedKeys, onColumnOrderChange]);

    const renderRow = (index: number) => {
        const task = sortedTasks[index];

        return (
            task && (
                <TaskRow
                    hiddenColumns={hiddenColumns}
                    columnKeys={orderedKeys}
                    key={`row-${task.id}`}
                    task={task}
                    onClick={(event) => {
                        track('click-on-task', { source: 'task-table' });
                        openLocation(SagaLocation.taskLocationFromId(task.id), event);
                    }}
                    referenceResults={results[task.id] ?? []}
                    isMobile={isMobile}
                    blockPlugins={blockPlugins}
                    onChangeState={(state) => {
                        track('change-task-state', { state, source: 'task-table' });
                        SpaceOperations.updatePartialTask(space, task.id, {
                            state,
                            completedDate: state === 'DONE' ? new Date().toISOString() : null,
                        });
                    }}
                    onChangeDueDate={(date) => {
                        if (date) {
                            track('change-task-due-date', { source: 'task-table' });
                        } else {
                            track('remove-task-due-date', { source: 'task-table' });
                        }

                        SpaceOperations.updatePartialTask(space, task.id, {
                            dueDate: date?.toISOString() ?? null,
                        });
                    }}
                    onChangeAssignee={async (assignee) => {
                        if (assignee.action === api.TaskAssignAction.Unassign) {
                            track('unassign-assignee-from-task', { source: 'task-table' });
                        } else {
                            track('assign-assignee-to-task', { source: 'task-table' });
                        }
                        SpaceOperations.updatePartialTask(space, task.id, {
                            assignee: assignee.action === api.TaskAssignAction.Assign ? assignee.id : null,
                        });
                        if (assignee.id && assignee.id !== currentUser?.uid) {
                            await sendTaskAssignmentNotification({
                                variables: {
                                    input: {
                                        urlKey,
                                        taskId: task.id,
                                        memberId: assignee.id,
                                        action: assignee.action,
                                    },
                                },
                            });
                        }
                    }}
                    onChangePriority={(priority) => {
                        if (priority == null) {
                            track('remove-task-priority', { source: 'task-table' });
                        } else {
                            track('change-task-priority', { source: 'task-table' });
                        }
                        SpaceOperations.updatePartialTask(space, task.id, {
                            priority: typeof priority === 'string' ? priority : null,
                        });
                    }}
                    onSelectLabel={(id) => {
                        track('select-task-label', { source: 'task-table' });
                        SpaceOperations.updatePartialTask(space, task.id, {
                            labels: [...new Set([...(task.labels ?? []), id])],
                        });
                    }}
                    onRemoveLabel={(id) => {
                        track('remove-task-label', { source: 'task-table' });
                        SpaceOperations.updatePartialTask(space, task.id, {
                            labels: (task.labels ?? []).filter((label) => label !== id),
                        });
                    }}
                    onCreateLabel={(title) => {
                        track('create-task-label', { source: 'task-table' });
                        const id = SpaceOperations.addTaskLabel(space, title, TaskLabelColors[0]);
                        SpaceOperations.updatePartialTask(space, task.id, {
                            labels: [...new Set([...(task.labels ?? []), id])],
                        });
                    }}
                    onChangeLabelColor={(id, color) => {
                        track('change-task-label-color', { source: 'task-table' });
                        SpaceOperations.updatePartialTaskLabel(space, id, { color });
                    }}
                    onCollectionClick={openCollection}
                    onAddToCollection={(id) => {
                        track('add-task-to-collection', { source: 'task-table' });
                        SpaceOperations.addTaskToCollection(space, task.id, id);
                    }}
                    onRemoveFromCollection={(id) => {
                        track('remove-task-from-collection', { source: 'task-table' });
                        SpaceOperations.removeTaskFromCollection(space, task.id, id);
                    }}
                    onCreateCollection={(title) => {
                        track('create-task-collection', { source: 'task-table' });
                        SpaceOperations.createCollection(space, title);
                    }}
                />
            )
        );
    };

    const renderHeader = React.useCallback(() => {
        return (
            <Table.Row>
                {orderedKeys.map((key, index) => {
                    switch (key) {
                        case 'title':
                            return hiddenColumns.includes(key) ? null : (
                                <Table.Cell
                                    key={key}
                                    className="h-10 pb-3 align-middle w-full min-w-[456px] max-w-[456px]"
                                >
                                    <Table.DraggableHeader
                                        id={key}
                                        index={index}
                                        moveHeader={moveHeader}
                                        onDrop={onDrop}
                                    >
                                        <SortableTableButton type="title">
                                            <TableHeaderContent>{t('tasks.task_title')}</TableHeaderContent>
                                        </SortableTableButton>
                                    </Table.DraggableHeader>
                                </Table.Cell>
                            );
                        case 'collection':
                            return hiddenColumns.includes(key) ? null : (
                                <Table.Cell key={key} className="h-10 pb-3 align-middle min-w-48 max-w-48 truncate">
                                    <Table.DraggableHeader
                                        id={key}
                                        index={index}
                                        moveHeader={moveHeader}
                                        onDrop={onDrop}
                                    >
                                        <SortableTableButton type="collection">
                                            <TableHeaderContent>{t('common.collections')}</TableHeaderContent>
                                        </SortableTableButton>
                                    </Table.DraggableHeader>
                                </Table.Cell>
                            );
                        case 'assignee':
                            return hiddenColumns.includes(key) ? null : (
                                <Table.Cell key={key} className="h-10 pb-3 align-middle min-w-40 max-w-40">
                                    <Table.DraggableHeader
                                        id={key}
                                        index={index}
                                        moveHeader={moveHeader}
                                        onDrop={onDrop}
                                    >
                                        <SortableTableButton type="assignee">
                                            <TableHeaderContent>{t('tasks.assignee')}</TableHeaderContent>
                                        </SortableTableButton>
                                    </Table.DraggableHeader>
                                </Table.Cell>
                            );
                        case 'dueDate':
                            return hiddenColumns.includes(key) ? null : (
                                <Table.Cell key={key} className="h-10 pb-3 align-middle min-w-40 max-w-40">
                                    <Table.DraggableHeader
                                        id={key}
                                        index={index}
                                        moveHeader={moveHeader}
                                        onDrop={onDrop}
                                    >
                                        <SortableTableButton type="dueDate">
                                            <TableHeaderContent>{t('tasks.due_date')}</TableHeaderContent>
                                        </SortableTableButton>
                                    </Table.DraggableHeader>
                                </Table.Cell>
                            );
                        case 'priority':
                            return hiddenColumns.includes(key) ? null : (
                                <Table.Cell key={key} className="h-10 pb-3 align-middle min-w-40 max-w-40">
                                    <Table.DraggableHeader
                                        id={key}
                                        index={index}
                                        moveHeader={moveHeader}
                                        onDrop={onDrop}
                                    >
                                        <SortableTableButton type="priority">
                                            <TableHeaderContent>{t('tasks.priority')}</TableHeaderContent>
                                        </SortableTableButton>
                                    </Table.DraggableHeader>
                                </Table.Cell>
                            );
                        case 'references':
                            return hiddenColumns.includes(key) ? null : (
                                <Table.Cell key={key} className="h-10 pb-3 align-middle min-w-48 max-w-48">
                                    <Table.DraggableHeader
                                        id={key}
                                        index={index}
                                        moveHeader={moveHeader}
                                        onDrop={onDrop}
                                    >
                                        <SortableTableButton type="references">
                                            <TableHeaderContent>{t('tasks.referenced')}</TableHeaderContent>
                                        </SortableTableButton>
                                    </Table.DraggableHeader>
                                </Table.Cell>
                            );
                        case 'labels':
                            return hiddenColumns.includes(key) ? null : (
                                <Table.Cell key={key} className="h-10 pb-3 align-middle min-w-40 max-w-40">
                                    <Table.DraggableHeader
                                        id={key}
                                        index={index}
                                        moveHeader={moveHeader}
                                        onDrop={onDrop}
                                    >
                                        <SortableTableButton type="labels">
                                            <TableHeaderContent>{t('settings.labels.title')}</TableHeaderContent>
                                        </SortableTableButton>
                                    </Table.DraggableHeader>
                                </Table.Cell>
                            );
                        case 'createdBy':
                            return hiddenColumns.includes(key) ? null : (
                                <Table.Cell key={key} className="h-10 pb-3 align-middle min-w-40 max-w-40">
                                    <Table.DraggableHeader
                                        id={key}
                                        index={index}
                                        moveHeader={moveHeader}
                                        onDrop={onDrop}
                                    >
                                        <SortableTableButton type="createdBy">
                                            <TableHeaderContent>{t('pages.headers.creator')}</TableHeaderContent>
                                        </SortableTableButton>
                                    </Table.DraggableHeader>
                                </Table.Cell>
                            );

                        case 'created':
                            return hiddenColumns.includes(key) ? null : (
                                <Table.Cell key={key} className="h-10 pb-3 align-middle min-w-40 max-w-40">
                                    <Table.DraggableHeader
                                        id={key}
                                        index={index}
                                        moveHeader={moveHeader}
                                        onDrop={onDrop}
                                    >
                                        <SortableTableButton type="createdAt">
                                            <TableHeaderContent>{t('tasks.createdAt')}</TableHeaderContent>
                                        </SortableTableButton>
                                    </Table.DraggableHeader>
                                </Table.Cell>
                            );
                        case 'updated':
                            return hiddenColumns.includes(key) ? null : (
                                <Table.Cell key={key} className="h-10 pb-3 align-middle min-w-40 max-w-40">
                                    <Table.DraggableHeader
                                        id={key}
                                        index={index}
                                        moveHeader={moveHeader}
                                        onDrop={onDrop}
                                    >
                                        <SortableTableButton type="updatedAt">
                                            <TableHeaderContent>{t('tasks.updatedAt')}</TableHeaderContent>
                                        </SortableTableButton>
                                    </Table.DraggableHeader>
                                </Table.Cell>
                            );
                        case 'completedDate':
                            return hiddenColumns.includes(key) ? null : (
                                <Table.Cell key={key} className="h-10 pb-3 align-middle min-w-40 max-w-40">
                                    <Table.DraggableHeader
                                        id={key}
                                        index={index}
                                        moveHeader={moveHeader}
                                        onDrop={onDrop}
                                    >
                                        <SortableTableButton type="completedDate">
                                            <TableHeaderContent>{t('tasks.completedDate')}</TableHeaderContent>
                                        </SortableTableButton>
                                    </Table.DraggableHeader>
                                </Table.Cell>
                            );
                        default:
                            return null;
                    }
                })}
            </Table.Row>
        );
    }, [t, hiddenColumns, orderedKeys, moveHeader, onDrop]);

    return sortedTasks.length > 0 ? (
        <Table.Virtualized
            data-testid="all-tasks-table"
            className={classNames('relative table-fixed border-collapse text-left h-full')}
            itemsCount={sortedTasks.length}
            renderHeader={renderHeader}
            renderItem={renderRow}
        />
    ) : (
        <Table.EmptyState>{t('tasks.create_task_to_see_them')}</Table.EmptyState>
    );
}

export default TaskTableContent;
