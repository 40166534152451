import React from 'react';
import { useTranslation } from 'react-i18next';
import { track } from '@/analytics';

import { Columns, Sidebar, Star, Trash } from 'react-feather';
import { PopOver } from '../popover/PopOver';
import Dropdown from '../popover/Dropdown';
import ContextMenuButton from '../styled/ContextMenuButton';
import { SpaceOperations, TaskView } from '@saga/shared';
import { PageView } from '@saga/shared';
import { useSpace } from '../SpaceProvider';
import { useOpenLocation } from '../PageNavigationProvider';
import { useFavoriteButton } from '@/hooks/useFavoriteButton';
import { usePinnedButton } from '@/hooks/usePinnedButton';
import { useSideBySideIndex } from '../SideBySide';
import { useNullableSideBySideApi } from '../SideBySide';

type Props = {
    view: PageView | TaskView;
    type: 'page-view' | 'task-view';
    isButtonSmall?: boolean;
};

export function ViewContextButton({ view, type, isButtonSmall = false }: Props) {
    const buttonRef = React.useRef<HTMLButtonElement>(null);
    const [isOpen, setIsOpen] = React.useState(false);

    const onClose = () => setIsOpen(false);

    return (
        <>
            <ContextMenuButton
                isButtonSmall={isButtonSmall}
                ref={buttonRef}
                isOpen={isOpen}
                onClick={() => {
                    setIsOpen((isOpen) => !isOpen);
                    if (isOpen) track('open-table-view-context-menu');
                    else track('close-table-view-context-menu');
                }}
            />
            <ViewContextDropdown isOpen={isOpen} onClose={onClose} buttonRef={buttonRef} view={view} type={type} />
        </>
    );
}

type ViewContextDropdownProps = {
    view: PageView | TaskView;
    type: 'page-view' | 'task-view';
    isOpen: boolean;
    onClose: () => void;
    buttonRef: React.RefObject<HTMLButtonElement>;
};

export function ViewContextDropdown({ isOpen, onClose, buttonRef, view, type }: ViewContextDropdownProps) {
    const { space } = useSpace();
    const openLocation = useOpenLocation();

    const { t } = useTranslation();

    const api = useNullableSideBySideApi();
    const currentPaneIndex = useSideBySideIndex();

    const { isFavorite, toggleFavorite } = useFavoriteButton(view.id);
    const { isPinned, togglePinned } = usePinnedButton(view.id);

    return (
        <Dropdown
            testId={isOpen ? 'table-view-context-menu' : undefined}
            isOpen={isOpen}
            onClose={onClose}
            attachToRef={buttonRef}
        >
            <div className="space-y-0.5">
                <PopOver.RoundedButton
                    onClick={() => {
                        const location =
                            type === 'page-view'
                                ? { type: 'allPages' as const, viewId: view.id }
                                : { type: 'allTasks' as const, viewId: view.id };

                        api?.openLocationOnIndex(location, currentPaneIndex === 0 ? 1 : 0);
                        onClose();
                    }}
                    aria-label={t('side_by_side.open') as string}
                >
                    <span className="flex">
                        <Columns className="stroke-gray-dark mr-2 my-auto" size={14} />
                        <span>{t('side_by_side.open')}</span>
                    </span>
                </PopOver.RoundedButton>
                <PopOver.RoundedButton
                    onClick={() => {
                        onClose();
                        toggleFavorite();
                    }}
                    aria-label={isFavorite ? t('common.remove_from_favorites') : t('common.add_to_favorites')}
                >
                    {isFavorite ? (
                        <Trash className="sroke-gray-dark mr-2 my-auto" size={14} />
                    ) : (
                        <Star className="sroke-gray-dark mr-2 my-auto" size={14} />
                    )}
                    {isFavorite ? t('common.remove_from_favorites') : t('common.add_to_favorites')}
                </PopOver.RoundedButton>
                <PopOver.RoundedButton
                    onClick={() => {
                        onClose();
                        togglePinned();
                    }}
                    aria-label={isPinned ? t('common.remove_from_pinned') : t('common.add_to_pinned')}
                >
                    <Sidebar className="sroke-gray-dark mr-2 my-auto" size={14} />
                    {isPinned ? t('common.remove_from_pinned') : t('common.add_to_pinned')}
                </PopOver.RoundedButton>
                {!view.isDefaultView && (
                    <div className="pt-0.5 space-y-0.5">
                        <PopOver.Divider />
                        <PopOver.RoundedButton
                            onClick={(e) => {
                                onClose();

                                if (type === 'page-view') {
                                    SpaceOperations.removePageView(space, view.id);
                                    openLocation({ type: 'allPages', viewId: 'non-deleted' }, e);
                                } else {
                                    SpaceOperations.removeTaskView(space, view.id);
                                    openLocation({ type: 'allTasks', viewId: 'all' }, e);
                                }
                            }}
                            aria-label={t('views.delete_view') as string}
                        >
                            <Trash className="stroke-gray-dark mr-2 my-auto" size={16} />
                            {t('views.delete_view')}
                        </PopOver.RoundedButton>
                    </div>
                )}
            </div>
        </Dropdown>
    );
}
