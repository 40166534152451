import { isSagaElement, SlateYjs, SpaceOperations } from '@saga/shared';
import { useOpenLocation } from '@/components/PageNavigationProvider';
import { useSpace } from '@/components/SpaceProvider';
import { Editor } from 'slate';
import { usePerformActionWithYBlocks } from '@/components/RealtimeDocumentProvider';

export default function useJumpToMemberCursor() {
    const { space } = useSpace();
    const openLocation = useOpenLocation();
    const performActionWithBlocks = usePerformActionWithYBlocks();

    return (cursor: SlateYjs.Cursor, event: Event | React.SyntheticEvent) => {
        performActionWithBlocks(cursor.location, (yBlocks) => {
            const range = cursor ? SlateYjs.getRangeFromCursor(yBlocks, cursor) : null;

            if (range == null) {
                openLocation(cursor.location, event);
            } else {
                SpaceOperations.changeWithEditor(space, yBlocks, (editor) => {
                    const firstNodeEntry = Editor.nodes(editor, { at: range, match: isSagaElement }).next().value;
                    const blockId = firstNodeEntry ? firstNodeEntry[0].id : undefined;
                    openLocation({ ...cursor.location, blockId }, event);
                });
            }
        });
    };
}
