import useMobile from '@/hooks/useMobile';
import classNames from 'classnames';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { PageForEditor } from './SpacePageEditor';
import { useBlockPlugins } from '@/components/BlockPluginProvider';
import { EditorOperations, RealtimeSagaEditor, SagaEditor, SagaLocation } from '@saga/shared';
import * as Y from 'yjs';
import { useDocumentAwareness } from '@/components/RealtimeDocumentProvider';
import { ReactEditor } from 'slate-react';

export default function PublicPageEditor({
    page,
    children,
    yBlocks,
    canEdit,
}: {
    page: PageForEditor;
    children: React.ReactNode;
    yBlocks: Y.Array<any>;
    canEdit: boolean;
}) {
    const blockPlugins = useBlockPlugins();
    const isMobile = useMobile();
    const windowLocation = useLocation();
    const focusBlockId = windowLocation.hash.replace('#', '');
    const editorRef = React.useRef<RealtimeSagaEditor | null>(null);
    const awareness = useDocumentAwareness(page.id);

    React.useEffect(() => {
        if (focusBlockId !== '') {
            React.startTransition(() => {
                window.location.hash = '';
            });
        }
    }, [focusBlockId]);

    const location = React.useMemo(() => SagaLocation.pageLocationFromId(page.id), [page.id]);

    React.useEffect(() => {
        if (!editorRef.current) return;

        const { apply } = editorRef.current;

        editorRef.current.apply = (operation) => {
            // title editing
            if (operation.type === 'merge_node' && operation.path[0] === 1) return;
            if (operation.type !== 'set_selection' && operation.path[0] === 0) return;

            // Other cases
            apply(operation);
        };
    }, [editorRef]);

    return (
        <SagaEditor.RealtimeEditor
            fullWidth={false}
            editorRef={editorRef}
            location={location}
            yBlocks={yBlocks}
            canEdit={canEdit}
            blockPlugins={blockPlugins}
            awareness={awareness}
            allowCollapsingListItems={true}
            disableUserInteraction={!canEdit}
        >
            <SagaEditor.Container
                className={classNames(
                    'hide-scrollbar overflow-y-auto relative mx-auto flex flex-col w-full sm:pl-8 sm:pr-2',
                    {
                        'px-1': isMobile,
                    },
                )}
                focus={{ blockId: focusBlockId }}
            >
                {children}
                <div
                    className="w-full flex-grow cursor-text pb-72"
                    data-testid="bottom-drop-area"
                    onClick={() => {
                        if (editorRef.current) {
                            ReactEditor.focus(editorRef.current);
                            EditorOperations.Selection.safeSelectEdge(editorRef.current, 'end');
                        }
                    }}
                />
            </SagaEditor.Container>
        </SagaEditor.RealtimeEditor>
    );
}
