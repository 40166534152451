import { Editor, Path, Range, Transforms } from 'slate';
import { Page, SagaElement, WeakBlock } from '..';

import { removeNullable } from '../utils';
import { visit, isSagaElement, isSagaText } from '../block-types';
import { toString } from '../EditorOperations/SagaElement';

import * as R from 'ramda';
import { isInline, NodeCheckFn } from '../types';
import Fuse from 'fuse.js';

/**
 * Checks if a searchString is part of the target in a case-insensitive way.
 *
 * @param target The string to check against.
 * @param searchString The searchString to find in the target.
 * @returns If the searchString is included in the target, regardless of casing.
 */
function includesLowerCase(target: string, searchString: string): boolean {
    return target.toLowerCase().includes(searchString.toLowerCase());
}

export type Offset = {
    start: number;
    end: number;
};

interface TermBrand {
    readonly Term: unique symbol;
}

export type ReferenceSearchResult = {
    type: 'referenceSearchResult';
    pageId: string;
    blockId: string;
    pageTitle: string;
    isTemplate: Page['isTemplate'];
    icon: Page['icon'];
};

export type Term = string & TermBrand;

// We need to have at least one term to search for
export type Terms = [Term, ...Term[]];
export type Condition = Terms[];

export function isTerm(value: string): value is Term {
    return value.trim() != '';
}

export function isTerms(terms: Term[]): terms is Terms {
    return terms.length > 0;
}

export function makeTerms(values: string[]): Terms | undefined {
    const terms = values.map(makeTerm).filter(removeNullable);

    if (!isTerms(terms)) {
        return undefined;
    }

    return terms;
}

export function makeTerm(value: string): Term | undefined {
    const trimmedValue = value.trim();

    if (!isTerm(trimmedValue)) {
        return undefined;
    }

    return trimmedValue;
}

/**
 * This function checks if given terms match a given condition.
 * 
 * Example Input
 * terms: [a]
 * condition: [[a,b], [c]] => a AND b OR C
 * return false

 * terms: [a,c]
 * condition: [[a],[b]] => a OR b
 * return true
 * 
 * @param terms The terms which to check against the condition
 * @param condition The condition
 * @returns true or false
 */
export function termsMatchCondition(terms: Terms, condition: Condition): boolean {
    return condition.some((andTerms) =>
        andTerms.every((a) => terms.map((t) => t.toLowerCase()).includes(a.toLowerCase())),
    );
}

export function replaceAllTerms(text: string, oldValue: Term, newValue: Term) {
    const matches = getMatches(text, [oldValue]).filter((match) => !match.isFuzzy);

    let replacedText = '';

    matches.forEach((match, i) => {
        const offset = buildOffsetFromMatch(match);

        if (offset) {
            if (i === 0) {
                replacedText += text.substring(0, offset.start);
            }

            replacedText += newValue;

            const nextMatch = matches[i + 1];

            if (nextMatch) {
                const nextMatchOffset = buildOffsetFromMatch(nextMatch);

                if (nextMatchOffset) {
                    replacedText += text.substring(offset.end, nextMatchOffset.start);
                } else {
                    replacedText += text.substring(offset.end);
                }
            } else {
                replacedText += text.substring(offset.end);
            }
        }
    });

    return replacedText;
}

const wrapInTermBoundary = (s: Term) => {
    return `(?:[\\s,.:;"'=(]|^)(${s})(?:[)\\s,.:;"'=\?\!]|$)`;
};

function escapeRegExpTerm(string: Term): Term {
    const result = makeTerm(string.replace(/[.*?^${}()|[\]\\+]/g, '\\$&')); // $& means the whole matched string

    if (result == null) {
        throw new Error(`escapeRegExp: ${result} is not a Term`);
    }

    return result;
}

function buildTermRegExp(terms: Terms, matchMode = MatchMode.TERMS) {
    if (matchMode === MatchMode.TERMS) {
        const joinedTerms = terms.map(escapeRegExpTerm).map(wrapInTermBoundary).join('|');
        return new RegExp(`${joinedTerms}`, 'igum');
    }

    const joinedTerms = terms.map(escapeRegExpTerm).join('|');
    return new RegExp(`(${joinedTerms})`, 'igum');
}

export type Match = {
    value: string;
    index: number;
    isFuzzy: boolean;
};

/**
 * Takes a RegExp match and turns it into an Offset based on the index and the matched value.
 *
 * @param match The RegExp match
 * @returns An Offset object or null
 */
function buildOffsetFromMatch(match: Match): Offset | null {
    const start = match.index;
    const end = start + match.value.length;

    return {
        start,
        end,
    };
}

export enum MatchMode {
    TERMS,
    ALL,
}

export function getFuzzyMatches(text: string, terms: string[]): Match[] {
    const fuse = new Fuse([text], { useExtendedSearch: true });
    let fuzzyMatches: Match[] = [];

    for (const term of terms) {
        fuzzyMatches.push(
            ...fuse
                .search(
                    term.split(' ').reduce((previousValue, currentValue) => previousValue + ` '${currentValue}`, ''),
                )
                .map((result) => ({ value: result.item, index: result.refIndex, isFuzzy: true })),
        );
    }

    return fuzzyMatches;
}

export function getMatches(text: string, terms: Terms, matchMode = MatchMode.TERMS): Match[] {
    const regexp = buildTermRegExp(terms, matchMode);
    const matches: Match[] = [];
    let currentMatch: RegExpExecArray | null;

    while ((currentMatch = regexp.exec(text)) !== null) {
        const index = currentMatch.index;

        if (index != null) {
            switch (matchMode) {
                case MatchMode.ALL: {
                    const value = currentMatch[1];

                    if (value != null) {
                        const match = {
                            index,
                            value,
                            isFuzzy: false,
                        };
                        regexp.lastIndex = match.index + value.length;
                        matches.push(match);
                    }

                    break;
                }
                case MatchMode.TERMS: {
                    for (let i = 0; i < terms.length; i++) {
                        const term = terms[i];
                        const value = currentMatch?.[i + 1];
                        if (value) {
                            const termIndex = text.toLowerCase().substring(index).indexOf(term.toLowerCase());
                            const match = {
                                index: index + termIndex ?? 0,
                                value,
                                isFuzzy: false,
                            };
                            regexp.lastIndex = match.index + value.length;
                            matches.push(match);
                            break;
                        }
                    }
                    break;
                }
            }
        }
    }

    if (matches.length) {
        return matches;
    }

    return getFuzzyMatches(text, terms);
}

export type TermMatch = { offset: Offset; matched: string };

export function findTermMatches(text: string, terms: Terms, matchMode = MatchMode.TERMS): TermMatch[] {
    // We need to sort the terms by length, starting from the longest
    // because we always want to give priority to the longest term
    const sortedTerms = terms.sort((a, b) => b.length - a.length);

    return getMatches(text, sortedTerms, matchMode)
        .filter((match) => !match.isFuzzy)
        .map((match) => {
            const offset = buildOffsetFromMatch(match);
            if (offset == null) {
                return null;
            }
            return { offset, matched: match.value };
        })
        .filter(removeNullable);
}

function splitByAnd(query: string): Terms | undefined {
    return makeTerms(
        query
            .split('AND')
            .map((s) => parseSearchQuery(s))
            .flat(2),
    );
}

function splitByOr(query: string): Terms[] {
    return query
        .split('OR')
        .map((s) => makeTerms(parseSearchQuery(s).flat()))
        .filter(removeNullable);
}

export function parseSearchQuery(query: string): Condition {
    if (query.includes('OR')) {
        return splitByOr(query);
    }

    if (query.includes('AND')) {
        const result = splitByAnd(query);
        if (result == null) {
            return [];
        }
        return [result];
    }

    const terms = makeTerms([query]);
    return [terms].filter(removeNullable);
}

export type TextQuery = {
    type: 'text';
    condition: Condition;
    fuzzySearch?: boolean;
    matchMode?: MatchMode;
};

type GuardedType<T> = T extends (x: any) => x is infer T ? T : never;
type Predicate<V> = (value: V) => boolean;

export type BlockQuery<Check extends NodeCheckFn = NodeCheckFn> = {
    type: 'block';
    queries?: Query[];
    match: Check;
    predicate?: Predicate<GuardedType<Check>>;
};

export function blockQuery<T extends NodeCheckFn>(props: Omit<BlockQuery<T>, 'type'>): BlockQuery<T> {
    return { type: 'block', ...props };
}

export function textQuery(props: Omit<TextQuery, 'type'>): TextQuery {
    return { type: 'text', ...props };
}

export type Query = TextQuery | BlockQuery<NodeCheckFn>;

export type TextMatch = {
    range: Range;
    value: string;
    isFuzzy: boolean;
};

type BlockResult = {
    type: 'block';
    path: Path;
    block: WeakBlock;
    blockId: string;
};

type TextResult = {
    type: 'text';
    block: WeakBlock;
    blockId: string;
    path: Path;
    matches: TextMatch[];
};

function getMatchesFromBlock(block: WeakBlock, path: Path, terms: Terms, matchMode?: MatchMode): TextMatch[] {
    if (isInline(block)) return [];

    const result: TextMatch[] = [];

    block.children.forEach((child, index) => {
        if (isInline(child)) {
            const text = toString(child);
            const matches = getMatches(text, terms, matchMode).map(
                (match): TextMatch => ({
                    range: {
                        focus: {
                            path: [...path, index],
                            offset: match.index + match.value.length,
                        },
                        anchor: {
                            path: [...path, index],
                            offset: match.index,
                        },
                    },
                    value: match.value,
                    isFuzzy: match.isFuzzy,
                }),
            );

            result.push(...matches);
        }
    });
    return result;
}

export type QueryResult = BlockResult | TextResult;

export function queryBlocks(query: Query, _path: number[], blocks: WeakBlock[]): QueryResult[] {
    switch (query.type) {
        case 'text': {
            const results: TextResult[] = [];
            blocks.forEach((block, index) => {
                const path = [..._path, index];

                if (block.children) {
                    const matches = query.condition
                        .map((terms) => getMatchesFromBlock(block, path, terms, query.matchMode))
                        .flat();

                    if (matches.length > 0) {
                        results.push({
                            type: 'text',
                            matches: matches.filter((match) => (query.fuzzySearch ? true : !match.isFuzzy)),
                            path,
                            block,
                            blockId: block.id,
                        });
                    }

                    const nestedResults = queryBlocks(query, path, block.children as WeakBlock[]);
                    // ugly hack to make sure typescript understands that the results are always text queries in this case
                    // all the other options (function overload, generic types) are way worse
                    results.push(...nestedResults.filter((result): result is TextResult => result.type === 'text'));
                }
            });

            const matchedTerms = makeTerms(
                results
                    .map(({ matches }) => matches)
                    .flat()
                    .map(({ value }) => value),
            );

            const matchesCondition =
                (matchedTerms && termsMatchCondition(matchedTerms, query.condition)) ||
                (query.fuzzySearch ? results.some((result) => result.matches.some((match) => match.isFuzzy)) : false);

            if (matchesCondition) {
                return results;
            }

            return [];
        }

        case 'block': {
            let finalResults: QueryResult[] = [];

            if (query.queries) {
                const [firstQuery, ...rest] = query.queries;

                let results = queryBlocks(firstQuery, _path, blocks);

                rest.forEach((query) => {
                    results = R.innerJoin(
                        (a, b) => a.block.id === b.block.id,
                        results,
                        queryBlocks(query, _path, blocks),
                    );
                });

                finalResults = results.filter(({ block }) => query.match(block));
            } else {
                const results: QueryResult[] = [];
                blocks.forEach((block, index) => {
                    if (query.match(block)) {
                        results.push({
                            type: 'block',
                            path: [..._path, index],
                            block,
                            blockId: block.id,
                        });
                    }

                    if (block.children) {
                        results.push(...queryBlocks(query, [..._path, index], block.children as WeakBlock[]));
                    }
                });
                finalResults = results;
            }

            const predicate = query.predicate;
            if (predicate) {
                return finalResults.filter(({ block }) => {
                    //@ts-expect-error TypeScript is lost here unfortunately
                    return predicate(block);
                });
            }

            return finalResults;
        }
    }
}

export function searchAndReplace(
    editor: Editor,
    { oldValue, newValue, blocksToUpdate }: { oldValue: string; newValue: string; blocksToUpdate: string[] },
) {
    visit({ type: 'root', children: editor.children as SagaElement[] }, ([node, path]) => {
        if (isSagaText(node) && includesLowerCase(node.text, oldValue) && isTerm(oldValue) && isTerm(newValue)) {
            const replacedText = replaceAllTerms(node.text, oldValue, newValue);
            Transforms.insertText(editor, replacedText, {
                at: {
                    anchor: {
                        path,
                        offset: 0,
                    },
                    focus: {
                        path,
                        offset: node.text.length,
                    },
                },
            });
        }

        if (isSagaElement(node) && path.length === 1 && !blocksToUpdate.includes(node.id)) {
            return { continueAction: 'stop-tree' };
        }

        return;
    });
}
