import React from 'react';
import * as Sentry from '@sentry/react';
import { enabledOEmbedProviders, matchCustomProvider, OEmbedProvider, PATTERNS } from '@saga/shared';
import { BACKEND_URL } from '@/constants';

type EmbedContext = {
    canBeEmbedded: (url: string) => boolean;
};

export const EmbedContext = React.createContext<EmbedContext>({ canBeEmbedded: () => false });

export default function EmbedProvider({ children }: { children: React.ReactNode }) {
    const [availableProviders, setAvailableProviders] = React.useState<OEmbedProvider[]>([]);

    React.useEffect(() => {
        const fetchProviders = async () => {
            try {
                const result: { providers: OEmbedProvider[] } = await fetch(`${BACKEND_URL}getOEmbedProviders`).then(
                    (res) => res.json(),
                );

                setAvailableProviders(result.providers);
            } catch (error) {
                setAvailableProviders([]);
            }
        };

        fetchProviders();
    }, []);

    const canBeEmbedded = React.useCallback(
        (url: string): boolean => {
            try {
                if ((url.match(PATTERNS.email) ?? []).length > 0) {
                    return false;
                }

                if (matchCustomProvider(url)) {
                    return true;
                }

                const inputUrl = parseUrl(url);

                if (!inputUrl) return false;

                return availableProviders
                    .filter((p) => enabledOEmbedProviders.includes(p.provider_name.toLowerCase()))
                    .some((provider) => {
                        const providerUrl = parseUrl(provider.provider_url);

                        if (!providerUrl) return false;

                        if (inputUrl.pathname.trim().length === 0 || inputUrl.pathname.trim() === '/') return false;

                        if (providerUrl.host === inputUrl.host) return true;

                        if (provider.endpoints == null) return false;

                        return provider.endpoints.some((endpoint) => {
                            if (endpoint.schemes == null) return false;

                            return endpoint.schemes.some((url) => {
                                const schemeUrl = parseUrl(url);

                                if (!schemeUrl) return false;

                                return schemeUrl.host === inputUrl.host;
                            });
                        });
                    });
            } catch (error) {
                Sentry.captureException(error, { extra: { url } });
                return false;
            }
        },
        [availableProviders],
    );
    const context = React.useMemo(() => ({ canBeEmbedded }), [canBeEmbedded]);

    return <EmbedContext.Provider value={context}>{children}</EmbedContext.Provider>;
}

const parseUrl = (url: string): URL | undefined => {
    try {
        return new window.URL(url);
    } catch (error) {
        return undefined;
    }
};

export const useEmbedContext = () => React.useContext(EmbedContext);
