import { toHast } from 'mdast-util-to-hast';
import { HastNode } from 'mdast-util-to-hast/lib';
import { toHtml } from 'hast-util-to-html';
import { sagaToMdast } from './sagaToMdast';
import { Root } from '..';

interface hastChild {
    type: string;
    value: string;
}

function removeEmptyTextNodes(node: any) {
    if (!node || !node.children) {
        return node;
    }

    const newChildren = node.children
        .filter((child: hastChild) => !(child.type === 'text' && child.value === ' '))
        .map((child: any) => removeEmptyTextNodes(child));

    return {
        ...node,
        children: newChildren,
    };
}

export function sagaToHtml(root: Root) {
    const mdast = sagaToMdast(root);

    // @ts-expect-error types are not matching up from mdast and unist
    const hast = toHast(mdast);

    const html = toHtml(removeEmptyTextNodes(hast));

    return html.replace('---', '<hr />');
}
