import React, { ReactNode } from 'react';
import useMobile from '@/hooks/useMobile';

export default function ActionBar({
    search,
    sort,
    mode,
    filter,
    saveView,
}: {
    search: ReactNode;
    sort: ReactNode;
    mode?: ReactNode;
    filter?: ReactNode;
    saveView?: ReactNode;
}) {
    const isMobile = useMobile();

    if (isMobile) {
        return (
            <div className="sm:px-8 pt-4 pb-8 flex flex-col space-y-2">
                <div className="flex space-x-2">
                    {search}
                    {mode}
                </div>
                <div className="w-full flex flex-row space-x-1">
                    {filter}
                    {sort}
                    {saveView}
                </div>
            </div>
        );
    } else {
        return (
            <div className="sm:px-8 pb-6 flex flex-wrap items-center justify-between gap-2">
                <div className="flex flex-wrap items-center">
                    <div className="mr-2 mt-2">{search}</div>
                    {filter && <div className="mr-2 mt-2">{filter}</div>}
                    <div className="mt-2">{sort}</div>
                </div>
                <div className="flex flex-row mt-2 gap-2">
                    {saveView}
                    {mode}
                </div>
            </div>
        );
    }
}
