import React from 'react';

export function TableIcon({ className, size }: { className?: string; size: number }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 14 14" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.91406 2.33341C2.5919 2.33341 2.33073 2.59458 2.33073 2.91675V11.0834C2.33073 11.4056 2.5919 11.6667 2.91406 11.6667H11.0807C11.4029 11.6667 11.6641 11.4056 11.6641 11.0834V2.91675C11.6641 2.59458 11.4029 2.33341 11.0807 2.33341H2.91406ZM1.16406 2.91675C1.16406 1.95025 1.94756 1.16675 2.91406 1.16675H11.0807C12.0472 1.16675 12.8307 1.95025 12.8307 2.91675V11.0834C12.8307 12.0499 12.0472 12.8334 11.0807 12.8334H2.91406C1.94756 12.8334 1.16406 12.0499 1.16406 11.0834V2.91675Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.16406 5.25008C1.16406 4.92792 1.42523 4.66675 1.7474 4.66675H12.2474C12.5696 4.66675 12.8307 4.92792 12.8307 5.25008C12.8307 5.57225 12.5696 5.83341 12.2474 5.83341H1.7474C1.42523 5.83341 1.16406 5.57225 1.16406 5.25008Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.2474 4.66675C5.56956 4.66675 5.83073 4.92792 5.83073 5.25008V12.2501C5.83073 12.5722 5.56956 12.8334 5.2474 12.8334C4.92523 12.8334 4.66406 12.5722 4.66406 12.2501V5.25008C4.66406 4.92792 4.92523 4.66675 5.2474 4.66675Z"
                fill="currentColor"
            />
        </svg>
    );
}
