import Button from '@/components/styled/Button';
import { AISourceItem } from '@saga/shared';
import React from 'react';
import { X } from 'react-feather';

type Props = {
    sources: AISourceItem[];
    onItemRemoved: (id: string) => void;
};

const AISourcesList = ({ sources, onItemRemoved }: Props) => {
    return sources.length ? (
        <div className="flex flex-row flex-wrap items-center ml-2">
            {sources.map((source, index) => (
                <div key={index} className="mr-1 mt-1">
                    <Button.Pill>
                        <div className="py-0.5">{source.title}</div>
                        <div className="ml-1 cursor-pointer" onClick={() => onItemRemoved(source.id)}>
                            <X size={14} />
                        </div>
                    </Button.Pill>
                </div>
            ))}
        </div>
    ) : null;
};

export default AISourcesList;
