import { isSagaText, SagaElement, SagaText, Search } from '@saga/shared';
import { Node, NodeEntry } from 'slate';

/**
 * This is a slate decorator function that takes a second word argument
 * and that will calculate highlight decorations for the nodeEntry based on this word.
 *
 * @param nodeEntry The nodeEntry to decorate
 * @param word The word that should be highlighted
 * @returns
 */
function highlightDecorate(
    [node, path]: NodeEntry<SagaElement | SagaText>,
    words: string[],
    replacedWord?: string,
    matchMode = Search.MatchMode.TERMS,
) {
    if (isSagaText(node)) {
        const terms = Search.makeTerms(words);
        if (terms == null) {
            return [];
        }

        const matches = Search.findTermMatches(Node.string(node), terms, matchMode);
        const highlightDecorations = matches.map(({ offset }) => {
            return {
                anchor: {
                    offset: offset.start,
                    path,
                },
                focus: {
                    offset: offset.end,
                    path,
                },
                highlight: true,
                replacedWord,
            };
        });

        return highlightDecorations;
    }

    return [];
}

export default highlightDecorate;
