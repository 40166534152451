import classNames from 'classnames';
import React from 'react';

function Input({
    innerRef,
    ...props
}: Omit<React.InputHTMLAttributes<HTMLInputElement>, 'className'> & { innerRef?: React.RefObject<HTMLInputElement> }) {
    return (
        <input
            ref={innerRef}
            {...props}
            className={classNames(
                ' border-saga-gray-200 h-12 dark:border-zinc-600 focus:bg-gray-100 dark:focus:bg-zinc-900/50 focus:border-gray-300 dark:focus:border-zinc-500 placeholder-saga-text-gray px-4 py-2 border rounded text-base focus:outline-none focus-visible:border-saga-selection-blue focus-visible:ring-1 focus-visible:ring-saga-selection-blue/50',
                {
                    'bg-gray-100 dark:bg-zinc-900/50 text-saga-gray-dark opacity-100 select-none': props.disabled,
                    'bg-white dark:bg-zinc-900': !props.disabled,
                },
            )}
        />
    );
}

Input.WithAddition = function InputWithAddition({
    innerRef,
    addition,
    ...props
}: Omit<React.InputHTMLAttributes<HTMLInputElement>, 'className'> & {
    innerRef?: React.RefObject<HTMLInputElement>;
    addition: React.ReactNode;
}) {
    return (
        <span className="inline-flex items-center w-full border-saga-gray-200 dark:border-zinc-600 dark:bg-zinc-900 border rounded focus-within:border-saga-selection-blue  focus-within:ring-1 focus-within:ring-saga-selection-blue/50">
            <input
                ref={innerRef}
                {...props}
                className={classNames(
                    'focus:bg-gray-100 dark:focus:bg-zinc-900/50 rounded-l focus:border-gray-300 dark:focus:border-zinc-500 placeholder-saga-text-gray px-4 py-2 text-base focus:outline-none ',
                    {
                        'bg-gray-100 dark:bg-zinc-900/50 text-saga-gray-dark opacity-100 select-none': props.disabled,
                        'bg-white dark:bg-zinc-900': !props.disabled,
                    },
                )}
            />
            <span className="w-1 h-1/2 border-l border-saga-gray-200 border:divide-zinc-600"></span>
            <span className="pr-4">{addition}</span>
        </span>
    );
};

Input.Small = function InputSmall({
    innerRef,
    ...props
}: Omit<React.InputHTMLAttributes<HTMLInputElement>, 'className'> & { innerRef?: React.RefObject<HTMLInputElement> }) {
    return (
        <input
            ref={innerRef}
            {...props}
            className={classNames(
                ' border-saga-gray-200 dark:border-zinc-600 focus:bg-gray-100 dark:focus:bg-zinc-900/50 focus:border-gray-300 dark:focus:border-zinc-500 placeholder-saga-text-gray px-2 py-0.5 border rounded focus:outline-none focus-visible:border-saga-selection-blue focus-visible:ring-1 focus-visible:ring-saga-selection-blue/50 text-xs h-8',
                {
                    'bg-gray-100 dark:bg-zinc-900/50 text-saga-gray-dark select-none opacity-100': props.disabled,
                    'bg-white dark:bg-zinc-900': !props.disabled,
                },
            )}
        />
    );
};

Input.SearchPanel = function InputSmall({
    innerRef,
    ...props
}: Omit<React.InputHTMLAttributes<HTMLInputElement>, 'className'> & { innerRef?: React.RefObject<HTMLInputElement> }) {
    return (
        <input
            ref={innerRef}
            {...props}
            className={classNames(
                ' focus:border-saga-gray-500 dark:focus:border-zinc-500 placeholder-saga-text-gray px-2.5 py-0.5 focus:outline-none focus-visible:border-saga-gray-500 text-base h-9',
                {
                    'bg-gray-100 dark:bg-saga-gray-1000 text-saga-gray-dark select-none opacity-100': props.disabled,
                    'bg-white dark:bg-saga-gray-1000': !props.disabled,
                },
            )}
        />
    );
};

export default Input;
