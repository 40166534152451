import { WeakPage } from '../types';
import * as E from 'fp-ts/lib/Either';
import { pipe } from 'fp-ts/function';
import { ReadonlyNonEmptyArray } from 'fp-ts/lib/ReadonlyNonEmptyArray';
import { SafeSpace } from '../types';
import { decodeKeysFromSafePage } from './utils';

function getPageByIndex<K extends keyof WeakPage>(
    space: SafeSpace,
    index: number,
    keys?: ReadonlyNonEmptyArray<K>,
): Pick<WeakPage, K> | null {
    const page = pipe(
        space.get('pages'),
        E.chain((pages) => pages.get(index)),
        E.chain((p) => {
            if (keys) {
                return decodeKeysFromSafePage(p, keys);
            }

            return p.decode();
        }),
        E.fold(
            () => null,
            (page) => page,
        ),
    );

    return page;
}

export default getPageByIndex;
