import React, { useState, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { HocuspocusProvider } from '@hocuspocus/provider';
import { safeMap, assertNonNull, DocumentName, spaceD, Task, SafeSpace } from '@saga/shared';
import { usePerformActionWithYBlocks } from '@/components/RealtimeDocumentProvider';
import useDuplicateAssets from './useDuplicateAssets';
import { useSpace } from '@/components/SpaceProvider';
import { useCurrentWorkspace } from '@/components/WorkspaceContext';
import { useRealtimeProvider } from '@/components/RealtimeProvider';
import { Page } from '@saga/shared';
import { PageLocation, TaskLocation } from '@saga/shared/src/SagaLocation';

interface TransformEntityFn<T> {
    (entityType: string, entity: T, sourceSpaceUrlKey: string, targetSpaceUrlKey: string): T;
}

interface DeleteEntityFn {
    (space: SafeSpace, id: string): void;
}

interface CreateEntityFn {
    (space: SafeSpace, startingFields: Partial<Page> | Partial<Task>, provider: HocuspocusProvider): Page | Task;
}
interface LocationFromIdFn {
    (pageId: string, opts?: { blockId?: string }): PageLocation | TaskLocation;
}

export function useMoveEntity<T extends Page | Task>({
    entityType,
    entityId,
    getEntityById,
    transformEntityForMove,
    deleteEntity,
    createEntity,
    locationFromId,
}: {
    entityType: string;
    entityId: string;
    getEntityById: any;
    transformEntityForMove: TransformEntityFn<T>;
    deleteEntity: DeleteEntityFn;
    createEntity: CreateEntityFn;
    locationFromId: LocationFromIdFn;
}): {
    targetSpaceUrlKey: string | null;
    setTargetSpaceUrlKey: React.Dispatch<React.SetStateAction<string | null>>;
    providerConfig: {
        documentName: string | null;
        offlineDocumentName: string | null;
        onSyncedCallback: (spaceProvider: HocuspocusProvider) => void;
    };
    moveEntityToAnotherSpace: ({
        targetSpaceUrlKey,
        spaceProvider,
    }: {
        targetSpaceUrlKey: string;
        spaceProvider: HocuspocusProvider;
    }) => void;
} {
    const { space } = useSpace();
    const history = useHistory();
    const currentWorkspace = useCurrentWorkspace();
    const { duplicateAssets } = useDuplicateAssets();
    const performActionWithYBlocks = usePerformActionWithYBlocks();
    const [targetSpaceUrlKey, setTargetSpaceUrlKey] = useState<string | null>(null);

    const moveEntityToAnotherSpace = useCallback(
        ({ targetSpaceUrlKey, spaceProvider }: { targetSpaceUrlKey: string; spaceProvider: HocuspocusProvider }) => {
            const map = spaceProvider.document.getMap('space');
            const targetSafeSpace = safeMap({ definition: spaceD, map });

            performActionWithYBlocks(locationFromId(entityId), async (blocks) => {
                const entity = getEntityById(space, entityId);
                assertNonNull(entity);

                // Duplicate assets on S3
                await duplicateAssets({
                    blocks: blocks.toJSON(),
                    sourceSpaceUrlKey: currentWorkspace.urlKey,
                    targetSpaceUrlKey,
                });

                // Transforms blocks with new block ids and asset URLs
                const transformedEntity = transformEntityForMove(
                    entityType,
                    { ...entity, blocks: blocks.toJSON() },
                    currentWorkspace.urlKey,
                    targetSpaceUrlKey,
                );

                // Create a new entity in the target space
                const newEntity = createEntity(targetSafeSpace, transformedEntity, spaceProvider);
                deleteEntity(space, entityId);
                {
                    entityType === 'page'
                        ? history.push(`/s/${targetSpaceUrlKey}/${newEntity.id}`)
                        : history.push(`/s/${targetSpaceUrlKey}/t/${newEntity.id}`);
                }
            });
        },
        [
            currentWorkspace.urlKey,
            duplicateAssets,
            entityId,
            history,
            space,
            performActionWithYBlocks,
            getEntityById,
            transformEntityForMove,
            deleteEntity,
            createEntity,
            locationFromId,
            entityType,
        ],
    );

    const providerConfig = useMemo(
        () => ({
            documentName: targetSpaceUrlKey ? DocumentName.build('space', targetSpaceUrlKey) : null,
            offlineDocumentName: targetSpaceUrlKey ? DocumentName.build('space-offline', targetSpaceUrlKey) : null,
            onSyncedCallback: (spaceProvider: HocuspocusProvider) => {
                targetSpaceUrlKey && moveEntityToAnotherSpace({ targetSpaceUrlKey, spaceProvider });
            },
        }),
        [targetSpaceUrlKey, moveEntityToAnotherSpace],
    );

    useRealtimeProvider(providerConfig);

    return {
        targetSpaceUrlKey,
        setTargetSpaceUrlKey,
        providerConfig,
        moveEntityToAnotherSpace,
    };
}
