import { BlockBuilder } from '../..';
import { newBlankPage } from '../../pageUtils';

export function newSagaAdvancedPageEs(collectionIds: string[]) {
    return newBlankPage({
        title: 'Saga avanzado',
        icon: {
            type: 'emoji',
            colons: ':heart_eyes:',
        },
        collections: collectionIds,
        blocks: [
            BlockBuilder.title('Saga avanzado'),
            BlockBuilder.callout([
                BlockBuilder.paragraph([
                    BlockBuilder.text('Explora características más poderosas y comienza a trabajar mejor con Saga.'),
                ]),
            ]),
            BlockBuilder.headingThree('Mantente organizado sin pensarlo'),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Crea '),
                BlockBuilder.link('https://saga.so/guides/collections-of-pages', 'colecciones'),
                BlockBuilder.text(' y agrupa páginas similares.'),
            ]),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Fija las páginas y colecciones más importantes en la '),
                BlockBuilder.link('https://saga.so/guides/sidebar', 'barra lateral'),
                BlockBuilder.text('.'),
            ]),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Cambia rápidamente entre páginas, tareas y colecciones con la '),
                BlockBuilder.link('https://saga.so/guides/search-in-saga', 'búsqueda unificada'),
                BlockBuilder.text('.'),
            ]),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Haz clic en '),
                BlockBuilder.link('https://saga.so/guides/task-management', 'Tareas'),
                BlockBuilder.text(' en la barra lateral para gestionar todas las tareas de todas tus páginas en un solo lugar.'),
            ]),
            BlockBuilder.paragraph(),
            BlockBuilder.headingThree('Conecta tu conocimiento como un profesional'),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Usa el menú de autocompletado para '),
                BlockBuilder.link('https://saga.so/guides/page-links', 'mencionar páginas'),
                BlockBuilder.text(' y crear enlaces dentro de tu espacio de trabajo.'),
            ]),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Abre las '),
                BlockBuilder.link('https://saga.so/guides/references', 'Referencias'),
                BlockBuilder.text(' al lado derecho para ver dónde se ha mencionado tu página actual.'),
            ]),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Crea enlaces a páginas '),
                BlockBuilder.link('https://saga.so/guides/automatic-linking', 'automáticamente'),
                BlockBuilder.text(' escribiendo sus títulos. Por ejemplo, este es un enlace a Saga IA.'),
            ]),
            BlockBuilder.checkListItem([
                BlockBuilder.link('https://saga.so/guides/page-links', 'Previsualiza'),
                BlockBuilder.text(' las páginas instantáneamente al pasar el cursor sobre los enlaces.'),
            ]),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Crea '),
                BlockBuilder.link('https://saga.so/guides/live-blocks', 'Bloques en vivo'),
                BlockBuilder.text(
                    ' para sincronizar contenido a través de páginas sin esfuerzo, eliminando duplicados y manteniendo la información actualizada.',
                ),
            ]),
            BlockBuilder.paragraph(),
            BlockBuilder.headingThree('Sé productivo como nunca antes'),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Aprende los '),
                BlockBuilder.link('https://saga.so/guides/keyboard-shortcuts', 'atajos de teclado'),
                BlockBuilder.text('.'),
            ]),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Abre páginas en vista '),
                BlockBuilder.link('https://saga.so/guides/side-by-side-view', 'lado a lado'),
                BlockBuilder.text(' para trabajar en dos páginas a la vez.'),
            ]),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Encuentra cualquier cosa al instante en todo tu espacio de trabajo con la '),
                BlockBuilder.link('https://saga.so/guides/search-in-saga', 'búsqueda unificada'),
                BlockBuilder.text('.'),
            ]),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Intégrate con '),
                BlockBuilder.link('https://saga.so/guides/google-drive-integration', 'Google Drive'),
                BlockBuilder.text(' o '),
                BlockBuilder.link('https://saga.so/guides/linear-integration', 'Linear'),
                BlockBuilder.text(' para mencionar o buscar archivos y tareas almacenados dentro de ellos.'),
            ]),
            BlockBuilder.checkListItem([
                BlockBuilder.text(
                    'Dirígete a ✨ Saga IA para aprender cómo usar el poderoso asistente de IA directamente en tu espacio de trabajo.',
                ),
            ]),
            BlockBuilder.paragraph(),
            BlockBuilder.divider(),
            BlockBuilder.headingThree('Más guías y preguntas frecuentes'),
            BlockBuilder.paragraph([
                BlockBuilder.text('Visita las Guías de Saga y descubre cómo sacar el máximo provecho de la aplicación.'),
            ]),
            BlockBuilder.rawPrettyLink('https://saga.so/guides', {
                _tag: 'loaded',
                description: "Esta guía te ayudará a entender los bloques de construcción de Saga y te pondrá al día.",
                title: '📍 Guías de Saga',
                logo: 'https://saga.so/thumbnail.png',
            }),
            BlockBuilder.paragraph(),
        ],
    });
}
