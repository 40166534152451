import React from 'react';
import { useHistory } from 'react-router-dom';
import Onboarding from './Onboarding';
import * as api from '@saga/api';
import Button from '../styled/Button';
import { pluralize } from '@/utils/stringUtils';
import WorkspaceAvatar from '../navigation/WorkspaceAvatar';
import { useTranslation, Trans } from 'react-i18next';

function CustomContainer({ children }: { children: React.ReactNode }) {
    return <div className="space-y-5 text-center p-8 md:px-20 md:py-16 lg:px-26 lg:py-20 max-w-3xl">{children}</div>;
}

function InviteOnboardingScreen() {
    const { t } = useTranslation();
    const history = useHistory();
    const { data, loading } = api.useUserInvitesQuery({
        fetchPolicy: 'cache-and-network',
    });

    const numberOfInvites = data?.userInvites.length ?? 0;

    const [acceptInvite, { loading: acceptingInvite }] = api.useAcceptInvitationMutation({
        refetchQueries: [api.SpacesDocument, api.UserInvitesDocument],
    });

    const onAcceptInvite = async (id: string) => {
        const result = await acceptInvite({
            variables: {
                input: {
                    id,
                },
            },
        }).then((result) => result.data?.acceptInvitation);

        if (!result) return;
        const spaceUrlKey = result.urlKey;

        history.push(`/s/${spaceUrlKey}`);
    };

    if (!loading && numberOfInvites === 0) {
        return (
            <Onboarding>
                <CustomContainer>
                    <Onboarding.Container>
                        <Onboarding.Heading>{t('invite_onboarding.join_title')}</Onboarding.Heading>
                        <Onboarding.Paragraph>{t('invite_onboarding.invited_description')}</Onboarding.Paragraph>

                        <div className="flex justify-center items-center">
                            <div>
                                <Onboarding.Button
                                    onClick={() => history.push('/new-workspace')}
                                    disabled={acceptingInvite}
                                    type="button"
                                >
                                    {t('invite_onboarding.create_button')}
                                </Onboarding.Button>
                            </div>
                        </div>
                    </Onboarding.Container>
                </CustomContainer>
            </Onboarding>
        );
    }

    return (
        <Onboarding>
            <CustomContainer>
                <Onboarding.Container>
                    <Onboarding.Heading>{t('invite_onboarding.join_title')}</Onboarding.Heading>

                    <Onboarding.Paragraph small>
                        <Trans
                            i18nKey="invite_onboarding.invited_description"
                            count={numberOfInvites}
                            components={{ bold: <span className="font-bold" /> }}
                        />
                    </Onboarding.Paragraph>
                    <div className="w-full">
                        <div className="max-h-64 overflow-auto space-y-4 pb-4 w-full">
                            {data?.userInvites.map((invite) => {
                                return (
                                    <div key={invite.inviteId} className="flex items-center justify-between w-full">
                                        <div className="flex items-center w-1/3">
                                            <div className="p-3.5">
                                                <WorkspaceAvatar workspace={{ title: invite.workspaceTitle }} />
                                            </div>
                                            <span className="font-semibold truncate min-w-0">
                                                {invite.workspaceTitle}
                                            </span>
                                        </div>
                                        <div className="text-saga-text-gray">
                                            {t('invite_onboarding.member', { count: invite.workspaceMembersCount })}
                                        </div>
                                        <Button
                                            disabled={acceptingInvite}
                                            onClick={() => onAcceptInvite(invite.inviteId)}
                                            variant="secondary"
                                        >
                                            {t('invite_onboarding.join_button')}
                                        </Button>
                                    </div>
                                );
                            })}
                        </div>
                        <hr className="border-saga-gray-200 dark:border-zinc-600" />
                    </div>

                    <Onboarding.Paragraph small>{t('invite_onboarding.create_space_title')}</Onboarding.Paragraph>
                    <div className="flex justify-center items-center">
                        <div>
                            <Onboarding.Button
                                onClick={() => {
                                    history.push('/new-workspace');
                                }}
                                disabled={acceptingInvite}
                                type="button"
                            >
                                {t('invite_onboarding.create_button')}
                            </Onboarding.Button>
                        </div>
                    </div>
                </Onboarding.Container>
            </CustomContainer>
        </Onboarding>
    );
}

export default InviteOnboardingScreen;
