import React from 'react';
import { CONTENT_URL } from '@/constants';
import { useDuplicatePageAssetsMutation } from '@saga/api';
import _ from 'lodash';

type AssetKeys = {
    sourceSpaceUrlKey: string;
    targetSpaceUrlKey: string;
    assetType: string;
    assetKey: string;
};

function mapAssets({
    blocks,
    sourceSpaceUrlKey,
    targetSpaceUrlKey,
}: {
    blocks: { [key: string]: any }[];
    sourceSpaceUrlKey: string;
    targetSpaceUrlKey: string;
}) {
    let assetKeys: AssetKeys[] = [];
    const duplicatedBlocks = _.cloneDeep(blocks);
    duplicatedBlocks.map((block) => {
        let blockUrl = block.url;
        if (blockUrl) {
            blockUrl = new URL(blockUrl);
            blockUrl.search = '';
            blockUrl = blockUrl.toString();
            const match = blockUrl.match(/spaces\/(.*)/);
            if (match && blockUrl.includes(CONTENT_URL)) {
                block.url = blockUrl.replace(sourceSpaceUrlKey, targetSpaceUrlKey);
                const keys = match[1].split('/');
                assetKeys.push({
                    sourceSpaceUrlKey,
                    targetSpaceUrlKey,
                    assetType: keys[1], // `files` or `images`
                    assetKey: keys[2], // `foo.jpg` or `bar.png`
                });
            }
        }
    });
    return assetKeys;
}

export default function useDuplicateAssets() {
    const [duplicateAssets, { loading, error }] = useDuplicatePageAssetsMutation();

    const duplicateAssetsFn = React.useCallback(
        async ({
            blocks,
            sourceSpaceUrlKey,
            targetSpaceUrlKey,
        }: {
            blocks: { [key: string]: any }[];
            sourceSpaceUrlKey: string;
            targetSpaceUrlKey: string;
        }) => {
            const assetKeys = mapAssets({ blocks, sourceSpaceUrlKey, targetSpaceUrlKey });
            await duplicateAssets({ variables: { input: { assetKeys } } });
        },
        [duplicateAssets],
    );

    return React.useMemo(
        () => ({ duplicateAssets: duplicateAssetsFn, duplicating: loading, error }),
        [loading, error, duplicateAssetsFn],
    );
}
