import { SafeSpace, WeakPage } from '../types';
import transactInSpace from './transactInSpace';
import getPageMapById from './getPageMapById';
import { assertNonNull } from '../utils';
import * as Y from 'yjs';
import { yarrayFromArray, ymapFromRecord } from '../yjs-utils';
import { BlocksLocation } from '../SagaLocation';
import findTaskMap from './findTaskMap';

export type Template = {
    id: WeakPage['id'];
    title: WeakPage['title'];
    icon: WeakPage['icon'];
    aliases?: WeakPage['aliases'];
    collections?: WeakPage['collections'];
    wordCount?: WeakPage['wordCount'];
};

export default function applyTemplateMetaToLocation(
    space: SafeSpace,
    { template, location }: { template: Template; location: BlocksLocation },
) {
    switch (location.type) {
        case 'page': {
            const targetPageMap = getPageMapById(space, location.pageId);
            assertNonNull(
                targetPageMap,
                `applyTemplateBlocksToLocation: PageMap for target with id ${location.pageId} not found`,
            );

            transactInSpace(space, () => {
                if (template.icon && targetPageMap.get('icon') == null) {
                    targetPageMap.set('icon', ymapFromRecord(template.icon));
                }

                const targetAliases = targetPageMap.get('aliases');
                if (template.aliases && targetAliases instanceof Y.Array && targetAliases.length === 0) {
                    targetPageMap.set('aliases', yarrayFromArray(template.aliases));
                }

                const targetCollections = targetPageMap.get('collections');
                if (template.collections && targetCollections instanceof Y.Array && targetCollections.length === 0) {
                    targetPageMap.set('collections', yarrayFromArray(template.collections));
                }

                const targetPageTitle = targetPageMap.get('title');
                if (targetPageTitle == null || (typeof targetPageTitle === 'string' && targetPageTitle.trim() === '')) {
                    targetPageMap.set('title', template.title);
                }

                if (template.wordCount) {
                    targetPageMap.set('wordCount', template.wordCount);
                }

                // We need to apply createdAt and updatedAt again once we applied the template,
                // to make sure that we correctly get the recently used templates
                targetPageMap.set('createdAt', new Date().toString());
                targetPageMap.set('updatedAt', new Date().toString());

                // we also always need to apply the createdFromTemplate id
                targetPageMap.set('createdFromTemplate', template.id);
            });
            break;
        }
        case 'task': {
            const targetTaskMap = findTaskMap(space, location.taskId);

            assertNonNull(
                targetTaskMap,
                `applyTemplateBlocksToLocation: TaskMap for target with id ${location.taskId} not found`,
            );

            transactInSpace(space, () => {
                // We need to apply createdAt and updatedAt again once we applied the template,
                // to make sure that we correctly get the recently used templates
                targetTaskMap.set('createdAt', new Date().toString());
                targetTaskMap.set('updatedAt', new Date().toString());

                // we also always need to apply the createdFromTemplate id
                // targetPageMap.set('createdFromTemplate', template.id);
            });
            break;
        }
    }
}
