import React, { useCallback, useMemo, useState } from 'react';
import { ChevronDown, ChevronUp, Edit3, Eye, EyeOff, Trash } from 'react-feather';

import SettingsPane from '../SettingsPane';
import * as api from '@saga/api';

import classNames from 'classnames';
import { CustomPromptType } from '@saga/api';
import BasicButton from '@/components/styled/BasicButton';
import { AI, Magic } from '@/assets/icons';

import DeletePromptModal from '@/components/settings/ai/DeletePromptModal';
import useAISuggestedPrompts, { AIActionCategory, AIActionSuggestion } from '@/hooks/useAISuggestedPrompts';
import { useUserContext } from '@/components/UserContext';

import Spinner from '@/components/loading/Spinner';
import { track } from '@/analytics';
import { Trans, useTranslation } from 'react-i18next';
import { TodesktopLink } from '@/components/Desktop';
import PromptModal from '@/components/settings/ai/PromptModal';

interface DefaultRowInputParams {
    suggestion: AIActionSuggestion;
    hidden: boolean;
    onHide: () => void;
    loading: boolean;
}

interface CustomRowInputParams {
    suggestion: AIActionSuggestion;
    onMoveUp: () => void;
    onMoveDown: () => void;
    onDelete: () => void;
    onEdit: () => void;
    canMoveUp: boolean;
    canMoveDown: boolean;
    loader?: PendingOperationType;
}

interface PromptSectionInputParams {
    editable?: boolean;
    section: AIActionCategory;
}

export type PromptModalData = {
    visible: boolean;
    prompt: Omit<api.CustomAiPrompt, '__typename' | 'position' | 'title' | 'prompt'> | null;
};

type DeletePromptModalData = {
    visible: boolean;
    prompt?: api.CustomAiPrompt;
};

type PendingOperationType = 'moveUp' | 'moveDown' | 'delete' | 'hide' | 'edit' | 'add';

type PendingOperation = {
    key?: string;
    type: PendingOperationType;
};

const AIDefaultPromptRow = ({ suggestion, hidden, onHide, loading }: DefaultRowInputParams) => {
    const { t } = useTranslation();
    return (
        <div
            key={suggestion.key}
            className={classNames(
                'flex flex-row text-sm font-normal py-2 px-2 text-saga-text dark:text-saga-gray-300 items-center',
                { 'text-saga-gray-500 dark:text-saga-gray-500': hidden },
            )}
        >
            <suggestion.icon className="w-[14px] h-[14px] mr-2" />
            <div className="flex-1">{t(suggestion.title)}</div>
            {loading ? (
                <Spinner size={16} />
            ) : hidden ? (
                <EyeOff className="cursor-pointer" onClick={!loading && onHide} size={16} />
            ) : (
                <Eye className="cursor-pointer" onClick={!loading && onHide} size={16} />
            )}
        </div>
    );
};

const AICustomPromptRow = ({
    suggestion,
    onMoveUp,
    onMoveDown,
    onEdit,
    onDelete,
    canMoveDown,
    canMoveUp,
    loader,
}: CustomRowInputParams) => (
    <div
        key={suggestion.key}
        className="flex flex-row text-base font-normal py-2 px-2 text-saga-text dark:text-saga-gray-300 items-center"
    >
        <suggestion.icon className="shrink-0 w-[14px] h-[14px] mr-2" />
        <div className="flex-1 shrink-1 truncate">{suggestion.title}</div>
        <div className="ml-2">
            {loader === 'moveUp' ? (
                <Spinner size={16} />
            ) : (
                <ChevronUp
                    className={classNames({
                        'cursor-pointer': canMoveUp,
                        'text-saga-gray-500 dark:text-saga-gray-500': !canMoveUp,
                    })}
                    onClick={canMoveUp ? onMoveUp : undefined}
                    size={16}
                />
            )}
        </div>
        <div className="ml-2">
            {loader === 'moveDown' ? (
                <Spinner size={16} />
            ) : (
                <ChevronDown
                    className={classNames({
                        'cursor-pointer': canMoveDown,
                        'text-saga-gray-500 dark:text-saga-gray-500': !canMoveDown,
                    })}
                    onClick={canMoveDown ? onMoveDown : undefined}
                    size={16}
                />
            )}
        </div>
        <div className="mx-4">
            {loader === 'edit' ? (
                <Spinner size={16} />
            ) : (
                <Edit3 className="cursor-pointer" onClick={onEdit} size={16} />
            )}
        </div>
        {loader === 'delete' ? (
            <Spinner size={16} />
        ) : (
            <Trash className="cursor-pointer" onClick={onDelete} size={16} />
        )}
    </div>
);

export function AISettings() {
    const { t } = useTranslation();
    const { staticPromptSuggestions, userPromptSuggestions } = useAISuggestedPrompts();
    const { userSettings } = useUserContext();

    const [updateUserSettings] = api.useUpdateUserSettingsMutation({
        refetchQueries: [api.UserDataDocument],
        awaitRefetchQueries: true,
        onCompleted: () => setPendingOp(undefined),
    });

    const [removeAIPrompt] = api.useRemoveAiPromptMutation({
        refetchQueries: [api.UserDataDocument],
        awaitRefetchQueries: true,
        onCompleted: () => setPendingOp(undefined),
    });
    const [updateAIPrompts] = api.useUpdateAiPromptsMutation({
        refetchQueries: [api.UserDataDocument],
        awaitRefetchQueries: true,
        onCompleted: () => setPendingOp(undefined),
    });

    const [editPromptData, setEditPromptData] = useState<PromptModalData>({ visible: false, prompt: null });
    const [deletePromptData, setDeletePromptData] = useState<DeletePromptModalData>({ visible: false });

    const [pendingOp, setPendingOp] = useState<PendingOperation>();

    const userPrompts = useMemo(
        () => userPromptSuggestions?.filter((prompt) => prompt.type === CustomPromptType.Prompt),
        [userPromptSuggestions],
    );
    const userCommands = useMemo(
        () => userPromptSuggestions?.filter((prompt) => prompt.type === CustomPromptType.Command),
        [userPromptSuggestions],
    );

    const userPromptsData = useMemo(
        () =>
            userPrompts?.length
                ? {
                      title: t('ai.my_prompts'),
                      suggestions: userPrompts.map((command) => ({
                          key: command.id,
                          title: command.title,
                          icon: Magic,
                      })),
                  }
                : null,
        [userPrompts, t],
    );

    const userCommandsData = useMemo(
        () =>
            userCommands?.length
                ? {
                      title: t('ai.my_commands'),
                      suggestions: userCommands.map((command) => ({
                          key: command.id,
                          title: command.title,
                          icon: AI,
                      })),
                  }
                : null,
        [userCommands, t],
    );

    const defaultPrompts = staticPromptSuggestions[CustomPromptType.Prompt];
    const defaultCommands = staticPromptSuggestions[CustomPromptType.Command];

    const openEditPrompt = useCallback((prompt) => {
        setEditPromptData({ visible: true, prompt });
    }, []);

    const openNewPrompt = useCallback((type: api.CustomPromptType) => {
        setEditPromptData({ visible: true, prompt: { id: '', type } });
    }, []);

    const closeEditPrompt = useCallback(() => {
        setEditPromptData({ visible: false, prompt: null });
    }, []);

    const openDeletePrompt = useCallback(
        (key) => {
            setDeletePromptData({
                visible: true,
                prompt: userPromptSuggestions?.find((prompt) => prompt.id === key),
            });
        },
        [userPromptSuggestions],
    );

    const closeDeletePrompt = useCallback(() => {
        setDeletePromptData({ visible: false });
    }, []);

    const onHide = useCallback(
        async (key) => {
            const hidden = userSettings?.hiddenDefaultPrompts ?? [];

            if (hidden.includes(key)) {
                track('ai-prompt-settings-unhide');
                await updateUserSettings({
                    variables: { input: { hiddenDefaultPrompts: hidden.filter((existing) => existing !== key) } },
                });
            } else {
                track('ai-prompt-settings-hide');
                await updateUserSettings({
                    variables: { input: { hiddenDefaultPrompts: [...hidden, key] } },
                });
            }
        },
        [userSettings, updateUserSettings],
    );

    const onEdit = useCallback(
        (key) => {
            const prompt = userPromptSuggestions?.find((prompt) => prompt.id === key);
            openEditPrompt(prompt);
        },
        [userPromptSuggestions, openEditPrompt],
    );

    const updatePromptPosition = useCallback(
        (key, offset) => {
            const prompt = userPrompts?.find((prompt) => prompt.id === key);
            const command = userCommands?.find((command) => command.id === key);

            const dataArray = prompt ? userPrompts : userCommands;
            const element = prompt ?? command;
            const position = prompt ? prompt.position : command?.position;

            if (position == null || !element) return;

            const switchElement = dataArray?.find((prev) => prev.position === position + offset);

            if (!switchElement) return;

            updateAIPrompts({
                variables: {
                    input: [
                        { id: switchElement.id, position },
                        { id: element.id, position: switchElement.position },
                    ],
                },
            });
        },
        [userCommands, userPrompts, updateAIPrompts],
    );

    const onMoveUp = useCallback(
        (key) => {
            setPendingOp({ key, type: 'moveUp' });
            updatePromptPosition(key, -1);
        },
        [updatePromptPosition],
    );

    const onMoveDown = useCallback(
        (key) => {
            setPendingOp({ key, type: 'moveDown' });
            updatePromptPosition(key, 1);
        },
        [updatePromptPosition],
    );

    const AIPromptSection = useCallback(
        ({ section, editable }: PromptSectionInputParams) => (
            <div
                key={section.title}
                className="mt-6 pb-1 px-1.5 text-xs font-medium leading-normal text-saga-gray-500 dark:text-zinc-400 rounded-md shadow-md border dark:border-saga-gray-dark divide-y divide-saga-gray-150 dark:divide-saga-gray-800"
            >
                <div className="p-2 flex flex-row justify-between items-center">
                    {t(section.title)}
                    {pendingOp?.type === 'add' && <Spinner size={14} />}
                </div>
                {section.suggestions.map((suggestion, index) =>
                    editable ? (
                        <AICustomPromptRow
                            key={suggestion.key}
                            suggestion={suggestion}
                            onDelete={() => openDeletePrompt?.(suggestion.key)}
                            onEdit={() => onEdit?.(suggestion.key)}
                            onMoveUp={() => onMoveUp?.(suggestion.key)}
                            onMoveDown={() => onMoveDown?.(suggestion.key)}
                            canMoveUp={index !== 0}
                            canMoveDown={index < section.suggestions.length - 1}
                            loader={pendingOp?.key === suggestion.key ? pendingOp.type : undefined}
                        />
                    ) : (
                        <AIDefaultPromptRow
                            key={suggestion.key}
                            suggestion={suggestion}
                            hidden={suggestion.hidden ?? false}
                            loading={pendingOp?.key === suggestion.key && pendingOp.type === 'hide'}
                            onHide={async () => {
                                if (pendingOp) {
                                    return;
                                }
                                setPendingOp({ type: 'hide', key: suggestion.key });
                                onHide?.(suggestion.key);
                            }}
                        />
                    ),
                )}
            </div>
        ),
        [openDeletePrompt, onEdit, onMoveUp, onMoveDown, onHide, pendingOp, t],
    );

    return (
        <>
            {editPromptData.visible && editPromptData.prompt && (
                <PromptModal
                    prompt={{
                        id: !editPromptData.prompt.id.length ? undefined : editPromptData.prompt.id,
                        type: editPromptData.prompt.type,
                    }}
                    onClose={closeEditPrompt}
                />
            )}
            <DeletePromptModal
                open={deletePromptData.visible}
                onClose={closeDeletePrompt}
                onConfirm={() => {
                    if (deletePromptData.prompt) {
                        setPendingOp({ type: 'delete', key: deletePromptData.prompt.id });
                        removeAIPrompt({ variables: { input: { id: deletePromptData.prompt.id } } });
                    }
                }}
                type={deletePromptData.prompt?.type}
            />
            <SettingsPane>
                <div>
                    <SettingsPane.Title>{t('settings.ai.title')}</SettingsPane.Title>
                    <SettingsPane.Small>{t('settings.ai.sub_title')}</SettingsPane.Small>
                </div>
                <SettingsPane.Content>
                    <SettingsPane.Section>
                        <div className="max-w-xl">
                            <div className="flex flex-row align-center justify-between">
                                <SettingsPane.Title>Prompts</SettingsPane.Title>
                                <BasicButton
                                    className="ml-3 px-4 h-8"
                                    size="small"
                                    variant="secondary"
                                    onClick={() => openNewPrompt(api.CustomPromptType.Prompt)}
                                >
                                    {t('settings.ai.commands.add_prompt')}
                                </BasicButton>
                            </div>
                            <SettingsPane.Small>
                                <Trans
                                    i18nKey="settings.ai.commands.customize_prompts"
                                    components={{
                                        1: (
                                            <TodesktopLink
                                                path="https://saga.so/guides/custom-ai-commands-and-prompts"
                                                className="text-saga-text-link dark:text-saga-text-link-dark cursor-pointer"
                                            />
                                        ),
                                    }}
                                />
                            </SettingsPane.Small>
                            {userPromptsData && (
                                <AIPromptSection key={userPromptsData.title} section={userPromptsData} editable />
                            )}
                            {defaultPrompts.map((category) => (
                                <AIPromptSection key={category.title} section={category} />
                            ))}
                        </div>
                        <div className="mt-6 mb-4 max-w-xl">
                            <div className="flex flex-row align-center justify-between">
                                <SettingsPane.Title>{t('settings.ai.commands.title')}</SettingsPane.Title>
                                <BasicButton
                                    className="ml-3 px-4 h-8"
                                    size="small"
                                    variant="secondary"
                                    onClick={() => openNewPrompt(api.CustomPromptType.Command)}
                                >
                                    {t('settings.ai.commands.add_command')}
                                </BasicButton>
                            </div>
                            <SettingsPane.Small>
                                <Trans
                                    i18nKey="settings.ai.commands.customize_prompts"
                                    components={{
                                        1: (
                                            <TodesktopLink
                                                path="https://saga.so/guides/custom-ai-commands-and-prompts"
                                                className="text-saga-text-link dark:text-saga-text-link-dark cursor-pointer"
                                            />
                                        ),
                                    }}
                                />
                            </SettingsPane.Small>
                            {userCommandsData && (
                                <AIPromptSection key={userCommandsData.title} section={userCommandsData} editable />
                            )}
                            {defaultCommands.map((category) => (
                                <AIPromptSection key={category.title} section={category} />
                            ))}
                        </div>
                    </SettingsPane.Section>
                </SettingsPane.Content>
            </SettingsPane>
        </>
    );
}
