import { PopOver } from '@/components/popover/PopOver';
import FormatIcon from '@/components/styled/FormatIcon';
import { formatTypes } from '@/constants';
import React from 'react';

import { PageIcon } from '@/components/icons';
import useMobile from '@/hooks/useMobile';
import { BlockType, FormatBlockElementTypeDef } from '@saga/shared';
import { CheckCircle, Link, Trash } from 'react-feather';
import ItemGroups from '../ItemGroups';
import Button from '../styled/Button';
import LinearIcon from '@/assets/LinearIcon';
import * as api from '@saga/api';
import { search } from '../search/useSearchQuery';
import Tooltip from './Tooltip';
import Dropdown, { useArrowSelectableIndex } from './Dropdown';
import { useSlateStatic } from 'slate-react';
import { Lightning } from '@/assets/icons';
import { Trans, useTranslation } from 'react-i18next';
import { useUserContext } from '../UserContext';
import { scrollToSelectedIndex } from '../editor/Suggestions';

export type TurnIntoResult =
    | { type: 'page'; value: string }
    | { type: 'task'; value: string }
    | { type: 'linear-issue'; value: string; team: api.LinearTeamFragment }
    | { type: 'block'; blockType: FormatBlockElementTypeDef };

type TurnIntoPopOverProps = {
    currentFormat: string;
    attachToRef: React.MutableRefObject<HTMLDivElement | HTMLButtonElement | null>;
    isOpen: boolean;
    onClose(): void;
    onTurnInto: (result: TurnIntoResult) => void;
    zIndex?: number;
    selectedValue: string;
    canTurnIntoPage: boolean;
    canTurnIntoTask: boolean;
    canTurnIntoLinearIssue: boolean;
    onCopyLinkToBlock?: () => void;
    onDeleteBlock?: () => void;
    onAskAI?: () => void;
    align?: 'right' | 'left';
};

type TurnIntoSuggestion = {
    type: 'turnIntoSuggestion';
    onSelect(): void;
    isActive: boolean;
    content: React.ReactNode;
    tooltipLabel?: string;
};

type OtherSuggestion = {
    type: 'other';
    onSelect(): void;
    isActive: boolean;
    content: React.ReactNode;
    tooltipLabel?: string;
};

type AskAISuggestion = {
    type: 'askAI';
    onSelect(): void;
    isActive: boolean;
    content: React.ReactNode;
    tooltipLabel?: string;
};

type Suggestion = TurnIntoSuggestion | OtherSuggestion | AskAISuggestion;

const TurnIntoPopOver = function TurnIntoPopOver({
    currentFormat,
    attachToRef,
    isOpen,
    onClose,
    onTurnInto,
    zIndex,
    selectedValue,
    canTurnIntoPage,
    canTurnIntoTask,
    canTurnIntoLinearIssue,
    onCopyLinkToBlock,
    onDeleteBlock,
    onAskAI,
    align,
}: TurnIntoPopOverProps) {
    const editor = useSlateStatic();
    const { user } = useUserContext();
    const { data } = api.useLinearTeamsQuery();
    const containerRef = React.useRef<HTMLDivElement>(null);
    const linearTeams = React.useMemo(() => data?.linearTeams ?? [], [data]);
    const { t } = useTranslation();

    const filteredFormatTypes = formatTypes(user?.data.language ?? api.Language.En)
        .filter((f) => !([BlockType.IMAGE, BlockType.DIVIDER, BlockType.TABLE] as string[]).includes(f.value as string))
        .filter((s) => (s.isEnabled ? s.isEnabled(editor, editor.selection, undefined) : true));

    const formatSuggestions = filteredFormatTypes.map((formatType): TurnIntoSuggestion => {
        return {
            type: 'turnIntoSuggestion' as const,
            onSelect() {
                onTurnInto({ type: 'block', blockType: formatType.value });
            },
            isActive: currentFormat === formatType.value,
            content: (
                <div className="space-x-2 flex items-center">
                    <FormatIcon type={formatType.value} active={currentFormat === formatType.value}></FormatIcon>
                    <div>{t(formatType.label)}</div>
                </div>
            ),
        };
    });

    const teamSuggestions = linearTeams.map((team): TurnIntoSuggestion => {
        return {
            type: 'turnIntoSuggestion' as const,
            onSelect() {
                onTurnInto({ type: 'linear-issue', value: selectedValue, team });
            },
            isActive: false,
            content: (
                <div className="space-x-2 flex items-center min-w-0">
                    <LinearIcon className="flex-none w-3.5 h-3.5 shrink-0" />
                    <div className="truncate leading-normal">
                        <Trans
                            i18nKey="editor.turn_into_liner_issue"
                            shouldUnescape
                            components={{ 1: <span className="font-semibold" /> }}
                            values={{ value: selectedValue, team: team.name }}
                        />
                    </div>
                </div>
            ),
            tooltipLabel: t('editor.turn_into_liner_issue_tooltip', {
                value: selectedValue,
                team: team.name,
            }) as string,
        };
    });

    const items: Suggestion[] = [
        ...(onCopyLinkToBlock
            ? [
                  {
                      type: 'other' as const,
                      onSelect() {
                          onCopyLinkToBlock();
                      },
                      isActive: false,
                      content: (
                          <div className="space-x-1 flex items-center min-w-0">
                              <Link className="flex-none shrink-0 mr-1" size={14} />
                              <div className="truncate leading-normal">{t('editor.copy_link_to_block')}</div>
                          </div>
                      ),
                  },
              ]
            : []),
        ...(onDeleteBlock
            ? [
                  {
                      type: 'other' as const,
                      onSelect() {
                          onDeleteBlock();
                      },
                      isActive: false,
                      content: (
                          <div className="space-x-1 flex items-center min-w-0">
                              <Trash className="flex-none shrink-0 mr-1" size={14} />
                              <div className="truncate leading-normal">{t('editor.delete_block')}</div>
                          </div>
                      ),
                  },
              ]
            : []),
        ...(onAskAI
            ? [
                  {
                      type: 'askAI' as const,
                      onSelect() {
                          onAskAI();
                      },
                      isActive: false,
                      content: (
                          <div className="space-x-1 flex items-center min-w-0">
                              <Lightning className="fill-saga-text dark:fill-saga-gray-300 flex-none w-[18px] h-[18px] shrink-0 -ml-0.5 mr-0.5" />
                              <div className="truncate leading-normal">{t('ai.ask_ai')}</div>
                          </div>
                      ),
                  },
              ]
            : []),
        ...(canTurnIntoPage
            ? [
                  {
                      type: 'turnIntoSuggestion' as const,
                      onSelect() {
                          onTurnInto({ type: 'page', value: selectedValue });
                      },
                      isActive: false,
                      content: (
                          <div className="space-x-2 flex items-center min-w-0">
                              <PageIcon icon={undefined} isTemplate={false} />
                              <div className="truncate leading-normal">
                                  <Trans
                                      i18nKey="editor.turn_into_page"
                                      shouldUnescape
                                      components={{ 1: <span className="font-semibold" /> }}
                                      values={{ value: selectedValue }}
                                  />
                              </div>
                          </div>
                      ),
                  },
              ]
            : []),
        ...(canTurnIntoTask
            ? [
                  {
                      type: 'turnIntoSuggestion' as const,
                      onSelect() {
                          onTurnInto({ type: 'task', value: selectedValue });
                      },
                      isActive: false,
                      content: (
                          <div className="space-x-2 flex items-center min-w-0">
                              <CheckCircle className="flex-none w-3.5 h-3.5 shrink-0" />
                              <div className="truncate leading-normal">
                                  <Trans
                                      i18nKey="editor.turn_into_task"
                                      shouldUnescape
                                      components={{ 1: <span className="font-semibold" /> }}
                                      values={{ value: selectedValue }}
                                  />
                              </div>
                          </div>
                      ),
                  },
              ]
            : []),
        ...(canTurnIntoLinearIssue && search.length > 0 ? teamSuggestions : []),
        ...formatSuggestions,
    ];

    const isMobile = useMobile();

    const { index, setIndex } = useArrowSelectableIndex({
        onSelect(index) {
            items[index].onSelect();
            onClose();
        },

        onUpdate() {
            if (containerRef.current) {
                scrollToSelectedIndex(containerRef.current);
            }
        },
        maxIndex: items.length - 1,
        initialIndex: 0,
        selectWithTab: true,
    });

    const popOverButtonItem = (item: Suggestion, currentIndex: number) => (
        <Button.PopOverButton
            key={currentIndex}
            active={item.isActive}
            selected={currentIndex === index}
            onMouseEnter={() => setIndex(currentIndex)}
            type="button"
            onMouseDown={() => {
                item.onSelect();
                onClose();
            }}
        >
            {item.content}
        </Button.PopOverButton>
    );

    return (
        <Dropdown
            testId={'format-block-popover'}
            isOpen={isOpen}
            attachToRef={attachToRef}
            onClose={onClose}
            zIndex={zIndex}
            maxWHighlight
            position="below"
            align={align ? align : isMobile ? 'right' : 'left'}
            overflow={false}
        >
            <div ref={containerRef} className="min-w-0 max-h-96">
                <ItemGroups
                    maxHeight={384}
                    divider={
                        <div className="py-0.5">
                            <PopOver.Divider />
                        </div>
                    }
                    items={items}
                    labels={{
                        turnIntoSuggestion: <PopOver.Label>{t('editor.turn_into')}</PopOver.Label>,
                        askAI: <PopOver.Label>Saga AI</PopOver.Label>,
                        other: <PopOver.Label>{t('editor.actions')}</PopOver.Label>,
                    }}
                    renderItem={(item, currentIndex) => {
                        return item?.tooltipLabel ? (
                            <Tooltip placement="right" content={item?.tooltipLabel}>
                                {popOverButtonItem(item, currentIndex)}
                            </Tooltip>
                        ) : (
                            popOverButtonItem(item, currentIndex)
                        );
                    }}
                />
            </div>
        </Dropdown>
    );
};

export default TurnIntoPopOver;
