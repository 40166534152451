import { EditorOperations, Normalization } from '..';
import { Editor } from 'slate';
import { BlockPlugin } from './Plugins';
import * as Y from 'yjs';
import { Normalizer } from '../Normalization/Normalizers';

// extend base slate editor with custom logic
const extendEditor = (
    editor: Editor,
    blockPlugins: BlockPlugin[] = [],
    normalizers: Normalizer[] = [],
    undoManager?: Y.UndoManager,
) => {
    Normalization.extendEditorForNormalization(editor, blockPlugins, normalizers);
    editor.insertText = EditorOperations.Extensions.insertText(editor, undoManager);
    editor.insertNode = EditorOperations.Extensions.insertNode(editor);
    editor.insertBreak = EditorOperations.Extensions.insertBreak(editor);
    editor.deleteBackward = EditorOperations.Extensions.deleteBackward(editor, blockPlugins);
    editor.deleteForward = EditorOperations.Extensions.deleteForward(editor);

    return editor;
};

export default extendEditor;
