import React, { useState, useEffect } from 'react';
import Button, { ButtonNew } from '@/components/styled/Button';
import { UrlUtils } from '@saga/shared';
import { track } from '@/analytics';
import { Link, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { IS_PRODUCTION } from '@/constants';
import Onboarding from '@/components/onboarding/Onboarding';
import Input from '../styled/Input';
import useRequestSignInLink from '@/hooks/useRequestSignInLink';
import { useDesktopContext } from '@/components/DesktopContext';
import { signInWithEmailAndPassword } from '@firebase/auth';
import { auth, signInWithGoogleProvider } from '@/firebase';
import { Trans, useTranslation } from 'react-i18next';
import mailChecker from 'mailchecker';
import _ from 'lodash';
import { isDebug } from '@/utils';
import useMobile from '@/hooks/useMobile';

export enum LoginState {
    INITIAL,
    LOADING,
    EMAIL_SENT,
}

function TestUserSignUpForm() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    return (
        <div className="border border-red-500 rounded p-4 flex flex-col items-center justify-center">
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    signInWithEmailAndPassword(auth, email, password);
                }}
                className="space-y-2"
            >
                <h2 className="w-full text-left">Test user login</h2>
                <Input
                    type="email"
                    id="testLoginEmail"
                    autoComplete="email"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                />
                <Input
                    type="password"
                    id="testLoginPassword"
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                    autoComplete="current-password"
                />
                <div className="justify-end flex">
                    <Button variant="alert">Login</Button>
                </div>
            </form>
        </div>
    );
}

export function LoginModalContent() {
    const { t } = useTranslation();
    const [email, setEmail] = useState('');
    const [state, setState] = useState<LoginState>(LoginState.INITIAL);
    const location = useLocation<{ referrer?: string } | undefined>();
    const [isInvalidLink, setInvalidLink] = useState<boolean | null>(null);
    const sendEmail = useRequestSignInLink();
    const { isDesktop } = useDesktopContext();
    const isMobile = useMobile();

    useEffect(() => {
        if (isInvalidLink === null) {
            const parsed: queryString.ParsedQuery<string> = queryString.parse(location.search);
            setInvalidLink('invalid_link' in parsed);
        }
    }, [isInvalidLink, location.search]);

    const onSubmit = async (event: any) => {
        event.preventDefault();
        setState(LoginState.LOADING);

        if (IS_PRODUCTION || isDebug()) {
            const response = await fetch('https://raw.githubusercontent.com/Saga-HQ/mailchecker/main/list.txt', {
                mode: 'cors',
                credentials: 'same-origin',
                headers: { 'Content-Type': 'text/plain', Accept: 'text/plain' },
            });

            if (response.ok) {
                const data = await response.text();
                const domains = data.split('\n').filter((domain) => domain.trim() !== '');
                const missingDomainsInBlacklist = _.difference(domains, Array.from(mailChecker.blacklist()));
                // @ts-expect-error missing type declaration
                mailChecker.addCustomDomains(missingDomainsInBlacklist);
            }

            const isValidEmail = await mailChecker.isValid(email);
            if (!isValidEmail) {
                setState(LoginState.INITIAL);
                track('login-email-is-not-valid');
                return;
            }
        }

        const parsed: queryString.ParsedQuery<string> = queryString.parse(location.search);
        const loginRedirect = typeof parsed.loginRedirect === 'string' ? parsed.loginRedirect : null;

        const redirectUrl = isDesktop
            ? window.location.href + '/desktop-email'
            : loginRedirect ?? location.state?.referrer ?? window.location.href;

        await sendEmail({ email, redirectUrl: redirectUrl });
        setState(LoginState.EMAIL_SENT);
        track('login-email-sent');
    };

    const onSignInWithGoogle = () => {
        track('login-with-google', { source: 'login-page' });

        if (isDesktop && window.todesktop) {
            window.todesktop.contents.openUrlInBrowser(window.location.href + '/desktop-google');
        } else {
            signInWithGoogleProvider();
        }
    };

    return (
        <>
            {state === LoginState.EMAIL_SENT && (
                <div className="text-xl text-center space-y-5">
                    <Onboarding.Heading>{t('onboarding.magic_link_login.title')}</Onboarding.Heading>
                    <p>{t('onboarding.magic_link_login.subtitle')}</p>
                    <Onboarding.InputContainer>
                        <Input type="email" id="email" value={email} disabled={true} />
                        <Onboarding.SecondaryButton
                            onClick={() => {
                                setState(LoginState.INITIAL);
                                setInvalidLink(false);
                            }}
                        >
                            {t('common.edit')}
                        </Onboarding.SecondaryButton>
                    </Onboarding.InputContainer>
                    {!isDesktop && <p>{t('onboarding.magic_link_login.close_this_tab')}</p>}
                </div>
            )}
            {state !== LoginState.EMAIL_SENT && (
                <>
                    <Onboarding.Body>
                        <Link to="/?r=0">
                            <Onboarding.Logo />
                        </Link>
                        <Onboarding.Heading>{t('onboarding.login.title')}</Onboarding.Heading>

                        <Onboarding.GoogleButton onClick={onSignInWithGoogle}>
                            {t('onboarding.login.with_google')}
                        </Onboarding.GoogleButton>
                        <Onboarding.Paragraph>{t('common.or')}</Onboarding.Paragraph>

                        {isInvalidLink && (
                            <p className="whitespace-pre-line">{t('onboarding.login.link_has_expired')}</p>
                        )}
                        <form onSubmit={onSubmit}>
                            <Onboarding.Container>
                                <Input
                                    autoFocus={true}
                                    type="email"
                                    id="email"
                                    value={email}
                                    disabled={state === LoginState.LOADING}
                                    onChange={(event) => setEmail(event.target.value)}
                                    placeholder="Enter your email address..."
                                />
                                <Onboarding.Button
                                    disabled={
                                        state === LoginState.LOADING ||
                                        email.length === 0 ||
                                        !UrlUtils.validateEmail(email)
                                    }
                                >
                                    {t('onboarding.login.with_email')}
                                </Onboarding.Button>
                            </Onboarding.Container>
                        </form>
                        {!isMobile && !isDesktop && (
                            <>
                                <div className="py-2">
                                    <hr className="border-saga-gray-200 dark:border-zinc-600" />
                                </div>
                                <div>
                                    <ButtonNew
                                        onClick={() => window.open('https://saga.so/desktop', '_blank')}
                                        variant="secondary"
                                        className="py-2 px-6 text-base"
                                    >
                                        {t('onboarding.login.desktop_app_button')}
                                    </ButtonNew>
                                </div>
                            </>
                        )}
                        <div className="py-2">
                            <hr className="border-saga-gray-200 dark:border-zinc-600" />
                        </div>
                        <p className="text-xs text-saga-text-gray dark:text-zinc-400">
                            <Trans
                                i18nKey="onboarding.login.toc"
                                components={{
                                    1: (
                                        <a
                                            href="https://saga.so/privacy"
                                            target="_blank"
                                            className="font-bold text-saga-text-link dark:text-saga-text-link-dark"
                                            rel="noreferrer"
                                        />
                                    ),
                                    2: (
                                        <a
                                            href="https://saga.so/terms"
                                            target="_blank"
                                            className="font-bold text-saga-text-link dark:text-saga-text-link-dark"
                                            rel="noreferrer"
                                        />
                                    ),
                                }}
                            />
                        </p>
                        <p className="text-xs text-saga-text-gray dark:text-zinc-400">{t('onboarding.login.info')}</p>
                    </Onboarding.Body>
                </>
            )}
            {!IS_PRODUCTION && <TestUserSignUpForm />}
        </>
    );
}

export default function LoginModal() {
    return (
        <div className="w-full max-w-sm md:max-w-2xl">
            <Onboarding>
                <Onboarding.MainContainer>
                    <LoginModalContent />
                </Onboarding.MainContainer>
            </Onboarding>
        </div>
    );
}
