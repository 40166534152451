import { ymapFromRecord } from '../yjs-utils';
import transactInSpace from './transactInSpace';
import { SafeSpace } from '../types';

import * as Y from 'yjs';
import { v4 as uuid } from 'uuid';

export default function addTaskLabel(space: SafeSpace, title: string, color: string) {
    const id = uuid();
    transactInSpace(space, () => {
        const labels = space.map.get('taskLabels') as Y.Array<Y.Map<unknown>>;
        const label = ymapFromRecord({ title, id, color }, new Y.Map());

        if (labels) {
            labels.push([label]);
        } else {
            const newLabels = new Y.Array();
            newLabels.push([label]);
            space.map.set('taskLabels', newLabels);
        }
    });

    return id;
}
