import React, { useState, useRef, useEffect } from 'react';
import Table from '@/components/table/Table';
import { useTableContext } from '@/components/table/TableContext';
import { PopOver } from '@/components/popover/PopOver';
import { ArrowDown, ArrowUp, Trash } from 'react-feather';
import Button from '../styled/Button';
import * as Popover from '@radix-ui/react-popover';
import { useTranslation } from 'react-i18next';

type SortableTableButtonProps = {
    type: string;
    children: React.ReactNode;
    onRemoveProperty?: () => void;
};

function SortableTableButton({ type, children, onRemoveProperty }: SortableTableButtonProps) {
    const { sorting, setSorting, columns } = useTableContext();
    const keyRef = useRef<HTMLDivElement>(null);
    const [index, setIndex] = useState<number>(0);
    const [showMenu, setShowMenu] = useState<boolean>(false);
    const { t } = useTranslation();

    const order = sorting?.by === type ? sorting.order : null;

    const onSort = (order: 'asc' | 'desc') => {
        setSorting({
            by: type,
            order,
        });
    };

    const labels = columns[type];

    useEffect(() => {
        if (sorting?.by !== type) {
            setIndex(0);
            return;
        }

        switch (sorting?.order) {
            case 'asc':
                setIndex(0);
                break;
            case 'desc':
                setIndex(1);
                break;
            default:
                if (onRemoveProperty) setIndex(2);
        }
    }, [onRemoveProperty, sorting, type]);

    const submit = () => {
        if (index === 0) {
            onSort('asc');
        }
        if (index === 1) {
            onSort('desc');
        }
        if (index === 2 && onRemoveProperty) {
            onRemoveProperty();
        }

        setShowMenu(false);
    };

    const suggestionsLength = onRemoveProperty ? 3 : 2;

    return (
        <Popover.Root open={showMenu} onOpenChange={setShowMenu}>
            <Popover.Trigger asChild>
                <Table.Button
                    onClick={() => {
                        setShowMenu(true);
                    }}
                >
                    {children}
                </Table.Button>
            </Popover.Trigger>
            <Popover.Portal>
                <Popover.Content sideOffset={5} align="start">
                    <PopOver.Card>
                        <form
                            onSubmit={(e) => {
                                e.preventDefault();
                                submit();
                            }}
                        >
                            <div
                                ref={keyRef}
                                onKeyDown={(event) => {
                                    switch (event.key) {
                                        case 'ArrowDown':
                                            event.preventDefault();
                                            const prevIndex = index >= suggestionsLength ? 0 : index + 1;
                                            setIndex(prevIndex);
                                            break;
                                        case 'ArrowUp':
                                            event.preventDefault();
                                            const nextIndex = index <= 0 ? suggestionsLength : index - 1;
                                            setIndex(nextIndex);
                                            break;
                                        case 'Enter':
                                            event.preventDefault();
                                            submit();
                                            return;
                                    }
                                }}
                                tabIndex={0}
                                className="outline-none"
                            >
                                <Button.PopOverButton
                                    active={order === 'asc'}
                                    selected={index === 0}
                                    onMouseEnter={() => {
                                        setIndex(0);
                                    }}
                                    type="button"
                                    onClick={submit}
                                >
                                    <div className="flex flex-row items-center h-6">
                                        <ArrowDown className="stroke-gray-dark mr-2" size={14} />
                                    </div>
                                    <p>{t(labels.asc)}</p>
                                </Button.PopOverButton>
                                <Button.PopOverButton
                                    active={order === 'desc'}
                                    selected={index === 1}
                                    onMouseEnter={() => {
                                        setIndex(1);
                                    }}
                                    type="button"
                                    onClick={submit}
                                >
                                    <div className="flex flex-row items-center h-6">
                                        <ArrowUp className="stroke-gray-dark mr-2" size={14} />
                                        <p>{t(labels.desc)}</p>
                                    </div>
                                </Button.PopOverButton>
                                {onRemoveProperty && (
                                    <Button.PopOverButton
                                        selected={index === 2}
                                        onMouseEnter={() => {
                                            setIndex(2);
                                        }}
                                        type="button"
                                        onClick={submit}
                                    >
                                        <div className="flex flex-row items-center h-6">
                                            <Trash className="stroke-gray-dark mr-2 flex-none" size={14} />
                                            <p>Remove Column</p>
                                        </div>
                                    </Button.PopOverButton>
                                )}
                            </div>
                        </form>
                    </PopOver.Card>
                </Popover.Content>
            </Popover.Portal>
        </Popover.Root>
    );
}

export default SortableTableButton;
