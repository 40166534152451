import React, { useEffect, useState } from 'react';

const isClient = typeof window === 'object';

export default function useMobile() {
    const [width, setWidth] = useState<number>(window.innerWidth);

    useEffect(() => {
        if (!isClient) {
            return;
        }

        const onResize = () => setWidth(window.innerWidth);
        window.addEventListener('resize', onResize);
        return () => window.removeEventListener('resize', onResize);
    }, []);

    let isMobile = React.useMemo(() => width <= 768, [width]);
    return isMobile;
}
