import { PATTERNS } from './constants';

export function addHttps(url: string) {
    if (!/^(?:f|ht)tps?\:\/\//.test(url)) {
        url = 'https://' + url;
    }
    return url;
}

export function addMailTo(email: string) {
    if (!email.startsWith('mailto:')) {
        email = 'mailto:' + email;
    }
    return email;
}

export function validateEmail(email: string) {
    const re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const valid = re.test(String(email).toLowerCase());
    re.lastIndex = 0;
    return valid;
}

export const toLink = (text: string): string | undefined => {
    const match = text.match(PATTERNS.link);

    if (match == null || match[0] == null) return;

    return addHttps(match[0]);
};
