import { assertYArray } from '../yjs-utils';
import transactInSpace from './transactInSpace';
import { SafeSpace } from '../types';
import getPageMapById from './getPageMapById';
import { assertNonNull } from '../utils';
import findCollectionById from './findCollectionById';

export default function removeCollectionFromCollection(
    space: SafeSpace,
    collectionToBeRemoved: string,
    collectionId: string,
) {
    transactInSpace(space, () => {
        const collectionMap = findCollectionById(space, collectionId);
        assertNonNull(collectionMap);
        const subCollections = collectionMap.get('subCollections');
        assertYArray(subCollections);
        const index = subCollections.toArray().findIndex((c) => c === collectionToBeRemoved);
        if (index !== -1) {
            subCollections.delete(index);
        } else {
            throw new Error(
                `Collection with id ${collectionToBeRemoved} could not be removed: Collection with id ${collectionToBeRemoved} not found`,
            );
        }
    });
}
