import { assertYArray, yarrayFromArray } from '../yjs-utils';
import transactInSpace from './transactInSpace';
import { SafeSpace } from '../types';

import { assertNonNull } from '../utils';
import findTaskMap from './findTaskMap';

export default function addTaskToCollection(space: SafeSpace, taskId: string, collectionId: string) {
    transactInSpace(space, () => {
        const taskMap = findTaskMap(space, taskId);
        assertNonNull(taskMap);
        const collections = taskMap.get('collections');

        if (!collections) {
            taskMap.set('collections', yarrayFromArray([collectionId]));
        } else {
            assertYArray(collections);
            if (!collections.toArray().includes(collectionId)) {
                collections.push([collectionId]);
            }
        }
    });
}
