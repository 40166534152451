import { useFirebaseContext } from '@/components/FirebaseContext';
import { BACKEND_URL } from '@/constants';
import { AIMessage, AIPrompt, AISourceItem, SagaLocation, SpaceOperations } from '@/../../shared/src';
import { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import { useCurrentWorkspace } from '@/components/WorkspaceContext';
import { captureException } from '@sentry/react';
import { debugLog, isDebug } from '@/utils';
import { useSpace } from '@/components/SpaceProvider';
import { useCurrentSideBySideLocation } from '@/components/SideBySide';
import { useAIEnabled } from '@/components/editor/ai/AIPermissionsProvider';

export type AIStatus = 'loading' | 'error' | 'idle' | 'done';
export type AIError = 'promptLength' | 'rateLimit' | 'unknown';

const useAIAssist = (history: AIMessage[], threadId?: string) => {
    const { firebaseUser } = useFirebaseContext();
    const { aiStatus, setShowUpgradePlanModal } = useAIEnabled();
    const { urlKey, permissions } = useCurrentWorkspace();

    const [streamMessages, setStreamMessages] = useState<AIMessage[]>([]);

    const messages = useMemo(
        () => (streamMessages.length >= history.length ? streamMessages : history),
        [streamMessages, history],
    );

    const [status, setStatus] = useState<AIStatus>('idle');
    const [error, setError] = useState<AIError>();

    const stopFlag = useRef<boolean>(false);

    const { space } = useSpace();
    const location = useCurrentSideBySideLocation();
    const locationId =
        SagaLocation.isPageLocation(location) || SagaLocation.isTaskLocation(location)
            ? SagaLocation.getIdFromLocation(location)
            : null;

    const csrfToken = document.head.querySelector('meta[name="x-csrf-token"]')!.getAttribute('content')!;
    if (!csrfToken) {
        captureException('CSRF Token not found', { extra: { urlKey, user: firebaseUser?.uid } });
        debugLog('CSRF Token not found');
    }

    const handleErrorCode = useCallback((errorCode) => {
        switch (errorCode) {
            case 429:
                setError('rateLimit');
                break;
            case 413:
                setError('promptLength');
                break;
            default:
                setError('unknown');
        }

        setStatus('error');
    }, []);

    const upsertMessage = useCallback((newMessage: AIMessage) => {
        const { content, tool_calls, id, tool_call_id } = newMessage;

        if (!content && !tool_calls && !tool_call_id) {
            return;
        }
        setStreamMessages((messages) => {
            if (messages.some((message) => message.id === id)) {
                return messages.map((message) =>
                    message.id === id
                        ? {
                              ...message,
                              content: content ? (message.content + content).replace(/^\s+/g, '') : message.content,
                              tool_calls,
                              tool_call_id,
                          }
                        : message,
                );
            } else {
                return [newMessage, ...messages];
            }
        });
    }, []);

    const generateAIText = useCallback(
        async (prompt: AIPrompt, id?: string) => {
            switch (aiStatus) {
                case 'disabled':
                    setShowUpgradePlanModal(true);
                    return;
                case 'restricted':
                    return;
                default:
                    break;
            }

            if (!firebaseUser) return;
            const token = await firebaseUser.getIdToken();
            if (token == null) return;

            let sources = [...prompt.references];
            let currentDocument: AISourceItem | null = null;

            // if no explicit sources provided by user, use currently opened page/task
            if (locationId) {
                const document =
                    SpaceOperations.getPageById(space, locationId) || SpaceOperations.findTask(space, locationId);

                currentDocument = {
                    id: locationId,
                    title: document?.title ?? '',
                    type: location.type === 'page' ? 'page' : 'task',
                    updatedAt: document?.updatedAt ?? new Date().toISOString(),
                };
            }

            stopFlag.current = false;
            setStatus('loading');
            try {
                const response = await fetch(`${BACKEND_URL}openAI/suggestion`, {
                    method: 'POST',
                    headers: { authorization: token, 'Content-type': 'application/json', 'x-csrf-token': csrfToken },
                    credentials: 'include',
                    body: JSON.stringify({
                        prompt: prompt.prompt,
                        promptId: id,
                        history: [...history].reverse(),
                        threadId,
                        location: {
                            spaceUrl: urlKey,
                            text: prompt.textContext,
                            sources,
                            currentDocument,
                        },
                    }),
                });

                if (!response.ok || !response.body) {
                    handleErrorCode(response.status);
                    return;
                }
                const reader = response.body.getReader();

                const stream = new ReadableStream({
                    start(controller) {
                        setStreamMessages([]);
                        let unparsed = '';

                        function pump(): any {
                            if (stopFlag.current) {
                                setStatus('done');
                                reader.cancel();
                                controller.close();
                                stream.cancel();
                                return;
                            }

                            return reader
                                .read()
                                .then(({ value, done }: any) => {
                                    if (done) {
                                        setStatus((status) => (status === 'loading' ? 'done' : status));
                                        controller.close();
                                        return;
                                    }

                                    const dec = new TextDecoder();
                                    const data = unparsed + dec.decode(value);

                                    const chunks = data.split('\u0001');

                                    const last = chunks.pop();

                                    unparsed = last ?? '';
                                    chunks.forEach((jsonMsg) => {
                                        const message = JSON.parse(jsonMsg);

                                        if (message.errorCode) {
                                            handleErrorCode(message.errorCode);
                                        } else if (!stopFlag.current) {
                                            upsertMessage(message);
                                        }
                                    });

                                    return pump();
                                })
                                .catch((error) => {
                                    console.log(error);
                                    setStatus('error');
                                });
                        }

                        return pump();
                    },
                });
            } catch (error) {
                setStatus('error');
            }
        },
        [
            csrfToken,
            firebaseUser,
            upsertMessage,
            urlKey,
            aiStatus,
            setShowUpgradePlanModal,
            handleErrorCode,
            space,
            locationId,
            location,
            threadId,
            history,
        ],
    );

    const stop = useCallback(async () => {
        setStatus('done');
        stopFlag.current = true;
    }, []);

    useEffect(() => {
        if (status !== 'error') {
            setError(undefined);
        }
        if (status === 'idle') {
            setStreamMessages([]);
        }
        if (status === 'done') {
            setStatus('idle');
        }
    }, [status]);

    useEffect(() => {
        setStatus('idle');
    }, [threadId]);

    useEffect(() => {
        if (status === 'done' && isDebug()) {
            debugLog(`
=== model ==========\n${permissions.sagaAi.model}\n
=== assistant ======\n${messages.filter((m) => m.role === 'assistant')[0]?.content}\n
=== user ===========\n${messages.filter((m) => m.role === 'user')[0]?.content}\n
=== system =========\n${messages.filter((m) => m.role === 'system')[0]?.content}\n
====================`);
        }
    }, [messages, status, permissions.sagaAi.model]);

    return {
        generateAIText,
        messages,
        model: permissions.sagaAi.model,
        status,
        stop,
        error,
    };
};

export default useAIAssist;
