import React from 'react';

export function NotificationsIndicator({ className }: { className?: string }) {
    return (
        <svg
            className={className}
            width="17"
            height="20"
            viewBox="0 0 17 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="11" cy="6" r="5" fill="#FC632C" stroke="white" strokeWidth="2" />
        </svg>
    );
}
