import { BlockBuilder } from '../../';
import { newBlankPage } from '../../pageUtils';

export function newSagaAdvancedPageFr(collectionIds: string[]) {
    return newBlankPage({
        title: 'Saga avancé',
        icon: {
            type: 'emoji',
            colons: ':heart_eyes:',
        },
        collections: collectionIds,
        blocks: [
            BlockBuilder.title('Saga avancé'),
            BlockBuilder.callout([
                BlockBuilder.paragraph([
                    BlockBuilder.text('Explorez des fonctionnalités plus puissantes et commencez à mieux travailler avec Saga.'),
                ]),
            ]),
            BlockBuilder.headingThree('Restez organisé sans y penser'),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Créez '),
                BlockBuilder.link('https://saga.so/guides/collections-of-pages', 'des collections'),
                BlockBuilder.text(' et groupez des pages similaires.'),
            ]),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Épinglez les pages et collections les plus importantes sur la '),
                BlockBuilder.link('https://saga.so/guides/sidebar', 'barre latérale'),
                BlockBuilder.text('.'),
            ]),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Passez rapidement entre les pages, les tâches et les collections avec la '),
                BlockBuilder.link('https://saga.so/guides/search-in-saga', 'recherche unifiée'),
                BlockBuilder.text('.'),
            ]),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Cliquez sur '),
                BlockBuilder.link('https://saga.so/guides/task-management', 'Tâches'),
                BlockBuilder.text(' dans la barre latérale pour gérer toutes les tâches de toutes vos pages en un seul endroit.'),
            ]),
            BlockBuilder.paragraph(),
            BlockBuilder.headingThree('Connectez vos connaissances comme un pro'),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Utilisez le menu d\'autocomplétion pour '),
                BlockBuilder.link('https://saga.so/guides/page-links', 'mentionner des pages'),
                BlockBuilder.text(' et créer des liens dans votre espace de travail.'),
            ]),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Ouvrez les '),
                BlockBuilder.link('https://saga.so/guides/references', 'Références'),
                BlockBuilder.text(' sur le côté droit pour voir où votre page actuelle a été mentionnée.'),
            ]),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Créez des liens vers des pages '),
                BlockBuilder.link('https://saga.so/guides/automatic-linking', 'automatiquement'),
                BlockBuilder.text(' en tapant leurs titres. Par exemple, ceci est un lien vers Saga IA.'),
            ]),
            BlockBuilder.checkListItem([
                BlockBuilder.link('https://saga.so/guides/page-links', 'Aperçu'),
                BlockBuilder.text(' instantanément les pages en survolant les liens.'),
            ]),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Créez '),
                BlockBuilder.link('https://saga.so/guides/live-blocks', 'des blocs en direct'),
                BlockBuilder.text(
                    ' pour synchroniser le contenu entre les pages sans effort, en éliminant les doublons et en maintenant les informations à jour.',
                ),
            ]),
            BlockBuilder.paragraph(),
            BlockBuilder.headingThree('Devenez productif comme jamais auparavant'),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Apprenez les '),
                BlockBuilder.link('https://saga.so/guides/keyboard-shortcuts', 'raccourcis'),
                BlockBuilder.text('.'),
            ]),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Ouvrez les pages en '),
                BlockBuilder.link('https://saga.so/guides/side-by-side-view', 'vue côte à côte'),
                BlockBuilder.text(' pour travailler sur deux pages à la fois.'),
            ]),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Trouvez instantanément n\'importe quoi dans votre espace de travail avec la '),
                BlockBuilder.link('https://saga.so/guides/search-in-saga', 'recherche unifiée'),
                BlockBuilder.text('.'),
            ]),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Intégrez avec '),
                BlockBuilder.link('https://saga.so/guides/google-drive-integration', 'Google Drive'),
                BlockBuilder.text(' ou '),
                BlockBuilder.link('https://saga.so/guides/linear-integration', 'Linear'),
                BlockBuilder.text(' pour mentionner ou rechercher des fichiers et des tâches stockés à l\'intérieur.'),
            ]),
            BlockBuilder.checkListItem([
                BlockBuilder.text(
                    'Rendez-vous à ✨ Saga IA pour apprendre à utiliser l\'assistant AI puissant directement dans votre espace de travail.',
                ),
            ]),
            BlockBuilder.paragraph(),
            BlockBuilder.divider(),
            BlockBuilder.headingThree('Plus de guides et FAQ'),
            BlockBuilder.paragraph([
                BlockBuilder.text('Visitez les guides de Saga et découvrez comment tirer le meilleur parti de l\'application.'),
            ]),
            BlockBuilder.rawPrettyLink('https://saga.so/guides', {
                _tag: 'loaded',
                description: "Ce guide vous aidera à comprendre les blocs de construction de Saga et à vous mettre à niveau.",
                title: '📍 Guides de Saga',
                logo: 'https://saga.so/thumbnail.png',
            }),
            BlockBuilder.paragraph(),
        ],
    });
}
