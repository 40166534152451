import { SpaceBlocks, Page, Task } from '.';

/**
 * Creates the the initial Map for space search index.
 * @param {Y.Doc} contentDocs Page/Task contents for which to create the Map.
 * @returns {SpaceBlocks} The resulting Map
 */
export function createMapForSearchIndex(pages: (Page | Task)[]): SpaceBlocks {
    const map = new Map();

    pages.forEach((page) => {
        map.set(page.id, page.blocks);
    });

    return map;
}
