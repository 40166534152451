import React from 'react';
import Tippy, { TippyProps } from '@tippyjs/react';
import useMobile from '@/hooks/useMobile';
import { Info } from 'react-feather';

const Tooltip: React.FC<TippyProps> = React.memo(function Tooltip({ content, children, ...props }) {
    const isMobile = useMobile();

    if (isMobile) return <>{children}</>;

    return (
        <Tippy content={content} {...props} allowHTML={false} hideOnClick={true}>
            {children}
        </Tippy>
    );
});

export function InfoTooltip(props: TippyProps) {
    return (
        <Tooltip {...props}>
            <Info size={12} className="focus:outline-none cursor-pointer" />
        </Tooltip>
    );
}

export default Tooltip;
