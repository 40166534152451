import React from 'react';
import Table from '@/components/table/Table';
import { SharedPageItem, TableColumn } from '@/types';
import SortableTableButton from './SortableTableButton';
import { formatDateOptimized } from '@/utils/dateUtils';
import useMobile from '@/hooks/useMobile';
import { useTranslation } from 'react-i18next';
import { useUserContext } from '../UserContext';
import * as api from '@saga/api';
import { SortOption, fieldsToSortOptions } from '@/components/table/TableSortButton';
import MemberAvatar from '@/components/MemberAvatar';
import { stringToColor } from '../../../../shared/src/utils/Colors';
import { AVAILABLE_COLORS } from '@/constants';
import { useOpenLocation } from '../PageNavigationProvider';
import { SharedPagesTableColumnKey } from '@saga/shared';

const columns: { [key: string]: TableColumn } = {
    title: { asc: 'filters.az', desc: 'filters.za', label: 'pages.headers.title', type: 'title', value: 'title' },
    sharedBy: {
        asc: 'filters.az',
        desc: 'filters.za',
        label: 'pages.headers.shared_by',
        type: 'sharedBy',
        value: 'sharedBy',
    },
    created: {
        asc: 'pages.oldest_first',
        desc: 'pages.newest_first',
        label: 'filters.created',
        type: 'created',
        value: 'created',
    },
    role: {
        asc: 'filters.az',
        desc: 'filters.za',
        label: 'pages.headers.role',
        type: 'role',
        value: 'role',
    },
};

export const getSortOptions = (keys: SharedPagesTableColumnKey[]): SortOption[] => {
    return fieldsToSortOptions(keys, columns);
};

export const getColumns = (keys: SharedPagesTableColumnKey[]) => {
    return keys.reduce(
        (acc, key) => {
            acc[key] = columns[key];
            return acc;
        },
        {} as { [key in SharedPagesTableColumnKey]: TableColumn },
    );
};

interface TableHeaderContentProps {
    children: React.ReactNode;
}

const TableHeaderContent: React.FC<TableHeaderContentProps> = ({ children }) => {
    return (
        <div className="px-1 py-0.5 flex">
            <p className="my-auto flex-grow truncate text-saga-gray-500 text-sm font-normal">{children}</p>
        </div>
    );
};

function SharedPageRow({
    item,
    isMobile,
    language,
    index,
    columnKeys,
}: {
    item: SharedPageItem;
    isMobile: boolean;
    index: number;
    columnKeys: SharedPagesTableColumnKey[];
    language?: api.Language;
}) {
    const createdAt = formatDateOptimized(item.data.createdAt, language);
    const openLocation = useOpenLocation();

    const { t } = useTranslation();

    const onTitleClick = React.useCallback(
        (id: string, event: React.SyntheticEvent | Event) => {
            openLocation({ type: 'page', pageId: id, blockId: undefined, public: true }, event);
        },
        [openLocation],
    );

    const renderCell = React.useCallback(
        (key: SharedPagesTableColumnKey) => {
            switch (key) {
                case 'title':
                    return (
                        <Table.Cell key={key} className="relative h-10 align-top p-2 w-full min-w-56 max-w-56">
                            <div className="h-8 w-8 absolute -ml-7"></div>
                            <Table.ItemButton onClick={(event) => onTitleClick(item.id, event)} item={item} />
                        </Table.Cell>
                    );
                case 'sharedBy':
                    return (
                        <Table.Cell key={key} className="h-10 align-top min-w-40 w-40 no-wrap p-2">
                            <div className="flex flex-row">
                                <div className="flex flex-row px-1.5 py-0.5 items-center border border-saga-gray-200 dark:border-zinc-600 rounded-md space-x-1">
                                    <MemberAvatar
                                        size="md"
                                        name={item.data.sharedBy}
                                        color={stringToColor(item.data.sharedBy, AVAILABLE_COLORS)}
                                    />
                                    <p className="text-sm truncate">{item.data.sharedBy}</p>
                                </div>
                                <div className="flex-1" />
                            </div>
                        </Table.Cell>
                    );
                case 'created':
                    return (
                        <Table.Cell key={key} className="h-10 align-top min-w-40 max-w-40 no-wrap p-2">
                            <p className="text-sm">{createdAt}</p>
                        </Table.Cell>
                    );
                case 'role':
                    return (
                        <Table.Cell key={key} className="h-10 align-top min-w-40 max-w-40 no-wrap p-2">
                            <p className="text-sm">
                                {t(
                                    `settings.members.role_${item.data.role === api.PagePermission.Viewer ? 'viewer' : 'editor'}`,
                                )}
                            </p>
                        </Table.Cell>
                    );
                default:
                    return null;
            }
        },
        [createdAt, t, onTitleClick, item],
    );

    return (
        <Table.Row
            key={item.id}
            onClick={(event) => isMobile && onTitleClick(item.id, event)}
            data-testid={`shared-page-index-${index}`}
            className="divide-x divide-saga-gray-150 dark:divide-saga-gray-800 py-1"
        >
            {columnKeys.map((key) => renderCell(key))}
        </Table.Row>
    );
}

function SharedPagesTable({
    items,
    testId,
    columnKeys,
    onColumnOrderChange,
}: {
    items: SharedPageItem[];
    testId: string;
    columnKeys: SharedPagesTableColumnKey[];
    onColumnOrderChange: (columnKeys: SharedPagesTableColumnKey[]) => void;
}) {
    const { user } = useUserContext();
    const isMobile = useMobile();

    const { t } = useTranslation();

    const [orderedKeys, setOrderedKeys] = React.useState(columnKeys);

    const moveHeader = React.useCallback((dragIndex: number, hoverIndex: number) => {
        setOrderedKeys((prevKeys) => {
            const newKeys = [...prevKeys];
            const [removed] = newKeys.splice(dragIndex, 1);
            newKeys.splice(hoverIndex, 0, removed);
            return newKeys;
        });
    }, []);

    const onDrop = React.useCallback(() => {
        onColumnOrderChange(orderedKeys);
    }, [orderedKeys, onColumnOrderChange]);

    const renderHeader = React.useCallback(
        () => (
            <Table.Row>
                {orderedKeys.map((key, index) => {
                    switch (key) {
                        case 'title':
                            return (
                                <Table.Cell key={key} className="h-10 pb-3 align-middle w-full min-w-56 max-w-56">
                                    <Table.DraggableHeader
                                        id="title"
                                        index={index}
                                        moveHeader={moveHeader}
                                        onDrop={onDrop}
                                    >
                                        <SortableTableButton type="title">
                                            <TableHeaderContent>{t(columns.title.label)}</TableHeaderContent>
                                        </SortableTableButton>
                                    </Table.DraggableHeader>
                                </Table.Cell>
                            );
                        case 'sharedBy':
                            return (
                                <Table.Cell key={key} className="h-10 pb-3 align-middle min-w-40 max-w-40">
                                    <Table.DraggableHeader
                                        id="sharedBy"
                                        index={index}
                                        moveHeader={moveHeader}
                                        onDrop={onDrop}
                                    >
                                        <SortableTableButton type="sharedBy">
                                            <TableHeaderContent>{t(columns.sharedBy.label)}</TableHeaderContent>
                                        </SortableTableButton>
                                    </Table.DraggableHeader>
                                </Table.Cell>
                            );
                        case 'created':
                            return (
                                <Table.Cell key={key} className="h-10 pb-3 align-middle min-w-40 max-w-40">
                                    <Table.DraggableHeader
                                        id="created"
                                        index={index}
                                        moveHeader={moveHeader}
                                        onDrop={onDrop}
                                    >
                                        <SortableTableButton type="created">
                                            <TableHeaderContent>{t(columns.created.label)}</TableHeaderContent>
                                        </SortableTableButton>
                                    </Table.DraggableHeader>
                                </Table.Cell>
                            );
                        case 'role':
                            return (
                                <Table.Cell key={key} className="h-10 pb-3 align-middle min-w-40 max-w-40">
                                    <Table.DraggableHeader
                                        id="role"
                                        index={index}
                                        moveHeader={moveHeader}
                                        onDrop={onDrop}
                                    >
                                        <SortableTableButton type="role">
                                            <TableHeaderContent>{t(columns.role.label)}</TableHeaderContent>
                                        </SortableTableButton>
                                    </Table.DraggableHeader>
                                </Table.Cell>
                            );
                    }
                })}
            </Table.Row>
        ),
        [t, moveHeader, onDrop, orderedKeys],
    );

    const renderRow = React.useCallback(
        (index: number) => {
            return (
                items[index] && (
                    <SharedPageRow
                        item={items[index]}
                        index={index}
                        isMobile={isMobile}
                        language={user?.data.language}
                        columnKeys={orderedKeys}
                    />
                )
            );
        },
        [items, isMobile, user?.data.language, orderedKeys],
    );

    return (
        <Table.Virtualized
            data-testid={testId}
            className="table-fixed border-collapse text-left divide-y divide-saga-gray-150 dark:divide-saga-gray-800 h-full"
            itemsCount={items.length}
            renderHeader={renderHeader}
            renderItem={renderRow}
        />
    );
}

export default SharedPagesTable;
