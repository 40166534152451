import { SafeSpace } from '../types';
import { removeNullable } from '../utils';
import getPages from './getPages';
import * as R from 'ramda';

const getRecentlyUsedTemplateIds = (space: SafeSpace): string[] => {
    const sortedPagesCreatedFromTemplates = getPages(space, ['id', 'createdFromTemplate', 'createdAt'])
        .filter((page) => page.createdFromTemplate != null)
        .sort((a, b) => {
            return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
        });

    return R.uniq(sortedPagesCreatedFromTemplates.map((page) => page.createdFromTemplate).filter(removeNullable));
};
export default getRecentlyUsedTemplateIds;
