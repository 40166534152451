import React, { useCallback, useState } from 'react';
import { ReactEditor, useFocused, useSelected, useSlateStatic } from 'slate-react';
import classNames from 'classnames';
import {
    BlockType,
    SagaElement,
    isParagraph,
    isHeading,
    isVoid,
    isBlockType,
    isIndentContainer,
    isBlockContainer,
    isNumberedList,
    isNumberedListItem,
    isBlockquote,
    isTitle,
    isLiveBlockSource,
    isCheckListItem,
    isListItem,
    isCallout,
    isTable,
    isTableRow,
    EditorOperations,
    SagaEditor,
    isAISuggestedText,
} from '@saga/shared';
import { LINE_START_TRIGGER } from '../useTrigger';
import { BaseRange, Editor as SlateEditor, Range } from 'slate';
import { useTableContext } from '../plugins/Table';
import { useOpenSuggestions } from '../Suggestions';
import { useSetAIPopoverOpen } from '@/components/editor/popover/AIPopoverProvider';
import { track } from '@/analytics';
import { useTranslation } from 'react-i18next';
import { isElementInCalloutHeading } from '../SpaceEditable';

export function PlaceholderContainer({
    children,
    withPadding,
    blockType,
    isInCallout,
}: {
    children: React.ReactNode;
    withPadding?: boolean;
    blockType?: (typeof BlockType)[keyof typeof BlockType];
    isInCallout?: boolean;
}) {
    return (
        <span
            contentEditable={false}
            className={classNames(
                'select-none whitespace-nowrap pointer-events-none top-0 bottom-0 align-baseline absolute outline-none opacity-30 dark:opacity-40',
                {
                    'py-1.5': withPadding && blockType === BlockType.PARAGRAPH,
                    'py-1': withPadding && blockType !== BlockType.PARAGRAPH,
                    'pt-9': withPadding && blockType === BlockType.HEADING_1 && !isInCallout,
                    'pt-6': withPadding && blockType === BlockType.HEADING_2 && !isInCallout,
                    'pt-5': withPadding && blockType === BlockType.HEADING_3 && !isInCallout,
                    'pt-1.5':
                        withPadding &&
                        (blockType === BlockType.HEADING_1 ||
                            blockType === BlockType.HEADING_2 ||
                            blockType === BlockType.HEADING_3) &&
                        isInCallout,
                },
            )}
        >
            {children}
        </span>
    );
}

function Placeholder({ element }: { element: SagaElement }) {
    const editor = useSlateStatic();
    const { blockPlugins, yBlocks } = SagaEditor.useEditorContext();
    const isExpanded = editor.selection && Range.isExpanded(editor.selection);
    const isSelected = useSelected();
    const isFocused = useFocused();
    const { openAIPopover } = useSetAIPopoverOpen();
    const { t } = useTranslation();
    const path = ReactEditor.findPath(editor, element);

    const isHeadingElementInCallout = isElementInCalloutHeading(editor, path);

    const shouldShowEmptyPagePlaceholder = useCallback(
        (blocks, element) => {
            return blocks ? ReactEditor.findPath(editor, element)?.[0] === 1 && blocks.length < 3 : false;
        },
        [editor],
    );

    const [showPlaceholder, setShowPlaceholder] = useState(shouldShowEmptyPagePlaceholder(yBlocks, element));

    const isEmpty = React.useMemo(
        () =>
            EditorOperations.SagaElement.toString(element, blockPlugins) === '' &&
            element.children.every((child) => !isVoid(child)),
        [element, blockPlugins],
    );

    const openSuggestions = useOpenSuggestions();
    const tableContext = useTableContext();

    const suggestionButton = (title: string, action: (selection: BaseRange) => void) => (
        <button
            className="underline cursor-pointer pointer-events-auto hover:text-black hover:dark:text-saga-gray-500"
            onMouseDown={(e) => e.preventDefault()}
            onClick={(e) => {
                if (e.button === 0) {
                    const path = ReactEditor.findPath(editor, element);
                    if (path) {
                        ReactEditor.focus(editor);
                        EditorOperations.Selection.safeSelectByLocation(editor, path);
                        e.stopPropagation();

                        if (editor.selection) {
                            action(editor.selection);
                        }
                    }
                }
            }}
        >
            {title}
        </button>
    );

    React.useEffect(() => {
        if (!yBlocks) return;
        function observe() {
            setShowPlaceholder(shouldShowEmptyPagePlaceholder(yBlocks, element));
        }
        yBlocks.observeDeep(observe);
        return () => yBlocks.unobserveDeep(observe);
    }, [yBlocks, editor, shouldShowEmptyPagePlaceholder, element]);

    if (
        isVoid(element) ||
        isBlockType(element, [
            isTitle,
            isIndentContainer,
            isBlockContainer,
            isNumberedList,
            isLiveBlockSource,
            isCallout,
            isTable,
            isTableRow,
            isAISuggestedText,
        ])
    ) {
        return null;
    }

    if (!isSelected && isCheckListItem(element) && isEmpty) {
        return (
            <PlaceholderContainer>
                <span>{t('editor.placeholder.task')}</span>
            </PlaceholderContainer>
        );
    }

    if (!isSelected && isBlockType(element, [isListItem, isNumberedListItem]) && isEmpty) {
        return (
            <PlaceholderContainer withPadding={isNumberedListItem(element)}>
                <span>{t('editor.placeholder.list')}</span>
            </PlaceholderContainer>
        );
    }

    if (!isSelected && isBlockquote(element) && isEmpty) {
        return (
            <PlaceholderContainer withPadding>
                <span>{t('editor.placeholder.quote')}</span>
            </PlaceholderContainer>
        );
    }

    if (isEmpty && isHeading(element)) {
        const type = element.type;
        const isInCallout = isHeadingElementInCallout ?? false;

        return (
            <PlaceholderContainer withPadding={true} blockType={type} isInCallout={isInCallout}>
                <span
                    className={classNames({
                        'sm:text-4xl text-[28px] font-bold': type === BlockType.HEADING_1,
                        'text-2xl font-bold': type === BlockType.HEADING_2,
                        'text-xl font-bold': type === BlockType.HEADING_3,
                    })}
                >
                    {type === 'heading-one' && <span>{t('editor.placeholder.h1')}</span>}
                    {type === 'heading-two' && <span>{t('editor.placeholder.h2')}</span>}
                    {type === 'heading-three' && <span>{t('editor.placeholder.h3')}</span>}
                </span>
            </PlaceholderContainer>
        );
    }

    if (showPlaceholder || (isSelected && isFocused && isEmpty && !isExpanded)) {
        if (isParagraph(element)) {
            if (tableContext) {
                return (
                    <PlaceholderContainer withPadding blockType={BlockType.PARAGRAPH}>
                        <span style={{ marginTop: '0.03rem' }}>
                            <span>{t('editor.placeholder.enter_text')}</span>
                        </span>
                    </PlaceholderContainer>
                );
            }

            return (
                <PlaceholderContainer withPadding blockType={BlockType.PARAGRAPH}>
                    <span style={{ marginTop: '0.03rem' }}>
                        <span>{` ${t('common.popover_placeholder')} `}</span>
                        {suggestionButton(` ${t('common.ai')} `, (selection) => {
                            track('open-ai-popover', { source: 'empty-line-placeholder' });
                            openAIPopover(selection);
                        })}
                        <span> {` ${t('common.and')} `}</span>
                        {suggestionButton(` ${t('common.commands')} `, (selection) => {
                            openSuggestions({
                                range: selection,
                                trigger: LINE_START_TRIGGER,
                                rangeRef: SlateEditor.rangeRef(editor, selection, {
                                    affinity: 'inward',
                                }),
                            });
                        })}
                    </span>
                </PlaceholderContainer>
            );
        }

        if (isBlockType(element, [isNumberedListItem, isBlockquote])) {
            return (
                <PlaceholderContainer withPadding>
                    <span>{t('editor.placeholder.enter_text_dotted')}</span>
                </PlaceholderContainer>
            );
        }

        return (
            <PlaceholderContainer>
                <span>{t('editor.placeholder.enter_text_dotted')}</span>
            </PlaceholderContainer>
        );
    }

    return null;
}

export default Placeholder;
