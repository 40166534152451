import * as api from '@saga/api';
import React from 'react';
import Input from '../styled/Input';
import { RoleSelect } from '../RoleSelect';

type MemberInvite = {
    email: string;
    role: api.Role;
    isPaidFeature: boolean;
};

export default function InviteMemberInput({
    canInviteAdmins,
    onChange,
    value,
    inputProps,
}: {
    canInviteAdmins: boolean;
    onChange: (memberInvite: MemberInvite) => void;
    value: MemberInvite;
    inputProps?: Omit<React.InputHTMLAttributes<HTMLInputElement>, 'className' | 'onChange' | 'value'>;
}) {
    return (
        <Input.WithAddition
            {...inputProps}
            value={value.email}
            onChange={(e) => onChange({ ...value, email: e.target.value })}
            addition={
                <RoleSelect
                    canInviteAdmins={canInviteAdmins}
                    role={value.role}
                    onChange={(role) => onChange({ ...value, role })}
                    isPaidFeature={value.isPaidFeature}
                />
            }
        />
    );
}
