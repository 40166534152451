import { CreatePageSuggestion, PageSuggestion } from '@/types';
import React from 'react';
import { filterSuggestions } from './Dropdown';
import PagesCollectionsPopOver, {
    Props as PagesCollectionsPopOverProps,
    appendCreatePageSuggestion,
} from './PagesCollectionsPopOver';
import Tooltip from './Tooltip';
import { useTranslation } from 'react-i18next';

type Props = {
    popoverProps: Omit<
        PagesCollectionsPopOverProps<PageSuggestion, PageSuggestion | CreatePageSuggestion>,
        'filterBySearch' | 'inputProps' | 'attachToRef' | 'label'
    >;
    zIndex?: number;
    onOpen: React.MouseEventHandler<HTMLButtonElement>;
    attachToRef: React.MutableRefObject<HTMLButtonElement | null>;
};

const MoveToPageButton: React.FC<Props> = ({ popoverProps, zIndex, onOpen, attachToRef, children }) => {
    const { dropdownProps, ...rest } = popoverProps;
    const { t } = useTranslation();

    return (
        <>
            <Tooltip
                content={
                    <div>
                        <div className="font-semibold">Move Selection</div>
                        <div>Move selection to another page.</div>
                    </div>
                }
                placement="top"
            >
                <button ref={attachToRef} className="unselectable" onMouseDown={onOpen}>
                    {children}
                </button>
            </Tooltip>
            {popoverProps.isOpen && (
                <PagesCollectionsPopOver
                    attachToRef={attachToRef}
                    filterBySearch={(currentSuggestions, search) => {
                        return appendCreatePageSuggestion(filterSuggestions(currentSuggestions, search), search);
                    }}
                    inputProps={{
                        placeholder: 'Type a destination Page...',
                        title: 'Type a destination Page...',
                    }}
                    dropdownProps={{
                        align: 'left',
                        position: 'below',
                        testId: 'send-text-to-page',
                        zIndex: zIndex ? zIndex + 10 : undefined,
                        maxWHighlight: true,
                        ...dropdownProps,
                    }}
                    {...rest}
                    label="Select or create new"
                />
            )}
        </>
    );
};

export default MoveToPageButton;
