import React from 'react';
import FlexSearch from 'flexsearch';
import * as R from 'ramda';
import { useSpace } from '@/components/SpaceProvider';
import * as Y from 'yjs';
import {
    BlockType,
    EditorOperations,
    removeNullable,
    SagaElement,
    SpaceOperations,
    WeakPage,
    Search,
    SagaLocation,
    IndexedItem,
    AutolinkResult,
    isBlockTypeCurried,
    isHeading,
    isBlockContainer,
} from '@saga/shared';
import useYPagesEvents from '@/hooks/useYPagesEvents';
import { BinarySearchTree } from '@/lib/BinarySearchTree';
import { useBlockPlugins } from '@/components/BlockPluginProvider';
import useYDocumentContentEvents from '@/hooks/useYDocumentContentEvents';
import { usePagesPermissions } from '@/components/PagesPermissionsBySpaceProvider';
import { useAutolinkEnabled } from '@/hooks/SpaceHooks';

const { findTermMatches, makeTerms } = Search;

type IndexObserver = () => void;

export type AutolinkIndexDocument = FlexSearch.Document<IndexedItem, true> & {
    observe(observer: IndexObserver): () => void;
    callObservers(): void;
    store: Record<string, IndexedItem>;
};

const AutolinkIndexContext = React.createContext<AutolinkIndexDocument | null>(null);

function searchForWord(index: AutolinkIndexDocument, word: string) {
    const normalizedWord = word.replace("'s", '');
    return index.search(normalizedWord, undefined, {
        enrich: true,
        index: ['names'],
    });
}

function findCandidateItemsForText(index: AutolinkIndexDocument, text: string): Set<IndexedItem> {
    const words = R.uniq(text.split(/\s/));

    const items = new Set<IndexedItem>();

    words.forEach((word) => {
        const [result] = searchForWord(index, word);

        if (result == null) return;
        result.result.forEach((innerResult) => {
            items.add(innerResult.doc);
        });
    });

    return items;
}

type ItemMatch = { match: Search.TermMatch; item: IndexedItem };

function matchCandidateItemsWithText(text: string, candidateItems: Set<IndexedItem>): ItemMatch[] {
    return Array.from(candidateItems.values())
        .map((item) => {
            const terms = makeTerms(item.names);

            if (terms) {
                return findTermMatches(text, terms).map((match) => {
                    return {
                        match,
                        item,
                    };
                });
            }

            return [];
        })
        .flat();
}

function sortMatchByLongest(a: ItemMatch, b: ItemMatch) {
    return b.match.matched.length - a.match.matched.length;
}

function itemMatchesToAutolinkResults(itemMatches: ItemMatch[]): AutolinkResult[] {
    const map = new Map<string, ItemMatch[]>();
    itemMatches.forEach((itemMatch) => {
        const id = itemMatch.item.id;
        if (map.has(id)) {
            map.get(id)?.push(itemMatch);
        } else {
            map.set(id, [itemMatch]);
        }
    });

    const results: AutolinkResult[] = [...map.values()]
        .map((value) => {
            if (value.length === 0) {
                return null;
            }
            const [first] = value;
            return {
                item: first.item,
                id: first.item.id,
                matches: value.map(({ match }) => match),
            };
        })
        .filter(removeNullable);

    return results;
}

function removeOverlappingItemMatches(itemMatches: ItemMatch[]): ItemMatch[] {
    // We create a BinarySearchTree that helps us with deciding if two matches overlap.
    const bst = new BinarySearchTree<ItemMatch>();

    // we need to sort them by the longest match in order to build
    // the search tree in a way where we don't need to delete nodes
    const sorted = itemMatches.sort(sortMatchByLongest);

    sorted.forEach((itemMatch) => {
        bst.add(itemMatch.match.offset.start, itemMatch, (node) => {
            // new match is before node, we can add the node
            if (itemMatch.match.offset.end <= node.payload.match.offset.start) {
                return true;
            }

            // new match is after node, we can add the node
            if (itemMatch.match.offset.start >= node.payload.match.offset.end) {
                return true;
            }

            // in any other case, the node item match overlaps with the node
            return false;
        });
    });

    return bst.toArray();
}

export const findInIndex = (index: AutolinkIndexDocument, text: string): AutolinkResult[] => {
    // find all matched items for text in index
    const candidateItems = findCandidateItemsForText(index, text);

    // This gives us the actual matches based on the candidates and the text
    const itemMatches = matchCandidateItemsWithText(text, candidateItems);

    // This removes overlapping matches
    const nonOverlappingItemMatches = removeOverlappingItemMatches(itemMatches);

    // finally we turn our itemMatches into a list of autolink results
    const results: AutolinkResult[] = itemMatchesToAutolinkResults(nonOverlappingItemMatches);

    // In the end, we just make sure that our results are unique based on type and id
    return R.uniqBy((r) => `${r.id}:${r.item.type}`, results);
};

export const useAutolinkIndex = () => {
    const index = React.useContext(AutolinkIndexContext);
    const [autolinkEnabled] = useAutolinkEnabled();

    if (index == null) {
        throw new Error('useAutolinkIndex needs to be used inside the AutolinkIndexContext');
    }

    const find = React.useCallback(
        (text: string) => {
            return autolinkEnabled ? findInIndex(index, text) : [];
        },
        [index, autolinkEnabled],
    );

    return { find, index };
};

export function createIndex() {
    let observers: IndexObserver[] = [];

    const index = new FlexSearch.Document<IndexedItem, true>({
        document: {
            id: 'id',
            index: ['names'],
            store: true,
        },
        charset: 'latin:simple',
    }) as AutolinkIndexDocument;

    index.observe = (observer: IndexObserver) => {
        observers.push(observer);
        return () => {
            observers = observers.filter((o) => o !== observer);
        };
    };

    index.callObservers = () => observers.forEach((cb) => cb());

    return index;
}

export function SpaceAutolinkIndex({ children }: { children: React.ReactNode }) {
    const { space, yDoc, searchMap } = useSpace();
    const blockPlugins = useBlockPlugins();
    const { hasAccess } = usePagesPermissions();

    const index = React.useMemo(() => {
        space;
        return createIndex();
    }, [space]);

    const syncHeadingsInIndex = React.useCallback(
        (pageId: string) => {
            setTimeout(() => {
                const location = SagaLocation.pageLocationFromId(pageId);
                const page = SpaceOperations.getPageById(space, pageId, ['settings', 'isTemplate']);
                const blocks = (searchMap?.get(pageId) as Y.Array<any>)?.toJSON();

                if (page?.isTemplate) {
                    return;
                }

                if (page?.settings.linkHeadings && blocks) {
                    const headings =
                        blocks.filter(isBlockTypeCurried([isHeading, isBlockContainer])).map((block) => {
                            if (isHeading(block)) {
                                return {
                                    id: block.id,
                                    title: EditorOperations.SagaElement.toString(block, blockPlugins),
                                };
                            }
                            const child = block.children[0];
                            return {
                                id: child.id,
                                title: EditorOperations.SagaElement.toString(child, blockPlugins),
                            };
                        }) ?? [];

                    headings.forEach((heading) => {
                        index.add({
                            id: `heading:${pageId}:${heading.id}`,
                            names: [heading.title],
                            type: 'heading',
                            location,
                            heading,
                        });
                    });
                } else {
                    const keysToRemove: string[] = [];
                    Object.keys(index.store).forEach((key) => {
                        if (key.startsWith(`heading:${pageId}`)) {
                            keysToRemove.push(key);
                        }
                    });

                    keysToRemove.forEach((key) => {
                        index.remove(key);
                    });
                }
            });
        },
        [space, index, searchMap, blockPlugins],
    );

    const updatePageInIndex = React.useCallback(
        (page: Pick<WeakPage, 'aliases' | 'title' | 'id' | 'settings' | 'isTemplate'>) => {
            if (page.isTemplate) return;

            const id = `page:${page.id}`;

            if (page.settings.linkTitle) {
                index.add({
                    id,
                    names: [page.title, ...page.aliases],
                    type: 'page',
                    location: SagaLocation.pageLocationFromId(page.id),
                });
                syncHeadingsInIndex(page.id);
            } else {
                index.remove(id);
            }
        },
        [index, syncHeadingsInIndex],
    );

    React.useEffect(() => {
        const ypages = space.map.get('pages') as Y.Array<unknown>;

        setTimeout(() => {
            const pages = SpaceOperations.getPages(
                space,
                ['id', 'title', 'archivedAt', 'aliases', 'settings', 'isTemplate'],
                'non-deleted',
                hasAccess,
            );

            pages.forEach((page) => {
                updatePageInIndex(page);
            });
            index.callObservers();
        });

        function observePages() {
            const pages = SpaceOperations.getPages(
                space,
                ['id', 'title', 'aliases', 'settings', 'isTemplate'],
                'non-deleted',
                hasAccess,
            );

            pages.forEach((page) => {
                updatePageInIndex(page);
            });

            index.callObservers();
        }

        ypages.observe(observePages);

        // With this we just want to make sure that any page where the title gets updated
        // in the background will be updated also in the index. this is useful if someone
        // creates a new page, changes the title and we expect the title to immediately be
        // autolinked for all collaboration users
        function observePagesDeep(events: Y.YEvent<any>[]) {
            events.forEach((event) => {
                const path = event.path;
                if (
                    event instanceof Y.YMapEvent &&
                    event.keysChanged.has('title') &&
                    path.length === 1 &&
                    typeof path[0] === 'number'
                ) {
                    const page = SpaceOperations.getPageByIndex(space, path[0], [
                        'id',
                        'title',
                        'aliases',
                        'settings',
                        'isTemplate',
                    ]);
                    if (page) {
                        updatePageInIndex(page);
                    }
                }
            });
        }

        ypages.observeDeep(observePagesDeep);

        return () => {
            ypages.unobserve(observePages);
            ypages.unobserveDeep(observePagesDeep);
        };
    }, [space, yDoc, index, updatePageInIndex, hasAccess]);

    const onYBlockUpdate = (yBlock: Y.Map<unknown>, yPage: Y.Map<unknown>, pageId: string) => {
        const type = yBlock.get('type');
        const id = yBlock.get('id');

        if (
            typeof id === 'string' &&
            [BlockType.HEADING_1, BlockType.HEADING_2, BlockType.HEADING_3].includes(type as any)
        ) {
            const ySettings = yPage.get('settings');

            if (ySettings instanceof Y.Map && ySettings.get('linkHeadings')) {
                const title = EditorOperations.SagaElement.toString(yBlock.toJSON() as SagaElement, blockPlugins);
                const location = SagaLocation.pageLocationFromId(pageId);
                index.add({
                    id: `heading:${pageId}:${id}`,
                    names: [title],
                    type: 'heading',
                    location,
                    heading: {
                        id,
                        title,
                    },
                });
                index.callObservers();
            }
        }
    };

    useYDocumentContentEvents({
        onTopLevelBlockChanged: onYBlockUpdate,
        onTopLevelBlockAdded: onYBlockUpdate,
        onTopLevelBlockDeleted(yBlock, _, pageId) {
            const typeItem = yBlock._map.get('type');
            if (typeItem) {
                const [type] = typeItem.content.getContent();

                const idItem = yBlock._map.get('id');

                if ([BlockType.HEADING_1, BlockType.HEADING_2, BlockType.HEADING_3].includes(type) && idItem) {
                    const [id] = idItem.content.getContent();
                    index.remove(`heading:${pageId}:${id}`);
                    index.callObservers();
                }
            }
        },
    });

    useYPagesEvents({
        onPageChanged(event, yPage) {
            if (event.target === yPage && event instanceof Y.YMapEvent) {
                const pageId = yPage.get('id');
                if (event.keysChanged.has('archivedAt') && typeof pageId === 'string') {
                    const page = SpaceOperations.getPageById(space, pageId, [
                        'id',
                        'title',
                        'archivedAt',
                        'aliases',
                        'settings',
                        'isTemplate',
                    ]);
                    if (page) {
                        if (page.archivedAt == null) {
                            updatePageInIndex(page);
                        } else {
                            index.remove(`page:${page.id}`);
                        }

                        index.callObservers();
                    }
                }

                if (
                    (event.keysChanged.has('aliases') || event.keysChanged.has('settings')) &&
                    typeof pageId === 'string'
                ) {
                    const page = SpaceOperations.getPageById(space, pageId, [
                        'id',
                        'title',
                        'aliases',
                        'settings',
                        'isTemplate',
                    ]);

                    if (page) {
                        updatePageInIndex(page);
                        index.callObservers();
                    }
                }
            }
        },
    });

    return <AutolinkIndexContext.Provider value={index}>{children}</AutolinkIndexContext.Provider>;
}
