export type SanitizerFn = (xmlDoc: Document) => XMLDocument;
export type Sanitizer = { name: string; fn: SanitizerFn };

const sanitizers: Sanitizer[] = [
    {
        name: 'wrapTextNodes',
        fn: (xmlDoc: Document) => {
            const textElements = Array.from(xmlDoc.getElementsByTagName('text')).filter(
                (e) => e.parentNode?.nodeName !== 'paragraph',
            );

            textElements.forEach((textElement) => {
                const parentNode = textElement.parentNode;
                const paragraphNode = xmlDoc.createElement('paragraph');
                const next = textElement.nextSibling;

                parentNode?.removeChild(textElement);
                paragraphNode.appendChild(textElement);

                parentNode?.insertBefore(paragraphNode, next);
            });

            return xmlDoc;
        },
    },
    {
        name: 'fixCodeNodes',
        fn: (xmlDoc: Document) => {
            const codeElements = Array.from(xmlDoc.getElementsByTagName('code')).filter(
                (e) => !e.getAttribute('content'),
            );

            codeElements.forEach((codeElement) => {
                const codeTexts = Array.from(codeElement.getElementsByTagName('text'));
                codeElement.setAttribute('content', codeTexts.map((textEl) => textEl.textContent).join('\n'));
            });

            return xmlDoc;
        },
    },
];

export const createXML = (xmlString: string) => {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(
        xmlString.startsWith('<root>') ? xmlString : `<root>${xmlString}`,
        'text/xml',
    );

    const errorNode = xmlDoc.querySelector('parsererror');

    if (
        errorNode &&
        !(errorNode.textContent?.includes('Premature end') || errorNode.textContent?.includes('Extra content'))
    ) {
        /* If error is 'premature end' or 'extra content' that is ok since we are still streaming
        and having a partial XML.Errors text differ for each browser.
        If it stops returning the text in chunks check the error message for the browser.
        Otherwise return */

        return null;
    }

    return sanitizers.reduce((sanitizedDoc: Document, sanitizer) => sanitizer.fn(sanitizedDoc), xmlDoc);
};
