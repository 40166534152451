import { Space, WeakBlock, WeakPage, WeakTask } from '../types';
import * as SagaLocation from '../SagaLocation';
import { queryBlocks, Query, QueryResult } from '../Search';

type FindInPageLocationsResult = {
    type: 'page';
    result: QueryResult;
    location: SagaLocation.PageLocation;
    blocks: WeakBlock[];
    page: WeakPage;
    updatedAt: string;
};

type FindInTaskLocationsResult = {
    type: 'task';
    result: QueryResult;
    location: SagaLocation.TaskLocation;
    blocks: WeakBlock[];
    task: WeakTask;
    updatedAt: string;
};

export type FindInLocationsResult = FindInTaskLocationsResult | FindInPageLocationsResult;

export default function findInLocations(
    space: Space,
    spaceBlocks: { [index: string]: WeakBlock[] } | undefined,
    queries: Query[],
): FindInLocationsResult[] {
    let results: FindInLocationsResult[] = [];
    Object.entries(spaceBlocks ?? []).forEach(([pageId, blocks]) => {
        const page = space.pages.find((page) => page.id === pageId);
        const task = space.tasks.find((task) => task.id === pageId);

        if (page) {
            const pageId = page.id;
            const updatedAt = page.updatedAt;

            const q = queries
                .map((query) => {
                    const results = queryBlocks(query, [], blocks);
                    return results.map((result) => ({ result, blocks }));
                })
                .flat()
                .map(({ result, blocks }) => ({
                    type: 'page' as const,
                    result,
                    blocks,
                    page,
                    location: { ...SagaLocation.pageLocationFromId(pageId, { blockId: result.blockId }) },
                    updatedAt,
                }));

            results.push(...q);
        }

        if (task) {
            const taskId = task.id;
            const updatedAt = task.updatedAt;

            const q = queries
                .map((query) => {
                    const results = queryBlocks(query, [], blocks);
                    return results.map((result) => ({ result, blocks }));
                })
                .flat()
                .map(({ result, blocks }) => ({
                    type: 'task' as const,
                    result,
                    blocks,
                    task,
                    location: { ...SagaLocation.taskLocationFromId(taskId, { blockId: result.blockId }) },
                    updatedAt,
                }));

            results.push(...q);
        }
    });

    return results;
}
