import { track } from '@/analytics';
import { getCurrentUser } from '@/firebase';
import { Page } from '@saga/shared';
import _ from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import ChooseWorkspaceModal from '../ChooseWorkspaceModal';
import { useWorkspaces } from '../WorkspacesContext';
import Button from '../styled/Button';

const BLOCKS_CANT_BE_DUPLICATED = ['reference', 'live-reference-source', 'task-block'];

export default function DuplicatePageButton({
    currentPage,
    blocks,
}: Readonly<{
    currentPage: Pick<Page, 'id' | 'title' | 'icon' | 'isPublicDuplicatable'>;
    blocks: { [key: string]: any }[];
}>) {
    const { t } = useTranslation();
    const history = useHistory();
    const { workspaces } = useWorkspaces();
    const [isWorkspacesModalOpen, setIsWorkspacesModalOpen] = useState(false);
    const currentUser = getCurrentUser();

    function onDuplicatePage() {
        if (!currentPage.isPublicDuplicatable) return;
        localStorage.setItem(
            'duplicatePageData',
            JSON.stringify({
                title: currentPage.title,
                icon: currentPage.icon,
                blocks: _.cloneDeep(blocks).filter((block) => !BLOCKS_CANT_BE_DUPLICATED.includes(block.type)),
            }),
        );
    }

    function onButtonClick() {
        track('duplicate-public-page');
        if (currentUser === null) {
            onDuplicatePage();
            history.push('/login');
            return;
        }

        if (workspaces.length > 1) {
            setIsWorkspacesModalOpen(true);
        } else {
            onDuplicatePage();
            history.push(`/s/${workspaces[0].urlKey}/duplicatePage`);
        }
    }

    return (
        <>
            <Button.Plain onClick={onButtonClick}>
                <Button.BasePadding>
                    <span className="font-medium whitespace-nowrap">{t('common.copy_page')}</span>
                </Button.BasePadding>
            </Button.Plain>

            {isWorkspacesModalOpen && (
                <ChooseWorkspaceModal
                    title={t('top_menu.share.copying_page_title')}
                    subTitle={t('top_menu.share.copying_page_sub_title')}
                    onClose={() => setIsWorkspacesModalOpen(!setIsWorkspacesModalOpen)}
                    onSelect={(urlKey) => {
                        onDuplicatePage();
                        localStorage.setItem('duplicateOnSpace', urlKey);
                        history.push(`/s/${urlKey}/duplicatePage`);
                    }}
                />
            )}
        </>
    );
}
