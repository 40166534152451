import React from 'react';
import * as Y from 'yjs';
import { Title } from '@saga/shared';

const useOnTitleChanged = (yBlocks: Y.Array<any> | null, onChange: (title: Title) => void) => {
    const handler = React.useRef({ onChange });
    handler.current = { onChange };
    React.useEffect(() => {
        function observeDeep(events: Y.YEvent<any>[]) {
            events.forEach((event) => {
                let blockIndex = event.path[0];

                if (!event.changes.delta.length && !event.changes.keys.size) return;

                // This is the case where title is being pasted, instead of typed
                if (
                    event instanceof Y.YArrayEvent &&
                    event.changes.delta.length === 2 &&
                    event.changes.deleted.size &&
                    event.changes.added.size
                ) {
                    blockIndex = 0;
                }

                // This is the case where title is sent to first row by pressing enter
                if (blockIndex === 1 && event.keys.has('type')) {
                    blockIndex = 0;
                }

                // We are dealing with the title in case the change happened in the first block
                if (blockIndex === 0) {
                    const firstYBlock = yBlocks?.get(blockIndex);
                    if (firstYBlock instanceof Y.Map) {
                        // TODO: Decode this
                        const titleBlock = firstYBlock.toJSON() as Title;
                        handler.current.onChange(titleBlock);
                    }
                }
            });
        }
        yBlocks?.observeDeep(observeDeep);

        return () => {
            yBlocks?.unobserveDeep(observeDeep);
        };
    }, [yBlocks]);
};

export default useOnTitleChanged;
