import { BlockBuilder } from '../../';
import { newBlankPage } from '../../pageUtils';

export function newSagaAdvancedPagePt(collectionIds: string[]) {
    return newBlankPage({
        title: 'Saga avançado',
        icon: {
            type: 'emoji',
            colons: ':heart_eyes:',
        },
        collections: collectionIds,
        blocks: [
            BlockBuilder.title('Saga avançado'),
            BlockBuilder.callout([
                BlockBuilder.paragraph([
                    BlockBuilder.text('Explore recursos mais poderosos e comece a trabalhar melhor com o Saga.'),
                ]),
            ]),
            BlockBuilder.headingThree('Mantenha-se organizado sem precisar pensar nisso'),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Crie '),
                BlockBuilder.link('https://saga.so/guides/collections-of-pages', 'coleções'),
                BlockBuilder.text(' e agrupe páginas semelhantes.'),
            ]),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Fixe as páginas e coleções mais importantes na '),
                BlockBuilder.link('https://saga.so/guides/sidebar', 'barra lateral'),
                BlockBuilder.text('.'),
            ]),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Troque rapidamente entre páginas, tarefas e coleções com a '),
                BlockBuilder.link('https://saga.so/guides/search-in-saga', 'busca unificada'),
                BlockBuilder.text('.'),
            ]),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Clique em '),
                BlockBuilder.link('https://saga.so/guides/task-management', 'Tarefas'),
                BlockBuilder.text(' na barra lateral para gerenciar todas as tarefas de todas as suas páginas em um só lugar.'),
            ]),
            BlockBuilder.paragraph(),
            BlockBuilder.headingThree('Conecte seu conhecimento como um profissional'),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Use o menu de autocompletar para '),
                BlockBuilder.link('https://saga.so/guides/page-links', 'mencionar páginas'),
                BlockBuilder.text(' e criar links dentro do seu espaço de trabalho.'),
            ]),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Abra as '),
                BlockBuilder.link('https://saga.so/guides/references', 'Referências'),
                BlockBuilder.text(' ao lado direito para ver onde sua página atual foi mencionada.'),
            ]),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Crie links para páginas '),
                BlockBuilder.link('https://saga.so/guides/automatic-linking', 'automaticamente'),
                BlockBuilder.text(' digitando seus títulos. Por exemplo, este é um link para o Saga IA.'),
            ]),
            BlockBuilder.checkListItem([
                BlockBuilder.link('https://saga.so/guides/page-links', 'Visualize'),
                BlockBuilder.text(' páginas instantaneamente passando o mouse sobre os links.'),
            ]),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Crie '),
                BlockBuilder.link('https://saga.so/guides/live-blocks', 'Blocos ao Vivo'),
                BlockBuilder.text(
                    ' para sincronizar conteúdo entre páginas sem esforço, eliminando duplicatas e mantendo as informações atualizadas.',
                ),
            ]),
            BlockBuilder.paragraph(),
            BlockBuilder.headingThree('Torne-se produtivo como nunca antes'),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Aprenda os '),
                BlockBuilder.link('https://saga.so/guides/keyboard-shortcuts', 'atalhos'),
                BlockBuilder.text('.'),
            ]),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Abra páginas em '),
                BlockBuilder.link('https://saga.so/guides/side-by-side-view', 'vista lado a lado'),
                BlockBuilder.text(' para trabalhar em duas páginas ao mesmo tempo.'),
            ]),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Encontre qualquer coisa instantaneamente em todo o seu espaço de trabalho com a '),
                BlockBuilder.link('https://saga.so/guides/search-in-saga', 'busca unificada'),
                BlockBuilder.text('.'),
            ]),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Integre-se com '),
                BlockBuilder.link('https://saga.so/guides/google-drive-integration', 'Google Drive'),
                BlockBuilder.text(' ou '),
                BlockBuilder.link('https://saga.so/guides/linear-integration', 'Linear'),
                BlockBuilder.text(' para mencionar ou buscar arquivos e tarefas armazenados dentro deles.'),
            ]),
            BlockBuilder.checkListItem([
                BlockBuilder.text(
                    'Vá para ✨ Saga IA para aprender como usar o poderoso assistente de IA diretamente no seu espaço de trabalho.',
                ),
            ]),
            BlockBuilder.paragraph(),
            BlockBuilder.divider(),
            BlockBuilder.headingThree('Mais guias e FAQs'),
            BlockBuilder.paragraph([
                BlockBuilder.text('Visite os Guias do Saga e veja como aproveitar ao máximo o aplicativo.'),
            ]),
            BlockBuilder.rawPrettyLink('https://saga.so/guides', {
                _tag: 'loaded',
                description: "Este guia ajudará você a entender os blocos de construção do Saga e a se atualizar.",
                title: '📍 Guias do Saga',
                logo: 'https://saga.so/thumbnail.png',
            }),
            BlockBuilder.paragraph(),
        ],
    });
}
