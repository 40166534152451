import React, { Dispatch, SetStateAction } from 'react';
import Button from '../styled/Button';
import { useSetSettings } from '../settings/SettingsProvider';
import { useSpaceAccess } from '@/hooks/useSpaceAccess';
import { useTranslation } from 'react-i18next';
import useOnEscape from '@/hooks/useOnEscape';
import { track } from '@/analytics';

export function UpgradePlanBannerBig({
    title,
    subTitle,
    description,
    className,
    onClose,
    onClickCTA,
    ctaLabel,
    setOpen,
    trackingSource,
}: {
    title: string;
    subTitle?: string;
    description: string;
    className?: string;
    onClose?: () => void;
    onClickCTA?: () => void;
    setOpen?: Dispatch<SetStateAction<boolean>>;
    ctaLabel?: string;
    trackingSource: string;
}) {
    const setSettings = useSetSettings();
    const { isOwner } = useSpaceAccess();
    const { t } = useTranslation();

    useOnEscape(onClose ? onClose : () => {}, true);

    const onConfirm = () => {
        onClickCTA ? onClickCTA() : setSettings('plans');
        onClose && onClose();
    };

    return (
        <div
            className={`${
                className ? className : ''
            } relative p-7 rounded border border-saga-gray-200 dark:border-saga-gray-800 max-w-xl bg-saga-gray-100 dark:bg-zinc-700 dark:text-zinc-200 text-saga-text`}
        >
            {subTitle && <p className="mb-5">{subTitle}</p>}
            <div className="font-bold text-4xl mb-5">{title}</div>
            <p className="mb-5">{description}</p>
            <div className="flex items-center">
                {isOwner ? (
                    <Button
                        onClick={() => {
                            track('upgrade-plan-banner-cta-clicked', { source: trackingSource });
                            onConfirm();
                            if (setOpen) setOpen(false);
                        }}
                    >
                        {ctaLabel ? ctaLabel : t('billing.upgrade_plan_banner.primary_button_default')}
                    </Button>
                ) : (
                    <p>{t('billing.upgrade_plan_banner.no_access_desciption')}</p>
                )}
            </div>
            {onClose && (
                <div className="absolute top-0 right-0 m-2">
                    <Button.XButton onClick={onClose} />
                </div>
            )}
        </div>
    );
}
