export { DocumentName } from './DocumentName';
import * as E from 'fp-ts/Either';
import * as A from 'fp-ts/Array';
import * as ReadonlyArray from 'fp-ts/ReadonlyArray';
import * as THS from 'fp-ts/These';
import * as M from 'fp-ts/Monoid';
import { pipe, flow } from 'fp-ts/lib/function';
import { DecodingError, SafeArrayOutput } from '../io-ts-js';
import { Collection, isMention, isSagaText } from '../types';
import * as Sentry from '@sentry/browser';
import _ from 'lodash';

export { default as mergeSiblings } from './mergeSiblings';

export * as CookieUtils from './CookieUtils';
export * as DateUtils from './DateUtils';
export * as Colors from './Colors';
export * as Stateless from './Stateless';

export function assertNonNull<Type>(value: Type | null | undefined, msg?: string): asserts value is Type {
    if (value == null) {
        throw new Error(msg ?? 'Expected non-null value');
    }
}

export function ensureNonNull<Type>(value: Type | null | undefined, msg?: string): Type {
    assertNonNull<Type>(value, msg);
    return value;
}

export const filterRights: <A>(as: E.Either<unknown, A>[]) => A[] = flow(
    A.map((e) => {
        if (E.isLeft(e)) {
            // console.warn(e.left);
        }
        return e;
    }),
    A.filter(E.isRight),
    A.map((a) => a.right),
);

export const monoidString: M.Monoid<THS.These<readonly DecodingError[], readonly string[]>> = {
    empty: THS.both([], []),
    concat: THS.getSemigroup(ReadonlyArray.getSemigroup<DecodingError>(), ReadonlyArray.getSemigroup<string>()).concat,
};

export const monoidCollection: M.Monoid<THS.These<readonly DecodingError[], readonly Collection[]>> = {
    empty: THS.both([], []),
    concat: THS.getSemigroup(ReadonlyArray.getSemigroup<DecodingError>(), ReadonlyArray.getSemigroup<Collection>())
        .concat,
};

export function toCtx<A>(safeArray: SafeArrayOutput<A>): E.Either<
    DecodingError,
    {
        items: A[];
        safeArray: SafeArrayOutput<A>;
    }
> {
    return pipe(
        safeArray.toDecodedArray(),
        E.map((items) => ({ items, safeArray })),
    );
}

export function logError(error: Error | DecodingError): void {
    const message = (() => {
        if (error instanceof Error) {
            return error.message;
        } else {
            return error.error;
        }
    })();

    console.warn(message);
    Sentry.captureMessage(message);
}

export function removeNullable<V>(v: V): v is NonNullable<V> {
    return v != null;
}

export function hasId(node: any): node is { id: string } {
    return 'id' in node && typeof node.id === 'string';
}

export function hasChildren(node: any): node is { children: unknown[] } {
    return 'children' in node && Array.isArray(node.children);
}

export function removeIds<T>(blocks: T[]): Omit<T, 'id'>[] {
    return blocks.map(removeIdFromBlock);
}

export function removeIdFromBlock<T>(block: T): Omit<T, 'id'> {
    if (!isSagaText(block) && hasId(block)) {
        const { id, ...rest } = block;
        id;

        if (hasChildren(block)) {
            return { ...rest, children: removeIds(block.children) };
        }

        return rest;
    }
    return block;
}

export function removeDates<T>(blocks: T[]): Omit<T, 'date'>[] {
    return blocks.map(removeDateFromBlock);
}

export function removeDateFromBlock<T>(block: T): Omit<T, 'date'> {
    if (isMention(block)) {
        const { date, ...rest } = block;
        date;

        return rest;
    }

    if (hasChildren(block)) {
        return { ...block, children: removeDates(block.children) };
    }

    return block;
}

type NonFalse<T> = T extends false ? never : T;

export function removeFalse<T, V extends T | false>(v: V): v is NonFalse<V> {
    return v !== false;
}

/**
 * This calculates a simple hashCode from a given string.
 * It is based on Java's implementation of hashCode
 * See https://werxltd.com/wp/2010/05/13/javascript-implementation-of-javas-string-hashcode-method/
 *
 * @param value the string to hash
 */
export function hashCode(value: string): number {
    let hash = 0;

    for (let i = 0; i < value.length; i++) {
        const charCode = value.charCodeAt(i);
        // This is the same as writing charCode + 31 * hash
        hash = charCode + ((hash << 5) - hash);
        // This converts the result to a 32bit integer
        hash = hash & hash;
    }

    return hash;
}

export const until = (conditionFunction: any, timeout = 3000) => {
    const created = new Date();

    const poll = async (resolve: any, reject: any, timeStamp: Date = created) => {
        if (await conditionFunction()) {
            resolve();
            return;
        }
        if (timeStamp.getTime() - created.getTime() > timeout) {
            reject('Timeout');
            return;
        }

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        else setTimeout((_) => poll(resolve, reject, new Date()), 400);
    };

    return new Promise(poll);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AnyFunction = (...args: any[]) => any;

export interface MemoizeDebouncedFunction<F extends AnyFunction> extends _.DebouncedFunc<F> {
    (...args: Parameters<F>): ReturnType<F> | undefined;
    flush: (...args: Parameters<F>) => ReturnType<F> | undefined;
    cancel: (...args: Parameters<F>) => void;
}
/**Combines Lodash's _.debounce with _.memoize to allow for debouncing
 * based on parameters passed to the function during runtime.
 *
 * @param func The function to debounce.
 * @param wait The number of milliseconds to delay.
 * @param options Lodash debounce options object.
 * @param resolver The function to resolve the cache key.
 */
export function memoizeDebounce<F extends AnyFunction>(
    func: F,
    wait = 0,
    options: _.DebounceSettings = {},
    resolver?: (...args: Parameters<F>) => unknown,
): MemoizeDebouncedFunction<F> {
    const debounceMemo = _.memoize<(...args: Parameters<F>) => _.DebouncedFunc<F>>(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        (..._args: Parameters<F>) => _.debounce(func, wait, options),
        resolver,
    );

    function wrappedFunction(this: MemoizeDebouncedFunction<F>, ...args: Parameters<F>): ReturnType<F> | undefined {
        return debounceMemo(...args)(...args);
    }

    const flush: MemoizeDebouncedFunction<F>['flush'] = (...args) => {
        return debounceMemo(...args).flush();
    };

    const cancel: MemoizeDebouncedFunction<F>['cancel'] = (...args) => {
        return debounceMemo(...args).cancel();
    };

    wrappedFunction.flush = flush;
    wrappedFunction.cancel = cancel;

    return wrappedFunction;
}
