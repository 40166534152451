import { Collection } from '../types';
import { ymapFromRecord } from '../yjs-utils';
import { SafeSpace } from '../types';
import { pipe } from 'fp-ts/lib/function';
import * as E from 'fp-ts/Either';
import transactInSpace from './transactInSpace';

export default function addCollection(space: SafeSpace, collection: Collection) {
    const ycollection = ymapFromRecord(collection);
    transactInSpace(space, () => {
        pipe(
            space.get('collections'),
            //  but the type looks correct but typescript complain here when running check-types
            E.map((collectionsSafeArray) => collectionsSafeArray.array.push([ycollection as any])),
        );
    });
}
