import { Cursor, UserState } from '@/../../shared/src/slateYjs';
import { useDesktopContext } from '@/components/DesktopContext';
import { CollectionIcon, LinearIcon, PageIcon } from '@/components/icons';
import { PopOver } from '@/components/popover/PopOver';
import { useAwareness } from '@/components/RealtimeProvider';
import { useSearchContext } from '@/components/search/SearchContext';
import { useSpace } from '@/components/SpaceProvider';
import { useCollectionsSnapshot, usePartialPages } from '@/hooks/SpaceHooks';
import useOnClickOutside from '@/hooks/useClickOutside';
import useJumpToMemberCursor from '@/hooks/useJumpToMemberCursor';
import useMobile from '@/hooks/useMobile';
import { useOpenGoogleDriveFile } from '@/hooks/useOpenGoogleDriveFile';
import { useSpaceAccess } from '@/hooks/useSpaceAccess';
import { escapeRegExp } from '@/lib/helpers';
import { googleDriveFileToSuggestion, linearIssueToSuggestion, taskToSuggestion } from '@/lib/Suggestions';
import { GoogleDriveFileSuggestion, LinearIssueSuggestion, TaskSuggestion } from '@/types';
import {
    Collection,
    SagaEditor,
    Page,
    removeNullable,
    SagaLocation,
    SpaceOperations,
    WeakPage,
    WeakTask,
} from '@saga/shared';
import { default as classNames } from 'classnames';
import * as R from 'ramda';
import React from 'react';
import { CheckCircle, CornerDownLeft, File, Layers, Search, Trash, X, Users, Archive } from 'react-feather';
import { animated, useSpring } from 'react-spring';
import ItemGroups from '../ItemGroups';
import MemberAvatar from '../MemberAvatar';
import { useOpenCollection, useOpenLocation } from '../PageNavigationProvider';
import { useArrowSelectableIndex } from '../popover/Dropdown';
import { useRecents } from '../RecentsContext';
import Button from '../styled/Button';
import { useUserContext } from '../UserContext';
import useSearchQuery from './useSearchQuery';
import { useTranslation } from 'react-i18next';
import { Search as SearchIcon } from 'react-feather';
import { getFuzzyMatches } from '../../../../shared/src/Search';
import { useSharedPages } from '../SharedPagesProvider';
import { useHistory, useLocation } from 'react-router-dom';
import TaskStatusSelect from '@/components/tasks/TaskStatusSelect';
import { VariableSizeList } from 'react-window';
import { useTasks } from '../tasks/hooks';

const keysPages = ['id', 'title', 'collections', 'archivedAt', 'updatedAt', 'icon', 'isTemplate', 'aliases'] as const;
const keysSharedPages = ['id', 'title', 'icon'] as const;

type PageForSearch = Pick<WeakPage, (typeof keysPages)[number]>;
type SharedPageForSearch = Pick<WeakPage, (typeof keysSharedPages)[number]>;

type PageSuggestion = {
    type: 'page';
    id: string;
    title: string;
    aliases: Page['aliases'];
    collections: Collection['title'][];
    icon: Page['icon'];
    isArchived: boolean;
    isTemplate: Page['isTemplate'];
};

type SharedPagesSuggestion = {
    type: 'shared-page';
    id: string;
    title: string;
    icon: Page['icon'];
};

function toPageSuggestion(page: PageForSearch, collections: string[]): PageSuggestion {
    return {
        type: 'page' as const,
        title: page.title,
        id: page.id,
        collections,
        isArchived: page.archivedAt != null,
        isTemplate: page.isTemplate,
        icon: page.icon,
        aliases: page.aliases,
    };
}

function toSharedPageSuggestion(page: SharedPageForSearch): SharedPagesSuggestion {
    return {
        id: page.id,
        title: page.title,
        icon: page.icon,
        type: 'shared-page',
    };
}

type CollectionSuggestion = { type: 'collection'; id: string; title: string; icon: Collection['icon'] };

function toCollectionSuggestion(collection: Collection): CollectionSuggestion {
    return { type: 'collection', id: collection.id, title: collection.title, icon: collection.icon };
}

type RecentSuggestion = {
    type: 'recent';
    location: SagaLocation.BlocksLocation;
    suggestion: PageSuggestion | TaskSuggestion;
};

type BaseMemberLocationSuggestion = {
    type: 'member-location';
    userState: UserState;
    cursor: Cursor;
    lastUpdatedAt: string;
    title: string;
};

type MemberPageLocationSuggestion = BaseMemberLocationSuggestion & {
    kind: 'page';
    page: PageForSearch;
};

type MemberTaskLocationSuggestion = BaseMemberLocationSuggestion & {
    kind: 'task';
    task: WeakTask;
};

type MemberLocationSuggestion = MemberTaskLocationSuggestion | MemberPageLocationSuggestion;

type NavigationSuggestion = {
    type: 'navigation';
    location: SagaLocation.AllCollectionsLocation | SagaLocation.AllPagesLocation | SagaLocation.AllTasksLocation;
    title: string;
};

type EntitySuggestion =
    | TaskSuggestion
    | PageSuggestion
    | SharedPagesSuggestion
    | CollectionSuggestion
    | GoogleDriveFileSuggestion
    | LinearIssueSuggestion;

export type Suggestion =
    | NavigationSuggestion
    | EntitySuggestion
    | RecentSuggestion
    | MemberLocationSuggestion
    | { type: 'search' };

function useGetCollectionsTitleByPage(pages: PageForSearch[], limit: number) {
    const collectionsCounterMap = React.useMemo(() => {
        const countMap: Map<string, number> = new Map();

        pages.forEach((p) =>
            p.collections.forEach((c) => {
                const counter = countMap.get(c);

                countMap.set(c, counter ? counter + 1 : 1);
            }),
        );

        return countMap;
    }, [pages]);

    return React.useCallback(
        (page: Pick<Page, 'collections'>, collections: Pick<Collection, 'title' | 'id'>[]) => {
            const availableCollections = collections.filter((c) => page.collections.includes(c.id));

            const sorted = availableCollections
                .sort((a, b) => (collectionsCounterMap.get(b.id) ?? 0) - (collectionsCounterMap.get(a.id) ?? 0))
                .map((c) => c.title);

            return R.take(limit, sorted);
        },
        [collectionsCounterMap, limit],
    );
}

function useMemberSuggestions(
    pages: PageForSearch[],
    tasks: WeakTask[],
    searchValue: string,
    currentLocation: SagaLocation.SagaLocation,
) {
    const { canEdit } = useSpaceAccess();
    const awareness = useAwareness();
    const awarenessStates = SagaEditor.useAwarenessStates(awareness);
    const { user } = useUserContext();
    return React.useMemo((): Suggestion[] => {
        if (!canEdit) {
            return [];
        }

        const cursors = awarenessStates
            .filter(([, state]) => state.user.userId !== user?.id)
            .map(([, state]) => {
                return (
                    state.cursors?.map((cursor) => {
                        return {
                            user: state.user,
                            cursor,
                        };
                    }) ?? []
                );
            })
            .flat()
            .filter(({ cursor }) => !SagaLocation.areLocationsEqual(cursor.location, currentLocation));

        const allMembersSuggestions = R.sortWith(
            [R.descend(R.prop('lastUpdatedAt'))],
            cursors
                .map((s): MemberLocationSuggestion | null => {
                    const location = s.cursor.location;
                    if (SagaLocation.isPageLocation(location)) {
                        const page = pages.find((p) => p.id === location.pageId);
                        if (page == null) return null;

                        return {
                            type: 'member-location',
                            kind: 'page',
                            lastUpdatedAt: page.updatedAt,
                            userState: s.user,
                            cursor: s.cursor,
                            page,
                            title: page.title.trim() || 'Untitled',
                        };
                    }

                    if (SagaLocation.isTaskLocation(location)) {
                        const task = tasks.find((task) => task.id === location.taskId);
                        if (task == null) return null;

                        const taskTitle = task.title ?? '';

                        return {
                            type: 'member-location',
                            kind: 'task',
                            lastUpdatedAt: task.updatedAt,
                            userState: s.user,
                            cursor: s.cursor,
                            task,
                            title: taskTitle.trim() || 'Untitled',
                        };
                    }

                    return null;
                })
                .filter(removeNullable),
        );

        if (searchValue.length > 0) {
            const regex = new RegExp(`(?:^|\\s)${escapeRegExp(searchValue)}`, 'gi');

            return allMembersSuggestions.filter((s) => s.userState.name.match(regex));
        } else {
            return R.take(4, allMembersSuggestions);
        }
    }, [awarenessStates, canEdit, searchValue, user, pages, tasks, currentLocation]);
}

function useTaskSuggestions(tasks: WeakTask[]) {
    return React.useMemo(
        () => tasks.map((task) => taskToSuggestion(task)).sort((a) => (a.task.state === 'OPEN' ? -1 : 1)),
        [tasks],
    );
}

function useGoogleDriveFileSuggestions(searchValue: string) {
    const { canEdit } = useSpaceAccess();
    const { searchResult, loading } = useSearchQuery(searchValue, !canEdit);

    return React.useMemo(
        () => ({
            googleDriveFileSuggestions:
                searchResult?.searchIntegrations.googleDriveFiles.map(googleDriveFileToSuggestion) ?? [],
            loading,
        }),
        [searchResult, loading],
    );
}

function useLinearSuggestions(searchValue: string) {
    const { canEdit } = useSpaceAccess();
    const { searchResult, loading } = useSearchQuery(searchValue, !canEdit);

    return React.useMemo(
        () => ({
            linearSuggestions: searchResult?.searchIntegrations.linearIssues.map(linearIssueToSuggestion) ?? [],
            loading,
        }),
        [searchResult, loading],
    );
}

function useRecentSuggestions(
    collections: Collection[],
    currentLocation: SagaLocation.SagaLocation,
    getCollectionsTitleByPage: (
        page: Pick<Page, 'collections'>,
        collections: Pick<Collection, 'id' | 'title'>[],
    ) => string[],
): RecentSuggestion[] {
    const { recentLocations } = useRecents();
    const { space } = useSpace();

    return React.useMemo(() => {
        return recentLocations
            .map((location) => {
                if (SagaLocation.areLocationsEqual(currentLocation, location)) {
                    return null;
                }

                switch (location.type) {
                    case 'page': {
                        const page = SpaceOperations.findLocation(space, location);
                        if (page) {
                            const suggestion = toPageSuggestion(page, getCollectionsTitleByPage(page, collections));
                            return { type: 'recent' as const, location, suggestion };
                        }
                        return null;
                    }
                    case 'task': {
                        const task = SpaceOperations.findLocation(space, location);
                        if (task) {
                            return {
                                type: 'recent' as const,
                                location,
                                suggestion: taskToSuggestion(task),
                            };
                        }
                        return null;
                    }
                }
            })
            .filter(removeNullable);
    }, [space, recentLocations, getCollectionsTitleByPage, collections, currentLocation]);
}

export function useSearchSuggestions(searchValue: string, currentLocation: SagaLocation.SagaLocation) {
    const { canEdit } = useSpaceAccess();
    const pages = usePartialPages(keysPages, 'deep', canEdit ? 'all' : 'non-deleted');
    const tasks = useTasks({
        mode: 'shallow',
        assigneeFilter: { kind: 'all' },
        priorityFilter: 'all',
        archivedFilter: canEdit ? 'all' : 'non-deleted',
    });
    const isMobile = useMobile();
    const collections = useCollectionsSnapshot();

    const taskSuggestions = useTaskSuggestions(tasks);
    const { googleDriveFileSuggestions, loading: googleDriveLoading } = useGoogleDriveFileSuggestions(searchValue);
    const { linearSuggestions, loading: linearLoading } = useLinearSuggestions(searchValue);
    const getCollectionsTitleByPage = useGetCollectionsTitleByPage(pages, isMobile ? 1 : 2);
    const recentSuggestions = useRecentSuggestions(collections, currentLocation, getCollectionsTitleByPage);
    const membersSuggestion = useMemberSuggestions(pages, tasks, searchValue, currentLocation);
    const sharedPages = useSharedPages();

    return React.useMemo((): { suggestions: Suggestion[]; loading: { linear: boolean; googleDrive: boolean } } => {
        if (searchValue.length > 0) {
            const pageSuggestions = pages
                .sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime())
                // Filter out the current location
                .filter((p) => !SagaLocation.areLocationsEqual(SagaLocation.pageLocationFromId(p.id), currentLocation))
                .map((page) => toPageSuggestion(page, getCollectionsTitleByPage(page, collections)));

            const sharedSuggestions = sharedPages.map((sharedPage) => toSharedPageSuggestion(sharedPage));

            const collectionSuggestions = collections.map(toCollectionSuggestion);

            const filterableSuggestions: (EntitySuggestion | NavigationSuggestion)[] = [
                { type: 'navigation', location: { type: 'allCollections' }, title: 'Collections' },
                { type: 'navigation', location: { type: 'allPages', viewId: 'non-deleted' }, title: 'Pages' },
                { type: 'navigation', location: { type: 'allPages', viewId: 'shared' }, title: 'Shared with me' },
                { type: 'navigation', location: { type: 'allTasks', viewId: 'all' }, title: 'Tasks' },
                ...sharedSuggestions,
                ...pageSuggestions,
                ...taskSuggestions,
                ...collectionSuggestions,
                ...googleDriveFileSuggestions,
                ...linearSuggestions,
            ];

            const regex = new RegExp(`(?:^|\\s|\\(|\\[)${escapeRegExp(searchValue)}`, 'gi');
            const results = filterableSuggestions.filter((item) => {
                if (item.type === 'page' && item.aliases.some((alias) => alias.match(regex))) {
                    return true;
                }
                return item.title.match(regex) || getFuzzyMatches(item.title, [searchValue]).length;
            }) as Suggestion[];

            const matchCurrentPage = pages.some((page) => page.title === searchValue);

            if (matchCurrentPage || !canEdit) {
                return {
                    suggestions: [...results, { type: 'search' }, ...membersSuggestion],
                    loading: { linear: linearLoading, googleDrive: googleDriveLoading },
                };
            } else {
                return {
                    suggestions: [...results, { type: 'search' }, ...membersSuggestion],
                    loading: { linear: linearLoading, googleDrive: googleDriveLoading },
                };
            }
        }

        return {
            suggestions: [...recentSuggestions.slice(0, 10), ...membersSuggestion],
            loading: { linear: linearLoading, googleDrive: googleDriveLoading },
        };
    }, [
        pages,
        collections,
        searchValue,
        recentSuggestions,
        getCollectionsTitleByPage,
        membersSuggestion,
        canEdit,
        googleDriveFileSuggestions,
        googleDriveLoading,
        linearSuggestions,
        linearLoading,
        currentLocation,
        taskSuggestions,
        sharedPages,
    ]);
}

interface SearchModalProps {
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    currentLocation: SagaLocation.SagaLocation;
}

function SearchModal({ isOpen, setIsOpen, currentLocation }: SearchModalProps) {
    const [searchValue, setSearchValue] = React.useState('');
    const [isParentHovered, setChildHovered] = React.useState(false);
    const { t } = useTranslation();
    const history = useHistory();
    const location = useLocation();
    const { search } = useSearchContext();
    const openLocation = useOpenLocation();
    const goToCollection = useOpenCollection();
    const jumpToCursor = useJumpToMemberCursor();

    const { useMacOsLayout } = useDesktopContext();

    const inputRef = React.useRef<HTMLInputElement>(null);
    const contentRef = React.useRef<HTMLDivElement>(null);
    const listRef = React.useRef<VariableSizeList>(null);
    const inputContainerRef = React.useRef<HTMLDivElement>(null);

    const [maxHeight, setMaxHeight] = React.useState(0);

    const { suggestions, loading } = useSearchSuggestions(searchValue, currentLocation);

    useOnClickOutside([contentRef], () => setIsOpen(false), {
        clickEvent: 'mousedown',
    });

    React.useEffect(() => {
        if (!isOpen) {
            setSearchValue('');
        }
    }, [isOpen]);

    const { opacity } = useSpring({
        from: {
            opacity: 0,
        },
        to: {
            opacity: isOpen ? 1 : 0,
        },
        config: {
            duration: 100,
        },
    });

    const openGoogleDriveFile = useOpenGoogleDriveFile();
    const onSelect = (event: Event | React.SyntheticEvent, suggestion: Suggestion) => {
        setIsOpen(false);

        switch (suggestion.type) {
            case 'recent': {
                openLocation(suggestion.location, event);
                break;
            }
            case 'navigation': {
                openLocation(suggestion.location, event);
                break;
            }
            case 'task': {
                openLocation(SagaLocation.taskLocationFromId(suggestion.task.id), event);
                break;
            }
            case 'search':
                search(searchValue);
                break;

            case 'collection':
                goToCollection(suggestion.id, event);
                break;

            case 'page': {
                openLocation(SagaLocation.pageLocationFromId(suggestion.id), event);
                break;
            }

            case 'shared-page': {
                history.push({
                    pathname: `/${suggestion.id}`,
                    state: { from: location.pathname },
                });
                break;
            }

            case 'drive': {
                openGoogleDriveFile(suggestion.file, event, 'search-modal');
                break;
            }

            case 'linear-issue': {
                window.open(suggestion.issue.url, '_blank', 'noopener noreferrer');
                break;
            }

            case 'member-location': {
                jumpToCursor(suggestion.cursor, event);
                break;
            }
        }

        setSearchValue('');
    };

    const { index: currentIndexSelection, setIndex } = useArrowSelectableIndex({
        onSelect: (index, event) => {
            const item = suggestions[index];
            if (item) {
                onSelect(event, item);
            }
        },
        onUpdate(index) {
            listRef.current?.scrollToItem(index);
        },
        maxIndex: suggestions.length - 1,
        active: isOpen,
        selectWithTab: true,
        initialIndex: 0,
    });

    return (
        <animated.div
            ref={(ref) => setMaxHeight((ref?.clientHeight ?? 0) - (inputContainerRef.current?.clientHeight ?? 0))}
            style={{ opacity }}
            className={classNames('sm:w-500 w-full h-full flex flex-col pointer-events-none', {
                'pt-8 sm:pt-0': useMacOsLayout,
            })}
        >
            <PopOver.UnboundCard>
                <div
                    data-testid="search-modal"
                    ref={contentRef}
                    className="pointer-events-auto shadow-popupSmall rounded-md flex flex-col h-full"
                >
                    <form
                        className="flex-1 flex flex-col min-h-0"
                        onSubmit={(event) => {
                            if (currentIndexSelection == null) {
                                return;
                            }
                            event.preventDefault();
                            onSelect(event, suggestions[currentIndexSelection]);
                        }}
                    >
                        <div className="relative" ref={inputContainerRef}>
                            <div className="flex flex-row items-center transition-colors duration-200 ease-in-out pl-3 bg-white dark:bg-saga-gray-1000 focus:outline-0 font-normal border-b border-saga-gray-200 dark:border-zinc-600 focus:bg-saga-gray-100 focus:border-gray-200 placeholder-saga-text-gray rounded-md rounded-b-none">
                                <SearchIcon size={20} className="ml-0.5 stroke-saga-gray-500" />
                                <input
                                    ref={inputRef}
                                    className="text-base pl-1.5 pr-8 block w-full bg-white dark:bg-saga-gray-1000 appearance-none leading-normal"
                                    type="text"
                                    placeholder={t('top_menu.search_dotted') as string}
                                    value={searchValue}
                                    onChange={(e) => setSearchValue(e.target.value)}
                                    id="search-input"
                                    autoFocus
                                    autoComplete="off"
                                />
                            </div>
                            <div
                                className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                                onClick={() => setIsOpen(false)}
                            >
                                <X className="stroke-saga-gray-500" size={20} />
                            </div>
                        </div>
                        {suggestions.length > 0 && (
                            <div className="min-h-0">
                                <ItemGroups
                                    itemClassName="px-1.5"
                                    ref={listRef}
                                    maxHeight={maxHeight}
                                    items={suggestions}
                                    labels={{
                                        navigation: <PopOver.Label>{t('common.navigation')}</PopOver.Label>,
                                        'member-location': <PopOver.Label>{t('common.members')}</PopOver.Label>,
                                        recent: <PopOver.Label>{t('common.recents')}</PopOver.Label>,
                                        page: <PopOver.Label>{t('common.pages')}</PopOver.Label>,
                                        'shared-page': <PopOver.Label>{t('sidebar.shared')}</PopOver.Label>,
                                        collection: <PopOver.Label>{t('common.collections')}</PopOver.Label>,
                                        drive: (
                                            <PopOver.Label loading={loading.googleDrive}>Google Drive</PopOver.Label>
                                        ),
                                        'linear-issue': <PopOver.Label loading={loading.linear}>Linear</PopOver.Label>,
                                        search: <PopOver.Label>{t('common.actions')}</PopOver.Label>,
                                        task: <PopOver.Label>{t('common.tasks')}</PopOver.Label>,
                                    }}
                                    renderItem={(item, index) => {
                                        const selected = index === currentIndexSelection;
                                        return (
                                            <Button.PopOverButton
                                                key={index}
                                                selected={selected}
                                                onMouseEnter={() => {
                                                    setIndex(index);
                                                    setChildHovered(true);
                                                }}
                                                onMouseLeave={() => setChildHovered(false)}
                                                type="button"
                                                onClick={(event) => onSelect(event, suggestions[index])}
                                                hoverable={false}
                                                height={9}
                                            >
                                                <div
                                                    className="w-full flex justify-between"
                                                    data-testid={`row-index-${index}`}
                                                >
                                                    <div className="flex truncate items-center">
                                                        <SuggestionContent
                                                            suggestion={item}
                                                            searchValue={searchValue}
                                                            key={index}
                                                        />
                                                    </div>
                                                    <div className="flex flex-row ">
                                                        <SuggestionExtra
                                                            suggestion={item}
                                                            selected={selected}
                                                            isParentHovered={selected && isParentHovered}
                                                        />
                                                        <EnterIndicator
                                                            visible={selected}
                                                            isParentHovered={selected && isParentHovered}
                                                        />
                                                    </div>
                                                </div>
                                            </Button.PopOverButton>
                                        );
                                    }}
                                />
                            </div>
                        )}
                        {suggestions.length === 0 && searchValue.length === 0 && (
                            <div className="px-4 py-3 flex">
                                {searchValue.length > 0 ? 'No results' : 'Search a Page or a Collection...'}
                            </div>
                        )}
                    </form>
                </div>
            </PopOver.UnboundCard>
        </animated.div>
    );
}

export function EnterIndicator({ visible, isParentHovered }: { visible: boolean; isParentHovered?: boolean }) {
    return (
        <div
            className={classNames(
                'text-xs leading-normal flex justify-center pl-2 rounded bg-saga-gray-150 dark:bg-saga-gray-800 items-center',
                {
                    'dark:bg-saga-gray-700 bg-saga-gray-250': isParentHovered,
                },
            )}
            style={{ visibility: visible ? 'visible' : 'hidden' }}
        >
            <CornerDownLeft className="text-saga-gray-dark dark:text-saga-gray-500 w-3.5 h-3.5" />
        </div>
    );
}

function CollectionLabel({
    index,
    selected,
    children,
    isParentHovered,
}: {
    index: number;
    selected: boolean;
    isParentHovered?: boolean;
    children: React.ReactNode;
}) {
    return (
        <p
            data-testid={`collection-index-${index}`}
            className={classNames(
                'text-xs p-1 inline-block leading-normal dark:focus:bg-saga-gray-800 text-saga-gray-500',
                {
                    'dark:bg-saga-gray-900 bg-white': !selected,
                    'dark:bg-saga-gray-800 bg-saga-gray-150': selected,
                    'dark:bg-saga-gray-700 bg-saga-gray-250': isParentHovered,
                },
            )}
        >
            {children}
        </p>
    );
}

export function SuggestionExtra({
    suggestion,
    selected,
    isParentHovered,
}: {
    suggestion: Suggestion;
    selected: boolean;
    isParentHovered?: boolean;
}) {
    switch (suggestion.type) {
        case 'page': {
            if (suggestion.collections.length > 0) {
                return (
                    <div className="flex flex-row items-center">
                        {suggestion.collections.map((title, i) => (
                            <div key={i} className="flex items-center justify-center">
                                <Layers className="stroke-saga-gray-500 w-3 flex-none mx-1" />
                                <CollectionLabel
                                    key={suggestion.id}
                                    index={i}
                                    selected={selected}
                                    isParentHovered={isParentHovered}
                                >
                                    {title}
                                </CollectionLabel>
                            </div>
                        ))}
                    </div>
                );
            }
            return null;
        }
        case 'recent': {
            return (
                <SuggestionExtra
                    selected={selected}
                    suggestion={suggestion.suggestion}
                    isParentHovered={isParentHovered}
                />
            );
        }
    }

    return null;
}

export const SuggestionContent: React.FC<{ suggestion: Suggestion; searchValue: string }> = ({
    suggestion,
    searchValue,
}) => {
    const { t } = useTranslation();
    switch (suggestion.type) {
        case 'recent':
            return <SuggestionContent suggestion={suggestion.suggestion} searchValue={searchValue} />;
        case 'navigation': {
            function Icon({ suggestion }: { suggestion: NavigationSuggestion }) {
                switch (suggestion.location.type) {
                    case 'allCollections':
                        return <CollectionIcon icon={undefined} />;
                    case 'allPages':
                        if (suggestion.location.viewId === 'shared') {
                            return <Users size={14} />;
                        }
                        return <File className="flex-none w-4" />;
                    case 'allTasks':
                        return <CheckCircle className="flex-none w-4" />;
                }
            }

            return (
                <>
                    <span className="mr-2 ml-0.5 flex items-center w-4">
                        <Icon suggestion={suggestion} />
                    </span>
                    <span className="text-left truncate ml-0.5">{suggestion.title}</span>
                </>
            );
        }
        case 'page':
            return (
                <>
                    {suggestion.isArchived ? (
                        <Trash className="mr-2 w-3.5 h-3.5 flex-none" />
                    ) : (
                        <div
                            className={classNames('mr-2 ml-0.5 flex items-center h-3.5 ', {
                                'mt-1.5': suggestion.icon,
                            })}
                        >
                            <PageIcon icon={suggestion.icon} isTemplate={suggestion.isTemplate} />
                        </div>
                    )}
                    <p className="text-left truncate space-x-2 ml-0.5">
                        <span>{suggestion.title.trim() === '' ? 'Untitled' : suggestion.title}</span>
                        {suggestion.isTemplate && (
                            <span className="p-0.5 rounded text-xxs bg-saga-primary-light text-yellow-800">
                                {t('common.template')}
                            </span>
                        )}
                    </p>
                </>
            );
        case 'shared-page':
            return (
                <>
                    <div className={classNames('mr-2 ml-0.5 flex items-center h-3.5 ', { 'mt-1.5': suggestion.icon })}>
                        <PageIcon icon={suggestion.icon} isTemplate={false} />
                    </div>
                    <p className="text-left truncate ml-0.5">{suggestion.title}</p>
                </>
            );
        case 'collection':
            return (
                <>
                    <div className="mr-2 flex items-center w-4">
                        <CollectionIcon icon={suggestion.icon} />
                    </div>
                    <p className="text-left truncate">{suggestion.title}</p>
                </>
            );

        case 'task':
            return (
                <>
                    {suggestion.task.archivedAt ? (
                        <Archive className="mr-2.5 flex items-center w-4" />
                    ) : (
                        <div className="mr-2.5 flex items-center w-4">
                            <TaskStatusSelect status={suggestion.task.state} disabled />
                        </div>
                    )}
                    <p className="text-left truncate">{suggestion.title}</p>
                </>
            );
        case 'drive':
            return (
                <>
                    <div className="mr-2 items-center w-4 flex-none relative">
                        {suggestion.file.iconLink && <img src={suggestion.file.iconLink} />}
                    </div>
                    <p className="text-left truncate">{suggestion.title}</p>
                </>
            );

        case 'linear-issue':
            return (
                <>
                    <div className="mr-2 flex items-center w-4">
                        <LinearIcon />
                    </div>
                    <p className="text-left truncate">
                        {suggestion.issue.identifier} - {suggestion.title}
                    </p>
                </>
            );

        case 'member-location':
            return (
                <>
                    <div className="mr-1.5 inline-flex">
                        <MemberAvatar name={suggestion.userState.name} color={suggestion.userState.color} />
                    </div>
                    <div className="items-center text-left truncate flex">
                        <span>{suggestion.userState.name}</span>
                        <span>&nbsp;{t('common.is_in')}</span>

                        {suggestion.kind === 'page' && (
                            <div className="w-4 mx-1 flex-none">
                                <PageIcon icon={suggestion.page.icon} isTemplate={suggestion.page.isTemplate} />
                            </div>
                        )}

                        {suggestion.kind === 'task' && (
                            <div className="w-4 mx-1 flex-none">
                                <TaskStatusSelect status={suggestion.task.state} disabled />
                            </div>
                        )}

                        <p className="text-left truncate">{suggestion.title}</p>
                    </div>
                </>
            );

        case 'search':
            return (
                <>
                    <Search className="ml-0.5 mr-2 w-4 my-auto flex-none" />
                    <div className="my-auto text-left truncate flex">
                        <p className="font-medium">{searchValue}</p>
                        <p>&nbsp;{`- ${t('common.search_in_pages')}`}</p>
                    </div>
                </>
            );
    }
};

export default SearchModal;
