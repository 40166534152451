import { prompt } from '../../../shared/src/aiPrompts.json';

export default {
    translation: {
        common: {
            open_saga: 'Apri Saga',
            popover_placeholder: 'Inserisci testo o @ per',
            ai: 'IA',
            commands: 'comandi',
            and: 'e',
            or: 'o',
            in: 'in',
            add_dotted: 'Aggiungi...',
            turn_into: 'Trasforma in',
            plus_template: '+ Modello',
            plus_collection: '+ Collezione',
            plus_alias: '+ Alias',
            new_alias_placeholder: 'Digita un nuovo Alias...',
            create_alias: '&nbsp;— Crea Alias',
            remove_alias: 'Rimuovi {{alias}}',
            add_template_title: 'Seleziona un modello',
            add_template_placeholder: 'Cerca un modello...',
            open: 'Apri',
            mark_as_complete: 'Segna come completato',
            open_in_new_tab: 'Apri in una nuova scheda',
            add_to_pinned: 'Aggiungi agli appuntati',
            add_to_favorites: 'Aggiungi ai preferiti',
            remove_from_pinned: 'Rimuovi dagli appuntati',
            remove_from_favorites: 'Rimuovi dai preferiti',
            create: 'Crea',
            create_workspace_title: 'Creiamo il tuo spazio di Lavoro.',
            create_workspace_description:
                'Uno Spazio di Lavoro è dove le tue pagine, idee e conoscenze si connettono e si organizzano.',
            create_workspace_placeholder: 'Il mio Spazio di Lavoro',
            save: 'Salva',
            edit: 'Modifica',
            cancel: 'Annulla',
            reload: 'Ricarica',
            reset: 'Reimposta',
            logout: 'Esci',
            login: 'Accedi',
            learn_more: 'Scopri di più',
            free_plan: 'Piano gratuito',
            upgrade: 'Aggiornare',
            show_me: 'Mostrami',
            got_it: 'Ok, capito.',
            dismiss: 'Ignora',
            do_this_later: 'Fallo più tardi',
            get_started: 'Inizia',
            dates: 'Date',
            link_to_collection: 'Collega a una Collezione',
            insert: 'Inserisci',
            recents: 'Recenti',
            google_drive: 'Google Drive',
            linear_issues: 'Problemi Linear',
            tasks: 'Compiti',
            table_commands: 'Comandi Tabella',
            mention_members: 'Menziona Membri',
            insert_page_link_to: 'Inserisci collegamento alla pagina per',
            link_to_page: 'Collega alla Pagina',
            gdrive_file: 'File Google Drive',
            linear_issue: 'Problema Linear',
            new_template: 'Nuovo Modello',
            create_template: 'Crea Modello',
            create_template_placeholder: 'Inserisci un nome per il modello...',
            edit_template: 'Modifica Modello',
            use_template: 'Usa Modello',
            recent_templates: 'Modelli Recenti',
            create_page_from_template: 'Crea pagina dal modello',
            page_created: 'Pagina Creata',
            has_been_created: 'è stata creata',
            blocks_moved: 'Blocchi spostati',
            selected_blocks_have_been_moved: 'I blocchi selezionati sono stati spostati in:',
            selected_blocks_have_been_turned: 'I blocchi selezionati sono stati trasformati in una nuova pagina:',
            live_block_details: 'Un Blocco Live dei blocchi selezionati è stato creato in:',
            live_block_created: 'Blocco Live Creato',
            duplicate: 'Duplica',
            move_to: 'Sposta in',
            duplicate_page: 'Duplica Pagina',
            duplicate_task: 'Attività duplicata',
            copy_page: 'Copia Pagina',
            undo: 'Annulla',
            is_mac: '⌘ + Z',
            is_windows: 'Ctrl + Z',
            export: 'Esporta',
            delete: 'Elimina',
            update: 'Aggiorna',
            continue: 'Continua',
            upgrade_plan: 'Aggiorna piano',
            see_plans: 'Gestisci piano',
            delete_page: 'Elimina Pagina',
            remove_from_page: 'Rimuovi dalla pagina',
            first_name: 'Nome',
            last_name: 'Cognome',
            open_workspace: 'Apri Spazio di Lavoro',
            open_page: 'Apri Pagina',
            quick_edit: 'Modifica rapida',
            open_full_page: 'Apri pagina intera',
            close: 'Chiudi',
            close_popup: 'Chiudi Popup',
            close_entity: 'Chiudi {{entity}}',
            close_modal: 'Chiudi modale',
            close_quick_edit_modal: 'Chiudi modale di modifica rapida',
            open_notifications: 'Apri Notifiche',
            close_notifications: 'Chiudi Notifiche',
            close_sidebar: 'Chiudi Barra Laterale',
            close_task_context_menu: 'Chiudi Menu Contestuale Compito',
            close_context_menu: 'Chiudi Menu Contestuale',
            open_context_menu: 'Apri Menu Contestuale',
            open_task_context_menu: 'Apri Menu Contestuale Compito',
            close_sidebar_context_menu: 'Chiudi Menu Contestuale Barra Laterale',
            open_sidebar: 'Apri Barra Laterale',
            open_sidebar_context_menu: 'Apri Menu Contestuale Barra Laterale',
            open_page_context_menu: 'Apri Menu Contestuale Pagina',
            close_page_context_menu: 'Chiudi Menu Contestuale Pagina',
            open_image_context_menu: 'Apri Menu Contestuale Immagine',
            close_property_menu: 'Chiudi Menu Proprietà',
            open_property_menu: 'Apri Menu Proprietà',
            close_pages_context_menu: 'Chiudi Menu Contestuale Pagine',
            open_pages_context_menu: 'Apri Menu Contestuale Pagine',
            open_in_codeSandbox: 'Apri in CodeSandbox',
            open_context_menu_for_column: 'Apri Menu Contestuale per Colonna {{column}}',
            back_to_my_saga: 'Torna a My Saga',
            referenced_in_pages: 'Citato in Pagine',
            see_pages_that_reference: 'Vedi le pagine che citano il blocco:',
            remove_icon: 'Rimuovi Icona',
            add_icon: 'Aggiungi Icona',
            change_icon: 'Cambia Icona',
            remove_callout: 'Rimuovi Callout',
            click_add_image: "Clicca per aggiungere un'immagine",
            click_add_image_or_embed: "Clicca per aggiungere un'immagine o <1>incorpora da URL</1>",
            image_could_not_be_loaded: "L'immagine non può essere caricata.",
            select_or_search: 'Seleziona o cerca',
            text_copied: 'Testo copiato',
            copy_text: 'Copia testo',
            prompt_copied: 'Prompt copiato',
            copy_prompt: 'Copia prompt',
            stop: 'Ferma',
            select: 'Seleziona',
            search_in_pages: 'Cerca nelle pagine',
            navigation: 'Navigazione',
            members: 'Membri',
            pages: 'Pagine',
            collections: 'Collezioni',
            actions: 'Azioni',
            create_new_page: 'Crea nuova pagina',
            create_live_block: 'Crea Blocco Live',
            create_live_block_description: "Riferisci questa selezione in un'altra pagina",
            create_live_block_disabled: 'Creare un Blocco Live non è possibile con questa selezione.',
            remove_image: 'Rimuovi Immagine',
            is_in: 'è in',
            template: 'Modello',
            view_references: 'Visualizza Riferimenti',
            try_saga: 'Prova Saga',
            search: 'Cerca',
            report_page: 'Segnala Pagina',
            paste_as_link: 'Incolla come Collegamento',
            paste_as_preview: 'Incolla come Anteprima del Collegamento',
            paste_as_google_drive: 'Incolla come Collegamento Google Drive',
            paste_as_linear_link: 'Incolla come Collegamento Linear',
            paste_as_text: 'Incolla come testo',
            paste_as_live_block: 'Incolla come Blocco Live',
            logged_in_with: "Hai effettuato l'accesso con",
            log_in: 'Accedi',
            gain_access:
                "con un'email diversa, o contatta un proprietario di questo spazio di lavoro per ottenere l'accesso.",
            highlighted_content: {
                text: 'Colore del Carattere',
                background: 'Evidenziazione del Testo',
            },
            properties_tooltip:
                'Le proprietà sono campi personalizzabili, come testo, tag e numeri che puoi aggiungere alle tue pagine per fornire un contesto più dettagliato.',
            confirm_changes: 'Conferma modifiche',
            you: 'Tu',
            mentioned_by: 'Menzionato da',
            mentioned_on: 'Menzionato il',
            copy: 'Copia',
            all_collections: 'Tutte le Collezioni',
            no_collections: 'Nessuna Collezione',
            n_collections_other: '{{count}} Collezioni',
            n_collections_one: '{{count}} Collezione',
            search_collection_placeholder: 'Cerca Collezione...',
        },
        inapp_notifications: {
            title: 'Saga 1.0',
            description:
                'Siamo ufficialmente fuori dalla beta! Leggi il comunicato per ulteriori informazioni su cosa ci aspetta.',
        },
        invite_onboarding: {
            create_button: 'Crea Nuovo Spazio di Lavoro',
            join_button: 'Unisciti a uno Spazio di Lavoro',
            join_title: 'Unisciti o crea uno spazio di lavoro',
            invited_description: 'Non sei stato invitato a nessuno Spazio di Lavoro.',
            create_space_title: 'Crea il tuo Spazio di Lavoro.',
            invited_description_one: 'Sei stato invitato allo:',
            invited_description_other: 'Sei stato invitato agli:',
            member_one: 'membro',
            member_other: 'membri',
        },
        editor: {
            loading_preview_dotted: "Caricamento dell'anteprima...",
            edit_link: 'Modifica Collegamento',
            link_to: 'Collegare a',
            link_copied: 'Collegamento copiato',
            copy_Link: 'Copia Collegamento',
            turn_into: 'Trasforma in',
            link_into_pretty_link: 'Trasforma il collegamento in pretty-link',
            link_into_embed: 'Trasforma il collegamento in incorporato',
            move_to_page: 'Sposta nella pagina',
            create_live_block: 'Crea Blocco Live',
            add_live_block: 'Aggiungi come Blocco Live alla pagina',
            create_template: 'Crea Modello',
            edit_template: 'Stai modificando un modello.',
            view_templates: 'Visualizza Tutti i Modelli',
            create_page: 'Crea Pagina',
            create_collection: 'Crea Collezione',
            create_task: 'Crea Compito',
            all_templates: 'Tutti i modelli',
            create_issue_in: 'Problema in {{teamName}}',
            turn_into_link: 'Trasforma in collegamento',
            turn_into_link_preview: 'Trasforma in anteprima link',
            turn_into_embed: 'Trasforma in incorporamento',
            embed_context_menu: 'Menu Contestuale Incorporato',
            loading_embed: "Caricamento dell'incorporato...",
            remove_embed: 'Rimuovi Incorporato',
            remove_link: 'Rimuovi link',
            link_preview_not_available: "L'anteprima del collegamento non è disponibile.",
            turn_into_page: 'Pagina <1>{{value}}</1>',
            turn_into_page_link: 'Trasforma in Collegamento alla Pagina',
            turn_into_task: 'Compito <1>{{value}}</1>',
            turn_into_liner_issue: 'Problema Linear <1>{{value}} in {{team}}</1>',
            turn_into_liner_issue_tooltip: 'Problema Linear {{value}} in {{team}}',
            copy_link_to_block: 'Copia link al blocco',
            delete_block: 'Elimina blocco',
            block_deleted: 'Blocco eliminato',
            quick_edit: 'Modifica rapida',
            empty_page: 'Pagina Vuota',
            format_text: 'Testo',
            format_todo: 'Lista di Cose da Fare',
            format_task: 'Compito',
            format_heading_l: 'Intestazione — Grande',
            format_heading_m: 'Intestazione — Media',
            format_heading_s: 'Intestazione — Piccola',
            format_ul: 'Punti Elenco',
            format_ol: 'Elenco Numerato',
            format_img: 'Immagine',
            format_file: 'Carica File',
            format_table: 'Tabella',
            format_callout: 'Callout',
            format_quote: 'Citazione',
            format_divider: 'Divisore',
            format_code: 'Codice',
            format_equation: 'Equazione',
            format_inline_equation: 'Equazione In Linea',
            suffix_create_task: '— Crea Compito',
            suffix_create_issue_in: '— Problema in {{team}}',
            insert_page_link_to: 'Inserisci Collegamento alla Pagina per {{title}}',
            insert_recent_page_link_to: 'Inserisci Collegamento Recente alla Pagina per {{title}}',
            insert_column_left: 'Inserisci Colonna a Sinistra',
            insert_column_right: 'Inserisci Colonna a Destra',
            insert_row_above: 'Inserisci Riga Sopra',
            insert_row_below: 'Inserisci Riga Sotto',
            placeholder: {
                h1: 'Intestazione 1',
                h2: 'Intestazione 2',
                h3: 'Intestazione 3',
                task: 'Compito',
                list: 'Elenco',
                quote: 'Citazione',
                enter_text: 'Inserisci testo ',
                enter_text_dotted: 'Inserisci testo...',
            },
            confirm_table_delete: 'Sei sicuro di voler eliminare la tabella selezionata?',
            file_upload: {
                click_to_upload_file: 'Clicca per caricare un file',
                uploading: 'Caricamento...',
                download_file: 'Scarica File',
                remove_file: 'Rimuovi File',
                close_file_context_menu: 'Chiudi Menu Contestuale File',
                open_file_context_menu: 'Apri Menu Contestuale File',
                max_file_size_error: 'La dimensione massima del file è {{size}}.',
            },
            actions: 'Azioni',
        },
        update_references_modal: {
            title: 'Aggiornare i collegamenti a questa pagina?',
            description_one:
                'Rinominare questa pagina da <span_red>{{-previousTitle}}</span_red> a <span_yellow>{{-newTitle}}</span_yellow> interromperà <span_bold>{{count}}</span_bold> collegamento a questa pagina. Vuoi aggiornarli?',
            description_other:
                'Rinominare questa pagina da <span_red>{{-previousTitle}}</span_red> a <span_yellow>{{-newTitle}}</span_yellow> interromperà <span_bold>{{count}}</span_bold> collegamenti a questa pagina. Vuoi aggiornarli?',
            select_all: 'Seleziona Tutto',
            deselect_all: 'Deseleziona Tutto',
            update_links: 'Aggiorna Collegamenti',
            update_link_one: 'Aggiorna {{count}} collegamento',
            update_link_other: 'Aggiorna {{count}} collegamenti',
            dont_update: 'Non aggiornare',
        },
        updated_title_notice: {
            outdated_link_one: "C'è <bold>{{count}}</bold> collegamento obsoleto a questa pagina.",
            outdated_link_other: 'Ci sono <bold>{{count}}</bold> collegamenti obsoleti a questa pagina.',
            review: 'Rivedi',
            update_all: 'Aggiorna tutto',
        },
        ai: {
            title: 'Saga IA',
            ask_ai: "Chiedi all'IA",
            ask_ai_anything_mentions_dotted: "Chiedi all'AI...",
            ask_ai_anything_dotted: "Chiedi all'AI...",
            rewrite: 'Riscrivi',
            discard: 'Scarta',
            rerun: 'Riesegui',
            keep: 'Mantieni',
            insert_below: 'Inserisci sotto',
            replace: 'Sostituisci',
            generating_dotted: 'Generando...',
            searching_space_dotted: 'Cercando nello spazio di lavoro...',
            searching_page_dotted: 'Cercando nella pagina...',
            too_many_requests: 'Hai inviato troppe richieste. Riprova tra qualche secondo.',
            too_many_prompt_tokens:
                "Hai raggiunto la lunghezza massima della conversazione per questa risposta dell'AI. <1>Scopri di più</1>",
            reduce_prompt_error: 'Si prega di accorciare il prompt a un massimo di {{max_length}} caratteri',
            restricted: 'Hai raggiunto la quota mensile di uso equo per Saga AI. <1>Scopri di più</1>',
            issue_with_generating: 'Si è verificato un problema nella generazione del tuo testo.',
            edit_selection: 'Modifica selezione',
            change_tone: 'Cambia tono',
            translate: 'Traduci',
            draft: 'Bozza',
            discard_response: "Vuoi scartare la risposta dell'AI?",
            my_prompts: 'I miei Prompt',
            my_commands: 'I miei Comandi',
            search_or_select: 'Cerca o seleziona',
            mention: 'Menziona una pagina o un compito',
            suggestion: {
                rewrite: 'Riscrivi',
                continue: 'Continua a scrivere',
                summarize: 'Riassumi',
                minimize: 'Rendi più corto',
                maximize: 'Rendi più lungo',
                explain: 'Spiega',
                fix: 'Correggi ortografia e grammatica',
                tone_concise: 'Conciso',
                tone_professional: 'Professionale',
                tone_casual: 'Informale',
                tone_funny: 'Divertente',
                translate_en: 'Inglese',
                translate_es: 'Spagnolo',
                translate_fr: 'Francese',
                translate_de: 'Tedesco',
                translate_pt: 'Portoghese',
                translate_it: 'Italiano',
                translate_nl: 'Olandese',
                translate_zh: 'Cinese',
                translate_ko: 'Coreano',
                translate_ja: 'Giapponese',
                translate_ua: 'Ucraino',
                translate_ru: 'Russo',
                brainstorm: 'Genera idee',
                brainstorm_about: prompt.brainstorm_about.it,
                outline: 'Struttura',
                outline_about: prompt.outline_about.it,
                email: 'Email',
                email_about: prompt.email_about.it,
                meeting: 'Agenda riunione',
                meeting_about: prompt.meeting_about.it,
                blog: 'Post del blog',
                blog_about: prompt.blog_about.it,
                social: 'Post sui social media',
                social_about: prompt.social_about.it,
                website: 'Testo per siti web',
                website_about: prompt.website_about.it,
                cover_letter: 'Lettera di presentazione',
                cover_letter_about: prompt.cover_letter_about.it,
                press: 'Comunicato stampa',
                press_about: prompt.press_about.it,
                job: 'Descrizione del lavoro',
                job_about: prompt.job_about.it,
                essay: 'Saggio',
                essay_about: prompt.essay_about.it,
            },
            chat: {
                tooltip: 'Aprire la chat IA',
                button: 'Chat IA',
                initial: {
                    greeting: 'Hey {{name}}, come posso aiutare?',
                    bullet1:
                        'Chatta con me per esplorare argomenti, rispondere a domande o creare contenuti dalle tue note di Saga.',
                    bullet2: 'Chiedi qualsiasi cosa e utilizzerò le tue note per rispondere.',
                    bullet3: 'Più aggiungi a Saga, più posso aiutarti!',
                },
                new_thread: 'Nuovo thread',
                history: 'Cronologia',
                no_threads: 'Non hai ancora nessun thread. Crea uno nuovo per iniziare.',
            },
        },
        errors: {
            cant_open_page: 'Sembra che stiamo avendo problemi ad aprire questa pagina in Saga.',
            something_went_wrong: 'Qualcosa è andato storto',
            something_went_wrong_description: 'Beh, questo non era previsto.\nPer favore, riprova.',
            something_went_wrong_try_again:
                '🤔 Qualcosa è andato storto, per favore riprova\nSe il problema persiste, contattaci',
            connection_lost_title: 'Connessione Persa',
            connection_lost_description:
                'La tua connessione è stata chiusa per inattività.\nPer favore ricarica Saga per riconnetterti.',
        },
        side_by_side: {
            open: 'Apri Affiancato',
            tooltip: 'Apri la pagina accanto alla pagina corrente',
            open_in_new_tab: 'Apri in una nuova scheda',
            copy_link: 'Copia collegamento negli appunti',
        },
        time: {
            years_ago: '{{years}} anni fa',
            months_ago: '{{months}} mesi fa',
            weeks_ago: '{{weeks}} settimane fa',
            days_ago: '{{days}} giorni fa',
            date: 'Data',
            today: 'Oggi',
            yesterday: 'Ieri',
            tomorrow: 'Domani',
            last_year: "L'anno scorso",
            last_week: 'La settimana scorsa',
            last_month: 'Il mese scorso',
            next_period: 'Prossimo {{label}} - {{date}}',
            last_period: 'Ultimo {{label}} - {{date}}',
        },
        tutorial: {
            finishButton: 'Inizia la tua Saga',
            writeWthEase: {
                title: 'Scrivi con facilità',
                description: "L'editor veloce e semplice di Saga ti aiuta a creare note e documenti belli.",
            },
            autolink_pages: {
                title: 'Collegamenti automatici alle pagine',
                description:
                    'Saga riconosce quando menzioni un titolo di pagina e crea automaticamente un collegamento.',
            },
            autocomplete_menu: {
                title: 'Menu Autocompletamento',
                description:
                    "<1>Inserisci blocchi e collega pagine con l'autocompletamento.</1><2>Usa @ o / per iniziare.</2>",
            },
            saga_ai: {
                title: 'Saga IA',
                description:
                    "L'assistente intelligente risponde a domande, genera contenuti e ti aiuta a lavorare più velocemente.",
            },
            unified_search: {
                title: 'Ricerca Unificata',
                description:
                    '<1>Cambia pagina o cerca nel tuo spazio di lavoro.</1><2>Premi {{searchHeading}} per provarlo.</2>',
            },
        },
        onboarding: {
            complete_signup: 'Completa la registrazione',
            start_your_saga: 'Iniziamo la tua Saga',
            i_agree_with_toc: 'Accetto la <1>Politica sulla Privacy</1> e i <2>Termini di Servizio</2> di Saga',
            create_first_workspace: 'Creiamo il tuo primo spazio di lavoro',
            create_workspace: 'Creiamo il tuo spazio di lavoro.',
            create_workspace_description:
                'Uno spazio di lavoro è dove le tue pagine, idee e conoscenze si connettono e si organizzano.',
            what_is_this_workspace_for: 'Per cosa è questo spazio di lavoro?',
            usecase_personalNotes: 'Note personali',
            usecase_teamWork: 'Lavoro di squadra',
            open_workspace: 'Apri Spazio di Lavoro',
            what_is_this_workspace: 'Per cosa è questo spazio di lavoro?',
            login: {
                title: 'Iniziamo la tua Saga',
                with_google: 'Continua con Google',
                with_email: 'Continua con Email',
                link_has_expired:
                    '🤔 Il tuo link è scaduto, o stai tentando di accedere da un dispositivo diverso.\n\nPer favore, accedi di nuovo 👇',
                toc: 'Cliccando "Continua con Google/Email" sopra, accetti la nostra <1>Politica sulla Privacy</1> e i <2>Termini di Servizio</2>.',
                info: 'Ti accederemo o creeremo un account se non ne hai già uno.',
                desktop_app_button: "Scarica l'app desktop",
            },
            magic_link_login: {
                title: '📨 Controlla la tua casella di posta',
                subtitle: 'Abbiamo appena inviato un link per accedere a questo indirizzo email:',
                enter_email: 'Inserisci il tuo indirizzo email...',
                subtitle_email_for_join:
                    'Abbiamo appena inviato un link per accedere a questo indirizzo email per unirti allo spazio di lavoro:',
                welcome_to: 'Benvenuto in {{-workspace}}',
                welcome_to_page: 'Benvenuto a {{-page}}',
                close_this_tab: 'Ora puoi chiudere questa scheda.',
                invite_for_join:
                    'Questo è un invito per <1>{{email}}</1>.\nAccedi con lo stesso indirizzo per accettarlo.',
                invite_not_active: 'Questo invito non è più attivo',
                request_new_invite_short:
                    'Contatta un proprietario di questo spazio di lavoro e richiedi un nuovo invito.',
                request_new_page_invite_short:
                    'Contatta un amministratore di questa pagina e richiedi un nuovo invito.',
                not_have_access: 'Non hai accesso a questo spazio di lavoro',
                not_have_page_access: 'Non hai accesso a questa pagina',
                logged_in_with_different_email:
                    "Sei connesso con <1>{{email}}</1>. <2>Accedi</2> con un'email diversa o contatta un proprietario di questo spazio di lavoro per richiedere l'accesso.",
                logged_in_with_different_email_page:
                    "Sei loggato con <1>{{email}}</1>. <2>Accedi</2> con un email diverso o contatta un amministratore di questa pagina per richiedere l'accesso.",
                request_new_invite:
                    'Contatta un proprietario di questo spazio di lavoro e richiedi un nuovo invito.\nTorna alla <1>Home Page</1> e crea il tuo spazio di lavoro.',
            },
        },
        help: {
            give_feedback: 'Dai un Feedback',
            give_feedback_tooltip:
                "🚀 Sei uno dei pionieri di Saga (iscritto il {{userCreatedDate}}). Stiamo lavorando duramente per spedire miglioramenti e nuove funzionalità alla velocità della luce, se hai feedback per noi, inviaci un'email a feedback@saga.so",
            contact_us: 'Contattaci',
            join_slack: 'Unisciti su Slack',
            join_discord: 'Unisciti su Discord',
            youtube_channel: 'Canale Youtube',
            tweet_us: 'Twittaci',
            open_website: 'Apri Sito Web',
            help_guide: 'Guida di Aiuto',
            start_tutorial: 'Inizia il tutorial',
        },
        top_menu: {
            search: 'Cerca...',
            searching: 'Cercando...',
            searching_for: 'Cercando:',
            search_dotted: 'Cerca...',
            search_shortcut: 'Cerca Ctrl/Cmd + K',
            add_page_to_favorites: 'Aggiungi pagina ai preferiti',
            add_collection_to_favorites: 'Aggiungi collezione ai preferiti',
            add_task_to_favorites: 'Aggiungi attività ai preferiti',
            add_task_view_to_favorites: 'Aggiungi vista attività ai preferiti',
            add_page_view_to_favorites: 'Aggiungi vista pagina ai preferiti',
            add_page_to_pinned: 'Aggiungi Pagina ai Preferiti',
            add_collection_to_pinned: 'Aggiungi Collezione ai Preferiti',
            add_task_to_pinned: 'Aggiungi Compito ai Preferiti',
            add_task_view_to_pinned: 'Aggiungi Vista Compito ai Preferiti',
            add_page_view_to_pinned: 'Aggiungi Vista Pagina ai Preferiti',
            search_for_or_create_new: 'Cerca o crea nuovo',
            search_for_collection_label: 'Seleziona o crea collezione',
            create_collection: '— Crea Collezione',
            create_page: '— Crea nuova pagina',
            close_search_results: 'Chiudi Risultati Ricerca',
            share: {
                label: 'Condividi',
                share_with: 'Condiviso con i membri dello spazio di lavoro',
                copy_link: 'Copia collegamento',
                copy_link_tooltip: 'Usando il collegamento pubblico, la pagina sarà accessibile a chiunque.',
                copy_public_link: 'Copia collegamento pubblico',
                copy_public_link_tooltip:
                    'Usando il collegamento privato, la pagina può essere accessa solo da te o altri membri dello spazio di lavoro.',
                link_copied: 'Collegamento Copiato!',
                members_count: 'membri di {{-workspaceTitle}}',
                share_count_zero: 'Condiviso solo con te',
                share_count_one: 'Condiviso solo con te',
                share_count_other: 'Condiviso con <1>{{membersCount}} persone</1>.',
                manage: 'Gestisci',
                manage_members: 'Gestisci membri',
                view_members: 'Visualizza membri',
                publish: 'Pubblica',
                page_not_published:
                    'Pubblica questa Pagina, poi chiunque con il collegamento può <1>visualizzarla</1>.',
                page_published: 'Chiunque con il collegamento può <1>visualizzare questa Pagina</1>.',
                workspace_not_published:
                    "Pubblica l'intero Spazio di Lavoro, poi chiunque può <1>visualizzare tutte le pagine</1> al suo interno",
                workspace_published:
                    "Chiunque con il collegamento può <1>visualizzare tutte le pagine</1> all'interno di questo Spazio di Lavoro.",
                copying_page: 'Permetti Copia della Pagina',
                members_one: '1 membro',
                members_other: '{{count}} membri',
                copying_page_title: 'Scegli uno spazio di lavoro',
                copying_page_sub_title: 'La pagina verrà duplicata in:',
                copying_page_toast_success: 'Pagina duplicata con successo!',
                copying_workspace: 'Permetti Copia dello Spazio di Lavoro',
                copying_workspace_toggle:
                    'Chiunque con il collegamento può copiare e duplicare questo spazio di lavoro.',
                copying_page_disabled_tooltip:
                    'Sei un visualizzatore in questo spazio di lavoro e non puoi copiare questa pagina.',
                invite_members: 'Invita nuovi membri',
                share_with_members: 'Condiviso con i membri dello spazio di lavoro',
                private_access: 'Accesso privato',
                members_with_access: 'Membri con accesso alla pagina',
                guests_with_access: 'Ospiti con accesso alla pagina',
                permission_admin_description: 'Può eliminare la pagina o gestire i permessi della pagina',
                permission_viewer_description: 'Può visualizzare il contenuto della pagina.',
                permission_editor_description: 'Può modificare ed eliminare il contenuto della pagina',
                invite_guest: 'Inserisci email per invitare un ospite...',
                type_email: 'Inserisci email...',
            },
        },
        move_page: {
            move_modal_title: 'Scegli uno spazio di lavoro',
            move_modal_sub_title: 'Sposta pagina in:',
            toast_success: 'Pagina spostata con successo!',
            confirm_modal_title:
                'Alcuni contenuti e impostazioni, come i collegamenti e i permessi, potrebbero non funzionare nella pagina spostata.',
            confirm_modal_content_left: "L'attività originale sarà archiviata senza modifiche.",
            confirm_modal_content_right: 'Verrà creato un duplicato nello spazio di lavoro selezionato.',
            confirm_changes: 'Conferma spostamento',
        },
        move_task: {
            move_modal_title: 'Scegli uno spazio di lavoro',
            move_modal_sub_title: 'Sposta attività in:',
            toast_success: 'Attività spostata con successo!',
            confirm_modal_title:
                "Alcuni contenuti e impostazioni, come collegamenti e permessi, potrebbero non funzionare nell'attività spostata.",
            confirm_modal_content_left: "L'attività originale sarà archiviata senza modifiche.",
            confirm_modal_content_right: 'Verrà creato un duplicato nello spazio di lavoro selezionato.',
            confirm_changes: 'Conferma spostamento',
        },

        sidebar: {
            new_page: 'Nuova pagina',
            create_page: 'Crea Pagina',
            create_collection: 'Crea Collezione',
            new_workspace: 'Nuovo Spazio di Lavoro',
            open_template_menu: 'Apri Menu Template',
            pages: 'Pagine',
            tasks: 'Compiti',
            settings: 'Impostazioni',
            pinned: 'Appuntato',
            favorites: 'Preferiti',
            logout: 'Esci',
            collections: 'Collezioni',
            shared: 'Condiviso con me',
            notifications: {
                dont_have_notifications: 'Non hai ancora notifiche.',
                accept: 'Accetta',
                manage: 'Gestisci',
                invite_message: '{{name}} ti ha invitato a unirti allo spazio di lavoro',
                user_joined: '{{name}} si è unito al tuo spazio di lavoro',
                user_left: '{{name}} ha lasciato il tuo spazio di lavoro',
                user_removed: '{{name}} ti ha rimosso dallo spazio di lavoro',
                go_to_workspace: 'Vai allo spazio di lavoro',
                go_to_page: 'Vai alla Pagina',
                go_to_task: 'Vai al Compito',
                load_more: 'Carica altro',
                mentioned_you: 'ti ha menzionato in',
                assigned_you: 'ti ha assegnato a',
                unassigned_you: 'ti ha disassegnato da',
                invite_page_message: 'ti ha invitato alla sua pagina',
            },
            upgrade_plan_modal: {
                title: 'Aggiorna il tuo piano',
                description:
                    'Per sbloccare spazi di lavoro illimitati e ottenere accesso a strumenti di collaborazione avanzati e funzionalità super aggiuntive che porteranno il tuo lavoro al livello successivo.',
            },
        },
        filters: {
            title: 'Titolo',
            az: 'A → Z',
            za: 'Z → A',
            created: 'Creato',
            updated: 'Aggiornato',
            creators: {
                all_creators: 'Tutti i creatori',
                no_creators: 'Nessun creatore',
                n_creators_other: '{{count}} creatori',
                n_creators_one: '{{count}} creatore',
                search_creator_placeholder: 'Cerca membri...',
            },
        },
        pages: {
            title_pages: 'Pagine',
            title_deleted_pages: 'Pagine Eliminate',
            title_deleted: 'Cancellato',
            title_templates: 'Template',
            title_shared: 'Condiviso',
            title_private: 'Privato',
            title_public: 'Pubblico',
            fewest_items_first: 'Meno elementi prima',
            most_items_first: 'Più elementi prima',
            collection: 'Collezione',
            fewest_words_first: 'Meno parole prima',
            most_words_first: 'Più parole prima',
            word_count: 'Conteggio parole',
            oldest_first: 'Più vecchi prima',
            newest_first: 'Più recenti prima',
            close_pages: 'Chiudi Pagine',
            empty_state_deleted: 'Non hai pagine eliminate.',
            empty_state_non_deleted: 'Aggiungi pagine per vederle qui.',
            empty_state_templates: 'Aggiungi template per vederli qui.',
            empty_state_shared: 'Non hai nessuna pagina condivisa con te.',
            empty_state_private: 'Non hai pagine private.',
            empty_state_public: 'Non hai pagine pubbliche.',
            enter_page_name: 'Inserisci il nome della pagina',
            favourite_untitled: 'Senza titolo',
            delete_all_forever: 'Elimina tutto per sempre',
            delete_all_page: 'Sei sicuro di voler eliminare tutte le pagine? Questa azione non può essere annullata.',
            delete_page_toast_title: 'La pagina è eliminata',
            delete_page_toast_description:
                'Le pagine eliminate sono archiviate fino a quando non vengono eliminate definitivamente.',
            history: {
                history: 'Storia',
                current: 'Attuale',
                versions_count: '({{number}} versioni)',
                versions: 'Versioni',
                upgrade_required: 'Aggiornamento richiesto',
                upgrade_modal: {
                    title: 'Aggiorna piano',
                    description:
                        'Passa a un piano a pagamento per accedere alla cronologia delle versioni più vecchie di 7 giorni e sbloccare altre funzionalità premium.',
                },
            },
            mode_dropdown: {
                pages: 'Pagine',
                templates: 'Template',
                deleted_pages: 'Pagine Eliminate',
            },
            new_page_placeholder: 'Nuova pagina',
            new: {
                create: 'Crea',
                label_page: 'Nuova Pagina',
                placeholder_page: 'Digita un nuovo titolo per la Pagina...',
                label_template: 'Nuovo Template',
                placeholder_template: 'Digita un nuovo titolo per il Template...',
            },
            headers: {
                title: 'Titolo',
                collection: 'Collezione',
                word_count: 'Conteggio Parole',
                created: 'Creato',
                updated: 'Aggiornato',
                shared_by: 'Condiviso da',
                role: 'Ruolo',
                creator: 'Creatore',
            },
            deleted_page: 'Questa pagina è stata eliminata',
            restore: 'Ripristina',
            restore_page: 'Ripristina pagina',
            delete_forever: 'Elimina per sempre',
        },
        references: {
            label: 'Riferimenti',
            tooltip: 'I riferimenti mostrano dove una pagina è stata menzionata in tutto il tuo spazio di lavoro.',
            result_one: '1 risultato',
            result_other: '{{count}} risultati',
            no_results: 'Nessun riferimento trovato per questa pagina.',
            no_results_search_panel: "Nessun risultato trovato. Prova con un'altra ricerca.",
        },
        table_of_content: {
            label: 'Indice',
        },
        properties: {
            label: 'Proprietà',
        },
        page_info: {
            label: 'Informazioni Pagina',
            creator: 'Creatore',
            created: 'Creato',
            updated: 'Aggiornato',
            word_count: 'Conteggio Parole',
            page_settings: 'Impostazioni Pagina',
            autolink_pages_label: 'Collega automaticamente le pagine',
            autolink_pages_description:
                'Collega automaticamente questa pagina quando il suo titolo viene menzionato in altre pagine',
            autolink_headings_label: 'Collega automaticamente le intestazioni',
            autolink_headings_description:
                'Collega automaticamente le intestazioni di questa pagina quando vengono menzionate in altre pagine',
            full_width_label: 'Pagina a piena larghezza',
            full_width_description: 'Mostra la pagina a piena larghezza.',
        },
        tasks: {
            title: 'Compiti',
            new_task: 'Nuovo Compito',
            new_task_placeholder: 'Inserisci un titolo per il compito',
            new_task_title_placeholder: 'Nuovo compito',
            new_task_editor_placeholder: 'Inserisci titolo del compito',
            createdAt: 'Creato',
            updatedAt: 'Aggiornato',
            completedDate: 'Completato',
            due_date: 'Data di Scadenza',
            no_due_date: 'Nessuna data di scadenza',
            sort_asc: 'Ascendente',
            sort_desc: 'Discendente',
            sorted_by: 'Ordinato per',
            task_title: 'Titolo del Compito',
            referenced: 'Menzionato in',
            assignee: 'Assegnatario',
            no_assignee: 'Nessun assegnatario',
            priority: 'Priorità',
            no_priority: 'Nessuna priorità',
            priority_all: 'Tutte le Priorità',
            priority_high: 'Alta',
            priority_medium: 'Media',
            priority_low: 'Bassa',
            priority_unprioritized: 'Senza priorità',
            add_assignee_placeholder: 'Inserisci un nome...',
            incomplete_tasks: 'Compiti Incompleti',
            incomplete_tasks_short: 'Incompleti',
            completed_tasks: 'Compiti Completati',
            completed_tasks_short: 'Completati',
            all_tasks: 'Tutti i Compiti',
            all_tasks_short: 'Tutti',
            all_assignees: 'Tutti gli Assegnatari',
            not_assigned: 'Non assegnato',
            close_task_one: 'Chiudi Compito',
            close_task_other: 'Chiudi Compiti',
            add_task_below: 'Aggiungi compito sotto',
            delete_task: 'Elimina Compito',
            confirm_delete_task:
                'Sei sicuro di voler eliminare questo compito? Sarà eliminato da tutto lo spazio di lavoro.',
            create_task_to_see_them: 'Crea Compiti per vederli qui.',
            status_open: 'Da Fare',
            status_in_progress: 'In Corso',
            status_done: 'Fatto',
            status: 'Stato',
            archived: 'Archiviato',
            deleted_task: 'Questo compito è eliminato',
            restore_task: 'Ripristina compito',
            delete_all_tasks: 'Sei sicuro di voler eliminare tutti i compiti? Questa azione non può essere annullata.',
            delete_task_toast_title: 'Il compito è eliminato',
            delete_task_toast_description:
                'I compiti eliminati sono archiviati fino a quando non vengono eliminati definitivamente.',
            new_label_placeholder: "Inserisci un nome per l'etichetta...",
            select_or_create_label: 'Crea nuova o seleziona',
            add_new_label: 'Aggiungi nuova etichetta',
            no_label: 'Nessuna etichetta',
            no_labels: 'Nessuna etichetta',
            n_labels_one: '{{count}} etichetta',
            n_labels_other: '{{count}} etichette',
            all_labels: 'Tutte le etichette',
            table_view: 'Vista Tabella',
            kanban_view: 'Vista Tavolo',
        },
        collections: {
            page_title: 'Collezioni',
            filter_placeholder: 'Filtra per parola chiave',
            add_to_collection: 'Aggiungi alla Collezione',
            add_page_to_collection: 'Aggiungi Pagina alla Collezione Interna',
            delete_collection: 'Elimina Collezione',
            add_parent_collection: 'Aggiungi collezione genitore',
            type_page_task_or_collection: 'Digita una Pagina/Compito o Collezione...',
            select_create_new: 'Seleziona o crea nuovo',
            new_collection: 'Nuova Collezione',
            new_collection_placeholder: 'Digita una nuova Collezione...',
            empty_state_message: 'Crea Collezioni per vederle qui.',
            sort: 'Ordina',
            folder_view: 'Vista Cartella',
            tag_view: 'Vista Tag',
            pages: 'Pagine',
            sub_collections: 'Sotto Collezioni',
            oldest_first: 'I più vecchi prima',
            newest_first: 'I più recenti prima',
            fewest_first: 'Con meno pagine prima',
            most_first: 'Con più pagine prima',
            sub_fewest_first: 'Con meno prima',
            sub_most_first: 'Con più prima',
            close_collections: 'Chiudi Collezioni',
            empty_state: 'Aggiungi Pagine o Collezioni a questa Collezione per vederle qui.',
        },
        views: {
            save_view: 'Salva',
            save_view_name_placeholder: 'Nome vista ...',
            create_new_button: 'Crea nuova vista',
            update_button: 'Aggiorna vista',
            delete_view: 'Elimina vista',
        },
        settings: {
            sidebar: {
                account: 'Account',
                title: 'Impostazioni',
                profile: 'Profilo',
                language: 'Lingua',
                appearance: 'Aspetto',
                editor: 'Editor',
                invites: 'Inviti',
                integrations: 'Integrazioni',
                general: 'Generale',
                members: 'Membri',
                plans: 'Piani',
                billing: 'Fatturazione',
                import_export: 'Importa & Esporta',
            },
            profile: {
                title: 'Profilo',
                sub_title: 'Gestisci il tuo profilo Saga',
                your_email: 'La tua Email',
            },
            language: {
                title: 'Lingua',
                sub_title: "Cambia la lingua utilizzata nell'interfaccia utente.",
                language: 'Lingua',
                language_change_title: 'Aggiorna lingua',
                language_change_confirmation: 'Sei sicuro di voler aggiornare la lingua? Saga verrà ricaricato!',
                language_changed_notification: 'Lingua aggiornata',
            },
            appearance: {
                title: 'Aspetto',
                sub_title: "Personalizza l'aspetto di Saga.",
                theme: 'Tema',
                show_icons: 'Mostra Icone nei Link delle Pagine Inline',
                mode_auto: 'Modalità Auto',
                mode_light: 'Modalità Chiara',
                mode_dark: 'Modalità Scura',
                start_week_on_monday: 'Inizia la settimana di Lunedì',
                font_default: 'Predefinito',
                interface_font: "Font dell'Interfaccia",
                text_font: 'Font del Testo',
                example_text: 'Testo di esempio per testare il tuo font',
            },
            editor: {
                title: 'Editor',
                sub_title: 'Personalizza la tua esperienza di editing.',
                spellcheck: 'Controllo ortografico',
            },
            invite: {
                title: 'Inviti Workspace',
                sub_title: 'Visualizza e accetta tutti i tuoi inviti workspace',
                no_invites: 'Non hai inviti workspace al momento.',
                invite_members_to: 'Invita membri',
                max_invites_reached:
                    '<bold>{{maxInvitedMembers}}/{{totalMembers}}</bold> inviti gratuiti rimanenti. <upgrade>Aggiorna</upgrade> il piano per inviti illimitati',
                accept_invite: 'Accetta Invito',
                invite_accepted: 'Invito Accettato',
                owned_by: 'di proprietà di <1>{{ownerEmail}}</1>',
            },
            page_invite: {
                title: 'Inviti alla pagina',
                sub_title: 'Visualizza e accetta tutti i tuoi inviti alla pagina',
                no_invites: 'Non hai inviti alla pagina al momento.',
            },
            integrations: {
                title: 'Integrazioni',
                sub_title: 'Integra altri strumenti con Saga',
                remove_integration: 'Sei sicuro di voler rimuovere questa integrazione?',
                google_drive: {
                    title: 'Google Drive',
                    description: 'Cerca nel tuo drive e menziona file in pagine e compiti. <1>Scopri di più</1>',
                    button_connect: 'Connetti a Google Drive',
                    button_disconnect: 'Rimuovi Integrazione',
                    last_updated: 'Ultimo aggiornamento {{date}}',
                },
                linear: {
                    title: 'Linear',
                    description: 'Cerca Linear e menziona problemi dal tuo workspace Linear. <1>Scopri di più</1>',
                    button_connect: 'Connetti a Linear',
                    button_disconnect: 'Rimuovi Integrazione',
                },
            },
            general: {
                title: 'Generale',
                sub_title: 'Gestisci le impostazioni generali del tuo workspace',
                workspace_name: 'Nome Workspace',
                icon: 'Icona',
                icon_description: "Cambia il colore dell'icona del workspace.",
                embeddings: 'Incorporazioni Workspace',
                embeddings_description: 'Genera incorporazioni per questo Workspace.',
                copy_workspace: 'Copia Workspace',
                copy_workspace_description:
                    'Tutte le pagine, collezioni e impostazioni di questo Workspace saranno copiate in un nuovo Workspace.',
                delete_workspace: 'Elimina Workspace',
                delete_workspace_description:
                    'Eliminare un Workspace cancellerà tutti i suoi dati. Questa azione non può essere annullata.',
                delete_workspace_confirmation: 'Sei sicuro di voler eliminare questo Workspace?',
                leave_workspace: 'Lascia Workspace',
                leave_workspace_confirmation: 'Sei sicuro di voler lasciare questo Workspace?',
                autolink: 'Collegamento Automatico',
                autolink_description:
                    "Quando è attivo, Saga crea automaticamente collegamenti ai titoli delle pagine all'interno delle pagine e dei compiti.",
            },
            members: {
                title: 'Membri',
                sub_title: 'Invita altri in questo Workspace',
                add_member: 'Aggiungi un Membro',
                remove_member: 'Rimuovi Membro',
                remove_member_confirmation: 'Sei sicuro di voler rimuovere {{name}}?',
                remove_confirm: 'Conferma rimozione',
                remove_member_toast_success: 'Membro rimosso',
                pending_invite: 'Invito in attesa',
                revoke_invite: 'Revoca Invito',
                revoke_invite_confirmation: "Sei sicuro di voler revocare l'invito?",
                revoke_confirm: 'Conferma revoca',
                revoke_invite_toast_success: 'Invito revocato',
                paid_feature: 'Funzionalità a pagamento',
                role_admin: 'Admin',
                role_page_admin: 'Amministratore della pagina',
                role_no_access: 'Nessun accesso',
                role_admin_tooltip:
                    'Questo membro può <1>creare, modificare ed eliminare contenuti</1> nel workspace e <1>invitare altri</1>. Possono anche <1>gestire le impostazioni</1> e <1>eliminare il workspace</1>.',
                role_editor: 'Editor',
                role_editor_tooltip:
                    'Questo membro può <1>creare, modificare ed eliminare contenuti</1> nel workspace.',
                role_viewer: 'Visualizzatore',
                role_viewer_tooltip: 'Questo membro può <1>visualizzare contenuti</1> nel workspace.',
                role_member: 'Membro',
                role_member_tooltip:
                    'Questo membro può <1>creare, modificare ed eliminare contenuti</1> nello spazio di lavoro e <1>invitare altri. Possono anche rendere pubbliche le pagine/gli spazi di lavoro</1> nello spazio di lavoro.',
                role_blocked_tooltip: 'Questo membro non ha accesso al workspace',
                invite_members: 'Invita Membri a {{-workspaceName}}',
                plus_add_more: '+ Aggiungi altri',
                send_invites: 'Invia inviti',
                invites_sent: 'Inviti inviati',
                upgrade_plan: 'Aggiorna il tuo piano',
                upgrade_plan_details:
                    'Hai raggiunto il limite di {{count}} utenti gratuiti. Aggiorna il tuo piano per invitare più utenti e sbloccare funzionalità aggiuntive per migliorare.',
            },
            plans: {
                title: 'Piani Workspace',
                loading: 'Caricamento ...',
                sub_title_free:
                    'Usa Saga gratuitamente, <bold>aggiorna per una maggiore collaborazione</bold>, <bold>gestione dei compiti</bold>, e <bold>Saga IA illimitato</bold>.',
                sub_title_monthly_one:
                    'Il tuo abbonamento attuale è il piano Mensile Standard. Il tuo workspace di <bold>{{membersCount}} membro</bold> costa <bold>{{amount}}</bold> al mese, e si rinnoverà il <bold>{{date}}</bold>.',
                sub_title_monthly_other:
                    'Il tuo abbonamento attuale è il piano Mensile Standard. Il tuo workspace di <bold>{{membersCount}} membri</bold> costa <bold>{{amount}}</bold> al mese, e si rinnoverà il <bold>{{date}}</bold>.',
                sub_title_yearly_one:
                    "Il tuo abbonamento attuale è il piano Annuale Standard. Il tuo workspace di <bold>{{membersCount}} membro</bold> costa <bold>{{amount_month}}</bold> al mese / <bold>{{amount_year}}</bold> all'anno, e si rinnoverà il <bold>{{date}}</bold>.",
                sub_title_yearly_other:
                    "Il tuo abbonamento attuale è il piano Annuale Standard. Il tuo workspace di <bold>{{membersCount}} membri</bold> costa <bold>{{amount_month}}</bold> al mese / <bold>{{amount_year}}</bold> all'anno, e si rinnoverà il <bold>{{date}}</bold>.",
                sub_title_monthly_canceled_one:
                    'Il piano del tuo workspace di <bold>{{membersCount}} membro</bold> costa <bold>{{amount}}</bold> al mese. Hai cancellato questo piano, ma rimarrà attivo fino al <bold>{{date}}</bold>. Dopo di che, passerai a un piano Gratuito.',
                sub_title_monthly_canceled_other:
                    'Il piano del tuo workspace di <bold>{{membersCount}} membri</bold> costa <bold>{{amount}}</bold> al mese. Hai cancellato questo piano, ma rimarrà attivo fino al <bold>{{date}}</bold>. Dopo di che, passerai a un piano Gratuito.',
                sub_title_yearly_canceled_one:
                    "Il piano del tuo workspace di <bold>{{membersCount}} membro</bold> costa <bold>{{amount_month}}</bold> al mese o <bold>{{amount_year}}</bold> all'anno. Hai cancellato questo piano, ma rimarrà attivo fino al <bold>{{date}}</bold>. Dopo di che, passerai a un piano Gratuito.",
                sub_title_yearly_canceled_other:
                    "Il piano del tuo workspace di <bold>{{membersCount}} membri</bold> costa <bold>{{amount_month}}</bold> al mese o <bold>{{amount_year}}</bold> all'anno. Hai cancellato questo piano, ma rimarrà attivo fino al <bold>{{date}}</bold>. Dopo di che, passerai a un piano Gratuito.",
            },
            billing: {
                title: 'Fatturazione',
                sub_title: 'Gestisci le tue informazioni di fatturazione e le fatture.',
                per_month: 'per membro del workspace / mese',
                free_plan: 'Piano gratuito',
                standard_annual_plan: 'Piano annuale standard',
                standard_monthly_plan: 'Piano mensile standard',
                billing_portal_label: 'Vedi e modifica i dettagli di fatturazione',
                invoices: {
                    title: 'Fatture',
                    loading: 'Caricamento delle fatture...',
                    no_invoices: 'Non ci sono fatture da mostrare.',
                    no_upcoming_invoices: 'Nessuna fattura imminente',
                    next_billing_on_one:
                        'Sarai fatturato <1>${{amount}}</1> il <1>{{date}}</1> per <1>{{billableMembersCount}} membro</1>',
                    next_billing_on_other:
                        'Sarai fatturato <1>${{amount}}</1> il <1>{{date}}</1> per <1>{{billableMembersCount}} membri</1>',
                    see_invoice: 'Vedi fattura',
                    invoice_not_ready: 'Fattura non pronta',
                    paid: 'Pagato',
                    not_paid: 'Non pagato',
                },
            },
            export_import: {
                title: 'Importa & Esporta',
                sub_title: 'Importa ed esporta spazio di lavoro e gestisci le impostazioni di esportazione.',
                import_workspace: 'Importa dati',
                import_workspace_description:
                    'Importa pagine, attività o spazio di lavoro Saga da file markdown, testo o JSON.',
                import_workspace_cta: 'Importa spazio di lavoro',
                export_workspace: 'Esporta spazio di lavoro',
                export_workspace_description: 'Esporta spazio di lavoro come file JSON.',
                export_workspace_cta: 'Esporta spazio di lavoro',
                import_modal: {
                    title: 'Rilascia i tuoi file per importare',
                    upload_button: 'Carica dal tuo computer',
                    max_file_size: 'max. {{size}} MB per file',
                    supported_file_types: 'Supportato: Markdown, JSON',
                    unexpected_error_message:
                        'Si è verificato un errore imprevisto.Per favore riprova o contattaci a <email/>',
                    unsupported_error_title: 'Formato di file non supportato',
                    unsupported_error_description:
                        'Si prega di trascinare e rilasciare cartelle, file Markdown o JSON.',
                    successfuly_imported_files_one: 'Importato con successo {{count}} file',
                    successfuly_imported_files_other: 'Importato con successo {{count}} file',
                    confirm_title: 'Importa file come',
                    add_to_collection: 'Aggiungi alla collezione (opzionale)',
                    confirm_button: 'Importa',
                    page: 'Pagine',
                    task: 'Attività',
                },
            },
            ai: {
                title: 'Saga IA',
                sub_title:
                    "Sii creativo, scrivi più velocemente e lavora meglio direttamente in Saga con l'aiuto di un assistente digitale AI.",
                confirm_delete_command: 'Vuoi eliminare questo comando?',
                confirm_delete_prompt: 'Vuoi eliminare questo prompt?',

                commands: {
                    title: 'Comandi',
                    add_command: 'Aggiungi Comando',
                    customize_prompts: 'Personalizza i tuoi prompt e comandi AI. <1>Scopri come funziona</1>.',
                    add_prompt: 'Aggiungi Prompt',
                },
                new: {
                    title_create_prompt: 'Crea un prompt personalizzato',
                    title_edit_prompt: 'Modifica il tuo prompt',
                    title_create_command: 'Crea un comando personalizzato',
                    title_edit_command: 'Modifica il tuo comando',
                    custom_command_tooltip: 'Il testo selezionato verrà posizionato alla fine del tuo prompt.',
                    command_name_placeholder: 'Nome comando',
                    prompt_name_placeholder: 'Nome prompt',
                    command_placeholder: 'Scrivi qui il tuo comando...',
                    prompt_placeholder: 'Scrivi qui il tuo prompt...',
                },
            },
            labels: {
                title: 'Etichette',
                sub_title: 'Aggiungi e modifica le tue etichette',
                add_label: 'Aggiungi etichetta',
                name: 'Nome',
                usage: 'Utilizzo',
                options: 'Opzioni',
                edit_modal: {
                    title_create: 'Crea etichetta',
                    title_edit: 'Modifica etichetta',
                    select_color: "Seleziona un colore per l'etichetta",
                    label_name: "Nome dell'etichetta",
                },
                delete_label_confirmation_title: 'Elimina l\'etichetta "{{title}}"',
                delete_label_confirmation_description:
                    "L'etichetta verrà rimossa da {{count}} attività.\n\nQuesta azione non può essere annullata.",
            },
        },
        billing: {
            free: 'Gratuito',
            free_title: 'Piano Gratuito',
            free_description: 'Per uso personale e piccoli team',
            standard_title: 'Piano Standard',
            standard_description: 'Ideale per team di medie dimensioni',
            business_title: 'Piano Business',
            business_description: 'Ideale per grandi organizzazioni',
            monthly: 'Mensile',
            annually: 'Annuale',
            downgrade: 'Retrocedi',
            upgrade_plan: 'Aggiorna piano',
            current_plan: 'Piano attuale',
            planned: 'Pianificato',
            join_waitlist: "Unisciti alla lista d'attesa",
            per_editor_month: 'per membro / mese',
            per_space_editor_month: 'per membro dello spazio di lavoro / mese',
            desktopModal: {
                title: 'Cambia Piano di Fatturazione',
                description:
                    'Cambiare il tuo piano di fatturazione richiede un browser web. Procedendo, si aprirà una nuova scheda del browser che ti reindirizzerà alla pagina di fatturazione della nostra applicazione. Si prega di completare le modifiche di fatturazione nel browser.',
                cta: 'Apri nel browser',
            },
            change_plan_modal_title: "Sei sicuro di voler cambiare l'intervallo di fatturazione?",
            plan_upgrade_success_toast: {
                title: 'Il piano è stato aggiornato',
                description: 'Ora hai accesso illimitato a tutte le super funzionalità di Saga!',
            },
            downgrade_toast: {
                title: 'La tua richiesta di retrocessione del piano è stata accettata',
                description: "Potrai utilizzare tutte le funzionalità a pagamento fino alla scadenza dell'abbonamento.",
            },
            upgrade_to_yearly_toast: {
                title: 'Intervallo di fatturazione cambiato',
            },
            change_plan_modal_description_one:
                'La fatturazione del tuo account passerà da mensile ad annuale immediatamente, i crediti del tuo piano attuale verranno trasferiti al nuovo piano e ti verrà addebitato <bold>{{price}}</bold> per <bold>un</bold> utente.',
            change_plan_modal_description_other:
                'La fatturazione del tuo account passerà da mensile ad annuale immediatamente, i crediti del tuo piano attuale verranno trasferiti al nuovo piano e ti verrà addebitato <bold>{{price}}</bold> per <bold>{{members}}</bold> utenti.',
            free_plan_info_banner: {
                title: 'Siamo felici di vederti collaborare!',
                description:
                    'Sei sul piano gratuito che include <bold>{{count}} membri</bold> in questo spazio di lavoro. <1>Scopri di più</1>',
            },
            upgrade_plan_banner: {
                title: 'Gestore di compiti',
                description:
                    'Ottieni una panoramica di tutti i tuoi compiti su tutte le tue pagine. Cerca e ordina, trova i compiti di cui hai bisogno e completali.',
                primary_button: 'Aggiorna piano',
                primary_button_default: 'Visualizza piani',
                no_access_desciption: 'Si prega di contattare il proprietario dello spazio di lavoro.',
            },
            feedback_modal: {
                title: 'Ci dispiace vederti retrocedere :(',
                description:
                    "Abbiamo creato Saga per aiutarti a organizzare la tua conoscenza. Ci piacerebbe davvero sapere se c'è qualcosa che ci manca o che potremmo fare meglio. I tuoi pensieri sarebbero molto utili per noi.",
                placeholder:
                    'Per favore condividi perché stai retrocedendo. Faremo del nostro meglio per migliorare Saga per te...',
                primary_button: 'Invia feedback',
            },
            downgrade_to_free_modal: {
                title: 'Sei sicuro di voler retrocedere il piano a Gratuito?',
                description:
                    "Il tuo spazio di lavoro ha <span class='font-bold'>{{membersCount}} $t(billing.downgrade_to_free_modal.member, {\"count\": {{membersCount}} })</span>. Sul piano gratuito puoi avere <span class='font-bold'>fino a {{allowedMembers}}</span>. Alla fine dell'abbonamento, <span class='font-bold'>{{membersLooseAccess}} $t(billing.downgrade_to_free_modal.member, {\"count\": {{membersLooseAccess}} }) perderanno l'accesso</span> allo spazio di lavoro, e i rimanenti <span class='font-bold'>{{allowedMembers}} diventeranno $t(billing.downgrade_to_free_modal.admin, {\"count\": {{allowedMembers}} }).</span>",
                member: 'membro',
                member_other: 'membri',
                admin: 'amministratore',
                admin_other: 'amministratori',
                features_title: 'Perderai queste super funzionalità:',
                feature_1: 'Saga IA illimitato con GPT-4',
                feature_2: 'Gestore di compiti',
                feature_3: 'Permessi utente',
                feature_4: 'Membri dello spazio di lavoro illimitati',
                primary_button: 'Mantieni piano',
                secondary_button: 'Retrocedi',
            },
            ai_upgrade_plan_banner: {
                description: 'Le tue richieste gratuite di AI sono state utilizzate',
                primary_action: 'Aggiorna piano',
            },
            ai_popover_modal_upgrade: {
                title: 'Aggiorna il tuo piano',
                description:
                    'Le tue richieste gratuite di AI sono state utilizzate. Aggiorna per risposte AI illimitate e funzionalità aggiuntive per aumentare la tua produttività.',
            },
            modal_plans: {
                title: 'Seleziona il piano dello spazio di lavoro',
                sub_title:
                    '<bold>Hai raggiunto il limite di {{count}} spazi di lavoro gratuiti</bold>. Aggiorna per accedere a funzionalità super aggiuntive che porteranno il tuo lavoro al livello successivo.',
                loading_plans: 'Caricamento dei piani...',
                footer_disclaimer:
                    "Tutti i prezzi sono in USD e addebitati per spazio di lavoro. L'IVA può essere applicata.",
                fair_usage: 'uso equo',
                sagaAi_disclaimer: '*Saga AI soggetto a <fairUsageLink/>.',
                footer_contacts: 'Hai domande sulla fatturazione? Chiedi a noi <link/>.',
            },
            plan_features: {
                unlimited_pages_tasks: 'Pagine & compiti illimitati',
                limited_members: '<bold>Fino a {{count}}</bold> utenti / spazio di lavoro',
                unlimited_members: '<bold>Membri dello spazio di lavoro illimitati</bold>',
                limited_saga_requests: 'Saga IA con <bold>{{count}} parole</bold> / mese',
                unlimited_saga_requests: '<bold>Saga IA illimitato</bold> con GPT-4',
                limited_integrations: '<bold>{{count}}</bold> integrazione: <google /> o <linear />',
                unlimited_integrations: '<google /> e <linear /> integrazioni',
                tooltip_integrations: {
                    google_drive: 'Apri, cerca e menziona file di Google Drive direttamente in Saga',
                    linear: 'Menziona, cerca o incolla collegamenti live a problemi Linear direttamente in Saga.',
                },
                apps: 'Mac e Windows',
                tooltip_sagaai: "Saga AI è l'assistente intelligente all'interno del tuo spazio di lavoro.",
                tooltip_apps: {
                    content: "Scarica le app desktop di Saga per un'esperienza ancora migliore.",
                    apps: 'app',
                },
                task_manager: 'Gestore di compiti',
                tooltip_task_manager: 'Gestisci tutte le tue attività in un unico posto.',
                user_permissions: 'Permessi utente',
                advanced_user_permissions: 'Permessi utente avanzati',
                search_with_ai: 'Usa <sagaAi /> con strumenti e il web',
                version_history: 'Cronologia delle versioni',
                version_history_free: 'Cronologia delle versioni di 7 giorni',
                version_history_standard: 'Cronologia delle versioni di 30 giorni',
                version_history_business: 'Cronologia delle versioni illimitata',
                offline_mode: 'Modalità offline',
                business_features_title: 'Tutto in Standard più:',
                free_limitations: '*Fino a {{count}} spazi di lavoro gratuiti per account',
                public_access: 'Pagine e spazi di lavoro pubblici',
                custom_ai_commands: 'Comandi personalizzati <sagaAi />',
                ai_talk_to_your_page: '<sagaAi /> parla con le tue pagine',
                limited_upload_file: '{{count}} MB dimensione massima del file',
                limited_upload_total_mb: '{{count}} MB archiviazione file',
                limited_upload_total_gb: '{{count}} GB archiviazione file',
                limited_guests: '{{count}} ospiti',
            },
            choose_workspace_modal: {
                title: 'Aggiornamento necessario',
                description:
                    "Hai raggiunto il numero massimo di spazi di lavoro gratuiti. Per accettare l'invito a unirti al nuovo spazio di lavoro, aggiorna uno dei tuoi spazi di lavoro gratuiti esistenti.",
                go_to_plans: 'Vai ai piani',
            },
        },
        table: {
            move_left: 'Sposta a Sinistra',
            move_right: 'Sposta a Destra',
            insert_column_left: 'Inserisci Colonna a Sinistra',
            insert_column_right: 'Inserisci Colonna a Destra',
            remove_column: 'Rimuovi colonna',
            append_column: 'Aggiungi colonna',
            append_row: 'Aggiungi riga',
            remove_row: 'Rimuovi riga',
        },
    },
};
