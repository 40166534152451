import { assertYArray } from '../yjs-utils';
import findCollectionById from './findCollectionById';
import transactInSpace from './transactInSpace';
import { SafeSpace } from '../types';

export default function removeSubCollectionById(space: SafeSpace, collectionId: string, subCollectionId: string) {
    transactInSpace(space, () => {
        const collection = findCollectionById(space, collectionId);
        const subCollections = collection.get('subCollections');
        assertYArray(subCollections);
        let foundIndex = -1;

        subCollections.forEach((id, i) => {
            if (id === subCollectionId) {
                foundIndex = i;
            }
        });

        if (foundIndex >= 0) {
            subCollections.delete(foundIndex);
        } else {
            throw new Error(`SubCollection with id ${subCollectionId} was not found`);
        }
    });
}
