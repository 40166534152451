import { PageView, WeakPage } from '../types';
import { ReadonlyNonEmptyArray } from 'fp-ts/lib/ReadonlyNonEmptyArray';
import { unsafeRight } from '../io-ts-js';
import { pipe } from 'fp-ts/function';
import { filterRights } from '../utils';
import * as A from 'fp-ts/Array';
import * as E from 'fp-ts/Either';
import { SafeSpace } from '../types';
import { decodeKeysFromSafePage } from './utils';

const getPages = <K extends keyof WeakPage>(
    space: SafeSpace,
    keys?: ReadonlyNonEmptyArray<K>,
    pageMode: PageView['mode'] = 'non-deleted',
    filter: (pageId: string) => boolean = () => true,
): Pick<WeakPage, K>[] => {
    const safePages = unsafeRight(space.get('pages'));
    return pipe(
        safePages.toArray(),
        filterRights,
        A.filter((safeMapOutput) => filter(safeMapOutput.map.get('id') as string)),
        A.filter((safeMapOutput) => {
            if (pageMode === 'all') return true;

            const isTemplate = E.fold(
                () => false,
                (isTemplate) => Boolean(isTemplate),
            )(safeMapOutput.getDecoded('isTemplate'));

            const isArchived = E.fold(
                () => false,
                (archivedAt) => archivedAt != null,
            )(safeMapOutput.getDecoded('archivedAt'));

            if (pageMode === 'templates') {
                return isTemplate && !isArchived;
            }

            return pageMode == 'deleted' ? isArchived : !isArchived && !isTemplate;
        }),
        A.map((safeMapOutput) => {
            if (keys) {
                return decodeKeysFromSafePage(safeMapOutput, keys);
            }

            return safeMapOutput.decode();
        }),
        filterRights,
    );
};

export default getPages;
