import { TaskView, SafeSpace } from '../types';
import transactInSpace from './transactInSpace';
import * as Y from 'yjs';

export default function editTaskView(space: SafeSpace, id: string, view: Partial<TaskView>) {
    transactInSpace(space, () => {
        const taskViews = space.map.get('taskViews') as Y.Array<Y.Map<unknown>> | undefined;

        if (!taskViews) return;

        const yTaskViewIndex = taskViews.toArray().findIndex((taskView) => taskView.get('id') === id);

        if (yTaskViewIndex === -1) return;
        const yTaskView = taskViews.get(yTaskViewIndex);

        const keys = Object.keys(view);

        keys.forEach((key) => {
            // @ts-expect-error
            const value = view[key];
            yTaskView.set(key, value);
        });
    });
}
