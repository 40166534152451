import React, { createContext, useState, useContext, ReactNode, useMemo } from 'react';
import { isFeatureEnabled } from '@/analytics';
import Modal from '@/components/Modal';
import { UpgradePlanBannerBig } from '@/components/billing/UpgradePlanBannerBig';
import { SagaAIStatus } from '@/../../shared/src';
import { FeatureFlag } from '@/../../shared/src/utils/FeatureFlags';
import { useSpacePermissions } from '@/components/SpacePermissionsProvider';
import { useTranslation } from 'react-i18next';

const AIPermissionsContext = createContext<{
    aiStatus: SagaAIStatus;
    setShowUpgradePlanModal: (isOpen: boolean) => void;
}>({
    aiStatus: 'disabled',
    setShowUpgradePlanModal: () => {},
});

export default function AIPermissionsProvider({ children }: { children: ReactNode }) {
    const [showUpgradePlanModal, setShowUpgradePlanModal] = useState(false);
    const { spacePermissions } = useSpacePermissions();
    const { t } = useTranslation();

    const aiStatus: SagaAIStatus = useMemo(() => {
        return isFeatureEnabled(FeatureFlag.allowBilling) ? (spacePermissions.sagaAiStatus as SagaAIStatus) : 'enabled';
    }, [spacePermissions.sagaAiStatus]);

    return (
        <AIPermissionsContext.Provider value={{ aiStatus, setShowUpgradePlanModal }}>
            {children}

            <Modal.Medium isOpen={showUpgradePlanModal}>
                <UpgradePlanBannerBig
                    title={t('billing.ai_popover_modal_upgrade.title')}
                    description={t('billing.ai_popover_modal_upgrade.description')}
                    onClose={() => setShowUpgradePlanModal(false)}
                    trackingSource="ai-popover"
                />
            </Modal.Medium>
        </AIPermissionsContext.Provider>
    );
}

export function useAIEnabled() {
    const { aiStatus, setShowUpgradePlanModal } = useContext(AIPermissionsContext);
    return {
        aiStatus,
        setShowUpgradePlanModal,
    };
}
