import { SpaceOperations } from '@saga/shared';
import isHotkey from 'is-hotkey';
import React from 'react';
import { useSpace } from '../SpaceProvider';
import { useTaskMap } from './hooks';

const TICK_CHECKBOX = 'mod+enter';

export default function useCheckTaskShortcut({ active, taskId }: { taskId: string; active?: boolean }) {
    const { space } = useSpace();
    const taskMap = useTaskMap(taskId);

    React.useEffect(() => {
        if (active) {
            function onKeyDown(event: KeyboardEvent) {
                if (isHotkey(TICK_CHECKBOX, event) && taskMap != null) {
                    event.preventDefault();
                    event.stopPropagation();
                    const state = taskMap.get('state');

                    if (state === 'OPEN') {
                        SpaceOperations.updatePartialTask(space, taskId, { state: 'DONE' });
                    } else {
                        SpaceOperations.updatePartialTask(space, taskId, { state: 'OPEN' });
                    }
                }
            }

            window.addEventListener('keydown', onKeyDown);

            return () => {
                window.removeEventListener('keydown', onKeyDown);
            };
        }

        return;
    }, [space, active, taskMap, taskId]);
}
