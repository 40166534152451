import { BACKEND_URL } from '@/constants';
import { DocumentName, Page, Task, assertNonNull } from '@saga/shared';

function isWeakTask(entity: Pick<Task, 'id' | 'title' | 'state'> | Pick<Page, 'id' | 'title'>): entity is Task {
    return 'state' in entity;
}

export async function exportEntity(
    entity: Pick<Task, 'id' | 'title' | 'state'> | Pick<Page, 'id' | 'title'>,
    documentName: string,
    spaceId: string,
    token: string,
) {
    await downloadZip({
        url: `${BACKEND_URL}export-zip`,
        filename: `saga-${(entity.title && entity.title.trim()) || entity.id}.zip`,
        body: JSON.stringify({
            documentName,
            entityId: entity.id,
            token,
            spaceId,
            type: isWeakTask(entity) ? 'task' : 'page',
        }),
    });
}

export async function exportWorkspace(documentName: string, token: string) {
    const { id: urlKey } = DocumentName.parse(documentName);

    await downloadZip({
        url: `${BACKEND_URL}export-workspace-zip`,
        filename: `${urlKey}.zip`,
        body: JSON.stringify({ documentName, token }),
    });
}

async function downloadZip({ url, filename, body }: { url: string; filename: string; body: string }) {
    const csrfToken = document.head.querySelector('meta[name="x-csrf-token"]')?.getAttribute('content');
    assertNonNull(csrfToken, 'CSRF Token not found');

    const response = await fetch(url, {
        method: 'POST',
        headers: { 'Content-type': 'application/json', 'x-csrf-token': csrfToken },
        credentials: 'include',
        body,
        cache: 'no-cache',
    });
    const result = await response.json();

    if (response.status === 200 && result.url != null) {
        fetch(result.url, { cache: 'no-cache' }).then(async (response_1) => {
            const url = URL.createObjectURL(await response_1.blob());
            const a = document.createElement('a');
            a.href = url;
            a.download = filename;
            const clickHandler = () => {
                setTimeout(() => {
                    // Clears up the reference to the file
                    // check https://developer.mozilla.org/en-US/docs/Web/API/URL/revokeObjectURL
                    URL.revokeObjectURL(url);
                    a.removeEventListener('click', clickHandler);
                }, 150);
            };
            a.addEventListener('click', clickHandler);
            a.click();
        });
    }
}
