import React from 'react';
import { isSagaText, SagaElement, SagaLocation, SagaText } from '@saga/shared';
import { NodeEntry } from 'slate';
import { useAutolinkIndex } from '@/components/AutolinkIndex';
import { mapAutolinkResultsToDecorations } from './useDecorator';
import { HighlightTargetType } from '@/types';

export default function useStaticDecorate(location?: SagaLocation.SagaLocation) {
    const { find } = useAutolinkIndex();

    const staticDecorate = React.useCallback(
        ([node, path]: NodeEntry<SagaElement | SagaText>) => {
            if (isSagaText(node)) {
                const autolinkResults = find(node.text);
                const decorations = mapAutolinkResultsToDecorations(autolinkResults, path).filter(
                    ({ decoration }) =>
                        decoration.referenceTargetType !== HighlightTargetType.TITLE ||
                        (location && !SagaLocation.areLocationsEqual(location, decoration.location)),
                );

                return decorations;
            }

            return [];
        },
        [find, location],
    );

    return staticDecorate;
}
