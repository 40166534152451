import {
    assertNonNull,
    BlockBuilder,
    EditorOperations,
    isTable,
    isTableCell,
    isTableRow,
    Table,
    TableCell,
    TableRow,
} from '..';
import { BasePoint, Editor, Node, NodeEntry, Path, Range, Transforms } from 'slate';
import { isNodeEntry } from '../types';
import { toNodes } from './NodeEntry';

function makeColumnPaths([table, tablePath]: NodeEntry<Table>, columnIndex: number): Path[] {
    const rows = table.children.length;
    return Array.from(new Array(rows)).map((_, i) => [...tablePath, i, columnIndex]);
}

export function getTableCellEntryByCoordinates(
    editor: Editor,
    [, tablePath]: NodeEntry<Table>,
    coordinates: [number, number],
): NodeEntry<TableCell> {
    const node = Editor.node(editor, [...tablePath, ...coordinates.slice().reverse()]);

    if (!isNodeEntry(node, isTableCell)) {
        throw new Error(
            `Node at coordinates ${JSON.stringify(coordinates)} is not a table cell: ${JSON.stringify(node, null, 2)}`,
        );
    }

    return node;
}

export function getLastTableRowEntry(editor: Editor, [, path]: NodeEntry<Table>) {
    const [, lastPath] = Editor.last(editor, path);
    const tableRowEntry = Editor.above(editor, { at: lastPath, match: isTableRow });
    assertNonNull(tableRowEntry, 'tableRowEntry should not be null');
    return tableRowEntry;
}

export function jumpToPreviousTableCell(editor: Editor, currentTableCellEntry: NodeEntry<TableCell>) {
    const previous = Editor.previous(editor, {
        at: currentTableCellEntry[1],
        match: isTableCell,
    });
    if (previous) {
        const [, path] = previous;
        Transforms.select(editor, Editor.end(editor, path));
    }
}

export function jumpToNextTableCell(editor: Editor, currentTableCellEntry: NodeEntry<TableCell>) {
    const next = Editor.next(editor, {
        at: currentTableCellEntry[1],
        match: isTableCell,
    });
    if (next) {
        const [, path] = next;
        Transforms.select(editor, Editor.end(editor, path));
    } else {
        const tableEntry = getTableEntry(editor, currentTableCellEntry);
        appendRow(editor, tableEntry);
    }
}

export function getPointBelowTableCell(editor: Editor, currentTableCellEntry: NodeEntry<TableCell>): BasePoint | null {
    const [table, tablePath] = getTableEntry(editor, currentTableCellEntry);
    const [, path] = currentTableCellEntry;
    const [columnIndex, rowIndex, ...firstPathPart] = path.slice().reverse();
    const nextRowIndex = rowIndex + 1;

    const newPath = [columnIndex, nextRowIndex, ...firstPathPart].reverse();
    if (nextRowIndex < table.children.length) {
        return Editor.end(editor, newPath);
    } else {
        const nextNodeEntry = Editor.next(editor, { at: tablePath });
        if (nextNodeEntry) {
            return Editor.start(editor, nextNodeEntry[1]);
        }
    }

    return null;
}

export function getPointAboveTableCell(editor: Editor, currentTableCellEntry: NodeEntry<TableCell>): BasePoint | null {
    const [, path] = currentTableCellEntry;
    const [columnIndex, rowIndex, ...firstPathPart] = path.slice().reverse();
    const prevRowIndex = rowIndex - 1;
    if (prevRowIndex >= 0) {
        const newPath = [columnIndex, prevRowIndex, ...firstPathPart].reverse();
        return Editor.end(editor, newPath);
    } else {
        const [, tablePath] = getTableEntry(editor, currentTableCellEntry);
        const previousNodeEntry = Editor.previous(editor, { at: tablePath });
        if (previousNodeEntry) {
            return Editor.end(editor, previousNodeEntry[1]);
        }
    }

    return null;
}

export function getEnclosingTableCellForCurrentSelection(editor: Editor): NodeEntry<TableCell> | null {
    if (editor.selection == null) {
        return null;
    }

    const tableCell = Editor.above(editor, {
        at: editor.selection,
        match: isTableCell,
        mode: 'lowest',
    });

    if (tableCell) {
        return tableCell;
    }

    const currentNodeEntry = Editor.node(editor, editor.selection);

    if (isNodeEntry(currentNodeEntry, isTableCell)) {
        return currentNodeEntry;
    }

    return null;
}

export function getTableRowEntryForCell(editor: Editor, [, path]: NodeEntry<TableCell>) {
    const tableRowEntry = Editor.above(editor, {
        at: path,
        match: isTableRow,
        mode: 'lowest',
    });
    assertNonNull(tableRowEntry, 'tableRowEntry should not be null');
    return tableRowEntry;
}

export function getTableEntry(editor: Editor, [node, path]: NodeEntry<Table | TableCell | TableRow>) {
    if (isTable(node)) {
        const node = Editor.node(editor, path);
        if (!isNodeEntry(node, isTable)) {
            throw new Error(`getTableEntry: Node ${JSON.stringify([node, path], null, 2)} is not a table.`);
        }
        return node;
    }

    const tableEntry = Editor.above(editor, {
        at: path,
        match: isTable,
        mode: 'lowest',
    });

    assertNonNull(
        tableEntry,
        `getTableEntry: No tableEntry found above entry ${JSON.stringify([node, path], null, 2)}`,
    );

    return tableEntry;
}

export function appendRow(editor: Editor, [table, tablePath]: NodeEntry<Table>) {
    const [, rows] = table.dimensions;
    insertRow(editor, [table, tablePath], rows);
}

export function appendColumn(editor: Editor, [table, tablePath]: NodeEntry<Table>) {
    const [columns] = table.dimensions;
    insertColumn(editor, [table, tablePath], columns);
}

export function insertRow(editor: Editor, [table, tablePath]: NodeEntry<Table>, index: number) {
    // based on the children of the row, we know how many columsn we need
    const newTableRow = BlockBuilder.tableRowByColumns(table.dimensions[0]);

    Editor.withoutNormalizing(editor, () => {
        const path = [...tablePath, index];
        Transforms.insertNodes(editor, [newTableRow], { at: path });
        recalculateAndSetTableDimensions(editor, [table, tablePath]);
        // Finally we set the selection in the first cell of the new row
        Transforms.select(editor, [...path, 0]);
    });
}

export function insertRowAfterTableCell(editor: Editor, tableCellEntry: NodeEntry<TableCell>) {
    const tableEntry = getTableEntry(editor, tableCellEntry);
    const [, path] = getTableRowEntryForCell(editor, tableCellEntry);
    insertRow(editor, tableEntry, path[path.length - 1] + 1);
}

export function insertRowBeforeTableCell(editor: Editor, tableCellEntry: NodeEntry<TableCell>) {
    const tableEntry = getTableEntry(editor, tableCellEntry);
    const [, path] = getTableRowEntryForCell(editor, tableCellEntry);
    insertRow(editor, tableEntry, path[path.length - 1]);
}

export function recalculateAndSetTableDimensions(editor: Editor, [table, path]: NodeEntry<Table>): [number, number] {
    const rows = toNodes([...Node.children(editor, path)]).filter(isTableRow);
    const maxColumnsLength = rows.reduce((acc, val) => Math.max(acc, val.children.length), 0);
    const dimensions: [number, number] = [maxColumnsLength, rows.length];
    Transforms.setNodes(editor, { dimensions }, { match: isTable, at: path });
    return dimensions;
}

export function normalizeTableCells(editor: Editor, [table, path]: NodeEntry<Table>): boolean {
    const rowEntries = [...Node.children(editor, path)].filter((entry): entry is NodeEntry<TableRow> =>
        isNodeEntry(entry, isTableRow),
    );

    const [columns, rows] = table.dimensions;

    // We need to keep track if any normalization happend at all to return this information
    // this is needed for the normalization
    let normalized = false;

    Editor.withoutNormalizing(editor, () => {
        rowEntries.forEach((rowEntry) => {
            const [row, rowPath] = rowEntry;
            const differenceToColumns = columns - row.children.length;
            if (differenceToColumns > 0) {
                const lastColumnPath = [
                    // the path of the row
                    ...rowPath,
                    // the index of the last column
                    row.children.length - 1,
                ];

                // We fill up the row with the missing columns based on the difference
                Transforms.insertNodes(editor, BlockBuilder.tableCells(differenceToColumns), {
                    at: Path.next(lastColumnPath),
                });
                normalized = true;
            }
        });

        const differenceToRows = rows - rowEntries.length;
        if (differenceToRows > 0) {
            const rows = BlockBuilder.tableRowsByColumns(columns, differenceToRows);
            const [, lastRowPath] = getLastTableRowEntry(editor, [table, path]);
            Transforms.insertNodes(editor, rows, {
                at: Path.next(lastRowPath),
            });
            normalized = true;
        }
    });

    return normalized;
}

export function insertColumn(editor: Editor, [table, tablePath]: NodeEntry<Table>, index: number) {
    Editor.withoutNormalizing(editor, () => {
        const columnPaths = makeColumnPaths([table, tablePath], index);

        // For each row, we need to insert a new column, so we iterate
        columnPaths.forEach((path, i) => {
            Transforms.insertNodes(editor, [BlockBuilder.tableCell()], {
                at: path,
            });

            if (i == 0) {
                Transforms.select(editor, path);
            }
        });

        recalculateAndSetTableDimensions(editor, [table, tablePath]);
    });
}

export function insertColumnAfterTableCell(editor: Editor, tableCellEntry: NodeEntry<TableCell>) {
    const tableEntry = getTableEntry(editor, tableCellEntry);
    const columnIndex = getColumnIndex(tableCellEntry);
    insertColumn(editor, tableEntry, columnIndex + 1);
}

export function insertColumnBeforeTableCell(editor: Editor, tableCellEntry: NodeEntry<TableCell>) {
    const tableEntry = getTableEntry(editor, tableCellEntry);
    const columnIndex = getColumnIndex(tableCellEntry);
    insertColumn(editor, tableEntry, Math.max(0, columnIndex));
}

export function deleteColumnByIndex(editor: Editor, [table, tablePath]: NodeEntry<Table>, columnIndex: number) {
    Editor.withoutNormalizing(editor, () => {
        const columnPaths = makeColumnPaths([table, tablePath], columnIndex);

        columnPaths.forEach((path) => {
            Transforms.delete(editor, { at: path });
        });

        recalculateAndSetTableDimensions(editor, [table, tablePath]);
        const firstCellPath = [...tablePath, 0, Math.max(0, columnIndex - 1)];
        EditorOperations.Transforms.selectPathIfExists(editor, firstCellPath);
    });
}

export function deleteRow(editor: Editor, [tableRow, tableRowPath]: NodeEntry<TableRow>) {
    Editor.withoutNormalizing(editor, () => {
        // we need to get the table before we remove the nodes, otherwise it blows up because it
        // cannot find the row anymore
        const tableEntry = getTableEntry(editor, [tableRow, tableRowPath]);
        Transforms.removeNodes(editor, { at: tableRowPath, match: isTableRow });
        const [columns] = recalculateAndSetTableDimensions(editor, tableEntry);

        if (tableRowPath[tableRowPath.length - 1] > 0) {
            const lastCellPath = [...Path.previous(tableRowPath), Math.max(0, columns - 1)];
            EditorOperations.Transforms.selectPathIfExists(editor, lastCellPath, 'end');
        }
    });
}

export function isColumnSelected(editor: Editor, [, tableCellPath]: NodeEntry<TableCell>, columnIndex: number) {
    return (
        editor.selection &&
        Range.isCollapsed(editor.selection) &&
        Path.common(editor.selection.focus.path, tableCellPath).length > 0 &&
        editor.selection.focus.path[tableCellPath.length - 1] === columnIndex
    );
}

export function getColumnIndex([, tableCellPath]: NodeEntry<TableCell>) {
    return tableCellPath[tableCellPath.length - 1];
}

export function isWithinFirstRow([, tableCellPath]: NodeEntry<TableCell>) {
    const rowPath = Path.parent(tableCellPath);
    const last = rowPath[rowPath.length - 1];
    return last === 0;
}

export function moveColumn(editor: Editor, [table, tablePath]: NodeEntry<Table>, fromIndex: number, toIndex: number) {
    Editor.withoutNormalizing(editor, () => {
        const columnPaths = makeColumnPaths([table, tablePath], fromIndex);
        const cells = columnPaths.map((path) => Node.get(editor, path));

        // Delete the original column
        deleteColumnByIndex(editor, [table, tablePath], fromIndex);

        // Insert the column at the new position
        insertColumn(editor, [table, tablePath], toIndex);

        // Replace the empty cells with the moved cells
        const newColumnPaths = makeColumnPaths([table, tablePath], toIndex);
        newColumnPaths.forEach((path, i) => {
            Transforms.removeNodes(editor, { at: path });
            Transforms.insertNodes(editor, cells[i], { at: path });
        });

        recalculateAndSetTableDimensions(editor, [table, tablePath]);
        const firstCellPath = [...tablePath, 0, toIndex];
        EditorOperations.Transforms.selectPathIfExists(editor, firstCellPath);
    });
}
