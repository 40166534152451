import { Location, NodeEntry, Path, Range } from 'slate';
import { Editor, Element, Node, Transforms } from 'slate';
import {
    BlockBuilder,
    BlockType,
    EditorOperations,
    isBlockContainer,
    isBlockType,
    isCallout,
    isCheckListItem,
    isDivider,
    isGoogleDriveFileLink,
    isIndentContainer,
    isLink,
    isListItem,
    isLiveBlockSource,
    isNodeEntry,
    isNumberedList,
    isNumberedListItem,
    isParagraph,
    isSagaElement,
    isSagaText,
    isTable,
    isTableCell,
    isTableRow,
    isTitle,
    isTitleInline,
    NumberedList,
    Paragraph,
    PATTERNS,
    PatternType,
    TableRow,
} from '..';
import { v4 as uuid } from 'uuid';
import invariant from 'tiny-invariant';

type NormalizeResult = void | 'stop';
type NormalizeFnParams = { editor: Editor; node: Node; path: Path; normalizers: Normalizer[] };
export type NormalizeFn = ({ editor, node, path }: NormalizeFnParams) => NormalizeResult;
export type Normalizer = { name: string; normalize: NormalizeFn };

export function createNormalizer(name: string, normalize: NormalizeFn): Normalizer {
    return { name, normalize };
}

export function evaluateNormalizers(
    normalizers: Normalizer[],
    params: NormalizeFnParams,
    debug = process.env.NORMALIZER_DEBUG,
): NormalizeResult {
    if (
        normalizers.some((normalizer) => {
            const before = debug ? EditorOperations.SagaElement.prettify(params.editor) : null;

            let result: NormalizeResult = 'stop';
            Editor.withoutNormalizing(params.editor, () => {
                try {
                    result = normalizer.normalize(params);
                } catch (error) {
                    if (debug) {
                        console.log(`=== ${normalizer.name} ===`);
                        console.log(error);
                        console.log(`=== ${normalizer.name} ===`);
                    }
                    throw error;
                }
            });

            if (result === 'stop' && debug) {
                console.log(`=== ${normalizer.name} ===`);
                console.log(before);
                console.log(EditorOperations.SagaElement.prettify(params.editor));
                console.log(`=== ${normalizer.name} ===`);
            }
            return result === 'stop';
        })
    ) {
        return 'stop';
    }
}

// This ensures that every element has an id, no matter what
const addMissingId = createNormalizer('addMissingId', ({ node, editor, path }) => {
    if (Element.isElement(node) && !node.id) {
        Transforms.setNodes(editor, { id: uuid() }, { at: path });
        return 'stop';
    }
});

// With this we want to make sure that there are no duplicate ids within the editor
const removeDuplicateIds = createNormalizer('removeDuplicateIds', ({ editor, path, node }) => {
    if (Editor.isEditor(node)) {
        const entries = [...Editor.nodes(editor, { at: path, match: isSagaElement })];
        const ids = entries.map(([node]) => node.id);
        const idsSet = new Set(ids);

        if (idsSet.size !== ids.length) {
            const idsCheck = new Set<string>([]);
            for (const [n, p] of entries) {
                if (!idsCheck.has(n.id)) {
                    idsCheck.add(n.id);
                } else {
                    Transforms.setNodes(editor, { id: uuid() }, { at: p });
                    return 'stop';
                }
            }
        }
    }
});

// Any block (not a text!) without a type will default to paragraph
const fixBlocksWithoutType = createNormalizer('fixBlocksWithoutType', ({ editor, node, path }) => {
    if (Element.isElement(node) && node.type == null) {
        Transforms.setNodes(editor, { type: BlockType.PARAGRAPH }, { at: path });
        return 'stop';
    }
});

// Empty containers should be removed
const removeEmptyContainers = createNormalizer('createNormalizer', ({ editor, node, path }) => {
    if (
        isBlockType(node, [isNumberedList, isBlockContainer, isIndentContainer]) &&
        Array.from(node.children).length === 0
    ) {
        Transforms.removeNodes(editor, { at: path });
        return 'stop';
    }
});

// The divider imported from markdown can sometimes be wrapped in a paragraph
const unwrapNestedDividers = createNormalizer('unwrapNestedDividers', ({ editor, node, path }) => {
    if (isParagraph(node) && isDivider(node.children[0])) {
        Transforms.unwrapNodes(editor, { at: path });
        return 'stop';
    }
});

const unwrapIndentsWithinIndents = createNormalizer('unwrapIndentsWithinIndents', ({ editor, node, path }) => {
    if (isIndentContainer(node)) {
        const [parent] = Editor.parent(editor, path);

        if (isIndentContainer(parent)) {
            Transforms.unwrapNodes(editor, { at: path });
            return 'stop';
        }
    }
});

const fixIndents = createNormalizer('fixIndents', ({ node, editor, path }) => {
    if (path.length === 0) return;

    const [parent, parentPath] = Editor.parent(editor, path);

    if (isIndentContainer(node) && !isBlockContainer(parent)) {
        if (Path.hasPrevious(path)) {
            const previous = Path.previous(path);
            const [previousNode, previousPath] = Editor.node(editor, previous);

            // if the previous node is a block container, wrap and merge
            if (isBlockContainer(previousNode)) {
                Transforms.wrapNodes(
                    editor,
                    BlockBuilder.blockContainer(
                        // @ts-expect-error blockContainer expects non-empty array
                        // but in this case it is fine because we just want to wrap the children
                        [],
                    ),
                    {
                        at: path,
                    },
                );
                Transforms.mergeNodes(editor, {
                    at: path,
                });
                return 'stop';
            }

            if (isNumberedList(previousNode)) {
                Transforms.wrapNodes(editor, BlockBuilder.numberedList([]), {
                    at: path,
                });
                Transforms.mergeNodes(editor, { at: path });

                return 'stop';
            }

            // if it's the first node after the title, remove the indent
            if (isTitle(previousNode)) {
                Transforms.unwrapNodes(editor, {
                    at: path,
                    match: isIndentContainer,
                    mode: 'highest',
                    split: true,
                });
                return 'stop';
            }

            if (!isIndentContainer(previousNode) && !isNumberedList(previousNode) && !isTitle(previousNode)) {
                const wrapPath = { anchor: { path: previousPath, offset: 0 }, focus: { path, offset: 0 } };

                // if (!Path.isPath(wrapPath)) return;

                // wrap this child and the previous one in a parent
                Transforms.wrapNodes(
                    editor,
                    BlockBuilder.blockContainer(
                        // @ts-expect-error blockContainer expects non-empty array
                        // but in this case it is fine because we just want to wrap the children
                        [],
                    ),
                    {
                        at: wrapPath,
                        mode: 'lowest',
                    },
                );
                return 'stop';
            }
        } else if (isCallout(parent)) {
            Transforms.unwrapNodes(editor, {
                at: path,
                split: true,
            });
            Transforms.wrapNodes(editor, BlockBuilder.indent([]), { at: parentPath });
            return 'stop';
        } else {
            Transforms.unwrapNodes(editor, {
                at: path,
                split: true,
            });
            return 'stop';
        }
    }
});

// This is here because some copy & paste issues caused links to be directly in indents and this fixes it
const fixLinksWithinIndents = createNormalizer('fixLinksWithinIndents', ({ editor, node, path }) => {
    if (isIndentContainer(node)) {
        for (let i = 0; i < Array.from(node.children).length; i++) {
            const child = node.children[i];
            if (isLink(child)) {
                Transforms.wrapNodes(editor, BlockBuilder.paragraph(), { at: [...path, i] });
                return 'stop';
            }
        }
    }
});

const fixEmptyLinks = createNormalizer('fixEmptyLinks', ({ editor, node, path }) => {
    if (isLink(node)) {
        // if element does not have children, set the url as text
        if (node.children.length === 0) {
            Transforms.insertNodes(editor, BlockBuilder.text(node.url), { at: [...path, 0] });
            return 'stop';
        } else if (Node.string(node).length === 0) {
            // if element is an empty link, remove it
            Transforms.unwrapNodes(editor, {
                at: path,
                match: isLink,
                split: true,
            });
            return 'stop';
        }
    }
});

// fixes links that are not matching anymore for some reason
const fixBrokenLinks = createNormalizer('fixBrokenLinks', ({ editor, node, path }) => {
    if (isLink(node)) {
        const linkText = Node.string(node);

        // if we are looking at a link node, we want to check if the text of the node fully matches the link pattern
        if (linkText.match(new RegExp(`^${PATTERNS.link.source}$`))) {
            // Now we compare the text of the link node with the url, if they don't match, we update the url
            // This has the consequence that you cannot have e.g. https://oneurl.com but have set
            // a different url e.g. https://anotherurl.com in the link node
            // but in the end that should not be a problem because it doesn't make any sense from a use case perspective
            if (linkText !== node.url) {
                Transforms.setNodes(editor, { url: linkText }, { at: path });
                return 'stop';
            }
        }
    }
});

// in case we have indent containers that are next to each other within a block container, we want to merge them
// this can happen as a intermediate result for example when you deindent a line within an indent (see test cases)
const mergeAdjacentIndents = createNormalizer('mergeAdjacentIndents', ({ node, editor, path, normalizers }) => {
    if (isIndentContainer(node)) {
        if (node.children.length === 0) {
            Transforms.removeNodes(editor, { at: path });
            return 'stop';
        }
    }

    if (isBlockContainer(node)) {
        const children = Array.from(node.children);

        for (let i = 1; i < children.length; i++) {
            const current = children[i];
            const currentPath = [...path, i];

            const previous = children[i - 1];
            const previousPath = [...path, i - 1];

            if (isIndentContainer(current)) {
                if (current.children.length === 0) {
                    Transforms.removeNodes(editor, { at: currentPath });
                    return 'stop';
                }

                if (isIndentContainer(previous)) {
                    Transforms.mergeNodes(editor, {
                        at: currentPath,
                    });

                    const [mergedNode, mergedPath] = Editor.node(editor, previousPath);
                    invariant(isIndentContainer(mergedNode));

                    // The merge from above does not trigger another round of normalization in the children of the merged node
                    // There is an edge case where we have adjacent numbered lists inside that node,
                    // therefore we need to merge them again

                    mergedNode.children.forEach((node, index) => {
                        const path = [...mergedPath, index];

                        if (Editor.hasPath(editor, path)) {
                            mergeAdjacentNumberedLists.normalize({
                                editor,
                                node,
                                path,
                                normalizers,
                            });
                        }
                    });
                    return 'stop';
                }
            }
        }
    }
});

const fixNumberedLists = createNormalizer('fixNumberedLists', ({ node, editor, path }) => {
    if (isNumberedList(node)) {
        const [parent, parentPath] = Editor.parent(editor, path);

        if (isNumberedListItem(parent)) {
            Transforms.setNodes(editor, BlockBuilder.indent([]), { at: parentPath });
            return 'stop';
        }

        const children = Array.from(node.children);
        for (let i = 0; i < children.length; i++) {
            const child = children[i];
            const childPath = [...path, i];

            // if anything else except a number_list_item or a parent with a number_list_item child is inside of a number_list, unwrap it
            if (
                !(
                    isBlockType(child, [isIndentContainer, isNumberedListItem]) ||
                    (isBlockType(child, isBlockContainer) && isBlockType(child.children[0], isNumberedListItem))
                )
            ) {
                Transforms.liftNodes(editor, { at: childPath });
                return 'stop';
            }
        }
    }
});

const fixNumberedListItems = createNormalizer('fixNumberedListItems', ({ node, editor, path }) => {
    if (isNumberedListItem(node)) {
        const [parent, parentPath] = Editor.parent(editor, path);
        // if a numbered list item is outside of a numbered lisdt but not the first child of a parent, wrap it
        if (!isNumberedList(parent) && !isBlockContainer(parent)) {
            if (isBlockType(parent, [isListItem, isCheckListItem, isNumberedListItem])) {
                Transforms.unwrapNodes(editor, { at: path });
            } else {
                Transforms.wrapNodes(editor, BlockBuilder.numberedList([]), { at: path });
            }
            return 'stop';
        }

        const children = Array.from(node.children);
        if (children.length === 1 && isSagaElement(children[0]) && !isLink(children[0])) {
            Transforms.unwrapNodes(editor, { at: [...path, 0] });
            return 'stop';
        }

        if (parentPath.length > 0) {
            const [grandParent, grandParentPath] = Editor.parent(editor, parentPath);
            // if a number list item is inside of a parent, wrap the parent in a number list
            if (isBlockContainer(parent) && !isNumberedList(grandParent)) {
                Transforms.wrapNodes(editor, BlockBuilder.numberedList([]), { at: parentPath });
                return 'stop';
            }
            // if a number list item is inside of a number list inside of a block container, unwrap it
            if (isNumberedList(parent) && isBlockContainer(grandParent)) {
                Transforms.unwrapNodes(editor, { at: parentPath });
                Transforms.wrapNodes(editor, BlockBuilder.numberedList([]), { at: grandParentPath });
                return 'stop';
            }
        }
    }
});

const isNextItemForList = (listNode: NumberedList, candidateNode: Paragraph) => {
    if (!candidateNode.children[0].text) return false;

    const numberedItemPrefix = candidateNode.children[0].text.match(/^[1-9]+[0-9]*. /gm)?.[0];
    const index = Number(numberedItemPrefix?.replaceAll('.', ''));
    return numberedItemPrefix && index === listNode.children.length + 1;
};

const continuePreviousNumberedList = createNormalizer('continuePreviousNumberedList', ({ node, editor, path }) => {
    if (isParagraph(node) && Path.hasPrevious(path)) {
        const [previousNode] = Editor.node(editor, Path.previous(path));

        if (isNumberedList(previousNode) && isNextItemForList(previousNode, node)) {
            const text = node.children[0].text;
            Transforms.insertText(editor, text.split('. ').pop(), { at: [...path, 0] });
            Transforms.setNodes(editor, BlockBuilder.numberedListItem([]), { at: path });
            return 'stop';
        }
    }
});

// in case we have indent containers that are next to each other within a block container, we want to merge them
// this can happen as a intermediate result for example when you deindent a line within an indent (see test cases)
const mergeAdjacentNumberedLists = createNormalizer(
    'mergeAdjacentNumberedLists',
    ({ node, editor, path, normalizers }) => {
        if (isNumberedList(node)) {
            const nextPath = Path.next(path);
            const afterNextPath = Path.next(nextPath);

            if (Editor.hasPath(editor, nextPath)) {
                const [nextNode] = Editor.node(editor, nextPath);
                const [afterNextNode] = Editor.hasPath(editor, afterNextPath) ? Editor.node(editor, afterNextPath) : [];

                if (isNumberedList(nextNode)) {
                    Transforms.mergeNodes(editor, {
                        at: nextPath,
                    });
                    return 'stop';
                }

                if (isParagraph(nextNode) && isNextItemForList(node, nextNode)) {
                    Editor.withoutNormalizing(editor, () => {
                        Transforms.delete(editor, { at: nextPath });
                        Transforms.insertNodes(
                            editor,
                            BlockBuilder.paragraph(
                                nextNode.children.map((node, index) =>
                                    index === 0 ? { ...node, text: node.text.split('. ').slice(1).join('') } : node,
                                ),
                            ),
                            {
                                at: nextPath,
                            },
                        );
                        Transforms.setNodes(editor, BlockBuilder.numberedListItem([]), {
                            at: nextPath,
                        });
                    });

                    return 'stop';
                }

                if (afterNextNode && isParagraph(afterNextNode) && isNextItemForList(node, afterNextNode)) {
                    Transforms.delete(editor, { at: nextPath });
                    mergeAdjacentNumberedLists.normalize({
                        editor,
                        node,
                        path,
                        normalizers,
                    });
                    return 'stop';
                }
            }

            if (Path.hasPrevious(path)) {
                const previousPath = Path.previous(path);
                const [previousNode] = Editor.node(editor, previousPath);
                if (isNumberedList(previousNode)) {
                    Transforms.mergeNodes(editor, {
                        at: path,
                    });
                    return 'stop';
                }
            }
        }
    },
);

// this normalizes blocks that have the legacy attribute isFirstChild, which is not needed anymore
const removeFirstChildAttribute = createNormalizer('removeFirstChildAttribute', ({ node, editor, path }) => {
    if ('isFirstChild' in node) {
        Transforms.unsetNodes(editor, 'isFirstChild', { at: path });
        return 'stop';
    }
});

const fixBlockContainerChildren = createNormalizer(
    'fixBlockContainerChildren',
    ({ node, editor, path, normalizers }) => {
        if (isBlockType(node, isBlockContainer)) {
            const children = Array.from(node.children);

            // lift lonely children from block container
            if (children.length === 1) {
                Transforms.liftNodes(editor, {
                    at: [...path, 0],
                });
                return 'stop';
            }

            // lift any second child that is not an indent
            if (!isIndentContainer(children[1])) {
                Transforms.liftNodes(editor, {
                    at: [...path, 1],
                });
                return 'stop';
            }

            if (isIndentContainer(children[0])) {
                mergeAdjacentIndents.normalize({ editor, node: children[1], path: [...path, 1], normalizers });
                return 'stop';
            }

            // lift any other child after the second child if they exist
            if (children.length > 2) {
                for (let i = 2; i < children.length; i++) {
                    Transforms.liftNodes(editor, {
                        at: [...path, i],
                    });
                    return 'stop';
                }
            }
        }
    },
);

// Make sure that there is no text node with only newline characters
const fixTextNewlineChar = createNormalizer('fixTextNewlineChar', ({ editor, node, path }) => {
    if (isSagaText(node)) {
        if (['\r\n', '\r', '\n'].includes(node.text)) {
            Transforms.insertText(editor, '', { at: path });
            return 'stop';
        }
    }
});

// Make sure that text nodes do not have id or type set, because it is not compatible with slate
const fixTextNode = createNormalizer('fixTextNode', ({ editor, node, path }) => {
    if (isSagaText(node)) {
        if ('id' in node) {
            Transforms.unsetNodes(editor, 'id', { at: path });
            return 'stop';
        }

        if ('type' in node) {
            Transforms.unsetNodes(editor, 'type', { at: path });
            return 'stop';
        }
    }
});

// In case we are dealing we a formatted inline code or a highlighter, we want to append the null character so that jumping out of the code
// element is possible and therefore we have improved UX
const appendNullCharacterToCodeAndHighlighter = createNormalizer(
    'appendNullCharacterToCodeAndHighlighter',
    ({ node, editor, path }) => {
        if (isSagaText(node) && (node.code === true || node.colorHighlighter != null)) {
            // We need to check if there is a sibling text child
            const hasNextTextChild = Editor.next(editor, { at: path }) != null;
            const isWindows = /Win/.test(navigator.userAgent);

            if (!hasNextTextChild) {
                Transforms.insertNodes(editor, { text: isWindows ? '\u200B' : '\0' }, { at: Editor.end(editor, path) });
                return 'stop';
            }
        }
    },
);

const SHORTCUTS: { [index: string]: (editor: Editor, path: Location) => void } = {
    '*': (editor, path) => {
        Transforms.setNodes(editor, BlockBuilder.listItem([]), { at: path });
    },
    '-': (editor, path) => {
        Transforms.setNodes(editor, BlockBuilder.listItem([]), { at: path });
    },
    '+': (editor, path) => {
        Transforms.setNodes(editor, BlockBuilder.listItem([]), { at: path });
    },
    '1.': (editor, path) => {
        Transforms.setNodes(editor, BlockBuilder.numberedListItem([]), { at: path });
    },
    '[]': (editor, path) => {
        Transforms.setNodes(editor, BlockBuilder.checkListItem([], false), { at: path });
    },
    '[x]': (editor, path) => {
        Transforms.setNodes(editor, BlockBuilder.checkListItem([], true), { at: path });
    },
    '>': (editor, path) => {
        Transforms.setNodes(editor, BlockBuilder.blockquote([]), { at: path });
    },
    '#': (editor, path) => {
        Transforms.setNodes(editor, BlockBuilder.headingOne([]), { at: path });
    },
    '##': (editor, path) => {
        Transforms.setNodes(editor, BlockBuilder.headingTwo([]), { at: path });
    },
    '###': (editor, path) => {
        Transforms.setNodes(editor, BlockBuilder.headingThree([]), { at: path });
    },
    '---': (editor, path) => {
        Transforms.insertFragment(editor, [BlockBuilder.divider()]);
    },
};

const applyMarkdownShortcuts = createNormalizer('applyMarkdownShortcuts', ({ node, editor, path }) => {
    if (isSagaText(node)) {
        const nodeEntry = Editor.above(editor, {
            match: (n) => Editor.isBlock(editor, n as any) || Editor.isInline(editor, n as any),
            at: path,
        });

        // if block is paragraph and text node is first in block, apply markdown shortcuts
        if (nodeEntry && isNodeEntry(nodeEntry, isParagraph) && path[path.length - 1] === 0) {
            const shortcut = Object.entries(SHORTCUTS).find(([key]) => node.text.startsWith(`${key} `));

            if (shortcut) {
                const [key, change] = shortcut;

                Transforms.delete(editor, {
                    at: { anchor: { path, offset: 0 }, focus: { path, offset: `${key} `.length } },
                });
                change(editor, { anchor: { path, offset: 0 }, focus: { path, offset: node.text.length } });

                return 'stop';
            }
        }
    }
});

const applyMarkdownPatterns = createNormalizer('applyMarkdownPatterns', ({ node, path, editor }) => {
    if (isSagaText(node)) {
        const nodeEntry = Editor.above(editor, {
            match: isSagaElement,
            at: path,
        });

        if (nodeEntry && !isLink(nodeEntry)) {
            // auto-transform markdown formatting to the correct marks
            for (const format in PATTERNS.marks) {
                const { selection } = editor;
                if (selection && Range.isCollapsed(selection)) {
                    for (const match of node.text.matchAll(PATTERNS.marks[format as PatternType])) {
                        EditorOperations.Extensions.applyMarkToRegexMatch(
                            editor,
                            [node, path],
                            format as PatternType,
                            match,
                        );
                        return 'stop';
                    }
                }
            }
        }
    }
});

const removeMarksForEmptyText = createNormalizer('removeMarksForEmptyText', ({ node, editor, path }) => {
    if (isSagaText(node)) {
        const { text } = node;
        if (text.length === 0) {
            for (const format in PATTERNS.marks) {
                if (node[format as PatternType]) {
                    Transforms.unsetNodes(editor, format, { at: path });
                    return 'stop';
                }
            }
        }
    }
});

// append an empty paragraph at the end if there is none or if the last element is not a paragraph
export const appendEmptyParagraph = createNormalizer('appendEmptyParagraph', ({ editor, node }) => {
    if (Editor.isEditor(node)) {
        const lastChild = editor.children[editor.children.length - 1];
        if (
            lastChild == null ||
            !isParagraph(lastChild) ||
            (isParagraph(lastChild) && Node.string(lastChild).length > 0)
        ) {
            Transforms.insertNodes(editor, BlockBuilder.paragraph(), { at: [editor.children.length] });
            return 'stop';
        }
    }
});

export const baseNormalizers: Normalizer[] = [
    // editor
    addMissingId,
    removeDuplicateIds,
    fixBlocksWithoutType,

    // blocks
    removeEmptyContainers,
    unwrapNestedDividers,

    // indents
    fixLinksWithinIndents,
    mergeAdjacentIndents,
    removeFirstChildAttribute,
    fixBlockContainerChildren,
    fixIndents,
    // needs to happen last because when indenting within an indent,
    // we first create a nested indent but then normalize it
    unwrapIndentsWithinIndents,

    // lists
    fixNumberedListItems,
    fixNumberedLists,
    mergeAdjacentNumberedLists,
    continuePreviousNumberedList,

    // links
    fixEmptyLinks,
    fixBrokenLinks,

    // text
    fixTextNode,
    fixTextNewlineChar,
    appendNullCharacterToCodeAndHighlighter,
    applyMarkdownShortcuts,
    applyMarkdownPatterns,
    removeMarksForEmptyText,
];

export const calloutNormalizer = createNormalizer('calloutNormalizer', ({ editor, node, path }) => {
    // turn empty callout into paragraph
    if (Element.isElement(node) && isCallout(node) && node.children.length === 0) {
        Transforms.setNodes(editor, BlockBuilder.paragraph(), { at: path });
        Transforms.unsetNodes(editor, ['backgroundColor', 'icon'], { at: path });
        return 'stop';
    }
});

export const googleDriveDocumentNormalizer = createNormalizer(
    'googleDriveDocumentNormalizer',
    ({ editor, node, path }) => {
        if (isGoogleDriveFileLink(node)) {
            const { file, ...rest } = node as any;
            if (file) {
                Transforms.removeNodes(editor, { at: path });
                Transforms.insertNodes(editor, { ...rest, state: { file, type: 'loaded' } }, { at: path });
                return 'stop';
            }
        }
    },
);

export const liveBlockSourceNormalizer = createNormalizer('liveBlockSourceNormalizer', ({ node, editor, path }) => {
    // if the live block source has no children, initialize it with an empty paragraph
    if (isLiveBlockSource(node)) {
        if (node.children.length === 0) {
            Transforms.insertNodes(editor, BlockBuilder.paragraph(), { at: [...path, 0] });
            return 'stop';
        }

        // If we have a path that is not the root path,
        // and the parent is not the editor, then we can say that the node has a parent node
        const hasParentNode = path.length > 0 && Node.parent(editor, path) !== editor;
        const isTitlePath = path[0] === 0;

        // We always need to unwrap nested live block sources as well as any live block source
        // that is present in the title path
        if (isTitlePath || hasParentNode) {
            Transforms.unwrapNodes(editor, { at: path });
            return 'stop';
        }
    }
});

export const titleNormalizer = createNormalizer('titleNormalizer', ({ editor, path, node }) => {
    if (!isTitleInline(node)) {
        const titleEntry = Editor.above(editor, { at: path, match: isTitle });

        if (titleEntry) {
            Transforms.unwrapNodes(editor, { at: path, voids: true });
            return 'stop';
        }
    }

    if (Editor.isEditor(node)) {
        if (editor.children.length === 0) {
            Transforms.insertNodes(editor, [BlockBuilder.title(''), BlockBuilder.paragraph()]);
            return 'stop';
        }

        for (const [child, childPath] of Node.children(editor, path)) {
            if (childPath[0] === 0 && !isTitle(child)) {
                // if the title is deleted, transform the first available node to title
                Transforms.setNodes(editor, BlockBuilder.title(), { at: childPath });
                return 'stop';
            } else if (childPath[0] > 0 && isTitle(child)) {
                // make sure there are no other titles after that
                Transforms.setNodes(editor, BlockBuilder.paragraph(), { at: childPath });
                return 'stop';
            }
        }
    }

    // we need to clear the title of the null character in case it is the only node, otherwise it is not selectable properly
    if (isTitle(node) && Node.string(node) === '\x00') {
        Transforms.delete(editor, { at: [...path, 0] });
        return 'stop';
    }
});

export const tableNormalizer = createNormalizer('tableNormalizer', ({ node, editor, path }) => {
    // Any nested table element should be unwrapped, because we don't allow nested tables
    if (isBlockType(node, [isTable, isTableRow, isTableCell])) {
        const cell = Editor.above(editor, { at: path, match: isTableCell });

        if (cell) {
            const nodeToUnwrap = Node.get(editor, path);
            // Check if the node has valid children
            //this is for inserting nested tables from pasting html in our editor
            //@ts-ignore
            if (nodeToUnwrap.children && nodeToUnwrap.children.length > 0) {
                Transforms.unwrapNodes(editor, { at: path });
                return 'stop';
            }
        }
    }

    // add dimensions if not present
    if (isTable(node)) {
        if (node.dimensions == null) {
            EditorOperations.Tables.recalculateAndSetTableDimensions(editor, [node, path]);
            return 'stop';
        }

        const [parent] = Editor.parent(editor, path);

        // Sometimes it is possible to have a table within a paragraph, we want to lift those
        if (isParagraph(parent)) {
            Transforms.liftNodes(editor, { at: path });
            return 'stop';
        }

        // the table is empty when it has no rows, then we remove the table
        const rowEntries = [...Node.children(editor, path)].filter((entry): entry is NodeEntry<TableRow> =>
            isNodeEntry(entry, isTableRow),
        );

        const tableIsEmpty = rowEntries.length === 0;

        if (tableIsEmpty) {
            Transforms.removeNodes(editor, { at: path });
            return 'stop';
        }

        // we want to make sure that a table is always normalized
        const normalizedTable = EditorOperations.Tables.normalizeTableCells(editor, [node, path]);

        if (normalizedTable) {
            return 'stop';
        }
    }

    if (isTableRow(node)) {
        const children = Array.from(node.children);

        if (children.length === 0) {
            Transforms.insertNodes(editor, BlockBuilder.tableCell([BlockBuilder.paragraph()]), {
                at: [...path, 0],
            });
            const tableEntry = Editor.above(editor, { at: path, match: isTable });

            if (tableEntry) {
                EditorOperations.Tables.recalculateAndSetTableDimensions(editor, tableEntry);
            }

            return 'stop';
        }

        for (let i = 0; i < children.length; i++) {
            const child = children[i];
            if (!isTableCell(child)) {
                const childPath = [...path, i];
                Transforms.wrapNodes(editor, BlockBuilder.tableCell(), {
                    at: childPath,
                });
                return 'stop';
            }
        }

        // make sure that a lonely table row gets unwrapped
        const [parent] = Editor.parent(editor, path);

        if (!isTable(parent)) {
            Transforms.unwrapNodes(editor, { at: path });
            return 'stop';
        }
    }

    // Ensure that any direct text inside a table cell is wrapped in a paragraph
    if (isTableCell(node)) {
        const children = Array.from(node.children);

        for (let i = 0; i < children.length; i++) {
            const child = children[i];
            const childPath = [...path, i];

            if (isSagaText(child)) {
                Transforms.wrapNodes(editor, BlockBuilder.paragraph(), {
                    at: childPath,
                });
                return 'stop';
            }

            if (isParagraph(child) && !child.children.length) {
                Transforms.insertNodes(editor, BlockBuilder.text(), { at: childPath });
                return 'stop';
            }
        }

        // make sure that a lonely table cell get unwrapped
        const [parent] = Editor.parent(editor, path);

        if (!isTableRow(parent) && children.length) {
            Transforms.unwrapNodes(editor, { at: path });
            return 'stop';
        }
    }
});

export const spaceNormalizers = [calloutNormalizer, liveBlockSourceNormalizer, titleNormalizer, tableNormalizer];
