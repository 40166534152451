import React from 'react';
import { SagaLocation } from '@saga/shared';
import MemberAvatar from '../MemberAvatar';
import useJumpToMemberCursor from '@/hooks/useJumpToMemberCursor';
import useDocumentCollaborators from '@/hooks/useDocumentCollaborators';

export default function Collaborators({ currentLocation }: { currentLocation: SagaLocation.SagaLocation }) {
    const jumpToCursor = useJumpToMemberCursor();

    const collaborators = useDocumentCollaborators(SagaLocation.getIdFromLocation(currentLocation));

    return (
        <div className="flex space-x-1">
            {collaborators
                .filter((collaborator) => SagaLocation.areLocationsEqual(collaborator.cursor.location, currentLocation))
                .map(({ user: { name, color }, cursor }, i) => (
                    <MemberAvatar key={i} name={name} color={color} onClick={(event) => jumpToCursor(cursor, event)} />
                ))}
        </div>
    );
}
