export default class Queue<T> {
    private array: T[];

    constructor() {
        this.array = [];
    }

    enqueue(item: T) {
        this.array.push(item);
    }

    dequeue() {
        return this.array.shift();
    }

    isEmpty() {
        return this.array.length === 0;
    }

    forEach(callback: (item: T) => void) {
        this.array.forEach(callback);
    }
}
