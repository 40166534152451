import { openWindow } from '@/utils';

const csbLanguages = ['typescript', 'javascript', 'html', 'css', 'scss', 'graphqlschema'] as const;
type CSBLanguage = (typeof csbLanguages)[number];

export function supportsCsb(language: string): language is CSBLanguage {
    return csbLanguages.includes(language as CSBLanguage);
}

function getConfig(content: string, language: CSBLanguage): { files: Record<string, any>; openFile?: string } {
    if (language === 'graphqlschema') {
        return {
            files: {
                'index.js': {
                    content: `const { ApolloServer, gql } = require("apollo-server");\n\n// Construct a schema, using GraphQL schema language\nconst typeDefs = gql\`\n${content}\n\`;\n\n// Provide resolver functions for your schema fields\nconst resolvers = {\n  Query: {\n    hello: (root, args, context) => "Hello world!"\n  }\n};\n\nconst server = new ApolloServer({\n  typeDefs,\n  resolvers\n});\n\nserver.listen().then(({ url }) => {\n  console.log(\`🚀 Server ready at $\{url\}\`);\n});\n`,
                },
                'package.json': {
                    content: {
                        name: 'apollo-server',
                        description: '',
                        dependencies: {
                            'apollo-server': '2.9.7',
                            graphql: '14.5.8',
                            'graphql-tag': '^2.10.1',
                        },
                        license: 'MIT',
                        main: 'index.js',
                        scripts: {
                            start: 'nodemon index.js localhost 4000',
                        },
                        keywords: [],
                        devDependencies: {
                            '@types/graphql': '14.0.1',
                            nodemon: '1.18.4',
                        },
                    },
                },
                'sandbox.config.json': {
                    content: {
                        template: 'apollo',
                    },
                },
            },
        };
    }
    if (language === 'css') {
        return {
            files: {
                'index.css': {
                    content,
                },
                'index.html': {
                    content: `<html>\n  <head>\n    <title>CSS</title>\n    <link href=\"index.css\" />\n   <meta charset=\"UTF-8\" />\n  </head>\n <body>\n  </body>\n</html>\n`,
                },
            },
            openFile: '/index.css',
        };
    }
    if (language === 'typescript') {
        return {
            files: {
                'src/index.ts': { content, isBinary: false },
                'index.html': {
                    content: `<html>\n  <head>\n    <title>Parcel Sandbox</title>\n    <meta charset=\"UTF-8\" />\n  </head>\n\n  <body>\n    <div id=\"app\"></div>\n\n    <script src=\"src/index.ts\"></script>\n  </body>\n</html>\n`,
                },
                'package.json': {
                    content: {
                        name: 'vanilla-typescript',
                        version: '1.0.0',
                        description: 'JavaScript and TypeScript example starter project',
                        main: 'index.html',
                        scripts: {
                            start: 'parcel index.html --open',
                            build: 'parcel build index.html',
                        },
                        dependencies: { 'parcel-bundler': '^1.6.1' },
                        devDependencies: {
                            typescript: '4.2.3',
                        },
                        resolutions: {
                            '@babel/preset-env': '7.13.8',
                        },
                        keywords: ['typescript', 'javascript'],
                    },
                },
                'tsconfig.json': {
                    content: {
                        compilerOptions: {
                            strict: true,
                            module: 'commonjs',
                            jsx: 'preserve',
                            esModuleInterop: true,
                            sourceMap: true,
                            allowJs: true,
                            lib: ['es6', 'dom'],
                            rootDir: 'src',
                            moduleResolution: 'node',
                        },
                    },
                },
            },
        };
    }

    if (language === 'html') {
        return {
            files: {
                'index.html': { content, isBinary: false },
            },
        };
    }

    if (language === 'javascript') {
        return {
            files: {
                'index.js': { content, isBinary: false },
                'package.json': {
                    content: {
                        dependencies: {},
                    },
                },
            },
        };
    }

    return {
        files: {
            [`index.${language}`]: { content },
        },
    };
}

export function createAndOpenCodeSandbox(content: string, language: CSBLanguage) {
    const { files, openFile } = getConfig(content, language);
    fetch('https://codesandbox.io/api/v1/sandboxes/define?json=1', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
        body: JSON.stringify({
            files,
        }),
    })
        .then((result) => result.json())
        .then((result) => {
            let url = `https://codesandbox.io/s/${result.sandbox_id}`;
            if (openFile) {
                url += `?file=${openFile}`;
            }
            openWindow(url, '_blank');
        });
}
