import React from 'react';
import { useSpace } from '@/components/SpaceProvider';
import { CONTENT_URL, BACKEND_URL } from '@/constants';
import { useCurrentWorkspace } from '@/components/WorkspaceContext';
import { CookieUtils, isFile, File as FileItem, SagaLocation } from '@saga/shared';
import { getCurrentUser } from '@/firebase';
import invariant from 'tiny-invariant';
import { Transforms } from 'slate';
import { convertFileSize, debugLog } from '@/utils';
import i18n from 'i18next';
import { usePendingUploads } from './usePendingUpload';
import { usePerformBlockChangeWithEditor } from '@/components/RealtimeDocumentProvider';
import { captureException } from '@sentry/react';
import { useFirebaseContext } from '@/components/FirebaseContext';

export function useUploadFile(location: SagaLocation.BlocksLocation) {
    const pendingUpload = usePendingUploads();
    const { firebaseUser } = useFirebaseContext();
    const { space } = useSpace();
    const { urlKey, permissions } = useCurrentWorkspace();
    const changeBlocksWithEditor = usePerformBlockChangeWithEditor();

    return React.useCallback(
        async function uploadFile({ file, element }: { file: File; element: FileItem }) {
            const csrfToken = document.head.querySelector('meta[name="x-csrf-token"]')!.getAttribute('content');
            if (!csrfToken) {
                captureException('CSRF Token not found', { extra: { urlKey, user: firebaseUser?.uid } });
                debugLog('CSRF Token not found');
                return;
            }

            pendingUpload.add(element.id, file);
            const key = `spaces/${urlKey}/files/${file.name}`;
            const contentType = file.type;
            const currentUser = getCurrentUser();
            invariant(currentUser != null, 'must be logged in to upload an file');

            const token = await currentUser.getIdToken();

            if (file.size > permissions.fileUpload.limitFile) {
                alert(
                    i18n.t('editor.file_upload.max_file_size_error', {
                        size: convertFileSize(permissions.fileUpload.limitFile),
                    }),
                );
                return;
            }

            const url = await fetch(`${BACKEND_URL}upload-signed-url-v2`, {
                method: 'POST',
                headers: { 'Content-type': 'application/json', 'x-csrf-token': csrfToken },
                credentials: 'include',
                body: JSON.stringify({
                    urlKey,
                    key,
                    type: CookieUtils.CookieTypes.space,
                    contentType,
                    token,
                }),
            })
                .then(async (result) => {
                    if (result.status === 200) {
                        const { url } = await result.json();
                        return url;
                    }
                    throw 'Failed to get signed url';
                })
                .then(async (url) => {
                    const result = await fetch(url, { method: 'PUT', body: file });
                    if (result.status === 200) {
                        const fileUrl = `${CONTENT_URL}/${key}`;
                        return fileUrl;
                    }
                    throw 'Failed to upload file';
                });

            changeBlocksWithEditor(space, location, (editor) => {
                Transforms.setNodes(
                    editor,
                    { url, title: file.name, size: file.size },
                    {
                        at: [],
                        match(block) {
                            return isFile(block) && block.id === element.id;
                        },
                    },
                );
                pendingUpload.remove(element.id);
            });
        },
        [pendingUpload, urlKey, space, location, changeBlocksWithEditor],
    );
}
