import React, { useState, useMemo } from 'react';
import { prepareCollectionItems } from '@/lib/Table';
import Table from '@/components/table/Table';
import { formatDateOptimized } from '@/utils/dateUtils';
import CollectionContextMenuButton from '@/components/popover/CollectionContextMenuButton';
import useWorkspaceSettings from '@/hooks/useWorkspaceSettings';
import NavigationTable from './NavigationTable';
import { useTableContext, TableContext } from './TableContext';
import SortableTableButton from '@/components/table/SortableTableButton';
import SearchBar from '@/components/table/TableSearchBar';
import { SearchBar as HeaderSearchBar } from '@/components/navigation/HeaderBar';
import SortButton, { fieldsToSortOptions, SortOption } from '@/components/table/TableSortButton';
import { track } from '@/analytics';
import type { CollectionItem, TableColumn } from '@/types';
import { CollectionsTableColumnKey, Sorting, SpaceOperations } from '@saga/shared';
import { useSpaceAccess } from '@/hooks/useSpaceAccess';
import { useCollectionsSnapshot, usePartialPages } from '@/hooks/SpaceHooks';
import { useOpenCollection } from '../PageNavigationProvider';
import { useDesktopContext } from '@/components/DesktopContext';
import { useSpace } from '@/components/SpaceProvider';
import CreateButton from '../popover/CreateButton';
import { makeCreateCollectionSuggestion } from '@/lib/Suggestions';
import DesktopNavigationButtons from '../DesktopNavigationButtons';
import { useSideBySideIndex, useSideBySideState } from '@/components/SideBySide';

import { useTranslation } from 'react-i18next';
import { useUserContext } from '../UserContext';

import ActionBar from './ActionBar';
import { useCollectionsTableColumns } from '@/hooks/TableHooks';
import classNames from 'classnames';

const columns: { [key: string]: TableColumn } = {
    title: { asc: 'filters.az', desc: 'filters.za', label: 'filters.title', type: 'title', value: 'title' },
    pageCount: {
        asc: 'collections.fewest_first',
        desc: 'collections.most_first',
        label: 'collections.pages',
        type: 'pageCount',
        value: 'pageCount',
    },
    created: {
        asc: 'collections.oldest_first',
        desc: 'collections.newest_first',
        label: 'filters.created',
        type: 'created',
        value: 'created',
    },
    updated: {
        asc: 'collections.oldest_first',
        desc: 'collections.newest_first',
        label: 'filters.updated',
        type: 'updated',
        value: 'updated',
    },
};

const sortOptions: SortOption[] = fieldsToSortOptions(['title', 'pageCount', 'created', 'updated'], columns);

interface TableHeaderContentProps {
    children: React.ReactNode;
}

const TableHeaderContent: React.FC<TableHeaderContentProps> = ({ children }) => {
    return <p className="my-auto py-0.5 px-1 flex-grow truncate text-saga-gray-500 text-sm  font-normal">{children}</p>;
};

function TableHeader({
    columnKeys,
    moveHeader,
    onDrop,
}: {
    columnKeys: CollectionsTableColumnKey[];
    moveHeader: (dragIndex: number, hoverIndex: number) => void;
    onDrop: () => void;
}) {
    const { t } = useTranslation();

    return (
        <Table.Header className="sticky top-0 z-50 bg-white dark:bg-saga-gray-1000">
            <Table.Row>
                {columnKeys.map((key, index) => {
                    switch (key) {
                        case 'title':
                            return (
                                <Table.Cell key={key} className="h-10 pb-3 align-middle w-full min-w-56">
                                    <Table.DraggableHeader
                                        id={key}
                                        index={index}
                                        moveHeader={moveHeader}
                                        onDrop={onDrop}
                                    >
                                        <SortableTableButton type="title">
                                            <TableHeaderContent>{t(columns.title.label)}</TableHeaderContent>
                                        </SortableTableButton>
                                    </Table.DraggableHeader>
                                </Table.Cell>
                            );
                        case 'pageCount':
                            return (
                                <Table.Cell key={key} className="h-10 pb-3 align-middle min-w-40 w-40 truncate no-wrap">
                                    <Table.DraggableHeader
                                        id={key}
                                        index={index}
                                        moveHeader={moveHeader}
                                        onDrop={onDrop}
                                    >
                                        <SortableTableButton type="pageCount">
                                            <TableHeaderContent>{t(columns.pageCount.label)}</TableHeaderContent>
                                        </SortableTableButton>
                                    </Table.DraggableHeader>
                                </Table.Cell>
                            );
                        case 'created':
                            return (
                                <Table.Cell key={key} className="h-10 pb-3 align-middle min-w-40 w-40">
                                    <Table.DraggableHeader
                                        id={key}
                                        index={index}
                                        moveHeader={moveHeader}
                                        onDrop={onDrop}
                                    >
                                        <SortableTableButton type="created">
                                            <TableHeaderContent>{t(columns.created.label)}</TableHeaderContent>
                                        </SortableTableButton>
                                    </Table.DraggableHeader>
                                </Table.Cell>
                            );
                        case 'updated':
                            return (
                                <Table.Cell key={key} className="h-10 pb-3 align-middle min-w-40 w-40">
                                    <Table.DraggableHeader
                                        id={key}
                                        index={index}
                                        moveHeader={moveHeader}
                                        onDrop={onDrop}
                                    >
                                        <SortableTableButton type="updated">
                                            <TableHeaderContent>{t(columns.updated.label)}</TableHeaderContent>
                                        </SortableTableButton>
                                    </Table.DraggableHeader>
                                </Table.Cell>
                            );
                        default:
                            return null;
                    }
                })}
                <Table.Cell></Table.Cell>
            </Table.Row>
        </Table.Header>
    );
}

const keysMap = ['id', 'collections', 'archivedAt'] as const;

const useCollections = (search: string, sorting: Sorting | null): CollectionItem[] => {
    const collections = useCollectionsSnapshot();
    const pages = usePartialPages(keysMap, 'deep');

    const pageCountMap = useMemo(() => {
        if (!pages) return {};
        let pageCountMap = collections.reduce((map: { [key: string]: number }, collection) => {
            map[collection.id] = 0;
            return map;
        }, {});
        for (const page of pages) {
            if (page.archivedAt != null) {
                continue;
            }
            for (const collection of page.collections) {
                pageCountMap[collection] += 1;
            }
        }
        return pageCountMap;
    }, [collections, pages]);

    return useMemo(() => {
        const items = collections.map((c): CollectionItem => {
            return {
                id: c.id,
                type: 'collection',
                title: c.title || 'Untitled',
                data: c,
                computed: {
                    pageCount: pageCountMap[c.id],
                },
            };
        });
        return prepareCollectionItems(items, { sorting, search });
    }, [collections, pageCountMap, search, sorting]);
};

type RowProps = {
    collection: CollectionItem;
    columnKeys: CollectionsTableColumnKey[];
    onTitleClick: (id: string, event: React.MouseEvent) => void;
    canEdit: boolean;
};

const Row = ({ collection, columnKeys, onTitleClick, canEdit }: RowProps) => {
    const [showContext, setShowContext] = useState<boolean>(false);
    const { user } = useUserContext();
    const createdAt = useMemo(
        () => formatDateOptimized(collection.data.createdAt, user?.data.language),
        [collection, user?.data.language],
    );
    const updatedAt = useMemo(
        () => formatDateOptimized(collection.data.updatedAt, user?.data.language),
        [collection, user?.data.language],
    );
    return (
        <Table.Row
            key={collection.id}
            onMouseOver={() => setShowContext(true)}
            onMouseLeave={() => setShowContext(false)}
            className="divide-x divide-saga-gray-150 dark:divide-saga-gray-800 py-1"
            aria-label={collection.title}
        >
            {columnKeys.map((key, index) => {
                const renderButtonIfFirstColumn = () =>
                    index === 0 && canEdit ? (
                        <>
                            <div className="h-10 w-10 absolute -ml-7"></div>
                            {canEdit && showContext && (
                                <div className="h-10 absolute -ml-7 self-center">
                                    <CollectionContextMenuButton
                                        className="my-auto"
                                        align="start"
                                        isButtonSmall
                                        collectionId={collection.id}
                                    />
                                </div>
                            )}
                        </>
                    ) : null;

                switch (key) {
                    case 'title':
                        return (
                            <Table.Cell
                                key={key}
                                className={classNames('relative h-10 align-middle px-1 w-full min-w-56 max-w-56', {
                                    'pl-0': index === 0,
                                })}
                            >
                                {renderButtonIfFirstColumn()}
                                <Table.ItemButton
                                    onClick={(event) => onTitleClick(collection.id, event)}
                                    item={collection}
                                />
                            </Table.Cell>
                        );
                    case 'pageCount':
                        return (
                            <Table.Cell
                                key={key}
                                className={classNames('relative h-10 align-middle min-w-40 w-40 no-wrap px-1', {
                                    'pl-0': index === 0,
                                })}
                            >
                                {renderButtonIfFirstColumn()}

                                <span className="text-sm">{collection.computed.pageCount}</span>
                            </Table.Cell>
                        );
                    case 'created':
                        return (
                            <Table.Cell
                                key={key}
                                className={classNames('relative h-10 align-middle min-w-40 w-40 no-wrap px-1', {
                                    'pl-0': index === 0,
                                })}
                            >
                                {renderButtonIfFirstColumn()}

                                <span className="text-sm">{createdAt}</span>
                            </Table.Cell>
                        );
                    case 'updated':
                        return (
                            <Table.Cell
                                key={key}
                                className={classNames('relative h-10 align-middle min-w-40 w-40 no-wrap px-1', {
                                    'pl-0': index === 0,
                                })}
                            >
                                {renderButtonIfFirstColumn()}

                                <span className="text-sm">{updatedAt}</span>
                            </Table.Cell>
                        );
                }
            })}
        </Table.Row>
    );
};

function TableContent() {
    const goToCollection = useOpenCollection();
    const { search, sorting } = useTableContext();
    const collections = useCollections(search, sorting);
    const { canEdit } = useSpaceAccess();
    const { t } = useTranslation();

    const onTitleClick = (id: string, event: React.MouseEvent) => {
        goToCollection(id, event);
    };

    const [columnOrder, setColumnOrder] = useCollectionsTableColumns();
    const [currentColumnOrder, setCurrentColumnOrder] = useState(columnOrder);

    const moveHeader = React.useCallback((dragIndex: number, hoverIndex: number) => {
        setCurrentColumnOrder((prevKeys) => {
            const newKeys = [...prevKeys];
            const [removed] = newKeys.splice(dragIndex, 1);
            newKeys.splice(hoverIndex, 0, removed);
            return newKeys;
        });
    }, []);

    const onDrop = React.useCallback(() => {
        setColumnOrder(currentColumnOrder);
    }, [currentColumnOrder, setColumnOrder]);

    return (
        <NavigationTable.PaneContainer className="pb-12 show-scrollbar-x">
            <Table
                data-testid="all-collections-table"
                className="table-fixed border-collapse text-left divide-y divide-saga-gray-150 dark:divide-saga-gray-800"
            >
                <TableHeader columnKeys={currentColumnOrder} moveHeader={moveHeader} onDrop={onDrop} />
                <Table.Body className="relative divide-y divide-saga-gray-150 dark:divide-saga-gray-800 h-full">
                    {collections.map((collection, i) => (
                        <Row
                            columnKeys={currentColumnOrder}
                            key={`row-${i}`}
                            collection={collection}
                            onTitleClick={onTitleClick}
                            canEdit={canEdit}
                        />
                    ))}

                    <Table.Row className="divide-x divide-saga-gray-150 dark:divide-saga-gray-800 py-1">
                        <Table.Cell></Table.Cell>
                        <Table.Cell></Table.Cell>
                        <Table.Cell></Table.Cell>
                        <Table.Cell></Table.Cell>
                        <Table.Cell></Table.Cell>
                    </Table.Row>
                </Table.Body>
            </Table>

            {collections.length === 0 && <Table.EmptyState>{t('collections.empty_state_message')}</Table.EmptyState>}
        </NavigationTable.PaneContainer>
    );
}

function useSorting() {
    const [settings, updateUserWorkspaceSettings] = useWorkspaceSettings();
    const sorting = settings?.allCollectionsSorting ?? null;

    const setSorting = (allCollectionsSorting: Sorting | null) => {
        updateUserWorkspaceSettings({ allCollectionsSorting });
        track('sort-table', { source: 'all-collections-table' });
    };

    return { sorting, setSorting };
}

const AllCollectionsTable = ({ onClose }: { onClose?: () => void }) => {
    const [search, setSearch] = useState<string>('');
    const { sorting, setSorting } = useSorting();
    const { canEdit } = useSpaceAccess();
    const { space } = useSpace();
    const { isDesktop } = useDesktopContext();
    const sideIndex = useSideBySideIndex();
    const { panes } = useSideBySideState();
    const { t } = useTranslation();
    const goToCollection = useOpenCollection();

    return (
        <TableContext.Provider
            value={{
                search,
                setSearch,
                sorting,
                setSorting,
                columns,
            }}
        >
            <NavigationTable>
                <NavigationTable.FixedPaneContainer variant="page-header">
                    <>
                        {isDesktop && sideIndex === 0 && <DesktopNavigationButtons />}
                        <div className="flex flex-grow justify-center">{panes.length === 1 && <HeaderSearchBar />}</div>
                        <div className="flex z-20">
                            <NavigationTable.SearchButton />
                            {onClose && (
                                <NavigationTable.CloseButton
                                    label={t('pages.close_pages') as string}
                                    onClick={onClose}
                                />
                            )}
                            {!onClose && <div style={{ height: 36 }} />}
                        </div>
                    </>
                </NavigationTable.FixedPaneContainer>
                <NavigationTable.FixedPaneContainer variant="page-title">
                    <NavigationTable.Header
                        title={t('collections.page_title')}
                        button={
                            canEdit && (
                                <CreateButton
                                    onSuggest={makeCreateCollectionSuggestion}
                                    placeholder={t('collections.new_collection_placeholder')}
                                    onCreate={(event) => {
                                        const collection = SpaceOperations.createCollection(space, '');
                                        goToCollection(collection.id, event);
                                    }}
                                >
                                    {t('collections.new_collection')}
                                </CreateButton>
                            )
                        }
                    />
                </NavigationTable.FixedPaneContainer>
                <ActionBar search={<SearchBar />} sort={<SortButton sortOptions={sortOptions} />} />
                <TableContent />
            </NavigationTable>
        </TableContext.Provider>
    );
};

export default AllCollectionsTable;
