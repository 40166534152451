import { BlockBuilder, SpaceOperations, WeakBlocks } from '..';
import { InlinePageLink, isTitle, SafeSpace } from '../types';

export default function createInlinePageLink(space: SafeSpace, pageId: string, blocks: WeakBlocks): InlinePageLink {
    const title = blocks[0];

    if (!isTitle(title)) {
        throw new Error('Invariant: First Block of page is not a title');
    }

    const liveReferenceSourceId = title.id;

    const entry = SpaceOperations.findReferenceRegistryEntry(space, title.id);
    const page = SpaceOperations.getPageById(space, pageId, ['title', 'icon', 'isTemplate']);

    if (!page) {
        throw new Error('Cannot find page');
    }

    if (entry == null) {
        SpaceOperations.addToReferenceRegistry(space, {
            pageId,
            liveReferenceSourceId,
            liveBlocks: [title],
            isArchived: false,
        });
    }
    return BlockBuilder.inlinePageLink(pageId, liveReferenceSourceId, { ...page });
}
