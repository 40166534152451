import React, { useCallback, useRef } from 'react';
import { Search, X } from 'react-feather';
import { useTableContext } from '@/components/table/TableContext';
import { useTranslation } from 'react-i18next';

const SearchBar = () => {
    const searchInputRef = useRef<HTMLInputElement>(null);
    const { search, setSearch } = useTableContext();
    const { t } = useTranslation();

    const onClose = useCallback(() => {
        if (searchInputRef) {
            searchInputRef.current?.blur();
            setSearch('');
        }
    }, [setSearch]);

    return (
        <div className="relative inline h-8 w-full">
            <div className="absolute left-0 flex items-center pt-1.5 px-2 z-100">
                <Search className="stroke-saga-grey flex-none ml-1 my-1" size={14} />
            </div>
            <form>
                <input
                    data-testid="search-menu"
                    ref={searchInputRef}
                    className="h-8 transition-colors duration-200 ease-in-out text-sm py-1 pl-10 pr-4  focus:outline-0 font-normal border bg-white dark:bg-inherit border-saga-gray-200 dark:border-saga-gray-700 focus:bg-gray-100 dark:focus:bg-zinc-900/50 focus:border-gray-300 dark:focus:border-zinc-500 placeholder-saga-text-gray rounded block w-full appearance-none leading-normal "
                    type="text"
                    placeholder={t('collections.filter_placeholder') as string}
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    onKeyDown={(event) => {
                        switch (event.key) {
                            case 'Escape':
                                onClose();
                                return;
                            case 'Enter':
                                event.preventDefault();
                                return;
                        }
                    }}
                ></input>
            </form>
            {search && (
                <div className="absolute inset-y-0 right-0 pr-2 flex items-center cursor-pointer" onClick={onClose}>
                    <X className="stroke-saga-grey" size={14} />
                </div>
            )}
        </div>
    );
};

export default SearchBar;
