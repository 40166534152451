import { BlockBuilder, SagaEditor, isTaskBlock, SagaLocation, SpaceOperations } from '@saga/shared';
import React, { useEffect } from 'react';
import classNames from 'classnames';
import { useOpenLocation } from '@/components/PageNavigationProvider';
import { useNullableTask } from '@/components/tasks/hooks';
import { ReactEditor, useFocused } from 'slate-react';
import { useSpace } from '@/components/SpaceProvider';
import useMobile from '@/hooks/useMobile';
import { Path, Transforms } from 'slate';
import { taskTitleEditors } from '@/components/tasks/TaskTitleEditor';
import useCheckTaskShortcut from '@/components/tasks/useCheckTaskShortcut';
import { TaskBlock, TaskNotFound } from '../Task';
import VoidSelectionShadow from '../../VoidSelectionShadow';
import { useTranslation } from 'react-i18next';
import { track } from '@/analytics';
import { createTask } from '@/utils/documentUtils';
import * as api from '@saga/api';
import { getCurrentUser } from '@/firebase';
import { useCurrentWorkspace } from '@/components/WorkspaceContext';
import { useArchiveTask } from '@/hooks/deleteTaskHooks';
import { TaskLabelColors } from '@/components/tasks/LabelSelect';
import { useTaskLabels } from '@/hooks/SpaceHooks';
import { useTaskFilters } from '@/hooks/useTaskFilters';

function AddTaskButton(props: Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'className'>) {
    const { t } = useTranslation();
    return (
        <button
            {...props}
            className="bg-saga-shade-800 hover:bg-saga-shade-500 dark:bg-saga-shade-400 dark:hover:bg-saga-shade-300 transition-colors text-white p-1 rounded inline-flex items-center justify-center"
        >
            <svg
                className="inline-block"
                width="8"
                height="8"
                viewBox="0 0 8 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M4.896 3.184V0H3.184V3.184H0V4.896H3.184V8.08H4.896V4.896H8.08V3.184H4.896Z"
                    fill="currentColor"
                />
            </svg>
            <span className="sr-only">{t('tasks.add_task_below')}</span>
        </button>
    );
}
export const spaceTaskBlockPluginPlugin = SagaEditor.Plugins.createBlockPlugin({
    match: isTaskBlock,
    Component({ children, element, blockPlugins, editor, path, selected }) {
        const { space, provider } = useSpace();
        const { canEdit, location } = SagaEditor.useEditorContext();
        const task = useNullableTask(element.taskId);
        const openLocation = useOpenLocation();
        const focused = useFocused();
        const archiveTask = useArchiveTask();
        const isMobile = useMobile();
        const { urlKey } = useCurrentWorkspace();
        const [sendTaskAssignmentNotification] = api.useSendTaskAssignmentNotificationMutation();
        const currentUser = getCurrentUser();
        const spaceLabels = useTaskLabels();
        const taskFilters = useTaskFilters();

        useCheckTaskShortcut({ active: focused && selected, taskId: element.taskId });

        function onAddTaskBelow() {
            const task = createTask(space, { title: '', ...taskFilters }, provider);
            const taskBlock = BlockBuilder.taskBlock(task.id, task);
            const target = Path.next(path);
            Transforms.insertNodes(editor, [taskBlock], { at: target });

            // We need to do this in a setTimeout in this case because the editor instance needs to be rendered first
            setTimeout(() => {
                const taskTitleEditor = taskTitleEditors.get(task.id);
                taskTitleEditor?.focus();
            });
        }

        function onDeleteBlock() {
            const path = ReactEditor.findPath(editor, element);
            Transforms.select(editor, path);
            setTimeout(() => {
                Transforms.removeNodes(editor, { at: path });
            });
            ReactEditor.focus(editor);
            Transforms.insertNodes(editor, [BlockBuilder.paragraph()]);
        }

        useEffect(() => {
            const onCopy = (event: ClipboardEvent) => {
                if (taskTitleEditors.get(element.taskId)?.isFocused) return;

                event.preventDefault();
                const clipboardData = event.clipboardData?.getData('application/x-saga');
                if (clipboardData && clipboardData.includes(element.id)) return;

                SagaEditor.Clipboard.copyBlocks([element], {
                    location: { ...location, blockId: element.id },
                    spaceUrlKey: urlKey,
                    event,
                    action: 'copy',
                    blockPlugins,
                });
            };

            if (focused && selected) {
                document.addEventListener('copy', onCopy);
            }

            return () => {
                document.removeEventListener('copy', onCopy);
            };
        }, [element, focused, selected, blockPlugins, location, urlKey]);

        return (
            <VoidSelectionShadow path={path}>
                <div
                    className={classNames('rounded my-1 relative task-block', {
                        'shadow-lightblue': selected,
                        'shadow-sm': !selected,
                    })}
                    contentEditable={false}
                    id={element.id}
                >
                    <span className="hidden select-none">{children}</span>
                    {task && (
                        <TaskBlock
                            spaceLabels={spaceLabels}
                            disabled={!canEdit}
                            onClick={(event) => openLocation(SagaLocation.taskLocationFromId(task.id), event)}
                            task={task}
                            blockPlugins={blockPlugins}
                            onChangeState={(state) => {
                                SpaceOperations.updatePartialTask(space, task.id, { state });
                            }}
                            onChangeAssignee={async (assignee) => {
                                if (assignee.action === api.TaskAssignAction.Unassign) {
                                    track('unassign-assignee-from-task', { source: 'task-table' });
                                } else {
                                    track('assign-assignee-to-task', { source: 'task-table' });
                                }
                                SpaceOperations.updatePartialTask(space, task.id, {
                                    assignee: assignee.action === api.TaskAssignAction.Assign ? assignee.id : null,
                                });
                                if (assignee.id && assignee.id !== currentUser?.uid) {
                                    await sendTaskAssignmentNotification({
                                        variables: {
                                            input: {
                                                urlKey,
                                                taskId: task.id,
                                                memberId: assignee.id,
                                                action: assignee.action,
                                            },
                                        },
                                    });
                                }
                            }}
                            onChangePriority={(priority) => {
                                if (priority == null) {
                                    track('remove-task-priority', { source: 'task-table' });
                                } else {
                                    track('change-task-priority', { source: 'task-table' });
                                }
                                SpaceOperations.updatePartialTask(space, task.id, {
                                    priority: typeof priority === 'string' ? priority : null,
                                });
                            }}
                            onChangeDueDate={(date) => {
                                SpaceOperations.updatePartialTask(space, task.id, {
                                    dueDate: date?.toDateString() ?? null,
                                });
                            }}
                            onDeleteTask={() => {
                                onDeleteBlock();
                                archiveTask(task.id);
                            }}
                            onAddTaskBelow={onAddTaskBelow}
                            onDeleteBlock={onDeleteBlock}
                            onChangeLabels={(labels) => {
                                SpaceOperations.updatePartialTask(space, task.id, {
                                    labels,
                                });
                            }}
                            onChangeLabelColor={(id, color) => {
                                SpaceOperations.updatePartialTaskLabel(space, id, { color });
                            }}
                            onCreateLabel={(title) => {
                                const id = SpaceOperations.addTaskLabel(space, title, TaskLabelColors[0]);
                                SpaceOperations.updatePartialTask(space, task.id, {
                                    labels: [...new Set([...(task.labels ?? []), id])],
                                });
                            }}
                        />
                    )}
                    {!task && <TaskNotFound />}

                    {canEdit && !isMobile && (
                        <div className="task-block-hover:pointer-events-auto pointer-events-none task-block-hover:opacity-100 opacity-0 transition-opacity absolute left-0 right-0 flex justify-center -bottom-2">
                            <AddTaskButton onClick={onAddTaskBelow} />
                        </div>
                    )}
                </div>
            </VoidSelectionShadow>
        );
    },
});
