import { PageIcon } from '@/components/icons';
import { dndTypes } from '@/constants';
import useMobile from '@/hooks/useMobile';
import {
    DndOverArea,
    DndSidebarCollection,
    DndSidebarPage,
    DndSidebarTask,
    DndSidebarPageView,
    DndSidebarTaskView,
    SharedPageItem,
} from '@/types';
import { Collection, PageView, SpaceOperations, TaskView, WeakPage, WeakTask } from '@saga/shared';
import classNames from 'classnames';
import React from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { FileText, CheckCircle, MoreHorizontal, Users, Trash, Lock, Globe, File } from 'react-feather';
import { PageContextMenuPopOver } from '../popover/PageContextMenu';
import Button from '../styled/Button';
import { useTranslation } from 'react-i18next';
import TaskStatusSelect from '../tasks/TaskStatusSelect';
import { track } from '@/analytics';
import { useSpace } from '../SpaceProvider';
import { TaskContextMenuPopOver } from '../popover/TaskContextMenuButton';
import { ViewContextDropdown } from '../table/ViewContextButton';
import SidebarExpandableMenu from './SidebarExpandableMenu';
import useInterfaceSettings from '@/hooks/useInterfaceSettings';
import useSortablePages from '@/hooks/useSortablePages';
import { usePartialPages } from '@/hooks/SpaceHooks';
import { useTasks } from '../tasks/hooks';
import { useOpenLocation } from '../PageNavigationProvider';
import { TableIcon } from '../icons/Table';

export function SidebarItemButton({
    isSelected,
    onClick,
    children,
    testId,
}: {
    isSelected?: boolean;
    onClick(event: React.MouseEvent): void;
    children: React.ReactNode;
    testId?: string;
}) {
    return (
        <Button.Plain data-testid={testId} type="button" isSelected={isSelected} onClick={onClick} widthFull>
            <div className="pl-2 px-1 py-1">
                <div className="h-6 space-x-2 flex flex-grow items-center z-10 text-left font-medium overflow-x-visible">
                    {children}
                </div>
            </div>
        </Button.Plain>
    );
}

SidebarItemButton.ContextMenuContainer = function SidebarItemButtonContextMenuContainer({
    pinned,
    children,
}: {
    pinned: boolean;
    children: React.ReactNode;
}) {
    return (
        <div
            className={classNames('absolute z-20 right-0 top-0 bottom-0 px-1 items-center', {
                'hidden group-hover:flex': !pinned,
                flex: pinned,
            })}
        >
            {children}
        </div>
    );
};

export type SidebarItemDroppable =
    | DndSidebarPage
    | DndSidebarTask
    | DndSidebarCollection
    | DndSidebarPageView
    | DndSidebarTaskView
    | DndSidebarCollection;

const accept = [
    dndTypes.SIDEBAR_PAGE,
    dndTypes.SIDEBAR_TASK,
    dndTypes.SIDEBAR_COLLECTION,
    dndTypes.SIDEBAR_PAGE_VIEW,
    dndTypes.SIDEBAR_TASK_VIEW,
];

function useSidebarItemDrop({
    enabled,
    onDrop,
    dropRef,
}: {
    enabled?: boolean;
    onDrop(item: SidebarItemDroppable, dropArea: DndOverArea | null): void;
    dropRef: React.RefObject<HTMLDivElement>;
}) {
    const [{ overArea }, connectDrop] = useDrop<SidebarItemDroppable, unknown, { overArea: DndOverArea | null }>({
        accept,
        canDrop() {
            return Boolean(enabled);
        },
        collect(monitor) {
            const isOver = monitor.isOver({ shallow: true });
            let overArea: DndOverArea | null = null;
            const offset = monitor.getClientOffset();

            if (isOver && dropRef.current && offset) {
                const rect = dropRef.current.getBoundingClientRect();
                const isOverHalf = (offset.y ?? 0) >= rect.top + rect.height / 2;
                overArea = isOverHalf ? 'bottom' : 'top';
            }

            return { overArea };
        },
        drop(item) {
            onDrop(item, overArea);
        },
    });

    connectDrop(dropRef);

    return { overArea };
}

SidebarItemButton.Draggable = function SidebarItemButtonDraggable({
    id,
    enabled,
    children,
    dndType,
    onDrop,
}: {
    id: string;
    enabled?: boolean;
    children: React.ReactNode;
    dndType: SidebarItemDroppable['type'];
    onDrop(item: SidebarItemDroppable | Collection, dropArea: DndOverArea): void;
}) {
    const ref = React.useRef<HTMLDivElement>(null);
    const [{ isDragging }, connectDrag] = useDrag(
        {
            canDrag: enabled,
            type: dndType,
            collect(monitor) {
                return {
                    isDragging: monitor.isDragging(),
                };
            },
            item: {
                id,
                type: dndType,
            },
        },
        [id, dndType],
    );

    const { overArea } = useSidebarItemDrop({
        enabled,
        onDrop,
        dropRef: ref,
    });

    connectDrag(ref);

    return (
        <div className="w-full relative" ref={ref}>
            {overArea === 'top' && (
                <div className="absolute left-0 right-0 -top-1 mx-auto max-w-700 bg-saga-blue-light opacity-25 h-1 rounded-sm"></div>
            )}
            <div className={classNames({ 'opacity-25 bg-saga-bg-blue dark:bg-transparent': isDragging })}>
                {children}
            </div>
            {overArea === 'bottom' && (
                <div className="absolute left-0 right-0 -bottom-1 mx-auto max-w-700 bg-saga-blue-light opacity-25 h-1 rounded-sm"></div>
            )}
        </div>
    );
};

SidebarItemButton.Container = React.forwardRef<HTMLDivElement, { children: React.ReactNode }>(
    function SidebarItemButtonContainer({ children }, ref) {
        return (
            <div ref={ref} className="relative w-full flex flex-row items-center rounded group">
                {children}
            </div>
        );
    },
);

const moreIcon = <MoreHorizontal size={14} className="stroke-gray-dark" />;

SidebarItemButton.TaskButton = React.forwardRef<
    HTMLDivElement,
    {
        isSelected: boolean;
        onClick(event: React.MouseEvent): void;
        children: React.ReactNode;
        showContextMenu?: boolean;
        task: Pick<WeakTask, 'id' | 'title' | 'archivedAt' | 'state'>;
        source?: string;
    }
>(function CollectionsTaskButton({ isSelected, onClick, children, showContextMenu, task, source }, ref) {
    const [contextMenuOpen, setContextMenuOpen] = React.useState(false);
    const isMobile = useMobile();
    const { space } = useSpace();
    const { t } = useTranslation();
    const contextMenuRef = React.useRef<HTMLButtonElement>(null);

    return (
        <SidebarItemButton.Container ref={ref}>
            <SidebarItemButton isSelected={isSelected} onClick={onClick}>
                <div className="py-1 mr-[2px]">
                    <TaskStatusSelect
                        status={task.state}
                        size="xs"
                        onChange={(state) => {
                            track('change-task-state', { state, source: source ? source : 'task-table' });
                            SpaceOperations.updatePartialTask(space, task.id, {
                                state,
                                completedDate: state === 'DONE' ? new Date().toISOString() : null,
                            });
                        }}
                    />
                </div>
                <div className="min-w-0 font-medium">{children}</div>
            </SidebarItemButton>
            {showContextMenu && !isMobile && (
                <SidebarItemButton.ContextMenuContainer pinned={contextMenuOpen}>
                    <Button.NestedButton onClick={() => setContextMenuOpen((isOpen) => !isOpen)} ref={contextMenuRef}>
                        {moreIcon}
                        <span className="sr-only">{t('common.open_page_context_menu')}</span>
                    </Button.NestedButton>
                </SidebarItemButton.ContextMenuContainer>
            )}
            {contextMenuOpen && (
                <TaskContextMenuPopOver
                    attachToRef={contextMenuRef}
                    isOpen
                    task={task}
                    onClose={() => setContextMenuOpen(false)}
                    editor={null}
                />
            )}
        </SidebarItemButton.Container>
    );
});

SidebarItemButton.PageButton = React.forwardRef<
    HTMLDivElement,
    {
        isSelected: boolean;
        onClick(event: React.MouseEvent): void;
        children: React.ReactNode;
        showContextMenu?: boolean;
        page: Pick<WeakPage, 'id' | 'title' | 'archivedAt' | 'icon' | 'isTemplate'>;
    }
>(function CollectionsPageButton({ isSelected, onClick, children, showContextMenu, page }, ref) {
    const [contextMenuOpen, setContextMenuOpen] = React.useState(false);
    const contextMenuRef = React.useRef<HTMLButtonElement>(null);
    const isMobile = useMobile();
    const { t } = useTranslation();

    return (
        <SidebarItemButton.Container ref={ref}>
            <SidebarItemButton isSelected={isSelected} onClick={onClick}>
                <div className="py-1 mr-[2px]">
                    <PageIcon icon={page.icon} isTemplate={page.isTemplate} />
                </div>
                <div className="min-w-0 font-medium">{children}</div>
            </SidebarItemButton>
            {showContextMenu && !isMobile && (
                <SidebarItemButton.ContextMenuContainer pinned={contextMenuOpen}>
                    <Button.NestedButton onClick={() => setContextMenuOpen((isOpen) => !isOpen)} ref={contextMenuRef}>
                        {moreIcon}
                        <span className="sr-only">{t('common.open_page_context_menu')}</span>
                    </Button.NestedButton>
                </SidebarItemButton.ContextMenuContainer>
            )}
            {contextMenuOpen && (
                <PageContextMenuPopOver
                    attachToRef={contextMenuRef}
                    isOpen
                    page={page}
                    onClose={() => setContextMenuOpen(false)}
                    editor={null}
                />
            )}
        </SidebarItemButton.Container>
    );
});

SidebarItemButton.SharedPageButton = React.forwardRef<
    HTMLDivElement,
    {
        isSelected: boolean;
        onClick(event: React.MouseEvent): void;
        children: React.ReactNode;
        page: SharedPageItem;
    }
>(function SharedPageButton({ isSelected, onClick, children, page }, ref) {
    return (
        <SidebarItemButton.Container ref={ref}>
            <SidebarItemButton isSelected={isSelected} onClick={onClick}>
                <div className="py-1 mr-[2px]">
                    <PageIcon icon={page.data.icon} isTemplate={false} />
                </div>
                <div className="min-w-0 font-medium">{children}</div>
            </SidebarItemButton>
        </SidebarItemButton.Container>
    );
});

SidebarItemButton.PageViewButton = React.forwardRef<
    HTMLDivElement,
    {
        selectedId?: string;
        onClick(event: React.MouseEvent): void;
        showContextMenu?: boolean;
        view: PageView;
    }
>(function PageViewButton({ selectedId, onClick, showContextMenu, view }, ref) {
    const openLocation = useOpenLocation();

    const { spacePages, sharedPages } = useSortablePages(view);

    const children = React.useMemo(
        () =>
            view.mode === 'shared'
                ? sharedPages.map((page) => (
                      <SidebarItemButton.SharedPageButton
                          key={page.id}
                          isSelected={selectedId === page.id}
                          onClick={(event) => {
                              openLocation({ type: 'page', pageId: page.id, blockId: undefined, public: true }, event);
                          }}
                          page={page}
                      >
                          <div className="truncate leading-normal text-sm">{page.title}</div>
                      </SidebarItemButton.SharedPageButton>
                  ))
                : spacePages.map((page) => (
                      <SidebarItemButton.PageButton
                          key={page.id}
                          isSelected={selectedId === page.id}
                          onClick={(event) => {
                              openLocation({ type: 'page', pageId: page.id, blockId: undefined }, event);
                          }}
                          page={page.data}
                          showContextMenu={showContextMenu}
                      >
                          <div className="truncate leading-normal text-sm">{page.title}</div>
                      </SidebarItemButton.PageButton>
                  )),
        [selectedId, spacePages, showContextMenu, openLocation, sharedPages, view.mode],
    );

    const Icon = React.useMemo(() => {
        switch (view.mode) {
            case 'shared':
                return <Users size={14} />;
            case 'non-deleted':
                return <File size={14} />;
            case 'deleted':
                return <Trash size={14} />;
            case 'templates':
                return <TableIcon size={14} />;
            case 'private':
                return <Lock size={14} />;
            case 'public':
                return <Globe size={14} />;
            default:
                return <FileText size={14} />;
        }
    }, [view.mode]);

    return (
        <ViewButton
            ref={ref}
            selectedId={selectedId}
            onClick={onClick}
            showContextMenu={showContextMenu}
            view={view}
            type="page-view"
            Icon={Icon}
            expandable={children.length > 0}
        >
            {children}
        </ViewButton>
    );
});

SidebarItemButton.TaskViewButton = React.forwardRef<
    HTMLDivElement,
    {
        selectedId?: string;
        onClick(event: React.MouseEvent): void;
        showContextMenu?: boolean;
        view: TaskView;
    }
>(function TaskViewButton({ selectedId, onClick, showContextMenu, view }, ref) {
    const openLocation = useOpenLocation();
    const stateFilter = React.useMemo(() => {
        switch (view.allTasksMode) {
            case 'completed':
                return ['DONE'];
            case 'incomplete':
                return ['OPEN', 'IN_PROGRESS'];
            default:
                return [];
        }
    }, [view.allTasksMode]);

    const archiveFilter = React.useMemo(() => {
        switch (view.allTasksMode) {
            case 'archived':
                return 'deleted';
            default:
                return 'non-deleted';
        }
    }, [view.allTasksMode]);

    const tasks = useTasks({
        mode: 'shallow',
        stateFilter: stateFilter as WeakTask['state'][],
        assigneeFilter: view.allTasksAssignee ?? { kind: 'all' },
        priorityFilter: view.allTasksPriority ?? 'all',
        labelsFilter: view.allTasksLabels ?? undefined,
        collectionsFilter: view.allTasksCollections ?? undefined,
        creatorsFilter: view.allTasksCreators ?? undefined,
        search: view.search ?? undefined,
        archivedFilter: archiveFilter,
    });

    const children = React.useMemo(() => {
        return tasks.map((task) => (
            <SidebarItemButton.TaskButton
                key={task.id}
                isSelected={selectedId === task.id}
                onClick={(event) => {
                    openLocation({ type: 'task', taskId: task.id, blockId: undefined }, event);
                }}
                task={task}
                showContextMenu={showContextMenu}
            >
                <div className="truncate leading-normal text-sm">{task.title}</div>
            </SidebarItemButton.TaskButton>
        ));
    }, [selectedId, tasks, showContextMenu, openLocation]);

    return (
        <ViewButton
            ref={ref}
            selectedId={selectedId}
            onClick={onClick}
            showContextMenu={showContextMenu}
            view={view}
            type="task-view"
            Icon={<CheckCircle size={14} />}
            expandable={tasks.length > 0}
        >
            {children}
        </ViewButton>
    );
});

const ViewButton = React.forwardRef<
    HTMLDivElement,
    {
        selectedId?: string;
        onClick(event: React.MouseEvent): void;
        showContextMenu?: boolean;
        view: PageView | TaskView;
        type: 'page-view' | 'task-view';
        children: React.ReactNode;
        Icon: JSX.Element;
        expandable: boolean;
    }
>(function ViewButton({ selectedId, onClick, showContextMenu, view, type, children, Icon, expandable }, ref) {
    const [isExpanded, setExpanded] = React.useState(false);
    const [contextMenuOpen, setContextMenuOpen] = React.useState(false);
    const isMobile = useMobile();
    const [, setInterfaceSettings] = useInterfaceSettings();
    const { t } = useTranslation();
    const contextMenuRef = React.useRef<HTMLButtonElement>(null);

    return (
        <SidebarExpandableMenu.Gap active={isExpanded}>
            <SidebarItemButton.Container ref={ref}>
                <SidebarExpandableMenu.Button
                    Icon={Icon}
                    isExpandable={expandable}
                    isExpanded={isExpanded}
                    isSelected={selectedId === view.id}
                    onClick={(event) => {
                        if (isMobile) setInterfaceSettings({ fixedSidebar: false });
                        onClick(event);
                    }}
                    onExpandClick={() => setExpanded(!isExpanded)}
                    isMobile={isMobile}
                >
                    {view.title}
                </SidebarExpandableMenu.Button>
                {showContextMenu && !isMobile && (
                    <SidebarItemButton.ContextMenuContainer pinned={contextMenuOpen}>
                        <Button.NestedButton
                            onClick={(e) => {
                                e.stopPropagation();
                                setContextMenuOpen((isOpen) => !isOpen);
                            }}
                            ref={contextMenuRef}
                        >
                            {moreIcon}
                            <span className="sr-only">{t('common.open_page_context_menu')}</span>
                        </Button.NestedButton>
                    </SidebarItemButton.ContextMenuContainer>
                )}
                {contextMenuOpen && (
                    <ViewContextDropdown
                        isOpen
                        view={view}
                        type={type}
                        onClose={() => setContextMenuOpen(false)}
                        buttonRef={contextMenuRef}
                    />
                )}
            </SidebarItemButton.Container>

            <div className="overflow-y-auto hide-scrollbar" style={{ maxHeight: '24rem' }}>
                <SidebarExpandableMenu.OpenCloseAnimation isOpen={isExpanded}>
                    <SidebarExpandableMenu.Indent>
                        <SidebarExpandableMenu.Gap>{children}</SidebarExpandableMenu.Gap>
                    </SidebarExpandableMenu.Indent>
                </SidebarExpandableMenu.OpenCloseAnimation>
            </div>
        </SidebarExpandableMenu.Gap>
    );
});

export default SidebarItemButton;
