import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import Button from '../styled/Button';
import { TaskLabel } from '@saga/shared';
import Dropdown, { InputDropdown, InputDropdownRenderItem } from '../popover/Dropdown';
import { Tag } from 'react-feather';

export const TaskLabelColors = ['#30383F', '#8A8A8A', '#E86C65', '#E39B4C', '#FC632C', '#4EC46E', '#396ED8', '#A57CFA'];

export default function LabelSelect({
    availableLabels,
    selectedIds,
    onSelectLabel,
    onCreateLabel,
    onRemoveLabel,
    onSelectColor,
    disabled,
    variant = 'default',
    showEmptyState = true,
    limit,
}: {
    availableLabels: TaskLabel[];
    selectedIds: string[];
    onSelectLabel(id: string): void;
    onCreateLabel(title: string): void;
    onRemoveLabel(id: string): void;
    onSelectColor(id: string, color: string): void;
    disabled: boolean;
    variant?: 'default' | 'minimal';
    showEmptyState?: boolean;
    limit?: number;
}) {
    const [isLabelsDropdownOpen, setIsLabelsDropdownOpen] = React.useState(false);
    const [isColorsDropdownOpen, setIsColorsDropdownOpen] = React.useState(false);

    const divRef = React.useRef<HTMLDivElement>(null);
    const buttonRef = React.useRef<HTMLButtonElement>(null);

    const { t } = useTranslation();

    const labels = selectedIds
        .map((id) => availableLabels.find((label) => label.id === id))
        .filter(Boolean) as TaskLabel[];

    return (
        <div
            ref={divRef}
            className={classNames('hover-trigger max-w-400', {
                'flex truncate rounded': labels.length,
            })}
        >
            {!labels.length &&
                showEmptyState &&
                (variant === 'default' ? (
                    <Button.DashedPill
                        disabled={disabled}
                        onClick={() => setIsLabelsDropdownOpen(true)}
                        ref={buttonRef}
                    >
                        {t('tasks.no_label')}
                    </Button.DashedPill>
                ) : (
                    <Button.Dashed
                        className="flex items-center justify-center rounded-5 h-[22px] w-[22px]"
                        data-testid="task-label"
                        onClick={(e) => {
                            if (disabled) return;

                            e.stopPropagation();
                            setIsLabelsDropdownOpen(!isLabelsDropdownOpen);
                        }}
                        ref={buttonRef}
                    >
                        <span className="sr-only">Add Task Label</span>
                        <Tag className="h-3.5 w-3.5" />
                    </Button.Dashed>
                ))}

            {!!labels.length && (
                <div className="flex flex-wrap gap-1.5">
                    {labels
                        .sort((a, b) => (limit == null ? a.title.localeCompare(b.title) : 0))
                        .slice(0, limit)
                        .map((label) => (
                            <LabelPill
                                key={label.id}
                                label={label}
                                onRemove={() => onRemoveLabel(label.id)}
                                disabled={disabled}
                                variant={variant}
                                onClick={() => {
                                    setIsLabelsDropdownOpen(true);
                                    setIsColorsDropdownOpen(false);
                                }}
                                extra={limit != null && labels.length > limit ? ` +${labels.length - limit}` : ''}
                            />
                        ))}
                </div>
            )}

            {isLabelsDropdownOpen && (
                <LabelsDropdown
                    attachToRef={buttonRef.current ? buttonRef : divRef}
                    isOpen={isLabelsDropdownOpen}
                    onClose={() => setIsLabelsDropdownOpen(false)}
                    onSelectLabel={onSelectLabel}
                    availableLabels={availableLabels}
                    onCreateLabel={(title) => {
                        onCreateLabel(title);
                        setIsLabelsDropdownOpen(false);
                        setIsColorsDropdownOpen(true);
                    }}
                    selectedIds={selectedIds}
                    onRemoveLabel={onRemoveLabel}
                />
            )}
            {isColorsDropdownOpen && (
                <ColorPickerDropdown
                    attachToRef={divRef.current ? divRef : buttonRef}
                    isOpen={isColorsDropdownOpen}
                    onClose={() => setIsColorsDropdownOpen(false)}
                    onSelectColor={(color) => {
                        onSelectColor(selectedIds[selectedIds.length - 1], color);
                        setIsColorsDropdownOpen(false);
                    }}
                    availableColors={TaskLabelColors}
                    selectedColor={TaskLabelColors[0]}
                />
            )}
        </div>
    );
}

function LabelPill({
    label,
    onRemove,
    disabled,
    variant,
    onClick,
    extra,
}: {
    label: TaskLabel;
    onRemove: () => void;
    disabled: boolean;
    variant: 'default' | 'minimal';
    onClick: () => void;
    extra?: string;
}) {
    return (
        <div className="inline-block max-w-full" key={label.id}>
            <Button.RemovableOption disabled={disabled || variant === 'minimal'} onRemove={onRemove}>
                <div
                    onClick={(e) => {
                        if (disabled) return;
                        e.stopPropagation();
                        onClick();
                    }}
                    className={classNames('cursor-pointer space-x-1.5 flex items-center')}
                >
                    <div className="ml-1 w-2 h-2 rounded-full" style={{ backgroundColor: label.color }} />
                    <span className="truncate max-w-20">{label.title}</span>
                    {extra && <span className="text-xs text-saga-gray-500">{extra}</span>}
                </div>
            </Button.RemovableOption>
        </div>
    );
}

function LabelsDropdown({
    attachToRef,
    isOpen,
    onClose,
    onSelectLabel,
    availableLabels,
    selectedIds,
    onCreateLabel,
    onRemoveLabel,
}: {
    attachToRef: React.RefObject<HTMLElement>;
    isOpen: boolean;
    onClose(): void;
    availableLabels: TaskLabel[];
    selectedIds: string[];
    onSelectLabel(id: string): void;
    onRemoveLabel(id: string): void;
    onCreateLabel(title: string): void;
}) {
    const { t } = useTranslation();

    return (
        <InputDropdown<TaskLabel | { id: null }>
            attachToRef={attachToRef}
            isOpen={isOpen}
            onClose={onClose}
            dropdownProps={{ align: 'left' }}
            onSubmit={({ selectedItem, search }) => {
                if (selectedItem.id) {
                    onSelectLabel(selectedItem.id);
                } else {
                    onCreateLabel(search);
                }
            }}
            inputProps={{
                placeholder: t('tasks.new_label_placeholder') as string,
                title: t('tasks.new_label_placeholder') as string,
            }}
            renderItems={({ search }) => {
                const selectedLabels = selectedIds
                    .map((id) => availableLabels.find((label) => label.id === id))
                    .filter(Boolean) as TaskLabel[];
                const filteredLabels = availableLabels
                    .filter(
                        (label) =>
                            label.title.toLowerCase().includes(search.toLowerCase()) && !selectedIds.includes(label.id),
                    )
                    .sort((a, b) => a.title.localeCompare(b.title))
                    .map((label): InputDropdownRenderItem<TaskLabel> => {
                        return {
                            render({ isSelected, setSelection, submit }) {
                                return (
                                    <div key={label.id}>
                                        <Button.PopOverButton
                                            selected={isSelected}
                                            onClick={submit}
                                            onMouseEnter={setSelection}
                                            hoverable
                                        >
                                            <div className="space-x-2 flex items-center max-w-52">
                                                <div
                                                    className="w-2 h-2 rounded-full flex-shrink-0"
                                                    style={{ backgroundColor: label.color }}
                                                />
                                                <span className="truncate">{label.title}</span>
                                            </div>
                                        </Button.PopOverButton>
                                    </div>
                                );
                            },
                            item: label,
                        };
                    });

                return [
                    {
                        render({ setSelection }) {
                            return selectedLabels.length > 0 ? (
                                <div
                                    key={'selected-labels'}
                                    className="flex flex-wrap gap-2 max-w-52 py-2"
                                    onMouseEnter={() => setSelection()}
                                >
                                    {selectedLabels.map((label) => (
                                        <LabelPill
                                            key={label.id}
                                            label={label}
                                            onRemove={() => onRemoveLabel(label.id)}
                                            disabled={false}
                                            variant="default"
                                            onClick={() => {}}
                                        />
                                    ))}
                                </div>
                            ) : null;
                        },
                        item: { id: null },
                    },

                    {
                        render({ isSelected, setSelection, submit }) {
                            return (
                                <div key={'search-label'}>
                                    <div className="flex items-center h-[26px] px-2 py-1 font-normal text-xs text-saga-gray-500">
                                        {filteredLabels.length
                                            ? t('tasks.select_or_create_label')
                                            : t('tasks.add_new_label')}
                                    </div>
                                    {search?.trim().length &&
                                    !availableLabels.some(
                                        (label) => label.title.toLowerCase() === search.toLowerCase(),
                                    ) ? (
                                        <Button.PopOverButton
                                            selected={isSelected}
                                            onClick={submit}
                                            onMouseEnter={setSelection}
                                            hoverable
                                        >
                                            <div className="space-x-2 flex items-center">
                                                <Tag size={16} />
                                                <span>{`${search} — `}</span>
                                                <span>{t('tasks.add_new_label')}</span>
                                            </div>
                                        </Button.PopOverButton>
                                    ) : null}
                                </div>
                            );
                        },
                        item: { id: null },
                    },
                    ...filteredLabels,
                ];
            }}
        />
    );
}

function ColorPickerDropdown({
    attachToRef,
    isOpen,
    onClose,
    onSelectColor,
    availableColors,
    selectedColor,
}: {
    attachToRef: React.RefObject<HTMLElement>;
    isOpen: boolean;
    onClose(): void;
    availableColors: string[];
    selectedColor: string;
    onSelectColor(color: string): void;
}) {
    const { t } = useTranslation();

    return (
        <Dropdown attachToRef={attachToRef} isOpen={isOpen} onClose={onClose}>
            <div className="flex items-center h-[26px] px-2 py-1 font-normal text-xs text-saga-gray-500">
                {t('settings.labels.edit_modal.select_color')}
            </div>
            <LabelColorPicker
                availableColors={availableColors}
                selectedColor={selectedColor}
                onSelectColor={onSelectColor}
            />
        </Dropdown>
    );
}

export function LabelColorPicker({
    availableColors,
    selectedColor,
    onSelectColor,
}: {
    availableColors: string[];
    selectedColor: string;
    onSelectColor(color: string): void;
}) {
    return (
        <div className="flex flex-wrap gap-1">
            {availableColors.map((color) => (
                <div
                    key={color}
                    className={classNames(
                        'w-8 h-8 rounded-md flex items-center justify-center hover:bg-saga-gray-150 dark:hover:bg-saga-gray-800',
                        { 'hover:bg-saga-gray-150 dark:hover:bg-saga-gray-800': color === selectedColor },
                    )}
                    onClick={() => onSelectColor(color)}
                >
                    <div className="w-4 h-4 rounded-full" style={{ backgroundColor: color }} />
                </div>
            ))}
        </div>
    );
}
