import { map } from 'unist-util-map';
import reduce from 'unist-util-reduce';
import * as md from 'mdast-builder';
import {
    BlockType,
    Root,
    isSagaText,
    isSagaElement,
    isGoogleDriveFileLink,
    BlockBuilder,
    isDivider,
    isKatexBlock,
    isKatexInline,
    isTableRow,
    isTableCell,
    isInlineCollection,
    isLinearIssue,
    isInlinePageLink,
    isDateBlock,
    isLiveBlockSource,
    isIndentContainer,
    isMention,
    isCallout,
    isBlockContainer,
    isPrettyLink,
    isEmbed,
    isLiveBlock,
    isListItem,
    isCheckListItem,
    isNumberedList,
    isNumberedListItem,
    isTitle,
    isBlockquote,
    isHeading,
    isCode,
    isAnyListItem,
    hasChildren,
    HelperBlockType,
    SagaText,
    SagaElement,
    isAISuggestedText,
    isFile,
} from '..';
import { DateTime } from 'luxon';
import * as R from 'ramda';
import { Node } from 'slate';
import { isTaskBlock } from '../types';
import { toMarkdown } from 'mdast-util-to-markdown';
import { gfmToMarkdown } from 'mdast-util-gfm';
import { gfmTaskListItemToMarkdown } from 'mdast-util-gfm-task-list-item';

export const codeLanguages: Record<string, string> = {
    js: 'javascript',
};

const invertedCodeMap = R.invertObj(codeLanguages);

function blocksToMdText(blocks: (SagaElement | SagaText)[]) {
    return blocks.map((block) => md.text(Node.string(block)));
}

function addHelperTypeToSagaText(sagaText: SagaText) {
    return { ...sagaText, type: HelperBlockType.TEXT };
}

/**
 * Group adjacent similar nodes type,
 * or if list is followed by a `block-container`, needed for convert nested list in mdast
 */
const groupNodes = (nodes: (SagaElement | SagaText)[]): (SagaElement | SagaText)[][] =>
    R.groupWith(
        R.anyPass([
            (a, b) => (isSagaElement(a) && isSagaElement(b) && a?.type === b?.type) || (isSagaText(a) && isSagaText(b)),
            (a, b) => isAnyListItem(a) && isBlockContainer(b),
        ]),
        nodes,
    );

export function prepareLists(nodes: (SagaElement | SagaText)[]) {
    const groupedNodes = groupNodes(nodes);

    return R.flatten(
        groupedNodes.map((nodes) => {
            const ignoreBlockParent = nodes.filter((n) => !isBlockContainer(n));

            if (ignoreBlockParent.every(isListItem)) {
                return md.list('unordered', blocksToMdText(nodes));
            }
            if (ignoreBlockParent.every(isCheckListItem)) {
                return md.list('unordered', blocksToMdText(nodes));
            }
            return nodes;
        }),
    );
}

export function sagaToMdast(root: Root) {
    const reducedRoot = reduce(root, (node) => {
        if (isBlockContainer(node)) {
            const [first, ...rest] = node.children;

            if (isAnyListItem(first)) {
                const listItem = (() => {
                    if (isCheckListItem(first)) {
                        return md.list('unordered', first.children);
                    } else if (isNumberedListItem(first)) {
                        return md.listItem(first.children);
                    } else {
                        return md.list('unordered', first.children);
                    }
                })();

                return { ...listItem, children: listItem.children.concat(rest) };
            }

            return node.children;
        }

        if (isCallout(node)) {
            return BlockBuilder.callout(node.children);
        }

        if (isLiveBlockSource(node) || isMention(node)) {
            if (node.children.length > 0 && node.children.every(isListItem)) {
                return md.list('unordered', node.children);
            }

            return node.children;
        }

        if (isIndentContainer(node)) return node.children;

        if (isDateBlock(node)) {
            const date = DateTime.fromISO(node.date);
            return BlockBuilder.paragraph([BlockBuilder.text(date.toFormat('LLLL d, yyyy'))]);
        }

        if (isKatexBlock(node) || isKatexInline(node)) {
            return BlockBuilder.code(node.value, 'latex');
        }

        if (isGoogleDriveFileLink(node)) {
            if (node.state?.type === 'loaded') {
                return BlockBuilder.link(node.state.file.webViewLink, [BlockBuilder.text(node.state.file.name)]);
            }
            return BlockBuilder.link('', [BlockBuilder.text('')]);
        }

        if (isLinearIssue(node)) {
            if (node.state?.type === 'loaded') {
                return BlockBuilder.link(node.state.issue.url, [BlockBuilder.text(node.state.issue.identifier)]);
            }

            return BlockBuilder.link('', [BlockBuilder.text('')]);
        }

        if (isPrettyLink(node)) {
            const linkText = 'title' in node.metadata ? node.metadata.title : node.url;
            return BlockBuilder.link(node.url, [BlockBuilder.text(linkText)]);
        }

        if (isEmbed(node)) {
            const linkText = 'title' in node.metadata ? node.metadata.title : node.url;
            return BlockBuilder.link(node.url, [BlockBuilder.text(linkText)]);
        }

        if (isLiveBlock(node)) {
            return { ...node, children: node.staticBlocks };
        }

        if (isListItem(node)) {
            return { ...node, children: [md.listItem(node.children)] };
        }

        if (isCheckListItem(node)) {
            return {
                ...node,
                children: [{ ...md.listItem(BlockBuilder.paragraph(node.children)), checked: node.checked ?? false }],
            };
        }

        if (isInlineCollection(node)) {
            return addHelperTypeToSagaText(
                BlockBuilder.text(node.staticCollection?.title ?? `collection:${node.collectionId}`),
            );
        }

        if (isDivider(node)) {
            return { ...node, children: [BlockBuilder.divider()] };
        }

        if (isTaskBlock(node)) {
            const title = node.staticTask ? node.staticTask.title : '';
            return {
                ...BlockBuilder.checkListItem('', node.staticTask?.state === 'DONE'),
                children: [
                    { ...md.listItem(BlockBuilder.paragraph(title)), checked: node.staticTask?.state === 'DONE' },
                ],
            };
        }

        if (isFile(node)) {
            return { ...node, children: [BlockBuilder.text(node.title || '')] };
        }

        if (isSagaElement(node) && hasChildren(node)) {
            return { ...node, children: prepareLists(node.children) };
        }

        if (isAISuggestedText(node)) {
            return { ...node, children: node.children[0].text };
        }

        if (isSagaText(node)) {
            return addHelperTypeToSagaText(node);
        }

        return node;
    });

    return map(reducedRoot, (node) => {
        if (isTableRow(node)) {
            return md.tableRow(node.children);
        }

        if (isInlinePageLink(node)) {
            const result = md.text(node.staticPage?.title ?? `page:${node.pageId}`);
            return result;
        }

        if (isTableCell(node)) {
            return md.tableCell(node.children);
        }

        if (isListItem(node)) {
            return md.list('unordered', node.children);
        }

        if (isNumberedList(node)) {
            return md.list('ordered', node.children);
        }

        if (isNumberedListItem(node)) {
            return md.listItem(node.children);
        }

        if (isTitle(node)) {
            return md.heading(1, node.children);
        }

        if (isAISuggestedText(node)) {
            return md.text(node.children[0].text);
        }

        if (isLiveBlock(node)) {
            return md.blockquote(node.children);
        }

        if (isBlockquote(node)) {
            return md.blockquote(node.children);
        }

        if (isSagaText(node)) {
            if (node.bold) {
                return md.strong(md.text(node.text));
            }

            if (node.italic) {
                return md.emphasis(md.text(node.text));
            }
            return md.text(node.text);
        }

        if (isHeading(node)) {
            switch (node?.type) {
                case BlockType.HEADING_3:
                    return md.heading(3, blocksToMdText(node.children));
                case BlockType.HEADING_2:
                    return md.heading(2, blocksToMdText(node.children));
                default:
                    return md.heading(1, blocksToMdText(node.children));
            }
        }

        if (isCode(node)) {
            return md.code(invertedCodeMap[node.language ?? ''] ?? node.language ?? '', node.content);
        }

        if (isCheckListItem(node)) {
            return md.list('unordered', node.children);
        }
        if (isFile(node)) {
            return md.link(node.url || '', node.title || '');
        }

        if (isDivider(node)) {
            return md.separator;
        }

        if (isCallout(node)) {
            //make callout structure like notion, put the content inside <aside> tag
            const calloutContent = node.children.map((child) => sagaToMdast(child));
            const calloutMD: any = calloutContent.map((fragment) => {
                return toMarkdown(
                    // @ts-expect-error types are not matching up from mdast and unist
                    fragment,
                    {
                        listItemIndent: 'one',
                        bullet: '-',
                        incrementListMarker: true,
                        extensions: [gfmToMarkdown(), gfmTaskListItemToMarkdown],
                    },
                );
            });
            const calloutContentString = calloutMD.join('\n');

            // Add a newline at the beginning to avoid <aside>\nExample text
            const formattedContent = `\n${calloutContentString}`;

            return md.html(`<aside>\n${formattedContent}\n</aside>`);
        }

        return node;
    });
}
