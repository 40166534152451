import { BlockBuilder } from '../..';
import { newBlankPage } from '../../pageUtils';
import { WeakTask } from '../../types';

// translation prompt
// this file contains strings that are displayed in the UI of a knowledge management application. Translate all strings from English to Brazilian Portuguese

export function newSagaBasicsPageEn(task: WeakTask, collectionIds: string[]) {
    return newBlankPage({
        title: 'Saga basics',
        icon: { type: 'emoji', colons: ':wave:' },
        collections: collectionIds,
        blocks: [
            BlockBuilder.title('Saga basics'),
            BlockBuilder.callout([
                BlockBuilder.paragraph([BlockBuilder.text('Type anywhere on this page to edit it!')]),
            ]),
            BlockBuilder.paragraph(),
            BlockBuilder.image('https://saga-public.s3.amazonaws.com/1-block-selection-menu.webp'),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Hit '),
                BlockBuilder.text('@', { code: true }),
                BlockBuilder.text(' or '),
                BlockBuilder.text('/', { code: true }),
                BlockBuilder.text(
                    ' to open the autocomplete menu and add new content such as headings, to-dos, images, files, tables, and more.',
                ),
            ]),
            BlockBuilder.paragraph(),
            BlockBuilder.paragraph(),
            BlockBuilder.paragraph(),
            BlockBuilder.image('https://saga-public.s3.amazonaws.com/2-text-styling-menu.webp'),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Select text', { bold: true }),
                BlockBuilder.text(' to '),
                BlockBuilder.text('style it,', { italic: true }),
                BlockBuilder.text(' turn it into another type of content, '),
                BlockBuilder.text('highlight it', { highlight: true }),
                // todo highlight in red
                BlockBuilder.text(', or improve it with AI.'),
            ]),
            BlockBuilder.paragraph(),
            BlockBuilder.paragraph(),
            BlockBuilder.image('https://saga-public.s3.amazonaws.com/3-drag-and-drop-images-and-files.webp'),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Add images and files', { bold: true }),
                BlockBuilder.text(' by drag and dropping or pasting them.'),
            ]),
            BlockBuilder.paragraph(),
            BlockBuilder.paragraph(),
            BlockBuilder.image('https://saga-public.s3.amazonaws.com/4-drag-and-drop-blocks-of-content.webp'),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Drag and drop', { bold: true }),
                BlockBuilder.text(' any block by selecting and dragging the ☰ icon to the left side of the block.'),
            ]),
            BlockBuilder.paragraph(),
            BlockBuilder.paragraph(),
            BlockBuilder.image('https://saga-public.s3.amazonaws.com/5-unified-search-for-fast-navigation.webp'),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Hit '),
                BlockBuilder.text('⌘ + K', { code: true }),
                BlockBuilder.text(' (macOS) or '),
                BlockBuilder.text('Ctrl + K', { code: true }),
                BlockBuilder.text(' (Windows) to '),
                BlockBuilder.text('find anything', { bold: true }),
                BlockBuilder.text(' and navigate across your workspace.'),
            ]),
            BlockBuilder.paragraph(),
            BlockBuilder.paragraph(),
            BlockBuilder.image('https://saga-public.s3.amazonaws.com/6-creating-and-managing-tasks.webp'),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Create tasks', { bold: true }),
                BlockBuilder.text(' by opening the autocomplete menu and selecting '),
                BlockBuilder.text('Task', { code: true }),
                BlockBuilder.text(
                    '. Tasks are special type of pages with an assignee, due date, and priority. Open the task below to learn more 👇.',
                ),
            ]),
            BlockBuilder.taskBlock(task.id),
            BlockBuilder.paragraph(),
            BlockBuilder.paragraph(),
            BlockBuilder.image('https://saga-public.s3.amazonaws.com/7-adding-to-pinned.webp'),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Favorite ', { bold: true }),
                BlockBuilder.text('any page to add it to the sidebar by clicking the ☆ icon in the top right corner.'),
            ]),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Head to the 😍 Saga advanced page to explore more advanced ways to use Saga.'),
            ]),
            BlockBuilder.paragraph(),
            BlockBuilder.paragraph(),
            BlockBuilder.divider(),
            BlockBuilder.headingThree('Have a question?'),
            BlockBuilder.paragraph([
                BlockBuilder.text('Hit the '),
                BlockBuilder.text('?', { bold: true }),
                BlockBuilder.text(
                    ' button in the bottom right corner to email us, send feature requests, or join our user communities on ',
                ),
                BlockBuilder.link('https://discord.com/invite/cgz2mUEq7P', 'Discord'),
                BlockBuilder.text(', '),
                BlockBuilder.link(
                    'https://sagacommunity.slack.com/join/shared_invite/zt-13m3lrrdt-1x6~l6sLuR8CX~4c3fWwHA#/shared-invite/email',
                    'Slack',
                ),
                BlockBuilder.text(', or '),
                BlockBuilder.link('https://twitter.com/saga_HQ', 'X'),
                BlockBuilder.text(
                    '. Also, visit the help guides on our website and see how to make the most out of the app.',
                ),
            ]),
            BlockBuilder.rawPrettyLink('https://saga.so/guides', {
                _tag: 'loaded',
                description: "This guide will help you understand Saga's building blocks and get you up to speed.",
                title: '📍 Saga Guides',
                logo: 'https://saga.so/thumbnail.png',
            }),
            BlockBuilder.paragraph(),
        ],
    });
}
