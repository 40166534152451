import { useHistory } from 'react-router-dom';
import { track } from '@/analytics';
import { useWorkspaceContext } from '@/components/WorkspaceContext';
import * as api from '@saga/api';
import useWorkspaceSettings from './useWorkspaceSettings';

export default function useLeaveWorkspace() {
    const [, , clearSettings] = useWorkspaceSettings();
    const { currentUrlKey } = useWorkspaceContext();
    const history = useHistory();

    const [leaveWorkspace] = api.useLeaveSpaceMutation({
        variables: {
            input: {
                urlKey: currentUrlKey,
            },
        },
        onCompleted: (data) => {
            if (!data?.leaveSpace) return;

            clearSettings();
            track('leave-workspace');
            history.replace('/s');
            window.location.reload();
        },
    });

    return leaveWorkspace;
}
