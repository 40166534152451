import { SafeSpace } from '../types';
import transactInSpace from './transactInSpace';
import * as Y from 'yjs';

export default function removeFavorite(space: SafeSpace, favoriteId: string, userId?: string) {
    transactInSpace(space, () => {
        const userFavorites = space.map.get('userFavorites') as Y.Map<Y.Array<string>>;

        if (userId) {
            // Remove favorite for a specific user
            const favoritesArray = userFavorites.get(userId);

            if (!favoritesArray) {
                return;
            }

            const index = favoritesArray.toArray().indexOf(favoriteId);

            if (index !== -1) {
                favoritesArray.delete(index);
            }
        } else {
            // If userId is undefined, remove this favorite from all users
            userFavorites.forEach((favoritesArray) => {
                const index = favoritesArray.toArray().indexOf(favoriteId);

                if (index !== -1) {
                    favoritesArray.delete(index);
                }
            });
        }
    });
}
