import * as t from 'io-ts';

export type CookieType = 'space';

export const CookieTypes = {
    space: 'space' as const,
} as const;

export function buildPathForSpace(urlKey: string) {
    // This is the path of the S3 resource
    return `/spaces/${urlKey}`;
}

export const cloudfrontMetadataD = t.type({
    expirationMs: t.number,
    urlKey: t.string,
});
export const cloudfrontMetadataDV2 = t.type({
    expirationMs: t.number,
    urlKey: t.string,
});

export type CloudfrontMetadata = t.TypeOf<typeof cloudfrontMetadataD>;

export const signedCookiesRequestInputD = t.type({
    token: t.union([t.string, t.undefined]),
    urlKey: t.string,
});

export const signedCookiesRequestInputDV2 = t.type({
    token: t.union([t.string, t.undefined]),
    urlKey: t.string,
    type: t.literal('space'),
});

export type SignedCookiesRequestInput = t.TypeOf<typeof signedCookiesRequestInputD>;
export type SignedCookiesRequestInputV2 = t.TypeOf<typeof signedCookiesRequestInputDV2>;
