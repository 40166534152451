import { track } from '@/analytics';
import useMobile from '@/hooks/useMobile';
import { useSpaceAccess } from '@/hooks/useSpaceAccess';
import * as Popover from '@radix-ui/react-popover';
import classNames from 'classnames';
import React, { forwardRef, useState } from 'react';
import { CheckCircle, FileText, Plus } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useOpenTask } from '../PageNavigationProvider';
import { PopOver } from '../popover/PopOver';
import Tooltip from '../popover/Tooltip';
import { useSpace } from '../SpaceProvider';
import Button from '../styled/Button';
import { useTasks } from '../tasks/hooks';
import { useTaskFilters } from '@/hooks/useTaskFilters';
import { createTask } from '@/utils/documentUtils';

function Tasks({ openTasksTable }: any) {
    const { t } = useTranslation();

    const goToTask = useOpenTask();
    const [isOpen, setIsOpen] = useState(false);
    const isMobile = useMobile();
    const { space, provider } = useSpace();
    const { canEdit } = useSpaceAccess();
    const taskFilters = useTaskFilters();

    return (
        <Tasks.HoverGroup onClick={openTasksTable}>
            <Popover.Root open={isOpen} onOpenChange={setIsOpen}>
                <div className="px-2 py-1.5 relative flex items-center space-x-2">
                    <div className="w-full flex items-center space-x-2">
                        <CheckCircle className="flex-none w-3.5 h-3.5 shrink-0 mr-0.5" />
                        <div data-testid="open-tasks-table" className="min-w-0 font-medium text-sm">
                            {t('sidebar.tasks')}
                        </div>
                    </div>
                    {canEdit && !isMobile && (
                        <Popover.Trigger asChild>
                            <Tasks.ShowOnHover>
                                <div
                                    className="absolute z-20 right-0 top-0 bottom-0 flex items-center px-1"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setIsOpen(true);
                                    }}
                                >
                                    <Tooltip placement="right" content={t('editor.create_task')}>
                                        <div className="h-6 px-1 bg-white dark:bg-saga-gray-1000 flex flex-row cursor-pointer items-center text-xs leading-normal border-saga-gray-200 dark:border-zinc-500 border-2 font-semibold focus:outline-none active:shadow-xs rounded">
                                            <Plus
                                                className="flex-none relative"
                                                size={12}
                                                onClick={(event) => {
                                                    const task = createTask(
                                                        space,
                                                        { title: '', ...taskFilters },
                                                        provider,
                                                    );
                                                    track('create-task', { source: 'sidebar-button' });
                                                    goToTask(task.id, event);
                                                }}
                                            />
                                        </div>
                                    </Tooltip>
                                </div>
                            </Tasks.ShowOnHover>
                        </Popover.Trigger>
                    )}
                </div>
            </Popover.Root>
        </Tasks.HoverGroup>
    );
}

Tasks.HoverGroup = function TasksHoverGroup({ children, onClick }: { children: React.ReactNode; onClick: any }) {
    return (
        <div className="group" onClick={onClick}>
            {children}
        </div>
    );
};

Tasks.ShowOnHover = forwardRef<
    HTMLDivElement,
    {
        children: React.ReactNode;
        forceVisible?: boolean;
    }
>(function TasksShowOnHover({ children, forceVisible }, ref) {
    return (
        <div
            ref={ref}
            className={classNames({
                'opacity-0 group-hover:opacity-100 pointer-events-none group-hover:pointer-events-auto': !forceVisible,
            })}
        >
            {children}
        </div>
    );
});

Tasks.CreateTaskPopOver = function TaskCreateTaskPopOver({
    onTaskCreation,
}: {
    onTaskCreation(title: string, event: React.FormEvent): void;
}) {
    const [title, setTitle] = useState('');
    const { t } = useTranslation();
    const tasks = useTasks({ mode: 'deep', assigneeFilter: { kind: 'all' }, priorityFilter: 'all' });

    const tasksTitles = tasks.map((task) => task.title);
    const titleExistsAlready =
        tasksTitles.find((taskTitle) => taskTitle?.trim().toLowerCase() === title.trim().toLowerCase()) != null;

    const inputRef = React.useRef<HTMLInputElement>(null);

    return (
        <Popover.Portal>
            <Popover.Content align="start" className="ml-3">
                <form
                    onSubmit={(e) => {
                        if (!titleExistsAlready) {
                            onTaskCreation(title, e);
                            setTitle('');
                        }

                        e.preventDefault();
                    }}
                >
                    <PopOver.Card>
                        <div data-testid="create-task-popover">
                            <PopOver.Input
                                ref={inputRef}
                                type="text"
                                title="Type a new Task..."
                                placeholder="Type a new Task..."
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                            />

                            {title.trim().length > 0 && (
                                <>
                                    <PopOver.Divider />
                                    <Button.PopOverButton disabled={titleExistsAlready} selected type="submit">
                                        <div className="flex w-full">
                                            <FileText className="flex-none stroke-saga-gray-500 mr-2 w-4 shrink-0" />
                                            <div className="font-medium truncate">{title}</div>
                                            <div>&nbsp;{`- ${t('editor.create_task')}`}</div>
                                        </div>
                                    </Button.PopOverButton>
                                </>
                            )}
                        </div>
                    </PopOver.Card>
                </form>
            </Popover.Content>
        </Popover.Portal>
    );
};

Tasks.PlusButton = forwardRef<HTMLButtonElement, { onClick(e: React.MouseEvent): void }>(function TasksPlusButton(
    { onClick },
    ref,
) {
    return (
        <Button.NestedButton ref={ref} onClick={onClick}>
            <Plus className="flex-none" size={12} />
        </Button.NestedButton>
    );
});

export default Tasks;
