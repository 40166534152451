import { mergeSiblings, BlockType, BlockD, TextD, HelperBlockType, isSagaText, hasChildren } from '..';
import * as R from 'ramda';
import rehypeParse from 'rehype-parse';
import { unified } from 'unified';
import unistReduce from 'unist-util-reduce';
import { Element as HastElement } from 'hast';
import { toString } from 'hast-util-to-string';
import { removePosition } from 'unist-util-remove-position';

type HelperAttributes = {
    remove?: boolean;
    br?: boolean;
    noMerge?: boolean;
    headingType?: string;
};

const HELPER_ATTRIBUTES: (keyof HelperAttributes)[] = ['noMerge', 'headingType', 'br', 'remove'];

type BlockWithHelper = BlockD & HelperAttributes;

type Transform = (opts: { element: HastElement; children: BlockWithHelper[] }) => BlockWithHelper;

const transformers: Record<string, Transform> = {
    h1: ({ children }) => ({ type: BlockType.HEADING_1, children, noMerge: true }),
    h2: ({ children }) => ({ type: BlockType.HEADING_2, children, noMerge: true }),
    h3: ({ children }) => ({ type: BlockType.HEADING_3, children, noMerge: true }),
    a: ({ children, element }) => ({
        type: BlockType.LINK,
        children,
        url: `${element.properties?.href ?? ''}`,
    }),
    br: () => ({
        type: BlockType.PARAGRAPH,
        children: [{ text: '' }],
        noMerge: true,
        // This is a helper to understand if the paragraph was created because of a <br/>
        br: true,
    }),
    ul: ({ children }) => ({
        type: BlockType.INDENT,
        // The remove flag is set here and removed again for any nested lists
        // This helps to remove only the top level indent, because it is not needed
        remove: true,
        children: applyIndentation(children),
    }),
    ol({ children }) {
        // We need to make sure that all inner children are number list items
        const numberedListChildren = children.map((child) => {
            if (child.type === BlockType.BULLET_LIST_ITEM) {
                return { ...child, type: BlockType.NUMBER_LIST_ITEM };
            }
            return child;
        });

        return {
            type: BlockType.INDENT,
            children: [
                {
                    type: BlockType.NUMBER_LIST,
                    children: applyIndentation(numberedListChildren),
                },
            ],
            // The remove flag is set here and removed again for any nested lists
            // This helps to remove only the top level indent, because it is not neede
            remove: true,
        };
    },
    // @ts-expect-error
    li({ children, element }) {
        const unwrappedChildren = unwrapParagraphs(children);

        let mergedChildren;

        if (checkForBulletListItemFromGDocs(element)) {
            mergedChildren = mergeSiblingsFunction(unwrappedChildren);
        } else {
            mergedChildren = mergeSiblingsFunction(children);
        }

        return {
            type: BlockType.BULLET_LIST_ITEM,
            children: mergedChildren,
            noMerge: true,
        };
    },
    // @ts-expect-error
    span({ element, children }) {
        const style = getStyleForElement(element);

        if (style) {
            const attributes: Partial<Omit<TextD, typeof HelperBlockType.TEXT>> & { headingType?: string | null } = {
                bold: style['font-weight'] === '700',
                underline: style['text-decoration'] === 'underline',
                italic: style['font-style'] === 'italic',
                // this is just a helper attribute to be able to keep the font-size information
                // and deal with it one level above
                // it is deleted afterwardsa gain
                headingType: getHeadingType(style['font-size']),
            };

            const enabledAttributes = R.reject((v) => !v, attributes);

            const textChildren = children.map((child) => {
                if (child.br) {
                    return { text: ' ', type: HelperBlockType.TEXT };
                }
                return { text: toString(child as any), type: HelperBlockType.TEXT, ...enabledAttributes };
            });

            return textChildren;
        }

        return [];
    },

    // @ts-expect-error
    p({ children, element }) {
        const style = getStyleForElement(element);

        if (style) {
            // at this point we know it's a title
            if (style['margin-bottom'] === '3pt') {
                const firstChild = children[0];

                if ('headingType' in firstChild) {
                    return { type: firstChild.headingType, children: children, noMerge: true };
                }
            }
        }

        // Paragraphs should not be merged anymore
        return wrapTextsInParagraph(children).map((node) => ({ ...node, noMerge: true }));
    },
};

export const gdocsHtmlToSaga = (html: string) => {
    const ast = unified()
        .use(rehypeParse as any, { emitParseErrors: true, duplicateAttribute: false })
        .parse(html);
    const astWithoutPositions = removePosition(ast, true);
    const res = unistReduce(astWithoutPositions as any, (node): any => {
        if (node.type === 'element') {
            const element = node as HastElement;
            let children = element.children as BlockWithHelper[];

            const transformer = transformers[element.tagName];

            // We merge the siblings together within heading tags or when there is no transformer
            if (['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(element.tagName) || transformer == null) {
                children = mergeSiblings((a, b) => {
                    if (!isMergable(a) || !isMergable(b)) {
                        return false;
                    }

                    return a.type !== HelperBlockType.TEXT && b.type !== HelperBlockType.TEXT && a.type === b.type;
                }, children).map((node) => ({ ...node, noMerge: true }));
            }

            if (transformer) {
                return transformer({ element, children });
            }

            // any leftover texts within the b tag should be wrapped in paragraphs
            return wrapTextsInParagraph(children);
        }
        if (node.type === 'text') {
            return node;
        }

        // remove any node that we do not expect
        return { ...node, remove: true, children: [] };
    });

    const cleanRes = unistReduce(res, (node) => {
        if (hasChildren(node) && (node as BlockWithHelper).remove) {
            return node.children ?? [];
        }

        // for some reason, on windows, it can happen that there are still
        // nodes that were not transformed. we can just remove them as they have no
        // useful information. this does not happen on macos or unix.
        if (node.type === HelperBlockType.TEXT && !('text' in node)) {
            return [];
        }

        if (node.type === HelperBlockType.TEXT && isSagaText(node)) {
            const { type, ...rest } = node;
            type;
            return R.omit(HELPER_ATTRIBUTES, rest) as any;
        }

        // This removes any helper attributes
        return R.omit(HELPER_ATTRIBUTES, node);
    });

    return cleanRes.children as BlockD[];
};

function getStyleObject(style: string): Record<string, string> {
    const lines = style.split(';').filter((line) => line.trim() !== '');
    return lines
        .map((line) => line.split(/:(.*)/).filter((item) => item.trim() !== ''))
        .reduce((acc, [property, cssText]) => ({ ...acc, [property]: cssText }), {});
}

function getHeadingType(fontSize?: string) {
    const match = fontSize?.match(/^(\d*\.?\d*)pt$/);
    if (match) {
        const size = parseFloat(match[1]);
        if (size >= 20) {
            return BlockType.HEADING_1;
        }
        if (size >= 16) {
            return BlockType.HEADING_2;
        }
        if (size >= 13.9) {
            return BlockType.HEADING_3;
        }
    }

    return null;
}

function groupAndFlatMap<In extends { type: string }, Out>(
    nodes: In[],
    shouldGroup: (a: In, b: In) => boolean,
    flatMap: (nodes: In[]) => Out[],
): Out[] {
    const chunks = R.groupWith(shouldGroup, nodes);
    const res = chunks
        .map((chunk): Out[] => {
            return flatMap(chunk);
        })
        .flat();

    return res;
}

const INLINE_TYPES = [HelperBlockType.TEXT, 'link'];

function wrapTextsInParagraph(nodes: BlockD[]) {
    return groupAndFlatMap<BlockD, BlockD>(
        nodes,
        (a, b) => {
            return INLINE_TYPES.includes(a.type) && INLINE_TYPES.includes(b.type);
        },
        (children) => {
            if (children.every((child) => INLINE_TYPES.includes(child.type))) {
                return [{ type: BlockType.PARAGRAPH, children }];
            }
            return children;
        },
    );
}

function getStyleForElement(element: HastElement) {
    const inlineStyle = element.properties?.style;

    if (typeof inlineStyle === 'string') {
        return getStyleObject(inlineStyle);
    }

    return null;
}

function isMergable(node: any) {
    if ('noMerge' in node && node.noMerge) {
        return false;
    }

    return true;
}

function applyIndentation(children: BlockWithHelper[]) {
    const result: BlockWithHelper[] = [];
    for (let i = 0; i < children.length; i++) {
        const child = children[i];
        const nextChild = children[i + 1];

        if (nextChild && nextChild.type === BlockType.INDENT) {
            const { remove, ...indentBlockWithoutRemove } = nextChild;
            remove;
            result.push({
                type: BlockType.BLOCK_CONTAINER,
                children: [child, indentBlockWithoutRemove],
            });
            result.push(...children.slice(i + 2));
            break;
        }

        result.push(child);
    }

    return result;
}

function unwrapParagraphs(blocks: BlockWithHelper[]) {
    return blocks
        .map((child) => {
            if (child.type === BlockType.PARAGRAPH) {
                return child.children;
            }

            return child;
        })
        .flat();
}

const checkForBulletListItemFromGDocs = (element: HastElement) => {
    if (
        element.properties &&
        element.properties.style &&
        typeof element.properties.style === 'string' &&
        (element.properties.style.includes('list-style-type:disc') ||
            element.properties.style.includes('list-style-type:circle') ||
            element.properties.style.includes('list-style-type:square'))
    ) {
        return true;
    }

    return false;
};

const mergeSiblingsFunction = (children: BlockWithHelper[] | any) => {
    return mergeSiblings((a, b) => {
        if (!isMergable(a) || !isMergable(b)) {
            return false;
        }

        // @ts-expect-error
        if (a.type === HelperBlockType.TEXT && b.type === HelperBlockType.TEXT && b.text.trim() === '') {
            return true;
        }

        return a.type !== HelperBlockType.TEXT && b.type !== HelperBlockType.TEXT;
    }, children);
};
