import React from 'react';
import Table from '@/components/table/Table';
import { Collection, Page, PagesTableColumnKey, SpaceOperations } from '@saga/shared';
import { useSpaceAccess } from '@/hooks/useSpaceAccess';
import { PageItem, TableColumn } from '@/types';
import SortableTableButton from './SortableTableButton';
import { useCollectionsSnapshot } from '@/hooks/SpaceHooks';
import { track } from '@/analytics';
import ManageCollectionsInput from '../popover/ManageCollectionsInput';
import { formatDateOptimized } from '@/utils/dateUtils';
import { useOpenCollection, useOpenPage } from '../PageNavigationProvider';
import useMobile from '@/hooks/useMobile';
import useInterfaceSettings from '@/hooks/useInterfaceSettings';
import { useSpace } from '@/components/SpaceProvider';
import { useTranslation } from 'react-i18next';
import { useUserContext } from '../UserContext';
import * as api from '@saga/api';
import { SortOption, fieldsToSortOptions } from '@/components/table/TableSortButton';
import MemberAvatar from '@/components/MemberAvatar';
import { AVAILABLE_COLORS } from '@/constants';
import { stringToColor } from '../../../../shared/src/utils/Colors';
import { useMembers } from '../MembersProvider';
import classNames from 'classnames';

type PageTableColumns = { [key in PagesTableColumnKey]: TableColumn };

const columns: PageTableColumns = {
    title: { asc: 'filters.az', desc: 'filters.za', label: 'filters.title', type: 'title', value: 'title' },
    collection: {
        asc: 'pages.fewest_items_first',
        desc: 'pages.most_items_first',
        label: 'pages.collection',
        type: 'collection',
        value: 'collection',
    },
    wordCount: {
        asc: 'pages.fewest_words_first',
        desc: 'pages.most_words_first',
        label: 'pages.word_count',
        type: 'wordCount',
        value: 'wordCount',
    },
    createdBy: {
        asc: 'filters.az',
        desc: 'filters.za',
        label: 'pages.headers.creator',
        type: 'createdBy',
        value: 'createdBy',
    },
    created: {
        asc: 'pages.oldest_first',
        desc: 'pages.newest_first',
        label: 'filters.created',
        type: 'created',
        value: 'created',
    },
    updated: {
        asc: 'pages.oldest_first',
        desc: 'pages.newest_first',
        label: 'filters.updated',
        type: 'updated',
        value: 'updated',
    },
};

export const getSortOptions = (keys: PagesTableColumnKey[]): SortOption[] => {
    return fieldsToSortOptions(keys, columns);
};

export const getColumns = (keys: PagesTableColumnKey[]) => {
    return keys.reduce(
        (acc, key) => {
            acc[key] = columns[key];
            return acc;
        },
        {} as { [key in PagesTableColumnKey]: TableColumn },
    );
};

function CollectionsInput({
    page,
    collections,
    disabled,
    isMobile,
}: {
    page: Pick<Page, 'collections' | 'id'>;
    collections: Collection[];
    isMobile: boolean;
    disabled?: boolean;
}) {
    const { space } = useSpace();
    const { canEdit } = useSpaceAccess();
    const goToCollection = useOpenCollection();

    const onCreateCollection = (title: string) => {
        const newCollection = SpaceOperations.createCollection(space, title);
        SpaceOperations.addPageToCollection(space, page.id, newCollection.id);
        track('create-collection-from-popover');
    };

    const onAddCollection = (id: string) => {
        SpaceOperations.addPageToCollection(space, page.id, id);
        track('add-collection-to-page');
    };

    const onRemoveCollection = (id: string) => {
        SpaceOperations.removePageFromCollection(space, page.id, id);
        track('remove-collection-from-page');
    };

    return (
        <ManageCollectionsInput
            canEdit={canEdit}
            currentCollections={collections.filter((c) => page.collections.includes(c.id))}
            availableCollections={collections.filter((c) => !page.collections.includes(c.id)) ?? []}
            onCollectionClick={(id, event) => goToCollection(id, event)}
            onCreate={(title) => onCreateCollection(title)}
            onSelect={(id) => onAddCollection(id)}
            onRemove={(id) => onRemoveCollection(id)}
            canHighlight={true}
            maxWidth={isMobile ? 250 : 400}
            disabled={disabled}
            canWrap
        />
    );
}

function PageTableRow({
    item,
    columnKeys,
    hiddenColumns,
    isMobile,
    language,
    canEdit,
    index,
    renderContextMenuButton,
}: {
    item: PageItem;
    columnKeys: PagesTableColumnKey[];
    hiddenColumns: PagesTableColumnKey[];
    isMobile: boolean;
    canEdit: boolean;
    language?: api.Language;
    renderContextMenuButton(item: PageItem): React.ReactNode;
    index: number;
}) {
    const createdAt = formatDateOptimized(item.data.createdAt, language);
    const updatedAt = formatDateOptimized(item.data.updatedAt, language);
    const [showContextForPageId, setShowContextForPageId] = React.useState<string | null>(null);
    const [, setInterfaceSettings] = useInterfaceSettings();
    const openPage = useOpenPage();

    const collections = useCollectionsSnapshot();

    const onTitleClick = React.useCallback(
        (id: string, event: React.MouseEvent) => {
            openPage(id, event);

            if (isMobile) {
                setInterfaceSettings({ fixedSidebar: false });
            }
        },
        [isMobile, setInterfaceSettings, openPage],
    );

    const renderCell = React.useCallback(
        (key: PagesTableColumnKey, index: number) => {
            const renderButtonIfFirstColumn = () =>
                !isMobile && canEdit && index === 0 ? (
                    <>
                        <div className="h-8 w-8 absolute -ml-7"></div>
                        {item.id === showContextForPageId && (
                            <div className="absolute -ml-7">{renderContextMenuButton(item)}</div>
                        )}
                    </>
                ) : null;

            switch (key) {
                case 'title':
                    return hiddenColumns.includes(key) ? null : (
                        <Table.Cell
                            key={key}
                            className={classNames('relative align-top p-2 w-full min-w-56 max-w-56', {
                                'pl-0': index === 0,
                            })}
                        >
                            {renderButtonIfFirstColumn()}
                            <Table.ItemButton onClick={(event) => onTitleClick(item.id, event)} item={item} />
                        </Table.Cell>
                    );
                case 'collection':
                    return hiddenColumns.includes(key) ? null : (
                        <Table.Cell
                            key={key}
                            className={classNames('align-top min-w-48 max-w-48 p-2', {
                                'pl-0': index === 0,
                            })}
                        >
                            {renderButtonIfFirstColumn()}
                            <CollectionsInput
                                isMobile={isMobile}
                                page={item.data}
                                collections={collections}
                                disabled={isMobile}
                            />
                        </Table.Cell>
                    );
                case 'wordCount':
                    return hiddenColumns.includes(key) ? null : (
                        <Table.Cell
                            key={key}
                            className={classNames('align-top min-w-40 max-w-40 p-2', {
                                'pl-0': index === 0,
                            })}
                        >
                            {renderButtonIfFirstColumn()}
                            <p className="text-sm">{item.data.wordCount}</p>
                        </Table.Cell>
                    );
                case 'createdBy':
                    return hiddenColumns.includes(key) ? null : (
                        <Table.Cell
                            key={key}
                            className={classNames('h-10 align-top min-w-40 max-w-40 no-wrap py-2 px-2', {
                                'pl-0': index === 0,
                            })}
                        >
                            {renderButtonIfFirstColumn()}
                            {item.computed.creatorName && item.data.createdBy && (
                                <div className="flex flex-row px-1.5 py-0.5">
                                    <div className="flex flex-row space-x-1">
                                        <MemberAvatar
                                            size="md"
                                            name={item.computed.creatorName}
                                            color={stringToColor(item.data.createdBy, AVAILABLE_COLORS)}
                                        />
                                        <p className="text-sm truncate">{item.computed.creatorName}</p>
                                    </div>
                                </div>
                            )}
                        </Table.Cell>
                    );
                case 'created':
                    return hiddenColumns.includes(key) ? null : (
                        <Table.Cell
                            key={key}
                            className={classNames('align-top min-w-40 max-w-40 p-2', {
                                'pl-0': index === 0,
                            })}
                        >
                            {renderButtonIfFirstColumn()}
                            <p className="text-sm">{createdAt}</p>
                        </Table.Cell>
                    );
                case 'updated':
                    return hiddenColumns.includes(key) ? null : (
                        <Table.Cell
                            key={key}
                            className={classNames('align-top min-w-40 max-w-40 p-2', {
                                'pl-0': index === 0,
                            })}
                        >
                            {renderButtonIfFirstColumn()}
                            <p className="text-sm">{updatedAt}</p>
                        </Table.Cell>
                    );
                default:
                    return null;
            }
        },
        [
            item,
            isMobile,
            canEdit,
            showContextForPageId,
            renderContextMenuButton,
            createdAt,
            updatedAt,
            collections,
            onTitleClick,
            hiddenColumns,
        ],
    );

    return (
        <Table.Row
            key={item.id}
            onClick={(e) => isMobile && onTitleClick(item.id, e)}
            onMouseOver={() => setShowContextForPageId(item.id)}
            onMouseLeave={() => setShowContextForPageId(null)}
            data-testid={`page-index-${index}`}
            className="divide-x divide-saga-gray-150 dark:divide-saga-gray-800 py-1"
        >
            {columnKeys.map(renderCell)}
        </Table.Row>
    );
}

interface TableHeaderContentProps {
    children: React.ReactNode;
}

const TableHeaderContent: React.FC<TableHeaderContentProps> = ({ children }) => {
    return (
        <div className="px-1 py-0.5 flex">
            <p className="my-auto flex-grow truncate text-saga-gray-500 text-sm font-normal">{children}</p>
        </div>
    );
};

function PageTable({
    items,
    renderContextMenuButton,
    testId,
    columnKeys,
    onColumnOrderChange,
}: {
    items: PageItem[];
    renderContextMenuButton(item: PageItem): React.ReactNode;
    testId: string;
    columnKeys: PagesTableColumnKey[];
    onColumnOrderChange(columnKeys: PagesTableColumnKey[]): void;
}) {
    const { user } = useUserContext();
    const { canEdit } = useSpaceAccess();
    const isMobile = useMobile();
    const { t } = useTranslation();

    const [orderedKeys, setOrderedKeys] = React.useState(columnKeys);

    const { members } = useMembers();

    const hiddenColumns: PagesTableColumnKey[] = React.useMemo(() => {
        return members.length === 1 ? ['createdBy'] : [];
    }, [members]);

    const moveHeader = React.useCallback((dragIndex: number, hoverIndex: number) => {
        setOrderedKeys((prevKeys) => {
            const newKeys = [...prevKeys];
            const [removed] = newKeys.splice(dragIndex, 1);
            newKeys.splice(hoverIndex, 0, removed);
            return newKeys;
        });
    }, []);

    const onDrop = React.useCallback(() => {
        onColumnOrderChange(orderedKeys);
    }, [orderedKeys, onColumnOrderChange]);

    const renderHeader = React.useCallback(
        () => (
            <Table.Row>
                {orderedKeys.map((key, index) => {
                    switch (key) {
                        case 'title':
                            return hiddenColumns.includes(key) ? null : (
                                <Table.Cell key={key} className="h-10 pb-3 align-middle w-full min-w-56 max-w-56">
                                    <Table.DraggableHeader
                                        id={key}
                                        index={index}
                                        moveHeader={moveHeader}
                                        onDrop={onDrop}
                                    >
                                        <SortableTableButton type="title">
                                            <TableHeaderContent>{t(columns.title.label)}</TableHeaderContent>
                                        </SortableTableButton>
                                    </Table.DraggableHeader>
                                </Table.Cell>
                            );
                        case 'collection':
                            return hiddenColumns.includes(key) ? null : (
                                <Table.Cell key={key} className="h-10 pb-3 align-middle min-w-48 max-w-48 truncate">
                                    <Table.DraggableHeader
                                        id={key}
                                        index={index}
                                        moveHeader={moveHeader}
                                        onDrop={onDrop}
                                    >
                                        <SortableTableButton type="collection">
                                            <TableHeaderContent>{t(columns.collection.label)}</TableHeaderContent>
                                        </SortableTableButton>
                                    </Table.DraggableHeader>
                                </Table.Cell>
                            );
                        case 'wordCount':
                            return hiddenColumns.includes(key) ? null : (
                                <Table.Cell key={key} className="h-10 pb-3 align-middle min-w-40 max-w-40 truncate">
                                    <Table.DraggableHeader
                                        id={key}
                                        index={index}
                                        moveHeader={moveHeader}
                                        onDrop={onDrop}
                                    >
                                        <SortableTableButton type="wordCount">
                                            <TableHeaderContent>{t(columns.wordCount.label)}</TableHeaderContent>
                                        </SortableTableButton>
                                    </Table.DraggableHeader>
                                </Table.Cell>
                            );
                        case 'createdBy':
                            return hiddenColumns.includes(key) ? null : (
                                <Table.Cell key={key} className="h-10 pb-3 align-middle min-w-40 max-w-40">
                                    <Table.DraggableHeader
                                        id={key}
                                        index={index}
                                        moveHeader={moveHeader}
                                        onDrop={onDrop}
                                    >
                                        <SortableTableButton type="createdBy">
                                            <TableHeaderContent>{t(columns.createdBy.label)}</TableHeaderContent>
                                        </SortableTableButton>
                                    </Table.DraggableHeader>
                                </Table.Cell>
                            );
                        case 'created':
                            return hiddenColumns.includes(key) ? null : (
                                <Table.Cell key={key} className="h-10 pb-3 align-middle min-w-40 max-w-40">
                                    <Table.DraggableHeader
                                        id={key}
                                        index={index}
                                        moveHeader={moveHeader}
                                        onDrop={onDrop}
                                    >
                                        <SortableTableButton type="created">
                                            <TableHeaderContent>{t(columns.created.label)}</TableHeaderContent>
                                        </SortableTableButton>
                                    </Table.DraggableHeader>
                                </Table.Cell>
                            );
                        case 'updated':
                            return hiddenColumns.includes(key) ? null : (
                                <Table.Cell key={key} className="h-10 pb-3 align-middle min-w-40 max-w-40">
                                    <Table.DraggableHeader
                                        id={key}
                                        index={index}
                                        moveHeader={moveHeader}
                                        onDrop={onDrop}
                                    >
                                        <SortableTableButton type="updated">
                                            <TableHeaderContent>{t(columns.updated.label)}</TableHeaderContent>
                                        </SortableTableButton>
                                    </Table.DraggableHeader>
                                </Table.Cell>
                            );
                        default:
                            return null;
                    }
                })}
            </Table.Row>
        ),
        [t, orderedKeys, moveHeader, onDrop, hiddenColumns],
    );

    const renderRow = React.useCallback(
        (index) => {
            return (
                items[index] && (
                    <PageTableRow
                        hiddenColumns={hiddenColumns}
                        columnKeys={orderedKeys}
                        key={items[index].id}
                        item={items[index]}
                        isMobile={isMobile}
                        canEdit={canEdit}
                        renderContextMenuButton={renderContextMenuButton}
                        language={user?.data.language}
                        index={index}
                    />
                )
            );
        },
        [isMobile, canEdit, renderContextMenuButton, user?.data.language, items, orderedKeys, hiddenColumns],
    );

    return (
        <Table.Virtualized
            data-testid={testId}
            className="table-fixed border-collapse text-left h-full"
            itemsCount={items.length}
            renderHeader={renderHeader}
            renderItem={renderRow}
        />
    );
}

export default PageTable;
