export function escapeRegExp(string: string) {
    return string.replace(/[.*?^${}()|[\]\\+]/g, '\\$&'); // $& means the whole matched string
}

export function getOS(): 'Mac OS' | 'iOS' | 'Windows' | 'Android' | 'Linux' | 'Unknown' {
    let userAgent = window.navigator.userAgent,
        platform = window.navigator.platform,
        macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
        windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
        iosPlatforms = ['iPhone', 'iPad', 'iPod'],
        os = null;

    if (macosPlatforms.indexOf(platform) !== -1) {
        os = 'Mac OS';
    } else if (iosPlatforms.indexOf(platform) !== -1) {
        os = 'iOS';
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
        os = 'Windows';
    } else if (/Android/.test(userAgent)) {
        os = 'Android';
    } else if (!os && /Linux/.test(platform)) {
        os = 'Linux';
    }

    //@ts-expect-error
    return os ? os : 'Unknown';
}

/**
 * Checks if a searchString is part of the target in a case-insensitive way.
 *
 * @param target The string to check against.
 * @param searchString The searchString to find in the target.
 * @returns If the searchString is included in the target, regardless of casing.
 */
export function includesLowerCase(target: string, searchString: string): boolean {
    return target.toLowerCase().includes(searchString.toLowerCase());
}

export type Offset = {
    start: number;
    end: number;
};
