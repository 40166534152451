import { UrlUtils } from '@saga/shared';
import { IS_PREVIEW, IS_DEV } from './constants';

export function isDebug() {
    const debug = localStorage.getItem('debugMode') === 'true';
    return IS_DEV || IS_PREVIEW || debug;
}

export function debugLog(message?: any, ...rest: any[]) {
    if (isDebug()) {
        console.log(message, ...rest);
    }
}

export const openWindow = (url: string, target?: string, features?: string) => {
    const urlWithHttp = UrlUtils.addHttps(url);
    if (Boolean(window.todesktop)) {
        window.todesktop?.contents.openUrlInBrowser(urlWithHttp);
    } else {
        window.open(urlWithHttp, target, features);
    }
};

export const truncateString = (str: string, num: number) => {
    if (str.length > num) {
        let subStr = str.substring(0, num);
        return subStr + '...';
    } else {
        return str;
    }
};

export function convertFileSize(size: number | undefined) {
    if (!size) return '0 KB';
    // Less than 1MB
    return size < 1e6 ? `${(size / 1024).toFixed(2)} KB` : `${(size / 1e6).toFixed(2)} MB`;
}

export const handleDesktopLinkOpen = (url: string) => {
    if (window.todesktop) return window.todesktop.contents.openUrlInBrowser(url);
};
