export const enabledOEmbedProviders = [
    'YouTube',
    'Vimeo',
    'Dailymotion',
    'Spotify',
    'CodePen',
    'CodeSandbox',
    'Replit',
    'SoundCloud',
    'GIPHY',
    'Prezi Video',
    'TED',
    'Loom',
].map((c) => c.toLowerCase());

export const googleDriveRegex =
    /https:\/\/(?:docs|jamboard|drive).google.com\/?(?:document|presentation|spreadsheets|forms|drive|file)?\/(?:d|folders)\/([a-zA-Z0-9-_]+)(?:\/.*)?/;
export const isGoogleDriveEmbeddableUrl = (url: string) => googleDriveRegex.test(url);

export const isFigmaEmbeddableUrl = (url: string) =>
    /https:\/\/([\w\.-]+\.)?figma.com\/(file|proto)\/([0-9a-zA-Z]{22,128})(?:\/.*)?$/.test(url);

export const linearIssueUrl = /https:\/\/linear.app\/[0-9a-zA-Z-]+\/issue\/([0-9a-zA-Z-]+)(?:\/.*)?$/;

export const isLinearIssueEmbeddableUrl = (url: string) => linearIssueUrl.test(url);

export const isGIPHYStickerEmbeddableURL = (url: string) => url.startsWith('https://giphy.com/stickers/');

export const isLoomEmbeddableURL = (url: string) => url.startsWith('https://www.loom.com/share/');

export const matchCustomProvider = (url: string) =>
    isFigmaEmbeddableUrl(url) ||
    isGIPHYStickerEmbeddableURL(url) ||
    isGoogleDriveEmbeddableUrl(url) ||
    isLoomEmbeddableURL(url);

export const allowedIframeAttributes = [
    'width',
    'height',
    'src',
    'frameborder',
    'allow',
    'allowfullscreen',
    'title',
    'style',
    'id',
    'scrolling',
    'allowtransparency',
    'class',
    'sandbox',
    'webkitallowfullscreen',
    'mozallowfullscreen',
];
