import React from 'react';
import {
    Collection,
    Property,
    Page,
    pageD,
    safeMap,
    DecodingError,
    unsafeRight,
    WeakPage,
    assertNonNull,
    SpaceOperations,
    SagaLocation,
    newBlankPage,
    BlockBuilder,
    isTitle,
    AnyBlockItem,
    TaskLabel,
    observeSafeMap,
    Space,
    PageView,
    TaskView,
    WeakTask,
} from '@saga/shared';
import * as Y from 'yjs';
import { useSafeArray, useSafeMap } from '@/io-ts-react';
import * as E from 'fp-ts/Either';
import { pipe } from 'fp-ts/lib/function';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { sequenceS } from 'fp-ts/lib/Apply';
import { ReadonlyNonEmptyArray } from 'fp-ts/lib/ReadonlyNonEmptyArray';
import { useSpace } from '../components/SpaceProvider';
import { useCurrentWorkspace, useSharedPagesStatuses } from '@/components/WorkspaceContext';
import { useRecents } from '@/components/RecentsContext';
import { track } from '@/analytics';
import { useOpenPage } from '@/components/PageNavigationProvider';
import { useToastContext } from '@/components/toast/ToastContext';
import { CreatePageSuggestion, PageSuggestion } from '@/types';
import { useSetQuickEditModalPage } from '@/components/QuickEditPageContainer';
import { OnContentMovedToast, OnPageCreated, OnReferenceCreatedToast } from '@/components/toast/ToastLibrary';
import { Location, Editor as SlateEditor, Transforms } from 'slate';
import invariant from 'tiny-invariant';
import { useSlateStatic } from 'slate-react';
import { createPage } from '@/utils/documentUtils';
import { usePerformActionWithYBlocks, usePerformAppendBlock } from '@/components/RealtimeDocumentProvider';
import { v4 as uuid, validate as uuidValidate } from 'uuid';
import { usePagesPermissions } from '@/components/PagesPermissionsBySpaceProvider';
import { useTasks } from '@/components/tasks/hooks';
import { useUserContext } from '@/components/UserContext';

export const useFavorites = () => {
    const { space } = useSpace();
    const { user } = useUserContext();

    const userId = React.useMemo(() => user?.id, [user]);

    const [favoritesYMap, setFavoritesYMap] = React.useState<Y.Map<string[]> | undefined>(
        space.map.get('userFavorites') as Y.Map<string[]>,
    );

    const [favorites, setFavorites] = React.useState<Record<string, string[]>>(favoritesYMap?.toJSON() ?? {});

    React.useEffect(() => {
        const unsubscribe = observeSafeMap(space, (keys) => {
            if (keys.includes('userFavorites')) {
                setFavoritesYMap(space.map.get('userFavorites') as Y.Map<string[]>);
            }
        });
        return unsubscribe;
    }, [space]);

    React.useEffect(() => {
        const updateFavorites = () => {
            setFavorites(favoritesYMap?.toJSON() ?? {});
        };

        favoritesYMap?.observeDeep(updateFavorites);
        updateFavorites();
        return () => favoritesYMap?.unobserveDeep(updateFavorites);
    }, [favoritesYMap]);

    if (userId == null) return [];

    return favorites[userId] ?? [];
};
import { useTranslation } from 'react-i18next';
import { useMembers } from '@/components/MembersProvider';

export const usePinned = (): readonly string[] => {
    const { space } = useSpace();
    const pinned = unsafeRight(space.get('pinned'));
    const safeArray = useSafeArray(pinned);

    return React.useMemo(() => {
        // we need to retrigger the useMemo when the safeArray changes
        safeArray;
        return SpaceOperations.getPinned(space);
    }, [safeArray, space]);
};

export const usePropertiesSnapshot = (): Property[] => {
    const { space } = useSpace();

    const properties = unsafeRight(space.get('properties'));

    const safeArray = useSafeArray(properties);

    const propertiesItems = (): Property[] =>
        pipe(
            safeArray.toDecodedArray(),
            E.getOrElseW(() => []),
        );

    return React.useMemo(propertiesItems, [safeArray]);
};

export const useCollectionsSnapshot = (): Collection[] => {
    const { space } = useSpace();
    const collections = unsafeRight(space.get('collections'));
    const safeArray = useSafeArray(collections, true);

    return React.useMemo(() => {
        return SpaceOperations.getCollections(space);
        // we need to retrigger the useMemo when the safeArray changes
        // eslint-disable-next-line
    }, [space, safeArray]);
};

export const useAutolinkEnabled = (): [boolean, (enabled: boolean) => void] => {
    const { space } = useSpace();
    const [enabled, setEnabled] = React.useState(space.map.get('autolinkEnabled') === false ? false : true);

    React.useEffect(() => {
        const unsubscribe = observeSafeMap(space, (keys) => {
            if (keys.includes('autolinkEnabled')) {
                setEnabled(space.map.get('autolinkEnabled') === false ? false : true);
            }
        });
        return unsubscribe;
    }, [space]);

    const setAutolinkEnabled = React.useCallback(
        (enabled: boolean) => {
            track(enabled ? 'autolink-enable' : 'autolink-disable');
            space.map.set('autolinkEnabled', enabled);
        },
        [space],
    );

    return React.useMemo(() => [enabled, setAutolinkEnabled], [enabled, setAutolinkEnabled]);
};

export const useObservedSpaceArrayProperty = <K extends keyof Space>(key: K): Space[K] => {
    const { space } = useSpace();
    const [yArray, setYArray] = React.useState<Y.Array<any> | undefined>(space.map.get(key) as Y.Array<any>);
    const [array, setArray] = React.useState(yArray?.toJSON() ?? []);

    React.useEffect(() => {
        const unsubscribe = observeSafeMap(space, (keys) => {
            if (keys.includes(key)) {
                setYArray(space.map.get(key) as Y.Array<any>);
            }
        });
        return unsubscribe;
    }, [space, key]);

    React.useEffect(() => {
        const updateArray = () => {
            setArray(yArray?.toJSON() ?? []);
        };
        yArray?.observeDeep(updateArray);
        updateArray();
        return () => yArray?.unobserveDeep(updateArray);
    }, [yArray]);

    return array as Space[K];
};

export const useDocumentCreator = (
    document: Pick<WeakPage, 'createdBy'> | Pick<WeakTask, 'createdBy'>,
): { id: string; name: string } | undefined => {
    const { members } = useMembers();
    const creator = React.useMemo(
        () => members.find((m) => m.id === document.createdBy),
        [members, document.createdBy],
    );
    const creatorName = React.useMemo(() => `${creator?.firstName} ${creator?.lastName}`, [creator]);

    return React.useMemo(() => (creator ? { id: creator.id, name: creatorName } : undefined), [creator, creatorName]);
};

export const usePageViews = (): PageView[] => {
    const { t } = useTranslation();
    const pageViews = useObservedSpaceArrayProperty('pageViews');

    return React.useMemo(
        () => [
            {
                id: 'non-deleted',
                title: t('pages.title_pages'),
                mode: 'non-deleted',
                allPagesSorting: null,
                allPagesCollections: 'all',
                allPagesCreators: 'all',
                isDefaultView: true,
                search: '',
            },
            {
                id: 'deleted',
                title: t('pages.title_deleted'),
                mode: 'deleted',
                allPagesSorting: null,
                allPagesCollections: 'all',
                allPagesCreators: 'all',
                isDefaultView: true,
                search: '',
            },
            {
                id: 'shared',
                title: t('sidebar.shared'),
                mode: 'shared',
                allPagesSorting: null,
                allPagesCollections: 'all',
                allPagesCreators: 'all',
                isDefaultView: true,
                search: '',
            },
            {
                id: 'templates',
                title: t('pages.title_templates'),
                mode: 'templates',
                allPagesSorting: null,
                allPagesCollections: 'all',
                allPagesCreators: 'all',
                isDefaultView: true,
                search: '',
            },
            {
                id: 'private',
                title: t('pages.title_private'),
                mode: 'private',
                allPagesSorting: null,
                allPagesCollections: 'all',
                allPagesCreators: 'all',
                isDefaultView: true,
                search: '',
            },
            {
                id: 'public',
                title: t('pages.title_public'),
                mode: 'public',
                allPagesSorting: null,
                allPagesCollections: 'all',
                allPagesCreators: 'all',
                isDefaultView: true,
                search: '',
            },
            ...(pageViews ?? []),
        ],
        [pageViews, t],
    );
};

export const useTaskViews = (): TaskView[] => {
    const { t } = useTranslation();
    const taskViews = useObservedSpaceArrayProperty('taskViews');

    return React.useMemo(
        () => [
            {
                id: 'all',
                title: t('common.tasks'),
                isDefaultView: true,
                allTasksMode: 'all',
                allTasksAssignee: { kind: 'all' },
                allTasksCollections: 'all',
                allTasksLabels: 'all',
                allTasksPriority: 'all',
                allTasksSorting: null,
                allTasksCreators: 'all',
                search: '',
            },
            ...(taskViews ?? []),
        ],
        [taskViews, t],
    );
};
export const useTaskLabels = (): TaskLabel[] => {
    const { space } = useSpace();

    const [labelsYArray, setLabelsYArray] = React.useState<Y.Array<any> | undefined>(
        space.map.get('taskLabels') as Y.Array<any>,
    );
    const [array, setArray] = React.useState(labelsYArray?.toJSON() ?? []);

    React.useEffect(() => {
        const unsubscribe = observeSafeMap(space, (keys) => {
            if (keys.includes('taskLabels')) {
                setLabelsYArray(space.map.get('taskLabels') as Y.Array<any>);
            }
        });
        return unsubscribe;
    }, [space]);

    React.useEffect(() => {
        const updateArray = () => {
            setArray(labelsYArray?.toJSON() ?? []);
        };

        labelsYArray?.observeDeep(updateArray);
        updateArray();
        return () => labelsYArray?.unobserveDeep(updateArray);
    }, [labelsYArray]);

    return array;
};

type ObserverMode = 'deep' | 'flat';

export const usePartialPages = <K extends keyof WeakPage>(
    keys: ReadonlyNonEmptyArray<K>,
    observerMode: ObserverMode = 'flat',
    pageMode: PageView['mode'] = 'non-deleted',
): Pick<WeakPage, K>[] => {
    const { space } = useSpace();
    const { hasAccess } = usePagesPermissions();
    const sharedPagesStatuses = useSharedPagesStatuses();

    const pagesSafeArrayOutput = React.useMemo(() => unsafeRight(space.get('pages')), [space]);

    const safeArray = useSafeArray(pagesSafeArrayOutput, observerMode === 'deep', {
        // Here, we want to not trigger updates for certain types of events, so that
        // we don't rerender the App on every keystroke,
        // e.g. if you are changing the text or the code in a codeblock
        // This is a performance optimization!
        shouldUpdate(events) {
            // Do not trigger update if only Y.Text changed inside of the pages
            const allTextEvents = events.every((e) => e instanceof Y.YTextEvent);

            // Do not trigger update if events come from Code block
            const allCodeContentEvents = events.every(
                (e) =>
                    e instanceof Y.YMapEvent &&
                    e.keysChanged.has('content') &&
                    e.target instanceof Y.Map &&
                    e.target.get('type') === 'code',
            );

            const arrayUpdated = events.some((e) => {
                if (e instanceof Y.YArrayEvent && e.target === pagesSafeArrayOutput.array) {
                    return true;
                }

                if (e instanceof Y.YMapEvent && e.path.length === 1) {
                    return keys.some((k) => e.keysChanged.has(k));
                }

                const [, key] = e.path;
                return typeof key === 'string' && keys.includes(key as K);
            });

            return !allTextEvents && !allCodeContentEvents && arrayUpdated;
        },
    });

    return React.useMemo(() => {
        // we need to retrigger the useMemo when the safeArray changes
        return SpaceOperations.getPages(space, keys, pageMode, (pageId) => {
            switch (pageMode) {
                case 'public':
                    return (
                        sharedPagesStatuses?.allSharedPagesStatusesByUrlKey.some(
                            (s) => s.id === pageId && s.isPublic && hasAccess(pageId),
                        ) ?? false
                    );
                case 'private':
                    return (
                        sharedPagesStatuses?.allSharedPagesStatusesByUrlKey.some(
                            (s) => s.id === pageId && s.isPrivateInSpace && hasAccess(pageId),
                        ) ?? false
                    );
                default:
                    return hasAccess(pageId);
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [keys, safeArray, pageMode, space, safeArray.array.length, hasAccess, sharedPagesStatuses]);
};

const templatePageKeys = [
    'id',
    'aliases',
    'title',
    'icon',
    'collections',
    'archivedAt',
    'isTemplate',
    'createdAt',
] as const;

export function useTemplates() {
    return usePartialPages(templatePageKeys, undefined, 'templates');
}

const sidebarPagesKeys = [
    'id',
    'title',
    'collections',
    'archivedAt',
    'icon',
    'isTemplate',
    'createdAt',
    'updatedAt',
] as const;

export function useSidebarPages() {
    return usePartialPages(sidebarPagesKeys, 'deep');
}

export function useShallowTasks() {
    return useTasks({
        mode: 'shallow',
        assigneeFilter: { kind: 'all' },
        priorityFilter: 'all',
        archivedFilter: 'non-deleted',
    });
}

export function useSidebarTasks() {
    return useTasks({
        mode: 'deep',
        assigneeFilter: { kind: 'all' },
        priorityFilter: 'all',
        archivedFilter: 'non-deleted',
    });
}

export const useCurrentPageId = () => {
    const match = useRouteMatch<{ pageid: string }>('/s/:id/:pageid');
    const pageId = match?.params.pageid;
    return pageId && uuidValidate(pageId) ? pageId : undefined;
};

export const useCurrentTaskId = () => {
    const match = useRouteMatch<{ taskid: string }>('/s/:id/t/:taskid');
    const taskId = match?.params.taskid;
    return taskId && uuidValidate(taskId) ? taskId : undefined;
};

function usePageMap(pageId: string): Y.Map<unknown> | null {
    const { space } = useSpace();

    const [pageMap, setPageMap] = React.useState(() => SpaceOperations.getPageMapById(space, pageId));

    // In this useEffect, we observe the space and check if there are (shallow)
    // changes in the pages of the space. This fires when we completely reset the attribute
    // e.g. space.set("pages", new Y.Array()). We also observe added and deleted pages
    // This fixes a very obscure bug that could lead to content loss
    React.useEffect(() => {
        // when the space or the pageId change, we need also change the pageMap
        refreshPageMap();

        function refreshPageMap() {
            const pageMap = SpaceOperations.getPageMapById(space, pageId);
            setPageMap(pageMap);
        }

        function resetPagesObserver() {
            const yPages = unsafeRight(
                pipe(
                    space.get('pages'),
                    E.map((pages) => pages.array),
                ),
            );

            // This removes any previous observer
            if (yPages._eH.l.includes(refreshPageMap)) {
                yPages.unobserve(refreshPageMap);
            }

            // this sets the new observer
            yPages.observe(refreshPageMap);
        }

        resetPagesObserver();

        function spaceObserve(event: Y.YMapEvent<unknown>) {
            if (event.keysChanged.has('pages')) {
                refreshPageMap();

                // we also need to reset the pagesObserver in case the pages array has been set
                resetPagesObserver();
            }
        }

        space.map.observe(spaceObserve);

        return () => {
            space.map.unobserve(spaceObserve);

            const yPages = unsafeRight(
                pipe(
                    space.get('pages'),
                    E.map((pages) => pages.array),
                ),
            );

            // this will also remove the resetted observer from above
            yPages.unobserve(refreshPageMap);
        };
    }, [space, pageId]);

    return pageMap;
}

export const usePageSnapshot = <K extends keyof WeakPage>(
    pageId: string,
    pageKeys: ReadonlyNonEmptyArray<K>,
    observerMode: ObserverMode = 'flat',
): Pick<WeakPage, K> | null => {
    const map = usePageMap(pageId);

    const safeMapMemoized = React.useMemo(() => {
        return safeMap({ map: map ?? new Y.Map(), definition: pageD });
    }, [map]);

    const safeMapValue = useSafeMap(safeMapMemoized, observerMode === 'deep');

    return React.useMemo(() => {
        // TODO: this check should be done above, find a proper solution
        if (safeMapValue.map === undefined) return null;

        const [first, ...rest] = pageKeys;

        const partial = rest.reduce(
            (acc, curr) => ({
                ...acc,
                [curr]: safeMapValue.getDecoded(curr),
            }),
            { [first]: safeMapValue.getDecoded(first) },
        );

        // Typescript is not smart enough to resolve the correct type!
        // @ts-ignore
        const partialPage: E.Either<DecodingError, Pick<Page, K>> = sequenceS(E.Apply)(partial);

        return pipe(
            partialPage,
            E.getOrElseW(() => null),
        );
    }, [pageKeys, safeMapValue]);
};

export const useNonNullablePageSnapshot = <K extends keyof WeakPage>(
    pageId: string,
    pageKeys: ReadonlyNonEmptyArray<K>,
    observerMode: ObserverMode = 'flat',
) => {
    const page = usePageSnapshot(pageId, pageKeys, observerMode);
    assertNonNull(page, `Page with id ${pageId} does not exist`);
    return page;
};

export function useCreatePage() {
    const { space, provider } = useSpace();
    const { showToast } = useToastContext();
    const setQuickEditPageId = useSetQuickEditModalPage();
    const openPage = useOpenPage();
    const history = useHistory();
    const currentWorkspace = useCurrentWorkspace();
    const { addRecentLocation } = useRecents();
    const performActionWithBlocks = usePerformActionWithYBlocks();
    return React.useCallback(
        (fields: Partial<Page>, options?: { showToast?: boolean; redirect?: boolean }): Page => {
            const page = newBlankPage(fields);
            createPage(space, page, provider);

            performActionWithBlocks(SagaLocation.pageLocationFromId(page.id), () => {
                track('create-page');
            });

            if (options?.showToast) {
                showToast(
                    OnPageCreated(
                        page.title,
                        (event) => openPage(page.id, event),
                        () => setQuickEditPageId && setQuickEditPageId(page.id),
                    ),
                );
            }

            if (options?.redirect) {
                addRecentLocation(SagaLocation.pageLocationFromId(page.id), currentWorkspace.urlKey);
                history.push(`/s/${currentWorkspace.urlKey}/${page.id}`);
            }

            return page;
        },
        [
            space,
            showToast,
            setQuickEditPageId,
            openPage,
            history,
            addRecentLocation,
            currentWorkspace,
            performActionWithBlocks,
            provider,
        ],
    );
}

export function useSendAsLiveBlock(location: SagaLocation.BlocksLocation) {
    const { space, provider } = useSpace();
    const editor = useSlateStatic();
    const openPage = useOpenPage();
    const { showToast } = useToastContext();
    const setQuickEditPageId = useSetQuickEditModalPage();
    const performAppendBlocks = usePerformAppendBlock();

    return React.useCallback(
        async ({
            location: slateLocation,
            selectedItem,
        }: {
            location: Location;
            selectedItem: PageSuggestion | CreatePageSuggestion;
        }) => {
            invariant(SagaLocation.isPageLocation(location), `Not implemented for location of type ${location.type}`);

            track('create-reference', { source: 'hovering-toolbar' });
            switch (selectedItem.type) {
                case 'page': {
                    const targetPage = SpaceOperations.getPageById(space, selectedItem.page.id, ['id', 'title']);
                    if (!targetPage) {
                        return;
                    }
                    const refBlock = SpaceOperations.createLiveBlock(space, {
                        editor,
                        at: slateLocation,
                        origin: location,
                    });
                    performAppendBlocks(space, { type: 'page', pageId: targetPage.id, blockId: undefined }, [refBlock]);
                    showToast(
                        OnReferenceCreatedToast(
                            targetPage.title,
                            (event) => openPage(targetPage.id, event),
                            () => setQuickEditPageId && setQuickEditPageId(targetPage.id),
                        ),
                    );

                    break;
                }
                case 'create': {
                    const newPage = createPage(
                        space,
                        newBlankPage({
                            id: uuid(),
                            title: selectedItem.title,
                        }),
                        provider,
                    );

                    const refBlock = SpaceOperations.createLiveBlock(space, {
                        editor,
                        at: slateLocation,
                        origin: location,
                    });

                    const blocks = [refBlock, BlockBuilder.paragraph()];

                    performAppendBlocks(
                        space,
                        { type: 'page', pageId: newPage.id, blockId: undefined },
                        blocks as AnyBlockItem[],
                    );

                    showToast(
                        OnReferenceCreatedToast(
                            newPage.title,
                            (event) => openPage(newPage.id, event),
                            () => setQuickEditPageId && setQuickEditPageId(newPage.id),
                        ),
                    );
                    track('create-page', { source: 'on-send-ref' });
                    break;
                }
            }
        },
        [space, location, setQuickEditPageId, editor, openPage, showToast, performAppendBlocks, provider],
    );
}

export function useMoveToPage() {
    const { space, provider } = useSpace();
    const editor = useSlateStatic();
    const openPage = useOpenPage();
    const { showToast } = useToastContext();
    const setQuickEditPageId = useSetQuickEditModalPage();
    const performAppendBlocks = usePerformAppendBlock();
    return React.useCallback(
        ({ location, selectedItem }: { location: Location; selectedItem: PageSuggestion | CreatePageSuggestion }) => {
            const blocks = SlateEditor.fragment(editor, location).map((block): AnyBlockItem => {
                if (isTitle(block)) {
                    return BlockBuilder.headingOne(block.children);
                }

                return block as AnyBlockItem;
            });

            track('move-text');

            Transforms.delete(editor, {
                at: location,
            });

            switch (selectedItem.type) {
                case 'page': {
                    const targetPage = SpaceOperations.getPageById(space, selectedItem.page.id, ['id', 'title']);
                    if (!targetPage) {
                        return;
                    }

                    performAppendBlocks(space, { type: 'page', pageId: targetPage.id, blockId: undefined }, blocks);

                    showToast(
                        OnContentMovedToast(
                            targetPage.title,
                            (event) => openPage(targetPage.id, event),
                            () => setQuickEditPageId && setQuickEditPageId(targetPage.id),
                        ),
                    );
                    break;
                }
                case 'create': {
                    const newPage = createPage(
                        space,
                        newBlankPage({
                            title: selectedItem.title,
                            blocks: [BlockBuilder.title(selectedItem.title), ...blocks, BlockBuilder.paragraph()],
                        }),
                        provider,
                    );

                    showToast(
                        OnContentMovedToast(
                            newPage.title,
                            (event) => openPage(newPage.id, event),
                            () => setQuickEditPageId && setQuickEditPageId(newPage.id),
                        ),
                    );

                    track('create-page', { source: 'on-send-text' });
                    break;
                }
            }
        },
        [space, editor, openPage, showToast, setQuickEditPageId, performAppendBlocks, provider],
    );
}
