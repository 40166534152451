import React, { useContext } from 'react';
import * as api from '@saga/api';

export const WorkspacesContext = React.createContext<{
    loading: boolean;
    workspaces: api.SpaceWithMembersFragment[];
}>({ loading: false, workspaces: [] });

export function useWorkspaces() {
    const result = useContext(WorkspacesContext);

    return result;
}
