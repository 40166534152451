import React, { useState, useRef, KeyboardEventHandler } from 'react';
import { track } from '@/analytics';
import classNames from 'classnames';
import Onboarding from '../Onboarding';
import Button from '@/components/styled/Button';
import * as api from '@saga/api';
import * as R from 'ramda';
import { FormEvent } from 'react';
import { setUserProperties } from '@/analytics';
import { useTranslation } from 'react-i18next';

const USE_CASES: Record<string, string> = {
    personalNotes: 'onboarding.usecase_personalNotes',
    // workNotes: 'onboarding.usecase_workNotes',
    teamWork: 'onboarding.usecase_teamWork',
    // studying: 'onboarding.usecase_studying',
    // worldbuilding: 'onboarding.usecase_worldbuilding',
};

function SpaceSurveyModal({
    urlKey,
    content,
    submitButtonLabel,
    onSubmitted,
}: {
    urlKey: string;
    content: React.ReactNode;
    submitButtonLabel: string;
    onSubmitted?: () => void;
}) {
    const { t } = useTranslation();
    const ref = useRef<HTMLSpanElement | null>(null);
    const [isEditingValue, setIsEditingValue] = useState(false);
    const [value, setValue] = useState('');
    const [selectedUseCases, setSelectedUseCases] = useState<string[]>([]);
    const [customUseCases, setCustomUseCases] = useState<string[]>([]);

    const [updateSpaceUseCases, { loading }] = api.useUpdateSpaceUseCasesMutation();

    const onSubmit = async (e: FormEvent) => {
        e.preventDefault();

        for (const selectedOption of selectedUseCases) {
            track('selected-use-case', { value: selectedOption });
        }
        for (const customUseCase of customUseCases) {
            track('new-use-case', { value: customUseCase });
        }

        const selectedOptions = selectedUseCases.reduce<Record<string, true>>(
            (result: Record<string, true>, option: string) => {
                return { ...result, [option]: true };
            },
            {},
        );
        setUserProperties(selectedOptions);

        await updateSpaceUseCases({ variables: { input: { urlKey, useCases: selectedUseCases } } });

        onSubmitted && onSubmitted();
    };

    const selectUseCase = (useCase: string) => {
        setSelectedUseCases((useCases) => {
            if (useCases.includes(useCase)) {
                return useCases.filter((u) => u !== useCase);
            } else {
                return [...useCases, useCase];
            }
        });
    };

    const onClick = () => {
        setValue('');
        setIsEditingValue(true);
        if (ref) {
            setTimeout(() => {
                ref.current?.focus();
            }, 0);
        }
    };

    const onBlur = () => {
        const text = ref.current?.innerText;
        if (text && text.length > 0) {
            setCustomUseCases((customUseCases) => R.uniq([...customUseCases, text]));
            setSelectedUseCases((selected) => [...selected, text]);
        }
        setValue('');
        setIsEditingValue(false);
    };

    const onKeyDown: KeyboardEventHandler<HTMLSpanElement> = (e) => {
        if (e.key === 'Escape' || e.key === 'Enter') {
            e.preventDefault();
            if (ref) {
                setTimeout(() => {
                    ref.current?.blur();
                }, 0);
            }
        }
    };

    return (
        <Onboarding>
            <Onboarding.SurveyContainer>
                <Onboarding.Container>
                    <Onboarding.Logo />
                    {content}
                </Onboarding.Container>
                <div className="flex flex-wrap p-2 mb-4 space-x-2 space-y-4 justify-center">
                    <span />
                    {Object.keys(USE_CASES).map((useCase: string, index: number) => (
                        <Button.SelectButton
                            key={`useCase-${index}`}
                            checked={selectedUseCases.includes(useCase)}
                            check={() => selectUseCase(useCase)}
                        >
                            <span>{t(USE_CASES[useCase])}</span>
                        </Button.SelectButton>
                    ))}

                    {customUseCases.map((option: string, index: number) => (
                        <Button.SelectButton
                            key={`customUseCase-${index}`}
                            checked={selectedUseCases.includes(option)}
                            check={() => selectUseCase(option)}
                        >
                            <span>{option}</span>
                        </Button.SelectButton>
                    ))}
                    <span
                        ref={ref}
                        role="textbox"
                        className={classNames(
                            'inline-block outline-none focus:outline-none focus-visible:outline-dotted focus-visible:outline-saga-gray-500 text-sm tracking-wider',
                            'border border-saga-gray-500 shadow-md items-center py-1 md:p-2 rounded',
                            'hover:bg-saga-gray-200 dark:hover:bg-zinc-600',
                            { 'px-4': isEditingValue },
                            { 'px-2': !isEditingValue },
                        )}
                        onClick={onClick}
                        onBlur={onBlur}
                        onKeyDown={onKeyDown}
                        contentEditable={true}
                        suppressContentEditableWarning={true}
                    >
                        {isEditingValue ? value : 'Other'}
                    </span>
                </div>
                <Onboarding.Form onSubmit={onSubmit}>
                    <Onboarding.Button disabled={loading || selectedUseCases.length === 0} onClick={onSubmit}>
                        {submitButtonLabel}
                    </Onboarding.Button>
                </Onboarding.Form>
            </Onboarding.SurveyContainer>
        </Onboarding>
    );
}

export default SpaceSurveyModal;
