import React from 'react';
import { isFile, assertNonNull, isImage, SpaceOperations, WeakBlocks, WeakTask } from '@/../../shared/src';
import { useCurrentSearchIndex, useSpace } from '@/components/SpaceProvider';
import { useUserContext } from '@/components/UserContext';
import { useCurrentWorkspace } from '@/components/WorkspaceContext';
import * as api from '@saga/api';
import { debuglog } from 'util';
import { isContentResultWithData, useDocumentContentRealtime } from '@/components/RealtimeDocumentProvider';
import { useToastContext } from '@/components/toast/ToastContext';
import { OnTaskArchived } from '@/components/toast/ToastLibrary';

export function useBatchDeleteTasksPermanently() {
    const { user } = useUserContext();
    const { urlKey } = useCurrentWorkspace();
    const { space } = useSpace();
    const [deleteAsset] = api.useDeleteAssetMutation();
    const spaceblocks = useCurrentSearchIndex();

    return React.useCallback(
        async (ids: string[]) => {
            assertNonNull(user);
            const tasks = ids.map((id) => SpaceOperations.findTask(space, id));
            assertNonNull(tasks);

            const deleteContent = async () => {
                const blocksMap: { [key: string]: WeakBlocks } = spaceblocks?.toJSON() || {};
                const blocksToDelete = Object.keys(blocksMap)
                    .flatMap((key) => (ids.includes(key) ? blocksMap[key] : []))
                    .flat();

                const assetBlocks = blocksToDelete.filter((block) => isImage(block) || isFile(block));

                if (assetBlocks.length) {
                    const assetKeys = assetBlocks.map((block) => block.url?.split('/').slice(-2).join('/') ?? '');
                    await deleteAsset({ variables: { input: { assetKeys, urlKey } } });
                }
            };

            deleteContent()
                .catch((e) => debuglog(e))
                .finally(() => {
                    SpaceOperations.transactInSpace(space, () =>
                        tasks.forEach((task) => {
                            assertNonNull(task);
                            SpaceOperations.deleteTaskPermanently(space, task.id);
                        }),
                    );
                });
        },
        [space, urlKey, user, spaceblocks, deleteAsset],
    );
}

export function useDeleteTaskPermanently() {
    const { user } = useUserContext();
    const { urlKey } = useCurrentWorkspace();
    const { space } = useSpace();
    const [deleteAsset] = api.useDeleteAssetMutation();
    const { loadDocument, unLoadDocument } = useDocumentContentRealtime();

    return React.useCallback(
        async (id: string) => {
            assertNonNull(user);
            const task = SpaceOperations.findTask(space, id);
            assertNonNull(task);

            const deleteContent = async () => {
                const content = await loadDocument(id);

                if (content && isContentResultWithData(content)) {
                    const blocks: any[] = content.data.map.toJSON().blocks;
                    const assetBlocks = blocks.filter((block) => isImage(block) || isFile(block));

                    if (assetBlocks.length) {
                        const assetKeys = assetBlocks.map((block) => block.url?.split('/').slice(-2).join('/') ?? '');
                        await deleteAsset({ variables: { input: { assetKeys, urlKey } } });
                    }
                }

                unLoadDocument(id);
            };

            deleteContent()
                .catch((e) => debuglog(e))
                .finally(() => {
                    SpaceOperations.deleteTaskPermanently(space, id);
                });
        },
        [space, urlKey, user, deleteAsset, loadDocument, unLoadDocument],
    );
}

export function useDeleteTaskIfEmpty() {
    const { user } = useUserContext();
    const { space } = useSpace();

    return React.useCallback(
        (task: Pick<WeakTask, 'id'> & { blocks: WeakBlocks }) => {
            if (!user) {
                return;
            }

            SpaceOperations.deleteTaskIfEmpty(space, task);
        },
        [space, user],
    );
}

export function useArchiveTask() {
    const { showToast } = useToastContext();
    const { space } = useSpace();
    const { user } = useUserContext();

    return React.useCallback(
        (id: string) => {
            if (!user) {
                return;
            }
            SpaceOperations.deleteTask(space, id);
            showToast(OnTaskArchived());
        },
        [space, showToast, user],
    );
}
