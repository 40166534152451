import { newBlankPage, SagaLocation, SpaceOperations } from '@/../../shared/src';
import { isFeatureEnabled, track } from '@/analytics';
import { useDesktopContext } from '@/components/DesktopContext';
import FocusTrap from '@/components/FocusTrap';
import {
    CloseSidebarIcon,
    GripIcon,
    NotificationsIcon,
    NotificationsIndicator,
    OpenSidebarIcon,
} from '@/components/icons';
import Collections from '@/components/navigation/Collections';
import SidebarItemButton from '@/components/navigation/SidebarItemButton';
import WorkspaceAvatar from '@/components/navigation/WorkspaceAvatar';
import { useOpenLocation, useOpenPage } from '@/components/PageNavigationProvider';
import TemplatesPopOver from '@/components/popover/TemplatesPopOver';
import { useSetSettings } from '@/components/settings/SettingsProvider';
import { useSpace } from '@/components/SpaceProvider';
import Button from '@/components/styled/Button';
import { useTemplatesContext } from '@/components/templates/TemplatesProvider';
import { useUserContext } from '@/components/UserContext';
import { useSubscriptions, useCurrentWorkspace } from '@/components/WorkspaceContext';
import { useWorkspaces } from '@/components/WorkspacesContext';
import { usePinned, useSidebarPages, useSidebarTasks, useTemplates, useFavorites } from '@/hooks/SpaceHooks';
import useInterfaceSettings from '@/hooks/useInterfaceSettings';
import { useLogout } from '@/hooks/useLogout';
import useOnEscape from '@/hooks/useOnEscape';
import { useSpaceAccess } from '@/hooks/useSpaceAccess';
import { config, SpringValue, useSpring, useTransition } from '@react-spring/core';
import { animated } from '@react-spring/web';
import * as api from '@saga/api';
import { useDrag as useGestureDrag } from '@use-gesture/react';
import classNames from 'classnames';
import isHotkey from 'is-hotkey';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { DropTargetMonitor, useDrag, useDrop, XYCoord } from 'react-dnd';
import { ChevronDown, ChevronUp, LogOut, Plus, Sliders, X } from 'react-feather';
import { Flipped, Flipper } from 'react-flip-toolkit';
import { Link, useHistory } from 'react-router-dom';
import { ArrowUpCircle } from 'react-feather';
import { UpgradePlanBannerBig } from '../billing/UpgradePlanBannerBig';
import Modal from '../Modal';
import { NewWorkspaceModal } from '../billing/NewWorkspaceModal';
import { useTranslation } from 'react-i18next';
import { FeatureFlag } from '@/../../shared/src/utils/FeatureFlags';

import Tasks from './Tasks';
import Pages from './Pages';
import { createPage } from '@/utils/documentUtils';
import { usePerformActionWithYBlocks } from '@/components/RealtimeDocumentProvider';
import { CustomTemplate, PredefinedTemplate } from '@saga/shared';

import HelpMenu from '@/components/popover/HelpMenu';
import Pinned from '@/components/navigation/Pinned';
import Favorites from './Favorites';
import SagaChatButton from './SagaChatButton';

function SidebarTemplatePopover({
    onClose,
    attachToRef,
}: {
    onClose(): void;
    attachToRef: React.RefObject<HTMLElement>;
}) {
    const setTemplatesPickerOpen = useTemplatesContext();
    const openPage = useOpenPage();
    const { space, provider } = useSpace();
    const templates = useTemplates();
    const { t } = useTranslation();

    const performActionWithBlocks = usePerformActionWithYBlocks();
    const performApplyTemplateToNewPage = React.useCallback(
        (template: CustomTemplate | PredefinedTemplate, event) => {
            function applyTemplate(template: CustomTemplate | PredefinedTemplate, action: (blocks: any) => void) {
                if (template.blocks) {
                    action(template.blocks);
                } else {
                    performActionWithBlocks(SagaLocation.pageLocationFromId(template.id), (templateBlocks) => {
                        action(templateBlocks.toJSON());
                    });
                }
            }

            applyTemplate(template, (blocks) => {
                const page = createPage(space, newBlankPage({ blocks }), provider);
                SpaceOperations.applyTemplateMetaToLocation(space, {
                    template,
                    location: SagaLocation.pageLocationFromId(page.id),
                });
                openPage(page.id, event);
            });
        },
        [performActionWithBlocks, space, openPage, provider],
    );

    return (
        <TemplatesPopOver
            onExploreTemplatesClick={() => {
                setTemplatesPickerOpen(true);
            }}
            onClose={onClose}
            isOpen
            onCreateTemplate={(title, event) => {
                const template = createPage(space, newBlankPage({ isTemplate: true, title }), provider);
                openPage(template.id, event);
            }}
            onApplyCustomTemplate={performApplyTemplateToNewPage}
            onApplyPredefinedTemplate={performApplyTemplateToNewPage}
            customTemplates={templates}
            recentlyUsedTemplateIds={SpaceOperations.getRecentlyUsedTemplateIds(space)}
            attachToRef={attachToRef}
            inputProps={{
                placeholder: t('common.create_template_placeholder') as string,
            }}
        />
    );
}

type DragItem = {
    index: number;
    id: string;
    type: string;
};

function useWorkspaceDnd({
    id,
    index,
    onMove,
    onDrop,
}: {
    id: string;
    index: number;
    onMove(dragIndex: number, hoverIndex: number): void;
    onDrop(item: DragItem): void;
}) {
    const ref = useRef<HTMLAnchorElement>(null);

    const [, drop] = useDrop<DragItem>({
        accept: 'avatar',
        drop: onDrop,
        hover(item: DragItem, monitor: DropTargetMonitor) {
            if (!ref.current) {
                return;
            }

            const dragIndex = item.index;
            const hoverIndex = index;

            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }

            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect();

            // Get vertical middle
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

            // Determine mouse position
            const clientOffset = monitor.getClientOffset();

            // Get pixels to the top
            const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%

            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }

            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }

            // Time to actually perform the action
            onMove(dragIndex, hoverIndex);

            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex;
        },
    });

    const [{ isDragging, isAnyDragging, handlerId }, drag] = useDrag({
        type: 'avatar',
        item: () => ({ id, index }),
        options: {
            dropEffect: 'copy',
        },
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
                isAnyDragging: monitor.getItem() != null,
                isDragging: monitor.isDragging(),
            };
        },
    });

    drag(drop(ref));

    return { ref, isDragging, isAnyDragging, handlerId };
}

function OpenSidebar({
    location,
    isOpen,
    isMobile,
    hasUnreadNotifications,
    progress,
    closedWidth,
    openWidth,
    onClose,
    onOpenNotifications,
}: {
    location?: SagaLocation.SagaLocation;
    isMobile: boolean;
    isOpen: boolean;
    hasUnreadNotifications: boolean;
    progress: SpringValue<number>;
    closedWidth: number;
    openWidth: number;
    onClose(): void;
    onOpenNotifications(): void;
}) {
    const { t } = useTranslation();
    const contextMenuRef = useRef<HTMLDivElement>(null);
    const contextMenuButtonRef = useRef<HTMLButtonElement>(null);
    const titleButtonRef = useRef<HTMLButtonElement>(null);
    const [contextMenuOpen, setContextMenuOpen] = useState(false);
    const [templateMenuOpen, setTemplateMenuOpen] = useState(false);

    const templateMenuButtonRef = useRef<HTMLButtonElement>(null);
    const pages = useSidebarPages();
    const tasks = useSidebarTasks();

    const openPage = useOpenPage();
    const pinned = usePinned();
    const favorites = useFavorites();
    const currentWorkspace = useCurrentWorkspace();
    const { currentSubscription } = useSubscriptions();

    const { isLoggedIn } = useUserContext();
    const contextMenuAnimation = useSpring({ progress: contextMenuOpen ? 1 : 0 });
    const setSettings = useSetSettings();
    const [, setInterfaceSettings] = useInterfaceSettings();
    const { space, provider } = useSpace();
    const { canEdit, isOwner, member } = useSpaceAccess();
    const { useMacOsLayout, isDesktop } = useDesktopContext();

    const selectedId = location ? SagaLocation.getIdFromLocation(location) : undefined;

    const openLocation = useOpenLocation();
    const [showUpgradePlanModal, setShowUpgradePlanModal] = useState(false);
    const [showUpgradePlanModalSimple, setShowUpgradePlanModalSimple] = useState(false);

    function toggleContextMenu() {
        track('sidebar-context-menu', { action: contextMenuOpen ? 'close' : 'open' });
        setContextMenuOpen((isOpen) => !isOpen);
    }

    function openSettings() {
        !!member ? setSettings('space') : setSettings('members');
        track('open-settings');
    }

    const onNewPage = useCallback(
        (event: React.MouseEvent | any) => {
            const newPage = newBlankPage({});
            createPage(space, newPage, provider);

            track('create-page', { source: 'navbar' });
            openPage(newPage.id, event);

            if (isMobile) {
                setInterfaceSettings({ fixedSidebar: false });
            }
        },
        [space, provider, isMobile, setInterfaceSettings, openPage],
    );

    const NEW_PAGE = 'mod+n';

    React.useEffect(() => {
        const handleKeyDown = (event: any) => {
            if (isHotkey(NEW_PAGE, event)) {
                event.preventDefault();
                onNewPage({});
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        return () => window.removeEventListener('keydown', handleKeyDown);
    }, [onNewPage]);

    function openPagesTable(event: React.MouseEvent) {
        openLocation({ type: 'allPages', viewId: 'non-deleted' }, event);

        if (isMobile) {
            setInterfaceSettings({ fixedSidebar: false });
        }
    }

    function openTasksTable(event: React.MouseEvent) {
        openLocation({ type: 'allTasks', viewId: 'all' }, event);

        if (isMobile) {
            setInterfaceSettings({ fixedSidebar: false });
        }
    }

    function toggleTemplateMenu(event: React.MouseEvent) {
        event.preventDefault();
        event.stopPropagation();

        if (!templateMenuOpen) {
            track('open-template-dropdown', { source: 'navbar' });
        } else {
            track('close-template-dropdown', { source: 'navbar' });
        }
        setTemplateMenuOpen((isOpen) => !isOpen);
    }

    function closeTemplateMenu() {
        setTemplateMenuOpen(false);
    }

    const closeContextMenu = useCallback(() => setContextMenuOpen(false), []);
    useOnEscape(closeContextMenu, contextMenuOpen);

    const opacity = progress.to({ range: [0.0, 1.0], output: [0, 1] });
    const maxWidth = progress.to({ range: [0.0, 1.0], output: [closedWidth, openWidth] });

    return (
        <animated.div
            style={isMobile ? undefined : { maxWidth }}
            className="absolute inset-0 w-full flex flex-col bg-white dark:bg-saga-gray-1000"
        >
            <div
                className={classNames('flex items-center space-x-2 border-b', {
                    'border-saga-gray-200 dark:border-saga-gray-800': isOpen,
                    'border-transparent': !isOpen,
                    'pl-16': useMacOsLayout,
                })}
            >
                <animated.button
                    onClick={onClose}
                    type="button"
                    className="focus:outline-none pl-3 py-3.5 dark:bg-saga-gray-1000"
                >
                    <span className="sr-only">{t('common.close_sidebar')}</span>
                    <div className={classNames('', { 'mt-[1px]': isDesktop })}>
                        <WorkspaceAvatar workspace={currentWorkspace} />
                    </div>
                </animated.button>
                <animated.span
                    style={{ opacity }}
                    data-testid="workspace-title"
                    className="[line-height:20px] font-semibold flex items-center flex-auto pr-2 min-w-0 space-x-2 justify-between"
                >
                    <span className="flex items-center min-w-0 space-x-1">
                        {!isLoggedIn && <span className="truncate min-w-0 text-sm">{currentWorkspace.title}</span>}

                        {isLoggedIn && (
                            <>
                                <button
                                    ref={titleButtonRef}
                                    onClick={toggleContextMenu}
                                    className="focus:outline-none font-semibold truncate min-w-0 text-sm"
                                >
                                    {currentWorkspace.title}
                                </button>
                                <Button.Plain
                                    aria-label={
                                        t(
                                            contextMenuOpen
                                                ? 'common.close_sidebar_context_menu'
                                                : 'common.open_sidebar_context_menu',
                                        ) as string
                                    }
                                    ref={contextMenuButtonRef}
                                    onClick={toggleContextMenu}
                                >
                                    <Button.XsPadding>
                                        <ChevronDown size={14} />
                                    </Button.XsPadding>
                                </Button.Plain>
                            </>
                        )}
                    </span>

                    {isLoggedIn && (
                        <span className="flex items-center space-x-2">
                            <Button.Plain
                                data-testid={hasUnreadNotifications ? 'notifications-unread' : 'notifications-all-read'}
                                aria-label={t('common.open_notifications') as string}
                                onClick={onOpenNotifications}
                            >
                                <Button.XsPadding>
                                    <div className="relative">
                                        <NotificationsIcon className="w-3.5 h-3.5" />
                                        {hasUnreadNotifications && (
                                            <NotificationsIndicator className="absolute -right-1 -top-1" />
                                        )}
                                    </div>
                                </Button.XsPadding>
                            </Button.Plain>
                        </span>
                    )}
                </animated.span>
            </div>

            <animated.div
                style={{ opacity }}
                className="flex-1 w-full relative flex flex-col justify-between overflow-hidden"
            >
                <animated.div
                    style={{
                        transform: contextMenuAnimation.progress.to({
                            range: [0.0, 1.0],
                            output: ['translateY(-110%)', 'translateY(0%)'],
                        }),
                    }}
                    ref={contextMenuRef}
                    data-testid="sidebar-context-menu"
                    className="absolute bg-white dark:bg-saga-gray-1000 shadow right-0 left-0 z-60 overflow-y-auto max-h-full border-saga-gray-200 dark:border-zinc-600"
                >
                    <FocusTrap
                        onClose={closeContextMenu}
                        prevKeys={['ArrowUp']}
                        nextKeys={['ArrowDown']}
                        active={contextMenuOpen}
                        triggerRefs={[titleButtonRef, contextMenuButtonRef]}
                    >
                        <SidebarContextMenu
                            isMobile={isMobile}
                            onClose={closeContextMenu}
                            showBillingModal={() => setShowUpgradePlanModal(true)}
                        />
                    </FocusTrap>
                </animated.div>
                <animated.div
                    style={{
                        opacity: contextMenuAnimation.progress.to({
                            range: [0.0, 1.0],
                            output: [0, 1],
                        }),
                        pointerEvents: contextMenuOpen ? 'all' : 'none',
                    }}
                    className="absolute bg-white/50 dark:bg-zinc-700/40 inset-0 z-50"
                />
                <div className="flex flex-col flex-auto min-h-0" style={{ width: isMobile ? '100%' : openWidth }}>
                    <div className="w-full" data-testid="sidebar-items">
                        <div className="p-2 space-y-0.5">
                            <div className="relative group">
                                {canEdit && (
                                    <Button.Plain widthFull onClick={onNewPage}>
                                        <div className="pl-[5px] pr-1 py-1.5 flex items-center space-x-[7px]">
                                            <Plus className="flex-none w-5 h-5 shrink-0 stroke-saga-green" />
                                            <div className="min-w-0 font-medium text-sm">{t('sidebar.new_page')}</div>
                                        </div>
                                    </Button.Plain>
                                )}
                                {canEdit && !isMobile && (
                                    <SidebarItemButton.ContextMenuContainer pinned={templateMenuOpen}>
                                        <Button.NestedButton
                                            onClick={toggleTemplateMenu}
                                            ref={templateMenuButtonRef}
                                            data-testid="open-template-btn"
                                        >
                                            {templateMenuOpen ? <ChevronUp size={14} /> : <ChevronDown size={14} />}
                                            <span className="sr-only">{t('sidebar.open_template_menu')}</span>
                                        </Button.NestedButton>
                                    </SidebarItemButton.ContextMenuContainer>
                                )}
                            </div>

                            <SagaChatButton isSelected={selectedId === 'sagaAI'} />

                            <Button.Plain
                                isSelected={
                                    location?.type === 'allPages' &&
                                    (!selectedId || ![...pinned, ...favorites].includes(selectedId))
                                }
                                widthFull
                            >
                                <Pages openPagesTable={openPagesTable} />
                            </Button.Plain>

                            <Button.Plain
                                isSelected={
                                    location?.type === 'allTasks' &&
                                    (!selectedId || ![...pinned, ...favorites].includes(selectedId))
                                }
                                widthFull
                            >
                                <Tasks openTasksTable={openTasksTable} />
                            </Button.Plain>

                            <Collections pages={pages} tasks={tasks} selectedId={selectedId} />
                        </div>
                    </div>

                    <div className="flex-1 overflow-y-auto hide-scrollbar">
                        <div className="flex flex-col">
                            <div className="mx-3.5 border-b border-saga-gray-200 dark:border-saga-gray-800" />
                            {favorites.length > 0 && (
                                <div className="px-2 pt-2 pb-0 w-full space-y-1">
                                    <Favorites selectedId={selectedId} pages={pages} tasks={tasks} />
                                </div>
                            )}
                            {pinned.length > 0 && (
                                <div className="pt-1.5 px-2 pb-2 w-full space-y-1">
                                    <Pinned selectedId={selectedId} pages={pages} tasks={tasks} />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div>
                    {isFeatureEnabled(FeatureFlag.allowBilling) && !currentSubscription && !isMobile && (
                        <animated.div
                            style={{ opacity }}
                            className={classNames(
                                'mx-2 p-2 rounded border border-saga-gray-200 dark:border-saga-gray-800 flex',
                                {
                                    'flex-col items-start': openWidth <= 210,
                                    'items-center justify-between': openWidth > 210,
                                },
                            )}
                        >
                            <div
                                className={classNames('flex items-center space-x-4', {
                                    'pb-1': openWidth <= 210,
                                })}
                            >
                                <ArrowUpCircle size={16} className="mr-2" />
                                <span className="font-semibold text-sm">{t('common.free_plan')}</span>
                            </div>
                            <Button.Action
                                onClick={() => {
                                    isOwner ? setSettings('plans') : setShowUpgradePlanModalSimple(true);
                                }}
                            >
                                <span className="px-2 space-x-2 items-center">
                                    <span>{t('common.upgrade')}</span>
                                </span>
                            </Button.Action>
                        </animated.div>
                    )}
                    <div className="flex justify-between px-2">
                        {isLoggedIn && !isMobile ? (
                            <div className="flex flex-row items-center space-x-3 py-0">
                                <animated.div style={{ opacity }}>
                                    <HelpMenu urlKey={currentWorkspace.urlKey} />
                                </animated.div>
                                <animated.div style={{ opacity }}>
                                    <div className="border-[1px] border-saga-gray-200 dark:border-saga-gray-700 rounded">
                                        <Button.PopOverButton onClick={openSettings}>
                                            <div className="min-w-0 font-medium text-sm">{t('sidebar.settings')}</div>
                                        </Button.PopOverButton>
                                    </div>
                                </animated.div>
                            </div>
                        ) : (
                            <div />
                        )}

                        <animated.div style={{ opacity }}>
                            <button
                                type="button"
                                onClick={onClose}
                                className="flex items-center h-full justify-center px-3 py-5 opacity-0 sidebar-hover:opacity-100 transition-opacity"
                            >
                                <CloseSidebarIcon className="[width:18px] [height:18px]" />
                            </button>
                        </animated.div>
                    </div>
                </div>
            </animated.div>

            {templateMenuOpen && (
                <SidebarTemplatePopover attachToRef={templateMenuButtonRef} onClose={closeTemplateMenu} />
            )}

            <Modal.Medium isOpen={showUpgradePlanModalSimple}>
                <UpgradePlanBannerBig
                    title={t('sidebar.upgrade_plan_modal.title')}
                    description={t('sidebar.upgrade_plan_modal.description')}
                    onClose={() => setShowUpgradePlanModalSimple(false)}
                    trackingSource="sidebar"
                />
            </Modal.Medium>

            <Modal.Jumbo isOpen={showUpgradePlanModal}>
                <NewWorkspaceModal onClose={() => setShowUpgradePlanModal(false)} action="create" />
            </Modal.Jumbo>
        </animated.div>
    );
}

function NewWorkspaceIcon() {
    return (
        <div className="bg-saga-bg-gray-light dark:bg-zinc-700 border border-saga-gray-200 dark:border-zinc-700 rounded-5 h-5 w-5 flex items-center justify-center">
            <Plus size={14} />
        </div>
    );
}

function WorkspaceItem({
    workspace,
    isCurrent,
    isDragging,
    isAnyDragging,
    dragRef,
}: {
    workspace: api.SpaceFragmentFragment;
    isCurrent: boolean;
    isDragging?: boolean;
    isAnyDragging?: boolean;
    dragRef?: React.RefObject<HTMLAnchorElement>;
}) {
    return (
        <Link
            ref={dragRef}
            id={workspace.id}
            to={`/s/${workspace.urlKey}`}
            onClick={() => track('switch-workspace', { source: 'sidebar-context-menu' })}
            className={classNames('relative w-full group p-3.5 focus:outline-none flex', {
                'opacity-20': isDragging,
                'focus:bg-saga-gray-200 dark:focus:bg-zinc-700 hover:bg-saga-gray-200/40 dark:hover:bg-zinc-700/40':
                    !isAnyDragging,
            })}
            data-focus={isCurrent}
        >
            {isCurrent && <span className="absolute left-0 h-6 w-1 bg-saga-green/70 dark:bg-saga-green/60 rounded-r" />}
            <span className="space-x-2 flex items-center flex-auto truncate min-w-0">
                <WorkspaceAvatar workspace={workspace} />
                <span className="font-medium text-sm truncate min-w-0">{workspace.title}</span>
            </span>

            {!isAnyDragging && dragRef && (
                <span className="group-hover:opacity-100 opacity-0 transition-opacity flex items-center">
                    <GripIcon />
                </span>
            )}
        </Link>
    );
}

function DraggableWorkspaceItem({
    workspace,
    isCurrent,
    index,
    moveWorkspaceItem,
    onDrop,
}: {
    workspace: api.SpaceFragmentFragment;
    isCurrent: boolean;
    index: number;
    moveWorkspaceItem: (dragIndex: number, hoverIndex: number) => void;
    onDrop(item: DragItem): void;
}) {
    const { isDragging, isAnyDragging, ref } = useWorkspaceDnd({
        id: workspace.id,
        index,
        onDrop,
        onMove: moveWorkspaceItem,
    });

    return (
        <Flipped flipId={workspace.id}>
            <WorkspaceItem
                workspace={workspace}
                isCurrent={isCurrent}
                dragRef={ref}
                isDragging={isDragging}
                isAnyDragging={isAnyDragging}
            />
        </Flipped>
    );
}

function SidebarContextMenu({
    onClose,
    isMobile,
    showBillingModal,
}: {
    onClose(): void;
    isMobile: boolean;
    showBillingModal(): void;
}) {
    const { t } = useTranslation();
    const currentWorkspace = useCurrentWorkspace();
    const { workspaces } = useWorkspaces();
    const logout = useLogout();
    const setSettings = useSetSettings();
    const { member } = useSpaceAccess();
    const { isDesktop } = useDesktopContext();
    const { permissions } = useUserContext();

    const isExternalPublicSpace = currentWorkspace?.isPublic && member == null;

    const [updateUserSettings] = api.useUpdateUserSettingsMutation({ refetchQueries: [api.UserDataDocument] });

    const [sortedWorkspaces, setSortedWorkspaces] = useState(workspaces);
    // this is a hack for being able to test drag and drop in playwright properly,
    // because the drop event is immediately sent after the drag event without waiting for react rerender
    // therefore we cannot just rely on the state in the onDrop handler
    const sortedUrlKeysRef = useRef(sortedWorkspaces.map((s) => s.urlKey));
    sortedUrlKeysRef.current = sortedWorkspaces.map((s) => s.urlKey);

    useEffect(() => {
        setSortedWorkspaces(workspaces);
    }, [workspaces]);

    const moveWorkspaceItem = useCallback((dragIndex: number, hoverIndex: number) => {
        setSortedWorkspaces((workspaces) => {
            const dragWorkspace = workspaces[dragIndex];
            const copy = workspaces.slice();
            copy.splice(dragIndex, 1);
            copy.splice(hoverIndex, 0, dragWorkspace);
            sortedUrlKeysRef.current = copy.map((s) => s.urlKey);
            return copy;
        });
    }, []);

    const onDrop = useCallback(
        (item) => {
            const sortedUrlKeys = sortedUrlKeysRef.current;

            updateUserSettings({ variables: { input: { sortedSpaces: sortedUrlKeys } } });
            const element = document.getElementById(item.id);
            if (element instanceof HTMLElement) {
                element.focus();
            }
        },
        [updateUserSettings],
    );

    const onLogout = async () => {
        await logout();

        if (isDesktop) {
            window.location.href = '/login';
        } else {
            window.location.href = '/?r=0';
        }
    };

    function openSettings() {
        !!member ? setSettings('space') : setSettings('members');
        track('open-settings');
    }

    function newWorkspace(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
        if (isFeatureEnabled(FeatureFlag.allowBilling) && !permissions.canCreateFreeSpace) {
            e.preventDefault();
            e.stopPropagation();
            showBillingModal();
        }

        track('new-workspace');
    }

    const handleCloseSidebarContextMenu = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        onClose();
        newWorkspace(e);
    };

    return (
        <div className="w-full">
            {isExternalPublicSpace && (
                <>
                    <WorkspaceItem workspace={currentWorkspace} isCurrent />
                    <hr className="border-saga-gray-200 dark:border-saga-gray-800" />
                    <div className="text-saga-gray-500 px-3.5 pt-1 text-sm">Your Workspaces</div>
                </>
            )}

            <Flipper spring="stiff" flipKey={sortedWorkspaces.map(({ id }) => id).join('')}>
                {sortedWorkspaces.map((workspace, index) => {
                    const isCurrent = workspace.id === currentWorkspace.id;
                    return (
                        <DraggableWorkspaceItem
                            key={workspace.id}
                            moveWorkspaceItem={moveWorkspaceItem}
                            onDrop={onDrop}
                            index={index}
                            workspace={workspace}
                            isCurrent={isCurrent}
                        />
                    );
                })}
            </Flipper>

            <Link
                to="/new-workspace"
                onClick={handleCloseSidebarContextMenu}
                className="relative w-full p-3.5 focus:outline-none hover:bg-saga-gray-200/40 dark:hover:bg-zinc-700/40 focus:bg-saga-gray-200 dark:focus:bg-zinc-700 flex"
            >
                <span className="space-x-2 flex items-center">
                    <NewWorkspaceIcon />
                    <span className="font-medium text-sm">{t('sidebar.new_workspace')}</span>
                </span>
            </Link>
            {!isMobile && (
                <>
                    <hr className="border-saga-gray-200 dark:border-saga-gray-800" />
                    <button
                        onClick={() => {
                            onClose();
                            openSettings();
                        }}
                        type="button"
                        aria-label="Settings"
                        className="relative w-full p-3.5 focus:outline-none hover:bg-saga-gray-200/40 dark:hover:bg-zinc-700/40 focus:bg-saga-gray-200 dark:focus:bg-zinc-700 flex"
                    >
                        <span className="space-x-2 flex items-center">
                            <span className="h-5 w-5 flex items-center justify-center">
                                <Sliders size={16} />
                            </span>
                            <span className="font-medium text-sm" data-testid="sidebar-context-menu-settings">
                                {t('sidebar.settings')}
                            </span>
                        </span>
                    </button>
                </>
            )}
            <hr className="border-saga-gray-200 dark:border-saga-gray-800" />
            <button
                onClick={onLogout}
                type="button"
                className="relative w-full p-3.5 focus:outline-none hover:bg-saga-gray-200/40 dark:hover:bg-zinc-700/40 focus:bg-saga-gray-200 dark:focus:bg-zinc-700 flex"
            >
                <span className="space-x-2 flex items-center">
                    <span className="h-5 w-5 flex items-center justify-center">
                        <LogOut size={16} />
                    </span>
                    <span className="font-medium text-sm">{t('sidebar.logout')}</span>
                </span>
            </button>
        </div>
    );
}

export default function Sidebar({
    isOpen,
    isMobile,
    hasUnreadNotifications,
    onClose,
    onOpen,
    onOpenNotifications,
    location,
}: {
    isOpen: boolean;
    isMobile: boolean;
    hasUnreadNotifications: boolean;
    onClose(): void;
    onOpen(): void;
    onOpenNotifications(): void;
    location?: SagaLocation.SagaLocation;
}) {
    const { t } = useTranslation();
    const [{ sidebarWidth }, setInterfaceSettings] = useInterfaceSettings();
    const currentWorkspace = useCurrentWorkspace();
    const sidebarAnimation = useSpring({ progress: isOpen ? 1 : 0 });
    const history = useHistory();
    const { workspaces } = useWorkspaces();
    const transitions = useTransition(isOpen, {
        from: { progress: isOpen ? 1 : 0 },
        enter: { progress: 1 },
        leave: { progress: 0 },
        default: isOpen,
        config: config.stiff,
    });
    const { useMacOsLayout } = useDesktopContext();

    const openWidth = sidebarWidth ?? 250;
    const closedWidth = 50;

    const bindHandle = useGestureDrag(
        (state) => {
            if (isOpen) {
                state.event.preventDefault();
                const nextSidebarWidth = Math.max(Math.min(500, openWidth + state.delta[0]), 200);
                setInterfaceSettings({ sidebarWidth: nextSidebarWidth });
            }
        },
        { filterTaps: true, rubberband: false },
    );

    const WORKSPACE_COMMANDS = Array.from({ length: 9 }, (_, i) => `mod+${i + 1}`);

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (isHotkey(WORKSPACE_COMMANDS, event)) {
                event.preventDefault();
                const workspaceIndex = Number(event.key);
                history.push(`/s/${workspaces[workspaceIndex - 1].urlKey}`);
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        return () => window.removeEventListener('keydown', handleKeyDown);
    }, [WORKSPACE_COMMANDS, history, workspaces]);

    return (
        <>
            <animated.div
                data-testid="sidebar"
                style={
                    isMobile
                        ? {
                              minHeight: sidebarAnimation.progress.to({ range: [0.0, 1.0], output: ['0%', '100%'] }),
                              minWidth: sidebarAnimation.progress.to({ range: [0.0, 1.0], output: ['0%', '80%'] }),
                              maxWidth: sidebarAnimation.progress.to({ range: [0.0, 1.0], output: ['0%', '80%'] }),
                          }
                        : {
                              minWidth: sidebarAnimation.progress.to({
                                  range: [0.0, 1.0],
                                  output: [closedWidth, openWidth],
                              }),
                              maxWidth: sidebarAnimation.progress.to({
                                  range: [0.0, 1.0],
                                  output: [closedWidth, openWidth],
                              }),
                          }
                }
                className={classNames('sidebar flex z-40 w-full', {
                    'border-r border-transparent hover:border-saga-gray-200 hover:dark:border-saga-gray-800':
                        !isOpen && !isMobile,
                    'border-r border-saga-gray-200 dark:border-saga-gray-800': isOpen && !isMobile,
                    'absolute left-0 top-0 shadow': isMobile,
                    'h-full relative': !isMobile,
                    'border-none': useMacOsLayout && !isOpen,
                })}
            >
                <div
                    onDoubleClick={() => isOpen && setInterfaceSettings({ sidebarWidth: null })}
                    {...bindHandle()}
                    className="absolute -right-1 bottom-0 top-0 w-3 cursor-ew-resize z-100 group"
                >
                    {isOpen && (
                        <div className="absolute top-0 bottom-0 w-0.5 right-1 group-hover:bg-saga-blue-light transition-colors"></div>
                    )}
                </div>

                {transitions(({ progress }, item) => {
                    if (item) {
                        return (
                            <OpenSidebar
                                location={location}
                                progress={progress}
                                closedWidth={closedWidth}
                                openWidth={openWidth}
                                hasUnreadNotifications={hasUnreadNotifications}
                                isMobile={isMobile}
                                isOpen={isOpen}
                                onClose={onClose}
                                onOpenNotifications={onOpenNotifications}
                            />
                        );
                    }

                    const opacity = progress.to({ range: [1.0, 0.0], output: [1, 0] });
                    const maxWidth = progress.to({ range: [1.0, 0.0], output: [openWidth, closedWidth] });

                    return (
                        <animated.button
                            style={{ maxWidth }}
                            onClick={onOpen}
                            type="button"
                            className="absolute inset-0 focus:outline-none flex-1 flex flex-col justify-between transition-colors"
                        >
                            <span className="sr-only">{t('common.open_sidebar')}</span>
                            <div
                                className={classNames('not-sr-only px-3 py-3.5 relative dark:bg-saga-gray-1000', {
                                    invisible: useMacOsLayout,
                                })}
                            >
                                <WorkspaceAvatar
                                    showNotificationsIndicator={!isOpen && hasUnreadNotifications}
                                    workspace={currentWorkspace}
                                />
                            </div>

                            {!isMobile && (
                                <animated.div style={{ opacity }}>
                                    <div className="not-sr-only flex justify-center [width:50px] p-4 opacity-0 sidebar-hover:opacity-100 transition-opacity">
                                        <OpenSidebarIcon className="[width:18px] [height:18px]" />
                                    </div>
                                </animated.div>
                            )}
                        </animated.button>
                    );
                })}
            </animated.div>
            {isMobile && (
                <animated.div
                    onClick={onClose}
                    style={{ opacity: sidebarAnimation.progress.to({ range: [0.0, 1.0], output: [0, 1] }) }}
                    className={classNames('absolute inset-0 z-30 bg-black/60', {
                        'pointer-events-none': !isOpen,
                    })}
                >
                    {isOpen && (
                        <div className="absolute right-0 top-0 p-3.5">
                            <button className="text-white focus:outline-none" onClick={onClose}>
                                <X size={20} />
                            </button>
                        </div>
                    )}
                </animated.div>
            )}
        </>
    );
}
