import { NodeEntry, Editor, Node } from 'slate';
import { SagaElement } from '../block-types';
import { SagaText } from '..';
import { evaluateNormalizers, Normalizer } from './Normalizers';

export function normalizeNode(editor: Editor, normalizers: Normalizer[]): (n: NodeEntry<Node>) => void {
    const defaultNormalizeNode = editor.normalizeNode;

    return (([node, path]: NodeEntry<SagaElement | SagaText>) => {
        if (evaluateNormalizers(normalizers, { editor, path, node, normalizers }) !== 'stop') {
            defaultNormalizeNode([node, path]);
        }
    }) as (n: NodeEntry<Node>) => void;
}

export default normalizeNode;
