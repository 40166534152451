import { DEFAULT_FONT, EDITOR_FONTS, INTERFACE_FONTS } from '@/../../shared/src';
import { track } from '@/analytics';
import { useUserContext } from '@/components/UserContext';
import { WithExplicitFontType } from '@/components/WithExplicitFontType';
import useInterfaceSettings from '@/hooks/useInterfaceSettings';
import * as Select from '@radix-ui/react-select';
import * as api from '@saga/api';
import classNames from 'classnames';
import React from 'react';
import { ChevronDown, Monitor, Moon, Sun } from 'react-feather';
import { useTranslation } from 'react-i18next';
import SettingsPane from './SettingsPane';
import { SettingsToggle } from './SettingsToggle';

function ViewModeSelectItem({
    value,
    children,
    selected,
}: {
    value: string;
    children: React.ReactNode;
    selected: boolean;
}) {
    return (
        <Select.Item
            value={value}
            className={classNames('focus:outline-none font-semibold cursor-pointer px-2 py-2', {
                'bg-saga-gray-200 dark:text-zinc-800': selected,
                'hover:bg-saga-gray-200 dark:hover:bg-zinc-600': !selected,
            })}
        >
            <Select.ItemText>{children}</Select.ItemText>
        </Select.Item>
    );
}

function UIModeSelect({ value, onChange }: { value: string; onChange(value: string): void }) {
    const { t } = useTranslation();
    return (
        <Select.Root value={value} onValueChange={onChange}>
            <Select.Trigger className="flex flex-row space-x-1 items-center focus:outline-none cursor-pointer px-2 h-8 text-xs border font-semibold rounded hover:bg-saga-gray-200 dark:hover:bg-zinc-600 border-saga-gray-200 dark:border-zinc-600 ">
                <Select.Value />
                <Select.Icon>
                    <ChevronDown size={18} />
                </Select.Icon>
            </Select.Trigger>

            <Select.Content className="bg-white dark:bg-zinc-700 border border-saga-gray-200 dark:border-zinc-600 rounded shadow-popoverSmall  text-xs">
                <Select.ScrollUpButton />
                <Select.Viewport className="rounded">
                    <ViewModeSelectItem value="auto" selected={value === 'auto'}>
                        <span className="flex items-center space-x-2">
                            <Monitor size={18} />
                            <span>{t('settings.appearance.mode_auto')}</span>
                        </span>
                    </ViewModeSelectItem>
                    <ViewModeSelectItem value="light" selected={value === 'light'}>
                        <span className="flex items-center space-x-2">
                            <Sun size={18} />
                            <span>{t('settings.appearance.mode_light')}</span>
                        </span>
                    </ViewModeSelectItem>
                    <ViewModeSelectItem value="dark" selected={value === 'dark'}>
                        <span className="flex items-center space-x-2">
                            <Moon size={18} />
                            <span>{t('settings.appearance.mode_dark')}</span>
                        </span>
                    </ViewModeSelectItem>
                </Select.Viewport>
                <Select.ScrollDownButton />
            </Select.Content>
        </Select.Root>
    );
}

function UIModeSelectFont({
    value,
    onChange,
    fonts,
}: {
    value: string | undefined;
    onChange(value: string): void;
    fonts: string[];
}) {
    const { t } = useTranslation();
    const selectedValue = value && fonts.includes(value) ? value : DEFAULT_FONT;
    return (
        <Select.Root value={selectedValue} onValueChange={onChange}>
            <Select.Trigger className="flex flex-row space-x-1 items-center focus:outline-none cursor-pointer px-2 h-8 text-xs border font-semibold rounded hover:bg-saga-gray-200 dark:hover:bg-zinc-600 border-saga-gray-200 dark:border-zinc-600 ">
                <Select.Value />
                <Select.Icon>
                    <ChevronDown size={18} />
                </Select.Icon>
            </Select.Trigger>

            <Select.Content className="bg-white dark:bg-zinc-700 border border-saga-gray-200 dark:border-zinc-600 rounded shadow-popoverSmall  text-xs">
                <Select.ScrollUpButton />
                <Select.Viewport className="rounded">
                    <ViewModeSelectItem
                        key={DEFAULT_FONT}
                        value={DEFAULT_FONT}
                        selected={selectedValue === DEFAULT_FONT}
                    >
                        <span className="flex items-center space-x-2">
                            <span>{t('settings.appearance.font_default')}</span>
                        </span>
                    </ViewModeSelectItem>
                    {fonts.map((font) => (
                        <ViewModeSelectItem key={font} value={font} selected={selectedValue === font}>
                            <span className="flex items-center space-x-2">
                                <span>{font}</span>
                            </span>
                        </ViewModeSelectItem>
                    ))}
                </Select.Viewport>
                <Select.ScrollDownButton />
            </Select.Content>
        </Select.Root>
    );
}

export function AppearanceSettings() {
    const { t } = useTranslation();
    const { userSettings } = useUserContext();
    const [updateUserSettings] = api.useUpdateUserSettingsMutation({ refetchQueries: [api.UserDataDocument] });

    const [{ darkMode, autoColorScheme }, setInterfaceSettings] = useInterfaceSettings();

    const toUIMode = (s: string) => {
        switch (s) {
            case 'dark':
                return 'dark';
            case 'light':
                return 'light';
            default:
                return 'auto';
        }
    };

    const getCurrentColorScheme = () => {
        if (autoColorScheme) {
            return 'auto';
        } else if (darkMode) {
            return 'dark';
        } else {
            return 'light';
        }
    };

    const onSelectChange = (s: string) => {
        const uiMode = toUIMode(s);

        if (uiMode === 'auto') {
            setInterfaceSettings({ autoColorScheme: true });
        } else {
            setInterfaceSettings({ darkMode: uiMode === 'dark', autoColorScheme: false });
        }
    };

    const onEditorFontChange = (f: string) => {
        updateUserSettings({ variables: { input: { editorFont: f } } });
        track('change-editor-font');
    };

    const onInterfaceFontChange = (f: string) => {
        updateUserSettings({ variables: { input: { interfaceFont: f } } });
        track('change-interface-font');
    };

    return (
        <SettingsPane>
            <div>
                <SettingsPane.Title>{t('settings.appearance.title')}</SettingsPane.Title>
                <SettingsPane.Small>{t('settings.appearance.sub_title')}</SettingsPane.Small>
            </div>
            <SettingsPane.Content>
                <SettingsPane.SectionFlex>
                    <h2>{t('settings.appearance.theme')}</h2>
                    <UIModeSelect value={getCurrentColorScheme()} onChange={onSelectChange} />
                </SettingsPane.SectionFlex>
                <SettingsPane.SectionFlex>
                    <h2>{t('settings.appearance.interface_font')}</h2>
                    <UIModeSelectFont
                        value={userSettings?.interfaceFont ?? DEFAULT_FONT}
                        onChange={onInterfaceFontChange}
                        fonts={INTERFACE_FONTS}
                    />
                </SettingsPane.SectionFlex>
                <SettingsPane.SectionFlex>
                    <div className="flex w-[525px] justify-between">
                        <h2>{t('settings.appearance.text_font')}</h2>
                        <WithExplicitFontType type="editor">
                            <h2>{t('settings.appearance.example_text')}</h2>
                        </WithExplicitFontType>
                    </div>
                    <UIModeSelectFont
                        value={userSettings?.editorFont ?? DEFAULT_FONT}
                        onChange={onEditorFontChange}
                        fonts={EDITOR_FONTS}
                    />
                </SettingsPane.SectionFlex>
                <SettingsPane.SectionFlex>
                    <SettingsToggle
                        title={t('settings.appearance.show_icons')}
                        checked={Boolean(userSettings?.showInlinePageLinkIcons)}
                        onChange={(showInlinePageLinkIcons) => {
                            if (showInlinePageLinkIcons) {
                                track('enable-spell-checking');
                            } else {
                                track('disable-spell-checking');
                            }
                            updateUserSettings({ variables: { input: { showInlinePageLinkIcons } } });
                        }}
                    />
                </SettingsPane.SectionFlex>
            </SettingsPane.Content>
        </SettingsPane>
    );
}
