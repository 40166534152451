import React from 'react';
import classNames from 'classnames';
import { useDrag } from '@use-gesture/react';
import { useSpring, animated } from '@react-spring/web';
import Button from '@/components/styled/Button';
import Tooltip from '@/components/popover/Tooltip';
import { Repeat } from 'react-feather';
import { track } from '@/analytics';
import AIToggleChatButton from '@/components/aichat/AIToggleChatButton';
import { SagaLocation } from '@saga/shared/src/SagaLocation';

export type SideBySideSize = 50 | 33 | 66 | 100;

function invertSize(size: SideBySideSize): SideBySideSize {
    if (size === 33) return 66;
    if (size === 66) return 33;

    return size;
}

function SizeDisplay({ show, size }: { show: boolean; size: SideBySideSize }) {
    return (
        <div
            className={classNames(
                'text-5xl transition-colors delay-150 absolute inset-0 flex justify-center items-center text-saga-gray-200 z-20 pointer-events-none',
                {
                    'opacity-100': show,
                    'opacity-0': !show,
                },
            )}
        >
            {size === 33 && '1/3'}
            {size === 50 && '1/2'}
            {size === 66 && '2/3'}
        </div>
    );
}

function ResizeHandle({ isActive, ...rest }: { isActive: boolean }) {
    return (
        <div
            {...rest}
            className={classNames(
                'relative z-30 group flex cursor-ew-resize items-center justify-center transition-colors ease-out duration-500 touch-none select-none',
                {
                    'bg-saga-gray-200 dark:bg-zinc-900': isActive,
                },
            )}
        >
            <div className="absolute z-10 w-1/2 transition-opacity top-0 bottom-0 left-0 border-r border-dashed dark:border-zinc-600 touch-none select-none" />
            <div className="z-50 touch-none w-1.5 group-hover:bg-saga-blue-light h-20 bg-saga-gray-200 dark:bg-zinc-500 rounded group-hover:shadow group-hover:shadow-saga-blue-light/50 transition-all select-none"></div>
        </div>
    );
}

function SwitchPanesButton({ onClick }: { onClick: () => void }) {
    return (
        <Tooltip content="Switch pages" placement="top">
            <button onClick={onClick}>
                <Button.Circle>
                    <>
                        <span className="sr-only">Switch panes</span>
                        <Repeat className={'stroke-currentColor flex-none'} size={14} />
                    </>
                </Button.Circle>
            </button>
        </Tooltip>
    );
}

function FloatingHelpMenu() {
    return (
        <div className="sm:absolute sm:flex hidden z-100 bottom-0 right-0 mb-[23px] mr-4 flex-col space-y-2">
            <AIToggleChatButton />
        </div>
    );
}

export default function SideBySidePanes({
    panes,
    switchPanes,
}: {
    panes: {
        key: string;
        location: SagaLocation;
        render: (size: SideBySideSize) => React.ReactNode;
        padding: boolean;
    }[];
    switchPanes?: () => void;
}) {
    const container = React.useRef<HTMLDivElement>(null);
    const leftRef = React.useRef<HTMLDivElement>(null);
    const cancelRef = React.useRef(() => {});
    const [size, setSize] = React.useState<SideBySideSize>(50);
    const [isActive, setIsActive] = React.useState(false);
    const [styles, api] = useSpring(() => ({
        x: '0%',
        transform: 'scale(1)',
        config: { friction: 0, bounce: 0, tension: 140 },
    }));

    React.useEffect(() => {
        if (panes.length === 1) {
            setSize(100);
        }
    }, [panes.length]);

    const bindHandle = useDrag(
        (state) => {
            if (state.active) {
                api.start({ transform: 'scale(0.98)' });
                setIsActive(true);
            }

            state.event.preventDefault();

            if (leftRef.current && container.current) {
                cancelRef.current = state.cancel;

                const leftWidth = leftRef.current.getBoundingClientRect().width;
                const containerWidth = container.current.getBoundingClientRect().width;
                const nextPercent = ((leftWidth + state.delta[0] - containerWidth / 2) / containerWidth) * 100;
                api.start({ x: `${nextPercent}%`, immediate: true });

                if (nextPercent < -8.5) {
                    setSize(33);
                } else if (nextPercent < 8.5) {
                    setSize(50);
                } else {
                    setSize(66);
                }

                if (!state.active) {
                    setIsActive(false);
                    if (nextPercent < -8.5) {
                        api.start({ x: `-17%`, transform: 'scale(1)' });
                    } else if (nextPercent < 8.5) {
                        api.start({ x: `0%`, transform: 'scale(1)' });
                    } else {
                        api.start({ x: `17%`, transform: 'scale(1)' });
                    }
                }
            }
        },
        { filterTaps: true, rubberband: false },
    );

    return (
        <div
            ref={container}
            onPointerUp={() => {
                cancelRef.current();
            }}
            className="flex justify-start w-full h-full min-w-0 relative"
        >
            {panes.map(({ render, key, padding }, index) => {
                const isFirstPaneAndNotSingle = index === 0 && panes.length > 1;
                const correctedSize = index === 0 ? size : invertSize(size);

                return (
                    <React.Fragment key={key}>
                        {index > 0 && (
                            <div className="relative flex justify-center">
                                <ResizeHandle {...bindHandle()} isActive={isActive} />
                                {switchPanes && (
                                    <div className="absolute bottom-6 z-40">
                                        <SwitchPanesButton
                                            onClick={() => {
                                                track('switch_panes', { location: 'switch-panes-button' });
                                                switchPanes();
                                            }}
                                        />
                                    </div>
                                )}
                            </div>
                        )}
                        <animated.div
                            ref={isFirstPaneAndNotSingle ? leftRef : undefined}
                            style={{
                                width: isFirstPaneAndNotSingle ? styles.x.to((v) => `calc(50% + (${v}))`) : undefined,
                                minWidth: '33%',
                            }}
                            className={classNames('relative z-10 bg-saga-gray-200 dark:bg-zinc-900', {
                                'flex-1 flex-shrink': !isFirstPaneAndNotSingle,
                            })}
                            id={'pane'}
                            data-testid={`pane-${index}`}
                        >
                            <animated.div
                                style={{ transform: styles.transform }}
                                className={classNames('z-10 h-full bg-white dark:bg-saga-gray-1000', {
                                    'px-2': padding,
                                })}
                            >
                                {render(correctedSize)}
                            </animated.div>
                            <SizeDisplay show={isActive} size={correctedSize} />
                        </animated.div>
                    </React.Fragment>
                );
            })}
            {!panes.some((p) => p.location.type === 'sagaAI') && <FloatingHelpMenu />}
        </div>
    );
}
