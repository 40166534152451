import React from 'react';
import * as Y from 'yjs';
import { SagaLocation, memoizeDebounce } from '@saga/shared';

import { usePerformActionWithYBlocks } from '@/components/RealtimeDocumentProvider';
import { useSpace, useCurrentSearchMap } from '@/components/SpaceProvider';
import { usePagesPermissions } from '@/components/PagesPermissionsBySpaceProvider';

export const SpaceSearchEventsContext = React.createContext<{
    updateSearchIndex: (id: string, yBlocks: Y.Array<any> | null) => void;
}>({ updateSearchIndex: () => {} });

export function useUpdateSearchIndex() {
    const { updateSearchIndex } = React.useContext(SpaceSearchEventsContext);
    return React.useMemo(() => ({ updateSearchIndex }), [updateSearchIndex]);
}

export function SpaceSearchEventsProvider({ children }: { children: React.ReactNode }) {
    const { space } = useSpace();
    const searchMap = useCurrentSearchMap();
    const performActionWithBlocks = usePerformActionWithYBlocks();
    const { hasAccess } = usePagesPermissions();

    const updateSpaceSearchIndex = React.useCallback(
        (id: string, yBlocks: Y.Array<any> | null) => {
            if (yBlocks) {
                searchMap?.set(id, yBlocks.clone());
            } else {
                performActionWithBlocks(SagaLocation.pageLocationFromId(id), (yBlocks) => {
                    searchMap?.set(id, yBlocks.clone());
                });
            }
        },
        [performActionWithBlocks, searchMap],
    );

    const memoizeDebounceResolver = React.useMemo(() => (id: string) => id, []);
    const debounceUpdateSpaceSearchIndex = memoizeDebounce(updateSpaceSearchIndex, 5000, {}, memoizeDebounceResolver);

    const handlePageEvents = React.useCallback(
        (events: Y.YEvent<any>[]) => {
            events.forEach((event) => {
                if (!event.transaction.local && event.keys.has('updatedAt')) {
                    const yMap: Y.Map<any> = event.target;
                    const pageId: string = yMap.get('id');

                    if (hasAccess(pageId)) {
                        debounceUpdateSpaceSearchIndex(yMap.get('id'), null);
                    }

                    return;
                }
            });
        },
        [debounceUpdateSpaceSearchIndex, hasAccess],
    );

    React.useEffect(() => {
        const yspace = space.map;
        const ypages = yspace.get('pages') as Y.Array<Y.Map<unknown>>;
        const ytasks = yspace.get('tasks') as Y.Array<Y.Map<unknown>>;

        ypages?.observeDeep(handlePageEvents);
        ytasks?.observeDeep(handlePageEvents);

        return () => {
            ypages?.unobserveDeep(handlePageEvents);
            ytasks?.unobserveDeep(handlePageEvents);
        };
    }, [space, handlePageEvents]);

    const deps = React.useMemo(() => {
        return {
            updateSearchIndex: updateSpaceSearchIndex,
        };
    }, [updateSpaceSearchIndex]);

    return <SpaceSearchEventsContext.Provider value={deps}>{children}</SpaceSearchEventsContext.Provider>;
}
