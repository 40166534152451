import React, { useCallback, useEffect } from 'react';
import { ReactEditor, useSlateStatic } from 'slate-react';
import { Transforms } from 'slate';
import { SagaEditor, isAISuggestedText, BlockBuilder, SagaElement } from '@saga/shared';
import { useUserContext } from '@/components/UserContext';
import { Editor } from 'slate';
import AIStaticEditorContent from '../ai/AIStaticEditorContent';
import AIAnswerContent from '@/components/editor/ai/AIAnswerContent';
import { useMountedEditors } from '../../../../../shared/src/Editor';
interface CustomKeyboardEvent extends KeyboardEvent {
    customProperty?: boolean;
}

export function createAISuggestedTextBlockPlugin() {
    return SagaEditor.Plugins.createBlockPlugin({
        match: isAISuggestedText,
        normalizers: [SagaEditor.Normalizers.titleNormalizer],
        stringify(element) {
            return element.messages.filter((m) => m.role === 'assistant')[0]?.content ?? '';
        },
        Component({ element, children, path }) {
            const editor = useSlateStatic();
            const { user } = useUserContext();
            const isMac = navigator.userAgent.match(/Mac/i);
            const isWindows = navigator.userAgent.match(/Win/i);

            const mountedEditors = useMountedEditors();
            const otherMountedEditors = React.useMemo(
                () => [...mountedEditors].filter((mountedEditors) => mountedEditors !== editor),
                [mountedEditors, editor],
            );
            const anotherEditorFocused = React.useMemo(
                () => otherMountedEditors.some((mountedEditor) => ReactEditor.isFocused(mountedEditor)),
                [otherMountedEditors],
            );

            const prompt = React.useMemo(
                () => ({
                    prompt: element.prompt,
                    textContext: element.textContext,
                    references: element.references ?? [],
                }),
                [element],
            );

            const keep = useCallback(
                (blocks: SagaElement[]) => {
                    if (!blocks.length) {
                        return;
                    }

                    Editor.withoutNormalizing(editor, () => {
                        Transforms.removeNodes(editor, { at: path });
                        Transforms.insertNodes(editor, blocks, { at: path });
                        Transforms.select(editor, Editor.end(editor, [path[0] + blocks.length - 1]));
                    });

                    ReactEditor.focus(editor);
                },
                [editor, path],
            );

            const discard = useCallback(() => {
                Transforms.delete(editor, { at: path });
                Transforms.insertNodes(editor, BlockBuilder.paragraph(''), { at: path });
                Transforms.select(editor, path);

                ReactEditor.focus(editor);
            }, [editor, path]);

            const onEscapeKeyDown = useCallback(() => {
                Transforms.delete(editor, { at: path });
                Transforms.insertNodes(editor, BlockBuilder.paragraph(''), { at: path });
                Transforms.select(editor, path);

                ReactEditor.focus(editor);

                //this custome event simulates pressing comd + J to open AI
                const event: CustomKeyboardEvent = new KeyboardEvent('keydown', {
                    key: 'j',
                    keyCode: 74,
                    metaKey: isMac ? true : false,
                    ctrlKey: isWindows ? true : false,
                });

                document.dispatchEvent(event);
            }, [editor, path, isMac, isWindows]);

            const onStatusChange = useCallback(
                (_, messages) => {
                    if (messages.length === 0) {
                        return;
                    }

                    Transforms.setNodes(editor, { messages });
                },
                [editor],
            );

            /*  Do not create multiple AIAnswerContent components for each tab for the same user.
                Render it for the one who created the node if still opened, if not, pick another tab
            */
            const userEditorInstances = [...(editor.awareness?.states || [])]
                .filter(([, v]) => v.user?.userId === user?.id)
                .map(([k]) => k);
            const activeEditorInstance =
                element.editorId && userEditorInstances.includes(element.editorId) ? element.editorId : null;

            useEffect(() => {
                if (!activeEditorInstance && userEditorInstances.length && element.creatorId === user?.id) {
                    Transforms.setNodes(editor, { editorId: Math.min(...userEditorInstances) }, { at: path });
                }
            }, [activeEditorInstance, editor, element, userEditorInstances, user, path]);

            return (
                <div>
                    <div contentEditable={false}>
                        {/* This guarantees only one AIAnswerContent per AISuggestion node */}
                        {element.creatorId !== user?.id || activeEditorInstance !== editor.awareness?.clientID ? (
                            <div className="w-full mt-1 rounded text-saga-text dark:text-white saga-text bg-saga-new-purple-light/20">
                                <AIStaticEditorContent
                                    content={element.messages.filter((m) => m.role === 'assistant')[0]?.content}
                                />
                            </div>
                        ) : (
                            <AIAnswerContent
                                autofocus={editor.selection?.anchor.path[0] === path[0] && !anotherEditorFocused}
                                history={element.messages}
                                prompt={prompt}
                                onKeep={keep}
                                onDiscard={discard}
                                onStatusChange={onStatusChange}
                                onEscapeKeyDown={onEscapeKeyDown}
                            />
                        )}
                    </div>
                    {children}
                </div>
            );
        },
    });
}

export const aiSuggestedTextBlockPlugin = createAISuggestedTextBlockPlugin();
