import React from 'react';
import * as api from '@saga/api';
import SettingsPane from './SettingsPane';
import { useSubscriptions, useCurrentWorkspace } from '../WorkspaceContext';
import { Loader } from 'react-feather';
import { DateTime } from 'luxon';
import Button from '../styled/Button';
import { useSetSettings } from './SettingsProvider';
import { Trans, useTranslation } from 'react-i18next';
import _ from 'lodash';

export function BillingSettings() {
    const { currentSubscription } = useSubscriptions();
    const currentWorkspace = useCurrentWorkspace();
    const [getBillingPortalUrl, { loading }] = api.useBillingPortalLazyQuery();
    const { t } = useTranslation();

    const setSettings = useSetSettings();
    const devideBy = currentSubscription
        ? currentSubscription.price.interval === api.SubscriptionPriceInterval.Month
            ? 1
            : 12
        : 0;

    return (
        <SettingsPane>
            <div>
                <SettingsPane.Title>{t('settings.billing.title')}</SettingsPane.Title>
                <SettingsPane.Small>{t('settings.billing.sub_title')}</SettingsPane.Small>
            </div>
            <SettingsPane.Content>
                <div data-testid="" className="max-w-xl">
                    <div className="border rounded border-saga-gray-200 dark:border-saga-gray-dark p-7 bg-saga-gray-100 dark:bg-saga-gray-800 my-11">
                        <div className="text-saga-text dark:text-zinc-200 leading-4 mb-5">
                            {currentSubscription
                                ? t(
                                      `settings.billing.${
                                          currentSubscription.price.interval === api.SubscriptionPriceInterval.Month
                                              ? 'standard_monthly_plan'
                                              : 'standard_annual_plan'
                                      }`,
                                  )
                                : t('settings.billing.free_plan')}
                        </div>
                        <div className="text-saga-text mb-6 dark:text-zinc-200">
                            {currentSubscription ? (
                                <>
                                    <span className="text-4xl leading-9 font-bold mr-1">
                                        ${currentSubscription.price.amount / 100 / devideBy}
                                    </span>
                                    {t('settings.billing.per_month')}
                                </>
                            ) : (
                                <span className="text-4xl leading-9 font-bold">Free</span>
                            )}
                        </div>
                        <Button
                            onClick={() => setSettings('plans')}
                            variant={currentSubscription ? 'secondary' : 'primary'}
                        >
                            {t(currentSubscription ? 'common.see_plans' : 'common.upgrade_plan')}
                        </Button>

                        {currentSubscription && <UpcomingInvoice subscriptionId={currentSubscription.subscriptionId} />}
                    </div>

                    {currentSubscription && (
                        <Button
                            onClick={() =>
                                getBillingPortalUrl({
                                    variables: {
                                        input: { sourceUrlKey: currentWorkspace.urlKey },
                                    },
                                    onCompleted: (data) => window.open(data.billingPortal, '_blank'),
                                })
                            }
                            disabled={loading}
                            className="mb-10"
                        >
                            {t('settings.billing.billing_portal_label')}
                        </Button>
                    )}

                    <div className="font-bold text-lg">{t('settings.billing.invoices.title')}</div>

                    <Invoices />
                </div>
            </SettingsPane.Content>
        </SettingsPane>
    );
}

function Invoices() {
    const { t } = useTranslation();
    const currentWorkspace = useCurrentWorkspace();
    const { data, loading } = api.useInvoicesQuery({ variables: { input: { spaceId: currentWorkspace.id } } });

    if (loading || !data) {
        return (
            <div className="flex items-center space-x-1 text-sm">
                <Loader className="animate-spin" size={16} />
                <div>{t('settings.billing.invoices.loading')}</div>
            </div>
        );
    }

    if (data.invoices.length === 0) {
        return <div className="text-saga-gray-500 mt-3">{t('settings.billing.invoices.no_invoices')}</div>;
    }

    return (
        <table className="w-full table-auto">
            <tbody>
                {data.invoices.map((invoice) => (
                    <tr key={invoice.id} className="leading-8 border-b">
                        <td>{DateTime.fromJSDate(new Date(invoice.created)).toFormat('dd MMM. yyyy')}</td>
                        <td>
                            {t(invoice.paid ? 'settings.billing.invoices.paid' : 'settings.billing.invoices.not_paid')}
                        </td>
                        <td>${invoice.total / 100}</td>
                        <td className="text-right">
                            {invoice.pdfUrl ? (
                                <a
                                    href={invoice.pdfUrl}
                                    target="_blank"
                                    rel="noreferrer noopener"
                                    className="text-saga-blue"
                                >
                                    {t('settings.billing.invoices.see_invoice')}
                                </a>
                            ) : (
                                t('settings.billing.invoices.invoice_not_ready')
                            )}
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
}

function UpcomingInvoice({ subscriptionId }: { subscriptionId: string }) {
    const { members } = useCurrentWorkspace();
    const { t } = useTranslation();
    const { data, loading } = api.useSubscriptionUpcomingQuery({
        variables: { input: { subscriptionId } },
    });

    if (loading) {
        return (
            <div className="mt-5 flex items-center space-x-1 text-sm">
                <Loader className="animate-spin" size={16} />
                <div>Calculating...</div>
            </div>
        );
    }

    if (!data?.subscriptionUpcoming) {
        return <p className="mt-5">{t('settings.billing.invoices.no_upcoming_invoices')}</p>;
    }

    return (
        <p className="mt-5">
            <Trans
                i18nKey="settings.billing.invoices.next_billing_on"
                count={members.length}
                components={{ 1: <strong /> }}
                values={{
                    amount: data.subscriptionUpcoming.total / 100,
                    date: DateTime.fromJSDate(new Date(data.subscriptionUpcoming.periodEnd)).toFormat('dd MMMM yyyy'),
                    billableMembersCount: members.length,
                }}
            />
        </p>
    );
}
