import transactInSpace from './transactInSpace';
import { SafeSpace } from '../types';
import { findYArrayIndex } from '../yjs-utils';
import * as Y from 'yjs';

export default function moveFavorites(
    space: SafeSpace,
    userId: string,
    sourceId: string,
    { targetId, position }: { targetId: string; position: 'before' | 'after' },
) {
    const userFavorites = space.map.get('userFavorites') as Y.Map<Y.Array<string>>;
    const yArrayOfFavorites = userFavorites.get(userId) ?? new Y.Array<string>();

    transactInSpace(space, () => {
        const favorites = yArrayOfFavorites.toArray();
        const set = new Set(favorites);
        const dedupedArray = Array.from(set);

        // We need to dedpulicate the array just to make sure we don't have any duplicates in the array,
        // otherwise we would get wrong results for the move operation
        // in practice, this should not happen, because it is checked before adding a pinned item
        // but it is better to deduplicate the array so that the operation works correctly
        if (dedupedArray.length !== favorites.length) {
            yArrayOfFavorites.delete(0, favorites.length);
            yArrayOfFavorites.insert(0, dedupedArray);
        }
        const fromIndex = findYArrayIndex(yArrayOfFavorites, (id) => id === sourceId);

        if (fromIndex === -1) {
            return;
        }

        yArrayOfFavorites.delete(fromIndex);

        const toIndex = findYArrayIndex(yArrayOfFavorites, (id) => id === targetId);

        if (toIndex === -1) {
            return;
        }

        const targetIndex = position === 'before' ? toIndex : toIndex + 1;
        yArrayOfFavorites.insert(targetIndex, [sourceId]);
    });
}
