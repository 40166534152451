import React, { useState, useCallback, useContext } from 'react';
import ToastContainer from '@/components/toast/ToastContainer';
import Toast from '@/components/toast/Toast';
import { v4 as uuid } from 'uuid';

type ToastContextType = {
    showToast: (render: () => React.ReactNode) => void;
};

export const ToastContext = React.createContext<ToastContextType>({
    showToast: () => {},
});

export function useToastContext() {
    return useContext(ToastContext);
}

type ToastPropsContextType = {
    onClose: () => void;
};

const SingleToastContext = React.createContext<ToastPropsContextType>({
    onClose: () => {},
});

export function useSingleToastContext() {
    return useContext(SingleToastContext);
}

type Toast = {
    id: string;
    render: () => React.ReactNode;
};

export const ToastContextProvider: React.FC = ({ children }) => {
    const [toasts, setToasts] = useState<Toast[]>([]);

    const onClose = useCallback(
        (id: string) => {
            setToasts(toasts.filter((t) => t.id !== id));
        },
        [toasts],
    );

    const showToast = useCallback(
        (render: () => React.ReactNode) => {
            const id = uuid();
            setToasts([
                ...toasts,
                {
                    id,
                    render,
                },
            ]);
            const timeout = setTimeout(() => onClose(id), 5000);
            return () => clearTimeout(timeout);
        },
        [onClose, toasts],
    );

    const isOpen = toasts.length > 0;

    return (
        <>
            <ToastContext.Provider
                value={{
                    showToast,
                }}
            >
                {children}
            </ToastContext.Provider>
            {isOpen && (
                <ToastContainer isOpen={isOpen}>
                    {toasts.map((toast, index) => (
                        <div key={`toast-${index}`} className="relative">
                            <SingleToastContext.Provider value={{ onClose: () => onClose(toast.id) }}>
                                {toast.render()}
                            </SingleToastContext.Provider>
                        </div>
                    ))}
                </ToastContainer>
            )}
        </>
    );
};
