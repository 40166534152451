import React, { useRef, useState, useEffect, useCallback } from 'react';
import { PopOver, usePopover } from '@/components/popover/PopOver';
import classNames from 'classnames';
import Button from '../styled/Button';
import useMobile from '@/hooks/useMobile';
import { useTranslation } from 'react-i18next';

type ManageAliasPopOverProps = {
    attachToRef: React.MutableRefObject<HTMLDivElement | HTMLButtonElement | null>;
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    currentTitle: string;
    currentAliases: string[];
    onCreate: (title: string) => void;
    onRemove: (alias: string) => void;
};

function ManageAliasPopOver({
    attachToRef,
    isOpen,
    setIsOpen,
    currentTitle,
    currentAliases,
    onCreate,
    onRemove,
}: ManageAliasPopOverProps) {
    const { t } = useTranslation();
    const formRef = useRef<HTMLFormElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const [search, setSearch] = useState<string>('');
    const isMobile = useMobile();
    const [popoverRef, contentRef] = usePopover({
        isOpen,
        onClose: () => setIsOpen(false),
        target: useCallback(() => {
            if (attachToRef.current) {
                return attachToRef.current.getBoundingClientRect();
            }
            return null;
        }, [attachToRef]),
        clickOutsideRefs: [attachToRef],
        position: 'below',
        align: isMobile ? 'right' : 'left',
    });

    useEffect(() => {
        if (isOpen) {
            setSearch('');
        }
    }, [isOpen]);

    const remove = (alias: string) => {
        onRemove(alias);
        inputRef.current?.focus();
        if (currentAliases.length < 2) {
            setIsOpen(false);
        }
    };

    const isSearchNew =
        search.trim().length > 0 &&
        currentAliases.filter((c) => c.toLowerCase() === search.toLowerCase()).length === 0 &&
        currentTitle.toLowerCase() !== search.toLowerCase();

    function onSubmit() {
        setSearch('');
        onCreate(search.trim());
        inputRef.current?.focus();
    }

    return (
        <PopOver
            isOpen={isOpen}
            ref={popoverRef}
            contentRef={contentRef}
            noPointer={true}
            zIndex={400}
            onOpenAnimationDone={() => {
                inputRef.current?.focus();
            }}
        >
            <form
                className="shadow-popupSmall rounded-md"
                ref={formRef}
                onSubmit={(e) => {
                    e.preventDefault();
                    if (isSearchNew) {
                        onSubmit();
                    }
                }}
            >
                <PopOver.Input
                    style={{ width: '269px' }}
                    rounded={search.trim().length === 0 && currentAliases.length === 0 ? 'full' : 'bottom'}
                    ref={inputRef}
                    type="text"
                    placeholder={t('common.new_alias_placeholder') as string}
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                />
                {(search.trim().length > 0 || currentAliases.length !== 0) && (
                    <>
                        <PopOver.Card rounded="bottom">
                            <div data-testid="manage-alias-popover">
                                {search.trim().length === 0 && currentAliases && currentAliases.length > 0 && (
                                    <div className="pt-1.5">
                                        <PopOver.SelectedOptionsContainer>
                                            {currentAliases.map((alias, i) => (
                                                <Button.RemovableOption
                                                    key={i}
                                                    label={t('common.remove_alias', { alias })}
                                                    onRemove={() => remove(alias)}
                                                >
                                                    {alias}
                                                </Button.RemovableOption>
                                            ))}
                                        </PopOver.SelectedOptionsContainer>
                                    </div>
                                )}
                                {search.trim().length > 0 && (
                                    <div className="pt-1.5">
                                        <Button.PopOverButton disabled={!isSearchNew} selected type="submit">
                                            <p className="font-medium">{search}</p>
                                            <p dangerouslySetInnerHTML={{ __html: t('common.create_alias') }} />
                                        </Button.PopOverButton>
                                    </div>
                                )}
                            </div>
                        </PopOver.Card>
                    </>
                )}
            </form>
        </PopOver>
    );
}

export default ManageAliasPopOver;
