import React from 'react';
import { NodeEntry, Range } from 'slate';
import { SagaElement, SagaText, SagaEditor, SagaLocation } from '@saga/shared';
import { RenderLeafProps } from 'slate-react';
import { Leaf } from './leaf/Leaf';
import { v4 as uuid } from 'uuid';

const renderLeaf = (props: RenderLeafProps) => {
    return <Leaf {...props} />;
};

const StaticEditor: React.FC<{
    blocks: SagaElement[];
    className?: string;
    decorate?: (entry: NodeEntry<SagaElement | SagaText>) => Range[];
    location: SagaLocation.BlocksLocation;
    blockPlugins: SagaEditor.Plugins.BlockPlugin[];
}> = ({ blocks, className, decorate = () => [], location, blockPlugins }) => {
    // Unfortunately, passing the blocks outside to slate does not allow to rerender the component with the fresh blocks
    // as you can read in this issue https://github.com/ianstormtaylor/slate/issues/4612
    // Therefore, a current good enough hack is to generate a new key when the blocks change from outside
    const key = React.useMemo(() => {
        blocks;
        return uuid();
    }, [blocks]);
    return (
        <SagaEditor.ReadonlyEditor key={key} blocks={blocks} location={location} blockPlugins={blockPlugins}>
            <SagaEditor.Container className={className}>
                <SagaEditor.ReadonlyEditable
                    decorate={decorate}
                    renderElement={SagaEditor.RenderElement}
                    renderLeaf={renderLeaf}
                />
            </SagaEditor.Container>
        </SagaEditor.ReadonlyEditor>
    );
};

export default StaticEditor;
