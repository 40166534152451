import { BlockBuilder } from '../..';
import { newBlankPage } from '../../pageUtils';

export function newSagaAiPageFr(collectionIds: string[]) {
    return newBlankPage({
        title: 'Saga IA',
        icon: {
            type: 'emoji',
            colons: ':sparkles:',
        },
        collections: collectionIds,
        blocks: [
            BlockBuilder.title('Saga IA'),
            BlockBuilder.headingThree('Générer du contenu et obtenir des réponses avec l’IA'),
            BlockBuilder.image('https://saga-public.s3.amazonaws.com/1-ai-assistant-generating-popover-launch.webp'),
            BlockBuilder.checkListItem([BlockBuilder.text('Créer un brouillon en utilisant l’IA.')]),
            BlockBuilder.listItem([
                BlockBuilder.text('Cliquez sur le '),
                BlockBuilder.text('IA', { code: true }),
                BlockBuilder.text(' bouton à l’intérieur de l’éditeur.'),
            ]),
            BlockBuilder.listItem([
                BlockBuilder.text('Alternativement, appuyez sur '),
                BlockBuilder.text('@', { code: true }),
                BlockBuilder.text(' ou '),
                BlockBuilder.text('/', { code: true }),
                BlockBuilder.text(' et sélectionnez Saga IA dans le menu d’autocomplétion.'),
            ]),
            BlockBuilder.paragraph(),
            BlockBuilder.paragraph(),
            BlockBuilder.image('https://saga-public.s3.amazonaws.com/2-ai-assistant-select-or-type-prompt.webp'),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Sélectionnez une option de la liste, ou rédigez votre propre invite.'),
            ]),
            BlockBuilder.paragraph(),
            BlockBuilder.paragraph(),
            BlockBuilder.image('https://saga-public.s3.amazonaws.com/3-ai-assistant-blog-post-generation.webp'),
            BlockBuilder.checkListItem([
                BlockBuilder.text(
                    'Itérez sur le résultat, ajoutez plus de détails, et consultez l’historique de votre conversation IA.',
                ),
            ]),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Sauvegardez votre invite pour la réutiliser plus tard en appuyant sur le bouton ☆.'),
            ]),
            BlockBuilder.paragraph(),
            BlockBuilder.paragraph(),
            BlockBuilder.headingThree('Éditer avec l’IA'),
            BlockBuilder.image('https://saga-public.s3.amazonaws.com/4-ai-assistant-commands-pallette.webp'),
            BlockBuilder.checkListItem([
                BlockBuilder.text(
                    'Demandez à Saga IA de réécrire votre texte, de corriger la grammaire, de le rendre plus court, de changer le ton, de traduire, et plus encore.',
                ),
            ]),
            // todo - indent
            BlockBuilder.listItem([
                BlockBuilder.text('Sélectionnez n’importe quel texte et cliquez sur "Saga IA" dans la barre d’outils pour ouvrir le menu IA.'),
            ]),
            BlockBuilder.listItem([BlockBuilder.text('Sélectionnez une option de la liste, ou rédigez votre propre invite.')]),
            BlockBuilder.paragraph(),
            BlockBuilder.paragraph(),
            BlockBuilder.image('https://saga-public.s3.amazonaws.com/5-ai-assistant-edit-the-text.webp'),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Demandez à Saga IA quoi faire ensuite avec la réponse, ajoutez des détails, et itérez.'),
            ]),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Remplacez le contenu initial par la réponse de l’IA ou insérez-la ci-dessous.'),
            ]),
            BlockBuilder.paragraph(),
            BlockBuilder.paragraph(),
            BlockBuilder.divider(),
            BlockBuilder.headingThree('Guides et FAQ'),
            BlockBuilder.paragraph([
                BlockBuilder.text(
                    'Consultez les guides de Saga IA pour découvrir plus d’astuces et de conseils sur l’intégration de l’IA dans votre espace de travail, ou explorez 👋 Les bases de Saga pour approfondir vos connaissances et continuer votre apprentissage.',
                ),
            ]),
            BlockBuilder.rawPrettyLink('https://saga.so/guides-category/saga-ai', {
                _tag: 'loaded',
                description:
                    'Apprenez à utiliser Saga IA - l’assistant alimenté par l’IA dans votre espace de travail. Soyez créatif et plus productif avec un assistant IA.',
                title: '📍 Saga IA – Guides de Saga',
                logo: 'https://saga.so/thumbnail.png',
            }),
            BlockBuilder.paragraph(),
        ],
    });
}
