import React from 'react';
import classNames from 'classnames';
import { Layers } from 'react-feather';
import { useSelected } from 'slate-react';

import { Collection, InlineCollection, SagaEditor, isInlineCollection } from '@saga/shared';
import Emoji from '@/components/Emoji';
import { usePageStaticElement } from '@/components/PublicPageProvider';

export function collectionToString(collection: Pick<Collection, 'title'> | undefined) {
    return collection?.title ?? 'collection not found';
}

export function InlineCollectionElement({
    collection,
    children,
    onClick,
    selected,
}: {
    selected?: boolean;
    collection?: Pick<Collection, 'icon' | 'title'>;
    onClick?: (evt: React.MouseEvent) => void;
} & SagaEditor.Plugins.BlockPluginProps<InlineCollection>) {
    return (
        <span
            className={classNames(
                'text-saga-text font-medium dark:text-neutral-200 title:font-semibold within-checked:text-saga-gray-dark dark:within-checked:text-saga-gray-200 relative select-none rounded overflow-hidden cursor-pointer',
                {
                    'bg-saga-new-purple-light/20 shadow-lightblue': selected,
                },
            )}
            onMouseDown={onClick}
        >
            <span className="hidden select-none">{children}</span>
            <span className="space-x-0.5">
                {collection?.icon && (
                    <span className="relative inline-emoji">
                        <React.Suspense fallback={null}>
                            <Emoji emoji={collection?.icon.colons} size={0} />
                        </React.Suspense>
                        <Layers className="layer-icon bg-slate-200/80 dark:bg-zinc-700/60 rounded-full absolute" />
                    </span>
                )}
                {!collection?.icon && <Layers className="layer-icon" />}
                <span>{collectionToString(collection)}</span>
            </span>
        </span>
    );
}

export const inlineCollectionPlugin = SagaEditor.Plugins.createBlockPlugin({
    match: isInlineCollection,
    Component(props) {
        const selected = useSelected();
        const collection = usePageStaticElement(props.element)?.staticCollection ?? props.element.staticCollection;

        return <InlineCollectionElement {...props} collection={collection} selected={selected} />;
    },
    stringify: (element) => collectionToString(element.staticCollection),
});
