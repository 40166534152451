import { assertYArray } from '../yjs-utils';
import findCollectionById from './findCollectionById';
import transactInSpace from './transactInSpace';
import { SafeSpace } from '../types';
import * as Y from 'yjs';

export default function addSubCollectionById(space: SafeSpace, collectionId: string, subCollectionId: string) {
    transactInSpace(space, () => {
        const collection = findCollectionById(space, collectionId);
        let subCollections = collection.get('subCollections');

        if (!subCollections) {
            subCollections = new Y.Array();
            collection.set('subCollections', subCollections);
        }

        assertYArray(subCollections);
        if (!subCollections.toArray().includes(subCollectionId)) {
            subCollections.push([subCollectionId]);
        }
    });
}
