import { dndTypes } from '@/constants';
import { BlockDroppable, DndOverArea } from '@/types';
import React from 'react';
import { DropTargetMonitor, useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';

const accept = [
    dndTypes.BLOCK,
    dndTypes.SIDEBAR_PAGE,
    dndTypes.SIDEBAR_TASK,
    dndTypes.SIDEBAR_COLLECTION,
    dndTypes.REFERENCE,
    NativeTypes.FILE,
];

// function getImagesFromDataTransfer(dataTransfer: DataTransfer): DataTransferItem[] {
//     return Array.from(dataTransfer.items).filter((item) => item.type.startsWith('image'));
// }

export default function useBlockDrop({
    onDrop,
    isEnabled,
}: {
    onDrop(item: BlockDroppable, monitor: DropTargetMonitor<unknown, unknown>): void;
    isEnabled: boolean;
}) {
    const dropRef = React.useRef<HTMLDivElement>(null);
    const [{ overArea }, connectDrop] = useDrop<BlockDroppable, unknown, { overArea: DndOverArea | null }>({
        accept,
        canDrop(item) {
            if ('dataTransfer' in item) {
                // Checking for images only in `getImagesFromDataTransfer` was removed because we want to allow dropping any type of file
                return isEnabled && item.dataTransfer.items.length > 0;
            }

            return isEnabled;
        },
        collect(monitor) {
            const isOver = monitor.isOver({ shallow: true });
            let overArea: DndOverArea | null = null;
            const offset = monitor.getClientOffset();

            if (isOver && dropRef.current && offset) {
                const rect = dropRef.current.getBoundingClientRect();
                const isOverHalf = (offset.y ?? 0) >= rect.top + rect.height / 2;
                overArea = isOverHalf ? 'bottom' : 'top';
            }

            return { overArea };
        },
        drop: onDrop,
    });

    connectDrop(dropRef);

    return { overArea, dropRef };
}
