import { SpaceProvider, useCurrentSearchMap, useSpace } from '@/components/SpaceProvider';
import React, { useEffect } from 'react';
import {
    Page,
    newBlankPage,
    BlockType,
    SpaceOperations,
    findYArrayIndex,
    getWordCount,
    DocumentName,
    CookieUtils,
    SagaElement,
    memoizeDebounce,
    Search,
    isLiveBlock,
    isTitle,
    SagaLocation,
    unsafeRight,
} from '@saga/shared';
import * as Y from 'yjs';
import { useCurrentWorkspace, WorkspaceContextProvider } from '@/components/WorkspaceContext';
import useYPagesEvents from '@/hooks/useYPagesEvents';
import { debounce } from 'lodash';
import { RealtimeProvider } from '@/components/RealtimeProvider';
import CloudfrontCookiesProvider from '@/components/CloudfrontCookiesProvider';
import LoadingScreen from '@/components/loading/LoadingScreen';
import CopyWorkspaceModalProvider from '@/components/CopyWorkspaceModalProvider';
import { track } from '@/analytics';
import RecentsContextProvider from '@/components/RecentsContext';
import { MembersProvider } from '@/components/MembersProvider';
import { SpaceAutolinkIndex } from '@/components/AutolinkIndex';
import ImportFileDropzone from '@/components/ImportFileDropzone';
import SpaceBlockPluginProvider from './pluginProviders/SpaceBlockPluginProvider';
import SpacePermissionsProvider from './SpacePermissionsProvider';
import { SpaceRealtimeDocumentProvider, usePerformActionWithSafeContent } from '@/components/RealtimeDocumentProvider';
import { createPage } from '@/utils/documentUtils';
import useYDocumentContentEvents from '@/hooks/useYDocumentContentEvents';
import { SpaceSearchEventsProvider, useUpdateSearchIndex } from '@/components/SpaceSearchEventsProvider';
import { useHistory } from 'react-router-dom';
import PagePermissionsBySpaceProvider from '@/components/PagesPermissionsBySpaceProvider';
import AIPermissionsProvider from '@/components/editor/ai/AIPermissionsProvider';

type Props = {
    children: React.ReactNode;
    urlKey: string;
};

function setUpdatedAtInYPage(yPage: Y.Map<unknown>, blocks?: SagaElement[]) {
    yPage.doc?.transact(() => {
        yPage.set('updatedAt', new Date().toString());

        if (blocks) {
            const wordCount = getWordCount(blocks.slice(1));
            yPage.set('wordCount', wordCount);
        }
    });
}

const trackEditPageContent = debounce(
    () => {
        track('edit-page-content');
    },
    60_000,
    { leading: true },
);

const trackEditTaskContent = debounce(
    () => {
        track('edit-task-content');
    },
    60_000,
    { leading: true },
);

const useSpaceEvents = () => {
    const [setupDone, setSetupDone] = React.useState(false);
    const { space, yDoc, provider } = useSpace();

    const spaceBlocks = useCurrentSearchMap();
    const { updateSearchIndex } = useUpdateSearchIndex();
    const performSafeContentAction = usePerformActionWithSafeContent();

    const updatePageTitle = React.useCallback((id: string, page: Y.Map<any>, title: string) => {
        id;
        page.set('title', title);
    }, []);

    const updatePageUpdatedAt = React.useCallback(
        (pageId: string, yContent: Y.Map<any>) => {
            const pageMap = SpaceOperations.getPageMapById(space, pageId);
            const taskMap = SpaceOperations.findTaskMap(space, pageId);
            const yBlocks = yContent.get('blocks') as Y.Array<any>;

            if (pageMap) {
                setUpdatedAtInYPage(pageMap, yBlocks?.toJSON());
                trackEditPageContent();
            }

            if (taskMap) {
                setUpdatedAtInYPage(taskMap, yBlocks?.toJSON());
                trackEditTaskContent();
            }
        },
        [space],
    );

    const memoizeDebounceResolver = React.useMemo(() => (id: string) => id, []);
    const debounceUpdateSearchIndex = memoizeDebounce(updateSearchIndex, 1000, {}, memoizeDebounceResolver);
    const debounceUpdatePageUpdatedAt = memoizeDebounce(updatePageUpdatedAt, 500, {}, memoizeDebounceResolver);
    const debounceUpdatePageTitle = memoizeDebounce(updatePageTitle, 500, {}, memoizeDebounceResolver);

    useYDocumentContentEvents({
        onAnyBlockChange(yContent, pageId) {
            const yBlocks = yContent.get('blocks') as Y.Array<any>;

            debounceUpdatePageUpdatedAt(pageId, yContent);

            if (yBlocks) {
                debounceUpdateSearchIndex(yContent.get('id') as string, yBlocks);
            }
        },
        onTopLevelBlockChanged(yBlock, page) {
            const type = yBlock.get('type');

            if (type === BlockType.LIVE_BLOCK_SOURCE || type === BlockType.TITLE) {
                const id =
                    type === BlockType.LIVE_BLOCK_SOURCE
                        ? (yBlock.get('liveReferenceSourceId') as string)
                        : (yBlock.get('id') as string);

                const entry = SpaceOperations.findReferenceRegistryEntry(space, id);

                if (entry) {
                    const children = (yBlock.get('children') as Y.Array<unknown>).toJSON();

                    SpaceOperations.updateReferenceRegistryEntry(space, id, {
                        liveBlocks: type === BlockType.TITLE ? [yBlock.toJSON()] : children,
                    });
                }
            }

            if (type === BlockType.TITLE) {
                const titleBlock = (yBlock.get('children') as Y.Array<unknown>).toJSON();
                if (isTitle(titleBlock)) {
                    debounceUpdatePageTitle(page.get('id') as string, page, titleBlock.children[0].text);
                }
            }
        },
        onTopLevelBlockAdded(yBlock, _, pageId) {
            const type = yBlock.get('type');

            if (type === BlockType.LIVE_BLOCK_SOURCE) {
                const id = yBlock.get('liveReferenceSourceId') as string;

                const entry = SpaceOperations.findReferenceRegistryEntry(space, id);

                if (entry) {
                    SpaceOperations.updateReferenceRegistryEntry(space, id, {
                        isArchived: false,
                        pageId,
                    });
                }
            }
        },
        onTopLevelBlockDeleted(yBlock, _, pageId) {
            const typeItem = yBlock._map.get('type');
            if (typeItem) {
                const [type] = typeItem.content.getContent();

                // Make sure that live references are up to date
                const liveReferenceSourceIdItem = yBlock._map.get('liveReferenceSourceId');
                if (type === BlockType.LIVE_BLOCK_SOURCE && liveReferenceSourceIdItem) {
                    const [liveReferenceSourceId] = liveReferenceSourceIdItem.content.getContent();

                    if (typeof liveReferenceSourceId === 'string') {
                        const yentry = SpaceOperations.findReferenceRegistryEntry(space, liveReferenceSourceId);

                        if (yentry) {
                            const blockQuery = Search.blockQuery({
                                match: isLiveBlock,
                                predicate(reference) {
                                    return reference.reference.liveReferenceSourceId === liveReferenceSourceId;
                                },
                            });
                            const references = SpaceOperations.findInLocations(
                                unsafeRight(space.decode()),
                                spaceBlocks?.toJSON(),
                                [blockQuery],
                            );
                            if (references.length === 0) {
                                const parent = yentry.parent;
                                if (parent instanceof Y.Array) {
                                    const index = findYArrayIndex(parent, (element) => element === yentry);
                                    parent.delete(index);
                                }
                            } else if (yentry.get('pageId') === pageId) {
                                SpaceOperations.updateReferenceRegistryEntry(space, liveReferenceSourceId, {
                                    isArchived: true,
                                });
                            }
                        }
                    }
                }
            }
        },
    });

    useYPagesEvents({
        onTaskChanged(event, yTask) {
            if (event.target === yTask && event instanceof Y.YMapEvent) {
                if (event.keysChanged.has('updatedAt') || event.keysChanged.has('wordCount')) {
                    return;
                }
            }

            debounceUpdatePageUpdatedAt(yTask.get('id') as string, yTask);
        },
        onPageChanged(event, yPage) {
            if (event.target === yPage && event instanceof Y.YMapEvent) {
                if (event.keysChanged.has('icon')) {
                    const iconYMap = yPage.get('icon') as Y.Map<any>;
                    performSafeContentAction(SagaLocation.pageLocationFromId(yPage.get('id') as string), (content) => {
                        content.map.set('icon', iconYMap?.clone());
                    });
                }

                if (event.keysChanged.has('updatedAt') || event.keysChanged.has('wordCount')) {
                    return;
                }
            }

            debounceUpdatePageUpdatedAt(yPage.get('id') as string, yPage);
        },
        onPageDeleted(id) {
            SpaceOperations.archiveAllReferenceRegistryEntriesByPageId(space, id);
        },

        onCollectionChanged(event, yCollection) {
            if (event.target === yCollection && event instanceof Y.YMapEvent && event.keysChanged.has('updatedAt')) {
                return;
            }
            yCollection.set('updatedAt', new Date().toString());
        },
    });

    // Ensure that at least one page exists in Saga
    React.useEffect(() => {
        const ypages = space.map.get('pages') as Y.Array<Y.Map<Page>>;

        function observePages() {
            const pages = SpaceOperations.getPages(space, ['id'], 'non-deleted');

            if (pages.length === 0) {
                createPage(space, newBlankPage({}), provider);
            }
        }

        ypages.observe(observePages);

        return () => {
            ypages.unobserve(observePages);
        };
    }, [space, yDoc, provider]);

    // Ensure that space is migrated
    React.useEffect(() => {
        // we need to migrate the space the first time we load it on the client
        SpaceOperations.migrateSpace(space);

        function observeSpace() {
            if (space.map.get('version') != null) setSetupDone(true);
        }

        space.map.observe(observeSpace);

        observeSpace();

        return () => {
            space.map.unobserve(observeSpace);
        };
    }, [space, yDoc]);

    return { setupDone };
};

function SpaceEvents({ children }: { children: React.ReactNode }) {
    const { setupDone } = useSpaceEvents();
    const currentSpace = useCurrentWorkspace();
    const duplicatePageData = localStorage.getItem('duplicatePageData');
    const history = useHistory();

    useEffect(() => {
        if (duplicatePageData && currentSpace.useCases.length > 0) {
            history.push(`/s/${currentSpace.urlKey}/duplicatePage`);
        }
    }, [duplicatePageData, history, currentSpace]);

    if (!setupDone) {
        return <LoadingScreen />;
    }

    return <>{children}</>;
}

export default function Space({ children, urlKey }: Props) {
    const documentName = DocumentName.build('space', urlKey);
    const offlineDocumentName = DocumentName.build('space-offline', urlKey);

    return (
        <WorkspaceContextProvider urlKey={urlKey}>
            <SpacePermissionsProvider urlKey={urlKey}>
                <MembersProvider>
                    <PagePermissionsBySpaceProvider>
                        <RecentsContextProvider>
                            <CloudfrontCookiesProvider urlKey={urlKey} cookieType={CookieUtils.CookieTypes.space}>
                                <RealtimeProvider
                                    documentName={documentName}
                                    offlineDocumentName={offlineDocumentName}
                                    allowOfflineLoadingState
                                >
                                    <SpaceProvider>
                                        <CopyWorkspaceModalProvider>
                                            <SpaceRealtimeDocumentProvider>
                                                <SpaceSearchEventsProvider>
                                                    <SpaceBlockPluginProvider>
                                                        <SpaceAutolinkIndex>
                                                            <SpaceEvents>
                                                                <AIPermissionsProvider>
                                                                    <ImportFileDropzone>{children}</ImportFileDropzone>
                                                                </AIPermissionsProvider>
                                                            </SpaceEvents>
                                                        </SpaceAutolinkIndex>
                                                    </SpaceBlockPluginProvider>
                                                </SpaceSearchEventsProvider>
                                            </SpaceRealtimeDocumentProvider>
                                        </CopyWorkspaceModalProvider>
                                    </SpaceProvider>
                                </RealtimeProvider>
                            </CloudfrontCookiesProvider>
                        </RecentsContextProvider>
                    </PagePermissionsBySpaceProvider>
                </MembersProvider>
            </SpacePermissionsProvider>
        </WorkspaceContextProvider>
    );
}
