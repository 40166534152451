import { BlockBuilder } from '../..';
import { newBlankPage } from '../../pageUtils';

export function newSagaAiPageEn(collectionIds: string[]) {
    return newBlankPage({
        title: 'Saga AI',
        icon: {
            type: 'emoji',
            colons: ':sparkles:',
        },
        collections: collectionIds,
        blocks: [
            BlockBuilder.title('Saga AI'),
            BlockBuilder.headingThree('Generate content and get aswers with AI'),
            BlockBuilder.image('https://saga-public.s3.amazonaws.com/1-ai-assistant-generating-popover-launch.webp'),
            BlockBuilder.checkListItem([BlockBuilder.text('Create a draft using AI.')]),
            BlockBuilder.listItem([
                BlockBuilder.text('Click on the '),
                BlockBuilder.text('AI', { code: true }),
                BlockBuilder.text(' button inside the editor.'),
            ]),
            BlockBuilder.listItem([
                BlockBuilder.text('Alternatively, hit '),
                BlockBuilder.text('@', { code: true }),
                BlockBuilder.text(' or '),
                BlockBuilder.text('/', { code: true }),
                BlockBuilder.text(' and select Saga AI from the autocomplete menu.'),
            ]),
            BlockBuilder.paragraph(),
            BlockBuilder.paragraph(),
            BlockBuilder.image('https://saga-public.s3.amazonaws.com/2-ai-assistant-select-or-type-prompt.webp'),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Select an option from the list, or write your own prompt.'),
            ]),
            BlockBuilder.paragraph(),
            BlockBuilder.paragraph(),
            BlockBuilder.image('https://saga-public.s3.amazonaws.com/3-ai-assistant-blog-post-generation.webp'),
            BlockBuilder.checkListItem([
                BlockBuilder.text(
                    'Iterate on the output, add more details, and see the history of your AI conversation.',
                ),
            ]),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Save your prompt to reuse it later by hitting the ☆ button. '),
            ]),
            BlockBuilder.paragraph(),
            BlockBuilder.paragraph(),
            BlockBuilder.headingThree('Edit with AI'),
            BlockBuilder.image('https://saga-public.s3.amazonaws.com/4-ai-assistant-commands-pallette.webp'),
            BlockBuilder.checkListItem([
                BlockBuilder.text(
                    'Ask Saga AI to rewrite your text, fix grammar, make it shorter, change the tone of voice, translate, and more. ',
                ),
            ]),
            // todo - indent
            BlockBuilder.listItem([
                BlockBuilder.text('Select any text and click on "Saga AI" in the toolbar to open the AI menu.'),
            ]),
            BlockBuilder.listItem([BlockBuilder.text('Select an option from the list, or write your own prompt.')]),
            BlockBuilder.paragraph(),
            BlockBuilder.paragraph(),
            BlockBuilder.image('https://saga-public.s3.amazonaws.com/5-ai-assistant-edit-the-text.webp'),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Ask Saga AI what to do next with the answer, add details, and iterate.'),
            ]),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Replace the initial content with the answer from the AI or insert it below.'),
            ]),
            BlockBuilder.paragraph(),
            BlockBuilder.paragraph(),
            BlockBuilder.divider(),
            BlockBuilder.headingThree('Guides and FAQs'),
            BlockBuilder.paragraph([
                BlockBuilder.text(
                    'Visit the Saga AI guides for more tips and tricks on using AI inside your workspace or head back to 👋 Saga basics to continue learning.',
                ),
            ]),
            BlockBuilder.rawPrettyLink('https://saga.so/guides-category/saga-ai', {
                _tag: 'loaded',
                description:
                    'Learn how to use Saga AI - the AI-powered assistant inside your workspace. Get creative and be more productive with an AI assistant.',
                title: '📍 Saga AI – Saga Guides',
                logo: 'https://saga.so/thumbnail.png',
            }),
            BlockBuilder.paragraph(),
        ],
    });
}
