import { applyYjsEvents, translateYjsEvent } from './applyToSlate';
import applySlateOps from './applyToYjs';
import { SyncElement, SyncNode, slateYjsSymbol } from './model';
import { CursorEditor, withCursor, withYjs, YjsEditor } from './plugin';
import { toSlateDoc, toSyncElement } from './utils';
import { getRangeFromCursor, relativePositionToAbsolutePosition } from './cursor/utils';
import * as t from 'io-ts';
import { blocksLocationD } from '../SagaLocation';

const yId = t.type({
    client: t.number,
    clock: t.number,
});

const yRelativePosition = t.type({
    type: t.union([yId, t.null]),
    item: t.union([yId, t.null]),
    tname: t.union([t.string, t.null]),
    assoc: t.number,
});

const userStateD = t.type({
    name: t.string,
    userId: t.string,
    color: t.string,
    alphaColor: t.string,
});

export type UserState = t.TypeOf<typeof userStateD>;

const cursorD = t.type({
    location: blocksLocationD,
    anchor: t.union([yRelativePosition, t.null]),
    focus: t.union([yRelativePosition, t.null]),
});

export type Cursor = t.TypeOf<typeof cursorD>;

const awarenessStateData = t.type({
    user: userStateD,
    cursors: t.union([t.array(cursorD), t.null, t.undefined]),
});

export const awarenessStateD = t.tuple([t.number, awarenessStateData]);

export type AwarenessStateData = t.TypeOf<typeof awarenessStateData>;
export type AwarenessState = t.TypeOf<typeof awarenessStateD>;

export {
    CursorEditor,
    SyncElement,
    SyncNode,
    withCursor,
    withYjs,
    YjsEditor,
    toSlateDoc,
    toSyncElement,
    translateYjsEvent,
    applyYjsEvents,
    applySlateOps,
    slateYjsSymbol,
    relativePositionToAbsolutePosition,
    getRangeFromCursor,
};
