import { LoadingSpinner } from '@/components/loading/LoadingScreen';
import Button from '@/components/styled/Button';
import React, { useEffect } from 'react';
import { ExternalLink } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

function waitForIFrameState(iframe: HTMLIFrameElement) {
    return new Promise<'LOADED' | 'ERROR'>((resolve) => {
        function checkIframeLoaded() {
            if ((iframe.contentWindow?.length ?? 0) > 0) {
                resolve('LOADED');
                return true;
            }

            return false;
        }
        const check = checkIframeLoaded();

        if (check) {
            return;
        }

        const checkIframeLoadedInterval = setInterval(checkIframeLoaded, 100);

        setTimeout(() => {
            clearInterval(checkIframeLoadedInterval);
            if ((iframe.contentWindow?.length ?? 0) === 0) {
                resolve('ERROR');
            }
        }, 1000);
    });
}

export function EmbedContainer({ embedUrl, openUrl }: { embedUrl: string; openUrl?: string }) {
    const [state, setState] = React.useState<'LOADING' | 'ERROR' | 'LOADED'>('LOADING');
    const isMounted = React.useRef(true);
    const { t } = useTranslation();
    const history = useHistory();

    useEffect(() => {
        if (state === 'ERROR') {
            window.open(openUrl ?? embedUrl, '_blank', 'noopener noreferrer');
            history.goBack();
        }
    }, [state, history, openUrl, embedUrl]);

    return (
        <div className="h-full w-full relative overflow-hidden pointer-events-auto">
            {state === 'ERROR' && (
                <div className="bg-white dark:bg-saga-shade-500 w-full p-2 flex items-center justify-center space-x-2">
                    <span>{t('errors.cant_open_page')}</span>
                    <Button.Plain
                        onClick={() => {
                            window.open(openUrl ?? embedUrl, '_blank', 'noopener noreferrer');
                        }}
                    >
                        <Button.SmallPadding>
                            <div className="flex space-x-1">
                                <ExternalLink size={16} />
                                <span>{t('common.open_in_new_tab')}</span>
                            </div>
                        </Button.SmallPadding>
                    </Button.Plain>
                </div>
            )}

            {state === 'LOADING' && (
                <div className="bg-white dark:bg-zinc-700 absolute inset-0 rounded overflow-hidden">
                    <LoadingSpinner />
                </div>
            )}

            {state !== 'ERROR' && (
                <iframe
                    className="bg-white rounded overflow-hidden"
                    onLoad={async (event) => {
                        const iframe = event.target;
                        if (iframe instanceof HTMLIFrameElement) {
                            const state = await waitForIFrameState(iframe);

                            if (isMounted.current) {
                                setState(state);
                            }
                        }
                    }}
                    height="100%"
                    width="100%"
                    src={embedUrl}
                />
            )}
        </div>
    );
}
