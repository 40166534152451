import React from 'react';
import { NotificationsIndicator } from '@/components/icons';
import classNames from 'classnames';
import * as api from '@saga/api';
import { animated } from '@react-spring/web';
import { useDesktopContext } from '../DesktopContext';
import useInterfaceSettings from '@/hooks/useInterfaceSettings';
import { useCurrentWorkspace } from '../WorkspaceContext';
import useMobile from '@/hooks/useMobile';
import { useTranslation } from 'react-i18next';
type Color = { r: number; b: number; g: number };

// Based on this https://stackoverflow.com/questions/5623838/rgb-to-hex-and-hex-to-rgb
function calcLuma(color: Color) {
    return (0.299 * color.r + 0.587 * color.g + 0.114 * color.g) / 255;
}

function hexToRgb(hex: string): Color {
    return {
        r: parseInt(hex.substr(-6, 2), 16),
        g: parseInt(hex.substr(-4, 2), 16),
        b: parseInt(hex.substr(-2), 16),
    };
}

function WorkspaceAvatar({
    showNotificationsIndicator,
    workspace: { title, color },
}: {
    workspace: Pick<api.SpaceFragmentFragment, 'title' | 'color'>;
    showNotificationsIndicator?: boolean;
}) {
    const shouldUseDarkColor = React.useMemo(() => {
        return color ? calcLuma(hexToRgb(color)) > 0.5 : false;
    }, [color]);

    const firstCodePoint = title.codePointAt(0);

    return (
        <div
            style={{
                backgroundColor: color ?? undefined,
            }}
            className={classNames(
                'flex-shrink-0 rounded-5 h-[22px] w-[22px] font-bold relative uppercase text-xs flex items-center justify-center ',
                {
                    'bg-saga-new-green dark:bg-saga-new-green/50': color == null,
                    'text-white dark:text-zinc-200': !shouldUseDarkColor,
                    'text-saga-text': shouldUseDarkColor,
                },
            )}
        >
            {firstCodePoint && String.fromCodePoint(firstCodePoint)}
            {showNotificationsIndicator && <NotificationsIndicator className="absolute -right-1 -top-1" />}
        </div>
    );
}

WorkspaceAvatar.SideBySide = function WorkspaceAvatarSideBySide() {
    const workspace = useCurrentWorkspace();
    const [{ fixedSidebar }, setInterfaceSettings] = useInterfaceSettings();
    const { useMacOsLayout } = useDesktopContext();
    const { t } = useTranslation();

    function openSidebar() {
        setInterfaceSettings({ fixedSidebar: true });
    }

    if (fixedSidebar || useMacOsLayout === false) {
        return null;
    }

    return (
        <animated.button onClick={openSidebar} type="button" className="focus:outline-none dark:bg-saga-gray-1000">
            <span className="sr-only">{t('common.open_sidebar')}</span>
            <WorkspaceAvatar workspace={workspace} />
        </animated.button>
    );
};

WorkspaceAvatar.AbsoluteContainer = function WorkspaceAvatarAbsoluteContainer({
    children,
}: {
    children: React.ReactNode;
}) {
    const isMobile = useMobile();
    const { isDesktop } = useDesktopContext();

    return (
        <div
            className={classNames('absolute z-100', {
                'left-5 top-2.5': !isMobile && !isDesktop,
                'left-[4.25rem] top-[0.78rem]': isMobile,
                'top-[0.78rem] left-[1.1rem]': isDesktop && !isMobile,
            })}
        >
            {children}
        </div>
    );
};

export default WorkspaceAvatar;
