import { deleteTaskPermanently } from '.';
import { isSagaText, SafeSpace, WeakTask, isVoid, WeakBlocks, flatMap } from '../types';
import findTask from './findTask';
import transactInSpace from './transactInSpace';
import updatePartialTask from './updatePartialTask';

export default function deleteTaskIfEmpty(
    space: SafeSpace,
    taskWithBlocks: Pick<WeakTask, 'id'> & { blocks: WeakBlocks },
) {
    transactInSpace(space, () => {
        const task = findTask(space, taskWithBlocks.id);

        if (task == null) return;

        if (taskIsEmpty(taskWithBlocks.blocks)) {
            updatePartialTask(space, task.id, { archivedAt: new Date().toString() });
            deleteTaskPermanently(space, task.id);
        }
    });
}

function taskIsEmpty(taskBlocks: WeakBlocks) {
    const blocks = flatMap(taskBlocks, ([n]) => n);
    if (blocks.some(isVoid)) return false;

    return blocks.filter(isSagaText).every((b) => isSagaText(b) && b.text.trim().length === 0);
}
