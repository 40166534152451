import React from 'react';
import { SagaEditor, isKatexBlock, isKatexInline, KatexBlock, KatexInline } from '@saga/shared';
import { Transforms } from 'slate';
import { ReactEditor } from 'slate-react';
import classNames from 'classnames';
import CodeEditor from '../CodeEditor';
import ErrorBoundary from '@/components/ErrorBoundary';
import VoidSelectionShadow from '../VoidSelectionShadow';
import * as Popover from '@radix-ui/react-popover';
import Button from '@/components/styled/Button';
import { MoreHorizontal, Trash } from 'react-feather';

const KatexRenderer = React.lazy(() => import('@/components/KatexRenderer'));

export const OPEN_KATEX_EDITOR_SET = new Set();

type KatexFeatures = {
    onChange: (value: string) => void;
    onAfterOpenEditor(): void;
    onRemove(): void;
};

export const KatexElement = ({
    children,
    element,
    selected,
    path,
    features,
}: SagaEditor.Plugins.BlockPluginProps<KatexBlock | KatexInline> & { features?: KatexFeatures }) => {
    const errorBoundaryRef = React.useRef<ErrorBoundary | null>(null);
    const [edit, setEdit] = React.useState(() => OPEN_KATEX_EDITOR_SET.has(element.id));

    React.useEffect(() => {
        errorBoundaryRef.current?.setState({ error: null });
    }, [element.value]);

    const canChange = features?.onChange;

    return (
        <span
            id={element.id}
            data-testid="katex-element"
            className={classNames('leading-normal group relative', {
                'inline-block max-w-full': isKatexInline(element),
                'flex items-center h-full': !isKatexInline(element),
            })}
        >
            <Popover.Root open={edit} onOpenChange={setEdit} defaultOpen={OPEN_KATEX_EDITOR_SET.has(element.id)}>
                <VoidSelectionShadow path={path}>
                    <span
                        className={classNames('select-none rounded flex relative', {
                            'hover:bg-saga-new-purple-light/10': !edit && canChange,
                            'bg-saga-new-purple-light/10': edit,
                            'cursor-pointer': canChange,
                            'shadow-lightblue': selected,
                            'p-4 justify-center': element.value === '' && isKatexBlock(element),
                        })}
                        contentEditable={false}
                        onClick={() => {
                            if (!canChange) return;
                            setEdit(true);
                            features?.onAfterOpenEditor();
                        }}
                    >
                        <ErrorBoundary
                            ref={errorBoundaryRef}
                            fallback={(error) => {
                                return (
                                    <span className="text-red-500">
                                        {error.name}: {error.message}
                                    </span>
                                );
                            }}
                        >
                            {element.value === '' && <span className="opacity-30">Equation</span>}
                            {element.value !== '' && (
                                <React.Suspense fallback={null}>
                                    <KatexRenderer value={element.value} displayMode={!isKatexInline(element)} />
                                </React.Suspense>
                            )}
                        </ErrorBoundary>
                        <Popover.Anchor asChild className="absolute left-0 bottom-0">
                            <span />
                        </Popover.Anchor>
                    </span>
                </VoidSelectionShadow>

                {features && (
                    <Popover.Content align="start" className="z-70">
                        <div className={classNames('w-400 relative rounded mt-2 pointer-events-auto')}>
                            <React.Suspense fallback={null}>
                                <CodeEditor
                                    content={element.value}
                                    language="latex"
                                    onChange={features?.onChange}
                                    disabled={!canChange}
                                />
                            </React.Suspense>
                        </div>
                    </Popover.Content>
                )}
                {features && isKatexBlock(element) && <KatexElement.ContextMenu onRemove={features.onRemove} />}
            </Popover.Root>

            <span className="select-none hidden">{children}</span>
        </span>
    );
};

KatexElement.ContextMenu = function ContextMenu({ onRemove }: { onRemove: () => void }) {
    const [isPopoverOpen, setPopoverOpen] = React.useState(false);

    return (
        <>
            <Popover.Root onOpenChange={setPopoverOpen}>
                <div
                    className={classNames('absolute top-0 right-0 group-hover:flex opacity-100 flex-row z-50', {
                        flex: isPopoverOpen,
                        hidden: !isPopoverOpen,
                    })}
                >
                    <Popover.Trigger>
                        <div
                            aria-label="Katex Context Menu"
                            aria-haspopup="true"
                            className="w-7 h-7 m-1 bg-white dark:bg-zinc-700 shadow-popupSmall inset-0 rounded focus:outline-none active:shadow-xs"
                        />
                        <MoreHorizontal size={16} className="absolute m-1  top-1.5 left-1.5" />
                    </Popover.Trigger>
                </div>
                <Popover.Content align="start" className="z-70">
                    <div className="p-1.5 overflow-hidden relative shadow-popupSmall max-w-highlight rounded-md bg-white dark:bg-saga-gray-900 dark:border dark:border-saga-gray-800 pointer-events-auto z-70">
                        <div className="flex flex-col w-full space-y-1">
                            <Button.Plain onClick={onRemove} onMouseDown={onRemove}>
                                <Button.SmallPadding>
                                    <div className="flex items-center space-x-2">
                                        <Trash size={14} />
                                        <p className="text-sm">Remove Equation</p>
                                    </div>
                                </Button.SmallPadding>
                            </Button.Plain>
                        </div>
                    </div>
                </Popover.Content>
            </Popover.Root>
        </>
    );
};

export function Katexelement(props: SagaEditor.Plugins.BlockPluginProps<KatexInline | KatexBlock>) {
    const { canEdit } = SagaEditor.useEditorContext();
    const { path, selected, editor, children } = props;

    const onChange = React.useCallback(
        (value: string) => {
            Transforms.setNodes(editor, { value, isNew: false }, { at: path });
        },
        [editor, path],
    );

    return (
        <KatexElement
            {...props}
            selected={selected}
            path={path}
            features={
                canEdit
                    ? {
                          onChange,
                          onAfterOpenEditor() {
                              ReactEditor.blur(editor);
                          },
                          onRemove() {
                              Transforms.removeNodes(editor, { at: path });
                          },
                      }
                    : undefined
            }
        >
            {children}
        </KatexElement>
    );
}

export const katexBlockPlugin = SagaEditor.Plugins.createBlockPlugin({
    match: isKatexBlock,
    Component: Katexelement,
});

export const katexInlinePlugin = SagaEditor.Plugins.createBlockPlugin({
    match: isKatexInline,
    Component: Katexelement,
});
