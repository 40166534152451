import { track } from '@/analytics';
import StaticEditor from '@/components/editor/StaticEditor';
import { PageIcon } from '@/components/icons';
import { useOpenPage } from '@/components/PageNavigationProvider';
import Tooltip from '@/components/popover/Tooltip';
import { useSetQuickEditModalPage } from '@/components/QuickEditPageContainer';
import { useSpace } from '@/components/SpaceProvider';
import Card from '@/components/styled/Card';
import { useCurrentWorkspace } from '@/components/WorkspaceContext';
import {
    SagaEditor,
    EditorOperations,
    isLiveBlock,
    isSagaElement,
    isTitle,
    LiveBlock,
    LiveBlockReferenceData,
    SagaElement,
    SagaLocation,
    SpaceOperations,
} from '@saga/shared';
import * as Sentry from '@sentry/browser';
import classNames from 'classnames';
import React, { useEffect } from 'react';
import { AlertTriangle, Edit2, Trash } from 'react-feather';
import { Transforms } from 'slate';
import { ReactEditor, RenderElementProps, useFocused, useSelected, useSlateStatic } from 'slate-react';
import * as Y from 'yjs';
import useStaticDecorate from '../../decorator/useStaticDecorate';
import VoidSelectionShadow from '../../VoidSelectionShadow';

type ElementProps = RenderElementProps & {
    element: LiveBlock;
    selected: boolean;
};

const pageKeys = ['title', 'icon', 'isTemplate'] as const;

type LiveBlockState =
    | { _tag: 'LiveBlocksNotFound' }
    | {
          _tag: 'LiveBlocksFound';
          value: LiveBlocksFoundType;
      };

type LiveBlocksFoundType = {
    yentry: Y.Map<unknown>;
    liveBlocks: SagaElement[];
    isArchived: boolean;
    liveReferenceSourceId: string;
};

const useLiveBlockState = (reference: LiveBlockReferenceData): LiveBlockState => {
    const { space } = useSpace();

    const [state, setState] = React.useState<LiveBlockState>(() => {
        const yentry = SpaceOperations.findReferenceRegistryEntry(space, reference.liveReferenceSourceId);

        if (!yentry) {
            Sentry.captureMessage('Reference registry entry not found', {
                extra: { reference },
                level: 'error',
            });

            return { _tag: 'LiveBlocksNotFound' };
        }

        const blocks = yentry.get('liveBlocks') as SagaElement[];
        const isArchived = yentry.get('isArchived') === true;

        return {
            _tag: 'LiveBlocksFound',
            value: { yentry, liveBlocks: blocks, isArchived, liveReferenceSourceId: reference.liveReferenceSourceId },
        };
    });

    React.useEffect(() => {
        if (state._tag === 'LiveBlocksFound') {
            const updateLiveBlocks = () => {
                const blocks = state.value.yentry.get('liveBlocks') as SagaElement[];
                const isArchived = state.value.yentry.get('isArchived') === true;

                setState({
                    ...state,
                    value: {
                        ...state.value,
                        isArchived: isArchived,
                        liveBlocks: blocks,
                    },
                });
            };

            state.value.yentry.observeDeep(updateLiveBlocks);

            return () => {
                state.value.yentry.unobserveDeep(updateLiveBlocks);
            };
        }

        return;
    }, [reference, state]);

    return state;
};

const LiveBlocksNotFound = (props: { elementProps: ElementProps }) => {
    const slateSelected = useSelected();
    const editor = useSlateStatic();
    const path = ReactEditor.findPath(editor, props.elementProps.element);

    const isSelected = slateSelected;

    return (
        <div
            className={classNames(
                'w-full h-20 rounded border-dashed border border-red-700 flex items-center justify-center text-red-700',
                {
                    'shadow-lightblue': isSelected,
                },
            )}
            id={props.elementProps.element.id}
            contentEditable={false}
        >
            <VoidSelectionShadow path={path}>
                <div className="flex justify-center items-center space-x-1 h-20 text-red-700">
                    <div className="flex space-x-2">
                        <AlertTriangle />
                        <div>This Live Block does not exist anymore.</div>
                    </div>
                </div>
            </VoidSelectionShadow>
            <div className="hidden select-none">{props.elementProps.children}</div>
        </div>
    );
};

const LiveBlocksFound = (props: { state: LiveBlocksFoundType; elementProps: ElementProps }) => {
    const editor = useSlateStatic();
    const { location, canEdit, blockPlugins } = SagaEditor.useEditorContext();
    const { selected, element } = props.elementProps;
    const path = ReactEditor.findPath(editor, element);

    const { space } = useSpace();

    const focused = useFocused();
    const { urlKey } = useCurrentWorkspace();

    const setQuickEditPageId = useSetQuickEditModalPage();
    const openPage = useOpenPage();

    const staticDecorate = useStaticDecorate(location);

    const { isArchived, liveBlocks, yentry } = props.state;

    const pageId = yentry.get('pageId') as string;
    const isPageRef = liveBlocks.some((b) => isSagaElement(b) && isTitle(b));

    const targetPageIsCurrentPage = SagaLocation.isPageLocation(location) && pageId === location.pageId;
    const canQuickEdit = canEdit && !targetPageIsCurrentPage;

    const page = React.useMemo(() => SpaceOperations.getPageById(space, pageId, pageKeys), [space, pageId]);

    const pageTitle = isPageRef
        ? isTitle(liveBlocks[0]) && EditorOperations.SagaElement.toString(liveBlocks[0], blockPlugins ?? [])
        : page?.title;

    const onClick = (event: React.MouseEvent, blockId?: string) => {
        openPage(pageId, event, { blockId });
    };

    const deleteReference = React.useCallback(
        (node: SagaElement) => {
            const path = ReactEditor.findPath(editor, node);
            Transforms.select(editor, path);
            Transforms.removeNodes(editor, { at: path });
        },
        [editor],
    );

    useEffect(() => {
        const onCopy = (event: ClipboardEvent) => {
            event.preventDefault();
            const clipboardData = event.clipboardData?.getData('application/x-saga');
            if (clipboardData && clipboardData.includes(props.elementProps.element.id)) return;

            SagaEditor.Clipboard.copyBlocks([props.elementProps.element], {
                location: { ...location, blockId: props.elementProps.element.id },
                spaceUrlKey: urlKey,
                event,
                action: 'copy',
                blockPlugins,
            });
        };

        if (focused && selected) {
            document.addEventListener('copy', onCopy);
        }

        return () => {
            document.removeEventListener('copy', onCopy);
        };
    }, [props.elementProps.element, , focused, selected, blockPlugins, location, urlKey]);

    return (
        <div
            className={classNames('live-block rounded my-1 shadow-sm', {
                'shadow-lightblue': selected,
            })}
            contentEditable={false}
            id={props.elementProps.element.id}
        >
            <VoidSelectionShadow path={path}>
                <Card>
                    <div className="px-2 py-2 relative" aria-label={page?.title ?? 'Untitled'}>
                        <div
                            data-testid="live-block-popover"
                            className={classNames(
                                'live-block-hover:flex absolute -top-10 left-0 z-30 bg-transparent h-10',
                                {
                                    hidden: !selected,
                                    flex: selected,
                                },
                            )}
                        >
                            <div className="flex shadow-popupSmall divide-x bg-white dark:bg-zinc-700 divide-saga-gray-150 dark:divide-gray-800 rounded overflow-hidden h-8">
                                {!isPageRef && page && (
                                    <Tooltip content="Go to the Page's block" placement="top">
                                        <button
                                            className="p-1 pr-2 min-w-0 select-none flex items-center hover:text-saga-gray-500 dark:hover:text-zinc-200 dark:hover:bg-zinc-600 cursor-pointer"
                                            onClick={(event) =>
                                                onClick(
                                                    event,
                                                    liveBlocks.length > 0 && isSagaElement(liveBlocks[0])
                                                        ? liveBlocks[0].id
                                                        : undefined,
                                                )
                                            }
                                        >
                                            <PageIcon icon={page.icon} size={16} isTemplate={page.isTemplate} />
                                            <p className="ml-2 text-base font-semibold truncate min-w-0">
                                                {page.title}
                                            </p>
                                        </button>
                                    </Tooltip>
                                )}
                                {isArchived && (
                                    <Tooltip
                                        content="This reference's original content does not exist anymore"
                                        placement="top"
                                    >
                                        <button className="text-sm p-2 focus:outline-none">
                                            <span className="sr-only">
                                                {"This reference's original content does not exist anymore"}
                                            </span>
                                            <AlertTriangle className="stroke-saga-red flex-none" size={16} />
                                        </button>
                                    </Tooltip>
                                )}
                                {canQuickEdit &&
                                    setQuickEditPageId &&
                                    page &&
                                    SagaLocation.isPageLocation(location) &&
                                    pageId !== location.pageId && (
                                        <Tooltip content={'Quick edit source Page'} placement="top">
                                            <button
                                                className="cursor-pointer p-2 hover:bg-saga-gray-200 dark:hover:bg-zinc-600 focus:outline-none active:bg-saga-gray-200 dark:active:bg-zinc-600 active:shadow-xs"
                                                onClick={() => {
                                                    track('open-quick-edit', { source: 'ref-block' });
                                                    setQuickEditPageId(pageId);
                                                }}
                                            >
                                                <span className="sr-only">Quick edit source Page</span>
                                                <Edit2
                                                    className="text-saga-gray-500 dark:text-zinc-200 flex-none"
                                                    size={16}
                                                />
                                            </button>
                                        </Tooltip>
                                    )}
                                {canEdit && (
                                    <Tooltip content={'Remove this Reference'} placement="top">
                                        <button
                                            className="cursor-pointer rounded-r p-2 hover:bg-saga-gray-200 dark:hover:bg-zinc-600 focus:outline-none active:bg-saga-gray-200 dark:active:bg-zinc-600 active:shadow-xs "
                                            onClick={() => {
                                                track('delete-reference');
                                                deleteReference(props.elementProps.element);
                                            }}
                                        >
                                            <span className="sr-only">Remove this Live Block</span>
                                            <Trash
                                                className="text-saga-gray-500 dark:text-zinc-200 flex-none"
                                                size={16}
                                            />
                                        </button>
                                    </Tooltip>
                                )}
                            </div>
                        </div>
                        <div className="overflow-hidden relative">
                            {isPageRef && (
                                <div className="flex flex-row">
                                    <div
                                        className={classNames('flex items-center py-1 flex-shrink-0', {
                                            'hover:text-saga-gray-500 hover:stroke-saga-gray-500 cursor-pointer':
                                                !isArchived,
                                        })}
                                        onClick={(event) => onClick(event)}
                                    >
                                        <PageIcon icon={page?.icon} size={16} isTemplate={page?.isTemplate} />
                                        <p className="ml-2 text-base font-semibold">{pageTitle}</p>
                                    </div>
                                </div>
                            )}
                            {!isPageRef && (
                                <StaticEditor
                                    blocks={liveBlocks}
                                    decorate={staticDecorate}
                                    className="transform"
                                    location={SagaLocation.pageLocationFromId(pageId)}
                                    blockPlugins={blockPlugins}
                                />
                            )}
                        </div>
                    </div>
                    <div className="hidden select-none">{props.elementProps.children}</div>
                </Card>
            </VoidSelectionShadow>
        </div>
    );
};

export const spaceLiveBlockPlugin = SagaEditor.Plugins.createBlockPlugin({
    match: isLiveBlock,
    Component(props) {
        const state = useLiveBlockState(props.element.reference);

        switch (state._tag) {
            case 'LiveBlocksNotFound':
                return <LiveBlocksNotFound elementProps={props} />;
            case 'LiveBlocksFound':
                return <LiveBlocksFound state={state.value} elementProps={props} />;
        }
    },
});
