import { CreatePageSuggestion, PageSuggestion } from '@/types';
import React from 'react';
import { filterSuggestions } from './Dropdown';
import { Props as PagesCollectionsPopOverProps, appendCreatePageSuggestion } from './PagesCollectionsPopOver';
import { useTranslation } from 'react-i18next';
import LinkToPageDropDown from './LinkToPageDropDown';

type Props = {
    popoverProps: Omit<
        PagesCollectionsPopOverProps<PageSuggestion, PageSuggestion | CreatePageSuggestion>,
        'filterBySearch' | 'inputProps' | 'attachToRef' | 'label'
    >;
    zIndex?: number;
    onOpen?: React.MouseEventHandler<HTMLDivElement>;
    attachToRef: React.RefObject<HTMLDivElement>;
};

const LinkToPagePopOver: React.FC<Props> = ({ popoverProps, attachToRef }) => {
    const { dropdownProps, ...rest } = popoverProps;
    const { t } = useTranslation();

    return (
        <LinkToPageDropDown
            attachToRef={attachToRef}
            filterBySearch={(currentSuggestions, search) => {
                return appendCreatePageSuggestion(filterSuggestions(currentSuggestions, search), search);
            }}
            inputProps={{
                placeholder: `${t('pages.enter_page_name')}`,
                title: `${t('collections.select_create_new')}`,
            }}
            dropdownProps={{
                maxWHighlight: true,
                ...dropdownProps,
            }}
            {...rest}
            label={t('collections.select_create_new')}
        />
    );
};

export default LinkToPagePopOver;
