import { useCurrentWorkspace } from '@/components/WorkspaceContext';
import { BlockBuilder, isPrettyLink, SagaEditor } from '@saga/shared';
import classNames from 'classnames';
import React, { useEffect } from 'react';
import { Editor as SlateEditor, Transforms } from 'slate';
import { useFocused } from 'slate-react';
import VoidSelectionShadow from '../../VoidSelectionShadow';
import { ContentLink, ErrorState, PrettyLinkViewMode } from '../PrettyLink';

function createPrettyLinkBlockPlugin(viewMode: PrettyLinkViewMode = 'default') {
    return SagaEditor.Plugins.createBlockPlugin({
        match: isPrettyLink,
        Component({ selected, element, path, children, editor, blockPlugins }) {
            const { canEdit, location } = SagaEditor.useEditorContext();
            const { urlKey } = useCurrentWorkspace();

            const focused = useFocused();

            useEffect(() => {
                const onCopy = (event: ClipboardEvent) => {
                    event.preventDefault();
                    const clipboardData = event.clipboardData?.getData('application/x-saga');
                    if (clipboardData && clipboardData.includes(element.id)) return;

                    SagaEditor.Clipboard.copyBlocks([element], {
                        location: { ...location, blockId: element.id },
                        spaceUrlKey: urlKey,
                        event,
                        action: 'copy',
                        blockPlugins,
                    });
                };

                if (focused && selected) {
                    document.addEventListener('copy', onCopy);
                }

                return () => {
                    document.removeEventListener('copy', onCopy);
                };
            }, [element, focused, selected, blockPlugins, location, urlKey]);

            const onImageLoadingError = () => {
                if (element.metadata._tag !== 'loaded') return;

                const { image, ...rest } = element.metadata;
                // makes eslint happy
                image;

                Transforms.setNodes(
                    editor,
                    {
                        metadata: rest,
                    },
                    {
                        at: path,
                    },
                );
            };

            const onTurnIntoLink = () => {
                const linkText = (() => {
                    if ('title' in element.metadata) {
                        return element.metadata.title;
                    } else {
                        return element.url;
                    }
                })();

                const range = SlateEditor.range(editor, path);

                const link = BlockBuilder.paragraph([BlockBuilder.link(element.url, [BlockBuilder.text(linkText)])]);

                Transforms.insertNodes(editor, link, {
                    at: range,
                });

                setTimeout(() => {
                    Transforms.removeNodes(editor);
                });
            };

            return (
                <div
                    className={classNames(
                        'group my-1 rounded shadow-sm hover:shadow-refHover bg-white dark:bg-saga-gray-1000',
                        {
                            'shadow-lightblue': selected,
                        },
                    )}
                    contentEditable={false}
                    id={element.id}
                >
                    <VoidSelectionShadow path={path}>
                        <ContentLink
                            metadata={element.metadata}
                            onImageLoadingError={onImageLoadingError}
                            url={element.url}
                            errorState={
                                <ErrorState canEdit={canEdit} selected={selected} turnIntoLink={onTurnIntoLink} />
                            }
                            viewMode={viewMode}
                        />
                        <div className="hidden select-none">{children}</div>
                    </VoidSelectionShadow>
                </div>
            );
        },
    });
}

export const spaceCompactPrettyLinkBlockPlugin = createPrettyLinkBlockPlugin('compact');
export const spacePrettyLinkBlockPlugin = createPrettyLinkBlockPlugin('default');
