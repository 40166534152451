import React from 'react';
import { useTranslation } from 'react-i18next';

import TableMultipleFilterSelect, { ItemsFilterMode } from './TableMultipleFilterSelect';
import MemberAvatar from '../MemberAvatar';
import { Member } from '@saga/api';
import { stringToColor } from '@/../../shared/src/utils/Colors';
import { AVAILABLE_COLORS } from '@/constants';
import { UserPlus } from 'react-feather';

export default function CreatorsFilterSelect({
    selectedIds,
    onUpdateSelectedIds,
    availableCreators,
}: {
    selectedIds: ItemsFilterMode;
    onUpdateSelectedIds(ids: ItemsFilterMode): void;
    availableCreators: Pick<Member, 'id' | 'firstName' | 'lastName'>[];
}) {
    const { t } = useTranslation();

    const availableItems = React.useMemo(() => {
        return availableCreators.map((creator) => {
            const fullName = `${creator.firstName} ${creator.lastName}`;
            return {
                id: creator.id,
                title: fullName,
                icon: <MemberAvatar name={fullName} color={stringToColor(creator.id, AVAILABLE_COLORS)} size="sm" />,
            };
        });
    }, [availableCreators]);

    return (
        <TableMultipleFilterSelect
            selectedIds={selectedIds}
            onUpdateSelectedIds={onUpdateSelectedIds}
            availableItems={availableItems}
            selectTexts={{
                default: t('pages.headers.creator'),
                all: t('filters.creators.all_creators'),
                none: t('filters.creators.no_creators'),
                n_items: t('filters.creators.n_creators', { count: selectedIds.length }),
                search_item_placeholder: t('filters.creators.search_creator_placeholder'),
            }}
            Icon={<UserPlus size={14} />}
        />
    );
}
