import React, { useState, useRef, MouseEventHandler, SyntheticEvent, useEffect } from 'react';
import { CreatePageSuggestion, CreateSuggestion, ShareSuggestion } from '@/types';
import { RenderItemProps, SelectDropdown } from './Dropdown';
import { CreateSuggestionSelectButton } from '../editor/Suggestions';
import { track } from '@/analytics';
import { newBlankPage } from '@/../../shared/src';
import { useOpenPage } from '../PageNavigationProvider';
import { useSpace } from '../SpaceProvider';
import { ButtonWithChevronOption } from './AddToCollectionButton';
import { createPage } from '@/utils/documentUtils';

const CreateButtonWithSelect = ({
    children,
    placeholder,
    onCreate,
    selectOptions,
    icon,
}: {
    children: string;
    placeholder: string;
    onCreate: MouseEventHandler<HTMLButtonElement> | undefined;
    selectOptions: CreatePageSuggestion[];
    icon: any;
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isSelected, setIsSelected] = useState(false);
    const elementRef = useRef<HTMLDivElement>(null);
    const openPage = useOpenPage();
    const { space, provider } = useSpace();

    const openDropdown = (
        item: { title: string; type: string; kind: string; isTemplate?: boolean },
        event: Event | SyntheticEvent<Element, Event>,
    ) => {
        if (item.isTemplate) {
            const template = createPage(space, newBlankPage({ title: '', isTemplate: true }), provider);
            track(`create-${item.kind}`);
            return openPage(template.id, event);
        }
        const page = createPage(space, newBlankPage({}), provider);
        track(`create-${item.kind}`);
        return openPage(page.id, event);
    };

    useEffect(() => {
        function handleClickOutside(event: Event) {
            if (elementRef.current && !elementRef.current.contains(event.target as Node)) {
                setIsSelected(false);
            }
        }

        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    return (
        <>
            <div ref={elementRef}>
                <ButtonWithChevronOption
                    onClick={onCreate}
                    setIsSelected={setIsSelected}
                    setIsOpen={setIsOpen}
                    isSelected={isSelected}
                    icon={icon}
                >
                    {children}
                </ButtonWithChevronOption>
            </div>
            {isOpen && (
                <SelectDropdown<CreateSuggestion>
                    attachToRef={elementRef}
                    isOpen={isOpen}
                    onClose={() => setIsOpen(false)}
                    onSubmit={({ selectedItem, event }) => openDropdown(selectedItem, event)}
                    inputProps={{
                        placeholder,
                        title: placeholder,
                    }}
                    renderItems={() => {
                        return selectOptions.map((item: ShareSuggestion | CreatePageSuggestion) => {
                            return {
                                render({ isSelected, setSelection, submit, index }: RenderItemProps) {
                                    return (
                                        <div className="min-w-0" key={index}>
                                            <CreateSuggestionSelectButton
                                                suggestion={item}
                                                selected={isSelected}
                                                onMouseEnter={setSelection}
                                                onClick={submit}
                                                showIcons={true}
                                            >
                                                {item.title}
                                            </CreateSuggestionSelectButton>
                                        </div>
                                    );
                                },
                                item,
                            };
                        });
                    }}
                />
            )}
        </>
    );
};

export default CreateButtonWithSelect;
