import { BlockBuilder } from '../..';
import { newBlankPage } from '../../pageUtils';

export function newSagaAiPageIt(collectionIds: string[]) {
    return newBlankPage({
        title: 'Saga IA',
        icon: {
            type: 'emoji',
            colons: ':sparkles:',
        },
        collections: collectionIds,
        blocks: [
            BlockBuilder.title('Saga IA'),
            BlockBuilder.headingThree('Genera contenuti e ottieni risposte con AI'),
            BlockBuilder.image('https://saga-public.s3.amazonaws.com/1-ai-assistant-generating-popover-launch.webp'),
            BlockBuilder.checkListItem([BlockBuilder.text('Crea una bozza utilizzando AI.')]),
            BlockBuilder.listItem([
                BlockBuilder.text('Clicca sul '),
                BlockBuilder.text('AI', { code: true }),
                BlockBuilder.text(' pulsante all\'interno dell\'editor.'),
            ]),
            BlockBuilder.listItem([
                BlockBuilder.text('In alternativa, premi '),
                BlockBuilder.text('@', { code: true }),
                BlockBuilder.text(' o '),
                BlockBuilder.text('/', { code: true }),
                BlockBuilder.text(' e seleziona Saga IA dal menu di completamento automatico.'),
            ]),
            BlockBuilder.paragraph(),
            BlockBuilder.paragraph(),
            BlockBuilder.image('https://saga-public.s3.amazonaws.com/2-ai-assistant-select-or-type-prompt.webp'),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Seleziona un\'opzione dall\'elenco, o scrivi il tuo prompt.'),
            ]),
            BlockBuilder.paragraph(),
            BlockBuilder.paragraph(),
            BlockBuilder.image('https://saga-public.s3.amazonaws.com/3-ai-assistant-blog-post-generation.webp'),
            BlockBuilder.checkListItem([
                BlockBuilder.text(
                    'Itera sul risultato, aggiungi più dettagli e vedi la cronologia della tua conversazione con AI.',
                ),
            ]),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Salva il tuo prompt per riutilizzarlo più tardi premendo il pulsante ☆.'),
            ]),
            BlockBuilder.paragraph(),
            BlockBuilder.paragraph(),
            BlockBuilder.headingThree('Modifica con AI'),
            BlockBuilder.image('https://saga-public.s3.amazonaws.com/4-ai-assistant-commands-pallette.webp'),
            BlockBuilder.checkListItem([
                BlockBuilder.text(
                    'Chiedi a Saga IA di riscrivere il tuo testo, correggere la grammatica, renderlo più breve, cambiare il tono di voce, tradurre e altro ancora.',
                ),
            ]),
            // todo - indent
            BlockBuilder.listItem([
                BlockBuilder.text('Seleziona qualsiasi testo e clicca su "Saga IA" nella barra degli strumenti per aprire il menu AI.'),
            ]),
            BlockBuilder.listItem([BlockBuilder.text('Seleziona un\'opzione dall\'elenco, o scrivi il tuo prompt.')]),
            BlockBuilder.paragraph(),
            BlockBuilder.paragraph(),
            BlockBuilder.image('https://saga-public.s3.amazonaws.com/5-ai-assistant-edit-the-text.webp'),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Chiedi a Saga IA cosa fare dopo con la risposta, aggiungi dettagli e itera.'),
            ]),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Sostituisci il contenuto iniziale con la risposta di AI o inseriscila sotto.'),
            ]),
            BlockBuilder.paragraph(),
            BlockBuilder.paragraph(),
            BlockBuilder.divider(),
            BlockBuilder.headingThree('Guide e FAQ'),
            BlockBuilder.paragraph([
                BlockBuilder.text(
                    'Visita le guide di Saga IA per altri consigli e trucchi sull\'uso di IA all\'interno del tuo spazio di lavoro o esplora 👋 Nozioni di base su Saga per continuare ad imparare.',
                ),
            ]),
            BlockBuilder.rawPrettyLink('https://saga.so/guides-category/saga-ai', {
                _tag: 'loaded',
                description:
                    'Impara ad usare Saga IA - l\'assistente alimentato da AI all\'interno del tuo spazio di lavoro. Diventa creativo e più produttivo con un assistente AI.',
                title: '📍 Saga IA – Guide di Saga',
                logo: 'https://saga.so/thumbnail.png',
            }),
            BlockBuilder.paragraph(),
        ],
    });
}
