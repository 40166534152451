import * as Y from 'yjs';
import { WeakTask } from '../types';
import { SafeSpace } from '../types';
import { unsafeRight } from '../io-ts-js';
import { findYArrayIndex } from '../yjs-utils';

export default function findTaskMap(space: SafeSpace, id: string): Y.Map<any> | null {
    const yTasks = unsafeRight(space.get('tasks'));

    const index = findYArrayIndex(yTasks.array as Y.Array<any>, (a) => {
        return a.get('id') === id;
    });

    if (index === -1) return null;

    const yTask = unsafeRight(yTasks.get(index));
    return yTask.map;
}
