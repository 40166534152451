import { SafeSpace } from '../types';
import * as Y from 'yjs';

export default function migrateSpace(space: SafeSpace) {
    const spaceMap = space.map;

    // Initialize the referencesRegistry in the document in case it was never initialized
    // (e.g. in db spaces)
    if (spaceMap.get('referencesRegistry') == null) {
        spaceMap.set('referencesRegistry', new Y.Array());
    }

    // Initialize the tasks in the document in case they never were set
    if (spaceMap.get('tasks') == null) {
        spaceMap.set('tasks', new Y.Array());
    }
}
