import React from 'react';
import Modal, { ModalLayout } from '../Modal';
import { useTranslation } from 'react-i18next';

export function DesktopModal({ onClose, onConfirm }: { onClose: () => void; onConfirm: () => void }) {
    const { t } = useTranslation();

    return (
        <Modal.Medium isOpen={true}>
            <ModalLayout
                title={t('billing.desktopModal.title')}
                primaryButtonLabel={t('billing.desktopModal.cta')}
                primaryButtonAction={onConfirm}
                onCloseAction={onClose}
            >
                <p className="mb-6">{t('billing.desktopModal.description')}</p>
            </ModalLayout>
        </Modal.Medium>
    );
}
