import classNames from 'classnames';
import React from 'react';

function Panel({ testId, children, styles }: { testId?: string; children: React.ReactNode; styles?: boolean }) {
    return (
        <div
            data-testid={testId}
            className={classNames('min-h-0 h-full w-full flex flex-col hide-scrollbar overflow-auto pb-24', {
                'space-y-0': styles,
                'space-y-1': !styles,
            })}
        >
            {children}
        </div>
    );
}

Panel.Header = function PanelHeader({ children, padding }: { children: React.ReactNode; padding?: boolean }) {
    return (
        <div className={classNames('text-saga-gray-dark dark:text-zinc-200 w-full', { 'px-3': padding })}>
            {children}
        </div>
    );
};

Panel.Body = function PanelBody({ children }: { children: React.ReactNode }) {
    return <div>{children}</div>;
};

Panel.Title = function PanelTitle({ children }: { children: React.ReactNode }) {
    return <h2 className="flex-shrink-0 text-base font-medium">{children}</h2>;
};

export default Panel;
