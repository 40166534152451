import { isHeadingOne, isParagraph, isSagaText, mapBlocks, Root, SagaElement, SagaText } from '../types';
import { MdastNode } from './types';
import * as R from 'ramda';
import deserialize from './deserialize';
import { BlockBuilder, isInline } from '..';
import type { Plugin } from 'unified';

export type MdastToSagaOpts = {
    transform?: <N extends SagaElement | SagaText>(node: N) => N;
    addTitle?: boolean;
    fileTitle?: string;
};

function extractContent(input: string): string {
    return input.replace(/^<aside>\n/, '').trim();
}

function extractCalloutContentFromNotionMD(nodes: MdastNode[], startTag: string, endTag: string): MdastNode[] {
    const startIndex = nodes.findIndex((node: MdastNode) => node.type === 'html' && node?.value?.includes(startTag));
    const endIndex = nodes.findIndex((node: MdastNode) => node.type === 'html' && node?.value?.includes(endTag));

    if (startIndex === -1 || endIndex === -1 || endIndex < startIndex) {
        return nodes;
    }

    // Extract content between <aside> tags
    let extractedNodes = nodes.slice(startIndex + 1, endIndex);

    // If there are no extracted nodes, it means the node contains inline content (like <aside>\nsomeText)
    if (extractedNodes.length === 0) {
        const startNode = nodes[startIndex];
        const inlineContent = extractContent(startNode.value as string);
        extractedNodes = [{ type: 'paragraph', children: [{ type: 'text', value: inlineContent }] }];
    }

    const calloutObject = {
        type: 'callout',
        value: extractedNodes,
    };

    // Create a new nodes array with the callout object inserted
    const newNodes = [
        ...nodes.slice(0, startIndex), // Nodes before <aside>
        calloutObject, // Insert the callout object
        ...nodes.slice(endIndex + 1), // Nodes after </aside>
    ];

    // Recursively process remaining nodes
    return extractCalloutContentFromNotionMD(newNodes, startTag, endTag);
}

export const mdastToSaga = (opts?: MdastToSagaOpts) => {
    return (node: MdastNode): Root => {
        let children = node.children;

        if (Array.isArray(children)) {
            //callout from notion and our .md files comes in <aside> tag
            const nodeContent = extractCalloutContentFromNotionMD(children, '<aside>', '</aside>');

            let deserializedChildren = R.flatten(nodeContent.map((c: MdastNode) => deserialize(c, opts)));

            if (opts?.addTitle) {
                const firstChild = deserializedChildren[0];
                if (isHeadingOne(firstChild)) {
                    deserializedChildren[0] = BlockBuilder.title(
                        //@ts-expect-error in this case, we allow the heading children since the title will be cleaned by the normalizers
                        firstChild.children,
                    );
                } else if (opts?.fileTitle) {
                    deserializedChildren.unshift(BlockBuilder.title(opts.fileTitle));
                } else {
                    deserializedChildren.unshift(BlockBuilder.title(''));
                }
            }

            deserializedChildren = mapBlocks(deserializedChildren, (block) => {
                if (isSagaText(block) && block.text === '---') {
                    return BlockBuilder.divider();
                } else if (isSagaText(block)) {
                    const nodeCopy = { ...block };
                    // @ts-expect-error
                    delete nodeCopy.type;
                    return nodeCopy;
                } else {
                    return block;
                }
            });

            // We now want to put all adjacent top inlines into a paragraph
            deserializedChildren = R.groupWith((a, b) => isInline(a) && isInline(b), deserializedChildren)
                .map((group) => {
                    if (group.every(isInline)) {
                        return BlockBuilder.paragraph(group);
                    }
                    return group;
                })
                .flat();

            // Here we remove any break paragraph that was inserted by the deserialize function
            // This allows us to turn any text followed by a break into paragraphs
            // e.g.
            // text 1
            // break
            // text 2
            //
            // results in
            //
            // paragraph text 1
            // paragraph text 2
            deserializedChildren = mapBlocks(deserializedChildren, (block) => {
                if (isParagraph(block) && block.break) {
                    return [];
                }

                return block;
            });

            return BlockBuilder.root(deserializedChildren);
        }

        return BlockBuilder.root([]);
    };
};

export const mdastToSagaPlugin: Plugin<[MdastToSagaOpts | undefined]> = mdastToSaga;
