import { unsafeRight } from '../io-ts-js';
import transactInSpace from './transactInSpace';
import { SafeSpace } from '../types';
import { findYArrayIndex } from '../yjs-utils';

export default function movePinned(
    space: SafeSpace,
    sourceId: string,
    { targetId, position }: { targetId: string; position: 'before' | 'after' },
) {
    const yPinned = unsafeRight(space.get('pinned')).array;

    transactInSpace(space, () => {
        const pinned = yPinned.toArray();
        const set = new Set(pinned);
        const dedupedArray = Array.from(set);

        // We need to dedpulicate the array just to make sure we don't have any duplicates in the array,
        // otherwise we would get wrong results for the move operation
        // in practice, this should not happen, because it is checked before adding a pinned item
        // but it is better to deduplicate the array so that the operation works correctly
        if (dedupedArray.length !== pinned.length) {
            yPinned.delete(0, pinned.length);
            yPinned.insert(0, dedupedArray);
        }
        const fromIndex = findYArrayIndex(yPinned, (id) => id === sourceId);

        if (fromIndex === -1) {
            return;
        }

        yPinned.delete(fromIndex);

        const toIndex = findYArrayIndex(yPinned, (id) => id === targetId);

        if (toIndex === -1) {
            return;
        }

        const targetIndex = position === 'before' ? toIndex : toIndex + 1;
        yPinned.insert(targetIndex, [sourceId]);
    });
}
