import React, { useEffect } from 'react';
import {
    NavigationProvider,
    useLocationNavigation,
    locationToUrl,
    OpenLocationOptions,
} from '@/components/PageNavigationProvider';
import Button from '@/components/styled/Button';
import { useHistory, useLocation } from 'react-router-dom';
import { useCurrentWorkspace } from '@/components/WorkspaceContext';
import { useSpaceAccess } from '@/hooks/useSpaceAccess';
import { SearchContextProvider, SearchModalContainer } from '@/components/search/SearchContext';
import NavigationContainer from './NavigationContainer';
import { ExternalLink, ChevronLeft, Copy } from 'react-feather';
import { track } from '@/analytics';
import HighlightsProvider from '@/components/HighlightsProvider';
import SideBySidePanes, { SideBySideSize } from './SideBySidePanes';
import { SagaLocation, ensureNonNull } from '@saga/shared';
import { useRecents } from '@/components/RecentsContext';
import * as R from 'ramda';
import { nanoid } from 'nanoid';
import TemplateProvider from '@/components/templates/TemplatesProvider';
import CollectionTable from '@/components/table/CollectionTable';
import { useIsOnline } from './BrowserContextProvider';
import { CloudOffline } from '@/components/icons';
import AllCollectionsTable from '@/components/table/AllCollectionsTable';
import { EmbedContainer } from './EmbedContainer';
import { openWindow } from '@/utils';
import AllPagesTable from '@/components/table/AllPagesTable';
import { TaskPane } from '@/components/tasks/TaskPane';
import AllTasksTable from '@/components/table/AllTasksTable';
import PagePane from './PagePane';
import WorkspaceAvatar from './navigation/WorkspaceAvatar';
import QuickEditPageContainer from './QuickEditPageContainer';
import NavigationTable from './table/NavigationTable';
import { SearchBar as HeaderSearchBar } from '@/components/navigation/HeaderBar';
import Dropdown from './popover/Dropdown';
import ContextMenuButton from './styled/ContextMenuButton';
import { PopOver } from './popover/PopOver';
import { useTranslation } from 'react-i18next';
import { SharedPagesProvider } from '@/components/SharedPagesProvider';
import { useSetSettings } from './settings/SettingsProvider';
import { AIChatContextProvider } from '@/components/aichat/AIChatContext';
import { SidePanel } from '@/components/SidePanel';
import { AIChatPane } from './aichat/AIChatPane';

function EditorSelectionIndicator() {
    return <div className="z-100 absolute -inset-x-3 inset-y-0 bg-saga-blue-light/50 animate-pulse" />;
}

function OfflineIndicator() {
    return (
        <div
            data-testid="offline-indicator"
            className="pointer-events-auto rounded-full bg-saga-new-orange text-white text-sm font-bold flex space-x-1 py-2 px-3 items-center"
        >
            <CloudOffline />
            <span>You&apos;re offline</span>
        </div>
    );
}

OfflineIndicator.Container = function OfflineIndicatorContainer({ children }: { children: React.ReactNode }) {
    return (
        <div className="absolute z-100 bottom-5 w-full flex items-center justify-center pointer-events-none">
            {children}
        </div>
    );
};

function PaneContainer({
    children,
    index,
    isSingle,
    pane,
}: {
    children: React.ReactNode;
    index: number;
    isSingle: boolean;
    pane: Pane;
}) {
    const { highlightedIndex } = useSideBySideState();
    const { setHighlightedIndex, openLocation } = useSideBySideApi();
    const container = React.useRef<HTMLDivElement>(null);

    return (
        <SideBySideIndexContext.Provider value={index}>
            <NavigationProvider
                openLocation={(location, event, options) => {
                    openLocation(location, event, options, index);
                }}
            >
                <SearchContextProvider
                    onChange={(isOpen) => {
                        if (isSingle) return;
                        if (isOpen) {
                            setHighlightedIndex(index);
                        } else {
                            setHighlightedIndex(null);
                        }
                    }}
                    scopeToContainer={container}
                >
                    <SearchModalContainer currentLocation={pane.location} />
                    <div ref={container} className="h-full flex flex-col relative">
                        <QuickEditPageContainer>
                            <HighlightsProvider location={pane.location}>
                                {children}
                                {highlightedIndex === index && <EditorSelectionIndicator />}
                            </HighlightsProvider>
                        </QuickEditPageContainer>
                    </div>
                </SearchContextProvider>
            </NavigationProvider>
        </SideBySideIndexContext.Provider>
    );
}

function EmbedContextMenuButton({
    align,
    isButtonSmall,
    location,
}: {
    align?: 'left' | 'right' | 'center';
    isButtonSmall?: boolean;
    location: { type: string; openUrl: string | undefined; embedUrl: string };
}) {
    const buttonRef = React.useRef<HTMLButtonElement>(null);
    const [isOpen, setIsOpen] = React.useState(false);
    const onClose = () => setIsOpen(false);
    const { t } = useTranslation();

    const openInNewTab = () => {
        track('open-embed-url-in-new-tab');

        openWindow(location.openUrl ?? location.embedUrl, '_blank', 'noopener noreferrer');
    };

    function copyToClipboard() {
        navigator.clipboard.writeText(location.embedUrl);

        track('copy-link embed-page');
    }

    return (
        <>
            <ContextMenuButton
                ref={buttonRef}
                isOpen={isOpen}
                onClick={() => {
                    setIsOpen((isOpen) => !isOpen);
                    if (isOpen) track('open-embed-context-menu');
                    else track('close-embed-context-menu');
                }}
                isButtonSmall={Boolean(isButtonSmall)}
            />

            <Dropdown
                testId={isOpen ? 'embed-context-menu' : undefined}
                isOpen={isOpen}
                onClose={onClose}
                attachToRef={buttonRef}
                align={align}
            >
                <div className="space-y-0.5">
                    <PopOver.RoundedButton
                        onClick={() => {
                            openInNewTab();
                            onClose();
                        }}
                        aria-label={t('side_by_side.open_in_new_tab') as string}
                    >
                        <ExternalLink className="stroke-gray-dark mr-2 my-auto" size={16} />
                        {t('side_by_side.open_in_new_tab')}
                    </PopOver.RoundedButton>
                    <PopOver.RoundedButton
                        onClick={() => {
                            copyToClipboard();
                            onClose();
                        }}
                        aria-label={t('side_by_side.copy_link') as string}
                    >
                        <Copy className="stroke-gray-dark mr-2 my-auto" size={16} />
                        {t('side_by_side.copy_link')}
                    </PopOver.RoundedButton>
                </div>
            </Dropdown>
        </>
    );
}

function SideBySide({ location }: { location: SagaLocation.SagaLocation }) {
    const isOnline = useIsOnline();
    const { panes } = useSideBySideState();
    const { closeByIndex, setHighlightedIndex, switchPanes } = useSideBySideApi();
    const history = useHistory();

    const renderPanes = panes.map((pane, index) => {
        const isFirst = index === 0;

        return {
            key: pane.nonce,
            padding: pane.location.type !== 'embed',
            location: pane.location,
            render(size: SideBySideSize) {
                switch (pane.location.type) {
                    case 'sagaAI': {
                        return (
                            <PaneContainer pane={pane} index={index} isSingle={panes.length === 1}>
                                <AIChatPane
                                    onClose={() => closeByIndex(index)}
                                    paneIndex={index}
                                    threadId={pane.location.threadId}
                                />
                            </PaneContainer>
                        );
                    }
                    case 'page':
                        return (
                            <PaneContainer pane={pane} index={index} isSingle={panes.length === 1}>
                                <PagePane
                                    size={size}
                                    location={pane.location}
                                    isSingle={panes.length === 1}
                                    paneIndex={index}
                                    onClose={() => closeByIndex(index)}
                                    onHighlight={() => setHighlightedIndex(index)}
                                />
                            </PaneContainer>
                        );
                    case 'task': {
                        return (
                            <PaneContainer pane={pane} index={index} isSingle={panes.length === 1}>
                                <TaskPane
                                    size={size}
                                    location={pane.location}
                                    onClose={panes.length > 1 ? () => closeByIndex(index) : undefined}
                                />
                            </PaneContainer>
                        );
                    }
                    case 'collection':
                        return (
                            <PaneContainer pane={pane} index={index} isSingle={panes.length === 1}>
                                {isFirst && (
                                    <WorkspaceAvatar.AbsoluteContainer>
                                        <WorkspaceAvatar.SideBySide />
                                    </WorkspaceAvatar.AbsoluteContainer>
                                )}
                                <CollectionTable
                                    collectionId={pane.location.collectionId}
                                    onClose={panes.length > 1 ? () => closeByIndex(index) : undefined}
                                />
                            </PaneContainer>
                        );
                    case 'allCollections': {
                        return (
                            <PaneContainer pane={pane} index={index} isSingle={panes.length === 1}>
                                {isFirst && (
                                    <WorkspaceAvatar.AbsoluteContainer>
                                        <WorkspaceAvatar.SideBySide />
                                    </WorkspaceAvatar.AbsoluteContainer>
                                )}
                                <AllCollectionsTable
                                    onClose={panes.length > 1 ? () => closeByIndex(index) : undefined}
                                />
                            </PaneContainer>
                        );
                    }
                    case 'allTasks': {
                        return (
                            <PaneContainer pane={pane} index={index} isSingle={panes.length === 1}>
                                {isFirst && (
                                    <WorkspaceAvatar.AbsoluteContainer>
                                        <WorkspaceAvatar.SideBySide />
                                    </WorkspaceAvatar.AbsoluteContainer>
                                )}

                                <AllTasksTable
                                    location={pane.location}
                                    onClose={panes.length > 1 ? () => closeByIndex(index) : undefined}
                                />
                            </PaneContainer>
                        );
                    }
                    case 'allPages': {
                        return (
                            <PaneContainer pane={pane} index={index} isSingle={panes.length === 1}>
                                {isFirst && (
                                    <WorkspaceAvatar.AbsoluteContainer>
                                        <WorkspaceAvatar.SideBySide />
                                    </WorkspaceAvatar.AbsoluteContainer>
                                )}
                                <AllPagesTable
                                    location={pane.location}
                                    onClose={panes.length > 1 ? () => closeByIndex(index) : undefined}
                                />
                            </PaneContainer>
                        );
                    }

                    case 'embed': {
                        const location = pane.location;
                        return (
                            <PaneContainer pane={pane} index={index} isSingle={panes.length === 1}>
                                <div className="z-30 py-2 pl-2 bg-white dark:bg-saga-gray-1000 w-full flex flex-row justify-between items-center sticky top-0 sm:relative sm:top-auto">
                                    <div className="flex flex-row space-x-3 items-center">
                                        {panes.length === 1 && (
                                            <Button.Plain
                                                onClick={() => {
                                                    track('go-back-from-embed-pane');
                                                    history.goBack();
                                                }}
                                            >
                                                <Button.SmallPadding>
                                                    <div className="flex space-x-2 items-center">
                                                        <ChevronLeft />
                                                    </div>
                                                </Button.SmallPadding>
                                            </Button.Plain>
                                        )}
                                    </div>

                                    {panes.length === 1 && <HeaderSearchBar />}
                                    <div className="flex justify-end items-center text-saga-gray-dark dark:text-zinc-200 space-x-1 pr-3">
                                        <NavigationTable.SearchButton />
                                        <EmbedContextMenuButton location={location} />
                                        {panes.length > 1 && <Button.XButton onClick={() => closeByIndex(index)} />}
                                    </div>
                                </div>
                                <div className="flex flex-col h-full">
                                    <div className="flex-auto">
                                        <EmbedContainer embedUrl={location.embedUrl} />
                                    </div>
                                </div>
                            </PaneContainer>
                        );
                    }
                }
            },
        };
    });

    return (
        <>
            <div className="flex relative h-full w-full">
                <SideBySidePanes switchPanes={isOnline ? switchPanes : undefined} panes={renderPanes} />
                <SidePanel location={location} />
            </div>
            {!isOnline && (
                <OfflineIndicator.Container>
                    <OfflineIndicator />
                </OfflineIndicator.Container>
            )}
        </>
    );
}

type Pane = { location: SagaLocation.SagaLocation; nonce: string };

function makePane(location: SagaLocation.SagaLocation): Pane {
    // The nonce helps us setting a stable key on the pane within the SideBySidePanes component
    return { location, nonce: nanoid() };
}

// using the nonce to check panes equality
// makes it stable in case we try to drag the page with the same pageId into the editor
export function arePanesEqual(paneA: Pane, paneB: Pane) {
    return paneA.nonce === paneB.nonce;
}

type SideBySideState = { highlightedIndex: number | null; panes: Pane[] };
const SideBySideStateContext = React.createContext<SideBySideState | null>(null);

type SideBySideApi = {
    setHighlightedIndex(index: number | null): void;
    openLocationOnIndex(location: SagaLocation.SagaLocation, index: number): void;
    openLocation(
        location: SagaLocation.SagaLocation,
        event: React.SyntheticEvent | Event,
        options: OpenLocationOptions | undefined,
        fromIndex: number,
    ): void;
    closeByIndex(index: number): void;
    switchPanes: () => void;
};

const SideBySideApiContext = React.createContext<SideBySideApi | null>(null);

export const useSideBySideState = () => {
    return ensureNonNull(
        React.useContext(SideBySideStateContext),
        'useSideBySideState must be used inside SideBySideStateContext',
    );
};

export const useNullableSideBySideApi = () => {
    const context = React.useContext(SideBySideApiContext);
    return context;
};

const useSideBySideApi = () => {
    const context = useNullableSideBySideApi();
    if (context === null) {
        throw new Error('useSideBySideApi must be used inside SideBySideApiContext');
    }
    return context;
};

const SideBySideIndexContext = React.createContext<number>(0);

export function useSideBySideIndex() {
    return React.useContext(SideBySideIndexContext);
}

export function useCurrentSideBySideLocation() {
    const { panes } = useSideBySideState();
    const index = useSideBySideIndex();

    return React.useMemo(() => panes[index].location, [panes, index]);
}

export function isSideBySideKey(event: KeyboardEvent | MouseEvent) {
    return event.shiftKey;
}

function isMetaKey(event: KeyboardEvent | MouseEvent) {
    return event.metaKey;
}

function getNativeEvent(event: React.SyntheticEvent | Event) {
    return event instanceof Event ? event : event.nativeEvent;
}

function isSideBySideEvent(event: Event): event is MouseEvent | KeyboardEvent {
    return event instanceof MouseEvent || event instanceof KeyboardEvent;
}

function SideBySideContainer({ location }: { location: SagaLocation.SagaLocation }) {
    const setSettings = useSetSettings();
    const routerLocation = useLocation();

    const [state, setState] = React.useState<SideBySideState>(() => {
        return {
            highlightedIndex: null,
            panes: [makePane(location)],
        };
    });

    const setHighlightedIndex = React.useCallback(
        (highlightedIndex: number | null) => setState((s) => ({ ...s, highlightedIndex })),
        [],
    );

    const history = useHistory();
    const { urlKey } = useCurrentWorkspace();
    const { canEdit } = useSpaceAccess();
    const { addRecentLocation } = useRecents();
    const navigateToLocation = useLocationNavigation();

    const openLocationOnIndex = React.useCallback(
        (location: SagaLocation.SagaLocation, index: number) => {
            setState((s) => {
                if (index > s.panes.length - 1) {
                    return { ...s, panes: [...s.panes, makePane(location)] };
                }

                return { ...s, panes: R.adjust(index, () => makePane(location), s.panes) };
            });

            if (index === 0) {
                navigateToLocation(location);
            }
        },
        [navigateToLocation],
    );

    useEffect(() => {
        const searchParams = new URLSearchParams(routerLocation.search);
        const settingsSearchParam = searchParams.get('settings');

        if (settingsSearchParam) {
            history.replace({ search: '' });
            const settingsType = SagaLocation.decodeSettings(settingsSearchParam);
            if (settingsType) {
                setSettings(settingsType);
            }
        }
    }, [routerLocation, setSettings, history]);

    React.useEffect(() => {
        if (state.panes.length === 0) {
            // If there is no panes left in the state, we want to redirect to the space
            // so that it can set it the first page again
            history.replace(`/s/${urlKey}`);
        } else if (state.panes.length >= 1) {
            const firstPane = state.panes[0];
            if (!SagaLocation.areLocationsEqual(location, firstPane.location)) {
                //we do this to sync up the url with the pageId of the first pane
                setState((s) => {
                    return { ...s, panes: R.adjust(0, () => makePane(location), s.panes) };
                });
            }
        }
    }, [location, state.panes, urlKey, history]);

    React.useEffect(() => {
        // Whenever the panes change, we add their ids to recent pages
        state.panes.forEach((pane) => {
            if (SagaLocation.isBlocksLocation(pane.location)) {
                addRecentLocation(pane.location, urlKey);
            }
        });
    }, [state.panes, addRecentLocation, urlKey]);

    const openLocation = React.useCallback(
        (
            location: SagaLocation.SagaLocation,
            event: React.SyntheticEvent | Event,
            options: OpenLocationOptions | undefined,
            fromIndex: number,
        ) => {
            const nativeEvent = getNativeEvent(event);
            if (isSideBySideEvent(nativeEvent) && isMetaKey(nativeEvent)) {
                openWindow(`${window.location.origin}${locationToUrl(location, urlKey)}`, '_blank');
                return;
            }

            if (
                options?.forceOtherIndex ||
                (isSideBySideEvent(nativeEvent) && isSideBySideKey(nativeEvent) && canEdit)
            ) {
                openLocationOnIndex(location, fromIndex === 1 ? 0 : 1);
                return;
            }

            if (options?.forceOtherIndex || (isSideBySideEvent(nativeEvent) && isSideBySideKey(nativeEvent))) {
                openLocationOnIndex(location, fromIndex === 1 ? 0 : 1);
                return;
            }

            openLocationOnIndex(location, fromIndex);
        },
        [openLocationOnIndex, canEdit, urlKey],
    );

    const closeByIndex = React.useCallback(
        (index: number) => {
            const nextPanes = R.remove(index, 1, state.panes);

            setState((s) => ({ ...s, panes: nextPanes }));

            const firstPane = nextPanes[0];

            if (firstPane) {
                navigateToLocation(firstPane.location);
            }
        },
        [navigateToLocation, state.panes],
    );

    const switchPanes = React.useCallback(() => {
        const currentLeftPane = state.panes[0];
        const currentRightPane = state.panes[1];

        setState((s) => ({ ...s, panes: [currentRightPane, currentLeftPane] }));
        navigateToLocation(currentRightPane.location);
    }, [navigateToLocation, state.panes]);

    const firstPane = state.panes[0];

    return (
        <NavigationProvider openLocation={(location, event, options) => openLocation(location, event, options, 0)}>
            <SharedPagesProvider>
                <SearchContextProvider
                    onChange={(isOpen) => {
                        if (state.panes.length === 1) return;
                        if (isOpen) {
                            setHighlightedIndex(0);
                        } else {
                            setHighlightedIndex(null);
                        }
                    }}
                >
                    <SearchModalContainer currentLocation={location} />
                    <SideBySideApiContext.Provider
                        value={{
                            setHighlightedIndex,
                            openLocationOnIndex,
                            closeByIndex,
                            openLocation,
                            switchPanes,
                        }}
                    >
                        <SideBySideStateContext.Provider value={state}>
                            <TemplateProvider>
                                <AIChatContextProvider>
                                    <NavigationContainer location={firstPane ? firstPane.location : undefined}>
                                        <SideBySide location={location} />
                                    </NavigationContainer>
                                </AIChatContextProvider>
                            </TemplateProvider>
                        </SideBySideStateContext.Provider>
                    </SideBySideApiContext.Provider>
                </SearchContextProvider>
            </SharedPagesProvider>
        </NavigationProvider>
    );
}

export default SideBySideContainer;
