import { BlockBuilder } from '../..';
import { newBlankPage } from '../../pageUtils';
import { WeakTask } from '../../types';

export function newSagaBasicsPageEs(task: WeakTask, collectionIds: string[]) {
    return newBlankPage({
        title: 'Fundamentos de Saga',
        icon: { type: 'emoji', colons: ':wave:' },
        collections: collectionIds,
        blocks: [
            BlockBuilder.title('Fundamentos de Saga'),
            BlockBuilder.callout([
                BlockBuilder.paragraph([BlockBuilder.text('¡Escribe en cualquier lugar de esta página para editarla!')]),
            ]),
            BlockBuilder.paragraph(),
            BlockBuilder.image('https://saga-public.s3.amazonaws.com/1-block-selection-menu.webp'),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Pulsa '),
                BlockBuilder.text('@', { code: true }),
                BlockBuilder.text(' o '),
                BlockBuilder.text('/', { code: true }),
                BlockBuilder.text(
                    ' para abrir el menú de autocompletado y añadir nuevo contenido como encabezados, tareas, imágenes, archivos, tablas y más.',
                ),
            ]),
            BlockBuilder.paragraph(),
            BlockBuilder.paragraph(),
            BlockBuilder.paragraph(),
            BlockBuilder.image('https://saga-public.s3.amazonaws.com/2-text-styling-menu.webp'),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Selecciona texto', { bold: true }),
                BlockBuilder.text(' para '),
                BlockBuilder.text('darle estilo,', { italic: true }),
                BlockBuilder.text(' convertirlo en otro tipo de contenido, '),
                BlockBuilder.text('resaltarlo', { highlight: true }),
                // todo highlight in red
                BlockBuilder.text(', o mejorarlo con IA.'),
            ]),
            BlockBuilder.paragraph(),
            BlockBuilder.paragraph(),
            BlockBuilder.image('https://saga-public.s3.amazonaws.com/3-drag-and-drop-images-and-files.webp'),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Añade imágenes y archivos', { bold: true }),
                BlockBuilder.text(' arrastrándolos o pegándolos.'),
            ]),
            BlockBuilder.paragraph(),
            BlockBuilder.paragraph(),
            BlockBuilder.image('https://saga-public.s3.amazonaws.com/4-drag-and-drop-blocks-of-content.webp'),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Arrastra y suelta', { bold: true }),
                BlockBuilder.text(' cualquier bloque seleccionándolo y arrastrando el icono ☰ al lado izquierdo del bloque.'),
            ]),
            BlockBuilder.paragraph(),
            BlockBuilder.paragraph(),
            BlockBuilder.image('https://saga-public.s3.amazonaws.com/5-unified-search-for-fast-navigation.webp'),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Pulsa '),
                BlockBuilder.text('⌘ + K', { code: true }),
                BlockBuilder.text(' (macOS) o '),
                BlockBuilder.text('Ctrl + K', { code: true }),
                BlockBuilder.text(' (Windows) para '),
                BlockBuilder.text('encontrar cualquier cosa', { bold: true }),
                BlockBuilder.text(' y navegar por tu espacio de trabajo.'),
            ]),
            BlockBuilder.paragraph(),
            BlockBuilder.paragraph(),
            BlockBuilder.image('https://saga-public.s3.amazonaws.com/6-creating-and-managing-tasks.webp'),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Crea tareas', { bold: true }),
                BlockBuilder.text(' abriendo el menú de autocompletado y seleccionando '),
                BlockBuilder.text('Tarea', { code: true }),
                BlockBuilder.text(
                    '. Las tareas son un tipo especial de páginas con un asignado, fecha de vencimiento y prioridad. Abre la tarea a continuación para aprender más 👇.',
                ),
            ]),
            BlockBuilder.taskBlock(task.id),
            BlockBuilder.paragraph(),
            BlockBuilder.paragraph(),
            BlockBuilder.image('https://saga-public.s3.amazonaws.com/7-adding-to-pinned.webp'),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Favorito ', { bold: true }),
                BlockBuilder.text('cualquier página para añadirla a la barra lateral haciendo clic en el icono ☆ en la esquina superior derecha.'),
            ]),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Dirígete a la página de 😍 Saga avanzado para explorar formas más avanzadas de usar Saga.'),
            ]),
            BlockBuilder.paragraph(),
            BlockBuilder.paragraph(),
            BlockBuilder.divider(),
            BlockBuilder.headingThree('¿Tienes alguna pregunta?'),
            BlockBuilder.paragraph([
                BlockBuilder.text('Pulsa el '),
                BlockBuilder.text('?', { bold: true }),
                BlockBuilder.text(
                    ' botón en la esquina inferior derecha para enviarnos un correo electrónico, enviar solicitudes de funciones o unirte a nuestras comunidades de usuarios en ',
                ),
                BlockBuilder.link('https://discord.com/invite/cgz2mUEq7P', 'Discord'),
                BlockBuilder.text(', '),
                BlockBuilder.link(
                    'https://sagacommunity.slack.com/join/shared_invite/zt-13m3lrrdt-1x6~l6sLuR8CX~4c3fWwHA#/shared-invite/email',
                    'Slack',
                ),
                BlockBuilder.text(', o '),
                BlockBuilder.link('https://twitter.com/saga_HQ', 'X'),
                BlockBuilder.text(
                    '. Además, visita las guías de ayuda en nuestro sitio web y descubre cómo sacar el máximo provecho de la aplicación.',
                ),
            ]),
            BlockBuilder.rawPrettyLink('https://saga.so/guides', {
                _tag: 'loaded',
                description: "Esta guía te ayudará a entender los bloques de construcción de Saga y a ponerte al día.",
                title: '📍 Guías de Saga',
                logo: 'https://saga.so/thumbnail.png',
            }),
            BlockBuilder.paragraph(),
        ],
    });
}
