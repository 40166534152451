import React, { useCallback, useEffect, useState } from 'react';
import Input from '../styled/Input';
import Button from '../styled/Button';
import { UserWithDetails, useAdminUserDeleteMutation, useAdminUserDetailsMutation } from '@saga/api';
import Spinner from '@/components/loading/Spinner';
import { format } from 'date-fns';
import { useUserContext } from '../UserContext';
import classNames from 'classnames';
import { useHistory, useLocation } from 'react-router-dom';
import Toggle from '../styled/Toggle';

export default function DeleteUser() {
    const { search } = useLocation();
    const emailParam = new URLSearchParams(search).get('email');
    const [userEmail, setUserEmail] = useState(emailParam ? emailParam : '');
    const [showBulkUserEmais, setShowBulkUserEmails] = useState(false);
    const [userDetails, setUserDetails] = useState<UserWithDetails | null>(null);
    const [findUserDetails, { loading }] = useAdminUserDetailsMutation();
    const [deleteUser, { loading: deletingUsers }] = useAdminUserDeleteMutation();
    const [userDeletable, setUserDeletable] = useState(false);
    const [notification, setNotification] = useState<{ status: 'success' | 'error'; message: string } | null>(null);
    const [deletePosthodData, setDeletePosthodData] = useState(false);
    const { user } = useUserContext();
    const history = useHistory();

    const onFindUserDetails = useCallback(() => {
        setNotification(null);
        setUserDetails(null);
        findUserDetails({
            variables: { input: { email: userEmail } },
            onCompleted: (data) => {
                setUserDetails(data.adminUserDetails);
            },
            onError: (e) => setNotification({ status: 'error', message: e.message }),
        });
    }, [findUserDetails, userEmail]);

    const onDeleteUser = (userId: string) => {
        if (window.confirm('Are you sure you want to delete this user?')) {
            deleteUser({
                variables: { input: { users: [{ userId, deletePosthodData }] } },
                onCompleted(data) {
                    if (data.adminUserDelete[0].status === 'SUCCESS') {
                        setUserEmail('');
                        setUserDetails(null);
                        setDeletePosthodData(false);
                        setNotification({
                            status: 'success',
                            message: 'The user was deleted, as well as related spaces etc.',
                        });
                    }
                },
            });
        }
    };

    const onDeleteBulk = () => {
        const userList = userEmail.split(',').map((u) => u.trim());
        if (
            window.confirm(
                `Are you sure you want to delete ${userList.length} users? Keep in mind that will remove the following resources as well\n* Firebase\n* Sendgrid\n* Posthog data (person + events)`,
            )
        ) {
            deleteUser({
                variables: { input: { users: userList.map((u) => ({ userEmail: u, deletePosthodData: true })) } },
                onCompleted(data) {
                    setUserEmail('');
                    setUserDetails(null);
                    setDeletePosthodData(false);
                    setNotification({
                        status: 'success',
                        message: `${data.adminUserDelete.length} users and associated spaces, Firebase, SendGrid, and PostHog data have been deleted.`,
                    });
                },
            }).catch((e) => alert(e));
        }
    };

    useEffect(() => {
        if (userDetails?.membership) {
            setUserDeletable(
                userDetails.role !== 'GOD' &&
                    userDetails.id !== user?.id &&
                    userDetails.membership.every((member) => member.spaceDeletable),
            );
        }
    }, [userDetails, user]);

    useEffect(() => {
        if (userEmail && emailParam) {
            onFindUserDetails();
            history.replace({ search: '' });
        }
    }, [userEmail, emailParam, onFindUserDetails, history]);

    console.log(userEmail);

    return (
        <div className="w-900">
            <h1 className="text-3xl mb-4">Delete User</h1>
            <div className="flex flex-row space-x-2 items-center mb-8">
                <Toggle
                    accessibleLabel={showBulkUserEmais ? 'Switch to single user' : 'Switch to bulk user'}
                    checked={showBulkUserEmais}
                    onChange={(e) => {
                        setUserEmail('');
                        setShowBulkUserEmails(e);
                        setUserDetails(null);
                    }}
                />
                <span>Bulk delete</span>
            </div>

            {showBulkUserEmais ? (
                <>
                    <p>
                        Enter the emails of the users you want to delete, separated by commas. e.g.: foo@bar.com,
                        bar@baz.com
                    </p>
                    <p>⚠️ This action will delete everything from our systems ⚠️</p>
                    <textarea
                        className="w-80 h-52 border-saga-gray-200 dark:border-zinc-600 focus:bg-gray-100 dark:focus:bg-zinc-900/50 focus:border-gray-300 dark:focus:border-zinc-500 placeholder-saga-text-gray px-4 py-2 border rounded text-base focus:outline-none focus-visible:border-saga-selection-blue focus-visible:ring-1 focus-visible:ring-saga-selection-blue/50 bg-white dark:bg-zinc-900"
                        onChange={({ currentTarget }) => setUserEmail(currentTarget.value)}
                        value={userEmail}
                        placeholder="foo@bar.com, bar@baz.com"
                    />
                </>
            ) : (
                <>
                    <p>Enter the email of the user you want to delete</p>
                    <div className="w-72">
                        <Input
                            onChange={({ currentTarget }) => setUserEmail(currentTarget.value)}
                            value={userEmail}
                            type="email"
                            onKeyDown={(e) => {
                                if (e.key === 'Enter' && userEmail) {
                                    onFindUserDetails();
                                }
                            }}
                        />
                    </div>
                </>
            )}

            {notification && (
                <div
                    className={classNames('mt-2 border rounded p-1 text-sm', {
                        'text-red-500 border-red-500': notification.status === 'error',
                        'text-green-700 border-green-700': notification.status === 'success',
                    })}
                >
                    {notification.message}
                </div>
            )}

            <div className=" mt-2">
                {showBulkUserEmais ? (
                    <Button disabled={!userEmail || loading || deletingUsers} onClick={onDeleteBulk}>
                        <div className="flex">
                            {(loading || deletingUsers) && <Spinner size={16} />}{' '}
                            <span className="ml-1">Delete users</span>
                        </div>
                    </Button>
                ) : (
                    <Button disabled={!userEmail || loading} onClick={onFindUserDetails}>
                        <div className="flex">
                            {loading && <Spinner size={16} />} <span className="ml-1">Find User</span>
                        </div>
                    </Button>
                )}
            </div>

            {!loading && userDetails && (
                <div className="grid grid-cols-[150px_1fr] gap-4 border rounded mt-4 p-4">
                    <div className="font-bold">Id</div>
                    <div className="select-text">{userDetails.id}</div>
                    <div className="font-bold">Names</div>
                    <div className="select-text">
                        {userDetails.firstName} {userDetails.lastName}
                    </div>
                    <div className="font-bold">Email</div>
                    <div className="select-text">
                        <a href={`mailto:${userDetails.email}`} className="underline">
                            {userDetails.email}
                        </a>
                    </div>
                    <div className="font-bold">User Role</div>
                    <div className="select-text">{userDetails.role}</div>
                    <div className="font-bold">Created At</div>
                    <div className="select-text">{format(new Date(userDetails.createdAt), 'dd.MM.yyyy HH:mm')}</div>
                    <div className="font-bold">Integrations</div>
                    <div>
                        {userDetails.integrations.map((integration) => (
                            <div className="select-text" key={integration.id}>
                                {integration.type} - {integration.isActive ? 'Active' : 'Inactive'}
                            </div>
                        ))}
                    </div>

                    {userDetails.membership && (
                        <>
                            <div className="font-bold">Member of</div>

                            <div className="divide-y">
                                {userDetails.membership.map(
                                    ({ spaceId, spaceName, role, spaceDeletable, publicPages }) => (
                                        <div className="select-text mb-2 pt-2" key={spaceId}>
                                            {spaceDeletable ? '👍' : '👎'} {spaceName} - {role}
                                            <br />
                                            {publicPages.length === 0 ? (
                                                'No shared pages'
                                            ) : (
                                                <>
                                                    <div>{`${publicPages.length} shared page${
                                                        publicPages.length > 1 ? 's:' : ':'
                                                    }`}</div>
                                                    {publicPages.map((page) => {
                                                        if (!page) return;
                                                        const url = `${window.location.protocol}//${window.location.host}/${page.id}`;
                                                        return (
                                                            <a
                                                                key={page.id}
                                                                href={url}
                                                                target="_blank"
                                                                rel="noreferrer"
                                                                className="text-sm inline-block"
                                                            >
                                                                {url}
                                                            </a>
                                                        );
                                                    })}
                                                </>
                                            )}
                                        </div>
                                    ),
                                )}
                            </div>
                        </>
                    )}
                    <div></div>
                    <div>
                        <label className="flex items-center ">
                            <input
                                type="checkbox"
                                className="w-3.5 mr-2"
                                checked={deletePosthodData}
                                onChange={(e) => setDeletePosthodData(e.currentTarget.checked)}
                            />
                            Delete posthog data as well
                        </label>
                    </div>
                    <div></div>
                    <div>
                        <Button onClick={() => onDeleteUser(userDetails.id)} disabled={!userDeletable}>
                            Delete User
                        </Button>
                    </div>
                </div>
            )}
        </div>
    );
}
