import { SafeSpace } from '../types';
import transactInSpace from './transactInSpace';
import * as Y from 'yjs';
import removePinnedById from './removePinnedById';
import removeFavorite from './removeFavorite';

export default function removePageView(space: SafeSpace, id: string) {
    transactInSpace(space, () => {
        const pageViews = space.map.get('pageViews') as Y.Array<Y.Map<unknown>> | undefined;

        const index = pageViews?.toArray().findIndex((pageView) => pageView.get('id') === id);

        if (index == null || index === -1) {
            return;
        }

        pageViews?.delete(index);
        removePinnedById(space, id);
        removeFavorite(space, id);
    });
}
