import React from 'react';
import { useTranslation } from 'react-i18next';
import SettingsPane from './SettingsPane';
import BasicButton from '../styled/BasicButton';
import { SpaceOperations, TaskLabel, WeakTask } from '@saga/shared';
import { useShallowTasks, useTaskLabels } from '@/hooks/SpaceHooks';
import { Edit2, Trash } from 'react-feather';
import { ConfirmationModal } from '../modal/ConfirmationModal';
import { useSpace } from '../SpaceProvider';
import Modal from '../Modal';
import Button from '../styled/Button';
import { LabelColorPicker, TaskLabelColors } from '../tasks/LabelSelect';

type LabelRowProps = {
    label: TaskLabel;
    tasks: WeakTask[];
    onEdit: (label: TaskLabel) => void;
    onDelete: (label: TaskLabel) => void;
};

type EditLabelModalProps = {
    isOpen: boolean;
    onCancel: () => void;
    onConfirm: ({ title, color }: { title: string; color: string }) => void;
    label: TaskLabel | null;
    isEdit: boolean;
};

const LabelRow = ({ label, tasks, onEdit, onDelete }: LabelRowProps) => {
    const occurences = tasks.reduce((acc, task) => (task?.labels?.includes(label.id) ? acc + 1 : acc), 0);
    return (
        <div className="flex flex-row p-1.5 items-center border-b border-saga-gray-150 dark:border-gray-800">
            <div className="w-2 h-2 rounded-full" style={{ backgroundColor: label.color }} />
            <div className="flex-1 truncate mx-3.5">{label.title}</div>
            <div className="w-24">{occurences}</div>
            <div className="flex flex-row space-x-5 w-12">
                <Edit2
                    size={16}
                    className="cursor-pointer text-saga-gray-500 hover:text-saga-text dark:hover:text-saga-text-darkmode"
                    onClick={() => onEdit(label)}
                />
                <Trash
                    size={16}
                    className="cursor-pointer text-saga-gray-500 hover:text-saga-text dark:hover:text-saga-text-darkmode"
                    onClick={() => onDelete(label)}
                />
            </div>
        </div>
    );
};

const TitleRow = () => {
    const { t } = useTranslation();

    return (
        <div className="flex flex-row p-1.5 items-center border-b border-saga-gray-150 dark:border-gray-800 text-xs text-saga-gray-500">
            <div className="flex-1">{t('settings.labels.name')}</div>
            <div className="w-24">{t('settings.labels.usage')}</div>
            <div className="flex flex-row space-x-4 w-12">{t('settings.labels.options')}</div>
        </div>
    );
};

const EditLabelModal = ({ isOpen, onCancel, onConfirm, label, isEdit }: EditLabelModalProps) => {
    const [title, setTitle] = React.useState(label?.title);
    const [color, setColor] = React.useState(label?.color || TaskLabelColors[0]);

    const { t } = useTranslation();

    return (
        <Modal.Medium isOpen={isOpen}>
            <div
                data-testid="edit-label-modal"
                className={`relative p-6 rounded max-w-xl bg-white dark:bg-saga-gray-1000 dark:text-zinc-200 text-saga-text space-y-6`}
            >
                <div className="font-bold text-base leading-6">
                    {isEdit ? t('settings.labels.edit_modal.title_edit') : t('settings.labels.edit_modal.title_create')}
                </div>
                <div className="flex flex-row py-1.5 px-3.5 items-center border border-saga-gray-200 dark:border-gray-800 rounded">
                    <div className="w-2 h-2 rounded-full" style={{ backgroundColor: color }} />
                    <input
                        autoFocus
                        placeholder={t('settings.labels.edit_modal.label_name')}
                        type="text"
                        className="flex-1 truncate mx-3.5 bg-transparent font-normal text-base"
                        onChange={(e) => setTitle(e.target.value)}
                        value={title}
                    />
                </div>
                <div>
                    <span className="text-xs text-saga-gray-500 ml-2">
                        {t('settings.labels.edit_modal.select_color')}
                    </span>
                    <LabelColorPicker
                        availableColors={TaskLabelColors}
                        selectedColor={color}
                        onSelectColor={(color) => setColor(color)}
                    />
                </div>
                <div className="flex justify-end">
                    <BasicButton onClick={onCancel} variant="secondary">
                        <Button.XsPadding>{t('common.cancel')}</Button.XsPadding>
                    </BasicButton>
                    <BasicButton
                        onClick={() => onConfirm({ title: title ?? '', color })}
                        className="ml-3"
                        disabled={!title}
                    >
                        <Button.XsPadding>{t('common.save')}</Button.XsPadding>
                    </BasicButton>
                </div>
            </div>
        </Modal.Medium>
    );
};

export default function LabelSettings() {
    const { t } = useTranslation();
    const labels = useTaskLabels();
    const tasks = useShallowTasks();

    const { space } = useSpace();

    const [showDeleteLabelModal, setShowDeleteLabelModal] = React.useState<TaskLabel | null>(null);
    const [showEditLabelModal, setShowEditLabelModal] = React.useState<TaskLabel | null>(null);

    return (
        <>
            <SettingsPane>
                <div>
                    <SettingsPane.Title>{t('settings.labels.title')}</SettingsPane.Title>
                    <SettingsPane.Small>{t('settings.labels.sub_title')}</SettingsPane.Small>
                </div>
                <SettingsPane.Content>
                    <SettingsPane.Section>
                        <BasicButton
                            className="px-4 h-8"
                            size="small"
                            variant="secondary"
                            onClick={() => setShowEditLabelModal({ id: 'new', title: '', color: TaskLabelColors[0] })}
                        >
                            {t('settings.labels.add_label')}
                        </BasicButton>
                        <div className="mt-6 max-w-xl">
                            <TitleRow />
                            {labels
                                .sort((a, b) => a.title.localeCompare(b.title))
                                .map((label) => (
                                    <LabelRow
                                        key={label.id}
                                        label={label}
                                        tasks={tasks}
                                        onEdit={() => setShowEditLabelModal(label)}
                                        onDelete={() => setShowDeleteLabelModal(label)}
                                    />
                                ))}
                        </div>
                    </SettingsPane.Section>
                </SettingsPane.Content>
            </SettingsPane>

            {showDeleteLabelModal && (
                <ConfirmationModal
                    confirmText={t('common.delete')}
                    description={t('settings.labels.delete_label_confirmation_description', {
                        count: tasks.filter((task) => task.labels?.includes(showDeleteLabelModal.id)).length,
                    })}
                    disabled={false}
                    onConfirm={() => {
                        tasks.forEach((task) => {
                            SpaceOperations.updatePartialTask(space, task.id, {
                                labels: task.labels?.filter((label) => label !== showDeleteLabelModal.id),
                            });
                        });
                        SpaceOperations.deleteTaskLabel(space, showDeleteLabelModal.id);
                        setShowDeleteLabelModal(null);
                    }}
                    onCancel={() => setShowDeleteLabelModal(null)}
                    title={t('settings.labels.delete_label_confirmation_title', {
                        title: showDeleteLabelModal.title,
                    })}
                />
            )}

            {showEditLabelModal && (
                <EditLabelModal
                    isOpen={!!showEditLabelModal}
                    onCancel={() => setShowEditLabelModal(null)}
                    onConfirm={({ title, color }) => {
                        if (tasks.some((task) => task.labels?.includes(showEditLabelModal.id))) {
                            SpaceOperations.updatePartialTaskLabel(space, showEditLabelModal.id, {
                                title,
                                color,
                            });
                        } else {
                            SpaceOperations.addTaskLabel(space, title, color);
                        }
                        setShowEditLabelModal(null);
                    }}
                    label={showEditLabelModal}
                    isEdit={tasks.some((task) => task.labels?.includes(showEditLabelModal.id))}
                />
            )}
        </>
    );
}
