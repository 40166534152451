import React, { useState, useRef } from 'react';
import Button from '../styled/Button';
import Dropdown, { useArrowSelectableIndex } from '@/components/popover/Dropdown';
import { ChevronDown, File, Trash, Users, FileText, Globe, Lock } from 'react-feather';
import { TableIcon } from '@/components/icons/Table';
import { track } from '@/analytics';
import { PageView } from '@saga/shared';

type PageTableViewDropdownProps = {
    selectedView: PageView;
    onSelectView: (view: PageView, event: Event | React.SyntheticEvent) => void;
    pageViews: PageView[];
};

export default function PageTableViewDropdown({ selectedView, onSelectView, pageViews }: PageTableViewDropdownProps) {
    const [viewMenuOpen, setViewMenuOpen] = useState(false);
    const buttonRef = useRef(null);

    const renderIconForView = (view: PageView) => {
        switch (view.mode) {
            case 'shared':
                return <Users size={14} />;
            case 'non-deleted':
                return <File size={14} />;
            case 'deleted':
                return <Trash size={14} />;
            case 'templates':
                return <TableIcon size={14} />;
            case 'private':
                return <Lock size={14} />;
            case 'public':
                return <Globe size={14} />;
            default:
                return <FileText size={14} />;
        }
    };

    function onSelect(index: number, event: Event | React.SyntheticEvent) {
        if (!viewMenuOpen) return;

        const viewOption = pageViews[index];

        if (viewOption) {
            track(`pages-table-view-change`, { value: viewOption.id });
            onSelectView(viewOption, event);
            setViewMenuOpen(false);
        }
    }

    const view = React.useMemo(() => pageViews.find((view) => view.id === selectedView.id), [selectedView, pageViews]);

    const { index, setIndex } = useArrowSelectableIndex({
        onSelect,
        maxIndex: pageViews.length - 1,
        active: viewMenuOpen,
        initialIndex: view ? pageViews.indexOf(view) ?? 0 : 0,
    });

    return (
        <>
            <button
                data-testid="pages-mode-dropdown"
                className="shrink-0 hover-trigger px-2 py-1.5 flex h-8 items-center rounded font-semibold text-xs leading-normal border dark:bg-inherit hover:bg-saga-gray-200 dark:hover:bg-saga-gray-700 border-saga-gray-200 dark:border-saga-gray-700 cursor-pointer shadow-button space-x-1.5"
                ref={buttonRef}
                onClick={() => setViewMenuOpen(!viewMenuOpen)}
            >
                <span>{renderIconForView(selectedView)}</span>
                <span>{view?.title}</span>
                <ChevronDown size={14} />
            </button>

            <Dropdown
                isOpen={viewMenuOpen}
                attachToRef={buttonRef}
                onClose={() => {
                    setViewMenuOpen(false);
                }}
                position="below"
            >
                {pageViews.map((option, i) => (
                    <Button.PopOverButton
                        key={i}
                        selected={i === index}
                        active={selectedView.id === option.id}
                        onMouseEnter={() => setIndex(i)}
                        onClick={(e) => onSelect(i, e)}
                        type="button"
                    >
                        <div className="pr-2">{renderIconForView(option)}</div>
                        {option.title}
                    </Button.PopOverButton>
                ))}
            </Dropdown>
        </>
    );
}
