import { unsafeRight } from '../io-ts-js';
import mustFindIndexByPageId from './mustFindIndexByPageId';
import removePinnedById from './removePinnedById';
import transactInSpace from './transactInSpace';
import { SafeSpace } from '../types';

export default function deletePagePermanently(space: SafeSpace, id: string) {
    const yPages = unsafeRight(space.get('pages'));
    const pageIndex = mustFindIndexByPageId(space, id);
    transactInSpace(space, () => {
        const yPage = unsafeRight(yPages.get(pageIndex));
        const isArchived = unsafeRight(yPage.getDecoded('archivedAt')) != undefined;

        if (!isArchived) {
            throw new Error(`Cannot delete page with id ${id}, page must be archived!`);
        }

        yPages.array.delete(pageIndex);

        removePinnedById(space, id);
    });
}
