import React from 'react';
import Toggle from '@/components/styled/Toggle';
import { Page, SpaceOperations } from '@saga/shared';
import { Clock, RefreshCcw, Hash, X, Icon as IconType, User } from 'react-feather';
import { formatDateOptimized } from '@/utils/dateUtils';
import Button from '../styled/Button';
import Panel from './Panel';
import classNames from 'classnames';
import { useSpace } from '@/components/SpaceProvider';
import { useTranslation } from 'react-i18next';
import { useUserContext } from '../UserContext';
import { useDocumentCreator } from '@/hooks/SpaceHooks';

function Field({
    label,
    Icon,
    value,
    valueTestId,
}: {
    label: string;
    Icon: IconType;
    value: string;
    valueTestId?: string;
}) {
    return (
        <div className="flex items-center">
            <div className="flex pr-2">
                <Icon className="stroke-saga-gray-500 my-auto mr-2" size={16} />
                <p className="text-saga-gray-500 dark:text-zinc-200 w-20 text-sm my-auto">{label}</p>
            </div>
            <div className="flex-grow px-2 border-2 border-transparent">
                <p data-testid={valueTestId} className="text-sm">
                    {value}
                </p>
            </div>
        </div>
    );
}

function ToggleSetting({
    checked,
    onChange,
    label,
    description,
}: {
    checked: boolean;
    onChange(checked: boolean): void;
    label: string;
    description: string;
}) {
    return (
        <div className="flex justify-between text-sm align-middle" data-testid={label}>
            <div className="space-y-1">
                <p className="uppercase text-sm font-semibold text-saga-gray-dark dark:text-zinc-200">{label}</p>
                <p>{description}</p>
            </div>
            <div className="flex-none">
                <Toggle checked={checked} onChange={onChange} accessibleLabel={label} />
            </div>
        </div>
    );
}

type Props = {
    currentPage: Pick<Page, 'id' | 'createdAt' | 'updatedAt' | 'wordCount' | 'archivedAt' | 'settings' | 'createdBy'>;
    floating: boolean;
    onClose(): void;
};

const PageSettingsPanel = ({ currentPage, onClose, floating }: Props) => {
    const { space } = useSpace();
    const { t } = useTranslation();
    const { user } = useUserContext();

    const creator = useDocumentCreator(currentPage);

    const updatePageSettings =
        <K extends keyof Page['settings']>(type: K) =>
        (newValue: Page['settings'][K]) => {
            const settings = {
                ...currentPage.settings,
                [type]: newValue,
            };

            SpaceOperations.updatePartialPage(space, currentPage.id, { settings });
        };

    return (
        <Panel testId="settings-panel">
            <div className="space-y-4">
                <Panel.Header padding={!floating}>
                    <div className="flex items-center justify-start space-x-2">
                        {floating && (
                            <Button.Plain onClick={onClose}>
                                <X size={20} />
                            </Button.Plain>
                        )}
                        <Panel.Title>
                            <span>{t('page_info.label')}</span>
                        </Panel.Title>
                    </div>
                </Panel.Header>
                <Panel.Body>
                    <div className={classNames('space-y-4', { 'px-3': !floating })}>
                        <div className="space-y-2">
                            {creator && <Field label={t('page_info.creator')} Icon={User} value={creator.name} />}
                            <Field
                                label={t('page_info.created')}
                                Icon={Clock}
                                value={formatDateOptimized(currentPage.createdAt, user?.data.language)}
                            />
                            <Field
                                label={t('page_info.updated')}
                                Icon={RefreshCcw}
                                value={formatDateOptimized(currentPage.updatedAt, user?.data.language)}
                            />
                            <Field
                                label={t('page_info.word_count')}
                                valueTestId="word-count-value"
                                Icon={Hash}
                                value={`${currentPage.wordCount}`}
                            />
                        </div>

                        <div className="space-y-2">
                            <Panel.Title>
                                <span>{t('page_info.page_settings')}</span>
                            </Panel.Title>
                            {currentPage.archivedAt == null && (
                                <>
                                    <ToggleSetting
                                        label={t('page_info.autolink_pages_label')}
                                        description={t('page_info.autolink_pages_description')}
                                        checked={currentPage.settings.linkTitle ?? false}
                                        onChange={updatePageSettings('linkTitle')}
                                    />
                                    <ToggleSetting
                                        label={t('page_info.autolink_headings_label')}
                                        description={t('page_info.autolink_headings_description')}
                                        checked={currentPage.settings.linkHeadings ?? false}
                                        onChange={updatePageSettings('linkHeadings')}
                                    />
                                    <ToggleSetting
                                        label={t('page_info.full_width_label')}
                                        description={t('page_info.full_width_description')}
                                        checked={currentPage.settings.fullWidth ?? false}
                                        onChange={updatePageSettings('fullWidth')}
                                    />
                                </>
                            )}
                        </div>
                    </div>
                </Panel.Body>
            </div>
        </Panel>
    );
};

export default PageSettingsPanel;
