import { Collection } from '../types';
import findCollectionById from './findCollectionById';
import transactInSpace from './transactInSpace';
import { SafeSpace } from '../types';

export default function setCollectionTitle(space: SafeSpace, id: string, title: Collection['title']) {
    const collection = findCollectionById(space, id);

    transactInSpace(space, () => {
        collection.set('title', title);
    });
}
