import { HocuspocusProvider } from '@hocuspocus/provider';
import * as Y from 'yjs';
import { debugLog, isDebug } from '@/utils';
import { WEBSOCKET_URL } from '@/constants';
import { CODE_VERSION } from '@saga/shared';

export async function setupRealtimeProvider({
    getToken,
    onSynced,
    onDisconnect,
    onAuthenticated,
    onAuthenticationFailed,
    onClose,
    onStateless,
    document,
    name,
}: {
    getToken(): Promise<string>;
    onSynced(): void;
    onDisconnect(): void;
    onAuthenticated?: () => void;
    onAuthenticationFailed(): void;
    onClose: (event: CloseEvent) => void;
    onStateless: (payload: string) => void;
    document: Y.Doc;
    name: string;
}) {
    debugLog(`Setting up provider with document name ${name}`);

    const spaceProvider = new HocuspocusProvider({
        url: WEBSOCKET_URL,
        name,
        document,
        broadcast: false,
        forceSyncInterval: 5000,
        parameters: { version: CODE_VERSION },
        token: getToken,
        quiet: !isDebug(),
        onSynced() {
            debugLog(`Space provider synced`);
            onSynced();
        },
        onClose: (event) => {
            debugLog('Space connection closed');
            //@ts-expect-error The hocuspocus type is not correct, `event` is an object
            onClose(event.event);
        },
        onAuthenticated() {
            debugLog('Space authenticated');
            if (onAuthenticated) onAuthenticated();
        },
        onAuthenticationFailed() {
            debugLog('Space authentication failed');
            onAuthenticationFailed();
        },
        onDisconnect: () => {
            debugLog('Space provider disconnected');
            onDisconnect();
        },
        onDestroy: () => {
            debugLog('Destroying space provider');
        },
        onStateless: (data) => {
            debugLog('Stateless received');
            onStateless(data.payload);
        },
    });

    return {
        spaceProvider,
        name,
        document,
        cleanup: () => {
            // destroy document before provider to not have any
            // pending changes that might get sent via the provider
            document.destroy();
            spaceProvider.destroy();
        },
    };
}
