import React from 'react';
import { MembersIllustration } from '../icons';
import { Trans, useTranslation } from 'react-i18next';
import { TodesktopLink } from '../Desktop';
import { isFeatureEnabled } from '@/analytics';
import { useSpaceAccess } from '@/hooks/useSpaceAccess';
import { FeatureFlag } from '@/../../shared/src/utils/FeatureFlags';

export function FreePlanInfoBanner() {
    const { t } = useTranslation();
    const { permissions } = useSpaceAccess();
    const planAllowedMembers = isFeatureEnabled(FeatureFlag.allowBilling) ? permissions.editors.limit! : 5;

    return (
        <div className="bg-saga-new-purple-light text-saga-text flex p-2 md:p-6 space-x-2 md:space-x-6">
            <div>
                <MembersIllustration />
            </div>
            <div className="space-y-2">
                <h2 className="text-xl font-bold">{t('billing.free_plan_info_banner.title')}</h2>
                <p>
                    <Trans
                        i18nKey="billing.free_plan_info_banner.description"
                        count={planAllowedMembers}
                        components={{
                            bold: <span className="font-bold" />,
                            1: <TodesktopLink path="https://saga.so/pricing" className="underline cursor-pointer" />,
                        }}
                    />
                </p>
            </div>
        </div>
    );
}
