import { BlockBuilder } from '../..';
import { newBlankPage } from '../../pageUtils';

export function newSagaAdvancedPageDe(collectionIds: string[]) {
    return newBlankPage({
        title: 'Saga fortgeschritten',
        icon: {
            type: 'emoji',
            colons: ':heart_eyes:',
        },
        collections: collectionIds,
        blocks: [
            BlockBuilder.title('Saga fortgeschritten'),
            BlockBuilder.callout([
                BlockBuilder.paragraph([
                    BlockBuilder.text('Entdecke leistungsfähigere Funktionen und arbeite besser mit Saga.'),
                ]),
            ]),
            BlockBuilder.headingThree('Bleibe organisiert, ohne darüber nachzudenken'),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Erstelle '),
                BlockBuilder.link('https://saga.so/guides/collections-of-pages', 'Sammlungen'),
                BlockBuilder.text(' und gruppiere ähnliche Seiten.'),
            ]),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Heft die wichtigsten Seiten und Sammlungen an die '),
                BlockBuilder.link('https://saga.so/guides/sidebar', 'Seitenleiste'),
                BlockBuilder.text('.'),
            ]),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Wechsle schnell zwischen Seiten, Aufgaben und Sammlungen mit der '),
                BlockBuilder.link('https://saga.so/guides/search-in-saga', 'einheitlichen Suche'),
                BlockBuilder.text('.'),
            ]),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Klicke auf '),
                BlockBuilder.link('https://saga.so/guides/task-management', 'Aufgaben'),
                BlockBuilder.text(' in der Seitenleiste, um alle Aufgaben von allen deinen Seiten an einem Ort zu verwalten.'),
            ]),
            BlockBuilder.paragraph(),
            BlockBuilder.headingThree('Vernetze dein Wissen wie ein Profi'),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Nutze das Autovervollständigungsmenü, um '),
                BlockBuilder.link('https://saga.so/guides/page-links', 'Seiten zu erwähnen'),
                BlockBuilder.text(' und Links innerhalb deines Arbeitsbereichs zu erstellen.'),
            ]),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Öffne die '),
                BlockBuilder.link('https://saga.so/guides/references', 'Referenzen'),
                BlockBuilder.text(' auf der rechten Seite, um zu sehen, wo deine aktuelle Seite erwähnt wurde.'),
            ]),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Erstelle Links zu Seiten '),
                BlockBuilder.link('https://saga.so/guides/automatic-linking', 'automatisch'),
                BlockBuilder.text(' indem du ihre Titel eintippst. Zum Beispiel ist dies ein Link zu Saga KI.'),
            ]),
            BlockBuilder.checkListItem([
                BlockBuilder.link('https://saga.so/guides/page-links', 'Vorschau'),
                BlockBuilder.text(' von Seiten sofort durch Überfahren von Links.'),
            ]),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Erstelle '),
                BlockBuilder.link('https://saga.so/guides/live-blocks', 'Live-Blöcke'),
                BlockBuilder.text(
                    ' um Inhalte mühelos über Seiten hinweg zu synchronisieren, Duplikate zu eliminieren und Informationen aktuell zu halten.',
                ),
            ]),
            BlockBuilder.paragraph(),
            BlockBuilder.headingThree('Werde produktiver wie nie zuvor'),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Lerne die '),
                BlockBuilder.link('https://saga.so/guides/keyboard-shortcuts', 'Tastenkürzel'),
                BlockBuilder.text('.'),
            ]),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Öffne Seiten im '),
                BlockBuilder.link('https://saga.so/guides/side-by-side-view', 'Nebeneinander-Ansicht'),
                BlockBuilder.text(' um an zwei Seiten gleichzeitig zu arbeiten.'),
            ]),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Finde alles sofort in deinem Arbeitsbereich mit der '),
                BlockBuilder.link('https://saga.so/guides/search-in-saga', 'einheitlichen Suche'),
                BlockBuilder.text('.'),
            ]),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Integriere mit '),
                BlockBuilder.link('https://saga.so/guides/google-drive-integration', 'Google Drive'),
                BlockBuilder.text(' oder '),
                BlockBuilder.link('https://saga.so/guides/linear-integration', 'Linear'),
                BlockBuilder.text(' um Dateien und Aufgaben darin zu erwähnen oder zu suchen.'),
            ]),
            BlockBuilder.checkListItem([
                BlockBuilder.text(
                    'Gehe zu ✨ Saga KI, um zu lernen, wie du den leistungsstarken KI-Assistenten direkt in deinem Arbeitsbereich nutzt.',
                ),
            ]),
            BlockBuilder.paragraph(),
            BlockBuilder.divider(),
            BlockBuilder.headingThree('Weitere Anleitungen und FAQs'),
            BlockBuilder.paragraph([
                BlockBuilder.text('Besuche die Saga-Anleitungen und sieh, wie du das Beste aus der App herausholst.'),
            ]),
            BlockBuilder.rawPrettyLink('https://saga.so/guides', {
                _tag: 'loaded',
                description: "Dieser Leitfaden hilft dir, die Bausteine von Saga zu verstehen und bringt dich schnell auf den neuesten Stand.",
                title: '📍 Saga-Anleitungen',
                logo: 'https://saga.so/thumbnail.png',
            }),
            BlockBuilder.paragraph(),
        ],
    });
}
