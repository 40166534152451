import React, { useContext, useEffect } from 'react';
import LoadingScreen from './loading/LoadingScreen';
import * as api from '@saga/api';
import { Redirect } from 'react-router-dom';
import { WorkspaceSettingsProvider } from '@/hooks/useWorkspaceSettings';
import { useUserContext } from './UserContext';
import { useWorkspaces } from './WorkspacesContext';
import { identifyWorkspace } from '@/analytics';
import { useAllSharedPagesStatusesByUrlKeyQuery } from '@saga/api';

type WorkspaceContextType = {
    currentWorkspace: api.SpaceWithMembersFragment;
    currentSubscription: api.SpaceSubscription | null;
    subscriptions: (api.SpaceSubscription | null)[];
    currentUrlKey: string;
    sharedPagesStatuses: api.AllSharedPagesStatusesByUrlKeyQuery | null;
};

export const WorkspaceContext = React.createContext<WorkspaceContextType | null>(null);

export function useWorkspaceContext() {
    const context = useContext(WorkspaceContext);

    if (context == null) {
        throw new Error('useWorkspaceContext must be used inside WorkspaceContext');
    }

    return context;
}

export function useCurrentWorkspace() {
    const { currentWorkspace } = useWorkspaceContext();
    return currentWorkspace;
}

export function useSubscriptions() {
    const { currentSubscription, subscriptions } = useWorkspaceContext();
    return { currentSubscription, subscriptions };
}

export function useSharedPagesStatuses() {
    const { sharedPagesStatuses } = useWorkspaceContext();
    return sharedPagesStatuses;
}

export const WorkspaceContextProvider: React.FC<{ urlKey: string }> = ({ children, urlKey }) => {
    const { isLoggedIn } = useUserContext();
    const { workspaces } = useWorkspaces();
    const loadedWorkspace = workspaces.find((workspace) => workspace.urlKey === urlKey);

    const skip = loadedWorkspace != null;
    const { data, loading, refetch } = api.useSpaceByUrlKeyQuery({
        variables: { input: { urlKey } },
        skip,
    });

    const currentWorkspace = loadedWorkspace ?? data?.spaceByUrlKey;

    const { data: sharedPagesStatuses } = useAllSharedPagesStatusesByUrlKeyQuery({
        variables: { input: { urlKey: urlKey } },
        fetchPolicy: 'cache-and-network',
    });

    useEffect(() => {
        if (!skip) {
            refetch();
        }
    }, [isLoggedIn, refetch, skip]);

    useEffect(() => {
        if (currentWorkspace) {
            identifyWorkspace(currentWorkspace);
        }
    }, [currentWorkspace]);

    if ((loading && data == null) || (currentWorkspace != null && currentWorkspace.urlKey !== urlKey)) {
        return <LoadingScreen />;
    }

    if (currentWorkspace == null) {
        console.warn('Workpace not found.');
        return (
            <Redirect
                to={isLoggedIn ? '/not-found' : { pathname: '/login', state: { referrer: window.location.href } }}
            />
        );
    }

    return (
        <WorkspaceContext.Provider
            value={{
                currentWorkspace,
                currentSubscription: currentWorkspace.activeSubscription || null,
                subscriptions: currentWorkspace.subscriptions,
                currentUrlKey: urlKey,
                sharedPagesStatuses: sharedPagesStatuses || null,
            }}
        >
            <WorkspaceSettingsProvider>{children}</WorkspaceSettingsProvider>
        </WorkspaceContext.Provider>
    );
};
