import AIChatMessageCard from '@/components/aichat/AIChatMessageCard';
import AIPromptInput from '@/components/editor/ai/AIPromptInput';
import { apolloClient } from '@/graphql';
import useAIAssist, { AIStatus } from '@/hooks/useAIAssist';
import { AiChatThread } from '@saga/api';
import { AIMessage, AISourceItem } from '@saga/shared';
import { ReactEditor } from 'slate-react';
import React from 'react';
import * as api from '@saga/api';
import { useCurrentWorkspace } from '@/components/WorkspaceContext';
import { useUserContext } from '@/components/UserContext';
import { useTranslation } from 'react-i18next';
import { useSpace } from '@/components/SpaceProvider';
import AISourcesList from '@/components/editor/ai/AISourcesList';
import { getPromptText, getReferencedDocuments } from '@/utils/aiUtils';
import { track } from '@/analytics';
import { v4 as uuid } from 'uuid';
import { useMountedEditors } from '../../../../shared/src/Editor';
import classNames from 'classnames';

type Props = {
    thread: AiChatThread;
    mode: 'pane' | 'sidebar';
};

const AIChatScreen = ({ thread, mode }: Props) => {
    const [history, setHistory] = React.useState<AIMessage[]>([]);

    React.useEffect(() => {
        setHistory(
            thread.messages.map((m) => ({
                content: m.content ?? null,
                tool_call_id: m.tool_call_id,
                tool_calls: m.tool_calls,
                role: m.role,
                id: uuid(),
            })),
        );
    }, [thread]);
    const { t } = useTranslation();
    const [text, setText] = React.useState<string>('');
    const [sources, setSources] = React.useState<AISourceItem[]>([]);
    const { urlKey, id: spaceId } = useCurrentWorkspace();
    const { space } = useSpace();

    const { user } = useUserContext();
    const { status: responseStatus, messages, generateAIText, stop, error } = useAIAssist(history, thread.id);
    const [status, setStatus] = React.useState<AIStatus>(responseStatus);

    const mountedEditors = useMountedEditors();

    const [scrollStick, setScrollStick] = React.useState(true);

    const scrollRef = React.useRef<HTMLDivElement>(null);
    const ref = React.useRef<HTMLDivElement>(null);

    const inputId = React.useMemo(() => uuid(), []);

    const onSend = React.useCallback(
        (text: string, sources: AISourceItem[]) => {
            const id = uuid();
            track('ai-chat-message-sent', { source: mode });
            setText('');
            generateAIText({ prompt: text, references: sources }, id);
            setHistory([{ content: `<instructions>${text}</instructions>`, role: 'user', id }, ...history]);
        },
        [generateAIText, history, mode],
    );

    const storeInCache = React.useCallback(
        (messages: AIMessage[]) => {
            const cache = apolloClient.cache.readQuery<api.ChatThreadsQuery>({
                query: api.ChatThreadsDocument,
                variables: { input: { urlKey } },
            });
            const cachedThreads = cache?.chatThreads ?? [];

            const mappedMessages: api.AiChatMessage[] = messages.map((message) => ({
                __typename: 'AIChatMessage',
                content: message.content,
                createdAt: new Date().toISOString(),
                role: message.role as api.AiChatRole,
                tool_call_id: message.tool_call_id ?? null,
                tool_calls: message.tool_calls ?? null,
            }));

            apolloClient.writeQuery({
                query: api.ChatThreadsDocument,
                data: {
                    ...cache,
                    chatThreads: cachedThreads.some((cached) => cached.id === thread.id)
                        ? cachedThreads.map((cached) =>
                              cached.id === thread.id ? { ...cached, messages: mappedMessages } : cached,
                          )
                        : [
                              {
                                  __typename: 'AIChatThread',
                                  id: thread.id,
                                  userId: user?.id ?? '',
                                  spaceId,
                                  messages: mappedMessages,
                              },
                              ...cachedThreads,
                          ],
                },
                variables: { input: { urlKey } },
            });
        },
        [spaceId, thread.id, urlKey, user],
    );

    const focusInputField = React.useCallback(() => {
        const element = document.getElementById(inputId);
        if (element instanceof HTMLElement) {
            setTimeout(() => element.focus());
        }
    }, [inputId]);

    const isEmpty = React.useMemo(
        () => thread.messages.length === 0 && history.length === 0,
        [thread.messages.length, history.length],
    );

    const messagesWithSources = React.useMemo(
        () =>
            messages
                .flatMap((message, i) => {
                    if (message.role !== 'user' && (message.role !== 'assistant' || message.tool_calls)) return [];

                    // Find all tool calls related to this message
                    let toolCalls: AIMessage[] = [];
                    for (let index = i + 1; index < messages.length; index++) {
                        const message = messages[index];
                        if (message.role === 'assistant' && !message.tool_calls) break;
                        if (message.role === 'assistant' && message.tool_calls) toolCalls.push(message);
                    }
                    return { message, sources: getReferencedDocuments(toolCalls, space) };
                })
                .reverse(),
        [messages, space],
    );

    React.useLayoutEffect(() => {
        focusInputField();
    }, [thread.id, focusInputField]);

    React.useEffect(() => {
        if (!ref.current) return;
        const resizeObserver = new ResizeObserver(() => {
            scrollStick && scrollRef.current?.scrollTo({ top: ref.current?.scrollHeight });
        });
        resizeObserver.observe(ref.current);
        return () => resizeObserver.disconnect();
    }, [ref, scrollStick, isEmpty]);

    React.useEffect(() => {
        setStatus(responseStatus);
    }, [responseStatus]);

    React.useEffect(() => {
        if (status === 'done') {
            setHistory(messages);
            if (![...mountedEditors].some(ReactEditor.isFocused)) focusInputField();
        }
    }, [messages, status, mountedEditors, focusInputField]);

    React.useEffect(() => {
        status === 'done' && messages.length && storeInCache(messages);
    }, [messages, storeInCache, status]);

    React.useEffect(() => {
        const handleEscapeKey = (e: KeyboardEvent) => {
            if (e.key === 'Escape' && status === 'loading') {
                e.preventDefault();
                track('esc-button-ai-stop-generation', { source: mode });
                stop?.();
            }
        };

        document.addEventListener('keydown', handleEscapeKey);
        return () => document.removeEventListener('keydown', handleEscapeKey);
    }, [status, stop, mode]);

    return (
        <div
            className={classNames('flex flex-col overflow-hidden', {
                'justify-center h-3/4': isEmpty,
                'h-full': !isEmpty,
            })}
        >
            {!isEmpty ? (
                <div
                    ref={scrollRef}
                    className="flex flex-1 flex-col overflow-y-auto px-3 select-text hide-scrollbar pb-5"
                    onScroll={(e) => {
                        // If the difference between the height of the chat and the scroll position is less than 10,
                        // we consider the chat scrolled to the bottom and we stick the scroll
                        setScrollStick(
                            Math.abs(
                                e.currentTarget.clientHeight +
                                    e.currentTarget.scrollTop -
                                    (scrollRef.current?.scrollHeight ?? 0),
                            ) < 10,
                        );
                    }}
                >
                    <div ref={ref}>
                        <div className="flex-grow"></div>
                        {messagesWithSources.map((item, index) => (
                            <div key={item.message.id ?? index} className="mt-5">
                                <AIChatMessageCard message={item.message} references={item.sources} />
                            </div>
                        ))}
                    </div>
                </div>
            ) : (
                <div className="text-2xl font-bold px-3 mb-5">
                    {t('ai.chat.initial.greeting', { name: user?.data.firstName })}
                </div>
            )}

            <div className="dark:bg-saga-gray-1000 pb-5 pt-3 px-3">
                <div className="border border-saga-gray-250 dark:border-saga-gray-800 rounded-md bg-saga-gray-100 dark:bg-saga-gray-900">
                    <div className="mt-1">
                        <AISourcesList
                            sources={sources}
                            onItemRemoved={(id) => {
                                setSources(sources.filter((source) => source.id !== id));
                                track('click-on-ai-chat-button', { source: mode });
                            }}
                        />
                    </div>
                    <AIPromptInput
                        id={inputId}
                        placeholder={t('ai.ask_ai_anything_dotted')}
                        sources={sources}
                        text={text}
                        error={error}
                        setText={setText}
                        status={status}
                        showIcon={false}
                        onUpdateSources={setSources}
                        onSubmit={(text) => onSend(text, sources)}
                        onCancel={() => {
                            if (stop && typeof stop === 'function') {
                                stop();
                            }
                        }}
                        onRetry={() => {
                            const lastUserPrompt = messages.find((m) => m.role === 'user');
                            if (lastUserPrompt) {
                                generateAIText({ prompt: getPromptText(lastUserPrompt), references: sources });
                            }
                        }}
                        onSourcesVisible={(visible) => {
                            if (!visible) focusInputField();
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default AIChatScreen;
