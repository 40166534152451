import { Collection } from '../types';
import { ymapFromRecord } from '../yjs-utils';
import findCollectionById from './findCollectionById';
import transactInSpace from './transactInSpace';
import { SafeSpace } from '../types';

export default function setCollectionIcon(space: SafeSpace, id: string, icon: Collection['icon']) {
    const collection = findCollectionById(space, id);

    transactInSpace(space, () => {
        if (icon) {
            collection.set('icon', ymapFromRecord(icon));
        } else {
            collection.set('icon', undefined);
        }
    });
}
