import React from 'react';
import { ArrowLeft, ArrowRight } from 'react-feather';
import Button from './styled/Button';
import invariant from 'tiny-invariant';
import { useHistory, useLocation } from 'react-router-dom';
import Tooltip from './popover/Tooltip';
import { getOS } from '@/lib/helpers';

export default function DesktopNavigationButtons() {
    const history = useHistory();
    const location = useLocation();

    const toDesktop = React.useMemo(() => {
        const toDesktop = window.todesktop;
        invariant(toDesktop != null, 'Cannot render DesktopNavigationButtons, window.todesktop is undefined');
        return toDesktop;
    }, []);

    const [{ canGoBack, canGoForward }, setButtonState] = React.useState({ canGoBack: true, canGoForward: true });

    React.useEffect(() => {
        async function initializeButtonState() {
            const canGoBack = await toDesktop.contents.canGoBack();
            const canGoForward = await toDesktop.contents.canGoForward();
            setButtonState({ canGoBack, canGoForward });
        }

        initializeButtonState();
    }, [toDesktop, location]);

    const tooltipContentBack = `Go back ${getOS() === 'Mac OS' ? '⌘' : 'Ctrl'} + [`;
    const tooltipContentForward = `Go forward ${getOS() === 'Mac OS' ? '⌘' : 'Ctrl'} + ]`;

    return (
        <div className="flex space-x-1">
            <Tooltip disabled={!canGoBack} content={tooltipContentBack} placement="bottom">
                <Button.Plain disabled={!canGoBack} onClick={history.goBack}>
                    <Button.SmallPadding>
                        <ArrowLeft size={18} />
                    </Button.SmallPadding>
                </Button.Plain>
            </Tooltip>
            <Tooltip disabled={!canGoForward} content={tooltipContentForward} placement="bottom">
                <Button.Plain disabled={!canGoForward} onClick={history.goForward}>
                    <Button.SmallPadding>
                        <ArrowRight size={18} />
                    </Button.SmallPadding>
                </Button.Plain>
            </Tooltip>
        </div>
    );
}
