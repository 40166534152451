import React from 'react';
import { SpaceOperations, WeakPage, WeakTask } from '@saga/shared';
import { SidebarSection } from './SidebarSection';
import { usePinned } from '@/hooks/SpaceHooks';

type PageForFavorites = Pick<
    WeakPage,
    'id' | 'title' | 'collections' | 'archivedAt' | 'icon' | 'isTemplate' | 'createdAt' | 'updatedAt'
>;

export default function Pinned({
    selectedId,
    pages,
    tasks,
}: {
    selectedId?: string;
    pages: PageForFavorites[];
    tasks: WeakTask[];
}) {
    const pinned = usePinned();

    return (
        <SidebarSection
            title="pinned"
            selectedId={selectedId}
            pages={pages}
            tasks={tasks}
            items={pinned as string[]}
            addItem={(space, itemId) => SpaceOperations.addPinned(space, itemId)}
            moveItem={(space, itemId, options) => SpaceOperations.movePinned(space, itemId, options)}
        />
    );
}
