import { Space, WeakBlock } from '../types';

import * as api from '@saga/api';
import { FindInLocationsResult } from './findInLocations';

function findSpaceEmbeddings(
    space: Space,
    spaceBlocks: { [index: string]: WeakBlock[] } | undefined,
    embeddings: api.AiEmbedding[],
): FindInLocationsResult[] {
    let results: FindInLocationsResult[] = [];

    for (const embedding of embeddings) {
        const page = space.pages.find((page) => page.id === embedding.pageId);
        const task = space.tasks.find((task) => task.id === embedding.pageId);
        const blocks = spaceBlocks?.[embedding.pageId];

        if ((!page && !task) || !blocks) {
            continue;
        }

        const startIndex = blocks.findIndex((block) => block.id === embedding.fromBlockId);
        const endIndex = blocks.findIndex((block) => block.id === embedding.toBlockId);

        if (page) {
            results = [
                ...results,
                {
                    type: 'page',
                    result: {
                        type: 'block',
                        path: [startIndex, endIndex],
                        block: blocks[startIndex],
                        blockId: embedding.fromBlockId,
                    },
                    blocks,
                    location: {
                        type: 'page',
                        blockId: embedding.fromBlockId,
                        pageId: page.id,
                        blockOffset: endIndex - startIndex,
                    },
                    page,
                    updatedAt: page.updatedAt,
                },
            ];
        }

        if (task) {
            results = [
                ...results,
                {
                    type: 'task',
                    result: {
                        type: 'block',
                        path: [startIndex, endIndex],
                        block: blocks[startIndex],
                        blockId: embedding.fromBlockId,
                    },
                    blocks,
                    location: {
                        type: 'task',
                        blockId: embedding.fromBlockId,
                        taskId: task.id,
                        blockOffset: endIndex - startIndex,
                    },
                    task,
                    updatedAt: task.updatedAt,
                },
            ];
        }
    }

    return results;
}

export default findSpaceEmbeddings;
