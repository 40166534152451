import {
    BlockType,
    FormatBlockElementTypeDef,
    isBlockTypeCurried,
    isCallout,
    isTable,
    isTableCell,
    isTableRow,
    isTitle,
    NonEmptyArray,
} from '@saga/shared';
import { Editor, Range } from 'slate';
import { HexColor } from '../../shared/src/utils/Colors';
import editorAliasses from './i18n/editor_aliases';
import * as api from '@saga/api';

// CONTEXT is a global injected by vite
export const IS_PREVIEW = CONTEXT === 'deploy-preview';
export const IS_DEV = import.meta.env.MODE === 'development';
export const IS_PRODUCTION = !(IS_DEV || IS_PREVIEW);
export const PREVIEW_URL = import.meta.env.VITE_PREVIEW_URL;

// If you need to test tracking locally, you can set this to true
export const SHOULD_TRACK = IS_PRODUCTION;

/**
 * If dev, we use a fixed extension id derived by the web-extension/package.json key property
 * otherwise we use the one created by the chrome web store
 */
export const WEB_EXTENSION_ID = IS_DEV ? 'aapocclcgogkmnckokdopfmhonfmgoek' : 'djikjedagjgoelkkbhjflfmejmpdhmpb';

export const DESKTOP_APP_PROTOCOL = IS_DEV || IS_PREVIEW ? 'sagadev' : 'saga';

export const BACKEND_URL = (() => {
    if (IS_DEV && !IS_PREVIEW) {
        if (import.meta.env.VITE_DISABLE_SSL) {
            return `http://${window.location.hostname}:1234/`;
        }

        return 'https://secure-sync-dev.saga.so:1234/';
    }

    if (IS_PREVIEW) {
        return `https://hocuspocus-pr-${REVIEW_ID}.onrender.com/`;
    }

    return import.meta.env.VITE_EXPRESS_URL;
})();

export const WEBSOCKET_URL = (() => {
    if (IS_DEV && !IS_PREVIEW) {
        if (import.meta.env.VITE_DISABLE_SSL) {
            return `ws://${window.location.hostname}:1234`;
        }

        return 'wss://secure-sync-dev.saga.so:1234';
    }

    if (IS_PREVIEW) {
        return `wss://hocuspocus-pr-${REVIEW_ID}.onrender.com`;
    }

    return import.meta.env.VITE_SYNC_BACKEND_URL;
})();

export const WEBSOCKET_GRAPHQL_URL = `${WEBSOCKET_URL}/graphql`;

export const CONTENT_URL = `https://${
    IS_PRODUCTION
        ? import.meta.env.VITE_CLOUDFRONT_URL
        : import.meta.env.VITE_STAGING_CLOUDFRONT_URL
          ? import.meta.env.VITE_STAGING_CLOUDFRONT_URL
          : 'secure-staging-content.saga.so'
}`;

export const GRAPHQL_URL = BACKEND_URL + 'graphql';

export const formatTypes = (
    language: api.Language,
): {
    label: string;
    value: FormatBlockElementTypeDef;
    aliases: string[];
    disallowIfParentMatches?: Parameters<typeof isBlockTypeCurried>[0];
    isEnabled?: (editor: Editor, range: Range | null, search: string | undefined) => boolean;
    divider?: boolean;
}[] => {
    return [
        {
            label: 'editor.format_text',
            aliases: ['block'],
            value: BlockType.PARAGRAPH,
            disallowIfParentMatches: [isTitle],
        },
        {
            label: 'editor.format_todo',
            aliases: editorAliasses.todo[language],
            value: BlockType.CHECK_LIST_ITEM,
            disallowIfParentMatches: [isTitle],
        },
        {
            label: 'editor.format_task',
            aliases: editorAliasses.task[language],
            value: BlockType.TASK_BLOCK,
            disallowIfParentMatches: [isTitle],
            isEnabled: (_, range, search) => range == null && (search == null || search.trim().length === 0),
        },
        {
            label: 'editor.format_heading_l',
            aliases: editorAliasses.headingLarge[language],
            value: BlockType.HEADING_1,
            disallowIfParentMatches: [isTitle],
        },
        {
            label: 'editor.format_heading_m',
            aliases: editorAliasses.headingMedium[language],
            value: BlockType.HEADING_2,
            disallowIfParentMatches: [isTitle],
        },
        {
            label: 'editor.format_heading_s',
            aliases: editorAliasses.headingSmall[language],
            value: BlockType.HEADING_3,
            disallowIfParentMatches: [isTitle],
        },
        {
            label: 'editor.format_ul',
            aliases: editorAliasses.bulletList[language],
            value: BlockType.BULLET_LIST_ITEM,
            disallowIfParentMatches: [isTitle],
        },
        {
            label: 'editor.format_ol',
            aliases: editorAliasses.numberList[language],
            value: BlockType.NUMBER_LIST_ITEM,
            disallowIfParentMatches: [isTitle],
        },
        {
            label: 'editor.format_img',
            aliases: editorAliasses.image[language],
            value: BlockType.IMAGE,
            disallowIfParentMatches: [isTitle],
        },
        {
            label: 'editor.format_file',
            aliases: editorAliasses.file[language],
            value: BlockType.FILE,
            disallowIfParentMatches: [isTitle],
        },
        {
            label: 'editor.format_table',
            aliases: editorAliasses.table[language],
            value: BlockType.TABLE,
            // Tables should not be created inside table cells, because nested tables are super confusing
            disallowIfParentMatches: [isTable, isTableRow, isTableCell, isTitle],
        },
        {
            label: 'editor.format_callout',
            aliases: editorAliasses.callout[language],
            value: BlockType.CALLOUT,
            disallowIfParentMatches: [isCallout, isTitle],
        },
        {
            label: 'editor.format_quote',
            aliases: editorAliasses.quote[language],
            value: BlockType.QUOTE,
            disallowIfParentMatches: [isTitle],
        },
        {
            label: 'editor.format_divider',
            aliases: editorAliasses.divider[language],
            value: BlockType.DIVIDER,
            disallowIfParentMatches: [isTitle],
        },
        {
            label: 'editor.format_code',
            aliases: editorAliasses.code[language],
            value: BlockType.CODE,
            disallowIfParentMatches: [isTitle],
        },
        {
            label: 'editor.format_equation',
            aliases: editorAliasses.katexBlock[language],
            value: BlockType.KATEX_BLOCK,
            disallowIfParentMatches: [isTitle],
        },
        {
            label: 'editor.format_inline_equation',
            aliases: editorAliasses.katexInline[language],
            value: BlockType.KATEX_INLINE,
            disallowIfParentMatches: [isTitle],
        },
    ];
};

export const AVAILABLE_COLORS: NonEmptyArray<HexColor> = [
    '#101010',
    '#A57CFA',
    '#4EC46E',
    '#FC632C',
    '#269AC7',
    '#626262',
];

export const dndTypes = {
    BLOCK: 'block' as const,
    REFERENCE: 'reference' as const,
    SIDEBAR_PAGE: 'sidebar_page' as const,
    SIDEBAR_TASK: 'sidebar_task' as const,
    SIDEBAR_COLLECTION: 'sidebar_collection' as const,
    SIDEBAR_PAGE_VIEW: 'sidebar_page_view' as const,
    SIDEBAR_TASK_VIEW: 'sidebar_task_view' as const,
    TASK_CARD: 'task_card' as const,
    TABLE_HEADER: 'table_header' as const,
};

export const FREE_SPACES_LIMIT = 100;
