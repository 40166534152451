import React from 'react';
import Toast from '@/components/toast/Toast';
import { FileText, AlertTriangle, Trash } from 'react-feather';
import { Trans, useTranslation } from 'react-i18next';

export const SomethingWentWrongToast = () => {
    const { t } = useTranslation();
    return (
        <Toast icon={<AlertTriangle className="text-saga-red w-6 flex-none mt-1 mr-2" />}>
            <div>
                <Toast.Title>{t('errors.something_went_wrong')}</Toast.Title>
            </div>
            <div className="flex flex-row pt-2 whitespace-pre-line">{t('errors.something_went_wrong_description')}</div>
        </Toast>
    );
};

export const OnContentMovedToast = (
    pageTitle: string,
    onOpenPage: (event: React.MouseEvent) => void,
    onQuickEdit: () => void,
) => {
    const subtitle = (
        <>
            <div>
                <Trans i18nKey="common.selected_blocks_have_been_moved" />
            </div>
            <div className="pt-2 flex flex-row w-full items-center">
                <FileText className="flex-none mr-2" size={16} />
                <div className="truncate font-semibold flex-shrink">{pageTitle}</div>
            </div>
        </>
    );
    return function OnPageCreatedFromText() {
        return (
            <Toast>
                <div>
                    <Toast.Title>
                        <Trans i18nKey="common.blocks_moved" />
                    </Toast.Title>
                    <Toast.Subtitle>{subtitle}</Toast.Subtitle>
                </div>
                <div className="flex flex-row pt-2">
                    <div className="flex-grow">
                        <Toast.CTA variant={'primary'} onClick={onOpenPage}>
                            <Trans i18nKey="common.open_page" />
                        </Toast.CTA>
                    </div>
                    <div className="flex-grow">
                        <Toast.CTA variant={'secondary'} onClick={onQuickEdit}>
                            <Trans i18nKey="common.quick_edit" />
                        </Toast.CTA>
                    </div>
                </div>
            </Toast>
        );
    };
};

export const OnReferenceCreatedToast = (
    pageTitle: string,
    onOpenPage: (event: React.MouseEvent) => void,
    onQuickEdit: () => void,
) => {
    const subtitle = (
        <>
            <div>
                <Trans i18nKey="common.live_block_details" />
            </div>
            <div className="pt-2 flex flex-row w-full items-center">
                <FileText className="flex-none mr-2" size={16} />
                <div className="truncate font-semibold flex-shrink">{pageTitle}</div>
            </div>
        </>
    );
    return function OnPageCreatedFromText() {
        return (
            <Toast>
                <div>
                    <Toast.Title>
                        <Trans i18nKey="common.live_block_created" />
                    </Toast.Title>
                    <Toast.Subtitle>{subtitle}</Toast.Subtitle>
                </div>
                <div className="flex flex-row pt-2">
                    <div className="flex-grow">
                        <Toast.CTA variant={'primary'} onClick={onOpenPage}>
                            <Trans i18nKey="common.open_page" />
                        </Toast.CTA>
                    </div>
                    <div className="flex-grow">
                        <Toast.CTA variant={'secondary'} onClick={onQuickEdit}>
                            <Trans i18nKey="common.quick_edit" />
                        </Toast.CTA>
                    </div>
                </div>
            </Toast>
        );
    };
};

export function SimpleTitleToast({ children }: { children: React.ReactNode }) {
    return (
        <Toast>
            <div>
                <Toast.Title>{children}</Toast.Title>
            </div>
        </Toast>
    );
}

export const OnPageCreated = (
    pageTitle: string,
    onOpenPage: (event: React.MouseEvent) => void,
    onQuickEdit: () => void,
) => {
    const subtitle = (
        <div className="flex flex-row w-full items-center dark:text-saga-text-darkmode">
            <FileText className="flex-none mr-2 " size={16} />
            <div className="truncate font-semibold flex-shrink mr-1">{pageTitle}</div>
            <div className="flex-shrink-0">
                <Trans i18nKey="common.has_been_created" />
            </div>
        </div>
    );
    return function OnPageCreated() {
        return (
            <Toast>
                <div>
                    <Toast.Title>
                        <Trans i18nkey="common.page_created" />
                    </Toast.Title>
                    <Toast.Subtitle>{subtitle}</Toast.Subtitle>
                </div>
                <div className="flex flex-row pt-2">
                    <div className="flex-grow">
                        <Toast.CTA variant={'primary'} onClick={onOpenPage}>
                            <Trans i18nKey="common.open_page" />
                        </Toast.CTA>
                    </div>
                    <div className="flex-grow">
                        <Toast.CTA variant={'secondary'} onClick={onQuickEdit}>
                            <Trans i18nKey="common.quick_edit" />
                        </Toast.CTA>
                    </div>
                </div>
            </Toast>
        );
    };
};

export const OnInviteAccepted = (onOpenWorkspace: () => void) => {
    return function OnPageCreated() {
        return (
            <Toast>
                <div>
                    <Toast.Title>Invite Accepted</Toast.Title>
                </div>
                <div className="flex flex-row pt-2">
                    <div className="flex-grow">
                        <Toast.CTA variant={'primary'} onClick={onOpenWorkspace}>
                            Open Workspace
                        </Toast.CTA>
                    </div>
                </div>
            </Toast>
        );
    };
};

export const OnPageInviteAccepted = (onOpenPage: () => void) => {
    return function OnPageCreated() {
        return (
            <Toast>
                <div>
                    <Toast.Title>Invite Accepted</Toast.Title>
                </div>
                <div className="flex flex-row pt-2">
                    <div className="flex-grow">
                        <Toast.CTA variant={'primary'} onClick={onOpenPage}>
                            Open Page
                        </Toast.CTA>
                    </div>
                </div>
            </Toast>
        );
    };
};

export const OnPageCreatedFromText = (pageTitle: string, onOpenPage: () => void, onQuickEdit: () => void) => {
    const subtitle = (
        <>
            <div>
                <Trans i18nkey="common.selected_blocks_have_been_turned" />
            </div>
            <div className="pt-2 flex flex-row w-full items-center">
                <FileText className="flex-none mr-2" size={16} />
                <div className="truncate font-semibold flex-shrink">{pageTitle}</div>
            </div>
        </>
    );
    return function OnPageCreatedFromText() {
        return (
            <Toast>
                <div>
                    <Toast.Title>
                        <Trans i18nkey="common.page_created" />
                    </Toast.Title>
                    <Toast.Subtitle>{subtitle}</Toast.Subtitle>
                </div>
                <div className="flex flex-row pt-2">
                    <div className="flex-grow">
                        <Toast.CTA variant={'primary'} onClick={onOpenPage}>
                            <Trans i18nkey="common.open_page" />
                        </Toast.CTA>
                    </div>
                    <div className="flex-grow">
                        <Toast.CTA variant={'secondary'} onClick={onQuickEdit}>
                            <Trans i18nkey="common.quick_edit" />
                        </Toast.CTA>
                    </div>
                </div>
            </Toast>
        );
    };
};

export const OnPageArchived = () => {
    return function OnPageArchived() {
        return (
            <Toast icon={<Trash className="text-saga-text w-5 h-5 flex-none mr-2" />}>
                <div>
                    <Toast.Title className="font-bold">
                        <Trans i18nKey="pages.delete_page_toast_title" />
                    </Toast.Title>
                    <Toast.Description>
                        <Trans i18nKey="pages.delete_page_toast_description" />
                    </Toast.Description>
                </div>
            </Toast>
        );
    };
};

export const OnTaskArchived = () => {
    return function OnTaskArchived() {
        return (
            <Toast icon={<Trash className="text-saga-text w-5 h-5 flex-none mr-2" />}>
                <div>
                    <Toast.Title className="font-bold">
                        <Trans i18nKey="tasks.delete_task_toast_title" />
                    </Toast.Title>
                    <Toast.Description>
                        <Trans i18nKey="tasks.delete_task_toast_description" />
                    </Toast.Description>
                </div>
            </Toast>
        );
    };
};
