import { assertYArray } from '../yjs-utils';
import transactInSpace from './transactInSpace';
import { SafeSpace } from '../types';
import getPageMapById from './getPageMapById';
import { assertNonNull } from '../utils';

export default function addPageToCollection(space: SafeSpace, pageId: string, collectionId: string) {
    transactInSpace(space, () => {
        const pageMap = getPageMapById(space, pageId);
        assertNonNull(pageMap);
        const collections = pageMap.get('collections');
        assertYArray(collections);
        if (!collections.toArray().includes(collectionId)) {
            collections.push([collectionId]);
        }
    });
}
