import React from 'react';

export function CloseSidebarIcon({ className }: { className?: string }) {
    return (
        <svg
            className={className}
            width="14"
            height="20"
            viewBox="0 0 14 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.13872 5.19526C9.39907 5.45561 9.39907 5.87772 9.13872 6.13807L5.61013 9.66667L9.13872 13.1953C9.39907 13.4556 9.39907 13.8777 9.13872 14.1381C8.87837 14.3984 8.45626 14.3984 8.19591 14.1381L4.19591 10.1381C3.93556 9.87772 3.93556 9.45561 4.19591 9.19526L8.19591 5.19526C8.45626 4.93491 8.87837 4.93491 9.13872 5.19526Z"
                fill="currentColor"
            />
            <rect x="5" y="9" width="9" height="1.5" rx="0.75" fill="currentColor" />
            <rect x="2" width="20" height="2" rx="1" transform="rotate(90 2 0)" fill="currentColor" />
        </svg>
    );
}
