import { track } from '@/analytics';
import { CollectionIcon, PageIcon } from '@/components/icons';
import { CreatePageSuggestion } from '@/types';
import * as api from '@saga/api';
import {
    Collection,
    CustomTemplate,
    newBlankPage,
    PredefinedTemplate,
    removeNullable,
    SpaceOperations,
} from '@saga/shared';
import * as R from 'ramda';
import React from 'react';
import { CreateSuggestionButton } from '../editor/Suggestions';
import { filterSuggestions } from '../popover/Dropdown';
import Button from '../styled/Button';
import { InputDropdown, InputDropdownRenderItem, RenderItemProps } from './Dropdown';
import { appendCreatePageSuggestion } from './PagesCollectionsPopOver';
import { PopOver } from './PopOver';
import { useTranslation } from 'react-i18next';
import { CheckCircle, File, IconProps } from 'react-feather';
import { useSpace } from '../SpaceProvider';
import { useOpenCollection, useOpenPage, useOpenTask } from '../PageNavigationProvider';
import { TableIcon } from '../icons/Table';
import { createPage, createTask } from '@/utils/documentUtils';
import { useTaskFilters } from '@/hooks/useTaskFilters';

type CustomTemplateSuggestion = { type: 'template'; title: string; template: CustomTemplate };
type PredefinedTemplateSuggestion = { type: 'predefined-template'; title: string; template: PredefinedTemplate };
type ExploreTemplatesSuggestion = { type: 'explore-templates' };
type CreateOperations = { type: 'create_page' | 'create_task' | 'create_collection' | 'explore-templates' };

const createPageKeywords = ['page', 'new page'];
const createTaskKeywords = ['task', 'new task'];
const createCollectionKeywords = ['collection', 'new collection'];

const matchKeywordsWithSearch = (search: string): boolean => {
    switch (search.toLowerCase()) {
        case createPageKeywords.find((keyword) => search.match(keyword)):
            return true;
        case createTaskKeywords.find((keyword) => search.match(keyword)):
            return true;
        case createCollectionKeywords.find((keyword) => search.match(keyword)):
            return true;

        default:
            return false;
    }
};

const createInputDropdownRenderItem = (
    type: 'create_page' | 'create_task' | 'create_collection' | 'explore-templates',
    icon: IconProps | Collection['icon'],
    text: string,
    singleStaticOption?: boolean,
): InputDropdownRenderItem<CreateOperations> => ({
    render({ isSelected, setSelection, submit, index }) {
        return (
            <React.Fragment key={index}>
                {type === 'explore-templates' && (
                    <div className="py-0.5">
                        <PopOver.Divider />
                    </div>
                )}
                {(type === 'create_page' || singleStaticOption) && <PopOver.Label>{text}</PopOver.Label>}
                <div>
                    <Button.PopOverButton selected={isSelected} onMouseEnter={setSelection} onClick={submit}>
                        <div className="flex-none mr-2">{icon}</div>
                        <p className="pt-0.5 truncate min-h-[24px]">{text}</p>
                    </Button.PopOverButton>
                </div>
                {(type === 'create_collection' || singleStaticOption) && (
                    <div className="py-0.5">
                        <PopOver.Divider />
                    </div>
                )}
            </React.Fragment>
        );
    },
    item: {
        type,
    },
});

const addCreateSectionOptions = (search: string, t: any) => {
    switch (search.toLowerCase()) {
        case createPageKeywords.find((keyword) => keyword.match(search.toLowerCase())):
            return [createInputDropdownRenderItem('create_page', <File size={14} />, t('pages.new.label_page'), true)];

        case createTaskKeywords.find((keyword) => keyword.match(search.toLowerCase())):
            return [createInputDropdownRenderItem('create_task', <CheckCircle size={14} />, t('tasks.new_task'), true)];

        case createCollectionKeywords.find((keyword) => keyword.match(search.toLowerCase())):
            return [
                createInputDropdownRenderItem(
                    'create_collection',
                    <CollectionIcon icon={undefined} />,
                    t('collections.new_collection'),
                    true,
                ),
            ];

        case '':
            return [
                createInputDropdownRenderItem('create_page', <File size={14} />, t('pages.new.label_page')),
                createInputDropdownRenderItem('create_task', <CheckCircle size={14} />, t('tasks.new_task')),
                createInputDropdownRenderItem(
                    'create_collection',
                    <CollectionIcon icon={undefined} />,
                    t('collections.new_collection'),
                ),
            ];

        default:
            return [];
    }
};

function mapTemplateToSuggestion(template: CustomTemplate): CustomTemplateSuggestion {
    return { type: 'template', title: template.title, template };
}

function mapPredefinedTemplateToSuggestion(template: PredefinedTemplate): PredefinedTemplateSuggestion {
    return { type: 'predefined-template', title: template.title, template };
}

export type Props = {
    isOpen: boolean;
    onClose: () => void;
    onExploreTemplatesClick(): void;
    attachToRef: React.MutableRefObject<HTMLElement | null>;
    inputProps: {
        placeholder: string;
        title?: string;
    };
    onCreateTemplate(title: string, event: Event | React.SyntheticEvent<Element, Event>): void;
    onApplyPredefinedTemplate(template: PredefinedTemplate, event: Event | React.SyntheticEvent<Element, Event>): void;
    onApplyCustomTemplate(template: CustomTemplate, event: Event | React.SyntheticEvent<Element, Event>): void;
    recentlyUsedTemplateIds: string[];
    customTemplates: CustomTemplate[];
};

function TemplatesPopOver({
    isOpen,
    onClose,
    attachToRef,
    onExploreTemplatesClick,
    inputProps,
    onCreateTemplate,
    onApplyPredefinedTemplate,
    onApplyCustomTemplate,
    recentlyUsedTemplateIds,
    customTemplates,
}: Props) {
    const { data } = api.useTemplatesQuery();
    const { t } = useTranslation();
    const { space, provider } = useSpace();
    const goToPage = useOpenPage();
    const goToTask = useOpenTask();
    const goToCollection = useOpenCollection();
    const taskFilters = useTaskFilters();

    const predefinedTemplateSuggestions =
        data?.templates.map((template) =>
            mapPredefinedTemplateToSuggestion({
                id: template.id,
                icon: template.icon ? { type: 'emoji', colons: template.icon } : undefined,
                aliases: template.aliases,
                title: template.title,
                blocks: template.blocks,
            }),
        ) ?? [];

    const currentSuggestions = [...customTemplates.map(mapTemplateToSuggestion), ...predefinedTemplateSuggestions];

    const EXPLORE_TEMPLATES_RENDER_ITEM = createInputDropdownRenderItem(
        'explore-templates',
        <TableIcon size={14} />,
        t('editor.all_templates'),
    );

    const recentTemplateSuggestions = (() => {
        const recently = recentlyUsedTemplateIds
            .slice(0, 5)
            .map((id) => currentSuggestions.find(({ template }) => template.id === id))
            .filter(removeNullable);

        if (recently.length === 5) return recently;

        const ownTemplatesSorted = currentSuggestions
            .filter((a): a is CustomTemplateSuggestion => a.type === 'template')
            .sort((a, b) => {
                return new Date(b.template.createdAt).getTime() - new Date(a.template.createdAt).getTime();
            });

        const recentlyUsedWithNewlyTemplates = R.uniqBy(
            (a) => a.template.id,
            recently.concat(ownTemplatesSorted).slice(0, 5),
        );

        if (recentlyUsedWithNewlyTemplates.length === 5) return recentlyUsedWithNewlyTemplates;

        return R.uniqBy((a) => a.template.id, recentlyUsedWithNewlyTemplates.concat(currentSuggestions).slice(0, 5));
    })();

    return (
        <InputDropdown<
            | CustomTemplateSuggestion
            | PredefinedTemplateSuggestion
            | CreatePageSuggestion
            | ExploreTemplatesSuggestion
            | CreateOperations
        >
            attachToRef={attachToRef}
            isOpen={isOpen}
            onClose={onClose}
            onSubmit={({ event, selectedItem: item }) => {
                onClose();

                switch (item.type) {
                    case 'create': {
                        track('create-new-template', { source: 'template-dropdown' });
                        onCreateTemplate(item.title, event);
                        break;
                    }
                    case 'explore-templates': {
                        track('explore-templates-click', { source: 'template-dropdown' });
                        onExploreTemplatesClick();
                        break;
                    }
                    case 'predefined-template': {
                        track('use-template', { templateId: item.template.id, source: 'template-dropdown' });
                        onApplyPredefinedTemplate(item.template, event);
                        break;
                    }
                    case 'template': {
                        track('use-template', { templateId: item.template.id, source: 'template-dropdown' });
                        onApplyCustomTemplate(item.template, event);
                        break;
                    }
                    case 'create_page': {
                        track('create_page', { source: 'template-dropdown' });
                        const page = createPage(space, newBlankPage({}), provider);
                        goToPage(page.id, event);
                        break;
                    }
                    case 'create_task': {
                        track('create_task', { source: 'template-dropdown' });
                        const task = createTask(space, { title: '', ...taskFilters }, provider);
                        goToTask(task.id, event);

                        break;
                    }
                    case 'create_collection': {
                        track('create_collection', { source: 'template-dropdown' });
                        const collection = SpaceOperations.createCollection(space, '');
                        goToCollection(collection.id, event);
                        break;
                    }
                }
            }}
            inputProps={inputProps}
            dropdownProps={{
                align: 'left',
                maxWHighlight: true,
                testId: 'template-dropdown',
            }}
            renderItems={({ search }) => {
                const suggestions =
                    search === ''
                        ? recentTemplateSuggestions
                        : appendCreatePageSuggestion(filterSuggestions(currentSuggestions, search), search, true);

                const suggestionsItems: InputDropdownRenderItem<
                    CustomTemplateSuggestion | PredefinedTemplateSuggestion | CreatePageSuggestion
                >[] = suggestions.map((item) => {
                    const render = ({ isSelected, setSelection, submit, index }: RenderItemProps) => {
                        return (
                            <React.Fragment key={index}>
                                {suggestions.length >= 1 && index === 3 && search === '' && (
                                    <>
                                        <PopOver.Label>
                                            {inputProps.title ? inputProps.title : t('common.recent_templates')}
                                        </PopOver.Label>
                                    </>
                                )}
                                {suggestions.length >= 1 && index === 0 && search !== '' && (
                                    <>
                                        <PopOver.Label>
                                            {inputProps.title
                                                ? inputProps.title
                                                : t('common.create_page_from_template')}
                                        </PopOver.Label>
                                    </>
                                )}
                                {suggestions.length >= 1 && index === 1 && matchKeywordsWithSearch(search) && (
                                    <>
                                        <PopOver.Label>
                                            {inputProps.title
                                                ? inputProps.title
                                                : t('common.create_page_from_template')}
                                        </PopOver.Label>
                                    </>
                                )}

                                <SuggestionButton
                                    suggestion={item}
                                    selected={isSelected}
                                    onMouseEnter={setSelection}
                                    onClick={submit}
                                    showIcons={true}
                                >
                                    {item.title.trim().length > 0 ? item.title : 'Untitled'}
                                </SuggestionButton>
                            </React.Fragment>
                        );
                    };

                    return {
                        render,
                        item,
                    };
                });

                const addStaticOptions = addCreateSectionOptions(search, t);

                return [...addStaticOptions, ...suggestionsItems, EXPLORE_TEMPLATES_RENDER_ITEM];
            }}
        />
    );
}

const SuggestionButton: React.FC<
    Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'className'> & {
        selected?: boolean;
        suggestion: CustomTemplateSuggestion | PredefinedTemplateSuggestion | CreatePageSuggestion;
        showIcons?: boolean;
        hoverable?: boolean;
    }
> = ({ selected, children, suggestion, showIcons = false, ...props }) => {
    if (suggestion.type === 'create') {
        return (
            <CreateSuggestionButton selected={selected} showIcons={showIcons} suggestion={suggestion} {...props}>
                {children}
            </CreateSuggestionButton>
        );
    }

    return (
        <Button.PopOverButton selected={selected} {...props}>
            {showIcons && (suggestion.type === 'template' || suggestion.type === 'predefined-template') && (
                <div className="flex-none mr-2">
                    <PageIcon icon={suggestion.template.icon} size={14} isTemplate />
                </div>
            )}

            <p className="my-auto truncate min-h-[24px] pt-0.5">{children}</p>
        </Button.PopOverButton>
    );
};

export default TemplatesPopOver;
