import { Collection } from '../types';
import { yarrayFromArray } from '../yjs-utils';
import findCollectionById from './findCollectionById';
import transactInSpace from './transactInSpace';
import { SafeSpace } from '../types';

export default function setSubCollections(space: SafeSpace, id: string, subCollections: Collection['subCollections']) {
    const collection = findCollectionById(space, id);
    transactInSpace(space, () => {
        collection.set('subCollections', yarrayFromArray(subCollections ?? []));
    });
}
