import { Root } from '..';
import { gfmToMarkdown } from 'mdast-util-gfm';
import { gfmTaskListItemToMarkdown } from 'mdast-util-gfm-task-list-item';
import { sagaToMdast } from './sagaToMdast';
import { toMarkdown } from 'mdast-util-to-markdown';

export function sagaToMd(root: Root) {
    const result = sagaToMdast(root);

    return toMarkdown(
        // @ts-expect-error types are not matching up from mdast and unist
        result,
        {
            listItemIndent: 'one',
            bullet: '-',
            incrementListMarker: true,
            extensions: [gfmToMarkdown(), gfmTaskListItemToMarkdown],
        },
    );
}
