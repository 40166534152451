import { newBlankCollection } from '../../pageUtils';
import * as api from '@saga/api';

const title = {
    [api.Language.En]: 'New to Saga',
    [api.Language.Fr]: 'Nouveau sur Saga',
    [api.Language.Es]: 'Nueva en Saga',
    [api.Language.Pt]: 'Novo no Saga',
    [api.Language.De]: 'Neu bei Saga',
    [api.Language.It]: 'Nuovo su Saga',
};
export function newToSagaCollection(language: api.Language) {
    return newBlankCollection({ title: title[language], defaultExpanded: true });
}
