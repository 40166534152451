import React, { useState, useContext } from 'react';
import SearchModal from '@/components/search/SearchModal';
import { Page, SagaLocation } from '@saga/shared';
import isHotkey from 'is-hotkey';
import ReactModal from 'react-modal';
import useOnEscape from '@/hooks/useOnEscape';
import { track } from '@/analytics';

type SearchContextType = {
    searchQuery: string | null;
    search: (text: string, tags?: string[]) => void;
    resetSearch: () => void;
};

export const SearchContext = React.createContext<SearchContextType>({
    searchQuery: null,
    search: () => [],
    resetSearch: () => {},
});

export function useSearchContext() {
    return useContext(SearchContext);
}

const SearchOpenContext = React.createContext(false);
export const useSearchOpen = () => useContext(SearchOpenContext);

const SetSearchOpenContext = React.createContext<React.Dispatch<React.SetStateAction<boolean>>>(() => {});
export const useSetSearchOpen = () => {
    const setter = useContext(SetSearchOpenContext);
    return setter;
};

export function SearchModalContainer({ currentLocation }: { currentLocation: SagaLocation.SagaLocation }) {
    const isSearchOpen = useSearchOpen();
    const setSearchOpen = useSetSearchOpen();

    useOnEscape(() => setSearchOpen(false), isSearchOpen);

    if (isSearchOpen == null) {
        return null;
    }

    return (
        <ReactModal
            isOpen={isSearchOpen}
            shouldCloseOnEsc
            shouldCloseOnOverlayClick
            onRequestClose={() => setSearchOpen(false)}
            shouldReturnFocusAfterClose={false}
            style={{
                overlay: {
                    background: 'rgba(0, 0, 0, 0.5)',
                    zIndex: 400,
                },
                content: {
                    border: undefined,
                    background: undefined,
                    inset: undefined,
                    padding: undefined,
                    width: '100%',
                    height: '100%',
                },
            }}
        >
            <div className="h-full w-full flex justify-center items-start cursor-default p-2">
                <SearchModal isOpen={isSearchOpen} setIsOpen={setSearchOpen} currentLocation={currentLocation} />
            </div>
        </ReactModal>
    );
}

export type PageForSearch = Pick<Page, 'blocks' | 'settings' | 'title' | 'id' | 'aliases'>;
const OPEN_SEARCH = 'mod+k';

type Callback = () => void;
type Unobserve = () => void;

class SearchState {
    private listeners: Callback[] = [];
    public searchQuery: string | null = null;

    observe(cb: Callback): Unobserve {
        this.listeners.push(cb);
        return () => {
            this.listeners = this.listeners.filter((l) => l !== cb);
        };
    }

    notifyAll() {
        this.listeners.forEach((cb) => cb());
    }

    resetSearch() {
        this.searchQuery = null;
        this.notifyAll();
    }

    search(value: string) {
        this.searchQuery = value;
        this.notifyAll();
    }
}

// the global search state is a singleton implemented by a providerless react context
export const GlobalSearchStateContext = React.createContext(new SearchState());

function useGlobalSearchState() {
    const searchState = React.useContext(GlobalSearchStateContext);
    const [rerenderTrigger, rerender] = React.useReducer(() => ({}), {});

    const resetSearch = React.useCallback(() => {
        searchState.resetSearch();
    }, [searchState]);

    const search = React.useCallback(
        (value: string) => {
            searchState.search(value);
        },
        [searchState],
    );

    const _searchState = React.useMemo(() => {
        rerenderTrigger;
        return {
            searchQuery: searchState.searchQuery,
            search,
            resetSearch,
        };
    }, [rerenderTrigger, searchState, resetSearch, search]);

    React.useEffect(() => {
        const unobserve = searchState.observe(rerender);
        return unobserve;
    }, [searchState]);

    return _searchState;
}

let hasOpenSearchModal = false;

export const SearchContextProvider: React.FC<{
    onChange?: (value: boolean) => void;
    scopeToContainer?: React.RefObject<HTMLElement>;
}> = ({ children, onChange, scopeToContainer }) => {
    const globalSearchState = useGlobalSearchState();

    const [isSearchOpen, setSearchOpen] = useState<boolean>(false);

    hasOpenSearchModal = isSearchOpen;

    const onChangeHandler = React.useRef(onChange);
    onChangeHandler.current = onChange;

    React.useEffect(() => {
        if (onChangeHandler.current) {
            onChangeHandler.current(isSearchOpen);
        }
    }, [isSearchOpen]);

    React.useEffect(() => {
        const containerElement = scopeToContainer?.current ?? document.body;

        const shortcutOpen = (event: KeyboardEvent) => {
            if (isHotkey(OPEN_SEARCH, event) && !hasOpenSearchModal) {
                event.preventDefault();
                event.stopImmediatePropagation();
                track('open-search-bar', { source: 'shortcut-mod+k' });
                setSearchOpen((isSearchOpen) => !isSearchOpen);
            }
        };
        containerElement.addEventListener('keydown', shortcutOpen);
        return () => {
            containerElement.removeEventListener('keydown', shortcutOpen);
        };
    }, [scopeToContainer]);

    return (
        <SearchContext.Provider value={globalSearchState}>
            <SearchOpenContext.Provider value={isSearchOpen}>
                <SetSearchOpenContext.Provider value={setSearchOpen}>{children}</SetSearchOpenContext.Provider>
            </SearchOpenContext.Provider>
        </SearchContext.Provider>
    );
};
