import * as t from 'io-ts';
import { BaseEditor, BaseSelection } from 'slate';
import { ReactEditor } from 'slate-react';
import { Title, AnyBlockItem, blockD, BlockD } from './block-types';
import { SafeMapOutput } from './io-ts-js';
import { BlocksLocation } from './SagaLocation';
import { CursorEditor } from './slateYjs';
import { SagaLocation } from '.';

const textPropertyD = t.type({
    id: t.string,
    type: t.literal('text'),
    label: t.string,
});

const emojiIconD = t.type({
    type: t.literal('emoji'),
    colons: t.string,
});

export type EmojiIcon = t.TypeOf<typeof emojiIconD>;

const propertyD = textPropertyD;

export type Property = t.TypeOf<typeof propertyD>;

export const sortingD = t.type({
    by: t.string,
    order: t.union([t.literal('asc'), t.literal('desc')]),
});

export type Sorting = t.TypeOf<typeof sortingD>;

export const collectionD = t.intersection([
    t.type({
        id: t.string,
        subCollections: t.union([t.array(t.string), t.null, t.undefined]),
        title: t.string,
        createdAt: t.string,
        updatedAt: t.string,
        properties: t.array(t.string),
        sorting: t.union([sortingD, t.null, t.undefined]),
        icon: t.union([t.undefined, emojiIconD]),
    }),
    t.partial({
        defaultExpanded: t.union([t.boolean, t.null, t.undefined]),
    }),
]);

export type Collection = t.TypeOf<typeof collectionD>;

const pagePropertyD = t.type({
    id: t.string,
    value: t.string,
});

export type PageProperty = t.TypeOf<typeof pagePropertyD>;

const pageSettingsD = t.partial({
    linkTitle: t.boolean,
    linkHeadings: t.boolean,
    fullWidth: t.boolean,
});

const referenceRegistryEntryD = t.type({
    pageId: t.string,
    liveReferenceSourceId: t.string,
    // TODO: improve me please!!!!!!!
    liveBlocks: t.array(t.any),
    isArchived: t.boolean,
});

const referencesRegistryD = t.array(referenceRegistryEntryD);

export type ReferenceRegistryEntry = t.TypeOf<typeof referenceRegistryEntryD>;
export type ReferencesRegistry = t.TypeOf<typeof referencesRegistryD>;

const blocksD = t.array(t.any);

export type WeakBlocks = t.TypeOf<typeof blocksD>;
export type WeakBlock = BlockD & { id: string };

export const taskStateD = t.union([t.literal('OPEN'), t.literal('DONE'), t.literal('IN_PROGRESS')]);
export const taskPriorityD = t.union([t.null, t.literal('LOW'), t.literal('MEDIUM'), t.literal('HIGH')]);
export const taskLabelD = t.type({
    id: t.string,
    title: t.string,
    color: t.string,
});

export const multiSelectD = t.union([t.array(t.string), t.literal('all'), t.literal('none'), t.null, t.undefined]);

export const pageFiltersD = t.type({
    allPagesSorting: t.union([sortingD, t.null]),
    allPagesCollections: multiSelectD,
    allPagesCreators: multiSelectD,
});

export const taskFiltersD = t.type({
    allTasksSorting: t.union([sortingD, t.null]),
    allTasksMode: t.union([
        t.union([t.literal('completed'), t.literal('all'), t.literal('incomplete'), t.literal('archived')]),
        t.null,
    ]),
    allTasksPriority: t.union([
        t.union([
            t.literal('unprioritized'),
            t.literal('all'),
            t.literal('HIGH'),
            t.literal('MEDIUM'),
            t.literal('LOW'),
        ]),
        t.null,
    ]),
    allTasksAssignee: t.union([
        t.type({ kind: t.union([t.literal('unassigned'), t.literal('all')]) }),
        t.type({ kind: t.literal('assigned'), memberId: t.string }),
        t.null,
    ]),
    allTasksLabels: multiSelectD,
    allTasksCollections: multiSelectD,
    allTasksCreators: multiSelectD,
});

export type TaskLabel = t.TypeOf<typeof taskLabelD>;

export const taskD = t.intersection([
    t.type({
        id: t.string,
        createdAt: t.string,
        createdBy: t.union([t.undefined, t.string]),
        updatedAt: t.string,
        assignee: t.union([t.null, t.string]),
        state: taskStateD,
        title: t.union([t.string, t.undefined]),
        archivedAt: t.union([t.string, t.undefined]),
        collections: t.union([t.array(t.string), t.undefined]),
    }),
    t.partial({
        dueDate: t.union([t.null, t.string]),
        completedDate: t.union([t.null, t.string]),
        priority: taskPriorityD,
        labels: t.union([t.array(t.string), t.undefined]),
    }),
]);

export const taskViewD = t.intersection([
    t.type({
        id: t.union([t.string, t.literal('all')]),
        isDefaultView: t.boolean,
    }),
    t.intersection([
        taskFiltersD,
        t.intersection([
            t.type({
                title: t.union([t.string, t.literal('all')]),
                search: t.union([t.string, t.undefined]),
            }),
            t.partial({
                createdAt: t.string,
                createdBy: t.string,
            }),
        ]),
    ]),
]);

export const pageViewD = t.intersection([
    t.type({
        id: t.union([
            t.string,
            t.literal('all'),
            t.literal('deleted'),
            t.literal('shared'),
            t.literal('non-deleted'),
            t.literal('templates'),
            t.literal('private'),
            t.literal('public'),
        ]),
        mode: t.union([
            t.literal('all'),
            t.literal('deleted'),
            t.literal('shared'),
            t.literal('non-deleted'),
            t.literal('templates'),
            t.literal('private'),
            t.literal('public'),
        ]),
        isDefaultView: t.boolean,
    }),
    t.intersection([
        pageFiltersD,
        t.partial({
            title: t.string,
            search: t.union([t.string, t.undefined]),
            createdAt: t.string,
            createdBy: t.string,
        }),
    ]),
]);

export const pageD = t.type({
    version: t.union([t.string, t.undefined]),
    id: t.string,
    title: t.string,
    aliases: t.array(t.string),
    createdAt: t.string,
    createdBy: t.union([t.undefined, t.string]),
    updatedAt: t.string,
    archivedAt: t.union([t.string, t.undefined]),
    collections: t.array(t.string),
    properties: t.array(pagePropertyD),
    wordCount: t.number,
    settings: pageSettingsD,
    icon: t.union([t.undefined, emojiIconD]),
    isTemplate: t.union([t.boolean, t.undefined]),
    createdFromTemplate: t.union([t.string, t.undefined]),
    isPublicDuplicatable: t.union([t.boolean, t.undefined]),
});

export const userFavoritesD = t.record(t.string, t.array(t.string));

export const spaceD = t.type({
    version: t.union([t.string, t.undefined]),
    id: t.string,
    createdAt: t.number,
    pinned: t.array(t.string),
    properties: t.array(propertyD),
    collections: t.array(collectionD),
    pages: t.array(pageD),
    tasks: t.array(taskD),
    referencesRegistry: referencesRegistryD,
    taskLabels: t.union([t.array(taskLabelD), t.undefined]),
    userFavorites: t.union([userFavoritesD, t.undefined]),
    autolinkEnabled: t.union([t.boolean, t.undefined]),
    taskViews: t.union([t.array(taskViewD), t.undefined]),
    pageViews: t.union([t.array(pageViewD), t.undefined]),
});

export const contentD = t.type({
    id: t.string,
    spaceId: t.string,
    blocks: t.array(blockD),
    icon: t.union([t.undefined, emojiIconD]),
});

export const pagesColumnKeysD = t.union([
    t.literal('title'),
    t.literal('collection'),
    t.literal('wordCount'),
    t.literal('createdBy'),
    t.literal('created'),
    t.literal('updated'),
]);

export const sharedPagesColumnKeysD = t.union([
    t.literal('title'),
    t.literal('sharedBy'),
    t.literal('created'),
    t.literal('role'),
]);

export const tasksColumnKeysD = t.union([
    t.literal('title'),
    t.literal('collection'),
    t.literal('assignee'),
    t.literal('dueDate'),
    t.literal('priority'),
    t.literal('references'),
    t.literal('labels'),
    t.literal('createdBy'),
    t.literal('created'),
    t.literal('updated'),
    t.literal('completedDate'),
]);

export const collectionsColumnKeysD = t.union([
    t.literal('title'),
    t.literal('pageCount'),
    t.literal('created'),
    t.literal('updated'),
]);

export const extendedUserSettingsD = t.type({
    pagesColumnsOrder: t.union([t.array(pagesColumnKeysD), t.undefined]),
    sharedPagesColumnsOrder: t.union([t.array(sharedPagesColumnKeysD), t.undefined]),
    tasksColumnsOrder: t.union([t.array(tasksColumnKeysD), t.undefined]),
    collectionsColumnsOrder: t.union([t.array(collectionsColumnKeysD), t.undefined]),
});

export type PagesTableColumnKey = t.TypeOf<typeof pagesColumnKeysD>;
export type SharedPagesTableColumnKey = t.TypeOf<typeof sharedPagesColumnKeysD>;
export type TasksTableColumnKey = t.TypeOf<typeof tasksColumnKeysD>;
export type CollectionsTableColumnKey = t.TypeOf<typeof collectionsColumnKeysD>;

export type ExtendedUserSettings = t.TypeOf<typeof extendedUserSettingsD>;

export type Space = t.TypeOf<typeof spaceD>;

export type WeakPage = t.TypeOf<typeof pageD>;
export type WeakTask = t.TypeOf<typeof taskD>;
export type WeakDocumentContent = t.TypeOf<typeof contentD>;
export type SpaceBlocks = Map<string, WeakBlocks>;

export type CustomTemplate = Pick<WeakPage, 'id' | 'title' | 'icon' | 'createdAt' | 'aliases' | 'collections'> & {
    blocks?: PageContent;
};
export type PredefinedTemplate = Pick<WeakPage, 'id' | 'title' | 'icon' | 'aliases'> & { blocks?: PageContent };

export type Page = WeakPage & { blocks: BlockD[] };
export type Task = WeakTask & { blocks: BlockD[] };

export type PageView = t.TypeOf<typeof pageViewD>;
export type TaskView = t.TypeOf<typeof taskViewD>;

export type PageContent = [Title, ...Array<AnyBlockItem>] | [];
export type TaskContent = [Title, ...Array<AnyBlockItem>] | [];
export type PageSettings = {
    linkTitle: boolean;
    linkHeadings: boolean;
};

export type SafeSpace = SafeMapOutput<Space>;
export type SafeDocumentContent = SafeMapOutput<WeakDocumentContent>;

export * from './block-types';

/**
 * type derived by https://oembed.com/providers.json
 */
export type OEmbedProvider = {
    provider_name: string;
    provider_url: string;
    endpoints?: {
        schemes?: string[];
        url: string;
        discovery: boolean;
    }[];
};

export type SpaceProvider = (opts: { documentName: string; token: string }) => Promise<SafeSpace>;
export type SpaceBlocksProvider = (opts: { spaceId: string; token: string }) => Promise<{ [key: string]: WeakBlocks }>;
export type DocumentBlocksProvider = (opts: {
    spaceId: string;
    documentId: string;
    token: string;
}) => Promise<WeakBlocks>;

export type BaseSagaEditor = BaseEditor & ReactEditor & { location: BlocksLocation };

// CursorEditor extends YjsEditor which extends BaseSagaEditor
type UndoProps = {
    redo: () => any;
    undo: () => any;
    canUndo: () => boolean;
    canRedo: () => boolean;
};
export type RealtimeSagaEditor = CursorEditor & UndoProps & { origin: any; savedSelection?: BaseSelection };

export type NonEmptyArray<T> = [T, ...T[]];

export type AIPrompt = { prompt: string; textContext?: string; references: AISourceItem[] };
export type AIRole = 'assistant' | 'system' | 'user' | 'tool';
export type AIMessage = {
    role: AIRole;
    content?: string | null;
    id?: string;
    tool_calls?: { id: string; type: 'function'; index: number; function: { name: string; arguments?: string } }[];
    tool_call_id?: string | null;
};
export type AISourceItem = {
    title: string;
    id: string;
    icon?: { type: 'emoji'; colons: string };
    isTemplate?: boolean;
    type: 'task' | 'page';
    updatedAt: string;
};
export type SagaAIStatus = 'enabled' | 'disabled' | 'restricted';
export enum AIFunctionName {
    getSpaceContext = 'get_space_context',
    getDocumentContext = 'get_document_context',
}

type BaseItem = {
    id: string;
    names: string[];
};

type PageItem = BaseItem & { type: 'page'; location: SagaLocation.PageLocation };
type HeadingItem = BaseItem & {
    type: 'heading';
    location: SagaLocation.PageLocation;
    heading: { id: string; title: string };
};

export type IndexedItem = PageItem | HeadingItem;

export type AutolinkResult = {
    matches: {
        matched: string;
        offset: {
            start: number;
            end: number;
        };
    }[];
    item: IndexedItem;
    id: string;
};

export type TransformOptions = { replaceImageUrl(url: string): Promise<string> };

type StatelessPayloaSpaceblocksRequest = {
    type: 'spaceblocksRequest';
};
type StatelessPayloadSpaceblocksReceive = {
    type: 'spaceblocksReceive';
    data: { [key: string]: any[] };
};
type StatelessPayloadDocumentData = {
    type: 'documentData';
    data: string;
    objectId: string;
};

export type StatelessPayload =
    | StatelessPayloadSpaceblocksReceive
    | StatelessPayloaSpaceblocksRequest
    | StatelessPayloadDocumentData;
