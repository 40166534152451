import React from 'react';
import * as Y from 'yjs';

export default function useOnTextChange(
    yBlocks: Y.Array<any> | undefined | null,
    origin: any,
    onChange: (events: Y.YEvent<any>[]) => void,
) {
    const handler = React.useRef({ onChange });
    handler.current.onChange = onChange;

    React.useEffect(() => {
        if (!yBlocks) return;
        function observe(events: Y.YEvent<any>[], tx: Y.Transaction) {
            if (tx.origin === origin && events.some((e) => e instanceof Y.YTextEvent)) {
                handler.current.onChange(events);
            }
        }
        yBlocks.observeDeep(observe);
        return () => yBlocks.unobserveDeep(observe);
    }, [yBlocks, origin]);
}
