import { myFirstPage } from './MyFirstPage';
import { newSagaAiPage } from './SagaAI';
import { newSagaBasicsPage } from './SagaBasics';
import { newSagaAdvancedPage } from './SagaAdvanced';
import { newToSagaCollection } from './NewToSagaCollection';
import { newTask } from './LearnMoreAboutTasks';

import * as api from '@saga/api';

export function generateSpaceContent(language: api.Language) {
    const task = newTask({ language });
    const collection = newToSagaCollection(language);
    const firstPage = myFirstPage(language);
    const sagaBasicsPage = newSagaBasicsPage({ task, language, collectionIds: [collection.id] });
    const sagaAdvancedPage = newSagaAdvancedPage({ language, collectionIds: [collection.id] });
    const sagaAiPage = newSagaAiPage({ language, collectionIds: [collection.id] });

    return {
        task,
        collection,
        pages: [firstPage, sagaBasicsPage, sagaAdvancedPage, sagaAiPage].flatMap((page) => (page ? [page] : [])),
    };
}
