import React from 'react';
import { PageForEditor } from '../editor/SpacePageEditor';
import { removeNullable, SagaEditor as SharedEditor, SagaLocation } from '@saga/shared';
import ManageCollectionsInput from '../popover/ManageCollectionsInput';
import useMobile from '@/hooks/useMobile';
import { useCollectionsSnapshot } from '@/hooks/SpaceHooks';
import { usePerformActionWithYBlocks } from '@/components/RealtimeDocumentProvider';
import StaticEditor from '@/components/editor/StaticEditor';

export default function TemplatePreview({
    template,
    blockPlugins,
}: {
    template: PageForEditor & { collections: string[]; blocks?: any[] };
    blockPlugins: SharedEditor.Plugins.BlockPlugin[];
}) {
    const isMobile = useMobile();
    const collections = useCollectionsSnapshot();
    const location = React.useMemo(() => SagaLocation.pageLocationFromId(template.id), [template.id]);
    const [blocks, setBlocks] = React.useState<any[] | undefined>(template.blocks);
    const performActionWithBlocks = usePerformActionWithYBlocks();

    React.useEffect(() => {
        if (template.blocks) {
            setBlocks(template.blocks);
        } else {
            setBlocks([]);
            performActionWithBlocks(location, (templateBlocks) => {
                setBlocks(templateBlocks.toJSON());
            });
        }
    }, [location, performActionWithBlocks, template.blocks]);

    return (
        <div className="dark:bg-saga-gray-1000 bg-white px-4 py-12 relative">
            <div className="absolute left-4 top-4">
                <ManageCollectionsInput
                    currentCollections={template.collections
                        .map((id) => collections.find((c) => c.id === id))
                        .filter(removeNullable)}
                    availableCollections={[]}
                    canEdit={false}
                    onCollectionClick={() => {}}
                    onCreate={() => {}}
                    onRemove={() => {}}
                    onSelect={() => {}}
                    maxWidth={isMobile ? 250 : 400}
                />
            </div>

            {blocks ? <StaticEditor blocks={blocks} location={location} blockPlugins={blockPlugins} /> : null}
        </div>
    );
}
