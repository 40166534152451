import { isFeatureEnabled, track } from '@/analytics';
import useMobile from '@/hooks/useMobile';
import * as Popover from '@radix-ui/react-popover';
import * as api from '@saga/api';
import { WeakPage } from '@saga/shared';
import classNames from 'classnames';
import React, { useState } from 'react';
import { Share } from 'react-feather';
import { useTranslation } from 'react-i18next';
import PopOverModal from '../popover/PopOverModal';
import Button from '../styled/Button';

import { FeatureFlag } from '@/../../shared/src/utils/FeatureFlags';
import { usePageAccess } from '@/components/PagesPermissionsBySpaceProvider';
import { PublishSection } from './PublishSection';
import { ShareSection } from './ShareSection';
import { useSpaceAccess } from '@/hooks/useSpaceAccess';

export type ShareablePage = Pick<
    WeakPage,
    'id' | 'title' | 'settings' | 'icon' | 'isTemplate' | 'isPublicDuplicatable'
>;

type ShareButtonProps = {
    className?: string;
    page: ShareablePage;
};

const views = {
    share: 'share',
    publish: 'publish',
};

const ShareButton = ({ page }: ShareButtonProps) => {
    const [view, setView] = useState(views.share);
    const { canEdit } = useSpaceAccess();
    const [open, setOpen] = useState(false);
    const { t } = useTranslation();
    const isMobile = useMobile();
    const isPublishToWebEnabled = isFeatureEnabled(FeatureFlag.publishToWeb);

    const { data, loading } = api.useSharedPageStatusQuery({
        variables: { input: { pageId: page.id } },
        fetchPolicy: 'cache-and-network',
    });

    const isPageShared = data?.sharedPageStatus?.isPublic ?? false;

    return (
        <>
            <Popover.Root
                onOpenChange={(open) => {
                    setOpen(open);

                    if (open) {
                        track('share-button');
                    }
                }}
                open={open}
            >
                <Popover.Trigger asChild>
                    <Button.Plain>
                        <Button.BasePadding>
                            <div
                                className={classNames('space-x-1 flex items-center font-medium', {
                                    'py-0.5': !isMobile,
                                })}
                            >
                                {isMobile ? (
                                    <Share className="inline" size={20} />
                                ) : (
                                    <span>{t('top_menu.share.label')}</span>
                                )}
                            </div>
                        </Button.BasePadding>
                    </Button.Plain>
                </Popover.Trigger>
                <Popover.Portal>
                    <Popover.Content className="pt-1 z-20" align={'end'} onOpenAutoFocus={(e) => e.preventDefault()}>
                        <PopOverModal.Frame>
                            <PopOverModal.Header>
                                <div className="flex items-center space-x-2">
                                    <Button.Plain
                                        isSelected={view === views.share}
                                        onClick={() => {
                                            setView(views.share);
                                            track(`share-section-${views.share}`);
                                        }}
                                    >
                                        <div className="px-2 py-1.5 flex items-center space-x-2">
                                            <div className="min-w-0 font-normal text-sm">
                                                {t('top_menu.share.label')}
                                            </div>
                                        </div>
                                    </Button.Plain>

                                    <Button.Plain
                                        isSelected={view === views.publish}
                                        onClick={() => {
                                            setView(views.publish);
                                            track(`share-section-${views.publish}`);
                                        }}
                                    >
                                        <div className="px-2 py-1.5 flex items-center space-x-2">
                                            <div className="min-w-0 font-normal text-sm">
                                                {t('top_menu.share.publish')}
                                            </div>
                                        </div>
                                    </Button.Plain>
                                </div>
                            </PopOverModal.Header>
                            {view === views.share ? (
                                <ShareSection page={page} isPageShared={isPageShared} setOpen={setOpen} />
                            ) : (
                                canEdit &&
                                isPublishToWebEnabled && (
                                    <PublishSection page={page} loading={loading} isPageShared={isPageShared} />
                                )
                            )}
                        </PopOverModal.Frame>
                    </Popover.Content>
                </Popover.Portal>
            </Popover.Root>
        </>
    );
};

export default ShareButton;
