import { SagaEditor, SlateYjs } from '@saga/shared';
import { useUserContext } from '@/components/UserContext';
import { useDocumentAwareness } from '@/components/RealtimeDocumentProvider';

export type Collaborator = {
    cursor: SlateYjs.Cursor;
    user: SlateYjs.AwarenessStateData['user'];
};

export default function useDocumentCollaborators(pageId: string): Collaborator[] {
    const { user: loggedUser } = useUserContext();
    const awareness = useDocumentAwareness(pageId);
    const awarenessStates = SagaEditor.useAwarenessStates(awareness);

    const collaborators = awarenessStates
        .filter(([, { user }]) => user.userId !== loggedUser?.id)
        .map(([, state]) => state)
        .map((state) => state.cursors?.map((cursor) => ({ cursor, user: state.user })) ?? [])
        .flat();

    return collaborators;
}
