import * as Y from 'yjs';
import { unsafeRight } from '../io-ts-js';
import { SafeSpace } from '../types';

export default function findAllReferenceRegistryEntriesByPageId(
    space: SafeSpace,
    pageId: string,
): Array<Y.Map<unknown>> {
    const safeArray = unsafeRight(space.get('referencesRegistry'));

    const yEntries: Array<Y.Map<unknown>> = [];

    (safeArray.array as Y.Array<Y.Map<unknown>>).forEach((yEntry: Y.Map<unknown>) => {
        if (yEntry instanceof Y.Map && yEntry.get('pageId') === pageId) {
            yEntries.push(yEntry);
        }
    });

    return yEntries;
}
