import { unsafeRight } from '../io-ts-js';
import { WeakPage } from '../types';
import { yarrayFromArray, ymapFromRecord } from '../yjs-utils';
import mustFindIndexByPageId from './mustFindIndexByPageId';
import { SafeSpace } from '../types';
import { isObject } from 'lodash';
import transactInSpace from './transactInSpace';

export default function updatePartialPage(space: SafeSpace, pageId: string, partialPage: Partial<WeakPage>) {
    const yPages = unsafeRight(space.get('pages'));
    const pageIndex = mustFindIndexByPageId(space, pageId);
    const yPage = unsafeRight(yPages.get(pageIndex));
    const keys = Object.keys(partialPage);

    /**
     * Use a transaction to fire 1 UpdateMessage.
     */
    transactInSpace(space, () => {
        keys.forEach((key) => {
            // @ts-expect-error
            const value = partialPage[key];

            if (Array.isArray(value)) {
                yPage.map.set(key, yarrayFromArray(value));
            } else if (isObject(value)) {
                yPage.map.set(key, ymapFromRecord(value as Record<string, any>));
            } else {
                yPage.map.set(key, value);
            }
        });
    });
}
