import { getApps, initializeApp } from 'firebase/app';
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';
import {
    getAuth,
    connectAuthEmulator,
    signInWithCustomToken,
    signOut,
    signInWithPopup,
    GoogleAuthProvider,
} from 'firebase/auth';
import { IS_DEV } from './constants';
import { debugLog } from './utils';

const config = {
    production: {
        apiKey: 'AIzaSyClX6p3LqgjI37pwoltVZcUHmOBBRFGmxg',
        authDomain: 'saga-firebase-backend.firebaseapp.com',
        databaseURL: 'https://saga-firebase-backend.firebaseio.com',
        projectId: 'saga-firebase-backend',
        storageBucket: 'saga-firebase-backend.appspot.com',
        messagingSenderId: '905805857430',
        appId: '1:905805857430:web:cc7797ea30ae79c11cf8b7',
    },
    staging: {
        apiKey: 'AIzaSyCv7rVFsU0JyuxW8VLwpsFK_B7Y_cU31dE',
        authDomain: 'saga-firebase-staging.firebaseapp.com',
        databaseURL: 'https://saga-firebase-staging.firebaseio.com',
        projectId: 'saga-firebase-staging',
        storageBucket: 'saga-firebase-staging.appspot.com',
        messagingSenderId: '780839728681',
        appId: '1:780839728681:web:f4812a827fdadce3eb4e38',
    },
};

const reCaptcha = {
    production: '6LeuRzkqAAAAANDw3cyCabOmopNDeN2eM89Uf9MM',
    staging: '6LfOQTkqAAAAADiv8P0zS2aT8_pqKHG_ZQ8ctzsY',
};

function initializeFirebaseApp() {
    if (getApps().length === 0) {
        const VITE_FIREBASE_STAGE = import.meta.env.VITE_FIREBASE_STAGE;

        // local development and tests
        if (VITE_FIREBASE_STAGE === undefined) {
            self.FIREBASE_APPCHECK_DEBUG_TOKEN = import.meta.env.VITE_FIREBASE_APPCHECK_DEBUG_TOKEN;
        }

        const configEnv = VITE_FIREBASE_STAGE ? VITE_FIREBASE_STAGE : 'staging';
        debugLog(`configEnv: ${configEnv}, VITE_FIREBASE_STAGE: ${VITE_FIREBASE_STAGE}`);

        const { app } = initializeAppCheck(initializeApp(config[configEnv]), {
            provider: new ReCaptchaV3Provider(reCaptcha[configEnv]),
            isTokenAutoRefreshEnabled: true,
        });
        return app;
    }

    return getApps()[0];
}

const app = initializeFirebaseApp();
const googleAuthProvider = new GoogleAuthProvider();

const auth = getAuth(app);

const authEmulatorHost = import.meta.env.VITE_FIREBASE_AUTH_EMULATOR_HOST;
if (authEmulatorHost) {
    debugLog('Running auth on emulator here:', authEmulatorHost);

    if (import.meta.env.VITE_DISABLE_SSL) {
        const url = new URL(`http://${authEmulatorHost}`);
        connectAuthEmulator(auth, `http://${window.location.hostname}:${url.port}`);
    } else {
        connectAuthEmulator(auth, `http://${authEmulatorHost}`);
    }
}

function getCurrentUser() {
    return auth.currentUser;
}

const signInWithGoogleProvider = () => {
    return signInWithPopup(auth, googleAuthProvider);
};

function logout() {
    return signOut(auth);
}

if (IS_DEV) {
    window.logout = logout;

    // This is just a helper for easily signing up with firebase in the e2e tests
    window.signInWithCustomToken = (token: string) => {
        signInWithCustomToken(auth, token);
    };
}

export { auth, getCurrentUser, signInWithGoogleProvider, logout };
