import React from 'react';
import { useTranslation } from 'react-i18next';
import { TaskLabel } from '@saga/shared';

import TableMultipleFilterSelect, { ItemsFilterMode } from './TableMultipleFilterSelect';
import { Tag } from 'react-feather';

export default function TaskLabelsFilterSelect({
    selectedIds,
    onUpdateSelectedIds,
    availableLabels,
}: {
    selectedIds: ItemsFilterMode;
    onUpdateSelectedIds(ids: ItemsFilterMode): void;
    availableLabels: TaskLabel[];
}) {
    const { t } = useTranslation();

    const availableItems = React.useMemo(() => {
        return availableLabels.map((label) => ({
            id: label.id,
            title: label.title,
            icon: (
                <div className="flex justify-center w-[14px] h-[14px] items-center">
                    <div className="w-2 h-2 rounded-full shrink-0" style={{ backgroundColor: label.color }} />
                </div>
            ),
        }));
    }, [availableLabels]);

    return (
        <TableMultipleFilterSelect
            selectedIds={selectedIds}
            onUpdateSelectedIds={onUpdateSelectedIds}
            availableItems={availableItems}
            selectTexts={{
                default: t('settings.labels.title'),
                all: t('tasks.all_labels'),
                none: t('tasks.no_labels'),
                n_items: t('tasks.n_labels', { count: selectedIds.length }),
                search_item_placeholder: t('tasks.new_label_placeholder'),
            }}
            Icon={<Tag size={14} />}
        />
    );
}
