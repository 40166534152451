import React from 'react';
import Spinner from '@/components/loading/Spinner';
import Modal from 'react-modal';
import modalStyle from '@/modalStyle';

const LoadingModal: React.FC<{ isOpen: boolean; message?: string }> = ({ isOpen, message }) => {
    return (
        <Modal
            isOpen={isOpen}
            style={modalStyle({
                position: 'center',
                darkOverlay: true,
                css: { backgroundColor: 'transparent', border: undefined },
            })}
            contentLabel={message}
        >
            <div className="mx-auto my-auto">
                <div className="flex flex-row space-x-2 bg-white dark:bg-zinc-700 items-center rounded-xl px-8 py-6">
                    <Spinner />
                    {message && <span className="font-semibold py-2 animate-pulse text-xl">{message}</span>}
                </div>
            </div>
        </Modal>
    );
};

export default LoadingModal;
