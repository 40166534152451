import React from 'react';
import { Collection, SpaceOperations } from '@saga/shared';
import { useSpace } from '../SpaceProvider';
import useWorkspaceSettings from '@/hooks/useWorkspaceSettings';
import { PopOver } from './PopOver';
import { Trash } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import { useCurrentWorkspace } from '../WorkspaceContext';

export function DeleteCollectionButton({
    onAfterClick,
    collectionId,
}: {
    onAfterClick?: () => void;
    collectionId: Collection['id'];
}) {
    const { space } = useSpace();
    const { urlKey } = useCurrentWorkspace();
    const [settings, updateUserWorkspaceSettings] = useWorkspaceSettings();
    const { t } = useTranslation();

    const onDelete = () => {
        SpaceOperations.deleteCollectionById(space, collectionId);

        if (settings) {
            if (settings.openBlocks.includes(collectionId)) {
                let openBlocks = settings.openBlocks;
                openBlocks = openBlocks.filter((_id) => collectionId !== _id);
                updateUserWorkspaceSettings({ openBlocks });
            }
        }

        return <Redirect to={`/s/${urlKey}/tasks`} />;
    };

    return (
        <PopOver.RoundedButton
            onClick={() => {
                if (window.confirm('Are you sure you want to delete this collection?')) {
                    onDelete();
                    onAfterClick && onAfterClick();
                }
            }}
            aria-label={t('collections.delete_collection') as string}
        >
            <Trash className="stroke-gray-dark mr-2 my-auto" size={14} />
            {t('common.delete')}
        </PopOver.RoundedButton>
    );
}
