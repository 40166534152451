import { Editor } from 'slate';
export * as Tables from './Tables';
export * as Transforms from './Transforms';
export * as NodeEntry from './NodeEntry';
export * as SagaElement from './SagaElement';
export * as Path from './Path';
export * as Selection from './Selection';
export * as Marks from './Marks';
export * as Extensions from './Extensions';

export function debug(editor: Editor) {
    console.log(JSON.stringify(editor.children, null, 2));
}
