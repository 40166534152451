import findIndexByPageId from './findIndexByPageId';
import * as E from 'fp-ts/lib/Either';
import * as Y from 'yjs';
import { pipe } from 'fp-ts/function';
import { SafeSpace } from '../types';

const getPageMapById = (space: SafeSpace, pageId: string): Y.Map<unknown> | null => {
    const index = findIndexByPageId(space, pageId);
    const pageMap = pipe(
        space.get('pages'),
        E.chain((pages) => pages?.get(index)),
        E.map((page) => page.map),
        E.getOrElseW(() => null),
    );
    return pageMap;
};

export default getPageMapById;
