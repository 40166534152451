import { SagaEditor, isTitle, WeakPage } from '@saga/shared';
import React from 'react';
import Emoji from '@/components/Emoji';
import classNames from 'classnames';

export function createTitleBlockPlugin(icon?: WeakPage['icon']) {
    return SagaEditor.Plugins.createBlockPlugin({
        match: isTitle,
        normalizers: [SagaEditor.Normalizers.titleNormalizer],
        Component({ element, children }) {
            return (
                <div className="space-x-2 flex items-start py-2">
                    {icon?.colons && (
                        <div
                            contentEditable={false}
                            className={classNames('select-none inline-flex px-1.5', { '-ml-2': icon != null })}
                        >
                            <Emoji emoji={icon?.colons} size={34} />
                        </div>
                    )}
                    <h1
                        id={element.id}
                        className="inline sm:text-4xl text-[28px] leading-snug sm:leading-[1.3] font-bold"
                        contentEditable={false}
                    >
                        {children}
                    </h1>
                </div>
            );
        },
    });
}

export const titleBlockPlugin = createTitleBlockPlugin();
