import React from 'react';
import Button from '../styled/Button';
import { InputDropdown, InputDropdownRenderItem } from '../popover/Dropdown';
import { Check } from 'react-feather';
import classNames from 'classnames';

export type ItemsFilterMode = 'all' | 'none' | string[];

type FilterItem = {
    id: string;
    title: string;
    icon: React.ReactNode;
};

type SelectTexts = {
    default: string;
    all: string;
    none: string;
    n_items: string;
    search_item_placeholder: string;
};

export default function TableMultipleFilterSelect({
    selectedIds,
    onUpdateSelectedIds,
    availableItems,
    Icon,
    selectTexts,
}: {
    selectedIds: ItemsFilterMode;
    onUpdateSelectedIds(ids: ItemsFilterMode): void;
    availableItems: FilterItem[];
    selectTexts: SelectTexts;
    Icon: React.ReactNode;
}) {
    const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);

    const divRef = React.useRef<HTMLDivElement>(null);

    return (
        <>
            <div
                ref={divRef}
                className="flex items-center whitespace-nowrap focus:outline-none cursor-pointer px-2 h-8 text-xs border font-semibold rounded hover:bg-saga-gray-200 dark:hover:bg-saga-gray-700 border-saga-gray-200 dark:border-saga-gray-700 shadow-button"
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            >
                {selectedIds === 'all' ? (
                    <div className="flex flex-row gap-1.5 items-center">
                        {Icon}
                        <div>{selectTexts.default}</div>
                    </div>
                ) : null}
                {selectedIds === 'none' ? (
                    <div className="flex flex-row gap-1.5 items-center">
                        <span>—</span>
                        <div>{selectTexts.none}</div>
                    </div>
                ) : null}
                {Array.isArray(selectedIds) && selectedIds.length ? (
                    <div className="flex flex-row gap-1.5 items-center">
                        {Icon}
                        <div>{selectTexts.n_items}</div>
                    </div>
                ) : null}
            </div>
            {isDropdownOpen ? (
                <Dropdown
                    attachToRef={divRef}
                    isOpen={isDropdownOpen}
                    onClose={() => setIsDropdownOpen(false)}
                    selectedIds={selectedIds}
                    availableItems={availableItems}
                    selectTexts={selectTexts}
                    onSelectItem={(id) => {
                        if (id === 'all') {
                            onUpdateSelectedIds('all');
                        } else if (id === 'none') {
                            onUpdateSelectedIds('none');
                        } else {
                            if (selectedIds === 'all' || selectedIds === 'none') {
                                onUpdateSelectedIds([id]);
                            } else {
                                const newIds = selectedIds?.includes(id)
                                    ? selectedIds.filter((existingId) => existingId !== id)
                                    : [...(selectedIds || []), id];
                                onUpdateSelectedIds(newIds.length === 0 ? 'all' : newIds);
                            }
                        }
                    }}
                    Icon={Icon}
                />
            ) : null}
        </>
    );
}

function Dropdown({
    attachToRef,
    isOpen,
    onClose,
    onSelectItem,
    selectedIds,
    availableItems,
    selectTexts,
    Icon,
}: {
    attachToRef: React.RefObject<HTMLElement>;
    isOpen: boolean;
    onClose(): void;
    availableItems: FilterItem[];
    selectedIds: ItemsFilterMode;
    onSelectItem(id: 'all' | 'none' | string): void;
    selectTexts: SelectTexts;
    Icon: React.ReactNode;
}) {
    return (
        <InputDropdown<FilterItem | { id: 'all' | 'none' }>
            addTopPadding
            attachToRef={attachToRef}
            isOpen={isOpen}
            onClose={onClose}
            dropdownProps={{ align: 'left' }}
            onSubmit={({ selectedItem }) => {
                onSelectItem(selectedItem.id);
            }}
            inputProps={{
                placeholder: selectTexts.search_item_placeholder,
                title: selectTexts.search_item_placeholder,
            }}
            renderItems={({ search }) => {
                const filteredItems = availableItems
                    .filter((item) => item.title.toLowerCase().includes(search.toLowerCase()))
                    .sort((a, b) => a.title.localeCompare(b.title))
                    .map((item): InputDropdownRenderItem<FilterItem> => {
                        return {
                            render({ setSelection, submit, index }) {
                                const isSelected = selectedIds?.includes(item.id);
                                return (
                                    <div key={index}>
                                        <Button.PopOverButton
                                            selected={false}
                                            onClick={(e) => {
                                                e.currentTarget.blur();
                                                submit(e);
                                            }}
                                            onMouseEnter={() => setSelection()}
                                            hoverable
                                        >
                                            <div className="space-x-2 flex w-full items-center">
                                                {item.icon}
                                                <div className="flex flex-1 truncate max-w-48">{item.title}</div>
                                                <Check
                                                    size={16}
                                                    className={classNames(
                                                        'border border-saga-new-purple text-saga-new-purple p-[1px] rounded-sm',
                                                        {
                                                            'opacity-100': isSelected,
                                                            'opacity-0': !isSelected,
                                                        },
                                                    )}
                                                />
                                            </div>
                                        </Button.PopOverButton>
                                    </div>
                                );
                            },
                            item,
                        };
                    });

                return [
                    {
                        render({ setSelection, submit, index }) {
                            const isSelected = selectedIds === 'all';
                            return (
                                <div key={index}>
                                    <Button.PopOverButton
                                        selected={false}
                                        onClick={(e) => {
                                            e.currentTarget.blur();
                                            submit(e);
                                        }}
                                        onMouseEnter={() => setSelection()}
                                        hoverable
                                    >
                                        <div className="space-x-2 flex flex-1 items-center">
                                            {Icon}
                                            <div className="flex flex-1">{selectTexts.all}</div>
                                            {isSelected ? (
                                                <Check
                                                    size={16}
                                                    className="border border-saga-new-purple text-saga-new-purple p-[1px] rounded-sm"
                                                />
                                            ) : null}
                                        </div>
                                    </Button.PopOverButton>
                                </div>
                            );
                        },
                        item: { id: 'all' },
                    },
                    ...filteredItems,
                    {
                        render({ setSelection, submit, index }) {
                            const isSelected = selectedIds === 'none';
                            return (
                                <div key={index}>
                                    <Button.PopOverButton
                                        selected={false}
                                        onClick={(e) => {
                                            e.currentTarget.blur();
                                            submit(e);
                                        }}
                                        onMouseEnter={() => setSelection()}
                                        hoverable
                                    >
                                        <div className="space-x-2 flex flex-1 items-center">
                                            <span>—</span>
                                            <div className="flex flex-1">{selectTexts.none}</div>
                                            {isSelected ? (
                                                <Check
                                                    size={16}
                                                    className="border border-saga-new-purple text-saga-new-purple p-[1px] rounded-sm"
                                                />
                                            ) : null}
                                        </div>
                                    </Button.PopOverButton>
                                </div>
                            );
                        },
                        item: { id: 'none' },
                    },
                ];
            }}
        />
    );
}
