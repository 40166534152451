import { SafeSpace, PageView } from '../types';
import transactInSpace from './transactInSpace';
import * as Y from 'yjs';
import { ymapFromRecord } from '../yjs-utils';
import { v4 as uuidv4 } from 'uuid';

export default function addPageView(
    space: SafeSpace,
    view: Omit<PageView, 'id' | 'isDefaultView' | 'createdAt'>,
): string {
    const id = uuidv4();

    transactInSpace(space, () => {
        let pageViews = space.map.get('pageViews') as Y.Array<Y.Map<unknown>> | undefined;

        if (!pageViews) {
            pageViews = new Y.Array();
            space.map.set('pageViews', pageViews);
        }

        const viewMap = ymapFromRecord(
            { ...view, isDefaultView: false, id, createdAt: new Date().toISOString() },
            new Y.Map(),
        );
        pageViews.push([viewMap]);
    });

    return id;
}
