import { Editor, Operation } from 'slate';
import invariant from 'tiny-invariant';
import * as Y from 'yjs';
import { BaseSagaEditor } from '../..';
import { applyYjsEvents } from '../applyToSlate';
import applySlateOps from '../applyToYjs';
import { SharedType } from '../model';
import { toSlateDoc } from '../utils';

const IS_REMOTE: WeakSet<Editor> = new WeakSet();
const LOCAL_OPERATIONS: WeakMap<Editor, Set<Operation>> = new WeakMap();
const SHARED_TYPES: WeakMap<Editor, SharedType> = new WeakMap();

export interface YjsEditor extends BaseSagaEditor {
    sharedType: SharedType;
    cleanup(): void;
}

export const YjsEditor = {
    /**
     * Set the editor value to the content of the to the editor bound shared type.
     */
    synchronizeValue: (e: Editor): void => {
        Editor.withoutNormalizing(e, () => {
            // @ts-expect-error
            e.children = toSlateDoc(e.sharedType);
            e.onChange();
        });
    },

    /**
     * Returns whether the editor currently is applying remote changes.
     */
    sharedType: (editor: Editor): SharedType => {
        const sharedType = SHARED_TYPES.get(editor);
        invariant(sharedType, 'YjsEditor without attached shared type');
        return sharedType;
    },

    /**
     * Returns whether the editor currently is applying remote changes.
     */
    isRemote: (editor: Editor): boolean => {
        return IS_REMOTE.has(editor);
    },

    /**
     * Performs an action as a remote operation.
     */
    asRemote: (editor: Editor, fn: () => void): void => {
        const wasRemote = YjsEditor.isRemote(editor);
        IS_REMOTE.add(editor);

        fn();

        if (!wasRemote) {
            IS_REMOTE.delete(editor);
        }
    },
};

function localOperations(editor: Editor): Set<Operation> {
    const operations = LOCAL_OPERATIONS.get(editor);
    invariant(operations, 'YjsEditor without attached local operations');
    return operations;
}

function trackLocalOperations(editor: Editor, operation: Operation): void {
    if (!YjsEditor.isRemote(editor)) {
        localOperations(editor).add(operation);
    }
}

/**
 * Applies a slate operations to the bound shared type.
 */
function applyLocalOperations(editor: Editor, origin: any): void {
    const editorLocalOperations = localOperations(editor);
    applySlateOps(YjsEditor.sharedType(editor), Array.from(editorLocalOperations), origin);

    editorLocalOperations.clear();
}

/**
 * Apply Yjs events to slate
 */
function applyRemoteYjsEvents(editor: Editor, events: Y.YEvent<any>[], origin: any): void {
    Editor.withoutNormalizing(editor, () =>
        YjsEditor.asRemote(editor, () => {
            return applyYjsEvents(
                editor,
                events.filter((event) => event.transaction.origin !== origin),
            );
        }),
    );
}

export function withYjs<T extends Editor>(
    editor: T,
    sharedType: SharedType,
    origin: any,
    { synchronizeValue = true }: WithYjsOptions = {},
): T & YjsEditor {
    const e = editor as T & YjsEditor;

    e.sharedType = sharedType;
    SHARED_TYPES.set(editor, sharedType);
    LOCAL_OPERATIONS.set(editor, new Set());

    if (synchronizeValue) {
        // We need to do this without a setTimeout because we expect the editor to immediately have the children upon creation
        YjsEditor.synchronizeValue(e);
    }

    function observeDeep(events: Y.YEvent<any>[]) {
        applyRemoteYjsEvents(e, events, origin);
    }

    sharedType.observeDeep(observeDeep);

    const { apply, onChange } = e;

    e.apply = (op: Operation) => {
        trackLocalOperations(e, op);

        apply(op);
    };

    e.onChange = () => {
        applyLocalOperations(e, origin);

        onChange();
    };

    e.cleanup = () => {
        e.apply = apply;
        e.onChange = onChange;
        sharedType.unobserveDeep(observeDeep);
        SHARED_TYPES.delete(editor);
    };

    return e;
}

export type WithYjsOptions = {
    synchronizeValue?: boolean;
};
