import React from 'react';
import { useSpring, useTransition } from '@react-spring/core';
import { animated, WithAnimated, config } from '@react-spring/web';

export function OpacityTransition({
    children,
    isOpen,
    duration,
    onRest,
    tag = 'div',
}: {
    isOpen: boolean;
    onRest?: () => void;
    tag?: keyof WithAnimated;
    duration: number;
    children: React.ReactNode;
}) {
    const Component = animated[tag];
    const transition = useTransition(isOpen, {
        from: {
            opacity: 0,
        },
        enter: {
            opacity: 1,
        },
        leave: {
            opacity: 0,
        },
        onRest,
        config: { duration },
    });

    return (
        <>
            {transition((animation, item) => {
                return (
                    item && (
                        <Component
                            contentEditable={false}
                            className="select-none"
                            style={{ opacity: animation.opacity }}
                        >
                            {children}
                        </Component>
                    )
                );
            })}
        </>
    );
}

export function SlideIn({ isOpen, children }: { isOpen: boolean; children: React.ReactNode }) {
    const [closed, setClosed] = React.useState(!isOpen);
    const notificationSidebarSpring = useSpring({
        left: isOpen ? 0 : -370,
        config: { ...config.stiff, bounce: 0 },
        onRest() {
            if (!isOpen) {
                setClosed(true);
            }
        },
        onStart() {
            if (isOpen) {
                setClosed(false);
            }
        },
    });

    return (
        <animated.div className="absolute" style={notificationSidebarSpring}>
            {!closed && children}
        </animated.div>
    );
}
