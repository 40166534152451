import React from 'react';
import NotFoundLogo from '@/assets/images/saga-404-page.svg';
import Card from './styled/Card';

const NotFound = () => {
    return (
        <div className="overflow-hidden relative w-full h-full ">
            <div className="absolute right-0 z-0 flex justify-end mt-76 xl:mt-64">
                <svg width="589" height="1430" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M593.463.01h4v-3.983l-3.982-.017-.018 4zM501.52 863.34l-.63 3.95.01.001.62-3.951zm91.943 16.913l-.89 3.9 4.89 1.116v-5.016h-4zm0-352.888h4v-3.179l-3.097-.717-.903 3.896zm0-198.714l-.159 3.997 4.159.165v-4.162h-4zm0 1100.499l.007 4 3.993-.01v-3.99h-4zm0-340.3h4v-3.86l-3.86-.14-.14 4zM445.569 945.244h4v-4h-4v4zm-445.106 0v-4h-4.035l.035 4.035 4-.035zM589.205 4c1.415 0 2.829.003 4.24.01l.036-8c-1.423-.007-2.849-.01-4.276-.01v8zM22 442.953c0-133.307 56.411-242.88 155.337-319.228C276.358 47.303 418.224 4 589.205 4v-8C416.962-4 273.225 39.616 172.45 117.392 71.579 195.241 14 307.144 14 442.953h8zM502.15 859.39c-104.838-16.721-224.948-40.246-318.848-99.926C89.752 700.006 22 604.526 22 442.953h-8c0 164.372 69.204 262.371 165.011 323.263C274.469 826.885 396.12 850.58 500.89 867.29l1.26-7.9zm92.203 16.963c-28.271-6.45-59.463-11.823-92.213-16.965l-1.24 7.903c32.702 5.134 63.676 10.474 91.673 16.862l1.78-7.8zm3.11 3.9V527.365h-8v352.888h8zM456.6 428.745c0 29.561 14.492 50.804 38.739 66.767 24.017 15.811 57.83 26.625 97.221 35.75l1.806-7.793c-39.257-9.094-71.876-19.66-94.628-34.639-22.522-14.828-35.138-33.786-35.138-60.085h-8zm126.759-104.291c-37.75 0-69.421 9.121-91.741 26.883-22.439 17.855-35.018 44.126-35.018 77.408h8c0-31.072 11.639-54.947 31.999-71.148 20.478-16.296 50.187-25.143 86.76-25.143v-8zm10.263.2c-3.375-.134-6.796-.2-10.263-.2v8c3.364 0 6.68.064 9.945.194l.318-7.994zm3.841 3.997V.01h-8V328.65h8zm0 1100.499v-340.3h-8v340.3h8zM441.569 945.244c0 47.921 16.622 84.236 44.177 108.896 27.475 24.58 65.431 37.24 107.577 38.71l.28-8c-40.793-1.42-76.785-13.64-102.522-36.68-25.657-22.95-41.512-56.995-41.512-102.926h-8zm-441.106 4h445.106v-8H.463v8zM591.71 1425.15c-182.218 0-328.414-47.88-429.293-131.65C61.611 1209.79 5.706 1089.96 4.463 945.21l-8 .069c1.262 146.931 58.095 269.051 160.843 354.371 102.676 85.27 250.85 133.5 434.404 133.5v-8zm1.746 0h-1.746v8h1.76l-.014-8z"
                        fill="currentColor"
                        mask="url(#a)"
                    />
                </svg>
            </div>
            <div className="relative h-full w-full flex items-center justify-center">
                <Card.WithShadow>
                    <Card.Background>
                        <div className="p-2 md:p-8">
                            <div className="m-8 space-y-5 md:w-96 text-center mx-auto">
                                <img className="md:h-20 mx-auto" alt="Saga Logo" src={NotFoundLogo}></img>
                                <h1 className="text-2xl md:text-4xl font-bold text-center text-saga-black dark:text-zinc-200">
                                    Your path you must decide.
                                </h1>

                                <p>
                                    {`We are sorry but the page you are looking for doesn't exist or got lost during our journey. Go back to the `}
                                    <a className="underline" href="https://saga.so/?r=0">
                                        Home Page
                                    </a>
                                    {` instead.`}
                                </p>
                            </div>
                        </div>
                    </Card.Background>
                </Card.WithShadow>
            </div>
        </div>
    );
};

export default NotFound;
