import { useLoadingContext } from '@/components/loading/LoadingContext';
import { useFirebaseContext } from '@/components/FirebaseContext';
import { useWorkspaceContext } from '@/components/WorkspaceContext';
import { track } from '@/analytics';
import { exportEntity } from '@/utils/export';
import { DocumentName, Task, Page } from '@saga/shared';

const useExport = () => {
    const { showLoadingSpinner, hideLoadingSpinner } = useLoadingContext();
    const { currentUrlKey, currentWorkspace } = useWorkspaceContext();
    const { firebaseUser } = useFirebaseContext();

    return async (task: Pick<Task, 'id' | 'title' | 'state'> | Pick<Page, 'id' | 'title'>) => {
        if (task && currentUrlKey) {
            showLoadingSpinner('Preparing export...');
            try {
                const token = await firebaseUser?.getIdToken();
                if (token == null) {
                    throw new Error('not logged in');
                }
                await exportEntity(task, DocumentName.build('space', currentUrlKey), currentWorkspace.id, token);
            } catch (e) {
                throw e;
            } finally {
                hideLoadingSpinner();
            }
            track('export-task');
        }
    };
};

export default useExport;
