import { usePagesPermissions } from '@/components/PagesPermissionsBySpaceProvider';
import { useSpace } from '@/components/SpaceProvider';
import { InputDropdown, InputDropdownProps, RenderItemProps } from '@/components/popover/Dropdown';
import { AISourceItem, SpaceOperations } from '@saga/shared';
import React from 'react';
import { PopOver } from '@/components/popover/PopOver';
import Button from '@/components/styled/Button';
import { PageIcon } from '@/components/icons';
import { useTranslation } from 'react-i18next';
import { CheckCircle } from 'react-feather';
import classNames from 'classnames';

function useAISourcesItems(): AISourceItem[] {
    const { space } = useSpace();
    const [items, setItems] = React.useState<AISourceItem[]>([]);
    const { hasAccess } = usePagesPermissions();

    React.useEffect(() => {
        const pages = SpaceOperations.getPages(
            space,
            ['title', 'id', 'icon', 'isTemplate', 'updatedAt'],
            undefined,
            hasAccess,
        );
        const tasks = SpaceOperations.getTasks(space);

        const items: AISourceItem[] = [
            ...pages.map((page) => ({
                ...page,
                title: page.title ?? 'Untitled',
                type: 'page' as const,
            })),
            ...tasks.map((task) => ({
                ...task,
                title: task.title ?? 'Untitled',
                type: 'task' as const,
            })),
        ];

        setItems(items);
    }, [space, hasAccess]);

    return items;
}

type Props = Omit<InputDropdownProps<AISourceItem>, 'renderItems'> & { selected: string[] };

export default function AISourcesPopover({
    isOpen,
    onClose,
    onSubmit,
    attachToRef,
    inputProps,
    dropdownProps,
    selected,
}: Props) {
    const sources = useAISourcesItems();
    const { t } = useTranslation();

    return (
        <InputDropdown
            attachToRef={attachToRef}
            isOpen={isOpen}
            onClose={onClose}
            onSubmit={onSubmit}
            inputProps={{
                ...(inputProps ?? {}),
                placeholder: t('pages.enter_page_name') as string,
                title: t('pages.enter_page_name') as string,
            }}
            dropdownProps={{ ...(dropdownProps ?? {}), align: 'right', zIndex: 101, maxWHighlight: true }}
            renderItems={({ search }) => {
                const items = sources
                    .sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime())
                    .filter(
                        (source) =>
                            source.title.toLowerCase().includes(search.toLowerCase()) && !selected.includes(source.id),
                    )
                    .slice(0, 11)
                    .map((item) => {
                        const render = ({ isSelected, setSelection, submit, index }: RenderItemProps) => {
                            return (
                                <React.Fragment key={index}>
                                    {items.length > 0 && search.trim().length >= 0 && index === 0 && (
                                        <>
                                            <PopOver.Label>{t('ai.search_or_select')}</PopOver.Label>
                                        </>
                                    )}
                                    <Button.PopOverButton
                                        selected={isSelected}
                                        onMouseEnter={setSelection}
                                        type="button"
                                        onClick={submit}
                                    >
                                        {item.type === 'page' && (
                                            <div
                                                className={classNames('', {
                                                    'mt-0.25': item.icon,
                                                })}
                                            >
                                                <PageIcon icon={item.icon} isTemplate={item.isTemplate} />
                                            </div>
                                        )}

                                        {item.type === 'task' && (
                                            <CheckCircle className="flex-none w-3.5 h-3.5 shrink-0" />
                                        )}

                                        <p className="pl-2 min-h-[24px] pt-0.5"> {item.title}</p>
                                    </Button.PopOverButton>
                                </React.Fragment>
                            );
                        };

                        return {
                            render,
                            item,
                        };
                    });

                return items;
            }}
        />
    );
}
