import * as Y from 'yjs';
import { unsafeRight } from '../io-ts-js';
import { findYArrayIndex } from '../yjs-utils';
import { SafeSpace } from '../types';

export default function findReferenceRegistryEntry(space: SafeSpace, id: string): Y.Map<unknown> | null {
    const safeArray = unsafeRight(space.get('referencesRegistry'));

    const index = findYArrayIndex(
        safeArray.array as Y.Array<Y.Map<unknown>>,
        (map: Y.Map<unknown>) => map.get('liveReferenceSourceId') === id,
    );

    if (index === -1) {
        return null;
    }

    const entry = safeArray.array.get(index);

    return entry as Y.Map<unknown>;
}
