import * as api from '@saga/api';

export const data = {
    codeHighlight: {
        '@': '@',
        [api.Language.En]: 'Ask AI',
        [api.Language.Fr]: "Demander à l'IA",
        [api.Language.Es]: 'Preguntar a la IA',
        [api.Language.Pt]: 'Perguntar à IA',
        [api.Language.De]: 'KI fragen',
        [api.Language.It]: "Chiedi all'IA",
    },
    pageTitle: {
        [api.Language.En]: 'Welcome to Saga',
        [api.Language.Fr]: 'Bienvenue chez Saga',
        [api.Language.Es]: 'Bienvenido a Saga',
        [api.Language.Pt]: 'Bem-vindo ao Saga',
        [api.Language.De]: 'Willkommen bei Saga',
        [api.Language.It]: 'Benvenuto in Saga',
    },
    callout: {
        [api.Language.En]: 'Click anywhere on this page to start editing.',
        [api.Language.Fr]: "Cliquez n'importe où sur cette page pour commencer à éditer.",
        [api.Language.Es]: 'Haga clic en cualquier lugar de esta página para comenzar a editar.',
        [api.Language.Pt]: 'Clique em qualquer lugar desta página para começar a editar.',
        [api.Language.De]: 'Klicken Sie irgendwo auf dieser Seite, um mit der Bearbeitung zu beginnen.',
        [api.Language.It]: 'Fai clic in un punto qualsiasi di questa pagina per iniziare a modificare.',
    },
    section1: {
        title: {
            [api.Language.En]: 'How to use Saga',
            [api.Language.Fr]: 'Comment utiliser Saga',
            [api.Language.Es]: 'Cómo usar Saga',
            [api.Language.Pt]: 'Como usar o Saga',
            [api.Language.De]: 'Wie man Saga benutzt',
            [api.Language.It]: 'Come usare Saga',
        },
        checkList: [
            {
                [api.Language.En]: 'Click anywhere to start writing.',
                [api.Language.Fr]: "Cliquez n'importe où pour commencer à écrire.",
                [api.Language.Es]: 'Haga clic en cualquier lugar para comenzar a escribir.',
                [api.Language.Pt]: 'Clique em qualquer lugar para começar a escrever.',
                [api.Language.De]: 'Klicken Sie irgendwo, um mit dem Schreiben zu beginnen.',
                [api.Language.It]: 'Fai clic in un punto qualsiasi per iniziare a scrivere.',
            },
            {
                [api.Language.En]:
                    'Press @ to explore the types of content you can add, such as lists, headings, images, etc.',
                [api.Language.Fr]:
                    'Appuyez sur @ pour explorer les types de contenu que vous pouvez ajouter, tels que des listes, des titres, des images, etc.',
                [api.Language.Es]:
                    'Presione @ para explorar los tipos de contenido que puede agregar, como listas, encabezados, imágenes, etc.',
                [api.Language.Pt]:
                    'Pressione @ para explorar os tipos de conteúdo que você pode adicionar, como listas, títulos, imagens, etc.',
                [api.Language.De]:
                    'Drücken Sie @, um die Arten von Inhalten zu erkunden, die Sie hinzufügen können, wie Listen, Überschriften, Bilder usw.',
                [api.Language.It]:
                    'Premi @ per esplorare i tipi di contenuto che puoi aggiungere, come elenchi, intestazioni, immagini, ecc.',
            },
            {
                [api.Language.En]: 'Press @ and select Ask AI to experiment with Saga AI.',
                [api.Language.Fr]: "Appuyez sur @ et sélectionnez Demander à l'IA pour expérimenter avec Saga IA.",
                [api.Language.Es]: 'Presione @ y seleccione Preguntar a la IA para experimentar con Saga IA.',
                [api.Language.Pt]: 'Pressione @ e selecione Perguntar à IA para experimentar com o Saga IA.',
                [api.Language.De]: 'Drücken Sie @ und wählen Sie KI fragen, um mit Saga KI zu experimentieren.',
                [api.Language.It]: "Premi @ e seleziona Chiedi all'IA per sperimentare con Saga IA.",
            },
            {
                [api.Language.En]:
                    'Select any text to transform it into another type of block or to edit it with Saga AI.',
                [api.Language.Fr]:
                    "Sélectionnez n'importe quel texte pour le transformer en un autre type de bloc ou pour le modifier avec Saga IA.",
                [api.Language.Es]:
                    'Seleccione cualquier texto para transformarlo en otro tipo de bloque o para editarlo con Saga IA.',
                [api.Language.Pt]:
                    'Selecione qualquer texto para transformá-lo em outro tipo de bloco ou para editá-lo com o Saga IA.',
                [api.Language.De]:
                    'Wählen Sie einen beliebigen Text aus, um ihn in einen anderen Blocktyp zu verwandeln oder mit Saga KI zu bearbeiten.',
                [api.Language.It]:
                    'Seleziona qualsiasi testo per trasformarlo in un altro tipo di blocco o per modificarlo con Saga IA.',
            },
            {
                [api.Language.En]: 'Drag and drop this text by clicking and holding the ⠿ icon on the left.',
                [api.Language.Fr]:
                    "Faites glisser et déposez ce texte en cliquant et en maintenant l'icône ⠿ à gauche.",
                [api.Language.Es]:
                    'Arrastre y suelte este texto haciendo clic y manteniendo presionado el icono ⠿ a la izquierda.',
                [api.Language.Pt]: 'Arraste e solte este texto clicando e segurando o ícone ⠿ à esquerda.',
                [api.Language.De]: 'Ziehen Sie diesen Text, indem Sie das ⠿ Symbol links anklicken und halten.',
                [api.Language.It]:
                    "Trascina e rilascia questo testo facendo clic e tenendo premuta l'icona ⠿ a sinistra.",
            },
        ],
    },
    section2: {
        title: {
            [api.Language.En]: 'Additional tips',
            [api.Language.Fr]: 'Conseils supplémentaires',
            [api.Language.Es]: 'Consejos adicionales',
            [api.Language.Pt]: 'Dicas adicionais',
            [api.Language.De]: 'Zusätzliche Tipps',
            [api.Language.It]: 'Suggerimenti aggiuntivi',
        },
        checkList: [
            {
                [api.Language.En]: 'Open the Saga Basics page for more tips.',
                [api.Language.Fr]: 'Ouvrez la page Les bases de Saga pour plus de conseils.',
                [api.Language.Es]: 'Abra la página de Fundamentos de Saga para obtener más consejos.',
                [api.Language.Pt]: 'Abra a página de Noções básicas do Saga para mais dicas.',
                [api.Language.De]: 'Öffnen Sie die Saga Grundlagen Seite für weitere Tipps.',
                [api.Language.It]: 'Apri la pagina delle Nozioni di base su Saga per ulteriori suggerimenti.',
            },
            {
                [api.Language.En]: 'Explore Saga Advanced to discover the advanced features.',
                [api.Language.Fr]: 'Explorez Saga Avancé pour découvrir les fonctionnalités avancées.',
                [api.Language.Es]: 'Explore Saga Avanzado para descubrir las funciones avanzadas.',
                [api.Language.Pt]: 'Explore o Saga Avançado para descobrir os recursos avançados.',
                [api.Language.De]: 'Erkunden Sie Saga Fortgeschritten, um die erweiterten Funktionen zu entdecken.',
                [api.Language.It]: 'Esplora Saga Avanzato per scoprire le funzionalità avanzate.',
            },
            {
                [api.Language.En]: 'Explore Saga AI and see how to use AI directly in your notes and tasks.',
                [api.Language.Fr]:
                    "Explorez Saga IA et voyez comment utiliser l'IA directement dans vos notes et tâches.",
                [api.Language.Es]: 'Explore Saga IA y vea cómo usar la IA directamente en sus notas y tareas.',
                [api.Language.Pt]: 'Explore o Saga IA e veja como usar a IA diretamente em suas notas e tarefas.',
                [api.Language.De]:
                    'Erkunden Sie Saga KI und sehen Sie, wie Sie KI direkt in Ihren Notizen und Aufgaben verwenden können.',
                [api.Language.It]:
                    "Esplora Saga IA e scopri come utilizzare l'IA direttamente nelle tue note e attività.",
            },
        ],
    },
};
