import React from 'react';
import { YEvent } from 'yjs';
import { observeSafeArray, observeSafeMap, SafeArrayOutput, SafeMapOutput } from '@saga/shared';

export function useSafeMap<A extends Record<string, unknown>>(safeMap: SafeMapOutput<A>, observeDeep = false) {
    const keysRef = React.useRef<Record<string, boolean>>({});
    const [trigger, forceUpdate] = React.useReducer(() => ({}), {});

    React.useEffect(() => {
        const unsubscribe = observeSafeMap(
            safeMap,
            (keys) => {
                if (keys.some((k) => keysRef.current[k])) {
                    forceUpdate();
                }
            },
            observeDeep,
        );
        return unsubscribe;
    }, [safeMap, observeDeep]);

    const proxy = React.useMemo(
        () =>
            new Proxy(safeMap, {
                get(target, prop, receiver) {
                    if (prop === 'get') {
                        return (key: string) => {
                            keysRef.current[key] = true;
                            return safeMap.get(key);
                        };
                    }

                    if (prop === 'getDecoded') {
                        return (key: string) => {
                            keysRef.current[key] = true;
                            return safeMap.getDecoded(key);
                        };
                    }

                    return Reflect.get(target, prop, receiver);
                },
            }),
        // We want to have a new proxy object when forceUpdate was called, therfore
        // we pass in the trigger
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [safeMap, trigger],
    );

    return proxy;
}

export function useSafeArray<A>(
    safeArray: SafeArrayOutput<A>,
    observeDeep = false,
    opts?: { shouldUpdate?: (events: YEvent<any>[]) => boolean },
) {
    const optsRef = React.useRef(opts);
    optsRef.current = opts;
    const [array, setArray] = React.useState(() => safeArray);

    React.useEffect(() => {
        const shouldUpdate = optsRef.current?.shouldUpdate;

        function update(events: YEvent<any>[]) {
            if (shouldUpdate ? shouldUpdate(events) : true) {
                setArray({ ...safeArray });
            }
        }

        const unsubscribe = observeSafeArray(
            safeArray,
            observeDeep
                ? {
                      observeDeep: true,
                      observe(events) {
                          update(events);
                      },
                  }
                : {
                      observeDeep: false,
                      observe(event) {
                          update([event]);
                      },
                  },
        );

        return unsubscribe;
    }, [safeArray, observeDeep]);

    return array;
}
