import React from 'react';
import { Member, MemberSuggestion } from '@/types';
import MemberAvatar from '../MemberAvatar';
import { getFullMemberName, useMembers } from '../MembersProvider';
import { InputDropdown, InputDropdownRenderItem } from '../popover/Dropdown';
import Button from '../styled/Button';
import { filterMembers } from '@/lib/Suggestions';
import { User, Minus } from 'react-feather';
import { stringToColor } from '@/../../shared/src/utils/Colors';
import { AVAILABLE_COLORS } from '@/constants';
import { useTranslation } from 'react-i18next';
import useMobile from '@/hooks/useMobile';
import { UserFragmentFragment } from '@/../../api/src';
import { useUserContext } from '../UserContext';
import * as api from '@saga/api';

type RemoveAssigneeSuggestion = {
    type: 'remove-assignee';
};

function AssigneeDropdown({
    attachToRef,
    isOpen,
    onClose,
    onChange,
    members,
    assignee,
}: {
    attachToRef: React.RefObject<HTMLElement>;
    isOpen: boolean;
    onClose(): void;
    onChange({ id, action }: { id: string | null; action: api.TaskAssignAction }): void;
    members: Member[];
    assignee: Member | null;
}) {
    const { user } = useUserContext();
    const { t } = useTranslation();

    const sortingFirstByAssigneeThenByUserViewing = (
        a: Member,
        b: Member,
        assignee: Member | null,
        user: UserFragmentFragment | undefined,
    ) => {
        const assigneeComparison = a.id === assignee?.id ? -1 : b.id === assignee?.id ? 1 : 0;

        if (assigneeComparison !== 0) {
            return assigneeComparison;
        }

        return a.id === user?.id ? -1 : b.id === user?.id ? 1 : 0;
    };

    return (
        <InputDropdown<MemberSuggestion | RemoveAssigneeSuggestion>
            attachToRef={attachToRef}
            isOpen={isOpen}
            onClose={onClose}
            onSubmit={({ selectedItem }) => {
                if (selectedItem.type === 'member') {
                    onChange({ id: selectedItem.member.id, action: api.TaskAssignAction.Assign });
                } else {
                    onChange({ id: assignee?.id || null, action: api.TaskAssignAction.Unassign });
                }
                onClose();
            }}
            inputProps={{
                placeholder: t('tasks.add_assignee_placeholder') as string,
                title: t('tasks.add_assignee_placeholder') as string,
            }}
            dropdownProps={{ align: 'left' }}
            renderItems={({ search }) => {
                const memberSuggestions = filterMembers(members, { search })
                    .sort((a, b) => sortingFirstByAssigneeThenByUserViewing(a, b, assignee, user))
                    .map((member): InputDropdownRenderItem<MemberSuggestion> => {
                        return {
                            render({ isSelected, setSelection, submit, index }) {
                                return (
                                    <div key={index}>
                                        <Button.PopOverButton
                                            selected={assignee?.id === member.id ?? isSelected}
                                            onClick={submit}
                                            onMouseEnter={() => setSelection()}
                                            hoverable
                                        >
                                            <div className="space-x-2 flex items-center">
                                                <MemberAvatar
                                                    name={getFullMemberName(member)}
                                                    color={stringToColor(member.id, AVAILABLE_COLORS)}
                                                    size="xs"
                                                    showBorder={false}
                                                />
                                                <span>{getFullMemberName(member)}</span>
                                            </div>
                                        </Button.PopOverButton>
                                    </div>
                                );
                            },
                            item: { type: 'member', member },
                        };
                    });

                return [
                    {
                        render({ isSelected, setSelection, submit, index }) {
                            return (
                                <div key={index}>
                                    <div className="flex items-center h-[26px] px-2 py-1 font-normal text-xs text-saga-gray-500">
                                        {t('common.select_or_search')}
                                    </div>
                                    <Button.PopOverButton
                                        selected={!assignee && isSelected}
                                        onClick={submit}
                                        onMouseEnter={() => setSelection()}
                                        hoverable
                                    >
                                        <div className="space-x-2 flex items-center">
                                            <Minus size={16} />
                                            <span>{t('tasks.no_assignee')}</span>
                                        </div>
                                    </Button.PopOverButton>
                                </div>
                            );
                        },
                        item: { type: 'remove-assignee' },
                    },
                    ...memberSuggestions,
                ];
            }}
        />
    );
}

export function AvatarAssigneeSelect({
    assignee,
    onChange,
    disabled,
}: {
    assignee: string | null;
    onChange?({ id, action }: { id: string | null; action: api.TaskAssignAction }): void;
    disabled: boolean;
}) {
    const { members, getMember } = useMembers();
    const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);
    const buttonRef = React.useRef<HTMLButtonElement>(null);
    const divRef = React.useRef<HTMLDivElement>(null);
    const assignedMember = assignee ? getMember(assignee) : null;
    const isMobile = useMobile();

    return (
        <>
            {assignedMember && (
                <div
                    ref={divRef}
                    onClick={
                        disabled || isMobile
                            ? undefined
                            : (e) => {
                                  e.stopPropagation();
                                  setIsDropdownOpen(!isDropdownOpen);
                              }
                    }
                    className="cursor-pointer space-x-2 flex items-center"
                >
                    <MemberAvatar
                        name={getFullMemberName(assignedMember)}
                        color={stringToColor(assignedMember.id, AVAILABLE_COLORS)}
                        showBorder={false}
                        size="l"
                    />
                </div>
            )}

            {!assignedMember && !isMobile && (
                <Button.Dashed
                    className="flex items-center justify-center rounded-5 h-[22px] w-[22px] shrink-0"
                    onClick={(e) => {
                        e.stopPropagation();
                        setIsDropdownOpen(!isDropdownOpen);
                    }}
                    disabled={disabled}
                    ref={buttonRef}
                >
                    <User className="h-3.5 w-3.5" />
                </Button.Dashed>
            )}

            {!disabled && onChange && (
                <AssigneeDropdown
                    attachToRef={assignedMember == null ? buttonRef : divRef}
                    isOpen={isDropdownOpen}
                    onClose={() => setIsDropdownOpen(false)}
                    onChange={onChange}
                    members={members}
                    assignee={assignedMember}
                />
            )}
        </>
    );
}

export default function AssigneeSelect({
    assignee,
    onChange,
    disabled,
    showEmptyState = true,
}: {
    assignee: string | null;
    onChange({ id, action }: { id: string | null; action: api.TaskAssignAction }): void;
    disabled: boolean;
    showEmptyState?: boolean;
}) {
    const { members, getMember } = useMembers();
    const assignedMember = assignee ? getMember(assignee) : null;

    const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);
    const buttonRef = React.useRef<HTMLButtonElement>(null);
    const divRef = React.useRef<HTMLDivElement>(null);
    const { t } = useTranslation();

    return (
        <>
            {assignedMember && (
                <div className="inline-block max-w-full">
                    <Button.RemovableOption
                        disabled={disabled}
                        onRemove={() => onChange({ id: assignedMember.id, action: api.TaskAssignAction.Unassign })}
                    >
                        <div
                            ref={divRef}
                            onClick={() => setIsDropdownOpen(true)}
                            className="cursor-pointer space-x-2 flex items-center"
                        >
                            <MemberAvatar
                                name={getFullMemberName(assignedMember)}
                                color={stringToColor(assignedMember.id, AVAILABLE_COLORS)}
                                size="sm"
                                showBorder={false}
                            />
                            <span className="truncate">{getFullMemberName(assignedMember)}</span>
                        </div>
                    </Button.RemovableOption>
                </div>
            )}

            {!assignedMember && showEmptyState && (
                <Button.DashedPill disabled={disabled} onClick={() => setIsDropdownOpen(true)} ref={buttonRef}>
                    {t('tasks.no_assignee')}
                </Button.DashedPill>
            )}

            {!disabled && (
                <AssigneeDropdown
                    attachToRef={assignedMember == null ? buttonRef : divRef}
                    isOpen={isDropdownOpen}
                    onClose={() => setIsDropdownOpen(false)}
                    onChange={onChange}
                    members={members}
                    assignee={assignedMember}
                />
            )}
        </>
    );
}
