import React from 'react';
import { Page } from '@saga/shared';
import { useUserContext } from '@/components/UserContext';
import * as api from '@saga/api';

const SharedPagesContext = React.createContext<{
    pages: (Pick<Page, 'id' | 'createdAt' | 'updatedAt' | 'icon' | 'title'> & {
        sharedBy: string;
        role: api.PagePermission;
    })[];
} | null>(null);

export function useSharedPages() {
    const pagesContext = React.useContext(SharedPagesContext);

    if (pagesContext == null) {
        throw new Error('Pages are null, hook needs to be inside of SharedPagesProvider');
    }

    return pagesContext.pages;
}

export function SharedPagesProvider({ children }: { children: React.ReactNode }) {
    const { user } = useUserContext();
    const [getPages, { data, subscribeToMore }] = api.useUserSharedPagesLazyQuery();

    React.useEffect(() => {
        if (!user) return;
        getPages();
    }, [user, getPages]);

    React.useEffect(() => {
        if (!user) return;

        const unsubscribe = subscribeToMore({
            document: api.UserSharedPagesUpdatedDocument,
            updateQuery: (_, { subscriptionData }) => subscriptionData.data,
        });

        return () => {
            unsubscribe();
        };
    }, [subscribeToMore, user]);

    const deps = React.useMemo(
        () => ({
            pages:
                data?.userSharedPages.map((page) => ({
                    title: page.title,
                    id: page.page.id,
                    createdAt: page.page.createdAt,
                    updatedAt: page.page.updatedAt,
                    icon: page.icon ? { type: 'emoji' as const, colons: page.icon.colons } : undefined,
                    sharedBy: page.sharedBy,
                    role: page.permission,
                })) || [],
        }),
        [data],
    );

    return <SharedPagesContext.Provider value={deps}>{children}</SharedPagesContext.Provider>;
}
