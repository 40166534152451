import { escapeRegExp } from '@/lib/helpers';
import classNames from 'classnames';
import React from 'react';
import { PopOver, usePopover } from './PopOver';
import useMobile from '@/hooks/useMobile';

export function useArrowSelectableIndex({
    onSelect,
    maxIndex,
    onUpdate,
    initialIndex,
    active = true,
    selectWithTab = false,
}: {
    onSelect(index: number, event: KeyboardEvent): void;
    onUpdate?: (index: number, event: KeyboardEvent) => void;
    initialIndex?: number;
    maxIndex: number;
    active?: boolean;
    selectWithTab?: boolean;
}) {
    const [index, setIndex] = React.useState<number | undefined>(initialIndex);

    const handlers = React.useRef({ onSelect, onUpdate });

    handlers.current = { onSelect, onUpdate };

    React.useEffect(() => {
        if (index == null) {
            return;
        }

        if (maxIndex < index) {
            setIndex(0);
        }
    }, [maxIndex, index]);

    React.useEffect(() => {
        if (active) {
            function onKeyDown(event: KeyboardEvent) {
                switch (true) {
                    case !selectWithTab && event.key === 'Tab' && !event.shiftKey:
                    case event.key === 'ArrowDown':
                        event.stopPropagation();
                        event.preventDefault();
                        const prevIndex = index == null ? 0 : index >= maxIndex ? 0 : index + 1;
                        setIndex(prevIndex);
                        handlers.current.onUpdate && handlers.current.onUpdate(prevIndex, event);
                        break;
                    case !selectWithTab && event.key === 'Tab' && event.shiftKey:
                    case event.key === 'ArrowUp':
                        event.stopPropagation();
                        event.preventDefault();
                        const nextIndex = index == null ? maxIndex : index <= 0 ? maxIndex : index - 1;
                        setIndex(nextIndex);
                        handlers.current.onUpdate && handlers.current.onUpdate(nextIndex, event);
                        break;
                    case selectWithTab && event.key === 'Tab':
                    case event.key === 'Enter':
                        if (index == null) {
                            return;
                        }
                        event.stopPropagation();
                        event.preventDefault();
                        handlers.current.onSelect(index, event);
                        return;
                }
            }

            document.addEventListener('keydown', onKeyDown, { capture: true });
            return () => document.removeEventListener('keydown', onKeyDown, { capture: true });
        }

        return;
    }, [index, maxIndex, active, selectWithTab]);

    return { index, setIndex };
}

type DropdownProps = {
    children: React.ReactNode;
    isOpen: boolean;
    onClose(): void;
    attachToRef: React.MutableRefObject<HTMLElement | null>;
    zIndex?: number;
    testId?: string;
    withPadding?: boolean;
    position?: 'below' | 'right';
    align?: 'left' | 'right' | 'center';
    onOpenAnimationDone?: () => void;
    maxWHighlight?: boolean;
    withOutPopOver?: boolean;
    renderDeps?: any[];
    widthLg?: boolean;
    overflow?: boolean;
};

function Dropdown({
    children,
    isOpen,
    onClose,
    attachToRef,
    zIndex,
    withPadding = true,
    testId,
    position,
    align,
    onOpenAnimationDone,
    maxWHighlight = false,
    renderDeps,
    withOutPopOver,
    widthLg,
    overflow = true,
}: DropdownProps) {
    const containerRef = React.useRef<HTMLDivElement>(null);
    const [popoverRef, contentRef] = usePopover({
        isOpen,
        onClose,
        target: () => {
            if (attachToRef.current) {
                return attachToRef.current.getBoundingClientRect();
            }
            return null;
        },
        clickOutsideRefs: [attachToRef],
        position,
        align,
        renderDeps,
    });

    React.useLayoutEffect(() => {
        const el = containerRef.current;
        if (el && isOpen && overflow) {
            const rect = el.getBoundingClientRect();
            el.style.maxHeight = `${window.innerHeight - rect.top - 10}px`;
        }
    }, [isOpen, overflow]);

    return withOutPopOver ? (
        <div
            className={classNames(
                'text-sm min-w-40 relative shadow-popupSmall rounded-md bg-white dark:bg-saga-gray-900 dark:border dark:border-saga-gray-800 pointer-events-auto',
                {
                    'p-1.5': withPadding,
                    'overflow-auto': overflow,
                },
            )}
        >
            <div
                ref={containerRef}
                className={classNames('text-sm min-w-40 relative bg-white dark:bg-saga-gray-900 pointer-events-auto', {
                    'max-w-highlight': maxWHighlight,
                })}
            >
                {children}
            </div>
        </div>
    ) : (
        <PopOver
            isOpen={isOpen}
            ref={popoverRef}
            contentRef={contentRef}
            testId={isOpen ? testId : undefined}
            zIndex={zIndex ?? 80}
            onOpenAnimationDone={onOpenAnimationDone}
        >
            <div
                className={classNames(
                    'text-sm min-w-40 relative shadow-popupSmall rounded-md bg-white dark:bg-saga-gray-900 dark:border dark:border-saga-gray-800 pointer-events-auto',
                    {
                        'p-1.5': withPadding,
                        'w-[272px]': widthLg,
                        'overflow-auto': overflow,
                    },
                )}
            >
                <div
                    ref={containerRef}
                    className={classNames(
                        'text-sm min-w-40 relative bg-white dark:bg-saga-gray-900 pointer-events-auto',
                        { 'max-w-highlight': maxWHighlight },
                    )}
                >
                    {children}
                </div>
            </div>
        </PopOver>
    );
}

export type RenderItemProps = {
    isSelected: boolean;
    setSelection: () => void;
    submit: (event: React.SyntheticEvent) => void;
    index: number;
};

export type InputDropdownRenderItem<Item> = {
    render: (props: RenderItemProps) => React.ReactNode;
    item: Item;
};

export type SelectDropdownRenderItem = {
    render: (props: RenderItemProps) => React.ReactNode;
    item: { title: string; type: string; kind: string; isTemplate?: boolean };
};

export type InputDropdownProps<Item> = {
    attachToRef: React.RefObject<HTMLElement>;
    isOpen: boolean;
    onClose: () => void;
    onSubmit: (metadata: {
        search: string;
        selectedItem: Item;
        event: React.SyntheticEvent<Element, Event> | Event;
    }) => void;
    renderItems: (metadata: { search: string }) => Array<InputDropdownRenderItem<Item>>;
    inputProps?: Omit<React.ButtonHTMLAttributes<HTMLInputElement>, 'className' | 'ref' | 'value' | 'onChange'>;
    dropdownProps?: Pick<DropdownProps, 'zIndex' | 'position' | 'align' | 'testId' | 'maxWHighlight'>;
    hideInput?: boolean;
    addTopPadding?: boolean;
    withOutPopOver?: boolean;
};

export function InputDropdown<Item>({
    attachToRef,
    isOpen,
    onClose,
    renderItems,
    onSubmit,
    inputProps,
    dropdownProps,
    hideInput,
    addTopPadding,
    withOutPopOver = false,
}: InputDropdownProps<Item>) {
    const inputRef = React.useRef<HTMLInputElement>(null);
    const [search, setSearch] = React.useState<string>('');
    const formRef = React.useRef<HTMLFormElement>(null);
    const isMobile = useMobile();

    const handleOnSubmit = (i: number, event: React.SyntheticEvent<Element, Event> | Event) => {
        const selectedIItem = renderItems({ search })[i]?.item;

        if (!selectedIItem) return;

        onSubmit({ search, selectedItem: selectedIItem, event });
    };

    const { index: selectedIndex, setIndex: setSelectedIndex } = useArrowSelectableIndex({
        onSelect: handleOnSubmit,
        maxIndex: renderItems({ search }).length - 1,
        active: isOpen,
        initialIndex: 0,
    });

    React.useEffect(() => {
        if (isOpen) {
            setSearch('');
            setSelectedIndex(0);
        }
    }, [isOpen, setSelectedIndex]);

    if (!isOpen) return null;

    return (
        <Dropdown
            isOpen={isOpen}
            attachToRef={attachToRef}
            onClose={onClose}
            align={isMobile ? 'left' : 'right'}
            withPadding={false}
            withOutPopOver={withOutPopOver}
            onOpenAnimationDone={() => {
                inputRef.current?.focus();
            }}
            {...dropdownProps}
        >
            {!hideInput && (
                <form
                    onSubmit={(e) => {
                        if (selectedIndex == null) {
                            return;
                        }

                        e.preventDefault();
                        handleOnSubmit(selectedIndex, e);
                    }}
                    ref={formRef}
                    className="sm:w-highlight max-w-highlight"
                >
                    <PopOver.Input
                        ref={inputRef}
                        type="text"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        {...inputProps}
                    />
                </form>
            )}
            <div
                className={classNames('px-1.5 pb-1.5', {
                    'pt-1.5': addTopPadding,
                })}
            >
                {renderItems({ search }).map(({ render }, index) =>
                    render({
                        isSelected: index === selectedIndex,
                        setSelection: () => setSelectedIndex(index),
                        submit: (event: React.SyntheticEvent) => handleOnSubmit(index, event),
                        index,
                    }),
                )}
            </div>
        </Dropdown>
    );
}

export type SelectDropdownProps = {
    attachToRef: React.RefObject<HTMLElement>;
    isOpen: boolean;
    onClose: () => void;
    onSubmit: (metadata: {
        selectedItem: { title: string; type: string; kind: string; isTemplate?: boolean };
        event: React.SyntheticEvent<Element, Event> | Event;
    }) => void;
    renderItems: () => Array<SelectDropdownRenderItem>;
    inputProps?: Omit<React.ButtonHTMLAttributes<HTMLInputElement>, 'className' | 'ref' | 'value' | 'onChange'>;
    dropdownProps?: Pick<DropdownProps, 'zIndex' | 'position' | 'align' | 'testId' | 'maxWHighlight'>;
    hideInput?: boolean;
    widthLg?: boolean;
};

export function SelectDropdown<Item>({
    attachToRef,
    isOpen,
    onClose,
    renderItems,
    onSubmit,
    dropdownProps,
    widthLg,
}: SelectDropdownProps) {
    const handleOnSubmit = (i: number, event: React.SyntheticEvent<Element, Event> | Event) => {
        const selectedIItem = renderItems()[i]?.item;

        if (!selectedIItem) return;

        onSubmit({ selectedItem: selectedIItem, event });
    };

    const { index: selectedIndex, setIndex: setSelectedIndex } = useArrowSelectableIndex({
        onSelect: handleOnSubmit,
        maxIndex: renderItems().length - 1,
        active: isOpen,
        initialIndex: 0,
    });

    React.useEffect(() => {
        if (isOpen) {
            setSelectedIndex(0);
        }
    }, [isOpen, setSelectedIndex]);

    if (!isOpen) return null;

    return (
        <Dropdown isOpen={isOpen} attachToRef={attachToRef} onClose={onClose} {...dropdownProps} widthLg={widthLg}>
            {renderItems().map(({ render }, index) =>
                render({
                    isSelected: index === selectedIndex,
                    setSelection: () => setSelectedIndex(index),
                    submit: (event: React.SyntheticEvent) => handleOnSubmit(index, event),
                    index,
                }),
            )}
        </Dropdown>
    );
}

export const filterSuggestions = <A extends { title: string }>(items: A[], search: string): A[] => {
    let newItems = items.slice();

    if (search.trim().length > 0) {
        const regex = new RegExp(`(?:^|\\s)${escapeRegExp(search.trim())}`, 'gi');
        newItems = newItems.filter((p) => p.title.match(regex));
    }

    return newItems.slice(0, 10);
};

export default Dropdown;
