import React, { useState } from 'react';
import { useSetSettings } from '../settings/SettingsProvider';
import Modal, { ModalLayout } from '../Modal';
import { track } from '@/analytics';
import { useTranslation } from 'react-i18next';

export function DowngradeFeedbackModal({ onClose }: { onClose: () => void }) {
    const [feedback, setFeedback] = useState('');
    const setSettings = useSetSettings();
    const { t } = useTranslation();

    return (
        <Modal.Medium isOpen={true}>
            <ModalLayout
                title={t('billing.feedback_modal.title')}
                primaryButtonLabel={t('billing.feedback_modal.primary_button')}
                primaryButtonAction={() => {
                    track('Downgrade Feedback', { feedback });
                    onClose();
                    setSettings('billing');
                }}
                primaryButtonDisabled={!feedback || feedback.trim().length < 6}
                onCloseAction={() => {
                    onClose();
                    setSettings('billing');
                }}
            >
                <p className="mb-6">{t('billing.feedback_modal.description')}</p>
                <textarea
                    className="w-full h-32 border rounded p-3 text-saga-text"
                    onChange={(e) => setFeedback(e.target.value)}
                    placeholder={t('billing.feedback_modal.placeholder')}
                    value={feedback}
                />
            </ModalLayout>
        </Modal.Medium>
    );
}
