import React, { useState } from 'react';
import { useAutolinkEnabled, useNonNullablePageSnapshot, usePageSnapshot } from '@/hooks/SpaceHooks';
import { useSlate } from 'slate-react';
import { useTriggerBlockSearch } from './ReferenceResults';
import { AlertTriangle, X } from 'react-feather';
import Notice from '../styled/Notice';
import { Range } from 'slate';
import Button from '@/components/styled/Button';
import { track } from '@/analytics';
import { SagaEditor, isTitle, SagaElement, SagaLocation, SpaceOperations, unsafeRight } from '@saga/shared';
import { useSpace, useCurrentSearchIndex } from '@/components/SpaceProvider';
import StaticEditor from '@/components/editor/StaticEditor';
import highlightDecorate from '../editor/decorator/highlightDecorate';
import { Search } from '@saga/shared';
import Modal from '../Modal';
import { compactPrettyLinkBlockPlugin } from '../editor/plugins/PrettyLink';
import { FindInLocationsResult } from '@/../../shared/src/SpaceOperations/findInLocations';
import { Trans, useTranslation } from 'react-i18next';
import { usePerformBlockChangeWithEditor } from '@/components/RealtimeDocumentProvider';
import Spinner from '@/components/loading/Spinner';

function UpdateReferencesReviewModal({
    results,
    previousTitle,
    newTitle,
    onClose,
    onUpdated,
    onDismiss,
    onStartUpdate,
    blockPlugins,
    updating,
}: {
    results: FindInLocationsResult[];
    previousTitle: string;
    newTitle: string;
    updating: boolean;
    onClose(): void;
    onDismiss(): void;
    onUpdated(): void;
    onStartUpdate(): void;
    blockPlugins: SagaEditor.Plugins.BlockPlugin[];
}) {
    const { space } = useSpace();
    const [checked, setChecked] = useState<string[]>(results.map((r) => r.result.blockId));
    const { t } = useTranslation();
    const performBlockChangeWithEditor = usePerformBlockChangeWithEditor();

    const update = async () => {
        onStartUpdate();

        await promiseAllInBatches(
            results.map(
                (result) => async () =>
                    performBlockChangeWithEditor(space, result.location, (editor) => {
                        Search.searchAndReplace(editor, {
                            oldValue: previousTitle,
                            newValue: newTitle,
                            blocksToUpdate: checked,
                        });
                    }),
            ),
            10,
        );

        track('update-references');
        onUpdated();
    };

    const staticBlockPlugins = React.useMemo(() => [compactPrettyLinkBlockPlugin, ...blockPlugins], [blockPlugins]);

    return (
        <Modal.Large zIndex={300} isOpen={true} onClose={onClose}>
            <div className="bg-white dark:bg-zinc-700 w-full p-8 space-y-5 text-center relative">
                <div className="absolute top-0 right-0 m-2">
                    <Button.XButton onClick={onClose} />
                </div>
                <>
                    <h1 className="text-3xl font-semibold text-center text-saga-black dark:text-zinc-200">
                        {t('update_references_modal.title')}
                    </h1>
                    <div className="w-96 space-y-5 mx-auto">
                        <p>
                            <Trans
                                i18nKey="update_references_modal.description"
                                count={results.length}
                                values={{ previousTitle, newTitle }}
                                components={{
                                    span_bold: <span className="font-bold" />,
                                    span_red: <span className="bg-saga-red bg-opacity-10 font-bold" />,
                                    span_yellow: <span className="bg-saga-primary bg-opacity-25 font-bold" />,
                                }}
                            />
                        </p>
                        <div className="flex flex-row justify-center space-x-2 mt-4">
                            <Button.Plain
                                disabled={checked.length === results.length}
                                onClick={() => setChecked(results.map((r) => r.result.blockId))}
                            >
                                <Button.SmallPadding>{t('update_references_modal.select_all')}</Button.SmallPadding>
                            </Button.Plain>

                            <Button.Plain disabled={checked.length === 0} onClick={() => setChecked([])}>
                                <Button.SmallPadding>{t('update_references_modal.deselect_all')}</Button.SmallPadding>
                            </Button.Plain>
                        </div>
                        <div className="text-left max-h-80 overflow-scroll mx-2 space-y-2">
                            {results.map(({ result: { blockId, block }, location }, index: number) => (
                                <div className="flex" key={`ref-${index}`}>
                                    <input
                                        className="w-4 my-auto mr-2 flex-none"
                                        type="checkbox"
                                        id={`checkbox-${index}`}
                                        checked={checked.includes(blockId)}
                                        onChange={() => {
                                            if (checked.includes(blockId)) {
                                                setChecked(checked.filter((c) => c !== blockId));
                                            } else {
                                                setChecked([...checked, blockId]);
                                            }
                                        }}
                                    />
                                    <div className="flex-grow px-2 rounded border border-saga-gray-200 dark:border-zinc-600 shadow-sm">
                                        <StaticEditor
                                            blocks={[block as SagaElement]}
                                            decorate={(nodeEntry) =>
                                                highlightDecorate(nodeEntry, [previousTitle], newTitle)
                                            }
                                            location={location}
                                            blockPlugins={staticBlockPlugins}
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="flex flex-row -mx-4 mt-4 space-x-2">
                            <Button className="w-full" variant="secondary" onClick={onDismiss} disabled={updating}>
                                {t('update_references_modal.dont_update')}
                            </Button>
                            <Button className="w-full" disabled={checked.length === 0 || updating} onClick={update}>
                                {updating ? (
                                    <div className="flex w-full items-center justify-center">
                                        <Spinner size={18} />
                                    </div>
                                ) : checked.length > 0 ? (
                                    t('update_references_modal.update_link', { count: checked.length })
                                ) : (
                                    t('update_references_modal.update_links')
                                )}
                            </Button>
                        </div>
                    </div>
                </>
            </div>
        </Modal.Large>
    );
}

const titlePageKeys = ['title'] as const;

type UpdatedTitleState = {
    newTitle: string;
    previousTitle: string;
    results: FindInLocationsResult[];
};

function useUpdatedTitle(
    location: SagaLocation.PageLocation,
    blockPlugins: SagaEditor.Plugins.BlockPlugin[],
    skip: boolean,
) {
    const editor = useSlate();
    const { title } = useNonNullablePageSnapshot(location.pageId, titlePageKeys);
    const { space } = useSpace();

    const [data, setData] = React.useState<UpdatedTitleState | null>(null);
    const initialTitleRef = React.useRef(skip ? null : title);

    const spaceblocks = useCurrentSearchIndex();
    const [autolinkEnabled] = useAutolinkEnabled();

    const clear = React.useCallback(() => {
        setData(null);
        // We need to also update the initialTitle after clearing because then we regard the new title as the initial title
        initialTitleRef.current = title;
    }, [title]);

    React.useEffect(() => {
        setData(null);
    }, [spaceblocks]);

    React.useEffect(() => {
        if (skip) return;
        const selection = editor.selection;
        const path = editor.selection?.focus.path;
        if (selection && Range.isCollapsed(selection) && path) {
            const [topLevel] = path;
            const timeout = setTimeout(() => {
                if (topLevel !== 0) {
                    initialTitleRef.current = title;
                }
            }, 500);

            return () => {
                clearTimeout(timeout);
            };
        }

        return;
    }, [editor, clear, title, skip]);

    React.useEffect(() => {
        if (skip) return;

        if (data !== null) {
            if (data.newTitle.trim() !== title.trim()) {
                setData((data) => {
                    if (data) {
                        return { ...data, newTitle: title };
                    }
                    return null;
                });
            } else if (data.previousTitle.trim() === title.trim()) {
                clear();
            }
        }
    }, [title, data, clear, skip]);

    React.useEffect(() => {
        if (skip || !autolinkEnabled) return;

        const previousTitle = initialTitleRef.current;

        // if we already have data, that means we already changed the title once, so we don't wanna set the data again
        if (data == null && previousTitle != null && title.trim() !== previousTitle.trim()) {
            const terms = Search.makeTerms([previousTitle]);
            if (terms == null) {
                return;
            }

            const condition = [terms];
            const results = SpaceOperations.findInLocations(unsafeRight(space.decode()), spaceblocks?.toJSON(), [
                Search.textQuery({ condition, matchMode: Search.MatchMode.TERMS, fuzzySearch: false }),
            ]);

            const filteredResults = results.filter(
                (result) => !SagaLocation.areLocationsEqual(result.location, location) && !isTitle(result.result.block),
            );

            if (filteredResults.length > 0) {
                setData({
                    newTitle: title,
                    previousTitle,
                    results: filteredResults,
                });
            }
        }
    }, [title, blockPlugins, space, location, data, skip, spaceblocks, autolinkEnabled]);

    return { data, clear };
}

const pageKeys = ['title', 'archivedAt', 'isTemplate', 'createdFromTemplate'] as const;
const templateKeys = ['title'] as const;

export default function UpdatedTitleNotice({
    location,
    blockPlugins,
}: {
    location: SagaLocation.PageLocation;
    blockPlugins: SagaEditor.Plugins.BlockPlugin[];
}) {
    const [reviewModalOpen, setReviewModalOpen] = React.useState(false);
    const { space } = useSpace();
    const page = usePageSnapshot(location.pageId, pageKeys);
    const templatePageId = page?.createdFromTemplate;
    const { t } = useTranslation();
    const performBlockChangeWithEditor = usePerformBlockChangeWithEditor();
    const [updating, setUpdating] = React.useState(false);

    const template = React.useMemo(() => {
        if (templatePageId) {
            return SpaceOperations.getPageById(space, templatePageId, templateKeys);
        }

        return null;
    }, [space, templatePageId]);

    const shouldSkip = Boolean(
        page?.archivedAt != null || page?.isTemplate || (template && template?.title === page?.title),
    );

    const { clear, data } = useUpdatedTitle(location, blockPlugins, shouldSkip);

    const triggerSearch = useTriggerBlockSearch();

    if (data == null || shouldSkip) {
        return null;
    }

    const { previousTitle, newTitle, results } = data;

    const blocks = results.filter(({ result: { block } }) => !isTitle(block));

    if (blocks.length === 0) {
        return null;
    }

    async function updateAll() {
        const allBlockIds = blocks.map(({ result }) => result.blockId);
        setUpdating(true);

        await promiseAllInBatches(
            results.map(
                (result) => async () =>
                    performBlockChangeWithEditor(space, result.location, (editor) => {
                        Search.searchAndReplace(editor, {
                            oldValue: previousTitle,
                            newValue: newTitle,
                            blocksToUpdate: allBlockIds,
                        });
                    }),
            ),
            10,
        );

        track('update-all-references');
        triggerSearch();
        clear();
        setUpdating(false);
    }

    return (
        <div className="w-full z-30 absolute left-0 right-0 top-0">
            <div className="w-full mx-auto max-w-700">
                <Notice>
                    <div className="py-2 px-3 inline-flex items-start space-x-4">
                        <div className="flex items-center h-5">
                            <AlertTriangle size={16} />
                        </div>
                        <div className="space-x-4 flex">
                            <div className="flex space-x-2 items-center whitespace-pre-wrap">
                                <Trans
                                    i18nKey="updated_title_notice.outdated_link"
                                    count={blocks.length}
                                    components={{
                                        bold: <span className="font-bold" />,
                                    }}
                                />
                            </div>
                            <div className="flex space-x-4">
                                <button
                                    disabled={updating}
                                    onClick={() => setReviewModalOpen(true)}
                                    className="hover:opacity-75 font-semibold"
                                >
                                    {t('updated_title_notice.review')}
                                </button>
                                <button
                                    onClick={updateAll}
                                    className="hover:opacity-75 font-semibold underline"
                                    disabled={updating}
                                >
                                    {t('updated_title_notice.update_all')}
                                </button>
                            </div>
                        </div>
                        <div className="flex items-center h-5">
                            {updating ? (
                                <div className="flex self-center">
                                    <Spinner size={16} />
                                </div>
                            ) : (
                                <button onClick={clear} className="hover:opacity-75">
                                    <X size={14} />
                                </button>
                            )}
                        </div>
                    </div>
                </Notice>
                {reviewModalOpen && (
                    <UpdateReferencesReviewModal
                        updating={updating}
                        blockPlugins={blockPlugins}
                        previousTitle={data.previousTitle}
                        newTitle={data.newTitle}
                        results={data.results}
                        onClose={() => setReviewModalOpen(false)}
                        onUpdated={() => {
                            setReviewModalOpen(false);
                            setUpdating(false);
                            triggerSearch();
                            clear();
                        }}
                        onDismiss={() => {
                            setReviewModalOpen(false);
                            clear();
                        }}
                        onStartUpdate={() => {
                            setUpdating(true);
                        }}
                    />
                )}
            </div>
        </div>
    );
}

async function promiseAllInBatches(items: (() => Promise<any>)[], batchSize: number): Promise<any> {
    let position = 0;
    let results: any[] = [];
    while (position < items.length) {
        const itemsForBatch = items.slice(position, position + batchSize);
        results = [...results, ...(await Promise.all(itemsForBatch.map((item) => item())))];
        position += batchSize;
    }
    return results;
}
