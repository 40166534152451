import React, { useEffect } from 'react';
import {
    BlockBuilder,
    flatMap,
    isBlockContainer,
    isIndentContainer,
    isLink,
    isParagraph,
    Link,
    PATTERNS,
    PrettyLink as PrettyLinkType,
    SagaElement,
    isPrettyLink,
    SagaEditor,
} from '@saga/shared';
import VoidSelectionShadow from '../VoidSelectionShadow';
import classNames from 'classnames';
import { AlertTriangle, Loader } from 'react-feather';
import { Editor as SlateEditor, Transforms, Text } from 'slate';
import { useTranslation } from 'react-i18next';
import { useFocused, useSelected } from 'slate-react';

export type PrettyLinkViewMode = 'compact' | 'default';

export const ContentLink = (props: {
    metadata: PrettyLinkType['metadata'];
    onImageLoadingError?: () => void;
    url: string;
    errorState: JSX.Element;
    viewMode: PrettyLinkViewMode;
}) => {
    const { metadata, onImageLoadingError, url, errorState, viewMode } = props;

    switch (metadata._tag) {
        case 'failed':
            return errorState;

        case 'loaded': {
            const { title, description, image, logo } = metadata;

            const withImage = viewMode !== 'compact' && image != null;

            return (
                <a
                    className={classNames('flex justify-between cursor-pointer w-full text-left select-none bg:white', {
                        'max-h-32': viewMode === 'default',
                        'max-h-24': viewMode === 'compact',
                        'border border-saga-gray-200 dark:border-zinc-600 rounded': !withImage,
                    })}
                    href={url}
                    target="_blank"
                    rel="noreferrer"
                >
                    <div
                        className={classNames(
                            'px-2 py-1 flex flex-col justify-between leading-normal overflow-hidden rounded w-full',
                            {
                                'border border-saga-gray-200 dark:border-zinc-600 border-r-0 rounded-r-none': withImage,
                            },
                        )}
                    >
                        <div className="flex flex-col">
                            <div className="flex flex-col">
                                <p
                                    className={classNames('text-gray-900 dark:text-white font-bold text-sm truncate', {
                                        'sm:text-base md:text-lg': viewMode === 'default',
                                    })}
                                >
                                    {title}
                                </p>
                                <div className="flex flex-row items-center h-7">
                                    {logo && (
                                        <img className="object-cover h-5 w-5 mr-2 rounded-sm" src={logo} alt={title} />
                                    )}
                                    <p className="truncate text-gray-400 dark:text-white font-normal text-xs">{url}</p>
                                </div>
                            </div>
                            {description && description.trim().length > 0 && (
                                <p className="text-gray-700 dark:text-white text-sm line-clamp-3">{description}</p>
                            )}
                        </div>
                    </div>
                    {image && viewMode === 'default' && (
                        <img
                            className={classNames(
                                'hidden sm:block object-cover aspect-video rounded h-32 border border-saga-gray-200 dark:border-zinc-600 border-l-0 rounded-l-none',
                            )}
                            src={image}
                            alt={title}
                            data-testid="pretty-link-main-image"
                            onError={() => onImageLoadingError && onImageLoadingError()}
                        />
                    )}
                </a>
            );
        }

        case 'loading':
        case 'not-loaded':
            return <LoadingState />;
    }
};

const LoadingState = () => {
    const { t } = useTranslation();
    return (
        <div className="flex justify-center h-16 items-center rounded border space-x-2">
            <Loader className="animate-spin" />
            <div>{t('editor.loading_preview_dotted')}</div>
        </div>
    );
};

export const ErrorState = (props: { selected: boolean; canEdit: boolean; turnIntoLink: () => void }) => {
    const { selected, canEdit, turnIntoLink } = props;
    const { t } = useTranslation();

    return (
        <div
            className={classNames(
                'w-full h-20 border-dashed border  rounded border-red-700 flex items-center justify-center text-red-700',
                {
                    'shadow-lightblue': selected,
                },
            )}
        >
            <div className="flex justify-center items-center space-x-1 ">
                <div className="flex space-x-2">
                    <AlertTriangle />
                    <div>{t('editor.link_preview_not_available')}</div>
                </div>
                {canEdit && (
                    <button
                        onClick={turnIntoLink}
                        className="font-semibold underline cursor hover:bg-saga-gray-200 p-1 rounded"
                    >
                        {t('editor.turn_into_link')}
                    </button>
                )}
            </div>
        </div>
    );
};

const getTheLinkOnly = (flatBlocks: [SagaElement, number[]][]): [Link, number[]] | null => {
    const allBlocksWithUrl = flatBlocks.filter((args): args is [Link, number[]] => isLink(args[0]));

    if (allBlocksWithUrl.length !== 1) return null;

    return allBlocksWithUrl[0];
};

// TODO: add unit test
export const getBlockToConvert = (blocks: SagaElement[]): [Link, number[]] | null => {
    const flatBlocks = flatMap(blocks, (n) => n);

    const block = getTheLinkOnly(flatBlocks);

    if (block == null) return null;

    const canBeConvertedToPrettyLink = flatBlocks.every(
        ([n]) =>
            isLink(n) ||
            isBlockContainer(n) ||
            isIndentContainer(n) ||
            isParagraph(n) ||
            (Text.isText(n) && (n.text === block[0].url || n.text.trim().length === 0)),
    );

    return canBeConvertedToPrettyLink ? block : null;
};

export const canBeConvertedToPrettyLink = (url: string): boolean => {
    const match = url.match(PATTERNS.link);

    if (match == null || match[0] == null) return false;

    return true;
};

function createPrettyLinkBlockPlugin(viewMode: PrettyLinkViewMode = 'default') {
    return SagaEditor.Plugins.createBlockPlugin({
        match: isPrettyLink,
        Component({ element, path, children, editor, blockPlugins }) {
            const { canEdit, location } = SagaEditor.useEditorContext();
            const selected = useSelected();
            const focused = useFocused();

            useEffect(() => {
                const onCopy = (event: ClipboardEvent) => {
                    event.preventDefault();

                    const clipboardData = event.clipboardData?.getData('application/x-saga');
                    if (clipboardData && clipboardData.includes(element.id)) return;

                    SagaEditor.Clipboard.copyBlocks([element], {
                        location: { ...location, blockId: element.id },
                        spaceUrlKey: 'public-page',
                        event,
                        action: 'copy',
                        blockPlugins,
                    });
                };

                if (focused && selected) {
                    document.addEventListener('copy', onCopy);
                }

                return () => {
                    document.removeEventListener('copy', onCopy);
                };
            }, [element, focused, selected, blockPlugins, location]);

            const onImageLoadingError = () => {
                if (element.metadata._tag !== 'loaded') return;

                const { image, ...rest } = element.metadata;
                // makes eslint happy
                image;

                Transforms.setNodes(
                    editor,
                    {
                        metadata: rest,
                    },
                    {
                        at: path,
                    },
                );
            };

            const onTurnIntoLink = () => {
                const linkText = (() => {
                    if ('title' in element.metadata) {
                        return element.metadata.title;
                    } else {
                        return element.url;
                    }
                })();

                const range = SlateEditor.range(editor, path);

                const link = BlockBuilder.paragraph([BlockBuilder.link(element.url, [BlockBuilder.text(linkText)])]);

                Transforms.insertNodes(editor, link, {
                    at: range,
                });

                setTimeout(() => {
                    Transforms.removeNodes(editor);
                });
            };

            return (
                <div
                    className={classNames(
                        'group my-1 rounded shadow-sm hover:shadow-refHover bg-white dark:bg-saga-gray-1000',
                        {
                            'shadow-lightblue': selected,
                        },
                    )}
                    contentEditable={false}
                    id={element.id}
                >
                    <VoidSelectionShadow path={path}>
                        <ContentLink
                            metadata={element.metadata}
                            onImageLoadingError={onImageLoadingError}
                            url={element.url}
                            errorState={
                                <ErrorState canEdit={canEdit} selected={selected} turnIntoLink={onTurnIntoLink} />
                            }
                            viewMode={viewMode}
                        />
                        <div className="hidden select-none">{children}</div>
                    </VoidSelectionShadow>
                </div>
            );
        },
    });
}

export const compactPrettyLinkBlockPlugin = createPrettyLinkBlockPlugin('compact');
export const prettyLinkBlockPlugin = createPrettyLinkBlockPlugin('default');
