import { HocuspocusProvider } from '@hocuspocus/provider';
import {
    DocumentName,
    Page,
    SafeSpace,
    SagaElement,
    SpaceOperations,
    Task,
    WeakPage,
    appendStringToTitle,
    assertNonNull,
    createDocumentForContent,
    newBlankPage,
    newBlankTask,
    unsafeRight,
    Stateless,
} from '@saga/shared';
import { IndexeddbPersistence } from 'y-indexeddb';
import * as Y from 'yjs';
import { fromUint8Array } from 'js-base64';
import { getCurrentUser } from '@/firebase';

export const createPage = (space: SafeSpace, startingFields: Partial<Page>, provider: HocuspocusProvider) => {
    const user = getCurrentUser();

    const page = SpaceOperations.createPage(
        space,
        newBlankPage({ ...startingFields, createdBy: user?.uid ?? undefined }),
    );
    const yDoc = createDocumentForContent({
        id: page.id,
        spaceId: unsafeRight(space.getDecoded('id')),
        blocks: page.blocks,
        icon: page.icon,
    });
    const docName = DocumentName.build('content', page.id, unsafeRight(space.getDecoded('id')));
    new IndexeddbPersistence(`offline-${docName}`, yDoc);

    provider.sendStateless(
        Stateless.encodeStateless('documentData', fromUint8Array(Y.encodeStateAsUpdate(yDoc)), page.id),
    );

    return page;
};

export const createTask = (space: SafeSpace, startingFields: Partial<Task>, provider: HocuspocusProvider) => {
    const user = getCurrentUser();
    const task = SpaceOperations.createTask(
        space,
        newBlankTask({ ...startingFields, createdBy: user?.uid ?? undefined }),
    );
    const yDoc = createDocumentForContent({
        id: task.id,
        spaceId: unsafeRight(space.getDecoded('id')),
        blocks: task.blocks,
        icon: undefined,
    });
    const docName = DocumentName.build('content', task.id, unsafeRight(space.getDecoded('id')));
    new IndexeddbPersistence(`offline-${docName}`, yDoc);

    provider.sendStateless(
        Stateless.encodeStateless('documentData', fromUint8Array(Y.encodeStateAsUpdate(yDoc)), task.id),
    );

    return task;
};

export const duplicatePage = (
    space: SafeSpace,
    provider: HocuspocusProvider,
    id: string,
    pageBlocks: SagaElement[],
    pageTitleAppendix = ` (Copy)`,
    partialProps: Partial<WeakPage> = {},
): Page => {
    const page = SpaceOperations.getPageById(space, id);

    assertNonNull(page);

    const duplicatedPage = SpaceOperations.transformDuplicate({ ...page, blocks: pageBlocks });
    const duplictatedPageWithNewTitle = appendStringToTitle(duplicatedPage, pageTitleAppendix);
    return createPage(space, { ...duplictatedPageWithNewTitle, ...partialProps }, provider);
};

export const duplicateTask = (
    space: SafeSpace,
    provider: HocuspocusProvider,
    id: string,
    taskBlocks: SagaElement[],
    taskTitleAppendix = ` (Copy)`,
    partialProps: Partial<WeakPage> = {},
): Task => {
    const task = SpaceOperations.findTask(space, id);

    assertNonNull(task);

    const duplicatedTask = SpaceOperations.transformDuplicate({ ...task, blocks: taskBlocks });
    const duplictatedTaskWithNewTitle = appendStringToTitle(duplicatedTask, taskTitleAppendix);
    return createTask(space, { ...duplictatedTaskWithNewTitle, ...partialProps }, provider);
};
