import React, { useContext } from 'react';
import * as api from '@saga/api';
import { useFirebaseContext } from './FirebaseContext';
import { FREE_SPACES_LIMIT, WEB_EXTENSION_ID } from '@/constants';
import { WorkspacesContext } from './WorkspacesContext';
import i18next from 'i18next';
import { isFeatureEnabled } from '@/analytics';
import { FeatureFlag } from '../../../shared/src/utils/FeatureFlags';

type UserContextType = {
    user: api.UserFragmentFragment | undefined;
    isLoading: boolean;
    isLoggedIn: boolean;
    userSettings: api.UserSettingsFragment | undefined;
    permissions: {
        canCreateFreeSpace: boolean;
        isAdmin: boolean;
    };
    cannyToken: string | undefined;
};

const UserContext = React.createContext<UserContextType>({
    user: undefined,
    isLoading: true,
    isLoggedIn: false,
    userSettings: undefined,
    permissions: {
        canCreateFreeSpace: true,
        isAdmin: false,
    },
    cannyToken: undefined,
});

export function useUserContext() {
    return useContext(UserContext);
}

export const UserContextProvider: React.FC = ({ children }) => {
    const { firebaseUser } = useFirebaseContext();
    const { data, loading } = api.useUserDataQuery({ skip: !firebaseUser });

    const freeSpacesCount = data ? data.spaces.filter((space) => !space.activeSubscription).length : FREE_SPACES_LIMIT;
    const canCreateFreeSpace = isFeatureEnabled(FeatureFlag.allowBilling) ? FREE_SPACES_LIMIT > freeSpacesCount : true;

    React.useEffect(() => {
        const sendTokenToSagaExtension = async () => {
            const token = data?.getCustomToken.token;

            if (!token) return;

            try {
                chrome &&
                    chrome.runtime.sendMessage(
                        WEB_EXTENSION_ID,
                        JSON.stringify({
                            type: 'store-token',
                            token,
                        }),
                        console.log,
                    );
            } catch (error) {}
        };

        sendTokenToSagaExtension();
    }, [data]);

    const user = data?.currentUser ?? undefined;
    const context = React.useMemo(() => ({ workspaces: data?.spaces ?? [], loading }), [data?.spaces, loading]);

    const userContext = React.useMemo(() => {
        if (user && i18next.language !== user.data.language.toLowerCase()) {
            i18next.changeLanguage(user?.data.language.toLowerCase());
        }
        return {
            user,
            isLoading: loading,
            isLoggedIn: user != null,
            userSettings: data?.userSettings ?? {
                showInlinePageLinkIcons: true,
                weekStartOnMonday: false,
                spaceSorting: [],
                customAIPrompts: [],
                hiddenDefaultPrompts: [],
            },
            permissions: {
                canCreateFreeSpace,
                isAdmin: user?.data.role === api.UserRole.God,
            },
            cannyToken: data?.cannyToken ?? undefined,
        };
    }, [data?.userSettings, data?.cannyToken, loading, user, canCreateFreeSpace]);

    return (
        <UserContext.Provider value={userContext}>
            <WorkspacesContext.Provider value={context}>{children}</WorkspacesContext.Provider>
        </UserContext.Provider>
    );
};
