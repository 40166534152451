import NavigationErrorBoundary from '@/components/NavigationErrorBoundary';
import useInterfaceSettings from '@/hooks/useInterfaceSettings';
import useMobile from '@/hooks/useMobile';
import { useRealtime } from '@/components/RealtimeProvider';
import React from 'react';
import { useNotifications } from './NotificationsProvider';
import { SlideIn } from '@/components/Transitions';
import NotificationsSidebar from '@/components/navigation/NotificationsSidebar';
import { useUserContext } from '@/components/UserContext';
import SettingsMenu from '@/components/settings/SettingsMenu';
import { useSettings } from '@/components/settings/SettingsProvider';
import Sidebar from '@/components/navigation/Sidebar';
import { ShareModalContextProvider } from '@/components/ShareButton/ShareModalContext';
import { useSpaceAccess } from '@/hooks/useSpaceAccess';
import isHotkey from 'is-hotkey';
import { InAppNotification } from '@/components/InAppNotifications';
import { IS_DEV } from '@/constants';
import ConnectionClosedModal from './ConnectionClosedModal';
import { isIOS, isAndroid } from 'react-device-detect';
import { SagaLocation } from '@saga/shared/src/SagaLocation';

function NavigationContainer({ children, location }: { children: React.ReactNode; location?: SagaLocation }) {
    const [isNotificationsOpen, setNotificationsOpen] = React.useState(false);

    const [{ fixedSidebar }, setInterfaceSettings] = useInterfaceSettings();
    const realtimeState = useRealtime();
    const isMobile = useMobile();
    const { canEdit } = useSpaceAccess();
    const { isLoggedIn } = useUserContext();
    const { unreadNotificationsCounter } = useNotifications();
    const hasUnreadNotifications = unreadNotificationsCounter > 0;
    const settings = useSettings();

    const openSidebar = React.useCallback(() => {
        setInterfaceSettings({ fixedSidebar: true });
    }, [setInterfaceSettings]);

    const closeSidebar = React.useCallback(() => {
        setInterfaceSettings({ fixedSidebar: false });
    }, [setInterfaceSettings]);

    function openNotifications() {
        setNotificationsOpen(true);
    }

    function closeNotifications() {
        setNotificationsOpen(false);
    }

    React.useEffect(() => {
        const listener = (ev: KeyboardEvent) => {
            if (isHotkey('mod+s')(ev)) {
                ev.preventDefault();
                ev.stopPropagation();

                if (fixedSidebar) {
                    closeSidebar();
                } else {
                    openSidebar();
                }
            }
        };

        document.addEventListener('keydown', listener);

        return () => document.removeEventListener('keydown', listener);
    }, [closeSidebar, fixedSidebar, openSidebar]);

    return (
        <ShareModalContextProvider>
            <div className="flex h-full">
                <Sidebar
                    isOpen={fixedSidebar}
                    isMobile={isMobile}
                    onClose={closeSidebar}
                    onOpen={openSidebar}
                    hasUnreadNotifications={hasUnreadNotifications}
                    onOpenNotifications={openNotifications}
                    location={location}
                />

                <div className="min-w-0 h-full relative flex flex-col w-full">
                    <NavigationErrorBoundary>{children}</NavigationErrorBoundary>
                </div>

                {realtimeState.state.type === 'CONNECTION_CLOSED' && <ConnectionClosedModal />}

                {settings != null && <SettingsMenu />}

                {isLoggedIn && (
                    <SlideIn isOpen={isNotificationsOpen}>
                        <NotificationsSidebar onClose={closeNotifications} />
                    </SlideIn>
                )}

                {canEdit && !IS_DEV && !isMobile && !isIOS && !isAndroid && <InAppNotification />}
            </div>
        </ShareModalContextProvider>
    );
}

export default NavigationContainer;
