import { isKatexBlock, SagaEditor } from '@saga/shared';
import React, { useEffect } from 'react';
import { useFocused } from 'slate-react';
import { Katexelement } from '../Katex';
import { useCurrentWorkspace } from '@/components/WorkspaceContext';

export const OPEN_KATEX_EDITOR_SET = new Set();

export const spaceKatexBlockPlugin = SagaEditor.Plugins.createBlockPlugin({
    match: isKatexBlock,
    Component(props) {
        const { location } = SagaEditor.useEditorContext();
        const { urlKey } = useCurrentWorkspace();
        const focused = useFocused();
        const { selected, element, blockPlugins } = props;

        useEffect(() => {
            const onCopy = (event: ClipboardEvent) => {
                event.preventDefault();
                const clipboardData = event.clipboardData?.getData('application/x-saga');
                if (clipboardData && clipboardData.includes(element.id)) return;

                SagaEditor.Clipboard.copyBlocks([element], {
                    location: { ...location, blockId: element.id },
                    spaceUrlKey: urlKey,
                    event,
                    action: 'copy',
                    blockPlugins,
                });
            };

            if (focused && selected) {
                document.addEventListener('copy', onCopy);
            }

            return () => {
                document.removeEventListener('copy', onCopy);
            };
        }, [element, focused, selected, blockPlugins, location, urlKey]);

        return <Katexelement {...props} />;
    },
});
