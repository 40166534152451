import { SagaElement } from '../types';
import extendEditorForNormalization from './extendEditorForNormalization';
import { createEditor } from 'slate';
import { Editor } from 'slate';
import { Normalizer } from './Normalizers';

// This function is used to normalize blocks before loading them into the server/editor
// We create an empty editor, and add one block at the time
// The Transforms operation triggers a normalization in Slate
// If the normalization fails, an error is thrown, and we remove any container block (removeParents)
// then try again the normalization, which will automatically add container blocks where needed
const normalizeBlocks = (blocks: SagaElement[], normalizers?: Normalizer[]): Editor => {
    const editor = extendEditorForNormalization(createEditor(), [], normalizers);
    editor.children = blocks;
    Editor.normalize(editor, { force: true });
    return editor;
};

export default normalizeBlocks;
