import React, { useMemo, useCallback } from 'react';
import { track } from '@/analytics';
import { useCreatePage, useShallowTasks } from '@/hooks/SpaceHooks';

import {
    CollectionSuggestion,
    CreateCollectionSuggestion,
    CreatePageSuggestion,
    CreateTaskSuggestion,
    PageSuggestion,
    TaskSuggestion,
} from '@/types';
import { useCollectionsSnapshot, usePartialPages } from '@/hooks/SpaceHooks';
import { filterSuggestions } from './Dropdown';
import PagesCollectionsPopOver, {
    appendCreateCollectionSuggestion,
    appendCreatePageSuggestion,
    appendCreateTaskSuggestion,
} from './PagesCollectionsPopOver';
import { assertYArray, findYArrayIndex, SpaceOperations } from '@saga/shared';
import { useSpace } from '@/components/SpaceProvider';
import * as Y from 'yjs';
import { useTranslation } from 'react-i18next';
import { createTask } from '@/utils/documentUtils';
import { useTaskFilters } from '@/hooks/useTaskFilters';

type AddToCollectionPopOverProps = {
    collectionId: string;
    attachToRef: React.MutableRefObject<HTMLDivElement | HTMLButtonElement | null>;
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    align?: 'left' | 'right' | 'center';
};

const keysMap = ['id', 'collections', 'title', 'icon', 'aliases', 'isTemplate', 'settings', 'updatedAt'] as const;

const useCreateSubCollection = () => {
    const { space } = useSpace();

    return useCallback(
        async (title: string, parentId: string) => {
            SpaceOperations.transactInSpace(space, () => {
                const newCollection = SpaceOperations.createCollection(space, title);
                const parentCollection = SpaceOperations.findCollectionById(space, parentId);
                const subCollections = parentCollection.get('subCollections');

                if (subCollections != null) {
                    assertYArray(subCollections);
                    if (findYArrayIndex(subCollections, (subCollection) => subCollection === newCollection.id) === -1) {
                        subCollections.push([newCollection.id]);
                    }
                } else {
                    const subCollections = new Y.Array();
                    subCollections.push([newCollection.id]);
                    parentCollection.set('subCollections', subCollections);
                }

                track('new-sub-collection');
            });
        },
        [space],
    );
};

function AddToCollectionPopOver({ collectionId, attachToRef, isOpen, setIsOpen, align }: AddToCollectionPopOverProps) {
    const pages = usePartialPages(keysMap, 'deep');
    const tasks = useShallowTasks();
    const collections = useCollectionsSnapshot();
    const { space, provider } = useSpace();
    const createPage = useCreatePage();
    const createSubCollection = useCreateSubCollection();
    const taskFilters = useTaskFilters();
    const { t } = useTranslation();

    const currentCollection = useMemo(() => {
        return collections.find((c) => c.id === collectionId);
    }, [collections, collectionId]);

    const items: (PageSuggestion | CollectionSuggestion | TaskSuggestion)[] = useMemo(() => {
        if (!currentCollection) return [];
        let pagesSuggestions = pages
            ? pages
                  .filter((p) => !p.collections.includes(collectionId))
                  .map((page): PageSuggestion => {
                      return { title: page.title, page, type: 'page' };
                  })
                  .sort(
                      (a: PageSuggestion, b: PageSuggestion) =>
                          new Date(b.page.updatedAt).getTime() - new Date(a.page.updatedAt).getTime(),
                  )
            : [];
        let collectionsSuggestions = collections
            ? collections
                  .filter((c) => c.id !== collectionId && !currentCollection.subCollections?.includes(c.id))
                  .map((collection): CollectionSuggestion => {
                      return { title: collection.title, collection, type: 'collection' };
                  })
            : [];

        let tasksSuggestions = tasks
            ? tasks
                  .filter((t) => !t.collections?.includes(collectionId))
                  .map((task): TaskSuggestion => {
                      return { title: task.title ?? 'Untitled', task, type: 'task' };
                  })
                  .sort(
                      (a: TaskSuggestion, b: TaskSuggestion) =>
                          new Date(b.task.updatedAt).getTime() - new Date(a.task.updatedAt).getTime(),
                  )
            : [];

        return [...pagesSuggestions, ...collectionsSuggestions, ...tasksSuggestions];
    }, [currentCollection, pages, collections, collectionId, tasks]);

    const onSubmit = useCallback(
        (data: {
            search: string;
            selectedItem:
                | PageSuggestion
                | CollectionSuggestion
                | TaskSuggestion
                | CreatePageSuggestion
                | CreateCollectionSuggestion
                | CreateTaskSuggestion;
            event: React.SyntheticEvent<Element, Event> | Event;
        }) => {
            setIsOpen(false);

            const { search, selectedItem } = data;

            switch (selectedItem.type) {
                case 'create': {
                    switch (selectedItem.kind) {
                        case 'collection': {
                            createSubCollection(search, collectionId);
                            track('create-collection', { source: 'collection-table' });
                            break;
                        }
                        case 'page': {
                            createPage({ title: search, collections: [collectionId] });
                            track('create-page', { source: 'collection-table' });
                            break;
                        }
                        case 'task': {
                            createTask(space, { title: search, collections: [collectionId], ...taskFilters }, provider);
                            track('create-task', { source: 'collection-table' });
                            break;
                        }
                    }
                    break;
                }
                case 'page':
                    const page = pages.find((p) => p.id === selectedItem.page.id);

                    if (page) {
                        SpaceOperations.addPageToCollection(space, page.id, collectionId);
                    }

                    track('add-page', { source: 'collection-table' });
                    break;

                case 'task':
                    const task = tasks.find((t) => t.id === selectedItem.task.id);

                    if (task) {
                        SpaceOperations.addTaskToCollection(space, task.id, collectionId);
                    }

                    track('add-task', { source: 'collection-table' });
                    break;

                case 'collection':
                    if (currentCollection) {
                        SpaceOperations.setSubCollections(
                            space,
                            currentCollection.id,
                            (currentCollection.subCollections ?? []).concat([selectedItem.collection.id]),
                        );
                    }
                    track('add-collection', { source: 'collection-table' });
                    break;
            }
        },
        [
            createSubCollection,
            setIsOpen,
            createPage,
            collectionId,
            pages,
            currentCollection,
            space,
            tasks,
            provider,
            taskFilters,
        ],
    );

    return isOpen ? (
        <PagesCollectionsPopOver
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
            attachToRef={attachToRef}
            onSubmit={onSubmit}
            getSuggestions={() => items}
            dropdownProps={{
                align,
                maxWHighlight: true,
            }}
            inputProps={{
                placeholder: t('collections.type_page_task_or_collection') as string,
                title: t('collections.type_page_task_or_collection') as string,
            }}
            label={t('collections.select_create_new')}
            filterBySearch={(currentSuggestions, search) => {
                return appendCreateCollectionSuggestion(
                    appendCreateTaskSuggestion(
                        appendCreatePageSuggestion(filterSuggestions(currentSuggestions, search), search),
                        search,
                    ),
                    search,
                );
            }}
        />
    ) : null;
}

export default AddToCollectionPopOver;
