import { track } from '@/analytics';
import React, { useEffect } from 'react';
import Button from './styled/Button';
import Card from './styled/Card';

type Props = {
    heading?: string | null;
    subheading: string;
    onButtonClick: () => void;
    buttonLabel: string;
};

const WhooopsModal = ({ subheading, onButtonClick, buttonLabel, heading }: Props) => {
    useEffect(() => {
        track('whoops_modal_poped_up');
    }, []);

    const onClick = () => {
        onButtonClick();
        track('reload button was clicked');
    };

    return (
        <div data-testid="whoops-modal" className="h-full w-full flex justify-center items-center">
            <Card.WithShadow>
                <Card.Background>
                    <div className="p-8">
                        <div className="m-8 space-y-5 w-96 text-center mx-auto flex flex-col items-center">
                            <h1 className="text-4xl font-bold text-center text-saga-black dark:text-zinc-200">
                                {heading ?? 'Whooops!'}
                            </h1>
                            <p>{subheading}</p>
                            <Button onClick={onClick}>{buttonLabel}</Button>
                        </div>
                    </div>
                </Card.Background>
            </Card.WithShadow>
        </div>
    );
};

export default WhooopsModal;
