import React from 'react';
import Modal, { ModalLayout } from '../Modal';
import * as api from '@saga/api';
import { useCurrentWorkspace } from '../WorkspaceContext';
import { Trans, useTranslation } from 'react-i18next';

export function ChangePlanIntervalModal({
    onClose,
    onConfirm,
    price,
    currentSubscription,
}: {
    onClose: () => void;
    onConfirm: () => void;
    price: api.SubscriptionPlanPrice;
    currentSubscription: api.SpaceSubscription;
}) {
    const { t } = useTranslation();
    const { members } = useCurrentWorkspace();
    const { data } = api.useSubscriptionUpcomingQuery({
        variables: { input: { subscriptionId: currentSubscription.subscriptionId, priceId: price.stripeId } },
    });

    return (
        <Modal.Medium isOpen={true}>
            <ModalLayout
                title={t('billing.change_plan_modal_title')}
                primaryButtonLabel={t('common.confirm_changes')}
                primaryButtonAction={onConfirm}
                secondaryButtonLabel={t('common.cancel')}
                secondaryButtonAction={onClose}
                onCloseAction={onClose}
            >
                <p className="mb-6">
                    {data?.subscriptionUpcoming && (
                        <Trans
                            i18nKey="billing.change_plan_modal_description"
                            shouldUnescape
                            components={{ bold: <span className="font-semibold" /> }}
                            count={members.length}
                            values={{
                                price: data ? `$${data.subscriptionUpcoming.total / 100}` : '...',
                                members: members.length,
                            }}
                        />
                    )}
                </p>
            </ModalLayout>
        </Modal.Medium>
    );
}
