import { BlockBuilder } from '..';
import { PageLocation } from '../SagaLocation';
import { isLiveBlockSource, SafeSpace, SagaElement } from '../types';
import { v4 as uuid } from 'uuid';
import { Editor, Location as SlateLocation, Transforms } from 'slate';
import addToReferenceRegistry from './addToReferenceRegistry';

export default function createLiveBlock(
    space: SafeSpace,
    { origin, editor, at }: { origin: PageLocation; editor: Editor; at: SlateLocation },
) {
    const nodes = Editor.nodes(editor, {
        at,
        mode: 'highest',
        match: (_, path) => path.length === 1,
    });

    const slateNodes = [...nodes].map((n) => n[0]) as SagaElement[];

    if (slateNodes.length === 0) {
        throw new Error('nodes not found');
    }

    const liveBlockSource = (() => {
        const includeLiveRefSource = slateNodes.some(isLiveBlockSource);
        const selectingMultipleBlocks = slateNodes.length > 1;

        if (selectingMultipleBlocks && includeLiveRefSource) {
            throw new Error(
                'Cannot create live block source when multiple selected nodes already contain a live block source.',
            );
        } else if (includeLiveRefSource) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            return slateNodes.find(isLiveBlockSource)!;
        } else {
            const liveBlockSource = BlockBuilder.liveBlockSource(uuid(), []);

            addToReferenceRegistry(space, {
                pageId: origin.pageId,
                liveReferenceSourceId: liveBlockSource.liveReferenceSourceId,
                liveBlocks: slateNodes,
                isArchived: false,
            });

            Transforms.wrapNodes(editor, liveBlockSource, { at, mode: 'highest', split: false });

            return liveBlockSource;
        }
    })();

    return BlockBuilder.liveBlock(liveBlockSource.liveReferenceSourceId, slateNodes);
}
