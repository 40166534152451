import React from 'react';
import {
    SagaEditor,
    isLinearIssue,
    LinearIssue,
    LinearIssueData,
    EditorOperations,
    SpaceOperations,
} from '@saga/shared';
import LinearIcon from '@/assets/LinearIcon';
import * as HoverCard from '@radix-ui/react-hover-card';
import parse from 'remark-parse';
import { toHast } from 'mdast-util-to-hast';
import { toHtml } from 'hast-util-to-html';
import { unified } from 'unified';
import gfm from 'remark-gfm';
import * as api from '@saga/api';
import Spinner from '@/components/loading/Spinner';
import { AlertTriangle, ExternalLink, Loader, RefreshCw } from 'react-feather';
import { useUserContext } from '@/components/UserContext';
import classNames from 'classnames';
import Button from '@/components/styled/Button';
import { openWindow, truncateString } from '@/utils';
import { formatDateOptimized } from '@/utils/dateUtils';
import { Transforms } from 'slate';
import { getSdk } from '@/graphql';

function Markdown({ value }: { value: string }) {
    const html = React.useMemo(() => {
        const processor = unified().use(parse).use(gfm);
        const mdast = processor.parse(value);
        const hast = toHast(mdast);
        if (hast) {
            const html = toHtml(hast);
            return html;
        }

        return '';
    }, [value]);

    return <div dangerouslySetInnerHTML={{ __html: html }}></div>;
}

function IssuePriorityIcon({ priority }: { priority: number }) {
    return (
        <>
            {priority === 0 && (
                <svg fill="#858699" width="16" height="16" viewBox="0 0 14 14" aria-label="No Priority">
                    <rect width="2" height="2" rx="1" transform="matrix(-1 0 0 1 5 6)"></rect>
                    <rect width="2" height="2" rx="1" transform="matrix(-1 0 0 1 8 6)"></rect>
                    <rect width="2" height="2" rx="1" transform="matrix(-1 0 0 1 11 6)"></rect>
                </svg>
            )}

            {priority === 1 && (
                <svg width="16" height="16" viewBox="-1 -1 16 16" fill="#F2994A" aria-label="Urgent Priority">
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M2 0a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2V2a2 2 0 00-2-2H2zm3.914 3h1.738L7.5 8.602H6.07L5.914 3zm1.809 7.164a.95.95 0 01-.938.938.934.934 0 110-1.867c.5 0 .934.417.938.93z"
                    ></path>
                </svg>
            )}

            {priority === 2 && (
                <svg fill="#858699" width="16" height="16" viewBox="0 0 16 16" aria-label="High Priority">
                    <rect x="1" y="8" width="3" height="6" rx="1"></rect>
                    <rect x="6" y="5" width="3" height="9" rx="1"></rect>
                    <rect x="11" y="2" width="3" height="12" rx="1"></rect>
                </svg>
            )}

            {priority === 3 && (
                <svg fill="#858699" width="16" height="16" viewBox="0 0 16 16" aria-label="Medium Priority">
                    <rect x="1" y="8" width="3" height="6" rx="1"></rect>
                    <rect x="6" y="5" width="3" height="9" rx="1"></rect>
                    <rect x="11" y="2" width="3" height="12" rx="1" fillOpacity="0.4"></rect>
                </svg>
            )}

            {priority === 4 && (
                <svg fill="#858699" width="16" height="16" viewBox="0 0 16 16" aria-label="Low Priority">
                    <rect x="1" y="8" width="3" height="6" rx="1"></rect>
                    <rect x="6" y="5" width="3" height="9" rx="1" fillOpacity="0.4"></rect>
                    <rect x="11" y="2" width="3" height="12" rx="1" fillOpacity="0.4"></rect>
                </svg>
            )}
        </>
    );
}

function IssueStateIcon({ type, color }: { type: string; color: string }) {
    return (
        <>
            {type === 'backlog' && (
                <div
                    style={{ borderColor: color, borderStyle: 'dotted', borderWidth: 1 }}
                    className="h-3 w-3 rounded-full"
                />
            )}
            {type === 'unstarted' && (
                <div
                    style={{ borderColor: color, borderStyle: 'solid', borderWidth: 1 }}
                    className="h-3 w-3 rounded-full"
                />
            )}
            {type === 'started' && (
                <svg className="h-3 w-3" viewBox="0 0 14 14" color={color} aria-label="In Progress">
                    <rect
                        x="1"
                        y="1"
                        className="h-3 w-3"
                        viewBox="0 0 14 14"
                        rx="6"
                        stroke="currentColor"
                        strokeWidth="2"
                        fill="none"
                    ></rect>
                    <path
                        fill="currentColor"
                        stroke="none"
                        d="M 3.5,3.5 L3.5,0 A3.5,3.5 0 0,1 5.557248383023656, 6.331559480312316 z"
                        transform="translate(3.5,3.5)"
                    ></path>
                </svg>
            )}
            {type === 'completed' && (
                <svg className="h-3 w-3" viewBox="0 0 14 14" aria-label="Done" color={color}>
                    <path
                        fill="currentColor"
                        stroke="currentColor"
                        d="M9.54541 3.54541L9.89896 3.89896L9.54541 3.54541L5.5 7.59081L4.45459 6.54541C3.92739 6.0182 3.07261 6.0182 2.54541 6.54541C2.0182 7.07261 2.0182 7.92739 2.54541 8.45459L4.54541 10.4546C5.07261 10.9818 5.92739 10.9818 6.45459 10.4546L11.4546 5.45459C11.9818 4.92739 11.9818 4.07261 11.4546 3.54541L11.101 3.89896L11.4546 3.54541C10.9274 3.0182 10.0726 3.0182 9.54541 3.54541ZM0.5 7C0.5 3.41015 3.41015 0.5 7 0.5C10.5899 0.5 13.5 3.41015 13.5 7C13.5 10.5899 10.5899 13.5 7 13.5C3.41015 13.5 0.5 10.5899 0.5 7Z"
                    ></path>
                </svg>
            )}
            {type === 'canceled' && (
                <svg className="h-3 w-3" viewBox="0 0 14 14" aria-label="Canceled" color={color}>
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14ZM5.03033 3.96967C4.73744 3.67678 4.26256 3.67678 3.96967 3.96967C3.67678 4.26256 3.67678 4.73744 3.96967 5.03033L5.93934 7L3.96967 8.96967C3.67678 9.26256 3.67678 9.73744 3.96967 10.0303C4.26256 10.3232 4.73744 10.3232 5.03033 10.0303L7 8.06066L8.96967 10.0303C9.26256 10.3232 9.73744 10.3232 10.0303 10.0303C10.3232 9.73744 10.3232 9.26256 10.0303 8.96967L8.06066 7L10.0303 5.03033C10.3232 4.73744 10.3232 4.26256 10.0303 3.96967C9.73744 3.67678 9.26256 3.67678 8.96967 3.96967L7 5.93934L5.03033 3.96967Z"
                        stroke="none"
                        fill="currentColor"
                    ></path>
                </svg>
            )}
        </>
    );
}

function LinearIssueContent({ id, refreshLinearIssue }: { id: string; refreshLinearIssue: () => void }) {
    const { user } = useUserContext();
    const { data, loading } = api.useLinearIssueQuery({
        variables: { input: { id } },
        fetchPolicy: 'network-only',
    });

    if (loading) {
        return (
            <div className="flex items-center justify-center h-16">
                <Spinner />
            </div>
        );
    }

    const issue = data?.linearIssue;

    if (issue == null) {
        return (
            <div className="flex items-center justify-center h-16 space-x-2 text-saga-red">
                <AlertTriangle />
                <span>Loading failed.</span>
            </div>
        );
    }

    return (
        <div className="py-1.5 px-2 space-y-1.5">
            <div className="flex items-center w-full justify-between">
                <div className="flex items-center justify-flex-start">
                    {issue.state && (
                        <div
                            className={classNames(
                                'rounded flex items-center space-x-1 bg-neutral-200 px-1 py-1 dark:bg-saga-gray-1000',
                            )}
                        >
                            <IssueStateIcon type={issue.state.type} color={issue.state.color} />
                            <span>{issue.state.name}</span>
                        </div>
                    )}
                    {issue?.priorityLabel && (
                        <div
                            className={classNames(
                                'rounded flex items-center space-x-1 bg-neutral-200 px-1 py-1 ml-2 dark:bg-saga-gray-1000',
                            )}
                        >
                            <IssuePriorityIcon priority={issue.priority} />
                            <span>{issue.priorityLabel}</span>
                        </div>
                    )}
                </div>
                {!issue.state && <div />}
                <div>
                    <Button.Plain onMouseDown={(e) => e.stopPropagation()} onClick={() => refreshLinearIssue()}>
                        <Button.SmallPadding>
                            <RefreshCw size={14} />
                        </Button.SmallPadding>
                    </Button.Plain>
                    <Button.Plain
                        onMouseDown={(e) => e.stopPropagation()}
                        onClick={() => {
                            openWindow(issue.url, '_blank');
                        }}
                    >
                        <Button.SmallPadding>
                            <ExternalLink size={14} />
                        </Button.SmallPadding>
                    </Button.Plain>
                </div>
            </div>

            {issue?.assignee?.name && <p>Asigned to: {issue.assignee.name}</p>}

            {issue?.updatedAt && (
                <p>Last updated: {formatDateOptimized(new Date(issue.updatedAt).toString(), user?.data.language)}</p>
            )}

            <h4 className="font-bold">{issue.title}</h4>
            {issue.description && <Markdown value={issue.description} />}
        </div>
    );
}

function LinearPopover({
    children,
    issue,
    refreshLinearIssue,
}: {
    children: React.ReactNode;
    issue: LinearIssueData;
    refreshLinearIssue: () => void;
}) {
    const { isLoggedIn } = useUserContext();
    if (!isLoggedIn) {
        return <>{children}</>;
    }

    return (
        <HoverCard.Root>
            <HoverCard.Trigger asChild>{children}</HoverCard.Trigger>
            <HoverCard.Portal>
                <HoverCard.Content style={{ zIndex: 1 }} align="start">
                    <div
                        onMouseDown={(e) => e.stopPropagation()}
                        className="text-xs space-y-2 relative shadow-popupSmall w-310 max-h-52 overflow-y-auto rounded-md bg-white dark:bg-zinc-700 dark:border dark:border-zinc-600 pointer-events-auto"
                    >
                        <LinearIssueContent refreshLinearIssue={refreshLinearIssue} id={issue?.id} />
                    </div>
                </HoverCard.Content>
            </HoverCard.Portal>
        </HoverCard.Root>
    );
}

function LinearIssueState({
    state,
    selected,
    refreshLinearIssue,
}: {
    state: LinearIssue['state'];
    selected?: boolean;
    refreshLinearIssue: () => void;
}) {
    if (state.type === 'loaded') {
        const issue = state.issue;

        return (
            <LinearPopover refreshLinearIssue={refreshLinearIssue} issue={issue}>
                <span
                    style={{
                        boxShadow: selected ? '0 0 0 3px #BBD6FB' : undefined,
                    }}
                    onClick={() => {
                        openWindow(issue.url, '_blank');
                    }}
                    className="select-none inline space-x-1 pl-0.5 pr-0.5 rounded text-saga-text dark:text-neutral-200 font-medium title:font-semibold"
                >
                    <LinearIcon className="inline-block align-text-bottom [height:1.2em] [width:1em]" />
                    <span>{truncateString(`${issue?.identifier} ${issue?.title}`, 50)}</span>
                </span>
            </LinearPopover>
        );
    }

    if (state.type === 'error') {
        return (
            <span
                style={{
                    boxShadow: selected ? '0 0 0 3px #BBD6FB' : undefined,
                }}
                className="text-saga-red select-none inline space-x-1 pl-0.5 pr-0.5 rounded font-medium title:font-semibold"
            >
                <AlertTriangle className="inline-block align-text-bottom [height:1.2em] [width:1em]" />
                <span className="max-w-56 truncate">Could not load Linear Issue</span>
            </span>
        );
    }

    return (
        <span
            style={{
                boxShadow: selected ? '0 0 0 3px #BBD6FB' : undefined,
            }}
            className="select-none inline space-x-1 pl-0.5 pr-0.5 rounded text-saga-text dark:text-neutral-200 font-medium title:font-semibold"
        >
            <Loader className="inline-block align-text-bottom [height:1.2em] [width:1em] animate-spin" />
            <span className="max-w-56 truncate">Loading</span>
        </span>
    );
}

export const linearIssueBlockPlugin = SagaEditor.Plugins.createBlockPlugin({
    match: isLinearIssue,
    Component({ element, selected, children, editor }) {
        const { isLoggedIn } = useUserContext();

        const refreshLinearIssue: any = () => {
            const nodeEntry = EditorOperations.Selection.getNode(editor, element.id);
            if (nodeEntry == null) return;

            Transforms.select(editor, {
                path: nodeEntry[1],
                offset: 2,
            });

            Transforms.delete(editor, { at: nodeEntry[1] });
            const elementStatus = element.state.type;
            if (elementStatus === 'loaded') {
                SpaceOperations.addLinearIssue(getSdk, editor, { type: 'get', linearIssueId: element.state?.issue.id });
            }
        };

        return (
            <span
                id={element.id}
                className={classNames('inline', { 'cursor-pointer': isLoggedIn })}
                contentEditable={false}
            >
                <LinearIssueState refreshLinearIssue={refreshLinearIssue} state={element.state} selected={selected} />
                <span className="select-none hidden">{children}</span>
            </span>
        );
    },
});
