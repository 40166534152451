import React, { useState, useRef, useEffect } from 'react';
import ManageAliasPopOver from '@/components/popover/ManageAliasPopOver';
import classNames from 'classnames';
import { Edit } from 'react-feather';
import Button from '../styled/Button';

const ManageAliasInput = ({
    currentTitle,
    currentAliases,
    isOpen,
    setIsOpen,
    onCreate,
    onRemove,
    canEdit,
    placeholder,
    small,
}: {
    currentTitle: string;
    currentAliases: string[];
    onCreate: (title: string) => void;
    onRemove: (id: string) => void;
    canEdit: boolean;
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    placeholder?: string;
    small?: boolean;
}) => {
    const buttonRef = useRef<HTMLButtonElement | null>(null);
    const ref = useRef<HTMLDivElement | null>(null);

    const maxWidth = small ? 400 : 800;

    const [buttonRefs, setButtonRefs] = useState<HTMLButtonElement[]>([]);
    const [maxIndex, setMaxIndex] = useState<number>(currentAliases.length);
    const [widthCalculated, setWidthCalculated] = useState<boolean>(false);

    useEffect(() => {
        setMaxIndex(currentAliases.length);
        setButtonRefs([]);
        setWidthCalculated(false);
    }, [currentAliases.length]);

    useEffect(() => {
        if (!widthCalculated && buttonRefs.length === maxIndex) {
            let width = 0;

            for (const buttonRef of buttonRefs) {
                width += buttonRef.getBoundingClientRect().width + 8;
            }
            if (width < maxWidth) {
                setWidthCalculated(true);
            } else {
                if (maxIndex > 0) {
                    setMaxIndex(maxIndex - 1);
                    setButtonRefs([]);
                } else {
                    setWidthCalculated(true);
                }
            }
        }
    }, [buttonRefs, maxIndex, maxWidth, widthCalculated]);

    const hasAliases = currentAliases.length > 0;
    const aliasesToShow = hasAliases ? currentAliases.slice(0, maxIndex) : [];

    return (
        <div
            className={classNames('hover-trigger max-w-400', {
                'flex truncate rounded': currentAliases.length > 0,
            })}
        >
            {aliasesToShow.length > 0 && (
                <div className="min-w-250 space-x-1 flex max-w-400" ref={ref}>
                    <p className="my-auto text-sm">Alias: </p>
                    {aliasesToShow.map((alias, i) => (
                        <Button.Pill
                            ref={(ref) => {
                                if (ref && !buttonRefs.includes(ref)) {
                                    setButtonRefs(buttonRefs.concat([ref]));
                                }
                            }}
                            onClick={() => canEdit && setIsOpen(true)}
                            key={`alias-pill-${i}`}
                            disabled={!canEdit}
                        >
                            <p className="my-auto">{alias}</p>
                        </Button.Pill>
                    ))}
                </div>
            )}
            {maxIndex < currentAliases.length && (
                <div
                    className={classNames('h-8 px-1 my-1 rounded-md border border-transparent text-sm', {
                        'cursor-pointer': canEdit,
                    })}
                    onClick={() => canEdit && setIsOpen(true)}
                >
                    +{currentAliases.length - maxIndex}
                </div>
            )}
            {canEdit && hasAliases && (
                <div className="px-1 my-1 rounded-md border border-transparent flex items-center hover-target text-sm">
                    <button className="cursor-pointer" onClick={() => setIsOpen(true)}>
                        <Edit className="stroke-saga-gray-500 rounded hover:bg-saga-gray-200 dark:hover:bg-zinc-200/10 focus:outline-none w-4 h-4" />
                    </button>
                </div>
            )}
            {canEdit && !hasAliases && (
                <div className="inline-block">
                    {placeholder && (
                        <Button.DashedPill
                            onClick={() => {
                                setIsOpen(true);
                            }}
                            ref={buttonRef}
                        >
                            <p className="my-auto">{placeholder}</p>
                        </Button.DashedPill>
                    )}
                    {!placeholder && ' '}
                </div>
            )}
            {isOpen && (
                <ManageAliasPopOver
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    attachToRef={hasAliases ? ref : buttonRef}
                    currentTitle={currentTitle}
                    currentAliases={currentAliases}
                    onCreate={onCreate}
                    onRemove={onRemove}
                ></ManageAliasPopOver>
            )}
        </div>
    );
};

export default ManageAliasInput;
