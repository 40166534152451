import React, { useState, useRef, useEffect, MouseEvent, Dispatch, SetStateAction, MouseEventHandler } from 'react';
import { ChevronDown, ChevronUp, Plus } from 'react-feather';
import AddToCollectionPopOver from '@/components/popover/AddToCollectionPopOver';
import Tooltip from '@/components/popover/Tooltip';
import Button from '../styled/Button';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

export const ButtonWithChevronOption = ({
    onClick,
    children,
    setIsSelected,
    setIsOpen,
    isSelected,
    icon,
}: {
    onClick: MouseEventHandler<HTMLButtonElement> | undefined;
    children: React.ReactNode;
    setIsSelected: Dispatch<SetStateAction<boolean>>;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    isSelected: boolean;
    icon: any;
}) => {
    return (
        <div className="flex flex-row items-center rounded">
            <Button.Action onClick={onClick} className="divide-x divide-saga-gray-300 dark:divide-saga-gray-dark">
                <div className="hover:bg-saga-gray-250 dark:hover:bg-saga-gray-800 rounded-l">
                    <Button.WithIconPadding>
                        <div className="h-5 flex flex-row items-center justify-center">
                            {icon}
                            <div className="h-8 flex items-center">
                                <p className="pr-2 truncate">{children}</p>
                            </div>
                        </div>
                    </Button.WithIconPadding>
                </div>
                <div
                    data-testid="add-to-collection-expand"
                    className={classNames(
                        'w-full h-full hover:bg-saga-gray-250 dark:hover:bg-saga-gray-800 rounded-r px-1',
                        {
                            'bg-saga-gray-200 dark:bg-saga-gray-700': isSelected,
                        },
                    )}
                    onClick={(e) => {
                        e.stopPropagation();
                        setIsSelected((isSelected) => !isSelected);
                        setIsOpen((state) => !state);
                    }}
                >
                    {isSelected ? (
                        <ChevronUp className="mx-1 h-full" size={14} />
                    ) : (
                        <ChevronDown className="mx-1 h-full" size={14} />
                    )}
                </div>
            </Button.Action>
        </div>
    );
};

export const SingleButtonWithChevronOption = ({
    children,
    setIsSelected,
    setIsOpen,
    isSelected,
    icon,
    widthLg,
    disabled,
}: {
    onClick: MouseEventHandler<HTMLButtonElement> | undefined;
    children: React.ReactNode;
    setIsSelected: Dispatch<SetStateAction<boolean>>;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    isSelected: boolean;
    icon: any;
    widthLg?: boolean;
    disabled?: boolean;
}) => {
    return (
        <div className="flex flex-row items-center rounded">
            <Button.Action
                onClick={(e) => {
                    e.stopPropagation();
                    setIsSelected((isSelected) => !isSelected);
                    setIsOpen((state) => !state);
                }}
                className="flex gap-1.5 px-2 py-1.5"
                disabled={disabled}
            >
                {icon}
                <div className="rounded-l">
                    <div
                        className={classNames('h-5 flex flex-row items-center justify-center', {
                            'w-56': widthLg,
                        })}
                    >
                        <div className="h-8 flex items-center">
                            <p className="truncate text-sm font-normal">{children}</p>
                        </div>
                    </div>
                </div>
                <div data-testid="add-to-collection-expand" className="w-full h-full 0 rounded-r">
                    {isSelected ? (
                        <ChevronUp className="h-full" size={14} />
                    ) : (
                        <ChevronDown className="h-full" size={14} />
                    )}
                </div>
            </Button.Action>
        </div>
    );
};

const AddToCollectionButton = ({
    collectionId,
    children,
    onCreateEmptyPageInCollection,
}: {
    collectionId: string;
    children: React.ReactNode;
    onCreateEmptyPageInCollection: (event: MouseEvent<HTMLButtonElement>) => void;
}) => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const [isSelected, setIsSelected] = useState(false);
    const elementRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        function handleClickOutside(event: Event) {
            if (elementRef.current && !elementRef.current.contains(event.target as Node)) {
                setIsSelected(false);
            }
        }

        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    return (
        <>
            <Tooltip content={t('collections.add_to_collection')} placement="right">
                <div ref={elementRef}>
                    <ButtonWithChevronOption
                        onClick={onCreateEmptyPageInCollection}
                        setIsSelected={setIsSelected}
                        setIsOpen={setIsOpen}
                        isSelected={isSelected}
                        icon={<Plus className="flex-none mx-1" size={14} />}
                    >
                        {children}
                    </ButtonWithChevronOption>
                </div>
            </Tooltip>

            {isOpen && (
                <AddToCollectionPopOver
                    collectionId={collectionId}
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    attachToRef={elementRef}
                />
            )}
        </>
    );
};

export default AddToCollectionButton;
