import { track } from '@/analytics';
import { resetCloudfrontCookies } from '@/utils/cookie';
import * as Sentry from '@sentry/react';
import { WEB_EXTENSION_ID } from '@/constants';
import { logout } from '@/firebase';
import { resetTracking } from '@/analytics';

export const useLogout = () => {
    return async () => {
        try {
            await logout();

            track('signout');
            resetTracking();
            resetCloudfrontCookies();
            try {
                chrome &&
                    chrome.runtime.sendMessage(
                        WEB_EXTENSION_ID,
                        JSON.stringify({
                            type: 'sign-out',
                        }),
                        console.log,
                    );
            } catch (error) {}
        } catch (error) {
            Sentry.captureException(error, { level: 'fatal' });
        }
    };
};
