import { BlockBuilder } from '../..';
import { newBlankPage } from '../../pageUtils';

export function newSagaAdvancedPageEn(collectionIds: string[]) {
    return newBlankPage({
        title: 'Saga advanced',
        icon: {
            type: 'emoji',
            colons: ':heart_eyes:',
        },
        collections: collectionIds,
        blocks: [
            BlockBuilder.title('Saga advanced'),
            BlockBuilder.callout([
                BlockBuilder.paragraph([
                    BlockBuilder.text('Explore more powerful features and start working better with Saga.'),
                ]),
            ]),
            BlockBuilder.headingThree('Stay organized without thinking about it'),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Create '),
                BlockBuilder.link('https://saga.so/guides/collections-of-pages', 'collections'),
                BlockBuilder.text(' and group similar pages.'),
            ]),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Pin the most important pages and collections to the '),
                BlockBuilder.link('https://saga.so/guides/sidebar', 'sidebar'),
                BlockBuilder.text('.'),
            ]),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Switch quickly between pages, tasks, and collections with the '),
                BlockBuilder.link('https://saga.so/guides/search-in-saga', 'unified search'),
                BlockBuilder.text('.'),
            ]),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Click on '),
                BlockBuilder.link('https://saga.so/guides/task-management', 'Tasks'),
                BlockBuilder.text(' in the sidebar to manage all tasks from all your pages in one place.'),
            ]),
            BlockBuilder.paragraph(),
            BlockBuilder.headingThree('Connect your knowledge like a pro'),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Use the autocomplete menu to '),
                BlockBuilder.link('https://saga.so/guides/page-links', 'mention pages'),
                BlockBuilder.text(' and create links inside your workspace.'),
            ]),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Open the '),
                BlockBuilder.link('https://saga.so/guides/references', 'References'),
                BlockBuilder.text(' to the right side to see where your current page has been mentioned.'),
            ]),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Create links to pages '),
                BlockBuilder.link('https://saga.so/guides/automatic-linking', 'automatically'),
                BlockBuilder.text(' by typing their titles. For example this is a link to Saga AI.'),
            ]),
            BlockBuilder.checkListItem([
                BlockBuilder.link('https://saga.so/guides/page-links', 'Preview'),
                BlockBuilder.text(' pages instantly by hovering over links.'),
            ]),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Create '),
                BlockBuilder.link('https://saga.so/guides/live-blocks', 'Live blocks'),
                BlockBuilder.text(
                    ' to effortlessly sync content across pages, eliminating duplicates and maintaining up-to-date information.',
                ),
            ]),
            BlockBuilder.paragraph(),
            BlockBuilder.headingThree('Get productive as never before'),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Learn the '),
                BlockBuilder.link('https://saga.so/guides/keyboard-shortcuts', 'shortcuts'),
                BlockBuilder.text('.'),
            ]),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Open pages in '),
                BlockBuilder.link('https://saga.so/guides/side-by-side-view', 'side-by-side'),
                BlockBuilder.text(' view to work on two pages at once.'),
            ]),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Find anything instantly across your workspace with the '),
                BlockBuilder.link('https://saga.so/guides/search-in-saga', 'unified search'),
                BlockBuilder.text('.'),
            ]),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Integrate with '),
                BlockBuilder.link('https://saga.so/guides/google-drive-integration', 'Google Drive'),
                BlockBuilder.text(' or '),
                BlockBuilder.link('https://saga.so/guides/linear-integration', 'Linear'),
                BlockBuilder.text(' to mention or search files and tasks stored inside them.'),
            ]),
            BlockBuilder.checkListItem([
                BlockBuilder.text(
                    'Head to ✨ Saga AI to learn how to use the powerful AI assistant directly in your workspace.',
                ),
            ]),
            BlockBuilder.paragraph(),
            BlockBuilder.divider(),
            BlockBuilder.headingThree('More guides and FAQs'),
            BlockBuilder.paragraph([
                BlockBuilder.text('Visit the Saga Guides and see how to make the most out of the app.'),
            ]),
            BlockBuilder.rawPrettyLink('https://saga.so/guides', {
                _tag: 'loaded',
                description: "This guide will help you understand Saga's building blocks and get you up to speed.",
                title: '📍 Saga Guides',
                logo: 'https://saga.so/thumbnail.png',
            }),
            BlockBuilder.paragraph(),
        ],
    });
}
