import React from 'react';

import { useUserContext } from '@/components/UserContext';

import { PagesTableColumnKey, TasksTableColumnKey, CollectionsTableColumnKey } from '@saga/shared';
import { SharedPagesTableColumnKey } from '@saga/shared';

import * as api from '@saga/api';
const DEFAULT_COLUMNS: PagesTableColumnKey[] = ['title', 'collection', 'wordCount', 'createdBy', 'created', 'updated'];
const DEFAULT_SHARED_COLUMNS: SharedPagesTableColumnKey[] = ['title', 'sharedBy', 'created', 'role'];
const DEFAULT_TASKS_COLUMNS: TasksTableColumnKey[] = [
    'title',
    'collection',
    'assignee',
    'dueDate',
    'priority',
    'references',
    'labels',
    'createdBy',
    'created',
    'updated',
    'completedDate',
];
const DEFAULT_COLLECTIONS_COLUMNS: CollectionsTableColumnKey[] = ['title', 'pageCount', 'created', 'updated'];

export const usePagesTableColumns = (): [PagesTableColumnKey[], (columns: PagesTableColumnKey[]) => void] => {
    const { userSettings } = useUserContext();
    const [updateUserSettings] = api.useUpdateUserSettingsMutation();

    const columns = React.useMemo(() => {
        return (userSettings?.extendedSettings?.pagesColumnsOrder as PagesTableColumnKey[]) ?? DEFAULT_COLUMNS;
    }, [userSettings]);

    const setColumns = React.useCallback(
        (columns: PagesTableColumnKey[]) => {
            updateUserSettings({
                variables: {
                    input: { extendedSettings: { pagesColumnsOrder: columns } },
                },
                update(cache, { data }) {
                    if (!data?.updateUserSettings) return;

                    const cachedData = cache.readQuery<api.UserDataQuery>({
                        query: api.UserDataDocument,
                    });

                    cache.writeQuery({
                        query: api.UserDataDocument,
                        data: {
                            ...(cachedData ?? {}),
                            userSettings: {
                                ...(cachedData?.userSettings ?? {}),
                                extendedSettings: {
                                    ...(cachedData?.userSettings?.extendedSettings ?? {}),
                                    pagesColumnsOrder: columns,
                                },
                            },
                        },
                    });
                },
            });
        },
        [updateUserSettings],
    );

    return React.useMemo(() => [columns, setColumns], [columns, setColumns]);
};

export const useSharedPagesTableColumns = (): [
    SharedPagesTableColumnKey[],
    (columns: SharedPagesTableColumnKey[]) => void,
] => {
    const { userSettings } = useUserContext();
    const [updateUserSettings] = api.useUpdateUserSettingsMutation();

    const columns = React.useMemo(() => {
        return (
            (userSettings?.extendedSettings?.sharedPagesColumnsOrder as SharedPagesTableColumnKey[]) ??
            DEFAULT_SHARED_COLUMNS
        );
    }, [userSettings]);

    const setColumns = React.useCallback(
        (columns: SharedPagesTableColumnKey[]) => {
            updateUserSettings({
                variables: {
                    input: { extendedSettings: { sharedPagesColumnsOrder: columns } },
                },
                update(cache, { data }) {
                    if (!data?.updateUserSettings) return;

                    const cachedData = cache.readQuery<api.UserDataQuery>({
                        query: api.UserDataDocument,
                    });

                    cache.writeQuery({
                        query: api.UserDataDocument,
                        data: {
                            ...(cachedData ?? {}),
                            userSettings: {
                                ...(cachedData?.userSettings ?? {}),
                                extendedSettings: {
                                    ...(cachedData?.userSettings?.extendedSettings ?? {}),
                                    sharedPagesColumnsOrder: columns,
                                },
                            },
                        },
                    });
                },
            });
        },
        [updateUserSettings],
    );

    return React.useMemo(() => [columns, setColumns], [columns, setColumns]);
};

export const useTaskTableColumns = (): [TasksTableColumnKey[], (columns: TasksTableColumnKey[]) => void] => {
    const { userSettings } = useUserContext();
    const [updateUserSettings] = api.useUpdateUserSettingsMutation();

    const columns = React.useMemo(() => {
        return (userSettings?.extendedSettings?.tasksColumnsOrder as TasksTableColumnKey[]) ?? DEFAULT_TASKS_COLUMNS;
    }, [userSettings]);

    const setColumns = React.useCallback(
        (columns: TasksTableColumnKey[]) => {
            updateUserSettings({
                variables: {
                    input: { extendedSettings: { tasksColumnsOrder: columns } },
                },
                update(cache, { data }) {
                    if (!data?.updateUserSettings) return;

                    const cachedData = cache.readQuery<api.UserDataQuery>({
                        query: api.UserDataDocument,
                    });

                    cache.writeQuery({
                        query: api.UserDataDocument,
                        data: {
                            ...(cachedData ?? {}),
                            userSettings: {
                                ...(cachedData?.userSettings ?? {}),
                                extendedSettings: {
                                    ...(cachedData?.userSettings?.extendedSettings ?? {}),
                                    tasksColumnsOrder: columns,
                                },
                            },
                        },
                    });
                },
            });
        },
        [updateUserSettings],
    );

    return React.useMemo(() => [columns, setColumns], [columns, setColumns]);
};

export const useCollectionsTableColumns = (): [
    CollectionsTableColumnKey[],
    (columns: CollectionsTableColumnKey[]) => void,
] => {
    const { userSettings } = useUserContext();
    const [updateUserSettings] = api.useUpdateUserSettingsMutation();

    const columns = React.useMemo(() => {
        return (
            (userSettings?.extendedSettings?.collectionsColumnsOrder as CollectionsTableColumnKey[]) ??
            DEFAULT_COLLECTIONS_COLUMNS
        );
    }, [userSettings]);

    const setColumns = React.useCallback(
        (columns: CollectionsTableColumnKey[]) => {
            updateUserSettings({
                variables: {
                    input: { extendedSettings: { collectionsColumnsOrder: columns } },
                },
                update(cache, { data }) {
                    if (!data?.updateUserSettings) return;

                    const cachedData = cache.readQuery<api.UserDataQuery>({
                        query: api.UserDataDocument,
                    });

                    cache.writeQuery({
                        query: api.UserDataDocument,
                        data: {
                            ...(cachedData ?? {}),
                            userSettings: {
                                ...(cachedData?.userSettings ?? {}),
                                extendedSettings: {
                                    ...(cachedData?.userSettings?.extendedSettings ?? {}),
                                    collectionsColumnsOrder: columns,
                                },
                            },
                        },
                    });
                },
            });
        },
        [updateUserSettings],
    );

    return React.useMemo(() => [columns, setColumns], [columns, setColumns]);
};
