import { PageIcon } from '@/components/icons';
import Tooltip from '@/components/popover/Tooltip';
import { dndTypes } from '@/constants';
import { DndReferenceItem } from '@/types';
import { Page, SagaLocation, WeakTask } from '@saga/shared';
import React from 'react';
import { useDrag } from 'react-dnd';
import { Archive, Link, Maximize2, Minimize2, Trash } from 'react-feather';
import Card from '../styled/Card';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import TaskStatusSelect from '@/components/tasks/TaskStatusSelect';

export default function ReferenceResultCard({ children, title }: { children: React.ReactNode; title?: string }) {
    return (
        <Card className="mb-3">
            <div
                className="p-3 text-sm"
                data-testid={title ? `ref-card-${title}` : undefined}
                style={{ minHeight: '2.6rem' }}
            >
                {children}
            </div>
        </Card>
    );
}

ReferenceResultCard.Contexts = {
    IsBlockOverflowingContext: React.createContext(false),
    IsBlockExpandedContext: React.createContext(false),
    SetIsBlockExpandedContext: React.createContext<React.Dispatch<React.SetStateAction<boolean>>>(() => {}),
};

const BLOCK_MAX_HEIGHT = 160;
const WHITE_OVERLAY_HEIGHT = 24;

ReferenceResultCard.Block = function BlockSearchResultBlock({
    children,
    isLastElement,
}: {
    children: React.ReactNode;
    isLastElement?: boolean;
}) {
    const ref = React.useRef<HTMLDivElement>(null);
    const [isOverflowing, setIsOverflowing] = React.useState(false);
    const [isExpanded, setIsExpanded] = React.useState(false);

    React.useLayoutEffect(() => {
        if (ref.current) {
            const rect = ref.current.getBoundingClientRect();
            setIsOverflowing(rect.height > BLOCK_MAX_HEIGHT - WHITE_OVERLAY_HEIGHT);
        }
    }, []);

    return (
        <ReferenceResultCard.Contexts.IsBlockOverflowingContext.Provider value={isOverflowing}>
            <ReferenceResultCard.Contexts.IsBlockExpandedContext.Provider value={isExpanded}>
                <ReferenceResultCard.Contexts.SetIsBlockExpandedContext.Provider value={setIsExpanded}>
                    <div
                        ref={ref}
                        className={classNames('relative py-2 overflow-hidden hover-trigger', {})}
                        style={{ maxHeight: isExpanded ? undefined : BLOCK_MAX_HEIGHT }}
                    >
                        {children}
                        {isOverflowing && (
                            <div
                                className="absolute bottom-0 inset-x-0 bg-gradient-to-t from-white dark:from-saga-gray-1000"
                                style={{ height: WHITE_OVERLAY_HEIGHT }}
                            />
                        )}
                    </div>
                    {!isLastElement && (
                        <div
                            className={classNames('border-b border-saga-gray-200 dark:border-saga-gray-800', {
                                'pt-2': isOverflowing,
                            })}
                        />
                    )}
                </ReferenceResultCard.Contexts.SetIsBlockExpandedContext.Provider>
            </ReferenceResultCard.Contexts.IsBlockExpandedContext.Provider>
        </ReferenceResultCard.Contexts.IsBlockOverflowingContext.Provider>
    );
};

ReferenceResultCard.Header = function BlockSerachResultCardHeader({ children }: { children: React.ReactNode }) {
    return <div className="relative hover-trigger pb-1">{children}</div>;
};

ReferenceResultCard.TaskButton = function BlockSearchResultCardTaskButton({
    children,
    onClick,
    task,
}: {
    children: React.ReactNode;
    onClick: (event: React.MouseEvent) => void;
    task: WeakTask;
}) {
    return (
        <button
            className="text-left flex items-center space-x-2 hover:text-saga-gray-500 dark:hover:text-zinc-400 hover:stroke-saga-gray-500 cursor-pointer"
            onClick={onClick}
        >
            <span className="flex-shrink-0">
                {task.archivedAt ? (
                    <Archive className="mr-1 flex items-center w-4" />
                ) : (
                    <TaskStatusSelect status={task.state} disabled />
                )}
            </span>
            <span className="break-words text-base font-semibold">{children}</span>
        </button>
    );
};

ReferenceResultCard.PageButton = function BlockSearchResultCardPageButton({
    children,
    onClick,
    icon,
    isTemplate,
    isArchived,
}: {
    children: React.ReactNode;
    onClick: (event: React.MouseEvent) => void;
    icon: Page['icon'];
    isTemplate: Page['isTemplate'];
    isArchived: boolean;
}) {
    return (
        <button
            className="text-left flex space-x-2 hover:text-saga-gray-500 dark:hover:text-zinc-400 hover:stroke-saga-gray-500 cursor-pointer"
            onClick={onClick}
        >
            {isArchived ? (
                <Trash className="flex items-center w-4" />
            ) : (
                <span
                    className={classNames('flex-shrink-0', {
                        'pt-0.5': icon?.type === 'emoji',
                        'pt-1': icon?.type !== 'emoji',
                    })}
                >
                    <PageIcon icon={icon} size={16} isTemplate={isTemplate} />
                </span>
            )}
            <span className="break-words text-base font-semibold">{children}</span>
        </button>
    );
};

ReferenceResultCard.MenuButton = function BlockSearchResultCardMenuButton({
    children,
    tooltip,
    onClick,
}: {
    children: React.ReactNode;
    tooltip: string;
    onClick: () => void;
}) {
    return (
        <Tooltip content={tooltip} placement="top">
            <button
                className="text-sm p-2 focus:outline-none hover:bg-saga-gray-200 dark:hover:bg-zinc-600"
                onClick={onClick}
            >
                {children}
            </button>
        </Tooltip>
    );
};

ReferenceResultCard.AddLiveReferenceButton = function BlockSearchResultCardAddLiveReferenceButton({
    onClick,
}: {
    onClick: () => void;
}) {
    const { t } = useTranslation();
    return (
        <ReferenceResultCard.MenuButton onClick={onClick} tooltip={t('editor.add_live_block')}>
            <span className="sr-only">{t('editor.add_live_block')}</span>
            <Link className="flex-none" size={16} />
        </ReferenceResultCard.MenuButton>
    );
};

ReferenceResultCard.MenuContainer = function BlockSearchResultCardMenuContainer({
    children,
}: {
    children: React.ReactNode;
}) {
    return (
        <div className="hover-target absolute top-1 right-1 flex z-10 bg-white dark:bg-zinc-700 shadow-popupSmall divide-x divide-saga-gray-150 dark:divide-saga-gray-800 rounded overflow-hidden">
            {children}
        </div>
    );
};

ReferenceResultCard.Menu = function BlockSearchResultCardMenu({
    canEdit,
    onAddToPage,
}: {
    canEdit: boolean;
    onAddToPage?: () => void;
}) {
    const isOverflowing = React.useContext(ReferenceResultCard.Contexts.IsBlockOverflowingContext);
    const isExpanded = React.useContext(ReferenceResultCard.Contexts.IsBlockExpandedContext);
    const setExpanded = React.useContext(ReferenceResultCard.Contexts.SetIsBlockExpandedContext);
    return (
        <ReferenceResultCard.MenuContainer>
            {isOverflowing && (
                <ReferenceResultCard.MenuButton
                    tooltip={isOverflowing ? 'Show full Reference' : 'Collapse Reference'}
                    onClick={() => {
                        setExpanded((isExpanded) => !isExpanded);
                    }}
                >
                    {isExpanded ? (
                        <Minimize2 className="flex-none" size={16} />
                    ) : (
                        <Maximize2 className="flex-none" size={16} />
                    )}
                </ReferenceResultCard.MenuButton>
            )}
            {canEdit && onAddToPage && <ReferenceResultCard.AddLiveReferenceButton onClick={onAddToPage} />}
        </ReferenceResultCard.MenuContainer>
    );
};

type ReferenceResultCardDraggableBlockContainerProps = {
    blockId: string;
    origin: SagaLocation.PageLocation;
    children: React.ReactNode;
};

ReferenceResultCard.DraggableBlockContainer = function BlockSearchResultCardDraggableBlockContainer({
    blockId,
    origin,
    children,
}: ReferenceResultCardDraggableBlockContainerProps) {
    const [, drag] = useDrag<DndReferenceItem>({
        type: dndTypes.REFERENCE,
        item: { id: blockId, type: dndTypes.REFERENCE, origin },
    });

    return <div ref={drag}>{children}</div>;
};
