import React from 'react';
import { useSpace } from '@/components/SpaceProvider';
import * as Y from 'yjs';
import { useRef } from 'react';
import { getBlockByPath, assertYArray, SpaceOperations, assertYMap } from '@saga/shared';
import { useConnectedDocuments } from '@/components/RealtimeDocumentProvider';

const useYDocumentContentEvents = (handlers: {
    onTopLevelBlockDeleted?: (deletedYBlock: Y.Map<unknown>, yPage: Y.Map<unknown>, pageId: string) => void;
    onTopLevelBlockChanged?: (yBlock: Y.Map<unknown>, yPage: Y.Map<unknown>, pageId: string) => void;
    onTopLevelBlockAdded?: (yBlock: Y.Map<unknown>, yPage: Y.Map<unknown>, pageId: string) => void;
    onAnyBlockChange?: (yContent: Y.Map<unknown>, pageId: string) => void;
}) => {
    const connectedDocuments = useConnectedDocuments();
    const handlersRef = useRef(handlers);
    handlersRef.current = handlers;

    const { space } = useSpace();

    React.useEffect(() => {
        const handleLiveReferenceEvents = (events: Y.YEvent<any>[]) => {
            events.forEach((event) => {
                // Only track local change to not cause any issues in collaboration
                const isLocalChange = event.transaction.local;
                if (!isLocalChange) {
                    return;
                }
                const path = event.path;

                const yMap = event.currentTarget;
                assertYMap(yMap);
                const id = yMap.get('id') as string;

                const document = SpaceOperations.getPageMapById(space, id) ?? SpaceOperations.findTaskMap(space, id);
                if (!document) return;

                const yBlocks = yMap.get('blocks');
                assertYArray(yBlocks);

                handlersRef.current.onAnyBlockChange?.(yMap, id);

                if (event instanceof Y.YArrayEvent) {
                    const added = [...event.changes.added.values()];

                    added.forEach((item) => {
                        item.content.getContent().forEach((content) => {
                            if (content instanceof Y.Map && handlersRef.current.onTopLevelBlockAdded) {
                                handlersRef.current.onTopLevelBlockAdded(content, document, id);
                            }
                        });
                    });
                    const deleted = [...event.changes.deleted.values()].map((item) => item.content.getContent()).flat();
                    deleted.forEach((item) => {
                        if (item instanceof Y.Map && handlersRef.current.onTopLevelBlockDeleted) {
                            handlersRef.current.onTopLevelBlockDeleted(item, document, id);
                        }
                    });
                }

                if (!(event instanceof Y.YArrayEvent) && handlersRef.current.onTopLevelBlockChanged) {
                    const yBlocks = yMap.get('blocks');
                    assertYArray(yBlocks);

                    const [property, ...restPath] = path;

                    if (property === 'blocks') {
                        const [topLevelPath] = restPath.filter((i): i is number => typeof i === 'number');
                        const yBlock = getBlockByPath(yBlocks, [topLevelPath]);
                        if (yBlock) {
                            handlersRef.current.onTopLevelBlockChanged(yBlock, document, id);
                        }
                    }
                }
            });
        };

        connectedDocuments.forEach((doc) => doc.map.observeDeep(handleLiveReferenceEvents));

        return () => {
            connectedDocuments.forEach((doc) => doc.map.unobserveDeep(handleLiveReferenceEvents));
        };
    }, [connectedDocuments, space]);
};

export default useYDocumentContentEvents;
