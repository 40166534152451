import { WeakPage } from '../types';
import findIndexByPageId from './findIndexByPageId';
import { ReadonlyNonEmptyArray } from 'fp-ts/lib/ReadonlyNonEmptyArray';
import { SafeSpace } from '../types';
import getPageByIndex from './getPageByIndex';

function getPageById<K extends keyof WeakPage>(
    space: SafeSpace,
    id: string,
    keys?: ReadonlyNonEmptyArray<K>,
): Pick<WeakPage, K> | null {
    const index = findIndexByPageId(space, id);
    return getPageByIndex(space, index, keys);
}

export default getPageById;
