import { v4 as uuid } from 'uuid';
import { CONTENT_URL, BACKEND_URL } from '@/constants';
import { CookieUtils, Image, isImage, SagaLocation } from '@saga/shared';
import { Transforms } from 'slate';
import { getCurrentUser } from '@/firebase';
import invariant from 'tiny-invariant';
import React from 'react';
import { useSpace } from '@/components/SpaceProvider';
import { useCurrentWorkspace } from '@/components/WorkspaceContext';
import { usePendingUploads } from './usePendingUpload';
import { usePerformBlockChangeWithEditor } from '@/components/RealtimeDocumentProvider';
import { captureException } from '@sentry/react';
import { debugLog } from '@/utils';
import { useFirebaseContext } from '@/components/FirebaseContext';

export function useUploadImage(location: SagaLocation.BlocksLocation) {
    const pendingImages = usePendingUploads();
    const { firebaseUser } = useFirebaseContext();
    const { space } = useSpace();
    const { urlKey } = useCurrentWorkspace();
    const changeBlocksWithEditor = usePerformBlockChangeWithEditor();

    return React.useCallback(
        async function uploadImage({ file, image }: { file: File; image: Image }) {
            const csrfToken = document.head.querySelector('meta[name="x-csrf-token"]')!.getAttribute('content');
            if (!csrfToken) {
                captureException('CSRF Token not found', { extra: { urlKey, user: firebaseUser?.uid } });
                debugLog('CSRF Token not found');
                return;
            }

            pendingImages.add(image.id, file);
            const id = uuid();
            const extension = file.name.split('.').pop();
            const key = `spaces/${urlKey}/images/${id}.${extension}`;
            const contentType = file.type;
            const currentUser = getCurrentUser();
            invariant(currentUser != null, 'must be logged in to upload an image');

            const token = await currentUser.getIdToken();

            const url = await fetch(`${BACKEND_URL}upload-signed-url-v2`, {
                method: 'POST',
                headers: { 'Content-type': 'application/json', 'x-csrf-token': csrfToken },
                credentials: 'include',
                body: JSON.stringify({
                    urlKey,
                    key,
                    // for now, every uploaded image is of type space, later we can use this to upload page scoped images
                    type: CookieUtils.CookieTypes.space,
                    contentType,
                    token,
                }),
            })
                .then(async (result) => {
                    if (result.status === 200) {
                        const { url } = await result.json();
                        return url;
                    }
                    throw 'Failed to get signed url';
                })
                .then(async (url) => {
                    const result = await fetch(url, {
                        method: 'PUT',
                        body: file,
                    });
                    if (result.status === 200) {
                        const imageUrl = `${CONTENT_URL}/${key}`;
                        return imageUrl;
                    }
                    throw 'Failed to upload image';
                });

            changeBlocksWithEditor(space, location, (editor) => {
                Transforms.setNodes(
                    editor,
                    { url },
                    {
                        at: [],
                        match(block) {
                            return isImage(block) && block.id === image.id;
                        },
                    },
                );
                pendingImages.remove(image.id);
            });
        },
        [pendingImages, urlKey, space, location, changeBlocksWithEditor],
    );
}
