import { assertNonNull } from '@saga/shared';
import posthog, { Properties } from 'posthog-js';
import { debugLog } from '@/utils';
import * as Sentry from '@sentry/react';
import { SpaceWithMembersFragment } from '@saga/api';
import { IS_PRODUCTION, SHOULD_TRACK } from '@/constants';
import { FeatureFlag } from '@/../../shared/src/utils/FeatureFlags';

const token = import.meta.env.VITE_POSTHOG_TOKEN;
const host = import.meta.env.VITE_POSTHOG_HOST;

function initialize() {
    if (token && host && SHOULD_TRACK) {
        assertNonNull(token, 'Posthog token needs to be set');
        assertNonNull(host, 'Posthog host needs to be set');
        posthog.init(token, {
            api_host: host,
            opt_out_capturing_by_default: false,
            debug: false,
            request_batching: true,
            disable_session_recording: true,
            enable_recording_console_log: true,
        });
        return true;
    }

    return false;
}

const shouldTrack = initialize();

export function setUserProperties(props: Properties) {
    if (!shouldTrack) {
        return;
    }
    posthog.people.set(props);
}

export function optInTracking(optIn: boolean) {
    if (!shouldTrack) {
        return;
    }
    if (optIn) {
        posthog.opt_in_capturing();
    } else {
        posthog.opt_out_capturing();
    }
}

export function resetTracking() {
    posthog.reset();
    Sentry.configureScope((scope) => scope.setUser(null));
}

export function track(event: string, options: Properties = {}) {
    debugLog('track', event, options);
    if (!shouldTrack) {
        return;
    }
    posthog.capture(event, options);
}

export function identifyUser(id: string, email: string) {
    if (!shouldTrack) {
        return;
    }
    debugLog(`Identifying user: Id ${id}, email ${email}`);

    if (window.todesktop) {
        posthog.identify(id, {
            email,
            isDesktop: true,
            version: window.todesktop.version,
            os: window.todesktop.os.platform,
        });
    } else {
        posthog.identify(id, { email });
    }
    posthog.people.set_once('identified_timestamp', new Date().valueOf().toString());
    posthog.people.set({ email });

    track('new-session');
}

export function identifyWorkspace(workspace: SpaceWithMembersFragment) {
    if (!shouldTrack) {
        return;
    }

    debugLog(`Identifying workspace: id:${workspace.id}`);
    posthog.group('workspace', `id:${workspace.id}`, {
        title: workspace.title,
        urlKey: workspace.urlKey,
        createdAt: workspace.createdAt,
        isPublic: workspace.isPublic,
        isCopyWorkspaceEnabled: workspace.isCopyWorkspaceEnabled,
        isEmbeddingEnabled: workspace.isEmbeddingEnabled,
        isPaid:
            workspace.activeSubscription !== null &&
            workspace.activeSubscription !== undefined &&
            workspace.activeSubscription.canceled === false,
        useCases: [...workspace.useCases],
        membersCount: workspace.members.length,
    });
}

export function isFeatureEnabled(key: FeatureFlag) {
    if (!IS_PRODUCTION && localStorage.getItem('disable-ff') === 'true') {
        return false;
    }

    if (!shouldTrack) {
        return true;
    }

    return posthog.isFeatureEnabled(key);
}
