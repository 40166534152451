import { Editor as SlateEditor, Range, Node as SlateNode, Transforms, Path, Node } from 'slate';
import {
    Paragraph,
    SagaElement,
    isBlockContainer,
    Converter,
    BlockBuilder,
    BlockType,
    Image,
    isNodeEntry,
    isTable,
    isTableCell,
    SagaLocation,
    Link,
    SagaText,
    UrlUtils,
    EditorOperations,
    NodeCheckFn,
    isBlockTypeCurried,
    isCallout,
    PATTERNS,
    isIndentContainer,
    isImage,
    isFile,
    isTaskBlock,
} from '..';
import { v4 as uuid } from 'uuid';
import * as R from 'ramda';
import { insertBlocks } from './insertBlocks';
import parseSagaContent from './parseSagaContent';
import { BlockPlugin } from './Plugins';

export type CopyTransformer = (fragment: SagaElement[]) => SagaElement[];

function getRelativeFragment(node: Node, range: Range, relativeToPath: Path): SagaElement[] {
    return SlateNode.fragment(node, {
        anchor: { path: Path.relative(range.anchor.path, relativeToPath), offset: range.anchor.offset },
        focus: { path: Path.relative(range.focus.path, relativeToPath), offset: range.focus.offset },
    }) as SagaElement[];
}

function getRelativeContainingNodeEntry(editor: SlateEditor, checkFns: NodeCheckFn[]) {
    const { selection } = editor;

    if (selection && !Range.isCollapsed(selection)) {
        const containerNodeEntry = SlateEditor.above(editor, {
            at: selection,
            match: isBlockTypeCurried(checkFns),
        });
        const currentNodeEntry = SlateEditor.node(editor, selection);

        if (containerNodeEntry != null || isNodeEntry(currentNodeEntry, checkFns)) {
            return containerNodeEntry ?? currentNodeEntry;
        }
    }

    return null;
}

function isIndentItem(node: SagaElement): boolean {
    return ['numbered-list-item', 'list-item', 'check-list-item', 'paragraph'].includes(node.type);
}
function findFirstIndentItem(node: SagaElement): SagaElement | null {
    while (node?.children?.length > 0) {
        if (isIndentItem(node)) return node;
        node = node.children[0];
    }
    return isIndentItem(node) ? node : null;
}

function unwrapEmptyBlockContainers(node: SagaElement): SagaElement[] | null {
    while (node?.children?.length > 0) {
        if (isBlockContainer(node)) {
            if (node.children[1]) return [node];
            node = node.children[0];
            continue;
        }

        if (isIndentContainer(node)) {
            if (!isBlockContainer(node.children[0])) return node.children;
            node = node.children[0];
            continue;
        }

        return [node];
    }
    return [node];
}

export function getBlocksToCopy(editor: SlateEditor): SagaElement[] | null {
    const { selection } = editor;

    if (selection && !Range.isCollapsed(selection)) {
        const containingNodeEntry = getRelativeContainingNodeEntry(editor, [isTableCell]);

        if (containingNodeEntry != null) {
            const [node, path] = containingNodeEntry;

            return getRelativeFragment(node, selection, path);
        }

        const [firstNode, ...rest] = SlateNode.fragment(editor, selection) as SagaElement[];

        const wholeNodeSelected =
            SlateEditor.isEdge(editor, selection.focus, selection.focus.path) &&
            SlateEditor.isEdge(editor, selection.anchor, selection.anchor.path);

        if (isTable(firstNode)) {
            return [firstNode, ...rest];
        }

        if (!wholeNodeSelected) {
            const children = findFirstIndentItem(firstNode)?.children;
            return [children ? BlockBuilder.paragraph(children) : { ...firstNode, type: BlockType.PARAGRAPH }, ...rest];
        }

        if (isBlockContainer(firstNode)) {
            const children = unwrapEmptyBlockContainers(firstNode);
            return [...(children ?? [firstNode]), ...rest];
        }

        return [firstNode, ...rest];
    }

    return null;
}

type CopyOptions = {
    location: SagaLocation.BlocksLocation;
    spaceUrlKey: string;
    event: { clipboardData: DataTransfer | null; preventDefault: () => void };
    action?: 'cut' | 'copy';
    transform?: CopyTransformer;
    blockPlugins: BlockPlugin[];
};

export function copyBlocks(
    blocks: SagaElement[] | null,
    { location, spaceUrlKey, event, action = 'copy', transform = (f) => f, blockPlugins }: CopyOptions,
) {
    if (event.clipboardData && blocks) {
        const transformedFragment = transform(blocks);

        event.clipboardData.setData(
            'application/x-saga',
            JSON.stringify({ fragment: transformedFragment, location, spaceUrlKey, action }),
        );

        const text = EditorOperations.SagaElement.toString(BlockBuilder.root(transformedFragment), blockPlugins, {
            join: '\n',
        });

        event.clipboardData.setData('text/plain', text);

        const html = Converter.sagaToHtml(BlockBuilder.root(transformedFragment));

        event.clipboardData.setData('text/html', html);
    }

    event.preventDefault();
}

export function copy(editor: SlateEditor, options: CopyOptions) {
    const fragment = getBlocksToCopy(editor);

    copyBlocks(fragment, options);
}

function mapText(text: string): Link | SagaText {
    const url = UrlUtils.toLink(text);
    return url ? BlockBuilder.link(url, [BlockBuilder.text(url)]) : BlockBuilder.text(text);
}

function transformPlainTextToNodes(plainText: string): Paragraph[] {
    return plainText
        .split('\n')
        .filter(Boolean)
        .map((text) => {
            const isTextContainsLink = PATTERNS.link.test(text);

            const children = [];

            if (isTextContainsLink) {
                const parts = text.split(PATTERNS.link).filter(Boolean);
                const urls = text.match(PATTERNS.link) || [];

                const result: string[] = [];
                for (let i = 0; i < parts.length; i++) {
                    result.push(parts[i]);
                    if (urls[i]) result.push(urls[i] + ' ');
                }

                if (result.length === 0 && urls.length > 0) {
                    result.push(urls[0] + '');
                }

                result.forEach((part) => children.push(mapText(part)));
            } else {
                children.push(mapText(text));
            }

            return { id: uuid(), type: BlockType.PARAGRAPH, children };
        });
}

type ContentTypes = { html?: string; saga?: string; plainText?: string; images: File[] };

export function getContent(dataTransfer: DataTransfer): ContentTypes {
    const images: File[] = [];
    for (let i = 0; i < dataTransfer.items.length; i++) {
        const item = dataTransfer.items[i];
        const file = item.getAsFile();
        if (item.type.startsWith('image/') && file) {
            images.push(file);
        }
    }

    return {
        ...R.reject((v) => v === '', {
            html: dataTransfer.getData('text/html'),
            saga: dataTransfer.getData('application/x-saga'),
            plainText: dataTransfer.getData('text/plain'),
        }),
        images,
    };
}

function isGdocsHtml(html: string) {
    try {
        const gdocsDom = new DOMParser().parseFromString(html, 'text/html');
        const ids = Array.from(gdocsDom.querySelectorAll('[id]')).map((e) => e.id);
        if (ids) {
            return ids.some((id) => id.startsWith('docs-internal-guid'));
        }
    } catch (e) {}

    return false;
}

const hasMSWordHtml = (html: string) => {
    if (html.includes('xmlns:o="urn:schemas-microsoft-com:office:')) {
        return true;
    }
    return false;
};

export type SagaElementedPasted = SagaElement & { originBlockId: string };

type PastedBlocks =
    | {
          from: 'saga';
          fromLocation: SagaLocation.BlocksLocation;
          blocks: Array<SagaElementedPasted>;
          action: 'cut' | 'copy';
      }
    | { from: 'unknown'; blocks: SagaElement[] };

const isPastingInCallout = (editor: SlateEditor) => {
    const { selection } = editor;

    if (selection) {
        const nodeEntry = SlateEditor.above(editor, {
            match: (n) => isCallout(n),
            at: selection.focus.path,
        });

        return nodeEntry != null;
    }

    return false;
};

export async function paste({
    editor,
    spaceUrlKey,
    event,
    onBeforePaste,
    onPasteImage,
}: {
    editor: SlateEditor;
    spaceUrlKey: string;
    event: React.ClipboardEvent;
    onBeforePaste?: (blocks: SagaElementedPasted[]) => SagaElementedPasted[];
    onPasteImage?: (params: { file: File; image: Image }) => void;
}): Promise<PastedBlocks> {
    event.preventDefault();

    const { saga, html, plainText, images } = getContent(event.clipboardData);

    const sagaContent = saga && parseSagaContent(saga);

    // Paste within Saga
    if (sagaContent) {
        const blocks = onBeforePaste ? onBeforePaste(sagaContent.blocks) : sagaContent.blocks;
        const finalBlocks = isPastingInCallout(editor)
            ? blocks.flatMap((block) => (isCallout(block) ? block.children : block))
            : blocks;

        // We don't want to allow pasting images, files and tasks in space that's not the same as the source
        const filteredFinalBlocks = finalBlocks.filter((block) => {
            const isNonduplicatableBlock = isImage(block) || isFile(block) || isTaskBlock(block);
            return isNonduplicatableBlock ? spaceUrlKey === sagaContent.spaceUrlKey : true;
        });

        if (filteredFinalBlocks.length > 0) {
            const inserted = insertBlocks(editor, filteredFinalBlocks);
            if (inserted) {
                return {
                    blocks: filteredFinalBlocks,
                    fromLocation: sagaContent.location,
                    from: 'saga',
                    action: sagaContent.action,
                };
            }
        }
    }

    // Paste images
    if (onPasteImage && images.length > 0 && !hasMSWordHtml(html ? html : '')) {
        const blocks: Image[] = [];
        images.forEach((file) => {
            const imageBlock = BlockBuilder.image('');
            onPasteImage({ file, image: imageBlock });
            blocks.push(imageBlock);
        });
        if (blocks) {
            const inserted = insertBlocks(editor, blocks);
            if (inserted) {
                return { blocks, from: 'unknown' };
            }
        }
    }

    // Paste from Google Docs
    if (html && !sagaContent) {
        if (isGdocsHtml(html)) {
            const blocks = Converter.gdocsHtmlToSaga(html) as SagaElement[];

            if (blocks.length > 0) {
                const inserted = insertBlocks(editor, blocks);
                if (inserted) {
                    return { blocks, from: 'unknown' };
                }
            }
        } else {
            const result = await Converter.htmlToSaga(html);

            const inserted = insertBlocks(editor, result.children);

            if (inserted) {
                return { blocks: result.children, from: 'unknown' };
            }
        }
    }

    // Fallback for plain text pasting
    if (plainText) {
        const fragment = transformPlainTextToNodes(plainText);
        insertBlocks(editor, fragment);

        return { blocks: fragment as SagaElement[], from: 'unknown' };
    }

    return sagaContent
        ? {
              from: 'saga',
              fromLocation: sagaContent.location,
              blocks: [],
              action: sagaContent.action,
          }
        : { from: 'unknown', blocks: [] };
}

type CutOptions = Omit<CopyOptions, 'action'>;

export function cut(editor: SlateEditor, options: CutOptions) {
    options.event.preventDefault();
    const { selection } = editor;
    if (selection && !Range.isCollapsed(selection)) {
        copy(editor, { ...options, action: 'cut' });
        Transforms.delete(editor, { at: selection, hanging: true });
    }
}
