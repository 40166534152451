import React from 'react';
import { RenderLeafProps } from 'slate-react';
import Caret from '@/components/editor/leaf/Caret';
import { EditorAutolink } from './AutolinkMention';
import { isAutolinkMention } from '../decorator/useDecorator';
import { Colors } from '@saga/shared';

type LeafProps = RenderLeafProps & {
    leaf: any;
    selected?: boolean;
};

export const Leaf = (props: LeafProps) => {
    let { attributes, children, leaf } = props;
    const isSelected = leaf.selected;
    const text = props.text.text;
    const isTextEmpty = text.trim() === '';
    const isCaret = leaf.isCaret && !leaf.isSelection;

    if (leaf.bold) {
        children = <strong>{children}</strong>;
    }

    if (leaf.code && leaf.text !== '') {
        children = <code className="text-base px-1 py-0.5 rounded">{children}</code>;
    }

    if (leaf.italic) {
        children = <em>{children}</em>;
    }

    if (leaf.underline) {
        children = <u>{children}</u>;
    }

    // autolink or url decoration
    if (leaf.highlight) {
        children = <span style={{ backgroundColor: 'rgba(254, 224, 2, 0.25)' }}>{children}</span>;
    }

    if (leaf.colorHighlighter) {
        const colorNormalized = Colors.convertOutdatedColor(leaf.colorHighlighter);
        const colorLabel = Colors.colorLabelByValue(colorNormalized);

        children = (
            <span
                data-testid={`background-color-highlighter-${colorLabel}`}
                className={`bg-opacity-50 dark:bg-opacity-30 ${colorNormalized}`}
            >
                {children}
            </span>
        );
    }

    if (leaf.colorHighlighterText) {
        const colorNormalized = Colors.convertOutdatedColor(leaf.colorHighlighterText);
        const colorLabel = Colors.colorLabelByValue(colorNormalized);

        children = (
            <span data-testid={`text-color-highlighter-${colorLabel}`} className={`${colorNormalized}`}>
                {children}
            </span>
        );
    }

    if (isSelected && leaf.text !== '') {
        children = (
            <span
                data-selection="true"
                className={leaf.code ? '' : 'bg-saga-selection-blue dark:text-saga-text'}
                style={{ padding: '0.20em 0' }}
            >
                {children}
            </span>
        );
    }

    if (leaf.isSelection) {
        children = (
            <span
                className="z-50"
                style={{
                    backgroundColor: leaf.data?.user.alphaColor,
                    paddingTop: leaf.data?.user.alphaColor ? '0.20em' : undefined,
                    paddingBottom: leaf.data?.user.alphaColor ? '0.20em' : undefined,
                }}
            >
                {children}
            </span>
        );
    }

    const decoration = leaf.decoration;

    if (isAutolinkMention(decoration)) {
        return (
            <EditorAutolink {...props} autolinkDecoration={decoration}>
                {isCaret ? <Caret {...(leaf as any)} /> : null}
                {children}
            </EditorAutolink>
        );
    }

    return (
        <span
            {...attributes}
            style={{
                position: 'relative',
                paddingRight: isCaret || !isTextEmpty ? 0 : 1,
            }}
        >
            {isCaret ? <Caret {...(leaf as any)} /> : null}
            {children}
        </span>
    );
};
