import { stringToColor } from '@/../../shared/src/utils/Colors';
import { isFeatureEnabled, track } from '@/analytics';
import { RoleSelect } from '@/components/RoleSelect';
import MemberAvatar from '@/components/MemberAvatar';
import Modal from '@/components/Modal';
import Button from '@/components/styled/Button';
import { useToastContext } from '@/components/toast/ToastContext';
import { SimpleTitleToast } from '@/components/toast/ToastLibrary';
import { useUserContext } from '@/components/UserContext';
import { useSubscriptions, useWorkspaceContext } from '@/components/WorkspaceContext';
import { AVAILABLE_COLORS } from '@/constants';
import useCollaborators from '@/hooks/useCollaborators';
import useJumpToMemberCursor from '@/hooks/useJumpToMemberCursor';
import { useSpaceAccess } from '@/hooks/useSpaceAccess';
import * as api from '@saga/api';
import React, { useState } from 'react';
import InviteMemberForm from '../collaboration/InviteMemberForm';
import SettingsPane from './SettingsPane';
import { useCloseSettings } from './SettingsProvider';
import { FreePlanInfoBanner } from '../collaboration/FreePlanInfoBanner';
import { UpgradePlanBannerBig } from '../billing/UpgradePlanBannerBig';
import { useTranslation } from 'react-i18next';
import { FeatureFlag } from '@/../../shared/src/utils/FeatureFlags';
import { ConfirmationModal } from '../modal/ConfirmationModal';

export default function MemberSettings() {
    const { t } = useTranslation();
    const { showToast } = useToastContext();
    const { currentUrlKey } = useWorkspaceContext();
    const { user } = useUserContext();
    const { isOwner, isMember, permissions } = useSpaceAccess();
    const { currentSubscription } = useSubscriptions();
    const { currentWorkspace } = useWorkspaceContext();
    const { data, loading } = api.useInvitesAndMembersQuery({
        variables: { urlKey: currentUrlKey },
        fetchPolicy: 'cache-and-network',
    });

    const [revokeInvite, { loading: revoking }] = api.useRevokeInviteMutation({
        refetchQueries: [api.InvitesAndMembersDocument],
    });
    const [removeMember, { loading: removingMember }] = api.useRemoveMemberMutation({
        refetchQueries: [api.InvitesAndMembersDocument],
    });

    const isAddMembersEnabled = isFeatureEnabled(FeatureFlag.addMembers);

    const [updateMemberRole] = api.useUpdateMemberRoleMutation();

    const closeSettings = useCloseSettings();
    const jumpToCursor = useJumpToMemberCursor();
    const collaborators = useCollaborators();

    const [isInviteModalOpen, setInviteModalOpen] = useState(false);
    const [showUpgradePlanModal, setShowUpgradePlanModal] = useState(false);
    const [showRevokeInviteModal, setShowRevokeInviteModal] = useState<string | null>(null);
    const [showRemoveMemberModal, setShowRemoveMemberModal] = useState<{ id: string; name: string } | null>(null);

    if (!data || loading) {
        return (
            <SettingsPane>
                <div>
                    <SettingsPane.Title>{t('settings.members.title')}</SettingsPane.Title>
                    <SettingsPane.Small>{t('settings.members.sub_title')}</SettingsPane.Small>
                </div>
            </SettingsPane>
        );
    }

    const { invites, members } = data;

    const hasAtLeastTwoOwners = data.members.filter((member) => member.role === api.Role.Owner).length >= 2;

    const onRevokeInvite = async (id: string) => {
        await revokeInvite({
            variables: { input: { id, urlKey: currentUrlKey } },
            onCompleted: () => {
                setShowRevokeInviteModal(null);
                showToast(() => (
                    <SimpleTitleToast>{t('settings.members.revoke_invite_toast_success')}</SimpleTitleToast>
                ));
            },
        });
    };
    const onRemoveMember = async (id: string) => {
        await removeMember({
            variables: { input: { userId: id, urlKey: currentUrlKey } },
            update(cache, { data }) {
                const userData = cache.readQuery<api.UserDataQuery>({ query: api.UserDataDocument });
                if (userData && data) {
                    cache.writeQuery({
                        query: api.UserDataDocument,
                        data: {
                            ...userData,
                            spaces: userData.spaces.map((space) =>
                                space.urlKey === currentUrlKey
                                    ? {
                                          ...space,
                                          members: space.members.filter((member) => member.id !== data.removeMember),
                                      }
                                    : space,
                            ),
                        },
                    });
                }
            },
            onCompleted: () => {
                setShowRemoveMemberModal(null);
                showToast(() => (
                    <SimpleTitleToast>{t('settings.members.remove_member_toast_success')}</SimpleTitleToast>
                ));
            },
        });
    };

    const maxInvitedMembers = isFeatureEnabled(FeatureFlag.allowBilling)
        ? permissions.editors.limit
            ? permissions.editors.limit - (members.length + invites.length)
            : null
        : null;

    const totalAllowedMembersForSpace = isFeatureEnabled(FeatureFlag.allowBilling)
        ? permissions.editors.limit
            ? permissions.editors.limit - 1
            : null
        : null;

    const onAvatarClick = (memberUserId: string, event: React.MouseEvent) => {
        const collaborator = collaborators.find((c) => c.user.userId === memberUserId);

        if (collaborator) {
            jumpToCursor(collaborator.cursor, event);
        }

        closeSettings();
    };

    const onAddMember = () => {
        if (maxInvitedMembers === null || maxInvitedMembers > 0) {
            setInviteModalOpen(true);
        } else {
            setShowUpgradePlanModal(true);
        }
        track('add-member');
    };

    const onChangeRole = (role: api.Role, memberId: string) => {
        updateMemberRole({
            variables: {
                input: {
                    userId: memberId,
                    role,
                    urlKey: currentUrlKey,
                },
            },
        });
    };

    return (
        <>
            <Modal.Large isOpen={isInviteModalOpen} onClose={() => setInviteModalOpen(false)}>
                <Modal.CloseButton />
                <Modal.Content>
                    <Modal.Title>
                        {t('settings.members.invite_members', { workspaceName: currentWorkspace.title })}
                    </Modal.Title>
                    <InviteMemberForm
                        canInviteAdmins={isOwner}
                        isSubscriptionActive={isFeatureEnabled(FeatureFlag.allowBilling) ? !!currentSubscription : true}
                        maxInvitedMembers={maxInvitedMembers}
                        totalAllowedMembersForSpace={totalAllowedMembersForSpace}
                        urlKey={currentUrlKey}
                        onInviteCompleted={() => {
                            setInviteModalOpen(false);
                            showToast(() => <SimpleTitleToast>{t('settings.members.invites_sent')}</SimpleTitleToast>);
                        }}
                    />
                </Modal.Content>
                {!currentSubscription && <FreePlanInfoBanner />}
            </Modal.Large>
            <Modal.Medium isOpen={showUpgradePlanModal}>
                <UpgradePlanBannerBig
                    title={t('settings.members.upgrade_plan')}
                    description={t('settings.members.upgrade_plan_details', { count: permissions.editors.limit ?? 0 })}
                    onClose={() => setShowUpgradePlanModal(false)}
                    trackingSource="member-settings"
                />
            </Modal.Medium>
            <SettingsPane>
                <div>
                    <SettingsPane.Title>{t('settings.members.title')}</SettingsPane.Title>
                    <SettingsPane.Small>{t('settings.members.sub_title')}</SettingsPane.Small>
                </div>
                <SettingsPane.Content>
                    <div className="space-y-3">
                        {isAddMembersEnabled && (isOwner || isMember) && (
                            <SettingsPane.Section>
                                <Button onClick={onAddMember} type="button">
                                    {t('settings.members.add_member')}
                                </Button>
                            </SettingsPane.Section>
                        )}
                        <div>
                            <div
                                role="table"
                                aria-label="Members"
                                className="divide-y border-t border-b divide-saga-gray-150 dark:divide-saga-gray-800 border-saga-gray-200 dark:border-zinc-600"
                            >
                                {data?.members.map((member) => (
                                    <div
                                        role="row"
                                        className="py-2 flex justify-between items-center pl-3"
                                        key={member.id}
                                    >
                                        <div className="flex items-center space-x-2">
                                            <MemberAvatar
                                                name={member.firstName}
                                                color={stringToColor(member.id, AVAILABLE_COLORS)}
                                                onClick={(event) => onAvatarClick(member.id, event)}
                                            />
                                            <div role="cell">
                                                <div className="inline-block">
                                                    {member.firstName} {member.lastName}
                                                </div>
                                                <div className="text-saga-text-gray text-xs">{member.userEmail}</div>
                                            </div>
                                        </div>

                                        <div className="flex flex-row space-x-2">
                                            {isOwner && user?.id !== member.id && (
                                                <Button.Plain
                                                    disabled={removingMember}
                                                    onClick={() =>
                                                        setShowRemoveMemberModal({
                                                            id: member.id,
                                                            name: `${member.firstName} ${member.lastName}`,
                                                        })
                                                    }
                                                >
                                                    <Button.BasePadding>
                                                        <span className="text-saga-red">
                                                            {t('settings.members.remove_member')}
                                                        </span>
                                                    </Button.BasePadding>
                                                </Button.Plain>
                                            )}
                                            <div
                                                role="cell"
                                                className="flex items-center space-x-2 text-saga-text-gray dark:text-zinc-400"
                                            >
                                                {member.role && (
                                                    <RoleSelect
                                                        canInviteAdmins={isOwner}
                                                        disabled={
                                                            (!isOwner && !isMember) ||
                                                            (member.role === api.Role.Owner && !hasAtLeastTwoOwners) ||
                                                            (isFeatureEnabled(FeatureFlag.allowBilling) &&
                                                                currentSubscription === null)
                                                        }
                                                        role={member.role}
                                                        isPaidFeature={
                                                            isFeatureEnabled(FeatureFlag.allowBilling)
                                                                ? currentSubscription === null
                                                                : false
                                                        }
                                                        onChange={(role) => {
                                                            track('member-change-role', {
                                                                fromRole: member.role,
                                                                toRole: role,
                                                            });
                                                            onChangeRole(role, member.id);
                                                        }}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>

                            <div
                                role="table"
                                aria-label="Invites"
                                className="divide-y divide-saga-gray-150 dark:divide-saga-gray-800 border-saga-gray-200 dark:border-zinc-600"
                            >
                                {invites.map((invite) => (
                                    <div
                                        role="row"
                                        className="py-2 flex justify-between items-center pl-3"
                                        key={invite.id}
                                    >
                                        <span role="cell" className="text-saga-text-gray dark:text-zinc-400">
                                            {invite.userEmail}
                                        </span>
                                        <div role="cell" className="flex items-center space-x-2">
                                            {(isOwner || isMember) && (
                                                <Button.Plain onClick={() => setShowRevokeInviteModal(invite.id)}>
                                                    <Button.BasePadding>
                                                        <span className="text-saga-red">
                                                            {t('settings.members.revoke_invite')}
                                                        </span>
                                                    </Button.BasePadding>
                                                </Button.Plain>
                                            )}
                                            <span className="text-saga-text-gray dark:text-zinc-400">
                                                {t('settings.members.pending_invite')}
                                            </span>
                                            <div className="text-saga-text-gray dark:text-zinc-400">
                                                <RoleSelect
                                                    canInviteAdmins={isOwner}
                                                    disabled
                                                    role={invite.role}
                                                    onChange={() => {}}
                                                    isPaidFeature={false}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    {!currentSubscription && (
                        <div className="rounded overflow-hidden">
                            <FreePlanInfoBanner />
                        </div>
                    )}
                    {showRevokeInviteModal && (
                        <ConfirmationModal
                            confirmText={t('settings.members.revoke_confirm')}
                            disabled={revoking}
                            onConfirm={() => onRevokeInvite(showRevokeInviteModal)}
                            onCancel={() => setShowRevokeInviteModal(null)}
                            title={t('settings.members.revoke_invite_confirmation')}
                        />
                    )}
                    {showRemoveMemberModal && (
                        <ConfirmationModal
                            confirmText={t('settings.members.remove_confirm')}
                            disabled={revoking || removingMember}
                            onLoading={removingMember}
                            onConfirm={() => onRemoveMember(showRemoveMemberModal.id)}
                            onCancel={() => setShowRemoveMemberModal(null)}
                            title={t('settings.members.remove_member_confirmation', {
                                name: showRemoveMemberModal.name,
                            })}
                        />
                    )}
                </SettingsPane.Content>
            </SettingsPane>
        </>
    );
}
