import * as t from 'io-ts';
import { AIMessage, AISourceItem, Collection, RealtimeSagaEditor, WeakPage, WeakTask } from './types';
import { Node, NodeEntry } from 'slate';
import * as api from '@saga/api';

declare module 'slate' {
    interface CustomTypes {
        Editor: RealtimeSagaEditor;
        Element: SagaElement;
        Text: SagaText;
    }
}

export interface BaseItem {
    id: string;
    children: any[];
}

export type SagaElement = BlockElement | InlineElement;

export type BlockElement = Title | SimpleElement | ContainerElement | VoidElement | IndentContainer;

export type SimpleElement = Blockquote | Paragraph | Heading | NumberedListItem | CheckListItem | ListItem;

export type MovableContainerElement = Paragraph | BlockContainer | LiveBlockSource | Table | Callout;

export type ContainerElement = MovableContainerElement | NumberedList | TableCell | TableRow;

export type InlineElement =
    | Link
    | Mention
    | GoogleDriveLink
    | LinearIssue
    | DateBlock
    | KatexInline
    | InlineCollection
    | InlinePageLink
    | AISuggestedText;

export type Inline = SagaText | InlineElement;

export type VoidElement = Code | Image | LiveBlock | PrettyLink | Embed | Divider | KatexBlock | TaskBlock | File;

export type Paragraph = BaseItem & {
    type: 'paragraph';
    children: Inline[];
    break?: boolean;
};

export type Link = BaseItem & {
    type: 'link';
    url: string;
    children: SagaText[];
};

export type LinearIssueData = Pick<api.LinearIssueFragment, 'id' | 'identifier' | 'url' | 'title'>;
export type LinearIssue = BaseItem & {
    type: typeof BlockType.LINEAR_ISSUE;
    state:
        | {
              type: 'loaded';
              issue: LinearIssueData;
          }
        | { type: 'loading' }
        | { type: 'error' };
    children: [SagaText];
};

export type GoogleDriveLink = BaseItem & {
    type: typeof BlockType.GOOGLE_DRIVE_LINK;
    children: [SagaText];
    state:
        | {
              type: 'loaded';
              file: api.GoogleDriveFileFragment;
          }
        | { type: 'loading' }
        | { type: 'error' };
};

export type Mention = BaseItem & {
    type: 'mention';
    memberId: string;
    creatorId?: string;
    creatorName?: string;
    date?: string;
    children: [SagaText];
};

export type DateBlock = BaseItem & {
    type: typeof BlockType.DATE_BLOCK;
    date: string;
    children: [SagaText];
};

export type InlinePageLink = BaseItem & {
    type: typeof BlockType.INLINE_PAGE_LINK;
    pageId: string;
    liveReferenceSourceId: string;
    children: [SagaText];
    staticPage?: {
        title: string;
        isTemplate: WeakPage['isTemplate'];
        icon: WeakPage['icon'];
    };
};

export type InlineCollection = BaseItem & {
    type: typeof BlockType.INLINE_COLLECTION;
    collectionId: string;
    children: [SagaText];
    staticCollection?: {
        title: string;
        icon: Collection['icon'];
    };
};

export type TaskBlock = BaseItem & {
    type: typeof BlockType.TASK_BLOCK;
    children: [SagaText];
    taskId: string;
    staticTask?: WeakTask;
};

export type KatexBlock = BaseItem & {
    type: typeof BlockType.KATEX_BLOCK;
    children: [SagaText];
    value: string;
};

export type KatexInline = BaseItem & {
    type: typeof BlockType.KATEX_INLINE;
    children: [SagaText];
    value: string;
};

export type PrettyLink = BaseItem & {
    type: 'pretty-link';
    url: string;
    children: [SagaText];
    metadata:
        | {
              _tag: 'not-loaded';
          }
        | {
              _tag: 'loading';
          }
        | {
              _tag: 'loaded';
              image?: string;
              title: string;
              description?: string;
              logo?: string;
          }
        | {
              _tag: 'failed';
          };
};

export type Divider = BaseItem & {
    type: typeof BlockType.DIVIDER;
    children: [SagaText];
};

export type Align = 'left' | 'center' | 'right';

export type Embed = BaseItem & {
    type: typeof BlockType.EMBED;
    url: string;
    children: [SagaText];
    metadata:
        | {
              _tag: 'not-loaded';
          }
        | {
              _tag: 'loading';
          }
        | {
              _tag: 'loaded';
              iframe: string;
              size: [number, number];
              align: Align;
              title: string;
          }
        | {
              _tag: 'failed';
          };
};

export type Callout = BaseItem & {
    type: typeof BlockType.CALLOUT;
    children: SagaElement[];
    icon:
        | {
              type: 'emoji';
              colons: string;
          }
        | undefined;
    backgroundColor: string;
};

export type PatternType = 'bold' | 'italic' | 'underline' | 'code';
export type MarkStringType = 'colorHighlighter' | 'colorHighlighterText' | 'replacedWord';
export type MarkType = PatternType | MarkStringType;

export type DecorationType = 'selected' | 'highlight' | 'mention';

export type TextType = MarkType | DecorationType;

export type TitleInline = SagaText | DateBlock | GoogleDriveLink | LinearIssue | Mention | Link;

export type Title = BaseItem & {
    type: typeof BlockType.TITLE;
    children: TitleInline[];
};

export type AISuggestedText = BaseItem & {
    type: typeof BlockType.AI_SUGGESTED_TEXT;
    children: SagaText[];
    messages: AIMessage[];
    prompt: string;
    textContext?: string;
    creatorId?: string;
    editorId?: number;
    references?: AISourceItem[];
};

export type SagaText = {
    text: string;
} & { [key in TextType]?: boolean } & { [key in MarkStringType]?: string };

export type BlockContainerChildren = [SimpleElement | VoidElement, IndentContainer];

export type BlockContainer = BaseItem & {
    type: 'block-container';
    children: BlockContainerChildren;
    collapsed?: boolean;
};

export type TableCell = BaseItem & {
    type: typeof BlockType.TABLE_CELL;
    children: AnyBlockItem[];
};

export type TableRow = BaseItem & {
    type: typeof BlockType.TABLE_ROW;
    children: TableCell[];
};

export type Table = BaseItem & {
    type: typeof BlockType.TABLE;
    children: TableRow[];
    dimensions: [number, number];
};

export type LiveBlockSource = BaseItem & {
    // historically, live blocks have been named live references
    type: 'live-reference-source';
    liveReferenceSourceId: string;
    children: AnyBlockItem[];
};

export type Image = BaseItem & {
    type: 'image';
    url?: string;
    alt?: string;
    title?: string;
    size?: [number, number];
    ratio?: number;
    ref?: string;
    align?: string;
    children: [SagaText];
};

export type File = BaseItem & {
    type: typeof BlockType.FILE;
    url?: string;
    title?: string;
    size?: number;
    ref?: string;
    children: [SagaText];
};

export type List = BaseItem & {
    type: 'list';
    children: SimpleElement[];
};

export type AnyBlockItem = SimpleElement | VoidElement | ContainerElement;

export type IndentContainer = BaseItem & {
    type: 'indent-container';
    children: AnyBlockItem[];
};

export type Blockquote = BaseItem & {
    type: 'block-quote';
    children: Inline[];
};

export type AnyListItem = ListItem | CheckListItem | NumberedListItem;

export type ListItem = BaseItem & {
    type: 'list-item';
    children: Inline[];
};

export type CheckListItem = BaseItem & {
    type: 'check-list-item';
    checked?: boolean;
    children: Inline[];
};

export type NumberedList = BaseItem & {
    type: 'number-list';
    children: SagaElement[];
};

export type NumberedListItem = BaseItem & {
    type: 'numbered-list-item';
    children: Inline[];
};

export type Code = BaseItem & {
    type: 'code';
    language: string;
    content: string;
    children: [SagaText];
    isNew?: boolean;
};

export type Heading = {
    type: 'heading-one' | 'heading-two' | 'heading-three';
    id: string;
    children: Inline[];
};

export type LiveBlockReferenceData = {
    liveReferenceSourceId: string;
};

export type LiveBlock = BaseItem & {
    type: 'reference';
    reference: LiveBlockReferenceData;
    children: [SagaText];
    staticBlocks?: SagaElement[];
};

export type Root = BaseItem & {
    type: 'root';
    children: (SagaElement | SagaText)[];
};

export type BlockElementTypeDef = t.TypeOf<typeof blockTypeD>;
export type FormatBlockElementTypeDef = t.TypeOf<typeof formatBlockTypeD>;
export type NonFormatBlockElementTypeDef = t.TypeOf<typeof nonFormatBlockTypeD>;
export type InlineElementTypeDef = t.TypeOf<typeof inlineBlocktypeD>;
export type HelperElementTypeDef = t.TypeOf<typeof helperBlocktypeD>;

export const BlockType = {
    TITLE: 'title' as const,
    PARAGRAPH: 'paragraph' as const,
    BLOCK_CONTAINER: 'block-container' as const,
    // Historically, live blocks have been named references and live block sources where live reference sources
    // do not rename it because it is used as a type in existing blocks
    LIVE_BLOCK_SOURCE: 'live-reference-source' as const,
    LIVE_BLOCK: 'reference' as const,
    TABLE: 'table' as const,
    TABLE_ROW: 'table-row' as const,
    TABLE_CELL: 'table-cell' as const,
    INDENT: 'indent-container' as const,
    LINK: 'link' as const,
    MENTION: 'mention' as const,
    KATEX_BLOCK: 'katex' as const,
    KATEX_INLINE: 'katex-inline' as const,
    PRETTY_LINK: 'pretty-link' as const,
    /**@deprecated */
    OLD_BULLET_LIST_ITEM: 'bulleted-list' as const,
    BULLET_LIST_ITEM: 'list-item' as const,
    NUMBER_LIST_ITEM: 'numbered-list-item' as const,
    /**@deprecated */
    OLD_NUMBER_LIST_ITEM: 'numbered-list' as const,
    CHECK_LIST_ITEM: 'check-list-item' as const,
    HEADING_1: 'heading-one' as const,
    HEADING_2: 'heading-two' as const,
    HEADING_3: 'heading-three' as const,
    QUOTE: 'block-quote' as const,
    CODE: 'code' as const,
    NUMBER_LIST: 'number-list' as const,
    IMAGE: 'image' as const,
    FILE: 'file' as const,
    GOOGLE_DRIVE_LINK: 'google-drive-link' as const,
    LINEAR_ISSUE: 'linear-issue' as const,
    EMBED: 'embed' as const,
    DATE_BLOCK: 'date-block' as const,
    INLINE_PAGE_LINK: 'inline-page-link' as const,
    DIVIDER: 'divider' as const,
    CALLOUT: 'callout' as const,
    INLINE_COLLECTION: 'inline-collection' as const,
    TASK_BLOCK: 'task-block' as const,
    AI_SUGGESTED_TEXT: 'ai-suggested-text' as const,
};

/** This is just helper types used in the converter and in unist, not used in any SagaElement  */
export const HelperBlockType = {
    ROOT: 'root' as const,
    LIST: 'list' as const,
    TEXT: 'text' as const,
};

/** Types that can be created directly from the UI */
const formatBlockTypeD = t.union([
    t.literal(BlockType.PARAGRAPH),
    t.literal(BlockType.BULLET_LIST_ITEM),
    t.literal(BlockType.NUMBER_LIST_ITEM),
    t.literal(BlockType.CHECK_LIST_ITEM),
    t.literal(BlockType.HEADING_1),
    t.literal(BlockType.HEADING_2),
    t.literal(BlockType.HEADING_3),
    t.literal(BlockType.QUOTE),
    t.literal(BlockType.CODE),
    t.literal(BlockType.IMAGE),
    t.literal(BlockType.FILE),
    t.literal(BlockType.PRETTY_LINK),
    t.literal(BlockType.DIVIDER),
    t.literal(BlockType.KATEX_BLOCK),
    t.literal(BlockType.KATEX_INLINE),
    t.literal(BlockType.CALLOUT),
    t.literal(BlockType.TABLE),
    t.literal(BlockType.INLINE_COLLECTION),
    t.literal(BlockType.TASK_BLOCK),
]);

/** Types that can't be created directly from the UI */
const nonFormatBlockTypeD = t.union([
    t.literal(BlockType.TITLE),
    t.literal(BlockType.BLOCK_CONTAINER),
    t.literal(BlockType.LIVE_BLOCK_SOURCE),
    t.literal(BlockType.INDENT),
    t.literal(BlockType.LIVE_BLOCK),
    t.literal(BlockType.NUMBER_LIST),
]);

const blockTypeD = t.union([
    t.literal(BlockType.PARAGRAPH),
    t.literal(BlockType.BULLET_LIST_ITEM),
    t.literal(BlockType.NUMBER_LIST_ITEM),
    t.literal(BlockType.CHECK_LIST_ITEM),
    t.literal(BlockType.HEADING_1),
    t.literal(BlockType.HEADING_2),
    t.literal(BlockType.HEADING_3),
    t.literal(BlockType.QUOTE),
    t.literal(BlockType.CODE),
    t.literal(BlockType.IMAGE),
    t.literal(BlockType.FILE),
    t.literal(BlockType.DIVIDER),
    t.literal(BlockType.TITLE),
    t.literal(BlockType.BLOCK_CONTAINER),
    t.literal(BlockType.LIVE_BLOCK_SOURCE),
    t.literal(BlockType.INDENT),
    t.literal(BlockType.LIVE_BLOCK),
    t.literal(BlockType.NUMBER_LIST),
    t.literal(BlockType.PRETTY_LINK),
    t.literal(BlockType.EMBED),
    t.literal(BlockType.KATEX_BLOCK),
    t.literal(BlockType.CALLOUT),
    t.literal(BlockType.TABLE),
    t.literal(BlockType.TABLE_ROW),
    t.literal(BlockType.TABLE_CELL),
    t.literal(BlockType.TASK_BLOCK),
]);

const inlineBlocktypeD = t.union([
    t.literal(BlockType.LINK),
    t.literal(BlockType.MENTION),
    t.literal(BlockType.INLINE_PAGE_LINK),
    t.literal(BlockType.KATEX_INLINE),
    t.literal(BlockType.GOOGLE_DRIVE_LINK),
    t.literal(BlockType.INLINE_COLLECTION),
]);

const helperBlocktypeD = t.union([
    t.literal(HelperBlockType.TEXT),
    t.literal(HelperBlockType.ROOT),
    t.literal(HelperBlockType.LIST),
]);

// The below will eventually be removed

const textD = t.intersection([
    t.type({
        text: t.string,
    }),
    t.partial({
        type: t.literal('text'),
        bold: t.boolean,
        italic: t.boolean,
        underline: t.boolean,
        code: t.boolean,
        selected: t.boolean,
        highlight: t.boolean,
        mention: t.boolean,
        link: t.boolean,
        email: t.boolean,
    }),
]);

export type TextD = t.TypeOf<typeof textD>;

const linkD = t.type({
    id: t.string,
    type: t.literal('link'),
    children: t.array(textD),
    url: t.string,
});

type LinkD = t.TypeOf<typeof linkD>;

const anythingThatHasTextD = t.type({
    children: t.array(textD),
});

const codeD = t.intersection([
    t.type({
        type: t.literal('code'),
        children: t.array(textD),
    }),
    t.partial({ content: t.array(anythingThatHasTextD), language: t.string }),
]);

export type CodeD = t.TypeOf<typeof codeD>;

export type BlockD = { type: string; children: (TextD | BlockD | LinkD | CodeD)[] } | CodeD | LinkD;

export const blockD: t.Type<BlockD> = t.recursion('block', () => {
    return t.union([
        t.type({
            type: t.string,
            children: t.array(t.union([textD, linkD, blockD, codeD])),
        }),
        codeD,
    ]);
});

export const blocksD = t.array(blockD);

export function isSagaElement(node: unknown): node is SagaElement {
    return node != null && 'type' in (node as SagaElement);
}

export function isSagaNodeEntry(node: NodeEntry): node is NodeEntry<SagaElement> {
    return isSagaElement(node[0]) || isSagaText(node[0]);
}

export type NodeCheckFn = (node: unknown) => boolean;
export type GuardedType<T> = T extends (x: any) => x is infer T ? T : never;

export function isBlockTypeCurried<T extends NodeCheckFn>(check: T | T[]) {
    return (node: unknown): node is GuardedType<typeof check> => {
        if (Node.isNode(node)) {
            if (!Array.isArray(check)) {
                check = [check];
            }
            for (const c of check) {
                if (c(node) === true) {
                    return c(node);
                }
            }
        }
        return false;
    };
}

export function isBlockType<T extends NodeCheckFn>(node: unknown, check: T | T[]): node is GuardedType<typeof check> {
    if (Node.isNode(node)) {
        if (!Array.isArray(check)) {
            check = [check];
        }
        for (const c of check) {
            if (c(node) === true) {
                return c(node);
            }
        }
    }
    return false;
}

export type GuardedNodeEntryType<T> = T extends Node ? NodeEntry<T> : never;

export function isNodeEntry<T extends NodeCheckFn>(
    node: NodeEntry<Node>,
    check: T | T[],
): node is GuardedNodeEntryType<GuardedType<typeof check>> {
    return isBlockType(node[0], check);
}

export function assertBlockType<T extends NodeCheckFn>(
    node: Node | unknown,
    check: T | T[],
): asserts node is GuardedType<typeof check> {
    if (!isBlockType(node, check)) {
        throw new Error('Node is not expected type');
    }
}

export function isTitle(node: unknown): node is Title {
    return isSagaElement(node) && node.type === BlockType.TITLE;
}

export function isAISuggestedText(node: unknown): node is AISuggestedText {
    return isSagaElement(node) && node.type === BlockType.AI_SUGGESTED_TEXT;
}

export function isParagraph(node: unknown): node is Paragraph {
    return isSagaElement(node) && node.type === BlockType.PARAGRAPH;
}

export function isBlockquote(node: unknown): node is Blockquote {
    return isSagaElement(node) && node.type === BlockType.QUOTE;
}

export function isCode(node: unknown): node is Code {
    return isSagaElement(node) && node.type === BlockType.CODE;
}

export function isImage(node: unknown): node is Image {
    return isSagaElement(node) && node.type === BlockType.IMAGE;
}

export function isFile(node: unknown): node is File {
    return isSagaElement(node) && node.type === BlockType.FILE;
}

export function isHeading(node: unknown): node is Heading {
    return isSagaElement(node) && node.type.startsWith('heading');
}

export function isHeadingOne(node: unknown): node is Heading {
    return isSagaElement(node) && node.type === BlockType.HEADING_1;
}

export function isHeadingTwo(node: unknown): node is Heading {
    return isSagaElement(node) && node.type === BlockType.HEADING_2;
}

export function isHeadingThree(node: unknown): node is Heading {
    return isSagaElement(node) && node.type === BlockType.HEADING_3;
}

export function isListItem(node: unknown): node is ListItem {
    return isSagaElement(node) && node.type === BlockType.BULLET_LIST_ITEM;
}

export function isNumberedList(node: unknown): node is NumberedList {
    return isSagaElement(node) && node.type === BlockType.NUMBER_LIST;
}

export function isNumberedListItem(node: unknown): node is NumberedListItem {
    return isSagaElement(node) && node.type === BlockType.NUMBER_LIST_ITEM;
}

export function isAnyListItem(node: unknown): node is CheckListItem | ListItem | NumberedListItem {
    return isBlockType(node, [isCheckListItem, isListItem, isNumberedListItem]);
}

export function isTable(node: unknown): node is Table {
    return isSagaElement(node) && node.type === BlockType.TABLE;
}

export function isTableCell(node: unknown): node is TableCell {
    return isSagaElement(node) && node.type === BlockType.TABLE_CELL;
}

export function isTableRow(node: unknown): node is TableRow {
    return isSagaElement(node) && node.type === BlockType.TABLE_ROW;
}

export function isCheckListItem(node: unknown): node is CheckListItem {
    return isSagaElement(node) && node.type === BlockType.CHECK_LIST_ITEM;
}

export function isLiveBlock(node: unknown): node is LiveBlock {
    return isSagaElement(node) && node.type === BlockType.LIVE_BLOCK;
}

export function isBlockContainer(node: unknown): node is BlockContainer {
    return isSagaElement(node) && node.type === BlockType.BLOCK_CONTAINER;
}

export function isLiveBlockSource(node: unknown): node is LiveBlockSource {
    return isSagaElement(node) && node.type === BlockType.LIVE_BLOCK_SOURCE;
}

export function isIndentContainer(node: unknown): node is IndentContainer {
    return isSagaElement(node) && node.type === BlockType.INDENT;
}

export function isLink(node: unknown): node is Link {
    return isSagaElement(node) && !isSagaText(node) && node.type === BlockType.LINK;
}

export function isMention(node: unknown): node is Mention {
    return isSagaElement(node) && node.type === BlockType.MENTION;
}

export function isDateBlock(node: unknown): node is DateBlock {
    return isSagaElement(node) && node.type === BlockType.DATE_BLOCK;
}

export function isKatexBlock(node: unknown): node is KatexBlock {
    return isSagaElement(node) && node.type === BlockType.KATEX_BLOCK;
}

export function isKatexInline(node: unknown): node is KatexInline {
    return isSagaElement(node) && node.type === BlockType.KATEX_INLINE;
}

function hasType(node: unknown): node is { type: string } {
    return 'type' in (node as any) && typeof (node as any).type === 'string';
}

export function isInlineCollection(node: unknown): node is InlineCollection {
    return hasType(node) && node.type === BlockType.INLINE_COLLECTION;
}

export function isTaskBlock(node: unknown): node is TaskBlock {
    return hasType(node) && node.type === BlockType.TASK_BLOCK;
}

export function isInlinePageLink(node: unknown): node is InlinePageLink {
    return hasType(node) && node.type === BlockType.INLINE_PAGE_LINK;
}

export function isPrettyLink(node: unknown): node is PrettyLink {
    return isSagaElement(node) && !isSagaText(node) && node.type === BlockType.PRETTY_LINK;
}

export function isEmbed(node: unknown): node is Embed {
    return isSagaElement(node) && node.type === BlockType.EMBED;
}

export function isInline(node: unknown): node is Inline {
    return isBlockType(node, [
        isSagaText,
        isLink,
        isMention,
        isGoogleDriveFileLink,
        isLinearIssue,
        isDateBlock,
        isKatexInline,
        isInlineCollection,
        isInlinePageLink,
    ]);
}

// this tells you if a given node is an inline that is allowed within a title
export function isTitleInline(node: unknown): node is TitleInline {
    return isBlockType(node, [
        isSagaText,
        isDateBlock,
        isLink,
        isInlinePageLink,
        isMention,
        isGoogleDriveFileLink,
        isLinearIssue,
    ]);
}

export function isSimpleElement(node: unknown): node is SimpleElement {
    return isBlockType(node, [isBlockquote, isParagraph, isHeading, isNumberedListItem, isCheckListItem, isListItem]);
}

export function isSagaText(child: unknown): child is SagaText {
    return 'text' in (child as SagaText) && (child as SagaText).text != null;
}

export function textChildren(children: Inline[]): SagaText[] {
    return children.reduce((result: SagaText[], child: Inline) => {
        if (isLink(child)) {
            result = result.concat(child.children);
        } else if (isSagaText(child)) {
            result.push(child);
        }
        return result;
    }, []);
}

export function stringifyInline(children: Inline[]): string {
    return textChildren(children)
        .map((c) => c.text)
        .join('');
}

export function nodesHaveSameType(a: SagaElement, b: SagaElement) {
    return isSagaElement(a) && isSagaElement(b) && a.type === b.type;
}

export const visit = <T extends SagaElement | { type: 'root'; children: SagaElement[] }>(
    root: T,
    visitFn: (
        [node, path]: [SagaElement, number[]],
        parent: SagaElement | { type: 'root'; children: SagaElement[] },
    ) => { continueAction: 'stop-all' | 'stop-tree' } | void,
    path: number[] = [],
): void => {
    const { children } = root;

    if (children == null) return;

    for (const [index, child] of children.entries()) {
        const childPath = [...path, index];
        const isDoneVisiting = visitFn([child, childPath], root);
        if (isDoneVisiting && isDoneVisiting?.continueAction === 'stop-all') {
            break;
        }

        if (!isDoneVisiting || isDoneVisiting.continueAction !== 'stop-tree') {
            visit(child, visitFn, childPath);
        }
    }
};

export function mapBlock<In extends SagaElement | SagaText, Out>(
    node: In,
    mapperFn: (node: SagaElement | SagaText) => Out,
): Out {
    if (isSagaElement(node)) {
        const sagaElementChildren = Array.from<SagaElement | SagaText>(node.children).filter(
            (node) => isSagaElement(node) || isSagaText(node),
        );

        // The as any is a very bad kludge, but the children types of SagaElement leave no other choice really
        const children = sagaElementChildren.map((child) => mapBlock(child, mapperFn)).flat() as any;
        const mapped = mapperFn({ ...node, children });
        return mapped;
    }

    return mapperFn(node);
}

/**
 * Same as the mapBlock function but async
 */
export async function mapBlockAsync<In extends SagaElement | SagaText, Out>(
    node: In,
    mapperFn: (node: SagaElement | SagaText) => Promise<Out>,
): Promise<Out> {
    if (isSagaElement(node)) {
        const sagaElementChildren = Array.from<SagaElement | SagaText>(node.children).filter(
            (node) => isSagaElement(node) || isSagaText(node),
        );

        // The as any is a very bad kludge, but the children types of SagaElement leave no other choice really
        const children = await Promise.all(sagaElementChildren.map((child) => mapBlockAsync(child, mapperFn)).flat());
        //@ts-ignore
        const mapped = mapperFn({ ...node, children });
        return mapped;
    }

    return mapperFn(node);
}

export const flatMap = <A>(children: SagaElement[], mapFn: ([node, path]: [SagaElement, number[]]) => A): A[] => {
    const result: A[] = [];

    visit({ type: 'root', children }, (nodeEntry) => {
        const mapped = mapFn(nodeEntry);
        result.push(mapped);
    });

    return result;
};

export function mapBlocks(
    blocks: (SagaElement | SagaText)[],
    map: (block: SagaElement | SagaText) => (SagaElement | SagaText) | (SagaElement | SagaText)[],
): (SagaElement | SagaText)[] {
    return blocks.map((block) => mapBlock(block, map)).flat();
}

export async function mapBlocksAsync(
    blocks: (SagaElement | SagaText)[],
    map: (block: SagaElement | SagaText) => Promise<(SagaElement | SagaText) | (SagaElement | SagaText)[]>,
): Promise<(SagaElement | SagaText)[]> {
    return (await Promise.all(blocks.map((block) => mapBlockAsync(block, map)))).flat();
}

export function isGoogleDriveFileLink(element: unknown): element is GoogleDriveLink {
    return isSagaElement(element) && element.type === BlockType.GOOGLE_DRIVE_LINK;
}

export function isLinearIssue(element: unknown): element is LinearIssue {
    return isSagaElement(element) && element.type === BlockType.LINEAR_ISSUE;
}

export function isDivider(element: unknown): element is Divider {
    return isSagaElement(element) && element.type === BlockType.DIVIDER;
}

export function isCallout(element: unknown): element is Callout {
    return isSagaElement(element) && element.type === BlockType.CALLOUT;
}

export const isVoid = (element: unknown) => {
    return (
        isSagaElement(element) &&
        isBlockType(element, [
            isLiveBlock,
            isCode,
            isImage,
            isFile,
            isMention,
            isGoogleDriveFileLink,
            isLinearIssue,
            isPrettyLink,
            isEmbed,
            isDateBlock,
            isDivider,
            isKatexBlock,
            isKatexInline,
            isInlineCollection,
            isInlinePageLink,
            isTaskBlock,
            isAISuggestedText,
        ])
    );
};

export function isMovableContainerElement(node: unknown): node is MovableContainerElement {
    return (
        isSagaElement(node) &&
        !isBlockType(node, [
            isTitle,
            isNumberedList,
            isIndentContainer,
            isTableCell,
            isTableRow,
            isSagaText,
            isLiveBlockSource,
            isInline,
        ])
    );
}

export function isAnyBlockItem(node: unknown): node is AnyBlockItem {
    return (
        isSagaElement(node) &&
        isBlockType(node, [isVoid, isSimpleElement, isNumberedList, isTableCell, isTableRow, isMovableContainerElement])
    );
}
