import React, { useCallback, useEffect } from 'react';
import { Transforms } from 'slate';
import { useFocused, ReactEditor, useSlateStatic } from 'slate-react';
import classNames from 'classnames';
import { SagaEditor, isCode } from '@saga/shared';
import VoidSelectionShadow from '../../VoidSelectionShadow';
import { useCurrentWorkspace } from '@/components/WorkspaceContext';
const CodeEditor = React.lazy(() => import('@/components/editor/CodeEditor'));

export const spaceCodePlugin = SagaEditor.Plugins.createBlockPlugin({
    match: isCode,
    stringify(element) {
        return element.content;
    },
    Component({ children, element, blockPlugins, selected }) {
        const { canEdit, location } = SagaEditor.useEditorContext();
        const focused = useFocused();
        const editor = useSlateStatic();
        const { urlKey } = useCurrentWorkspace();
        const path = React.useMemo(() => ReactEditor.findPath(editor, element), [editor, element]);

        useEffect(() => {
            const onCopy = (event: ClipboardEvent) => {
                event.preventDefault();
                const clipboardData = event.clipboardData?.getData('application/x-saga');
                if (clipboardData && clipboardData.includes(element.id)) return;

                SagaEditor.Clipboard.copyBlocks([element], {
                    location: { ...location, blockId: element.id },
                    spaceUrlKey: urlKey,
                    event,
                    action: 'copy',
                    blockPlugins,
                });
            };

            if (focused && selected) {
                document.addEventListener('copy', onCopy);
            }

            return () => {
                document.removeEventListener('copy', onCopy);
            };
        }, [element, focused, selected, blockPlugins, location, urlKey]);

        const onSetLanguage = useCallback(
            (language) => {
                const path = ReactEditor.findPath(editor, element);
                Transforms.setNodes(editor, { language }, { at: path });
            },
            [editor, element],
        );

        const onChange = useCallback(
            (value: string) => {
                const path = ReactEditor.findPath(editor, element);
                Transforms.setNodes(editor, { content: value }, { at: path });
            },
            [editor, element],
        );

        useEffect(() => {
            if (element.isNew) {
                Transforms.unsetNodes(editor, 'isNew', { at: path });
            }
        }, [element.isNew, editor, path]);

        return (
            <div id={element.id}>
                <VoidSelectionShadow path={path}>
                    <div
                        className={classNames('select-none overflow-x-auto rounded my-1', {
                            'shadow-lightblue': selected && focused,
                        })}
                        contentEditable={false}
                    >
                        <React.Suspense fallback={null}>
                            <CodeEditor
                                content={element.content}
                                language={element.language}
                                onSetLanguage={onSetLanguage}
                                onChange={onChange}
                                disabled={!canEdit}
                                showCodeSandbox={true}
                                isNew={element.isNew}
                            ></CodeEditor>
                        </React.Suspense>

                        <div className="hidden select-none">{children}</div>
                    </div>
                </VoidSelectionShadow>
            </div>
        );
    },
});
