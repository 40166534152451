import React, { useMemo } from 'react';
import { PRIORITIES } from '../tasks/PrioritySelect';

const powerToColourLight = {
    empty: ['#B8B8B8', '#B8B8B8', '#B8B8B8'],
    LOW: ['#30383F', '#B8B8B8', '#B8B8B8'],
    MEDIUM: ['#30383F', '#30383F', '#B8B8B8'],
    HIGH: ['#30383F', '#30383F', '#30383F'],
};

const powerToColourDark = {
    empty: ['#626262', '#626262', '#626262'],
    LOW: ['#E4E4E7', '#626262', '#626262'],
    MEDIUM: ['#E4E4E7', '#E4E4E7', '#626262'],
    HIGH: ['#E4E4E7', '#E4E4E7', '#E4E4E7'],
};

export function SignalIcon({
    power,
    theme = 'light',
}: {
    power?: typeof PRIORITIES[number];
    theme?: 'light' | 'dark';
}) {
    const fill = useMemo(() => (theme === 'light' ? powerToColourLight : powerToColourDark), [theme]);

    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="red" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.00016 10C4.36835 10 4.66683 10.2985 4.66683 10.6667V13.3333C4.66683 13.7015 4.36835 14 4.00016 14C3.63197 14 3.3335 13.7015 3.3335 13.3333V10.6667C3.3335 10.2985 3.63197 10 4.00016 10Z"
                fill={fill[power ? power : 'empty'][0]}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.00016 6C8.36835 6 8.66683 6.29848 8.66683 6.66667V13.3333C8.66683 13.7015 8.36835 14 8.00016 14C7.63197 14 7.3335 13.7015 7.3335 13.3333V6.66667C7.3335 6.29848 7.63197 6 8.00016 6Z"
                fill={fill[power ? power : 'empty'][1]}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.0002 2C12.3684 2 12.6668 2.29848 12.6668 2.66667V13.3333C12.6668 13.7015 12.3684 14 12.0002 14C11.632 14 11.3335 13.7015 11.3335 13.3333V2.66667C11.3335 2.29848 11.632 2 12.0002 2Z"
                fill={fill[power ? power : 'empty'][2]}
            />
        </svg>
    );
}
