import { stringToColor } from '@/../../shared/src/utils/Colors';
import { AVAILABLE_COLORS } from '@/constants';
import * as Select from '@radix-ui/react-select';
import classNames from 'classnames';
import React from 'react';
import { User } from 'react-feather';
import { useTranslation } from 'react-i18next';
import MemberAvatar from '../MemberAvatar';
import { getFullMemberName } from '../MembersProvider';
import { Member } from '@/types';

export type AssigneeFilterMode = { kind: 'unassigned' } | { kind: 'assigned'; memberId: string } | { kind: 'all' };

function TaskAssigneeSelect({
    value,
    members,
    onChange,
}: {
    value: AssigneeFilterMode;
    members: Member[];
    onChange: (id: AssigneeFilterMode) => void;
}) {
    const { t } = useTranslation();
    const selectValue = (() => {
        switch (value.kind) {
            case 'all':
                return 'all';
            case 'assigned':
                return value.memberId;
            case 'unassigned':
                return 'unassigned';
        }
    })();

    const onSelectChange = (s: string) => {
        switch (s) {
            case 'all':
                onChange({ kind: 'all' });
                break;
            case 'unassigned':
                onChange({ kind: 'unassigned' });
                break;
            default:
                onChange({ kind: 'assigned', memberId: s });
                break;
        }
    };

    const matchMemberId = (v: AssigneeFilterMode, memberId: string): boolean =>
        v.kind === 'assigned' && v.memberId === memberId;

    const currentSelectMember = value.kind === 'assigned' && members.find((m) => m.id === value.memberId);

    return (
        <Select.Root value={selectValue} onValueChange={onSelectChange}>
            <Select.Trigger className="whitespace-nowrap focus:outline-none cursor-pointer px-2 h-8 text-xs border font-semibold rounded hover:bg-saga-gray-200 dark:hover:bg-saga-gray-700 border-saga-gray-200 dark:border-saga-gray-700 shadow-button">
                {currentSelectMember ? (
                    <Select.Value>
                        <div className="cursor-pointer space-x-2 flex items-center">
                            <MemberAvatar
                                name={getFullMemberName(currentSelectMember)}
                                color={stringToColor(currentSelectMember.id, AVAILABLE_COLORS)}
                                size="sm"
                                showBorder={false}
                            />
                            <span>{getFullMemberName(currentSelectMember)}</span>
                        </div>
                    </Select.Value>
                ) : (
                    <Select.Value />
                )}
            </Select.Trigger>

            <Select.Content className="bg-white dark:bg-zinc-700 border border-saga-gray-200 dark:border-zinc-600 rounded shadow-popoverSmall  text-xs">
                <Select.ScrollUpButton />
                <Select.Viewport className="rounded">
                    <Select.Item
                        value={'all'}
                        className={classNames('focus:outline-none font-semibold cursor-pointer px-2 py-2', {
                            'bg-saga-gray-200 dark:text-zinc-800': value.kind === 'all',
                            'hover:bg-saga-gray-200 dark:hover:bg-zinc-600': !(value.kind === 'all'),
                        })}
                    >
                        <Select.ItemText>
                            <div className="cursor-pointer space-x-2 flex items-center">
                                <User size={14} />
                                <span>{t('tasks.all_assignees')}</span>
                            </div>
                        </Select.ItemText>
                    </Select.Item>
                    <Select.Item
                        value={'unassigned'}
                        className={classNames('focus:outline-none font-semibold cursor-pointer px-2 py-2', {
                            'bg-saga-gray-200 dark:text-zinc-800': value.kind === 'unassigned',
                            'hover:bg-saga-gray-200 dark:hover:bg-zinc-600': !(value.kind === 'unassigned'),
                        })}
                    >
                        <Select.ItemText>
                            <div className="cursor-pointer space-x-2 flex items-center">
                                <User size={14} />
                                <span>{t('tasks.not_assigned')}</span>
                            </div>
                        </Select.ItemText>
                    </Select.Item>
                    {members.map((m, i) => (
                        <Select.Item
                            key={i}
                            value={m.id}
                            className={classNames('focus:outline-none font-semibold cursor-pointer px-2 py-2', {
                                'bg-saga-gray-200 dark:text-zinc-800': matchMemberId(value, m.id),
                                'hover:bg-saga-gray-200 dark:hover:bg-zinc-600': !matchMemberId(value, m.id),
                            })}
                        >
                            <div className="cursor-pointer space-x-1.5 flex items-center">
                                <MemberAvatar
                                    name={getFullMemberName(m)}
                                    color={stringToColor(m.id, AVAILABLE_COLORS)}
                                    size="sm"
                                    showBorder={false}
                                />
                                <Select.ItemText>{getFullMemberName(m)}</Select.ItemText>
                            </div>
                        </Select.Item>
                    ))}
                </Select.Viewport>
                <Select.ScrollDownButton />
            </Select.Content>
        </Select.Root>
    );
}

export default TaskAssigneeSelect;
