import * as Y from 'yjs';
import findReferenceRegistryEntry from './findReferenceRegistryEntry';
import { SafeSpace } from '../types';
import { assertNonNull } from '../utils';

export default function mustFindReferenceRegistryEntry(space: SafeSpace, id: string): Y.Map<unknown> {
    const entry = findReferenceRegistryEntry(space, id);
    assertNonNull(entry, `mustFindReferenceRegistry: ReferenceRegistryEntry with id ${id} cannot be found`);
    return entry;
}
