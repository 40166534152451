import * as Y from 'yjs';
import { ReferenceRegistryEntry } from '../types';
import { ymapFromRecord } from '../yjs-utils';
import findReferenceRegistryEntry from './findReferenceRegistryEntry';
import { SafeSpace } from '../types';
import { pipe } from 'fp-ts/lib/function';
import * as E from 'fp-ts/Either';
import transactInSpace from './transactInSpace';

export default function addToReferenceRegistry(space: SafeSpace, entry: ReferenceRegistryEntry) {
    const existingEntry = findReferenceRegistryEntry(space, entry.liveReferenceSourceId);

    if (existingEntry != null) {
        throw new Error(`A live block source with the id ${entry.liveReferenceSourceId} already exists`);
    }

    const yentry = ymapFromRecord(entry, undefined, (_, value) => value) as unknown as Y.Map<ReferenceRegistryEntry>;

    transactInSpace(space, () => {
        pipe(
            space.get('referencesRegistry'),
            //  but the type looks correct but typescript complain here when running check-types
            E.map((safeArray) => safeArray.array.push([yentry])),
        );
    });
}
