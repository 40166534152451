import { track } from '@/analytics';
import { AI, Magic } from '@/assets/icons';
import { scrollToSelectedIndex } from '@/components/editor/Suggestions';
import { useArrowSelectableIndex } from '@/components/popover/Dropdown';
import { PromptModalData } from '@/components/settings/ai/AISettings';
import PromptModal from '@/components/settings/ai/PromptModal';
import useAISuggestedPrompts, { AIActionCategory, AIActionSuggestion } from '@/hooks/useAISuggestedPrompts';
import { CustomPromptType } from '@saga/api';
import { assertNonNull } from '@saga/shared';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Edit3 } from 'react-feather';
import { useTranslation } from 'react-i18next';

const AIActionsSuggest = ({
    type,
    filter,
    onSuggestedPrompt,
}: {
    type: CustomPromptType;
    filter?: string;
    onSuggestedPrompt: (prompt: string) => void;
}) => {
    const suggestionsContainerRef = useRef<HTMLDivElement>(null);
    const { t } = useTranslation();

    const { generateAIPrompt, staticPromptSuggestions, userPromptSuggestions } = useAISuggestedPrompts();

    const [editPrompt, setEditPrompt] = useState<PromptModalData>({ visible: false, prompt: null });

    const defaultAISuggestions = staticPromptSuggestions[type];
    const userAISuggestions: AIActionCategory = {
        title: type === CustomPromptType.Command ? 'ai.my_commands' : 'ai.my_prompts',
        suggestions:
            userPromptSuggestions
                ?.filter((prompt) => prompt.type === type)
                .map((userPrompt) => ({
                    title: userPrompt.title,
                    key: userPrompt.id,
                    icon: type === CustomPromptType.Command ? AI : Magic,
                })) ?? [],
    };

    const filteredSuggestions = [userAISuggestions, ...defaultAISuggestions]
        .map((category) => ({
            title: category.title,
            suggestions: category.suggestions.filter(
                (suggestion) =>
                    (filter
                        ? suggestion.title.toLowerCase().includes(filter.toLowerCase()) ||
                          category.title.toLowerCase().includes(filter.toLowerCase())
                        : true) && !suggestion.hidden,
            ),
        }))
        .filter((category) => category.suggestions.length);

    const isSuggetionsEmpty = isEmpty(filteredSuggestions);

    const { index: selectedIndex, setIndex: setSelectedIndex } = useArrowSelectableIndex({
        onSelect: (index) => {
            const selectedSuggestion = filteredSuggestions.map((category) => category.suggestions).flat()[index];
            handleOnSuggestion(selectedSuggestion);
        },
        onUpdate() {
            if (suggestionsContainerRef.current) {
                scrollToSelectedIndex(suggestionsContainerRef.current);
            }
        },
        maxIndex: filteredSuggestions.map((category) => category.suggestions).flat().length - 1,
        active: !!filteredSuggestions.length && !editPrompt.visible,
        selectWithTab: true,
    });

    const handleOnSuggestion = useCallback(
        (suggestion: AIActionSuggestion) => {
            setSelectedIndex(undefined);

            const prompt = generateAIPrompt(suggestion, type);

            if (prompt) {
                onSuggestedPrompt?.(prompt);
            }
        },
        [setSelectedIndex, onSuggestedPrompt, generateAIPrompt, type],
    );

    useEffect(() => {
        setSelectedIndex(undefined);
    }, [filter, setSelectedIndex]);

    const onEditPrompt = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, suggestion: AIActionSuggestion) => {
        e.stopPropagation();

        const prompt = userPromptSuggestions?.find((prompt) => prompt.id === suggestion.key);

        assertNonNull(prompt);
        setEditPrompt({ visible: true, prompt });
    };

    if (isSuggetionsEmpty) return null;

    return (
        <div
            className="max-h-suggestion overflow-auto rounded-md bg-white dark:bg-saga-gray-900 shadow-small py-1.5"
            ref={suggestionsContainerRef}
        >
            {filteredSuggestions.map((category, cIndex) => (
                <div
                    key={category.title}
                    className="text-xs font-medium leading-normal text-saga-gray-500 dark:text-zinc-400"
                >
                    <div className="px-3.5 py-1">{t(category.title)}</div>
                    {category.suggestions.map((suggestion, sIndex) => {
                        const indexes: number = filteredSuggestions
                            .slice(0, cIndex)
                            .map((category) => category.suggestions)
                            .flat().length;
                        const currentIndex = indexes + sIndex;
                        return (
                            <div
                                data-selected={selectedIndex === currentIndex}
                                onMouseEnter={() => setSelectedIndex(currentIndex)}
                                onClick={() => {
                                    track('click-on-ai-suggestion', { suggestion: suggestion.title });
                                    handleOnSuggestion(suggestion);
                                }}
                                key={suggestion.title}
                                className={classNames(
                                    'flex flex-row text-sm font-normal mx-1.5 py-1.5 px-2 text-saga-text dark:text-saga-gray-300 cursor-pointer items-center rounded',
                                    {
                                        'bg-saga-gray-250 dark:bg-saga-gray-700': selectedIndex === currentIndex,
                                    },
                                )}
                            >
                                <suggestion.icon className="w-[14px] h-[14px] mr-2 shrink-0" />
                                <div className="truncate inline-block flex-1">{suggestion.title}</div>
                                {userPromptSuggestions?.map((s) => s.id).includes(suggestion.key) &&
                                    selectedIndex === currentIndex && (
                                        <div
                                            className="ml-2 mr-1 rounded hover:bg-saga-gray-300 hover:dark:bg-saga-gray-700"
                                            onClick={(e) => onEditPrompt(e, suggestion)}
                                        >
                                            <Edit3 size={14} />
                                        </div>
                                    )}
                            </div>
                        );
                    })}
                </div>
            ))}
            {editPrompt.visible && editPrompt.prompt && (
                <PromptModal
                    prompt={editPrompt.prompt}
                    onClose={() => setEditPrompt({ visible: false, prompt: null })}
                />
            )}
        </div>
    );
};

export default AIActionsSuggest;
