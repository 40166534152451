import React, { forwardRef } from 'react';
import classNames from 'classnames';
import { X } from 'react-feather';

type ClearableButtonProps = {
    children: React.ReactNode;
    active: boolean;
    onClear(): void;
    onClick(): void;
    className?: string;
};

export default forwardRef<HTMLDivElement, ClearableButtonProps>(function CombinedButton(
    { children, active, onClear, onClick, className },
    ref,
) {
    return (
        <div
            className={classNames(
                'hover-trigger pl-2 py-1.5 flex h-8 items-center rounded text-xs leading-normal border dark:bg-inherit hover:bg-saga-gray-200 dark:hover:bg-saga-gray-700 border-saga-gray-200 dark:border-saga-gray-700 cursor-pointer shadow-button',
                className,
            )}
            ref={ref}
        >
            <button className="focus:outline-none pr-1.5 flex items-center justify-center" onClick={onClear}>
                <i
                    style={{ fontSize: '14px' }}
                    className={classNames('ri-arrow-up-down-line', {
                        'hover-target-hidden': active,
                    })}
                />
                {active && <X className="hover-target-block text-saga-gray-dark dark:text-zinc-200" size={14} />}
            </button>
            <button className="focus:outline-none h-8 flex items-center" onClick={onClick}>
                {children}
            </button>
        </div>
    );
});
