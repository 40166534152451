import { SagaEditor, isGoogleDriveFileLink, EditorOperations, SpaceOperations, GoogleDriveLink } from '@saga/shared';
import React from 'react';
import { useOpenGoogleDriveFile } from '@/hooks/useOpenGoogleDriveFile';
import { truncateString } from '@/utils';
import { useUserContext } from '@/components/UserContext';
import * as HoverCard from '@radix-ui/react-hover-card';
import { GoogleDriveFileFragment } from '@/../../api/src';
import Spinner from '@/components/loading/Spinner';
import * as api from '@saga/api';
import { formatDateOptimized } from '@/utils/dateUtils';
import { Transforms } from 'slate';
import { getSdk } from '@/graphql';
import Button from '@/components/styled/Button';
import { AlertTriangle, Loader, RefreshCw } from 'react-feather';
import { useTranslation } from 'react-i18next';

function GoogleDriveLinkContent({ refreshGoogleDrive, id }: { id: string; refreshGoogleDrive: () => void }) {
    const GOOGLE_THUMBNAIL_URL = 'https://drive.google.com/thumbnail?authuser=0&sz=w999&id=';
    const { user } = useUserContext();
    const { t } = useTranslation();
    const { data, loading } = api.useGoogleDriveDocumentQuery({
        variables: { input: { id } },
        fetchPolicy: 'network-only',
    });

    if (loading) {
        return (
            <div className="flex items-center justify-center h-16">
                <Spinner />
            </div>
        );
    }

    const googleDriveDocument = data?.googleDriveDocument;
    const googleDriveOwner = googleDriveDocument?.owners && googleDriveDocument?.owners[0];

    return (
        <div className="py-1.5 px-2 space-y-1.5">
            <div className="flex flex-col w-full justify-between">
                <div className="flex items-center justify-between">
                    <div className="flex flex-row">
                        {googleDriveDocument?.iconLink && (
                            <img
                                className="object-contain rounded-sm inline-block align-text-bottom pr-2"
                                src={googleDriveDocument.iconLink}
                            />
                        )}
                        <h1 className="text-base font-medium">{googleDriveDocument?.name}</h1>
                    </div>
                    <Button.Plain
                        onClick={(e) => {
                            e.stopPropagation();
                            refreshGoogleDrive();
                        }}
                    >
                        <Button.SmallPadding>
                            <RefreshCw size={14} />
                        </Button.SmallPadding>
                    </Button.Plain>
                </div>

                <div>
                    {googleDriveOwner?.displayName && (
                        <p className="py-0.5">Created by: {googleDriveOwner.displayName}</p>
                    )}
                </div>
                <div>
                    {googleDriveDocument?.modifiedTime && (
                        <p className="py-0.5">
                            {t('settings.integrations.google_drive.last_updated', {
                                date: formatDateOptimized(
                                    new Date(googleDriveDocument.modifiedTime).toString(),
                                    user?.data.language,
                                ),
                            })}
                        </p>
                    )}
                </div>
                <div className="flex justify-center bg-saga-gray-300 dark:bg-saga-gray-600 rounded">
                    <img
                        onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) =>
                            (e.currentTarget.style.display = 'none')
                        }
                        src={GOOGLE_THUMBNAIL_URL + googleDriveDocument?.id}
                    />
                </div>
            </div>
        </div>
    );
}

function GoogleDrivePopover({
    children,
    driveFile,
    refreshGoogleDrive,
}: {
    children: React.ReactNode;
    driveFile: GoogleDriveFileFragment;
    refreshGoogleDrive: () => void;
}) {
    const { isLoggedIn } = useUserContext();
    const openGoogleDriveFile = useOpenGoogleDriveFile();

    if (!isLoggedIn) {
        return <>{children}</>;
    }

    return (
        <HoverCard.Root>
            <HoverCard.Trigger>{children}</HoverCard.Trigger>
            <HoverCard.Portal>
                <HoverCard.Content style={{ zIndex: 1 }} align="start">
                    <div
                        onClick={(event) => openGoogleDriveFile(driveFile, event, 'inline-link')}
                        onMouseDown={(e) => e.stopPropagation()}
                        className="text-xs space-y-2 relative shadow-popupSmall w-300 max-h-60 overflow-y-auto rounded-md bg-white dark:bg-zinc-700 dark:border dark:border-zinc-600 pointer-events-auto cursor-pointer"
                    >
                        <GoogleDriveLinkContent refreshGoogleDrive={refreshGoogleDrive} id={driveFile.id} />
                    </div>
                </HoverCard.Content>
            </HoverCard.Portal>
        </HoverCard.Root>
    );
}

function GoogleDriveState({
    state,
    selected,
    refreshGoogleDrive,
}: {
    state: GoogleDriveLink['state'];
    selected?: boolean;
    refreshGoogleDrive: () => void;
}) {
    const openGoogleDriveFile = useOpenGoogleDriveFile();
    const { t } = useTranslation();
    if (state?.type === 'loaded') {
        const file = state.file;

        return (
            <GoogleDrivePopover refreshGoogleDrive={refreshGoogleDrive} driveFile={file}>
                <span
                    style={{
                        boxShadow: selected ? '0 0 0 3px #BBD6FB' : undefined,
                    }}
                    className="select-none inline space-x-1 pl-0.5 pr-0.5 rounded  text-saga-text dark:text-neutral-200 font-medium title:font-semibold"
                >
                    {file.iconLink && (
                        <img
                            className="[height:1.1em] [width:1em] object-contain rounded-sm inline-block align-text-bottom"
                            src={file.iconLink}
                        />
                    )}
                    <span
                        onClick={(event) => openGoogleDriveFile(file, event, 'inline-link')}
                        className="max-w-56 truncate"
                    >
                        {truncateString(file.name, 50)}
                    </span>
                </span>
            </GoogleDrivePopover>
        );
    }

    if (state?.type === 'error') {
        return (
            <span
                style={{
                    boxShadow: selected ? '0 0 0 3px #BBD6FB' : undefined,
                }}
                className="text-saga-red select-none inline space-x-1 pl-0.5 pr-0.5 rounded font-medium title:font-semibold"
            >
                <AlertTriangle className="inline-block align-text-bottom [height:1.2em] [width:1em]" />
                <span className="max-w-56 truncate">{t('settings.integrations.google_drive.cant_load_file')}</span>
            </span>
        );
    }

    return (
        <span
            style={{
                boxShadow: selected ? '0 0 0 3px #BBD6FB' : undefined,
            }}
            className="select-none inline space-x-1 pl-0.5 pr-0.5 rounded text-saga-text dark:text-neutral-200 font-medium title:font-semibold"
        >
            <Loader className="inline-block align-text-bottom [height:1.2em] [width:1em] animate-spin" />
            <span className="max-w-56 truncate">{t('common.loading')}</span>
        </span>
    );
}

export const googleDriveLinkBlockPlugin = SagaEditor.Plugins.createBlockPlugin({
    match: isGoogleDriveFileLink,
    normalizers: [SagaEditor.Normalizers.googleDriveDocumentNormalizer],
    Component({ element, selected, children, editor }) {
        const refreshGoogleDrive: any = () => {
            const nodeEntry = EditorOperations.Selection.getNode(editor, element.id);

            if (nodeEntry == null) return;

            Transforms.select(editor, {
                path: nodeEntry[1],
                offset: 2,
            });

            Transforms.delete(editor, { at: nodeEntry[1] });
            if (element.state?.type === 'loaded') {
                const googleDriveFileId = element.state.file.id;
                SpaceOperations.addGoogleDrive(getSdk, editor, googleDriveFileId);
            }
        };

        return (
            <span id={element.id} className="cursor-pointer inline" contentEditable={false}>
                <GoogleDriveState refreshGoogleDrive={refreshGoogleDrive} state={element.state} selected={selected} />
                <span className="select-none hidden">{children}</span>
            </span>
        );
    },
});
