import { track } from '@/analytics';
import { IconPicker } from '@/components/IconPicker';
import { TemplateIcon } from '@/components/icons';
import { useOpenPage } from '@/components/PageNavigationProvider';
import ManageAliasInput from '@/components/popover/ManageAliasInput';
import TemplatesPopOver from '@/components/popover/TemplatesPopOver';
import { useSpace } from '@/components/SpaceProvider';
import Button from '@/components/styled/Button';
import Notice from '@/components/styled/Notice';
import AssigneeSelect from '@/components/tasks/AssigneeSelect';
import { DueDateSelect } from '@/components/tasks/DueDateSelect';
import { useTask } from '@/components/tasks/hooks';
import { useTemplatesContext } from '@/components/templates/TemplatesProvider';
import { usePageSnapshot, useTaskLabels } from '@/hooks/SpaceHooks';
import useInterfaceSettings from '@/hooks/useInterfaceSettings';

import {
    assertNonNull,
    SagaEditor,
    EditorOperations,
    isTitle,
    isVoid,
    newBlankPage,
    SagaLocation,
    SpaceOperations,
    Title,
    RealtimeSagaEditor,
    CustomTemplate,
    PredefinedTemplate,
} from '@saga/shared';
import classNames from 'classnames';
import React, { useMemo, useState } from 'react';
import { Calendar, Smile } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { Editor as SlateEditor, Node } from 'slate';
import { ReactEditor, RenderElementProps } from 'slate-react';
import PrioritySelect from '@/components/tasks/PrioritySelect';
import { WithExplicitFontType } from '@/components/WithExplicitFontType';
import { formatTaskDate } from '@/../../shared/src/utils/DateUtils';
import { createPage } from '@/utils/documentUtils';
import { usePerformActionWithYBlocks } from '@/components/RealtimeDocumentProvider';
import { usePageAccess, usePagesPermissions } from '@/components/PagesPermissionsBySpaceProvider';
import { useCurrentWorkspace } from '@/components/WorkspaceContext';
import * as api from '@saga/api';
import { getCurrentUser } from '@/firebase';
import TaskStatusSelect from '@/components/tasks/TaskStatusSelect';
import LabelSelect, { TaskLabelColors } from '@/components/tasks/LabelSelect';

function isEmptyPage(editor: SlateEditor) {
    const content = editor.children.slice(1).map(Node.string).join('').trim();
    return content === '';
}

function TemplateNotice({ onViewTemplates }: { onViewTemplates: () => void }) {
    const { t } = useTranslation();
    return (
        <Notice>
            <div className="py-2 px-3 inline-flex items-start space-x-4">
                <div className="flex items-center h-5">
                    <TemplateIcon />
                </div>
                <span>{t('editor.edit_template')}</span>
                <div>
                    <button
                        onClick={onViewTemplates}
                        className="ml-4 hover:opacity-75 font-semibold underline outline-none"
                    >
                        {t('editor.view_templates')}
                    </button>
                </div>
            </div>
        </Notice>
    );
}

const templatePageKeys = [
    'id',
    'aliases',
    'title',
    'icon',
    'createdAt',
    'collections',
    'archivedAt',
    'isTemplate',
] as const;

const pageTitleKeys = ['icon', 'archivedAt', 'aliases', 'title', 'isTemplate'] as const;
function PageTitle({
    children,
    element,
    editor,
    location,
}: SagaEditor.Plugins.BlockPluginProps<Title> & { location: SagaLocation.PageLocation }) {
    const pageId = location.pageId;
    const { canEdit, blockPlugins } = SagaEditor.useEditorContext();
    const { space, provider } = useSpace();
    const { t } = useTranslation();
    const { hasAccess } = usePagesPermissions();

    const customTemplates = React.useMemo(
        () => SpaceOperations.getPages(space, templatePageKeys, 'templates', hasAccess),
        [space, hasAccess],
    );

    const recentlyUsedTemplateIds = React.useMemo(() => SpaceOperations.getRecentlyUsedTemplateIds(space), [space]);

    const createAlias = React.useCallback(
        async (title: string) => {
            const page = SpaceOperations.getPageById(space, pageId, ['aliases']);
            assertNonNull(page, 'createAlias: Page not found');
            const newAliases = [...page.aliases, title];
            SpaceOperations.updatePartialPage(space, pageId, { aliases: newAliases });
            track('new-alias', { source: 'title' });
        },
        [space, pageId],
    );

    const removeAlias = React.useCallback(
        async (alias: string) => {
            const page = SpaceOperations.getPageById(space, pageId, ['aliases']);
            assertNonNull(page, 'createAlias: Page not found');
            const newAliases = page.aliases.filter((c) => c !== alias);
            SpaceOperations.updatePartialPage(space, pageId, { aliases: newAliases });
            track('remove-alias', { source: 'title' });
        },
        [space, pageId],
    );

    const [showAddAlias, setShowAddAlias] = useState(false);
    const [{ darkMode }] = useInterfaceSettings();

    const page = usePageSnapshot(pageId, pageTitleKeys);

    const isArchived = page?.archivedAt != null;
    const templateButtonRef = React.useRef<HTMLButtonElement>(null);
    const [isTemplatePopoverOpen, setIsTemplatePopoverOpen] = React.useState(false);
    const setTemplatesPickerOpen = useTemplatesContext();
    const openPage = useOpenPage();

    const performActionWithBlocks = usePerformActionWithYBlocks();

    const isTitleEmpty = React.useMemo(
        () =>
            EditorOperations.SagaElement.toString(element, blockPlugins).length === 0 &&
            element.children.every((child) => !isVoid(child)),
        [element, blockPlugins],
    );

    if (isTitleEmpty) {
        children = (
            <div
                data-testid="title-placeholder"
                className="inline-flex placeholder"
                data-content={t('pages.new_page_placeholder')}
                data-font-weight="semibold"
            >
                {children}
            </div>
        );
    }

    const performApplyTemplateToPage = React.useCallback(
        (template: CustomTemplate | PredefinedTemplate) => {
            if (page?.isTemplate) return;

            performActionWithBlocks(SagaLocation.pageLocationFromId(pageId), (targetYBlocks) => {
                SpaceOperations.applyTemplateMetaToLocation(space, { template, location });

                if (template.blocks) {
                    SpaceOperations.applyTemplateBlocksToLocation(space, {
                        templateBlocks: template.blocks,
                        location,
                        targetYBlocks,
                    });
                } else {
                    performActionWithBlocks(SagaLocation.pageLocationFromId(template.id), (templateYBlocks) => {
                        SpaceOperations.applyTemplateBlocksToLocation(space, {
                            templateBlocks: templateYBlocks.toJSON(),
                            location,
                            targetYBlocks,
                        });
                    });
                }
            });
        },
        [location, performActionWithBlocks, space, page, pageId],
    );

    return (
        <div
            className={classNames('title group', { 'py-2': !isArchived, 'pt-1 pb-2': isArchived })}
            id="title-element"
            data-testid="page-title"
        >
            {canEdit && page?.isTemplate && (
                <span contentEditable={false} className={classNames('select-none', { '-ml-2': page.icon != null })}>
                    <TemplateNotice onViewTemplates={() => setTemplatesPickerOpen(true)} />
                </span>
            )}

            {!isArchived && canEdit && page && (
                <WithExplicitFontType type="interface">
                    <div
                        contentEditable={false}
                        className={classNames('hidden sm:flex select-none relative h-8 items-center', {
                            '-ml-2': page.icon,
                        })}
                    >
                        {!page.icon && (
                            <IconPicker
                                darkMode={darkMode}
                                icon={page.icon}
                                onChange={(emoji) => {
                                    if (emoji.colons) {
                                        track('add-emoji', { source: 'page' });
                                        SpaceOperations.updatePartialPage(space, pageId, {
                                            icon: { colons: emoji.colons, type: 'emoji' },
                                        });
                                    }
                                }}
                                onRemove={() => {
                                    track('remove-emoji', { source: 'page' });
                                    SpaceOperations.updatePartialPage(space, pageId, {
                                        icon: undefined,
                                    });
                                }}
                                top={32}
                            >
                                {(props) => (
                                    <div
                                        className={classNames('mr-2', {
                                            'opacity-0 group-hover:opacity-100':
                                                !props.isOpen && !isTemplatePopoverOpen && !showAddAlias,
                                        })}
                                    >
                                        <Button.DashedPill
                                            onClick={() => {
                                                ReactEditor.blur(editor);
                                                props.onClick();
                                            }}
                                            ref={props.ref}
                                        >
                                            <div className="flex space-x-1 items-center">
                                                <Smile size={14} />
                                                <span>+ Icon</span>
                                            </div>
                                        </Button.DashedPill>
                                    </div>
                                )}
                            </IconPicker>
                        )}

                        {page.aliases.length === 0 && (
                            <div
                                className={classNames('group-hover:opacity-100', {
                                    'opacity-0': !showAddAlias && !isTemplatePopoverOpen,
                                })}
                            >
                                <ManageAliasInput
                                    canEdit={canEdit}
                                    currentTitle={page.title}
                                    currentAliases={[]}
                                    isOpen={showAddAlias}
                                    setIsOpen={setShowAddAlias}
                                    onCreate={createAlias}
                                    onRemove={removeAlias}
                                    placeholder={t('common.plus_alias') as string}
                                    small={true}
                                />
                            </div>
                        )}

                        {!page.isTemplate && isEmptyPage(editor) && (
                            <>
                                <div
                                    className={classNames('ml-2', {
                                        'opacity-0 group-hover:opacity-100': !isTemplatePopoverOpen && !showAddAlias,
                                    })}
                                >
                                    <Button.DashedPill
                                        ref={templateButtonRef}
                                        onClick={() => {
                                            ReactEditor.blur(editor);
                                            setIsTemplatePopoverOpen(true);
                                        }}
                                    >
                                        <div className="flex space-x-1 items-center h-5">
                                            <span>{t('common.plus_template')}</span>
                                        </div>
                                    </Button.DashedPill>
                                </div>
                                {isTemplatePopoverOpen && (
                                    <TemplatesPopOver
                                        onExploreTemplatesClick={() => {
                                            setTemplatesPickerOpen(true);
                                        }}
                                        onCreateTemplate={async (title, event) => {
                                            const targetPage = createPage(
                                                space,
                                                newBlankPage({ title, isTemplate: true }),
                                                provider,
                                            );
                                            openPage(targetPage.id, event);
                                        }}
                                        onApplyCustomTemplate={performApplyTemplateToPage}
                                        onApplyPredefinedTemplate={performApplyTemplateToPage}
                                        recentlyUsedTemplateIds={recentlyUsedTemplateIds}
                                        customTemplates={customTemplates}
                                        onClose={() => setIsTemplatePopoverOpen(false)}
                                        isOpen
                                        attachToRef={templateButtonRef}
                                        inputProps={{
                                            placeholder: t('common.add_template_placeholder'),
                                            title: 'Select a template',
                                        }}
                                    />
                                )}
                            </>
                        )}
                    </div>
                </WithExplicitFontType>
            )}
            <div className="space-x-2 flex items-start">
                {page && page.icon && (
                    <div contentEditable={false} className="select-none inline-flex relative -ml-2">
                        <IconPicker
                            darkMode={darkMode}
                            icon={page.icon}
                            onChange={(emoji) => {
                                if (emoji.colons) {
                                    track('add-emoji', { source: 'page' });
                                    SpaceOperations.updatePartialPage(space, pageId, {
                                        icon: { colons: emoji.colons, type: 'emoji' },
                                    });
                                }
                            }}
                            onRemove={() => {
                                track('remove-emoji', { source: 'page' });
                                SpaceOperations.updatePartialPage(space, pageId, {
                                    icon: undefined,
                                });
                            }}
                            top={54}
                        >
                            {(props) => (
                                <IconPicker.Button
                                    disabled={!canEdit}
                                    icon={page.icon}
                                    onClick={() => {
                                        ReactEditor.blur(editor);
                                        props.onClick();
                                    }}
                                    label="Change Icon"
                                    ref={props.ref}
                                />
                            )}
                        </IconPicker>
                    </div>
                )}

                <h1 id={element.id} className="inline sm:text-4xl text-[28px] leading-snug sm:leading-[1.3] font-bold">
                    {children}
                </h1>
            </div>
            {page && page.aliases.length > 0 && (
                <WithExplicitFontType type="interface">
                    <div contentEditable={false} id="alias-element">
                        <ManageAliasInput
                            canEdit={canEdit}
                            currentTitle={page.title}
                            currentAliases={page.aliases}
                            isOpen={showAddAlias}
                            setIsOpen={setShowAddAlias}
                            onCreate={createAlias}
                            onRemove={removeAlias}
                            small={true}
                        />
                    </div>
                </WithExplicitFontType>
            )}
        </div>
    );
}

function TaskTitle({
    element,
    children,
    location,
    editor,
}: {
    attributes: RenderElementProps['attributes'];
    children: RenderElementProps['children'];
    location: SagaLocation.TaskLocation;
    editor: RealtimeSagaEditor;
    element: Title;
}) {
    const task = useTask(location.taskId);
    const { canEdit } = usePageAccess(location.taskId);
    const { space, provider } = useSpace();
    const { blockPlugins } = SagaEditor.useEditorContext();
    const templateButtonRef = React.useRef<HTMLButtonElement>(null);
    const [isTemplatePopoverOpen, setIsTemplatePopoverOpen] = React.useState(false);
    const { t, i18n } = useTranslation();
    const setTemplatesPickerOpen = useTemplatesContext();
    const openPage = useOpenPage();
    const { hasAccess } = usePagesPermissions();
    const { urlKey } = useCurrentWorkspace();
    const [sendTaskAssignmentNotification] = api.useSendTaskAssignmentNotificationMutation();
    const currentUser = getCurrentUser();
    const taskLabels = useTaskLabels();

    const performActionWithBlocks = usePerformActionWithYBlocks();

    const performApplyTemplateToTask = React.useCallback(
        (template: CustomTemplate | PredefinedTemplate) => {
            performActionWithBlocks(SagaLocation.taskLocationFromId(task.id), (targetYBlocks) => {
                SpaceOperations.applyTemplateMetaToLocation(space, { template, location });

                if (template.blocks) {
                    SpaceOperations.applyTemplateBlocksToLocation(space, {
                        templateBlocks: template.blocks,
                        location,
                        targetYBlocks,
                    });
                } else {
                    performActionWithBlocks(SagaLocation.pageLocationFromId(template.id), (templateYBlocks) => {
                        SpaceOperations.applyTemplateBlocksToLocation(space, {
                            templateBlocks: templateYBlocks.toJSON(),
                            location,
                            targetYBlocks,
                        });
                    });
                }
            });
        },
        [performActionWithBlocks, location, space, task],
    );

    const customTemplates = React.useMemo(
        () => SpaceOperations.getPages(space, templatePageKeys, 'templates', hasAccess),
        [space, hasAccess],
    );

    const recentlyUsedTemplateIds = React.useMemo(() => SpaceOperations.getRecentlyUsedTemplateIds(space), [space]);

    const isTitleEmpty = React.useMemo(
        () =>
            EditorOperations.SagaElement.toString(element, blockPlugins).length === 0 &&
            element.children.every((child) => !isVoid(child)),
        [element, blockPlugins],
    );

    const i18nLanguageMapper = useMemo(() => {
        const detectedLanguage = i18n.language.toLocaleLowerCase();

        if (detectedLanguage.includes('fr')) {
            return '125px';
        } else if (detectedLanguage.includes('es')) {
            return '100px';
        } else if (detectedLanguage.includes('pt')) {
            return '125px';
        }
        return '75px';
    }, [i18n.language]);

    if (isTitleEmpty) {
        children = (
            <div
                data-testid="title-placeholder"
                className="inline-flex placeholder"
                data-content={t('tasks.new_task_title_placeholder')}
                data-font-weight="semibold"
            >
                {children}
            </div>
        );
    }

    return (
        <div
            className={classNames({
                'pt-1': task?.archivedAt,
                'pt-2': !task?.archivedAt,
            })}
        >
            {!task?.archivedAt && (
                <div className="h-8 flex items-center group" contentEditable={false}>
                    {isEmptyPage(editor) && (
                        <div
                            className={classNames('transition-opacity', {
                                'opacity-0 group-hover:opacity-100': !isTemplatePopoverOpen,
                            })}
                        >
                            <Button.DashedPill
                                ref={templateButtonRef}
                                onClick={() => {
                                    ReactEditor.blur(editor);
                                    setIsTemplatePopoverOpen(true);
                                }}
                            >
                                <div className="flex space-x-1 items-center h-5">
                                    <span>{t('common.plus_template')}</span>
                                </div>
                            </Button.DashedPill>
                        </div>
                    )}
                    {isTemplatePopoverOpen && (
                        <TemplatesPopOver
                            onExploreTemplatesClick={() => {
                                setTemplatesPickerOpen(true);
                            }}
                            onCreateTemplate={async (title, event) => {
                                const targetPage = createPage(
                                    space,
                                    newBlankPage({ title, isTemplate: true }),
                                    provider,
                                );
                                openPage(targetPage.id, event);
                            }}
                            onApplyCustomTemplate={performApplyTemplateToTask}
                            onApplyPredefinedTemplate={performApplyTemplateToTask}
                            recentlyUsedTemplateIds={recentlyUsedTemplateIds}
                            customTemplates={customTemplates}
                            onClose={() => setIsTemplatePopoverOpen(false)}
                            isOpen
                            attachToRef={templateButtonRef}
                            inputProps={{
                                placeholder: t('common.add_template_title') as string,
                                title: t('common.add_template_placeholder') as string,
                            }}
                        />
                    )}
                </div>
            )}
            <div className={`w-full flex select-none space-x-2 items-start`}>
                <div contentEditable={false} className="pt-2">
                    <TaskStatusSelect
                        size="medium"
                        status={task.state}
                        onChange={(status) => {
                            track('change-task-state', { state: status, source: 'task-page' });

                            SpaceOperations.updatePartialTask(space, task.id, {
                                state: status,
                                completedDate: status === 'DONE' ? new Date().toISOString() : null,
                            });
                        }}
                        disabled={!canEdit}
                    />
                </div>
                <h1
                    id="title-element"
                    data-testid="task-title"
                    className="min-w-0 select-text inline sm:text-4xl text-[28px] leading-snug sm:leading-[1.3] font-bold break-word"
                >
                    {children}
                </h1>
            </div>
            <WithExplicitFontType type="interface">
                <div className="py-3 flex justify-start mb-3 border-b border-gray-light dark:border-zinc-600">
                    <div
                        contentEditable={false}
                        style={{
                            gridTemplateColumns: `${i18nLanguageMapper} 1fr`,
                        }}
                        className="grid select-none text-sm justify-start items-center gap-y-1.5 gap-x-5"
                    >
                        <div className="text-saga-gray-500">{t('tasks.status')}</div>
                        <div className="h-8 flex items-center">
                            <TaskStatusSelect
                                onChange={(status) => {
                                    track('change-task-state', { state: status, source: 'task-page' });

                                    SpaceOperations.updatePartialTask(space, task.id, {
                                        state: status,
                                        completedDate: status === 'DONE' ? new Date().toISOString() : null,
                                    });
                                }}
                                status={task.state}
                                showLabel
                            />
                        </div>
                        <div className="text-saga-gray-500">{t('tasks.assignee')}</div>
                        <div className="flex items-center gap-3">
                            <div className="h-8 flex items-center">
                                <AssigneeSelect
                                    disabled={!canEdit}
                                    assignee={task.assignee}
                                    onChange={async (assignee) => {
                                        if (assignee.action === api.TaskAssignAction.Unassign) {
                                            track('unassign-assignee-from-task', { source: 'task-page' });
                                        } else {
                                            track('assign-assignee-to-task', { source: 'task-page' });
                                        }
                                        SpaceOperations.updatePartialTask(space, task.id, {
                                            assignee:
                                                assignee.action === api.TaskAssignAction.Assign ? assignee.id : null,
                                        });
                                        if (assignee.id && assignee.id !== currentUser?.uid) {
                                            await sendTaskAssignmentNotification({
                                                variables: {
                                                    input: {
                                                        urlKey,
                                                        taskId: task.id,
                                                        memberId: assignee.id,
                                                        action: assignee.action,
                                                    },
                                                },
                                            });
                                        }
                                    }}
                                />
                            </div>
                        </div>
                        <div className="text-saga-gray-500">{t('tasks.due_date')}</div>
                        <div className="h-8 flex items-center">
                            <DueDateSelect
                                task={task}
                                onChange={(date) => {
                                    if (date) {
                                        track('change-task-due-date', { source: 'task-page' });
                                    } else {
                                        track('remove-task-due-date', { source: 'task-page' });
                                    }

                                    SpaceOperations.updatePartialTask(space, task.id, {
                                        dueDate: date?.toDateString() ?? null,
                                    });
                                }}
                                disabled={!canEdit}
                            />
                        </div>
                        <div className="text-saga-gray-500">{t('tasks.priority')}</div>
                        <div className="h-8 flex items-center">
                            <PrioritySelect
                                onChange={(priority) => {
                                    if (priority == null) {
                                        track('remove-task-priority', { source: 'task-page' });
                                    } else {
                                        track('change-task-priority', { source: 'task-page' });
                                    }
                                    SpaceOperations.updatePartialTask(space, task.id, {
                                        priority: typeof priority === 'string' ? priority : null,
                                    });
                                }}
                                assignedPriority={task.priority}
                            />
                        </div>
                        <div className="text-saga-gray-500">{t('settings.labels.title')}</div>
                        <div className="flex items-center min-h-8">
                            <LabelSelect
                                availableLabels={taskLabels ?? []}
                                selectedIds={task.labels ?? []}
                                onSelectLabel={(id) => {
                                    track('select-task-label', { source: 'task-page' });
                                    SpaceOperations.updatePartialTask(space, task.id, {
                                        labels: [...new Set([...(task.labels ?? []), id])],
                                    });
                                }}
                                onRemoveLabel={(id) => {
                                    track('remove-task-label', { source: 'task-page' });
                                    SpaceOperations.updatePartialTask(space, task.id, {
                                        labels: (task.labels ?? []).filter((label) => label !== id),
                                    });
                                }}
                                onCreateLabel={(title) => {
                                    track('create-task-label', { source: 'task-page' });
                                    const id = SpaceOperations.addTaskLabel(space, title, TaskLabelColors[0]);
                                    SpaceOperations.updatePartialTask(space, task.id, {
                                        labels: [...new Set([...(task.labels ?? []), id])],
                                    });
                                }}
                                onSelectColor={(id, color) => {
                                    track('change-task-label-color', { source: 'task-page' });
                                    SpaceOperations.updatePartialTaskLabel(space, id, { color });
                                }}
                                disabled={!canEdit}
                                showEmptyState={true}
                            />
                        </div>
                        <div className="text-saga-gray-500">{t('tasks.createdAt')}</div>
                        <div className="h-8 flex items-center">
                            <Calendar className="h-4 w-4 inline-block align-text-bottom select-none" />
                            <span className="ml-1">{formatTaskDate(task.createdAt)}</span>
                        </div>
                        <div className="text-saga-gray-500">{t('tasks.updatedAt')}</div>
                        <div className="h-8 flex items-center">
                            <Calendar className="h-4 w-4 inline-block align-text-bottom select-none" />
                            <span className="ml-1">{formatTaskDate(task.updatedAt)}</span>
                        </div>
                        {task.state === 'DONE' && task.completedDate && (
                            <>
                                <div className="text-saga-gray-500">{t('tasks.completedDate')}</div>
                                <div className="h-8 flex items-center">
                                    <Calendar className="h-4 w-4 inline-block align-text-bottom select-none" />
                                    <span className="ml-1">{formatTaskDate(task.completedDate)}</span>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </WithExplicitFontType>
        </div>
    );
}

export const spaceTitlePlugin = SagaEditor.Plugins.createBlockPlugin({
    match: isTitle,
    normalizers: [SagaEditor.Normalizers.titleNormalizer],
    Component(props) {
        const { location } = SagaEditor.useEditorContext();

        switch (location.type) {
            case 'page': {
                return (
                    <PageTitle location={location} {...props}>
                        {props.children}
                    </PageTitle>
                );
            }
            case 'task': {
                return (
                    <TaskTitle location={location} {...props}>
                        {props.children}
                    </TaskTitle>
                );
            }
        }
    },
});
