import React, { useMemo } from 'react';
import { MoreHorizontal } from 'react-feather';
import { BaseRange, NodeEntry } from 'slate';
import StaticEditor from '../editor/StaticEditor';
import ReferenceResultCard from './ReferenceResultCard';
import classNames from 'classnames';
import useIntersection from '@/hooks/useOnIntersect';
import {
    assertYMap,
    BlockBuilder,
    isTitle,
    SagaElement,
    SagaLocation,
    SagaText,
    Search,
    SpaceOperations,
    WeakPage,
    WeakTask,
} from '@saga/shared';
import useStaticDecorate from '../editor/decorator/useStaticDecorate';
import highlightDecorate from '../editor/decorator/highlightDecorate';
import { useIsSearchingReferences } from '../editor/ReferenceResults';
import { useSpace } from '@/components/SpaceProvider';

import * as R from 'ramda';
import { FindInLocationsResult } from '@/../../shared/src/SpaceOperations/findInLocations';
import { BlocksLocation, isPageLocation, PageLocation, TaskLocation } from '@/../../shared/src/SagaLocation';
import { BlockPlugin } from '@/../../shared/src/Editor/Plugins';
import { useBlockPlugins } from '@/components/BlockPluginProvider';
import { useOpenLocation } from '../PageNavigationProvider';
import { compactPrettyLinkBlockPlugin } from '../editor/plugins/PrettyLink';
import invariant from 'tiny-invariant';
import _, { isEqual } from 'lodash';
import { useTranslation } from 'react-i18next';
import { WithExplicitFontType } from '@/components/WithExplicitFontType';
import { Lightning } from '@/assets/icons';
import Tooltip from '../popover/Tooltip';
import { usePerformAppendBlock, usePerformBlockChangeWithEditor } from '@/components/RealtimeDocumentProvider';
import { usePageAccess } from '@/components/PagesPermissionsBySpaceProvider';

const LIST_LIMIT = 30;

function ListObserver({ onIntersect }: { onIntersect(): void }) {
    const ref = useIntersection<HTMLDivElement>(onIntersect);
    return <div ref={ref}></div>;
}

function DraggableWrapper({
    location,
    children,
    blockId,
}: {
    blockId: string;
    location: BlocksLocation;
    children: React.ReactNode;
}) {
    if (isPageLocation(location)) {
        return (
            <ReferenceResultCard.DraggableBlockContainer blockId={blockId} origin={location}>
                {children}
            </ReferenceResultCard.DraggableBlockContainer>
        );
    }

    return <>{children}</>;
}

function ReferenceResultRow({
    location,
    canEdit,
    onAddToPage,
    onClick,
    blocks,
    decorate,
    blockPlugins,
    isLastElement,
    hasMoreResults,
}: {
    blocks: SagaElement[];
    location: BlocksLocation;
    canEdit: boolean;
    onAddToPage?: () => void;
    onClick(event: React.MouseEvent): void;
    decorate?: (entry: NodeEntry<SagaElement | SagaText>) => BaseRange[];
    blockPlugins: BlockPlugin[];
    isLastElement?: boolean;
    hasMoreResults?: boolean;
}) {
    const staticBlockPlugins = React.useMemo(() => [compactPrettyLinkBlockPlugin, ...blockPlugins], [blockPlugins]);
    const [memoizedBlocks, setMemoizedBlocks] = React.useState<SagaElement[]>();

    React.useEffect(() => {
        setMemoizedBlocks((memoizedBlocks) => (isEqual(memoizedBlocks, blocks) ? memoizedBlocks : blocks));
    }, [blocks]);

    if (!memoizedBlocks) return null;

    return (
        <div data-testid="reference-result-block-row">
            <DraggableWrapper blockId={memoizedBlocks[0].id} location={location}>
                <ReferenceResultCard.Block isLastElement={isLastElement}>
                    <ReferenceResultCard.Menu canEdit={canEdit} onAddToPage={onAddToPage} />
                    <div onClick={onClick} onMouseDownCapture={(e) => e.stopPropagation()}>
                        <StaticEditor
                            className="transform hover:text-saga-gray-500 dark:hover:text-zinc-400 hover:stroke-saga-gray-500 cursor-pointer select-none"
                            blocks={memoizedBlocks}
                            decorate={decorate}
                            location={location}
                            blockPlugins={staticBlockPlugins}
                        />
                    </div>
                </ReferenceResultCard.Block>
            </DraggableWrapper>
            {hasMoreResults && <MoreHorizontal className="stroke-saga-gray-500 mx-auto" size={12} />}
        </div>
    );
}

function TaskResultsCard({
    task,
    results,
    location,
    onClick,
    onBlockClick,
    blockPlugins,
    decorate,
}: {
    task: WeakTask;
    results: FindInLocationsResult[];
    location: TaskLocation;
    onClick(event: React.MouseEvent): void;
    onBlockClick(block: SagaElement, event: React.MouseEvent): void;
    blockPlugins: BlockPlugin[];
    decorate(nodeEntry: NodeEntry<SagaElement | SagaText>, values: string[]): BaseRange[];
}) {
    const { canEdit } = usePageAccess(task.id);

    const title = task.title ?? 'Undefined';

    return (
        <ReferenceResultCard title={title}>
            <ReferenceResultCard.Header>
                <ReferenceResultCard.TaskButton task={task} onClick={onClick}>
                    {title}
                </ReferenceResultCard.TaskButton>
            </ReferenceResultCard.Header>

            {results.map(({ result, blocks: allBlocks }, i) => {
                const topLevelBlock = allBlocks[result.path[0]];

                let blocks = [topLevelBlock as SagaElement];

                if (result.path[1]) {
                    for (let index = result.path[0] + 1; index <= result.path[1]; index++) {
                        const bottomLevelBlock = blocks[index];
                        if (bottomLevelBlock) {
                            assertYMap(bottomLevelBlock);
                            blocks = [...blocks, bottomLevelBlock.toJSON() as SagaElement];
                        }
                    }
                }

                if (result.type === 'block') {
                    return (
                        <ReferenceResultRow
                            key={`${blocks[0].id}-${i}`}
                            blocks={blocks}
                            blockPlugins={blockPlugins}
                            canEdit={canEdit}
                            location={location}
                            onClick={(event) => onBlockClick(blocks[0], event)}
                        />
                    );
                }

                return (
                    <ReferenceResultRow
                        key={`${blocks[0].id}-${i}`}
                        blocks={blocks}
                        blockPlugins={blockPlugins}
                        canEdit={canEdit}
                        location={location}
                        onClick={(event) => onBlockClick(blocks[0], event)}
                        decorate={(entry) =>
                            decorate(
                                entry,
                                result.matches.map(({ value }) => value),
                            )
                        }
                    />
                );
            })}
        </ReferenceResultCard>
    );
}

function PageResultsCard({
    page,
    currentLocation,
    location,
    results,
    blockPlugins,
    isSearchResults,
    onClick,
    onBlockClick,
    decorate,
}: {
    page: WeakPage;
    results: FindInLocationsResult[];
    location: PageLocation;
    currentLocation: SagaLocation.SagaLocation;
    isSearchResults?: boolean;
    onClick(event: React.MouseEvent): void;
    onBlockClick(block: SagaElement, event: React.MouseEvent): void;
    blockPlugins: BlockPlugin[];
    decorate(nodeEntry: NodeEntry<SagaElement | SagaText>, values: string[]): BaseRange[];
}) {
    const { space } = useSpace();

    const { canEdit } = usePageAccess(SagaLocation.getIdFromLocation(currentLocation));

    const lastElement = useMemo(() => results[results.length - 1], [results]);

    const isEmbeding = results.some(({ result }) => result.type === 'block');
    const performAppendBlock = usePerformAppendBlock();
    const performBlockChangeWithEditor = usePerformBlockChangeWithEditor();

    return (
        <ReferenceResultCard title={page.title}>
            <ReferenceResultCard.Header>
                <div className="flex flex-row items-start justify-between">
                    <ReferenceResultCard.PageButton
                        icon={page.icon}
                        onClick={onClick}
                        isTemplate={page.isTemplate}
                        isArchived={page.archivedAt != null}
                    >
                        {page.title}
                    </ReferenceResultCard.PageButton>
                    {isEmbeding && isSearchResults && (
                        <Tooltip placement="left" content="This result is AI powered.">
                            <div className="mt-1 h-5 text-saga-gray-500 hover:text-saga-text dark:hover:text-saga-text-darkmode">
                                <Lightning className="w-[18px] h-[18px] shrink-0 mr-1" />
                            </div>
                        </Tooltip>
                    )}
                </div>
            </ReferenceResultCard.Header>
            {results.map(({ result, blocks: allBlocks }, i) => {
                const topLevelBlock = allBlocks[result.path[0]];

                let blocks = [topLevelBlock as SagaElement];

                const isLast = lastElement.result.blockId === result.blockId;

                if (result.path[1]) {
                    for (let index = result.path[0] + 1; index <= result.path[1]; index++) {
                        const bottomLevelBlock = blocks[index];
                        if (bottomLevelBlock) {
                            assertYMap(bottomLevelBlock);
                            blocks = [...blocks, bottomLevelBlock.toJSON() as SagaElement];
                        }
                    }
                }

                function appendAsLiveBlock() {
                    invariant(
                        isPageLocation(currentLocation),
                        'Append As Live Block only works when being in a page location',
                    );

                    const [block, path] = [blocks[0], [result.path[0]]];

                    if (isTitle(block)) {
                        const inlinePageLink = SpaceOperations.createInlinePageLink(space, location.pageId, [block]);
                        performAppendBlock(space, currentLocation, [BlockBuilder.paragraph([inlinePageLink])]);
                    } else {
                        performBlockChangeWithEditor(space, location, (editor) => {
                            const liveBlock = SpaceOperations.createLiveBlock(space, {
                                editor,
                                origin: location,
                                at: path,
                            });
                            performAppendBlock(space, currentLocation, [liveBlock]);
                        });
                    }
                }

                if (result.type === 'block') {
                    return (
                        <ReferenceResultRow
                            key={`${blocks[0].id}-${i}`}
                            blocks={blocks}
                            blockPlugins={blockPlugins}
                            canEdit={canEdit}
                            location={location}
                            onClick={(event) => onBlockClick(blocks[0], event)}
                            onAddToPage={isPageLocation(currentLocation) ? appendAsLiveBlock : undefined}
                            isLastElement={isLast}
                        />
                    );
                }

                return (
                    <ReferenceResultRow
                        key={`${blocks[0].id}-${i}`}
                        blocks={blocks}
                        blockPlugins={blockPlugins}
                        canEdit={canEdit}
                        location={location}
                        onClick={(event) => onBlockClick(blocks[0], event)}
                        decorate={(entry) =>
                            decorate(
                                entry,
                                result.matches.map(({ value }) => value),
                            )
                        }
                        onAddToPage={isPageLocation(currentLocation) ? appendAsLiveBlock : undefined}
                        isLastElement={isLast}
                    />
                );
            })}
        </ReferenceResultCard>
    );
}

function ReferenceResultsList({
    referenceResults,
    matchMode,
    currentLocation,
    isSearchResults,
}: {
    referenceResults: FindInLocationsResult[];
    matchMode: Search.MatchMode;
    currentLocation: SagaLocation.SagaLocation;
    isSearchResults?: boolean;
}) {
    const blockPlugins = useBlockPlugins();
    const staticDecorate = useStaticDecorate(currentLocation);
    const [limit, setLimit] = React.useState(LIST_LIMIT);
    const isSearchingBlocks = useIsSearchingReferences();
    const openLocation = useOpenLocation();

    const groupedByLocation = useMemo(
        () =>
            R.groupWith(
                (a, b) =>
                    SagaLocation.areLocationsEqual(a.location, b.location) &&
                    !!a.location.blockOffset === !!b.location.blockOffset,
                referenceResults,
            ).slice(0, limit),
        [referenceResults, limit],
    );

    const sorted = useMemo(
        () => _.orderBy(groupedByLocation, (a) => new Date(a[0].updatedAt), 'desc'),
        [groupedByLocation],
    );

    function increaseLimit() {
        setLimit((limit) => limit + LIST_LIMIT);
    }

    function decorate(nodeEntry: NodeEntry<SagaElement | SagaText>, values: string[]): BaseRange[] {
        const highlightDecorationRanges = highlightDecorate(nodeEntry, values, undefined, matchMode);

        // This will decorate all mentions (autolinks)
        const staticDecorationRanges = staticDecorate(nodeEntry);

        return [...highlightDecorationRanges, ...staticDecorationRanges];
    }

    return (
        <ReferenceResultsList.Container isSearching={isSearchingBlocks}>
            <WithExplicitFontType type="editor">
                {sorted.map((results, index) => {
                    const firstResult = results[0];
                    const isLastItem = index === groupedByLocation.length - 1;

                    const resultsWithoutTitles = results.filter(({ result, blocks }) => {
                        const topLevelIndex = result.path[0];
                        const topLevelBlock = blocks[topLevelIndex];
                        if (!topLevelBlock) {
                            return false;
                        }

                        return !isTitle(result);
                    });

                    if (firstResult.type === 'task') {
                        const location = firstResult.location;
                        const task = firstResult.task;

                        return (
                            <React.Fragment key={index}>
                                {isLastItem && <ListObserver onIntersect={increaseLimit} />}
                                <TaskResultsCard
                                    task={task}
                                    location={location}
                                    blockPlugins={blockPlugins}
                                    decorate={decorate}
                                    onBlockClick={(block, event) => {
                                        openLocation({ ...location, blockId: block.id }, event);
                                    }}
                                    onClick={(event) => openLocation(location, event)}
                                    results={resultsWithoutTitles}
                                />
                            </React.Fragment>
                        );
                    }

                    if (firstResult.type === 'page') {
                        const location = firstResult.location;
                        const page = firstResult.page;

                        return (
                            <React.Fragment key={index}>
                                {isLastItem && <ListObserver onIntersect={increaseLimit} />}
                                <PageResultsCard
                                    page={page}
                                    location={location}
                                    currentLocation={currentLocation}
                                    blockPlugins={blockPlugins}
                                    decorate={decorate}
                                    onBlockClick={(block, event) =>
                                        openLocation({ ...location, blockId: block.id }, event)
                                    }
                                    onClick={(event) => openLocation(location, event)}
                                    results={resultsWithoutTitles}
                                    isSearchResults={isSearchResults}
                                />
                            </React.Fragment>
                        );
                    }

                    return null;
                })}
            </WithExplicitFontType>
        </ReferenceResultsList.Container>
    );
}

ReferenceResultsList.EmptyState = function BlockSearchResultsListEmptyState() {
    const { t } = useTranslation();
    return <div className="text-sm py-4 text-center">{t('references.no_results')}</div>;
};

ReferenceResultsList.EmptyStateSearchPanel = function BlockSearchResultsListEmptyState() {
    const { t } = useTranslation();
    return <div className="text-sm py-4 text-center">{t('references.no_results_search_panel')}</div>;
};

ReferenceResultsList.Container = function BlockSearchResultsListContainer({
    children,
    isSearching,
}: {
    children: React.ReactNode;
    isSearching: boolean;
}) {
    return (
        <div
            className={classNames('space-y-1 overflow-y-auto pb-24 hide-scrollbar', {
                'opacity-40': isSearching,
            })}
        >
            {children}
        </div>
    );
};

export default ReferenceResultsList;
