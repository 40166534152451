import { Editor } from 'slate';
import { BlockPlugin } from '../Editor/Plugins';
import { Extensions } from '../EditorOperations';
import normalizeNode from './normalizeNode';
import { appendEmptyParagraph, baseNormalizers, Normalizer } from './Normalizers';
import * as R from 'ramda';

// extend base slate editor with normalization logic
const extendEditorForNormalization = (
    editor: Editor,
    blockPlugins: BlockPlugin[] = [],
    normalizers: Normalizer[] = [],
) => {
    editor.isVoid = Extensions.isVoid(editor);
    editor.isInline = Extensions.isInline(editor);

    // Make sure to remove unnecessary normalizers if they are actually the same function
    const pluginNormalizers = R.uniq(blockPlugins.map(({ normalizers }) => normalizers ?? []).flat());
    editor.normalizeNode = normalizeNode(editor, [
        ...baseNormalizers,
        appendEmptyParagraph,
        ...pluginNormalizers,
        ...normalizers,
    ]);

    return editor;
};

export default extendEditorForNormalization;
