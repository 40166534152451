import { DateTime } from 'luxon';
import i18next from 'i18next';

export function formatDateBlockDate(dateTime: DateTime, locale = 'en') {
    return dateTime.setLocale(locale).toFormat('LLLL d, yyyy');
}

export function formatDateWithWeekday(dateTime: DateTime) {
    return DateTime.local().hasSame(dateTime, 'year')
        ? dateTime.toFormat('EEEE, LLLL d')
        : dateTime.toFormat('EEEE, LLLL d, yyyy');
}

export function isPast(date: Date) {
    return DateTime.fromJSDate(date).startOf('day') < DateTime.local().startOf('day');
}

export function isToday(date: Date) {
    return DateTime.fromJSDate(date).startOf('day').equals(DateTime.local().startOf('day'));
}

export function formatTaskDate(date: Date | string, showMinutes = true) {
    const format = DateTime.fromJSDate(new Date(date)).hasSame(DateTime.fromJSDate(new Date()), 'year')
        ? `LLL d${showMinutes ? ', HH:mm' : ''}`
        : `LLL d yyyy${showMinutes ? ', HH:mm' : ''}`;
    return DateTime.fromJSDate(new Date(date)).setLocale(i18next.language).toFormat(format);
}
