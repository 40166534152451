import React, { useState, useRef } from 'react';
import Button from '../styled/Button';
import Dropdown, { useArrowSelectableIndex } from '@/components/popover/Dropdown';
import { ChevronDown, File, Trash, Users, FileText } from 'react-feather';
import { TableIcon } from '@/components/icons/Table';
import { track } from '@/analytics';
import { TaskView } from '@saga/shared';

type TaskTableViewDropdownProps = {
    selectedView: TaskView;
    onSelectView: (view: TaskView, event: Event | React.SyntheticEvent) => void;
    taskViews: TaskView[];
};

export default function TaskTableViewDropdown({ selectedView, onSelectView, taskViews }: TaskTableViewDropdownProps) {
    const [viewMenuOpen, setViewMenuOpen] = useState(false);
    const buttonRef = useRef(null);

    const renderIconForView = (view: TaskView) => {
        return <FileText size={14} />;
    };

    function onSelect(index: number, event: Event | React.SyntheticEvent) {
        if (!viewMenuOpen) return;

        const viewOption = taskViews[index];

        if (viewOption) {
            track(`tasks-table-view-change`, { value: viewOption.id });
            onSelectView(viewOption, event);
            setViewMenuOpen(false);
        }
    }

    const view = React.useMemo(() => taskViews.find((view) => view.id === selectedView.id), [selectedView, taskViews]);

    const { index, setIndex } = useArrowSelectableIndex({
        onSelect,
        maxIndex: taskViews.length - 1,
        active: viewMenuOpen,
        initialIndex: view ? taskViews.indexOf(view) ?? 0 : 0,
    });

    return (
        <>
            <button
                data-testid="pages-mode-dropdown"
                className="shrink-0 hover-trigger px-2 py-1.5 flex h-8 items-center rounded font-semibold text-xs leading-normal border dark:bg-inherit hover:bg-saga-gray-200 dark:hover:bg-saga-gray-700 border-saga-gray-200 dark:border-saga-gray-700 cursor-pointer shadow-button space-x-1.5"
                ref={buttonRef}
                onClick={() => setViewMenuOpen(!viewMenuOpen)}
            >
                <span>{renderIconForView(selectedView)}</span>
                <span>{view?.title}</span>
                <ChevronDown size={14} />
            </button>

            <Dropdown
                isOpen={viewMenuOpen}
                attachToRef={buttonRef}
                onClose={() => {
                    setViewMenuOpen(false);
                }}
                position="below"
            >
                {taskViews.map((option, i) => (
                    <Button.PopOverButton
                        key={i}
                        selected={i === index}
                        active={selectedView.id === option.id}
                        onMouseEnter={() => setIndex(i)}
                        onClick={(e) => onSelect(i, e)}
                        type="button"
                    >
                        <div className="pr-2">{renderIconForView(option)}</div>
                        {option.title}
                    </Button.PopOverButton>
                ))}
            </Dropdown>
        </>
    );
}
