import React from 'react';
import classNames from 'classnames';

type ToggleProps = {
    checked: boolean;
    onChange(checked: boolean): void;
    accessibleLabel?: string;
    disabled?: boolean;
    testId?: string;
};
export default function Toggle({ checked, onChange, accessibleLabel, disabled, testId }: ToggleProps) {
    return (
        <label
            className={classNames('block border-2 relative rounded-full cursor-pointer', {
                'bg-saga-shade-700 border-saga-shade-700 dark:bg-saga-shade-200 dark:border-saga-shade-200': checked,
                'border-saga-new-lightest bg-saga-shade-200 dark:bg-saga-shade-400 dark:border-saga-shade-400':
                    !checked,
                'cursor-default': disabled,
            })}
            style={{ width: 30, height: 20 }}
        >
            <span className="sr-only">{accessibleLabel}</span>
            <input
                className={classNames('w-full h-full appearance-none absolute inset-0 z-30 cursor-pointer', {
                    'cursor-default': disabled,
                })}
                type="checkbox"
                checked={checked}
                onChange={(event) => onChange(event.target.checked)}
                disabled={disabled}
                data-testid={testId}
            />
            <div className="relative">
                <div
                    className={classNames(
                        'z-10 rounded-full h-3 w-3 absolute transition-transform duration-100 ease-in-out',
                        {
                            'bg-saga-shade-200 dark:bg-saga-shade-700': checked,
                            'bg-saga-shade-300 dark:bg-saga-shade-200': !checked,
                        },
                    )}
                    style={{ transform: checked ? 'translateX(12px)' : 'translateX(2px)', top: 2 }}
                ></div>
            </div>
        </label>
    );
}
