import { stringToColor } from '@/../../shared/src/utils/Colors';
import { track } from '@/analytics';
import GoogleDriveSvg from '@/assets/GoogleDriveSvg';
import LinearIcon from '@/assets/LinearIcon';
import { CollectionIcon, PageIcon } from '@/components/icons';
import { useSpace } from '@/components/SpaceProvider';
import { AVAILABLE_COLORS } from '@/constants';
import { getSdk } from '@/graphql';
import { usePartialPages } from '@/hooks/SpaceHooks';
import {
    collectionToSuggestion,
    filterMembers,
    filterPageSuggestions,
    filterTaskSuggestions,
    getDateSuggestions,
    getFormatTypes,
    getSearchRegex,
    hasParentOrCurrentNode,
    makeCreatePageSuggestion,
    makeTaskCreateSuggestion,
    pageToInlinePageLinkSuggestion,
    pageToPageSuggestion,
    pageToRecentPageSuggestion,
    taskToSuggestion,
} from '@/lib/Suggestions';
import {
    AICommandSuggestion,
    BlocksPastedSuggestion,
    CollectionSuggestion,
    CreatePageSuggestion,
    CreateSuggestion,
    DateBlockSuggestion,
    FormatSuggestion,
    GoogleDriveFileSuggestion,
    InlinePageLinkSuggestion,
    InsertIntegrationCommands,
    InsertIntegrationSuggestions,
    LinearIssueSuggestion,
    Member,
    MemberSuggestion,
    PageSuggestion,
    RecentPageSuggestion,
    ShareSuggestion,
    SimplePageForSuggestion,
    TableCommandSuggestion,
    TaskSuggestion,
} from '@/types';
import * as Popover from '@radix-ui/react-popover';
import * as api from '@saga/api';
import {
    assertNonNull,
    BlockBuilder,
    BlockType,
    Code,
    Collection,
    SagaEditor,
    EditorOperations,
    flatMap,
    googleDriveRegex,
    Image,
    isBlockType,
    isCallout,
    isCode,
    isImage,
    isInline,
    isLiveBlock,
    isMention,
    isMovableContainerElement,
    isSagaElement,
    isTable,
    isTableCell,
    isTitle,
    linearIssueUrl,
    newBlankPage,
    SafeSpace,
    SagaElement,
    SagaLocation,
    SpaceOperations,
    WeakTask,
    isTaskBlock,
    RealtimeSagaEditor,
    WeakPage,
} from '@saga/shared';
import _, { debounce } from 'lodash';
import { Properties } from 'posthog-js';
import React, { useEffect } from 'react';
import { Calendar, CheckCircle, Edit3, Lock, Search, Link } from 'react-feather';
import {
    Editor,
    Editor as SlateEditor,
    Location,
    Node,
    NodeEntry,
    Range,
    RangeRef,
    Transforms,
    BaseRange,
} from 'slate';
import { ReactEditor, useSlateStatic } from 'slate-react';
import { v4 as uuid } from 'uuid';
import ItemGroups from '../ItemGroups';
import Spinner from '../loading/Spinner';
import MemberAvatar from '../MemberAvatar';
import { getFullMemberName, useMembers } from '../MembersProvider';
import { useArrowSelectableIndex } from '../popover/Dropdown';
import { PopOver } from '../popover/PopOver';
import Tooltip from '../popover/Tooltip';
import { searchIntegrations } from '../search/useSearchQuery';
import Button from '../styled/Button';
import FormatIcon from '../styled/FormatIcon';
import { taskTitleEditors } from '../tasks/TaskTitleEditor';
import { useToastContext } from '../toast/ToastContext';
import { SimpleTitleToast } from '../toast/ToastLibrary';
import { OpacityTransition } from '../Transitions';
import { useUserContext } from '../UserContext';
import { openDateBlockPicker } from './plugins/DateBlock';
import { OPEN_KATEX_EDITOR_SET } from './plugins/Katex';
import { useQuickEditorSelectionOverflow } from './QuickPageEditor';
import useTrigger, { Trigger, TriggerState } from './useTrigger';
import useOnTextEvent from '@/components/editor/useOnTextEvent';
import { useSetAIPopoverOpen } from '@/components/editor/popover/AIPopoverProvider';
import RangeAnchor from '@/components/editor/RangeAnchor';
import { Lightning } from '@/assets/icons';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import useMobile from '@/hooks/useMobile';
import { useOpenTask } from '../PageNavigationProvider';
import { useRecents } from '../RecentsContext';
import { getRecentPageSuggestions } from './popover/HoveringToolbar';
import LinkToPagePopOver from '../popover/LinkToPagePopOver';
import { TFunction } from 'i18next';
import { createTask, createPage } from '@/utils/documentUtils';
import { usePerformActionWithYBlocks, usePerformBlockChangeWithEditor } from '@/components/RealtimeDocumentProvider';
import * as Y from 'yjs';
import { usePagesPermissions } from '@/components/PagesPermissionsBySpaceProvider';
import { HocuspocusProvider } from '@hocuspocus/provider';
import { VariableSizeList } from 'react-window';
import { useTaskFilters } from '@/hooks/useTaskFilters';

function filterByNonEmptyTitle(page: SimplePageForSuggestion): boolean {
    return page.title.trim() !== '';
}

function SuggestionsModalContainer({
    isOpen,
    children,
    containerRef,
    range,
    onClose,
    hidden,
    includeCard,
}: {
    isOpen: boolean;
    children: React.ReactNode;
    containerRef: React.RefObject<HTMLElement> | null;
    range: Range | null;
    onClose(): void;
    hidden?: boolean;
    includeCard?: boolean;
}) {
    return (
        <Popover.Root
            open={isOpen}
            onOpenChange={(open) => {
                if (!open) {
                    onClose();
                }
            }}
        >
            {isOpen && <RangeAnchor range={range} containerRef={containerRef} />}
            <Popover.Portal>
                <Popover.Content
                    onOpenAutoFocus={(e) => e.preventDefault()}
                    onFocusOutside={(e) => e.preventDefault()}
                    align="start"
                    collisionPadding={-50}
                    className="z-100"
                    avoidCollisions={false}
                >
                    <OpacityTransition isOpen={isOpen && !hidden} duration={100}>
                        {includeCard ? (
                            <div className="relative">{children}</div>
                        ) : (
                            <PopOver.Card>{children}</PopOver.Card>
                        )}
                    </OpacityTransition>
                </Popover.Content>
            </Popover.Portal>
        </Popover.Root>
    );
}

export function scrollToSelectedIndex(containerEl: HTMLDivElement) {
    const containerRect = containerEl.getBoundingClientRect();
    const selected = containerEl.querySelector('[data-selected="true"]');
    if (selected) {
        const buttonRect = selected.getBoundingClientRect();
        const isVisible = containerRect.bottom > buttonRect.bottom && containerRect.top < buttonRect.top;
        if (!isVisible) {
            if (buttonRect.top - containerRect.top > 0) {
                containerEl.scrollTo({
                    top: buttonRect.top - (containerRect.top - containerEl.scrollTop),
                    behavior: 'smooth',
                });
            } else {
                containerEl.scrollTo({
                    top: buttonRect.bottom - (containerRect.bottom - containerEl.scrollTop),
                    behavior: 'smooth',
                });
            }
        }
    }
}

type Suggestion =
    | PageSuggestion
    | RecentPageSuggestion
    | CollectionSuggestion
    | CreateSuggestion
    | ShareSuggestion
    | TaskSuggestion;

function SuggestionIcon({ suggestion }: { suggestion: Suggestion }) {
    switch (suggestion.type) {
        case 'recentPage':
        case 'page': {
            return <PageIcon icon={suggestion.page.icon} size={14} isTemplate={suggestion.page.isTemplate} />;
        }
        case 'task': {
            return <CheckCircle size={14} />;
        }
        case 'collection': {
            return <CollectionIcon icon={suggestion.collection.icon} size={14} />;
        }
        case 'share': {
            switch (suggestion.kind) {
                case 'share': {
                    return <Edit3 size={14} />;
                }
                case 'access': {
                    return <Lock size={14} />;
                }
            }
        }
        case 'create': {
            switch (suggestion.kind) {
                case 'page': {
                    return <PageIcon icon={undefined} size={14} isTemplate={suggestion.isTemplate} />;
                }
                case 'collection': {
                    return <CollectionIcon icon={undefined} size={14} />;
                }
                case 'linear-issue': {
                    return <LinearIcon className="h-4 w-4 flex-shrink-0" />;
                }
                case 'task': {
                    return <CheckCircle className="h-3 w-3 flex-shrink-0" />;
                }
            }
        }
    }
}

function formatCreateSuggestionLabel(suggestion: CreateSuggestion) {
    switch (suggestion.kind) {
        case 'page':
            if (suggestion.isTemplate) {
                return i18next.t('common.create_template');
            }
            return i18next.t('editor.create_page');
        case 'collection':
            return i18next.t('editor.create_collection');
        case 'task':
            return i18next.t('editor.create_task');
        case 'linear-issue':
            return i18next.t('Issue in', { teamName: suggestion.team.name });
    }
}

export const CreateSuggestionButton = React.forwardRef<
    HTMLButtonElement,
    Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'className'> & {
        selected?: boolean;
        suggestion: CreateSuggestion;
        showIcons?: boolean;
        hoverable?: boolean;
    }
>(function CreateSuggestionButton({ selected, children, suggestion, showIcons = false, ...props }, ref) {
    return (
        <div>
            <Button.PopOverButton ref={ref} selected={selected} {...props}>
                <div className="flex items-center">
                    {showIcons && (
                        <div className="flex-none mr-2">
                            <SuggestionIcon suggestion={suggestion} />
                        </div>
                    )}
                    <p className="font-medium truncate">{children}</p>
                    <p>&nbsp;— {formatCreateSuggestionLabel(suggestion)}</p>
                </div>
            </Button.PopOverButton>
        </div>
    );
});

export const CreateSuggestionSelectButton = React.forwardRef<
    HTMLButtonElement,
    Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'className'> & {
        selected?: boolean;
        suggestion: CreatePageSuggestion | ShareSuggestion;
        showIcons?: boolean;
        hoverable?: boolean;
    }
>(function CreateSuggestionSelectButton({ selected, children, suggestion, showIcons = false, ...props }, ref) {
    return (
        <Button.PopOverButton ref={ref} selected={selected} {...props}>
            <div className="flex items-center ">
                {showIcons && (
                    <div className="flex-none mr-2">
                        <SuggestionIcon suggestion={suggestion} />
                    </div>
                )}
                <p className="font truncate -ml-0.5">{children}</p>
            </div>
        </Button.PopOverButton>
    );
});

export const SuggestionButton = React.forwardRef<
    HTMLButtonElement,
    Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'className'> & {
        selected?: boolean;
        suggestion: Suggestion;
        showIcons?: boolean;
        hoverable?: boolean;
    }
>(function SuggestionButton({ selected, children, suggestion, showIcons = false, ...props }, ref) {
    if (suggestion.type === 'create') {
        return (
            <CreateSuggestionButton selected={selected} showIcons={showIcons} suggestion={suggestion} {...props}>
                {children}
            </CreateSuggestionButton>
        );
    }

    return (
        <Button.PopOverButton ref={ref} selected={selected} {...props}>
            <div className="flex items-center min-w-0 ">
                {showIcons && (
                    <div className="flex-none mr-2">
                        <SuggestionIcon suggestion={suggestion} />
                    </div>
                )}
                <span className="my-auto truncate">{children}</span>
            </div>
        </Button.PopOverButton>
    );
});

export type LinkToPage = {
    type: 'inline-page-link';
    title: string;
};

export type SuggestionModalSuggestion =
    | AICommandSuggestion
    | FormatSuggestion
    | InlinePageLinkSuggestion
    | RecentPageSuggestion
    | MemberSuggestion
    | GoogleDriveFileSuggestion
    | DateBlockSuggestion
    | TableCommandSuggestion
    | CollectionSuggestion
    | LinearIssueSuggestion
    | TaskSuggestion
    | CreateSuggestion
    | InsertIntegrationSuggestions
    | InsertIntegrationCommands;

const trackAction: Record<SuggestionModalSuggestion['type'] | BlocksPastedSuggestion['type'], string> = {
    'ai-command': 'open-ai-popover',
    create: 'create',
    date: 'date',
    'table-command': 'table-command',
    collection: 'insert-collection-in-editor',
    'inline-page-link': 'insert-inline-page-link',
    format: 'add-block',
    recentPage: 'insert-recent-page-in-editor',
    member: 'mention-member',
    drive: 'insert-google-drive-file',
    'linear-issue': 'insert-linear-issue',
    task: 'insert-task',
    insert: 'insert',
    'turn-into-pretty-link': 'turn-link-into-pretty-link',
    'keep-as-link': 'keep-as-link',
    'turn-into-live-block': 'turn-into-live-block',
    'keep-as-text': 'keep-as-text',
    'turn-into-embed': 'turn-into-embed',
    'turn-into-google-drive': 'turn-into-google-drive',
    'turn-into-linear-issue': 'turn-into-linear-issue',
};

function formatSelection(
    editor: SlateEditor,
    item: FormatSuggestion,
    triggerState: TriggerState,
    space: SafeSpace | null,
    provider: HocuspocusProvider | null,
    taskFilters: Partial<WeakTask>,
) {
    const selection = editor.selection;

    if (!selection) return;
    const type = item.value;

    SlateEditor.withoutNormalizing(editor, () => {
        if (triggerState.rangeRef.current?.focus.offset) {
            Transforms.delete(editor, { at: triggerState.rangeRef.current });
        }

        if (type === BlockType.CODE) {
            const nodeEntries: NodeEntry<SagaElement>[] | undefined = [
                ...SlateEditor.nodes<SagaElement>(editor, {
                    at: selection,
                }),
            ];

            const block: Code = {
                id: uuid(),
                type,
                content: EditorOperations.SagaElement.fromNodeEntryToCodeContent(nodeEntries),
                children: [{ text: '' }],
                language: 'html',
                isNew: true,
            };

            Transforms.insertFragment(editor, [block]);
        } else if (type === BlockType.TASK_BLOCK) {
            if (!space || !provider) throw new Error('Not in space');

            track('create-task', { source: 'suggestions' });

            Transforms.collapse(editor, { edge: 'end' });

            const task = createTask(space, { title: '', ...taskFilters }, provider);

            const taskBlock = BlockBuilder.taskBlock(task.id, task);

            Transforms.insertFragment(editor, [taskBlock]);

            // We need to do this in a setTimeout in this case because the editor instance needs to be rendered first
            setTimeout(() => {
                const taskTitleEditor = taskTitleEditors.get(task.id);

                taskTitleEditor?.focus();
            });
        } else if (type === BlockType.IMAGE) {
            const block: Image = {
                ...BlockBuilder.image(''),
                align: 'left',
            };

            Transforms.insertFragment(editor, [block]);
        } else if (type === BlockType.KATEX_BLOCK) {
            const block = BlockBuilder.katexBlock('');
            OPEN_KATEX_EDITOR_SET.add(block.id);
            Transforms.insertFragment(editor, [block]);
        } else if (type === BlockType.KATEX_INLINE) {
            const block = BlockBuilder.katexInline('');
            OPEN_KATEX_EDITOR_SET.add(block.id);
            Transforms.insertFragment(editor, [block]);
        } else if (type === BlockType.CALLOUT) {
            const nodeEntries = [
                ...SlateEditor.nodes(editor, {
                    at: selection,
                    match: isMovableContainerElement,
                    mode: 'lowest',
                }),
            ];
            const nodes = EditorOperations.NodeEntry.toNodes(nodeEntries);
            const block = BlockBuilder.callout(nodes);

            if (!Editor.isEmpty(editor, nodes[0])) {
                Transforms.removeNodes(editor, { at: selection });
            }
            Transforms.insertFragment(editor, [block]);
        } else if (type === BlockType.TABLE) {
            const nodeEntry = SlateEditor.above(editor, {
                at: selection.focus.path,
                match: isMovableContainerElement,
                mode: 'lowest',
            });

            if (nodeEntry) {
                const [node, path] = nodeEntry;
                const block = BlockBuilder.tableByChildren([
                    BlockBuilder.tableRow([BlockBuilder.tableCell([node]), BlockBuilder.tableCell()]),
                    BlockBuilder.tableRow([BlockBuilder.tableCell(), BlockBuilder.tableCell()]),
                ]);
                const firstCell = block.children[0].children[0];

                SlateEditor.withoutNormalizing(editor, () => {
                    Transforms.delete(editor, { at: path });
                    Transforms.insertNodes(editor, [block], { at: path });
                    EditorOperations.Selection.safeSelectBlockById(editor, firstCell.id);
                });
            }
        } else if (type === BlockType.FILE) {
            track('create-file', { source: 'suggestions' });
            const block = BlockBuilder.file('');
            Transforms.insertFragment(editor, [block]);
        } else if (type === BlockType.DIVIDER) {
            Transforms.insertFragment(editor, [BlockBuilder.divider()]);
            Transforms.move(editor, { distance: 1, unit: 'offset' });
        } else {
            Transforms.setNodes(
                editor,
                { type },
                {
                    at: selection,
                    match: (n) =>
                        isSagaElement(n) &&
                        !isBlockType(n as SagaElement, [isLiveBlock, isCode, isImage, isInline, isCallout]),
                },
            );
        }
    });
}

type PerformSuggestionOptions = {
    editor: SlateEditor;
    item: SuggestionModalSuggestion;
    space: SafeSpace | null;
    provider: HocuspocusProvider | null;
    target: Range;
    triggerState: TriggerState;
};

function performCreateSuggestion(
    createSuggestion: CreateSuggestion,
    { space, editor, target, provider }: PerformSuggestionOptions,
    taskFilters: Partial<WeakTask>,
): SagaElement | null {
    switch (createSuggestion.kind) {
        case 'collection': {
            throw new Error('Not implemented');
        }
        case 'page': {
            if (!space || !provider) throw new Error('Not in space');

            const title = createSuggestion.title;
            const page = createPage(space, newBlankPage({ title }), provider);

            const inlinePageLink = SpaceOperations.createInlinePageLink(space, page.id, page.blocks);
            EditorOperations.Transforms.insertInlineNode(editor, inlinePageLink, target);
            return inlinePageLink;
        }
        case 'task': {
            if (!space || !provider) throw new Error('Not in space');

            const task = createTask(space, { title: createSuggestion.title, ...taskFilters }, provider);

            const taskBlock = BlockBuilder.taskBlock(task.id, task);
            Transforms.insertFragment(editor, [taskBlock], { at: target });
            return taskBlock;
        }
        case 'linear-issue': {
            return SpaceOperations.addLinearIssue(
                getSdk,
                editor,
                { type: 'add', teamId: createSuggestion.team.id, title: createSuggestion.title },
                target,
            );
        }
    }
}

export function useBlocksPastedSuggestionModalAction(editor: Editor) {
    const { space } = useSpace();
    const changeBlocksWithEditor = usePerformBlockChangeWithEditor();

    return async (item: BlocksPastedSuggestion) => {
        track(trackAction[item.type], { source: 'blocks-pasted-menu' });

        switch (item.type) {
            case 'turn-into-live-block': {
                const calculateLiveSourceLocation = (
                    editor: Editor,
                    blocks: Array<SagaEditor.Clipboard.SagaElementedPasted>,
                ): Location | null => {
                    const firstBlockNodeEntry = EditorOperations.Selection.getNode(editor, blocks[0].originBlockId);

                    if (firstBlockNodeEntry == null) return null;

                    if (blocks.length === 1) {
                        return {
                            anchor: { offset: 0, path: firstBlockNodeEntry[1] },
                            focus: { offset: 0, path: firstBlockNodeEntry[1] },
                        };
                    }

                    const flattedBlocks = flatMap(
                        blocks,
                        ([b]) => b,
                    ) as Array<SagaEditor.Clipboard.SagaElementedPasted>;
                    const lastContainer = flattedBlocks.reverse().find((b) => b.originBlockId);

                    if (lastContainer == null) return null;

                    const lastBlock = EditorOperations.Selection.getNode(editor, lastContainer.originBlockId);

                    if (!lastBlock) return null;

                    const location = {
                        anchor: { offset: 0, path: firstBlockNodeEntry[1] },
                        focus: { offset: 0, path: lastBlock[1] },
                    };

                    return location;
                };

                const calculatePastedBlocksLocation = (
                    editor: Editor,
                    blocks: Array<SagaElement & { originBlockId: string }>,
                ): Location | null => {
                    const firstCopyPastedBlock = EditorOperations.Selection.getNode(editor, blocks[0].id);

                    if (firstCopyPastedBlock == null) return null;

                    if (blocks.length === 1) {
                        return {
                            anchor: {
                                offset: Node.string(firstCopyPastedBlock[0]).length,
                                path: firstCopyPastedBlock[1],
                            },
                            focus: {
                                offset: Node.string(firstCopyPastedBlock[0]).length,
                                path: firstCopyPastedBlock[1],
                            },
                        };
                    }

                    const lastCopyPastedBlock = EditorOperations.Selection.getNode(
                        editor,
                        blocks[blocks.length - 1].id,
                    );

                    if (lastCopyPastedBlock == null) return null;

                    return {
                        anchor: {
                            offset: Node.string(firstCopyPastedBlock[0]).length,
                            path: firstCopyPastedBlock[1],
                        },
                        focus: {
                            offset: Node.string(lastCopyPastedBlock[0]).length,
                            path: lastCopyPastedBlock[1],
                        },
                    };
                };

                changeBlocksWithEditor(space, item.origin, (targetEditor) => {
                    const targetLocation = calculateLiveSourceLocation(targetEditor, item.blocks);

                    if (targetLocation == null) {
                        throw new Error('Live source location not found');
                    }

                    const liveBlock = SpaceOperations.createLiveBlock(space, {
                        editor: targetEditor,
                        at: targetLocation,
                        origin: item.origin,
                    });

                    const pastedBlocksLocation = calculatePastedBlocksLocation(editor, item.blocks);
                    if (!pastedBlocksLocation) return;

                    Transforms.removeNodes(editor, {
                        match: (n) => {
                            if (isSagaElement(n) && item.blocks.some((a) => a.id === n.id)) {
                                return true;
                            } else {
                                return false;
                            }
                        },
                        at: pastedBlocksLocation,
                    });

                    Transforms.insertFragment(editor, [liveBlock]);
                });

                break;
            }

            case 'keep-as-text':
            case 'keep-as-link': {
                break;
            }

            case 'turn-into-pretty-link': {
                setTimeout(() => {
                    const nodeEntry = EditorOperations.Selection.getNode(editor, item.link.id);

                    if (nodeEntry == null) return;

                    Transforms.delete(editor, { at: nodeEntry[1] });

                    Transforms.insertFragment(editor, [BlockBuilder.prettyLink(item.link.url)], {});
                });
                break;
            }

            case 'turn-into-embed': {
                setTimeout(() => {
                    track('turn-into-pretty-link', { source: 'blocks-pasted-suggestion' });

                    const nodeEntry = EditorOperations.Selection.getNode(editor, item.link.id);

                    if (nodeEntry == null) return;

                    Transforms.delete(editor, { at: nodeEntry[1] });

                    Transforms.insertFragment(editor, [BlockBuilder.embed(item.link.url)], {});
                });
                break;
            }

            case 'turn-into-google-drive': {
                setTimeout(() => {
                    track('turn-into-google-drive', { source: 'blocks-pasted-suggestion' });

                    const nodeEntry = EditorOperations.Selection.getNode(editor, item.link.id);

                    if (nodeEntry == null) return;

                    Transforms.delete(editor, { at: nodeEntry[1] });

                    const googleDriveFileId = new RegExp(googleDriveRegex).exec(item.link.url)?.[1];
                    if (googleDriveFileId) {
                        SpaceOperations.addGoogleDrive(getSdk, editor, googleDriveFileId);
                    }
                });
                break;
            }

            case 'turn-into-linear-issue': {
                setTimeout(() => {
                    track('turn-into-linear-issue', { source: 'blocks-pasted-suggestion' });

                    const nodeEntry = EditorOperations.Selection.getNode(editor, item.link.id);
                    if (nodeEntry == null) return;

                    Transforms.delete(editor, { at: nodeEntry[1] });

                    const linearIssueId = new RegExp(linearIssueUrl).exec(item.link.url)?.[1];

                    if (!linearIssueId) {
                        return;
                    }

                    SpaceOperations.addLinearIssue(getSdk, editor, { type: 'get', linearIssueId: linearIssueId });
                });
                break;
            }
        }
    };
}

export function useInsertIntegrationSuggestionModalAction(editor: Editor, target: Range | undefined) {
    return async (item: SuggestionModalSuggestion) => {
        track(trackAction[item.type], { source: 'insert-integration-menu' });

        assertNonNull(target);
        switch (item.type) {
            case 'drive': {
                const link = BlockBuilder.googleDriveLink([BlockBuilder.text(item.file.name)], {
                    type: 'loaded',
                    file: item.file,
                });

                EditorOperations.Transforms.insertInlineNode(editor, link, target);
                ReactEditor.focus(editor);
                return { trackingOptions: {}, inserted: link };
            }
            case 'linear-issue': {
                const link = BlockBuilder.linearIssue([BlockBuilder.text(item.issue.identifier)], {
                    type: 'loaded',
                    issue: {
                        id: item.issue.id,
                        identifier: item.issue.identifier,
                        url: item.issue.url,
                        title: item.issue.title,
                    },
                });
                EditorOperations.Transforms.insertInlineNode(editor, link, target);
                ReactEditor.focus(editor);
                return { trackingOptions: {}, inserted: link };
            }
            default: {
                return { trackingOptions: {}, inserted: null };
            }
        }
    };
}

function getAISuggestions(editor: SlateEditor, range: Range | null, search: string): AICommandSuggestion[] {
    const regex = getSearchRegex(search);
    const keywords = ['ask', 'ai', 'ia', 'saga ai'];

    return keywords.some((keyWord) => keyWord.match(regex))
        ? [
              {
                  type: 'ai-command',
                  title: 'Ask AI',
                  command: 'open-ai-popover',
              },
          ]
        : [];
}

function getInsertIntegrationSuggestions(
    editor: SlateEditor,
    range: Range | null,
    search: string,
    t: TFunction,
): InsertIntegrationCommands[] {
    const { data } = api.useUserIntegrationsQuery();
    const googleDriveIntegration = data?.userIntegrations.find(
        (integration) => integration.type === api.UserIntegrationType.GoogleDrive,
    );

    const linearIntegration = data?.userIntegrations.find(
        (integration) => integration.type === api.UserIntegrationType.Linear,
    );

    if (range == null) return [];

    const regex = getSearchRegex(search);
    const driveKeywords = ['drive', 'google', 'slides', 'docs', 'sheets'];
    const linearKeywords = ['linear'];
    const linkPageKeywords = ['link', 'linkto', 'to', 'page'];

    return [
        ...(linkPageKeywords.some((keyWord) => keyWord.match(regex))
            ? [
                  {
                      command: 'inline-page-link' as const,
                      type: 'insert' as const,
                      title: t('common.link_to_page'),
                  },
              ]
            : []),
        ...(driveKeywords.some((keyWord) => keyWord.match(regex) && googleDriveIntegration)
            ? [
                  {
                      command: 'drive' as const,
                      type: 'insert' as const,
                      title: t('common.gdrive_file'),
                  },
              ]
            : []),
        ...(linearKeywords.some((keyWord) => keyWord.match(regex) && linearIntegration)
            ? [{ command: 'linear-issue' as const, type: 'insert' as const, title: t('common.linear_issue') }]
            : []),
    ];
}

function getTableCommandSuggestions(
    editor: SlateEditor,
    range: Range | null,
    search: string,
): TableCommandSuggestion[] {
    if (range == null) return [];

    const [currentNode] = Editor.nodes(editor, { at: range });
    if (!currentNode || !Editor.hasPath(editor, range.anchor.path) || !Editor.hasPath(editor, range.focus.path))
        return [];

    const tableEntry = SlateEditor.above(editor, { at: range, match: isTable, mode: 'lowest' });
    if (tableEntry == null) return [];

    const regex = getSearchRegex(search);

    return [
        { command: 'insert-row-above' as const, type: 'table-command' as const, title: 'Insert Row Above', tableEntry },
        { command: 'insert-row-below' as const, type: 'table-command' as const, title: 'Insert Row Below', tableEntry },
        {
            command: 'insert-column-left' as const,
            type: 'table-command' as const,
            title: 'Insert Column Left',
            tableEntry,
        },
        {
            command: 'insert-column-right' as const,
            type: 'table-command' as const,
            title: 'Insert Column Right',
            tableEntry,
        },
    ].filter((item) => item.title.match(regex));
}

function useOnSearch(
    searchValue: string,
    onSearch?: (searchValue: string, stopTrim?: boolean) => Promise<SuggestionModalSuggestion[]>,
) {
    const [debouncedSearchQuery, setDebouncedSearchQuery] = React.useState(searchValue);
    const [searchResultSuggestions, setSearchResultSuggestions] = React.useState<SuggestionModalSuggestion[]>([]);
    const [loading, setLoading] = React.useState(false);
    const onSearchHandler = React.useRef(onSearch);
    onSearchHandler.current = onSearch;

    const debounceSearch = React.useMemo(() => debounce(setDebouncedSearchQuery, 200), []);
    React.useEffect(() => {
        debounceSearch(searchValue);
    }, [searchValue, debounceSearch]);

    React.useEffect(() => {
        if (onSearchHandler.current) {
            setLoading(true);
            onSearchHandler.current(debouncedSearchQuery, true).then((results) => {
                React.startTransition(() => {
                    setSearchResultSuggestions(results);
                    setLoading(false);
                });
            });
        }
    }, [debouncedSearchQuery]);

    return { loading, results: searchResultSuggestions };
}

const pageSuggestionsKeysMap = ['id', 'title', 'icon', 'aliases', 'isTemplate', 'settings', 'updatedAt'] as const;
type SuggestionPageKeys = (typeof pageSuggestionsKeysMap)[number];

export function usePagesForSuggestion() {
    return usePartialPages(pageSuggestionsKeysMap, 'deep');
}

function SuggestionsModal({
    search,
    isOpen,
    onSuggestionSelected,
    onClose,
    currentFormat,
    range,
    recentLocations,
    members,
    tasks,
    pages,
    linearTeams,
    onSearch,
    isCurrentBlockEmpty,
    collections,
    showSpaceSuggestions,
}: {
    search: string;
    isOpen: boolean;
    onSuggestionSelected(suggestion: SuggestionModalSuggestion, e?: React.MouseEvent | React.KeyboardEvent): void;
    onClose(): void;
    currentFormat: SagaElement['type'] | undefined;
    range: Range | null;
    recentLocations: SagaLocation.BlocksLocation[];
    members: Member[];
    linearTeams: api.LinearTeamFragment[];
    tasks: WeakTask[];
    pages: Pick<WeakPage, SuggestionPageKeys>[];
    onSearch?: (searchValue: string) => Promise<SuggestionModalSuggestion[]>;
    isCurrentBlockEmpty: boolean;
    collections: Collection[];
    showSpaceSuggestions: boolean;
}) {
    const { t } = useTranslation();
    const { user } = useUserContext();
    const listRef = React.useRef<VariableSizeList>(null);
    const { location } = SagaEditor.useEditorContext();
    const editor = useSlateStatic();
    const taskSuggestions = search
        ? filterTaskSuggestions(
              tasks.map((task) => taskToSuggestion(task)),
              { search },
          )
        : [];

    const orderedTaskSuggestions = _.orderBy(taskSuggestions, (a) => new Date(a.task.updatedAt), 'desc');

    const teamSuggestions: CreateSuggestion[] = linearTeams.map((team) => {
        return { team, title: search, type: 'create' as const, kind: 'linear-issue' as const };
    });

    const formatSuggestions: FormatSuggestion[] = getFormatTypes(editor, {
        range,
        search,
        currentFormat,
        language: user?.data.language || api.Language.En,
    }).flatMap(({ label, value }) => {
        return (value === 'task-block' || value === 'file' || value === 'image') && !showSpaceSuggestions
            ? []
            : [
                  {
                      type: 'format',
                      title: t(label),
                      value,
                  },
              ];
    });

    const filteredPageSuggestions = search
        ? filterPageSuggestions(pages.map(pageToPageSuggestion), { search, location }).filter(({ page }) =>
              filterByNonEmptyTitle(page),
          )
        : [];

    const recentSuggestions = filteredPageSuggestions
        .filter(({ page }) => filterByNonEmptyTitle(page))
        .filter(({ page }) =>
            recentLocations
                .filter(SagaLocation.isPageLocation)
                .map((l) => l.pageId)
                .slice(0, 5)
                .includes(page.id),
        )
        .filter(({ page }) => page.settings.linkTitle)
        .slice(0, 5)
        .map(({ page }) => pageToRecentPageSuggestion(page));

    const isWithinTitle = range != null && hasParentOrCurrentNode(editor, range, [isTitle]);

    const inlinePageLinkSuggestions =
        search && !isWithinTitle
            ? filteredPageSuggestions.map(({ page }) => page).map(pageToInlinePageLinkSuggestion)
            : [];
    const sortedPageSuggestions = _.orderBy(inlinePageLinkSuggestions, (a) => new Date(a.page.updatedAt), 'desc');

    const createPageSuggestion: CreateSuggestion | null = search ? makeCreatePageSuggestion(search) : null;

    const taskCreateSuggestion: CreateSuggestion | null = search ? makeTaskCreateSuggestion(search) : null;

    const memberSuggestions: MemberSuggestion[] = search
        ? filterMembers(members, { search }).map((member) => ({ type: 'member', member }))
        : [];

    const searchResultSuggestions = useOnSearch(search, onSearch);
    const parsedDateSuggestions = getDateSuggestions(search, t, i18next.language);
    const tableCommandSuggestions = getTableCommandSuggestions(editor, range, search);
    const insertIntegrationSuggestions = getInsertIntegrationSuggestions(editor, range, search, t);

    const aiSuggestions = getAISuggestions(editor, range, search);

    const regex = getSearchRegex(search);

    const collectionSuggestions = !isWithinTitle
        ? collections.map(collectionToSuggestion).filter((suggestion) => suggestion.title.match(regex))
        : [];

    const items: SuggestionModalSuggestion[] = [
        ...(showSpaceSuggestions ? aiSuggestions : []),
        ...tableCommandSuggestions,
        ...memberSuggestions,
        ...formatSuggestions,
        ...(showSpaceSuggestions ? sortedPageSuggestions : []),
        ...(showSpaceSuggestions ? orderedTaskSuggestions : []),

        ...(showSpaceSuggestions ? insertIntegrationSuggestions : []),
        ...(showSpaceSuggestions ? collectionSuggestions : []),
        ...(showSpaceSuggestions ? recentSuggestions : []),
        ...parsedDateSuggestions,

        ...(createPageSuggestion && showSpaceSuggestions ? [createPageSuggestion] : []),
        ...(taskCreateSuggestion && showSpaceSuggestions ? [taskCreateSuggestion] : []),
        ...(search.length > 0 ? teamSuggestions : []),
        ...searchResultSuggestions.results,
    ];

    function onSelectItem(item: SuggestionModalSuggestion, e?: React.MouseEvent | React.KeyboardEvent) {
        onSuggestionSelected(item, e);
        onClose();
    }

    const { index: selectedIndex, setIndex } = useArrowSelectableIndex({
        onSelect: (index) => {
            const item = items[index];
            if (item) {
                onSelectItem(item);
            }
        },
        onUpdate(index) {
            listRef.current?.scrollToItem(index);
        },
        maxIndex: items.length - 1,
        active: isOpen,
        selectWithTab: true,
        initialIndex: 0,
    });

    if (items.length === 0) {
        return null;
    }

    return (
        <div data-testid="suggestion-popover" id="suggestion-popover" className="max-h-72 min-w-0">
            <ItemGroups
                ref={listRef}
                maxHeight={288}
                divider={
                    <div className="py-0.5">
                        <PopOver.Divider />
                    </div>
                }
                items={items}
                labels={{
                    'ai-command': <PopOver.Label>{t('ai.title')}</PopOver.Label>,
                    create: <PopOver.Label>{t('common.create')}</PopOver.Label>,
                    date: <PopOver.Label>{t('common.dates')}</PopOver.Label>,
                    'table-command': <PopOver.Label>{t('common.table_commands')}</PopOver.Label>,
                    member: <PopOver.Label>{t('common.mention_members')}</PopOver.Label>,
                    'inline-page-link': <PopOver.Label>{t('common.insert_page_link_to')}</PopOver.Label>,
                    format: (
                        <PopOver.Label>
                            {t(isCurrentBlockEmpty ? 'common.add_dotted' : 'common.turn_into')}
                        </PopOver.Label>
                    ),
                    collection: <PopOver.Label>{t('common.link_to_collection')}</PopOver.Label>,
                    insert: <PopOver.Label>{t('common.insert')}</PopOver.Label>,
                    recentPage: <PopOver.Label>{t('common.recents')}</PopOver.Label>,
                    drive: <PopOver.Label>{t('common.google_drive')}</PopOver.Label>,
                    'linear-issue': <PopOver.Label>{t('common.linear_issues')}</PopOver.Label>,
                    task: <PopOver.Label>{t('common.tasks')}</PopOver.Label>,
                }}
                renderItem={(item, i) => {
                    switch (item.type) {
                        case 'member': {
                            return (
                                <Button.PopOverButton
                                    selected={selectedIndex === i}
                                    onClick={() => onSelectItem(item)}
                                    onMouseEnter={() => setIndex(i)}
                                    hoverable={false}
                                >
                                    <div className="space-x-2 flex items-center ">
                                        <MemberAvatar
                                            name={getFullMemberName(item.member)}
                                            color={stringToColor(item.member.id, AVAILABLE_COLORS)}
                                            size="sm"
                                            showBorder={false}
                                        />
                                        <span>{getFullMemberName(item.member)}</span>
                                    </div>
                                </Button.PopOverButton>
                            );
                        }
                        case 'drive': {
                            return (
                                <Tooltip zIndex={2000} content={item.file.name} placement="right">
                                    <Button.PopOverButton
                                        selected={selectedIndex === i}
                                        onClick={() => onSelectItem(item)}
                                        onMouseEnter={() => setIndex(i)}
                                        hoverable={false}
                                    >
                                        <div className="space-x-2 flex items-center ">
                                            {item.file.iconLink && (
                                                <img className="h-3.5 w-3.5" src={item.file.iconLink} />
                                            )}
                                            <span>{item.file.name}</span>
                                        </div>
                                    </Button.PopOverButton>
                                </Tooltip>
                            );
                        }
                        case 'linear-issue': {
                            return (
                                <Tooltip zIndex={2000} content={item.issue.title} placement="right">
                                    <Button.PopOverButton
                                        selected={selectedIndex === i}
                                        onClick={() => onSelectItem(item)}
                                        onMouseEnter={() => setIndex(i)}
                                        hoverable={false}
                                    >
                                        <div className="space-x-2 flex items-center min-w-0 ">
                                            <LinearIcon className="h-3.5 w-3.5 flex-shrink-0" />
                                            <span className="truncate">
                                                {item.issue.identifier}: {item.issue.title}
                                            </span>
                                        </div>
                                    </Button.PopOverButton>
                                </Tooltip>
                            );
                        }
                        case 'create': {
                            switch (item.kind) {
                                case 'linear-issue': {
                                    return (
                                        <Button.PopOverButton
                                            selected={selectedIndex === i}
                                            onClick={() => onSelectItem(item)}
                                            onMouseEnter={() => setIndex(i)}
                                            hoverable={false}
                                        >
                                            <div className="space-x-2 flex items-center min-w-0 ">
                                                <LinearIcon className="h-4 w-4 flex-shrink-0" />
                                                <span className="flex min-w-0">
                                                    <span className="font-medium truncate min-w-0 -ml-0.5 mr-1">
                                                        {item.title}
                                                    </span>
                                                    {t('editor.suffix_create_issue_in', {
                                                        team: item.team.name,
                                                    })}
                                                </span>
                                            </div>
                                        </Button.PopOverButton>
                                    );
                                }
                                case 'task': {
                                    return (
                                        <Button.PopOverButton
                                            selected={selectedIndex === i}
                                            onClick={(e) => onSelectItem(item, e)}
                                            onMouseEnter={() => setIndex(i)}
                                            hoverable={false}
                                        >
                                            <div className="space-x-2 flex items-center min-w-0 ">
                                                <CheckCircle className="h-3.5 w-3.5 flex-shrink-0" />
                                                <span className="flex min-w-0">
                                                    <span className="font-medium truncate min-w-0 mr-1">
                                                        {item.title}
                                                    </span>
                                                    {t('editor.suffix_create_task')}
                                                </span>
                                            </div>
                                        </Button.PopOverButton>
                                    );
                                }
                                case 'collection':
                                case 'page': {
                                    return (
                                        <SuggestionButton
                                            selected={selectedIndex === i}
                                            onClick={() => onSelectItem(item)}
                                            suggestion={item}
                                            onMouseEnter={() => setIndex(i)}
                                            hoverable={false}
                                            showIcons
                                        >
                                            {item.title}
                                        </SuggestionButton>
                                    );
                                }
                                default: {
                                    return null;
                                }
                            }
                        }
                        case 'task': {
                            return (
                                <Button.PopOverButton
                                    selected={selectedIndex === i}
                                    onClick={() => onSelectItem(item)}
                                    onMouseEnter={() => setIndex(i)}
                                    hoverable={false}
                                >
                                    <Tooltip zIndex={2000} content={item.title} placement="right">
                                        <div className="space-x-2 flex items-center min-w-0 ">
                                            <CheckCircle className="h-3.5 w-3.5 flex-shrink-0" />
                                            <span className="flex min-w-0">
                                                <span className="font-normal truncate min-w-0">{item.title}</span>
                                            </span>
                                        </div>
                                    </Tooltip>
                                </Button.PopOverButton>
                            );
                        }
                        case 'format': {
                            return (
                                <Button.PopOverButton
                                    selected={selectedIndex === i}
                                    onClick={(e) => onSelectItem(item, e)}
                                    onMouseEnter={() => setIndex(i)}
                                    hoverable={false}
                                >
                                    <div className="space-x-2 flex items-center " data-testid={item.value}>
                                        <FormatIcon type={item.value} />
                                        <span>{item.title}</span>
                                    </div>
                                </Button.PopOverButton>
                            );
                        }
                        case 'inline-page-link':
                        case 'recentPage': {
                            return (
                                <Tooltip zIndex={2000} content={item.title} placement="right">
                                    <Button.PopOverButton
                                        selected={selectedIndex === i}
                                        onClick={() => onSelectItem(item)}
                                        onMouseEnter={() => setIndex(i)}
                                        hoverable={false}
                                    >
                                        <div className="space-x-2 flex items-center min-w-0 ">
                                            <div className="flex-none">
                                                <PageIcon
                                                    icon={item.page.icon}
                                                    size={16}
                                                    isTemplate={item.page.isTemplate}
                                                />
                                            </div>
                                            {item.type === 'inline-page-link' && (
                                                <span className="sr-only">
                                                    {t('editor.insert_page_link_to', {
                                                        title: item.title || t('pages.favourite_untitled'),
                                                    })}
                                                </span>
                                            )}
                                            {item.type === 'recentPage' && (
                                                <span className="sr-only">
                                                    {t('editor.insert_recent_page_link_to', {
                                                        title: item.title || t('pages.favourite_untitled'),
                                                    })}
                                                </span>
                                            )}

                                            <span className="truncate">
                                                {item.title || t('pages.favourite_untitled')}
                                            </span>
                                        </div>
                                    </Button.PopOverButton>
                                </Tooltip>
                            );
                        }
                        case 'table-command': {
                            return (
                                <Button.PopOverButton
                                    selected={selectedIndex === i}
                                    onClick={() => onSelectItem(item)}
                                    onMouseEnter={() => setIndex(i)}
                                    hoverable={false}
                                >
                                    <div className="space-x-2 flex items-center ">
                                        <FormatIcon type="table" />
                                        <span>{item.title}</span>
                                    </div>
                                </Button.PopOverButton>
                            );
                        }

                        case 'collection': {
                            return (
                                <Tooltip zIndex={2000} content={item.title} placement="right">
                                    <SuggestionButton
                                        selected={selectedIndex === i}
                                        onClick={() => onSelectItem(item)}
                                        suggestion={item}
                                        onMouseEnter={() => setIndex(i)}
                                        hoverable={false}
                                        showIcons
                                    >
                                        {item.title}
                                    </SuggestionButton>
                                </Tooltip>
                            );
                        }
                        case 'date': {
                            return (
                                <Button.PopOverButton
                                    selected={selectedIndex === i}
                                    onClick={() => onSelectItem(item)}
                                    onMouseEnter={() => setIndex(i)}
                                    hoverable={false}
                                >
                                    <div className="space-x-2 flex items-center" data-testid={item.type}>
                                        <Calendar className="flex-none text-saga-text dark:text-zinc-200 whitespace-nowrap w-3.5" />
                                        <span>{item.title}</span>
                                    </div>
                                </Button.PopOverButton>
                            );
                        }

                        case 'insert': {
                            return (
                                <Button.PopOverButton
                                    selected={selectedIndex === i}
                                    onClick={() => onSelectItem(item)}
                                    onMouseEnter={() => setIndex(i)}
                                    hoverable={false}
                                >
                                    <div className="space-x-2 flex items-center ">
                                        {item.command === 'linear-issue' && (
                                            <LinearIcon className="h-3.5 w-3.5 flex-shrink-0" />
                                        )}
                                        {item.command === 'drive' && <GoogleDriveSvg className="h-3.5 w-3.5" />}
                                        {item.command === 'inline-page-link' && (
                                            <Link className="h-3.5 w-3.5 flex-shrink-0" />
                                        )}
                                        <span>{item.title}</span>
                                    </div>
                                </Button.PopOverButton>
                            );
                        }

                        case 'ai-command': {
                            return (
                                <Button.PopOverButton
                                    selected={selectedIndex === i}
                                    onClick={() => onSelectItem(item)}
                                    onMouseEnter={() => setIndex(i)}
                                    hoverable={false}
                                >
                                    <div className="space-x-1.5 flex items-center ">
                                        <Lightning className="fill-saga-text dark:fill-saga-gray-300 w-[18px] h-[18px] -ml-0.5" />
                                        <span className="-ml-0.5">{t('ai.ask_ai')}</span>
                                    </div>
                                </Button.PopOverButton>
                            );
                        }
                        default: {
                            return null;
                        }
                    }
                }}
            />
        </div>
    );
}

export function BlocksPastedSuggestionsModal({
    isOpen,
    onSuggestionSelected,
    onClose,
    suggestions,
}: {
    isOpen: boolean;
    onSuggestionSelected(suggestion: BlocksPastedSuggestion): void;
    onClose(): void;
    suggestions: BlocksPastedSuggestion[];
}) {
    const containerRef = React.useRef<HTMLDivElement>(null);

    function onSelectItem(item: BlocksPastedSuggestion) {
        onSuggestionSelected(item);
        onClose();
    }

    const { index: selectedIndex, setIndex } = useArrowSelectableIndex({
        onSelect: (index) => {
            const item = suggestions[index];
            if (item) {
                onSelectItem(item);
            }
        },
        onUpdate() {
            if (containerRef.current) {
                scrollToSelectedIndex(containerRef.current);
            }
        },
        maxIndex: suggestions.length - 1,
        active: isOpen,
        initialIndex: 0,
    });

    return (
        <div
            ref={containerRef}
            data-testid="suggestion-blocks-pasted-popover"
            id="suggestion-blocks-pasted-popover"
            className="max-h-72 min-w-0 flex flex-col space-y-1"
        >
            {suggestions.map((item, i) => {
                const isSelected = selectedIndex === i;

                return (
                    <PopOver.RoundedButton
                        key={i}
                        selected={isSelected}
                        onClick={() => onSelectItem(item)}
                        onMouseEnter={() => setIndex(i)}
                    >
                        {item.title}
                    </PopOver.RoundedButton>
                );
            })}
        </div>
    );
}

const OpenBlocksPastedSuggestionsContext = React.createContext<
    (args: { range: Range; suggestions: BlocksPastedSuggestion[] }) => void
>(() => {});
const BlocksPastedSuggestionsStateContext = React.createContext<{ isOpen: boolean }>({ isOpen: false });

export const useOpenBlocksPastedSuggestions = () => React.useContext(OpenBlocksPastedSuggestionsContext);

export function BlocksPastedSuggestions({ children }: { children: React.ReactNode }) {
    const [state, setState] = React.useState<{ range: Range; suggestions: BlocksPastedSuggestion[] } | null>(null);
    const container = SagaEditor.useContainer();
    const { yBlocks } = SagaEditor.useEditorContext();

    const isSelectionOverflowing = useQuickEditorSelectionOverflow();
    const editor = useSlateStatic();

    const runSuggestionModalAction = useBlocksPastedSuggestionModalAction(editor);
    const [, startTransition] = React.useTransition();

    const open = React.useCallback(
        (args: { range: Range; suggestions: BlocksPastedSuggestion[] }) => {
            startTransition(() => {
                setState(args);
            });
        },
        [startTransition],
    );

    const isOpen = state != null;

    const suggestionsStateContext = React.useMemo(() => ({ isOpen }), [isOpen]);

    const close = () => {
        setState(null);
    };

    useOnTextEvent(yBlocks, editor.origin, {
        onInsert: () => {
            if (isOpen) {
                close();
            }
        },
    });

    return (
        <OpenBlocksPastedSuggestionsContext.Provider value={open}>
            <BlocksPastedSuggestionsStateContext.Provider value={suggestionsStateContext}>
                {children}
                <SuggestionsModalContainer
                    onClose={close}
                    containerRef={container}
                    isOpen={isOpen}
                    range={state?.range ?? null}
                    hidden={isSelectionOverflowing}
                >
                    <BlocksPastedSuggestionsModal
                        isOpen={isOpen}
                        onClose={close}
                        onSuggestionSelected={(item) => {
                            if (state == null) return;
                            runSuggestionModalAction(item);
                        }}
                        suggestions={state?.suggestions ?? []}
                    />
                </SuggestionsModalContainer>
            </BlocksPastedSuggestionsStateContext.Provider>
        </OpenBlocksPastedSuggestionsContext.Provider>
    );
}

export function InsertIntegrationSuggestionsModal({
    isOpen,
    onSuggestionSelected,
    onClose,
    type,
}: {
    isOpen: boolean;
    onSuggestionSelected(suggestion: SuggestionModalSuggestion): void;
    onClose(): void;
    type: string | undefined;
}) {
    const inputRef = React.useRef<HTMLInputElement>(null);
    const [search, setSearch] = React.useState<string>('');
    const containerRef = React.useRef<HTMLDivElement>(null);

    function onSelectItem(item: SuggestionModalSuggestion) {
        onSuggestionSelected(item);
        onClose();
    }

    useEffect(() => {
        inputRef?.current?.focus();
    }, []);

    const searchResultSuggestions = useOnSearch(search, searchIntegrations);

    const items = [...searchResultSuggestions.results.filter((x) => x?.type === type)];
    const SUGGESTION_ITEM_HEIGHT = 32;
    const SUGGESTION_COUNT = 10;
    const suggestionMaxHeight = SUGGESTION_ITEM_HEIGHT * SUGGESTION_COUNT;
    const { index: selectedIntegrationIndex, setIndex: setIntegrationIndex } = useArrowSelectableIndex({
        onSelect: (index) => {
            const item = items[index];
            if (item) {
                onSelectItem(item);
            }
        },
        onUpdate() {
            if (containerRef.current) {
                scrollToSelectedIndex(containerRef.current);
            }
        },
        maxIndex: items.length - 1,
        active: isOpen,
        initialIndex: 0,
    });

    return (
        <div ref={containerRef} data-testid="suggestion-integration-popover">
            <div className="relative max-h-20">
                <div className="absolute inset-y-0 left-0 flex items-center pl-1 pointer-events-none">
                    <Search className="stroke-saga-gray-500 flex-none pl-1" size={18} />
                </div>
                <PopOver.Input
                    ref={inputRef}
                    type="text"
                    title="Find a file..."
                    placeholder="Find a file..."
                    value={search}
                    className="block w-full py-2 pr-2 pl-8 placeholder-saga-gray-500 text-sm border border-saga-gray-300 rounded-md bg-gray-50 focus:border focus:ring-saga-gray-500 focus:border-saga-gray-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    onChange={(e) => setSearch(e.target.value)}
                />
            </div>
            <div className="overflow-y-auto mt-2" style={{ maxHeight: `${suggestionMaxHeight}px` }}>
                <div className="flex justify-center items-center mx-1 my-1">
                    <div className="flex-auto">
                        <span className="text-sm text-saga-gray-500">
                            {searchResultSuggestions.loading
                                ? 'Loading...'
                                : items.length === 0
                                  ? 'No search results'
                                  : search.trim() === ''
                                    ? 'Recent'
                                    : 'Search results'}
                        </span>
                    </div>
                    <div className="flex-none ">{searchResultSuggestions.loading && <Spinner size={16} />}</div>
                </div>
                {items.map((item, i) => {
                    const isSelected = selectedIntegrationIndex === i;
                    switch (item.type) {
                        case 'drive': {
                            return (
                                <Tooltip key={i} content={item.file.name} placement="right">
                                    <PopOver.RoundedButton
                                        selected={isSelected}
                                        onClick={() => onSelectItem(item)}
                                        onMouseEnter={() => setIntegrationIndex(i)}
                                    >
                                        <div className="space-x-2 flex items-center">
                                            {item.file.iconLink && <img src={item.file.iconLink} />}
                                            <span>{item.file.name}</span>
                                        </div>
                                    </PopOver.RoundedButton>
                                </Tooltip>
                            );
                        }
                        case 'linear-issue': {
                            return (
                                <Tooltip key={i} content={item.title} placement="right">
                                    <PopOver.RoundedButton
                                        fullWidth={false}
                                        selected={isSelected}
                                        onClick={() => onSelectItem(item)}
                                        onMouseEnter={() => setIntegrationIndex(i)}
                                    >
                                        <LinearIcon className="w-4 h-4 mr-2" />
                                        <p className="text-left" style={{ width: '205px' }}>
                                            {item.title}
                                        </p>
                                    </PopOver.RoundedButton>
                                </Tooltip>
                            );
                        }

                        default: {
                            return <></>;
                        }
                    }
                })}
            </div>
        </div>
    );
}

async function insertInlinePageLink(
    pageId: string,
    space: SafeSpace,
    editor: RealtimeSagaEditor,
    range: BaseRange,
    perform: (location: SagaLocation.BlocksLocation, action: (blocks: Y.Array<any>) => void) => Promise<void>,
) {
    // instant insert without waiting for the liveSourceId for smooth UX
    const node = BlockBuilder.inlinePageLink(pageId, '');
    EditorOperations.Transforms.insertInlineNode(editor, node, range);

    // get the blocks and update the sourceId
    await perform(SagaLocation.pageLocationFromId(pageId), (yBlocks) => {
        if (!yBlocks) {
            return;
        }

        const { liveReferenceSourceId, staticPage } = SpaceOperations.createInlinePageLink(
            space,
            pageId,
            yBlocks.toJSON(),
        );
        const path = EditorOperations.Selection.getNode(editor, node.id)?.[1];

        if (path) {
            Transforms.setNodes(editor, { liveReferenceSourceId, staticPage }, { at: path });

            ReactEditor.focus(editor);
        }
    });
}

export function InsertIntegrationSuggestionsModalForLinkPage({
    isOpen,
    onClose,
    range,
}: {
    isOpen: boolean;
    onClose(): void;
    range: Range;
}) {
    const { space, provider } = useSpace();
    const editor = useSlateStatic();
    const { recentLocations } = useRecents();
    const { location } = SagaEditor.useEditorContext();
    const containerRef = React.useRef<HTMLDivElement>(null);
    const { hasAccess } = usePagesPermissions();

    const performActionWithBlocks = usePerformActionWithYBlocks();

    const getSuggestions = React.useCallback(
        () => getRecentPageSuggestions(space, location, recentLocations, hasAccess),
        [space, location, recentLocations, hasAccess],
    );

    function onSelectItem(selectedItem: PageSuggestion | CreatePageSuggestion) {
        if (selectedItem.type === 'create') {
            const page = createPage(space, newBlankPage({ title: selectedItem.title }), provider);
            const inlinePageLink = SpaceOperations.createInlinePageLink(space, page.id, page.blocks);
            EditorOperations.Transforms.insertInlineNode(editor, inlinePageLink, range);
        } else {
            insertInlinePageLink(selectedItem.page.id, space, editor, range, performActionWithBlocks);
        }

        onClose();
    }

    return (
        <div ref={containerRef} data-testid="suggestion-link-to-page-popover">
            <LinkToPagePopOver
                popoverProps={{
                    isOpen: isOpen,
                    onClose: () => onClose(),
                    onSubmit({ selectedItem }) {
                        onSelectItem(selectedItem);
                    },
                    getSuggestions,
                }}
                attachToRef={containerRef}
            />
        </div>
    );
}

const OpenInsertIntegrationSuggestionsContext = React.createContext<(args: { range: Range; type: string }) => void>(
    () => {},
);
const InsertIntegrationSuggestionsStateContext = React.createContext<{ isOpen: boolean }>({ isOpen: false });

export const useOpenInsertIntegrationSuggestions = () => React.useContext(OpenInsertIntegrationSuggestionsContext);

export function InsertIntegrationSuggestionsComponent({ children }: { children: React.ReactNode }) {
    const [state, setState] = React.useState<{ range: Range; type: string } | null>(null);
    const container = SagaEditor.useContainer();
    const isSelectionOverflowing = useQuickEditorSelectionOverflow();
    const editor = useSlateStatic();
    const runSuggestionModalAction = useInsertIntegrationSuggestionModalAction(editor, state?.range);
    const [, startTransition] = React.useTransition();

    const open = React.useCallback(
        (args: { range: Range; type: string }) => {
            startTransition(() => {
                setState(args);
            });
        },
        [startTransition],
    );
    const isOpen = state != null;

    const suggestionsStateContext = React.useMemo(() => ({ isOpen }), [isOpen]);
    const close = () => {
        setState(null);
    };

    return (
        <OpenInsertIntegrationSuggestionsContext.Provider value={open}>
            <InsertIntegrationSuggestionsStateContext.Provider value={suggestionsStateContext}>
                {children}
                {state?.type === 'inline-page-link' ? (
                    <SuggestionsModalContainer
                        onClose={close}
                        containerRef={container}
                        isOpen={isOpen}
                        range={state?.range ?? null}
                        hidden={isSelectionOverflowing}
                        includeCard
                    >
                        <InsertIntegrationSuggestionsModalForLinkPage
                            isOpen={isOpen}
                            onClose={close}
                            range={state.range}
                        />
                    </SuggestionsModalContainer>
                ) : (
                    <SuggestionsModalContainer
                        onClose={close}
                        containerRef={container}
                        isOpen={isOpen}
                        range={state?.range ?? null}
                        hidden={isSelectionOverflowing}
                    >
                        <InsertIntegrationSuggestionsModal
                            isOpen={isOpen}
                            onClose={close}
                            onSuggestionSelected={(item) => {
                                if (state == null) return;
                                runSuggestionModalAction(item);
                            }}
                            type={state?.type}
                        />
                    </SuggestionsModalContainer>
                )}
            </InsertIntegrationSuggestionsStateContext.Provider>
        </OpenInsertIntegrationSuggestionsContext.Provider>
    );
}

const OpenSuggestionsContext = React.createContext<
    (args: { trigger?: Trigger; range: Range; rangeRef: RangeRef }) => void
>(() => {});
const SuggestionsStateContext = React.createContext<{ isOpen: boolean }>({ isOpen: false });

export const useOpenSuggestions = () => React.useContext(OpenSuggestionsContext);

export function Suggestions({
    children,
    recentLocations,
    members,
    linearTeams,
    onSearch,
    tasks,
    pages,
    collections,
    location,
    spaceId,
    space,
    provider,
}: {
    children: React.ReactNode;
    recentLocations: SagaLocation.BlocksLocation[];
    members: Member[];
    linearTeams: api.LinearTeamFragment[];
    tasks: WeakTask[];
    pages: Pick<WeakPage, SuggestionPageKeys>[];
    collections: Collection[];
    onSearch?: (searchValue: string) => Promise<SuggestionModalSuggestion[]>;
    location: SagaLocation.BlocksLocation;
    spaceId: string;
    space: SafeSpace | null;
    provider: HocuspocusProvider | null;
}) {
    const container = SagaEditor.useContainer();
    const { state, close, open } = useTrigger();
    const editor = useSlateStatic();
    const isSelectionOverflowing = useQuickEditorSelectionOverflow();
    const { openAIPopover } = useSetAIPopoverOpen();
    const { blockPlugins } = SagaEditor.useEditorContext();
    const [sendNotification] = api.useSendNotificationMutation();
    const [sendTaskMentionNotification] = api.useSendTaskMentionNotificationMutation();
    const { getMember } = useMembers();
    const { user } = useUserContext();
    const { showToast } = useToastContext();
    const openInsertIntegrationSuggestions = useOpenInsertIntegrationSuggestions();
    const isMobile = useMobile();
    const goToTask = useOpenTask();
    const taskFilters = useTaskFilters();

    const currentBlock = SlateEditor.above<SagaElement>(editor, { at: editor.selection ?? undefined })?.[0];
    const currentFormat = currentBlock?.type;

    const isCurrentBlockEmpty = currentBlock
        ? EditorOperations.SagaElement.toString(currentBlock, blockPlugins).length === 0
        : false;

    const isOpen = state != null;
    const suggestionState = React.useMemo(() => ({ isOpen }), [isOpen]);

    const performActionWithBlocks = usePerformActionWithYBlocks();

    const performSuggestion = React.useCallback(
        (
            options: PerformSuggestionOptions,
        ): {
            trackingOptions: Properties;
            inserted: SagaElement | null;
        } => {
            const { editor, item, space, target, triggerState } = options;

            switch (item.type) {
                case 'create': {
                    const inserted = performCreateSuggestion(item, options, taskFilters);
                    return { trackingOptions: {}, inserted };
                }

                case 'date': {
                    const block = BlockBuilder.dateBlock(item.date);
                    EditorOperations.Transforms.insertInlineNode(editor, block, target);

                    if (item.openPicker) {
                        openDateBlockPicker.add(block.id);
                    }

                    return { trackingOptions: {}, inserted: block };
                }

                case 'collection': {
                    const block = BlockBuilder.inlineCollection(item.collection.id, item.collection);
                    EditorOperations.Transforms.insertInlineNode(editor, block, target);
                    return { trackingOptions: {}, inserted: block };
                }

                case 'table-command': {
                    const tableCellEntry = SlateEditor.above(editor, {
                        match: isTableCell,
                        at: target,
                        mode: 'lowest',
                    });

                    Transforms.delete(editor, { at: target });

                    if (tableCellEntry) {
                        switch (item.command) {
                            case 'insert-row-below': {
                                EditorOperations.Tables.insertRowAfterTableCell(editor, tableCellEntry);
                                break;
                            }
                            case 'insert-row-above': {
                                EditorOperations.Tables.insertRowBeforeTableCell(editor, tableCellEntry);
                                break;
                            }
                            case 'insert-column-right': {
                                EditorOperations.Tables.insertColumnAfterTableCell(editor, tableCellEntry);
                                break;
                            }
                            case 'insert-column-left': {
                                EditorOperations.Tables.insertColumnBeforeTableCell(editor, tableCellEntry);
                                break;
                            }
                        }
                    }
                    return { trackingOptions: { command: item.command }, inserted: null };
                }

                case 'member': {
                    const member = item.member;
                    const memberFullname = getFullMemberName(member);
                    const creatorFullName = user?.data ? `${user.data.firstName} ${user.data.lastName}` : undefined;
                    const mention = BlockBuilder.mention(member.id, memberFullname, user?.id, creatorFullName);
                    EditorOperations.Transforms.insertInlineNode(editor, mention, target);

                    return { trackingOptions: {}, inserted: mention };
                }

                case 'recentPage':
                case 'inline-page-link': {
                    if (!space) throw new Error('Not in space');

                    insertInlinePageLink(item.page.id, space, editor, target, performActionWithBlocks);
                    return { trackingOptions: {}, inserted: null };
                }
                case 'format': {
                    if (item.value === BlockType.TASK_BLOCK) {
                        if (!space || !provider) throw new Error('Not in space');

                        track('create-task', { source: 'suggestions' });

                        const task = createTask(space, { title: '', ...taskFilters }, provider);
                        const taskBlock = BlockBuilder.taskBlock(task.id, task);

                        SlateEditor.withoutNormalizing(editor, () => {
                            if (triggerState.rangeRef.current?.focus.offset) {
                                Transforms.delete(editor, { at: triggerState.rangeRef.current });
                            }

                            Transforms.collapse(editor, { edge: 'end' });

                            Transforms.insertFragment(editor, [taskBlock]);
                            // We need to do this in a setTimeout in this case because the editor instance needs to be rendered first
                            setTimeout(() => {
                                const taskTitleEditor = taskTitleEditors.get(task.id);

                                taskTitleEditor?.focus();
                            });
                        });

                        return { trackingOptions: { blockType: item.value }, inserted: taskBlock };
                    } else {
                        formatSelection(editor, item, triggerState, space, provider, taskFilters);
                        return { trackingOptions: { blockType: item.value }, inserted: null };
                    }
                }
                case 'drive': {
                    const link = BlockBuilder.googleDriveLink([BlockBuilder.text(item.file.name)], {
                        type: 'loaded',
                        file: item.file,
                    });
                    EditorOperations.Transforms.insertInlineNode(editor, link, target);
                    return { trackingOptions: {}, inserted: link };
                }
                case 'linear-issue': {
                    const link = BlockBuilder.linearIssue([BlockBuilder.text(item.issue.identifier)], {
                        type: 'loaded',
                        issue: {
                            id: item.issue.id,
                            identifier: item.issue.identifier,
                            url: item.issue.url,
                            title: item.issue.title,
                        },
                    });
                    EditorOperations.Transforms.insertInlineNode(editor, link, target);
                    return { trackingOptions: {}, inserted: link };
                }

                case 'task': {
                    const taskBlock = BlockBuilder.taskBlock(item.task.id, item.task);
                    Transforms.insertFragment(editor, [taskBlock], { at: target });
                    return { trackingOptions: {}, inserted: taskBlock };
                }

                case 'insert': {
                    return { trackingOptions: {}, inserted: null };
                }
                case 'ai-command':
                    return { trackingOptions: {}, inserted: null };
            }
        },
        [user, provider, performActionWithBlocks],
    );

    return (
        <OpenSuggestionsContext.Provider value={open}>
            <SuggestionsStateContext.Provider value={suggestionState}>
                {children}
                <SuggestionsModalContainer
                    onClose={close}
                    containerRef={container}
                    isOpen={isOpen}
                    range={state?.range ?? null}
                    hidden={isSelectionOverflowing}
                >
                    <SuggestionsModal
                        range={state?.range ?? null}
                        showSpaceSuggestions={!!space}
                        isOpen={state != null}
                        search={state?.suggestionValue ?? ''}
                        onClose={close}
                        currentFormat={currentFormat}
                        isCurrentBlockEmpty={isCurrentBlockEmpty}
                        onSuggestionSelected={async (item, e) => {
                            if (state == null) return;
                            const { rangeRef } = state;

                            const target = rangeRef.current;
                            assertNonNull(target);

                            ReactEditor.focus(editor);

                            const { trackingOptions, inserted } = performSuggestion({
                                editor,
                                space,
                                provider,
                                target,
                                item,
                                triggerState: state,
                            });
                            track(trackAction[item.type], { source: 'suggestion-menu', ...trackingOptions });

                            if (item.type === 'ai-command') {
                                Transforms.delete(editor, { at: target });

                                if (target.anchor.offset) {
                                    // There is other content on current line
                                    Transforms.insertNodes(editor, BlockBuilder.paragraph(''));
                                }

                                openAIPopover();
                            }

                            if (item.type === 'insert' && editor.selection) {
                                openInsertIntegrationSuggestions({
                                    range: target,
                                    type: item.command,
                                });
                            }
                            if (inserted && isMention(inserted)) {
                                if (inserted.memberId === user?.id) return;
                                const member = getMember(inserted.memberId);

                                if (SagaLocation.isPageLocation(location)) {
                                    await sendNotification({
                                        variables: {
                                            input: {
                                                pageId: location.pageId,
                                                blockId: currentBlock ? currentBlock.id : '',
                                                memberId: inserted.memberId,
                                                spaceId,
                                            },
                                        },
                                    }).then(
                                        (emailSent) =>
                                            emailSent &&
                                            member &&
                                            showToast(() => (
                                                <SimpleTitleToast>{`Notification sent to ${getFullMemberName(
                                                    member,
                                                )}`}</SimpleTitleToast>
                                            )),
                                    );
                                } else {
                                    await sendTaskMentionNotification({
                                        variables: {
                                            input: {
                                                taskId: location.taskId,
                                                blockId: currentBlock ? currentBlock.id : '',
                                                memberId: inserted.memberId,
                                                spaceId,
                                            },
                                        },
                                    }).then(
                                        (emailSent) =>
                                            emailSent &&
                                            member &&
                                            showToast(() => (
                                                <SimpleTitleToast>{`Notification sent to ${getFullMemberName(
                                                    member,
                                                )}`}</SimpleTitleToast>
                                            )),
                                    );
                                }
                            }

                            setTimeout(() => {
                                if (isMobile && e && inserted) {
                                    if (isTaskBlock(inserted)) {
                                        goToTask(inserted.taskId, e);
                                    }
                                }
                            });
                        }}
                        recentLocations={recentLocations}
                        members={members}
                        linearTeams={linearTeams}
                        onSearch={onSearch}
                        tasks={tasks}
                        pages={pages}
                        collections={collections}
                    />
                </SuggestionsModalContainer>
            </SuggestionsStateContext.Provider>
        </OpenSuggestionsContext.Provider>
    );
}
