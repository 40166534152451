import * as R from 'ramda';
import { escapeRegExp } from '@/lib/helpers';
import * as api from '@saga/api';
import { Item, ComputedData, PageItem, CollectionItem, SharedPageItem } from '@/types';
import { Page, Sorting } from '@saga/shared';
import { ItemsFilterMode } from '@/components/table/TableMultipleFilterSelect';

export const propertySorter = (key: string) => (item: PageItem) => {
    return item.data.properties?.find((p) => p.id === key)?.value || '';
};

export const dateSorter =
    <T extends 'createdAt' | 'updatedAt'>(key: T) =>
    (item: Item) =>
        new Date(item.data[key]).getTime();

export const computedSorter = (key: keyof ComputedData) => (item: Item) => item.computed[key];

type ItemSorter<T extends Item> = (obj: T) => any;

export function sort<T extends Item>(items: T[], sorter: ItemSorter<T>, order: string) {
    if (order === 'asc') {
        return R.sortWith([R.ascend(sorter)], items);
    } else {
        return R.sortWith([R.descend(sorter)], items);
    }
}

export type SortableSharedPage = Pick<Page, 'id' | 'title' | 'updatedAt' | 'createdAt' | 'icon'> & {
    sharedBy: string;
    role: api.PagePermission;
};

export type SortablePage = Pick<
    Page,
    | 'id'
    | 'title'
    | 'collections'
    | 'wordCount'
    | 'updatedAt'
    | 'createdAt'
    | 'archivedAt'
    | 'properties'
    | 'icon'
    | 'isTemplate'
    | 'createdBy'
>;

export function preparePageItems(
    items: PageItem[],
    options: {
        sorting?: Sorting | null;
        search?: string;
        collectionsFilter?: ItemsFilterMode;
        creatorsFilter?: ItemsFilterMode;
    },
): PageItem[] {
    let newItems = items.slice();
    if (options?.search) {
        const { search } = options;
        const regex = new RegExp(`(?:^|\\s)${escapeRegExp(search)}`, 'gi');
        newItems = newItems.filter((item) => item.title.match(regex));
    }

    if (options?.collectionsFilter) {
        const { collectionsFilter } = options;

        switch (collectionsFilter) {
            case 'none':
                newItems = newItems.filter((item) => !item.data['collections'].length);
                break;
            case 'all':
                break;
            default:
                newItems = newItems.filter((item) =>
                    item.data['collections'].some((collection) => collectionsFilter.includes(collection)),
                );
                break;
        }
    }

    if (options?.creatorsFilter) {
        const { creatorsFilter } = options;

        switch (creatorsFilter) {
            case 'none':
                newItems = newItems.filter((item) => !item.data.createdBy);
                break;
            case 'all':
                break;
            default:
                newItems = newItems.filter(
                    (item) => item.data.createdBy && creatorsFilter.includes(item.data.createdBy),
                );
                break;
        }
    }

    if (options?.sorting) {
        const { sorting } = options;
        if (sorting.by == 'title') return sort(newItems, R.compose(R.toLower, R.prop('title')), sorting.order);
        if (sorting.by == 'collection')
            return sort(newItems as PageItem[], (item: PageItem) => item.data['collections'].length, sorting.order);
        if (sorting.by == 'subCollection') return sort(newItems, computedSorter('subCollections'), sorting.order);
        if (sorting.by == 'wordCount') return sort(newItems, (item) => item.data['wordCount'], sorting.order);
        if (sorting.by == 'created') return sort(newItems, dateSorter('createdAt'), sorting.order);
        if (sorting.by == 'updated') return sort(newItems, dateSorter('updatedAt'), sorting.order);
        else return sort(newItems, R.compose(R.toLower, propertySorter(sorting.by)), sorting.order);
    }

    return sort(newItems, dateSorter('createdAt'), 'desc');
}

export function prepareSharedPageItems(
    items: SharedPageItem[],
    options: { sorting?: Sorting | null; search?: string },
): SharedPageItem[] {
    let newItems = items.slice();
    if (options?.search) {
        const { search } = options;
        const regex = new RegExp(`(?:^|\\s)${escapeRegExp(search)}`, 'gi');
        newItems = newItems.filter((item) => item.title.match(regex));
    }

    if (options?.sorting) {
        const { sorting } = options;
        if (sorting.by == 'title') return sort(newItems, R.compose(R.toLower, R.prop('title')), sorting.order);
        if (sorting.by == 'created') return sort(newItems, dateSorter('createdAt'), sorting.order);
        if (sorting.by == 'updated') return sort(newItems, dateSorter('updatedAt'), sorting.order);
    }

    return sort(newItems, dateSorter('createdAt'), 'desc');
}

export function prepareCollectionItems(
    items: CollectionItem[],
    options: { sorting?: Sorting | null; search?: string },
): CollectionItem[] {
    let newItems = items.slice();
    if (options?.search) {
        const { search } = options;
        const regex = new RegExp(`(?:^|\\s)${escapeRegExp(search)}`, 'gi');
        newItems = newItems.filter((item) => item.title.match(regex));
    }

    if (options?.sorting) {
        const { sorting } = options;
        if (sorting.by == 'title') return sort(newItems, R.compose(R.toLower, R.prop('title')), sorting.order);
        if (sorting.by == 'pageCount') return sort(newItems, computedSorter('pageCount'), sorting.order);
        if (sorting.by == 'created') return sort(newItems, dateSorter('createdAt'), sorting.order);
        if (sorting.by == 'updated') return sort(newItems, dateSorter('updatedAt'), sorting.order);
    }

    return sort(newItems, dateSorter('updatedAt'), 'desc');
}
