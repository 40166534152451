import { prompt } from '../../../shared/src/aiPrompts.json';

export default {
    translation: {
        common: {
            open_saga: 'Ouvrir Saga',
            popover_placeholder: 'Entrez du texte ou @ pour',
            ai: 'IA',
            commands: 'commandes',
            and: 'et',
            or: 'ou',
            in: 'dans',
            add_dotted: 'Ajouter...',
            turn_into: 'Transformer en',
            plus_template: '+ Modèle',
            plus_collection: '+ Collection',
            plus_alias: '+ Alias',
            new_alias_placeholder: 'Tapez un nouvel Alias...',
            create_alias: '&nbsp;— Créer Alias',
            remove_alias: 'Supprimer {{alias}}',
            add_template_title: 'Sélectionnez un modèle',
            add_template_placeholder: 'Rechercher un modèle...',
            open: 'Ouvrir',
            mark_as_complete: 'Marquer comme terminé',
            open_in_new_tab: 'Ouvrir dans un nouvel onglet',
            add_to_pinned: 'Ajouter aux épinglés',
            add_to_favorites: 'Ajouter aux favoris',
            remove_from_pinned: 'Retirer des épinglés',
            remove_from_favorites: 'Retirer des favoris',
            create: 'Créer',
            create_workspace_title: 'Créons votre espace de travail.',
            create_workspace_description:
                "Un Espace de travail est où vos pages, idées et connaissances se connectent et s'organisent.",
            create_workspace_placeholder: 'Mon Espace de travail',
            save: 'Sauvegarder',
            edit: 'Modifier',
            cancel: 'Annuler',
            reload: 'Recharger',
            reset: 'Réinitialiser',
            logout: 'Déconnexion',
            login: 'Connexion',
            learn_more: 'En savoir plus',
            free_plan: 'Plan gratuit',
            upgrade: 'Mettre à niveau',
            show_me: 'Montrez-moi',
            got_it: 'Ok, compris.',
            dismiss: 'Ignorer',
            do_this_later: 'Faire cela plus tard',
            get_started: 'Commencer',
            dates: 'Dates',
            link_to_collection: 'Lier à une Collection',
            insert: 'Insérer',
            recents: 'Récents',
            google_drive: 'Google Drive',
            linear_issues: 'Problèmes Linéaires',
            tasks: 'Tâches',
            table_commands: 'Commandes de Tableau',
            mention_members: 'Mentionner les Membres',
            insert_page_link_to: 'Insérer le lien de la page vers',
            link_to_page: 'Lien vers la Page',
            gdrive_file: 'Fichier Google Drive',
            linear_issue: 'Problème Linéaire',
            new_template: 'Nouveau Modèle',
            create_template: 'Créer Modèle',
            create_template_placeholder: 'Entrez un nom de modèle...',
            edit_template: 'Modifier le Modèle',
            use_template: 'Utiliser le Modèle',
            recent_templates: 'Modèles Récents',
            create_page_from_template: 'Créer une page à partir du modèle',
            page_created: 'Page Créée',
            has_been_created: 'a été créé',
            blocks_moved: 'Blocs déplacés',
            selected_blocks_have_been_moved: 'Les blocs sélectionnés ont été déplacés vers :',
            selected_blocks_have_been_turned: 'Les blocs sélectionnés ont été transformés en une nouvelle page :',
            live_block_details: 'Un Bloc en Direct des blocs sélectionnés a été créé dans :',
            live_block_created: 'Bloc en Direct Créé',
            duplicate: 'Dupliquer',
            move_to: 'Déplacer vers',
            duplicate_page: 'Dupliquer la Page',
            duplicate_task: 'Tâche en double',
            copy_page: 'Copier la Page',
            undo: 'Annuler',
            is_mac: '⌘ + Z',
            is_windows: 'Ctrl + Z',
            export: 'Exporter',
            delete: 'Supprimer',
            update: 'Mettre à jour',
            continue: 'Continuer',
            upgrade_plan: 'Améliorer le plan',
            see_plans: 'Gérer le plan',
            delete_page: 'Supprimer la Page',
            remove_from_page: 'Retirer de la page',
            first_name: 'Prénom',
            last_name: 'Nom de famille',
            open_workspace: "Ouvrir l'Espace de travail",
            open_page: 'Ouvrir la Page',
            quick_edit: 'Édition rapide',
            open_full_page: 'Ouvrir la page entièrement',
            close: 'Fermer',
            close_popup: 'Fermer la Popup',
            close_entity: 'Fermer {{entity}}',
            close_modal: 'Fermer le modal',
            close_quick_edit_modal: "Fermer le modal d'édition rapide",
            open_notifications: 'Ouvrir les Notifications',
            close_notifications: 'Fermer les Notifications',
            close_sidebar: 'Fermer la Barre latérale',
            close_task_context_menu: 'Fermer le Menu contextuel de la Tâche',
            close_context_menu: 'Fermer le Menu contextuel',
            open_context_menu: 'Ouvrir le Menu contextuel',
            open_task_context_menu: 'Ouvrir le Menu contextuel de la Tâche',
            close_sidebar_context_menu: 'Fermer le Menu contextuel de la Barre latérale',
            open_sidebar: 'Ouvrir la Barre latérale',
            open_sidebar_context_menu: 'Ouvrir le Menu contextuel de la Barre latérale',
            open_page_context_menu: 'Ouvrir le Menu contextuel de la Page',
            close_page_context_menu: 'Fermer le Menu contextuel de la Page',
            open_image_context_menu: "Ouvrir le Menu contextuel de l'Image",
            close_property_menu: 'Fermer le Menu des propriétés',
            open_property_menu: 'Ouvrir le Menu des propriétés',
            close_pages_context_menu: 'Fermer le Menu contextuel des Pages',
            open_pages_context_menu: 'Ouvrir le Menu contextuel des Pages',
            open_in_codeSandbox: 'Ouvrir dans CodeSandbox',
            open_context_menu_for_column: 'Ouvrir le Menu contextuel pour la Colonne {{column}}',
            back_to_my_saga: 'Retour à ma Saga',
            referenced_in_pages: 'Référencé dans les Pages',
            see_pages_that_reference: 'Voir les pages qui référencent le bloc :',
            remove_icon: "Supprimer l'Icône",
            add_icon: 'Ajouter une Icône',
            change_icon: "Changer l'Icône",
            remove_callout: "Supprimer l'Encadré",
            click_add_image: 'Cliquez pour ajouter une image',
            click_add_image_or_embed: 'Cliquez pour ajouter une image ou <1>intégrer depuis une URL</1>',
            image_could_not_be_loaded: "L'image n'a pas pu être chargée.",
            select_or_search: 'Sélectionner ou rechercher',
            text_copied: 'Texte copié',
            copy_text: 'Copier le texte',
            prompt_copied: 'Invite copié',
            copy_prompt: "Copier l'invite",
            stop: 'Arrêter',
            select: 'Sélectionner',
            search_in_pages: 'Rechercher dans les pages',
            navigation: 'Navigation',
            members: 'Membres',
            pages: 'Pages',
            collections: 'Collections',
            actions: 'Actions',
            create_new_page: 'Créer une nouvelle page',
            create_live_block: 'Créer un Bloc en Direct',
            create_live_block_description: 'Référencer cette sélection dans une autre page',
            create_live_block_disabled: "La création d'un Bloc en Direct n'est pas possible avec cette sélection.",
            remove_image: "Supprimer l'Image",
            is_in: 'est dans',
            template: 'Modèle',
            view_references: 'Voir les Références',
            try_saga: 'Essayer Saga',
            search: 'Rechercher',
            report_page: 'Signaler la Page',
            paste_as_link: 'Coller comme Lien',
            paste_as_preview: 'Coller comme Aperçu de Lien',
            paste_as_google_drive: 'Coller comme Lien Google Drive',
            paste_as_linear_link: 'Coller comme Lien Linéaire',
            paste_as_text: 'Coller comme texte',
            paste_as_live_block: 'Coller comme Bloc en Direct',
            logged_in_with: 'Vous êtes connecté avec',
            log_in: 'Se connecter',
            gain_access:
                "avec un autre email, ou contactez un propriétaire de cet espace de travail pour obtenir l'accès.",
            highlighted_content: {
                text: 'Couleur de la police',
                background: 'Surlignage du texte',
            },
            properties_tooltip:
                'Les propriétés sont des champs personnalisables, tels que le texte, les étiquettes et les nombres que vous pouvez ajouter à vos pages afin de fournir un contexte plus détaillé.',
            confirm_changes: 'Confirmer les changements',
            you: 'Vous',
            mentioned_by: 'Mentionné par',
            mentioned_on: 'Mentionné le',
            copy: 'Copier',
            all_collections: 'Toutes les Collections',
            no_collections: 'Aucune Collection',
            n_collections_other: '{{count}} Collections',
            n_collections_one: '{{count}} Collection',
            search_collection_placeholder: 'Rechercher une Collection...',
        },
        inapp_notifications: {
            title: 'Saga 1.0',
            description:
                "Nous sommes officiellement sortis de la version bêta ! Lisez le communiqué pour plus d'informations sur la suite.",
        },
        invite_onboarding: {
            create_button: 'Créer un Nouvel Espace de travail',
            join_button: 'Rejoindre un Espace de travail',
            join_title: 'Rejoindre ou créer un espace de travail',
            invited_description: "Vous n'avez été invité à aucun espace de travail.",
            create_space_title: 'Créez votre propre Espace de travail à la place.',
            invited_description_one: 'Vous avez été invité à:',
            invited_description_other: 'Vous avez été invité à:',
            member_one: 'membre',
            member_other: 'membres',
        },
        editor: {
            loading_preview_dotted: "Chargement de l'aperçu...",
            edit_link: 'Modifier le Lien',
            link_to: 'Lier à',
            link_copied: 'Lien copié',
            copy_Link: 'Copier le Lien',
            turn_into: 'Transformer en',
            link_into_pretty_link: 'Transformer le lien en joli-lien',
            link_into_embed: 'Transformer le lien en intégration',
            move_to_page: 'Déplacer vers la page',
            create_live_block: 'Créer un Bloc en Direct',
            add_live_block: 'Ajouter comme Bloc en Direct à la page',
            create_template: 'Créer un Modèle',
            edit_template: 'Vous modifiez un modèle.',
            view_templates: 'Voir tous les Modèles',
            create_page: 'Créer une Page',
            create_collection: 'Créer une Collection',
            create_task: 'Créer une Tâche',
            all_templates: 'Tous les modèles',
            create_issue_in: 'Problème dans {{teamName}}',
            turn_into_link: 'Transformer en lien',
            turn_into_link_preview: 'Transformer en aperçu de lien',
            turn_into_embed: 'Transformer en intégration',
            embed_context_menu: "Menu Contextuel d'Intégration",
            loading_embed: "Chargement de l'intégration...",
            remove_embed: "Supprimer l'Intégration",
            remove_link: 'Supprimer le lien',
            link_preview_not_available: "L'aperçu du lien n'est pas disponible.",
            turn_into_page: 'Page <1>{{value}}</1>',
            turn_into_page_link: 'Transformer en Lien de Page',
            turn_into_task: 'Tâche <1>{{value}}</1>',
            turn_into_liner_issue: 'Problème Linéaire <1>{{value}} dans {{team}}</1>',
            turn_into_liner_issue_tooltip: 'Problème Linear {{value}} dans {{team}}',
            copy_link_to_block: 'Copier le lien vers le bloc',
            delete_block: 'Supprimer le bloc',
            block_deleted: 'Bloc supprimé',
            quick_edit: 'Édition rapide',
            empty_page: 'Page Vide',
            format_text: 'Texte',
            format_todo: 'Liste de Tâches',
            format_task: 'Tâche',
            format_heading_l: 'Titre — Grand',
            format_heading_m: 'Titre — Moyen',
            format_heading_s: 'Titre — Petit',
            format_ul: 'Points',
            format_ol: 'Liste Numérotée',
            format_img: 'Image',
            format_file: 'Téléverser un Fichier',
            format_table: 'Tableau',
            format_callout: 'Encadré',
            format_quote: 'Citation',
            format_divider: 'Séparateur',
            format_code: 'Code',
            format_equation: 'Équation',
            format_inline_equation: 'Équation en ligne',
            suffix_create_task: '— Créer Tâche',
            suffix_create_issue_in: '— Problème dans {{team}}',
            insert_page_link_to: 'Insérer le Lien de la Page vers {{title}}',
            insert_recent_page_link_to: 'Insérer le Lien de la Page Récente vers {{title}}',
            insert_column_left: 'Insérer une Colonne à Gauche',
            insert_column_right: 'Insérer une Colonne à Droite',
            insert_row_above: 'Insérer une Ligne au-dessus',
            insert_row_below: 'Insérer une Ligne en dessous',
            placeholder: {
                h1: 'Titre 1',
                h2: 'Titre 2',
                h3: 'Titre 3',
                task: 'Tâche',
                list: 'Liste',
                quote: 'Citation',
                enter_text: 'Entrez du texte ',
                enter_text_dotted: 'Entrez du texte...',
            },
            confirm_table_delete: 'Êtes-vous sûr de vouloir supprimer le tableau sélectionné ?',
            file_upload: {
                click_to_upload_file: 'Cliquez pour téléverser un fichier',
                uploading: 'Téléversement...',
                download_file: 'Télécharger le Fichier',
                remove_file: 'Supprimer le Fichier',
                close_file_context_menu: 'Fermer le Menu Contextuel du Fichier',
                open_file_context_menu: 'Ouvrir le Menu Contextuel du Fichier',
                max_file_size_error: 'La taille maximale du fichier est de {{size}}.',
            },
            actions: 'Actions',
        },
        update_references_modal: {
            title: 'Mettre à jour les liens vers cette page ?',
            description_one:
                'Renommer cette page de <span_red>{{-previousTitle}}</span_red> à <span_yellow>{{-newTitle}}</span_yellow> rompra <span_bold>{{count}}</span_bold> lien vers cette page. Voulez-vous les mettre à jour ?',
            description_other:
                'Renommer cette page de <span_red>{{-previousTitle}}</span_red> à <span_yellow>{{-newTitle}}</span_yellow> rompra <span_bold>{{count}}</span_bold> liens vers cette page. Voulez-vous les mettre à jour ?',
            select_all: 'Tout sélectionner',
            deselect_all: 'Tout désélectionner',
            update_links: 'Mettre à jour les Liens',
            update_link_one: 'Mettre à jour {{count}} lien',
            update_link_other: 'Mettre à jour {{count}} liens',
            dont_update: 'Ne pas mettre à jour',
        },
        updated_title_notice: {
            outdated_link_one: 'Il y a <bold>{{count}}</bold> lien périmé vers cette page.',
            outdated_link_other: 'Il y a <bold>{{count}}</bold> liens périmés vers cette page.',
            review: 'Revoir',
            update_all: 'Tout mettre à jour',
        },
        ai: {
            title: 'Saga IA',
            ask_ai: "Demander à l'IA",
            ask_ai_anything_mentions_dotted: "Demander à l'IA...",
            ask_ai_anything_dotted: "Demander à l'IA...",
            rewrite: 'Réécrire',
            discard: 'Jeter',
            rerun: 'Relancer',
            keep: 'Garder',
            insert_below: 'Insérer ci-dessous',
            replace: 'Remplacer',
            generating_dotted: 'Génération...',
            searching_space_dotted: "Recherche dans l'espace de travail...",
            searching_page_dotted: 'Recherche dans la page...',
            too_many_requests: 'Vous avez envoyé trop de demandes. Réessayez dans quelques secondes.',
            too_many_prompt_tokens:
                "Vous avez atteint la longueur maximale de conversation pour cette réponse de l'IA. <1>En savoir plus</1>",
            reduce_prompt_error: "Veuillez raccourcir l'invite à un maximum de {{max_length}} caractères",
            restricted:
                "Vous avez atteint le quota d'utilisation équitable mensuel pour Saga IA. <1>En savoir plus</1>",
            issue_with_generating: 'Il y a eu un problème avec la génération de votre texte.',
            edit_selection: 'Modifier la sélection',
            change_tone: 'Changer le ton',
            translate: 'Traduire',
            draft: 'Brouillon',
            discard_response: "Voulez-vous jeter la réponse de l'IA ?",
            my_prompts: 'Mes Invites',
            my_commands: 'Mes Commandes',
            search_or_select: 'Rechercher ou sélectionner',
            mention: 'Mentionnez une page ou une tâche',
            suggestion: {
                rewrite: 'Réécrire',
                continue: 'Continuer à écrire',
                summarize: 'Résumer',
                minimize: 'Rendre plus court',
                maximize: 'Rendre plus long',
                explain: 'Expliquer',
                fix: "Corriger l'orthographe & la grammaire",
                tone_concise: 'Concis',
                tone_professional: 'Professionnel',
                tone_casual: 'Décontracté',
                tone_funny: 'Drôle',
                translate_en: 'Anglais',
                translate_es: 'Espagnol',
                translate_fr: 'Français',
                translate_de: 'Allemand',
                translate_pt: 'Portugais',
                translate_it: 'Italien',
                translate_nl: 'Néerlandais',
                translate_zh: 'Chinois',
                translate_ko: 'Coréen',
                translate_ja: 'Japonais',
                translate_ua: 'Ukrainien',
                translate_ru: 'Russe',
                brainstorm: 'Générer des idées',
                brainstorm_about: prompt.brainstorm_about.fr,
                outline: 'Plan',
                outline_about: prompt.outline_about.fr,
                email: 'Email',
                email_about: prompt.email_about.fr,
                meeting: 'Ordre du jour de la réunion',
                meeting_about: prompt.meeting_about.fr,
                blog: 'Article de blog',
                blog_about: prompt.blog_about.fr,
                social: 'Publication sur les réseaux sociaux',
                social_about: prompt.social_about.fr,
                website: 'Contenu de site web',
                website_about: prompt.website_about.fr,
                cover_letter: 'Lettre de motivation',
                cover_letter_about: prompt.cover_letter_about.fr,
                press: 'Communiqué de presse',
                press_about: prompt.press_about.fr,
                job: 'Description de poste',
                job_about: prompt.job_about.fr,
                essay: 'Dissertation',
                essay_about: prompt.essay_about.fr,
            },
            chat: {
                tooltip: 'Ouvrir le chat IA',
                button: 'Chat IA',
                initial: {
                    greeting: 'Hey {{name}}, comment puis-je aider?',
                    bullet1:
                        'Discutez avec moi pour explorer des sujets, répondre à des questions ou créer du contenu à partir de vos notes Saga.',
                    bullet2: "Posez n'importe quelle question et j'utiliserai vos notes pour répondre.",
                    bullet3: 'Plus vous ajoutez à Saga, plus je peux vous aider!',
                },
                new_thread: 'Nouveau thread',
                history: 'Historique',
                no_threads: "Vous n'avez pas encore de threads. Commencez un nouveau thread pour commencer.",
            },
        },
        errors: {
            cant_open_page: 'Il semble que nous ayons des problèmes pour ouvrir cette page dans Saga.',
            something_went_wrong: 'Quelque chose a mal tourné',
            something_went_wrong_description: "Oups, cela n'était pas prévu.\nVeuillez réessayer.",
            something_went_wrong_try_again:
                '🤔 Quelque chose a mal tourné, veuillez réessayer\nSi le problème persiste, contactez-nous',
            connection_lost_title: 'Connexion perdue',
            connection_lost_description:
                "Votre connexion a été fermée pour cause d'inactivité.\nVeuillez recharger Saga pour vous reconnecter.",
        },
        side_by_side: {
            open: 'Ouvrir côte à côte',
            tooltip: 'Ouvrir la page à côté de la page actuelle',
            open_in_new_tab: 'Ouvrir dans un nouvel onglet',
            copy_link: 'Copier le lien dans le presse-papiers',
        },
        time: {
            years_ago: 'il y a {{years}} ans',
            months_ago: 'il y a {{months}} mois',
            weeks_ago: 'il y a {{weeks}} semaines',
            days_ago: 'il y a {{days}} jours',
            date: 'Date',
            today: "Aujourd'hui",
            yesterday: 'Hier',
            tomorrow: 'Demain',
            last_year: "L'année dernière",
            last_week: 'La semaine dernière',
            last_month: 'Le mois dernier',
            next_period: 'Prochain {{label}} - {{date}}',
            last_period: 'Dernier {{label}} - {{date}}',
        },
        tutorial: {
            finishButton: 'Commencez votre Saga',
            writeWthEase: {
                title: 'Écrire avec facilité',
                description: "L'éditeur rapide et simple de Saga vous aide à créer de belles notes et documents.",
            },
            autolink_pages: {
                title: 'Liaison automatique des pages',
                description: 'Saga reconnaît lorsque vous mentionnez un titre de page et crée automatiquement un lien.',
            },
            autocomplete_menu: {
                title: 'Le menu de saisie automatique',
                description:
                    "<1>Insérez des blocs, liez des pages.</1><2>Tapez @ ou / dans l'éditeur pour l'essayer.</2>",
            },
            saga_ai: {
                title: 'Saga IA',
                description:
                    "L'assistant intelligent répond aux questions, génère du contenu et vous aide à travailler plus rapidement.",
            },
            unified_search: {
                title: 'Recherche unifiée',
                description:
                    "<1>Passer rapidement d'une page à l'autre ou rechercher dans tout votre espace de travail.</1><2>Appuyez sur {{searchHeading}} pour l'essayer.</2>",
            },
        },
        onboarding: {
            complete_signup: "Complétez l'inscription",
            start_your_saga: 'Commençons votre Saga',
            i_agree_with_toc:
                "J'accepte la <1>Politique de confidentialité</1> et les <2>Conditions d'utilisation</2> de Saga",
            create_first_workspace: 'Créons votre premier espace de travail',
            create_workspace: 'Créons votre espace de travail',
            create_workspace_description:
                "Un espace de travail est l'endroit où vos pages, idées et connaissances se connectent et s'organisent.",
            what_is_this_workspace_for: 'À quoi sert cet espace de travail ?',
            usecase_personalNotes: 'Notes personnelles',
            usecase_teamWork: "Travail d'équipe",
            open_workspace: "Ouvrir l'espace de travail",
            what_is_this_workspace: 'À quoi sert cet espace de travail ?',
            login: {
                title: 'Commençons votre Saga',
                with_google: 'Continuer avec Google',
                with_email: 'Continuer avec Email',
                link_has_expired:
                    '🤔 Votre lien a expiré, ou vous essayez de vous connecter depuis un autre appareil.\n\nVeuillez vous reconnecter 👇',
                toc: 'En cliquant sur "Continuer avec Google/Email" ci-dessus, vous acceptez notre <1>Politique de confidentialité</1> et nos <2>Conditions d\'utilisation</2>.',
                info: "Nous vous connecterons ou créerons un compte si vous n'en avez pas encore.",
                desktop_app_button: "Téléchargez l'application de bureau",
            },
            magic_link_login: {
                title: '📨 Vérifiez votre boîte de réception',
                subtitle: 'Nous venons de vous envoyer un lien de connexion à cette adresse email :',
                enter_email: 'Entrez votre adresse email...',
                subtitle_email_for_join:
                    "Nous venons de vous envoyer un lien de connexion à cette adresse email pour rejoindre l'espace de travail :",
                welcome_to: 'Bienvenue chez {{-workspace}}',
                welcome_to_page: 'Bienvenue sur {{-page}}',
                close_this_tab: 'Vous pouvez maintenant fermer cet onglet.',
                invite_for_join:
                    "Ceci est une invitation pour <1>{{email}}</1>.\nConnectez-vous avec la même adresse pour l'accepter.",
                invite_not_active: "Cette invitation n'est plus active",
                request_new_invite_short:
                    'Contactez un propriétaire de cet espace de travail et demandez une nouvelle invitation.',
                request_new_page_invite_short:
                    'Contactez un administrateur de cette page et demandez une nouvelle invitation.',
                not_have_access: "Vous n'avez pas accès à cet espace de travail",
                not_have_page_access: "Vous n'avez pas accès à cette page",
                logged_in_with_different_email:
                    "Vous êtes connecté avec <1>{{email}}</1>. <2>Connectez-vous</2> avec une autre adresse email ou contactez un propriétaire de cet espace de travail pour demander l'accès.",
                logged_in_with_different_email_page:
                    "Vous êtes connecté avec <1>{{email}}</1>. <2>Connectez-vous</2> avec un email différent ou contactez un administrateur de cette page pour demander l'accès.",
                request_new_invite:
                    "Contactez un propriétaire de cet espace de travail et demandez une nouvelle invitation.\nRetournez à la <1>Page d'accueil</1> et créez votre propre espace de travail à la place",
            },
        },
        help: {
            give_feedback: 'Donner un retour',
            give_feedback_tooltip:
                '🚀 Vous faites partie des pionniers de Saga (rejoint {{userCreatedDate}}). Nous travaillons dur pour livrer des améliorations et de nouvelles fonctionnalités à la vitesse de la lumière, si vous avez des retours pour nous, envoyez-nous un email à feedback@saga.so',
            contact_us: 'Contactez-nous',
            join_slack: 'Rejoignez sur Slack',
            join_discord: 'Rejoignez sur Discord',
            youtube_channel: 'Chaîne Youtube',
            tweet_us: 'Tweetez-nous',
            open_website: 'Ouvrir le site Web',
            help_guide: "Guide d'aide",
            start_tutorial: 'Commencer le tutoriel',
        },
        top_menu: {
            search: 'Rechercher...',
            searching: 'Recherche en cours...',
            searching_for: 'Recherche de :',
            search_dotted: 'Rechercher...',
            search_shortcut: 'Rechercher Ctrl/Cmd + K',
            add_page_to_favorites: 'Ajouter la page aux favoris',
            add_collection_to_favorites: 'Ajouter la collection aux favoris',
            add_task_to_favorites: 'Ajouter la tâche aux favoris',
            add_task_view_to_favorites: 'Ajouter la vue de tâche aux favoris',
            add_page_view_to_favorites: 'Ajouter la vue de page aux favoris',
            add_page_to_pinned: 'Ajouter la page aux épinglés',
            add_collection_to_pinned: 'Ajouter la collection aux épinglés',
            add_task_to_pinned: 'Ajouter la tâche aux épinglés',
            add_task_view_to_pinned: 'Ajouter la vue de tâche aux épinglés',
            add_page_view_to_pinned: 'Ajouter la vue de page aux épinglés',
            search_for_or_create_new: 'Rechercher ou créer nouveau',
            search_for_collection_label: 'Sélectionner ou créer une collection',
            create_collection: '— Créer une collection',
            create_page: '— Créer une nouvelle page',
            close_search_results: 'Fermer les résultats de recherche',
            share: {
                label: 'Partager',
                share_with: "Partagé avec les membres de l'espace de travail",
                copy_link: 'Copier le lien',
                copy_link_tooltip: "En utilisant le lien public, la page sera accessible par n'importe qui.",
                copy_public_link: 'Copier le lien public',
                copy_public_link_tooltip:
                    "En utilisant le lien privé, la page ne peut être accédée que par vous ou d'autres membres de l'espace de travail.",
                link_copied: 'Lien copié !',
                members_count: 'membres de {{-workspaceTitle}}',
                share_count_zero: 'Partagé uniquement avec vous',
                share_count_one: 'Partagé uniquement avec vous',
                share_count_other: 'Partagé avec <1>{{membersCount}} personnes</1>.',
                manage: 'Gérer',
                manage_members: 'Gérer les membres',
                view_members: 'Voir les membres',
                publish: 'Publier',
                page_not_published: "Publiez cette Page, puis n'importe qui avec le lien peut <1>la voir</1>.",
                page_published: "N'importe qui avec le lien peut <1>voir cette Page</1>.",
                workspace_not_published:
                    "Publiez tout l'Espace de travail, puis n'importe qui peut <1>voir toutes les pages</1> à l'intérieur",
                workspace_published:
                    "N'importe qui avec le lien peut <1>voir toutes les pages</1> à l'intérieur de cet Espace de travail.",
                copying_page: 'Permettre la copie de la page',
                members_one: '1 membre',
                members_other: '{{count}} membres',
                copying_page_title: 'Choisissez un espace de travail',
                copying_page_sub_title: 'La page sera dupliquée dans :',
                copying_page_toast_success: 'Page dupliquée avec succès !',
                copying_workspace: "Permettre la copie de l'Espace de travail",
                copying_workspace_toggle: "N'importe qui avec le lien peut copier et dupliquer cet espace de travail.",
                copying_page_disabled_tooltip:
                    'Vous êtes un spectateur dans cet espace de travail et vous ne pouvez pas copier cette page.',
                invite_members: 'Inviter de nouveaux membres',
                share_with_members: "Partagé avec les membres de l'espace de travail",
                private_access: 'Accès privé',
                members_with_access: 'Membres ayant accès à la page',
                guests_with_access: 'Invités ayant accès à la page',
                permission_admin_description: 'Peut supprimer la page ou gérer les permissions de la page',
                permission_viewer_description: 'Peut voir le contenu de la page.',
                permission_editor_description: 'Peut modifier et supprimer le contenu de la page',
                invite_guest: "Tapez l'email pour inviter un invité...",
                type_email: "Tapez l'email...",
            },
        },
        move_page: {
            move_modal_title: 'Choisissez un espace de travail',
            move_modal_sub_title: 'Déplacer la page vers :',
            toast_success: 'Page déplacée avec succès !',
            confirm_modal_title:
                'Certains contenus et paramètres, comme les liens et les permissions, peuvent ne pas fonctionner sur la page déplacée.',
            confirm_modal_content_left: 'La page originale sera archivée inchangée.',
            confirm_modal_content_right: "Un duplicata sera créé dans l'espace de travail sélectionné.",
            confirm_changes: 'Confirmer le déplacement',
        },
        move_task: {
            move_modal_title: 'Choisissez un espace de travail',
            move_modal_sub_title: 'Déplacer la tâche vers :',
            toast_success: 'Tâche déplacée avec succès !',
            confirm_modal_title:
                'Certains contenus et paramètres, comme les liens et autorisations, peuvent ne pas fonctionner sur la tâche déplacée.',
            confirm_modal_content_left: 'La tâche originale sera archivée sans modification.',
            confirm_modal_content_right: "Un duplicata sera créé dans l'espace de travail sélectionné.",
            confirm_changes: 'Confirmer le déplacement',
        },

        sidebar: {
            new_page: 'Nouvelle page',
            create_page: 'Créer une Page',
            create_collection: 'Créer une Collection',
            new_workspace: 'Nouvel Espace de travail',
            open_template_menu: 'Ouvrir le Menu des modèles',
            pages: 'Pages',
            tasks: 'Tâches',
            settings: 'Paramètres',
            pinned: 'Épinglé',
            favorites: 'Favoris',
            logout: 'Se déconnecter',
            collections: 'Collections',
            shared: 'Partagé avec moi',
            notifications: {
                dont_have_notifications: "Vous n'avez pas encore de notifications.",
                accept: 'Accepter',
                manage: 'Gérer',
                invite_message: "{{name}} vous a invité à rejoindre l'espace de travail",
                user_joined: '{{name}} a rejoint votre espace de travail',
                user_left: '{{name}} a quitté votre espace de travail',
                user_removed: "{{name}} vous a retiré de l'espace de travail",
                go_to_workspace: "Aller à l'espace de travail",
                go_to_page: 'Aller à la Page',
                go_to_task: 'Aller à la Tâche',
                load_more: 'Charger plus',
                mentioned_you: 'vous a mentionné dans',
                assigned_you: 'vous a assigné à',
                unassigned_you: 'vous a désassigné de',
                invite_page_message: 'vous a invité sur sa page',
            },
            upgrade_plan_modal: {
                title: 'Améliorez votre plan',
                description:
                    "Pour débloquer un nombre illimité d'espaces de travail et accéder à des outils de collaboration avancés et à des fonctionnalités super supplémentaires qui porteront votre travail au niveau supérieur.",
            },
        },
        filters: {
            title: 'Titre',
            az: 'A → Z',
            za: 'Z → A',
            created: 'Créé',
            updated: 'Mis à jour',
            creators: {
                all_creators: 'Tous les créateurs',
                no_creators: 'Aucun créateur',
                n_creators_other: '{{count}} créateurs',
                n_creators_one: '{{count}} créateur',
                search_creator_placeholder: 'Rechercher des membres...',
            },
        },
        pages: {
            title_pages: 'Pages',
            title_deleted_pages: 'Pages supprimées',
            title_deleted: 'Supprimé',
            title_templates: 'Modèles',
            title_shared: 'Partagé',
            title_private: 'Privé',
            title_public: 'Public',
            fewest_items_first: "Le moins d'éléments en premier",
            most_items_first: "Le plus d'éléments en premier",
            collection: 'Collection',
            fewest_words_first: 'Le moins de mots en premier',
            most_words_first: 'Le plus de mots en premier',
            word_count: 'Nombre de mots',
            oldest_first: 'Le plus ancien en premier',
            newest_first: 'Le plus récent en premier',
            close_pages: 'Fermer les pages',
            empty_state_deleted: "Vous n'avez pas de pages supprimées.",
            empty_state_non_deleted: 'Ajoutez des pages pour les voir ici.',
            empty_state_templates: 'Ajoutez des modèles pour les voir ici.',
            empty_state_shared: "Vous n'avez aucune page partagée avec vous.",
            empty_state_private: "Vous n'avez pas de pages privées.",
            empty_state_public: "Vous n'avez pas de pages publiques.",
            enter_page_name: 'Entrez un nom de page',
            favourite_untitled: 'Sans titre',
            delete_all_forever: 'Supprimer tout définitivement',
            delete_all_page:
                'Êtes-vous sûr de vouloir supprimer toutes les pages ? Cette action ne peut pas être annulée.',
            delete_page_toast_title: 'La page est supprimée',
            delete_page_toast_description:
                "Les pages supprimées sont stockées dans les Archives jusqu'à ce qu'elles soient supprimées définitivement.",
            history: {
                history: 'Histoire',
                current: 'Actuel',
                versions_count: '({{number}} versions)',
                versions: 'Versions',
                upgrade_required: 'Mise à niveau requise',
                upgrade_modal: {
                    title: 'Mettre à niveau le plan',
                    description:
                        "Passez à un plan payant pour accéder à l'historique des versions de plus de 7 jours et débloquer d'autres fonctionnalités premium.",
                },
            },
            mode_dropdown: {
                pages: 'Pages',
                templates: 'Modèles',
                deleted_pages: 'Pages supprimées',
            },
            new_page_placeholder: 'Nouvelle page',
            new: {
                create: 'Créer',
                label_page: 'Nouvelle Page',
                placeholder_page: 'Tapez un nouveau titre de page...',
                label_template: 'Nouveau modèle',
                placeholder_template: 'Tapez un nouveau titre de page...',
            },
            headers: {
                title: 'Titre',
                collection: 'Collection',
                word_count: 'Nombre de Mots',
                created: 'Créé',
                updated: 'Mis à jour',
                shared_by: 'Partagé par',
                role: 'Rôle',
                creator: 'Créateur',
            },
            deleted_page: 'Cette page est supprimée',
            restore: 'Restaurer',
            restore_page: 'Restaurer la page',
            delete_forever: 'Supprimer définitivement',
        },
        references: {
            label: 'Références',
            tooltip: 'Les références montrent où une page a été mentionnée dans tout votre espace de travail.',
            result_one: '1 résultat',
            result_other: '{{count}} résultats',
            no_results: 'Aucune référence trouvée pour cette page.',
            no_results_search_panel: 'Aucun résultat trouvé. Essayez une autre recherche.',
        },
        table_of_content: {
            label: 'Table des matières',
        },
        properties: {
            label: 'Propriétés',
        },
        page_info: {
            label: 'Infos de la Page',
            creator: 'Créateur',
            created: 'Créé',
            updated: 'Mis à jour',
            word_count: 'Nombre de Mots',
            page_settings: 'Paramètres de la Page',
            autolink_pages_label: 'Liaison automatique des pages',
            autolink_pages_description:
                "Lier automatiquement cette page lorsque son titre est mentionné dans d'autres pages",
            autolink_headings_label: 'Liaison automatique des titres',
            autolink_headings_description:
                "Lier automatiquement les titres de cette page lorsqu'ils sont mentionnés dans d'autres pages",
            full_width_label: 'Page en pleine largeur',
            full_width_description: 'Afficher la page en pleine largeur.',
        },
        tasks: {
            title: 'Tâches',
            new_task: 'Nouvelle Tâche',
            new_task_placeholder: 'Entrez un titre de tâche',
            new_task_title_placeholder: 'Nouvelle tâche',
            new_task_editor_placeholder: 'Entrez un titre de tâche',
            createdAt: 'Créé',
            updatedAt: 'Mis à jour',
            completedDate: 'Complété',
            due_date: "Date d'échéance",
            no_due_date: "Aucune date d'échéance",
            sort_asc: 'Ascendant',
            sort_desc: 'Descendant',
            sorted_by: 'Trié par',
            task_title: 'Titre de la tâche',
            referenced: 'Référencé dans',
            assignee: 'Assigné à',
            no_assignee: 'Non assigné',
            priority: 'Priorité',
            no_priority: 'Aucune priorité',
            priority_all: 'Toutes les priorités',
            priority_high: 'Haute',
            priority_medium: 'Moyenne',
            priority_low: 'Basse',
            priority_unprioritized: 'Sans priorité',
            add_assignee_placeholder: 'Entrez un nom...',
            incomplete_tasks: 'Tâches incomplètes',
            incomplete_tasks_short: 'Incomplètes',
            completed_tasks: 'Tâches complétées',
            completed_tasks_short: 'Complétées',
            all_tasks: 'Toutes les tâches',
            all_tasks_short: 'Toutes',
            all_assignees: 'Tous les assignés',
            not_assigned: 'Non assigné',
            close_task_one: 'Fermer la tâche',
            close_task_other: 'Fermer les tâches',
            add_task_below: 'Ajouter une tâche ci-dessous',
            delete_task: 'Supprimer la tâche',
            confirm_delete_task:
                "Êtes-vous sûr de vouloir supprimer cette tâche ? Elle sera supprimée de tout l'espace de travail.",
            create_task_to_see_them: 'Créez des tâches pour les voir ici.',
            status_open: 'À Faire',
            status_in_progress: 'En Cours',
            status_done: 'Fait',
            status: 'Statut',
            archived: 'Archivé',
            deleted_task: 'Cette tâche est supprimée',
            restore_task: 'Restaurer la tâche',
            delete_all_tasks:
                'Êtes-vous sûr de vouloir supprimer toutes les tâches? Cette action ne peut pas être annulée.',
            delete_task_toast_title: 'La tâche est supprimée',
            delete_task_toast_description:
                "Les tâches supprimées sont stockées dans les Archives jusqu'à ce qu'elles soient supprimées définitivement.",
            new_label_placeholder: "Entrez un nom d'étiquette...",
            select_or_create_label: 'Créer nouveau ou sélectionner',
            add_new_label: 'Ajouter une nouvelle étiquette',
            no_label: 'Aucune étiquette',
            no_labels: 'Aucune étiquette',
            n_labels_one: '{{count}} étiquette',
            n_labels_other: '{{count}} étiquettes',
            all_labels: 'Toutes les étiquettes',
            table_view: 'Vue Table',
            kanban_view: 'Vue Kanban',
        },
        collections: {
            page_title: 'Collections',
            filter_placeholder: 'Filtrer par mot-clé',
            add_to_collection: 'Ajouter à la collection',
            add_page_to_collection: 'Ajouter une page à la collection interne',
            delete_collection: 'Supprimer la collection',
            add_parent_collection: 'Ajouter une collection parente',
            type_page_task_or_collection: 'Tapez une page/tâche ou une collection...',
            select_create_new: 'Sélectionner ou créer nouveau',
            new_collection: 'Nouvelle Collection',
            new_collection_placeholder: 'Tapez une nouvelle Collection...',
            empty_state_message: 'Créez des collections pour les voir ici.',
            sort: 'Trier',
            folder_view: 'Vue Dossier',
            tag_view: 'Vue Étiquette',
            pages: 'Pages',
            sub_collections: 'Sous-collections',
            oldest_first: 'Les plus anciens en premier',
            newest_first: 'Les plus récents en premier',
            fewest_first: 'Le moins de pages en premier',
            most_first: 'Le plus de pages en premier',
            sub_fewest_first: 'Le moins en premier',
            sub_most_first: 'Le plus en premier',
            close_collections: 'Fermer les collections',
            empty_state: 'Ajoutez des pages ou des collections à cette collection pour les voir ici.',
        },
        views: {
            save_view: 'Enregistrer',
            save_view_name_placeholder: 'Nom de la vue ...',
            create_new_button: 'Créer une nouvelle vue',
            update_button: 'Mettre à jour la vue',
            delete_view: 'Supprimer la vue',
        },
        settings: {
            sidebar: {
                account: 'Compte',
                title: 'Paramètres',
                profile: 'Profil',
                language: 'Langue',
                appearance: 'Apparence',
                editor: 'Éditeur',
                invites: 'Invitations',
                integrations: 'Intégrations',
                general: 'Général',
                members: 'Membres',
                plans: 'Plans',
                billing: 'Facturation',
                import_export: 'Importer & Exporter',
            },
            profile: {
                title: 'Profil',
                sub_title: 'Gérez votre profil Saga',
                your_email: 'Votre Email',
            },
            language: {
                title: 'Langue',
                sub_title: "Changez la langue utilisée dans l'interface utilisateur.",
                language: 'Langue',
                language_change_title: 'Mettre à jour la langue',
                language_change_confirmation: 'Êtes-vous sûr de vouloir mettre à jour la langue ? Saga va redémarrer !',
                language_changed_notification: 'Langue mise à jour',
            },
            appearance: {
                title: 'Apparence',
                sub_title: "Personnalisez l'apparence de Saga.",
                theme: 'Thème',
                show_icons: 'Afficher les icônes dans les liens de page en ligne',
                mode_auto: 'Mode Auto',
                mode_light: 'Mode Clair',
                mode_dark: 'Mode Sombre',
                start_week_on_monday: 'Commencer la semaine le lundi',
                font_default: 'Défaut',
                interface_font: "Police de l'interface",
                text_font: 'Police du texte',
                example_text: "Texte d'exemple pour tester votre police",
            },
            editor: {
                title: 'Éditeur',
                sub_title: "Personnalisez votre expérience d'édition.",
                spellcheck: 'Vérification orthographique',
            },
            invite: {
                title: "Invitations à l'espace de travail",
                sub_title: "Voir et accepter toutes vos invitations à l'espace de travail",
                no_invites: "Vous n'avez pas d'invitations à l'espace de travail pour le moment.",
                invite_members_to: 'Inviter des membres',
                max_invites_reached:
                    '<bold>{{maxInvitedMembers}}/{{totalMembers}}</bold> invitations gratuites restantes. <upgrade>Améliorez</upgrade> le plan pour des invitations illimitées',
                accept_invite: "Accepter l'invitation",
                invite_accepted: 'Invitation acceptée',
                owned_by: 'appartenant à <1>{{ownerEmail}}</1>',
            },
            page_invite: {
                title: 'Invitations de page',
                sub_title: 'Voir et accepter toutes vos invitations de page',
                no_invites: "Vous n'avez pas d'invitations de page pour le moment.",
            },
            integrations: {
                title: 'Intégrations',
                sub_title: "Intégrez d'autres outils avec Saga",
                remove_integration: 'Êtes-vous sûr de vouloir supprimer cette intégration ?',
                google_drive: {
                    title: 'Google Drive',
                    description:
                        'Recherchez dans votre drive et mentionnez des fichiers dans des pages et des tâches. <1>En savoir plus</1>',
                    button_connect: 'Se connecter à Google Drive',
                    button_disconnect: "Supprimer l'intégration",
                    last_updated: 'Dernière mise à jour {{date}}',
                },
                linear: {
                    title: 'Linear',
                    description:
                        'Recherchez dans Linear et mentionnez des problèmes de votre espace de travail Linear. <1>En savoir plus</1>',
                    button_connect: 'Se connecter à Linear',
                    button_disconnect: "Supprimer l'intégration",
                },
            },
            general: {
                title: 'Général',
                sub_title: 'Gérez les paramètres généraux de votre espace de travail',
                workspace_name: "Nom de l'espace de travail",
                icon: 'Icône',
                icon_description: "Changez la couleur de l'icône de l'espace de travail.",
                embeddings: "Intégrations de l'espace de travail",
                embeddings_description: 'Générez des intégrations pour cet espace de travail.',
                copy_workspace: "Copier l'espace de travail",
                copy_workspace_description:
                    'Toutes les pages, collections et paramètres de cet espace de travail seront copiés dans un nouvel espace de travail.',
                delete_workspace: "Supprimer l'espace de travail",
                delete_workspace_description:
                    "La suppression d'un espace de travail supprimera toutes ses données. Cela ne peut pas être annulé.",
                delete_workspace_confirmation: 'Êtes-vous sûr de vouloir supprimer cet espace de travail ?',
                leave_workspace: "Quitter l'espace de travail",
                leave_workspace_confirmation: 'Êtes-vous sûr de vouloir quitter cet espace de travail ?',
                autolink: 'Lien automatique',
                autolink_description:
                    'Lorsque cela est activé, Saga crée automatiquement des liens vers les titres de pages dans les pages et les tâches.',
            },
            members: {
                title: 'Membres',
                sub_title: "Invitez d'autres personnes à cet espace de travail",
                add_member: 'Ajouter un membre',
                remove_member: 'Supprimer le membre',
                remove_member_confirmation: 'Êtes-vous sûr de vouloir supprimer {{name}} ?',
                remove_confirm: 'Confirmer la suppression',
                remove_member_toast_success: 'Membre supprimé',
                pending_invite: 'Invitation en attente',
                revoke_invite: "Révoquer l'invitation",
                revoke_invite_confirmation: "Êtes-vous sûr de vouloir révoquer l'invitation ?",
                revoke_confirm: 'Confirmer la révocation',
                revoke_invite_toast_success: 'Invitation révoquée',
                paid_feature: 'Fonctionnalité payante',
                role_admin: 'Admin',
                role_page_admin: 'Administrador de página',
                role_no_access: 'Aucun accès',
                role_admin_tooltip:
                    "Ce membre peut <1>créer, éditer et supprimer du contenu</1> dans l'espace de travail et <1>inviter d'autres personnes</1>. Ils peuvent également <1>gérer les paramètres</1> et <1>supprimer l'espace de travail</1>.",
                role_editor: 'Éditeur',
                role_editor_tooltip:
                    "Ce membre peut <1>créer, éditer et supprimer du contenu</1> dans l'espace de travail.",
                role_viewer: 'Visualiseur',
                role_viewer_tooltip: "Ce membre peut <1>voir le contenu</1> dans l'espace de travail.",
                role_member: 'Membre',
                role_member_tooltip:
                    "Ce membre peut <1>créer, modifier et supprimer du contenu</1> dans l'espace de travail et <1>inviter d'autres. Ils peuvent également rendre les pages/espaces de travail publics</1> dans l'espace de travail.",
                role_blocked_tooltip: "Ce membre n'a pas accès à l'espace de travail",
                invite_members: 'Inviter des membres à {{-workspaceName}}',
                plus_add_more: '+ Ajouter plus',
                send_invites: 'Envoyer des invitations',
                invites_sent: 'Invitations envoyées',
                upgrade_plan: 'Améliorez votre plan',
                upgrade_plan_details:
                    "Vous avez atteint la limite de {{count}} utilisateurs gratuits. Améliorez votre plan pour inviter plus d'utilisateurs et débloquer des fonctionnalités supplémentaires pour passer au niveau supérieur.",
            },
            plans: {
                title: "Plans de l'espace de travail",
                loading: 'Chargement ...',
                sub_title_free:
                    'Utilisez Saga gratuitement, <bold>améliorez pour une meilleure collaboration</bold>, <bold>gestion des tâches</bold>, et <bold>Saga IA illimité</bold>.',
                sub_title_monthly_one:
                    'Votre abonnement actuel est le plan Mensuel Standard. Votre espace de travail de <bold>{{membersCount}} membre</bold> coûte <bold>{{amount}}</bold> par mois, et sera renouvelé le <bold>{{date}}</bold>.',
                sub_title_monthly_other:
                    'Votre abonnement actuel est le plan Mensuel Standard. Votre espace de travail de <bold>{{membersCount}} membres</bold> coûte <bold>{{amount}}</bold> par mois, et sera renouvelé le <bold>{{date}}</bold>.',
                sub_title_yearly_one:
                    'Votre abonnement actuel est le plan Annuel Standard. Votre espace de travail de <bold>{{membersCount}} membre</bold> coûte <bold>{{amount_month}}</bold> par mois / <bold>{{amount_year}}</bold> par an, et sera renouvelé le <bold>{{date}}</bold>.',
                sub_title_yearly_other:
                    'Votre abonnement actuel est le plan Annuel Standard. Votre espace de travail de <bold>{{membersCount}} membres</bold> coûte <bold>{{amount_month}}</bold> par mois / <bold>{{amount_year}}</bold> par an, et sera renouvelé le <bold>{{date}}</bold>.',
                sub_title_monthly_canceled_one:
                    "Le plan de votre espace de travail de <bold>{{membersCount}} membre</bold> coûte <bold>{{amount}}</bold> par mois. Vous avez annulé ce plan, mais il restera actif jusqu'au <bold>{{date}}</bold>. Après cela, vous serez transféré à un plan Gratuit.",
                sub_title_monthly_canceled_other:
                    "Le plan de votre espace de travail de <bold>{{membersCount}} membres</bold> coûte <bold>{{amount}}</bold> par mois. Vous avez annulé ce plan, mais il restera actif jusqu'au <bold>{{date}}</bold>. Après cela, vous serez transféré à un plan Gratuit.",
                sub_title_yearly_canceled_one:
                    "Le plan de votre espace de travail de <bold>{{membersCount}} membre</bold> coûte <bold>{{amount_month}}</bold> par mois ou <bold>{{amount_year}}</bold> par an. Vous avez annulé ce plan, mais il restera actif jusqu'au <bold>{{date}}</bold>. Après cela, vous serez transféré à un plan Gratuit.",
                sub_title_yearly_canceled_other:
                    "Le plan de votre espace de travail de <bold>{{membersCount}} membres</bold> coûte <bold>{{amount_month}}</bold> par mois ou <bold>{{amount_year}}</bold> par an. Vous avez annulé ce plan, mais il restera actif jusqu'au <bold>{{date}}</bold>. Après cela, vous serez transféré à un plan Gratuit.",
            },
            billing: {
                title: 'Facturation',
                sub_title: 'Gérez vos informations de facturation et vos factures.',
                per_month: "par membre de l'espace de travail / mois",
                free_plan: 'Plan gratuit',
                standard_annual_plan: 'Plan annuel standard',
                standard_monthly_plan: 'Plan mensuel standard',
                billing_portal_label: 'Voir et modifier les détails de facturation',
                invoices: {
                    title: 'Factures',
                    loading: 'Chargement des factures...',
                    no_invoices: "Il n'y a pas de factures à afficher.",
                    no_upcoming_invoices: 'Pas de factures à venir',
                    next_billing_on_one:
                        'Vous serez facturé <1>${{amount}}</1> le <1>{{date}}</1> pour <1>{{billableMembersCount}} membre</1>',
                    next_billing_on_other:
                        'Vous serez facturé <1>${{amount}}</1> le <1>{{date}}</1> pour <1>{{billableMembersCount}} membres</1>',
                    see_invoice: 'Voir la facture',
                    invoice_not_ready: 'Facture non prête',
                    paid: 'Payé',
                    not_paid: 'Non payé',
                },
            },
            export_import: {
                title: 'Import & Export',
                sub_title: "Importer et exporter l'espace de travail et gérer les paramètres d'exportation.",
                import_workspace: 'Importer des données',
                import_workspace_description:
                    'Importer des pages, tâches ou espace de travail Saga à partir de fichiers markdown, texte ou JSON.',
                import_workspace_cta: "Importer l'espace de travail",
                export_workspace: "Exporter l'espace de travail",
                export_workspace_description: "Exporter l'espace de travail en tant que fichiers JSON.",
                export_workspace_cta: "Exporter l'espace de travail",
                import_modal: {
                    title: 'Déposez vos fichiers pour importer',
                    upload_button: 'Télécharger depuis votre ordinateur',
                    max_file_size: 'max. {{size}} Mo par fichier',
                    supported_file_types: 'Pris en charge: Markdown, JSON',
                    unexpected_error_message:
                        "Une erreur inattendue s'est produite.Veuillez réessayer ou nous contacter à <email/>",
                    unsupported_error_title: 'Format de fichier non pris en charge',
                    unsupported_error_description: 'Veuillez glisser-déposer des dossiers, fichiers Markdown ou JSON.',
                    successfuly_imported_files_one: 'Importé avec succès {{count}} fichier',
                    successfuly_imported_files_other: 'Importé avec succès {{count}} fichiers',
                    confirm_title: 'Importer les fichiers en tant que',
                    add_to_collection: 'Ajouter à la collection (optionnel)',
                    confirm_button: 'Importer',
                    page: 'Pages',
                    task: 'Tâches',
                },
            },
            ai: {
                title: 'Saga IA',
                sub_title:
                    "Soyez créatif, écrivez plus vite, et travaillez mieux directement dans Saga avec l'aide d'un assistant IA numérique.",
                confirm_delete_command: 'Voulez-vous supprimer cette commande ?',
                confirm_delete_prompt: 'Voulez-vous supprimer cette invite ?',

                commands: {
                    title: 'Commandes',
                    add_command: 'Ajouter une Commande',
                    customize_prompts: 'Personnalisez vos invites et commandes IA. <1>Voir comment ça fonctionne</1>.',
                    add_prompt: 'Ajouter une Invite',
                },
                new: {
                    title_create_prompt: 'Créer une invite personnalisée',
                    title_edit_prompt: 'Modifier votre invite',
                    title_create_command: 'Créer une commande personnalisée',
                    title_edit_command: 'Modifier votre commande',
                    custom_command_tooltip: 'Le texte sélectionné sera placé à la fin de votre invite.',
                    command_name_placeholder: 'Nom de la commande',
                    prompt_name_placeholder: "Nom de l'invite",
                    command_placeholder: 'Écrivez votre commande ici...',
                    prompt_placeholder: 'Écrivez votre invite ici...',
                },
            },
            labels: {
                title: 'Étiquettes',
                sub_title: 'Ajouter et modifier vos étiquettes',
                add_label: 'Ajouter une étiquette',
                name: 'Nom',
                usage: 'Utilisation',
                options: 'Options',
                edit_modal: {
                    title_create: 'Créer une étiquette',
                    title_edit: "Modifier l'étiquette",
                    select_color: "Sélectionner une couleur d'étiquette",
                    label_name: "Nom de l'étiquette",
                },
                delete_label_confirmation_title: 'Supprimer l\'étiquette "{{title}}"',
                delete_label_confirmation_description:
                    "L'étiquette sera supprimée de {{count}} tâches.\n\nCette action ne peut pas être annulée.",
            },
        },
        billing: {
            free: 'Gratuit',
            free_title: 'Plan Gratuit',
            free_description: 'Pour individus et petites équipes',
            standard_title: 'Plan Standard',
            standard_description: 'Idéal pour les équipes de taille moyenne',
            business_title: 'Plan Entreprise',
            business_description: 'Idéal pour les grandes organisations',
            monthly: 'Mensuel',
            annually: 'Annuel',
            downgrade: 'Rétrograder',
            upgrade_plan: 'Changer de plan',
            current_plan: 'Plan actuel',
            planned: 'Prévu',
            join_waitlist: "Rejoindre la liste d'attente",
            per_editor_month: 'par membre / mois',
            per_space_editor_month: "par membre de l'espace de travail / mois",
            desktopModal: {
                title: 'Changer de Plan de Facturation',
                description:
                    "Changer votre plan de facturation nécessite un navigateur web. En continuant, un nouvel onglet de navigateur s'ouvrira, vous redirigeant vers la page de facturation de notre application. Veuillez compléter vos modifications de facturation dans le navigateur.",
                cta: 'Ouvrir dans le navigateur',
            },
            change_plan_modal_title: 'Êtes-vous sûr de vouloir changer la périodicité de facturation ?',
            plan_upgrade_success_toast: {
                title: 'Le plan a été mis à niveau',
                description: 'Vous avez maintenant un accès illimité à toutes les super fonctionnalités de Saga !',
            },
            downgrade_toast: {
                title: 'Votre demande de rétrogradation du plan a été acceptée',
                description:
                    "Vous pourrez utiliser toutes les fonctionnalités payantes jusqu'à l'expiration de votre abonnement.",
            },
            upgrade_to_yearly_toast: {
                title: 'Intervalle de facturation modifié',
            },
            change_plan_modal_description_one:
                'La facturation de votre compte passera de mensuelle à annuelle immédiatement, les crédits de votre plan actuel seront transférés vers un nouveau plan et vous serez facturé <bold>{{price}}</bold> pour <bold>un</bold> utilisateur.',
            change_plan_modal_description_other:
                'La facturation de votre compte passera de mensuelle à annuelle immédiatement, les crédits de votre plan actuel seront transférés vers un nouveau plan et vous serez facturé <bold>{{price}}</bold> pour <bold>{{members}}</bold> utilisateurs.',
            free_plan_info_banner: {
                title: 'Nous sommes heureux de vous voir collaborer !',
                description:
                    'Vous êtes sur le plan gratuit qui inclut <bold>{{count}} membres</bold> dans cet espace de travail. <1>En savoir plus</1>',
            },
            upgrade_plan_banner: {
                title: 'Gestionnaire de tâches',
                description:
                    'Obtenez un aperçu de toutes vos tâches sur toutes vos pages. Recherchez et triez, trouvez les tâches dont vous avez besoin et accomplissez-les.',
                primary_button: 'Changer de plan',
                primary_button_default: 'Voir les plans',
                no_access_desciption: "Veuillez contacter le propriétaire de l'espace de travail.",
            },
            feedback_modal: {
                title: 'Nous sommes tristes de vous voir rétrograder :(',
                description:
                    "Nous avons créé Saga pour vous aider à organiser vos connaissances. Nous aimerions vraiment savoir s'il y a quelque chose qui nous manque ou que nous pourrions améliorer. Vos réflexions nous seraient très utiles.",
                placeholder:
                    'Veuillez partager pourquoi vous rétrogradez. Nous ferons de notre mieux pour améliorer Saga pour vous...',
                primary_button: 'Envoyer le retour',
            },
            downgrade_to_free_modal: {
                title: 'Êtes-vous sûr de vouloir rétrograder le plan à Gratuit ?',
                description:
                    "Votre espace de travail compte <span class='font-bold'>{{membersCount}} $t(billing.downgrade_to_free_modal.member, {\"count\": {{membersCount}} })</span>. Avec le plan gratuit, vous pouvez avoir <span class='font-bold'>jusqu'à {{allowedMembers}}</span>. À la fin de l'abonnement, <span class='font-bold'>{{membersLooseAccess}} $t(billing.downgrade_to_free_modal.member, {\"count\": {{membersLooseAccess}} }) perdront l'accès</span> à l'espace de travail, et les <span class='font-bold'>{{allowedMembers}} restants deviendront $t(billing.downgrade_to_free_modal.admin, {\"count\": {{allowedMembers}} }).</span>",
                member: 'membre',
                member_other: 'membres',
                admin: 'administrateur',
                admin_other: 'administrateurs',
                features_title: 'Vous perdrez ces super fonctionnalités :',
                feature_1: 'Saga IA illimité avec GPT-4',
                feature_2: 'Gestionnaire de tâches',
                feature_3: 'Permissions utilisateur',
                feature_4: "Membres de l'espace de travail illimités",
                primary_button: 'Garder le plan',
                secondary_button: 'Rétrograder',
            },
            ai_upgrade_plan_banner: {
                description: "Vos demandes d'IA gratuites ont été utilisées",
                primary_action: 'Changer de plan',
            },
            ai_popover_modal_upgrade: {
                title: 'Améliorez votre plan',
                description:
                    "Vos demandes d'IA gratuites ont été utilisées. Passez à un plan supérieur pour des réponses d'IA illimitées et des fonctionnalités supplémentaires pour booster votre productivité.",
            },
            modal_plans: {
                title: "Sélectionnez le plan de l'espace de travail",
                sub_title:
                    '<bold>Vous avez atteint la limite de {{count}} espaces de travail gratuits</bold>. Passez à un plan supérieur pour accéder à des super fonctionnalités supplémentaires qui porteront votre travail au niveau supérieur.',
                loading_plans: 'Chargement des plans...',
                footer_disclaimer:
                    "Tous les prix sont en USD et facturés par espace de travail. La TVA peut s'appliquer.",
                fair_usage: 'usage équitable',
                sagaAi_disclaimer: '*Saga AI soumis à <fairUsageLink/>.',
                footer_contacts: 'Des questions sur la facturation ? Demandez-nous <link/>.',
            },
            plan_features: {
                unlimited_pages_tasks: 'Pages & tâches illimitées',
                limited_members: "<bold>Jusqu'à {{count}}</bold> utilisateurs / espace de travail",
                unlimited_members: "<bold>Membres de l'espace de travail illimités</bold>",
                limited_saga_requests: 'Saga IA avec <bold>{{count}} mots</bold> / mois',
                unlimited_saga_requests: '<bold>Saga IA illimité</bold> avec GPT-4',
                limited_integrations: '<bold>{{count}}</bold> intégration : <google /> ou <linear />',
                unlimited_integrations: '<google /> et <linear /> intégrations',
                tooltip_integrations: {
                    google_drive: 'Ouvrir, rechercher et mentionner des fichiers Google Drive directement dans Saga',
                    linear: 'Mentionner, rechercher ou coller des liens directs vers des problèmes Linear dans Saga.',
                },
                apps: 'Mac et Windows',
                tooltip_sagaai: "Saga AI est l'assistant intelligent dans votre espace de travail.",
                tooltip_apps: {
                    content: 'Téléchargez les applications de bureau Saga pour une expérience encore meilleure.',
                    apps: 'applications',
                },
                task_manager: 'Gestionnaire de tâches',
                tooltip_task_manager: 'Gérez toutes vos tâches à un seul endroit.',
                user_permissions: 'Permissions utilisateur',
                advanced_user_permissions: 'Permissions utilisateur avancées',
                search_with_ai: 'Utilisez <sagaAi /> avec des outils et le web',
                version_history: 'Historique des versions',
                version_history_free: 'Historique des versions de 7 jours',
                version_history_standard: 'Historique des versions de 30 jours',
                version_history_business: 'Historique des versions illimité',
                offline_mode: 'Mode hors ligne',
                business_features_title: 'Tout dans Standard plus :',
                free_limitations: "*Jusqu'à {{count}} espaces de travail gratuits par compte",
                public_access: 'Pages et espaces de travail publics',
                custom_ai_commands: 'Commandes personnalisées <sagaAi />',
                ai_talk_to_your_page: '<sagaAi /> parle à vos pages',
                limited_upload_file: '{{count}} Mo taille maximale de fichier',
                limited_upload_total_mb: '{{count}} Mo stockage de fichiers',
                limited_upload_total_gb: '{{count}} Go stockage de fichiers',
                limited_guests: '{{count}} invités',
            },
            choose_workspace_modal: {
                title: 'Mise à niveau nécessaire',
                description:
                    "Vous avez atteint le nombre maximum d'espaces de travail gratuits. Pour accepter l'invitation à rejoindre le nouvel espace de travail, veuillez mettre à niveau l'un de vos espaces de travail gratuits existants.",
                go_to_plans: 'Aller aux plans',
            },
        },
        table: {
            move_left: 'Déplacer à Gauche',
            move_right: 'Déplacer à Droite',
            insert_column_left: 'Insérer une colonne à gauche',
            insert_column_right: 'Insérer une colonne à droite',
            remove_column: 'Supprimer la colonne',
            append_column: 'Ajouter une colonne',
            append_row: 'Ajouter une ligne',
            remove_row: 'Supprimer la ligne',
        },
    },
};
