import { track } from '@/analytics';
import { useSpace } from '@/components/SpaceProvider';
import { SpaceOperations } from '@saga/shared';
import { usePinned } from './SpaceHooks';

export const usePinnedButton = (pageId?: string) => {
    const { space } = useSpace();
    const pinned = usePinned();
    const isPinned = pageId ? pinned.includes(pageId) : false;

    const togglePinned = async () => {
        if (pageId == null) return;
        if (isPinned) {
            SpaceOperations.removePinnedById(space, pageId);
            track('remove-from-favorites');
        } else {
            SpaceOperations.addPinned(space, pageId);
            track('add-to-favorites');
        }
    };

    return { isPinned, togglePinned };
};
