type NodeAst = {
    value: number;
    left: NodeAst | null;
    right: NodeAst | null;
};

class TreeNode<Payload> {
    public left: TreeNode<Payload> | null = null;
    public right: TreeNode<Payload> | null = null;

    constructor(public value: number, public payload: Payload) {}

    public toJSON(): NodeAst {
        return {
            value: this.value,
            left: this.left ? this.left.toJSON() : null,
            right: this.right ? this.right.toJSON() : null,
        };
    }

    public toArray(): Payload[] {
        return [this.payload]
            .concat(this.left ? this.left.toArray() : [])
            .concat(this.right ? this.right.toArray() : []);
    }
}

export class BinarySearchTree<Payload> {
    public root: TreeNode<Payload> | null = null;

    public add(value: number, payload: Payload, condition?: (currentNode: TreeNode<Payload>) => boolean): void {
        const node = new TreeNode(value, payload);

        if (this.isEmpty()) {
            this.root = node;
        } else {
            let currentNode = this.root;

            while (currentNode) {
                if (condition && !condition(currentNode)) {
                    return;
                }

                if (value > currentNode.value) {
                    if (currentNode.right === null) {
                        currentNode.right = node;
                        return;
                    }

                    currentNode = currentNode.right;
                } else {
                    if (currentNode.left === null) {
                        currentNode.left = node;
                        return;
                    }

                    currentNode = currentNode.left;
                }
            }
        }
    }

    public isEmpty(): boolean {
        return this.root === null;
    }

    public toJSON(): NodeAst | null {
        return this.root?.toJSON() ?? null;
    }

    public toArray(): Payload[] {
        return this.root?.toArray() ?? [];
    }
}
