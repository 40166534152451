import { RealtimeSagaEditor } from '@saga/shared';
import { useEffect } from 'react';
import { Transforms } from 'slate';
import { ReactEditor } from 'slate-react';

type ArrowDownSelect = {
    editorRef: React.MutableRefObject<RealtimeSagaEditor | null>;
    paneIndex: number;
    editors: Set<RealtimeSagaEditor>;
};

export const useArrowDownSelect = ({ editorRef, paneIndex, editors }: ArrowDownSelect): void => {
    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (
                event.key === 'ArrowDown' &&
                editorRef.current &&
                !Array.from(editors).some(ReactEditor.isFocused) &&
                document.activeElement?.tagName === 'BODY'
            ) {
                event.preventDefault();

                !editorRef.current.selection &&
                    Transforms.select(editorRef.current, {
                        anchor: { offset: 0, path: [1, 0] },
                        focus: { offset: 0, path: [1, 0] },
                    });

                ReactEditor.focus(editorRef.current);
            }
        };

        paneIndex === 0 && document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [paneIndex, editorRef, editors]);
};
