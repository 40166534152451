import DocumentTitle from '@/components/DocumentTitle';
import HeaderBar from '@/components/navigation/HeaderBar';
import React from 'react';
import PublicPageEditor from '@/components/editor/PublicPageEditor';
import PublicPageBlockPluginProvider from './pluginProviders/PublicPageBlockPluginProvider';
import { PageMembersProvider, useMembers } from '@/components/MembersProvider';
import useInterfaceSettings from '@/hooks/useInterfaceSettings';
import useMobile from '@/hooks/useMobile';
import { useBlockPlugins } from './BlockPluginProvider';
import { PublicRightPanel } from './rightpanel/RightPanel';
import { Page, SagaEditor as SharedEditor, SagaEditor, SagaLocation } from '@saga/shared';
import { Leaf } from './editor/leaf/Leaf';
import { RenderElementProps, RenderLeafProps, useSlateStatic } from 'slate-react';
import { Helmet } from 'react-helmet-async';
import EmptyBlocksPlaceholder from '@/components/EmptyBlocksPlaceholder';
import { RealtimeDocumentPovider, useDocumentYBlocks } from '@/components/RealtimeDocumentProvider';

import { useCurrentPublicPage } from '@/components/PublicPageProvider';
import * as api from '@saga/api';
import { EditorAwareness } from '@/components/editor/EditorAwareness';
import BaseRenderElement from '@/components/editor/BaseRenderElement';
import { Suggestions } from '@/components/editor/Suggestions';

function PublicRightMenuContainer({
    children,
    page,
}: {
    children: React.ReactNode;
    page: Pick<Page, 'id' | 'blocks'>;
}) {
    const containerRef = React.useRef<HTMLDivElement | null>(null);
    const [{ rightMenu }, setInterfaceSettings] = useInterfaceSettings();
    const isMobile = useMobile();
    const blockPlugins = useBlockPlugins();

    return (
        <div ref={containerRef} className="flex h-full w-full relative min-h-0">
            {children}
            <PublicRightPanel
                rightMenu={rightMenu}
                container={containerRef}
                onRightMenuChange={(rightMenu) => setInterfaceSettings({ rightMenu })}
                floating={isMobile}
                onFocusBlockId={(blockId) => {
                    window.location.hash = `#${blockId}`;
                }}
                pageBlocks={page.blocks}
                blockPlugins={blockPlugins}
            />
        </div>
    );
}

function PublicPageEditables() {
    const editor = useSlateStatic();
    SharedEditor.useEditorShortcuts(editor);

    const renderElement = React.useCallback((props: RenderElementProps) => <BaseRenderElement {...props} />, []);
    const renderLeaf = React.useCallback((props: RenderLeafProps) => <Leaf {...props} />, []);

    return <SagaEditor.Editable spaceUrlKey={'public-page'} renderElement={renderElement} renderLeaf={renderLeaf} />;
}

function PublicPageContainerContent({ page, canEdit }: { page: api.Page; canEdit: boolean }) {
    const { data: yBlocks, type } = useDocumentYBlocks(page.id);
    const { members } = useMembers();

    return yBlocks ? (
        <>
            <Helmet>
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>

            <PublicPageBlockPluginProvider sharedPage={page}>
                <DocumentTitle>{page.title}</DocumentTitle>
                <div>
                    <HeaderBar.PublicPage
                        currentPage={{
                            ...page,
                            icon: page.icon ? { type: 'emoji', colons: page.icon.colons } : undefined,
                            isPublicDuplicatable: page.isPublicDuplicatable ?? undefined,
                            blocks: yBlocks.toJSON(),
                        }}
                    />
                </div>

                <div className="relative min-h-0 h-full pb-12 px-2">
                    <PublicRightMenuContainer page={{ ...page, blocks: yBlocks.toJSON() }}>
                        <PublicPageEditor
                            page={{
                                ...page,
                                icon: page.icon ? { type: 'emoji', colons: page.icon.colons } : undefined,
                                archivedAt: page.archivedAt ?? undefined,
                                isTemplate: page.isTemplate ?? undefined,
                                settings: {
                                    fullWidth: page.settings.fullWidth ?? undefined,
                                },
                            }}
                            yBlocks={yBlocks}
                            canEdit={canEdit}
                        >
                            <Suggestions
                                location={SagaLocation.pageLocationFromId(page.id)}
                                tasks={[]}
                                pages={[]}
                                recentLocations={[]}
                                members={members}
                                collections={[]}
                                linearTeams={[]}
                                onSearch={async () => {
                                    return [];
                                }}
                                space={null}
                                provider={null}
                                spaceId={page.spaceId}
                            >
                                <EditorAwareness>
                                    <PublicPageEditables />
                                </EditorAwareness>
                            </Suggestions>
                        </PublicPageEditor>
                    </PublicRightMenuContainer>
                </div>
            </PublicPageBlockPluginProvider>
        </>
    ) : (
        <EmptyBlocksPlaceholder state={type} type="page" />
    );
}

export default function PublicPageContainer() {
    const { page, canEdit } = useCurrentPublicPage();

    return (
        <RealtimeDocumentPovider spaceId={page.spaceId}>
            <PageMembersProvider spaceId={page.spaceId} pageId={page.id}>
                <PublicPageContainerContent page={page} canEdit={canEdit} />
            </PageMembersProvider>
        </RealtimeDocumentPovider>
    );
}
