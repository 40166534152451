import classNames from 'classnames';
import React from 'react';
import { Path, Range } from 'slate';
import { useEditorAwareness } from './EditorAwareness';

export default function VoidSelectionShadow({
    children,
    plain = false,
    path,
    isBlock = false,
}: {
    children: React.ReactNode;
    plain?: boolean;
    path: Path;
    isBlock?: boolean;
}) {
    const awarenessStates = useEditorAwareness();
    const selected = awarenessStates.filter((state) => Range.includes(state.selection, path));
    const userState = selected[0]?.user;

    return (
        <span
            className={classNames({
                'inline-block': !isBlock,
                block: isBlock,
                'rounded w-full': !plain,
            })}
            data-testid={'void-block'}
            style={{
                boxShadow: userState ? `0 0 0 3px ${userState.color}` : undefined,
                width: isBlock ? '100%!important' : undefined,
            }}
        >
            {children}
        </span>
    );
}
