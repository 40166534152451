import { assertBlockType, isDateBlock, isSagaText } from '../types';
import { DateTime } from 'luxon';
import { formatDateBlockDate } from '../utils/DateUtils';
import { Editor, Node, NodeEntry, Path } from 'slate';
import { BlockPlugin } from '../Editor/Plugins';
import {
    Inline,
    isBlockContainer,
    isBlockType,
    isIndentContainer,
    isInline,
    isSagaElement,
    isSimpleElement,
    Root,
    SagaElement,
    SagaText,
} from '../types';

function removeNullBytes(value: string) {
    return value
        .split('')
        .filter((char) => char.codePointAt(0))
        .join('');
}

export function toString(block: Node | Root, plugins?: BlockPlugin[], options?: { join?: string }): string {
    const matchedPlugin = plugins?.find((plugin) => plugin.match(block));

    if (matchedPlugin?.stringify) {
        assertBlockType(block, matchedPlugin.match);
        return removeNullBytes(matchedPlugin.stringify(block, plugins ?? []));
    }

    if (isSagaText(block)) {
        return removeNullBytes(block.text);
    } else if (isDateBlock(block)) {
        return formatDateBlockDate(DateTime.fromISO(block.date));
    } else {
        // if we don't spread, typescript complains
        return [...block.children].map((block) => toString(block, plugins, options)).join(options?.join ?? '');
    }
}

// nested determines if when going back, we look for a child at the same depth, or we move to a different depth instead
export function findNext(editor: Editor, path: Path, nested = true): NodeEntry<SagaElement> | undefined | null {
    try {
        let after = Editor.next<SagaElement>(editor, { at: path });
        // if the next node is a BlockType.BLOCK_CONTAINER, get its first child
        if (after && isBlockType(after[0], isBlockContainer)) {
            const afterChildren = [...Node.children(editor, after[1])];
            if (afterChildren.length > 0) {
                after = afterChildren[0] as NodeEntry<SagaElement>;
            }
        }
        // if the next node is a BlockType.INDENT
        // if 'nested' is true, go one level down, get its first child
        // otherwise go up
        let goUp = false;
        if (after && isBlockType(after[0], isIndentContainer)) {
            if (nested) {
                const afterChildren = [...Node.children(editor, after[1])];
                if (afterChildren.length > 0) {
                    after = afterChildren[0] as NodeEntry<SagaElement>;
                }
            } else {
                goUp = true;
            }
        }
        // if there is no next node, go one level up
        if ((!after && nested) || goUp) {
            const match = Editor.above<SagaElement>(editor, {
                at: path,
                match: isBlockContainer,
                mode: 'lowest',
            });
            if (match) {
                after = Editor.next<SagaElement>(editor, { at: match[1] });
                // if there is a level up and the next node is a parent, go one level down, get its first child
                if (after && isBlockType(after[0], [isBlockContainer, isIndentContainer])) {
                    const afterChildren = [...Node.children(editor, after[1])];
                    if (afterChildren.length > 0) {
                        after = afterChildren[0] as NodeEntry<SagaElement>;
                    }
                }
            }
        }
        return after;
    } catch {
        return null;
    }
}

export const fromNodeEntryToCodeContent = (n: NodeEntry<SagaElement>[]): string => {
    const content = n
        .reduce(
            (result: { strings: string[]; indentLevel: 0 }, [block]: NodeEntry<SagaElement>) => {
                if (isSimpleElement(block)) {
                    let text = '';
                    if (result.indentLevel > 0) {
                        text += '    '.repeat(result.indentLevel);
                    }
                    text += [...block.children]
                        .map((n: Inline) => {
                            if (isSagaElement(n) && isInline(n)) {
                                return n.children.map((n) => n.text).join('');
                            }
                            return n.text;
                        })
                        .join('');
                    result.strings.push(text);
                } else if (isIndentContainer(block)) {
                    result.indentLevel += 1;
                }
                return result;
            },
            { strings: [], indentLevel: 0 },
        )
        .strings.join('\n');

    return content;
};

export function prettify(node: Editor | SagaElement | SagaText, indent = 2, level = 0): string {
    if (Editor.isEditor(node)) {
        return node.children.map((child) => prettify(child, indent, level)).join('\n');
    }

    const buffer = Array.from(new Array(level))
        .map(() =>
            Array.from(new Array(indent))
                .map(() => ' ')
                .join(''),
        )
        .join('');

    if (isSagaText(node)) {
        return `${buffer}${node.text}\n`;
    }

    let children = '';
    node.children.forEach((node) => {
        children += prettify(node, indent, level + 1);
    });

    return `${buffer}<${node.type}>\n${children}${buffer}</${node.type}>\n`;
}
