import React from 'react';
import { ReactEditor, useSlateStatic } from 'slate-react';
import { DateUtils, SagaEditor, isDateBlock } from '@saga/shared';
import { Calendar } from 'react-feather';
import { DayPicker } from 'react-day-picker';
import Dropdown from '@/components/popover/Dropdown';
import { Transforms } from 'slate';
import { DateTime } from 'luxon';
import classNames from 'classnames';
import { track } from '@/analytics';
import i18next from 'i18next';
import { useUserContext } from '@/components/UserContext';
import useMobile from '@/hooks/useMobile';

export const openDateBlockPicker = new Set<string>();

const mobileStyles = {
    root: { margin: '8px' },
    cell: { width: '35px', height: '35px' },
    day: { width: '35px', height: '35px' },
};

export const dateBlockPlugin = SagaEditor.Plugins.createBlockPlugin({
    match: isDateBlock,
    Component({ children, element, selected }) {
        const { canEdit } = SagaEditor.useEditorContext();
        const editor = useSlateStatic();
        const [isOpen, setIsOpen] = React.useState(false);
        const dateTime = DateTime.fromJSDate(new Date(element.date));
        const { userSettings } = useUserContext();
        const isMobile = useMobile();

        const ref = React.useRef<HTMLElement | null>(null);

        const features = canEdit
            ? {
                  updateDate(date: Date) {
                      track('update-date-block');
                      const isoDate = DateTime.fromJSDate(date).toISO();
                      const path = ReactEditor.findPath(editor, element);

                      Transforms.setNodes(
                          editor,
                          { date: isoDate },
                          {
                              at: path,
                          },
                      );
                      ReactEditor.focus(editor);
                  },
              }
            : undefined;

        React.useEffect(() => {
            if (openDateBlockPicker.has(element.id)) {
                setIsOpen(true);
                openDateBlockPicker.delete(element.id);
            }
        }, [element.id]);

        return (
            <span
                className={classNames(
                    'whitespace-nowrap text-saga-text dark:text-neutral-200 font-medium heading1:font-bold heading2:font-bold title:font-bold within-checked:text-saga-gray-dark dark:within-checked:text-saga-gray-200 relative select-none rounded space-x-1 cursor-pointer',
                    {
                        'bg-saga-new-purple-light/20 shadow-lightblue': selected,
                    },
                )}
                onMouseDown={() => setIsOpen(true)}
                data-testid="date-block"
                ref={ref}
            >
                <Calendar
                    className="inline-block align-text-bottom select-none"
                    style={{ height: '1.2em', width: '1em' }}
                />
                <span>{DateUtils.formatDateBlockDate(dateTime, i18next.language)}</span>
                <span className="hidden select-none">{children}</span>
                {features && (
                    <Dropdown
                        testId="date-block-picker"
                        isOpen={isOpen}
                        attachToRef={ref}
                        onClose={() => setIsOpen(false)}
                        align={isMobile ? 'right' : 'left'}
                    >
                        <div className="pb-4">
                            <DayPicker
                                weekStartsOn={userSettings?.weekStartOnMonday ? 1 : 0}
                                mode="single"
                                selected={new Date(element.date)}
                                defaultMonth={new Date(element.date)}
                                onDayClick={(date) => {
                                    features.updateDate(date);
                                    setIsOpen(false);
                                }}
                                styles={isMobile ? mobileStyles : undefined}
                            />
                        </div>
                    </Dropdown>
                )}
            </span>
        );
    },
});
