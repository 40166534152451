import { BlockBuilder } from '../..';
import { newBlankPage } from '../../pageUtils';
import { WeakTask } from '../../types';

export function newSagaBasicsPageDe(task: WeakTask, collectionIds: string[]) {
    return newBlankPage({
        title: 'Saga Grundlagen',
        icon: { type: 'emoji', colons: ':wave:' },
        collections: collectionIds,
        blocks: [
            BlockBuilder.title('Saga Grundlagen'),
            BlockBuilder.callout([
                BlockBuilder.paragraph([BlockBuilder.text('Tippe irgendwo auf dieser Seite, um sie zu bearbeiten!')]),
            ]),
            BlockBuilder.paragraph(),
            BlockBuilder.image('https://saga-public.s3.amazonaws.com/1-block-selection-menu.webp'),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Drücke '),
                BlockBuilder.text('@', { code: true }),
                BlockBuilder.text(' oder '),
                BlockBuilder.text('/', { code: true }),
                BlockBuilder.text(
                    ' um das Autocomplete-Menü zu öffnen und neue Inhalte wie Überschriften, To-Dos, Bilder, Dateien, Tabellen und mehr hinzuzufügen.',
                ),
            ]),
            BlockBuilder.paragraph(),
            BlockBuilder.paragraph(),
            BlockBuilder.paragraph(),
            BlockBuilder.image('https://saga-public.s3.amazonaws.com/2-text-styling-menu.webp'),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Wähle Text aus', { bold: true }),
                BlockBuilder.text(' um ihn '),
                BlockBuilder.text('zu formatieren,', { italic: true }),
                BlockBuilder.text(' in einen anderen Inhaltstyp umzuwandeln, '),
                BlockBuilder.text('hervorzuheben', { highlight: true }),
                // todo highlight in red
                BlockBuilder.text(', oder mit KI zu verbessern.'),
            ]),
            BlockBuilder.paragraph(),
            BlockBuilder.paragraph(),
            BlockBuilder.image('https://saga-public.s3.amazonaws.com/3-drag-and-drop-images-and-files.webp'),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Füge Bilder und Dateien hinzu', { bold: true }),
                BlockBuilder.text(' indem du sie per Drag & Drop oder Einfügen hinzufügst.'),
            ]),
            BlockBuilder.paragraph(),
            BlockBuilder.paragraph(),
            BlockBuilder.image('https://saga-public.s3.amazonaws.com/4-drag-and-drop-blocks-of-content.webp'),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Ziehe und lege', { bold: true }),
                BlockBuilder.text(' jeden Block, indem du das ☰ Symbol links neben dem Block auswählst und ziehst.'),
            ]),
            BlockBuilder.paragraph(),
            BlockBuilder.paragraph(),
            BlockBuilder.image('https://saga-public.s3.amazonaws.com/5-unified-search-for-fast-navigation.webp'),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Drücke '),
                BlockBuilder.text('⌘ + K', { code: true }),
                BlockBuilder.text(' (macOS) oder '),
                BlockBuilder.text('Strg + K', { code: true }),
                BlockBuilder.text(' (Windows) um '),
                BlockBuilder.text('alles zu finden', { bold: true }),
                BlockBuilder.text(' und durch deinen Arbeitsbereich zu navigieren.'),
            ]),
            BlockBuilder.paragraph(),
            BlockBuilder.paragraph(),
            BlockBuilder.image('https://saga-public.s3.amazonaws.com/6-creating-and-managing-tasks.webp'),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Erstelle Aufgaben', { bold: true }),
                BlockBuilder.text(' indem du das Autocomplete-Menü öffnest und '),
                BlockBuilder.text('Aufgabe', { code: true }),
                BlockBuilder.text(
                    ' auswählst. Aufgaben sind spezielle Seiten mit einem Beauftragten, einem Fälligkeitsdatum und einer Priorität. Öffne die Aufgabe unten, um mehr zu erfahren 👇.',
                ),
            ]),
            BlockBuilder.taskBlock(task.id),
            BlockBuilder.paragraph(),
            BlockBuilder.paragraph(),
            BlockBuilder.image('https://saga-public.s3.amazonaws.com/7-adding-to-pinned.webp'),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Favorisiere ', { bold: true }),
                BlockBuilder.text('jede Seite, um sie zur Seitenleiste hinzuzufügen, indem du das ☆ Symbol in der oberen rechten Ecke anklickst.'),
            ]),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Gehe zur 😍 Saga Fortgeschritten, um fortgeschrittenere Nutzungsmöglichkeiten von Saga zu erkunden.'),
            ]),
            BlockBuilder.paragraph(),
            BlockBuilder.paragraph(),
            BlockBuilder.divider(),
            BlockBuilder.headingThree('Hast du eine Frage?'),
            BlockBuilder.paragraph([
                BlockBuilder.text('Drücke den '),
                BlockBuilder.text('?', { bold: true }),
                BlockBuilder.text(
                    ' Knopf in der unteren rechten Ecke, um uns eine E-Mail zu senden, Feature-Anfragen zu stellen oder unseren Benutzergemeinschaften auf ',
                ),
                BlockBuilder.link('https://discord.com/invite/cgz2mUEq7P', 'Discord'),
                BlockBuilder.text(', '),
                BlockBuilder.link(
                    'https://sagacommunity.slack.com/join/shared_invite/zt-13m3lrrdt-1x6~l6sLuR8CX~4c3fWwHA#/shared-invite/email',
                    'Slack',
                ),
                BlockBuilder.text(', oder '),
                BlockBuilder.link('https://twitter.com/saga_HQ', 'X'),
                BlockBuilder.text(
                    ' beizutreten. Besuche auch die Hilfeguides auf unserer Webseite und sieh, wie du das Beste aus der App herausholen kannst.',
                ),
            ]),
            BlockBuilder.rawPrettyLink('https://saga.so/guides', {
                _tag: 'loaded',
                description: "Dieser Leitfaden hilft dir, die Bausteine von Saga zu verstehen und dich schnell einzuarbeiten.",
                title: '📍 Saga Leitfäden',
                logo: 'https://saga.so/thumbnail.png',
            }),
            BlockBuilder.paragraph(),
        ],
    });
}
