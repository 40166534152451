import React from 'react';
import * as api from '@saga/api';

import { BlockPluginProvider } from '@/components/BlockPluginProvider';
import { createTitleBlockPlugin } from '@/components/editor/plugins/Title';
import { basePlugin } from './Plugins';

export default function PublicPageBlockPluginProvider({
    children,
    sharedPage,
}: {
    children: React.ReactNode;
    sharedPage: api.PageFragmentFragment;
}) {
    const titleBlockPlugin = React.useMemo(() => {
        const icon = sharedPage.icon ? { colons: sharedPage.icon.colons, type: 'emoji' as const } : undefined;
        return createTitleBlockPlugin(icon);
    }, [sharedPage.icon]);

    const plugins = React.useMemo(
        () => [
            // custom
            titleBlockPlugin,

            // shared
            basePlugin,
        ],
        [titleBlockPlugin],
    );

    return <BlockPluginProvider plugins={plugins}>{children}</BlockPluginProvider>;
}
