import { useSpace, useCurrentSearchIndex } from '@/components/SpaceProvider';
import React from 'react';
import { useContext } from 'react';
import { useAutolinkIndex } from '../AutolinkIndex';
import { useSearchContext } from '../search/SearchContext';
import { SpaceOperations, Search, SagaLocation, isTitle, unsafeRight, isInlinePageLink } from '@saga/shared';
import { BlocksLocation } from '@/../../shared/src/SagaLocation';
import { FindInLocationsResult } from '@/../../shared/src/SpaceOperations/findInLocations';
import { MatchMode, textQuery } from '@/../../shared/src/Search';
import * as api from '@saga/api';
import { useCurrentWorkspace } from '@/components/WorkspaceContext';
import { useIsOnline } from '../BrowserContextProvider';
import useFindInLocationsWorker from '@/hooks/useFindInLocationsWorker';
import { useAutolinkEnabled } from '@/hooks/SpaceHooks';

const { parseSearchQuery } = Search;

const ReferenceResultsContext = React.createContext<FindInLocationsResult[]>([]);
const IsSearchingContext = React.createContext<boolean>(false);
const TriggerSearchContext = React.createContext<() => void>(() => {});

export function useTriggerBlockSearch() {
    const triggerSearch = useContext(TriggerSearchContext);
    return triggerSearch;
}

export function useIsSearchingReferences() {
    const isSearching = useContext(IsSearchingContext);
    return isSearching;
}

export function useReferenceResults() {
    const referenceResults = useContext(ReferenceResultsContext);
    return referenceResults;
}

export function useSearchResults() {
    const { urlKey, isEmbeddingEnabled } = useCurrentWorkspace();
    const spaceblocks = useCurrentSearchIndex();

    const { space } = useSpace();
    const { searchQuery } = useSearchContext();
    const { index } = useAutolinkIndex();

    const [results, setResults] = React.useState<FindInLocationsResult[] | null>([]);
    const isOnline = useIsOnline();

    const { search } = useFindInLocationsWorker();

    const query = React.useMemo(
        () =>
            searchQuery
                ? [
                      textQuery({
                          condition: parseSearchQuery(searchQuery),
                          matchMode: MatchMode.ALL,
                          fuzzySearch: true,
                      }),
                  ]
                : null,
        [searchQuery],
    );

    const [searchEmbeddings, { loading: searchingEmbeddings }] = api.useSpaceEmbeddingsLazyQuery({
        variables: { input: { urlKey, query: query?.[0].condition.join('') } },
    });

    React.useEffect(() => {
        function runSearch() {
            let timeout: ReturnType<typeof setTimeout> | null = null;
            if (searchQuery) {
                const condition = parseSearchQuery(searchQuery);

                timeout = setTimeout(async () => {
                    if (condition == null || query == null) {
                        return;
                    }

                    search({ type: 'text', text: searchQuery }, async (results) => {
                        if (isEmbeddingEnabled) {
                            const { data } = await searchEmbeddings();
                            setResults([
                                ...results,
                                ...SpaceOperations.findSpaceEmbeddings(
                                    unsafeRight(space.decode()),
                                    spaceblocks?.toJSON(),
                                    data?.spaceEmbeddings ?? [],
                                ),
                            ]);
                        } else {
                            setResults(results);
                        }
                    });
                }, 0);
            } else {
                setResults(null);
            }

            return () => {
                if (timeout) {
                    clearTimeout(timeout);
                }
            };
        }
        const cleanup = runSearch();
        const unobserve = index.observe(runSearch);

        return () => {
            cleanup();
            unobserve();
        };
    }, [searchQuery, index, space, isOnline, isEmbeddingEnabled, spaceblocks, query, searchEmbeddings, search]);

    return { results, isSearching: searchingEmbeddings };
}

export default function ReferenceResults({
    children,
    location,
}: {
    children: React.ReactNode;
    location: BlocksLocation;
}) {
    const { index } = useAutolinkIndex();
    const [autolinkEnabled] = useAutolinkEnabled();

    const [trigger, forceUpdate] = React.useReducer(() => ({}), {});
    const [isSearching, setIsSearching] = React.useState(false);
    const [referenceResults, setReferenceResults] = React.useState<FindInLocationsResult[]>([]);

    const { search } = useFindInLocationsWorker();

    React.useEffect(() => {
        function runSearch() {
            setIsSearching(true);

            let timeout: ReturnType<typeof setTimeout> | null = setTimeout(() => {
                search({ type: 'blocks', location }, (results) => {
                    setReferenceResults(
                        results
                            .filter((result) => !SagaLocation.areLocationsEqual(result.location, location))
                            .filter((result) => {
                                if (!autolinkEnabled && !isInlinePageLink(result.result.block)) {
                                    return false;
                                }

                                if (result.type === 'page') {
                                    return result.page.settings.linkTitle !== false && !isTitle(result.result.block);
                                }

                                return !isTitle(result.result.block);
                            }),
                    );
                    setIsSearching(false);
                });
            }, 0);

            return () => {
                if (timeout) {
                    clearTimeout(timeout);
                }
            };
        }
        const cleanup = runSearch();
        const unobserve = index.observe(runSearch);

        return () => {
            cleanup();
            unobserve();
        };
    }, [trigger, location, index, search, autolinkEnabled]);

    return (
        <ReferenceResultsContext.Provider value={referenceResults}>
            <IsSearchingContext.Provider value={isSearching}>
                <TriggerSearchContext.Provider value={forceUpdate}>{children}</TriggerSearchContext.Provider>
            </IsSearchingContext.Provider>
        </ReferenceResultsContext.Provider>
    );
}
