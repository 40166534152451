import { SafeSpace } from '../types';
import { pipe } from 'fp-ts/function';
import * as E from 'fp-ts/Either';
import transactInSpace from './transactInSpace';

export default function addPinned(space: SafeSpace, id: string) {
    transactInSpace(space, () => {
        pipe(
            space.get('pinned'),
            E.fold(
                (error) => {
                    throw new Error(JSON.stringify(error, null, 2));
                },
                (pinnedSafeArray) => {
                    const values = pinnedSafeArray.array.toArray();
                    // make sure that the id does not get added twice
                    if (!values.includes(id)) {
                        pinnedSafeArray.array.unshift([id]);
                    }
                },
            ),
        );
    });
}
