import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import fetch from 'cross-fetch';
import { relayStylePagination, getMainDefinition } from '@apollo/client/utilities';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { GraphQLClient } from 'graphql-request';
import * as api from '@saga/api';
import { getCurrentUser } from '@/firebase';
import { GRAPHQL_URL, WEBSOCKET_GRAPHQL_URL } from './constants';
import { captureException } from '@sentry/browser';
import { debugLog } from '@/utils';
import { CODE_VERSION } from '../../shared/src/constants';

async function getToken() {
    const token = await (async () => {
        const currentUser = getCurrentUser();
        if (!currentUser) {
            return undefined;
        }

        try {
            return currentUser.getIdToken();
        } catch (error) {
            captureException(error, { extra: { errorFormatted: 'Get Token: Could not get token', currentUser } });
            debugLog('Get Token: Could not get token', error);
            return undefined;
        }
    })();
    return token;
}

const client = new GraphQLClient(GRAPHQL_URL, {
    credentials: 'include',
    mode: 'cors',
});

export async function getSdk() {
    const csrfToken = document.head.querySelector('meta[name="x-csrf-token"]')!.getAttribute('content');
    const token = await getToken();
    if (!token || !csrfToken) {
        const error = new Error('Get SDK: No token/csrfToken found');
        captureException(error, { extra: { errorFormatted: 'Get SDK: No token/csrfToken found' } });
        throw error;
    }

    client.setHeader('authorization', token);
    client.setHeader('x-csrf-token', csrfToken);

    return api.SDK.getSdk(client);
}

export const wsClient = createClient({
    url: WEBSOCKET_GRAPHQL_URL,
    retryAttempts: Infinity,
    isFatalConnectionProblem: (event) => event instanceof CloseEvent && event.code === 4403,
    async connectionParams() {
        const token = await getToken();
        const csrfToken = document.head.querySelector('meta[name="x-csrf-token"]')!.getAttribute('content');
        return {
            token,
            csrfToken,
            clientVersion: CODE_VERSION,
            headers: {
                'x-csrf-token': csrfToken,
            },
        };
    },
});
const wsLink = new GraphQLWsLink(wsClient);

const httpLink = createHttpLink({
    uri: GRAPHQL_URL,
    fetch,
    credentials: 'include',
});

const authLink = setContext(async (_, { headers }) => {
    const token = await getToken();

    const csrfToken = document.head.querySelector('meta[name="x-csrf-token"]')!.getAttribute('content');
    if (!csrfToken) {
        captureException('CSRF Token not found');
        debugLog('CSRF Token not found');
        return;
    }

    return {
        headers: {
            ...headers,
            authorization: token,
            'x-csrf-token': csrfToken,
        },
    };
});

const link = authLink.split(
    (op) => {
        const definition = getMainDefinition(op.query);
        return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
    },
    wsLink,
    httpLink,
);

export const apolloClient = new ApolloClient({
    link,
    cache: new InMemoryCache({
        possibleTypes: {
            LocationResult: ['TaskLocationResult', 'PageLocationResult'],
            QueryResult: ['BlockResult', 'TextResult'],
        },
        typePolicies: {
            Query: {
                fields: {
                    notificationsConnection: relayStylePagination(),
                },
            },
            Member: {
                // we need to declare composite keys also for the apollo cache,
                // otherwise we might get wrong cached data
                keyFields: ['id', 'spaceId'],
            },
        },
    }),
});
