import React, { useEffect, useState } from 'react';
import Input from '../styled/Input';
import Button from '../styled/Button';
import { useAdminSpaceListLazyQuery } from '@saga/api';
import Spinner from '@/components/loading/Spinner';
import { format } from 'date-fns';
import classNames from 'classnames';
import { useHistory, useLocation } from 'react-router-dom';

export default function ListWorkspaces() {
    const { search } = useLocation();
    const urlKeyParam = new URLSearchParams(search).get('urlKey');
    const [urlKey, seturlKey] = useState(urlKeyParam ? urlKeyParam : '');
    const [spaceDetails, setSpaceDetails] = useState<any>(null);
    const [getSpace, { loading }] = useAdminSpaceListLazyQuery();
    const [notification, setNotification] = useState<{ status: 'success' | 'error'; message: string } | null>(null);

    const history = useHistory();

    const onFindSpaceDetails = () => {
        setNotification(null);
        setSpaceDetails(null);

        getSpace({
            variables: { input: { urlKey } },
            onCompleted: (data) => {
                setSpaceDetails(data.adminSpaceList || null);
            },
            onError: (e) => setNotification({ status: 'error', message: e.message }),
        });
    };

    useEffect(() => {
        if (urlKey && urlKeyParam) {
            onFindSpaceDetails();
            history.replace({ search: '' });
        }
    }, [urlKey, urlKeyParam]);

    return (
        <div className="w-900">
            <h1 className="text-3xl mb-4">List Workspaces</h1>
            <p>Enter the urlKey of the space</p>
            <Input
                onChange={({ currentTarget }) => seturlKey(currentTarget.value)}
                value={urlKey}
                type="email"
                onKeyDown={(e) => {
                    if (e.key === 'Enter' && urlKey) {
                        onFindSpaceDetails();
                    }
                }}
            />
            <div className="flex items-center mt-2">
                <Button disabled={!urlKey || loading} onClick={onFindSpaceDetails}>
                    <div className="flex">
                        {loading && <Spinner size={16} />} <span className="ml-1">Find Space</span>
                    </div>
                </Button>

                {notification && (
                    <div
                        className={classNames('border rounded p-1 ml-2 text-sm', {
                            'text-red-500 border-red-500': notification.status === 'error',
                            'text-green-700 border-green-700': notification.status === 'success',
                        })}
                    >
                        {notification.message}
                    </div>
                )}
            </div>

            {!loading && spaceDetails && (
                <div className="grid grid-cols-[150px_1fr] gap-4 border rounded mt-4 p-4">
                    <div className="font-bold">Space Id</div>
                    <div className="select-text">{spaceDetails.id}</div>

                    <div className="font-bold">Title</div>
                    <div className="select-text">{spaceDetails.title}</div>

                    <div className="font-bold">Created At</div>
                    <div className="select-text">{format(new Date(spaceDetails.createdAt), 'dd.MM.yyyy HH:mm')}</div>

                    <div className="font-bold">Owner/s</div>
                    <div className="select-text">{spaceDetails.ownerIds.join(', ')}</div>

                    <div className="font-bold">Used Tokens</div>
                    <div className="select-text">{spaceDetails.usedSpaceTokens}</div>

                    <div className="font-bold">Subscription</div>
                    <div>
                        {spaceDetails.activeSubscription ? (
                            <div>
                                <div>
                                    {spaceDetails.activeSubscription.status} {spaceDetails.activeSubscription.plan.name}
                                </div>
                                <div>
                                    {format(
                                        new Date(spaceDetails.activeSubscription.currentPeriodStart),
                                        'dd.MM.yyyy HH:mm',
                                    )}
                                    {' - '}
                                    {format(
                                        new Date(spaceDetails.activeSubscription.currentPeriodEnd),
                                        'dd.MM.yyyy HH:mm',
                                    )}
                                </div>
                            </div>
                        ) : (
                            '-'
                        )}
                    </div>
                </div>
            )}
        </div>
    );
}
