import React from 'react';
import { MoreHorizontal } from 'react-feather';
import Button from './Button';
import { useTranslation } from 'react-i18next';

const ContextMenuButton = React.forwardRef<
    HTMLButtonElement,
    {
        isOpen?: boolean;
        onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
        isButtonSmall?: boolean;
        label?: string;
    }
>(function ContextMenu({ isOpen, onClick, isButtonSmall, label }, ref) {
    const { t } = useTranslation();
    return (
        <Button.Plain
            aria-label={
                label ? label : (t(isOpen ? 'common.close_context_menu' : 'common.open_context_menu') as string)
            }
            aria-haspopup="true"
            ref={ref}
            onClick={onClick}
        >
            {isButtonSmall && (
                <Button.SmallPadding>
                    <MoreHorizontal size={isButtonSmall ? 14 : 20} className="stroke-gray-dark" />
                </Button.SmallPadding>
            )}

            {!isButtonSmall && (
                <Button.BasePadding>
                    <MoreHorizontal size={isButtonSmall ? 14 : 20} className="stroke-gray-dark" />
                </Button.BasePadding>
            )}
        </Button.Plain>
    );
});

export default ContextMenuButton;
