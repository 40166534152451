import React from 'react';
import Notice from '../styled/Notice';
import { Trash } from 'react-feather';
import { usePageSnapshot } from '@/hooks/SpaceHooks';
import { useSpace } from '@/components/SpaceProvider';
import { SpaceOperations } from '@saga/shared';
import { useHistory } from 'react-router-dom';
import { useCurrentWorkspace } from '../WorkspaceContext';
import { useDeletePagePermanently } from '@/hooks/deletePageHooks';
import { useSideBySideState } from '@/components/SideBySide';
import { useTranslation } from 'react-i18next';
import { useSpaceAccess } from '@/hooks/useSpaceAccess';
import { useNullableTask } from '../tasks/hooks';
import { useDeleteTaskPermanently } from '@/hooks/deleteTaskHooks';

enum DocumentType {
    Page = 'page',
    Task = 'task',
}

const pageKeys = ['archivedAt'] as const;

function ArchivedNotice({ id, type }: { id: string; type: DocumentType }) {
    const { t } = useTranslation();
    const { space } = useSpace();
    const history = useHistory();
    const { urlKey } = useCurrentWorkspace();

    const deletePagePermanently = useDeletePagePermanently();
    const deleteTaskPermanently = useDeleteTaskPermanently();

    const { panes } = useSideBySideState();
    const { isOwner, isMember } = useSpaceAccess();

    const page = usePageSnapshot(id, pageKeys);
    const task = useNullableTask(id);

    const document = type === DocumentType.Page ? page : task;

    if (document == null || document.archivedAt == null) {
        return null;
    }

    function onDelete() {
        type === DocumentType.Page ? deletePagePermanently(id) : deleteTaskPermanently(id);

        if (panes.length === 1) {
            history.replace(`/s/${urlKey}`);
        }
    }

    function onRestore() {
        type === DocumentType.Page ? SpaceOperations.restorePage(space, id) : SpaceOperations.restoreTask(space, id);
    }

    return (
        <div className="w-full flex justify-start z-30 mx-auto max-w-700">
            <Notice.Danger>
                <div className="py-2 px-3 space-x-4 flex items-center">
                    <div className="flex items-center space-x-2">
                        <Trash size={16} />
                    </div>

                    <div className="flex flex-wrap space-x-4">
                        <div>{type === DocumentType.Page ? t('pages.deleted_page') : t('tasks.deleted_task')}</div>

                        {(isOwner || isMember) && (
                            <div className="space-x-4 flex">
                                <button onClick={onRestore} className="hover:opacity-75 font-semibold">
                                    {type === DocumentType.Page ? t('pages.restore_page') : t('tasks.restore_task')}
                                </button>
                                <button onClick={onDelete} className="hover:opacity-75 font-semibold underline">
                                    {t('pages.delete_forever')}
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </Notice.Danger>
        </div>
    );
}

export function ArchivedPageNotice({ id }: { id: string }) {
    return <ArchivedNotice id={id} type={DocumentType.Page} />;
}

export function ArchivedTaskNotice({ id }: { id: string }) {
    return <ArchivedNotice id={id} type={DocumentType.Task} />;
}
