import { unsafeRight } from '../io-ts-js';
import transactInSpace from './transactInSpace';
import { SafeSpace } from '../types';
import * as Y from 'yjs';
import { findYArrayIndex } from '../yjs-utils';

export default function restoreTask(space: SafeSpace, id: string) {
    const yTasks = unsafeRight(space.get('tasks'));

    const index = findYArrayIndex(yTasks.array as Y.Array<any>, (a) => {
        return a.get('id') === id;
    });

    if (index === -1) {
        throw new Error(`Cannot delete Task: Task with id ${id} not found`);
    }

    transactInSpace(space, () => {
        const yTask = yTasks.array.get(index) as Y.Map<unknown>;
        yTask.set('archivedAt', undefined);
    });
}
