import React from 'react';
import ReactDOM from 'react-dom';

//typings
interface IProps {
    children: React.ReactNode;
}

const Portal: React.FC<IProps> = ({ children }) => {
    return ReactDOM.createPortal(children, document.body);
};

export default Portal;
