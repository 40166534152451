import React from 'react';
import classNames from 'classnames';
import Button from '../styled/Button';

import Tooltip from '../popover/Tooltip';
import { Search } from 'react-feather';
import { useSetSearchOpen } from '../search/SearchContext';
import { useTranslation } from 'react-i18next';
import { useDesktopContext } from '../DesktopContext';
import useInterfaceSettings from '@/hooks/useInterfaceSettings';
import useMobile from '@/hooks/useMobile';
import { useSideBySideIndex } from '../SideBySide';
import { track } from '@/analytics';

function NavigationTable({ children }: { children: React.ReactNode }) {
    return (
        <div data-testid="table" className="relative h-full flex flex-col">
            {children}
        </div>
    );
}

NavigationTable.Title = function Title({ children }: { children: React.ReactNode }) {
    return (
        <div className="flex justify-between items-baseline">
            <h1 className="sm:text-4xl text-2xl font-semibold !leading-relaxed truncate">{children}</h1>
        </div>
    );
};

NavigationTable.Header = function Header({ title, button }: { title: string; button?: React.ReactNode }) {
    return (
        <div className="flex flex-1 sm:items-center items-center">
            <NavigationTable.Title>
                <div className="mr-8">{title}</div>
            </NavigationTable.Title>
            {button && <div className="flex justify-end flex-1 items-center sm:pr-8 ml-2">{button}</div>}
        </div>
    );
};

NavigationTable.EditableHeader = function EditableHeader({
    button,
    renderTitle,
}: {
    button?: React.ReactNode;
    renderTitle?: () => React.ReactNode;
}) {
    return (
        <div className="flex flex-1 sm:items-center items-center">
            {renderTitle ? renderTitle() : null}
            {button && <div className="flex justify-end flex-1 items-center sm:pr-8 ml-2">{button}</div>}
        </div>
    );
};

NavigationTable.CloseButton = function CloseButton({ onClick, label }: { onClick(): void; label?: string }) {
    return (
        <div className="flex justify-end items-center">
            <Button.XButton onClick={onClick} label={label} />
        </div>
    );
};

NavigationTable.SearchButton = function SearchButton() {
    const setSearchOpen = useSetSearchOpen();
    const { t } = useTranslation();

    return (
        <Tooltip content={t('top_menu.search_shortcut')} placement="bottom">
            <Button.Plain
                onClick={() => {
                    track('open-search-bar', { source: 'header-bar-search-button' });
                    setSearchOpen(true);
                }}
            >
                <Button.BasePadding>
                    <span className="sr-only">{t('top_menu.search')}</span>
                    <Search size={20} />
                </Button.BasePadding>
            </Button.Plain>
        </Tooltip>
    );
};

NavigationTable.PaneContainer = function Container({
    className,
    children,
}: React.ButtonHTMLAttributes<HTMLDivElement>) {
    return <div className={classNames(className, 'overflow-x-auto flex-row flex-grow sm:px-8')}>{children}</div>;
};

NavigationTable.FixedPaneContainer = function FixedPaneContainer({
    children,
    variant,
}: {
    children: React.ButtonHTMLAttributes<HTMLDivElement>;
    variant: 'page-header' | 'page-title' | 'page-title-collections';
}) {
    const { useMacOsLayout, isDesktop } = useDesktopContext();
    const [{ fixedSidebar }] = useInterfaceSettings();
    const sideIndex = useSideBySideIndex();
    const isMobile = useMobile();

    let variantStyles = '';
    const basePageHeaderStyles = 'flex flex-row justify-between items-center relative';
    if (variant === 'page-header') {
        variantStyles = classNames(basePageHeaderStyles, {
            'pt-2 pl-2': useMacOsLayout,
            'pt-2 pl-14': useMacOsLayout && !fixedSidebar,
            'pt-2 pl-[106px]': useMacOsLayout && !fixedSidebar && isMobile,
            'justify-between': isDesktop && sideIndex === 0,
        });
    }

    if (variant === 'page-title') {
        variantStyles = 'sm:pt-10 sm:pb-4 sm:pl-8 flex flex-row justify-between items-center';
    }

    if (variant === 'page-title-collections') {
        variantStyles = basePageHeaderStyles;
    }

    return <div className={classNames(variantStyles, 'flex-none py-2')}>{children}</div>;
};

export default NavigationTable;
