import React from 'react';
import { getStoredOrDefault } from '@/utils/localStorageUtils';
import * as t from 'io-ts';
import * as R from 'ramda';
import { useCurrentWorkspace } from './WorkspaceContext';
import { useFirebaseContext } from './FirebaseContext';
import { assertNonNull, SagaLocation } from '@saga/shared';
import { usePagesPermissions } from '@/components/PagesPermissionsBySpaceProvider';

const recentEntryD = t.type({
    owner: t.union([t.string, t.undefined]),
    workspace: t.string,
    locations: t.array(SagaLocation.blocksLocationD),
});

const recentsD = t.array(recentEntryD);

type RecentEntry = t.TypeOf<typeof recentEntryD>;
type Recents = t.TypeOf<typeof recentsD>;

function updateStoredRecents(newRecents: Recents) {
    localStorage.setItem('recentLocations', JSON.stringify(newRecents));
}

function getAllStoredRecents() {
    return getStoredOrDefault('recentLocations', recentsD.decode, []);
}

function getStoredRecents(urlKey: string, uid?: string) {
    const allStoredRecents = getAllStoredRecents();
    const result = allStoredRecents.find((recent) => recent.workspace === urlKey && recent.owner === uid);
    return result?.locations ?? [];
}

function addRecentLocationToLocalStorage(location: SagaLocation.BlocksLocation, urlKey: string, uid?: string) {
    const stored = getAllStoredRecents();
    const index = stored.findIndex((recent) => recent.workspace === urlKey && recent.owner === uid);
    if (index >= 0) {
        stored[index].locations = R.uniq([location, ...stored[index].locations]);
    } else {
        stored.push({ owner: uid, workspace: urlKey, locations: [location] });
    }
    updateStoredRecents(stored);
}

const RecentsContext = React.createContext<{
    recentLocations: RecentEntry['locations'];
    addRecentLocation(location: SagaLocation.BlocksLocation, urlKey: string): void;
} | null>(null);

export const useRecents = () => {
    const context = React.useContext(RecentsContext);
    assertNonNull(context, 'useRecents must be used inside RecentsContext');
    return context;
};

function RecentsContextProvider({ children }: { children: React.ReactNode }) {
    const { urlKey } = useCurrentWorkspace();
    const { firebaseUser } = useFirebaseContext();
    const uid = firebaseUser?.uid;
    const { hasAccess } = usePagesPermissions();

    const [trigger, reloadStoredRecents] = React.useReducer(() => ({}), {});
    const recentLocations = React.useMemo(() => {
        trigger;
        const allRecent = getStoredRecents(urlKey, uid);
        return allRecent.filter((location) => {
            if (location.type === 'page') return hasAccess(location.pageId);
            if (location.type === 'task') return hasAccess(location.taskId);
            return true;
        });
    }, [urlKey, uid, trigger, hasAccess]);

    const addRecentLocation = React.useCallback(
        (location: SagaLocation.BlocksLocation, urlKey: string) => {
            addRecentLocationToLocalStorage(location, urlKey, uid);
            reloadStoredRecents();
        },
        [uid],
    );

    const context = React.useMemo(
        () => ({
            recentLocations,
            addRecentLocation,
        }),
        [recentLocations, addRecentLocation],
    );

    return <RecentsContext.Provider value={context}>{children}</RecentsContext.Provider>;
}

export default RecentsContextProvider;
