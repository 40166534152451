import { prompt } from '../../../shared/src/aiPrompts.json';

export default {
    translation: {
        common: {
            open_saga: 'Saga öffnen',
            popover_placeholder: 'Text eingeben oder @ für',
            ai: 'KI',
            commands: 'Befehle',
            and: 'und',
            or: 'oder',
            in: 'in',
            add_dotted: 'Hinzufügen...',
            turn_into: 'Umwandeln in',
            plus_template: '+ Vorlage',
            plus_collection: '+ Sammlung',
            plus_alias: '+ Alias',
            new_alias_placeholder: 'Neuen Alias eingeben...',
            create_alias: '&nbsp;— Alias erstellen',
            remove_alias: '{{alias}} entfernen',
            add_template_title: 'Eine Vorlage auswählen',
            add_template_placeholder: 'Nach einer Vorlage suchen...',
            open: 'Öffnen',
            mark_as_complete: 'Als erledigt markieren',
            open_in_new_tab: 'In neuem Tab öffnen',
            add_to_pinned: 'Zu Angehefteten hinzufügen',
            add_to_favorites: 'Zu Favoriten hinzufügen',
            remove_from_pinned: 'Von Angehefteten entfernen',
            remove_from_favorites: 'Von Favoriten entfernen',
            create: 'Erstellen',
            create_workspace_title: 'Lass uns deinen Arbeitsbereich erstellen.',
            create_workspace_description:
                'Ein Arbeitsbereich ist, wo deine Seiten, Ideen und Wissen sich verbinden und organisiert werden.',
            create_workspace_placeholder: 'Mein Arbeitsbereich',
            save: 'Speichern',
            edit: 'Bearbeiten',
            cancel: 'Abbrechen',
            reload: 'Neu laden',
            reset: 'Zurücksetzen',
            logout: 'Abmelden',
            login: 'Anmelden',
            learn_more: 'Mehr erfahren',
            free_plan: 'Gratis',
            upgrade: 'Aktualisieren',
            show_me: 'Zeig mir',
            got_it: 'Ok, verstanden.',
            dismiss: 'Verwerfen',
            do_this_later: 'Später machen',
            get_started: 'Loslegen',
            dates: 'Termine',
            link_to_collection: 'Mit einer Sammlung verlinken',
            insert: 'Einfügen',
            recents: 'Zuletzt verwendet',
            google_drive: 'Google Drive',
            linear_issues: 'Lineare Probleme',
            tasks: 'Aufgaben',
            table_commands: 'Tabellenbefehle',
            mention_members: 'Mitglieder erwähnen',
            insert_page_link_to: 'Seitenlink einfügen zu',
            link_to_page: 'Link zur Seite',
            gdrive_file: 'Google Drive-Datei',
            linear_issue: 'Lineares Problem',
            new_template: 'Neue Vorlage',
            create_template: 'Vorlage erstellen',
            create_template_placeholder: 'Vorlagennamen eingeben...',
            edit_template: 'Vorlage bearbeiten',
            use_template: 'Vorlage verwenden',
            recent_templates: 'Neueste Vorlagen',
            create_page_from_template: 'Seite aus Vorlage erstellen',
            page_created: 'Seite erstellt',
            has_been_created: 'wurde erstellt',
            blocks_moved: 'Blöcke verschoben',
            selected_blocks_have_been_moved: 'Ausgewählte Blöcke wurden verschoben nach:',
            selected_blocks_have_been_turned: 'Ausgewählte Blöcke wurden in eine neue Seite umgewandelt:',
            live_block_details: 'Ein Live-Block der ausgewählten Blöcke wurde erstellt in:',
            live_block_created: 'Live-Block erstellt',
            duplicate: 'Duplizieren',
            move_to: 'Verschieben nach',
            duplicate_page: 'Seite duplizieren',
            duplicate_task: 'Doppelte Aufgabe',
            copy_page: 'Seite kopieren',
            undo: 'Rückgängig',
            is_mac: '⌘ + Z',
            is_windows: 'Strg + Z',
            export: 'Exportieren',
            delete: 'Löschen',
            update: 'Aktualisieren',
            continue: 'Fortfahren',
            upgrade_plan: 'Plan aktualisieren',
            see_plans: 'Plan verwalten',
            delete_page: 'Seite löschen',
            remove_from_page: 'Von Seite entfernen',
            first_name: 'Vorname',
            last_name: 'Nachname',
            open_workspace: 'Arbeitsbereich öffnen',
            open_page: 'Seite öffnen',
            quick_edit: 'Schnellbearbeitung',
            open_full_page: 'Ganze Seite öffnen',
            close: 'Schließen',
            close_popup: 'Popup schließen',
            close_entity: '{{entity}} schließen',
            close_modal: 'Modal schließen',
            close_quick_edit_modal: 'Schnellbearbeitungsmodal schließen',
            open_notifications: 'Benachrichtigungen öffnen',
            close_notifications: 'Benachrichtigungen schließen',
            close_sidebar: 'Seitenleiste schließen',
            close_task_context_menu: 'Kontextmenü für Aufgabe schließen',
            close_context_menu: 'Kontextmenü schließen',
            open_context_menu: 'Kontextmenü öffnen',
            open_task_context_menu: 'Kontextmenü für Aufgabe öffnen',
            close_sidebar_context_menu: 'Kontextmenü der Seitenleiste schließen',
            open_sidebar: 'Seitenleiste öffnen',
            open_sidebar_context_menu: 'Kontextmenü der Seitenleiste öffnen',
            open_page_context_menu: 'Kontextmenü der Seite öffnen',
            close_page_context_menu: 'Kontextmenü der Seite schließen',
            open_image_context_menu: 'Kontextmenü des Bildes öffnen',
            close_property_menu: 'Eigenschaftsmenü schließen',
            open_property_menu: 'Eigenschaftsmenü öffnen',
            close_pages_context_menu: 'Kontextmenü der Seiten schließen',
            open_pages_context_menu: 'Kontextmenü der Seiten öffnen',
            open_in_codeSandbox: 'In CodeSandbox öffnen',
            open_context_menu_for_column: 'Kontextmenü für Spalte {{column}} öffnen',
            back_to_my_saga: 'Zurück zu meiner Saga',
            referenced_in_pages: 'Referenziert in Seiten',
            see_pages_that_reference: 'Seiten anzeigen, die den Block referenzieren:',
            remove_icon: 'Icon entfernen',
            add_icon: 'Icon hinzufügen',
            change_icon: 'Icon ändern',
            remove_callout: 'Callout entfernen',
            click_add_image: 'Klicken, um ein Bild hinzuzufügen',
            click_add_image_or_embed: 'Klicken, um ein Bild hinzuzufügen oder <1>von URL einbetten</1>',
            image_could_not_be_loaded: 'Bild konnte nicht geladen werden.',
            select_or_search: 'Auswählen oder suchen',
            text_copied: 'Text kopiert',
            copy_text: 'Text kopieren',
            prompt_copied: 'Aufforderung kopiert',
            copy_prompt: 'Aufforderung kopieren',
            stop: 'Stoppen',
            select: 'Auswählen',
            search_in_pages: 'In Seiten suchen',
            navigation: 'Navigation',
            members: 'Mitglieder',
            pages: 'Seiten',
            collections: 'Sammlungen',
            actions: 'Aktionen',
            create_new_page: 'Neue Seite erstellen',
            create_live_block: 'Live-Block erstellen',
            create_live_block_description: 'Diese Auswahl in einer anderen Seite referenzieren',
            create_live_block_disabled: 'Einen Live-Block mit dieser Auswahl zu erstellen ist nicht möglich.',
            remove_image: 'Bild entfernen',
            is_in: 'ist in',
            template: 'Vorlage',
            view_references: 'Referenzen anzeigen',
            try_saga: 'Saga ausprobieren',
            search: 'Suchen',
            report_page: 'Seite melden',
            paste_as_link: 'Als Link einfügen',
            paste_as_preview: 'Als Linkvorschau einfügen',
            paste_as_google_drive: 'Als Google Drive-Link einfügen',
            paste_as_linear_link: 'Als Linear-Link einfügen',
            paste_as_text: 'Als Text einfügen',
            paste_as_live_block: 'Als Live-Block einfügen',
            logged_in_with: 'Angemeldet mit',
            log_in: 'Anmelden',
            gain_access:
                'mit einer anderen E-Mail anmelden oder einen Eigentümer dieses Arbeitsbereichs kontaktieren, um Zugang zu erhalten.',
            highlighted_content: {
                text: 'Schriftfarbe',
                background: 'Text hervorheben',
            },
            properties_tooltip:
                'Eigenschaften sind anpassbare Felder wie Text, Tags und Zahlen, die du deinen Seiten hinzufügen kannst, um detaillierteren Kontext bereitzustellen.',
            confirm_changes: 'Änderungen bestätigen',
            you: 'Sie',
            mentioned_by: 'Erwähnt von',
            mentioned_on: 'Erwähnt am',
            copy: 'Kopieren',
            all_collections: 'Alle Sammlungen',
            no_collections: 'Keine Sammlungen',
            n_collections_other: '{{count}} Sammlungen',
            n_collections_one: '{{count}} Sammlung',
            search_collection_placeholder: 'Sammlung durchsuchen...',
        },
        inapp_notifications: {
            title: 'Saga 1.0',
            description:
                'Wir sind offiziell aus der Beta! Lesen Sie die Veröffentlichung für weitere Informationen darüber, was als Nächstes kommt.',
        },
        invite_onboarding: {
            create_button: 'Neuen Arbeitsbereich erstellen',
            join_button: 'Arbeitsbereich beitreten',
            join_title: 'Einen Arbeitsbereich beitreten oder erstellen',
            invited_description: 'Du wurdest zu keinem Arbeitsbereich eingeladen.',
            create_space_title: 'Stattdessen deinen eigenen Arbeitsbereich erstellen.',
            invited_description_one: 'Du wurdest eingeladen:',
            invited_description_other: 'Du wurdest eingeladen:',
            member_one: 'Mitglied',
            member_other: 'Mitglieder',
        },
        editor: {
            loading_preview_dotted: 'Lade die Vorschau...',
            edit_link: 'Link bearbeiten',
            link_to: 'Verlinken mit',
            link_copied: 'Link kopiert',
            copy_Link: 'Link kopieren',
            turn_into: 'Umwandeln in',
            link_into_pretty_link: 'Link in schönen Link umwandeln',
            link_into_embed: 'Link einbetten',
            move_to_page: 'Zur Seite verschieben',
            create_live_block: 'Live-Block erstellen',
            add_live_block: 'Als Live-Block zur Seite hinzufügen',
            create_template: 'Vorlage erstellen',
            edit_template: 'Du bearbeitest eine Vorlage.',
            view_templates: 'Alle Vorlagen anzeigen',
            create_page: 'Seite erstellen',
            create_collection: 'Sammlung erstellen',
            create_task: 'Aufgabe erstellen',
            all_templates: 'Alle Vorlagen',
            create_issue_in: 'Problem in {{teamName}}',
            turn_into_link: 'In Link umwandeln',
            turn_into_link_preview: 'In Link-Vorschau umwandeln',
            turn_into_embed: 'In Einbettung umwandeln',
            embed_context_menu: 'Einbettungs-Kontextmenü',
            loading_embed: 'Das Einbetten wird geladen...',
            remove_link: 'Link entfernen',
            remove_embed: 'Einbettung entfernen',
            link_preview_not_available: 'Die Linkvorschau ist nicht verfügbar.',
            turn_into_page: 'Seite <1>{{value}}</1>',
            turn_into_page_link: 'In Seitenlink umwandeln',
            turn_into_task: 'Aufgabe <1>{{value}}</1>',
            turn_into_liner_issue: 'Lineares Problem <1>{{value}} in {{team}}</1>',
            turn_into_liner_issue_tooltip: 'Linear-Problem {{value}} in {{team}}',
            copy_link_to_block: 'Link zum Block kopieren',
            delete_block: 'Block löschen',
            block_deleted: 'Block gelöscht',
            quick_edit: 'Schnellbearbeitung',
            empty_page: 'Leere Seite',
            format_text: 'Text',
            format_todo: 'To-Do-Liste',
            format_task: 'Aufgabe',
            format_heading_l: 'Überschrift — Groß',
            format_heading_m: 'Überschrift — Mittel',
            format_heading_s: 'Überschrift — Klein',
            format_ul: 'Aufzählungszeichen',
            format_ol: 'Nummerierte Liste',
            format_img: 'Bild',
            format_file: 'Datei hochladen',
            format_table: 'Tabelle',
            format_callout: 'Callout',
            format_quote: 'Zitat',
            format_divider: 'Trennlinie',
            format_code: 'Code',
            format_equation: 'Gleichung',
            format_inline_equation: 'Inline-Gleichung',
            suffix_create_task: '— Aufgabe erstellen',
            suffix_create_issue_in: '— Problem in {{team}}',
            insert_page_link_to: 'Seitenlink einfügen zu {{title}}',
            insert_recent_page_link_to: 'Kürzlich verwendeten Seitenlink einfügen zu {{title}}',
            insert_column_left: 'Spalte links einfügen',
            insert_column_right: 'Spalte rechts einfügen',
            insert_row_above: 'Zeile oben einfügen',
            insert_row_below: 'Zeile unten einfügen',
            placeholder: {
                h1: 'Überschrift 1',
                h2: 'Überschrift 2',
                h3: 'Überschrift 3',
                task: 'Aufgabe',
                list: 'Liste',
                quote: 'Zitat',
                enter_text: 'Text eingeben ',
                enter_text_dotted: 'Text eingeben...',
            },
            confirm_table_delete: 'Bist du sicher, dass du die ausgewählte Tabelle löschen möchtest?',
            file_upload: {
                click_to_upload_file: 'Klicken, um eine Datei hochzuladen',
                uploading: 'Hochladen...',
                download_file: 'Datei herunterladen',
                remove_file: 'Datei entfernen',
                close_file_context_menu: 'Datei-Kontextmenü schließen',
                open_file_context_menu: 'Datei-Kontextmenü öffnen',
                max_file_size_error: 'Die maximale Dateigröße beträgt {{size}}.',
            },
            actions: 'Aktionen',
        },
        update_references_modal: {
            title: 'Links zu dieser Seite aktualisieren?',
            description_one:
                'Das Umbenennen dieser Seite von <span_red>{{-previousTitle}}</span_red> zu <span_yellow>{{-newTitle}}</span_yellow> wird <span_bold>{{count}}</span_bold> Link zu dieser Seite unterbrechen. Möchten Sie diese aktualisieren?',
            description_other:
                'Das Umbenennen dieser Seite von <span_red>{{-previousTitle}}</span_red> zu <span_yellow>{{-newTitle}}</span_yellow> wird <span_bold>{{count}}</span_bold> Links zu dieser Seite unterbrechen. Möchten Sie diese aktualisieren?',
            select_all: 'Alle auswählen',
            deselect_all: 'Alle abwählen',
            update_links: 'Links aktualisieren',
            update_link_one: '{{count}} Link aktualisieren',
            update_link_other: '{{count}} Links aktualisieren',
            dont_update: 'Nicht aktualisieren',
        },
        updated_title_notice: {
            outdated_link_one: 'Es gibt <bold>{{count}}</bold> veralteten Link zu dieser Seite.',
            outdated_link_other: 'Es gibt <bold>{{count}}</bold> veraltete Links zu dieser Seite.',
            review: 'Überprüfen',
            update_all: 'Alle aktualisieren',
        },
        ai: {
            title: 'Saga KI',
            ask_ai: 'KI fragen',
            ask_ai_anything_mentions_dotted: 'Fragen Sie die KI...',
            ask_ai_anything_dotted: 'Fragen Sie die KI...',
            rewrite: 'Umschreiben',
            discard: 'Verwerfen',
            rerun: 'Erneut ausführen',
            keep: 'Behalten',
            insert_below: 'Unten einfügen',
            replace: 'Ersetzen',
            generating_dotted: 'Generiert...',
            searching_space_dotted: 'Im Arbeitsbereich suchen...',
            searching_page_dotted: 'Auf der Seite suchen...',
            too_many_requests: 'Sie haben zu viele Anfragen gesendet. Versuchen Sie es in ein paar Sekunden erneut.',
            too_many_prompt_tokens:
                'Sie haben die maximale Gesprächslänge für diese KI-Antwort erreicht. <1>Mehr erfahren</1>',
            reduce_prompt_error: 'Bitte verkürzen Sie die Eingabeaufforderung auf maximal {{max_length}} Zeichen',
            restricted:
                'Sie haben das faire Nutzungskontingent für Saga KI für diesen Monat erreicht. <1>Mehr erfahren</1>',
            issue_with_generating: 'Es gab ein Problem bei der Generierung Ihres Textes.',
            edit_selection: 'Auswahl bearbeiten',
            change_tone: 'Ton ändern',
            translate: 'Übersetzen',
            draft: 'Entwurf',
            discard_response: 'Möchten Sie die KI-Antwort verwerfen?',
            my_prompts: 'Meine Aufforderungen',
            my_commands: 'Meine Befehle',
            search_or_select: 'Suchen oder auswählen',
            mention: 'Erwähnen Sie eine Seite oder Aufgabe',
            suggestion: {
                rewrite: 'Umschreiben',
                continue: 'Weiterschreiben',
                summarize: 'Zusammenfassen',
                minimize: 'Kürzer machen',
                maximize: 'Länger machen',
                explain: 'Erklären',
                fix: 'Rechtschreibung & Grammatik korrigieren',
                tone_concise: 'Kurz und bündig',
                tone_professional: 'Professionell',
                tone_casual: 'Leger',
                tone_funny: 'Witzig',
                translate_en: 'Englisch',
                translate_es: 'Spanisch',
                translate_fr: 'Französisch',
                translate_de: 'Deutsch',
                translate_pt: 'Portugiesisch',
                translate_it: 'Italienisch',
                translate_nl: 'Niederländisch',
                translate_zh: 'Chinesisch',
                translate_ko: 'Koreanisch',
                translate_ja: 'Japanisch',
                translate_ua: 'Ukrainisch',
                translate_ru: 'Russisch',
                brainstorm: 'Ideen brainstormen',
                brainstorm_about: prompt.brainstorm_about.de,
                outline: 'Gliederung',
                outline_about: prompt.outline_about.de,
                email: 'E-Mail',
                email_about: prompt.email_about.de,
                meeting: 'Besprechungsagenda',
                meeting_about: prompt.meeting_about.de,
                blog: 'Blogbeitrag',
                blog_about: prompt.blog_about.de,
                social: 'Social-Media-Beitrag',
                social_about: prompt.social_about.de,
                website: 'Webseitentext',
                website_about: prompt.website_about.de,
                cover_letter: 'Bewerbungsschreiben',
                cover_letter_about: prompt.cover_letter_about.de,
                press: 'Pressemitteilung',
                press_about: prompt.press_about.de,
                job: 'Stellenbeschreibung',
                job_about: prompt.job_about.de,
                essay: 'Aufsatz',
                essay_about: prompt.essay_about.de,
            },
            chat: {
                tooltip: 'Öffnen Sie den AI-Chat',
                button: 'KI-Chat',
                initial: {
                    greeting: 'Hey {{name}}, wie kann ich helfen?',
                    bullet1:
                        'Chatte mit mir, um Themen zu erkunden, Fragen zu beantworten oder Inhalte aus deinen Saga-Notizen zu erstellen.',
                    bullet2: 'Frag irgendetwas und ich werde deine Notizen für Antworten verwenden.',
                    bullet3: 'Je mehr du zu Saga hinzufügst, desto mehr kann ich helfen!',
                },
                new_thread: 'Neuer Thread',
                history: 'Verlauf',
                no_threads: 'Sie haben noch keine Threads.',
            },
        },
        errors: {
            cant_open_page: 'Es scheint, als hätten wir Probleme, diese Seite innerhalb von Saga zu öffnen.',
            something_went_wrong: 'Etwas ist schiefgelaufen',
            something_went_wrong_description: 'Nun, das war nicht erwartet.\nBitte versuchen Sie es erneut.',
            something_went_wrong_try_again:
                '🤔 Etwas ist schiefgelaufen, bitte versuchen Sie es erneut\nWenn das Problem weiterhin besteht, kontaktieren Sie uns',
            connection_lost_title: 'Verbindung verloren',
            connection_lost_description:
                'Ihre Verbindung wurde aufgrund von Inaktivität geschlossen.\nBitte laden Sie Saga neu, um die Verbindung wiederherzustellen.',
        },
        side_by_side: {
            open: 'Nebeneinander öffnen',
            tooltip: 'Die Seite neben der aktuellen Seite öffnen',
            open_in_new_tab: 'In einem neuen Tab öffnen',
            copy_link: 'Link in die Zwischenablage kopieren',
        },
        time: {
            years_ago: 'vor {{years}} Jahren',
            months_ago: 'vor {{months}} Monaten',
            weeks_ago: 'vor {{weeks}} Wochen',
            days_ago: 'vor {{days}} Tagen',
            date: 'Datum',
            today: 'Heute',
            yesterday: 'Gestern',
            tomorrow: 'Morgen',
            last_year: 'Letztes Jahr',
            last_week: 'Letzte Woche',
            last_month: 'Letzter Monat',
            next_period: 'Nächste {{label}} - {{date}}',
            last_period: 'Letzte {{label}} - {{date}}',
        },
        tutorial: {
            finishButton: 'Beginnen Sie Ihre Saga',
            writeWthEase: {
                title: 'Schreiben mit Leichtigkeit',
                description:
                    'Der schnelle und einfache Editor von Saga hilft Ihnen, schöne Notizen und Dokumente zu erstellen.',
            },
            autolink_pages: {
                title: 'Seiten automatisch verlinken',
                description: 'Saga erkennt, wenn Sie einen Seitentitel erwähnen und erstellt automatisch einen Link.',
            },
            autocomplete_menu: {
                title: 'Das Autovervollständigungsmenü',
                description:
                    '<1>Nutzen Sie das Autovervollständigungsmenü für Blöcke und Links.</1><2>Probieren Sie es mit @ oder / aus.</2>',
            },
            saga_ai: {
                title: 'Saga KI',
                description:
                    'Der intelligente Assistent beantwortet Fragen, generiert Inhalte und hilft Ihnen, schneller zu arbeiten.',
            },
            unified_search: {
                title: 'Einheitliche Suche',
                description:
                    '<1>Zwischen Seiten wechseln oder im Arbeitsbereich suchen.</1><2>Drücken Sie {{searchHeading}}, um es zu testen.</2>',
            },
        },
        onboarding: {
            complete_signup: 'Die Anmeldung abschließen',
            start_your_saga: 'Beginnen wir Ihre Saga',
            i_agree_with_toc:
                'Ich stimme den <1>Datenschutzbestimmungen</1> und <2>Nutzungsbedingungen</2> von Saga zu',
            create_first_workspace: 'Erstellen Sie Ihren ersten Arbeitsbereich',
            create_workspace: 'Erstellen Sie Ihren Arbeitsbereich',
            create_workspace_description:
                'Ein Arbeitsbereich ist, wo Ihre Seiten, Ideen und Wissen sich verbinden und organisiert werden.',
            what_is_this_workspace_for: 'Wofür ist dieser Arbeitsbereich?',
            usecase_personalNotes: 'Persönliche Notizen',
            usecase_teamWork: 'Teamarbeit',
            open_workspace: 'Arbeitsbereich öffnen',
            what_is_this_workspace: 'Wofür ist dieser Arbeitsbereich?',
            login: {
                title: 'Beginnen wir Ihre Saga',
                with_google: 'Mit Google fortfahren',
                with_email: 'Mit E-Mail fortfahren',
                link_has_expired:
                    '🤔 Ihr Link ist abgelaufen, oder Sie versuchen, sich von einem anderen Gerät anzumelden.\n\nBitte melden Sie sich erneut an 👇',
                toc: 'Indem Sie oben auf "Mit Google/E-Mail fortfahren" klicken, stimmen Sie unseren <1>Datenschutzbestimmungen</1> und <2>Nutzungsbedingungen</2> zu.',
                info: 'Wir melden Sie an oder erstellen ein Konto, falls Sie noch keines haben.',
                desktop_app_button: 'Hol dir die Desktop-App',
            },
            magic_link_login: {
                title: '📨 Überprüfen Sie Ihren Posteingang',
                subtitle: 'Wir haben Ihnen gerade einen Anmeldelink an diese E-Mail-Adresse gesendet:',
                enter_email: 'Geben Sie Ihre E-Mail-Adresse ein...',
                subtitle_email_for_join:
                    'Wir haben Ihnen gerade einen Anmeldelink an diese E-Mail-Adresse gesendet, um dem Arbeitsbereich beizutreten:',
                welcome_to: 'Willkommen bei {{-workspace}}',
                welcome_to_page: 'Willkommen auf {{-page}}',
                close_this_tab: 'Sie können diesen Tab jetzt schließen.',
                invite_for_join:
                    'Dies ist eine Einladung für <1>{{email}}</1>.\nMelden Sie sich mit derselben Adresse an, um sie zu akzeptieren.',
                invite_not_active: 'Diese Einladung ist nicht mehr aktiv',
                request_new_invite_short:
                    'Kontaktieren Sie einen Eigentümer dieses Arbeitsbereichs und bitten Sie um eine neue Einladung.',
                request_new_page_invite_short:
                    'Kontaktieren Sie einen Admin dieser Seite und fordern Sie eine neue Einladung an.',

                not_have_access: 'Sie haben keinen Zugang zu diesem Arbeitsbereich',
                not_have_page_access: 'Sie haben keinen Zugriff auf diese Seite',
                logged_in_with_different_email:
                    'Sie sind angemeldet mit <1>{{email}}</1>. <2>Melden Sie sich</2> mit einer anderen E-Mail an oder kontaktieren Sie einen Eigentümer dieses Arbeitsbereichs, um Zugang zu beantragen.',
                logged_in_with_different_email_page:
                    'Sie sind mit <1>{{email}}</1> angemeldet. <2>Melden Sie sich</2> mit einer anderen E-Mail an oder kontaktieren Sie einen Administrator dieser Seite, um Zugriff anzufordern.',
                request_new_invite:
                    'Kontaktieren Sie einen Eigentümer dieses Arbeitsbereichs und bitten Sie um eine neue Einladung.\nGehen Sie zurück zur <1>Startseite</1> und erstellen Sie stattdessen Ihren eigenen Arbeitsbereich',
            },
        },
        help: {
            give_feedback: 'Feedback geben',
            give_feedback_tooltip:
                '🚀 Sie sind einer der Saga-Pioniere (beigetreten am {{userCreatedDate}}). Wir arbeiten hart daran, Verbesserungen und neue Funktionen in Lichtgeschwindigkeit zu liefern. Wenn Sie Feedback für uns haben, senden Sie uns eine E-Mail an feedback@saga.so',
            contact_us: 'Kontaktieren Sie uns',
            join_slack: 'Auf Slack beitreten',
            join_discord: 'Auf Discord beitreten',
            youtube_channel: 'YouTube-Kanal',
            tweet_us: 'Tweeten Sie uns',
            open_website: 'Webseite öffnen',
            help_guide: 'Hilfeleitfaden',
            start_tutorial: 'Tutorial starten',
        },
        top_menu: {
            search: 'Suchen...',
            searching: 'Suche...',
            searching_for: 'Suche nach:',
            search_dotted: 'Suchen...',
            search_shortcut: 'Suche Ctrl/Cmd + K',
            add_page_to_favorites: 'Seite zu Favoriten hinzufügen',
            add_collection_to_favorites: 'Sammlung zu Favoriten hinzufügen',
            add_task_to_favorites: 'Toevoegen taak aan favorieten',
            add_task_view_to_favorites: 'Aufgabenansicht zu Favoriten hinzufügen',
            add_page_view_to_favorites: 'Seitenansicht zu Favoriten hinzufügen',
            add_page_to_pinned: 'Seite zu Angepinnt hinzufügen',
            add_collection_to_pinned: 'Sammlung zu Angepinnt hinzufügen',
            add_task_to_pinned: 'Aufgabe zu Angepinnt hinzufügen',
            add_task_view_to_pinned: 'Aufgabenansicht zu Angepinnt hinzufügen',
            add_page_view_to_pinned: 'Seitenansicht zu Angepinnt hinzufügen',
            search_for_or_create_new: 'Suchen oder neu erstellen',
            search_for_collection_label: 'Sammlung auswählen oder erstellen',
            create_collection: '— Sammlung erstellen',
            create_page: '— Neue Seite erstellen',
            close_search_results: 'Suchergebnisse schließen',
            share: {
                label: 'Teilen',
                share_with: 'Mit Arbeitsbereichsmitgliedern geteilt',
                copy_link: 'Link kopieren',
                copy_link_tooltip:
                    'Durch Verwendung des öffentlichen Links kann die Seite von jedem aufgerufen werden.',
                copy_public_link: 'Öffentlichen Link kopieren',
                copy_public_link_tooltip:
                    'Durch Verwendung des privaten Links kann die Seite nur von Ihnen oder anderen Arbeitsbereichsmitgliedern aufgerufen werden.',
                link_copied: 'Link kopiert!',
                members_count: '{{-workspaceTitle}} Mitglieder',
                share_count_zero: 'Nur mit Ihnen geteilt',
                share_count_one: 'Nur mit Ihnen geteilt',
                share_count_other: 'Mit <1>{{membersCount}} Personen</1> geteilt.',
                manage: 'Verwalten',
                manage_members: 'Mitglieder verwalten',
                view_members: 'Mitglieder anzeigen',
                publish: 'Veröffentlichen',
                page_not_published: 'Veröffentlichen Sie diese Seite, dann kann jeder mit dem Link <1>sie ansehen</1>.',
                page_published: 'Jeder mit dem Link kann <1>diese Seite ansehen</1>.',
                workspace_not_published:
                    'Veröffentlichen Sie den gesamten Arbeitsbereich, dann kann jeder <1>alle Seiten darin ansehen</1>.',
                workspace_published: 'Jeder mit dem Link kann <1>alle Seiten in diesem Arbeitsbereich ansehen</1>.',
                copying_page: 'Seitenduplikation erlauben',
                members_one: '1 Mitglied',
                members_other: '{{count}} Mitglieder',
                copying_page_title: 'Wählen Sie einen Arbeitsbereich',
                copying_page_sub_title: 'Seite wird dupliziert in:',
                copying_page_toast_success: 'Seite erfolgreich dupliziert!',
                copying_workspace: 'Kopieren des Arbeitsbereichs erlauben',
                copying_workspace_toggle: 'Jeder mit dem Link kann diesen Arbeitsbereich kopieren und duplizieren.',
                copying_page_disabled_tooltip:
                    'Sie sind ein Betrachter in diesem Arbeitsbereich und können diese Seite nicht kopieren.',
                invite_members: 'Neue Mitglieder einladen',
                share_with_members: 'Mit Arbeitsbereichsmitgliedern geteilt',
                private_access: 'Privater Zugang',
                members_with_access: 'Mitglieder mit Zugriff auf die Seite',
                guests_with_access: 'Gäste mit Zugriff auf die Seite',
                permission_admin_description: 'Kann die Seite löschen oder Seitenberechtigungen verwalten',
                permission_viewer_description: 'Kann den Seiteninhalt ansehen.',
                permission_editor_description: 'Kann den Seiteninhalt bearbeiten und löschen',
                invite_guest: 'E-Mail eingeben, um einen Gast einzuladen...',
                type_email: 'E-Mail eingeben...',
            },
        },
        move_page: {
            move_modal_title: 'Wählen Sie einen Arbeitsbereich',
            move_modal_sub_title: 'Seite verschieben nach:',
            toast_success: 'Seite erfolgreich verschoben!',
            confirm_modal_title:
                'Einige Inhalte und Einstellungen, wie Links und Berechtigungen, funktionieren möglicherweise nicht auf der verschobenen Seite.',
            confirm_modal_content_left: 'Die Originalseite wird unverändert archiviert.',
            confirm_modal_content_right: 'Ein Duplikat wird im ausgewählten Arbeitsbereich erstellt.',
            confirm_changes: 'Verschiebung bestätigen',
        },
        move_task: {
            move_modal_title: 'Kies een werkruimte',
            move_modal_sub_title: 'Verplaats taak naar:',
            toast_success: 'Taak succesvol verplaatst!',
            confirm_modal_title:
                'Sommige inhoud en instellingen, zoals koppelingen en machtigingen, werken mogelijk niet op de verplaatste taak.',
            confirm_modal_content_left: 'De originele taak wordt ongewijzigd gearchiveerd.',
            confirm_modal_content_right: 'Er wordt een duplicaat gemaakt in de geselecteerde werkruimte.',
            confirm_changes: 'Verplaats bevestigen',
        },

        sidebar: {
            new_page: 'Neue Seite',
            create_page: 'Seite erstellen',
            create_collection: 'Sammlung erstellen',
            new_workspace: 'Neuer Arbeitsbereich',
            open_template_menu: 'Vorlagenmenü öffnen',
            pages: 'Seiten',
            tasks: 'Aufgaben',
            settings: 'Einstellungen',
            pinned: 'Angeheftet',
            favorites: 'Favoriten',
            logout: 'Abmelden',
            collections: 'Sammlungen',
            shared: 'Mit mir geteilt',
            notifications: {
                dont_have_notifications: 'Sie haben noch keine Benachrichtigungen.',
                accept: 'Akzeptieren',
                manage: 'Verwalten',
                invite_message: '{{name}} hat Sie eingeladen, dem Arbeitsbereich beizutreten',
                user_joined: '{{name}} ist Ihrem Arbeitsbereich beigetreten',
                user_left: '{{name}} hat Ihren Arbeitsbereich verlassen',
                user_removed: '{{name}} hat Sie aus dem Arbeitsbereich entfernt',
                go_to_workspace: 'Zur Arbeitsbereich gehen',
                go_to_page: 'Zur Seite gehen',
                go_to_task: 'Zur Aufgabe gehen',
                load_more: 'Mehr laden',
                mentioned_you: 'hat Sie erwähnt in',
                assigned_you: 'hat dich zugewiesen zu',
                unassigned_you: 'hat dich abgemeldet von',
                invite_page_message: 'hat Sie zu seiner Seite eingeladen',
            },
            upgrade_plan_modal: {
                title: 'Ihren Plan upgraden',
                description:
                    'Um unbegrenzte Arbeitsbereiche freizuschalten und Zugang zu fortschrittlichen Kollaborationstools und zusätzlichen Superfunktionen zu erhalten, die Ihre Arbeit auf die nächste Stufe bringen.',
            },
        },
        filters: {
            title: 'Titel',
            az: 'A → Z',
            za: 'Z → A',
            created: 'Erstellt',
            updated: 'Aktualisiert',
            creators: {
                all_creators: 'Alle Mitglieder',
                no_creators: 'Keine Mitglieder',
                n_creators_other: '{{count}} Mitglieder',
                n_creators_one: '{{count}} Mitglied',
                search_creator_placeholder: 'Mitglieder suchen...',
            },
        },
        pages: {
            title_pages: 'Seiten',
            title_deleted_pages: 'Gelöschte Seiten',
            title_deleted: 'Gelöscht',
            title_templates: 'Vorlagen',
            title_shared: 'Geteilt',
            title_private: 'Privat',
            title_public: 'Öffentlich',
            fewest_items_first: 'Wenigste Elemente zuerst',
            most_items_first: 'Meiste Elemente zuerst',
            collection: 'Sammlung',
            fewest_words_first: 'Wenigste Wörter zuerst',
            most_words_first: 'Meiste Wörter zuerst',
            word_count: 'Wortanzahl',
            oldest_first: 'Älteste zuerst',
            newest_first: 'Neueste zuerst',
            close_pages: 'Seiten schließen',
            empty_state_deleted: 'Sie haben keine gelöschten Seiten.',
            empty_state_non_deleted: 'Fügen Sie Seiten hinzu, um sie hier zu sehen.',
            empty_state_templates: 'Fügen Sie Vorlagen hinzu, um sie hier zu sehen.',
            empty_state_shared: 'Sie haben keine Seiten, die mit Ihnen geteilt wurden.',
            empty_state_private: 'Sie haben keine privaten Seiten.',
            empty_state_public: 'Sie haben keine öffentlichen Seiten.',
            enter_page_name: 'Seitennamen eingeben',
            favourite_untitled: 'Ohne Titel',
            delete_all_forever: 'Für immer alle löschen',
            delete_all_page:
                'Sind Sie sicher, dass Sie alle Seiten löschen möchten? Diese Aktion kann nicht rückgängig gemacht werden.',
            history: {
                history: 'Geschichte',
                current: 'Aktuell',
                versions_count: '({{number}} Versionen)',
                versions: 'Versionen',
                upgrade_required: 'Upgrade erforderlich',
                upgrade_modal: {
                    title: 'Plan aktualisieren',
                    description:
                        'Upgrade auf einen kostenpflichtigen Plan, um auf Versionsverlauf älter als 7 Tage zuzugreifen und weitere Premium-Funktionen freizuschalten.',
                },
            },
            mode_dropdown: {
                pages: 'Seiten',
                templates: 'Vorlagen',
                deleted_pages: 'Gelöschte Seiten',
            },
            new_page_placeholder: 'Neue Seite',
            new: {
                create: 'Erstellen',
                label_page: 'Neue Seite',
                placeholder_page: 'Einen neuen Seitentitel eingeben...',
                label_template: 'Neue Vorlage',
                placeholder_template: 'Einen neuen Seitentitel eingeben...',
            },
            headers: {
                title: 'Titel',
                collection: 'Sammlung',
                word_count: 'Wortanzahl',
                created: 'Erstellt',
                updated: 'Aktualisiert',
                shared_by: 'Geteilt von',
                role: 'Rolle',
                creator: 'Ersteller',
            },
            deleted_page: 'Diese Seite wurde gelöscht',
            restore: 'Wiederherstellen',
            restore_page: 'Seite wiederherstellen',
            delete_forever: 'Für immer löschen',
            delete_page_toast_title: 'Seite ist gelöscht',
            delete_page_toast_description:
                'Gelöschte Seiten werden im Archiv gespeichert, bis sie endgültig gelöscht werden.',
        },
        references: {
            label: 'Referenzen',
            tooltip: 'Referenzen zeigen, wo eine Seite in Ihrem Arbeitsbereich erwähnt wurde.',
            result_one: '1 Ergebnis',
            result_other: '{{count}} Ergebnisse',
            no_results: 'Für diese Seite konnten keine Referenzen gefunden werden.',
            no_results_search_panel: 'Keine Ergebnisse gefunden. Versuchen Sie eine andere Suche.',
        },
        table_of_content: {
            label: 'Inhaltsverzeichnis',
        },
        properties: {
            label: 'Eigenschaften',
        },
        page_info: {
            label: 'Seiteninformation',
            creator: 'Ersteller',
            created: 'Erstellt',
            updated: 'Aktualisiert',
            word_count: 'Wortanzahl',
            page_settings: 'Seiteneinstellungen',
            autolink_pages_label: 'Seiten automatisch verlinken',
            autolink_pages_description:
                'Diese Seite automatisch verlinken, wenn ihr Titel auf anderen Seiten erwähnt wird',
            autolink_headings_label: 'Überschriften automatisch verlinken',
            autolink_headings_description:
                'Überschriften von dieser Seite automatisch verlinken, wenn sie auf anderen Seiten erwähnt werden',
            full_width_label: 'Seite in voller Breite',
            full_width_description: 'Seite in voller Breite anzeigen.',
        },
        tasks: {
            title: 'Aufgaben',
            new_task: 'Neue Aufgabe',
            new_task_placeholder: 'Aufgabentitel eingeben',
            new_task_title_placeholder: 'Neue Aufgabe',
            new_task_editor_placeholder: 'Aufgabentitel eingeben',
            createdAt: 'Erstellt',
            updatedAt: 'Aktualisiert',
            completedDate: 'Abgeschlossen',
            due_date: 'Fälligkeitsdatum',
            no_due_date: 'Kein Fälligkeitsdatum',
            sort_asc: 'Aufsteigend',
            sort_desc: 'Absteigend',
            sorted_by: 'Sortiert nach',
            task_title: 'Aufgabentitel',
            referenced: 'Erwähnt in',
            assignee: 'Zugewiesen an',
            no_assignee: 'Kein Zugewiesener',
            priority: 'Priorität',
            no_priority: 'Keine Priorität',
            priority_all: 'Alle Prioritäten',
            priority_high: 'Hoch',
            priority_medium: 'Mittel',
            priority_low: 'Niedrig',
            priority_unprioritized: 'Keine Priorität',
            add_assignee_placeholder: 'Namen eingeben...',
            incomplete_tasks: 'Unvollständige Aufgaben',
            incomplete_tasks_short: 'Unvollständig',
            completed_tasks: 'Abgeschlossene Aufgaben',
            completed_tasks_short: 'Abgeschlossen',
            all_tasks: 'Alle Aufgaben',
            all_tasks_short: 'Alle',
            all_assignees: 'Alle Zugewiesenen',
            not_assigned: 'Nicht zugewiesen',
            close_task_one: 'Aufgabe schließen',
            close_task_other: 'Aufgaben schließen',
            add_task_below: 'Aufgabe unten hinzufügen',
            delete_task: 'Aufgabe löschen',
            confirm_delete_task:
                'Sind Sie sicher, dass Sie diese Aufgabe löschen möchten? Sie wird aus dem gesamten Arbeitsbereich gelöscht.',
            create_task_to_see_them: 'Erstellen Sie Aufgaben, um sie hier zu sehen.',
            status_open: 'Zu Erledigen',
            status_in_progress: 'In Bearbeitung',
            status_done: 'Fertig',
            status: 'Status',
            archived: 'Archiviert',
            deleted_task: 'Diese Aufgabe ist gelöscht',
            restore_task: 'Aufgabe wiederherstellen',
            delete_all_tasks:
                'Sind Sie sicher, dass Sie alle Aufgaben löschen möchten? Diese Aktion kann nicht rückgängig gemacht werden.',
            delete_task_toast_title: 'Aufgabe ist gelöscht',
            delete_task_toast_description:
                'Gelöschte Aufgaben werden im Archiv gespeichert, bis sie endgültig gelöscht werden.',
            new_label_placeholder: 'Label-Namen eingeben...',
            select_or_create_label: 'Neu erstellen oder auswählen',
            add_new_label: 'Neues Label hinzufügen',
            no_label: 'Kein Label',
            no_labels: 'Keine Labels',
            n_labels_one: '{{count}} Label',
            n_labels_other: '{{count}} Labels',
            all_labels: 'Alle Labels',
            table_view: 'Tabelle',
            kanban_view: 'Karteikarten',
        },
        collections: {
            page_title: 'Sammlungen',
            filter_placeholder: 'Nach Stichwort filtern',
            add_to_collection: 'Zur Sammlung hinzufügen',
            add_page_to_collection: 'Seite zur inneren Sammlung hinzufügen',
            delete_collection: 'Sammlung löschen',
            add_parent_collection: 'Übergeordnete Sammlung hinzufügen',
            type_page_task_or_collection: 'Eine Seite/Aufgabe oder Sammlung eingeben...',
            select_create_new: 'Auswählen oder neu erstellen',
            new_collection: 'Neue Sammlung',
            new_collection_placeholder: 'Eine neue Sammlung eingeben...',
            empty_state_message: 'Erstellen Sie Sammlungen, um sie hier zu sehen.',
            sort: 'Sortieren',
            folder_view: 'Ordneransicht',
            tag_view: 'Tag-Ansicht',
            pages: 'Seiten',
            sub_collections: 'Untersammlungen',
            oldest_first: 'Älteste zuerst',
            newest_first: 'Neueste zuerst',
            fewest_first: 'Wenigste Seiten zuerst',
            most_first: 'Meiste Seiten zuerst',
            sub_fewest_first: 'Wenigste zuerst',
            sub_most_first: 'Meiste zuerst',
            close_collections: 'Sammlungen schließen',
            empty_state: 'Fügen Sie Seiten oder Sammlungen zu dieser Sammlung hinzu, um sie hier zu sehen.',
        },
        views: {
            save_view: 'Speichern',
            save_view_name_placeholder: 'Ansichtsname ...',
            create_new_button: 'Neue Ansicht erstellen',
            update_button: 'Ansicht aktualisieren',
            delete_view: 'Ansicht löschen',
        },
        settings: {
            sidebar: {
                account: 'Konto',
                title: 'Einstellungen',
                profile: 'Profil',
                language: 'Sprache',
                appearance: 'Erscheinungsbild',
                editor: 'Editor',
                invites: 'Einladungen',
                integrations: 'Integrationen',
                general: 'Allgemein',
                members: 'Mitglieder',
                plans: 'Pläne',
                billing: 'Abrechnung',
                import_export: 'Import & Export',
            },
            profile: {
                title: 'Profil',
                sub_title: 'Verwalten Sie Ihr Saga-Profil',
                your_email: 'Ihre E-Mail',
            },
            language: {
                title: 'Sprache',
                sub_title: 'Ändern Sie die in der Benutzeroberfläche verwendete Sprache.',
                language: 'Sprache',
                language_change_title: 'Sprache aktualisieren',
                language_change_confirmation:
                    'Sind Sie sicher, dass Sie die Sprache aktualisieren möchten? Saga wird neu geladen!',
                language_changed_notification: 'Sprache aktualisiert',
            },
            appearance: {
                title: 'Erscheinungsbild',
                sub_title: 'Personalisieren Sie das Erscheinungsbild von Saga.',
                theme: 'Thema',
                show_icons: 'Symbole in Inline-Seitenlinks anzeigen',
                mode_auto: 'Automodus',
                mode_light: 'Hellmodus',
                mode_dark: 'Dunkelmodus',
                start_week_on_monday: 'Woche am Montag beginnen',
                font_default: 'Standard',
                interface_font: 'Schnittstellenschrift',
                text_font: 'Textschrift',
                example_text: 'Beispieltext, um Ihre Schrift zu testen',
            },
            editor: {
                title: 'Editor',
                sub_title: 'Personalisieren Sie Ihr Bearbeitungserlebnis.',
                spellcheck: 'Rechtschreibprüfung',
            },
            invite: {
                title: 'Arbeitsbereichseinladungen',
                sub_title: 'Sehen und akzeptieren Sie alle Ihre Arbeitsbereichseinladungen',
                no_invites: 'Sie haben im Moment keine Arbeitsbereichseinladungen.',
                invite_members_to: 'Mitglieder einladen',
                max_invites_reached:
                    '<bold>{{maxInvitedMembers}}/{{totalMembers}}</bold> freie Einladungen verbleibend. <upgrade>Plan upgraden</upgrade> für unbegrenzte Einladungen',
                accept_invite: 'Einladung annehmen',
                invite_accepted: 'Einladung angenommen',
                owned_by: 'gehört <1>{{ownerEmail}}</1>',
            },
            page_invite: {
                title: 'Seiteneinladungen',
                sub_title: 'Sehen und akzeptieren Sie alle Ihre Seiteneinladungen',
                no_invites: 'Sie haben im Moment keine Seiteneinladungen.',
            },
            integrations: {
                title: 'Integrationen',
                sub_title: 'Integrieren Sie andere Tools mit Saga',
                remove_integration: 'Sind Sie sicher, dass Sie diese Integration entfernen möchten?',
                google_drive: {
                    title: 'Google Drive',
                    description:
                        'Durchsuchen Sie Ihr Laufwerk und erwähnen Sie Dateien auf Seiten und in Aufgaben. <1>Mehr erfahren</1>',
                    button_connect: 'Mit Google Drive verbinden',
                    button_disconnect: 'Integration entfernen',
                    last_updated: 'Zuletzt aktualisiert {{date}}',
                },
                linear: {
                    title: 'Linear',
                    description:
                        'Durchsuchen Sie Linear und erwähnen Sie Probleme aus Ihrem Linear-Arbeitsbereich. <1>Mehr erfahren</1>',
                    button_connect: 'Mit Linear verbinden',
                    button_disconnect: 'Integration entfernen',
                },
            },
            general: {
                title: 'Allgemein',
                sub_title: 'Verwalten Sie Ihre allgemeinen Arbeitsbereichseinstellungen',
                workspace_name: 'Arbeitsbereichsname',
                icon: 'Symbol',
                icon_description: 'Ändern Sie die Farbe des Arbeitsbereichssymbols.',
                embeddings: 'Arbeitsbereichseinbettungen',
                embeddings_description: 'Erzeugen Sie Einbettungen für diesen Arbeitsbereich.',
                copy_workspace: 'Arbeitsbereich kopieren',
                copy_workspace_description:
                    'Alle Seiten, Sammlungen und Einstellungen dieses Arbeitsbereichs werden in einen neuen Arbeitsbereich kopiert.',
                delete_workspace: 'Arbeitsbereich löschen',
                delete_workspace_description:
                    'Das Löschen eines Arbeitsbereichs wird alle seine Daten löschen. Dies kann nicht rückgängig gemacht werden.',
                delete_workspace_confirmation: 'Sind Sie sicher, dass Sie diesen Arbeitsbereich löschen möchten?',
                leave_workspace: 'Arbeitsbereich verlassen',
                leave_workspace_confirmation: 'Sind Sie sicher, dass Sie diesen Arbeitsbereich verlassen möchten?',
                autolink: 'Automatische Verlinkung',
                autolink_description:
                    'Wenn aktiviert, erstellt Saga automatisch Verlinkungen zu Seitentiteln innerhalb von Seiten und Aufgaben.',
            },
            members: {
                title: 'Mitglieder',
                sub_title: 'Laden Sie andere in diesen Arbeitsbereich ein',
                add_member: 'Ein Mitglied hinzufügen',
                remove_member: 'Mitglied entfernen',
                remove_member_confirmation: 'Sind Sie sicher, dass Sie {{name}} entfernen möchten?',
                remove_confirm: 'Entfernen bestätigen',
                remove_member_toast_success: 'Mitglied entfernt',
                pending_invite: 'Ausstehende Einladung',
                revoke_invite: 'Einladung widerrufen',
                revoke_invite_confirmation: 'Sind Sie sicher, dass Sie die Einladung widerrufen möchten?',
                revoke_confirm: 'Widerruf bestätigen',
                revoke_invite_toast_success: 'Einladung widerrufen',
                paid_feature: 'Bezahltes Feature',
                role_admin: 'Admin',
                role_page_admin: 'Seitenadministrator',
                role_no_access: 'Kein Zugriff',
                role_admin_tooltip:
                    'Dieses Mitglied kann <1>Inhalte erstellen, bearbeiten und löschen</1> im Arbeitsbereich und <1>andere einladen</1>. Sie können auch <1>die Einstellungen verwalten</1> und <1>den Arbeitsbereich löschen</1>.',
                role_editor: 'Editor',
                role_editor_tooltip:
                    'Dieses Mitglied kann <1>Inhalte erstellen, bearbeiten und löschen</1> im Arbeitsbereich.',
                role_viewer: 'Betrachter',
                role_viewer_tooltip: 'Dieses Mitglied kann <1>Inhalte ansehen</1> im Arbeitsbereich.',
                role_member: 'Mitglied',
                role_member_tooltip:
                    'Dieses Mitglied kann <1>Inhalte im Arbeitsbereich erstellen, bearbeiten und löschen</1> und <1>andere einladen. Sie können auch Seiten/Arbeitsbereiche öffentlich machen</1> im Arbeitsbereich.',
                role_blocked_tooltip: 'Dieses Mitglied hat keinen Zugriff auf den Arbeitsbereich',
                invite_members: 'Mitglieder zu {{-workspaceName}} einladen',
                plus_add_more: '+ Mehr hinzufügen',
                send_invites: 'Einladungen senden',
                invites_sent: 'Einladungen gesendet',
                upgrade_plan: 'Ihren Plan upgraden',
                upgrade_plan_details:
                    'Sie haben das Limit von {{count}} kostenlosen Benutzern erreicht. Upgraden Sie Ihren Plan, um mehr Benutzer einzuladen und zusätzliche Funktionen freizuschalten, um aufzusteigen.',
            },
            plans: {
                title: 'Workspace-Pläne',
                loading: 'Lädt ...',
                sub_title_free:
                    'Nutzen Sie Saga kostenlos, <bold>upgraden Sie für bessere Zusammenarbeit</bold>, <bold>Aufgabenmanagement</bold> und <bold>unbegrenztes Saga KI</bold>.',
                sub_title_monthly_one:
                    'Ihr aktuelles Abonnement ist der Standard-Monatsplan. Ihr Arbeitsbereich mit <bold>{{membersCount}} Mitglied</bold> kostet <bold>{{amount}}</bold> pro Monat und wird am <bold>{{date}}</bold> erneuert.',
                sub_title_monthly_other:
                    'Ihr aktuelles Abonnement ist der Standard-Monatsplan. Ihr Arbeitsbereich mit <bold>{{membersCount}} Mitgliedern</bold> kostet <bold>{{amount}}</bold> pro Monat und wird am <bold>{{date}}</bold> erneuert.',
                sub_title_yearly_one:
                    'Ihr aktuelles Abonnement ist der Standard-Jahresplan. Ihr Arbeitsbereich mit <bold>{{membersCount}} Mitglied</bold> kostet <bold>{{amount_month}}</bold> pro Monat / <bold>{{amount_year}}</bold> pro Jahr und wird am <bold>{{date}}</bold> erneuert.',
                sub_title_yearly_other:
                    'Ihr aktuelles Abonnement ist der Standard-Jahresplan. Ihr Arbeitsbereich mit <bold>{{membersCount}} Mitgliedern</bold> kostet <bold>{{amount_month}}</bold> pro Monat / <bold>{{amount_year}}</bold> pro Jahr und wird am <bold>{{date}}</bold> erneuert.',
                sub_title_monthly_canceled_one:
                    'Ihr Arbeitsbereichsplan mit <bold>{{membersCount}} Mitglied</bold> kostet <bold>{{amount}}</bold> pro Monat. Sie haben diesen Plan gekündigt, aber er bleibt aktiv bis <bold>{{date}}</bold>. Danach werden Sie auf einen kostenlosen Plan umgestellt.',
                sub_title_monthly_canceled_other:
                    'Ihr Arbeitsbereichsplan mit <bold>{{membersCount}} Mitgliedern</bold> kostet <bold>{{amount}}</bold> pro Monat. Sie haben diesen Plan gekündigt, aber er bleibt aktiv bis <bold>{{date}}</bold>. Danach werden Sie auf einen kostenlosen Plan umgestellt.',
                sub_title_yearly_canceled_one:
                    'Ihr Arbeitsbereichsplan mit <bold>{{membersCount}} Mitglied</bold> kostet <bold>{{amount_month}}</bold> pro Monat oder <bold>{{amount_year}}</bold> pro Jahr. Sie haben diesen Plan gekündigt, aber er bleibt aktiv bis <bold>{{date}}</bold>. Danach werden Sie auf einen kostenlosen Plan umgestellt.',
                sub_title_yearly_canceled_other:
                    'Ihr Arbeitsbereichsplan mit <bold>{{membersCount}} Mitgliedern</bold> kostet <bold>{{amount_month}}</bold> pro Monat oder <bold>{{amount_year}}</bold> pro Jahr. Sie haben diesen Plan gekündigt, aber er bleibt aktiv bis <bold>{{date}}</bold>. Danach werden Sie auf einen kostenlosen Plan umgestellt.',
            },
            billing: {
                title: 'Abrechnung',
                sub_title: 'Verwalten Sie Ihre Abrechnungsinformationen und Rechnungen.',
                per_month: 'pro Monat / Mitglied',
                free_plan: 'Kostenloser Plan',
                standard_annual_plan: 'Standard-Jahresplan',
                standard_monthly_plan: 'Standard-Monatsplan',
                billing_portal_label: 'Abrechnungsdetails ansehen und bearbeiten',
                invoices: {
                    title: 'Rechnungen',
                    loading: 'Rechnungen werden geladen...',
                    no_invoices: 'Es gibt keine Rechnungen zu zeigen.',
                    no_upcoming_invoices: 'Keine bevorstehenden Rechnungen',
                    next_billing_on_one:
                        'Sie werden <1>${{amount}}</1> am <1>{{date}}</1> für <1>{{billableMembersCount}} Mitglied</1> in Rechnung gestellt',
                    next_billing_on_other:
                        'Sie werden <1>${{amount}}</1> am <1>{{date}}</1> für <1>{{billableMembersCount}} Mitglieder</1> in Rechnung gestellt',
                    see_invoice: 'Rechnung ansehen',
                    invoice_not_ready: 'Rechnung nicht fertig',
                    paid: 'Bezahlt',
                    not_paid: 'Nicht bezahlt',
                },
            },
            export_import: {
                title: 'Import & Export',
                sub_title: 'Arbeitsbereich importieren und exportieren und Exporteinstellungen verwalten.',
                import_workspace: 'Daten importieren',
                import_workspace_description:
                    'Seiten, Aufgaben oder Saga-Arbeitsbereich aus Markdown-, Text- oder JSON-Dateien importieren.',
                import_workspace_cta: 'Arbeitsbereich importieren',
                export_workspace: 'Arbeitsbereich exportieren',
                export_workspace_description: 'Arbeitsbereich als JSON-Dateien exportieren.',
                export_workspace_cta: 'Arbeitsbereich exportieren',
                import_modal: {
                    title: 'Legen Sie Ihre Dateien zum Importieren ab',
                    upload_button: 'Von Ihrem Computer hochladen',
                    max_file_size: 'max. {{size}} MB pro Datei',
                    supported_file_types: 'Unterstützt: Markdown, JSON',
                    unexpected_error_message:
                        'Ein unerwarteter Fehler ist aufgetreten.<br/>Bitte versuchen Sie es erneut oder kontaktieren Sie uns unter <email/>',
                    unsupported_error_title: 'Nicht unterstütztes Dateiformat',
                    unsupported_error_description:
                        'Bitte ziehen Sie entweder einen Ordner, Markdown- oder JSON-Dateien.',
                    successfuly_imported_files_one: 'Erfolgreich {{count}} Datei importiert',
                    successfuly_imported_files_other: 'Erfolgreich {{count}} Dateien importiert',
                    confirm_title: 'Dateien importieren als',
                    add_to_collection: 'Zur Sammlung hinzufügen (optional)',
                    confirm_button: 'Importieren',
                    page: 'Seiten',
                    task: 'Aufgaben',
                },
            },
            ai: {
                title: 'Saga KI',
                sub_title:
                    'Werden Sie kreativ, schreiben Sie schneller und leisten Sie bessere Arbeit direkt in Saga mit Hilfe eines digitalen KI-Assistenten.',
                confirm_delete_command: 'Möchten Sie diesen Befehl wirklich löschen?',
                confirm_delete_prompt: 'Möchten Sie diese Aufforderung wirklich löschen?',

                commands: {
                    title: 'Befehle',
                    add_command: 'Befehl hinzufügen',
                    customize_prompts:
                        'Passen Sie Ihre KI-Aufforderungen und Befehle an. <1>Sehen Sie, wie es funktioniert</1>.',
                    add_prompt: 'Aufforderung hinzufügen',
                },
                new: {
                    title_create_prompt: 'Erstellen Sie eine benutzerdefinierte Aufforderung',
                    title_edit_prompt: 'Bearbeiten Sie Ihre Aufforderung',
                    title_create_command: 'Erstellen Sie einen benutzerdefinierten Befehl',
                    title_edit_command: 'Bearbeiten Sie Ihren Befehl',
                    custom_command_tooltip: 'Ausgewählter Text wird am Ende Ihrer Aufforderung platziert.',
                    command_name_placeholder: 'Befehlsname',
                    prompt_name_placeholder: 'Aufforderungsname',
                    command_placeholder: 'Schreiben Sie hier Ihren Befehl...',
                    prompt_placeholder: 'Schreiben Sie hier Ihre Aufforderung...',
                },
            },
            labels: {
                title: 'Labels',
                sub_title: 'Labels hinzufügen und bearbeiten',
                add_label: 'Label hinzufügen',
                name: 'Name',
                usage: 'Verwendung',
                options: 'Optionen',
                edit_modal: {
                    title_create: 'Label erstellen',
                    title_edit: 'Label bearbeiten',
                    select_color: 'Eine Label-Farbe auswählen',
                    label_name: 'Label-Name',
                },
                delete_label_confirmation_title: 'Das Label "{{title}}" löschen',
                delete_label_confirmation_description:
                    'Das Label wird von {{count}} Aufgaben entfernt.\n\nDiese Aktion kann nicht rückgängig gemacht werden.',
            },
        },
        billing: {
            free: 'Kostenlos',
            free_title: 'Kostenloser Tarif',
            free_description: 'Für Einzelnutzer & kleine Teams',
            standard_title: 'Standardtarif',
            standard_description: 'Am besten für mittelgroße Teams',
            business_title: 'Geschäftstarif',
            business_description: 'Am besten für größere Organisationen',
            monthly: 'Monatlich',
            annually: 'Jährlich',
            downgrade: 'Herabstufen',
            upgrade_plan: 'Tarif upgraden',
            current_plan: 'Aktueller Tarif',
            planned: 'Geplant',
            join_waitlist: 'Warteliste beitreten',
            per_editor_month: 'pro Mitglied / Monat',
            per_space_editor_month: 'pro Mitglied / Monat',
            desktopModal: {
                title: 'Abrechnungsplan ändern',
                description:
                    'Das Ändern Ihres Abrechnungsplans erfordert einen Webbrowser. Wenn Sie fortfahren, wird ein neuer Browser-Tab geöffnet, der Sie zur Abrechnungsseite unserer Anwendung weiterleitet. Bitte schließen Sie Ihre Abrechnungsänderungen im Browser ab.',
                cta: 'Im Browser öffnen',
            },
            change_plan_modal_title: 'Sind Sie sicher, dass Sie das Abrechnungsintervall ändern möchten?',
            plan_upgrade_success_toast: {
                title: 'Der Tarif wurde upgegradet',
                description: 'Sie haben jetzt unbegrenzten Zugang zu allen Saga-Superfunktionen!',
            },
            downgrade_toast: {
                title: 'Ihre Anfrage zum Herabstufen des Tarifs wurde akzeptiert',
                description: 'Sie können alle bezahlten Funktionen nutzen, bis Ihr Abonnement abläuft.',
            },
            upgrade_to_yearly_toast: {
                title: 'Abrechnungsintervall geändert',
            },
            change_plan_modal_description_one:
                'Ihre Kontenabrechnung wird jetzt von monatlich auf jährlich geändert, Guthaben von Ihrem aktuellen Tarif werden auf einen neuen Tarif übertragen und Sie werden <bold>{{price}}</bold> für <bold>einen</bold> Benutzer berechnet.',
            change_plan_modal_description_other:
                'Ihre Kontenabrechnung wird jetzt von monatlich auf jährlich geändert, Guthaben von Ihrem aktuellen Tarif werden auf einen neuen Tarif übertragen und Sie werden <bold>{{price}}</bold> für <bold>{{members}}</bold> Benutzer berechnet.',
            free_plan_info_banner: {
                title: 'Wir freuen uns, dass Sie im Team sind!',
                description:
                    'Sie befinden sich im kostenlosen Tarif, der <bold>{{count}} Mitglieder</bold> in diesem Arbeitsbereich beinhaltet. <1>Mehr erfahren</1>',
            },
            upgrade_plan_banner: {
                title: 'Aufgabenmanager',
                description:
                    'Erhalten Sie einen Überblick über alle Ihre Aufgaben auf allen Ihren Seiten. Suchen und sortieren, finden Sie die Aufgaben, die Sie benötigen und erledigen Sie.',
                primary_button: 'Tarif upgraden',
                primary_button_default: 'Tarife ansehen',
                no_access_desciption: 'Bitte kontaktieren Sie den Eigentümer des Arbeitsbereichs.',
            },
            feedback_modal: {
                title: 'Wir sind traurig, dass Sie den Tarif herabstufen :(',
                description:
                    'Wir haben Saga erstellt, um Ihnen zu helfen, Ihr Wissen zu organisieren. Wir würden wirklich gerne wissen, ob etwas fehlt oder wir etwas besser machen könnten. Ihre Gedanken wären uns sehr hilfreich.',
                placeholder:
                    'Bitte teilen Sie uns mit, warum Sie den Tarif herabstufen. Wir werden unser Bestes tun, um Saga für Sie zu verbessern...',
                primary_button: 'Feedback senden',
            },
            downgrade_to_free_modal: {
                title: 'Sind Sie sicher, dass Sie den Tarif auf Kostenlos herabstufen möchten?',
                description:
                    "Ihr Arbeitsbereich hat <span class='font-bold'>{{membersCount}} $t(billing.downgrade_to_free_modal.member, {\"count\": {{membersCount}} })</span>. Im kostenlosen Tarif können Sie <span class='font-bold'>bis zu {{allowedMembers}}</span> haben. Am Ende des Abonnements werden <span class='font-bold'>{{membersLooseAccess}} $t(billing.downgrade_to_free_modal.member, {\"count\": {{membersLooseAccess}} }) den Zugang verlieren</span> zum Arbeitsbereich, und die verbleibenden <span class='font-bold'>{{allowedMembers}} werden zu $t(billing.downgrade_to_free_modal.admin, {\"count\": {{allowedMembers}} }).</span>",
                member: 'Mitglied',
                member_other: 'Mitglieder',
                admin: 'Admin',
                admin_other: 'Admins',
                features_title: 'Sie werden diese Superfunktionen verlieren:',
                feature_1: 'Unbegrenztes Saga KI mit GPT-4',
                feature_2: 'Aufgabenmanager',
                feature_3: 'Benutzerberechtigungen',
                feature_4: 'Unbegrenzte Arbeitsbereichsmitglieder',
                primary_button: 'Tarif behalten',
                secondary_button: 'Herabstufen',
            },
            ai_upgrade_plan_banner: {
                description: 'Ihre kostenlosen KI-Anfragen wurden verwendet',
                primary_action: 'Tarif upgraden',
            },
            ai_popover_modal_upgrade: {
                title: 'Upgraden Sie Ihren Tarif',
                description:
                    'Ihre kostenlosen KI-Anfragen wurden verwendet. Upgraden Sie für unbegrenzte KI-Antworten und zusätzliche Funktionen, um Ihre Produktivität zu steigern.',
            },
            modal_plans: {
                title: 'Arbeitsbereichstarif auswählen',
                sub_title:
                    '<bold>Sie haben das Limit von {{count}} kostenlosen</bold> Arbeitsbereichen erreicht. Upgraden Sie, um Zugang zu zusätzlichen Superfunktionen zu erhalten, die Ihre Arbeit auf die nächste Stufe bringen.',
                loading_plans: 'Tarife werden geladen...',
                footer_disclaimer:
                    'Alle Preise sind in USD und werden pro Arbeitsbereich berechnet. MwSt. kann anfallen.',
                fair_usage: 'faire Nutzung',
                sagaAi_disclaimer: '*Saga AI unterliegt <fairUsageLink/>.',
                footer_contacts: 'Haben Sie Fragen zur Abrechnung? Fragen Sie uns <link/>.',
            },
            plan_features: {
                unlimited_pages_tasks: 'Unbegrenzte Seiten & Aufgaben',
                limited_members: '<bold>Bis zu {{count}}</bold> Benutzer / Arbeitsbereich',
                unlimited_members: '<bold>Unbegrenzte</bold> Arbeitsbereichsmitglieder',
                limited_saga_requests: 'Saga KI mit <bold>{{count}} Wörtern</bold> / Monat',
                unlimited_saga_requests: '<bold>Unbegrenztes</bold> Saga KI mit GPT-4',
                limited_integrations: '<bold>{{count}}</bold> Integration: <google /> oder <linear />',
                unlimited_integrations: '<google /> und <linear /> Integrationen',
                tooltip_integrations: {
                    google_drive: 'Öffnen, suchen und Google Drive-Dateien direkt in Saga erwähnen',
                    linear: 'Erwähnen, suchen oder Live-Links zu Linear-Problemen direkt in Saga einfügen.',
                },
                apps: 'Mac und Windows',
                tooltip_sagaai: 'Saga AI ist der intelligente Assistent in Ihrem Arbeitsbereich.',
                tooltip_apps: {
                    content: 'Laden Sie die Saga-Desktop-Apps für ein noch besseres Erlebnis herunter.',
                    apps: 'Apps',
                },
                task_manager: 'Aufgabenmanager',
                tooltip_task_manager: 'Verwalten Sie alle Ihre Aufgaben an einem Ort.',
                user_permissions: 'Benutzerberechtigungen',
                advanced_user_permissions: 'Erweiterte Benutzerberechtigungen',
                search_with_ai: 'Verwenden Sie <sagaAi /> mit Tools und dem Web',
                version_history: 'Versionsgeschichte',
                version_history_free: '7-Tage-Versionsverlauf',
                version_history_standard: '30-Tage-Versionsverlauf',
                version_history_business: 'Unbegrenzter Versionsverlauf',
                offline_mode: 'Offline-Modus',
                business_features_title: 'Alles im Standardtarif plus:',
                free_limitations: '*Bis zu {{count}} kostenlose Arbeitsbereiche pro Konto',
                public_access: 'Öffentliche Seiten und Arbeitsbereiche',
                custom_ai_commands: 'Benutzerdefinierte <sagaAi /> Befehle',
                ai_talk_to_your_page: '<sagaAi /> spricht mit Ihren Seiten',
                limited_upload_file: '{{count}} MB maximale Dateigröße',
                limited_upload_total_mb: '{{count}} MB Dateispeicher',
                limited_upload_total_gb: '{{count}} GB Dateispeicher',
                limited_guests: '{{count}} Gäste',
            },
            choose_workspace_modal: {
                title: 'Upgrade erforderlich',
                description:
                    'Sie haben die maximale Anzahl kostenloser Arbeitsbereiche erreicht. Um die Einladung zum neuen Arbeitsbereich anzunehmen, aktualisieren Sie bitte einen Ihrer bestehenden kostenlosen Arbeitsbereiche.',
                go_to_plans: 'Zu den Plänen',
            },
        },
        table: {
            move_left: 'Nach Links verschieben',
            move_right: 'Nach Rechts verschieben',
            insert_column_left: 'Spalte Links einfügen',
            insert_column_right: 'Spalte Rechts einfügen',
            remove_column: 'Spalte entfernen',
            append_column: 'Spalte anhängen',
            append_row: 'Zeile anhängen',
            remove_row: 'Zeile entfernen',
        },
    },
};
