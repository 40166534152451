import { RefObject, useEffect } from 'react';

type Options = {
    clickEvent: 'click' | 'mousedown';
};

const DEFAULT_OPTIONS: Options = {
    clickEvent: 'click',
};

export default function useOnClickOutside<T extends HTMLElement>(
    ref: RefObject<T> | RefObject<T | HTMLDivElement>[],
    handler: (event: MouseEvent | TouchEvent) => void,
    options: Partial<Options> = DEFAULT_OPTIONS,
    active = true,
) {
    const { clickEvent } = { ...DEFAULT_OPTIONS, ...options };

    useEffect(
        () => {
            if (!active) return;

            const listener = (event: MouseEvent | TouchEvent) => {
                // Do nothing if clicking ref's element or descendent elements
                if (Array.isArray(ref)) {
                    for (const r of ref) {
                        if (r.current?.contains(event.target as Node)) {
                            return;
                        }
                    }
                } else {
                    if (ref.current?.contains(event.target as Node)) {
                        return;
                    }
                }

                if (handler) {
                    handler(event);
                }
            };

            document.addEventListener(clickEvent, listener);
            document.addEventListener('touchstart', listener);

            return () => {
                document.removeEventListener(clickEvent, listener);
                document.removeEventListener('touchstart', listener);
            };
        },
        // Add ref and handler to effect dependencies
        // It's worth noting that because passed in handler is a new ...
        // ... function on every render that will cause this effect ...
        // ... callback/cleanup to run every render. It's not a big deal ...
        // ... but to optimize you can wrap handler in useCallback before ...
        // ... passing it into this hook.
        [ref, handler, clickEvent, active],
    );
}
