import React, { useState, useRef, useEffect } from 'react';
import { ShareSuggestion } from '@/types';
import { RenderItemProps, SelectDropdown } from './Dropdown';
import { CreateSuggestionSelectButton } from '../editor/Suggestions';
import { SingleButtonWithChevronOption } from './AddToCollectionButton';

export type SelectedItemType = { title: string; type: string; kind: string; isTemplate?: boolean | undefined };

const SingleCreateButtonWithSelect = ({
    children,
    placeholder,
    onSelect,
    selectOptions,
    icon,
    widthLg,
    disabled = false,
}: {
    children: string;
    placeholder: string;
    onSelect: (selectedItem: SelectedItemType) => void;
    selectOptions: ShareSuggestion[];
    icon: any;
    disabled: boolean;
    widthLg?: boolean;
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isSelected, setIsSelected] = useState(false);
    const elementRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        function handleClickOutside(event: Event) {
            if (elementRef.current && !elementRef.current.contains(event.target as Node)) {
                setIsSelected(false);
            }
        }

        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    return (
        <>
            <div ref={elementRef}>
                <SingleButtonWithChevronOption
                    onClick={() => {}}
                    setIsSelected={setIsSelected}
                    setIsOpen={() => setIsOpen(!isOpen)}
                    isSelected={isSelected}
                    icon={icon}
                    widthLg={widthLg}
                    disabled={disabled}
                >
                    {children}
                </SingleButtonWithChevronOption>
            </div>
            {isOpen && (
                <SelectDropdown<ShareSuggestion>
                    attachToRef={elementRef}
                    isOpen={isOpen}
                    onClose={() => setIsOpen(false)}
                    onSubmit={({ selectedItem }) => {
                        onSelect(selectedItem);
                        setIsOpen(false);
                    }}
                    inputProps={{
                        placeholder,
                        title: placeholder,
                    }}
                    widthLg={widthLg}
                    renderItems={() => {
                        return selectOptions.map((item: ShareSuggestion) => {
                            return {
                                render({ isSelected, setSelection, submit, index }: RenderItemProps) {
                                    return (
                                        <div className="min-w-0" key={index}>
                                            <CreateSuggestionSelectButton
                                                suggestion={item}
                                                selected={isSelected}
                                                onMouseEnter={setSelection}
                                                onClick={submit}
                                                showIcons={true}
                                            >
                                                {item.title}
                                            </CreateSuggestionSelectButton>
                                        </div>
                                    );
                                },
                                item,
                            };
                        });
                    }}
                />
            )}
        </>
    );
};

export default SingleCreateButtonWithSelect;
