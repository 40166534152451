import { SafeSpace } from '../types';
import deleteCollectionById from './deleteCollectionById';
import findCollectionById from './findCollectionById';
import transactInSpace from './transactInSpace';

export default function deleteCollectionIfEmpty(space: SafeSpace, collectionId: any) {
    transactInSpace(space, () => {
        try {
            const collection = findCollectionById(space, collectionId);
            //@ts-ignore
            if (collection.get('title') === '' && collection.get('subCollections').length === 0) {
                deleteCollectionById(space, collectionId);
            }
        } catch {
            // cannot find collection
        }
    });

    return;
}
