import { Align } from '@/../../shared/src';
import classNames from 'classnames';
import React, { ButtonHTMLAttributes } from 'react';
import { AlignCenter, AlignLeft, AlignRight } from 'react-feather';
import Tooltip from './popover/Tooltip';

export default function ImageAlignSelect({ align, onAlign }: { align: Align; onAlign(align: Align): void }) {
    return (
        <div className="w-full flex justify-between space-x-2 px-2 py-1 rounded text-left text-sm">
            <div className="my-auto">Align:</div>
            <Tooltip content="Align Left" placement="top">
                <SelectableButton onClick={() => onAlign('left')} selected={!align || align === 'left'}>
                    <AlignLeft size={14} className="stroke-gray-dark" />
                </SelectableButton>
            </Tooltip>
            <Tooltip content="Align Center" placement="top">
                <SelectableButton onClick={() => onAlign('center')} selected={align === 'center'}>
                    <AlignCenter size={14} className="stroke-gray-dark" />
                </SelectableButton>
            </Tooltip>
            <Tooltip content="Align Right" placement="top">
                <SelectableButton onClick={() => onAlign('right')} selected={align === 'right'}>
                    <AlignRight size={14} className="stroke-gray-dark" />
                </SelectableButton>
            </Tooltip>
        </div>
    );
}

const SelectableButton = React.forwardRef<
    HTMLDivElement,
    Omit<ButtonHTMLAttributes<HTMLDivElement>, 'className'> & { selected: boolean }
>(function ImageSelectableButton({ selected, children, ...props }, ref) {
    return (
        <div
            ref={ref}
            {...props}
            className={classNames(
                'cursor-pointer relative block p-2 overflow-hidden rounded hover:bg-saga-gray-250 dark:hover:bg-saga-gray-700 focus:outline-none',
                {
                    'bg-saga-gray-150 dark:bg-saga-gray-800': selected,
                },
            )}
        >
            {children}
        </div>
    );
});
