import React from 'react';
import useBlockDrop from '@/hooks/useBlockDrop';
import { EditorOperations } from '@saga/shared';
import { ReactEditor, useSlateStatic } from 'slate-react';
import { useIsDragAndDropEnabled, useOnDrop } from './DragAndDrop';
import { DndOverArea } from '@/types';
import { useSetAIPopoverOpen } from '@/components/editor/popover/AIPopoverProvider';
import classNames from 'classnames';

export default function BottomDropArea({
    className,
    children,
}: {
    className?: string;
    children(overArea: DndOverArea | null): React.ReactNode;
}) {
    const editor = useSlateStatic();
    const onDrop = useOnDrop();
    const isDndEnabled = useIsDragAndDropEnabled();
    const { isOpen: isAIPopoverOpen } = useSetAIPopoverOpen();
    const { overArea, dropRef } = useBlockDrop({
        onDrop(item) {
            onDrop({ item, editor, path: [editor.children.length] });
        },
        isEnabled: isDndEnabled,
    });

    function onClick() {
        ReactEditor.focus(editor);
        EditorOperations.Selection.safeSelectEdge(editor, 'end');
    }

    return (
        <div
            ref={dropRef}
            onClick={onClick}
            className={classNames(className, {
                'pb-[650px]': isAIPopoverOpen,
                'pb-72': !isAIPopoverOpen,
            })}
            data-testid="bottom-drop-area"
        >
            {children(overArea)}
        </div>
    );
}
