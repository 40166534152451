import React, { ReactNode, createContext, useContext } from 'react';
import { usePermissionsUpdatedSubscription, useSpacePermissionsQuery } from '@saga/api';

const SpacePermissionsContext = createContext({
    spacePermissions: {
        sagaAiStatus: 'enabled',
    },
});

export const useSpacePermissions = () => useContext(SpacePermissionsContext);

export default function SpacePermissionsProvider({ children, urlKey }: { children: ReactNode; urlKey: string }) {
    const { data } = usePermissionsUpdatedSubscription({ variables: { input: { urlKey: urlKey } } });

    const result = useSpacePermissionsQuery({
        variables: { input: { urlKey: urlKey } },
        fetchPolicy: 'cache-and-network',
    });

    const spacePermissions = data?.permissionsUpdated ??
        result.data?.spacePermissions ?? {
            sagaAiStatus: 'enabled',
        };

    return <SpacePermissionsContext.Provider value={{ spacePermissions }}>{children}</SpacePermissionsContext.Provider>;
}
