import { apolloClient } from '@/graphql';
import React, {
    ClipboardEvent,
    Dispatch,
    KeyboardEvent,
    SetStateAction,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { ChevronDown, ChevronUp } from 'react-feather';
import { v4 as uuid } from 'uuid';
import { CustomPromptType } from '@/../../api/src';
import { AIMessage, AIPrompt, AISourceItem, Converter, SagaElement, SagaLocation } from '@/../../shared/src';
import { track } from '@/analytics';
import { useBlockPlugins } from '@/components/BlockPluginProvider';
import { WithExplicitFontType } from '@/components/WithExplicitFontType';
import AIActionsSuggest from '@/components/editor/ai/AIActionsSuggest';
import AIPromptDisplay from '@/components/editor/ai/AIPromptDisplay';
import RestrictedAIBanner from '@/components/editor/ai/RestrictedAIBanner';
import BasicButton from '@/components/styled/BasicButton';
import useAIAssist, { AIStatus } from '@/hooks/useAIAssist';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSlateStatic } from 'slate-react';
import UpgradePlanBanner from './UpgradePlanBanner';
import { SagaEditor } from '@saga/shared';
import AIStaticEditorContent from './AIStaticEditorContent';
import { useCurrentWorkspace } from '@/components/WorkspaceContext';
import AISourcesList from '@/components/editor/ai/AISourcesList';
import AIPromptInput from '@/components/editor/ai/AIPromptInput';
import { getPromptText, getReferencedDocuments } from '@/utils/aiUtils';
import { useAIEnabled } from '@/components/editor/ai/AIPermissionsProvider';
import AICopyButton from '@/components/editor/ai/AICopyButton';
import { useSpace } from '@/components/SpaceProvider';

interface InputProps {
    history: AIMessage[];
    prompt: AIPrompt;
    onKeep: (blocks: SagaElement[], replace: boolean) => void;
    onDiscard: () => void;
    onStatusChange: (status: AIStatus, messages: AIMessage[]) => void;
    setPrompt?: Dispatch<SetStateAction<AIPrompt | undefined>>;
    onEscapeKeyDown?: () => void;
    embedText?: boolean;
    autofocus?: boolean;
}

const AIAnswerContent = ({
    prompt,
    history,
    onKeep,
    onDiscard,
    onStatusChange,
    setPrompt,
    onEscapeKeyDown,
    embedText = false,
    autofocus = true,
}: InputProps) => {
    const { t } = useTranslation();
    const { urlKey } = useCurrentWorkspace();
    const blockPlugins = useBlockPlugins();

    const { status: responseStatus, messages, generateAIText, stop, error } = useAIAssist(history);
    const [userInput, setUserInput] = useState<string>('');
    const [messageIndex, setMessageIndex] = useState(0);
    const [blocks, setBlocks] = useState<SagaElement[]>([]);
    const { space } = useSpace();

    const [status, setStatus] = useState<AIStatus>(responseStatus);

    const assistantMessages = useMemo(
        () => messages.filter((m) => m.role === 'assistant' && !m.tool_calls),
        [messages],
    );

    const selectedAssistantMessage = useMemo(() => assistantMessages[messageIndex], [assistantMessages, messageIndex]);
    const references = useMemo(
        () =>
            getReferencedDocuments(
                messages.filter((m) => m.role === 'assistant' && m.tool_calls),
                space,
            ),
        [space, messages],
    );

    const [sources, setSources] = React.useState<AISourceItem[]>(prompt.references);

    const userPrompts = useMemo(() => messages.filter((m) => m.role === 'user'), [messages]);
    const selectedUserPrompt: AIMessage | undefined = useMemo(
        () => userPrompts[messageIndex],
        [userPrompts, messageIndex],
    );
    const [suggestionsOpened, setSuggestionsOpened] = useState(false);
    const { aiStatus } = useAIEnabled();

    const id = useMemo(() => uuid(), []);

    const isOKStatus = useMemo(() => ['done', 'idle'].includes(status), [status]);

    const renderActionBarRef = useRef<HTMLDivElement | null>(null);

    const onAsk = useCallback(
        (question: string, context: string | undefined = undefined) => {
            generateAIText({ prompt: question, textContext: context, references: sources });
            track('ai-popover-message-sent');
        },
        [generateAIText, sources],
    );

    const handleKeyDown = useCallback(
        (e: KeyboardEvent<HTMLDivElement>) => {
            if (e.key === 'Escape') {
                e.preventDefault();
                track('esc-button-ai-stop-generation');

                if (!blocks.length) {
                    if (setPrompt && typeof setPrompt === 'function') {
                        setPrompt(undefined);
                    } else if (onEscapeKeyDown && typeof onEscapeKeyDown === 'function') {
                        onEscapeKeyDown();
                    }

                    if (stop && typeof stop === 'function') {
                        stop();
                    }
                } else {
                    if (stop && typeof stop === 'function') {
                        stop();
                    }
                }
            }
        },
        [stop, setPrompt, onEscapeKeyDown, blocks],
    );

    const canGoBack = useMemo(() => messageIndex < assistantMessages.length - 1, [messageIndex, assistantMessages]);
    const canGoForward = useMemo(() => messageIndex > 0, [messageIndex]);

    const editor = useSlateStatic();

    const trackButton = useCallback((name: string) => {
        track('click-on-ai-popover-button', { button: name });
    }, []);

    useEffect(() => {
        setStatus(responseStatus);
    }, [responseStatus]);

    useEffect(() => {
        if (!blocks.length && status === 'done') {
            setStatus('error');
        }
    }, [blocks, status]);

    useEffect(() => {
        // If there are no suggestions yet, generate one using useAIAssist hook
        if (messages?.length === 0 && status === 'idle') {
            generateAIText(prompt);
        }
    }, [prompt, generateAIText, messages.length, status]);

    useEffect(() => {
        if (['done', 'error'].includes(status)) {
            apolloClient.refetchQueries({ include: ['UserData'] });
        }

        if (status === 'done') {
            setUserInput('');
        }

        onStatusChange(status, messages);

        if (status === 'loading') {
            setSuggestionsOpened(false);
        }
    }, [messages, status, onStatusChange, isOKStatus]);

    useEffect(() => {
        setMessageIndex(0);
    }, [assistantMessages.length]);

    useEffect(() => {
        if (selectedAssistantMessage) {
            const elements = Converter.xmlToSaga(selectedAssistantMessage?.content ?? '');

            if (elements) {
                setBlocks(elements as SagaElement[]);
            }
        }
    }, [selectedAssistantMessage]);

    const focusInputField = useCallback(() => {
        const element = document.getElementById(id);
        if (element instanceof HTMLElement) {
            setTimeout(() => element.focus());
        }
    }, [id]);

    React.useLayoutEffect(() => {
        if (status === 'done' && autofocus) {
            // focusing the input manually since using autofocus causes issues with Slate: Cannot resolve a DOM node from Slate node
            focusInputField();
        }

        if (status === 'loading') {
            renderActionBarRef.current?.focus();
        }
    }, [focusInputField, status, editor, autofocus]);

    const RenderSuggestedText = () => {
        const [height, setHeight] = useState(0);
        const containerRef = useRef<HTMLDivElement>(null);

        useEffect(() => {
            if (containerRef.current) setHeight(containerRef.current?.clientHeight);
        }, []);

        function onCopy() {
            track('ai-copy-button');
            const copyListener = (event: ClipboardEvent) => {
                SagaEditor.Clipboard.copyBlocks(blocks, {
                    location: SagaLocation.pageLocationFromId('SagaAIPopover'),
                    spaceUrlKey: urlKey,
                    blockPlugins,
                    event,
                });
            };

            //@ts-ignore
            document.addEventListener('copy', copyListener);

            document.execCommand('copy');

            //@ts-ignore
            document.removeEventListener('copy', copyListener);
        }

        return blocks.length ? (
            <div
                className={classNames('w-full rounded text-saga-text dark:text-white relative group', {
                    'bg-saga-new-purple-light/20 dark:bg-saga-purple-dark': !embedText,
                    'pb-0.5': embedText && selectedAssistantMessage?.content,
                    'max-h-[336px] overflow-auto whitespace-pre-wrap': embedText,
                    'px-3': embedText,
                })}
                ref={containerRef}
                data-testid="AI-answer"
            >
                <WithExplicitFontType type="editor">
                    <AIStaticEditorContent content={selectedAssistantMessage?.content} />
                </WithExplicitFontType>

                <div
                    className={classNames(
                        'absolute h-7 w-7 rounded bg-white hover:bg-saga-gray-250 dark:bg-saga-gray-1000 dark:hover:bg-saga-gray-700 items-center justify-center border border-solid border-saga-gray-300 hover:border-saga-gray-250 dark:border-saga-gray-600 dark:hover:border-saga-gray-700 cursor-pointer z-100 hidden group-hover:flex',
                        {
                            'top-1 right-1': height <= 44,
                            'top-2 right-2': height > 44,
                        },
                    )}
                >
                    <AICopyButton
                        onCopy={onCopy}
                        text={{ before: t('common.copy_text'), after: t('common.text_copied') }}
                        id="AI-answer-copy-button"
                    />
                </div>
            </div>
        ) : null;
    };

    const containerClassName = 'shadow-popupSmall rounded-md bg-white dark:bg-saga-gray-900 p-1 mt-2 outline-none';

    const renderActionButtons = () => (
        <div>
            <div className="flex justify-between items-center pb-2 px-2">
                <div className="flex flex-row items-center">
                    <BasicButton
                        className="h-8 pl-4 pr-2.5"
                        variant="secondary_inverted"
                        onClick={() => {
                            trackButton('rewrite');
                            setSuggestionsOpened((opened) => !opened);
                        }}
                        size="small"
                    >
                        <div className="flex items-center ">
                            {t('ai.rewrite')}
                            {suggestionsOpened ? (
                                <ChevronUp className="ml-1.5" size={12} />
                            ) : (
                                <ChevronDown className="ml-1.5" size={12} />
                            )}
                        </div>
                    </BasicButton>
                </div>

                <div className="flex flex-row items-center">
                    {!embedText ? (
                        <BasicButton
                            data-testid="keep-button"
                            className="h-8 px-4"
                            size="small"
                            variant="secondary"
                            onClick={() => {
                                trackButton('keep');
                                onKeep?.(blocks, false);
                            }}
                        >
                            {t('ai.keep')}
                        </BasicButton>
                    ) : (
                        <>
                            <BasicButton
                                className="mr-2 h-8 px-4"
                                variant="secondary_inverted"
                                size="small"
                                onClick={() => {
                                    trackButton('insert-below');
                                    onKeep?.(blocks, false);
                                }}
                            >
                                {t('ai.insert_below')}
                            </BasicButton>
                            <BasicButton
                                className="h-8 px-4"
                                size="small"
                                variant="secondary"
                                onClick={() => {
                                    trackButton('replace');
                                    onKeep?.(blocks, true);
                                }}
                            >
                                {t('ai.replace')}
                            </BasicButton>
                        </>
                    )}
                </div>
            </div>
        </div>
    );

    const renderActionBar = () => (
        <>
            {status === 'done' && embedText && <div className="bg-saga-gray-200 dark:bg-saga-gray-700 h-[1px] mx-2" />}
            <div
                className={classNames('outline-none', { [containerClassName]: !embedText })}
                onKeyDown={handleKeyDown}
                ref={renderActionBarRef}
                tabIndex={1}
            >
                {!embedText ? renderPrompt() : null}
                <div
                    className={classNames('flex justify-between items-center', {
                        'flex-row px-2 py-0.5 my-1': status !== 'loading',
                        'px-2.5 py-0.5': status === 'loading',
                    })}
                >
                    <AIPromptInput
                        id={id}
                        placeholder={t('ai.ask_ai_anything_mentions_dotted')}
                        sources={sources}
                        text={userInput}
                        setText={setUserInput}
                        status={status}
                        error={error}
                        onUpdateSources={setSources}
                        onSubmit={onAsk}
                        onRetry={() => generateAIText({ ...prompt, references: sources })}
                        onCancel={() => {
                            if (!blocks.length) {
                                setPrompt ? setPrompt?.(undefined) : onEscapeKeyDown?.();
                                stop?.();
                            } else {
                                stop?.();
                            }
                        }}
                        onSourcesVisible={(visible) => {
                            if (!visible) focusInputField();
                        }}
                    />
                </div>
                {aiStatus === 'disabled' && <UpgradePlanBanner />}
                {aiStatus === 'restricted' && <RestrictedAIBanner />}
                {(status === 'done' || status === 'idle') && renderActionButtons()}
            </div>
        </>
    );

    const renderPrompt = () => {
        const type: CustomPromptType =
            !embedText && messageIndex === userPrompts.length - 1 ? CustomPromptType.Prompt : CustomPromptType.Command;
        const text = selectedUserPrompt && getPromptText(selectedUserPrompt);

        if (!text) return null;

        return (
            <div>
                <AIPromptDisplay
                    promptType={type}
                    promptText={text}
                    canGoBack={canGoBack}
                    canGoForward={canGoForward}
                    messageIndex={messageIndex}
                    assistantMessages={assistantMessages}
                    referencedDocuments={references}
                    isOKStatus={isOKStatus}
                    trackButton={trackButton}
                    setMessageIndex={setMessageIndex}
                    onAsk={onAsk}
                    onDiscard={() => {
                        trackButton('discard');
                        onDiscard?.();
                    }}
                />
                <div className="mt-1">
                    <AISourcesList
                        sources={sources}
                        onItemRemoved={(id) => {
                            setSources(sources.filter((source) => source.id !== id));
                            track('click-on-ai-popover-button', { source: 'ai-popover' });
                        }}
                    />
                </div>
            </div>
        );
    };

    return (
        <>
            <div contentEditable={false} className={classNames({ [containerClassName]: embedText })}>
                {embedText ? renderPrompt() : null}
                {RenderSuggestedText()}
                <WithExplicitFontType type="interface">{renderActionBar()}</WithExplicitFontType>
            </div>
            {suggestionsOpened && (
                <div className="mt-1">
                    <AIActionsSuggest
                        type={CustomPromptType.Command}
                        onSuggestedPrompt={(prompt) => onAsk(prompt, selectedAssistantMessage.content ?? undefined)}
                        filter={userInput}
                    />
                </div>
            )}
        </>
    );
};

export default AIAnswerContent;
