import * as api from '@saga/api';
import { newSagaAdvancedPageEn } from './en';
import { newSagaAdvancedPageFr } from './fr';
import { newSagaAdvancedPageEs } from './es';
import { newSagaAdvancedPagePt } from './pt';
import { newSagaAdvancedPageDe } from './de';
import { newSagaAdvancedPageIt } from './it';
import { Page } from '../../types';

export function newSagaAdvancedPage({ language, collectionIds }: { language: api.Language; collectionIds: string[] }) {
    switch (language) {
        case api.Language.En:
            return newSagaAdvancedPageEn(collectionIds);
        case api.Language.Fr:
            return newSagaAdvancedPageFr(collectionIds);
        case api.Language.Es:
            return newSagaAdvancedPageEs(collectionIds);
        case api.Language.Pt:
            return newSagaAdvancedPagePt(collectionIds);
        case api.Language.De:
            return newSagaAdvancedPageDe(collectionIds);
        case api.Language.It:
            return newSagaAdvancedPageIt(collectionIds);
    }
}
