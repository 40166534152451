import { Page, WeakTask } from '../../types';
import * as api from '@saga/api';
import { newSagaBasicsPageEn } from './en';
import { newSagaBasicsPageFr } from './fr';
import { newSagaBasicsPageEs } from './es';
import { newSagaBasicsPagePt } from './pt';
import { newSagaBasicsPageDe } from './de';
import { newSagaBasicsPageIt } from './it';

export function newSagaBasicsPage({
    task,
    language,
    collectionIds,
}: {
    task: WeakTask;
    language: api.Language;
    collectionIds: string[];
}) {
    switch (language) {
        case api.Language.En:
            return newSagaBasicsPageEn(task, collectionIds);
        case api.Language.Fr:
            return newSagaBasicsPageFr(task, collectionIds);
        case api.Language.Es:
            return newSagaBasicsPageEs(task, collectionIds);
        case api.Language.Pt:
            return newSagaBasicsPagePt(task, collectionIds);
        case api.Language.De:
            return newSagaBasicsPageDe(task, collectionIds);
        case api.Language.It:
            return newSagaBasicsPageIt(task, collectionIds);
    }
}
