import React from 'react';

export function NotificationsIcon({ className }: { className?: string }) {
    return (
        <svg
            className={className}
            width="13"
            height="16"
            viewBox="0 0 13 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M6.41572 0C4.71416 0 3.0823 0.67594 1.87912 1.87912C0.675939 3.0823 0 4.71416 0 6.41572V12.8314C0 13.0441 0.0844926 13.2481 0.23489 13.3985C0.385288 13.5489 0.58927 13.6334 0.801965 13.6334H3.32013C3.50481 14.313 3.90797 14.9129 4.46742 15.3406C5.02687 15.7683 5.71151 16 6.41572 16C7.11993 16 7.80457 15.7683 8.36401 15.3406C8.92346 14.9129 9.32662 14.313 9.5113 13.6334H12.0295C12.2422 13.6334 12.4461 13.5489 12.5965 13.3985C12.7469 13.2481 12.8314 13.0441 12.8314 12.8314V6.41572C12.8314 4.71416 12.1555 3.0823 10.9523 1.87912C9.74913 0.67594 8.11727 0 6.41572 0ZM6.41572 14.4354C6.13445 14.4351 5.8582 14.3608 5.61468 14.2201C5.37117 14.0793 5.16895 13.877 5.02832 13.6334H7.80312C7.66248 13.877 7.46026 14.0793 7.21675 14.2201C6.97323 14.3608 6.69699 14.4351 6.41572 14.4354ZM11.2275 12.0295H1.60393V6.41572C1.60393 5.13955 2.11088 3.91566 3.01327 3.01327C3.91565 2.11088 5.13955 1.60393 6.41572 1.60393C7.69188 1.60393 8.91578 2.11088 9.81817 3.01327C10.7206 3.91566 11.2275 5.13955 11.2275 6.41572V12.0295Z"
                fill="currentColor"
            />
        </svg>
    );
}
