import { SafeSpace } from '../types';
import removePinnedById from './removePinnedById';
import transactInSpace from './transactInSpace';
import * as Y from 'yjs';
import removeFavorite from './removeFavorite';

export default function removeTaskView(space: SafeSpace, id: string) {
    transactInSpace(space, () => {
        const taskViews = space.map.get('taskViews') as Y.Array<Y.Map<unknown>> | undefined;

        const index = taskViews?.toArray().findIndex((taskView) => taskView.get('id') === id);

        if (index == null || index === -1) {
            return;
        }

        taskViews?.delete(index);
        removePinnedById(space, id);
        removeFavorite(space, id);
    });
}
