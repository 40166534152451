import * as Y from 'yjs';
import { transformTaskToSharedType } from '../pageUtils';
import { WeakTask, Task } from '../types';
import { SafeSpace } from '../types';
import { unsafeRight } from '../io-ts-js';
import transactInSpace from './transactInSpace';

export default function createTask(space: SafeSpace, task: Task): Task {
    const yTasks = unsafeRight(space.get('tasks'));
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { blocks: _, ...taskWithoutBlocks } = task;
    transactInSpace(space, () => {
        yTasks.array.push([transformTaskToSharedType(taskWithoutBlocks) as Y.Map<WeakTask>]);
    });

    return task;
}
