import React from 'react';
import { useSpace } from '@/components/SpaceProvider';
import * as Y from 'yjs';
import { useRef } from 'react';
import { SpaceOperations } from '@saga/shared';

const useYPagesEvents = (handlers: {
    onPageDeleted?: (id: string) => void;
    onTaskChanged?: (event: Y.YEvent<any>, yPage: Y.Map<unknown>) => void;
    onPageChanged?: (event: Y.YEvent<any>, yPage: Y.Map<unknown>) => void;
    onCollectionChanged?: (event: Y.YEvent<any>, yCollection: Y.Map<unknown>) => void;
}) => {
    const handlersRef = useRef(handlers);
    handlersRef.current = handlers;

    const { space, yDoc } = useSpace();

    React.useEffect(() => {
        const yspace = space.map;
        const ypages = yspace.get('pages') as Y.Array<Y.Map<unknown>>;
        const ytasks = yspace.get('tasks') as Y.Array<Y.Map<unknown>>;
        const ycollections = yspace.get('collections') as Y.Array<Y.Map<unknown>>;

        const handleLiveReferenceEvents = (events: Y.YEvent<any>[]) => {
            events.forEach((event) => {
                const isLocalChange = event.transaction.local;
                if (!isLocalChange) {
                    return;
                }

                const path = event.path;
                const [pageIndex] = path;
                const [property] = path.slice().reverse();

                if (typeof pageIndex === 'number') {
                    const yPage = ypages.get(pageIndex);

                    if (property === 'collections') {
                        [...event.changes.added.values()].forEach((item) => {
                            const collection = SpaceOperations.findCollectionById(space, item.content.getContent()[0]);
                            handlersRef.current?.onCollectionChanged?.(event, collection);
                        });
                    }

                    if (handlersRef.current.onPageChanged) {
                        handlersRef.current.onPageChanged(event, yPage);
                    }
                }

                // Handle page deletion event
                if (event instanceof Y.YArrayEvent && event.target === ypages) {
                    const deleted = [...event.changes.deleted.values()].map((item) => item.content.getContent()).flat();

                    deleted.forEach((item) => {
                        if (item instanceof Y.Map) {
                            const idItem = item._map.get('id');
                            if (idItem) {
                                const [id] = idItem.content.getContent();

                                if (typeof id === 'string' && handlersRef.current.onPageDeleted) {
                                    handlersRef.current.onPageDeleted(id);
                                }
                            }
                        }
                    });
                }
            });
        };

        const handleTaskEvents = (events: Y.YEvent<any>[]) => {
            events.forEach((event) => {
                // Only track local change to not cause any issues in collaboration
                const isLocalChange = event.transaction.local;
                if (!isLocalChange) {
                    return;
                }
                const path = event.path;
                const [pageIndex] = path;

                if (typeof pageIndex === 'number') {
                    const yTask = ytasks.get(pageIndex);
                    if (handlersRef.current.onTaskChanged) {
                        handlersRef.current.onTaskChanged(event, yTask);
                    }
                }
            });
        };

        const handleCollectionEvents = (events: Y.YEvent<any>[]) => {
            events.forEach((event) => {
                // Only track local change to not cause any issues in collaboration
                const isLocalChange = event.transaction.local;
                if (!isLocalChange) {
                    return;
                }
                const path = event.path;
                const [collectionIndex] = path;
                if (typeof collectionIndex === 'number') {
                    const yCollection = ycollections.get(collectionIndex);
                    handlersRef.current?.onCollectionChanged?.(event, yCollection);
                }
            });
        };

        ypages?.observeDeep(handleLiveReferenceEvents);
        ytasks?.observeDeep(handleTaskEvents);
        ycollections?.observeDeep(handleCollectionEvents);
        return () => {
            ypages?.unobserveDeep(handleLiveReferenceEvents);
            ytasks?.unobserveDeep(handleTaskEvents);
            ycollections?.unobserveDeep(handleCollectionEvents);
        };
    }, [space, yDoc]);
};

export default useYPagesEvents;
