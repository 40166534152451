import { SpaceBlocks } from '..';

export default function isBlockExisting(searchIndexMap: SpaceBlocks, pageId: string, blockId: string) {
    const blocks = searchIndexMap.get(pageId);

    if (blocks) {
        return blocks.some((block) => block.id === blockId);
    }

    return false;
}
