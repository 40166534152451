import { Editor, Transforms } from 'slate';
import { ReactEditor } from 'slate-react';
import { BlockBuilder, isGoogleDriveFileLink } from '..';

export default function addGoogleDrive(getSdk: any, editor: Editor, googleDriveFileId: string) {
    const link = BlockBuilder.googleDriveLink([BlockBuilder.text('')], {
        type: 'loading',
    });

    Transforms.insertFragment(editor, [link]);

    getSdk()
        .then((sdk: any) => {
            if (googleDriveFileId) {
                return sdk.GoogleDriveDocument({
                    input: {
                        id: googleDriveFileId,
                    },
                });
            } else {
                throw 'Field fileId is empty. Invalid query';
            }
        })
        .then((data: any) => {
            const path = ReactEditor.findPath(editor, link);
            Transforms.setNodes(
                editor,
                { state: { type: 'loaded', file: data.googleDriveDocument } },
                { at: path, match: isGoogleDriveFileLink },
            );
        })
        .catch(() => {
            const path = ReactEditor.findPath(editor, link);
            Transforms.setNodes(editor, { state: { type: 'error' } }, { at: path, match: isGoogleDriveFileLink });
        });
}
