import { track } from '@/analytics';
import { FREE_SPACES_LIMIT, PREVIEW_URL } from '@/constants';
import * as api from '@saga/api';
import classNames from 'classnames';
import { nanoid } from 'nanoid';
import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useUserContext } from '../UserContext';
import { useCurrentWorkspace, useSubscriptions } from '../WorkspaceContext';
import { useSetSettings } from '../settings/SettingsProvider';
import { ButtonNew } from '../styled/Button';
import Toast from '../toast/Toast';
import { useToastContext } from '../toast/ToastContext';
import { SimpleTitleToast } from '../toast/ToastLibrary';
import { ChangePlanIntervalModal } from './ChangePlanIntervalModal';
import { DowngradeFeedbackModal } from './DowngradeFeedbackModal';
import { DowngradeToFreePlanModal } from './DowngradeToFreePlanModal';
import { DesktopModal } from './DesktopModal';
import PlanDetails from './PlanDetails';
import { useTranslation } from 'react-i18next';
import { Check } from 'react-feather';
import Spinner from '../loading/Spinner';
import { useDesktopContext } from '../DesktopContext';
import { handleDesktopLinkOpen } from '@/utils';
import Cookie from 'js-cookie';

export type PlanCardActions = 'duplicate' | 'create' | 'change';

export function PlanCard({
    children,
    subscriptionPlan,
    classNames,
    price,
    interval,
}: {
    children: React.ReactNode;
    subscriptionPlan: api.SubscriptionPlan & { name: string; description: string };
    classNames?: string;
    price: number;
    interval?: api.SubscriptionPriceInterval;
}) {
    const { t } = useTranslation();
    return (
        <div
            className={`relative w-[20rem] min-h-[30rem] shrink-0 border rounded border-saga-gray-200 p-6 dark:border-saga-gray-dark ${
                classNames ? classNames : ''
            }`}
        >
            <p className="pb-5 text-base flex items-center">
                {subscriptionPlan.name}
                {interval === api.SubscriptionPriceInterval.Year && (
                    <span className="rounded text-saga-text bg-white text-sm ml-3 px-1">-25%</span>
                )}
            </p>

            <div className="flex items-end mb-3 leading-9">
                <span className="font-bold text-4xl leading-9 mr-2">{price > 0 ? `$${price}` : t('billing.free')}</span>
                {price > 0 && (
                    <div className="text-sm lowercase">
                        {subscriptionPlan.featureSet === api.FeatureSet.Standard && t('billing.per_space_editor_month')}
                        {subscriptionPlan.featureSet === api.FeatureSet.Business && (
                            <>
                                <div className="text-saga-gray-500 text-sm leading-[14px]">{t('billing.annually')}</div>
                                {t('billing.per_editor_month')}
                            </>
                        )}
                    </div>
                )}
            </div>
            <div className=" text-saga-gray-500 text-sm">{subscriptionPlan.description}</div>

            {children}
        </div>
    );
}

PlanCard.FreePlan = function FreePlan({
    subscriptionPlan,
    disabled,
    setDisabled,
    action,
}: {
    subscriptionPlan: api.SubscriptionPlan;
    disabled: boolean;
    setDisabled: React.Dispatch<React.SetStateAction<boolean>>;
    action?: PlanCardActions;
}) {
    const { t } = useTranslation();
    const { showToast } = useToastContext();
    const history = useHistory();
    const { permissions } = useUserContext();
    const { currentSubscription } = useSubscriptions();
    const [showDowngradeFeedbackModal, setShowDowngradeFeedbackModal] = useState(false);
    const [showDowngradePlanModal, setShowDowngradePlanModal] = useState<{
        visible: boolean;
        subscriptionId: null | string;
    }>({ visible: false, subscriptionId: null });

    const [cancelSubscription, { loading: canceling }] = api.useCancelSubscriptionMutation();

    const isButtonDisabled = action
        ? !permissions.canCreateFreeSpace
        : !currentSubscription || currentSubscription?.canceled || canceling;

    function onButtonClick() {
        setDisabled(true);
        if (!isButtonDisabled && action && permissions.canCreateFreeSpace) {
            track('new-workspace');
            history.push('/new-workspace');
        }
        if (!isButtonDisabled && !action && currentSubscription && !currentSubscription.canceled) {
            setShowDowngradePlanModal({
                visible: true,
                subscriptionId: currentSubscription.subscriptionId,
            });
        }
    }

    async function onDowngradeConfirm() {
        if (showDowngradePlanModal.subscriptionId === null) return;
        await cancelSubscription({
            variables: { input: { subscriptionId: showDowngradePlanModal.subscriptionId } },
            refetchQueries: [api.SubscriptionUpcomingDocument],
            update(cache, { data }) {
                const userData = cache.readQuery<api.UserDataQuery>({ query: api.UserDataDocument });
                if (userData && data) {
                    cache.writeQuery({
                        query: api.UserDataDocument,
                        data: {
                            ...userData,
                            spaces: userData.spaces.map((space) =>
                                space.id === data.cancelSubscription.spaceId
                                    ? { ...space, activeSubscription: data.cancelSubscription }
                                    : space,
                            ),
                        },
                    });

                    setShowDowngradePlanModal({ visible: false, subscriptionId: null });
                    setShowDowngradeFeedbackModal(true);
                    showToast(() => (
                        <Toast>
                            <Toast.Title>{t('billing.downgrade_toast.title')}</Toast.Title>
                            <Toast.Subtitle>{t('billing.downgrade_toast.description')}</Toast.Subtitle>
                        </Toast>
                    ));
                }
            },
        });
    }

    const buttonLabel = useMemo(() => {
        if (action && !currentSubscription) {
            return t('billing.free_title');
        }
        if (currentSubscription?.canceled) {
            return t('billing.planned');
        }
        if (currentSubscription) {
            return t('billing.downgrade');
        } else {
            return t('billing.current_plan');
        }
    }, [action, currentSubscription, t]);

    const buttonIcon = useMemo(() => {
        if (canceling) {
            return <Spinner size={16} />;
        }
        if (buttonLabel === t('billing.current_plan')) {
            return <Check size={16} />;
        }
        return undefined;
    }, [buttonLabel, canceling]);

    return (
        <PlanCard
            subscriptionPlan={{
                ...subscriptionPlan,
                name: t('billing.free_title'),
                description: t('billing.free_description'),
            }}
            price={0}
        >
            <div className="border-b bg-saga-gray-300 dark:border-saga-gray-dark my-[33.5px]" />

            <ButtonNew
                variant="secondary"
                disabled={isButtonDisabled || disabled}
                className="w-full"
                onClick={onButtonClick}
                icon={buttonIcon}
            >
                {buttonLabel}
            </ButtonNew>

            <PlanDetails.FreePlan />
            <p className="absolute bottom-7 left-7 text-xs text-saga-gray-400">
                {t('billing.plan_features.free_limitations', { count: FREE_SPACES_LIMIT })}
            </p>

            {showDowngradePlanModal.visible && (
                <DowngradeToFreePlanModal
                    onClose={() => {
                        setDisabled(false);
                        setShowDowngradePlanModal({ visible: false, subscriptionId: null });
                    }}
                    onConfirm={onDowngradeConfirm}
                />
            )}
            {showDowngradeFeedbackModal && (
                <DowngradeFeedbackModal onClose={() => setShowDowngradeFeedbackModal(false)} />
            )}
        </PlanCard>
    );
};

PlanCard.StandardPlan = function StandardPlan({
    subscriptionPlan,
    disabled,
    setDisabled,
    action,
}: {
    subscriptionPlan: api.SubscriptionPlan;
    disabled: boolean;
    setDisabled: React.Dispatch<React.SetStateAction<boolean>>;
    action?: PlanCardActions;
}) {
    const { isDesktop } = useDesktopContext();
    const { t } = useTranslation();
    const { showToast } = useToastContext();
    const { currentSubscription } = useSubscriptions();
    const { urlKey, members } = useCurrentWorkspace();
    const [showChangePlanIntervalModal, setShowChangePlanIntervalModal] = useState(false);
    const [showDesktopModal, setShowDesktopModal] = useState(false);
    const [createSubscription, { loading: creatingSubscription }] = api.useCreateSubscriptionSessionMutation();
    const [changeSubscription, { loading: changing }] = api.useChangeSubscriptionMutation();
    const setSettings = useSetSettings();
    const [subscriptionType, setSubscriptionType] = useState(
        currentSubscription?.price.interval || api.SubscriptionPriceInterval.Year,
    );

    const price = useMemo(() => {
        const prices = subscriptionPlan.price.filter((price) => price.interval === subscriptionType);

        if (currentSubscription && currentSubscription.price.interval === subscriptionType && !action) {
            return currentSubscription.price;
        } else {
            return prices.length > 1 ? prices.filter((price) => price.status === 'active')[0] : prices[0];
        }
    }, [subscriptionType, currentSubscription, subscriptionPlan, action]);

    const devideBy = price.interval === api.SubscriptionPriceInterval.Month ? 1 : 12;
    const pricePerPeriod = price.amount / 100 / devideBy;

    const onUpgrade = (priceId: string) => {
        if (isDesktop) {
            setShowDesktopModal(true);
            setDisabled(false);
            return;
        }

        if (currentSubscription && !action) {
            changeSubscription({
                variables: {
                    input: {
                        priceId,
                        subscriptionId: currentSubscription.subscriptionId,
                    },
                },
                update(cache, { data }) {
                    const userData = cache.readQuery<api.UserDataQuery>({ query: api.UserDataDocument });
                    if (userData && data) {
                        cache.writeQuery({
                            query: api.UserDataDocument,
                            data: {
                                ...userData,
                                spaces: userData.spaces.map((space) =>
                                    space.id === data.changeSubscription.spaceId
                                        ? { ...space, activeSubscription: data.changeSubscription }
                                        : space,
                                ),
                            },
                        });

                        setDisabled(false);
                        showToast(() => (
                            <SimpleTitleToast>{t('billing.upgrade_to_yearly_toast.title')}</SimpleTitleToast>
                        ));
                        setSettings('billing');
                    }
                },
            });
        } else {
            const input: api.CreateSubscriptionSessionInput = {
                priceId,
                action: action ? action : 'change',
                sourceUrlKey: urlKey,
                spaceUrlKey: action ? nanoid(20) : urlKey,
                workspaceMembersCount: action ? 1 : members.filter((member) => member.role !== 'VIEWER').length,
            };

            const getRewardfulCookie = Cookie.get('rewardful.referral');
            if (getRewardfulCookie) {
                const cookieParsed = JSON.parse(getRewardfulCookie);
                input.referral = cookieParsed.affiliate.token;
            }

            if (PREVIEW_URL) {
                input.clientUrl = PREVIEW_URL;
            }

            createSubscription({
                variables: { input },
                onCompleted(data) {
                    const { session } = data.createSubscriptionSession;
                    session ? window.location.assign(session) : console.error('No Stripe Session');
                },
            });
        }
    };

    const isButtonDisabled = action
        ? false
        : currentSubscription?.canceled ||
          changing ||
          currentSubscription?.price.interval === subscriptionType ||
          (currentSubscription?.price.interval === api.SubscriptionPriceInterval.Year &&
              subscriptionType === api.SubscriptionPriceInterval.Month);

    const buttonLabel = useMemo(() => {
        if (action) return t('billing.standard_title');
        if (!action && !currentSubscription) return t('billing.upgrade_plan');

        if (!action && currentSubscription?.price.stripeId !== price.stripeId) {
            return subscriptionType === api.SubscriptionPriceInterval.Year
                ? t('billing.upgrade_plan')
                : t('billing.downgrade');
        }

        return t('billing.current_plan');
    }, [action, currentSubscription, price, subscriptionType, t]);

    const onButtonClick = () => {
        setDisabled(true);
        if (action || !currentSubscription) {
            onUpgrade(price.stripeId);
            return;
        }

        if (currentSubscription.price.interval === api.SubscriptionPriceInterval.Month) {
            setShowChangePlanIntervalModal(true);
        }
    };

    const buttonIcon = useMemo(() => {
        if (changing || creatingSubscription) {
            return <Spinner size={16} />;
        }
        if (buttonLabel === t('billing.current_plan')) {
            return <Check size={16} />;
        }
        return undefined;
    }, [buttonLabel, changing, creatingSubscription]);

    return (
        <PlanCard
            subscriptionPlan={{
                ...subscriptionPlan,
                name: t('billing.standard_title'),
                description: t('billing.standard_description'),
            }}
            price={pricePerPeriod}
            classNames="border-saga-gray-300 bg-saga-gray-100 dark:bg-saga-gray-900 dark:border-saga-gray-800"
            interval={price.interval}
        >
            <div className="flex w-full bg-white p-1 my-5 rounded-sm text-xs leading-5 text-saga-gray-600 dark:bg-saga-gray-1000 dark:text-saga-gray-500">
                <span
                    onClick={() => setSubscriptionType(api.SubscriptionPriceInterval.Month)}
                    className={classNames('w-6/12 text-center cursor-pointer rounded-sm', {
                        'text-saga-black bg-saga-gray-200 dark:bg-saga-gray-800 dark:text-white':
                            subscriptionType === api.SubscriptionPriceInterval.Month,
                    })}
                >
                    {t('billing.monthly')}
                </span>
                <span
                    onClick={() => setSubscriptionType(api.SubscriptionPriceInterval.Year)}
                    className={classNames('w-6/12 text-center cursor-pointer rounded-sm', {
                        'text-saga-black bg-saga-gray-200 dark:bg-saga-gray-800 dark:text-white':
                            subscriptionType === api.SubscriptionPriceInterval.Year,
                    })}
                >
                    {t('billing.annually')}
                </span>
            </div>
            <ButtonNew
                icon={buttonIcon}
                onClick={onButtonClick}
                disabled={isButtonDisabled || disabled}
                className="w-full"
            >
                {buttonLabel}
            </ButtonNew>
            <PlanDetails.StandardPlan />

            {showChangePlanIntervalModal && currentSubscription && (
                <ChangePlanIntervalModal
                    currentSubscription={currentSubscription}
                    price={price}
                    onClose={() => {
                        setDisabled(false);
                        setShowChangePlanIntervalModal(false);
                    }}
                    onConfirm={() => {
                        onUpgrade(price.stripeId);
                        setShowChangePlanIntervalModal(false);
                    }}
                />
            )}

            {showDesktopModal && (
                <DesktopModal
                    onConfirm={() => {
                        const settingsPage = action ? (action !== 'duplicate' ? 'plans' : 'space') : 'plans';
                        handleDesktopLinkOpen(`${window.location.origin}/s/${urlKey}/pages?settings=${settingsPage}`);
                        setShowDesktopModal(false);
                    }}
                    onClose={() => {
                        setShowDesktopModal(false);
                    }}
                />
            )}
        </PlanCard>
    );
};

PlanCard.BusinessPlan = function BusinessPlan({ subscriptionPlan }: { subscriptionPlan: api.SubscriptionPlan }) {
    const { t } = useTranslation();
    return (
        <PlanCard
            subscriptionPlan={{
                ...subscriptionPlan,
                name: t('billing.business_title'),
                description: t('billing.business_description'),
            }}
            price={subscriptionPlan.price[0].amount / 100}
        >
            <div className="border-b bg-saga-gray-300 dark:border-saga-gray-dark my-[33.5px]" />
            <a
                href="https://saga.so/business-plan"
                rel="noreferrer noopener"
                target="_blank"
                className="font-semibold text-sm text-center block outline-none focus:outline-none leading-[30px] rounded whitespace-nowrap px-5 border bg-white text-saga-text border-saga-black hover:border-saga-black hover:bg-saga-black hover:text-white focus-visible:outline-dotted focus-visible:outline-saga-gray-500 dark:bg-zinc-700 dark:text-white dark:border-white dark:hover:bg-white dark:hover:text-black"
            >
                {t('billing.join_waitlist')}
            </a>
            <PlanDetails.BusinessPlan />
        </PlanCard>
    );
};
