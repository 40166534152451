import { v4 as uuid } from 'uuid';

export default class IdMap {
    protected map: Map<string, string>;
    constructor() {
        this.map = new Map<string, string>();
    }

    public swapId(id: string) {
        const newId = uuid();
        this.map.set(id, newId);
        return newId;
    }

    public tryGetSwappedId(id: string) {
        if (!this.map.has(id)) {
            return id;
        }
        return this.map.get(id) as string;
    }

    public mapSwappedIds(ids: string[]) {
        return ids.map((id) => this.tryGetSwappedId(id));
    }
}
