import React from 'react';
import { PopOver } from '@/components/popover/PopOver';
import { Delete } from 'react-feather';
import { track } from '@/analytics';
import ContextMenuButton from '@/components/styled/ContextMenuButton';
import { OpenCollectionSideBySideButton } from './PageContextMenu';
import { DeleteCollectionButton } from './CollectionContextMenu';
import * as Popover from '@radix-ui/react-popover';

function CollectionInCollectionContextMenuButton({
    onRemove,
    className,
    align,
    isButtonSmall,
    collectionId,
}: {
    onRemove: () => void;
    className?: string;
    align?: 'start' | 'end' | 'center';
    isButtonSmall?: boolean;
    collectionId: string;
}) {
    return (
        <>
            <div className={'flex items-center ' + className}>
                <Popover.Root
                    onOpenChange={(open) => {
                        if (open) track('open-context-menu');
                        else track('close-context-menu');
                    }}
                >
                    <Popover.Trigger asChild>
                        <ContextMenuButton isButtonSmall={Boolean(isButtonSmall)} />
                    </Popover.Trigger>
                    <Popover.Portal>
                        <Popover.Content align={align} onOpenAutoFocus={(e) => e.preventDefault()}>
                            <PopOver.PaddedCard>
                                <div data-testid="context-menu" className="text-sm">
                                    <OpenCollectionSideBySideButton
                                        collectionId={collectionId}
                                        onAfterClick={() => {}}
                                    />

                                    <PopOver.RoundedButton onClick={onRemove}>
                                        <Delete className="stroke-gray-dark mr-2 my-auto" size={14} />
                                        Remove from Collection
                                    </PopOver.RoundedButton>

                                    <div className="py-0.5">
                                        <PopOver.Divider />
                                    </div>

                                    <DeleteCollectionButton collectionId={collectionId} />
                                </div>
                            </PopOver.PaddedCard>
                        </Popover.Content>
                    </Popover.Portal>
                </Popover.Root>
            </div>
        </>
    );
}

export default CollectionInCollectionContextMenuButton;
