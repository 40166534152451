import { TodesktopLink } from '@/components/Desktop';
import React from 'react';
import { StopCircle } from 'react-feather';
import { Trans } from 'react-i18next';

export default function RestrictedAIBanner() {
    return (
        <div className="text-sm min-h-7 flex items-center text-saga-gray-500 justify-between p-2 whitespace-pre-wrap">
            <div className="flex items-center">
                <StopCircle size="13" className="mr-2" />
                <Trans
                    i18nKey={'ai.restricted'}
                    components={{
                        1: (
                            <TodesktopLink
                                path="https://saga.so/guides/saga-ai-faqs"
                                className="underline cursor-pointer"
                            />
                        ),
                    }}
                />
            </div>
        </div>
    );
}
