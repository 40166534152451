import * as t from 'io-ts';

const pageLocationD = t.intersection([
    t.type({ type: t.literal('page'), pageId: t.string, blockId: t.union([t.undefined, t.string]) }),
    t.partial({
        blockOffset: t.union([t.undefined, t.number]),
        public: t.union([t.undefined, t.boolean]),
    }),
]);

export type PageLocation = t.TypeOf<typeof pageLocationD>;

const collectionLocationD = t.type({
    type: t.literal('collection'),
    collectionId: t.string,
});

export type CollectionLocation = t.TypeOf<typeof collectionLocationD>;

const taskLocationD = t.intersection([
    t.type({
        type: t.literal('task'),
        taskId: t.string,
        blockId: t.union([t.undefined, t.string]),
    }),
    t.partial({
        blockOffset: t.union([t.undefined, t.number]),
    }),
]);

export type TaskLocation = t.TypeOf<typeof taskLocationD>;

const embedLocationD = t.type({
    type: t.literal('embed'),
    embedUrl: t.string,
    openUrl: t.union([t.undefined, t.string]),
});

export type EmbedLocation = t.TypeOf<typeof embedLocationD>;

const allCollectionsLocationD = t.type({
    type: t.literal('allCollections'),
});

export type AllCollectionsLocation = t.TypeOf<typeof allCollectionsLocationD>;

const sagaAILocationD = t.type({
    type: t.literal('sagaAI'),
    threadId: t.union([t.undefined, t.string]),
});

export type SagaAILocation = t.TypeOf<typeof sagaAILocationD>;

const allPagesLocationD = t.type({
    type: t.literal('allPages'),
    viewId: t.union([t.undefined, t.string]),
});

export type AllPagesLocation = t.TypeOf<typeof allPagesLocationD>;

const allTasksLocationD = t.type({
    type: t.literal('allTasks'),
    viewId: t.union([t.undefined, t.string]),
});

export type AllTasksLocation = t.TypeOf<typeof allTasksLocationD>;

export const blocksLocationD = t.union([pageLocationD, taskLocationD]);

// These are all locations that contain a blocks array
export type BlocksLocation = t.TypeOf<typeof blocksLocationD>;

export const sagaLocationD = t.union([
    blocksLocationD,
    collectionLocationD,
    embedLocationD,
    allCollectionsLocationD,
    allPagesLocationD,
    allTasksLocationD,
    sagaAILocationD,
]);

export type SagaLocation = t.TypeOf<typeof sagaLocationD>;

export type SagaSettings = t.TypeOf<typeof sagaSettingsD>;
export const sagaSettingsD = t.union([t.literal('space'), t.literal('billing'), t.literal('plans')]);

// Function to decode and validate the settings parameter
export function decodeSettings(type: string) {
    // Attempt to decode the object using the sagaSettingsD decoder
    const result = sagaSettingsD.decode(type);

    // If decoding is successful, return the decoded object or return undefined
    return result._tag === 'Right' ? result.right : undefined;
}

export function pageLocationFromId(pageId: string, opts?: { blockId?: string }): PageLocation {
    return { type: 'page', pageId, blockId: opts?.blockId };
}

export function taskLocationFromId(taskId: string, opts?: { blockId?: string }): TaskLocation {
    return { type: 'task', taskId, blockId: opts?.blockId };
}

export function isPageLocation(location: SagaLocation): location is PageLocation {
    return location.type === 'page';
}

export function isTaskLocation(location: SagaLocation): location is TaskLocation {
    return location.type === 'task';
}

export function isBlocksLocation(location: SagaLocation): location is BlocksLocation {
    return isPageLocation(location) || isTaskLocation(location);
}

export function assertBlocksLocation(location: SagaLocation): asserts location is BlocksLocation {
    if (!isBlocksLocation(location)) {
        throw new Error(`Location ${JSON.stringify(location)} must be a block location`);
    }
}

export function isCollectionLocation(location: SagaLocation): location is CollectionLocation {
    return location.type === 'collection';
}

export function isAllPagesLocation(location: SagaLocation): location is AllPagesLocation {
    return location.type === 'allPages';
}

export function isAllTasksLocation(location: SagaLocation): location is AllTasksLocation {
    return location.type === 'allTasks';
}

export function areLocationsEqual(a: SagaLocation, b: SagaLocation) {
    if (isPageLocation(a) && isPageLocation(b)) {
        return a.pageId === b.pageId;
    }

    if (isCollectionLocation(a) && isCollectionLocation(b)) {
        return a.collectionId === b.collectionId;
    }

    if (isTaskLocation(a) && isTaskLocation(b)) {
        return a.taskId === b.taskId;
    }

    if (isAllPagesLocation(a) && isAllPagesLocation(b)) {
        return a.viewId === b.viewId;
    }

    if (isAllTasksLocation(a) && isAllTasksLocation(b)) {
        return a.viewId === b.viewId;
    }

    return a.type === b.type;
}

export function getIdFromLocation(location: SagaLocation): string {
    switch (location.type) {
        case 'page':
            return location.pageId;
        case 'collection':
            return location.collectionId;
        case 'task':
            return location.taskId;
        case 'allCollections':
            return 'collections';
        case 'allPages':
            return location.viewId ?? 'pages';
        case 'allTasks':
            return location.viewId ?? 'tasks';
        case 'embed':
            return 'embed';
        case 'sagaAI':
            return 'sagaAI';
    }
}
