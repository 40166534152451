import { toMdast } from 'hast-util-to-mdast';
import { parseFragment } from 'parse5';
import { fromParse5 } from 'hast-util-from-parse5';
import { mdToSagaProcessor } from './mdToSaga';
import { Root } from '../types';
import {
    configureChecklistFromNotion,
    getCheckedStateFromNotion,
    isChecklistItemFromNotion,
} from '../utils/notionToSagaUtil';
import * as md from 'mdast-builder';

function extractLeadingCheckbox(node: any) {
    const head = node.children[0];

    if (
        head &&
        head.type === 'element' &&
        head.tagName === 'input' &&
        head.properties &&
        (head.properties.type === 'checkbox' || head.properties.type === 'radio')
    ) {
        const rest = { ...node, children: node.children.slice(1) };
        return { checkbox: head, rest };
    }

    if (head && head.type === 'element' && head.tagName === 'p') {
        const { checkbox, rest: restHead }: any = extractLeadingCheckbox(head);

        if (checkbox) {
            const rest = { ...node, children: [restHead, ...node.children.slice(1)] };
            return { checkbox, rest };
        }
    }

    return { checkbox: undefined, rest: node };
}

export async function htmlToSaga(fragment: string) {
    const parse5 = parseFragment(fragment);

    const hast = fromParse5(parse5);

    const mdast = toMdast(hast, {
        handlers: {
            //@ts-ignore types are not matching up from mdast and unist
            li(_, node) {
                const { checkbox } = extractLeadingCheckbox(node);
                const checked = checkbox ? Boolean(checkbox.properties.checked) : null;
                const mdastNode = toMdast(node);

                //@ts-ignore typescript complains that mdastNode doesnt have children, which it has
                const getMdastNodeValue = () => mdastNode.children[0]?.children[0]?.value || '';

                if (isChecklistItemFromNotion(getMdastNodeValue())) {
                    const checkListFromNotion = configureChecklistFromNotion(mdastNode);

                    const checkedNode = {
                        ...checkListFromNotion,
                        checked:
                            node.children.length === 1
                                ? getCheckedStateFromNotion(node.children[0].value)
                                : getCheckedStateFromNotion(getMdastNodeValue()),
                    };
                    return checkedNode;
                } else {
                    const checkedNode = {
                        ...mdastNode,
                        checked,
                    };

                    return checkedNode;
                }
            },
            //@ts-ignore types are not matching up from mdast and unist
            p(_, node) {
                const mdastNode = toMdast(node);

                //@ts-ignore typescript complains that mdastNode doesnt have children, which it has
                const paragraphs = mdastNode.children;

                const numberListMatchFromMSWord = /^\d+\.\s*$/;
                const isListFromMSWord = paragraphs.some((item: { text: string; value: string }) =>
                    item?.value?.includes('!--[if !supportLists]-->'),
                );

                if (isListFromMSWord) {
                    const isNumberedListFromMSWord = paragraphs.some((item: { text: string; value: string }) => {
                        return numberListMatchFromMSWord.test(item.value);
                    });

                    if (!isNumberedListFromMSWord) {
                        return md.list('unordered', md.listItem(paragraphs.slice(-1)));
                    }

                    if (isNumberedListFromMSWord) {
                        return md.list('ordered', md.listItem(paragraphs.slice(-1)));
                    }
                }

                return mdastNode;
            },
        },
    });

    const processor = mdToSagaProcessor();

    // @ts-expect-error types are not matching up from mdast and unist
    const result = await processor.run(mdast);

    return result as unknown as Root;
}
