import React, { useId, useState } from 'react';
import {
    SagaEditor,
    isInlinePageLink,
    InlinePageLink,
    SpaceOperations,
    Title,
    EditorOperations,
    SagaLocation,
    BlockBuilder,
    SagaElement,
    isTitle,
} from '@saga/shared';
import { useSelected } from 'slate-react';
import { useSpace } from '@/components/SpaceProvider';
import { usePageSnapshot } from '@/hooks/SpaceHooks';
import { useOpenPage } from '@/components/PageNavigationProvider';
import { useSetHighlight } from '@/components/HighlightsProvider';
import * as Y from 'yjs';
import { useUserContext } from '@/components/UserContext';
import { HighlightTargetType } from '@/types';
import { InlinePageLinkElement } from '../InlinePageLink';
import { useEffect } from 'react';

const keys = ['title', 'icon', 'id'] as const;

function useLiveTitle(element: InlinePageLink): Title | null {
    const [title, setTitle] = useState<Title | null>(null);
    const { pageId, liveReferenceSourceId } = element;
    const { space } = useSpace();

    const pageMap = React.useMemo(() => SpaceOperations.getPageMapById(space, pageId), [space, pageId]);
    const liveReferenceSourceMap = React.useMemo(
        () => SpaceOperations.findReferenceRegistryEntry(space, liveReferenceSourceId),
        [space, liveReferenceSourceId],
    );
    useEffect(() => {
        const updateTitle = () => {
            const liveTitleBlock = (liveReferenceSourceMap?.get('liveBlocks') as SagaElement[])?.find((element) =>
                isTitle(element),
            );
            const titleFromPage = pageMap ? BlockBuilder.title(pageMap.get('title') as string) : null;

            setTitle(titleFromPage ?? (liveTitleBlock as Title));
        };
        pageMap?.observe(updateTitle);
        updateTitle();
        return () => pageMap?.unobserve(updateTitle);
    }, [pageMap, liveReferenceSourceMap]);

    return title;
}

export function useInlinePageLinkBlockPlugin() {
    const { space } = useSpace();
    const { userSettings } = useUserContext();
    const showInlinePageLinkIcons = userSettings?.showInlinePageLinkIcons;

    return React.useMemo(() => {
        return SagaEditor.Plugins.createBlockPlugin({
            match: isInlinePageLink,
            Component(props) {
                const { pageId, staticPage } = props.element;
                const highlightId = useId();
                const openPage = useOpenPage();
                const selected = useSelected();
                const page = usePageSnapshot(pageId, keys);
                const title = useLiveTitle(props.element);
                const setHighlight = useSetHighlight();
                const { blockPlugins } = SagaEditor.useEditorContext();
                const titleString = React.useMemo(() => {
                    return title && EditorOperations.SagaElement.toString(title, blockPlugins);
                }, [title, blockPlugins]);

                return (
                    <InlinePageLinkElement
                        {...props}
                        onClick={(event) => {
                            if (page) {
                                openPage(page.id, event);
                            }
                        }}
                        highlightId={highlightId}
                        onMouseOver={(event) => {
                            event.stopPropagation();

                            setHighlight({
                                type: 'inline-page-link',
                                highlightId,
                                target: {
                                    location: SagaLocation.pageLocationFromId(pageId),
                                    type: HighlightTargetType.TITLE,
                                },
                                event,
                            });
                        }}
                        title={titleString ?? staticPage?.title ?? 'Page not found'}
                        page={page}
                        selected={selected}
                        showIcon={showInlinePageLinkIcons}
                    />
                );
            },
            stringify(element, plugins) {
                if (element.pageId) {
                    const childrenMap = SpaceOperations.getPageMapById(space, element.pageId);
                    const blocks = childrenMap?.get('blocks') as Y.Array<any>;
                    const firstBlock = blocks?.get(0)?.toJSON();
                    if (firstBlock) {
                        return EditorOperations.SagaElement.toString(firstBlock, plugins);
                    }
                }

                return '';
            },
        });
    }, [showInlinePageLinkIcons, space]);
}
