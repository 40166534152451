import { useLoadingContext } from '@/components/loading/LoadingContext';
import { useFirebaseContext } from '@/components/FirebaseContext';
import { useWorkspaceContext } from '@/components/WorkspaceContext';
import { track } from '@/analytics';
import { exportWorkspace } from '@/utils/export';
import { DocumentName } from '@saga/shared';

const useExportWorkspace = () => {
    const { showLoadingSpinner, hideLoadingSpinner } = useLoadingContext();
    const { currentUrlKey } = useWorkspaceContext();
    const { firebaseUser } = useFirebaseContext();

    return async () => {
        showLoadingSpinner('Preparing export...');
        try {
            const token = await firebaseUser?.getIdToken();
            if (token == null) {
                throw new Error('not logged in');
            }
            await exportWorkspace(DocumentName.build('space', currentUrlKey), token);
        } catch (e) {
            throw e;
        } finally {
            hideLoadingSpinner();
        }
        track('export-workspace');
    };
};

export default useExportWorkspace;
