import { isSagaText, removeNullable, SagaElement, SagaLocation, SagaEditor, SagaText } from '@saga/shared';
import { Editor, NodeEntry, Path, Range } from 'slate';
import { useCallback } from 'react';
import { useEffect } from 'react';
import { useReducer } from 'react';
import { useAutolinkIndex } from '@/components/AutolinkIndex';
import { Offset } from '@/lib/helpers';
import { AwarenessStateData } from '@/../../shared/src/slateYjs';
import { HighlightTargetType } from '@/types';
import { AutolinkResult } from '@saga/shared';

type BaseDecoration = {
    offset: Offset;
    range: Range;
};

type BaseAutolinkDecoration = BaseDecoration & { location: SagaLocation.PageLocation; type: 'autolink' };

type HeadingDecoration = BaseAutolinkDecoration & {
    referenceTargetType: HighlightTargetType.HEADING;
    heading: {
        id: string;
        title: string;
    };
};

type TitleDecoration = BaseAutolinkDecoration & {
    referenceTargetType: HighlightTargetType.TITLE;
};

export type AutolinkDecoration = HeadingDecoration | TitleDecoration;

type Decoration = AutolinkDecoration;

type AutolinkDecorationRange = Range & { decoration: AutolinkDecoration };
type DecorationRange = Range & { decoration: Decoration };

type RangeWithSelectionAndAnchorId = Range & {
    selected?: boolean;
    anchorId?: string;
    clientId?: number;
} & Partial<DecorationRange>;

export function isAutolinkMention(decoration: unknown): decoration is AutolinkDecoration {
    return (decoration as any)?.type === 'autolink';
}

function isTitlePath(path: Path) {
    return path[0] != null && path[0] === 0;
}

export function mapAutolinkResultsToDecorations(
    autolinkResults: AutolinkResult[],
    path: Path,
): AutolinkDecorationRange[] {
    return autolinkResults
        .map(({ item, matches }) => {
            return matches
                .map((match): AutolinkDecorationRange | null => {
                    const range = {
                        anchor: {
                            path,
                            offset: match.offset.start,
                        },
                        focus: {
                            path,
                            offset: match.offset.end,
                        },
                    };

                    if (item.type === 'page') {
                        return {
                            ...range,
                            decoration: {
                                type: 'autolink',
                                offset: match.offset,
                                location: item.location,
                                referenceTargetType: HighlightTargetType.TITLE,
                                range,
                            },
                        };
                    } else if (item.type === 'heading') {
                        return {
                            ...range,
                            decoration: {
                                type: 'autolink',
                                offset: match.offset,
                                location: item.location,
                                referenceTargetType: HighlightTargetType.HEADING,
                                heading: item.heading,
                                range,
                            },
                        };
                    }

                    return null;
                })
                .filter(removeNullable);
        })
        .flat();
}

interface Cursor extends Range {
    data: AwarenessStateData;
}

export default function useDecorator() {
    const { location } = SagaEditor.useEditorContext();
    const [forceUpdateTrigger, forceUpdate] = useReducer(() => ({}), {});
    const { find, index } = useAutolinkIndex();

    useEffect(() => {
        const unobserve = index.observe(forceUpdate);
        return unobserve;
    }, [index]);

    return useCallback(
        ([block, path]: NodeEntry<SagaElement | SagaText | Editor>) => {
            // this makes eslint stop crying, it does nothing
            forceUpdateTrigger;

            let ranges: RangeWithSelectionAndAnchorId[] = [];
            if (!isTitlePath(path) && isSagaText(block)) {
                const text = block.text;

                const autolinkResults = find(text);
                const decorations = mapAutolinkResultsToDecorations(autolinkResults, path).filter(
                    ({ decoration }) => !SagaLocation.areLocationsEqual(location, decoration.location),
                );
                ranges.push(...decorations);
            }

            return ranges;
        },
        [location, forceUpdateTrigger, find],
    );
}
