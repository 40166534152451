import React from 'react';
import { Editor, Path } from 'slate';
import { RenderElementProps } from 'slate-react';
import invariant from 'tiny-invariant';
import { EditorOperations, SagaElement } from '..';
import { Normalizer } from '../Normalization/Normalizers';
import { NodeCheckFn } from '../types';

type GuardedType<T> = T extends (x: any) => x is infer T ? T : never;

export type BlockPluginProps<Element> = Omit<RenderElementProps, 'children'> & {
    element: Element;
    children: RenderElementProps['children'];
    blockPlugins: BlockPlugin[];
    path: Path;
    selected: boolean;
    editor: Editor;
};

export type BlockPlugin<T extends NodeCheckFn = NodeCheckFn> = {
    Component: React.FC<BlockPluginProps<GuardedType<T>>>;
    match: T;
    stringify?: (element: GuardedType<T>, blockPlugins: BlockPlugin[]) => string;
    normalizers?: Normalizer[];
};

export function createBlockPlugin<T extends NodeCheckFn>(plugin: BlockPlugin<T>): BlockPlugin<T> {
    return plugin;
}

export function combine(...plugins: BlockPlugin[]): BlockPlugin {
    return createBlockPlugin({
        match: (el) => plugins.some((p) => p.match(el)),
        Component(props) {
            const Component = findComponent(props.element, plugins);
            invariant(Component != null, 'Component must match plugin');
            return <Component {...props} />;
        },
        normalizers: plugins.map(({ normalizers }) => normalizers ?? []).flat(),
        stringify(element) {
            return EditorOperations.SagaElement.toString(element, plugins);
        },
    });
}

export function findPlugin(element: SagaElement, blockPlugins: BlockPlugin[]) {
    return blockPlugins.find((plugin) => plugin.match(element));
}

export function findComponent(element: SagaElement, blockPlugins: BlockPlugin[]) {
    const matchedPlugin = findPlugin(element, blockPlugins);
    if (matchedPlugin) {
        return matchedPlugin.Component as React.FC<BlockPluginProps<SagaElement>>;
    }
    return null;
}
