import React from 'react';
import { SafeSpace, SagaLocation, SpaceOperations, WeakTask, taskStateD } from '@saga/shared';
import { useDrag, useDrop } from 'react-dnd';
import { useOpenTask, useOpenLocation } from '../PageNavigationProvider';
import { VariableSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { Archive, Check, ChevronDown, ChevronRight, Maximize2, Plus, X } from 'react-feather';
import { useSpace } from '../SpaceProvider';
import { AvatarAssigneeSelect } from '../tasks/AssigneeSelect';
import PrioritySelect from '../tasks/PrioritySelect';
import { DueDateSelect } from '../tasks/DueDateSelect';
import LabelSelect from '@/components/tasks/LabelSelect';
import { dndTypes } from '@/constants';
import { TaskLabelColors } from '@/components/tasks/LabelSelect';
import { useTaskLabels } from '@/hooks/SpaceHooks';
import { track } from '@/analytics';
import { usePageAccess } from '../PagesPermissionsBySpaceProvider';
import * as api from '@saga/api';
import { useTaskFilters } from '@/hooks/useTaskFilters';
import { useTranslation } from 'react-i18next';
import { InProgress } from '@/assets/icons';
import { useCurrentWorkspace } from '../WorkspaceContext';
import { getCurrentUser } from '@/firebase';
import Modal from '@/components/Modal';
import SpaceTaskEditor from '@/components/tasks/SpaceTaskEditor';
import { useDocumentYBlocks } from '@/components/RealtimeDocumentProvider';
import { useBlockPlugins } from '@/components/BlockPluginProvider';
import { useDeleteTaskIfEmpty } from '@/hooks/deleteTaskHooks';
import { createTask } from '@/utils/documentUtils';

type Column = {
    name: string;
    status: (typeof taskStateD.types)[number]['value'] | 'ARCHIVED';
    icon: () => React.ReactNode;
    collapsible?: boolean;
};

const TASKBOARD_COLLAPSED_COLUMNS_KEY = 'taskboard-collapsed-columns';

function TaskBoardContent({ sortedTasks }: { sortedTasks: WeakTask[] }) {
    const { t } = useTranslation();
    const [collapsedColumns, setCollapsedColumns] = React.useState<{ [key: string]: boolean }>(() => {
        const stored = localStorage.getItem(TASKBOARD_COLLAPSED_COLUMNS_KEY);
        return stored ? JSON.parse(stored) : { ARCHIVED: true };
    });

    const columns: Column[] = React.useMemo(() => {
        return [
            {
                name: t('tasks.status_open'),
                status: 'OPEN',
                icon: () => (
                    <div className="border border-dashed h-[14px] w-[14px] rounded-[7px] border-saga-gray-500" />
                ),
            },
            {
                name: t('tasks.status_in_progress'),
                status: 'IN_PROGRESS',
                icon: () => (
                    <div className="flex items-center justify-center h-[14px] w-[14px] rounded-[7px] border border-saga-new-yellow-medium text-saga-new-yellow-medium">
                        <InProgress />
                    </div>
                ),
            },
            {
                name: t('tasks.status_done'),
                status: 'DONE',
                icon: () => <Check className="h-[14px] w-[14px] text-saga-gray-500" />,
            },
            {
                name: t('tasks.archived'),
                status: 'ARCHIVED',
                icon: () => <Archive className="h-[14px] w-[14px] text-saga-gray-500" />,
                collapsible: true,
            },
        ];
    }, [t]);

    const toggleColumnCollapse = React.useCallback((status: string) => {
        setCollapsedColumns((prev) => {
            const newState = {
                ...prev,
                [status]: !prev[status],
            };
            localStorage.setItem(TASKBOARD_COLLAPSED_COLUMNS_KEY, JSON.stringify(newState));
            return newState;
        });
    }, []);

    return (
        <div className="sm:px-8 flex flex-1 flex-row py-2 space-x-4 overflow-x-auto show-scrolllbar-x overscroll-x-none">
            {columns.map((column, index) => (
                <TaskColumn
                    key={index}
                    column={column}
                    isCollapsed={column.collapsible ? collapsedColumns[column.status] : false}
                    onToggleCollapse={column.collapsible ? () => toggleColumnCollapse(column.status) : undefined}
                    tasks={
                        column.status === 'ARCHIVED'
                            ? sortedTasks.filter((task) => task.archivedAt)
                            : sortedTasks.filter((task) => task.state === column.status && !task.archivedAt)
                    }
                />
            ))}
        </div>
    );
}

function Row({
    index,
    style,
    task,
    onHeightChange,
    space,
}: {
    index: number;
    style: React.CSSProperties;
    task: WeakTask;
    space: SafeSpace;
    onHeightChange: (height: number) => void;
}) {
    const [sendTaskAssignmentNotification] = api.useSendTaskAssignmentNotificationMutation();
    const { urlKey } = useCurrentWorkspace();
    const currentUser = getCurrentUser();

    const ref = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        if (ref.current?.clientHeight) {
            onHeightChange(ref.current.clientHeight);
        }
    }, [ref, onHeightChange]);

    return (
        <div key={index} style={style} className="px-4">
            <div ref={ref}>
                <div className="h-3 w-full" />
                <TaskCard
                    space={space}
                    key={task.id}
                    task={task}
                    onChangeDueDate={(date) => {
                        if (date) {
                            track('change-task-due-date', { source: 'task-table' });
                        } else {
                            track('remove-task-due-date', { source: 'task-table' });
                        }

                        SpaceOperations.updatePartialTask(space, task.id, {
                            dueDate: date?.toISOString() ?? null,
                        });
                    }}
                    onChangeAssignee={async (assignee) => {
                        if (assignee.action === api.TaskAssignAction.Unassign) {
                            track('unassign-assignee-from-task', { source: 'task-table' });
                        } else {
                            track('assign-assignee-to-task', { source: 'task-table' });
                        }
                        SpaceOperations.updatePartialTask(space, task.id, {
                            assignee: assignee.action === api.TaskAssignAction.Assign ? assignee.id : null,
                        });
                        if (assignee.id && assignee.id !== currentUser?.uid) {
                            await sendTaskAssignmentNotification({
                                variables: {
                                    input: {
                                        urlKey,
                                        taskId: task.id,
                                        memberId: assignee.id,
                                        action: assignee.action,
                                    },
                                },
                            });
                        }
                    }}
                    onChangePriority={(priority) => {
                        if (priority == null) {
                            track('remove-task-priority', { source: 'task-table' });
                        } else {
                            track('change-task-priority', { source: 'task-table' });
                        }
                        SpaceOperations.updatePartialTask(space, task.id, {
                            priority: typeof priority === 'string' ? priority : null,
                        });
                    }}
                />
            </div>
        </div>
    );
}

function TaskColumn({
    column,
    tasks,
    isCollapsed,
    onToggleCollapse,
}: {
    column: Column;
    tasks: WeakTask[];
    isCollapsed?: boolean;
    onToggleCollapse?: () => void;
}) {
    const { space, provider } = useSpace();
    const { t } = useTranslation();
    const [createdTask, setCreatedTask] = React.useState<WeakTask | null>(null);

    const rowHeights = React.useRef<{ [key: number]: number }>({});
    const listRef = React.useRef<VariableSizeList>(null);
    const taskFilters = useTaskFilters();

    const tasksRef = React.useRef(tasks);

    React.useEffect(() => {
        tasksRef.current = tasks;
        listRef.current?.resetAfterIndex(0);
    }, [tasks]);

    const getRowHeight = React.useCallback(
        (index: number) => (index === tasks.length ? 44 : rowHeights.current[index] ?? 50),
        [rowHeights, tasks],
    );
    const setRowHeight = React.useCallback(
        (index: number, height: number) => {
            if (height !== rowHeights.current[index]) {
                listRef.current?.resetAfterIndex(index);
            }

            rowHeights.current[index] = height;
        },
        [rowHeights],
    );

    const [, drop] = useDrop<{ id: string }>({
        accept: dndTypes.TASK_CARD,
        drop: (item) => {
            if (column.status === 'ARCHIVED') {
                SpaceOperations.deleteTask(space, item.id);
            } else {
                SpaceOperations.updatePartialTask(space, item.id, {
                    state: column.status,
                    archivedAt: undefined,
                });
            }
        },
    });

    const handleCreateTask = React.useCallback(() => {
        if (column.status === 'ARCHIVED') return;

        const task = createTask(space, { title: '', state: column.status, ...taskFilters }, provider);

        setCreatedTask(task);
    }, [column.status, taskFilters, space, provider]);

    const renderRow = React.useCallback(
        ({ index, style }: { index: number; style: React.CSSProperties }) => {
            const task = tasksRef.current?.[index];

            if (index === tasksRef.current?.length) {
                return (
                    <div style={style} className="px-4">
                        <div className="h-3 w-full" />
                        <div
                            onClick={handleCreateTask}
                            className="group-hover:opacity-100 opacity-0 transition-opacity duration-100 h-8 flex-shrink-0 w-full flex items-center justify-center flex-row border dark:border-saga-gray-800 border-saga-gray-150 rounded-md space-x-2 hover:bg-saga-gray-200 dark:hover:bg-saga-gray-700 cursor-pointer"
                        >
                            <Plus size={14} />
                        </div>
                    </div>
                );
            }

            if (!task) return null;

            return (
                <Row
                    key={tasksRef.current?.[index]?.id}
                    index={index}
                    style={style}
                    task={tasksRef.current?.[index]}
                    space={space}
                    onHeightChange={(height) => setRowHeight(index, height)}
                />
            );
        },
        [handleCreateTask, setRowHeight, tasksRef, t, space],
    );

    return (
        <div
            ref={drop}
            key={column.name}
            className="group flex flex-1 flex-col bg-saga-gray-50 dark:bg-saga-gray-900 rounded-md pb-1 max-w-[358px] min-w-[348px]"
        >
            <div className="text-sm flex flex-row items-center pt-3.5 pb-2 top-0 z-10 sticky bg-saga-gray-50 dark:bg-saga-gray-900 px-4">
                <div className="flex flex-row flex-1 items-center space-x-2">
                    {column.icon()}
                    <div>{column.name}</div>
                    <div className="text-saga-gray-500 dark:text-saga-gray-400">{tasks.length}</div>
                </div>
                <div className="flex flex-row items-center space-x-2">
                    {onToggleCollapse && (
                        <button
                            onClick={onToggleCollapse}
                            className="p-0.5 hover:bg-saga-gray-200 dark:hover:bg-saga-gray-700 rounded"
                        >
                            {isCollapsed ? <ChevronRight size={14} /> : <ChevronDown size={14} />}
                        </button>
                    )}
                    {column.status !== 'ARCHIVED' && (
                        <span
                            className="cursor-pointer font-bold hover:bg-saga-gray-200 dark:hover:bg-saga-gray-700 p-1 rounded"
                            onClick={handleCreateTask}
                        >
                            <Plus size={14} />
                        </span>
                    )}
                </div>
            </div>
            {!isCollapsed && (
                <div style={{ flex: '1 1 auto' }}>
                    <AutoSizer>
                        {({ height, width }) => (
                            <VariableSizeList
                                ref={listRef}
                                itemCount={column.status === 'ARCHIVED' ? tasks.length : tasks.length + 1}
                                itemSize={getRowHeight}
                                height={height}
                                width={width}
                                overscanCount={10}
                            >
                                {renderRow}
                            </VariableSizeList>
                        )}
                    </AutoSizer>
                </div>
            )}

            {createdTask && <EditTaskModal task={createdTask} onClose={() => setCreatedTask(null)} />}
        </div>
    );
}

function TaskCard({
    task,
    space,
    onChangeAssignee,
    onChangePriority,
    onChangeDueDate,
}: {
    task: WeakTask;
    space: SafeSpace;
    onChangeAssignee({ id, action }: { id: string | null; action: api.TaskAssignAction }): void;
    onChangeDueDate(date: Date | null): void;
    onChangePriority(priority: WeakTask['priority']): void;
}) {
    const [collected, drag] = useDrag(() => ({
        type: dndTypes.TASK_CARD,
        item: { id: task.id },
        collect(monitor) {
            return {
                isDragging: monitor.isDragging(),
            };
        },
    }));

    const openTask = useOpenTask();
    const labels = useTaskLabels();
    const { canEdit } = usePageAccess(task.id);

    const handleOnClick = React.useCallback(
        (e: React.MouseEvent<HTMLDivElement>) => {
            if (e.currentTarget === e.target) {
                openTask(task.id, e);
            }
        },
        [openTask, task.id],
    );

    return collected.isDragging ? null : (
        <div
            ref={drag}
            className="relative flex p-3 flex-col rounded-md bg-white dark:bg-saga-gray-1000 shadow-sm space-y-3 cursor-pointer dark:border dark:border-saga-gray-800"
            onClick={handleOnClick}
        >
            <div className="flex flex-row space-x-2 items-start" onClick={handleOnClick}>
                <div className="flex-1 text-sm font-medium" onClick={handleOnClick}>
                    {task.title}
                </div>

                <AvatarAssigneeSelect
                    assignee={task.assignee}
                    onChange={(assignee) => {
                        if (assignee.action === api.TaskAssignAction.Unassign) {
                            track('unassign-assignee-from-board');
                        } else {
                            track('assign-assignee-to-board');
                        }
                        onChangeAssignee?.(assignee);
                    }}
                    disabled={!canEdit}
                />
            </div>
            <div className="flex flex-row items-start space-x-2" onClick={handleOnClick}>
                <PrioritySelect
                    onChange={(priority) => onChangePriority?.(priority)}
                    assignedPriority={task.priority}
                    variant="minimal"
                    showEmptyState
                    disabled={!canEdit}
                />
                <DueDateSelect task={task} onChange={onChangeDueDate} disabled={!canEdit} variant="minimal" />
                <LabelSelect
                    availableLabels={labels ?? []}
                    selectedIds={task.labels ?? []}
                    onSelectLabel={(id) => {
                        track('select-task-label', { source: 'task-board' });
                        SpaceOperations.updatePartialTask(space, task.id, {
                            labels: [...new Set([...(task.labels ?? []), id])],
                        });
                    }}
                    onRemoveLabel={(id) => {
                        track('remove-task-label', { source: 'task-board' });
                        SpaceOperations.updatePartialTask(space, task.id, {
                            labels: (task.labels ?? []).filter((label) => label !== id),
                        });
                    }}
                    onCreateLabel={(title) => {
                        track('create-task-label', { source: 'task-board' });
                        const id = SpaceOperations.addTaskLabel(space, title, TaskLabelColors[0]);
                        SpaceOperations.updatePartialTask(space, task.id, {
                            labels: [...new Set([...(task.labels ?? []), id])],
                        });
                    }}
                    onSelectColor={(id, color) => {
                        track('change-task-label-color', { source: 'task-board' });
                        SpaceOperations.updatePartialTaskLabel(space, id, { color });
                    }}
                    variant="minimal"
                    showEmptyState
                    disabled={!canEdit}
                />
            </div>
        </div>
    );
}

function EditTaskModal({ task, onClose }: { task: WeakTask; onClose: () => void }) {
    const { data: yBlocks } = useDocumentYBlocks(task.id);
    const blockPlugins = useBlockPlugins();
    const deleteTaskIfEmpty = useDeleteTaskIfEmpty();

    const blocksRef = React.useRef(yBlocks);
    const shouldDeleteIfEmpty = React.useRef(true);

    const openLocation = useOpenLocation();

    React.useEffect(() => {
        blocksRef.current = yBlocks;
    }, [yBlocks]);

    React.useEffect(() => {
        return () => {
            const blocks = blocksRef.current?.toJSON();
            if (blocks && shouldDeleteIfEmpty.current) {
                setTimeout(() => deleteTaskIfEmpty({ id: task.id, blocks }), 0);
            }
        };
    }, [deleteTaskIfEmpty, blocksRef, task.id]);

    return (
        <Modal.Medium isOpen onClose={onClose} height="80vh" zIndex={10}>
            <div className="overflow-y-auto w-full h-full dark:bg-saga-gray-1000 bg-white">
                {yBlocks && (
                    <SpaceTaskEditor
                        location={SagaLocation.taskLocationFromId(task.id)}
                        yBlocks={yBlocks}
                        blockPlugins={blockPlugins}
                        disableInteraction={!yBlocks}
                    />
                )}
            </div>
            <div className="absolute top-1 right-1 flex w-full items-end justify-end flex-row space-x-2">
                <button
                    className="cursor-pointer p-2 rounded hover:bg-saga-gray-200 dark:hover:bg-zinc-600 focus:outline-none active:bg-saga-gray-200 dark:active:bg-zinc-600 active:shadow-xs"
                    onClick={(event) => {
                        shouldDeleteIfEmpty.current = false;
                        onClose();
                        openLocation({ type: 'task', taskId: task.id, blockId: undefined }, event);
                    }}
                >
                    <Maximize2 className="text-saga-gray-dark dark:text-zinc-200 flex-none" size={20} />
                </button>
                <button
                    onClick={onClose}
                    className="cursor-pointer p-2 rounded hover:bg-saga-gray-200 dark:hover:bg-zinc-600 focus:outline-none active:bg-saga-gray-200 dark:active:bg-zinc-600 active:shadow-xs"
                >
                    <X size={20} className="text-saga-gray-dark dark:text-zinc-200 flex-none" />
                </button>
            </div>
        </Modal.Medium>
    );
}

export default TaskBoardContent;
