import React from 'react';
import * as Sentry from '@sentry/browser';
import WhooopsModal from './WhooopsModal';

export default class NavigationErrorBoundary extends React.Component<
    { children: React.ReactNode },
    { hasError: boolean }
> {
    constructor(props: any) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    componentDidCatch(error: any, errorInfo: any) {
        Sentry.captureException(error, { extra: { errorInfo } });
    }

    render() {
        if (this.state.hasError) {
            return (
                <WhooopsModal
                    onButtonClick={() => window.location.reload()}
                    buttonLabel="Reload Page"
                    subheading="There was a problem with this page, please reload the page and try again."
                />
            );
        }

        return this.props.children;
    }
}
