import React from 'react';

const DesktopContext = React.createContext<{
    isDesktop: boolean;
    isFullScreen: boolean;
    useMacOsLayout: boolean;
}>({
    isDesktop: false,
    isFullScreen: false,
    useMacOsLayout: false,
});

const DesktopContextProvider = ({ children }: { children: React.ReactNode }) => {
    const [isFullScreen, setIsFullScreen] = React.useState(false);

    React.useEffect(() => {
        window.todesktop && window.todesktop.app.updateInternalUrls('');
        window.todesktop && window.todesktop.window.isFullscreen().then(setIsFullScreen);
    }, []);

    React.useEffect(() => {
        const onResize = () => {
            window.todesktop &&
                window.todesktop.window.isFullscreen().then((value) => {
                    if (value !== isFullScreen) {
                        setIsFullScreen(value);
                    }
                });
        };

        if (window.todesktop) {
            window.addEventListener('resize', onResize);
        }

        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, [isFullScreen]);

    const object = React.useMemo(() => {
        return {
            isFullScreen,
            isDesktop: Boolean(window.todesktop),
            useMacOsLayout: Boolean(
                isFullScreen === false &&
                    Boolean(window.todesktop) &&
                    window.todesktop &&
                    window.todesktop.os.platform === 'darwin',
            ),
        };
    }, [isFullScreen]);

    return (
        <DesktopContext.Provider value={object}>
            {object.isDesktop && object.isFullScreen === false && (
                <div id="drag-top-bar" className="absolute bg-transparent h-12 w-screen" />
            )}
            {children}
        </DesktopContext.Provider>
    );
};

export const useDesktopContext = () => React.useContext(DesktopContext);

export default DesktopContextProvider;
