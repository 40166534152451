import React from 'react';
import AuthenticationErrorModal from '@/components/AuthenticationErrorModal';
import { RealtimeState } from '@/components/RealtimeProvider';
import classNames from 'classnames';
import useMobile from '@/hooks/useMobile';

type DocumentType = 'page' | 'task';

export default function EmptyBlocksPlaceholder({ state, type }: { state: RealtimeState['type']; type: DocumentType }) {
    const isMobile = useMobile();

    switch (state) {
        case 'MEMBER_REMOVED':
        case 'AUTHENTICATION_FAILED':
            return <AuthenticationErrorModal option="page-unavailable" />;
        default:
            return (
                <div
                    className={classNames(
                        'hide-scrollbar overflow-y-auto relative mx-auto flex flex-col w-full sm:pl-8 sm:pr-6 transition items-center animate-fadeIn',
                        {
                            'ml-1 mr-1': isMobile,
                        },
                    )}
                >
                    {type === 'page' ? <PageSkeleton /> : <TaskSkeleton />}
                </div>
            );
    }
}

function PageSkeleton() {
    return (
        <div className={classNames('w-full relative space-y-2 animate-pulse max-w-700')}>
            <TitleLine />
            <SingleLine className="w-full" />
            <SingleLine className="w-11/12" />
            <SingleLine className="w-full" />
            <SingleLine className="w-1/2" />
            <Paragraph>
                <SingleLine className="w-1/2 " />
                <SingleLine className="w-3/4" />
                <SingleLine className="w-full" />
            </Paragraph>
            <DoubleLine className="w-full" />
            <Paragraph>
                <ListItem className="w-3/4" />
                <ListItem className="w-full" />
                <ListItem className="w-1/2" />
            </Paragraph>
            <SingleLine className="w-full" />
            <SingleLine className="w-11/12" />
            <SingleLine className="w-full" />
            <SingleLine className="w-1/2" />
        </div>
    );
}

function TaskSkeleton() {
    return (
        <div className={classNames('w-full relative space-y-3 animate-pulse max-w-700')}>
            <TitleLine />
            <div className="space-y-5 mb-5 pb-5">
                <ListItem className="w-1/2" size="m" />
                <ListItem className="w-1/2" size="m" />
                <ListItem className="w-1/2" size="m" />
                <ListItem className="w-1/2" size="m" />
                <ListItem className="w-1/2" size="m" />
            </div>
            <SingleLine className="w-full" />
            <SingleLine className="w-11/12" />
            <SingleLine className="w-full" />
            <SingleLine className="w-1/2" />
            <Paragraph>
                <SingleLine className="w-1/2 " />
                <SingleLine className="w-3/4" />
                <SingleLine className="w-full" />
            </Paragraph>
            <DoubleLine className="w-full" />
        </div>
    );
}

function SingleLine({ className }: { className?: string }) {
    return <div className={classNames(className, 'w-full h-5 dark:bg-saga-gray-900 bg-saga-gray-100 rounded')} />;
}

function DoubleLine({ className }: { className?: string }) {
    return <div className={classNames(className, 'w-full h-14 dark:bg-saga-gray-900 bg-saga-gray-100 rounded')} />;
}

function ListItem({ className, size = 's' }: { className?: string; size?: 's' | 'm' }) {
    return (
        <div className="flex-row flex">
            <div
                className={classNames('h-5 dark:bg-saga-gray-900 bg-saga-gray-100 rounded mr-3', {
                    'w-5': size === 's',
                    'w-14': size === 'm',
                })}
            ></div>
            <div className="flex flex-1">
                <SingleLine className={className} />
            </div>
        </div>
    );
}

function Paragraph({ children }: { children: React.ReactNode }) {
    return <div className="py-5 space-y-2">{children}</div>;
}

function TitleLine() {
    return (
        <div className={classNames('title group')}>
            <div className={classNames('select-none inline-flex p-1.5 -ml-2')}></div>
            <div className="flex-row flex pt-4 pb-1">
                <div className="w-8 h-12 dark:bg-saga-gray-900 bg-saga-gray-100 rounded"></div>
                <div className="inline h-12 w-1/2 ml-3 dark:bg-saga-gray-900 bg-saga-gray-100 rounded"></div>
            </div>
        </div>
    );
}
