import { track } from '@/analytics';
import { useNullableSideBySideApi, useSideBySideIndex } from '@/components/SideBySide';
import { useSpace } from '@/components/SpaceProvider';
import { TemplateIcon } from '@/components/icons';
import { PopOver } from '@/components/popover/PopOver';
import Tooltip from '@/components/popover/Tooltip';
import ContextMenuButton from '@/components/styled/ContextMenuButton';
import { useArchivePage, useDeletePagePermanently } from '@/hooks/deletePageHooks';
import { useFavoriteButton } from '@/hooks/useFavoriteButton';
import { duplicatePage } from '@/utils/documentUtils';
import { RealtimeSagaEditor, SagaLocation, SpaceOperations, WeakPage } from '@saga/shared';
import classNames from 'classnames';
import React, { useRef, useState } from 'react';
import { Columns, Copy, CornerUpLeft, Rewind, Trash } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useOpenPage } from '../PageNavigationProvider';
import { useCurrentWorkspace } from '../WorkspaceContext';
import { useWorkspaces } from '../WorkspacesContext';
import Dropdown from './Dropdown';
import {
    AddToFavoritePageButton,
    RemoveFromFavoriteButton,
    RemoveFromPinnedButton,
    AddToPinnedPageButton,
} from './common';
import { usePerformActionWithYBlocks } from '@/components/RealtimeDocumentProvider';
import { usePageAccess } from '@/components/PagesPermissionsBySpaceProvider';
import { DocumentHistoryButton } from '@/components/DocumentHistoryButton';
import MoveEntityButton from './MoveEntityButton';
import ExportButton from './ExportButton';
import useExport from '@/hooks/useExport';
import { usePinnedButton } from '@/hooks/usePinnedButton';

export function OpenSideBySideButton({
    location,
    onAfterClick,
}: Readonly<{
    location: SagaLocation.SagaLocation;
    onAfterClick?(): void;
}>) {
    const api = useNullableSideBySideApi();
    const currentPaneIndex = useSideBySideIndex();
    const { t } = useTranslation();

    return (
        <Tooltip content={t('side_by_side.tooltip')} placement="right">
            <PopOver.RoundedButton
                onClick={() => {
                    api?.openLocationOnIndex(location, currentPaneIndex === 0 ? 1 : 0);
                    onAfterClick && onAfterClick();
                }}
                aria-label={t('side_by_side.open') as string}
            >
                <span className="flex">
                    <Columns className="stroke-gray-dark mr-2 my-auto" size={14} />
                    <span>{t('side_by_side.open')}</span>
                </span>
            </PopOver.RoundedButton>
        </Tooltip>
    );
}

export function OpenLocationSideBySideButton({
    location,
    onAfterClick,
}: {
    location: SagaLocation.SagaLocation;
    onAfterClick: () => void;
}) {
    const { t } = useTranslation();
    const currentPaneIndex = useSideBySideIndex();
    const api = useNullableSideBySideApi();
    return (
        <Tooltip content={t('side_by_side.tooltip')} placement="right">
            <PopOver.RoundedButton
                onClick={() => {
                    api?.openLocationOnIndex(location, currentPaneIndex === 0 ? 1 : 0);
                    onAfterClick();
                }}
                aria-label={t('side_by_side.open') as string}
            >
                <Columns className="stroke-gray-dark mr-2 my-auto" size={14} />
                {t('side_by_side.open')}
            </PopOver.RoundedButton>
        </Tooltip>
    );
}

export function OpenCollectionSideBySideButton({
    collectionId,
    onAfterClick,
}: {
    collectionId: string;
    onAfterClick(): void;
}) {
    const api = useNullableSideBySideApi();
    const currentPaneIndex = useSideBySideIndex();
    const { t } = useTranslation();

    return (
        <Tooltip content={t('side_by_side.tooltip')} placement="right">
            <PopOver.RoundedButton
                onClick={() => {
                    api?.openLocationOnIndex({ type: 'collection', collectionId }, currentPaneIndex === 0 ? 1 : 0);
                    onAfterClick();
                }}
                aria-label={t('side_by_side.open') as string}
            >
                <Columns className="stroke-gray-dark mr-2 my-auto" size={14} />
                {t('side_by_side.open')}
            </PopOver.RoundedButton>
        </Tooltip>
    );
}

export function DuplicatePageButton({ pageId }: { pageId: string }) {
    const openPage = useOpenPage();
    const { space, provider } = useSpace();
    const { t } = useTranslation();
    const performActionWithYBlocks = usePerformActionWithYBlocks();

    function onDuplicatePage(event: React.MouseEvent) {
        track('duplicate-page');
        performActionWithYBlocks(SagaLocation.pageLocationFromId(pageId), (blocks) => {
            const duplicatedPage = duplicatePage(space, provider, pageId, blocks.toJSON());
            openPage(duplicatedPage.id, event);
        });
    }

    return (
        <PopOver.RoundedButton onClick={onDuplicatePage} aria-label={t('common.duplicate_page')}>
            <Copy className="stroke-gray-dark mr-2 my-auto" size={14} />
            {t('common.duplicate')}
        </PopOver.RoundedButton>
    );
}

export function CreateTemplateFromPageButton({ pageId }: { pageId: string }) {
    const { space, provider } = useSpace();
    const openPage = useOpenPage();
    const { t } = useTranslation();
    const performActionWithYBlocks = usePerformActionWithYBlocks();

    function onCreateTemplateFromPage(event: React.MouseEvent) {
        performActionWithYBlocks(SagaLocation.pageLocationFromId(pageId), (blocks) => {
            const templatePage = duplicatePage(space, provider, pageId, blocks.toJSON(), '', { isTemplate: true });
            track('create-template-from-page');
            openPage(templatePage.id, event);
        });
    }

    return (
        <PopOver.RoundedButton onClick={onCreateTemplateFromPage} aria-label={t('common.create_template') as string}>
            <TemplateIcon className="stroke-gray-dark mr-2 my-auto" />
            {t('common.create_template')}
        </PopOver.RoundedButton>
    );
}

export function ExportPageButton(props: { onAfterClick: () => void; page: Pick<WeakPage, 'id' | 'title'> }) {
    const exportPage = useExport();
    return (
        <ExportButton
            {...props}
            entity={props.page}
            exportFunction={exportPage}
            label="Export Page"
            tooltipContent="Export as Markdown & JSON"
        />
    );
}

export function DeletePageButton({ onAfterClick, pageId }: { onAfterClick(): void; pageId: string }) {
    const { t } = useTranslation();
    const archivePage = useArchivePage();
    const onDelete = () => {
        archivePage(pageId);
    };

    return (
        <PopOver.RoundedButton
            onClick={() => {
                onDelete();
                onAfterClick();
            }}
            aria-label={t('common.delete_page') as string}
        >
            <Trash className="stroke-gray-dark mr-2 my-auto" size={14} />
            {t('common.delete')}
        </PopOver.RoundedButton>
    );
}

export function RestorePageButton({ onAfterClick, pageId }: { onAfterClick(): void; pageId: string }) {
    const { space } = useSpace();
    const { t } = useTranslation();
    const onRestore = () => {
        SpaceOperations.restorePage(space, pageId);
    };

    return (
        <PopOver.RoundedButton
            onClick={() => {
                onRestore();
                onAfterClick();
            }}
            aria-label="Restore Page"
        >
            <Rewind className="stroke-gray-dark mr-2 my-auto" size={14} />
            {t('pages.restore')}
        </PopOver.RoundedButton>
    );
}

export function DeletePageForeverButton({ onAfterClick, pageId }: { onAfterClick(): void; pageId: string }) {
    const history = useHistory();
    const { urlKey } = useCurrentWorkspace();
    const deletePagePermanently = useDeletePagePermanently();
    const { t } = useTranslation();

    const onDeletePermanently = () => {
        deletePagePermanently(pageId);

        history.replace(`/s/${urlKey}`);
    };

    return (
        <PopOver.RoundedButton
            onClick={() => {
                onDeletePermanently();
                onAfterClick();
            }}
            aria-label="Delete forever"
        >
            <Trash className="stroke-gray-dark mr-2 my-auto" size={14} />
            {t('pages.delete_forever')}
        </PopOver.RoundedButton>
    );
}

export function UndoChangesButton({
    onAfterClick,
    editor,
}: {
    onAfterClick(): void;
    editor: RealtimeSagaEditor | null;
}) {
    const { t } = useTranslation();

    const isMac = navigator.userAgent.match(/Mac/i);
    const isWindows = navigator.userAgent.match(/Win/i);

    return (
        <Tooltip content="Undo latest changes" placement="right">
            <PopOver.RoundedButton
                aria-label="Undo Page"
                disabled={!editor?.canUndo()}
                onClick={() => {
                    editor?.undo();
                    onAfterClick();
                }}
            >
                <CornerUpLeft
                    className={classNames(' mr-2 my-auto min-h-[14px] min-w-[14px]', {
                        'stroke-gray-dark': !editor?.canUndo(),
                    })}
                    size={14}
                />
                <div
                    className={classNames('flex justify-between items-center w-full ', {
                        'text-saga-gray-500': !editor?.canUndo(),
                    })}
                >
                    {t('common.undo')}
                    {isMac ? <div className="text-saga-gray-500">{t('common.is_mac')}</div> : null}
                    {isWindows ? <div className="text-saga-gray-500">{t('common.winows')}</div> : null}
                </div>
            </PopOver.RoundedButton>
        </Tooltip>
    );
}

export function PageContextMenuPopOver({
    isOpen,
    onClose,
    attachToRef,
    page,
    align,
    children,
    editor,
}: {
    isOpen: boolean;
    onClose(): void;
    attachToRef: React.MutableRefObject<HTMLElement | null>;
    page: Pick<WeakPage, 'id' | 'title' | 'archivedAt' | 'isTemplate'>;
    align?: 'left' | 'right' | 'center';
    children?: React.ReactNode;
    editor: RealtimeSagaEditor | null;
}) {
    const pageId = page.id;
    const isArchived = page.archivedAt != null;
    const { isFavorite, toggleFavorite } = useFavoriteButton(pageId);
    const { isPinned, togglePinned } = usePinnedButton(pageId);
    const { isPageAdmin, canEdit } = usePageAccess(pageId);
    const { workspaces } = useWorkspaces();

    return (
        <Dropdown
            testId={isOpen ? 'context-menu' : undefined}
            isOpen={isOpen}
            onClose={onClose}
            attachToRef={attachToRef}
            align={align}
        >
            <div>
                {children && children}
                <span className="hidden sm:flex">
                    <OpenSideBySideButton onAfterClick={onClose} location={SagaLocation.pageLocationFromId(pageId)} />
                </span>
                {!isFavorite ? (
                    <AddToFavoritePageButton onAfterClick={onClose} onClick={toggleFavorite} />
                ) : (
                    <RemoveFromFavoriteButton onAfterClick={onClose} onClick={toggleFavorite} />
                )}
                {!isPinned ? (
                    <AddToPinnedPageButton onAfterClick={onClose} onClick={togglePinned} />
                ) : (
                    <RemoveFromPinnedButton onAfterClick={onClose} onClick={togglePinned} />
                )}
                {!isArchived && <DuplicatePageButton pageId={pageId} />}
                {!isArchived && (
                    <MoveEntityButton
                        disabled={workspaces.length === 1}
                        entityId={pageId}
                        entityType="page"
                        onAfterClick={onClose}
                    />
                )}
                {!page.isTemplate && <CreateTemplateFromPageButton pageId={pageId} />}
                {canEdit && <UndoChangesButton onAfterClick={onClose} editor={editor} />}
                <ExportPageButton page={page} onAfterClick={onClose} />
                <DocumentHistoryButton pageId={pageId} onAfterClose={onClose} />
                {isPageAdmin && (
                    <div className="pt-0.5 space-y-0.5">
                        <PopOver.Divider />
                        {!isArchived && <DeletePageButton onAfterClick={onClose} pageId={pageId} />}
                        {isArchived && <RestorePageButton onAfterClick={onClose} pageId={pageId} />}
                        {isArchived && <DeletePageForeverButton onAfterClick={onClose} pageId={pageId} />}
                    </div>
                )}
            </div>
        </Dropdown>
    );
}

export function PageContextMenuButton({
    align,
    isButtonSmall,
    page,
    children,
    editor,
}: {
    align?: 'left' | 'right' | 'center';
    isButtonSmall?: boolean;
    page: Pick<WeakPage, 'id' | 'title' | 'archivedAt' | 'isTemplate'>;
    children?: (setIsOpen: (open: boolean) => void) => React.ReactNode;
    editor: RealtimeSagaEditor | null;
}) {
    const buttonRef = useRef<HTMLButtonElement>(null);
    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <ContextMenuButton
                ref={buttonRef}
                isOpen={isOpen}
                onClick={() => {
                    setIsOpen((isOpen) => !isOpen);
                    if (isOpen) track('open-context-menu');
                    else track('close-context-menu');
                }}
                isButtonSmall={Boolean(isButtonSmall)}
            />

            <PageContextMenuPopOver
                isOpen={isOpen}
                attachToRef={buttonRef}
                align={align}
                onClose={() => setIsOpen(false)}
                page={page}
                editor={editor}
            >
                {children && children(setIsOpen)}
            </PageContextMenuPopOver>
        </>
    );
}
