import { createDocumentForSpace } from './createDocumentForSpace';
import { v4 as uuid } from 'uuid';
import { Page } from './types';
import * as R from 'ramda';
import { createDocumentForContent } from './createDocumentForContent';
import { createMapForSearchIndex } from './createMapForSearchIndex';

type Options = {
    id?: string;
    createdAt?: Date;
    pages?: Page[];
};

export function createEmptySpaceDocument({ id = uuid(), createdAt = new Date(), pages = [] }: Options) {
    const spacePages = pages.map((page) => R.omit(['blocks'], page));
    const pageDocs = pages.map((page) =>
        createDocumentForContent({ id: page.id, spaceId: id, blocks: page.blocks, icon: page.icon }),
    );
    const searchIndexMap = createMapForSearchIndex(pages);

    return {
        space: createDocumentForSpace({
            version: '2',
            id,
            createdAt: createdAt.valueOf(),
            collections: [],
            pages: spacePages,
            pinned: [],
            properties: [],
            referencesRegistry: [],
            tasks: [],
            taskLabels: [],
            userFavorites: {},
            autolinkEnabled: true,
            pageViews: [],
            taskViews: [],
        }),
        pages: pageDocs,
        searchIndexMap,
    };
}
