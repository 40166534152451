import { BlockBuilder } from '../../';
import { newBlankPage } from '../../pageUtils';

export function newSagaAdvancedPageIt(collectionIds: string[]) {
    return newBlankPage({
        title: 'Saga avanzato',
        icon: {
            type: 'emoji',
            colons: ':heart_eyes:',
        },
        collections: collectionIds,
        blocks: [
            BlockBuilder.title('Saga avanzato'),
            BlockBuilder.callout([
                BlockBuilder.paragraph([
                    BlockBuilder.text('Esplora funzionalità più potenti e inizia a lavorare meglio con Saga.'),
                ]),
            ]),
            BlockBuilder.headingThree('Rimani organizzato senza pensarci'),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Crea '),
                BlockBuilder.link('https://saga.so/guides/collections-of-pages', 'collezioni'),
                BlockBuilder.text(' e raggruppa pagine simili.'),
            ]),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Fissa le pagine e le collezioni più importanti nella '),
                BlockBuilder.link('https://saga.so/guides/sidebar', 'barra laterale'),
                BlockBuilder.text('.'),
            ]),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Passa rapidamente tra pagine, compiti e collezioni con la '),
                BlockBuilder.link('https://saga.so/guides/search-in-saga', 'ricerca unificata'),
                BlockBuilder.text('.'),
            ]),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Clicca su '),
                BlockBuilder.link('https://saga.so/guides/task-management', 'Compiti'),
                BlockBuilder.text(' nella barra laterale per gestire tutti i compiti da tutte le tue pagine in un unico posto.'),
            ]),
            BlockBuilder.paragraph(),
            BlockBuilder.headingThree('Collega le tue conoscenze come un pro'),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Usa il menu di autocompletamento per '),
                BlockBuilder.link('https://saga.so/guides/page-links', 'menzionare pagine'),
                BlockBuilder.text(' e creare collegamenti all’interno del tuo spazio di lavoro.'),
            ]),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Apri le '),
                BlockBuilder.link('https://saga.so/guides/references', 'Referenze'),
                BlockBuilder.text(' sul lato destro per vedere dove la tua pagina corrente è stata menzionata.'),
            ]),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Crea collegamenti alle pagine '),
                BlockBuilder.link('https://saga.so/guides/automatic-linking', 'automaticamente'),
                BlockBuilder.text(' digitando i loro titoli. Ad esempio, questo è un collegamento a Saga IA.'),
            ]),
            BlockBuilder.checkListItem([
                BlockBuilder.link('https://saga.so/guides/page-links', 'Anteprima'),
                BlockBuilder.text(' delle pagine istantaneamente passando il mouse sopra i collegamenti.'),
            ]),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Crea '),
                BlockBuilder.link('https://saga.so/guides/live-blocks', 'Blocchi Live'),
                BlockBuilder.text(
                    ' per sincronizzare senza sforzo i contenuti tra le pagine, eliminando i duplicati e mantenendo le informazioni aggiornate.',
                ),
            ]),
            BlockBuilder.paragraph(),
            BlockBuilder.headingThree('Diventa produttivo come mai prima d’ora'),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Impara le '),
                BlockBuilder.link('https://saga.so/guides/keyboard-shortcuts', 'scorciatoie'),
                BlockBuilder.text('.'),
            ]),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Apri le pagine in '),
                BlockBuilder.link('https://saga.so/guides/side-by-side-view', 'vista affiancata'),
                BlockBuilder.text(' per lavorare su due pagine contemporaneamente.'),
            ]),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Trova qualsiasi cosa istantaneamente in tutto il tuo spazio di lavoro con la '),
                BlockBuilder.link('https://saga.so/guides/search-in-saga', 'ricerca unificata'),
                BlockBuilder.text('.'),
            ]),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Integra con '),
                BlockBuilder.link('https://saga.so/guides/google-drive-integration', 'Google Drive'),
                BlockBuilder.text(' o '),
                BlockBuilder.link('https://saga.so/guides/linear-integration', 'Linear'),
                BlockBuilder.text(' per menzionare o cercare file e compiti memorizzati al loro interno.'),
            ]),
            BlockBuilder.checkListItem([
                BlockBuilder.text(
                    'Vai a ✨ Saga IA per imparare come usare l’assistente AI potente direttamente nel tuo spazio di lavoro.',
                ),
            ]),
            BlockBuilder.paragraph(),
            BlockBuilder.divider(),
            BlockBuilder.headingThree('Altre guide e FAQ'),
            BlockBuilder.paragraph([
                BlockBuilder.text('Visita le Guide di Saga e scopri come ottenere il massimo dall’app.'),
            ]),
            BlockBuilder.rawPrettyLink('https://saga.so/guides', {
                _tag: 'loaded',
                description: "Questa guida ti aiuterà a comprendere i blocchi costruttivi di Saga e a metterti in pari.",
                title: '📍 Guide di Saga',
                logo: 'https://saga.so/thumbnail.png',
            }),
            BlockBuilder.paragraph(),
        ],
    });
}
