import React, { ReactNode } from 'react';
import * as Select from '@radix-ui/react-select';
import classNames from 'classnames';
import { SignalIcon } from '@/components/icons';
import { PriorityColors } from '../tasks/PrioritySelect';
import { useTranslation } from 'react-i18next';
import useInterfaceSettings from '@/hooks/useInterfaceSettings';

export type PriorityFilterMode = 'unprioritized' | 'all' | 'HIGH' | 'MEDIUM' | 'LOW';

function TaskPrioritySelectItem({
    value,
    children,
    selected,
}: {
    value: PriorityFilterMode;
    children: ReactNode;
    selected: boolean;
}) {
    return (
        <Select.Item
            value={value}
            className={classNames('flex focus:outline-none font-semibold cursor-pointer px-2 py-[6px] select-none', {
                'bg-saga-gray-200 dark:text-zinc-800': selected,
                'hover:bg-saga-gray-200 dark:hover:bg-zinc-600': !selected,
            })}
        >
            <Select.ItemText>{children}</Select.ItemText>
        </Select.Item>
    );
}

function TaskPrioritySelect({
    value,
    onChange,
}: {
    value: PriorityFilterMode;
    onChange: (priority: PriorityFilterMode) => void;
}) {
    const { t } = useTranslation();
    const [{ darkMode }] = useInterfaceSettings();
    const [open, setOpen] = React.useState(false);

    return (
        <Select.Root value={value} onValueChange={onChange} onOpenChange={setOpen}>
            <Select.Trigger
                aria-label={value}
                className="whitespace-nowrap focus:outline-none cursor-pointer px-2 h-8 text-xs border font-semibold rounded hover:bg-saga-gray-200 dark:hover:bg-saga-gray-700 border-saga-gray-200 dark:border-saga-gray-700 shadow-button"
            >
                <Select.Value>
                    <span className={`flex px-1 space-x-1 text-xs items-center`}>
                        <SignalIcon
                            theme={darkMode ? 'dark' : 'light'}
                            power={value === 'all' || value === 'unprioritized' ? 'HIGH' : value}
                        />
                        <span>{t(`tasks.priority_${value.toLowerCase()}`)}</span>
                    </span>
                </Select.Value>
            </Select.Trigger>

            {open ? (
                <Select.Content className="bg-white dark:bg-zinc-700 border border-saga-gray-200 dark:border-saga-gray-700 rounded shadow-popoverSmall  text-xs">
                    <Select.ScrollUpButton />
                    <Select.Viewport className="rounded">
                        <TaskPrioritySelectItem value="all" selected={value === 'all'}>
                            <span className={`flex px-1 space-x-1 text-xs rounded leading-5 items-center`}>
                                <SignalIcon theme={darkMode && value !== 'all' ? 'dark' : 'light'} power="HIGH" />
                                <span>{t(`tasks.priority_all`)}</span>
                            </span>
                        </TaskPrioritySelectItem>
                        <TaskPrioritySelectItem value="HIGH" selected={value === 'HIGH'}>
                            <span
                                className={`flex px-1 space-x-1 text-xs rounded leading-5 items-center text-saga-text ${PriorityColors.HIGH}`}
                            >
                                <SignalIcon power="HIGH" />
                                <span>{t(`tasks.priority_high`)}</span>
                            </span>
                        </TaskPrioritySelectItem>
                        <TaskPrioritySelectItem value="MEDIUM" selected={value === 'MEDIUM'}>
                            <span
                                className={`flex px-1 space-x-1 text-xs rounded leading-5 items-center text-saga-text ${PriorityColors.MEDIUM}`}
                            >
                                <SignalIcon power="MEDIUM" />
                                <span>{t(`tasks.priority_medium`)}</span>
                            </span>
                        </TaskPrioritySelectItem>
                        <TaskPrioritySelectItem value="LOW" selected={value === 'LOW'}>
                            <span
                                className={`flex px-1 space-x-1 text-xs rounded leading-5 items-center text-saga-text ${PriorityColors.LOW}`}
                            >
                                <SignalIcon power="LOW" />
                                <span>{t(`tasks.priority_low`)}</span>
                            </span>
                        </TaskPrioritySelectItem>
                        <TaskPrioritySelectItem value="unprioritized" selected={value === 'unprioritized'}>
                            <span className={`text-xs leading-5`}>— {t(`tasks.priority_unprioritized`)}</span>
                        </TaskPrioritySelectItem>
                    </Select.Viewport>
                    <Select.ScrollDownButton />
                </Select.Content>
            ) : null}
        </Select.Root>
    );
}

export default TaskPrioritySelect;
