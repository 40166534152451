import { SagaEditor as SharedEditor, isTaskBlock, SagaLocation, WeakTask, TaskLabel } from '@saga/shared';
import React from 'react';
import Card from '@/components/styled/Card';
import classNames from 'classnames';
import { BlockPlugin } from '@/../../shared/src/Editor/Plugins';
import { AvatarAssigneeSelect } from '@/components/tasks/AssigneeSelect';
import { AlertTriangle, ArrowRightCircle, Check as CheckIcon, Trash, XCircle } from 'react-feather';
import { track } from '@/analytics';
import { DueDateSelect } from '@/components/tasks/DueDateSelect';
import { TaskTitleEditor } from '@/components/tasks/TaskTitleEditor';
import Dropdown from '@/components/popover/Dropdown';
import ContextMenuButton from '@/components/styled/ContextMenuButton';
import { OpenSideBySideButton } from '@/components/popover/PageContextMenu';
import { PopOver } from '@/components/popover/PopOver';
import { useOpenLocation } from '@/components/PageNavigationProvider';
import { useTranslation } from 'react-i18next';
import PrioritySelect from '@/components/tasks/PrioritySelect';
import useMobile from '@/hooks/useMobile';
import { usePageStaticElement } from '@/components/PublicPageProvider';
import * as api from '@saga/api';
import VoidSelectionShadow from '@/components/editor/VoidSelectionShadow';
import TaskStatusSelect from '@/components/tasks/TaskStatusSelect';
import LabelSelect from '@/components/tasks/LabelSelect';

function TaskContextMenu({
    task,
    onChangeState,
    onDeleteBlock,
    onDeleteTask,
}: {
    task: WeakTask;
    onChangeState?: (state: WeakTask['state']) => void;
    onDeleteBlock?: () => void;
    onDeleteTask?: () => void;
}) {
    const { t } = useTranslation();
    const buttonRef = React.useRef<HTMLButtonElement>(null);
    const [isOpen, setIsOpen] = React.useState(false);
    const openLocation = useOpenLocation();
    const location = React.useMemo(() => SagaLocation.taskLocationFromId(task.id), [task.id]);

    function onClose() {
        setIsOpen(false);
    }

    return (
        <>
            <ContextMenuButton
                ref={buttonRef}
                isOpen={isOpen}
                onClick={(e) => {
                    e.stopPropagation();
                    setIsOpen((isOpen) => !isOpen);
                    if (isOpen) track('open-context-menu');
                    else track('close-context-menu');
                }}
                isButtonSmall
                label={t(isOpen ? 'common.close_task_context_menu' : 'common.open_task_context_menu') as string}
            />
            <Dropdown
                testId={isOpen ? 'context-menu' : undefined}
                isOpen={isOpen}
                onClose={onClose}
                attachToRef={buttonRef}
                align="left"
            >
                <div>
                    <PopOver.RoundedButton
                        onClick={(event) => {
                            openLocation(location, event);
                        }}
                        aria-label={t('common.mark_as_complete') as string}
                    >
                        <ArrowRightCircle className="stroke-gray-dark mr-2 my-auto" size={14} />
                        {t('common.open')}
                    </PopOver.RoundedButton>

                    <span className="hidden sm:flex">
                        <OpenSideBySideButton
                            onAfterClick={onClose}
                            location={SagaLocation.taskLocationFromId(task.id)}
                        />
                    </span>

                    {onChangeState && task.state === 'OPEN' && (
                        <>
                            <div className="py-0.5">
                                <PopOver.Divider />
                            </div>
                            <PopOver.RoundedButton
                                onClick={(e) => {
                                    e.stopPropagation();
                                    onChangeState('DONE');
                                    onClose();
                                }}
                                aria-label={t('common.mark_as_complete') as string}
                            >
                                <CheckIcon className="stroke-gray-dark mr-2 my-auto" size={14} />
                                {t('common.mark_as_complete')}
                            </PopOver.RoundedButton>
                        </>
                    )}

                    <div className="py-0.5">
                        <PopOver.Divider />
                    </div>

                    {onDeleteBlock && (
                        <PopOver.RoundedButton
                            onClick={(e) => {
                                e.stopPropagation();
                                onDeleteBlock();
                            }}
                            aria-label={t('common.remove_from_page') as string}
                        >
                            <XCircle className="stroke-gray-dark mr-2 my-auto" size={14} />
                            {t('common.remove_from_page')}
                        </PopOver.RoundedButton>
                    )}

                    {onDeleteTask && (
                        <PopOver.RoundedButton
                            onClick={(e) => {
                                e.stopPropagation();
                                onDeleteTask();
                            }}
                            aria-label={t('common.delete') as string}
                        >
                            <Trash className="stroke-gray-dark mr-2 my-auto" size={14} />
                            {t('common.delete')}
                        </PopOver.RoundedButton>
                    )}
                </div>
            </Dropdown>
        </>
    );
}

export function TaskBlock({
    task,
    blockPlugins,
    onChangeState,
    onChangeAssignee,
    onChangePriority,
    onClick,
    disabled,
    onAddTaskBelow,
    onDeleteBlock,
    onChangeDueDate,
    onDeleteTask,
    onChangeLabels,
    onChangeLabelColor,
    onCreateLabel,
    spaceLabels,
}: {
    task: WeakTask;
    blockPlugins: BlockPlugin[];
    onChangeState?: (state: WeakTask['state']) => void;
    onChangeAssignee?({ id, action }: { id: string | null; action: api.TaskAssignAction }): void;
    onChangePriority?: (state: WeakTask['priority']) => void;
    onChangeDueDate?: (date: Date | null) => void;
    onChangeLabels?: (labels: string[]) => void;
    onChangeLabelColor?: (id: string, color: string) => void;
    onCreateLabel?: (title: string) => void;
    onClick?: (event: React.MouseEvent) => void;
    onAddTaskBelow?: () => void;
    disabled: boolean;
    onDeleteTask?: () => void;
    onDeleteBlock?: () => void;
    spaceLabels: TaskLabel[];
}) {
    const isMobile = useMobile();

    const onClickHandler = (event: React.MouseEvent) => {
        if (!onClick) return;
        track('click-on-task', { source: 'task-block' });
        onClick(event);
    };

    return (
        <div contentEditable={false} className="select-none">
            <Card className="transition-colors group hover:bg-saga-bg-gray-light dark:hover:bg-zinc-800 my-1">
                <div
                    className="px-[7px] py-1 relative flex items-center space-x-1 w-full"
                    aria-label={task.title}
                    onClick={isMobile ? onClickHandler : undefined}
                    data-testid={`task-${task.id}`}
                >
                    <div className="flex-shrink-0">
                        <TaskStatusSelect
                            status={task.state}
                            onChange={(status) => {
                                if (!onChangeState) return;
                                track('change-task-state', { state: status, source: 'task-block' });
                                onChangeState(status);
                            }}
                            disabled={disabled}
                        />
                    </div>
                    <div
                        className={classNames('min-w-24 flex flex-auto flex-shrink-0', {
                            'cursor-pointer': onClick != null,
                        })}
                        onClick={!isMobile ? onClickHandler : undefined}
                    >
                        <span
                            className={classNames('-ml-[1px] [min-width:25%] [max-width:75%] h-full min-h-[24px]', {
                                'opacity-50 within-checked': task.state === 'DONE',
                            })}
                            onClickCapture={(e) => !isMobile && e.stopPropagation()}
                            contentEditable={false}
                        >
                            {disabled || isMobile ? (
                                task.title
                            ) : (
                                <TaskTitleEditor
                                    blockPlugins={blockPlugins}
                                    taskId={task.id}
                                    onAddTaskBelow={onAddTaskBelow}
                                    onDelete={onDeleteBlock}
                                />
                            )}
                        </span>
                    </div>
                    <div className="flex flex-row items-center gap-x-2 gap-y-1 flex-wrap justify-end">
                        <div className="h-[22px] flex">
                            <AvatarAssigneeSelect
                                assignee={task.assignee}
                                onChange={
                                    onChangeAssignee
                                        ? (id) => {
                                              if (id == null) {
                                                  track('unassign-assignee-from-task', { source: 'task-block' });
                                              } else {
                                                  track('assign-assignee-to-task', { source: 'task-block' });
                                              }
                                              onChangeAssignee(id);
                                          }
                                        : undefined
                                }
                                disabled={disabled}
                            />
                        </div>

                        <div className="h-[22px] flex">
                            <DueDateSelect
                                task={task}
                                onChange={(date) => {
                                    if (!onChangeDueDate) return;

                                    if (date) {
                                        track('change-task-due-date', { source: 'task-block' });
                                    } else {
                                        track('remove-task-due-date', { source: 'task-block' });
                                    }

                                    onChangeDueDate(date);
                                }}
                                disabled={disabled}
                                variant="minimal"
                            />
                        </div>

                        <div className="h-[22px] flex">
                            <PrioritySelect
                                onChange={onChangePriority ? (priority) => onChangePriority(priority) : undefined}
                                assignedPriority={task.priority}
                                variant="minimal"
                                disabled={disabled || isMobile}
                                showEmptyState={!isMobile}
                            />
                        </div>
                        <div className="h-[22px] flex">
                            <LabelSelect
                                availableLabels={spaceLabels ?? []}
                                selectedIds={task.labels ?? []}
                                onSelectLabel={(id) => {
                                    track('select-task-label', { source: 'task-block' });
                                    onChangeLabels?.([...new Set([...(task.labels ?? []), id])]);
                                }}
                                onRemoveLabel={(id) => {
                                    track('remove-task-label', { source: 'task-block' });
                                    onChangeLabels?.((task.labels ?? []).filter((label) => label !== id));
                                }}
                                onCreateLabel={(title) => {
                                    track('create-task-label', { source: 'task-block' });
                                    onCreateLabel?.(title);
                                }}
                                onSelectColor={(id, color) => {
                                    track('change-task-label-color', { source: 'task-block' });
                                    onChangeLabelColor?.(id, color);
                                }}
                                variant="minimal"
                                limit={1}
                                showEmptyState
                                disabled={disabled}
                            />
                        </div>
                    </div>
                    {!disabled && (
                        <TaskContextMenu
                            task={task}
                            onChangeState={onChangeState}
                            onDeleteBlock={onDeleteBlock}
                            onDeleteTask={onDeleteTask}
                        />
                    )}
                </div>
            </Card>
        </div>
    );
}

export function TaskNotFound() {
    return (
        <div contentEditable={false} className="select-none">
            <Card>
                <div className="px-2 py-1 text-saga-red flex items-center space-x-1 w-full">
                    <AlertTriangle className="[height:18px] [width:18px]" />
                    <div>Task not found</div>
                </div>
            </Card>
        </div>
    );
}

export const taskBlockPlugin = SharedEditor.Plugins.createBlockPlugin({
    match: isTaskBlock,
    Component({ element, blockPlugins, path, children, selected }) {
        const staticTask = usePageStaticElement(element)?.staticTask ?? element.staticTask;
        if (!staticTask) {
            return <TaskNotFound />;
        }
        return (
            <VoidSelectionShadow path={path}>
                <div
                    className={classNames('rounded my-1 relative task-block', {
                        'shadow-lightblue': selected,
                        'shadow-sm': !selected,
                    })}
                    contentEditable={false}
                    id={element.id}
                >
                    <span className="hidden select-none">{children}</span>
                    <TaskBlock disabled blockPlugins={blockPlugins} task={staticTask} spaceLabels={[]} />
                </div>
            </VoidSelectionShadow>
        );
    },
});
