import React, { useRef } from 'react';
import Dropdown from '../popover/Dropdown';
import Button from '../styled/Button';
import { X } from 'react-feather';
import { Check } from 'react-feather';
import { useTranslation } from 'react-i18next';

type SaveViewButtonProps = {
    showDropdown: boolean;
    onCreateNewClick: (name: string, event: React.SyntheticEvent) => void;
    onUpdateClick: () => void;
};

export default function TableSaveViewButton({ showDropdown, onCreateNewClick, onUpdateClick }: SaveViewButtonProps) {
    const buttonRef = useRef(null);
    const [dropdownOpen, setDropdownOpen] = React.useState(false);
    const [inputOpen, setInputOpen] = React.useState(false);
    const [saveViewName, setSaveViewName] = React.useState('');

    const { t } = useTranslation();

    const hasInput = React.useMemo(() => {
        return saveViewName.trim() !== '';
    }, [saveViewName]);

    return (
        <>
            {inputOpen ? (
                <div className="flex items-center">
                    <input
                        type="text"
                        value={saveViewName}
                        onChange={(e) => setSaveViewName(e.target.value)}
                        placeholder={t('views.save_view_name_placeholder')}
                        className="border-saga-gray-200 dark:border-zinc-600 focus:bg-gray-100 dark:focus:bg-zinc-900/50 focus:border-gray-300 dark:focus:border-zinc-500 placeholder-saga-text-gray px-2 py-0.5 border rounded focus:outline-none focus-visible:border-saga-selection-blue focus-visible:ring-1 focus-visible:ring-saga-selection-blue/50 text-xs h-8 bg-white dark:bg-zinc-900 w-48"
                        autoFocus
                        onKeyDown={(e) => {
                            if (e.key === 'Enter' && hasInput) {
                                onCreateNewClick(saveViewName, e);
                            } else if (e.key === 'Escape') {
                                setInputOpen(false);
                            }
                        }}
                    />
                    <div className="flex">
                        <div className="px-2 py-1">
                            <Button.Plain
                                onClick={(e) => {
                                    onCreateNewClick(saveViewName, e);
                                    setInputOpen(false);
                                }}
                                disabled={!hasInput}
                            >
                                <Check size={14} />
                            </Button.Plain>
                        </div>
                        <div className="px-2 py-1" onClick={() => setInputOpen(false)}>
                            <Button.Plain>
                                <X size={14} />
                            </Button.Plain>
                        </div>
                    </div>
                </div>
            ) : (
                <>
                    <button
                        data-testid="save-view-button"
                        className="hover-trigger px-2 py-1.5 flex h-8 items-center rounded font-semibold text-xs leading-normal border dark:bg-inherit hover:bg-saga-gray-200 dark:hover:bg-saga-gray-700 border-saga-gray-200 dark:border-saga-gray-700 cursor-pointer shadow-button space-x-1.5"
                        ref={buttonRef}
                        onClick={() => {
                            if (showDropdown) {
                                setDropdownOpen(true);
                            } else {
                                setInputOpen(true);
                            }
                        }}
                    >
                        {t('views.save_view')}
                    </button>

                    <Dropdown
                        isOpen={dropdownOpen}
                        attachToRef={buttonRef}
                        onClose={() => {
                            setDropdownOpen(false);
                        }}
                        position="below"
                    >
                        <Button.PopOverButton onClick={onUpdateClick} type="button">
                            {t('views.update_button')}
                        </Button.PopOverButton>
                        <Button.PopOverButton
                            onClick={() => {
                                setInputOpen(true);
                            }}
                            type="button"
                        >
                            {t('views.create_new_button')}
                        </Button.PopOverButton>
                    </Dropdown>
                </>
            )}
        </>
    );
}
