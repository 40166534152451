import * as Y from 'yjs';
import { Space, yarrayFromArray, ymapFromRecord } from '.';
import { transformPagesToSharedType, transformTasksToSharedType } from './pageUtils';

/**
 * Creates the the initial Y.Doc for a given space.
 * @param {Space} space The space for which to create the Y.Doc.
 * @returns {Y.Doc} The resulting Y.Doc
 */
export function createDocumentForSpace(space: Space): Y.Doc {
    const doc = new Y.Doc();

    const spaceMap = doc.getMap('space');
    spaceMap.set('version', space.version);
    spaceMap.set('createdAt', space.createdAt);
    spaceMap.set('id', space.id);
    spaceMap.set('pinned', yarrayFromArray(space.pinned ?? []));
    spaceMap.set('properties', yarrayFromArray(space.properties));
    spaceMap.set('collections', yarrayFromArray(space.collections));
    spaceMap.set('pages', transformPagesToSharedType(space.pages));
    spaceMap.set('tasks', transformTasksToSharedType(space.tasks));
    spaceMap.set(
        'referencesRegistry',
        yarrayFromArray(space.referencesRegistry ?? [], undefined, (_, value) => value),
    );
    spaceMap.set('taskLabels', yarrayFromArray(space.taskLabels ?? []));
    spaceMap.set('userFavorites', ymapFromRecord(space.userFavorites ?? {}));
    return doc;
}
