import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { i18nextPlugin } from 'translation-check';

import { IS_DEV, IS_PREVIEW } from '../constants';
import en from './en';
import fr from './fr';
import es from './es';
import pt from './pt';
import de from './de';
import it from './it';

function getMissingKeys(languages: any[], currentPath = '', missingKeys: string[] = []) {
    const allKeys = [...new Set(languages.flatMap((language) => Object.keys(language)))];

    allKeys.forEach((key) => {
        const newPath = currentPath ? `${currentPath}.${key}` : key;

        if (languages.some((language) => !language.hasOwnProperty(key))) {
            missingKeys.push(newPath);
        }

        if (languages.some((language) => typeof language[key] === 'object')) {
            const keys = languages.map((language) => (language.hasOwnProperty(key) ? language[key] : {}));

            getMissingKeys(keys, newPath, missingKeys);
        }
    });

    return missingKeys;
}

if (localStorage.getItem('i18n-missing-translations') === 'true') {
    i18next.use({
        type: 'postProcessor',
        name: 'missingTranslations',
        process: (value: string) => '*'.repeat(value.length),
    });
}

if (IS_DEV || IS_PREVIEW) {
    i18next.use(i18nextPlugin);

    const missingKeys = getMissingKeys([en, fr, es, pt, de, it]);

    if (missingKeys.length > 0) {
        console.warn('i18next missing keys: ', missingKeys);
    }
}

i18next
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
        fallbackLng: 'en',
        debug: IS_DEV,
        resources: { en, fr, es, pt, de, it },
        postProcess: ['missingTranslations'],
    });
