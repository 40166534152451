import React, { useId } from 'react';
import { RenderLeafProps } from 'slate-react';
import { useOpenLocation } from '@/components/PageNavigationProvider';
import { useSetHighlight } from '@/components/HighlightsProvider';
import { AutolinkDecoration } from '../decorator/useDecorator';

type LeafProps = {
    autolinkDecoration: AutolinkDecoration;
    attributes?: RenderLeafProps['attributes'];
    children?: RenderLeafProps['children'];
};

export function EditorAutolink(props: LeafProps) {
    const { autolinkDecoration } = props;
    const setHighlight = useSetHighlight();
    const highlightId = useId();
    const openLocation = useOpenLocation();

    const onMouseOver = React.useCallback(
        (event: React.MouseEvent) => {
            event.stopPropagation();

            const heading =
                autolinkDecoration.referenceTargetType === 'heading' ? autolinkDecoration.heading : undefined;

            setHighlight({
                type: 'autolink',
                target: {
                    type: autolinkDecoration.referenceTargetType,
                    location: autolinkDecoration.location,
                    heading,
                },
                highlightId,
                range: autolinkDecoration.range,
                event,
            });
        },
        [highlightId, autolinkDecoration, setHighlight],
    );

    const onMouseDown = React.useCallback(
        (event: React.MouseEvent) => {
            if (event.button === 0) {
                const heading =
                    autolinkDecoration.referenceTargetType === 'heading' ? autolinkDecoration.heading : undefined;
                const blockId = heading?.id;

                openLocation({ ...autolinkDecoration.location, blockId }, event);
            }
        },
        [openLocation, autolinkDecoration],
    );

    return <Autolink {...props} onMouseOver={onMouseOver} onMouseDown={onMouseDown} highlightId={highlightId} />;
}

const Autolink = ({
    children,
    attributes,
    onMouseOver,
    onMouseDown,
    onMouseOut,
    highlightId,
}: LeafProps & {
    onMouseOver(event: React.MouseEvent): void;
    onMouseDown(event: React.MouseEvent): void;
    onMouseOut?: (event: React.MouseEvent) => void;
    highlightId: string;
}) => {
    return (
        <span
            className="cursor-pointer relative autolink"
            data-highlight-id={highlightId}
            onMouseOver={onMouseOver}
            onMouseDown={onMouseDown}
            onMouseOut={onMouseOut}
            role="link"
            {...attributes}
        >
            {children}
        </span>
    );
};
