import { track } from '@/analytics';
import { AI } from '@/assets/icons';
import Spinner from '@/components/loading/Spinner';
import Modal from '@/components/Modal';
import Tooltip from '@/components/popover/Tooltip';
import BasicButton from '@/components/styled/BasicButton';
import useAISuggestedPrompts from '@/hooks/useAISuggestedPrompts';
import { formatNumberWithSpaces } from '@/utils/numberUtils';
import * as api from '@saga/api';
import classNames from 'classnames';
import React, { useMemo, useState } from 'react';
import { AlertCircle } from 'react-feather';
import { useTranslation } from 'react-i18next';

const MAX_LENGTH_OF_PROMPT = 10_000;

export default function PromptModal({
    prompt,
    onClose,
}: {
    prompt: { id?: string; type: api.CustomPromptType };
    onClose: () => void;
}) {
    const { t } = useTranslation();
    const { userPromptSuggestions } = useAISuggestedPrompts();
    const userPrompt = userPromptSuggestions?.find((suggestion) => suggestion.id === prompt.id);

    console.log(prompt, userPrompt);

    const [title, setTitle] = useState(userPrompt?.title ?? '');
    const [value, setValue] = useState(userPrompt?.prompt ?? '');
    const [error, setError] = useState<boolean>(false);

    const [addAIPrompt, { loading: addingPrompt }] = api.useAddAiPromptMutation({
        update: (cache, { data }) => {
            const userData = cache.readQuery<api.UserDataQuery>({ query: api.UserDataDocument });

            if (userData && data?.addAIPrompt) {
                cache.writeQuery({
                    query: api.UserDataDocument,
                    data: {
                        ...userData,
                        userSettings: {
                            ...userData.userSettings,
                            customAIPrompts: [...(userData.userSettings?.customAIPrompts ?? []), data.addAIPrompt],
                        },
                    },
                });
            }
        },
        onCompleted: () => onClose(),
    });

    const [updateAIPrompt, { loading: updatingPrompt }] = api.useUpdateAiPromptsMutation({
        update: (cache, { data }) => {
            const userData = cache.readQuery<api.UserDataQuery>({ query: api.UserDataDocument });
            if (userData && data?.updateAIPrompts) {
                cache.writeQuery({
                    query: api.UserDataDocument,
                    data: {
                        ...userData,
                        userSettings: { ...userData.userSettings, customAIPrompts: data.updateAIPrompts },
                    },
                });
            }
        },
        onCompleted: () => onClose(),
    });

    const modalTitle = useMemo(
        () =>
            `settings.ai.new.title_${prompt.id ? 'edit' : 'create'}_${prompt.type === api.CustomPromptType.Command ? 'command' : 'prompt'}`,
        [prompt.type, prompt.id],
    );

    const namePlaceholder = useMemo(
        () =>
            prompt.type === api.CustomPromptType.Command
                ? 'settings.ai.new.command_name_placeholder'
                : 'settings.ai.new.prompt_name_placeholder',
        [prompt.type],
    );

    const promptPlaceholder = useMemo(
        () =>
            prompt.type === api.CustomPromptType.Command
                ? 'settings.ai.new.command_placeholder'
                : 'settings.ai.new.prompt_placeholder',
        [prompt.type],
    );

    const handleSave = () => {
        if (value.replace(/\s+/g, '').length > MAX_LENGTH_OF_PROMPT) {
            setError(true);
            return;
        }

        if (title && value && !error) {
            track(`ai-prompt-settings-${prompt.id ? 'edit' : 'create'}`, { type: prompt.type });

            if (prompt.id) {
                updateAIPrompt({ variables: { input: [{ id: prompt.id, title, prompt: value }] } });
            } else {
                addAIPrompt({ variables: { input: { title, prompt: value, type: prompt.type } } });
            }
        }
    };

    return (
        <Modal.Medium isOpen onClose={onClose}>
            <Modal.CloseButton />
            <div className="p-4 md:p-8 space-y-2 md:space-y-5 bg-white dark:bg-zinc-700 flex flex-col">
                <Modal.Title>{t(modalTitle)}</Modal.Title>
                <div>
                    <div className="border rounded px-3 py-1 mb-3 flex flex-row items-center">
                        <AI className="w-[16px] h-[16px]" />
                        <input
                            autoFocus={true}
                            onChange={(e) => setTitle(e.target.value)}
                            value={title}
                            className="pl-2 placeholder:text-sm  text-sm text-saga-text bg-transparent dark:text-saga-gray-300"
                            placeholder={t(namePlaceholder)}
                        />
                    </div>
                    <textarea
                        onChange={(e) => setValue(e.target.value)}
                        value={value}
                        className={classNames(
                            'border rounded p-2 w-full focus:outline-none placeholder:text-sm text-sm text-saga-text bg-transparent dark:text-saga-gray-300 min-h-[50vh] max-h-[60vh]',
                            {
                                'border-saga-red': error,
                            },
                        )}
                        placeholder={t(promptPlaceholder)}
                    />

                    {error && (
                        <div className="text-sm  h-4 text-saga-red">
                            {t('ai.reduce_prompt_error', { max_length: formatNumberWithSpaces(MAX_LENGTH_OF_PROMPT) })}
                        </div>
                    )}
                </div>
                <div className="flex flex-row justify-between items-center">
                    <Tooltip placement="top" maxWidth={150} content={t('settings.ai.new.custom_command_tooltip')}>
                        <AlertCircle size={20} className="cursor-pointer focus:outline-none" />
                    </Tooltip>

                    <div className="flex flex-row space-x-2">
                        <BasicButton onClick={onClose} variant="secondary">
                            {t('common.cancel')}
                        </BasicButton>
                        <BasicButton
                            disabled={!value?.length || !title.length || error || addingPrompt || updatingPrompt}
                            onClick={handleSave}
                            className="space-x-2 flex"
                        >
                            {(addingPrompt || updatingPrompt) && <Spinner size={16} />}
                            <span>{t('common.save')}</span>
                        </BasicButton>
                    </div>
                </div>
            </div>
        </Modal.Medium>
    );
}
