import Modal from '@/components/Modal';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useCurrentWorkspace, useSubscriptions } from './WorkspaceContext';
import { usePageHistoryQuery, usePageInvitesAndMembersQuery } from '@saga/api';
import { useUserContext } from '@/components/UserContext';
import { formatDateTimeOptimized, formatTimeAgo } from '@/utils/dateUtils';
import StaticEditor from '@/components/editor/StaticEditor';
import { SagaElement, SagaLocation } from '@saga/shared';
import { useBlockPlugins } from '@/components/BlockPluginProvider';
import { toUint8Array } from 'js-base64';
import * as Y from 'yjs';
import { groupBy, orderBy } from 'lodash';
import { DateTime } from 'luxon';
import classNames from 'classnames';
import { X, Lock } from 'react-feather';
import BasicButton from '@/components/styled/BasicButton';
import Button from '@/components/styled/Button';
import Spinner from '@/components/loading/Spinner';
import { useDocumentYBlocks } from '@/components/RealtimeDocumentProvider';
import useMobile from '@/hooks/useMobile';
import { track } from '@/analytics';
import { UpgradePlanBannerBig } from '@/components/billing/UpgradePlanBannerBig';
import { useSetSettings } from '@/components/settings/SettingsProvider';

export default function DocumentHistoryModal({
    pageId,
    onClose,
}: Readonly<{
    pageId: string;
    onClose: () => void;
}>) {
    const { t } = useTranslation();
    const { id } = useCurrentWorkspace();
    const { user } = useUserContext();
    const blockPlugins = useBlockPlugins();
    const yBlocks = useDocumentYBlocks(pageId);
    const isMobile = useMobile();
    const { currentSubscription } = useSubscriptions();
    const setSettings = useSetSettings();

    const [selectedTimestamp, setSelectedTimestamp] = React.useState<Date>();
    const [showUpgradePlanModal, setShowUpgradePlanModal] = React.useState(false);

    const { data: pageMembers } = usePageInvitesAndMembersQuery({
        variables: {
            pageId,
            spaceId: id,
        },
    });

    const { data, loading } = usePageHistoryQuery({
        variables: {
            input: {
                pageId,
                spaceId: id,
                userId: user?.id ?? '',
            },
        },
        fetchPolicy: 'cache-and-network',
    });

    useEffect(() => {
        setSelectedTimestamp(data?.pageHistory[0]?.updatedAt);
    }, [data?.pageHistory]);

    const blocks = React.useMemo(() => {
        const snapshot = data?.pageHistory.find((h) => h.updatedAt === selectedTimestamp);
        if (!snapshot) return [];

        const doc = new Y.Doc();
        Y.applyUpdate(doc, toUint8Array(snapshot.buffer));

        return (doc.getMap('content').toJSON().blocks as SagaElement[]).map((el) =>
            el.type === 'image' ? { ...el, size: undefined } : el,
        );
    }, [data?.pageHistory, selectedTimestamp]);

    const isVersionAccessible = React.useCallback(
        (timestamp: Date) => {
            if (currentSubscription) return true;
            const versionDate = DateTime.fromISO(timestamp.toString());
            const sevenDaysAgo = DateTime.now().minus({ days: 7 });
            return versionDate >= sevenDaysAgo;
        },
        [currentSubscription],
    );

    const groupedHistory = React.useMemo(
        () =>
            data?.pageHistory
                ? groupBy(data.pageHistory, (history) => formatTimeAgo(DateTime.fromISO(history.updatedAt)))
                : {},
        [data?.pageHistory],
    );

    const onRestore = React.useCallback(() => {
        track('document-history-restored');

        const snapshot = data?.pageHistory.find((h) => h.updatedAt === selectedTimestamp);
        if (!snapshot) return;

        const mainDoc = yBlocks.data?.doc;

        if (!mainDoc) return;

        const snapDoc = new Y.Doc();
        const type = snapDoc.getMap('content');

        Y.applyUpdate(snapDoc, toUint8Array(snapshot.buffer));

        if (type.doc !== snapDoc) {
            throw new Error('tried tracking types not on `src` Y.Doc');
        }

        const origin = `restore`;

        const undoManager = new Y.UndoManager(type, {
            trackedOrigins: new Set([origin]),
            captureTimeout: 0,
        });

        const snapSv = Y.encodeStateVector(snapDoc);
        const snapDiff = Y.encodeStateAsUpdate(mainDoc, snapSv);
        Y.applyUpdate(snapDoc, snapDiff, origin);

        undoManager.undoStack.forEach(() => undoManager.undo());

        const mainSv = Y.encodeStateVector(mainDoc);
        const mainDiff = Y.encodeStateAsUpdate(snapDoc, mainSv);
        Y.applyUpdate(mainDoc, mainDiff, `restore-${origin}-complete`);
    }, [data, selectedTimestamp, yBlocks]);

    const handleVersionClick = (timestamp: Date) => {
        if (!isVersionAccessible(timestamp)) {
            setShowUpgradePlanModal(true);
            return;
        }
        setSelectedTimestamp(timestamp);
    };

    return (
        <>
            <Modal.Jumbo isOpen onClose={onClose}>
                <div className="flex flex-row h-screen w-screen p-12">
                    <div
                        className={classNames('flex flex-1 bg-white dark:bg-saga-gray-1000 rounded', {
                            'flex-col-reverse': isMobile,
                            'flex-row': !isMobile,
                        })}
                    >
                        <div className="flex flex-1 overflow-y-auto py-6 justify-center">
                            <div className="max-w-700 px-4 w-full mx-auto">
                                <StaticEditor
                                    blocks={blocks}
                                    location={SagaLocation.pageLocationFromId('PageHistory')}
                                    blockPlugins={blockPlugins}
                                />
                            </div>
                        </div>
                        <div
                            className={classNames(' bg-saga-gray-200 dark:bg-saga-gray-800', {
                                'w-[1px] h-full': !isMobile,
                                'h-[1px] w-full': isMobile,
                            })}
                        />
                        <div
                            className={classNames('flex flex-col p-3 overflow-hidden', {
                                'w-full max-h-[40%]': isMobile,
                                'w-60': !isMobile,
                            })}
                        >
                            <div className="flex flex-row items-center w-full mb-3">
                                <div className="flex flex-row flex-1 items-center">
                                    <div className="text-base font-bold mr-2">{t('pages.history.versions')}</div>
                                    <div className="font-normal text-xs text-saga-gray-500">
                                        {!loading &&
                                            t('pages.history.versions_count', {
                                                number: data?.pageHistory.length ?? 0,
                                            })}
                                    </div>
                                </div>
                                {loading && <Spinner size={14} />}
                                <Button.Plain onClick={onClose}>
                                    <Button.BasePadding>
                                        <X size={20} />
                                    </Button.BasePadding>
                                </Button.Plain>
                            </div>
                            <div className="flex flex-1 flex-col overflow-y-auto">
                                {Object.keys(groupedHistory).map((time, timeIndex) => (
                                    <div key={timeIndex} className="flex flex-col">
                                        <div className="sticky -top-[1px] bg-white dark:bg-saga-gray-1000 mb-3">
                                            <div className="border-b border-saga-gray-200 dark:border-saga-gray-800" />
                                            <div className="my-2 font-normal text-xs text-saga-gray-500">{time}</div>
                                            <div className="border-b border-saga-gray-200 dark:border-saga-gray-800" />
                                        </div>

                                        {orderBy(groupedHistory[time], (history) => history.updatedAt, 'desc').map(
                                            (history, index) => {
                                                const author = pageMembers?.pageMembers.find(
                                                    (m) => m.userEmail === history.authorEmail,
                                                );
                                                const authorName = author
                                                    ? `${author.firstName} ${author.lastName}`
                                                    : history.authorEmail ?? '';
                                                const isAccessible = isVersionAccessible(history.updatedAt);

                                                return (
                                                    <div
                                                        key={index}
                                                        onClick={() => handleVersionClick(history.updatedAt)}
                                                        className={classNames(
                                                            'flex flex-col items-start cursor-pointer hover:bg-saga-gray-200 dark:hover:bg-saga-gray-700 m-1 px-2 py-1 rounded group',
                                                            {
                                                                'bg-saga-gray-200 dark:bg-saga-gray-700':
                                                                    selectedTimestamp === history.updatedAt,
                                                                'opacity-50': !isAccessible,
                                                            },
                                                        )}
                                                    >
                                                        <div className="flex items-center justify-between w-full">
                                                            <div className="flex-1 truncate text-sm font-medium">
                                                                {index === 0 && timeIndex === 0
                                                                    ? t('pages.history.current')
                                                                    : formatDateTimeOptimized(
                                                                          history.updatedAt,
                                                                          user?.data.language,
                                                                      )}
                                                            </div>
                                                            {!isAccessible && (
                                                                <Lock size={14} className="text-saga-gray-500 ml-2" />
                                                            )}
                                                        </div>
                                                        <div className="text-xs font-normal text-saga-gray-500">
                                                            {authorName}
                                                        </div>
                                                    </div>
                                                );
                                            },
                                        )}
                                    </div>
                                ))}
                            </div>
                            <div className="flex flex-row justify-end pt-3">
                                <BasicButton className="h-8 px-4 mr-3" variant="tertiary" onClick={onClose}>
                                    {t('common.cancel')}
                                </BasicButton>
                                <BasicButton
                                    className="h-8 px-4"
                                    variant="primary"
                                    onClick={() => {
                                        onRestore();
                                        onClose();
                                    }}
                                    disabled={selectedTimestamp === data?.pageHistory[0]?.updatedAt}
                                >
                                    {t('pages.restore')}
                                </BasicButton>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Jumbo>
            <Modal.Medium isOpen={showUpgradePlanModal}>
                <UpgradePlanBannerBig
                    title={t('billing.upgrade_plan')}
                    description={t('pages.history.upgrade_modal.description')}
                    onClose={() => setShowUpgradePlanModal(false)}
                    onClickCTA={() => {
                        setShowUpgradePlanModal(false);
                        onClose();
                        setSettings('plans');
                    }}
                    trackingSource="document-history"
                />
            </Modal.Medium>
        </>
    );
}
