import * as api from '@saga/api';
import { newSagaAiPageEn } from './en';
import { newSagaAiPageFr } from './fr';
import { newSagaAiPageEs } from './es';
import { newSagaAiPagePt } from './pt';
import { newSagaAiPageDe } from './de';
import { newSagaAiPageIt } from './it';
import { Page } from '../../types';

export function newSagaAiPage({ language, collectionIds }: { language: api.Language; collectionIds: string[] }) {
    switch (language) {
        case api.Language.En:
            return newSagaAiPageEn(collectionIds);
        case api.Language.Fr:
            return newSagaAiPageFr(collectionIds);
        case api.Language.Es:
            return newSagaAiPageEs(collectionIds);
        case api.Language.Pt:
            return newSagaAiPagePt(collectionIds);
        case api.Language.De:
            return newSagaAiPageDe(collectionIds);
        case api.Language.It:
            return newSagaAiPageIt(collectionIds);
    }
}
