import React, { RefObject, useRef, useState } from 'react';
import Button from '../styled/Button';
import { useTranslation } from 'react-i18next';
import { InputDropdown, RenderItemProps } from '../popover/Dropdown';
import { PopOver } from '../popover/PopOver';
import { SignalIcon } from '@/components/icons';
import { BarChart } from 'react-feather';
import classNames from 'classnames';

export const PRIORITIES = ['HIGH', 'MEDIUM', 'LOW', null] as const;

type RemovePriority = {
    type: 'remove-priority';
};

type Priority = {
    type: 'priority';
    priority: (typeof PRIORITIES)[number];
};

type RenderItem<Item> = {
    render: (props: RenderItemProps) => React.ReactNode;
    item: Item;
};

export const PriorityColors = {
    LOW: 'bg-green-100',
    MEDIUM: 'bg-amber-100',
    HIGH: 'bg-red-200',
    UNPRIORITIZED: 'bg-transparent',
};

function PriorityDropdown({
    attachToRef,
    isOpen,
    onClose,
    onChange,
}: {
    attachToRef: RefObject<HTMLElement>;
    isOpen: boolean;
    onClose(): void;
    onChange(memberId: string | null): void;
}) {
    const { t } = useTranslation();

    const onSubmit = ({ selectedItem }: { selectedItem: Priority | RemovePriority }) => {
        if (selectedItem.type === 'priority') {
            onChange(selectedItem.priority);
        } else {
            onChange(null);
        }
        onClose();
    };

    const items = () =>
        PRIORITIES.map(
            (priority): RenderItem<Priority> => ({
                render({ setSelection, submit, index }) {
                    return (
                        <div key={index}>
                            {priority !== 'HIGH' && <PopOver.Divider />}
                            <div
                                className="px-2 py-1.5 flex flex-wrap rounded content-evenly gap-1 cursor-pointer hover:bg-saga-gray-250 dark:hover:bg-saga-gray-800 dark:text-saga-text"
                                onClick={submit}
                                onMouseEnter={() => setSelection()}
                            >
                                <Button.RemovableOption
                                    disabled
                                    backgroundColour={priority ? PriorityColors[priority] : undefined}
                                >
                                    <span className="flex space-x-1 items-center">
                                        {priority ? (
                                            <>
                                                <SignalIcon power={priority} />
                                                <span>{t(`tasks.priority_${priority.toLowerCase()}`)}</span>
                                            </>
                                        ) : (
                                            <div className="flex items-center space-x-1 dark:text-white">
                                                <BarChart className="h-3.5 w-3.5" />
                                                <span>{t('tasks.no_priority')}</span>
                                            </div>
                                        )}
                                    </span>
                                </Button.RemovableOption>
                            </div>
                        </div>
                    );
                },
                item: { type: 'priority', priority },
            }),
        );

    return (
        <InputDropdown<Priority | RemovePriority>
            attachToRef={attachToRef}
            isOpen={isOpen}
            onClose={onClose}
            onSubmit={onSubmit}
            renderItems={items}
            hideInput
            addTopPadding
            dropdownProps={{ align: 'left' }}
        />
    );
}

export default function PrioritySelect({
    onChange,
    assignedPriority = null,
    showEmptyState = true,
    variant = 'default',
    disabled = false,
}: {
    onChange?: (priority: (typeof PRIORITIES)[number] | null) => void;
    assignedPriority: (typeof PRIORITIES)[number] | null | undefined;
    showEmptyState?: boolean;
    variant?: 'default' | 'minimal';
    disabled?: boolean;
}) {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const divRef = useRef<HTMLDivElement>(null);
    const buttonRef = React.useRef<HTMLButtonElement>(null);
    const { t } = useTranslation();

    return (
        <>
            {assignedPriority && (
                <div className="flex" data-testid={`priority-${assignedPriority.toLowerCase()}`}>
                    <Button.RemovableOption
                        onRemove={(e) => {
                            e.stopPropagation();
                            onChange && onChange(null);
                        }}
                        backgroundColour={PriorityColors[assignedPriority]}
                        disabled={disabled || variant === 'minimal'}
                    >
                        <div
                            ref={divRef}
                            onClick={(e) => {
                                if (disabled) return;
                                e.stopPropagation();
                                setIsDropdownOpen(!isDropdownOpen);
                            }}
                            className={classNames('cursor-pointer space-x-1 flex items-center dark:text-saga-text', {
                                'h-[22px]': variant === 'minimal',
                            })}
                        >
                            <span
                                className={classNames('flex space-x-1 items-center', {
                                    'w-3.5 -ml-[1px]': variant === 'minimal',
                                    'pl-[1px]': variant === 'default',
                                })}
                            >
                                <SignalIcon power={assignedPriority} />
                                {variant === 'default' && (
                                    <span>{t(`tasks.priority_${assignedPriority.toLowerCase()}`)}</span>
                                )}
                            </span>
                        </div>
                    </Button.RemovableOption>
                </div>
            )}
            {!assignedPriority &&
                showEmptyState &&
                (variant === 'default' ? (
                    <Button.DashedPill
                        data-testid={`priority-unassined`}
                        onClick={(e) => {
                            e.stopPropagation();
                            setIsDropdownOpen(true);
                        }}
                        ref={buttonRef}
                    >
                        <span>{t('tasks.no_priority')}</span>
                    </Button.DashedPill>
                ) : (
                    <Button.Dashed
                        className="flex items-center justify-center rounded-5 h-[22px] w-[21px] shrink-0"
                        data-testid={`priority-unassined`}
                        onClick={(e) => {
                            e.stopPropagation();
                            setIsDropdownOpen(!isDropdownOpen);
                        }}
                        ref={buttonRef}
                    >
                        <BarChart className="h-3.5 w-3.5" />
                    </Button.Dashed>
                ))}

            {!disabled && onChange && (
                <PriorityDropdown
                    attachToRef={assignedPriority == null ? buttonRef : divRef}
                    isOpen={isDropdownOpen}
                    onClose={() => setIsDropdownOpen(false)}
                    onChange={onChange}
                />
            )}
        </>
    );
}
