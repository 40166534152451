import { Editor, Location, Node, NodeEntry } from 'slate';
import { BlockElement, isSagaElement, isInline } from '../types';

export function toNodes<T extends Node>(nodeEntries: NodeEntry<T>[]): T[] {
    return nodeEntries.map(([node]) => node);
}

export function closestBlockElement(editor: Editor, at: Location): NodeEntry<BlockElement> | undefined {
    return Editor.above(editor, {
        at,
        mode: 'lowest',
        match(n) {
            return isSagaElement(n) && !isInline(n);
        },
    });
}
