import React from 'react';
import { Icon, Slash } from 'react-feather';
import Button from '@/components/styled/Button';
import type { EmojiIcon } from '@saga/shared';
import type { EmojiData } from 'emoji-mart';
import * as Popover from '@radix-ui/react-popover';
import useMobile from '@/hooks/useMobile';

const EmojiPicker = React.lazy(() => import('./EmojiPicker'));
const Emoji = React.lazy(() => import('./Emoji'));

function IconPickerPopUp({
    icon,
    onRemove,
    onClose,
    onSelectEmoji,
    darkMode,
    top,
    isOpen,
    children,
}: {
    icon: EmojiIcon | undefined;
    onRemove(): void;
    onClose(): void;
    onSelectEmoji(emojiData: EmojiData): void;
    darkMode?: boolean;
    top?: number;
    isOpen: boolean;
    children: React.ReactNode;
}) {
    return (
        <Popover.Root
            open={isOpen}
            onOpenChange={(isOpen) => {
                if (!isOpen) {
                    onClose();
                }
            }}
        >
            <Popover.Trigger asChild>{children}</Popover.Trigger>
            <Popover.Portal>
                <Popover.Content align="start" className="z-100">
                    <div contentEditable={false} data-testid="icon-picker" style={{ top }}>
                        {icon != null && (
                            <div className="dark:bg-zinc-700 p-1 border-t border-l border-r rounded-t bg-white dark:border-zinc-600 border-saga-gray-200">
                                <Button.Plain onClick={onRemove}>
                                    <Button.BasePadding>
                                        <div className="flex items-center space-x-2 justify-center">
                                            <Slash size={20} />
                                            <span>Remove Icon</span>
                                        </div>
                                    </Button.BasePadding>
                                </Button.Plain>
                            </div>
                        )}

                        <React.Suspense fallback={null}>
                            <EmojiPicker
                                onSelect={onSelectEmoji}
                                emoji={icon?.colons}
                                theme={darkMode ? 'dark' : 'light'}
                                title="Pick an emoji"
                                showPreview={true}
                            />
                        </React.Suspense>
                    </div>
                </Popover.Content>
            </Popover.Portal>
        </Popover.Root>
    );
}

export function IconPicker({
    icon,
    onChange,
    onRemove,
    darkMode,
    children,
    top,
}: {
    icon: EmojiIcon | undefined;
    onChange(emojiData: EmojiData): void;
    onRemove(): void;
    darkMode?: boolean;
    children(props: { onClick: () => void; ref: React.RefObject<HTMLButtonElement>; isOpen: boolean }): React.ReactNode;
    top?: number;
}) {
    const ref = React.useRef<HTMLButtonElement>(null);
    const [showIconPickerPopUp, setShowIconPickerPopUp] = React.useState(false);

    React.useEffect(() => {
        if (showIconPickerPopUp) {
            function onKeyDown(event: KeyboardEvent) {
                if (event.code === 'Escape') {
                    event.preventDefault();
                    event.stopPropagation();
                    setShowIconPickerPopUp(false);
                }
            }

            document.addEventListener('keydown', onKeyDown);
            return () => document.removeEventListener('keydown', onKeyDown);
        }

        return;
    }, [showIconPickerPopUp]);

    return (
        <>
            <IconPickerPopUp
                isOpen={showIconPickerPopUp}
                icon={icon}
                onClose={() => {
                    setShowIconPickerPopUp(false);
                }}
                onRemove={() => {
                    setShowIconPickerPopUp(false);
                    onRemove();
                }}
                onSelectEmoji={(emojiData) => {
                    setShowIconPickerPopUp(false);
                    onChange(emojiData);
                }}
                darkMode={darkMode}
                top={top}
            >
                {children({
                    onClick: () => setShowIconPickerPopUp((show) => !show),
                    ref,
                    isOpen: showIconPickerPopUp,
                })}
            </IconPickerPopUp>
        </>
    );
}

IconPicker.Button = React.forwardRef<
    HTMLButtonElement,
    {
        disabled: boolean;
        icon: EmojiIcon | undefined;
        DefaultIcon?: Icon;
        onClick(): void;
        label?: string;
        size?: number;
    }
>(function IconPickerButton({ icon, DefaultIcon, disabled, onClick, label, size }, ref) {
    const isMobile = useMobile();
    const iconSize = size || isMobile ? 24 : 38;
    const content = (() => {
        if (icon) {
            return (
                <Button.XsPadding>
                    <React.Suspense fallback={null}>
                        <Emoji emoji={icon.colons} size={iconSize} />
                    </React.Suspense>
                </Button.XsPadding>
            );
        }
        if (DefaultIcon) {
            return (
                <Button.XsPadding>
                    <DefaultIcon className="relative inline-block leading-none align-sub" size={iconSize} />
                </Button.XsPadding>
            );
        }

        return null;
    })();

    if (disabled) {
        return content;
    }

    if (content) {
        return (
            <Button.Plain ref={ref} onClick={onClick}>
                {content}
                <span className="sr-only">{label}</span>
            </Button.Plain>
        );
    }

    return null;
});
