import React, { useState } from 'react';
import { BaseSagaEditor, isSagaNodeEntry, isSagaText, RealtimeSagaEditor, SagaLocation, SagaText, SlateYjs } from '..';
import { Slate, useFocused, useSlateStatic } from 'slate-react';
import { createEditor, Editor, Text, TextUnit } from 'slate';
import { withReact } from 'slate-react';
import * as Plugins from './Plugins';
import * as Clipboard from './Clipboard';
import extendEditor from './extendEditor';
import { SharedType } from '../slateYjs/model';
import { AwarenessStateData, CursorEditor, toSlateDoc, withCursor, withYjs } from '../slateYjs';
import { BlocksLocation } from '../SagaLocation';
import { Selection } from '../EditorOperations';
import * as Y from 'yjs';
import { Awareness } from 'y-protocols/awareness';
import { Descendant } from 'slate';
import { v4 as uuid } from 'uuid';
import useEditorShortcuts from './useEditorShortcuts';
import parseSagaContent from './parseSagaContent';
import { insertBlocks } from './insertBlocks';
import { BaseRange, NodeEntry, Path, Range, Transforms } from 'slate';
import { Editable as SlateEditable, ReactEditor, RenderElementProps, RenderLeafProps } from 'slate-react';
import { DOMRange } from 'slate-react/dist/utils/dom';
import { EditorOperations, Image, isNodeEntry, isSagaElement, isTableCell, isVoid, SagaElement } from '..';
import invariant from 'tiny-invariant';
import scrollIntoView from 'scroll-into-view-if-needed';
import normalizeNode from '../Normalization/normalizeNode';
import RenderElement from './RenderElement';
import type { CoreElement } from './RenderElement';
import RenderLeaf from './RenderLeaf';
import useAwarenessStates from './useAwarenessStates';
import * as Normalizers from '../Normalization/Normalizers';

function EditorInstance({
    location,
    children,
    yBlocks,
    blockPlugins,
    editorRef,
    awareness,
    normalizers,
}: {
    location: SagaLocation.BlocksLocation;
    children: React.ReactNode;
    yBlocks: Y.Array<any>;
    blockPlugins: Plugins.BlockPlugin[];
    normalizers?: Normalizers.Normalizer[];
    editorRef: React.MutableRefObject<RealtimeSagaEditor | null> | undefined;
    awareness: Awareness | null;
}) {
    const origin = React.useMemo(() => Symbol('editor-origin'), []);
    const editor = useRealtimeSagaEditor({
        location,
        sharedType: yBlocks,
        awareness,
        origin,
        blockPlugins,
        normalizers,
    });

    const mountedEditors = useMountedEditors();
    React.useEffect(() => {
        mountedEditors.add(editor);
        return () => {
            mountedEditors.delete(editor);
        };
    }, [editor, mountedEditors]);

    const [blocks, setBlocks] = React.useState<Descendant[]>(editor.children);

    if (editorRef) {
        editorRef.current = editor;
    }

    React.useEffect(() => {
        if (editorRef?.current) {
            editorRef.current.savedSelection = undefined;
        }
    }, [blocks, editorRef]);

    return (
        <Slate editor={editor} initialValue={blocks} onChange={setBlocks}>
            {children}
        </Slate>
    );
}

function useEditorInstanceKey(pageId: string, yArray: Y.Array<unknown>) {
    return React.useMemo(() => {
        pageId;
        yArray;
        return uuid();
    }, [pageId, yArray]);
}

// This is just a wrapper around EditorInstance, that makes sure that everything gets unmounted
// and remounted appropriately to ensure consistency
function RealtimeEditor({
    children,
    location,
    yBlocks,
    canEdit,
    editorRef,
    zIndex,
    blockPlugins,
    normalizers,
    awareness,
    fullWidth,
    allowCollapsingListItems,
    disableUserInteraction,
}: {
    location: SagaLocation.BlocksLocation;
    children: React.ReactNode;
    yBlocks: Y.Array<any>;
    canEdit: boolean;
    editorRef?: React.MutableRefObject<RealtimeSagaEditor | null>;
    zIndex?: number;
    blockPlugins: Plugins.BlockPlugin[];
    normalizers?: Normalizers.Normalizer[];
    awareness: Awareness | null;
    fullWidth: boolean;
    allowCollapsingListItems?: boolean;
    disableUserInteraction?: boolean;
}) {
    // Setting a different key based on the page id has the consequence
    // that the whole component remounts if the key changes
    // This ensures, that the editor and slate instance gets properly
    // unmounted and recreated for the new page and prevents state inconsistency
    const locationId = SagaLocation.getIdFromLocation(location);
    const key = useEditorInstanceKey(locationId, yBlocks);
    const context = React.useMemo(
        () => ({
            location,
            canEdit,
            yBlocks,
            zIndex: zIndex ?? 10,
            showPlaceholder: canEdit,
            blockPlugins,
            awareness,
            allowCollapsingListItems,
            fullWidth,
            disableUserInteraction,
        }),
        [
            location,
            canEdit,
            yBlocks,
            disableUserInteraction,
            zIndex,
            blockPlugins,
            awareness,
            fullWidth,
            allowCollapsingListItems,
        ],
    );

    if (key == null) {
        return null;
    }

    return (
        <Context.Provider value={context}>
            <EditorInstance
                location={location}
                editorRef={editorRef}
                key={key}
                yBlocks={yBlocks}
                blockPlugins={blockPlugins}
                awareness={awareness}
                normalizers={normalizers}
            >
                {children}
            </EditorInstance>
        </Context.Provider>
    );
}

// This is the function from slate, but extended to not have an effect on void nodes
const scrollSelectionIntoView = (editor: ReactEditor, domRange: DOMRange) => {
    // This was affecting the selection of multiple blocks and dragging behavior,
    // so enabled only if the selection has been collapsed.
    if (!editor.selection || (editor.selection && Range.isCollapsed(editor.selection))) {
        if (editor.selection) {
            const node = Editor.above(editor as Editor, { at: editor.selection, match: isSagaElement, mode: 'lowest' });
            if (node && isNodeEntry(node, isVoid)) {
                return;
            }
        }

        const leafEl = domRange.startContainer.parentElement;
        invariant(leafEl != null);

        scrollIntoView(leafEl, {
            scrollMode: 'if-needed',
        });

        // @ts-expect-error
        delete leafEl.getBoundingClientRect;
    }
};

function cursorToCaret(cursor: Cursor, path: Path) {
    const { data, focus, anchor } = cursor;

    const isInRange = Range.includes(
        {
            anchor,
            focus,
        },
        path,
    );

    const isFocusNode = Path.equals(focus.path, path);

    if (isInRange && isFocusNode) {
        const finalFocus = {
            path,
            offset: focus.offset,
        };

        return {
            data,
            isSelection: false,
            isCaret: true,
            anchor: finalFocus,
            focus: finalFocus,
        };
    }

    return null;
}

interface Cursor extends Range {
    data: AwarenessStateData;
}

function cursorToRange(cursor: Cursor, path: Path, block: SagaText) {
    if (Range.isCollapsed(cursor)) {
        return null;
    }

    if (Range.includes(cursor, path)) {
        const { data, focus, anchor } = cursor;

        const isFocusNode = Path.equals(focus.path, path);
        const isAnchorNode = Path.equals(anchor.path, path);
        const isForward = Range.isForward({ anchor, focus });

        let anchorOffset = block.text.length;

        if (isAnchorNode) {
            anchorOffset = anchor.offset;
        } else if (isForward) {
            anchorOffset = 0;
        } else {
            anchorOffset = block.text.length;
        }

        let focusOffset = 0;

        if (isFocusNode) {
            focusOffset = focus.offset;
        } else if (isForward) {
            focusOffset = block.text.length;
        } else {
            focusOffset = 0;
        }

        const finalAnchor = {
            path,
            offset: anchorOffset,
        };
        const finalFocus = {
            path,
            offset: focusOffset,
        };
        return {
            data,
            isForward,
            isSelection: true,
            anchor: finalAnchor,
            focus: finalFocus,
        };
    }

    return null;
}

function Editable({
    spaceUrlKey,
    onPaste,
    spellCheck,
    beforeCopy,
    onPasteImage,
    decorate,
    renderLeaf = RenderLeaf,
    renderElement = RenderElement,
}: {
    spaceUrlKey: string;
    onPaste?: React.DOMAttributes<HTMLDivElement>['onPaste'];
    spellCheck?: boolean;
    beforeCopy?: Clipboard.CopyTransformer;
    onPasteImage?: (params: { file: File; image: Image }) => void;
    decorate?: (entry: NodeEntry<SagaElement | Editor | SagaText>) => BaseRange[];
    renderLeaf?: (props: RenderLeafProps) => JSX.Element;
    renderElement?: (props: RenderElementProps) => JSX.Element;
}) {
    const editor = useSlateStatic();
    const mountedEditors = useMountedEditors();
    const isFocused = useFocused();
    const [shouldScroll, setShouldScroll] = useState(false);

    const defaultOnPaste = React.useCallback(
        (event: React.ClipboardEvent) =>
            Clipboard.paste({
                editor,
                event,
                onPasteImage,
                spaceUrlKey,
            }),
        [editor, onPasteImage, spaceUrlKey],
    );

    const { location, canEdit, yBlocks, awareness, blockPlugins, disableUserInteraction } = useEditorContext();

    const awarenessStates = useAwarenessStates(awareness);

    const onDragStart = React.useCallback((e) => {
        if ((e.target as HTMLElement).id !== 'draggable') {
            e.preventDefault();
        }
    }, []);

    const onDrop = React.useCallback((e) => {
        e.preventDefault();
    }, []);

    React.useEffect(() => {
        setTimeout(() => setShouldScroll(isFocused), 100);
    }, [isFocused]);

    React.useEffect(() => {
        setTimeout(() => {
            if (editor.selection) {
                try {
                    ReactEditor.focus(editor);
                } catch {
                    // silence if focus fails for some reason
                }
            }
        });
    }, [editor]);

    const { onKeyDown } = useEditorShortcuts(editor);

    const defaultOnCopy = React.useCallback(
        (event: React.ClipboardEvent) => {
            Clipboard.copy(editor, {
                location,
                spaceUrlKey,
                event,
                transform: beforeCopy,
                blockPlugins,
            });
        },
        [editor, location, spaceUrlKey, beforeCopy, blockPlugins],
    );

    const defaultOnCut = React.useCallback(
        (event: React.ClipboardEvent) => {
            Clipboard.cut(editor, { location, spaceUrlKey, event, transform: beforeCopy, blockPlugins });
        },
        [editor, location, spaceUrlKey, beforeCopy, blockPlugins],
    );

    const onMouseDown = React.useCallback(
        (e: React.MouseEvent) => {
            const { selection } = editor;

            mountedEditors.forEach((mountedEditor) => {
                if (mountedEditor !== editor) {
                    Transforms.deselect(mountedEditor);
                }
            });

            if (selection) {
                if (e.detail >= 2 && e.button === 0) {
                    const tableCellEntry = Editor.above(editor, { match: isTableCell, at: selection });

                    // This fixes a small ux problem where when you are inside a table cell and you double or triple click,
                    // it would select the whole row, but we only want to stay inside the cell in this case
                    if (
                        Range.isCollapsed(selection) &&
                        tableCellEntry &&
                        Editor.isEnd(editor, selection.focus, tableCellEntry[1])
                    ) {
                        e.preventDefault();
                        e.stopPropagation();

                        // in the case of tripple click we also want to select the whole cell
                        if (e.detail >= 3) {
                            const range = EditorOperations.Selection.getRangeFromStartToEndInCurrentPath(editor);

                            if (range) {
                                Transforms.select(editor, range);
                            }
                        }
                    }
                }
                if (e.detail >= 3 && e.button === 0) {
                    if (Range.isExpanded(selection) && Path.equals(selection.focus.path, selection.anchor.path)) {
                        e.preventDefault();
                        e.stopPropagation();

                        const range = EditorOperations.Selection.getRangeFromStartToEndInCurrentPath(editor);

                        if (range) {
                            Transforms.select(editor, range);
                        }
                    }
                }
            }
        },
        [editor, mountedEditors],
    );

    const id = `editable-location:${SagaLocation.getIdFromLocation(location)}`;

    React.useEffect(() => {
        const editorElement = document.getElementById(id);
        if (editorElement) {
            const observer = new MutationObserver((mutationList) => {
                for (const mutation of mutationList) {
                    if (
                        mutation.target instanceof HTMLElement &&
                        mutation.type === 'attributes' &&
                        mutation.attributeName === 'data-ms-editor'
                    ) {
                        mutation.target.removeAttribute('data-ms-editor');
                        mutation.target.setAttribute('spellcheck', 'true');
                        document.querySelector('editor-card')?.remove();
                    }
                }
            });
            observer.observe(editorElement, { attributes: true, attributeFilter: ['data-ms-editor'] });
            return () => observer.disconnect();
        }
        return;
    }, [id]);

    const innerDecorate = React.useCallback(
        ([block, path]: NodeEntry<SagaElement | SagaText | Editor>): Range[] => {
            const ranges = decorate ? decorate([block, path]) : [];
            // Here, we add the cursor decorations to the ranges
            if (canEdit && Text.isText(block) && yBlocks) {
                const result = awarenessStates
                    .map(([, state]) => {
                        const cursor = state.cursors?.find((c) =>
                            SagaLocation.areLocationsEqual(c.location, editor.location),
                        );
                        if (cursor == null) {
                            return null;
                        }

                        const { anchor, focus } = SlateYjs.getRangeFromCursor(yBlocks, cursor) ?? {
                            anchor: null,
                            focus: null,
                        };

                        return { anchor, focus, data: state };
                    })
                    .filter(
                        (cursor): cursor is Cursor => cursor != null && cursor.anchor != null && cursor.focus != null,
                    );

                result.forEach((cursor) => {
                    const cursorRange = cursorToRange(cursor, path, block);
                    if (cursorRange) {
                        ranges.push(cursorRange);
                    }
                    const cursorCaret = cursorToCaret(cursor, path);
                    if (cursorCaret) {
                        ranges.push(cursorCaret);
                    }
                });
            }

            if (canEdit && isSagaText(block)) {
                const cachedSelection = editor.selection;

                if (!isFocused && cachedSelection && !Range.isCollapsed(cachedSelection)) {
                    const range = Editor.range(editor, path);
                    const intersection = Range.intersection(range, cachedSelection);
                    if (intersection) {
                        const selectionRange = { ...intersection, selected: true };
                        ranges.push(selectionRange);
                    }
                }
            }

            return ranges;
        },
        [canEdit, decorate, isFocused, editor, yBlocks, awarenessStates],
    );

    return (
        <SlateEditable
            id={id}
            data-testid="editor"
            spellCheck={spellCheck}
            onDragStart={onDragStart}
            onDrop={onDrop}
            onKeyDown={onKeyDown}
            onMouseDown={onMouseDown}
            renderLeaf={renderLeaf}
            renderElement={renderElement}
            onCopy={defaultOnCopy}
            onPaste={onPaste ?? defaultOnPaste}
            onCut={defaultOnCut}
            readOnly={!canEdit || disableUserInteraction}
            scrollSelectionIntoView={shouldScroll ? scrollSelectionIntoView : noop}
            decorate={innerDecorate}
        />
    );
}

function createBaseSagaEditor(
    blockPlugins: Plugins.BlockPlugin[],
    normalizer?: Normalizers.Normalizer[],
    undoManager?: Y.UndoManager,
): Editor {
    return withReact(extendEditor(createEditor(), blockPlugins, normalizer, undoManager));
}

type RealtimeSagaEditorProps = {
    location: BlocksLocation;
    sharedType: SharedType;
    awareness: Awareness | null;
    origin: any;
    blockPlugins: Plugins.BlockPlugin[];
    normalizers?: Normalizers.Normalizer[];
};

function createRealtimeSagaEditor({
    sharedType,
    location,
    awareness,
    origin,
    blockPlugins,
    normalizers,
}: RealtimeSagaEditorProps): RealtimeSagaEditor {
    const undoManager = new Y.UndoManager(sharedType, { trackedOrigins: new Set([origin]) });

    // we pass the undoManager down so that we can more control the undo logic more in a more fine-grained way
    // e.g. we can stop capturing before certain actions to make the undo / redo experience better
    const baseSagaEditor = createBaseSagaEditor(blockPlugins, normalizers, undoManager);
    const editor = withCursor(withYjs(baseSagaEditor, sharedType, origin), awareness, location) as RealtimeSagaEditor;
    Editor.normalize(editor, { force: true });
    Selection.initialSelect(editor, blockPlugins);

    editor.undo = () => undoManager.undo();
    editor.redo = () => undoManager.redo();

    editor.canUndo = () => undoManager.canUndo();
    editor.canRedo = () => undoManager.canRedo();

    editor.origin = origin;

    return editor;
}

export function useRealtimeSagaEditor(props: RealtimeSagaEditorProps): RealtimeSagaEditor {
    const editor = React.useRef<RealtimeSagaEditor | null>(null);

    // Why useRef? Glad you asked.
    // Check this issue https://github.com/ianstormtaylor/slate/issues/3886
    if (editor.current === null) {
        editor.current = createRealtimeSagaEditor(props);
    }

    React.useEffect(() => {
        setTimeout(() => {
            if (editor.current) {
                CursorEditor.updateCursor(editor.current);
            }
        }, 0);

        return () => {
            if (editor.current) {
                editor.current.cleanup();
            }
            editor.current = null;
        };
    }, [editor]);

    return editor.current;
}

export type EditorContext = {
    location: BlocksLocation;
    canEdit: boolean;
    zIndex: number;
    showPlaceholder: boolean;
    yBlocks?: Y.Array<any>;
    blockPlugins: Plugins.BlockPlugin[];
    awareness?: Awareness | null;
    fullWidth?: boolean;
    allowCollapsingListItems?: boolean;
    disableUserInteraction?: boolean;
};

const Context = React.createContext<EditorContext | null>(null);

const useEditorContext = () => {
    const context = React.useContext(Context);

    if (context == null) {
        throw new Error('useEditorContext needs to be used within EditorContext');
    }

    return context;
};

const MountedEditorsContext = React.createContext(new Set<RealtimeSagaEditor>());

const useMountedEditors = () => React.useContext(MountedEditorsContext);

function BlockEditor({
    sharedType,
    location,
    blockPlugins,
    children,
    onInsertSoftBreak,
    onDeleteBackward,
}: {
    sharedType: SharedType;
    location: SagaLocation.BlocksLocation;
    blockPlugins: Plugins.BlockPlugin[];
    children: React.ReactNode;
    onInsertSoftBreak?: () => void;
    onDeleteBackward?: (editor: Editor, unit: TextUnit) => void;
}) {
    const handlersRef = React.useRef({ onInsertSoftBreak, onDeleteBackward });
    handlersRef.current = { onInsertSoftBreak, onDeleteBackward };

    const editor = React.useMemo(() => {
        const origin = Symbol('block-editor-origin');
        const editor = withReact(SlateYjs.withYjs(createEditor(), sharedType, origin));
        const undoManager = new Y.UndoManager(sharedType, { trackedOrigins: new Set([origin]) });

        editor.undo = () => undoManager.undo();
        editor.redo = () => undoManager.redo();

        editor.isVoid = EditorOperations.Extensions.isVoid(editor);
        editor.isInline = EditorOperations.Extensions.isInline(editor);

        const pluginNormalizers = blockPlugins.map(({ normalizers }) => normalizers ?? []).flat();
        editor.normalizeNode = normalizeNode(editor, [...Normalizers.baseNormalizers, ...pluginNormalizers]);
        editor.insertBreak = () => {};
        const { deleteBackward } = editor;
        editor.deleteBackward = (unit: TextUnit) => {
            const handlers = handlersRef.current;
            if (handlers.onDeleteBackward) {
                handlers.onDeleteBackward(editor, unit);
            }

            deleteBackward(unit);
        };

        editor.insertSoftBreak = () => {
            const handlers = handlersRef.current;
            if (handlers.onInsertSoftBreak) {
                handlers.onInsertSoftBreak();
            }
        };

        // @ts-expect-error
        editor.children = toSlateDoc(sharedType);
        Editor.normalize(editor, { force: true });

        return editor;
    }, [sharedType, blockPlugins]);

    const [blocks, setBlocks] = React.useState<Descendant[]>(editor.children);

    const context = React.useMemo(
        () => ({
            location,
            canEdit: true,
            zIndex: 10,
            showPlaceholder: false,
            blockPlugins,
            fullWidth: false,
            allowCollapsingListItems: false,
        }),
        [location, blockPlugins],
    );

    return (
        <Context.Provider value={context}>
            <Slate editor={editor} initialValue={blocks} onChange={setBlocks}>
                {children}
            </Slate>
        </Context.Provider>
    );
}

function BlockEditable({
    renderElement = RenderElement,
    renderLeaf = RenderLeaf,
}: {
    renderElement?: (props: RenderElementProps) => JSX.Element;
    renderLeaf?: (props: RenderLeafProps) => JSX.Element;
}) {
    const editor = useSlateStatic();

    const handleKeyDown = (e: React.KeyboardEvent) => {
        if ((e.metaKey || e.ctrlKey) && e.key === 'a') {
            e.preventDefault();

            const start = Editor.start(editor, []);
            const end = Editor.end(editor, []);

            Transforms.select(editor, {
                anchor: start,
                focus: end,
            });
        }

        if (e.key === 'Escape') {
            e.preventDefault();

            Transforms.collapse(editor, { edge: 'end' });
        }
    };

    const handleMouseDown = (e: React.MouseEvent) => {
        // Left mouse button
        if (e.button === 0) {
            Transforms.collapse(editor, { edge: 'end' });
        }
    };

    return (
        <SlateEditable
            renderElement={renderElement}
            renderLeaf={renderLeaf}
            onDrop={(e) => e.preventDefault()}
            onPaste={(e) => {
                e.preventDefault();
                const { plainText } = Clipboard.getContent(e.clipboardData);

                if (plainText) {
                    Transforms.insertText(editor, plainText.replace(/(\r\n|\n|\r)/gm, ''));
                }
            }}
            onKeyDown={handleKeyDown}
            onMouseDown={handleMouseDown}
        />
    );
}

const noop = () => {};

function ReadonlyEditable({
    onCopy,
    decorate,
    testId,
    renderElement = RenderElement,
    renderLeaf = RenderLeaf,
}: {
    testId?: string;
    onCopy?: React.ClipboardEventHandler<HTMLDivElement>;
    decorate?: (entry: NodeEntry<SagaElement>) => BaseRange[];
    renderElement?: (props: RenderElementProps) => JSX.Element;
    renderLeaf?: (props: RenderLeafProps) => JSX.Element;
}) {
    const editor = useSlateStatic();

    const decorator = React.useCallback(
        (entry: NodeEntry): BaseRange[] => {
            if (decorate && isSagaNodeEntry(entry)) {
                return decorate(entry);
            }
            return [];
        },
        [decorate],
    );

    const onMouseDown = React.useCallback(
        (event: React.MouseEvent) => {
            if (event.detail >= 3) {
                const { selection } = editor;

                if (selection) {
                    if (Range.isExpanded(selection) && Path.equals(selection.focus.path, selection.anchor.path)) {
                        const range = EditorOperations.Selection.getRangeFromStartToEndInCurrentPath(editor);
                        const domSelection = window.getSelection();

                        if (range && domSelection) {
                            event.preventDefault();
                            event.stopPropagation();
                            const domRange = ReactEditor.toDOMRange(editor as ReactEditor, range);
                            domRange.selectNodeContents(domRange.commonAncestorContainer);
                            domSelection.removeAllRanges();
                            domSelection.addRange(domRange);
                        }
                    }
                }
            }
        },
        [editor],
    );

    return (
        <SlateEditable
            data-testid={testId}
            spellCheck={false}
            scrollSelectionIntoView={noop}
            readOnly={true}
            renderLeaf={renderLeaf}
            renderElement={renderElement}
            decorate={decorator}
            onCopy={onCopy}
            style={{ zIndex: 0 }}
            onMouseDown={onMouseDown}
        />
    );
}

function ReadonlyEditorInstance({
    children,
    blocks,
    editorRef,
    blockPlugins,
}: {
    blocks: Descendant[];
    children: React.ReactNode;
    editorRef: React.MutableRefObject<BaseSagaEditor | null> | undefined;
    blockPlugins: Plugins.BlockPlugin[];
}) {
    const editor = React.useMemo(() => {
        const editor = createBaseSagaEditor(blockPlugins);
        editor.normalizeNode = () => {};
        editor.children = blocks;
        return editor;
    }, [blockPlugins, blocks]);

    if (editorRef) {
        editorRef.current = editor;
    }

    return (
        <Slate editor={editor} initialValue={blocks}>
            {children}
        </Slate>
    );
}

function ReadonlyEditor({
    children,
    blocks,
    editorRef,
    zIndex,
    blockPlugins,
    fullWidth,
    location,
    allowCollapsingListItems,
}: {
    blocks: Descendant[];
    location: SagaLocation.BlocksLocation;
    children: React.ReactNode;
    editorRef?: React.MutableRefObject<RealtimeSagaEditor | null>;
    zIndex?: number;
    blockPlugins: Plugins.BlockPlugin[];
    fullWidth?: boolean;
    allowCollapsingListItems?: boolean;
}) {
    const context = React.useMemo(
        () => ({
            location,
            canEdit: false,
            zIndex: zIndex ?? 10,
            showPlaceholder: false,
            blockPlugins,
            fullWidth,
            allowCollapsingListItems,
        }),
        [location, zIndex, blockPlugins, fullWidth, allowCollapsingListItems],
    );

    return (
        <Context.Provider value={context}>
            {/*
               // Setting a different key based on the page id has the consequence
                // that the whole component remounts if the key changes
                // This ensures, that the editor and slate instance gets properly
                // unmounted and recreated for the new page and prevents state inconsistency
            */}
            <ReadonlyEditorInstance
                editorRef={editorRef}
                key={SagaLocation.getIdFromLocation(location)}
                blocks={blocks}
                blockPlugins={blockPlugins}
            >
                {children}
            </ReadonlyEditorInstance>
        </Context.Provider>
    );
}

const ContainerContext = React.createContext<React.RefObject<HTMLDivElement> | null>(null);
const useContainer = () => React.useContext(ContainerContext);

type ContainerProps = {
    children: React.ReactNode;
    yBlocks?: Y.Array<any>;
    focus?: { blockId: string; offset?: number };
    className?: string;
};

function Container({ children, className, focus, yBlocks }: ContainerProps) {
    const containerRef = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        const containerElement = containerRef.current;
        if (containerElement) {
            const observer = new MutationObserver((mutationList) => {
                for (const mutation of mutationList) {
                    mutation.addedNodes.forEach((node) => {
                        // This is a hack that removes the Microsoft Spellchecker node because it does not work well with saga
                        if (node instanceof HTMLElement && node.tagName === 'EDITOR-SQUIGGLER') {
                            node.remove();
                        }
                    });
                }
            });
            observer.observe(containerElement, { childList: true });

            return () => observer.disconnect();
        }
        return;
    }, []);

    const blocksToFocus = React.useMemo(() => {
        if (!focus) {
            return undefined;
        }

        if (!yBlocks) {
            return [focus.blockId];
        }

        const blockOffset = focus.offset ?? 0;
        const blockIndex = yBlocks.toJSON().findIndex((b) => b.id === focus.blockId);
        const blocks = yBlocks.toJSON().slice(blockIndex, blockIndex + blockOffset + 1);

        return blocks.map((block) => block.id);
    }, [yBlocks, focus]);

    React.useEffect(() => {
        if (containerRef.current && blocksToFocus) {
            blocksToFocus.forEach((blockId) => {
                const block = document.getElementById(blockId);
                if (block) {
                    scrollIntoView(block, { scrollMode: 'if-needed', behavior: 'smooth' });
                    const observer = new IntersectionObserver((entries) => {
                        if (entries[0].intersectionRatio > 0) {
                            observer.disconnect();
                            block.parentElement?.classList.add('block-highlight');
                            setTimeout(() => {
                                block.parentElement?.classList.remove('block-highlight');
                            }, 1000);
                        }
                    });

                    observer.observe(block);
                }
            });
        }
    }, [blocksToFocus]);

    return (
        <ContainerContext.Provider value={containerRef}>
            <div
                ref={containerRef}
                data-editor-container={true}
                id="editor-container"
                data-testid="editor-container"
                className={className}
            >
                {children}
            </div>
        </ContainerContext.Provider>
    );
}

export {
    createBaseSagaEditor,
    createRealtimeSagaEditor,
    extendEditor,
    useEditorShortcuts,
    useEditorContext,
    useMountedEditors,
    parseSagaContent,
    insertBlocks,
    RealtimeEditor,
    BlockEditor,
    BlockEditable,
    Editable,
    ReadonlyEditable,
    ReadonlyEditor,
    Clipboard,
    Plugins,
    Normalizers,
    Container,
    ContainerContext,
    useContainer,
    RenderElement,
    CoreElement,
    useAwarenessStates,
};
