import React from 'react';
import * as api from '@saga/api';
import { useCurrentWorkspace } from './WorkspaceContext';
import { assertNonNull } from '@/../../shared/src';
import { Member } from '@/types';
import { useCurrentPageId, useCurrentTaskId } from '@/hooks/SpaceHooks';

const MembersContext = React.createContext<{
    getMember(memberId: string): Member | null;
    members: Member[];
} | null>(null);

export const useMembers = () => {
    const context = React.useContext(MembersContext);
    assertNonNull(context, 'useMembers must be used inside MembersContext');
    return context;
};

export function getFullMemberName(member: Member) {
    return `${member.firstName} ${member.lastName}`;
}

const defaultContext = {
    getMember() {
        return null;
    },
    members: [],
};

export function NoopMembersProvider({ children }: { children: React.ReactNode }) {
    return <MembersContext.Provider value={defaultContext}>{children}</MembersContext.Provider>;
}

export function MembersProvider({ children }: { children: React.ReactNode }) {
    const { members, id: spaceId } = useCurrentWorkspace();

    const pageId = useCurrentPageId();
    const taskId = useCurrentTaskId();

    const [getPageMembersAndInvites, { data }] = api.usePageInvitesAndMembersLazyQuery();

    const pageMembers = React.useMemo(
        () =>
            data?.pageMembers.map((member) => ({
                id: member.userId,
                firstName: member.firstName,
                lastName: member.lastName,
            })) || [],
        [data?.pageMembers],
    );

    const getMember = React.useCallback(
        (id: string) => {
            return [...members, ...pageMembers].find((member) => member.id === id) ?? null;
        },
        [members, pageMembers],
    );

    const context = React.useMemo(() => {
        const map = new Map([...members, ...pageMembers].map((member) => [member.id, member]));
        const uniqueMembers = [...map.values()];
        return { getMember, members: uniqueMembers };
    }, [getMember, members, pageMembers]);

    React.useEffect(() => {
        const id = pageId || taskId;

        if (id) {
            getPageMembersAndInvites({ variables: { spaceId, pageId: id } });
        }
    }, [pageId, taskId, getPageMembersAndInvites, spaceId]);

    return <MembersContext.Provider value={context}>{children}</MembersContext.Provider>;
}

export function PageMembersProvider({
    pageId,
    spaceId,
    children,
}: {
    children: React.ReactNode;
    pageId: string;
    spaceId: string;
}) {
    const { data: membersData } = api.usePageInvitesAndMembersQuery({
        variables: { pageId, spaceId },
        fetchPolicy: 'cache-and-network',
    });

    const members = React.useMemo(
        () =>
            membersData?.pageMembers.map((member) => ({
                id: member.userId,
                firstName: member.firstName,
                lastName: member.lastName,
            })) || [],
        [membersData?.pageMembers],
    );

    const getMember = React.useCallback(
        (id: string) => {
            return members.find((member) => member.id === id) ?? null;
        },
        [members],
    );

    const context = React.useMemo(() => ({ getMember, members }), [getMember, members]);

    return <MembersContext.Provider value={context}>{children}</MembersContext.Provider>;
}
