import './i18n';
import * as React from 'react';
import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import App from '@/components/App';
import { UserContextProvider } from '@/components/UserContext';
import { FirebaseContextProvider } from '@/components/FirebaseContext';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider as ReactDndProvider } from 'react-dnd';
import { LoadingContextProvider } from '@/components/loading/LoadingContext';
import { ToastContextProvider } from '@/components/toast/ToastContext';
import posthog from 'posthog-js';
import { debugLog, isDebug } from '@/utils';
import { apolloClient } from './graphql';
import { ApolloProvider } from '@apollo/client';
import { InterfaceSettingsProvider } from './hooks/useInterfaceSettings';
import { LoadingScreenProvider } from './components/loading/LoadingScreen';
import { assertNonNull } from '@saga/shared';
import * as Shared from '@saga/shared';
import { BrowserContextProvider, useCsrfTokenReady } from './components/BrowserContextProvider';
import Desktop from './components/Desktop';
import DesktopContextProvider from './components/DesktopContext';

import 'emoji-mart/css/emoji-mart.css';
import 'react-day-picker/dist/style.css';
import 'remixicon/fonts/remixicon.css';
import '@/assets/stylesheets/index.css';
import { IS_DEV, IS_PREVIEW, IS_PRODUCTION } from './constants';
import { HelmetProvider } from 'react-helmet-async';
import { ExtraErrorData } from '@sentry/integrations';

if (isDebug()) {
    //@ts-expect-error
    window.Shared = Shared;
}

function GraphQLProvider({ children }: Readonly<{ children: React.ReactNode }>) {
    const csrfTokenReady = useCsrfTokenReady();
    debugLog('csrfTokenReady', csrfTokenReady);
    if (!csrfTokenReady) return null;

    return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
}

if (import.meta.env.VITE_SENTRY_DSN && import.meta.env.VITE_SENTRY_PROJECT_ID && !IS_DEV) {
    Sentry.init({
        dsn: import.meta.env.VITE_SENTRY_DSN,
        integrations: [
            new ExtraErrorData(),
            new Sentry.BrowserTracing(),
            new posthog.SentryIntegration(posthog, 'saga-hq', parseInt(import.meta.env.VITE_SENTRY_PROJECT_ID)),
        ],
        environment: IS_PRODUCTION ? import.meta.env.MODE : IS_PREVIEW ? 'pull-request' : 'development',
        release: COMMIT_REF,

        // We recommend adjusting this value in production, or using tracesSampler
        // for finer control
        tracesSampleRate: 0.5,
    });
}

const rootEl = document.getElementById('root');
assertNonNull(rootEl, 'root element must exist');
const root = ReactDOM.createRoot(rootEl);

function CloseWindow() {
    React.useEffect(() => {
        window.close();
    }, []);
    return null;
}

root.render(
    <BrowserContextProvider>
        <HelmetProvider>
            <InterfaceSettingsProvider>
                <DesktopContextProvider>
                    <LoadingScreenProvider>
                        <Router>
                            <Switch>
                                <Route path="/close">
                                    <CloseWindow />
                                </Route>
                                <Route path="/login/desktop-email">
                                    <Desktop loginType="email" />
                                </Route>
                                <Route path="/login/desktop-google">
                                    <Desktop loginType="google" />
                                </Route>
                                <FirebaseContextProvider>
                                    <GraphQLProvider>
                                        <Router>
                                            <UserContextProvider>
                                                <ReactDndProvider backend={HTML5Backend}>
                                                    <LoadingContextProvider>
                                                        <ToastContextProvider>
                                                            <Switch>
                                                                <Route>
                                                                    <App />
                                                                </Route>
                                                            </Switch>
                                                        </ToastContextProvider>
                                                    </LoadingContextProvider>
                                                </ReactDndProvider>
                                            </UserContextProvider>
                                        </Router>
                                    </GraphQLProvider>
                                </FirebaseContextProvider>
                            </Switch>
                        </Router>
                    </LoadingScreenProvider>
                </DesktopContextProvider>
            </InterfaceSettingsProvider>
        </HelmetProvider>
    </BrowserContextProvider>,
);
