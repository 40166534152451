import { LoaderIcon } from '@/components/icons';
import Portal from '@/components/popover/Portal';
import useOnClickOutside from '@/hooks/useClickOutside';
import { useCombinedRefs } from '@/hooks/useCombinedRefs';
import {
    moveElementAboveRect,
    moveElementBelowRect,
    moveElementComparedToWindow,
    moveElementOnRect,
    moveElementToRightOfRect,
} from '@/utils/popoverUtils';
import classNames from 'classnames';
import * as R from 'ramda';
import React, { forwardRef, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { animated, useSpring } from 'react-spring';

type PositionOption = 'over' | 'below' | 'above' | 'right';
export type AlignmentOption = 'right' | 'left' | 'center';

type PopOverHookProps = {
    isOpen: boolean;
    target?: () => DOMRect | null;
    position?: PositionOption;
    align?: AlignmentOption;
    onClose?: () => void;
    overflow?: {
        x?: number;
        y?: number;
    };
    offset?: {
        x?: number;
        y?: number;
    };
    renderDeps?: any[];
    clickOutsideRefs?: React.RefObject<HTMLElement>[];
    beforeMove?: () => void;
    combineRef?: ((instance: HTMLDivElement | null) => void) | React.MutableRefObject<HTMLDivElement | null> | null;
};

type PopOverProps = {
    children: React.ReactNode;
    contentRef: React.RefObject<HTMLDivElement>;
    isOpen: boolean;
    className?: string;
    zIndex?: number;
    noPointer?: boolean;
    delay?: number;
    testId?: string;
    onOpenAnimationDone?: () => void;
};

const DEFAULT_OVERFLOW = {
    x: 100,
    y: 100,
};

const DEFAULT_OFFSET = {
    x: 0,
    y: 0,
};

function usePrevious<T>(value: T) {
    // The ref object is a generic container whose current property is mutable ...
    // ... and can hold any value, similar to an instance property on a class
    const ref = useRef<T | null>(null);

    // Store current value in ref
    useEffect(() => {
        ref.current = value;
    }, [value]); // Only re-run if value changes

    // Return previous value (happens before update in useEffect above)
    return ref.current;
}

/**
 * @param target needs to wrapped in a useCallback
 * @deprecated
 */
export const usePopover = ({
    isOpen,
    onClose,
    target,
    position = 'below',
    align = 'left',
    overflow,
    offset,
    renderDeps,
    clickOutsideRefs = [],
    beforeMove,
    combineRef,
}: PopOverHookProps) => {
    const ref = useRef<HTMLDivElement>(null);
    const baseContentRef = useRef<HTMLDivElement>(null);
    const contentRef = useCombinedRefs<HTMLDivElement>(baseContentRef, combineRef);
    const handlersRef = useRef({
        beforeMove,
    });
    handlersRef.current = { beforeMove };

    const { x: overflowX, y: overflowY } = { ...DEFAULT_OVERFLOW, ...overflow };
    const { x: offsetX, y: offsetY } = { ...DEFAULT_OFFSET, ...offset };

    const previousDeps = usePrevious(renderDeps);
    const depsHaveChanged = !R.equals(previousDeps, renderDeps);

    useOnClickOutside([ref, contentRef, ...clickOutsideRefs], () => onClose && onClose(), {
        clickEvent: 'mousedown',
    });

    const [update, setUpdate] = useState(0);

    useEffect(() => {
        const handleEsc = (event: KeyboardEvent) => {
            if (event.key === 'Escape' && onClose) {
                event.stopPropagation();
                onClose();
            }
        };
        if (isOpen && onClose) {
            window.addEventListener('keydown', handleEsc, { capture: true });
            return () => {
                window.removeEventListener('keydown', handleEsc, { capture: true });
            };
        }
        return;
    }, [isOpen, onClose]);

    useEffect(() => {
        if (typeof window !== 'object') {
            return;
        }
        if (isOpen) {
            const handleUpdate = () => setUpdate(update + 1);
            window.addEventListener('scroll', handleUpdate, true);
            window.addEventListener('resize', handleUpdate);
            return () => {
                window.removeEventListener('scroll', handleUpdate, true);
                window.removeEventListener('resize', handleUpdate);
            };
        }
        return;
    }, [isOpen, update]);

    useLayoutEffect(() => {
        // if there is a mouseover, move the preview modal to the current DOM point
        if (typeof ref === 'function' || !ref) {
            return;
        }
        const { beforeMove } = handlersRef.current;
        const el = ref.current;
        const container = contentRef.current;
        if (!(isOpen && el && container)) {
            return;
        }
        if (beforeMove) {
            beforeMove();
        }

        if (!target) {
            moveElementComparedToWindow(el);
            return;
        }
        const rect = target();

        if (!rect) {
            return;
        }

        const isRectOverflowingRight = rect.right + overflowX > window.scrollX + window.innerWidth;
        const isElementOverflowingRight = rect.right + overflowX > window.scrollX + window.innerWidth;
        const isRectOverflowingLeft = rect.left - overflowX < window.scrollX;
        const isElementOverflowingLeft = rect.left - overflowX < window.scrollX;
        const isRectOverflowingTop = rect.top - overflowY < window.scrollY;
        const isRectOverflowingBottom = rect.bottom + overflowY > window.scrollY + window.innerHeight;
        let alignment: AlignmentOption = 'center';
        if (align !== 'center') {
            if (align === 'right') {
                alignment = isRectOverflowingLeft || isElementOverflowingLeft ? 'left' : 'right';
            }
            if (align === 'left') {
                alignment = isRectOverflowingRight || isElementOverflowingRight ? 'right' : 'left';
            }
        }
        switch (position) {
            case 'below':
                if (isRectOverflowingBottom) {
                    moveElementAboveRect(el, rect, { alignment, paddingY: -4 + offsetY });
                } else {
                    moveElementBelowRect(el, rect, { alignment, paddingY: 4 + offsetY });
                }
                break;
            case 'above':
                if (isRectOverflowingTop && isRectOverflowingBottom) {
                    moveElementComparedToWindow(el, { alignment });
                } else if (isRectOverflowingTop) {
                    moveElementBelowRect(el, rect, { alignment, paddingY: 4 + offsetY });
                } else {
                    moveElementAboveRect(el, rect, { alignment, paddingY: -4 + offsetY });
                }
                break;
            case 'right':
                if (isElementOverflowingRight) {
                    moveElementBelowRect(el, rect, { alignment, paddingY: 4 + offsetY });
                } else {
                    moveElementToRightOfRect(el, rect, { alignment: 'top', paddingX: 4 + offsetX });
                }
                break;
            case 'over':
                moveElementOnRect(el, rect);
                break;
        }
    }, [
        target,
        contentRef,
        isOpen,
        overflowX,
        overflowY,
        ref,
        update,
        depsHaveChanged,
        position,
        align,
        offsetY,
        offsetX,
    ]);

    return [ref, contentRef];
};

/**
 * @deprecated
 */
export const PopOver = forwardRef<HTMLDivElement, PopOverProps>(function PopOver(
    { children, testId, className, isOpen, contentRef, noPointer, zIndex, onOpenAnimationDone, delay = 0 },
    ref,
) {
    const innerRef = useRef<HTMLDivElement>(null);
    const combinedRef = useCombinedRefs<HTMLDivElement>(innerRef, ref);

    const transitionSpring: any = useSpring({
        from: {
            visibility: 'hidden',
            opacity: 0,
            pointerEvents: 'none',
        },
        to: {
            visibility: isOpen ? 'visible' : 'hidden',
            opacity: isOpen ? 1 : 0,
            pointerEvents: noPointer ? 'none' : 'auto',
        },
        delay,
        config: {
            duration: 100 + delay,
        },
        onRest() {
            if (isOpen && onOpenAnimationDone) {
                onOpenAnimationDone();
            }
        },
    });

    return (
        <Portal>
            <div className="absolute inset-0 pointer-events-none" ref={contentRef}>
                <animated.div
                    data-testid={testId}
                    style={{ ...transitionSpring, zIndex: isOpen ? zIndex ?? 60 : 1 }}
                    className={classNames('absolute pointer-events-auto', className)}
                    ref={combinedRef}
                >
                    {children}
                </animated.div>
            </div>
        </Portal>
    );
}) as CompoundPopover;

const PopOverInput = React.forwardRef<
    HTMLInputElement,
    React.InputHTMLAttributes<HTMLInputElement> & {
        rounded?: 'full' | 'bottom' | 'top';
    }
>(function PopOverInput({ rounded = 'bottom', ...props }, ref) {
    return (
        <input
            ref={ref}
            className={classNames(
                'text-sm pl-3.5 pr-2 py-2 bg-saga-gray-100 dark:bg-saga-gray-800 focus:outline-0 font-normal placeholder-saga-text-gray block w-full appearance-none leading-normal ds-input pointer-events-auto',
                {
                    'rounded-md': rounded === 'full',
                    'rounded-b-md': rounded === 'top',
                    'rounded-t-md': rounded === 'bottom',
                },
            )}
            {...props}
        ></input>
    );
});

const PopOverRoundedButton = React.forwardRef<
    HTMLButtonElement,
    Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'className'> & {
        selected?: boolean;
        active?: boolean;
        fullWidth?: boolean;
    }
>(function PopOverRoundedButton({ children, selected, active, disabled, fullWidth, ...props }, ref) {
    return (
        <button
            ref={ref}
            {...props}
            className={classNames(
                'pl-2 pr-2 py-1 text-sm truncate flex focus:outline-none focus:bg-saga-gray-250 dark:focus:text-zinc-800 dark:active:text-zinc-200 rounded items-center h-8',
                {
                    'bg-saga-bg-gray dark:bg-zinc-200/10': selected && !disabled && !active,
                    'bg-saga-gray-200': selected && disabled,
                    'font-semibold bg-saga-gray-250 ': active && !disabled,
                    'text-saga-text-gray hover:bg-saga-gray-250 dark:hover:bg-zinc-200/10': disabled,
                    'hover:bg-saga-gray-250 dark:hover:bg-saga-gray-800': !disabled && !active,
                    'w-full': fullWidth === undefined ? true : false,
                },
            )}
            disabled={disabled}
        >
            {children}
        </button>
    );
});

function PopOverCard({
    size = 'base',
    rounded = 'full',
    children,
}: {
    size?: 'sm' | 'base';
    rounded?: 'full' | 'top' | 'bottom';
    children: React.ReactNode;
}) {
    return (
        <div
            className={classNames(
                'relative p-1.5 bg-white dark:bg-saga-gray-900 dark:border dark:border-saga-gray-800 pointer-events-auto',
                {
                    'w-highlight': size === 'base',
                    'min-w-[180px]': size === 'sm',
                    'rounded-md shadow-popupSmall': rounded === 'full',
                    'rounded-t-md': rounded === 'top',
                    'rounded-b-md pt-0': rounded === 'bottom',
                },
            )}
        >
            {children}
        </div>
    );
}
function PopOverPaddedCard({ children }: { children: React.ReactNode }) {
    return (
        <div className="p-1.5 space-y-0.5 overflow-hidden relative shadow-popupSmall max-w-highlight rounded-md bg-white dark:bg-saga-gray-900 dark:border dark:border-saga-gray-800 pointer-events-auto">
            {children}
        </div>
    );
}

function PopOverUnboundCard({ children }: { children: React.ReactNode }) {
    return (
        <div className="overflow-hidden relative shadow-popupSmall rounded-md bg-white dark:bg-saga-gray-900 dark:border dark:border-saga-gray-800 pointer-events-auto">
            {children}
        </div>
    );
}

function PopOverLabel({ children, loading }: { children?: React.ReactNode; loading?: boolean }) {
    return (
        <div className="flex justify-between text-xs leading-normal px-2 py-1 text-saga-gray-500">
            {children}
            {loading && <LoaderIcon className="h-4 w-4 text-white" />}
        </div>
    );
}

function PopOverLabelSearchPanel({ children, loading }: { children?: React.ReactNode; loading?: boolean }) {
    return (
        <div className="flex justify-between text-sm leading-normal pr-2 pl-[9px] py-1 text-saga-text-gray">
            {children}
            {loading && <LoaderIcon className="h-4 w-4 text-white" />}
        </div>
    );
}

function PopOverSelectedOptionsContainer({ children }: { children: React.ReactNode }) {
    return <div className="flex flex-wrap content-evenly gap-1">{children}</div>;
}

function PopOverDivider() {
    return <div className="border-t border-saga-gray-150 dark:border-saga-gray-800 border-solid"></div>;
}

interface CompoundPopover extends React.ForwardRefExoticComponent<PopOverProps & React.RefAttributes<HTMLDivElement>> {
    Input: typeof PopOverInput;
    RoundedButton: typeof PopOverRoundedButton;
    Card: typeof PopOverCard;
    PaddedCard: typeof PopOverPaddedCard;
    UnboundCard: typeof PopOverUnboundCard;
    Label: typeof PopOverLabel;
    SelectedOptionsContainer: typeof PopOverSelectedOptionsContainer;
    Divider: typeof PopOverDivider;
    SearchPanelLabel: typeof PopOverLabelSearchPanel;
}

PopOver.RoundedButton = PopOverRoundedButton;
PopOver.Input = PopOverInput;
PopOver.Card = PopOverCard;
PopOver.PaddedCard = PopOverPaddedCard;
PopOver.UnboundCard = PopOverUnboundCard;
PopOver.Label = PopOverLabel;
PopOver.SelectedOptionsContainer = PopOverSelectedOptionsContainer;
PopOver.Divider = PopOverDivider;
PopOver.SearchPanelLabel = PopOverLabelSearchPanel;
