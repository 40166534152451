import React from 'react';
import Modal from '@/components/Modal';
import Button from '@/components/styled/Button';
import { useLocalStorageValue } from '@/utils/localStorageUtils';
import * as io from 'io-ts';
import SagaRelease from '@/assets/images/saga-in-app-1-0.png';
import { track } from '@/analytics';
import { useTranslation } from 'react-i18next';

// This key is used for tracking and for storing the information if the notification has been already seen
const CURRENT_IN_APP_NOTIFICATION_KEY = 'saga-release';

// This is called from the SignupModal to have new signups not see the notification
export function setCurrentInAppNotificationSeen() {
    localStorage.setItem(CURRENT_IN_APP_NOTIFICATION_KEY, 'true');
}

// This component renders the notification itself, change the content as desired
export function InAppNotification() {
    const { t } = useTranslation();
    const [hasSeenNotification, setHasSeenNotification] = useLocalStorageValue(
        CURRENT_IN_APP_NOTIFICATION_KEY,
        io.boolean.decode,
        false,
    );

    function action() {
        track(`${CURRENT_IN_APP_NOTIFICATION_KEY}-action`);
        setHasSeenNotification(true);

        // Change this action depending on the notification
        // with for example: setSettings('integrations');
        window.open('https://saga.so/blog/introducing-saga/', '_blank');
    }

    function dismiss() {
        track(`${CURRENT_IN_APP_NOTIFICATION_KEY}-dismiss`);
        setHasSeenNotification(true);
    }

    return (
        <Modal.Medium
            shouldCloseOnOverlayClick={false}
            shouldCloseOnEsc={false}
            onClose={dismiss}
            isOpen={!hasSeenNotification}
        >
            <div className="p-2">
                <div className="rounded overflow-hidden relative">
                    <div className="bg-saga-new-purple-light">
                        <Modal.CloseButton dark={false} />
                        <Modal.HeroContainerWithoutPadding>
                            <img src={SagaRelease} className="w-full" />
                        </Modal.HeroContainerWithoutPadding>
                    </div>
                    <Modal.Content>
                        <Modal.Title>{t('inapp_notifications.title')}</Modal.Title>

                        <div className="space-y-6">
                            <p className="text-center">
                                {t('inapp_notifications.description')}{' '}
                                {/* This shows a Learn more link after the description.
                                <a
                                    href="https://saga.so/blog/introducing-saga/"
                                    target="_blank"
                                    rel="noreferrer noopener"
                                    className="underline"
                                    onClick={() => track(`${CURRENT_IN_APP_NOTIFICATION_KEY}-learn-more`)}
                                >
                                    {t('common.learn_more')}
                                </a> */}
                            </p>
                            <div className="flex items-center justify-center space-x-2">
                                <Button onClick={action}>{t('common.learn_more')}</Button>
                                <Button.Plain onClick={dismiss}>
                                    <Button.LargePadding>{t('common.dismiss')}</Button.LargePadding>
                                </Button.Plain>
                            </div>
                        </div>
                    </Modal.Content>
                </div>
            </div>
        </Modal.Medium>
    );
}
