import { BlockBuilder, EditorOperations, SagaEditor, SpaceOperations } from '@saga/shared';
import { track } from '@/analytics';
import { useCreatePage } from '@/hooks/SpaceHooks';
import React from 'react';
import { Editor as SlateEditor, Range, Transforms } from 'slate';
import { ReactEditor, useSlateStatic } from 'slate-react';
import TurnIntoPopOver from './popover/TurnIntoPopOver';
import { useSpace } from './SpaceProvider';
import { useToastContext } from './toast/ToastContext';
import { SimpleTitleToast } from './toast/ToastLibrary';
import { getSdk } from '@/graphql';
import useOnTextEvent from '@/components/editor/useOnTextEvent';
import { useSetAIPopoverOpen } from '@/components/editor/popover/AIPopoverProvider';
import { useTranslation } from 'react-i18next';
import { useTaskFilters } from '@/hooks/useTaskFilters';
import { createTask } from '@/utils/documentUtils';

type OpenTurnIntoSuggestionState = { ref: React.MutableRefObject<HTMLDivElement | null>; blockId: string };
const OpenTurnIntoSuggestions = React.createContext<(args: OpenTurnIntoSuggestionState) => void>(() => {});
const TurnIntoSuggestionsStateContext = React.createContext<{ isOpen: boolean }>({ isOpen: false });

export const useOpenTurnIntoSuggestions = () => React.useContext(OpenTurnIntoSuggestions);
export const useTurnIntoSuggestionsContext = () => React.useContext(TurnIntoSuggestionsStateContext);

export function TurnIntoSuggestions({ children }: { children: React.ReactNode }) {
    const [state, setState] = React.useState<OpenTurnIntoSuggestionState | null>(null);
    const { space, provider } = useSpace();
    const editor = useSlateStatic();
    const [, startTransition] = React.useTransition();
    const { selection } = editor;
    const { showToast } = useToastContext();
    const { yBlocks } = SagaEditor.useEditorContext();
    const { openAIPopover, isOpen: isAIPopoverOpen } = useSetAIPopoverOpen();
    const { t } = useTranslation();
    const taskFilters = useTaskFilters();

    const createPage = useCreatePage();

    const open = React.useCallback(
        (args: OpenTurnIntoSuggestionState) => {
            startTransition(() => {
                setState(args);
            });
        },
        [startTransition],
    );

    const isOpen = state != null;

    const suggestionsStateContext = React.useMemo(() => ({ isOpen }), [isOpen]);

    const close = React.useCallback(() => {
        ReactEditor.deselect(editor);
        setState(null);
    }, [editor]);

    useOnTextEvent(yBlocks, editor.origin, {
        onDelete: ({ value }) => {
            if (isOpen && (!value || value === '')) {
                setState(null);
            }
        },
    });

    React.useEffect(() => {
        if (isAIPopoverOpen) {
            setState(null);
        }
    }, [isAIPopoverOpen]);

    const createPageFromValue = React.useCallback(
        (value: string, selection: Range, source: 'hovering-toolbar' | 'keyboard-shortcut') => {
            track('create-page', { source });

            const title = value.trim();
            Transforms.collapse(editor, { edge: 'end' });

            const page = createPage({ title }, { showToast: true });
            const inlinePageLink = SpaceOperations.createInlinePageLink(space, page.id, page.blocks);

            EditorOperations.Transforms.insertInlineNode(editor, inlinePageLink, selection);
            close();
        },
        [space, createPage, editor, close],
    );

    const value = selection ? SlateEditor.string(editor, selection) : '';

    const currentFormat = React.useMemo(() => {
        if (selection) {
            return EditorOperations.Selection.getFormat(editor, selection) ?? 'text';
        }
        return 'text';
    }, [editor, selection]);

    return (
        <OpenTurnIntoSuggestions.Provider value={open}>
            <TurnIntoSuggestionsStateContext.Provider value={suggestionsStateContext}>
                {children}
                {state?.ref && (
                    <TurnIntoPopOver
                        currentFormat={currentFormat}
                        isOpen={isOpen}
                        onClose={() => setState(null)}
                        attachToRef={state.ref}
                        align="right"
                        onCopyLinkToBlock={() => {
                            navigator.clipboard.writeText(`${window.location.href}#${state.blockId}`);
                            track('copy-block-link', { source: 'turn-into-popover' });
                            showToast(() => (
                                <SimpleTitleToast>{`Link to block copied to clipboard.`}</SimpleTitleToast>
                            ));
                        }}
                        onDeleteBlock={() => {
                            const nodeEntry = EditorOperations.Selection.getNode(editor, state.blockId);
                            if (nodeEntry) {
                                const [, path] = nodeEntry;
                                Transforms.removeNodes(editor, { at: path });
                                track('delete-block', { source: 'turn-into-popover' });
                                showToast(() => <SimpleTitleToast>{t('editor.block_deleted')}</SimpleTitleToast>);
                            }
                            setState(null);
                        }}
                        onAskAI={() => {
                            const selection = editor.selection;

                            if (!selection) {
                                return;
                            }

                            openAIPopover(editor.selection);
                        }}
                        onTurnInto={(result) => {
                            if (!selection) {
                                return;
                            }

                            if (result.type === 'page') {
                                createPageFromValue(result.value, selection, 'hovering-toolbar');
                            } else if (result.type === 'block') {
                                track(`turn-into-${result.blockType}`, { source: 'turn-into-suggestions' });
                                EditorOperations.Transforms.turnIntoBlock({
                                    editor,
                                    blockType: result.blockType,
                                    range: selection,
                                });
                            } else if (result.type === 'task') {
                                track('create-task', { source: 'hovering-toolbar' });

                                const title = result.value.trim();
                                Transforms.collapse(editor, { edge: 'end' });

                                const task = createTask(space, { title, ...taskFilters }, provider);

                                const taskBlock = BlockBuilder.taskBlock(task.id, task);
                                Transforms.insertFragment(editor, [taskBlock], { at: selection });
                            } else if (result.type === 'linear-issue') {
                                const title = result.value.trim();
                                SpaceOperations.addLinearIssue(getSdk, editor, {
                                    type: 'add',
                                    teamId: result.team.id,
                                    title,
                                });
                            }

                            close();
                        }}
                        zIndex={10}
                        selectedValue={value}
                        canTurnIntoPage
                        canTurnIntoTask
                        canTurnIntoLinearIssue
                    />
                )}
            </TurnIntoSuggestionsStateContext.Provider>
        </OpenTurnIntoSuggestions.Provider>
    );
}
