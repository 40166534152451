import {
    collectionD,
    isInlineCollection,
    isInlinePageLink,
    isLiveBlock,
    isSagaElement,
    isTaskBlock,
    mapBlocks,
    SafeSpace,
    SagaElement,
    SagaText,
    WeakBlocks,
} from '../types';
import findCollectionById from './findCollectionById';
import findReferenceRegistryEntry from './findReferenceRegistryEntry';
import getPageById from './getPageById';
import * as E from 'fp-ts/Either';
import findTask from './findTask';

export default function prepareBlocksForExport(space: SafeSpace, blocks: WeakBlocks): (SagaElement | SagaText)[] {
    return mapBlocks(blocks, (block) => {
        if (isLiveBlock(block)) {
            const entry = findReferenceRegistryEntry(space, block.reference.liveReferenceSourceId);
            if (entry) {
                const liveBlocks = entry.get('liveBlocks') as SagaElement[];
                const staticBlocks = prepareBlocksForExport(space, liveBlocks);
                return { ...block, staticBlocks: staticBlocks.filter(isSagaElement) };
            }
        }

        if (isTaskBlock(block)) {
            const task = findTask(space, block.taskId);
            if (task) {
                return {
                    ...block,
                    staticTask: task,
                };
            }
        }

        if (isInlinePageLink(block)) {
            const page = getPageById(space, block.pageId, ['title', 'icon', 'isTemplate']);
            if (page) {
                return {
                    ...block,
                    staticPage: {
                        title: page.title,
                        isTemplate: page.isTemplate,
                        icon: page.icon,
                    },
                };
            }

            return block;
        }

        if (isInlineCollection(block)) {
            const collectionMap = findCollectionById(space, block.collectionId);

            if (collectionMap) {
                const collection = collectionD.decode(collectionMap.toJSON());

                if (E.isRight(collection)) {
                    return {
                        ...block,
                        staticCollection: {
                            title: collection.right.title,
                            icon: collection.right.icon,
                        },
                    };
                }
            }
        }

        return block;
    });
}
