import React from 'react';
import { SagaEditor, isLiveBlock, SagaLocation } from '@saga/shared';
import StaticEditor from '../StaticEditor';
import { usePageStaticElement } from '@/components/PublicPageProvider';

export const liveBlockPlugin = SagaEditor.Plugins.createBlockPlugin({
    match: isLiveBlock,
    Component({ blockPlugins, element, children }) {
        const staticBlocks = usePageStaticElement(element)?.staticBlocks ?? element.staticBlocks;

        return (
            <div className="select-none mr-1 my-1 shadow-sm rounded border border-saga-gray-200 dark:border-zinc-600 p-2">
                <StaticEditor
                    location={SagaLocation.pageLocationFromId('')}
                    blocks={staticBlocks ?? []}
                    blockPlugins={blockPlugins}
                />
                {children}
            </div>
        );
    },
});
