import React, { useState } from 'react';
import { Clock } from 'react-feather';
import { useTranslation } from 'react-i18next';
import Tooltip from '../popover/Tooltip';
import { Lightning } from '@/assets/icons';

import Button from '../styled/Button';
import Dropdown from '../popover/Dropdown';
import AIChatThreadsList from '../aichat/AIChatThreadsList';
import { useOpenLocation } from '../PageNavigationProvider';
import useMobile from '@/hooks/useMobile';
import useInterfaceSettings from '@/hooks/useInterfaceSettings';

type Props = {
    isSelected: boolean;
};

function SagaChatButton({ isSelected }: Props) {
    const { t } = useTranslation();

    const [historyOpen, setHistoryOpen] = useState(false);

    const openLocation = useOpenLocation();
    const historyButtonRef = React.useRef<HTMLDivElement>(null);
    const isMobile = useMobile();
    const [, setInterfaceSettings] = useInterfaceSettings();
    const handleOpenSagaAI = (event: React.MouseEvent, threadId?: string) => {
        openLocation({ type: 'sagaAI', threadId }, event);

        if (isMobile) {
            setInterfaceSettings({ fixedSidebar: false });
        }
    };

    return (
        <>
            <Button.Plain widthFull isSelected={isSelected} onClick={(event) => handleOpenSagaAI(event)}>
                <div className="relative group pl-[5px] pr-1 py-1.5 flex items-center space-x-[7px]">
                    <Lightning className="flex-none w-5 h-5 shrink-0" />
                    <div className="min-w-0 font-medium text-sm">{t('ai.title')}</div>
                    <div className="opacity-0 group-hover:opacity-100 pointer-events-none group-hover:pointer-events-auto">
                        <div
                            className="absolute z-20 right-0 top-0 bottom-0 flex items-center px-1"
                            onClick={(e) => {
                                e.stopPropagation();
                                setHistoryOpen((open) => !open);
                            }}
                        >
                            <Tooltip placement="right" content={t('ai.chat.history')}>
                                <div
                                    ref={historyButtonRef}
                                    className="h-6 px-1 bg-white dark:bg-saga-gray-1000 flex flex-row cursor-pointer items-center text-xs leading-normal border-saga-gray-200 dark:border-zinc-500 border-2 font-semibold focus:outline-none active:shadow-xs rounded"
                                >
                                    <Clock className="flex-none" size={12} />
                                </div>
                            </Tooltip>
                        </div>
                    </div>
                </div>
            </Button.Plain>
            <Dropdown
                testId={'ai-chat-history-dropdown'}
                isOpen={historyOpen}
                onClose={() => setHistoryOpen(false)}
                attachToRef={historyButtonRef}
                align={'left'}
                overflow={false}
            >
                <div className="max-h-96 overflow-y-auto w-80">
                    <AIChatThreadsList
                        onSelected={(event, thread) => {
                            setHistoryOpen(false);
                            handleOpenSagaAI(event, thread.id);
                        }}
                    />
                </div>
            </Dropdown>
        </>
    );
}

export default SagaChatButton;
