import { BlockBuilder } from '../..';
import { newBlankPage } from '../../pageUtils';

export function newSagaAiPageDe(collectionIds: string[]) {
    return newBlankPage({
        title: 'Saga KI',
        icon: {
            type: 'emoji',
            colons: ':sparkles:',
        },
        collections: collectionIds,
        blocks: [
            BlockBuilder.title('Saga KI'),
            BlockBuilder.headingThree('Inhalte generieren und Antworten mit KI erhalten'),
            BlockBuilder.image('https://saga-public.s3.amazonaws.com/1-ai-assistant-generating-popover-launch.webp'),
            BlockBuilder.checkListItem([BlockBuilder.text('Erstelle einen Entwurf mit KI.')]),
            BlockBuilder.listItem([
                BlockBuilder.text('Klicke auf den '),
                BlockBuilder.text('KI', { code: true }),
                BlockBuilder.text(' Knopf im Editor.'),
            ]),
            BlockBuilder.listItem([
                BlockBuilder.text('Alternativ, drücke '),
                BlockBuilder.text('@', { code: true }),
                BlockBuilder.text(' oder '),
                BlockBuilder.text('/', { code: true }),
                BlockBuilder.text(' und wähle KI aus dem Autovervollständigungsmenü.'),
            ]),
            BlockBuilder.paragraph(),
            BlockBuilder.paragraph(),
            BlockBuilder.image('https://saga-public.s3.amazonaws.com/2-ai-assistant-select-or-type-prompt.webp'),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Wähle eine Option aus der Liste oder schreibe deine eigene Aufforderung.'),
            ]),
            BlockBuilder.paragraph(),
            BlockBuilder.paragraph(),
            BlockBuilder.image('https://saga-public.s3.amazonaws.com/3-ai-assistant-blog-post-generation.webp'),
            BlockBuilder.checkListItem([
                BlockBuilder.text(
                    'Iteriere über das Ergebnis, füge mehr Details hinzu und sieh dir die Geschichte deiner KI-Konversation an.',
                ),
            ]),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Speichere deine Aufforderung, um sie später wiederzuverwenden, indem du den ☆ Knopf drückst.'),
            ]),
            BlockBuilder.paragraph(),
            BlockBuilder.paragraph(),
            BlockBuilder.headingThree('Mit KI bearbeiten'),
            BlockBuilder.image('https://saga-public.s3.amazonaws.com/4-ai-assistant-commands-pallette.webp'),
            BlockBuilder.checkListItem([
                BlockBuilder.text(
                    'Bitte Saga KI, deinen Text umzuschreiben, Grammatik zu korrigieren, ihn kürzer zu machen, den Ton zu ändern, zu übersetzen und mehr.',
                ),
            ]),
            // todo - indent
            BlockBuilder.listItem([
                BlockBuilder.text('Wähle einen beliebigen Text aus und klicke auf "Saga KI" in der Werkzeugleiste, um das KI-Menü zu öffnen.'),
            ]),
            BlockBuilder.listItem([BlockBuilder.text('Wähle eine Option aus der Liste oder schreibe deine eigene Aufforderung.')]),
            BlockBuilder.paragraph(),
            BlockBuilder.paragraph(),
            BlockBuilder.image('https://saga-public.s3.amazonaws.com/5-ai-assistant-edit-the-text.webp'),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Frage Saga KI, was du als Nächstes mit der Antwort tun sollst, füge Details hinzu und iteriere.'),
            ]),
            BlockBuilder.checkListItem([
                BlockBuilder.text('Ersetze den ursprünglichen Inhalt mit der Antwort von der KI oder füge sie darunter ein.'),
            ]),
            BlockBuilder.paragraph(),
            BlockBuilder.paragraph(),
            BlockBuilder.divider(),
            BlockBuilder.headingThree('Leitfäden und FAQs'),
            BlockBuilder.paragraph([
                BlockBuilder.text(
                    'Besuche die Saga KI Leitfäden für mehr Tipps und Tricks zur Nutzung von KI in deinem Arbeitsbereich oder kehre zurück zu 👋 Saga Grundlagen, um weiterzulernen.',
                ),
            ]),
            BlockBuilder.rawPrettyLink('https://saga.so/guides-category/saga-ai', {
                _tag: 'loaded',
                description:
                    'Lerne, wie du Saga KI - den KI-gestützten Assistenten in deinem Arbeitsbereich - nutzen kannst. Werde kreativ und produktiver mit einem KI-Assistenten.',
                title: '📍 Saga KI – Saga Leitfäden',
                logo: 'https://saga.so/thumbnail.png',
            }),
            BlockBuilder.paragraph(),
        ],
    });
}
