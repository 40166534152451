import { CreatePageSuggestion, PageSuggestion } from '@/types';
import classNames from 'classnames';
import React from 'react';
import { filterSuggestions } from './Dropdown';
import PagesCollectionsPopOver, {
    Props as PagesCollectionsPopOverProps,
    appendCreatePageSuggestion,
} from './PagesCollectionsPopOver';
import Tooltip from './Tooltip';
import { useTranslation } from 'react-i18next';

type Props = {
    popoverProps: Omit<
        PagesCollectionsPopOverProps<PageSuggestion, PageSuggestion | CreatePageSuggestion>,
        'filterBySearch' | 'inputProps' | 'attachToRef' | 'label'
    >;
    zIndex?: number;
    onOpen: React.MouseEventHandler<HTMLButtonElement>;
    attachToRef: React.MutableRefObject<HTMLButtonElement | null>;
    createLiveReferenceDisabled: boolean;
};

const SendAsLiveBlockButton: React.FC<Props> = ({
    popoverProps,
    zIndex,
    onOpen,
    attachToRef,
    createLiveReferenceDisabled,
    children,
}) => {
    const { dropdownProps, ...rest } = popoverProps;
    const { t } = useTranslation();

    return (
        <>
            <Tooltip
                content={
                    createLiveReferenceDisabled ? (
                        t('common.create_live_block_disabled')
                    ) : (
                        <div>
                            <div className="font-semibold">{t('common.create_live_block')}</div>
                            <span>{t('common.create_live_block_description')}</span>
                        </div>
                    )
                }
                placement="top"
            >
                <button
                    ref={attachToRef}
                    className={classNames('unselectable', {
                        'opacity-50': createLiveReferenceDisabled,
                        'cursor-pointer': !createLiveReferenceDisabled,
                    })}
                    onClick={(e) => {
                        if (!createLiveReferenceDisabled) {
                            onOpen(e);
                        }
                    }}
                >
                    {children}
                </button>
            </Tooltip>
            {popoverProps.isOpen && (
                <PagesCollectionsPopOver
                    attachToRef={attachToRef}
                    filterBySearch={(currentSuggestions, search) => {
                        return appendCreatePageSuggestion(filterSuggestions(currentSuggestions, search), search);
                    }}
                    inputProps={{
                        placeholder: 'Type a destination Page...',
                        title: 'Type a destination Page...',
                    }}
                    dropdownProps={{
                        align: 'left',
                        position: 'below',
                        testId: 'send-reference-to-page',
                        zIndex: zIndex ? zIndex + 10 : undefined,
                        maxWHighlight: true,
                        ...dropdownProps,
                    }}
                    {...rest}
                    label="Select or create new"
                />
            )}
        </>
    );
};

export default SendAsLiveBlockButton;
