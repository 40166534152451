import { logError, toCtx } from '../utils';
import { SafeSpace } from '../types';
import { pipe } from 'fp-ts/function';
import * as E from 'fp-ts/Either';
import { deleteFromYArray } from '../yjs-utils';
import transactInSpace from './transactInSpace';

export default function removePinnedById(space: SafeSpace, idToDelete: string) {
    transactInSpace(space, () => {
        pipe(
            space.get('pinned'),
            E.chain(toCtx),
            E.chainW((ctx) =>
                deleteFromYArray(ctx.safeArray.array, ctx.items, (id) => id === idToDelete, 'removePinned'),
            ),
        );
    });
}
