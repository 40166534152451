import React, { useState } from 'react';

type DraggableDots = {
    className?: string;
};

export function DraggableDots({ className }: DraggableDots) {
    const [color, setColor] = useState<undefined | string>(undefined);

    const changeColor = () => {
        setColor((prevColor) => (prevColor === 'currentColor' ? undefined : 'currentColor'));
    };

    return (
        <svg
            width="18"
            height="24"
            viewBox="0 0 18 24"
            fill="none"
            className={className}
            xmlns="http://www.w3.org/2000/svg"
            onMouseEnter={changeColor}
            onMouseLeave={changeColor}
        >
            <g clipPath="url(#clip0_13051_106518)">
                <path
                    d="M6.5 7.5C7.32843 7.5 8 6.82843 8 6C8 5.17157 7.32843 4.5 6.5 4.5C5.67157 4.5 5 5.17157 5 6C5 6.82843 5.67157 7.5 6.5 7.5Z"
                    fill={color || '#B8B8B8'}
                />
                <path
                    d="M6.5 19.5C7.32843 19.5 8 18.8284 8 18C8 17.1716 7.32843 16.5 6.5 16.5C5.67157 16.5 5 17.1716 5 18C5 18.8284 5.67157 19.5 6.5 19.5Z"
                    fill={color || '#B8B8B8'}
                />
                <path
                    d="M11.5 7.5C12.3284 7.5 13 6.82843 13 6C13 5.17157 12.3284 4.5 11.5 4.5C10.6716 4.5 10 5.17157 10 6C10 6.82843 10.6716 7.5 11.5 7.5Z"
                    fill={color || '#B8B8B8'}
                />
                <path
                    d="M11.5 19.5C12.3284 19.5 13 18.8284 13 18C13 17.1716 12.3284 16.5 11.5 16.5C10.6716 16.5 10 17.1716 10 18C10 18.8284 10.6716 19.5 11.5 19.5Z"
                    fill={color || '#B8B8B8'}
                />
                <path
                    d="M11.5 13.5C12.3284 13.5 13 12.8284 13 12C13 11.1716 12.3284 10.5 11.5 10.5C10.6716 10.5 10 11.1716 10 12C10 12.8284 10.6716 13.5 11.5 13.5Z"
                    fill={color || '#B8B8B8'}
                />
                <path
                    d="M6.5 13.5C7.32843 13.5 8 12.8284 8 12C8 11.1716 7.32843 10.5 6.5 10.5C5.67157 10.5 5 11.1716 5 12C5 12.8284 5.67157 13.5 6.5 13.5Z"
                    fill={color || '#B8B8B8'}
                />
            </g>
            <defs>
                <clipPath id="clip0_13051_106518">
                    <rect width="18" height="24" rx="4" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
