import { isLiveBlockSource, isSagaElement, mapBlock, Page, SagaElement, Task } from '../types';

import { PAGE_SCHEMA_VERSION } from '../constants';
import IdMap from '../utils/IdMap';

function isPage(entity: Page | Task): entity is Page {
    return (entity as Page).version !== undefined;
}

export function transformDuplicate(entity: Page | Task): Page | Task {
    const idMap = new IdMap();
    const mappedBlocks = entity.blocks.map((block) => {
        return mapBlock(block as SagaElement, (node) => {
            if (isLiveBlockSource(node)) {
                return node.children;
            }
            if (isSagaElement(node)) {
                return { ...node, id: idMap.swapId(node.id) };
            }
            return node;
        });
    });

    return {
        ...entity,
        ...(isPage(entity) && { version: PAGE_SCHEMA_VERSION }),
        createdAt: new Date().toString(),
        updatedAt: new Date().toString(),
        id: idMap.swapId(entity.id),
        blocks: mappedBlocks.flat(),
    };
}
