import * as api from '@saga/api';
export default {
    todo: {
        [api.Language.En]: ['todo', 'checklist'],
        [api.Language.Fr]: ['faire', 'liste-de-contrôle'],
        [api.Language.Es]: ['hacer', 'lista de verificación'],
        [api.Language.Pt]: ['fazer', 'lista de verificação'],
        [api.Language.De]: ['Aufgabe', 'todo', 'Checkliste'],
        [api.Language.It]: ['fare', 'lista di controllo'],
    },
    task: {
        [api.Language.En]: ['task'],
        [api.Language.Fr]: ['tâche'],
        [api.Language.Es]: ['tarea'],
        [api.Language.Pt]: ['tarefa'],
        [api.Language.De]: ['Aufgabe'],
        [api.Language.It]: ['compito'],
    },
    headingLarge: {
        [api.Language.En]: ['h1', 'heading 1'],
        [api.Language.Fr]: ['t1', 'titre 1'],
        [api.Language.Es]: ['t1', 'título 1'],
        [api.Language.Pt]: ['c1', 'cabeçalho 1'],
        [api.Language.De]: ['ü1', 'Überschrift 1'],
        [api.Language.It]: ['h1', 'intestazione 1'],
    },
    headingMedium: {
        [api.Language.En]: ['h2', 'heading 2'],
        [api.Language.Fr]: ['t2', 'titre 2'],
        [api.Language.Es]: ['t2', 'título 2'],
        [api.Language.Pt]: ['c2', 'cabeçalho 2'],
        [api.Language.De]: ['ü2', 'Überschrift 2'],
        [api.Language.It]: ['h2', 'intestazione 2'],
    },
    headingSmall: {
        [api.Language.En]: ['h3', 'heading 3'],
        [api.Language.Fr]: ['t3', 'titre 3'],
        [api.Language.Es]: ['t3', 'título 3'],
        [api.Language.Pt]: ['c3', 'cabeçalho 3'],
        [api.Language.De]: ['ü3', 'Überschrift 3'],
        [api.Language.It]: ['h3', 'intestazione 3'],
    },
    table: {
        [api.Language.En]: ['table'],
        [api.Language.Fr]: ['tableau'],
        [api.Language.Es]: ['tabla'],
        [api.Language.Pt]: ['tabela'],
        [api.Language.De]: ['Tabelle'],
        [api.Language.It]: ['tavola'],
    },
    callout: {
        [api.Language.En]: ['callout'],
        [api.Language.Fr]: ['encadré'],
        [api.Language.Es]: ['recuadro'],
        [api.Language.Pt]: ['destaque'],
        [api.Language.De]: ['callout', 'Hinweis'],
        [api.Language.It]: ['riquadro', 'callout'],
    },
    katexBlock: {
        [api.Language.En]: ['tex', 'latex', 'equation'],
        [api.Language.Fr]: ['tex', 'latex', 'équation'],
        [api.Language.Es]: ['tex', 'latex', 'ecuación'],
        [api.Language.Pt]: ['tex', 'latex', 'equação'],
        [api.Language.De]: ['tex', 'latex', 'Gleichung'],
        [api.Language.It]: ['tex', 'latex', 'equazione'],
    },
    katexInline: {
        [api.Language.En]: ['tex', 'latex', 'equation', 'inline equation'],
        [api.Language.Fr]: ['tex', 'latex', 'équation', 'équation en ligne'],
        [api.Language.Es]: ['tex', 'latex', 'ecuación', 'ecuación en línea'],
        [api.Language.Pt]: ['tex', 'latex', 'equação', 'equação em linha'],
        [api.Language.De]: ['tex', 'latex', 'Gleichung', 'Inline-Gleichung'],
        [api.Language.It]: ['tex', 'latex', 'equazione', 'equazione in linea'],
    },
    divider: {
        [api.Language.En]: ['divider'],
        [api.Language.Fr]: ['séparateur'],
        [api.Language.Es]: ['divisor'],
        [api.Language.Pt]: ['divisor'],
        [api.Language.De]: ['Trennlinie'],
        [api.Language.It]: ['divisore'],
    },
    code: {
        [api.Language.En]: ['code'],
        [api.Language.Fr]: ['code'],
        [api.Language.Es]: ['code', 'código'],
        [api.Language.Pt]: ['code', 'código'],
        [api.Language.De]: ['code'],
        [api.Language.It]: ['codice'],
    },
    dateNext: {
        [api.Language.En]: ['next'],
        [api.Language.Fr]: ['prochain'],
        [api.Language.Es]: ['próximo'],
        [api.Language.Pt]: ['próximo'],
        [api.Language.De]: ['nächste'],
        [api.Language.It]: ['prossimo'],
    },
    image: {
        [api.Language.En]: ['image'],
        [api.Language.Fr]: ['image'],
        [api.Language.Es]: ['imagen'],
        [api.Language.Pt]: ['imagem'],
        [api.Language.De]: ['Bild'],
        [api.Language.It]: ['immagine'],
    },
    file: {
        [api.Language.En]: ['file', 'upload file'],
        [api.Language.Fr]: ['fichier', 'téléverser un fichier'],
        [api.Language.Es]: ['archivo', 'subir archivo'],
        [api.Language.Pt]: ['arquivo', 'subir arquivo'],
        [api.Language.De]: ['Datei', 'Datei hochladen'],
        [api.Language.It]: ['file', 'caricare file'],
    },
    quote: {
        [api.Language.En]: ['quote'],
        [api.Language.Fr]: ['citation'],
        [api.Language.Es]: ['cita'],
        [api.Language.Pt]: ['citação'],
        [api.Language.De]: ['Zitat'],
        [api.Language.It]: ['citazione'],
    },
    bulletList: {
        [api.Language.En]: ['bullet list'],
        [api.Language.Fr]: ['liste à puces'],
        [api.Language.Es]: ['lista con viñetas'],
        [api.Language.Pt]: ['lista com marcadores'],
        [api.Language.De]: ['Aufzählungsliste'],
        [api.Language.It]: ['lista puntata'],
    },
    numberList: {
        [api.Language.En]: ['number list'],
        [api.Language.Fr]: ['liste numérotée'],
        [api.Language.Es]: ['lista numerada'],
        [api.Language.Pt]: ['lista numerada'],
        [api.Language.De]: ['nummerierte Liste'],
        [api.Language.It]: ['lista numerata'],
    },
    dateLast: {
        [api.Language.En]: ['last'],
        [api.Language.Fr]: ['dernier'],
        [api.Language.Es]: ['último'],
        [api.Language.Pt]: ['último'],
        [api.Language.De]: ['letzte'],
        [api.Language.It]: ['ultimo'],
    },
};
