import classNames from 'classnames';
import React, { RefObject } from 'react';
import Modal from 'react-modal';
import modalStyle from '@/modalStyle';
import * as Popover from '@radix-ui/react-popover';
import useMobile from '@/hooks/useMobile';

type PopOverModalProps<T extends HTMLElement> = {
    isOpen: boolean;
    setIsOpen: (value: boolean) => void;
    children: React.ReactNode;
    anchorRef: RefObject<T>;
    className?: string;
    isMobile?: boolean;
};
function PopOverModal<T extends HTMLElement>({ isOpen, children, className, isMobile }: PopOverModalProps<T>) {
    return (
        <>
            {!isMobile && (
                <Popover.Portal>
                    <Popover.Content sideOffset={5} align="end" side="bottom">
                        <div className={className}>{children}</div>
                    </Popover.Content>
                </Popover.Portal>
            )}
            {isMobile && (
                <Modal isOpen={isOpen} style={modalStyle({ darkOverlay: true })}>
                    <div className={className}>{children}</div>
                </Modal>
            )}
        </>
    );
}

interface PropsWithClassName {
    className?: string;
}

interface PropsWithChildren {
    children: React.ReactNode;
}

PopOverModal.Frame = function Frame({ children, className }: PropsWithClassName & PropsWithChildren) {
    const isMobile = useMobile();
    return (
        <div
            className={classNames(
                'border dark:border-saga-gray-800 shadow rounded-md bg-white dark:bg-saga-gray-900 w-full',
                className,
                {
                    'mx-auto': isMobile,
                    'min-w-[30rem]': !isMobile,
                },
            )}
        >
            {children}
        </div>
    );
};

PopOverModal.Header = function Header({ children, className }: PropsWithClassName & PropsWithChildren) {
    return (
        <div className={classNames('border-b border-saga-gray-200 dark:border-saga-gray-800 p-2', className)}>
            {children}
        </div>
    );
};

PopOverModal.Hr = function Hr({ className }: PropsWithClassName) {
    return <hr className={classNames('border-saga-gray-200 dark:border-saga-gray-800', className)} />;
};

PopOverModal.Title = function Header({ children, className }: PropsWithClassName & PropsWithChildren) {
    return (
        <h1 className={classNames('text-sm uppercase text-saga-gray-500 dark:text-zinc-200', className)}>{children}</h1>
    );
};

PopOverModal.H2 = function PopOverModalH2({ children }: { children: React.ReactNode }) {
    return <h2 className="text-sm text-saga-gray-dark dark:text-zinc-200">{children}</h2>;
};

PopOverModal.Content = function Content({ children, className }: PropsWithClassName & PropsWithChildren) {
    return <div className={classNames('p-2', className)}>{children}</div>;
};

PopOverModal.ShareModalContent = function Content({ children, className }: PropsWithClassName & PropsWithChildren) {
    return <div className={classNames('px-2 pt-2 space-y-3 overflow-y-auto', className)}>{children}</div>;
};

PopOverModal.Footer = function Footer({ children, className }: PropsWithClassName & PropsWithChildren) {
    return <div className={classNames('border-t dark:border-zinc-600 p-2', className)}>{children}</div>;
};

export default PopOverModal;
