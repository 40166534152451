import React from 'react';
import { useTranslation } from 'react-i18next';
import { Collection } from '@saga/shared';

import TableMultipleFilterSelect, { ItemsFilterMode } from './TableMultipleFilterSelect';
import { CollectionIcon } from '../icons';

export default function CollectionsFilterSelect({
    selectedIds,
    onUpdateSelectedIds,
    availableCollections,
}: {
    selectedIds: ItemsFilterMode;
    onUpdateSelectedIds(ids: ItemsFilterMode): void;
    availableCollections: Pick<Collection, 'id' | 'title' | 'icon'>[];
}) {
    const { t } = useTranslation();

    const availableItems = React.useMemo(() => {
        return availableCollections.map((collection) => ({
            id: collection.id,
            title: collection.title,
            icon: <CollectionIcon icon={collection.icon} size={14} />,
        }));
    }, [availableCollections]);

    return (
        <TableMultipleFilterSelect
            selectedIds={selectedIds}
            onUpdateSelectedIds={onUpdateSelectedIds}
            availableItems={availableItems}
            selectTexts={{
                default: t('collections.page_title'),
                all: t('collections.all_collections'),
                none: t('collections.no_collections'),
                n_items: t('collections.n_collections', { count: selectedIds.length }),
                search_item_placeholder: t('collections.search_collection_placeholder'),
            }}
            Icon={<CollectionIcon size={14} icon={undefined} />}
        />
    );
}
