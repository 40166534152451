import React from 'react';

export function TemplateIcon({ className, style }: { className?: string; style?: React.CSSProperties }) {
    return (
        <svg width={14} height={14} viewBox="0 0 18 16" className={className} style={style}>
            <path
                d="M16.5 0.5C16.721 0.5 16.933 0.587797 17.0893 0.744078C17.2456 0.900358 17.3334 1.11232 17.3334 1.33333V14.6667C17.3334 14.8877 17.2456 15.0996 17.0893 15.2559C16.933 15.4122 16.721 15.5 16.5 15.5H1.50002C1.27901 15.5 1.06704 15.4122 0.910765 15.2559C0.754484 15.0996 0.666687 14.8877 0.666687 14.6667V1.33333C0.666687 1.11232 0.754484 0.900358 0.910765 0.744078C1.06704 0.587797 1.27901 0.5 1.50002 0.5H16.5ZM2.33335 6.33333V13.8333H15.6667V6.33333H2.33335ZM2.33335 4.66667H15.6667V2.16667H2.33335V4.66667Z"
                fill="currentColor"
            />
        </svg>
    );
}
