import React, { useCallback } from 'react';
import { Editor, Path, Range, Transforms } from 'slate';
import isHotkey from 'is-hotkey';
import {
    SagaElement,
    isBlockType,
    isLiveBlock,
    isCode,
    isCheckListItem,
    isTableCell,
    isVoid,
    EditorOperations,
    BlockBuilder,
    isBlockContainer,
    isAISuggestedText,
    isTitle,
    isListItem,
    isTable,
    isTableRow,
    isNodeEntry,
    RealtimeSagaEditor,
    isIndentContainer,
    isAnyListItem,
    isParagraph,
} from '..';

import i18n from 'i18next';
import { ReactEditor } from 'slate-react';

const BOLD = 'mod+b';
const ITALIC = 'mod+i';
const UNDERLINE = 'mod+u';
const CODE = 'mod+shift+c';
export const INDENT_FORWARD = 'tab';
export const INDENT = 'shift+tab';
const DELETE = 'backspace';
const SELECT_ALL = 'mod+a';
export const TICK_CHECKBOX = 'mod+enter';
const SHIFT_ENTER = 'shift+enter';
const ARROW_DOWN = 'arrowdown';
const ARROW_UP = 'arrowup';
const ARROW_LEFT = 'arrowleft';
const ARROW_RIGHT = 'arrowright';
const ENTER = 'enter';

const HOTKEYS: Record<string, (editor: RealtimeSagaEditor, event: React.KeyboardEvent) => void | 'continue'> = {
    [BOLD]: (editor) => EditorOperations.Marks.toggleMark(editor, 'bold'),
    [ITALIC]: (editor) => EditorOperations.Marks.toggleMark(editor, 'italic'),
    [SHIFT_ENTER]: (editor) => EditorOperations.Extensions.insertBreakWithShift(editor),
    [UNDERLINE]: (editor) => EditorOperations.Marks.toggleMark(editor, 'underline'),
    [CODE]: (editor) => EditorOperations.Marks.toggleMark(editor, 'code'),
    [ARROW_DOWN]: EditorOperations.Extensions.onArrowDown,
    [ARROW_UP]: EditorOperations.Extensions.onArrowUp,
    [ARROW_LEFT]: EditorOperations.Extensions.onArrowLeft,
    [ARROW_RIGHT]: EditorOperations.Extensions.onArrowRight,
    [INDENT_FORWARD]: (editor, event) => EditorOperations.Extensions.indent(editor, true, event),
    [INDENT]: (editor, event) => EditorOperations.Extensions.indent(editor, false, event),
    [TICK_CHECKBOX]: (editor) => {
        const { selection } = editor;
        if (selection) {
            const nodeEntry = Editor.node(editor, selection);
            const match = Editor.above<SagaElement>(editor, {
                at: nodeEntry[1],
                match: isCheckListItem,
            });

            if (match) {
                const [node, path] = match;
                if (isCheckListItem(node)) {
                    Transforms.setNodes(editor, { checked: !node.checked }, { at: path });
                }
            }
        }
    },
    [DELETE]: (editor) => {
        const { selection } = editor;
        if (selection) {
            const nodeEntry = Editor.node(editor, selection);

            // Forbid deletion of AISuggestedText
            if (Editor.nodes(editor, { match: isAISuggestedText }).next().value) {
                return;
            }

            const tableCellEntry = Editor.above(editor, { at: selection, match: isTableCell });
            const isWithinTableCell = isTableCell(nodeEntry) || tableCellEntry != null;

            if (
                Editor.nodes(editor, { match: isTable }).next().value &&
                !(isNodeEntry(nodeEntry, [isTable, isTableRow]) || isWithinTableCell) &&
                !window.confirm(i18n.t('editor.confirm_table_delete') ?? undefined)
            ) {
                return;
            }

            const match = Editor.above<SagaElement>(editor, {
                at: nodeEntry[1],
                match: (n) => isBlockType(n as SagaElement, [isLiveBlock, isCode]),
            });
            if (match || Range.isExpanded(selection)) {
                Transforms.delete(editor, { at: selection, hanging: true });
                Transforms.collapse(editor, { edge: 'start' });
            } else {
                Editor.deleteBackward(editor);
            }
        }
    },

    [SELECT_ALL]: (editor) => {
        const tableCell = Editor.above(editor, { match: isTableCell });
        if (tableCell) {
            Transforms.setSelection(editor, {
                anchor: Editor.start(editor, tableCell[1]),
                focus: Editor.end(editor, tableCell[1]),
            });
        } else {
            editor.savedSelection = editor.selection;
            Transforms.setSelection(editor, { anchor: Editor.start(editor, []), focus: Editor.end(editor, []) });
        }
    },
    [ENTER]: (editor) => {
        const { selection } = editor;
        if (!selection) {
            return;
        }
        // Check if the selection is at the root (path of [])
        if (selection.anchor.path.length === 0 || selection.focus.path.length === 0) {
            return;
        }

        // Ensure there's a valid parent node to avoid accessing the root's parent
        const parent = Editor.parent(editor, selection.anchor.path);
        if (!parent) {
            return;
        }

        const [node, nodePath] = Editor.above(editor, { match: isBlockContainer }) || [];
        const parentIndentContainer = Editor.above(editor, { match: isIndentContainer, mode: 'lowest' });
        const startPoint = nodePath && Editor.start(editor, nodePath);
        const children = node ? Array.from(node.children) : [];

        const [table] = Editor.nodes(editor, { match: isTable }) || [];
        const [list] = Editor.above(editor, { match: isAnyListItem }) || [];
        const [paragraph] = Editor.above(editor, { match: isParagraph }) || [];
        const [indent] = Editor.above(editor, { match: isIndentContainer }) || [];

        const checkIfParentOfListIsEmpty = parent && !list?.children[1] && list?.children[0].text === '';
        const checkIfParentOfIndentedParagraphIsEmpty =
            indent && !paragraph?.children[1] && paragraph?.children[0].text === '';

        /**
         * To maintain collapsed list containers, we need to insert list items manually when at the beginning
         * of a list item -> https://linear.app/saga-app/issue/SAGA-1915/pressing-enter-in-front-of-a-collapsed-list-item-expands-the-item
         */
        if (
            selection &&
            startPoint &&
            Range.equals({ anchor: startPoint, focus: startPoint }, selection) &&
            isListItem(children[0]) &&
            !checkIfParentOfListIsEmpty
        ) {
            Transforms.insertNodes(editor, BlockBuilder.listItem(''), {
                at: nodePath,
            });
            return;
        }

        // If multiple blocks are selected, delete them
        if (selection && !Path.equals(selection.anchor.path, selection.focus.path)) {
            Transforms.delete(editor, { at: nodePath });
            return;
        }

        // Allow pressing enter on empty title
        if (parent && isTitle(parent[0]) && !EditorOperations.SagaElement.toString(parent[0]).length) {
            Transforms.insertNodes(editor, BlockBuilder.paragraph(''), { at: Path.next(parent[1]) });
            Transforms.select(editor, Path.next(parent[1]));
            return;
        }

        // if a nested paragraph item has empty title and we press Enter
        // we use the same functionality as in lists
        if (checkIfParentOfIndentedParagraphIsEmpty && parentIndentContainer) {
            return EditorOperations.Extensions.indent(editor, false);
        }

        // If there is a bullet item , numbered item or todo item has empty title
        // and you try to press Enter, it will be deleted.
        // If nested return one level of nest when clicking enter with empty item.
        // If level is 1 enter will work like backspace

        if (checkIfParentOfListIsEmpty) {
            if (table) {
                return Editor.deleteBackward(editor);
            }

            if (!node) {
                return Editor.deleteBackward(editor);
            }

            if (parentIndentContainer) {
                return EditorOperations.Extensions.indent(editor, false);
            }

            if (isVoid(children[1].children[0])) {
                return Editor.deleteBackward(editor);
            }

            Transforms.delete(editor, { at: selection });
            ReactEditor.focus(editor);
            Transforms.insertNodes(editor, [BlockBuilder.paragraph()]);
            return;
        }

        return EditorOperations.Extensions.insertBreakIfMatch(editor, (n) => isVoid(n) && !isTableCell(n));
    },
};

const useEditorShortcuts = (editor: Editor, hotkeysDisabled: string[] = []) => {
    const onKeyDown = useCallback(
        (event: React.KeyboardEvent) => {
            // apply marks if the key event matches a hotkey
            for (const hotkey in HOTKEYS) {
                if (isHotkey(hotkey, event.nativeEvent) && !hotkeysDisabled.includes(hotkey)) {
                    const action = HOTKEYS[hotkey];

                    if (action) {
                        const result = action(editor, event);
                        if (result === 'continue') {
                            return;
                        } else {
                            event.preventDefault();
                            return;
                        }
                    } else {
                        console.warn(`Hotkey ${hotkey} has no action`);
                    }
                }
            }
        },
        [editor, hotkeysDisabled],
    );

    return { onKeyDown };
};

export default useEditorShortcuts;
