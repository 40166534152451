import * as Y from 'yjs';
import { unsafeRight } from '../io-ts-js';

import { SafeSpace } from '../types';

const findIndexByPageId = (space: SafeSpace, id: string): number => {
    const yPages = unsafeRight(space.get('pages'));

    const index = yPages.array
        .map((p) => p)
        .findIndex((yPage) => yPage instanceof Y.Map && (yPage as Y.Map<unknown>).get('id') === id);

    return index;
};

export default findIndexByPageId;
