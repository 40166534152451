import React from 'react';
import * as Y from 'yjs';
import { debugLog, isDebug } from '@/utils';
import { useContext } from 'react';
import { Unauthorized } from '@hocuspocus/common';
import {
    DocumentName,
    SpaceOperations,
    SafeDocumentContent,
    SafeSpace,
    AnyBlockItem,
    SagaLocation,
    until,
    transformBlocksToSharedType,
    CODE_VERSION,
    EmojiIcon,
} from '@saga/shared';
import { getCurrentUser } from '@/firebase';

import { HocuspocusProvider } from '@hocuspocus/provider';
import {
    RealtimeState,
    RealtimeStateWithDocument,
    getToken,
    isStateTypeWithDocument,
    isStateWithDocument,
    setupOfflineProvider,
} from '@/components/RealtimeProvider';

import { BlocksLocation } from '../../../shared/src/SagaLocation';
import { Editor, Transforms } from 'slate';
import { changeWithEditor } from '../../../shared/src/SpaceOperations';
import { throttle } from 'lodash';
import { WEBSOCKET_URL } from '@/constants';
import { useCurrentWorkspace } from '@/components/WorkspaceContext';

type DocumentContentResult<T> =
    | { type: RealtimeStateWithDocument['type']; data: T; remoteSynced: boolean }
    | { type: RealtimeState['type']; data: null };

export type DocumentStateMap = {
    [key: string]: { state: RealtimeState; cleanup: () => void; connections: number };
};

export const RealtimeDocumentContext = React.createContext<{
    loadDocument: (ids: string) => Promise<DocumentContentResult<SafeDocumentContent> | undefined>;
    unLoadDocument: (ids: string) => void;
    documentStatesMap: DocumentStateMap;
}>({
    loadDocument: () => Promise.resolve(undefined),
    unLoadDocument: () => {},
    documentStatesMap: {},
});

export function useDocumentContentRealtime() {
    return useContext(RealtimeDocumentContext);
}

export function useDocumentAwareness(documentId: string | undefined) {
    const { documentStatesMap } = useDocumentContentRealtime();

    if (!documentId) return null;

    const state = documentStatesMap[documentId]?.state;

    if (state && !isStateWithDocument(state)) return null;

    return state?.awareness;
}

export function useConnectedDocuments() {
    const { documentStatesMap } = useContext(RealtimeDocumentContext);

    const documentStates = React.useMemo(
        () => Object.values(documentStatesMap).map((b) => b.state),
        [documentStatesMap],
    );

    const states = documentStates
        .filter(isStateWithDocument)
        .map((state) => SpaceOperations.safeDocumentContentFromYMap(state.document.getMap('content')));
    return states;
}

function useDocumentProperty<T>(
    documentId: string | undefined,
    prop: string,
): { data: T | null; type: RealtimeState['type'] } {
    const { loadDocument, unLoadDocument, documentStatesMap } = useDocumentContentRealtime();

    const [trigger, forceUpdate] = React.useReducer(() => ({}), {});

    const safeContent = React.useMemo(() => {
        if (!documentId) return;
        return getDocumentContentResult(documentStatesMap, documentId);
    }, [documentStatesMap, documentId]);

    React.useEffect(() => {
        safeContent?.data?.map.observe(forceUpdate);
        return () => {
            safeContent?.data?.map.unobserve(forceUpdate);
        };
    }, [safeContent]);

    React.useEffect(() => {
        if (!documentId) return;

        loadDocument(documentId);
        return () => {
            unLoadDocument(documentId);
        };
    }, [documentId, loadDocument, unLoadDocument]);

    return React.useMemo(() => {
        trigger;
        if (safeContent && isContentResultWithData(safeContent)) {
            const yArray = safeContent.data.map.get(prop) as T;
            return { data: yArray, type: safeContent.type };
        } else {
            return { data: null, type: safeContent?.type ?? 'LOADING' };
        }
    }, [safeContent, trigger, prop]);
}

export function useDocumentYBlocks(documentId: string | undefined) {
    return useDocumentProperty<Y.Array<any>>(documentId, 'blocks');
}

export function useDocumentIcon(documentId: string | undefined) {
    return useDocumentProperty<EmojiIcon>(documentId, 'icon');
}

export function usePerformActionWithSafeContent() {
    const { loadDocument, unLoadDocument } = useDocumentContentRealtime();

    return React.useCallback(
        async (location: BlocksLocation, action: (content: SafeDocumentContent) => void) => {
            return new Promise<void>(async (resolve) => {
                const id = SagaLocation.getIdFromLocation(location);
                const safeContent = await loadDocument(id);

                if (safeContent && isContentResultWithData(safeContent)) {
                    await until(() => safeContent.data.map._dEH.l.length);
                    action(safeContent.data);

                    until(() => safeContent.remoteSynced, 5000)
                        .catch(() => {})
                        .finally(() => {
                            unLoadDocument(id);
                            resolve();
                        });
                }
            });
        },
        [loadDocument, unLoadDocument],
    );
}

export function usePerformActionWithYBlocks() {
    const performActionWithSafeContent = usePerformActionWithSafeContent();

    return React.useCallback(
        async (location: BlocksLocation, action: (blocks: Y.Array<any>) => void) => {
            return performActionWithSafeContent(location, (content: SafeDocumentContent) => {
                const yArray = content.map.get('blocks') as Y.Array<any> | undefined;
                action(yArray ?? transformBlocksToSharedType([]));
            });
        },
        [performActionWithSafeContent],
    );
}

export function usePerformBlockChangeWithEditor() {
    const performActionWithBlocks = usePerformActionWithYBlocks();

    return React.useCallback(
        async (space: SafeSpace, location: BlocksLocation, change: (editor: Editor, blocks?: Y.Array<any>) => void) =>
            performActionWithBlocks(location, (blocks) => {
                changeWithEditor(space, blocks, (editor: Editor) => change(editor, blocks));
            }),
        [performActionWithBlocks],
    );
}

export function usePerformAppendBlock() {
    const performBlockChangeWithEditor = usePerformBlockChangeWithEditor();

    return React.useCallback(
        (space: SafeSpace, location: BlocksLocation, blocks: Array<AnyBlockItem>) =>
            performBlockChangeWithEditor(space, location, (editor) => {
                Transforms.insertNodes(editor, blocks, { at: [editor.children.length - 1] });
            }),
        [performBlockChangeWithEditor],
    );
}

// Throttle getToken when multiple providers are connecting at the same time, so we don't spam firebase
const getTokenThrottled = throttle(getToken, 1000, { leading: true });

export const SpaceRealtimeDocumentProvider = ({ children }: { children: React.ReactNode }) => {
    const { id } = useCurrentWorkspace();
    return <RealtimeDocumentPovider spaceId={id}>{children}</RealtimeDocumentPovider>;
};

export const RealtimeDocumentPovider = ({ children, spaceId }: { children: React.ReactNode; spaceId: string }) => {
    const [trigger, forceUpdate] = React.useReducer(() => ({}), {});
    const stateMap = React.useRef<DocumentStateMap>({});

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const documentStatesMap = React.useMemo(() => ({ ...stateMap.current }), [trigger]);

    const setupDocumentProvider = React.useCallback(async (documentName: string) => {
        await new Promise((r) => setTimeout(r, 0));

        const yDoc = new Y.Doc();
        const offlineDocumentName = `offline-${documentName}`;
        const currentUser = getCurrentUser();
        const { id } = DocumentName.parse(documentName);

        const offlineProvider = await setupOfflineProvider(offlineDocumentName, yDoc, getCurrentUser());

        const upsertDocumentStates = (id: string, newState: RealtimeState, cleanup: () => void) => {
            stateMap.current[id] = {
                state: newState,
                cleanup,
                connections: stateMap.current[id]?.connections ?? 0,
            };
            forceUpdate();
        };

        const provider = new HocuspocusProvider({
            url: WEBSOCKET_URL,
            name: documentName,
            document: yDoc,
            broadcast: false,
            forceSyncInterval: 5000,
            parameters: { version: CODE_VERSION },
            quiet: !isDebug(),
            token: async () => {
                return getTokenThrottled(currentUser, documentName) ?? '';
            },
            onSynced: async () => {
                debugLog(`Document provider synced`);
                upsertDocumentStates(
                    id,
                    {
                        type: 'SYNCED',
                        document: yDoc,
                        documentName,
                        awareness: provider.awareness,
                        remoteSynced: provider.isSynced,
                        provider,
                    },
                    cleanup,
                );
            },
            onClose: (event) => {
                debugLog(`Document connection closed`);

                if (event.event.code === 4004 && event.event.reason === 'Member removed') {
                    upsertDocumentStates(id, { type: 'MEMBER_REMOVED' }, cleanup);
                    offlineProvider.clearData();
                    provider.destroy();
                }

                if (event.event.code === Unauthorized.code) {
                    upsertDocumentStates(
                        id,
                        {
                            type: 'CONNECTION_CLOSED',
                            document: yDoc,
                            documentName,
                            awareness: provider.awareness,
                            remoteSynced: provider.isSynced,
                            provider,
                        },
                        cleanup,
                    );
                    provider.destroy();
                }
            },
            onAuthenticated() {
                debugLog('Document authenticated');
            },
            onAuthenticationFailed() {
                debugLog('Document authentication failed');
                upsertDocumentStates(id, { type: 'AUTHENTICATION_FAILED' }, cleanup);

                // Destroy the provider to avoid trying to reconnect
                cleanup();
            },
            onDisconnect: () => {
                debugLog('Document provider disconnected');
                upsertDocumentStates(
                    id,
                    {
                        type: 'DISCONNECTED',
                        document: yDoc,
                        documentName,
                        awareness: provider.awareness,
                        remoteSynced: provider.isSynced,
                        provider,
                    },
                    cleanup,
                );
            },
            onDestroy: () => {
                debugLog('Document provider destroyed');
            },
        });

        const cleanup = () => {
            yDoc.destroy();
            provider.destroy();
            offlineProvider.destroy();
        };

        upsertDocumentStates(
            id,
            {
                type: 'SYNCED',
                document: yDoc,
                documentName,
                awareness: provider.awareness,
                remoteSynced: provider.isSynced,
                provider,
            },
            cleanup,
        );
    }, []);

    const unLoadDocument = React.useCallback((id: string) => {
        if (!stateMap.current[id]) return;

        stateMap.current[id].connections -= 1;
        if (!stateMap.current[id].connections) {
            stateMap.current[id]?.cleanup();
            delete stateMap.current[id];
            forceUpdate();
        }
    }, []);

    const loadDocument = React.useCallback(
        async (id: string) => {
            const isDocumentLoaded = () => {
                const state = stateMap.current[id]?.state;
                if (state?.type === 'LOADING') return false;

                return true;
            };

            if (!stateMap.current[id]) {
                stateMap.current[id] = { state: { type: 'LOADING' }, cleanup: () => {}, connections: 0 };
                const documentName = DocumentName.build('content', id, spaceId);
                setupDocumentProvider(documentName);
            }

            stateMap.current[id].connections += 1;

            try {
                await until(isDocumentLoaded, 10000);
            } catch (e) {
                debugLog(e);
            }

            return getDocumentContentResult(stateMap.current, id);
        },
        [setupDocumentProvider, spaceId],
    );

    return (
        <RealtimeDocumentContext.Provider value={{ loadDocument, unLoadDocument, documentStatesMap }}>
            {children}
        </RealtimeDocumentContext.Provider>
    );
};

export function isContentResultWithData<T>(
    state: DocumentContentResult<T>,
): state is { type: RealtimeStateWithDocument['type']; data: T; remoteSynced: boolean } {
    return isStateTypeWithDocument(state.type);
}

function getDocumentContentResult(stateMap: DocumentStateMap | undefined, documentId: string) {
    const state = stateMap?.[documentId]?.state;

    if (!state) {
        return { type: 'LOADING' as const, data: null };
    } else if (isStateWithDocument(state)) {
        return {
            type: state.type,
            data: SpaceOperations.safeDocumentContentFromYMap(state.document.getMap('content')),
            remoteSynced: state.remoteSynced,
        };
    }

    return { type: state.type, data: null };
}
