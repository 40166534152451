import { prompt } from '../../../shared/src/aiPrompts.json';

export default {
    translation: {
        common: {
            open_saga: 'Abrir Saga',
            popover_placeholder: 'Digite texto ou @ para',
            ai: 'IA',
            commands: 'comandos',
            and: 'e',
            or: 'ou',
            in: 'em',
            add_dotted: 'Adicionar...',
            turn_into: 'Transformar em',
            plus_template: '+ Modelo',
            plus_collection: '+ Coleção',
            plus_alias: '+ Alias',
            new_alias_placeholder: 'Digite um novo Alias...',
            create_alias: '&nbsp;— Criar Alias',
            remove_alias: 'Remover {{alias}}',
            add_template_title: 'Selecionar um modelo',
            add_template_placeholder: 'Buscar por um modelo...',
            open: 'Abrir',
            mark_as_complete: 'Marcar como concluído',
            open_in_new_tab: 'Abrir em nova aba',
            add_to_pinned: 'Adicionar aos fixados',
            add_to_favorites: 'Adicionar aos favoritos',
            remove_from_pinned: 'Remover dos fixados',
            remove_from_favorites: 'Remover dos favoritos',
            create: 'Criar',
            create_workspace_title: 'Vamos criar seu espaço de Trabalho.',
            create_workspace_description:
                'Um Espaço de Trabalho é onde suas páginas, ideias e conhecimentos se conectam e se organizam.',
            create_workspace_placeholder: 'Meu Espaço de Trabalho',
            save: 'Salvar',
            edit: 'Editar',
            cancel: 'Cancelar',
            reload: 'Recarregar',
            reset: 'Redefinir',
            logout: 'Sair',
            login: 'Entrar',
            learn_more: 'Saiba mais',
            free_plan: 'Plano Gratuito',
            upgrade: 'Atualizar',
            show_me: 'Me mostre',
            got_it: 'Entendi, obrigado.',
            dismiss: 'Descartar',
            do_this_later: 'Fazer isso depois',
            get_started: 'Começar',
            dates: 'Datas',
            link_to_collection: 'Link para uma Coleção',
            insert: 'Inserir',
            recents: 'Recentes',
            google_drive: 'Google Drive',
            linear_issues: 'Problemas no Linear',
            tasks: 'Tarefas',
            table_commands: 'Comandos de Tabela',
            mention_members: 'Mencionar Membros',
            insert_page_link_to: 'Inserir link da página para',
            link_to_page: 'Link para a Página',
            gdrive_file: 'Arquivo do Google Drive',
            linear_issue: 'Problema no Linear',
            new_template: 'Novo Modelo',
            create_template: 'Criar Modelo',
            create_template_placeholder: 'Digite um nome para o modelo...',
            edit_template: 'Editar Modelo',
            use_template: 'Usar Modelo',
            recent_templates: 'Modelos Recentes',
            create_page_from_template: 'Criar página a partir do modelo',
            page_created: 'Página Criada',
            has_been_created: 'foi criada',
            blocks_moved: 'Blocos movidos',
            selected_blocks_have_been_moved: 'Os blocos selecionados foram movidos para:',
            selected_blocks_have_been_turned: 'Os blocos selecionados foram transformados em uma nova página:',
            live_block_details: 'Um Bloco ao Vivo dos blocos selecionados foi criado em:',
            live_block_created: 'Bloco ao Vivo Criado',
            duplicate: 'Duplicar',
            move_to: 'Mover para',
            duplicate_page: 'Duplicar Página',
            duplicate_task: 'Tarefa Duplicada',
            copy_page: 'Copiar Página',
            undo: 'Desfazer',
            is_mac: '⌘ + Z',
            is_windows: 'Ctrl + Z',
            export: 'Exportar',
            delete: 'Excluir',
            update: 'Atualizar',
            continue: 'Continuar',
            upgrade_plan: 'Atualizar plano',
            see_plans: 'Gerenciar plano',
            delete_page: 'Excluir Página',
            remove_from_page: 'Remover da página',
            first_name: 'Nome',
            last_name: 'Sobrenome',
            open_workspace: 'Abrir Espaço de Trabalho',
            open_page: 'Abrir Página',
            quick_edit: 'Edição Rápida',
            open_full_page: 'Abrir página completa',
            close: 'Fechar',
            close_popup: 'Fechar Popup',
            close_entity: 'Fechar {{entity}}',
            close_modal: 'Fechar modal',
            close_quick_edit_modal: 'Fechar modal de edição rápida',
            open_notifications: 'Abrir Notificações',
            close_notifications: 'Fechar Notificações',
            close_sidebar: 'Fechar Barra Lateral',
            close_task_context_menu: 'Fechar Menu de Contexto da Tarefa',
            close_context_menu: 'Fechar Menu de Contexto',
            open_context_menu: 'Abrir Menu de Contexto',
            open_task_context_menu: 'Abrir Menu de Contexto da Tarefa',
            close_sidebar_context_menu: 'Fechar Menu de Contexto da Barra Lateral',
            open_sidebar: 'Abrir Barra Lateral',
            open_sidebar_context_menu: 'Abrir Menu de Contexto da Barra Lateral',
            open_page_context_menu: 'Abrir Menu de Contexto da Página',
            close_page_context_menu: 'Fechar Menu de Contexto da Página',
            open_image_context_menu: 'Abrir Menu de Contexto da Imagem',
            close_property_menu: 'Fechar Menu de Propriedades',
            open_property_menu: 'Abrir Menu de Propriedades',
            close_pages_context_menu: 'Fechar Menu de Contexto das Páginas',
            open_pages_context_menu: 'Abrir Menu de Contexto das Páginas',
            open_in_codeSandbox: 'Abrir no CodeSandbox',
            open_context_menu_for_column: 'Abrir Menu de Contexto para Coluna {{column}}',
            back_to_my_saga: 'Voltar para minha Saga',
            referenced_in_pages: 'Referenciado em Páginas',
            see_pages_that_reference: 'Ver páginas que referenciam o bloco:',
            remove_icon: 'Remover Ícone',
            add_icon: 'Adicionar Ícone',
            change_icon: 'Mudar Ícone',
            remove_callout: 'Remover Chamada',
            click_add_image: 'Clique para adicionar uma imagem',
            click_add_image_or_embed: 'Clique para adicionar uma imagem ou <1>incorporar a partir do URL</1>',
            image_could_not_be_loaded: 'A imagem não pôde ser carregada.',
            select_or_search: 'Selecionar ou buscar',
            text_copied: 'Texto copiado',
            copy_text: 'Copiar texto',
            prompt_copied: 'Prompt copiado',
            copy_prompt: 'Copiar prompt',
            stop: 'Parar',
            select: 'Selecionar',
            search_in_pages: 'Buscar em páginas',
            navigation: 'Navegação',
            members: 'Membros',
            pages: 'Páginas',
            collections: 'Coleções',
            actions: 'Ações',
            create_new_page: 'Criar nova página',
            create_live_block: 'Criar Bloco ao Vivo',
            create_live_block_description: 'Referenciar esta seleção em outra página',
            create_live_block_disabled: 'Criar um Bloco ao Vivo não é possível com esta seleção.',
            remove_image: 'Remover Imagem',
            is_in: 'está em',
            template: 'Modelo',
            view_references: 'Ver Referências',
            try_saga: 'Experimentar Saga',
            search: 'Buscar',
            report_page: 'Reportar Página',
            paste_as_link: 'Colar como Link',
            paste_as_preview: 'Colar como Visualização de Link',
            paste_as_google_drive: 'Colar como Link do Google Drive',
            paste_as_linear_link: 'Colar como Link do Linear',
            paste_as_text: 'Colar como texto',
            paste_as_live_block: 'Colar como Bloco ao Vivo',
            logged_in_with: 'Você está logado com',
            log_in: 'Entrar',
            gain_access:
                'com um email diferente, ou contate um proprietário deste espaço de trabalho para obter acesso.',
            highlighted_content: {
                text: 'Cor da Fonte',
                background: 'Destaque do Texto',
            },
            properties_tooltip:
                'Propriedades são campos personalizáveis, como texto, tags e números que você pode adicionar às suas páginas para fornecer um contexto mais detalhado.',
            confirm_changes: 'Confirmar mudanças',
            you: 'Você',
            mentioned_by: 'Mencionado por',
            mentioned_on: 'Mencionado em',
            copy: 'Copiar',
            all_collections: 'Todas as Coleções',
            no_collections: 'Sem Coleções',
            n_collections_other: '{{count}} Coleções',
            n_collections_one: '{{count}} Coleção',
            search_collection_placeholder: 'Pesquisar Coleção...',
        },
        inapp_notifications: {
            title: 'Saga 1.0',
            description:
                'Estamos oficialmente fora da versão beta! Leia o lançamento para mais informações sobre o que vem a seguir.',
        },
        invite_onboarding: {
            create_button: 'Criar Novo Espaço de Trabalho',
            join_button: 'Juntar-se ao Espaço de Trabalho',
            join_title: 'Junte-se ou crie um espaço de trabalho',
            invited_description: 'Você não foi convidado para nenhum Espaço de Trabalho.',
            create_space_title: 'Crie seu próprio Espaço de Trabalho.',
            invited_description_one: 'Você foi convidado para o <bold>espaço de trabalho</bold>.',
            invited_description_other: 'Você foi convidado para <bold>espaços de trabalho</bold>.',
            member_one: 'membro',
            member_other: 'membros',
        },
        editor: {
            loading_preview_dotted: 'Carregando a pré-visualização...',
            edit_link: 'Editar Link',
            link_to: 'Linkar para',
            link_copied: 'Link copiado',
            copy_Link: 'Copiar Link',
            turn_into: 'Transformar em',
            link_into_pretty_link: 'Transformar link em link bonito',
            link_into_embed: 'Transformar link em incorporação',
            move_to_page: 'Mover para a página',
            create_live_block: 'Criar Bloco ao Vivo',
            add_live_block: 'Adicionar como Bloco ao Vivo à página',
            create_template: 'Criar Modelo',
            edit_template: 'Você está editando um modelo.',
            view_templates: 'Ver Todos os Modelos',
            create_page: 'Criar Página',
            create_collection: 'Criar Coleção',
            create_task: 'Criar Tarefa',
            all_templates: 'Todos os modelos',
            create_issue_in: 'Problema em {{teamName}}',
            turn_into_link: 'Transformar em link',
            turn_into_link_preview: 'Transformar em pré-visualização de link',
            turn_into_embed: 'Transformar em incorporação',
            embed_context_menu: 'Menu de Contexto de Incorporação',
            loading_embed: 'Carregando a incorporação...',
            remove_embed: 'Remover Incorporação',
            remove_link: 'Remover link',
            link_preview_not_available: 'A pré-visualização do link não está disponível.',
            turn_into_page: 'Página <1>{{value}}</1>',
            turn_into_page_link: 'Transformar em Link da Página',
            turn_into_task: 'Tarefa <1>{{value}}</1>',
            turn_into_liner_issue: 'Problema no Linear <1>{{value}} em {{team}}</1>',
            turn_into_liner_issue_tooltip: 'Problema no Linear {{value}} em {{team}}',
            copy_link_to_block: 'Copiar link para o bloco',
            delete_block: 'Excluir bloco',
            block_deleted: 'Bloco excluído',
            quick_edit: 'Edição rápida',
            empty_page: 'Página Vazia',
            format_text: 'Texto',
            format_todo: 'Lista de Tarefas',
            format_task: 'Tarefa',
            format_heading_l: 'Título — Grande',
            format_heading_m: 'Título — Médio',
            format_heading_s: 'Título — Pequeno',
            format_ul: 'Pontos',
            format_ol: 'Lista Numerada',
            format_img: 'Imagem',
            format_file: 'Enviar Arquivo',
            format_table: 'Tabela',
            format_callout: 'Chamada',
            format_quote: 'Citação',
            format_divider: 'Divisor',
            format_code: 'Código',
            format_equation: 'Equação',
            format_inline_equation: 'Equação Inline',
            suffix_create_task: '— Criar Tarefa',
            suffix_create_issue_in: '— Problema em {{team}}',
            insert_page_link_to: 'Inserir Link da Página para {{title}}',
            insert_recent_page_link_to: 'Inserir Link Recente da Página para {{title}}',
            insert_column_left: 'Inserir Coluna à Esquerda',
            insert_column_right: 'Inserir Coluna à Direita',
            insert_row_above: 'Inserir Linha Acima',
            insert_row_below: 'Inserir Linha Abaixo',
            placeholder: {
                h1: 'Título 1',
                h2: 'Título 2',
                h3: 'Título 3',
                task: 'Tarefa',
                list: 'Lista',
                quote: 'Citação',
                enter_text: 'Digite texto ',
                enter_text_dotted: 'Digite texto...',
            },
            confirm_table_delete: 'Tem certeza de que deseja excluir a tabela selecionada?',
            file_upload: {
                click_to_upload_file: 'Clique para enviar um arquivo',
                uploading: 'Enviando...',
                download_file: 'Baixar Arquivo',
                remove_file: 'Remover Arquivo',
                close_file_context_menu: 'Fechar Menu de Contexto do Arquivo',
                open_file_context_menu: 'Abrir Menu de Contexto do Arquivo',
                max_file_size_error: 'O tamanho máximo do arquivo é {{size}}.',
            },
            actions: 'Ações',
        },
        update_references_modal: {
            title: 'Atualizar links para esta página?',
            description_one:
                'Renomear esta página de <span_red>{{-previousTitle}}</span_red> para <span_yellow>{{-newTitle}}</span_yellow> quebrará <span_bold>{{count}}</span_bold> link para esta página. Você deseja atualizá-los?',
            description_other:
                'Renomear esta página de <span_red>{{-previousTitle}}</span_red> para <span_yellow>{{-newTitle}}</span_yellow> quebrará <span_bold>{{count}}</span_bold> links para esta página. Você deseja atualizá-los?',
            select_all: 'Selecionar Todos',
            deselect_all: 'Desmarcar Todos',
            update_links: 'Atualizar Links',
            update_link_one: 'Atualizar {{count}} link',
            update_link_other: 'Atualizar {{count}} links',
            dont_update: 'Não atualizar',
        },
        updated_title_notice: {
            outdated_link_one: 'Há <bold>{{count}}</bold> link desatualizado para esta página.',
            outdated_link_other: 'Há <bold>{{count}}</bold> links desatualizados para esta página.',
            review: 'Revisar',
            update_all: 'Atualizar todos',
        },
        ai: {
            title: 'Saga IA',
            ask_ai: 'Perguntar à IA',
            ask_ai_anything_mentions_dotted: 'Pergunte à IA...',
            ask_ai_anything_dotted: 'Pergunte à IA...',
            rewrite: 'Reescrever',
            discard: 'Descartar',
            rerun: 'Executar novamente',
            keep: 'Manter',
            insert_below: 'Inserir abaixo',
            replace: 'Substituir',
            generating_dotted: 'Gerando...',
            searching_space_dotted: 'Procurando no espaço de trabalho...',
            searching_page_dotted: 'Procurando na página...',
            too_many_requests: 'Você enviou muitas solicitações. Tente novamente em alguns segundos.',
            too_many_prompt_tokens:
                'Você atingiu o comprimento máximo de conversa para esta resposta da IA. <1>Saiba mais</1>',
            reduce_prompt_error: 'Por favor, encurte o aviso para um máximo de {{max_length}} caracteres',
            restricted: 'Você atingiu a cota de uso justo mensal para a Saga IA. <1>Saiba mais</1>',
            issue_with_generating: 'Houve um problema ao gerar seu texto.',
            edit_selection: 'Editar seleção',
            change_tone: 'Mudar tom',
            translate: 'Traduzir',
            draft: 'Rascunho',
            discard_response: 'Você deseja descartar a resposta da IA?',
            my_prompts: 'Meus Comandos',
            my_commands: 'Meus Comandos',
            search_or_select: 'Pesquisar ou selecionar',
            mention: 'Mencione uma página ou tarefa',
            suggestion: {
                rewrite: 'Reescrever',
                continue: 'Continuar escrevendo',
                summarize: 'Resumir',
                minimize: 'Tornar mais curto',
                maximize: 'Tornar mais longo',
                explain: 'Explicar',
                fix: 'Corrigir ortografia & gramática',
                tone_concise: 'Conciso',
                tone_professional: 'Profissional',
                tone_casual: 'Informal',
                tone_funny: 'Divertido',
                translate_en: 'Inglês',
                translate_es: 'Espanhol',
                translate_fr: 'Francês',
                translate_de: 'Alemão',
                translate_pt: 'Português',
                translate_it: 'Italiano',
                translate_nl: 'Holandês',
                translate_zh: 'Chinês',
                translate_ko: 'Coreano',
                translate_ja: 'Japonês',
                translate_ua: 'Ucraniano',
                translate_ru: 'Russo',
                brainstorm: 'Gerar ideias',
                brainstorm_about: prompt.brainstorm_about.pt,
                outline: 'Esboço',
                outline_about: prompt.outline_about.pt,
                email: 'E-mail',
                email_about: prompt.email_about.pt,
                meeting: 'Agenda de reunião',
                meeting_about: prompt.meeting_about.pt,
                blog: 'Post de blog',
                blog_about: prompt.blog_about.pt,
                social: 'Postagem em mídia social',
                social_about: prompt.social_about.pt,
                website: 'Texto para site',
                website_about: prompt.website_about.pt,
                cover_letter: 'Carta de apresentação',
                cover_letter_about: prompt.cover_letter_about.pt,
                press: 'Comunicado de imprensa',
                press_about: prompt.press_about.pt,
                job: 'Descrição de emprego',
                job_about: prompt.job_about.pt,
                essay: 'Ensaio',
                essay_about: prompt.essay_about.pt,
            },
            chat: {
                tooltip: 'Abrir o chat de IA',
                button: 'Chat de IA',
                initial: {
                    greeting: 'Hey {{name}}, como posso ajudar?',
                    bullet1:
                        'Converse comigo para explorar tópicos, responder perguntas ou criar conteúdo a partir das suas notas do Saga.',
                    bullet2: 'Pergunte qualquer coisa e eu usarei suas notas para responder.',
                    bullet3: 'Quanto mais você adicionar ao Saga, mais posso ajudar!',
                },
                new_thread: 'Novo thread',
                history: 'Histórico',
                no_threads: 'Você ainda não tem nenhum thread. Comece um novo para começar.',
            },
        },
        errors: {
            cant_open_page: 'Parece que estamos tendo problemas para abrir esta página no Saga.',
            something_went_wrong: 'Algo deu Errado',
            something_went_wrong_description: 'Ops, isso não era esperado.\nPor favor, tente novamente.',
            something_went_wrong_try_again:
                '🤔 Algo deu errado, Por favor, tente novamente\nSe o problema persistir, entre em contato conosco',
            connection_lost_title: 'Conexão Perdida',
            connection_lost_description:
                'Sua conexão foi fechada devido à inatividade.\nPor favor, recarregue o Saga para reconectar.',
        },
        side_by_side: {
            open: 'Abrir Lado a Lado',
            tooltip: 'Abrir a página ao lado da página atual',
            open_in_new_tab: 'Abrir em uma nova aba',
            copy_link: 'Copiar link para a área de transferência',
        },
        time: {
            years_ago: 'há {{years}} anos',
            months_ago: 'há {{months}} meses',
            weeks_ago: 'há {{weeks}} semanas',
            days_ago: 'há {{days}} dias',
            date: 'Data',
            today: 'Hoje',
            yesterday: 'Ontem',
            tomorrow: 'Amanhã',
            last_year: 'Ano passado',
            last_week: 'Semana passada',
            last_month: 'Mês passado',
            next_period: 'Próximo {{label}} - {{date}}',
            last_period: 'Último {{label}} - {{date}}',
        },
        tutorial: {
            finishButton: 'Iniciar sua Saga',
            writeWthEase: {
                title: 'Escreva com facilidade',
                description: 'O editor rápido e simples da Saga ajuda você a criar notas e documentos bonitos.',
            },
            autolink_pages: {
                title: 'Autolink Páginas',
                description:
                    'O Saga reconhece quando você menciona um título de página e cria automaticamente um link.',
            },
            autocomplete_menu: {
                title: 'O Menu de Autocompletar',
                description:
                    '<1>Insira blocos e crie links com o menu de autocompletar.</1><2>Experimente com @ ou / no editor.</2>',
            },
            saga_ai: {
                title: 'Saga IA',
                description:
                    'O assistente inteligente responde perguntas, gera conteúdo e ajuda você a trabalhar mais rápido.',
            },
            unified_search: {
                title: 'Pesquisa Unificada',
                description:
                    '<1>Navegue ou pesquise pelo espaço de trabalho rapidamente.</1><2>Pressione {{searchHeading}} para testar.</2>',
            },
        },
        onboarding: {
            complete_signup: 'Complete o cadastro',
            start_your_saga: 'Vamos começar sua Saga',
            i_agree_with_toc: 'Eu concordo com a <1>Política de Privacidade</1> e os <2>Termos de Serviço</2> do Saga',
            create_first_workspace: 'Crie seu primeiro espaço de trabalho',
            create_workspace: 'Criar seu espaço de trabalho',
            create_workspace_description:
                'Um espaço de trabalho é onde suas páginas, ideias e conhecimento se conectam e se organizam.',
            what_is_this_workspace_for: 'Para que é este espaço de trabalho?',
            usecase_personalNotes: 'Notas pessoais',
            usecase_teamWork: 'Trabalho em equipe',
            open_workspace: 'Abrir Espaço de Trabalho',
            what_is_this_workspace: 'Para que é este espaço de trabalho?',
            login: {
                title: 'Vamos começar sua Saga',
                with_google: 'Continuar com Google',
                with_email: 'Continuar com Email',
                link_has_expired:
                    '🤔 Seu link expirou, ou você está tentando fazer login de um dispositivo diferente.\n\nPor favor, faça o login novamente 👇',
                toc: 'Ao clicar em "Continuar com Google/Email" acima, você concorda com nossa <1>Política de Privacidade</1> e <2>Termos de Serviço</2>.',
                info: 'Nós vamos fazer o login ou criar uma conta se você ainda não tiver uma.',
                desktop_app_button: 'Baixe o aplicativo para desktop',
            },
            magic_link_login: {
                title: '📨 Verifique sua caixa de entrada',
                subtitle: 'Acabamos de enviar um link de login para este endereço de email:',
                enter_email: 'Digite seu endereço de email...',
                subtitle_email_for_join:
                    'Acabamos de enviar um link de login para este endereço de email para se juntar ao espaço de trabalho:',
                welcome_to: 'Bem-vindo(a) ao {{-workspace}}',
                welcome_to_page: 'Bem-vindo à {{-page}}',
                close_this_tab: 'Você pode fechar esta aba agora.',
                invite_for_join:
                    'Este é um convite para <1>{{email}}</1>.\nFaça login com o mesmo endereço para aceitá-lo.',
                invite_not_active: 'Este convite não está mais ativo',
                request_new_invite_short:
                    'Contate um proprietário deste espaço de trabalho e solicite um novo convite.',
                request_new_page_invite_short: 'Contate um administrador desta página e solicite um novo convite.',
                not_have_access: 'Você não tem acesso a este espaço de trabalho',
                not_have_page_access: 'Você não tem acesso a esta página',
                logged_in_with_different_email:
                    'Você está logado com <1>{{email}}</1>. <2>Faça login</2> com um email diferente ou contate um proprietário deste espaço de trabalho para solicitar acesso.',
                logged_in_with_different_email_page:
                    'Você está logado com <1>{{email}}</1>. <2>Faça login</2> com um email diferente ou contate um administrador desta página para solicitar acesso.',
                request_new_invite:
                    'Contate um proprietário deste espaço de trabalho e solicite um novo convite.\nVolte para a <1>Página Inicial</1> e crie seu próprio espaço de trabalho em vez disso',
            },
        },
        help: {
            give_feedback: 'Enviar Feedback',
            give_feedback_tooltip:
                '🚀 Você é um dos pioneiros do Saga (entrou em {{userCreatedDate}}). Estamos trabalhando duro para enviar melhorias e novos recursos na velocidade da luz, se você tiver feedback para nós, envie-nos um email para feedback@saga.so',
            contact_us: 'Contate-nos',
            join_slack: 'Junte-se no Slack',
            join_discord: 'Junte-se no Discord',
            youtube_channel: 'Canal do Youtube',
            tweet_us: 'Tweete para nós',
            open_website: 'Abrir Site',
            help_guide: 'Guia de Ajuda',
            start_tutorial: 'Iniciar tutorial',
        },
        top_menu: {
            search: 'Pesquisar...',
            searching: 'Pesquisando...',
            searching_for: 'Pesquisando por:',
            search_dotted: 'Pesquisar...',
            search_shortcut: 'Pesquisar Ctrl/Cmd + K',
            add_page_to_favorites: 'Adicionar página aos favoritos',
            add_collection_to_favorites: 'Adicionar coleção aos favoritos',
            add_task_to_favorites: 'Adicionar tarefa aos favoritos',
            add_task_view_to_favorites: 'Adicionar visualização de tarefa aos favoritos',
            add_page_view_to_favorites: 'Adicionar visualização de página aos favoritos',
            add_page_to_pinned: 'Adicionar Página aos Fixados',
            add_collection_to_pinned: 'Adicionar Coleção aos Fixados',
            add_task_to_pinned: 'Adicionar Tarefa aos Fixados',
            add_task_view_to_pinned: 'Adicionar Visualização de Tarefa aos Fixados',
            add_page_view_to_pinned: 'Adicionar Visualização de Página aos Fixados',
            search_for_or_create_new: 'Pesquisar ou criar novo',
            search_for_collection_label: 'Selecionar ou criar coleção',
            create_collection: '— Criar Coleção',
            create_page: '— Criar nova página',
            close_search_results: 'Fechar Resultados da Pesquisa',
            share: {
                label: 'Compartilhar',
                share_with: 'Compartilhado com membros do espaço de trabalho',
                copy_link: 'Copiar link',
                copy_link_tooltip: 'Usando link público, a página pode ser acessada por qualquer pessoa.',
                copy_public_link: 'Copiar link público',
                copy_public_link_tooltip:
                    'Usando link privado, a página só pode ser acessada por você ou outros membros do espaço de trabalho.',
                link_copied: 'Link Copiado!',
                members_count: 'membros de {{-workspaceTitle}}',
                share_count_zero: 'Compartilhado apenas com você',
                share_count_one: 'Compartilhado apenas com você',
                share_count_other: 'Compartilhado com <1>{{membersCount}} pessoas</1>.',
                manage: 'Gerenciar',
                manage_members: 'Gerenciar membros',
                view_members: 'Ver membros',
                publish: 'Publicar',
                page_not_published: 'Publique esta Página, então qualquer pessoa com o link pode <1>visualizá-la</1>.',
                page_published: 'Qualquer pessoa com o link pode <1>visualizar esta Página</1>.',
                workspace_not_published:
                    'Publique todo o Espaço de Trabalho, então qualquer pessoa pode <1>visualizar todas as páginas</1> dentro dele',
                workspace_published:
                    'Qualquer pessoa com o link pode <1>visualizar todas as páginas</1> dentro deste Espaço de Trabalho.',
                copying_page: 'Permitir Copiar Página',
                members_one: '1 membro',
                members_other: '{{count}} membros',
                copying_page_title: 'Escolha um espaço de trabalho',
                copying_page_sub_title: 'A página será duplicada em:',
                copying_page_toast_success: 'Página duplicada com sucesso!',
                copying_workspace: 'Permitir Copiar Espaço de Trabalho',
                copying_workspace_toggle: 'Qualquer pessoa com o link pode copiar e duplicar este espaço de trabalho.',
                copying_page_disabled_tooltip:
                    'Você é um visualizador neste espaço de trabalho e não pode copiar esta página.',
                invite_members: 'Convidar novos membros',
                share_with_members: 'Compartilhado com membros do espaço de trabalho',
                private_access: 'Acesso Privado',
                members_with_access: 'Membros com acesso à página',
                guests_with_access: 'Convidados com acesso à página',
                permission_admin_description: 'Pode deletar a página ou gerenciar permissões da página',
                permission_viewer_description: 'Pode visualizar o conteúdo da página.',
                permission_editor_description: 'Pode editar e deletar o conteúdo da página',
                invite_guest: 'Digite o email para convidar um convidado...',
                type_email: 'Digite o email...',
            },
        },
        move_page: {
            move_modal_title: 'Escolha um espaço de trabalho',
            move_modal_sub_title: 'Mover página para:',
            toast_success: 'Página movida com sucesso!',
            confirm_modal_title:
                'Alguns conteúdos e configurações, como links e permissões, podem não funcionar na página movida.',
            confirm_modal_content_left: 'A página original será arquivada sem alterações.',
            confirm_modal_content_right: 'Um duplicado será criado no espaço de trabalho selecionado.',
            confirm_changes: 'Confirmar movimento',
        },
        move_task: {
            move_modal_title: 'Escolha um espaço de trabalho',
            move_modal_sub_title: 'Mover tarefa para:',
            toast_success: 'Tarefa movida com sucesso!',
            confirm_modal_title:
                'Alguns conteúdos e configurações, como links e permissões, podem não funcionar na tarefa movida.',
            confirm_modal_content_left: 'A tarefa original será arquivada sem alterações.',
            confirm_modal_content_right: 'Um duplicado será criado no espaço de trabalho selecionado.',
            confirm_changes: 'Confirmar movimento',
        },

        sidebar: {
            new_page: 'Nova página',
            create_page: 'Criar Página',
            create_collection: 'Criar Coleção',
            new_workspace: 'Novo Espaço de Trabalho',
            open_template_menu: 'Abrir Menu de Modelos',
            pages: 'Páginas',
            tasks: 'Tarefas',
            settings: 'Configurações',
            pinned: 'Fixado',
            favorites: 'Favoritos',
            logout: 'Sair',
            collections: 'Coleções',
            shared: 'Compartilhado comigo',
            notifications: {
                dont_have_notifications: 'Você ainda não tem notificações.',
                accept: 'Aceitar',
                manage: 'Gerenciar',
                invite_message: '{{name}} convidou você para se juntar ao espaço de trabalho',
                user_joined: '{{name}} entrou no seu espaço de trabalho',
                user_left: '{{name}} saiu do seu espaço de trabalho',
                user_removed: '{{name}} removeu você do espaço de trabalho',
                go_to_workspace: 'Ir para o espaço de trabalho',
                go_to_page: 'Ir para a Página',
                go_to_task: 'Ir para a Tarefa',
                load_more: 'Carregar mais',
                mentioned_you: 'mencionou você em',
                assigned_you: 'atribuiu você a',
                unassigned_you: 'desatribuiu você de',
                invite_page_message: 'convidou você para a página dele',
            },
            upgrade_plan_modal: {
                title: 'Atualize seu plano',
                description:
                    'Para desbloquear espaços de trabalho ilimitados e ganhar acesso a ferramentas avançadas de colaboração e recursos super adicionais que levarão seu trabalho para o próximo nível.',
            },
        },
        filters: {
            title: 'Título',
            az: 'A → Z',
            za: 'Z → A',
            created: 'Criado',
            updated: 'Atualizado',
            creators: {
                all_creators: 'Todos os criadores',
                no_creators: 'Nenhum criador',
                n_creators_other: '{{count}} criadores',
                n_creators_one: '{{count}} criador',
                search_creator_placeholder: 'Buscar membros...',
            },
        },
        pages: {
            title_pages: 'Páginas',
            title_deleted_pages: 'Páginas Excluídas',
            title_deleted: 'Excluído',
            title_templates: 'Modelos',
            title_shared: 'Compartilhado',
            title_private: 'Privado',
            title_public: 'Público',
            fewest_items_first: 'Menos itens primeiro',
            most_items_first: 'Mais itens primeiro',
            collection: 'Coleção',
            fewest_words_first: 'Menos palavras primeiro',
            most_words_first: 'Mais palavras primeiro',
            word_count: 'Contagem de Palavras',
            oldest_first: 'Mais antigos primeiro',
            newest_first: 'Mais novos primeiro',
            close_pages: 'Fechar Páginas',
            empty_state_deleted: 'Você não tem páginas excluídas.',
            empty_state_non_deleted: 'Adicione páginas para vê-las aqui.',
            empty_state_templates: 'Adicione modelos para vê-los aqui.',
            empty_state_shared: 'Você não tem nenhuma página compartilhada com você.',
            empty_state_private: 'Você não tem páginas privadas.',
            empty_state_public: 'Você não tem páginas públicas.',
            enter_page_name: 'Digite o nome da página',
            favourite_untitled: 'Sem Título',
            delete_all_forever: 'Excluir tudo para sempre',
            delete_all_page: 'Tem certeza de que deseja excluir todas as páginas? Esta ação não pode ser desfeita.',
            delete_page_toast_title: 'Página está excluída',
            delete_page_toast_description:
                'Páginas excluídas são armazenadas no Arquivo até serem excluídas permanentemente.',
            history: {
                history: 'História',
                current: 'Atual',
                versions_count: '({{number}} versões)',
                versions: 'Versões',
                upgrade_required: 'Atualização necessária',
                upgrade_modal: {
                    title: 'Atualizar plano',
                    description:
                        'Atualize para um plano pago para acessar o histórico de versões com mais de 7 dias e desbloquear outros recursos premium.',
                },
            },
            mode_dropdown: {
                pages: 'Páginas',
                templates: 'Modelos',
                deleted_pages: 'Páginas Excluídas',
            },
            new_page_placeholder: 'Nova página',
            new: {
                create: 'Criar',
                label_page: 'Nova Página',
                placeholder_page: 'Digite um novo título de página...',
                label_template: 'Novo Modelo',
                placeholder_template: 'Digite um novo título de página...',
            },
            headers: {
                title: 'Título',
                collection: 'Coleção',
                word_count: 'Contagem de Palavras',
                created: 'Criado',
                updated: 'Atualizado',
                shared_by: 'Compartilhado por',
                role: 'Papel',
                creator: 'Criador',
            },
            deleted_page: 'Esta página foi excluída',
            restore: 'Restaurar',
            restore_page: 'Restaurar página',
            delete_forever: 'Excluir para sempre',
        },
        references: {
            label: 'Referências',
            tooltip: 'As referências mostram onde uma página foi mencionada em todo o seu espaço de trabalho.',
            result_one: '1 resultado',
            result_other: '{{count}} resultados',
            no_results: 'Nenhuma referência pôde ser encontrada para esta página.',
            no_results_search_panel: 'Nenhum resultado pôde ser encontrado. Tente outra pesquisa.',
        },
        table_of_content: {
            label: 'Tabela de Conteúdo',
        },
        properties: {
            label: 'Propriedades',
        },
        page_info: {
            label: 'Informações da Página',
            creator: 'Criador',
            created: 'Criado',
            updated: 'Atualizado',
            word_count: 'Contagem de Palavras',
            page_settings: 'Configurações da Página',
            autolink_pages_label: 'Auto-link páginas',
            autolink_pages_description: 'Auto-link esta página quando seu título for mencionado em outras páginas',
            autolink_headings_label: 'Auto-link títulos',
            autolink_headings_description: 'Auto-link títulos desta página quando forem mencionados em outras páginas',
            full_width_label: 'Página de Largura Total',
            full_width_description: 'Fazer a página ser exibida em largura total.',
        },
        tasks: {
            title: 'Tarefas',
            new_task: 'Nova Tarefa',
            new_task_placeholder: 'Digite um título para a tarefa',
            new_task_title_placeholder: 'Nova tarefa',
            new_task_editor_placeholder: 'Digite o título da tarefa',
            createdAt: 'Criado',
            updatedAt: 'Atualizado',
            completedDate: 'Concluído',
            due_date: 'Data de Vencimento',
            no_due_date: 'Sem data de vencimento',
            sort_asc: 'Ascendente',
            sort_desc: 'Descendente',
            sorted_by: 'Ordenado por',
            task_title: 'Título da Tarefa',
            referenced: 'Referenciado em',
            assignee: 'Responsável',
            no_assignee: 'Sem responsável',
            priority: 'Prioridade',
            no_priority: 'Sem prioridade',
            priority_all: 'Todas as Prioridades',
            priority_high: 'Alta',
            priority_medium: 'Média',
            priority_low: 'Baixa',
            priority_unprioritized: 'Sem prioridade',
            add_assignee_placeholder: 'Digite um nome...',
            incomplete_tasks: 'Tarefas Incompletas',
            incomplete_tasks_short: 'Incompletas',
            completed_tasks: 'Tarefas Concluídas',
            completed_tasks_short: 'Concluídas',
            all_tasks: 'Todas as Tarefas',
            all_tasks_short: 'Todas',
            all_assignees: 'Todos os Responsáveis',
            not_assigned: 'Não atribuído',
            close_task_one: 'Fechar Tarefa',
            close_task_other: 'Fechar Tarefas',
            add_task_below: 'Adicionar tarefa abaixo',
            delete_task: 'Excluir Tarefa',
            confirm_delete_task:
                'Tem certeza de que deseja excluir esta tarefa? Ela será excluída de todo o espaço de trabalho.',
            create_task_to_see_them: 'Crie Tarefas para vê-las aqui.',
            status_open: 'A Fazer',
            status_in_progress: 'Em Progresso',
            status_done: 'Concluído',
            status: 'Status',
            archived: 'Arquivado',
            deleted_task: 'Esta tarefa está excluída',
            restore_task: 'Restaurar tarefa',
            delete_all_tasks: 'Tem certeza de que deseja excluir todas as tarefas? Esta ação não pode ser desfeita.',
            delete_task_toast_title: 'Tarefa está excluída',
            delete_task_toast_description:
                'Tarefas excluídas são armazenadas no Arquivo até serem excluídas permanentemente.',
            new_label_placeholder: 'Digite um nome para a etiqueta...',
            select_or_create_label: 'Criar nova ou selecionar',
            add_new_label: 'Adicionar nova etiqueta',
            no_label: 'Sem etiqueta',
            no_labels: 'Sem etiquetas',
            n_labels_one: '{{count}} etiqueta',
            n_labels_other: '{{count}} etiquetas',
            all_labels: 'Todas as etiquetas',
            table_view: 'Visualização de Tabela',
            kanban_view: 'Visualização de Quadro',
        },
        collections: {
            page_title: 'Coleções',
            filter_placeholder: 'Filtrar por palavra-chave',
            add_to_collection: 'Adicionar à Coleção',
            add_page_to_collection: 'Adicionar Página à Coleção Interna',
            delete_collection: 'Excluir Coleção',
            add_parent_collection: 'Adicionar coleção pai',
            type_page_task_or_collection: 'Digite uma Página/Tarefa ou Coleção...',
            select_create_new: 'Selecionar ou criar nova',
            new_collection: 'Nova Coleção',
            new_collection_placeholder: 'Digite uma nova Coleção...',
            empty_state_message: 'Crie Coleções para vê-las aqui.',
            sort: 'Ordenar',
            folder_view: 'Visualização de Pasta',
            tag_view: 'Visualização de Tag',
            pages: 'Páginas',
            sub_collections: 'Subcoleções',
            oldest_first: 'Mais antigos primeiro',
            newest_first: 'Mais novos primeiro',
            fewest_first: 'Menos páginas primeiro',
            most_first: 'Mais páginas primeiro',
            sub_fewest_first: 'Menos primeiro',
            sub_most_first: 'Mais primeiro',
            close_collections: 'Fechar Coleções',
            empty_state: 'Adicione Páginas ou Coleções a esta Coleção para vê-las aqui.',
        },
        views: {
            save_view: 'Salvar',
            save_view_name_placeholder: 'Nome da vista ...',
            create_new_button: 'Criar nova vista',
            update_button: 'Atualizar vista',
            delete_view: 'Excluir vista',
        },
        settings: {
            sidebar: {
                account: 'Conta',
                title: 'Configurações',
                profile: 'Perfil',
                language: 'Idioma',
                appearance: 'Aparência',
                editor: 'Editor',
                invites: 'Convites',
                integrations: 'Integrações',
                general: 'Geral',
                members: 'Membros',
                plans: 'Planos',
                billing: 'Faturamento',
                import_export: 'Importar & Exportar',
            },
            profile: {
                title: 'Perfil',
                sub_title: 'Gerencie seu perfil no Saga',
                your_email: 'Seu Email',
            },
            language: {
                title: 'Idioma',
                sub_title: 'Altere o idioma usado na interface do usuário.',
                language: 'Idioma',
                language_change_title: 'Atualizar idioma',
                language_change_confirmation: 'Tem certeza de que deseja atualizar o idioma? O Saga será recarregado!',
                language_changed_notification: 'Idioma atualizado',
            },
            appearance: {
                title: 'Aparência',
                sub_title: 'Personalize a aparência do Saga.',
                theme: 'Tema',
                show_icons: 'Mostrar Ícones em Links de Página Inline',
                mode_auto: 'Modo Automático',
                mode_light: 'Modo Claro',
                mode_dark: 'Modo Escuro',
                start_week_on_monday: 'Começar a semana na segunda-feira',
                font_default: 'Padrão',
                interface_font: 'Fonte da Interface',
                text_font: 'Fonte do Texto',
                example_text: 'Texto de exemplo para testar sua fonte',
            },
            editor: {
                title: 'Editor',
                sub_title: 'Personalize sua experiência de edição.',
                spellcheck: 'Verificação ortográfica',
            },
            invite: {
                title: 'Convites do Espaço de Trabalho',
                sub_title: 'Veja e aceite todos os seus convites do espaço de trabalho',
                no_invites: 'Você não tem convites do espaço de trabalho no momento.',
                invite_members_to: 'Convidar membros',
                max_invites_reached:
                    '<bold>{{maxInvitedMembers}}/{{totalMembers}}</bold> convites gratuitos restantes. <upgrade>Atualize</upgrade> o plano para convites ilimitados',
                accept_invite: 'Aceitar Convite',
                invite_accepted: 'Convite Aceito',
                owned_by: 'propriedade de <1>{{ownerEmail}}</1>',
            },
            page_invite: {
                title: 'Convites de página',
                sub_title: 'Veja e aceite todos os seus convites de página',
                no_invites: 'Você não tem convites de página no momento.',
            },
            integrations: {
                title: 'Integrações',
                sub_title: 'Integre outras ferramentas com o Saga',
                remove_integration: 'Tem certeza de que deseja remover esta integração?',
                google_drive: {
                    title: 'Google Drive',
                    description: 'Pesquise no seu drive e mencione arquivos em páginas e tarefas. <1>Saiba mais</1>',
                    button_connect: 'Conectar ao Google Drive',
                    button_disconnect: 'Remover Integração',
                    last_updated: 'Última atualização {{date}}',
                },
                linear: {
                    title: 'Linear',
                    description:
                        'Pesquise no Linear e mencione problemas do seu espaço de trabalho no Linear. <1>Saiba mais</1>',
                    button_connect: 'Conectar ao Linear',
                    button_disconnect: 'Remover Integração',
                },
            },
            general: {
                title: 'Geral',
                sub_title: 'Gerencie as configurações gerais do seu espaço de trabalho',
                workspace_name: 'Nome do Espaço de Trabalho',
                icon: 'Ícone',
                icon_description: 'Altere a cor do ícone do espaço de trabalho.',
                embeddings: 'Incorporações do Espaço de Trabalho',
                embeddings_description: 'Gere incorporações para este Espaço de Trabalho.',
                copy_workspace: 'Copiar Espaço de Trabalho',
                copy_workspace_description:
                    'Todas as páginas, coleções e configurações deste Espaço de Trabalho serão copiadas para um novo Espaço de Trabalho.',
                delete_workspace: 'Excluir Espaço de Trabalho',
                delete_workspace_description:
                    'Excluir um Espaço de Trabalho excluirá todos os seus dados. Isso não pode ser desfeito.',
                delete_workspace_confirmation: 'Tem certeza de que deseja excluir este Espaço de Trabalho?',
                leave_workspace: 'Sair do Espaço de Trabalho',
                leave_workspace_confirmation: 'Tem certeza de que deseja sair deste Espaço de Trabalho?',
                autolink: 'Linkagem Automática',
                autolink_description:
                    'Quando habilitado, o Saga automaticamente cria links para os títulos das páginas dentro das páginas e tarefas.',
            },
            members: {
                title: 'Membros',
                sub_title: 'Convide outros para este Espaço de Trabalho',
                add_member: 'Adicionar um Membro',
                remove_member: 'Remover Membro',
                remove_member_confirmation: 'Tem certeza sobre remover {{name}}?',
                remove_confirm: 'Confirmar remoção',
                remove_member_toast_success: 'Membro removido',
                pending_invite: 'Convite Pendente',
                revoke_invite: 'Revogar Convite',
                revoke_invite_confirmation: 'Tem certeza sobre revogar o convite?',
                revoke_confirm: 'Confirmar revogação',
                revoke_invite_toast_success: 'Convite revogado',
                paid_feature: 'Recurso Pago',
                role_admin: 'Admin',
                role_page_admin: 'Administrador da página',
                role_no_access: 'Sem acesso',
                role_admin_tooltip:
                    'Este membro pode <1>criar, editar e excluir conteúdo</1> no espaço de trabalho e <1>convidar outros</1>. Eles também podem <1>gerenciar as configurações</1> e <1>excluir o espaço de trabalho</1>.',
                role_editor: 'Editor',
                role_editor_tooltip: 'Este membro pode <1>criar, editar e excluir conteúdo</1> no espaço de trabalho.',
                role_viewer: 'Visualizador',
                role_viewer_tooltip: 'Este membro pode <1>visualizar conteúdo</1> no espaço de trabalho.',
                role_member: 'Membro',
                role_member_tooltip:
                    'Este membro pode <1>criar, editar e excluir conteúdo</1> no espaço de trabalho e <1>convidar outros. Eles também podem tornar páginas/espaços de trabalho públicos</1> no espaço de trabalho.',
                role_blocked_tooltip: 'Este membro não tem acesso ao espaço de trabalho',
                invite_members: 'Convidar Membros para {{-workspaceName}}',
                plus_add_more: '+ Adicionar mais',
                send_invites: 'Enviar convites',
                invites_sent: 'Convites enviados',
                upgrade_plan: 'Atualize seu plano',
                upgrade_plan_details:
                    'Você atingiu o limite de {{count}} usuários gratuitos. Atualize seu plano para convidar mais usuários e desbloquear recursos adicionais para avançar.',
            },
            plans: {
                title: 'Planos do Espaço de Trabalho',
                loading: 'Carregando ...',
                sub_title_free:
                    'Use o Saga gratuitamente, <bold>atualize para maior colaboração</bold>, <bold>gerenciamento de tarefas</bold>, e <bold>Saga IA ilimitado</bold>.',
                sub_title_monthly_one:
                    'Sua assinatura atual é o plano Mensal Padrão. Seu espaço de trabalho de <bold>{{membersCount}} membro</bold> custa <bold>{{amount}}</bold> por mês, e será renovado em <bold>{{date}}</bold>.',
                sub_title_monthly_other:
                    'Sua assinatura atual é o plano Mensal Padrão. Seu espaço de trabalho de <bold>{{membersCount}} membros</bold> custa <bold>{{amount}}</bold> por mês, e será renovado em <bold>{{date}}</bold>.',
                sub_title_yearly_one:
                    'Sua assinatura atual é o plano Anual Padrão. Seu espaço de trabalho de <bold>{{membersCount}} membro</bold> custa <bold>{{amount_month}}</bold> por mês / <bold>{{amount_year}}</bold> por ano, e será renovado em <bold>{{date}}</bold>.',
                sub_title_yearly_other:
                    'Sua assinatura atual é o plano Anual Padrão. Seu espaço de trabalho de <bold>{{membersCount}} membros</bold> custa <bold>{{amount_month}}</bold> por mês / <bold>{{amount_year}}</bold> por ano, e será renovado em <bold>{{date}}</bold>.',
                sub_title_monthly_canceled_one:
                    'O plano do seu espaço de trabalho de <bold>{{membersCount}} membro</bold> custa <bold>{{amount}}</bold> por mês. Você cancelou este plano, mas ele permanecerá ativo até <bold>{{date}}</bold>. Depois disso, você será transferido para um plano Gratuito.',
                sub_title_monthly_canceled_other:
                    'O plano do seu espaço de trabalho de <bold>{{membersCount}} membros</bold> custa <bold>{{amount}}</bold> por mês. Você cancelou este plano, mas ele permanecerá ativo até <bold>{{date}}</bold>. Depois disso, você será transferido para um plano Gratuito.',
                sub_title_yearly_canceled_one:
                    'O plano do seu espaço de trabalho de <bold>{{membersCount}} membro</bold> custa <bold>{{amount_month}}</bold> por mês ou <bold>{{amount_year}}</bold> por ano. Você cancelou este plano, mas ele permanecerá ativo até <bold>{{date}}</bold>. Depois disso, você será transferido para um plano Gratuito.',
                sub_title_yearly_canceled_other:
                    'O plano do seu espaço de trabalho de <bold>{{membersCount}} membros</bold> custa <bold>{{amount_month}}</bold> por mês ou <bold>{{amount_year}}</bold> por ano. Você cancelou este plano, mas ele permanecerá ativo até <bold>{{date}}</bold>. Depois disso, você será transferido para um plano Gratuito.',
            },
            billing: {
                title: 'Faturamento',
                sub_title: 'Gerencie suas informações de faturamento e faturas.',
                per_month: 'por membro do espaço de trabalho / mês',
                free_plan: 'Plano Gratuito',
                standard_annual_plan: 'Plano anual padrão',
                standard_monthly_plan: 'Plano mensal padrão',
                billing_portal_label: 'Ver e editar detalhes de faturamento',
                invoices: {
                    title: 'Faturas',
                    loading: 'Carregando as faturas...',
                    no_invoices: 'Não há faturas para mostrar.',
                    no_upcoming_invoices: 'Sem faturas próximas',
                    next_billing_on_one:
                        'Você será cobrado <1>${{amount}}</1> em <1>{{date}}</1> por <1>{{billableMembersCount}} membro</1>',
                    next_billing_on_other:
                        'Você será cobrado <1>${{amount}}</1> em <1>{{date}}</1> por <1>{{billableMembersCount}} membros</1>',
                    see_invoice: 'Ver fatura',
                    invoice_not_ready: 'Fatura não pronta',
                    paid: 'Pago',
                    not_paid: 'Não pago',
                },
            },
            export_import: {
                title: 'Importar & Exportar',
                sub_title: 'Importar e exportar espaço de trabalho e gerenciar configurações de exportação.',
                import_workspace: 'Importar dados',
                import_workspace_description:
                    'Importar páginas, tarefas ou espaço de trabalho Saga de arquivos markdown, texto ou JSON.',
                import_workspace_cta: 'Importar espaço de trabalho',
                export_workspace: 'Exportar espaço de trabalho',
                export_workspace_description: 'Exportar espaço de trabalho como arquivos JSON.',
                export_workspace_cta: 'Exportar espaço de trabalho',
                import_modal: {
                    title: 'Solte seus arquivos para importar',
                    upload_button: 'Carregar do seu computador',
                    max_file_size: 'máx. {{size}} MB por arquivo',
                    supported_file_types: 'Suportado: Markdown, JSON',
                    unexpected_error_message:
                        'Ocorreu um erro inesperado.Por favor, tente novamente ou entre em contato conosco em <email/>',
                    unsupported_error_title: 'Formato de arquivo não suportado',
                    unsupported_error_description: 'Por favor, arraste e solte pastas, arquivos Markdown ou JSON.',
                    successfuly_imported_files_one: 'Importado com sucesso {{count}} arquivo',
                    successfuly_imported_files_other: 'Importado com sucesso {{count}} arquivos',
                    confirm_title: 'Importar arquivos como',
                    add_to_collection: 'Adicionar à coleção (opcional)',
                    confirm_button: 'Importar',
                    page: 'Páginas',
                    task: 'Tarefas',
                },
            },
            ai: {
                title: 'Saga IA',
                sub_title:
                    'Seja criativo, escreva mais rápido e faça um trabalho melhor diretamente no Saga com a ajuda de um assistente digital de IA.',
                confirm_delete_command: 'Você deseja deletar este comando?',
                confirm_delete_prompt: 'Você deseja deletar este prompt?',

                commands: {
                    title: 'Comandos',
                    add_command: 'Adicionar Comando',
                    customize_prompts: 'Personalize seus prompts e comandos de IA. <1>Veja como funciona</1>.',
                    add_prompt: 'Adicionar Prompt',
                },
                new: {
                    title_create_prompt: 'Criar um prompt personalizado',
                    title_edit_prompt: 'Editar seu prompt',
                    title_create_command: 'Criar um comando personalizado',
                    title_edit_command: 'Editar seu comando',
                    custom_command_tooltip: 'O texto selecionado será colocado no final do seu prompt.',
                    command_name_placeholder: 'Nome do comando',
                    prompt_name_placeholder: 'Nome do prompt',
                    command_placeholder: 'Escreva seu comando aqui...',
                    prompt_placeholder: 'Escreva seu prompt aqui...',
                },
            },
            labels: {
                title: 'Etiquetas',
                sub_title: 'Adicionar e editar suas etiquetas',
                add_label: 'Adicionar etiqueta',
                name: 'Nome',
                usage: 'Uso',
                options: 'Opções',
                edit_modal: {
                    title_create: 'Criar etiqueta',
                    title_edit: 'Editar etiqueta',
                    select_color: 'Selecionar uma cor para a etiqueta',
                    label_name: 'Nome da etiqueta',
                },
                delete_label_confirmation_title: 'Excluir a etiqueta "{{title}}"',
                delete_label_confirmation_description:
                    'A etiqueta será removida de {{count}} tarefas.\n\nEsta ação não pode ser desfeita.',
            },
        },
        billing: {
            free: 'Grátis',
            free_title: 'Plano Grátis',
            free_description: 'Para uso pessoal e pequenas equipes',
            standard_title: 'Plano Padrão',
            standard_description: 'Ideal para equipes de médio porte',
            business_title: 'Plano Empresarial',
            business_description: 'Ideal para organizações maiores',
            monthly: 'Mensal',
            annually: 'Anual',
            downgrade: 'Rebaixar',
            upgrade_plan: 'Melhorar plano',
            current_plan: 'Plano atual',
            planned: 'Planejado',
            join_waitlist: 'Entrar na lista de espera',
            per_editor_month: 'por membro / mês',
            per_space_editor_month: 'por membro do espaço de trabalho / mês',
            desktopModal: {
                title: 'Alterar Plano de Cobrança',
                description:
                    'Alterar seu plano de cobrança requer um navegador web. Ao continuar, uma nova aba do navegador será aberta, redirecionando você para a página de cobrança do nosso aplicativo. Por favor, complete suas alterações de cobrança no navegador.',
                cta: 'Abrir no navegador',
            },
            change_plan_modal_title: 'Tem certeza de que deseja alterar o intervalo de cobrança?',
            plan_upgrade_success_toast: {
                title: 'O plano foi melhorado',
                description: 'Agora você tem acesso ilimitado a todos os super recursos do Saga!',
            },
            downgrade_toast: {
                title: 'Sua solicitação para rebaixar o plano foi aceita',
                description: 'Você poderá usar todos os recursos pagos até que sua assinatura expire.',
            },
            upgrade_to_yearly_toast: {
                title: 'Intervalo de cobrança alterado',
            },
            change_plan_modal_description_one:
                'A cobrança da sua conta mudará de mensal para anual agora, créditos do seu plano atual serão transferidos para um novo plano e você será cobrado <bold>{{price}}</bold> por <bold>um</bold> usuário.',
            change_plan_modal_description_other:
                'A cobrança da sua conta mudará de mensal para anual agora, créditos do seu plano atual serão transferidos para um novo plano e você será cobrado <bold>{{price}}</bold> por <bold>{{members}}</bold> usuários.',
            free_plan_info_banner: {
                title: 'Estamos felizes em vê-lo colaborando!',
                description:
                    'Você está no plano grátis que inclui <bold>{{count}} membros</bold> neste espaço de trabalho. <1>Saiba mais</1>',
            },
            upgrade_plan_banner: {
                title: 'Gerenciador de tarefas',
                description:
                    'Obtenha uma visão geral de todas as suas tarefas em todas as suas páginas. Pesquise e ordene, encontre as tarefas de que precisa e complete.',
                primary_button: 'Melhorar plano',
                primary_button_default: 'Ver planos',
                no_access_desciption: 'Por favor, entre em contato com o proprietário do espaço de trabalho.',
            },
            feedback_modal: {
                title: 'Estamos tristes em vê-lo rebaixando o plano :(',
                description:
                    'Criamos o Saga para ajudá-lo a organizar seu conhecimento. Gostaríamos muito de saber se há algo que estamos perdendo ou poderíamos fazer melhor. Seus pensamentos seriam muito úteis para nós.',
                placeholder:
                    'Por favor, compartilhe por que você está rebaixando. Faremos o nosso melhor para tornar o Saga melhor para você...',
                primary_button: 'Enviar feedback',
            },
            downgrade_to_free_modal: {
                title: 'Tem certeza de que deseja rebaixar o plano para Grátis?',
                description:
                    "Seu espaço de trabalho tem <span class='font-bold'>{{membersCount}} $t(billing.downgrade_to_free_modal.member, {\"count\": {{membersCount}} })</span>. No plano grátis, você pode ter <span class='font-bold'>até {{allowedMembers}}</span>. No final da assinatura, <span class='font-bold'>{{membersLooseAccess}} $t(billing.downgrade_to_free_modal.member, {\"count\": {{membersLooseAccess}} }) perderão acesso</span> ao espaço de trabalho, e os restantes <span class='font-bold'>{{allowedMembers}} se tornarão $t(billing.downgrade_to_free_modal.admin, {\"count\": {{allowedMembers}} }).</span>",
                member: 'membro',
                member_other: 'membros',
                admin: 'administrador',
                admin_other: 'administradores',
                features_title: 'Você perderá esses super recursos:',
                feature_1: 'Saga IA ilimitado com GPT-4',
                feature_2: 'Gerenciador de tarefas',
                feature_3: 'Permissões de usuário',
                feature_4: 'Membros ilimitados no espaço de trabalho',
                primary_button: 'Manter plano',
                secondary_button: 'Rebaixar',
            },
            ai_upgrade_plan_banner: {
                description: 'Seus pedidos de IA grátis foram usados',
                primary_action: 'Melhorar plano',
            },
            ai_popover_modal_upgrade: {
                title: 'Melhore seu plano',
                description:
                    'Seus pedidos de IA grátis foram usados. Melhore para respostas ilimitadas de IA e recursos adicionais para aumentar sua produtividade.',
            },
            modal_plans: {
                title: 'Selecionar plano do espaço de trabalho',
                sub_title:
                    '<bold>Você atingiu o limite de {{count}} espaços de trabalho grátis</bold>. Melhore para ter acesso a recursos super adicionais que levarão seu trabalho para o próximo nível.',
                loading_plans: 'Carregando os planos...',
                footer_disclaimer:
                    'Todos os preços são em USD e cobrados por espaço de trabalho. O IVA pode ser aplicável.',
                fair_usage: 'uso justo',
                sagaAi_disclaimer: '*Saga AI sujeito a <fairUsageLink/>.',
                footer_contacts: 'Tem dúvidas sobre cobrança? Pergunte-nos <link/>.',
            },
            plan_features: {
                unlimited_pages_tasks: 'Páginas e tarefas ilimitadas',
                limited_members: '<bold>Até {{count}}</bold> usuários / espaço de trabalho',
                unlimited_members: '<bold>Membros ilimitados</bold> no espaço de trabalho',
                limited_saga_requests: 'Saga IA com <bold>{{count}} palavras</bold> / mês',
                unlimited_saga_requests: '<bold>Saga IA ilimitado</bold> com GPT-4',
                limited_integrations: '<bold>{{count}}</bold> integração: <google /> ou <linear />',
                unlimited_integrations: '<google /> e <linear /> integrações',
                tooltip_integrations: {
                    google_drive: 'Abrir, pesquisar e mencionar arquivos do Google Drive diretamente no Saga',
                    linear: 'Mencionar, pesquisar ou colar links ao vivo para problemas Linear diretamente no Saga.',
                },
                apps: 'Mac e Windows',
                tooltip_sagaai: 'Saga AI é o assistente inteligente dentro do seu espaço de trabalho.',
                tooltip_apps: {
                    content: 'Baixe os aplicativos de desktop do Saga para uma experiência ainda melhor.',
                    apps: 'aplicativos',
                },
                task_manager: 'Gerenciador de tarefas',
                tooltip_task_manager: 'Gerencie todas as suas tarefas em um só lugar.',
                user_permissions: 'Permissões de usuário',
                advanced_user_permissions: 'Permissões de usuário avançadas',
                search_with_ai: 'Use <sagaAi /> com ferramentas e a web',
                version_history: 'Histórico de versões',
                version_history_free: 'Histórico de versões de 7 dias',
                version_history_standard: 'Histórico de versões de 30 dias',
                version_history_business: 'Histórico de versões ilimitado',
                offline_mode: 'Modo offline',
                business_features_title: 'Tudo no Padrão, mais:',
                free_limitations: '*Até {{count}} espaços de trabalho Grátis por conta',
                public_access: 'Páginas e espaços de trabalho públicos',
                custom_ai_commands: 'Comandos personalizados <sagaAi />',
                ai_talk_to_your_page: '<sagaAi /> fala com suas páginas',
                limited_upload_file: '{{count}} MB tamanho máximo do arquivo',
                limited_upload_total_mb: '{{count}} MB armazenamento de arquivos',
                limited_upload_total_gb: '{{count}} GB armazenamento de arquivos',
                limited_guests: '{{count}} convidados',
            },
            choose_workspace_modal: {
                title: 'Atualização necessária',
                description:
                    'Você atingiu o número máximo de espaços de trabalho gratuitos. Para aceitar o convite para ingressar no novo espaço de trabalho, atualize um dos seus espaços de trabalho gratuitos existentes.',
                go_to_plans: 'Ir para planos',
            },
        },
        table: {
            move_left: 'Mover para Esquerda',
            move_right: 'Mover para Direita',
            insert_column_left: 'Inserir Coluna à Esquerda',
            insert_column_right: 'Inserir Coluna à Direita',
            remove_column: 'Remover coluna',
            append_column: 'Adicionar coluna',
            append_row: 'Adicionar linha',
            remove_row: 'Remover linha',
        },
    },
};
