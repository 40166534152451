import { NodeEntry, Path, Range } from 'slate';
import * as SharedTypes from '@saga/shared';
import {
    Page,
    Collection,
    FormatBlockElementTypeDef,
    WeakPage,
    Link,
    SagaElement,
    Table,
    SagaLocation,
    WeakTask,
    RealtimeSagaEditor,
} from '@saga/shared';
import { SortablePage, SortableSharedPage } from './lib/Table';
import * as api from '@saga/api';
import React from 'react';

declare global {
    const APP_VERSION: string;
    const CONTEXT: string | undefined;
    const BRANCH_HEAD: string | undefined;
    const COMMIT_REF: string | undefined;
    const REVIEW_ID: string;
    interface ImportMeta {
        env: {
            MODE: 'development' | 'production';
            VITE_DISABLE_SSL: boolean | undefined;
            VITE_FIREBASE_STAGE?: 'production' | 'staging';
            VITE_FIREBASE_EMULATOR_HOST: string;
            VITE_FIREBASE_STORAGE_EMULATOR_HOST: string;
            VITE_POSTHOG_TOKEN: string | undefined;
            VITE_POSTHOG_HOST: string | undefined;
            VITE_SENTRY_DSN: string;
            VITE_SENTRY_AUTH_TOKEN: string;
            VITE_SENTRY_PROJECT_ID: string;
            VITE_MIXPANEL_HOST: string;
            VITE_MIXPANEL_TOKEN: string;
            VITE_GRAPHQL_URL: string;
            VITE_GRAPHQL_SUBSCRIPTIONS_URL: string;
            VITE_FIREBASE_AUTH_EMULATOR_HOST?: string;
            VITE_FIREBASE_APPCHECK_DEBUG_TOKEN?: string;
            VITE_BACKEND_URL: string;
            VITE_EXPRESS_URL: string;
            VITE_STAGING_CLOUDFRONT_URL: string;
            VITE_CLOUDFRONT_URL: string;
            VITE_SYNC_BACKEND_URL: string;
            VITE_PREVIEW_URL: string | undefined;
        };
    }

    interface Window {
        todesktop?: ToDesktop;
        logout?: () => void;
        FIREBASE_APPCHECK_DEBUG_TOKEN?: string;
        signInWithCustomToken?: (token: string) => void;
    }
}

export type ToDesktop = {
    on: (s: 'found-in-page', fn: (event: any, result: any) => void) => void;
    app: {
        updateInternalUrls: (s: string) => Promise<void>;
        getInternalUrls: () => Promise<string>;
    };
    contents: {
        findInPage: {
            find: (s: string, options: { forward: boolean }) => Promise<number>;
            stop: () => void;
        };
        openUrlInBrowser: (s: string) => void;
        canGoBack(): Promise<boolean>;
        canGoForward(): Promise<boolean>;
        goBack(): void;
        goForward(): void;
    };
    window: {
        isFullscreen: () => Promise<boolean>;
    };
    version: string;
    os: {
        platform: string;
    };
};

declare module 'slate' {
    interface CustomTypes {
        Editor: RealtimeSagaEditor;
        Element: SharedTypes.SagaElement;
        Text: SharedTypes.SagaText;
    }
}

export enum HighlightTargetType {
    TITLE = 'title',
    HEADING = 'heading',
}

export type HighlightTarget = {
    location: SagaLocation.PageLocation;
    type: HighlightTargetType;
    heading?: {
        id: string;
        title: string;
    };
};

export type AutolinkHighlight = {
    type: 'autolink';
    target: HighlightTarget;
    highlightId: string;
    range: Range;
    event: React.MouseEvent;
};

export type InlinePageLinkHighlight = {
    type: 'inline-page-link';
    target: HighlightTarget;
    highlightId: string;
    // location?: Location;
    event: React.MouseEvent;
};

export type Highlight = AutolinkHighlight | InlinePageLinkHighlight;

export type ComputedData = {
    pageCount?: number;
    subCollections?: string[];
    creatorName?: string;
};

export type CollectionItem = {
    id: string;
    title: string;
    type: 'collection';
    data: Collection;
    computed: ComputedData;
};

export type PageItem = {
    id: string;
    title: string;
    type: 'page';
    data: SortablePage;
    computed: ComputedData;
};

export type SharedPageItem = {
    id: string;
    title: string;
    type: 'shared-page';
    data: SortableSharedPage;
    computed: ComputedData;
};

export type Item = CollectionItem | PageItem | SharedPageItem;

export type TableColumn = {
    type: string;
    label: string;
    value: string;
    asc: string;
    desc: string;
};

export type SimplePageForSuggestion = Pick<
    WeakPage,
    'id' | 'title' | 'aliases' | 'icon' | 'isTemplate' | 'settings' | 'updatedAt'
>;

export type PageSuggestion = {
    type: 'page';
    title: string;
    page: SimplePageForSuggestion;
};

export type RecentPageSuggestion = {
    type: 'recentPage';
    title: string;
    page: SimplePageForSuggestion;
};

export type CollectionSuggestion = {
    type: 'collection';
    title: string;
    collection: Collection;
};

export type PageAliasSuggestion = {
    type: 'pageAlias';
    title: string;
    alias: string;
    page: Pick<Page, 'id' | 'title' | 'icon'>;
};

export type InlinePageLinkSuggestion = {
    type: 'inline-page-link';
    title: string;
    page: Pick<Page, 'id' | 'title' | 'icon' | 'settings' | 'isTemplate' | 'updatedAt'>;
};

export type FormatSuggestion = {
    type: 'format';
    title: string;
    value: FormatBlockElementTypeDef;
};

export type Member = {
    id: string;
    firstName: string;
    lastName: string;
};

export type MemberSuggestion = {
    type: 'member';
    member: Member;
};

export type GoogleDriveFileSuggestion = {
    type: 'drive';
    file: api.GoogleDriveFileFragment;
    title: string;
};

export type LinearIssueSuggestion = {
    type: 'linear-issue';
    issue: api.LinearIssueFragment;
    title: string;
};

export type TaskSuggestion = {
    type: 'task';
    title: string;
    task: WeakTask;
};

export type CreatePageSuggestion = { type: 'create'; kind: 'page'; title: string; isTemplate?: boolean };
export type CreateCollectionSuggestion = {
    type: 'create';
    title: string;
    kind: 'collection';
};
export type CreateTaskSuggestion = {
    type: 'create';
    title: string;
    kind: 'task';
};
export type CreateLinearIssueSuggesstion = {
    type: 'create';
    kind: 'linear-issue';
    title: string;
    team: api.LinearTeamFragment;
};

export type CreateSuggestion =
    | CreatePageSuggestion
    | CreateCollectionSuggestion
    | CreateTaskSuggestion
    | CreateLinearIssueSuggesstion;

export type ShareSuggestion = {
    type: 'share';
    kind: 'share' | 'access';
    title: string;
};

export type DateBlockSuggestion = {
    type: 'date';
    title: string;
    date: string;
    alias?: string[];
    openPicker?: boolean;
};

export type AICommandSuggestion = {
    type: 'ai-command';
    title: string;
    command: 'open-ai-popover';
};

export type TableCommandSuggestion = {
    type: 'table-command';
    title: string;
    command: 'insert-row-above' | 'insert-row-below' | 'insert-column-left' | 'insert-column-right';
    tableEntry: NodeEntry<Table>;
};

export type InsertIntegrationCommands = {
    title: string;
    command: 'drive' | 'linear-issue' | 'inline-page-link';
    type: 'insert';
};

export type InsertIntegrationSuggestions =
    | {
          type: 'insert';
          title: string;
          command: 'drive';
          file: api.GoogleDriveFileFragment;
      }
    | {
          type: 'insert';
          command: 'linear-issue';
          issue: api.LinearIssueFragment;
          title: string;
      }
    | {
          type: 'insert';
          command: 'link-to-page';
          title: string;
      };

export type BlocksPastedSuggestion =
    | { type: 'turn-into-pretty-link'; title: string; link: Link }
    | { type: 'turn-into-linear-issue'; title: string; link: Link }
    | {
          type: 'turn-into-live-block';
          title: string;
          origin: SagaLocation.PageLocation;
          blocks: Array<SagaElement & { originBlockId: string }>;
      }
    | {
          type: 'turn-into-embed';
          title: string;
          link: Link;
      }
    | { type: 'keep-as-link'; title: string }
    | { type: 'turn-into-google-drive'; title: string; link: Link }
    | { type: 'keep-as-text'; title: string }
    | { type: 'insert'; title: string };

export type InsertIntegrationSuggestion = { type: 'insert'; title: string };

export type DndBlockItem = {
    id: string;
    type: 'block';
    origin: SagaLocation.BlocksLocation;
    location: Path | Range;
};

export type DndSidebarPage = { id: string; type: 'sidebar_page' };
export type DndSidebarTask = { id: string; type: 'sidebar_task' };
export type DndSidebarCollection = { id: string; type: 'sidebar_collection' };
export type DndSidebarPageView = { id: string; type: 'sidebar_page_view' };
export type DndSidebarTaskView = { id: string; type: 'sidebar_task_view' };

export type DndReferenceItem = { id: string; type: 'reference'; origin: SagaLocation.PageLocation };

export type BlockDroppable =
    | DndBlockItem
    | DndSidebarPage
    | DndSidebarTask
    | DndSidebarCollection
    | DndReferenceItem
    | { dataTransfer: DataTransfer };

export type DndOverArea = 'top' | 'bottom';
