import React, { useEffect, useRef } from 'react';
import ReferencePanel from '@/components/rightpanel/ReferencePanel';
import PropertiesPanel from '@/components/rightpanel/PropertiesPanel';
import TOCPanel from '@/components/rightpanel/TOCPanel';
import PageSettingsPanel from '@/components/rightpanel/PageSettingsPanel';
import { AlignLeft, Database, Link, Sliders } from 'react-feather';
import { track } from '@/analytics';
import classNames from 'classnames';
import { BlockD, PageContent, SagaLocation } from '@saga/shared';
import { usePageSnapshot } from '@/hooks/SpaceHooks';
import { useReferenceResults } from '../editor/ReferenceResults';
import Button from '../styled/Button';
import useInterfaceSettings, { RightMenu } from '@/hooks/useInterfaceSettings';
import { useBlockPlugins } from '@/components/BlockPluginProvider';
import { BlockPlugin } from '@/../../shared/src/Editor/Plugins';
import Panel from './Panel';
import { PageLocation } from '@/../../shared/src/SagaLocation';
import { useTranslation } from 'react-i18next';
import { useDocumentYBlocks } from '@/components/RealtimeDocumentProvider';
import { usePageAccess } from '@/components/PagesPermissionsBySpaceProvider';
import Tooltip from '@/components/popover/Tooltip';

type MenuButtonProps = {
    isOpen: boolean;
    onClick: () => void;
    label: string;
    icon: React.ReactNode;
    addRef?: (ref: React.MutableRefObject<HTMLButtonElement | null>) => void;
    floating: boolean;
};

const MenuButton = ({ isOpen, onClick, label, icon, addRef, floating }: MenuButtonProps) => {
    const ref = useRef<HTMLButtonElement | null>(null);

    useEffect(() => {
        if (addRef && ref && ref.current) {
            addRef(ref);
        }
    }, [ref, addRef]);

    return (
        <Tooltip content={label} disabled={floating}>
            <button
                data-testid={`menubutton-${label}`}
                ref={ref}
                className="w-full space-y-2 justify-start flex flex-col items-center focus:outline-none"
                onClick={onClick}
            >
                <Button.Circle isOpen={isOpen}>{icon}</Button.Circle>
            </button>
        </Tooltip>
    );
};

const pageKeys = [
    'id',
    'title',
    'settings',
    'aliases',
    'wordCount',
    'createdAt',
    'updatedAt',
    'archivedAt',
    'properties',
    'isTemplate',
    'createdBy',
] as const;

function ReferenceMenuButtonIcon({ resultNumber }: { resultNumber: number }) {
    if (resultNumber > 0) {
        return <span className="stroke-currentColor mx-auto text-xs">{resultNumber > 99 ? '99+' : resultNumber}</span>;
    }

    return <Link className={'stroke-currentColor flex-none'} size={16} />;
}

function EnableLinkTitleInfo() {
    const [, setInterfaceSettings] = useInterfaceSettings();

    return (
        <div className="text-sm flex items-center justify-center space-x-2 p-2">
            <span>
                {`Auto-linking to this page's title is disabled`}. Go to{' '}
                <span
                    className="underline cursor-pointer"
                    onClick={() => setInterfaceSettings({ rightMenu: 'settings' })}
                >
                    Page Info
                </span>{' '}
                to enable it.
            </span>
        </div>
    );
}

const RightPanel = ({
    location,
    container,
    rightMenu,
    onRightMenuChange,
    floating,
    onFocusBlockId,
}: {
    location: PageLocation;
    container: React.RefObject<HTMLElement | null>;
    rightMenu: RightMenu;
    onRightMenuChange(menu: RightMenu): void;
    floating: boolean;
    onFocusBlockId: (blockId: string) => void;
}) => {
    const page = usePageSnapshot(location.pageId, pageKeys);
    const blockPlugins = useBlockPlugins();
    const { isPageAdmin } = usePageAccess(location.pageId);
    const referenceResults = useReferenceResults();
    const { t } = useTranslation();
    const { data: yBlocks } = useDocumentYBlocks(location.pageId);

    function onClose() {
        onRightMenuChange(null);
    }

    const openMenu = (menu: typeof rightMenu) => {
        let newMenu = null;
        if (rightMenu !== menu) {
            newMenu = menu;
        }
        onRightMenuChange(newMenu);

        track(`open-${menu}-menu`);
    };

    const isOpen = Boolean(rightMenu);

    if (page == null) {
        return null;
    }

    return (
        <RightPanel.Container absolute={floating && isOpen}>
            <RightPanel.Panels floating={floating} isOpen={isOpen}>
                {!page.isTemplate && page.archivedAt == null && rightMenu === 'references' && (
                    <>
                        {page.settings.linkTitle && (
                            <ReferencePanel
                                location={location}
                                referenceResults={referenceResults}
                                floating={floating}
                                onClose={onClose}
                            />
                        )}

                        {!page.settings.linkTitle && (
                            <Panel testId="reference-panel">
                                <ReferencePanel.Header referenceResults={[]} floating={floating} onClose={onClose} />
                                <Panel.Body>
                                    <EnableLinkTitleInfo />
                                </Panel.Body>
                            </Panel>
                        )}
                    </>
                )}

                {!page.isTemplate && page.properties.length > 0 && rightMenu === 'properties' && yBlocks && (
                    <PropertiesPanel
                        floating={floating}
                        currentPage={page}
                        blocks={yBlocks.toJSON() as PageContent}
                        onClose={onClose}
                    />
                )}
                {rightMenu === 'toc' && yBlocks && (
                    <TOCPanel
                        container={container}
                        floating={floating}
                        onClose={onClose}
                        onFocusBlockId={onFocusBlockId}
                        blockPlugins={blockPlugins}
                        entityBlocks={yBlocks.toJSON() as PageContent}
                    />
                )}
                {!page.isTemplate && isPageAdmin && rightMenu === 'settings' && (
                    <PageSettingsPanel currentPage={page} onClose={onClose} floating={floating} />
                )}
            </RightPanel.Panels>
            <RightPanel.Buttons floating={floating}>
                {!page.isTemplate && page.archivedAt == null && (
                    <MenuButton
                        isOpen={rightMenu === 'references'}
                        onClick={() => openMenu('references')}
                        label={t('references.label')}
                        icon={
                            <ReferenceMenuButtonIcon
                                resultNumber={page.settings.linkTitle ? referenceResults.length : 0}
                            />
                        }
                        floating={floating}
                    ></MenuButton>
                )}

                {!page.isTemplate && page.properties.length > 0 && (
                    <MenuButton
                        isOpen={rightMenu === 'properties'}
                        onClick={() => openMenu('properties')}
                        label={t('properties.label')}
                        icon={<Database className={'stroke-currentColor flex-none'} size={16} />}
                        floating={floating}
                    ></MenuButton>
                )}

                <MenuButton
                    isOpen={rightMenu === 'toc'}
                    onClick={() => openMenu('toc')}
                    label={t('table_of_content.label')}
                    icon={<AlignLeft className={'stroke-currentColor flex-none'} size={16} />}
                    floating={floating}
                />

                {!page.isTemplate && isPageAdmin && (
                    <MenuButton
                        isOpen={rightMenu === 'settings'}
                        onClick={() => openMenu('settings')}
                        label={t('page_info.label')}
                        icon={<Sliders className={'stroke-currentColor flex-none'} size={16} />}
                        floating={floating}
                    ></MenuButton>
                )}
            </RightPanel.Buttons>
        </RightPanel.Container>
    );
};

RightPanel.Container = function RightPanelContainer({
    absolute,
    children,
}: {
    absolute: boolean;
    children: React.ReactNode;
}) {
    return (
        <div
            className={classNames('flex-shrink-1 z-40 flex flex-row h-full', {
                'absolute inset-0 bg-white dark:bg-saga-gray-1000 pt-12': absolute,
            })}
            data-testid="right-panel"
        >
            {children}
        </div>
    );
};

RightPanel.Panels = function RightPanelPanels({
    isOpen,
    floating,
    children,
}: {
    isOpen: boolean;
    floating: boolean;
    children: React.ReactNode;
}) {
    return (
        <div
            className={classNames('py-2 pr-2 flex-auto h-full', {
                hidden: !isOpen,
                'min-w-xs max-w-sm w-full': !floating,
            })}
        >
            {children}
        </div>
    );
};

RightPanel.Buttons = function RightPanelButtons({
    floating,
    children,
}: {
    floating: boolean;
    children: React.ReactNode;
}) {
    return (
        <div
            className={classNames('hidden sm:block space-y-6 py-2 text-center flex-none', {
                'w-8': floating,
                'w-12': !floating,
            })}
        >
            {children}
        </div>
    );
};

export function PublicRightPanel({
    container,
    rightMenu,
    onRightMenuChange,
    floating,
    onFocusBlockId,
    pageBlocks,
    blockPlugins,
}: {
    container: React.RefObject<HTMLElement | null>;
    rightMenu: RightMenu;
    onRightMenuChange(menu: RightMenu): void;
    floating: boolean;
    onFocusBlockId: (blockId: string) => void;
    pageBlocks: BlockD[];
    blockPlugins: BlockPlugin[];
}) {
    const { t } = useTranslation();
    const openMenu = (menu: typeof rightMenu) => {
        let newMenu = null;
        if (rightMenu !== menu) {
            newMenu = menu;
        }
        onRightMenuChange(newMenu);

        track(`open-${menu}-menu`);
    };

    function onClose() {
        onRightMenuChange(null);
    }

    const isOpen = rightMenu != null;

    return (
        <RightPanel.Container absolute={floating && isOpen}>
            <RightPanel.Panels isOpen={isOpen} floating={floating}>
                {rightMenu === 'toc' && (
                    <TOCPanel
                        container={container}
                        floating={floating}
                        onClose={onClose}
                        onFocusBlockId={onFocusBlockId}
                        blockPlugins={blockPlugins}
                        entityBlocks={pageBlocks as PageContent}
                    />
                )}
            </RightPanel.Panels>

            <RightPanel.Buttons floating={floating}>
                <MenuButton
                    isOpen={rightMenu === 'toc'}
                    onClick={() => openMenu('toc')}
                    label={t('table_of_content.label')}
                    icon={<AlignLeft className={'stroke-currentColor flex-none'} size={16} />}
                    floating={floating}
                />
            </RightPanel.Buttons>
        </RightPanel.Container>
    );
}

export function TaskRightPanel({
    rightMenu,
    onRightMenuChange,
    floating,
    location,
    container,
    onFocusBlockId,
}: {
    rightMenu: RightMenu;
    onRightMenuChange(menu: RightMenu): void;
    floating: boolean;
    location: SagaLocation.TaskLocation;
    container: React.RefObject<HTMLElement | null>;
    onFocusBlockId: (blockId: string) => void;
}) {
    const blockPlugins = useBlockPlugins();
    const referenceResults = useReferenceResults();
    const { data: yBlocks } = useDocumentYBlocks(location.taskId);
    const { t } = useTranslation();

    const openMenu = (menu: typeof rightMenu) => {
        let newMenu = null;
        if (rightMenu !== menu) {
            newMenu = menu;
        }
        onRightMenuChange(newMenu);

        track(`open-${menu}-menu`, { source: 'task-pane' });
    };

    function onClose() {
        onRightMenuChange(null);
    }

    const isOpen = rightMenu != null;

    return (
        <RightPanel.Container absolute={floating && isOpen}>
            <RightPanel.Panels isOpen={isOpen} floating={floating}>
                {rightMenu === 'references' && (
                    <ReferencePanel
                        location={location}
                        referenceResults={referenceResults}
                        floating={floating}
                        onClose={onClose}
                    />
                )}
                {rightMenu === 'toc' && yBlocks && (
                    <TOCPanel
                        container={container}
                        floating={floating}
                        onClose={onClose}
                        onFocusBlockId={onFocusBlockId}
                        blockPlugins={blockPlugins}
                        entityBlocks={yBlocks.toJSON() as PageContent}
                    />
                )}
            </RightPanel.Panels>

            <RightPanel.Buttons floating={floating}>
                <MenuButton
                    isOpen={rightMenu === 'references'}
                    onClick={() => {
                        if (rightMenu === 'references') {
                            onClose();
                        } else {
                            openMenu('references');
                        }
                    }}
                    label={t('references.label')}
                    icon={<ReferenceMenuButtonIcon resultNumber={referenceResults.length} />}
                    floating={floating}
                ></MenuButton>

                <MenuButton
                    isOpen={rightMenu === 'toc'}
                    onClick={() => openMenu('toc')}
                    label={t('table_of_content.label')}
                    icon={<AlignLeft className={'stroke-currentColor flex-none'} size={16} />}
                    floating={floating}
                />
            </RightPanel.Buttons>
        </RightPanel.Container>
    );
}

export default RightPanel;
