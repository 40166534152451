import React, { useState, useEffect } from 'react';
import { DateRange, DayPicker } from 'react-day-picker';
import {
    AdminUser,
    useAdminUserDeleteMutation,
    useAdminUserListQuery,
    useAdminUserListSharedCountLazyQuery,
} from '@saga/api';
import { format, formatISO } from 'date-fns';
import { debugLog } from '@/utils';
import Table from '../table/Table';
import { useHistory } from 'react-router-dom';
import Tooltip from '../popover/Tooltip';
import Button from '../styled/Button';
import mailChecker from 'mailchecker';
import _ from 'lodash';

const copyToClipboard = async (e: React.MouseEvent<HTMLSpanElement, MouseEvent>, string: string) => {
    e.stopPropagation();
    await navigator.clipboard.writeText(string);
};

const checkIfMailValid = async (email: string) => {
    const response = await fetch('https://raw.githubusercontent.com/Saga-HQ/mailchecker/main/list.txt', {
        mode: 'cors',
        credentials: 'same-origin',
        headers: { 'Content-Type': 'text/plain', Accept: 'text/plain' },
    });

    if (response.ok) {
        const data = await response.text();
        const domains = data.split('\n').filter((domain) => domain.trim() !== '');
        const missingDomainsInBlacklist = _.difference(domains, Array.from(mailChecker.blacklist()));
        // @ts-expect-error missing type declaration
        mailChecker.addCustomDomains(missingDomainsInBlacklist);
    }

    const isValidEmail = await mailChecker.isValid(email);
    return isValidEmail;
};

function SharedAssets({ userId }: { userId: string }) {
    const [counters, setCounters] = useState<{ spaces: number; pages: number } | null>(null);
    const [getCounters, { data, loading }] = useAdminUserListSharedCountLazyQuery({ variables: { input: { userId } } });

    useEffect(() => {
        if (!data) return;
        setCounters({
            pages: data.adminUserListSharedCount.publicPagesCount,
            spaces: data.adminUserListSharedCount.publicSpacesCount,
        });
    }, [data]);

    if (loading) return <span>Loading...</span>;

    return (
        <span>
            {counters ? (
                `${counters.spaces} spaces | ${counters.pages} pages`
            ) : (
                <button
                    onClick={(e) => {
                        e.stopPropagation();
                        getCounters();
                    }}
                    disabled={loading}
                >
                    Get the counters
                </button>
            )}
        </span>
    );
}

export default function ListUsers() {
    const history = useHistory();
    const [page, setPage] = useState(1);
    const [userList, setUserList] = useState<AdminUser[]>([]);
    const [selectedUsersList, setSelectedUsersList] = useState<string[]>([]);
    const [range, onChange] = useState<DateRange | undefined>({ from: new Date(), to: new Date() });
    const [deleteUser, { loading: deletingUsers }] = useAdminUserDeleteMutation();

    const createdAtFrom = range && range.from && formatISO(range.from, { representation: 'date' });
    const createdAtTo = range && range.to && formatISO(range.to, { representation: 'date' });

    async function setSelectedIfEmailNotValid(email: string, userId: string) {
        const isEmailValid = await checkIfMailValid(email);
        if (!isEmailValid) {
            setSelectedUsersList((prev) => [...prev, userId]);
        }
    }

    const { data, loading, error, fetchMore } = useAdminUserListQuery({
        variables: {
            input: { page, createdAtFrom, createdAtTo },
        },
        skip: !range,
        onCompleted: (data) => {
            for (const user of data.adminUserList.users) {
                setSelectedIfEmailNotValid(user.email, user.id);
            }

            setUserList((prev) => [...prev, ...data.adminUserList.users]);
        },
    });

    const onFetchMore = () => {
        fetchMore({
            variables: {
                input: { page: page + 1, createdAtFrom, createdAtTo },
            },
        }).then(() => setPage(page + 1));
    };

    if (error) {
        debugLog(error);
    }

    const onDeleteUsers = () => {
        if (
            window.confirm(
                `Are you sure you want to delete ${selectedUsersList.length} users? Keep in mind that will remove the following resources as well\n* Sendgrid\n* Posthog data (person + events)`,
            )
        ) {
            deleteUser({
                variables: { input: { users: selectedUsersList.map((u) => ({ userId: u, deletePosthodData: true })) } },
                onCompleted(data) {
                    console.log(data);
                    alert(`Deleting done. Deleted ${data.adminUserDelete.length} users.`);
                    setUserList((prev) => prev.filter((user) => !selectedUsersList.includes(user.id)));
                    setSelectedUsersList([]);
                },
            });
        }
    };

    return (
        <div className="w-full">
            <h1 className="text-3xl mb-4">List Users</h1>
            <p>
                Users registerd on
                <span className="font-bold">
                    {range !== undefined
                        ? ` ${range.from && format(range.from, 'dd.LL.yyyy')}${
                              range.to !== undefined ? ` - ${format(range.to, 'dd.LL.yyyy')}` : ''
                          }`
                        : '...'}
                </span>
            </p>

            <DayPicker
                weekStartsOn={1}
                mode="range"
                selected={range}
                disabled={{ after: new Date() }}
                onSelect={(range) => {
                    onChange(range);
                    setPage(1);
                    setUserList([]);
                    setSelectedUsersList([]);
                }}
                max={7}
            />

            {loading && <div>Loading...</div>}
            <div className="py-2 space-x-4 flex items-center">
                <span>Selected users: {selectedUsersList.length}</span>
                <Button onClick={() => setSelectedUsersList([])} variant="secondary" size="small">
                    Deselect all
                </Button>
            </div>
            {userList.length >= 1 && !loading ? (
                <Table className="table-fixed border-collapse text-left divide-y divide-saga-gray-150 w-full mb-3">
                    <Table.Header>
                        <Table.Row className="divide-x divide-saga-gray-150 font-semibold">
                            <Table.Cell className="p-1 w-8 text-right">#</Table.Cell>
                            <Table.Cell className="p-1 w-8"></Table.Cell>
                            <Table.Cell className="p-1 w-50">Id</Table.Cell>
                            <Table.Cell className="p-1">Names</Table.Cell>
                            <Table.Cell className="p-1 w-64">Email</Table.Cell>
                            <Table.Cell className="p-1">Created At</Table.Cell>
                            <Table.Cell className="p-1">Shared</Table.Cell>
                            <Table.Cell className="p-1 w-14">Role</Table.Cell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body className="">
                        {userList.map(({ id, names, email, role, createdAt }, i) => (
                            <Table.Row
                                key={id}
                                className={`divide-x divide-saga-gray-15 py-1 cursor-pointer hover:bg-saga-gray-100 dark:hover:bg-saga-gray-800 ${selectedUsersList.includes(id) ? 'bg-gray-100' : ''}`}
                                onClick={() => history.push(`/admin/delete-users?email=${email}`)}
                            >
                                <Table.Cell className="text-right p-1">{i + 1}</Table.Cell>
                                <Table.Cell onClick={(e) => e.stopPropagation()}>
                                    <input
                                        type="checkbox"
                                        checked={selectedUsersList.includes(id)}
                                        onChange={(e) =>
                                            setSelectedUsersList((prev) =>
                                                e.target.checked
                                                    ? [...prev, id]
                                                    : prev.filter((userId) => userId !== id),
                                            )
                                        }
                                    />
                                </Table.Cell>
                                <Table.Cell className="p-1 truncate">
                                    <Tooltip content={`Copy to clipboard ${id}`} placement="top">
                                        <span onClick={(e) => copyToClipboard(e, id)}>{id}</span>
                                    </Tooltip>
                                </Table.Cell>
                                <Table.Cell className="p-1 truncate">{names}</Table.Cell>
                                <Table.Cell className="p-1 truncate">
                                    <Tooltip content="Copy to clipboard" placement="top">
                                        <span onClick={(e) => copyToClipboard(e, email)}>{email}</span>
                                    </Tooltip>
                                </Table.Cell>
                                <Table.Cell className="p-1">
                                    {format(new Date(createdAt), 'dd.LL.yyyy HH:mm')}
                                </Table.Cell>
                                <Table.Cell className="p-1">
                                    <SharedAssets userId={id} />
                                </Table.Cell>
                                <Table.Cell className="p-1">{role}</Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
            ) : (
                <div className="p-1">No data</div>
            )}

            <div className="flex space-x-4 items-center">
                {range && data?.adminUserList.pageInfo.hasNextPage && (
                    <Button onClick={onFetchMore}>Fetch 20 more</Button>
                )}

                {selectedUsersList.length > 0 && (
                    <Button disabled={deletingUsers} onClick={onDeleteUsers} variant="alert">
                        {deletingUsers ? 'Deleting users' : 'Delete selected users'}
                    </Button>
                )}
            </div>
        </div>
    );
}
