import { SafeSpace } from '../types';
import { pipe } from 'fp-ts/function';
import * as E from 'fp-ts/Either';
import { deleteFromYArray } from '../yjs-utils';
import { logError, toCtx } from '../utils';
import transactInSpace from './transactInSpace';

export default function deleteProperties(space: SafeSpace, ids: string[]) {
    transactInSpace(space, () => {
        ids.forEach((idToDeleted) =>
            pipe(
                space.get('properties'),
                E.chain(toCtx),
                E.chainW((ctx) =>
                    deleteFromYArray(
                        ctx.safeArray.array,
                        ctx.items,
                        ({ id }) => id === idToDeleted,
                        'deleteProperties',
                    ),
                ),
                E.fold(logError, (indexDeleted) => console.log(`property deleted with index: ${indexDeleted}`)),
            ),
        );
    });
}
