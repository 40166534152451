import React from 'react';
import { BlockType, Callout as CalloutType, SagaElement, SagaEditor, isCallout, Colors } from '@saga/shared';
import useInterfaceSettings from '@/hooks/useInterfaceSettings';
import classNames from 'classnames';
import { ReactEditor, useSlateStatic } from 'slate-react';
import { IconPicker } from '@/components/IconPicker';
import Button from '../../styled/Button';
import { Smile, Trash } from 'react-feather';
import { Transforms } from 'slate';
import Tooltip from '../../popover/Tooltip';
import * as Popover from '@radix-ui/react-popover';
import { Emoji } from 'emoji-mart';
import { track } from '@/analytics';
import { useTranslation } from 'react-i18next';
import { WithExplicitFontType } from '@/components/WithExplicitFontType';

const calloutColorSuggestions = [
    ...Colors.colorSuggestions,
    {
        label: 'Grey',
        value: 'bg-callout-default',
    },
];

const findFirstChildren = (
    element: SagaElement,
    predicate: (element: SagaElement) => boolean,
): SagaElement | undefined => {
    if (predicate(element)) return element;

    if (element.children.length > 0) {
        return findFirstChildren(element.children[0], predicate);
    }

    return undefined;
};

function ContextMenu({
    onRemove,
    background,
    onBackgroundChange,
    isIconDefined,
    onToggleIcon,
}: {
    onRemove: () => void;
    background: string;
    onBackgroundChange(background: string): void;
    onToggleIcon: () => void;
    isIconDefined: boolean;
}) {
    const [isPopoverOpen, setPopoverOpen] = React.useState(false);
    const { t } = useTranslation();

    return (
        <Popover.Root onOpenChange={setPopoverOpen}>
            <div
                className={classNames('absolute top-[3px] right-[-3px] callout-hover:flex opacity-100 flex-row', {
                    flex: isPopoverOpen,
                    hidden: !isPopoverOpen,
                })}
            >
                <Popover.Trigger asChild>
                    <Button.MenuButton aria-label="Callout Context Menu" aria-haspopup="true" />
                </Popover.Trigger>
            </div>
            <Popover.Content align="end" className="z-50">
                <WithExplicitFontType type="interface">
                    <div className="p-1.5 overflow-hidden relative shadow-popupSmall max-w-highlight rounded-md bg-white dark:bg-saga-gray-900 dark:border dark:border-saga-gray-800 pointer-events-auto z-50">
                        <div className="flex flex-col w-full space-y-1">
                            <div className="flex flex-row px-1.5 space-x-1 items-center">
                                <p>Color:</p>
                                <div className="flex flex-row">
                                    {calloutColorSuggestions.map((currentColor, i) => (
                                        <Tooltip key={i} placement={'top'} content={currentColor.label}>
                                            <div className="p-0.5">
                                                <Button.ColorButton
                                                    color={currentColor.value}
                                                    label={currentColor.label}
                                                    testId={currentColor.label}
                                                    isSelected={currentColor.value === background}
                                                    onMouseDown={() => {
                                                        onBackgroundChange(currentColor.value);
                                                    }}
                                                />
                                            </div>
                                        </Tooltip>
                                    ))}
                                </div>
                            </div>
                            <Button.Plain onClick={onToggleIcon} onMouseDown={onToggleIcon}>
                                <Button.SmallPadding>
                                    <div className="flex items-center space-x-2">
                                        <Smile size={14} />
                                        <p className="text-sm">
                                            {t(isIconDefined ? 'common.remove_icon' : 'common.add_icon')}
                                        </p>
                                    </div>
                                </Button.SmallPadding>
                            </Button.Plain>
                            <Button.Plain onClick={onRemove} onMouseDown={onRemove}>
                                <Button.SmallPadding>
                                    <div className="flex items-center space-x-2">
                                        <Trash size={14} />
                                        <p className="text-sm">{t('common.remove_callout')}</p>
                                    </div>
                                </Button.SmallPadding>
                            </Button.Plain>
                        </div>
                    </div>
                </WithExplicitFontType>
            </Popover.Content>
        </Popover.Root>
    );
}

export const calloutPlugin = SagaEditor.Plugins.createBlockPlugin({
    match: isCallout,
    normalizers: [SagaEditor.Normalizers.calloutNormalizer],
    Component({ children, element }) {
        const { t } = useTranslation();
        const { canEdit } = SagaEditor.useEditorContext();
        const editor = useSlateStatic();

        const updateIcon = (icon: { type: 'emoji'; colons: string } | undefined) => {
            const path = ReactEditor.findPath(editor, element);

            Transforms.setNodes(editor, { icon } as Partial<CalloutType>, {
                at: path,
            });
        };

        const removeCallout = () => {
            const path = ReactEditor.findPath(editor, element);

            Transforms.unwrapNodes(editor, { at: path });
        };

        const onBackgroundChange = (bg: string) => {
            const path = ReactEditor.findPath(editor, element);

            Transforms.setNodes(editor, { backgroundColor: bg } as Partial<CalloutType>, {
                at: path,
            });
        };

        const [{ darkMode }] = useInterfaceSettings();

        const isIconDefined = Boolean(element.icon);

        const firstChildrenType = (() => {
            try {
                const firstChildren = element.children[0] as SagaElement | undefined;

                if (firstChildren == null) return BlockType.PARAGRAPH;

                return (
                    findFirstChildren(
                        firstChildren,
                        (element: SagaElement) =>
                            element.type !== BlockType.BLOCK_CONTAINER && element.type !== BlockType.INDENT,
                    )?.type ?? BlockType.PARAGRAPH
                );
            } catch (error) {
                return BlockType.PARAGRAPH;
            }
        })();

        const features = canEdit
            ? {
                  updateIcon,
                  removeCallout,
                  onBackgroundChange,
              }
            : undefined;

        return (
            <div className="callout">
                <div
                    className={`my-1.5 flex items-start rounded flex-row w-full py-2 px-4 ${element.backgroundColor} bg-opacity-50 dark:bg-saga-gray-900`}
                    id={element.id}
                    data-testid={`callout-${element.backgroundColor}`}
                >
                    {element.icon && (
                        <div
                            contentEditable={false}
                            className={classNames('mr-2 select-none', {
                                'mt-0':
                                    firstChildrenType !== BlockType.HEADING_1 &&
                                    firstChildrenType !== BlockType.HEADING_2,
                                'mt-[0.4rem]': firstChildrenType === BlockType.HEADING_1,
                                'mt-[0.2rem]': firstChildrenType === BlockType.HEADING_2,
                            })}
                        >
                            {features ? (
                                <IconPicker
                                    darkMode={darkMode}
                                    icon={element.icon}
                                    onChange={(emoji) => {
                                        if (emoji.colons) {
                                            track('add-emoji', { source: 'callout' });
                                            features.updateIcon({ colons: emoji.colons, type: 'emoji' });
                                        }
                                    }}
                                    top={38}
                                    onRemove={() => features.updateIcon(undefined)}
                                >
                                    {(props) => (
                                        <IconPicker.Button
                                            disabled={false}
                                            icon={element.icon}
                                            onClick={() => {
                                                props.onClick();
                                            }}
                                            label={t('common.change_icon') as string}
                                            ref={props.ref}
                                            size={24}
                                        />
                                    )}
                                </IconPicker>
                            ) : (
                                <Button.SmallPadding>
                                    <React.Suspense fallback={null}>
                                        <Emoji emoji={element.icon.colons} size={24} />
                                    </React.Suspense>
                                </Button.SmallPadding>
                            )}
                        </div>
                    )}
                    <div
                        className={classNames('w-full text-saga-text', {
                            'mt-[0.1rem]': !firstChildrenType.startsWith('heading'),
                            'max-w-[calc(100%_-_72px)]': isIconDefined,
                            'max-w-[calc(100%_-_58px)] mx-auto': !isIconDefined,
                            'text-zinc-200': darkMode,
                        })}
                    >
                        {children}
                    </div>
                    {features && (
                        <ContextMenu
                            onRemove={features.removeCallout}
                            background={element.backgroundColor}
                            onBackgroundChange={features.onBackgroundChange}
                            onToggleIcon={() =>
                                isIconDefined
                                    ? features.updateIcon(undefined)
                                    : features.updateIcon({ colons: 'bulb', type: 'emoji' })
                            }
                            isIconDefined={isIconDefined}
                        />
                    )}
                </div>
            </div>
        );
    },
});
