import { debugLog } from '@/utils';
import { auth } from '@/firebase';
import { captureMessage } from '@sentry/browser';
import { track } from '@/analytics';

export default function Logout() {
    const reqOpen = window.indexedDB.open('firebaseLocalStorageDb', 1);
    reqOpen.onsuccess = (event: any) => {
        debugLog('firebaseLocalStorageDb opened');
        const db = event.target.result;
        const tx = db.transaction('firebaseLocalStorage', 'readonly');
        const os = tx.objectStore('firebaseLocalStorage');
        os.openCursor().onsuccess = (event: any) => {
            const cursor = event.target.result;
            if (cursor) {
                const uid = cursor.value.value.uid;
                track('Logout via url', { uid });
                cursor.continue();
            } else {
                debugLog('No more entries');
            }
        };
    };
    reqOpen.onerror = () => debugLog("Couldn't open firebaseLocalStorageDb");
    reqOpen.onblocked = () => debugLog("Couldn't open firebaseLocalStorageDb due to the operation being blocked");

    auth.signOut()
        .then(() => captureMessage('Signed out from url'))
        .catch((error) =>
            captureMessage(error, { extra: { context: 'Error in firebase auth when signing out from url' } }),
        );

    return null;
}
