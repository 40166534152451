import React from 'react';

type FilledStarProps = {
    className?: string;
    size?: string;
};

export function FilledStar({ className, size }: FilledStarProps) {
    return (
        <svg className={className} width={size} height={size} fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10 1.667l2.575 5.216 5.758.842-4.166 4.058.983 5.734L10 14.808l-5.15 2.709.983-5.734-4.166-4.058 5.758-.842L10 1.667z"
                fill="currentColor"
                stroke="currentColor"
                strokeWidth="1.667"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
