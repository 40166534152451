import { stringToColor } from '@/../../shared/src/utils/Colors';
import { useDesktopContext } from '@/components/DesktopContext';
import { NotificationsIcon } from '@/components/icons';
import Button from '@/components/styled/Button';
import { AVAILABLE_COLORS } from '@/constants';
import useOnClickOutside from '@/hooks/useClickOutside';
import useInterfaceSettings from '@/hooks/useInterfaceSettings';
import useMobile from '@/hooks/useMobile';
import useOnEscape from '@/hooks/useOnEscape';
import useIntersection from '@/hooks/useOnIntersect';
import * as api from '@saga/api';
import {
    MentionNotification,
    NotificationsConnectionQuery,
    TaskMentionNotification,
    TaskAssignmentNotification,
} from '@saga/api';
import classNames from 'classnames';
import { DateTime } from 'luxon';
import * as R from 'ramda';
import React, { useState } from 'react';
import { X } from 'react-feather';
import { useHistory } from 'react-router-dom';
import MemberAvatar from '../MemberAvatar';
import Tooltip from '../popover/Tooltip';
import { useSetSettings } from '../settings/SettingsProvider';
import { useToastContext } from '../toast/ToastContext';
import { OnInviteAccepted, OnPageInviteAccepted } from '../toast/ToastLibrary';
import { useCurrentWorkspace, useWorkspaceContext } from '../WorkspaceContext';
import { useWorkspaces } from '../WorkspacesContext';
import WorkspaceAvatar from './WorkspaceAvatar';
import { useTranslation } from 'react-i18next';
import Spinner from '@/components/loading/Spinner';
import { useSharedPages } from '@/components/SharedPagesProvider';
import { isFeatureEnabled } from '@/analytics';
import { FeatureFlag } from '../../../../shared/src/utils/FeatureFlags';
import { useUserContext } from '../UserContext';
import ChooseWorkspaceModal from '../ChooseWorkspaceModal';
import { assertNonNull } from '@saga/shared';
import { formatTimeAgo } from '@/utils/dateUtils';

type Props = {
    onClose: () => void;
};

type NotificationEdge = NotificationsConnectionQuery['notificationsConnection']['edges'][number];

function groupEdges(edges: NotificationEdge[]): Record<string, NotificationEdge[]> {
    return R.groupBy(
        (notification) => formatTimeAgo(DateTime.fromJSDate(new Date(notification.node.createdAt))),
        edges,
    );
}

function NotificationsSidebar({ onClose }: Props) {
    const { t } = useTranslation();
    const history = useHistory();
    const { showToast } = useToastContext();
    const { id: currentWorkspaceId } = useCurrentWorkspace();
    const setSettings = useSetSettings();
    const { workspaces } = useWorkspaces();
    const isMobile = useMobile();
    const [, setInterfaceSettings] = useInterfaceSettings();
    const sharedPages = useSharedPages();
    const { permissions } = useUserContext();
    const [showChooseWorkspaceModal, setShowChooseWorkspaceModal] = useState(false);
    const { currentWorkspace } = useWorkspaceContext();

    const [markNotificationsAsRead] = api.useMarkNotificationsAsReadMutation();
    const notificationsResult = api.useNotificationsConnectionQuery({
        fetchPolicy: 'cache-and-network',
        variables: { first: 10, last: null, before: null, after: null },
    });
    const [acceptWorkspaceInvite, { loading: acceptingInvite }] = api.useAcceptInvitationMutation({
        refetchQueries: [api.SpacesDocument, api.UserInvitesDocument, api.UserDataDocument],
    });

    const [acceptPageInvite, { loading: acceptingPageInvite }] = api.useAcceptPageInvitationMutation();

    const connection = notificationsResult.data?.notificationsConnection;

    const groupedEdges = groupEdges(connection?.edges ?? []);

    function loadMore(after: string) {
        notificationsResult.fetchMore({
            variables: {
                after,
            },
        });
    }

    const intersectionRef = useIntersection<HTMLDivElement>(() => {
        if (connection?.pageInfo.endCursor) {
            loadMore(connection.pageInfo.endCursor);
        }
    }, [notificationsResult]);

    const onCloseHandler = () => {
        onClose();

        const notificationsToMark = notificationsResult.data?.notificationsConnection.edges
            .map((n) => n.node)
            .filter((n) => n.readAt === null)
            .map((n) => n.id);

        if (!notificationsToMark || notificationsToMark.length === 0) return;

        markNotificationsAsRead({
            variables: {
                input: {
                    ids: notificationsToMark,
                },
            },
            refetchQueries: [api.UnreadNotificationsCounterDocument],
        });
    };

    useOnEscape(onCloseHandler, true);

    const viewState = (() => {
        const notificationsLength = notificationsResult.data?.notificationsConnection.edges.length ?? 0;

        if (notificationsResult.loading && notificationsLength === 0) {
            return 'loading';
        } else if (notificationsLength === 0 && notificationsResult.loading === false) {
            return 'empty';
        } else {
            return 'loaded';
        }
    })();

    const onAcceptPageInvite = async (id: string) => {
        const { data } = await acceptPageInvite({
            variables: { input: { id } },
        });

        const pageId = data?.acceptPageInvitation.id;

        if (pageId) {
            showToast(
                OnPageInviteAccepted(() => {
                    history.push(`/${pageId}`);
                }),
            );
        }
    };

    const onAcceptWorkspaceInvite = async (id: string, activeSubscription: boolean) => {
        if (isFeatureEnabled(FeatureFlag.allowBilling) && !permissions.canCreateFreeSpace && !activeSubscription) {
            setShowChooseWorkspaceModal(true);
            return;
        }
        const result = await acceptWorkspaceInvite({
            variables: {
                input: { id },
            },
        }).then((result) => result.data?.acceptInvitation);

        if (!result) return;

        onCloseHandler();

        showToast(
            OnInviteAccepted(() => {
                const spaceUrlKey = result.urlKey;
                history.push(`/s/${spaceUrlKey}`);
            }),
        );
    };

    const onWorkspaceInviteManage = () => {
        onCloseHandler();
        setSettings('invites');
    };

    const onGoToSharedPage = React.useCallback(
        (pageId: string) => {
            history.push(`/${pageId}`);
        },
        [history],
    );

    const onGoToPage = (notification: MentionNotification) => {
        if (isMobile) {
            onCloseHandler();
            setInterfaceSettings({ fixedSidebar: false });
        }

        if (notification.spaceId === currentWorkspaceId) {
            onCloseHandler();
        }

        const url = new URL(notification.mentionBlockUrl);
        history.push(url.pathname + url.hash);
    };

    const onGoToTask = (notification: TaskMentionNotification | TaskAssignmentNotification) => {
        if (isMobile) {
            setInterfaceSettings({ fixedSidebar: false });
        }

        const spaceUrlKey = workspaces.find((space) => space.id === notification.spaceId)?.urlKey;
        assertNonNull(spaceUrlKey);

        const path = `/s/${spaceUrlKey}/t/${notification.taskId}`;

        onCloseHandler();
        history.push(notification.__typename === 'TaskMentionNotification' ? `${path}#${notification.blockId}` : path);
    };

    return (
        <NotificationsSidebar.Container onClickOutside={onCloseHandler}>
            {
                {
                    empty: <NotificationsSidebar.Empty onClose={onCloseHandler} />,
                    loading: <NotificationsSidebar.Loading onClose={onCloseHandler} />,
                    loaded: (
                        <>
                            {Object.entries(groupedEdges).map(([key, edges]) => {
                                return (
                                    <NotificationsSidebar.NotificationsGroup key={key}>
                                        <NotificationsSidebar.Header onClose={onCloseHandler}>
                                            {(isSticky) => (
                                                <>
                                                    {isSticky && <NotificationsIcon className="w-4 h-4" />}
                                                    <p className="text-xl">{key}</p>
                                                </>
                                            )}
                                        </NotificationsSidebar.Header>
                                        {edges.map(({ node: notification }, i) => {
                                            if (notification.__typename === 'MentionNotification') {
                                                return (
                                                    <NotificationsSidebar.MentionNotification
                                                        notification={notification}
                                                        key={notification.id}
                                                        datatestId={`notification-${i}`}
                                                        onGoToPage={() => onGoToPage(notification)}
                                                    />
                                                );
                                            } else if (notification.__typename === 'TaskAssignmentNotification') {
                                                return (
                                                    <NotificationsSidebar.TaskAssignmentNotification
                                                        notification={notification}
                                                        key={notification.id}
                                                        datatestId={`notification-${i}`}
                                                        onGoToTask={onGoToTask}
                                                    />
                                                );
                                            } else if (notification.__typename === 'TaskMentionNotification') {
                                                return (
                                                    <NotificationsSidebar.TaskMentionNotification
                                                        notification={notification}
                                                        key={notification.id}
                                                        datatestId={`notification-${i}`}
                                                        onGoToTask={onGoToTask}
                                                    />
                                                );
                                            } else if (notification.__typename === 'PageInviteNotification') {
                                                return (
                                                    <NotificationsSidebar.PageInviteNotification
                                                        loading={acceptingPageInvite}
                                                        notification={notification}
                                                        key={notification.id}
                                                        datatestId={`notification-${i}`}
                                                        onAcceptInvite={onAcceptPageInvite}
                                                        sharedPageIds={sharedPages.map(({ id }) => id)}
                                                        onGoToPage={onGoToSharedPage}
                                                    />
                                                );
                                            } else {
                                                return (
                                                    <NotificationsSidebar.WorkspaceCollaborationNotification
                                                        notification={notification}
                                                        key={notification.id}
                                                        onAcceptInvite={onAcceptWorkspaceInvite}
                                                        onAcceptingInvite={acceptingInvite}
                                                        onManage={onWorkspaceInviteManage}
                                                        datatestId={`notification-${i}`}
                                                        workspaceIds={workspaces.map((w) => w.id)}
                                                    />
                                                );
                                            }
                                        })}
                                    </NotificationsSidebar.NotificationsGroup>
                                );
                            })}

                            {connection?.pageInfo.hasNextPage && (
                                <>
                                    <div ref={intersectionRef}></div>
                                    <div className="flex justify-center p-6">
                                        <NotificationsSidebar.UnderlineButton
                                            onClick={() => {
                                                if (connection?.pageInfo.endCursor) {
                                                    loadMore(connection.pageInfo.endCursor);
                                                }
                                            }}
                                        >
                                            {t('sidebar.notifications.load_more')}
                                        </NotificationsSidebar.UnderlineButton>
                                    </div>
                                </>
                            )}

                            {showChooseWorkspaceModal && (
                                <ChooseWorkspaceModal
                                    title={t('billing.choose_workspace_modal.title')}
                                    subTitle={t('billing.choose_workspace_modal.description')}
                                    spaceCtaLabel={t('billing.choose_workspace_modal.go_to_plans')}
                                    showOnlyFreeSpaces
                                    onClose={() => setShowChooseWorkspaceModal(false)}
                                    onSelect={(urlKey: string) => {
                                        setShowChooseWorkspaceModal(false);
                                        if (urlKey === currentWorkspace.urlKey) {
                                            setSettings('plans');
                                        } else {
                                            window.open(
                                                `${window.location.origin}/s/${urlKey}/pages?settings=plans`,
                                                '_blank',
                                            );
                                        }
                                    }}
                                />
                            )}
                        </>
                    ),
                }[viewState]
            }
        </NotificationsSidebar.Container>
    );
}

NotificationsSidebar.Empty = function EmptyState({ onClose }: { onClose: () => void }) {
    const { t } = useTranslation();
    return (
        <>
            <NotificationsSidebar.Header onClose={onClose}>
                {() => <NotificationsIcon className="w-4 h-4" />}
            </NotificationsSidebar.Header>
            <div className="flex py-5 px-7 justify-center">
                <p>{t('sidebar.notifications.dont_have_notifications')}</p>
            </div>
        </>
    );
};

NotificationsSidebar.Loading = function EmptyState({ onClose }: { onClose: () => void }) {
    return (
        <>
            <NotificationsSidebar.Header onClose={onClose}>
                {() => <NotificationsIcon className="w-4 h-4" />}
            </NotificationsSidebar.Header>
            <div className="p-6 flex justify-center items-center">
                <svg
                    className="animate-spin -ml-1 mr-3 h-7 w-7 text-saga-orange"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                >
                    <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                    ></circle>
                    <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                </svg>
            </div>
        </>
    );
};

NotificationsSidebar.UnderlineButton = function UnderlineButton({
    children,
    onClick,
}: {
    children: React.ReactNode;
    onClick?: () => void;
}) {
    return (
        <Button.Plain onClick={onClick}>
            <Button.BasePadding>
                <span className="underline underline-offset-2">{children}</span>
            </Button.BasePadding>
        </Button.Plain>
    );
};

NotificationsSidebar.Container = function Container({
    children,
    onClickOutside,
}: {
    children: React.ReactNode;
    onClickOutside: () => void;
}) {
    const ref = React.useRef<HTMLDivElement>(null);
    const isMobile = useMobile();

    useOnClickOutside(ref, onClickOutside);

    return (
        <div ref={ref} className="absolute h-screen w-screen sm:w-[370px] flex-none flex inset-0 z-90">
            <div
                data-testid="notifications-sidebar"
                className="bg-[#F8F8F8] dark:bg-saga-gray-1000 flex-grow overflow-y-auto shadow-right"
                style={{ width: isMobile ? '100%' : 'calc(75% - 250px)' }}
            >
                {children}
            </div>
        </div>
    );
};

NotificationsSidebar.NotificationsGroup = function NotificationsGroup({ children }: { children: React.ReactNode }) {
    return <div className="flex flex-col">{children}</div>;
};

NotificationsSidebar.Notification = function Notification({
    read,
    createdAt,
    children,
    avatar,
    datatestId,
}: {
    read: boolean;
    createdAt: string;
    children?: React.ReactNode;
    avatar: React.ReactNode;
    datatestId?: string;
}) {
    const date = DateTime.fromJSDate(new Date(createdAt));
    const isToday = date.hasSame(DateTime.local(), 'day');
    const isThisYear = date.hasSame(DateTime.local(), 'year');

    return (
        <div data-testid={datatestId} className="relative border-b dark:border-zinc-600">
            {!read && <div className="absolute left-0 top-0 bottom-0 bg-red-500 w-1" />}
            <div
                className={classNames('p-6 flex flex-row space-x-4', {
                    'bg-white dark:bg-zinc-900': !read,
                    'bg-[#F8F8F8] dark:bg-saga-gray-1000': read,
                })}
            >
                {avatar}
                <div className="flex-1 flex flex-col space-y-3">
                    {children}
                    <p className="font-light dark:text-gray-400 text-gray-600 text-sm">
                        {isToday
                            ? date.toRelative({ locale: 'en', style: 'long' })
                            : date.toFormat(`LLL dd${isThisYear ? '' : ' yyyy'}, HH:MM`)}
                    </p>
                </div>
            </div>
        </div>
    );
};

NotificationsSidebar.NotificationBody = function Body({ children }: { children: React.ReactNode }) {
    return <div className="flex flex-col space-y-2 -mt-[1px]">{children}</div>;
};

NotificationsSidebar.WorkspaceCollaborationNotification = function WorkspaceNotification({
    notification,
    workspaceIds,
    onAcceptInvite,
    onAcceptingInvite,
    onManage,
    datatestId,
}: {
    notification: api.WorkspaceCollaborationNotification;
    onAcceptInvite: (id: string, activeSubscription: boolean) => void;
    onAcceptingInvite: boolean;
    onManage: () => void;
    workspaceIds: string[];
    datatestId?: string;
}) {
    const { t } = useTranslation();
    const history = useHistory();
    return (
        <NotificationsSidebar.Notification
            avatar={
                <Tooltip content={notification.spaceTitle} placement="bottom">
                    <span className="h-min w-min">
                        <WorkspaceAvatar
                            workspace={{ title: notification.spaceTitle, color: notification.spaceColor }}
                        />
                    </span>
                </Tooltip>
            }
            createdAt={notification.createdAt}
            read={notification.readAt !== null}
            datatestId={datatestId}
        >
            <NotificationsSidebar.NotificationBody>
                {
                    {
                        [api.WorkspaceCollaborationNotificationType.WorkspaceInvite]: (
                            <>
                                <p data-testid="mention-notification-text">
                                    {t('sidebar.notifications.invite_message', { name: notification.username })}{' '}
                                    <span className="font-bold">{notification.spaceTitle}</span>
                                </p>
                                {notification.inviteId && workspaceIds.includes(notification.spaceId) === false && (
                                    <div className="flex flex-row space-x-2">
                                        <Button
                                            onClick={() =>
                                                notification.inviteId &&
                                                onAcceptInvite(notification.inviteId, notification.activeSubscription)
                                            }
                                            disabled={onAcceptingInvite}
                                            variant="secondary"
                                            className="w-min text-sm"
                                            size="action"
                                        >
                                            <div className="flex items-center space-x-2">
                                                {onAcceptingInvite && <Spinner size={16} />}
                                                <span>{t('sidebar.notifications.accept')}</span>
                                            </div>
                                        </Button>
                                        <NotificationsSidebar.UnderlineButton onClick={onManage}>
                                            {t('sidebar.notifications.manage')}
                                        </NotificationsSidebar.UnderlineButton>
                                    </div>
                                )}
                                {!notification.inviteId && workspaceIds.includes(notification.spaceId) && (
                                    <div className="flex flex-row space-x-2">
                                        <Button
                                            onClick={() => history.push(`/s/${notification.spaceUrlKey}`)}
                                            variant="secondary"
                                            className="w-min text-sm"
                                            size="action"
                                        >
                                            {t('sidebar.notifications.go_to_workspace')}
                                        </Button>
                                    </div>
                                )}
                            </>
                        ),
                        [api.WorkspaceCollaborationNotificationType.WorkspaceJoined]: (
                            <>
                                <p data-testid="mention-notification-text">
                                    {t('sidebar.notifications.user_joined', { name: notification.username })}{' '}
                                    <span className="font-bold">{notification.spaceTitle}</span>
                                </p>
                            </>
                        ),
                        [api.WorkspaceCollaborationNotificationType.WorkspaceLeft]: (
                            <>
                                <p data-testid="mention-notification-text">
                                    {t('sidebar.notifications.user_left', { name: notification.username })}{' '}
                                    <span className="font-bold">{notification.spaceTitle}</span>
                                </p>
                            </>
                        ),
                        [api.WorkspaceCollaborationNotificationType.WorkspaceMemberRemoved]: (
                            <>
                                <p data-testid="mention-notification-text">
                                    {t('sidebar.notifications.user_removed', { name: notification.username })}{' '}
                                    <span className="font-bold">{notification.spaceTitle}</span>
                                </p>
                            </>
                        ),
                    }[notification.type]
                }
            </NotificationsSidebar.NotificationBody>
        </NotificationsSidebar.Notification>
    );
};

NotificationsSidebar.MentionNotification = function MentionNotification({
    notification,
    datatestId,
    onGoToPage,
}: {
    notification: api.MentionNotification;
    datatestId?: string;
    onGoToPage: (event: React.SyntheticEvent) => void;
}) {
    const { t } = useTranslation();
    return (
        <NotificationsSidebar.Notification
            avatar={
                <MemberAvatar
                    size="5"
                    name={notification.username}
                    color={stringToColor(notification.userId, AVAILABLE_COLORS)}
                />
            }
            createdAt={notification.createdAt}
            read={notification.readAt !== null}
            datatestId={datatestId}
        >
            <NotificationsSidebar.NotificationBody>
                <p data-testid="mention-notification-text">
                    {`${notification.username} ${t('sidebar.notifications.mentioned_you')} `}
                    <span className="font-bold">{notification.pageTitle}</span>
                </p>
                <Button onClick={onGoToPage} variant="secondary" className="w-min text-sm" size="action">
                    {t('sidebar.notifications.go_to_page')}
                </Button>
            </NotificationsSidebar.NotificationBody>
        </NotificationsSidebar.Notification>
    );
};

NotificationsSidebar.TaskMentionNotification = function TaskMentionNotification({
    notification,
    datatestId,
    onGoToTask,
}: {
    notification: api.TaskMentionNotification;
    datatestId?: string;
    onGoToTask: (notification: TaskMentionNotification) => void;
}) {
    const { t } = useTranslation();
    return (
        <NotificationsSidebar.Notification
            avatar={
                <MemberAvatar
                    size="5"
                    name={notification.username}
                    color={stringToColor(notification.userId, AVAILABLE_COLORS)}
                />
            }
            createdAt={notification.createdAt}
            read={notification.readAt !== null}
            datatestId={datatestId}
        >
            <NotificationsSidebar.NotificationBody>
                <p data-testid="mention-notification-text">
                    {`${notification.username} ${t('sidebar.notifications.mentioned_you')} `}
                    <span className="font-bold">{notification.taskTitle}</span>
                </p>
                <Button
                    onClick={() => onGoToTask(notification)}
                    variant="secondary"
                    className="w-min text-sm"
                    size="action"
                >
                    {t('sidebar.notifications.go_to_task')}
                </Button>
            </NotificationsSidebar.NotificationBody>
        </NotificationsSidebar.Notification>
    );
};

NotificationsSidebar.TaskAssignmentNotification = function TaskAssignmentNotification({
    notification,
    datatestId,
    onGoToTask,
}: {
    notification: api.TaskAssignmentNotification;
    datatestId?: string;
    onGoToTask: (notification: TaskAssignmentNotification) => void;
}) {
    const { t } = useTranslation();

    return (
        <NotificationsSidebar.Notification
            avatar={
                <MemberAvatar
                    size="5"
                    name={notification.username}
                    color={stringToColor(notification.userId, AVAILABLE_COLORS)}
                />
            }
            createdAt={notification.createdAt}
            read={notification.readAt !== null}
            datatestId={datatestId}
        >
            <NotificationsSidebar.NotificationBody>
                <p data-testid="mention-notification-text">
                    {`${notification.username} ${notification.action === api.TaskAssignAction.Assign ? t('sidebar.notifications.assigned_you') : t('sidebar.notifications.unassigned_you')} `}
                    <span className="font-bold">{notification.taskTitle}</span>
                </p>
                {notification.action === api.TaskAssignAction.Assign && (
                    <Button
                        onClick={() => onGoToTask(notification)}
                        variant="secondary"
                        className="w-min text-sm"
                        size="action"
                    >
                        {t('sidebar.notifications.go_to_task')}
                    </Button>
                )}
            </NotificationsSidebar.NotificationBody>
        </NotificationsSidebar.Notification>
    );
};

NotificationsSidebar.PageInviteNotification = function PageInviteNotification({
    notification,
    datatestId,
    onAcceptInvite,
    sharedPageIds,
    onGoToPage,
    loading = false,
}: {
    notification: api.PageInviteNotification;
    sharedPageIds: string[];
    datatestId?: string;
    loading?: boolean;
    onAcceptInvite: (id: string) => void;
    onGoToPage: (pageId: string) => void;
}) {
    const { t } = useTranslation();

    const renderAccessory = React.useCallback(() => {
        if (sharedPageIds.includes(notification.pageId)) {
            return (
                <Button
                    onClick={() => onGoToPage(notification.pageId)}
                    variant="secondary"
                    className="w-min text-sm"
                    size="action"
                >
                    {t('sidebar.notifications.go_to_page')}
                </Button>
            );
        }

        if (!notification.inviteId) return null;
        if (loading) return <Spinner />;

        return (
            <Button
                disabled={loading}
                onClick={() => notification.inviteId && onAcceptInvite(notification.inviteId)}
                variant="secondary"
                className="w-min text-sm"
                size="action"
            >
                {t('sidebar.notifications.accept')}
            </Button>
        );
    }, [notification, sharedPageIds, loading, onAcceptInvite, t, onGoToPage]);

    return (
        <NotificationsSidebar.Notification
            avatar={
                <MemberAvatar
                    size="5"
                    name={notification.username}
                    color={stringToColor(notification.userId, AVAILABLE_COLORS)}
                />
            }
            createdAt={notification.createdAt}
            read={notification.readAt !== null}
            datatestId={datatestId}
        >
            <NotificationsSidebar.NotificationBody>
                <p data-testid="pageinvite-notification-text">
                    <span className="font-bold">{notification.username}</span>
                    {` ${t('sidebar.notifications.invite_page_message')} `}
                    <span className="font-bold">{notification.pageTitle}</span>
                </p>
                {renderAccessory()}
            </NotificationsSidebar.NotificationBody>
        </NotificationsSidebar.Notification>
    );
};

NotificationsSidebar.Header = function Header({
    children,
    onClose,
}: {
    children(isSticky: boolean): React.ReactNode;
    onClose: () => void;
}) {
    const { t } = useTranslation();
    const ref = React.useRef<HTMLDivElement>(null);
    const [isSticky, setSticky] = React.useState(true);
    const { useMacOsLayout } = useDesktopContext();
    React.useEffect(() => {
        const el = ref.current;
        if (el) {
            const observer = new IntersectionObserver(
                ([e]) => {
                    if (e.intersectionRatio === 0) {
                        setSticky(true);
                    } else if (e.intersectionRatio === 1) {
                        setSticky(false);
                    }
                },
                { threshold: [0, 1] },
            );

            observer.observe(el);

            return () => {
                observer.unobserve(el);
            };
        }

        return;
    }, []);

    return (
        <>
            <div style={{ height: 1, marginTop: -1, opacity: 0 }} ref={ref}></div>
            <div className="bg-[#F8F8F8] dark:bg-saga-gray-1000 z-90 sticky top-0 flex flex-row justify-between items-center py-4 pl-6 pr-4 border-b dark:border-zinc-600">
                <div
                    className={classNames('flex flex-row items-center space-x-2', {
                        'ml-1': !useMacOsLayout,
                        'ml-14': useMacOsLayout,
                    })}
                >
                    {children(isSticky)}
                </div>
                <div className="absolute right-0 top-0 bottom-0 flex items-center pr-3">
                    {isSticky && (
                        <Button.Plain onClick={onClose}>
                            <Button.BasePadding>
                                <span className="sr-only">{t('common.close_notifications')}</span>
                                <X size={18} />
                            </Button.BasePadding>
                        </Button.Plain>
                    )}
                </div>
            </div>
        </>
    );
};

export default NotificationsSidebar;
