import { Editor } from 'slate';
import invariant from 'tiny-invariant';
import { Awareness } from 'y-protocols/awareness';
import { absolutePositionToRelativePosition } from '../cursor/utils';
import { YjsEditor } from './yjsEditor';
import * as Sentry from '@sentry/browser';
import { Cursor } from '..';
import { SagaLocation } from '../..';
import { areLocationsEqual } from '../../SagaLocation';

const AWARENESS: WeakMap<Editor, Awareness> = new WeakMap();

export interface CursorEditor extends YjsEditor {
    awareness: Awareness;
    cleanup(): void;
}

export const CursorEditor = {
    awareness(editor: Editor): Awareness {
        const awareness = AWARENESS.get(editor);
        invariant(awareness, 'CursorEditor without attaches awareness');
        return awareness;
    },

    updateCursor: (editor: Editor): void => {
        const sharedType = YjsEditor.sharedType(editor);
        const { selection } = editor;

        try {
            const anchor = selection && absolutePositionToRelativePosition(sharedType, selection.anchor);
            const focus = selection && absolutePositionToRelativePosition(sharedType, selection.focus);
            const awareness = CursorEditor.awareness(editor);

            const currentLocalState = awareness.getLocalState();

            if (currentLocalState) {
                const currentCursors = currentLocalState?.cursors ?? [];
                const existingCursor = currentCursors.find((cursor: Cursor) =>
                    areLocationsEqual(cursor.location, editor.location),
                );

                if (existingCursor) {
                    awareness.setLocalState({
                        ...currentLocalState,
                        cursors: currentCursors.map((cursor: Cursor) => {
                            if (areLocationsEqual(cursor.location, editor.location)) {
                                return {
                                    ...cursor,
                                    anchor,
                                    focus,
                                };
                            }

                            return cursor;
                        }),
                    });
                } else {
                    awareness.setLocalState({
                        ...currentLocalState,
                        cursors: [...currentCursors, { location: editor.location, anchor, focus }],
                    });
                }
            } else {
                awareness.setLocalState({
                    cursors: [{ location: editor.location, anchor, focus }],
                });
            }
        } catch (e) {
            Sentry.captureException(e);
            console.warn(e);
        }
    },
};

export function withCursor(editor: Editor, awareness: Awareness | null, location: SagaLocation.BlocksLocation): Editor {
    const e = editor;

    if (awareness) {
        AWARENESS.set(e, awareness);
        e.awareness = awareness;
    }

    e.location = location;

    const { onChange, cleanup } = editor;
    let cleanedUp = false;

    e.onChange = () => {
        setTimeout(() => {
            // we need to make sure that the editor was not cleaned up in the meanwhile
            if (!cleanedUp) {
                CursorEditor.updateCursor(e);
            }
        }, 0);

        if (onChange) {
            onChange();
        }
    };

    e.cleanup = () => {
        // Reset change handler
        e.onChange = onChange;

        const currentLocalState = awareness?.getLocalState();
        awareness?.setLocalState({
            ...currentLocalState,
            cursors: (currentLocalState?.cursors ?? []).filter(
                (cursor: Cursor) => !areLocationsEqual(cursor.location, e.location),
            ),
        });

        // remove editor from awareness map
        AWARENESS?.delete(e);
        cleanup();
        cleanedUp = true;
    };

    return e;
}
