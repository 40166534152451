import React, { useState } from 'react';
import * as api from '@saga/api';

import { CustomPromptType } from '@/../../api/src';
import useAISuggestedPrompts from '@/hooks/useAISuggestedPrompts';
import { AI } from '@/assets/icons';
import classNames from 'classnames';
import { ChevronLeft, ChevronRight, FileText, RotateCw, Star, X } from 'react-feather';
import Spinner from '@/components/loading/Spinner';
import Tooltip from '@/components/popover/Tooltip';
import * as Popover from '@radix-ui/react-popover';
import Button from '@/components/styled/Button';
import Input from '@/components/styled/Input';
import { OpacityTransition } from '@/components/Transitions';
import { track } from '@/analytics';
import { AIMessage } from '@/../../shared/src';
import { t } from 'i18next';
import AICopyButton from '@/components/editor/ai/AICopyButton';

interface ButtonProps {
    loading: boolean;
    prompt?: api.CustomAiPrompt;
    onAdd: (title: string) => void;
    onDelete: () => void;
}

interface InputProps {
    promptText: string;
    promptType: CustomPromptType;
    canGoBack: boolean;
    canGoForward: boolean;
    messageIndex: number;
    assistantMessages: AIMessage[];
    referencedDocuments: string[];
    isOKStatus: boolean;
    trackButton: (name: string) => void;
    setMessageIndex: React.Dispatch<React.SetStateAction<number>>;
    onAsk: (question: string, context?: string | undefined) => void;
    onDiscard: () => void;
}

const FavButton = ({ onAdd, onDelete, prompt, loading }: ButtonProps) => {
    const [titleInputOpen, setTitleInputOpen] = React.useState(false);
    const [title, setTitle] = React.useState<string>('');

    React.useEffect(() => {
        setTitle('');
    }, [titleInputOpen]);

    const handleSubmit = React.useCallback(() => {
        if (title) {
            onAdd?.(title);
            setTitleInputOpen(false);
        }
    }, [title, setTitleInputOpen, onAdd]);

    return (
        <Popover.Root open={titleInputOpen} onOpenChange={setTitleInputOpen}>
            <Popover.Trigger asChild>
                {loading ? (
                    <>
                        <Spinner size={14} />
                    </>
                ) : (
                    <div
                        onClick={() => {
                            if (!prompt) {
                                setTitleInputOpen(true);
                                track('ai-prompt-popover-create');
                            } else {
                                onDelete?.();
                                track('ai-prompt-popover-delete');
                            }
                        }}
                        className="h-6 w-6 flex items-center justify-center cursor-pointer"
                    >
                        <Tooltip
                            content={prompt ? 'Remove from saved' : 'Save prompt'}
                            placement="top"
                            disabled={titleInputOpen}
                        >
                            <Star
                                className={classNames('hover:stroke-saga-black dark:hover:stroke-white', {
                                    'text-saga-gray-500 dark:text-saga-gray-500 fill-none': !prompt,
                                    'fill-saga-gray-500 text-saga-gray-500 dark:fill-saga-gray-500': prompt,
                                })}
                                size={14}
                            />
                        </Tooltip>
                    </div>
                )}
            </Popover.Trigger>

            <Popover.Portal>
                <Popover.Content
                    onFocusOutside={(e) => {
                        e.preventDefault();
                    }}
                    align="end"
                    className="z-100"
                >
                    <OpacityTransition tag="span" duration={200} isOpen={titleInputOpen}>
                        <div className="mt-1 outline-none w-highlight" data-testid="set-link-popover">
                            <div className="relative shadow-popupSmall dark:border dark:border-solid dark:border-zinc-600 bg-white dark:bg-zinc-700 max-w-highlight rounded-md py-1 pointer-events-auto space-y-1">
                                <div className="relative w-full flex flex-row px-1 space-x-1">
                                    <Input.Small
                                        type="text"
                                        placeholder="Prompt title..."
                                        value={title}
                                        onChange={(e) => setTitle(e.target.value)}
                                        onKeyDown={(event) => {
                                            switch (event.key) {
                                                case 'Enter':
                                                    event.preventDefault();
                                                    handleSubmit();
                                                    return;
                                            }
                                        }}
                                    />
                                    <Button.Plain type="submit" disabled={!title} onClick={handleSubmit}>
                                        <Button.SmallPadding>
                                            <span className="my-auto font-medium text-sm">Save</span>
                                        </Button.SmallPadding>
                                    </Button.Plain>
                                </div>
                            </div>
                        </div>
                    </OpacityTransition>
                </Popover.Content>
            </Popover.Portal>
        </Popover.Root>
    );
};

const AIPromptDisplay = ({
    promptText,
    promptType,
    canGoBack,
    canGoForward,
    messageIndex,
    assistantMessages,
    referencedDocuments,
    isOKStatus,
    trackButton,
    setMessageIndex,
    onAsk,
    onDiscard,
}: InputProps) => {
    const { staticPromptSuggestions, userPromptSuggestions, generateAIPrompt } = useAISuggestedPrompts();
    const [showCopyPrompt, setShowCopyPrompt] = useState(false);

    function onCopy() {
        track('ai-copy-button-prompt-text');
        navigator.clipboard.writeText(promptText);
    }

    const [addAIPrompt] = api.useAddAiPromptMutation({
        refetchQueries: [api.UserDataDocument],
        awaitRefetchQueries: true,
        onCompleted: () => setLoading(false),
    });
    const [removeAIPrompt] = api.useRemoveAiPromptMutation({
        refetchQueries: [api.UserDataDocument],
        awaitRefetchQueries: true,
        onCompleted: () => setLoading(false),
    });
    const [loading, setLoading] = React.useState(false);

    const defaultPrompt = staticPromptSuggestions[promptType]
        .flatMap((category) => category.suggestions)
        .find(
            (suggestion) =>
                generateAIPrompt(suggestion, promptType)?.trim().toLocaleLowerCase() ===
                promptText.trim().toLocaleLowerCase(),
        );

    const existingCustomPrompt = userPromptSuggestions?.find(
        (prompt) => prompt.prompt.trim().toLowerCase() === promptText.trim().toLowerCase(),
    );

    return (
        <>
            <div
                className="flex flex-row items-center justify-center mx-2 pt-1 pb-2 relative border-b border-saga-gray-200 dark:border-saga-gray-700"
                onMouseEnter={() => setShowCopyPrompt(true)}
                onMouseLeave={() => setShowCopyPrompt(false)}
            >
                <div className="h-[18px] w-[18px] flex items-center justify-center">
                    <AI className="w-[14px] h-[14px] text-saga-gray-500 dark:text-saga-gray-500" />
                </div>
                <div className="text-sm text-saga-gray-500 dark:text-saga-gray-500 flex-1 truncate mx-2">
                    {defaultPrompt?.title ?? existingCustomPrompt?.title ?? promptText}
                </div>

                <div className="flex items-center justify-center space-x-2 pr-2">
                    {showCopyPrompt && (
                        <AICopyButton
                            onCopy={onCopy}
                            text={{ before: t('common.copy_prompt'), after: t('common.prompt_copied') }}
                            id="AI-prompt-copy-button"
                        />
                    )}
                    {isOKStatus && (
                        <Tooltip content={t('ai.rerun')} placement="top">
                            <div
                                onClick={() => onAsk(existingCustomPrompt?.title ?? promptText)}
                                className="h-6 w-6 flex items-center justify-center text-saga-gray-500 hover:text-saga-black dark:hover:text-white cursor-pointer "
                            >
                                <RotateCw size={14} />
                            </div>
                        </Tooltip>
                    )}
                    {referencedDocuments.length ? (
                        <Tooltip
                            zIndex={100}
                            content={
                                <div className="flex flex-col space-y-1">
                                    {referencedDocuments.map((source, index) => (
                                        <div key={index}>{source}</div>
                                    ))}
                                </div>
                            }
                            placement="top"
                        >
                            <FileText className="text-saga-gray-500" size={14} />
                        </Tooltip>
                    ) : null}
                    {assistantMessages.length > 1 && (
                        <div className="flex flex-row items-center text-xs text-saga-gray-500">
                            <span
                                className={classNames('mr-1 w-6 h-6 flex items-center justify-center rounded', {
                                    'text-saga-gray-500 dark:text-saga-gray-500 hover:text-saga-black dark:hover:text-white cursor-pointer':
                                        canGoBack,
                                    'text-saga-gray-300 dark:text-saga-gray-700': !canGoBack,
                                })}
                                onClick={() => {
                                    if (canGoBack) {
                                        trackButton('arrow-left');
                                        setMessageIndex((index) => Math.min(index + 1, assistantMessages.length - 1));
                                    }
                                }}
                            >
                                <ChevronLeft size={14} />
                            </span>
                            {`${assistantMessages.length - messageIndex} / ${assistantMessages.length}`}
                            <span
                                className={classNames('ml-1 w-6 h-6 flex items-center justify-center rounded', {
                                    'text-saga-gray-500 dark:text-saga-gray-500 hover:text-saga-black dark:hover:text-white cursor-pointer':
                                        canGoForward,
                                    'text-saga-gray-300 dark:text-saga-gray-700': !canGoForward,
                                })}
                                onClick={() => {
                                    if (canGoForward) {
                                        trackButton('arrow-right');
                                        setMessageIndex((index) => Math.max(0, index - 1));
                                    }
                                }}
                            >
                                <ChevronRight size={14} />
                            </span>
                        </div>
                    )}
                    {defaultPrompt ? null : (
                        <FavButton
                            loading={loading}
                            prompt={existingCustomPrompt}
                            onAdd={(title) => {
                                setLoading(true);
                                addAIPrompt({
                                    variables: {
                                        input: {
                                            title,
                                            prompt: promptText,
                                            type: promptType,
                                        },
                                    },
                                });
                            }}
                            onDelete={() => {
                                if (!existingCustomPrompt) {
                                    return;
                                }
                                setLoading(true);
                                removeAIPrompt({ variables: { input: { id: existingCustomPrompt.id } } });
                            }}
                        />
                    )}
                </div>

                <Tooltip content={t('ai.discard')} placement="top">
                    <div
                        className="h-6 w-6 flex items-center justify-center text-saga-gray-500 dark:text-saga-gray-500 hover:text-saga-black dark:hover:text-white cursor-pointer"
                        onClick={onDiscard}
                    >
                        <X size={14} />
                    </div>
                </Tooltip>
            </div>
        </>
    );
};

export default AIPromptDisplay;
