import { debugLog } from '@/utils';
import * as api from '@saga/api';
import React from 'react';

export default function useRequestSignInLink() {
    const [request] = api.useRequestSignInLinkMutation();

    return React.useCallback(
        async (input: api.RequestSignInLinkInput) => {
            await request({
                variables: { input },
            });

            // Save the email locally so you don't need to ask the user for it again
            // if they open the link on the same device.
            window.localStorage.setItem('emailForSignIn', input.email);

            debugLog(input, localStorage.getItem('emailForSignIn'));
        },
        [request],
    );
}
