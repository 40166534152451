import { track } from '@/analytics';
import { useOpenLocation } from '@/components/PageNavigationProvider';

import { openWindow } from '@/utils';
import { GoogleDriveFileFragment } from '@saga/api';
import React from 'react';
import useMobile from './useMobile';

const GOOGLE_DRIVE_MIME_TYPES = {
    Docs: [
        'application/vnd.google-apps.document',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    ],
    Sheets: [
        'application/vnd.google-apps.spreadsheet',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    ],
    Slides: [
        'application/vnd.google-apps.presentation',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    ],
};

export const EMBEDDABLE_MIME_TYPES = [
    ...GOOGLE_DRIVE_MIME_TYPES.Docs,
    ...GOOGLE_DRIVE_MIME_TYPES.Slides,
    ...GOOGLE_DRIVE_MIME_TYPES.Sheets,
];

export function useOpenGoogleDriveFile() {
    const isMobile = useMobile();

    const openLocation = useOpenLocation();
    return React.useCallback(
        (file: GoogleDriveFileFragment, event: Event | React.SyntheticEvent, source?: string) => {
            // In case we deal with one of the embeddable mime types above, we allow the opening in the iframe
            // otherwise we just directly open the page in a new tab
            if (file.webViewLink && file.mimeType && EMBEDDABLE_MIME_TYPES.includes(file.mimeType) && !isMobile) {
                track('open-embeddable-google-drive-link', { source });

                const nativeEvent = event instanceof Event ? event : event.nativeEvent;
                if (nativeEvent instanceof MouseEvent && nativeEvent.shiftKey) {
                    openLocation({ type: 'embed', embedUrl: file.webViewLink, openUrl: undefined }, event);
                } else {
                    openLocation({ type: 'embed', embedUrl: file.webViewLink, openUrl: undefined }, event);
                }
            } else {
                track('open-non-embeddable-google-drive-link', { source });

                openWindow(file.webViewLink, '_blank', 'noopener noreferrer');
            }
        },
        [isMobile, openLocation],
    );
}
