import React from 'react';
import { Transforms } from 'slate';
import { ReactEditor } from 'slate-react';
import { SagaEditor, isCheckListItem } from '@saga/shared';
import classNames from 'classnames';

const Check = ({ className }: { className?: string }) => (
    <svg className={className} fill="none" viewBox="0 0 12 9" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.914.293a1 1 0 0 1 0 1.414l-6.207 6.5a1 1 0 0 1-1.414 0l-3-3a1 1 0 1 1 1.414-1.414L4 6.086 9.5.293a1 1 0 0 1 1.414 0Z"
            fill="#fff"
        />
    </svg>
);

export function Checkbox(props: Omit<React.InputHTMLAttributes<HTMLInputElement>, 'type' | 'className'>) {
    return (
        <div className="relative w-5">
            <input type="checkbox" className="checkmark select-none" {...props} />
            <div className="absolute inset-0 text-white pointer-events-none" style={{ height: 18, width: 18 }}>
                <div className="flex items-center justify-center absolute h-full w-full">
                    <Check
                        className={classNames('h-3 w-3 transition-transform origin-bottom-left duration-150', {
                            'scale-100 rotate-0': props.checked,
                            'scale-0 -rotate-45': !props.checked,
                        })}
                    />
                </div>
            </div>
        </div>
    );
}

export const checkListItemPlugin = SagaEditor.Plugins.createBlockPlugin({
    match: isCheckListItem,
    Component({ editor, element, children }) {
        const { canEdit } = SagaEditor.useEditorContext();
        const check = () => {
            if (canEdit) {
                const path = ReactEditor.findPath(editor, element);
                Transforms.setNodes(editor, { checked: !element.checked }, { at: path });
                ReactEditor.focus(editor);
            }
        };
        return (
            <div className="relative py-1" data-testid="checklist-item">
                <div contentEditable={false} className="absolute select-none flex items-center inset-0 h-8">
                    <Checkbox
                        aria-labelledby={`content-${element.id}`}
                        checked={element.checked}
                        onChange={check}
                        disabled={!canEdit}
                        contentEditable={false}
                    />
                </div>
                <div
                    id={`content-${element.id}`}
                    className={classNames('relative ml-7 transition-colors', {
                        'opacity-50 within-checked': element.checked,
                    })}
                >
                    {children}
                </div>
            </div>
        );
    },
});
