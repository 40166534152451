import { SagaLocation } from '@saga/shared';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { track } from '@/analytics';
import { useCurrentWorkspace } from './WorkspaceContext';

type OpenLocationEvent = React.SyntheticEvent | Event;
export type OpenLocationOptions = { forceOtherIndex?: boolean };

export type NavigationFn = (
    location: SagaLocation.SagaLocation,
    event: OpenLocationEvent,
    options?: OpenLocationOptions,
) => void;

export const NavigationContext = React.createContext<NavigationFn | null>(null);

export function useOpenLocation() {
    const contextFn = useContext(NavigationContext);

    if (contextFn == null) {
        throw new Error('useOpenLocation needs to be used inside NavigationContext');
    }

    return contextFn;
}

export function useOpenPage() {
    const openLocation = useOpenLocation();
    const openPage = React.useCallback(
        (pageId: string, event: OpenLocationEvent, options?: { blockId?: string }) => {
            openLocation({ type: 'page', pageId, blockId: undefined, ...options }, event);
        },
        [openLocation],
    );
    return openPage;
}

export function useOpenCollection() {
    const openLocation = useOpenLocation();
    const openCollection = React.useCallback(
        (collectionId: string, event: OpenLocationEvent) => {
            openLocation({ type: 'collection', collectionId }, event);
        },
        [openLocation],
    );
    return openCollection;
}

export function useOpenTask() {
    const openLocation = useOpenLocation();
    const openTask = React.useCallback(
        (taskId: string, event: OpenLocationEvent, options?: { blockId?: string }) => {
            openLocation({ type: 'task', taskId, blockId: undefined, ...options }, event);
        },
        [openLocation],
    );
    return openTask;
}

export function NavigationProvider({
    children,
    openLocation: openLocationHandler,
}: {
    children: React.ReactNode;
    openLocation: NavigationFn;
}) {
    const openLocationHandlerRef = React.useRef(openLocationHandler);
    openLocationHandlerRef.current = openLocationHandler;

    const openLocation = React.useCallback<NavigationFn>((location, event, options) => {
        track('open-location', location);
        openLocationHandlerRef.current(location, event, options);
    }, []);

    return <NavigationContext.Provider value={openLocation}>{children}</NavigationContext.Provider>;
}

export function locationToUrl(location: SagaLocation.SagaLocation, urlKey: string) {
    switch (location.type) {
        case 'page':
            return `/${location.public ? '' : `s/${urlKey}/`}${location.pageId}${location.blockId ? `#${location.blockId}` : ''}`;
        case 'collection':
            return `/s/${urlKey}/c/${location.collectionId}`;
        case 'task':
            return `/s/${urlKey}/t/${location.taskId}`;
        case 'allCollections':
            return `/s/${urlKey}/collections`;
        case 'allPages':
            return location.viewId === 'non-deleted' ? `/s/${urlKey}/pages` : `/s/${urlKey}/pages/${location.viewId}`;
        case 'allTasks':
            return location.viewId === 'all' ? `/s/${urlKey}/tasks` : `/s/${urlKey}/tasks/${location.viewId}`;
        case 'embed':
            return `/s/${urlKey}/embed?embedUrl=${location.embedUrl}`;
        case 'sagaAI':
            return `/s/${urlKey}/saga-ai`;
    }
}

export function useLocationNavigation() {
    const history = useHistory();
    const { urlKey } = useCurrentWorkspace();

    return React.useCallback(
        (location: SagaLocation.SagaLocation) => {
            history.push(locationToUrl(location, urlKey));
        },
        [history, urlKey],
    );
}

const noop = () => {};
export function NoopNavigationProvider({ children }: { children: React.ReactNode }) {
    return <NavigationContext.Provider value={noop}>{children}</NavigationContext.Provider>;
}
