import Button from '@/components/styled/Button';
import { useToastContext } from '@/components/toast/ToastContext';
import { OnInviteAccepted, OnPageInviteAccepted } from '@/components/toast/ToastLibrary';
import * as api from '@saga/api';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import SettingsPane from './SettingsPane';
import { useCloseSettings } from './SettingsProvider';

function InviteSettingSection({
    title,
    subTitle,
    testId,
    onAccept,
    data,
    loading,
    emptyPlaceholder,
}: {
    title: string;
    subTitle: string;
    testId: string;
    emptyPlaceholder: string;
    onAccept: (id: string) => void;
    data?: { id: string; title: string; email: string }[] | null;
    loading: boolean;
}) {
    const { t } = useTranslation();

    return (
        <div>
            <div>
                <SettingsPane.Title>{title}</SettingsPane.Title>
                <SettingsPane.Small>{subTitle}</SettingsPane.Small>
            </div>
            <SettingsPane.Content>
                <div data-testid={testId} className="space-y-3 pt-3">
                    {(data?.length ?? 0) > 0 ? (
                        <div
                            role="table"
                            aria-label="Members"
                            className="divide-y border-t border-b divide-saga-gray-150 dark:divide-saga-gray-800 border-saga-gray-200 dark:border-zinc-600"
                        >
                            {data?.map((invite) => (
                                <div role="row" className="py-2 flex justify-between items-center pl-3" key={invite.id}>
                                    <div className="flex items-center space-x-2">
                                        <div role="cell">
                                            <div className="inline-block">{invite.title}</div>
                                            <div className="text-saga-text-gray text-xs">
                                                <Trans
                                                    i18nKey={'settings.invite.owned_by'}
                                                    values={{ ownerEmail: invite.email }}
                                                    components={{ 1: <span className="font-bold" /> }}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="flex flex-row space-x-2">
                                        <Button disabled={loading} onClick={() => onAccept(invite.id)} type="button">
                                            {t('settings.invite.accept_invite')}
                                        </Button>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <div className="pt-4">
                            <div className="text-base">{emptyPlaceholder}</div>
                        </div>
                    )}
                </div>
            </SettingsPane.Content>
        </div>
    );
}

export function InviteSettings() {
    const { t } = useTranslation();
    const history = useHistory();
    const { showToast } = useToastContext();
    const closeSettings = useCloseSettings();

    const { data: spaceInvitesData } = api.useUserInvitesQuery({
        fetchPolicy: 'cache-and-network',
    });
    const { data: pageInvitesData } = api.useUserPageInvitesQuery({
        fetchPolicy: 'cache-and-network',
    });

    const [acceptSpaceInvite, { loading: loadingSpaceInvite }] = api.useAcceptInvitationMutation({
        refetchQueries: [api.SpacesDocument, api.UserInvitesDocument],
    });

    const [acceptPageInvite, { loading: loadingPageInvite }] = api.useAcceptPageInvitationMutation({
        refetchQueries: [api.UserSharedPagesDocument, api.UserPageInvitesDocument],
    });

    const onAcceptSpaceInvite = React.useCallback(
        async (id: string) => {
            const result = await acceptSpaceInvite({
                variables: {
                    input: {
                        id,
                    },
                },
            }).then((result) => result.data?.acceptInvitation);

            if (!result) return;

            showToast(
                OnInviteAccepted(() => {
                    closeSettings();
                    const spaceUrlKey = result.urlKey;
                    history.push(`/s/${spaceUrlKey}`);
                }),
            );
        },
        [closeSettings, history, showToast, acceptSpaceInvite],
    );

    const onAcceptPageInvite = React.useCallback(
        async (id: string) => {
            const result = await acceptPageInvite({
                variables: {
                    input: {
                        id,
                    },
                },
            }).then((result) => result.data?.acceptPageInvitation);

            if (!result) return;

            showToast(
                OnPageInviteAccepted(() => {
                    closeSettings();
                    const pageId = result.id;
                    history.push(`/${pageId}`);
                }),
            );
        },
        [closeSettings, history, showToast, acceptPageInvite],
    );

    return (
        <SettingsPane>
            <div className="flex flex-1 flex-col space-y-16">
                <InviteSettingSection
                    testId="workspace-invites"
                    title={t('settings.invite.title')}
                    subTitle={t('settings.invite.sub_title')}
                    data={spaceInvitesData?.userInvites.map((spaceInvite) => ({
                        id: spaceInvite.inviteId,
                        email: spaceInvite.userEmail,
                        title: spaceInvite.workspaceTitle,
                    }))}
                    onAccept={onAcceptSpaceInvite}
                    loading={loadingSpaceInvite}
                    emptyPlaceholder={t('settings.invite.no_invites')}
                />
                <InviteSettingSection
                    testId="page-invites"
                    title={t('settings.page_invite.title')}
                    subTitle={t('settings.page_invite.sub_title')}
                    data={pageInvitesData?.userPageInvites.map((pageInvite) => ({
                        id: pageInvite.id,
                        email: pageInvite.userEmail,
                        title: pageInvite.title,
                    }))}
                    onAccept={onAcceptPageInvite}
                    loading={loadingPageInvite}
                    emptyPlaceholder={t('settings.page_invite.no_invites')}
                />
            </div>
        </SettingsPane>
    );
}
