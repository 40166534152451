import { PageView, SafeSpace } from '../types';
import transactInSpace from './transactInSpace';
import * as Y from 'yjs';
export default function editPageView(space: SafeSpace, id: string, view: Partial<PageView>) {
    transactInSpace(space, () => {
        const pageViews = space.map.get('pageViews') as Y.Array<Y.Map<unknown>> | undefined;

        if (!pageViews) return;

        const yPageViewIndex = pageViews.toArray().findIndex((pageView) => pageView.get('id') === id);

        if (yPageViewIndex === -1) return;
        const yPageView = pageViews.get(yPageViewIndex);

        const keys = Object.keys(view);

        keys.forEach((key) => {
            // @ts-expect-error
            const value = view[key];
            yPageView.set(key, value);
        });
    });
}
