import { useSearchContext, useSetSearchOpen } from '../search/SearchContext';
import { useSideBySideState } from '../SideBySide';
import React from 'react';
import { useDesktopContext } from '../DesktopContext';

import { useTranslation } from 'react-i18next';
import { SearchBar } from '../navigation/HeaderBar';
import { Clock, Plus, Search } from 'react-feather';
import DesktopNavigationButtons from '../DesktopNavigationButtons';
import { track } from '@/analytics';
import Tooltip from '../popover/Tooltip';
import Button from '../styled/Button';
import { useUserContext } from '../UserContext';
import AIChatThreadsList from './AIChatThreadsList';
import AIChatScreen from './AIChatScreen';
import * as api from '@saga/api';
import { v4 as uuid } from 'uuid';
import { useCurrentWorkspace } from '../WorkspaceContext';
import useMobile from '@/hooks/useMobile';
import classNames from 'classnames';
import Dropdown from '../popover/Dropdown';
import { useChatThreadsQuery } from '@saga/api';
import useInterfaceSettings from '@/hooks/useInterfaceSettings';
import WorkspaceAvatar from '../navigation/WorkspaceAvatar';

function ChatPaneHeader({
    onClose,
    onSearchOpened,
    onNewThread,
    onThreadSelected,
    paneIndex,
}: {
    onClose(event: React.MouseEvent): void;
    onSearchOpened(): void;
    onNewThread(): void;
    onThreadSelected(thread: api.AiChatThread): void;
    paneIndex: number | undefined;
}) {
    const { isDesktop, useMacOsLayout } = useDesktopContext();
    const { panes } = useSideBySideState();
    const { t } = useTranslation();
    const isMobile = useMobile();

    const [historyOpen, setHistoryOpen] = React.useState<boolean>(false);
    const historyButtonRef = React.useRef<HTMLButtonElement>(null);

    const isInPane = paneIndex !== undefined;
    const isFirst = paneIndex === 0;
    const [{ fixedSidebar }] = useInterfaceSettings();

    return (
        <div
            className={classNames('flex items-center w-full py-2', {
                'pl-8': isMobile && paneIndex === 0,
                'pl-2': !isMobile && paneIndex === 0,
            })}
        >
            {isFirst && useMacOsLayout && !fixedSidebar && (
                <div className="pl-3 mb-1">
                    <WorkspaceAvatar.SideBySide />
                </div>
            )}

            {isFirst && isDesktop && <DesktopNavigationButtons />}

            <Tooltip content={t('ai.chat.new_thread')} placement="bottom">
                <Button.Plain
                    onClick={() => {
                        track('ai-chat-new-thread-created', { source: isInPane ? 'pane' : 'sidebar' });
                        onNewThread();
                    }}
                >
                    <Button.BasePadding>
                        <Plus size={20} />
                    </Button.BasePadding>
                </Button.Plain>
            </Tooltip>

            <Tooltip content={t('ai.chat.history')} placement="bottom">
                <Button.Plain onClick={() => setHistoryOpen((open) => !open)} ref={historyButtonRef}>
                    <Button.BasePadding>{<Clock size={20} />}</Button.BasePadding>
                </Button.Plain>
            </Tooltip>

            <div className="min-w-0 flex flex-row flex-1 justify-center">
                {panes.length && isInPane && <SearchBar />}
            </div>

            <Tooltip content={t('top_menu.search_shortcut')} placement="bottom" disabled={!isInPane}>
                <Button.Plain
                    onClick={() => {
                        track('open-search-bar', { source: isInPane ? 'chat-pane' : 'chat-sidebar' });
                        onSearchOpened();
                    }}
                >
                    <Button.BasePadding>
                        <span className="sr-only">Search</span>
                        <Search size={20} />
                    </Button.BasePadding>
                </Button.Plain>
            </Tooltip>

            {(panes.length > 1 || !isInPane) && (
                <Tooltip content={t('common.close')} placement="bottom">
                    <Button.XButton onClick={onClose} label={t('common.close') as string} />
                </Tooltip>
            )}
            <Dropdown
                testId={'ai-chat-history-dropdown'}
                isOpen={historyOpen}
                onClose={() => setHistoryOpen(false)}
                attachToRef={historyButtonRef}
                align={'left'}
                overflow={false}
            >
                <div
                    className={classNames('max-h-96 overflow-y-auto', {
                        'w-80': isInPane,
                        'w-full': !isInPane,
                    })}
                >
                    <AIChatThreadsList
                        onSelected={(event, thread) => {
                            setHistoryOpen(false);
                            onThreadSelected(thread);
                        }}
                    />
                </div>
            </Dropdown>
        </div>
    );
}

type Props = {
    onClose: () => void;
    paneIndex?: number;
    threadId?: string;
};

const createThread = (spaceId: string, userId?: string): api.AiChatThread => ({
    __typename: 'AIChatThread',
    id: uuid(),
    messages: [],
    spaceId,
    userId: userId ?? '',
});

export function AIChatPane({ onClose, paneIndex, threadId }: Props) {
    const { id: spaceId, urlKey } = useCurrentWorkspace();
    const { user } = useUserContext();

    const setSearchOpen = useSetSearchOpen();
    const { search } = useSearchContext();

    const { data: threads } = useChatThreadsQuery({ variables: { input: { urlKey } }, fetchPolicy: 'cache-only' });

    const [thread, setThread] = React.useState<api.AiChatThread>(
        threadId
            ? threads?.chatThreads.find((t) => t.id === threadId) ?? createThread(spaceId, user?.id)
            : createThread(spaceId, user?.id),
    );

    return (
        <div data-testid="ai-chat-pane" className="h-full flex flex-col items-center min-w-72">
            <ChatPaneHeader
                onThreadSelected={setThread}
                onClose={onClose}
                onSearchOpened={() => (paneIndex != undefined ? setSearchOpen(true) : search(''))}
                onNewThread={() => setThread(createThread(spaceId, user?.id))}
                paneIndex={paneIndex}
            />
            <div className="max-w-700 flex-grow w-full overflow-hidden">
                <AIChatScreen thread={thread} mode={paneIndex == undefined ? 'sidebar' : 'pane'} />
            </div>
        </div>
    );
}
