import React, { useState, useRef, useMemo } from 'react';
import ClearableButton from '@/components/ClearableButton';
import { Sorting } from '@saga/shared';
import { useTableContext } from '@/components/table/TableContext';
import Dropdown, { useArrowSelectableIndex } from '../popover/Dropdown';
import useMobile from '@/hooks/useMobile';
import Button from '../styled/Button';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';

export type SortOption = {
    label: string;
    sorting: Sorting;
};

export function fieldsToSortOptions(
    fields: string[],
    columns: Record<string, { label: string; asc: string; desc: string }>,
) {
    return fields.flatMap((by): [SortOption, SortOption] => [
        {
            sorting: {
                by,
                order: 'asc',
            },
            label: `${t(columns[by].label)}: ${t(columns[by].asc)}`,
        },
        {
            sorting: {
                by,
                order: 'desc',
            },
            label: `${t(columns[by].label)}: ${t(columns[by].desc)}`,
        },
    ]);
}

type SortButtonProps = {
    sortOptions: SortOption[];
    popoverTestId?: string;
};

export default function SortButton({ popoverTestId, sortOptions }: SortButtonProps) {
    const [sortMenuOpen, setSortMenuOpen] = useState(false);
    const { sorting, setSorting, columns } = useTableContext();
    const sortRef = useRef(null);
    const isMobile = useMobile();
    const { t } = useTranslation();

    function onSelect(index: number) {
        if (!sortMenuOpen) return;

        const sortOption = sortOptions[index];

        if (sortOption) {
            setSorting(sortOption.sorting);
            setSortMenuOpen(false);
        }
    }

    const { index, setIndex } = useArrowSelectableIndex({
        onSelect,
        maxIndex: sortOptions.length - 1,
        active: sortMenuOpen,
        initialIndex: 0,
    });

    const sortingTitle = useMemo(() => {
        if (sorting && columns[sorting.by]) {
            return t(columns[sorting.by].label);
        }
        return '';
    }, [columns, sorting, t]);

    const sortingOrder = useMemo(() => {
        if (sorting && columns[sorting.by]) {
            return t(columns[sorting.by][sorting.order]);
        }
        return '';
    }, [columns, sorting, t]);

    return (
        <>
            <ClearableButton
                onClear={() => {
                    if (!sorting) {
                        setSortMenuOpen(!sortMenuOpen);
                    }
                    setSorting(null);
                }}
                onClick={() => setSortMenuOpen(!sortMenuOpen)}
                active={sorting != null}
                ref={sortRef}
            >
                {sorting && (
                    <>
                        <p className="truncate">{t('tasks.sorted_by')}</p>
                        <p className="truncate font-medium  px-1">{sortingTitle}</p>
                        <p className="h-8 px-2 ml-1 rounded-r bg-saga-gray-200 dark:bg-zinc-600 font-semibold flex items-center truncate">
                            {sortingOrder}
                        </p>
                    </>
                )}
                {!sorting && <p className="font-semibold pr-2.5">{t('collections.sort')}</p>}
            </ClearableButton>

            <Dropdown
                testId={popoverTestId}
                isOpen={sortMenuOpen}
                attachToRef={sortRef}
                onClose={() => {
                    setSortMenuOpen(false);
                }}
                position="below"
                align={isMobile ? 'right' : 'left'}
            >
                {sortOptions.map((option, i) => (
                    <Button.PopOverButton
                        key={i}
                        selected={i === index}
                        active={sorting && option.sorting.by === sorting.by && option.sorting.order === sorting.order}
                        onMouseEnter={() => setIndex(i)}
                        onClick={() => onSelect(i)}
                        type="button"
                    >
                        {option.label}
                    </Button.PopOverButton>
                ))}
            </Dropdown>
        </>
    );
}
