import { track } from '@/analytics';
import { ViewBoards } from '@/assets/icons';
import classNames from 'classnames';
import React from 'react';
import { List } from 'react-feather';
import Tooltip from '../popover/Tooltip';
import { useTranslation } from 'react-i18next';
export type TaskContentMode = 'board' | 'list';

type TaskContentModeButtonProps = {
    value: TaskContentMode;
    onChange: (value: TaskContentMode) => void;
};

const TaskContentModeButton = ({ value, onChange }: TaskContentModeButtonProps) => {
    const { t } = useTranslation();
    return (
        <div className="flex flex-row p-[2px] dark:p-0 w-16 h-8 rounded-md bg-saga-gray-150 dark:bg-saga-gray-1000 cursor-pointer flex-shrink-0 dark:border dark:border-saga-gray-700">
            <Tooltip content={t('tasks.kanban_view')} placement="top">
                <div
                    className={classNames('flex flex-1 items-center justify-center rounded-md', {
                        'bg-white dark:bg-saga-gray-800': value === 'board',
                    })}
                    onClick={() => {
                        onChange('board');
                    }}
                >
                    <ViewBoards size={14} />
                </div>
            </Tooltip>
            <Tooltip content={t('tasks.table_view')} placement="top">
                <div
                    className={classNames('flex flex-1 items-center justify-center rounded-md', {
                        'bg-white dark:bg-saga-gray-800': value === 'list',
                    })}
                    onClick={() => {
                        onChange('list');
                    }}
                >
                    <List size={14} />
                </div>
            </Tooltip>
        </div>
    );
};

export default TaskContentModeButton;
