import { assertNonNull, isFile, isImage, pageD, SpaceOperations, WeakBlocks, WeakPage } from '@saga/shared';
import React from 'react';
import { useSpace, useCurrentSearchIndex } from '../components/SpaceProvider';
import * as t from 'io-ts';
import { getStoredOrDefault } from '@/utils/localStorageUtils';
import * as R from 'ramda';
import { useCurrentWorkspace } from '@/components/WorkspaceContext';
import { useUserContext } from '@/components/UserContext';
import { debugLog } from '@/utils';
import * as api from '@saga/api';
import { isContentResultWithData, useDocumentContentRealtime } from '@/components/RealtimeDocumentProvider';
import { OnPageArchived } from '@/components/toast/ToastLibrary';
import { useToastContext } from '@/components/toast/ToastContext';

const deletedPagesForWorkspace = t.type({
    owner: t.union([t.string, t.undefined]),
    workspace: t.string,
    pages: t.array(pageD),
});
const deletedPagesD = t.array(deletedPagesForWorkspace);

function getStoredDeletedPages() {
    return getStoredOrDefault('saga-deleted-pages', deletedPagesD.decode, []);
}

function cacheDeletedPageInLocalStorage({ page, uid, urlKey }: { page: WeakPage; uid: string; urlKey: string }) {
    const deletedPages = getStoredDeletedPages();
    const index = deletedPages.findIndex(({ owner, workspace }) => workspace === urlKey && owner === uid);

    if (index >= 0) {
        deletedPages[index].pages = R.takeLast(10, [...deletedPages[index].pages, page]);
    } else {
        deletedPages.push({ owner: uid, workspace: urlKey, pages: [page] });
    }

    localStorage.setItem('saga-deleted-pages', JSON.stringify(deletedPages));

    debugLog('Cached Deleted Pages:', deletedPages);
}

const saveObjectToFile = (filename: string, object: any) => {
    const blob = new Blob([JSON.stringify(object)], { type: 'text/json' });
    const link = document.createElement('a');

    link.download = filename;
    link.href = window.URL.createObjectURL(blob);
    link.dataset.downloadurl = ['text/json', link.download, link.href].join(':');

    const evt = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true,
    });

    link.dispatchEvent(evt);
    link.remove();
};

export function useExportCachedDeletedPages() {
    const { user } = useUserContext();
    const uid = user?.id;
    const { urlKey } = useCurrentWorkspace();

    return React.useCallback(() => {
        if (!uid) return;
        const deletedPages = getStoredDeletedPages();
        const deletedPagesForWorkspace = deletedPages.find(
            ({ owner, workspace }) => workspace === urlKey && owner === uid,
        );
        if (deletedPagesForWorkspace) {
            deletedPagesForWorkspace.pages
                .slice()
                .reverse()
                .forEach((page) => {
                    saveObjectToFile(`${page.title || page.id}.json`, page);
                });
        }
    }, [urlKey, uid]);
}

export function useBatchDeletePagesPermanently() {
    const { user } = useUserContext();
    const { urlKey } = useCurrentWorkspace();
    const { space } = useSpace();
    const [deleteAsset] = api.useDeleteAssetMutation();
    const spaceblocks = useCurrentSearchIndex();

    return React.useCallback(
        async (ids: string[]) => {
            assertNonNull(user);
            const pages = ids.map((id) => SpaceOperations.getPageById(space, id));
            assertNonNull(pages);

            const deleteContent = async () => {
                const blocksMap: { [key: string]: WeakBlocks } = spaceblocks?.toJSON() || {};
                const blocksToDelete = Object.keys(blocksMap)
                    .flatMap((key) => (ids.includes(key) ? blocksMap[key] : []))
                    .flat();

                const assetBlocks = blocksToDelete.filter((block) => isImage(block) || isFile(block));

                if (assetBlocks.length) {
                    const assetKeys = assetBlocks.map((block) => block.url?.split('/').slice(-2).join('/') ?? '');
                    await deleteAsset({ variables: { input: { assetKeys, urlKey } } });
                }
            };

            deleteContent()
                .catch((e) => debugLog(e))
                .finally(() => {
                    SpaceOperations.transactInSpace(space, () =>
                        pages.forEach((page) => {
                            assertNonNull(page);
                            SpaceOperations.deletePagePermanently(space, page.id);
                            cacheDeletedPageInLocalStorage({ page, urlKey, uid: user.id });
                        }),
                    );
                });
        },
        [space, urlKey, user, spaceblocks, deleteAsset],
    );
}

export function useDeletePagePermanently() {
    const { user } = useUserContext();
    const { urlKey } = useCurrentWorkspace();
    const { space } = useSpace();
    const [deleteAsset] = api.useDeleteAssetMutation();
    const { loadDocument, unLoadDocument } = useDocumentContentRealtime();

    return React.useCallback(
        async (id: string) => {
            assertNonNull(user);
            const page = SpaceOperations.getPageById(space, id);
            assertNonNull(page);

            const deleteContent = async () => {
                const content = await loadDocument(id);

                if (content && isContentResultWithData(content)) {
                    const blocks: any[] = content.data.map.toJSON().blocks;
                    const assetBlocks = blocks.filter((block) => isImage(block) || isFile(block));

                    if (assetBlocks.length) {
                        const assetKeys = assetBlocks.map((block) => block.url?.split('/').slice(-2).join('/') ?? '');
                        await deleteAsset({ variables: { input: { assetKeys, urlKey } } });
                    }
                }

                unLoadDocument(id);
            };

            deleteContent()
                .catch((e) => debugLog(e))
                .finally(() => {
                    SpaceOperations.deletePagePermanently(space, id);
                    cacheDeletedPageInLocalStorage({ page, urlKey, uid: user.id });
                });
        },
        [space, urlKey, user, deleteAsset, loadDocument, unLoadDocument],
    );
}

export function useDeletePageIfEmpty() {
    const { user } = useUserContext();
    const { urlKey } = useCurrentWorkspace();

    const { space } = useSpace();

    return React.useCallback(
        (page: Pick<WeakPage, 'id'> & { blocks: WeakBlocks }) => {
            if (!user) {
                return;
            }

            SpaceOperations.deletePageIfEmpty(space, page, (page) => {
                cacheDeletedPageInLocalStorage({ page, urlKey, uid: user.id });
            });
        },
        [space, urlKey, user],
    );
}

export function useArchivePage() {
    const { showToast } = useToastContext();
    const { space } = useSpace();
    const { user } = useUserContext();

    return React.useCallback(
        (id: string) => {
            if (!user) {
                return;
            }
            SpaceOperations.deletePage(space, id);
            showToast(OnPageArchived());
        },
        [space, showToast, user],
    );
}
