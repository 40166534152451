import React from 'react';
import SpaceSurveyModal from '@/components/onboarding/survey/SpaceSurveyModal';
import { useSpaceAccess } from '@/hooks/useSpaceAccess';
import Modal from '@/components/Modal';
import { useCurrentWorkspace } from '@/components/WorkspaceContext';
import Onboarding from '../Onboarding';
import UpdateWorkspaceModal from '../UpdateWorkspaceModal';
import { useTranslation } from 'react-i18next';

export const SurveyContextProvider: React.FC<{ urlKey: string }> = ({ urlKey, children }) => {
    const { isOwner } = useSpaceAccess();
    const { useCases, title } = useCurrentWorkspace();
    const showSurveyModal = isOwner && useCases.length === 0;
    const showNewWorkspaceModal = isOwner && !title;
    const { t } = useTranslation();

    return (
        <>
            {children}
            {showNewWorkspaceModal && (
                <Modal maxWidth="48rem" isOpen={true}>
                    <UpdateWorkspaceModal />
                </Modal>
            )}
            {showSurveyModal && !showNewWorkspaceModal && (
                <Modal maxWidth="48rem" isOpen={true}>
                    <SpaceSurveyModal
                        urlKey={urlKey}
                        submitButtonLabel={t('onboarding.open_workspace')}
                        content={
                            <Onboarding.Heading>
                                <span className="whitespace-pre-line">{t('onboarding.what_is_this_workspace')}</span>
                            </Onboarding.Heading>
                        }
                    />
                </Modal>
            )}
        </>
    );
};
