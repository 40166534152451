import { SagaEditor, isImage, Image as ImageType } from '@saga/shared';
import Button from '@/components/styled/Button';
import classNames from 'classnames';
import React, { useState } from 'react';
import { AlertTriangle } from 'react-feather';
import { RenderElementProps } from 'slate-react';
import * as t from 'io-ts';
import { pipe } from 'fp-ts/lib/function';
import * as E from 'fp-ts/lib/Either';
import * as Dialog from '@radix-ui/react-dialog';
import { animated, useSpring } from '@react-spring/web';
import { useTranslation } from 'react-i18next';
import { usePageStaticElement } from '@/components/PublicPageProvider';
import { EditableImage } from '@/components/editor/plugins/space/Image';

export const alignD = t.union([t.literal('left'), t.literal('center'), t.literal('right')]);
export type Align = t.TypeOf<typeof alignD>;

export function useAlign(imageElement: ImageType) {
    return React.useMemo(
        () =>
            pipe(
                alignD.decode(imageElement.align),
                E.getOrElseW(() => 'left' as const),
            ),
        [imageElement.align],
    );
}

export function Image({
    element,
    children,
}: Omit<RenderElementProps, 'children'> & {
    element: ImageType;
    children?: RenderElementProps['children'];
}) {
    const imageRef = React.useRef<HTMLImageElement>(null);
    const [imageLoadingError, setImageLoadingError] = useState(false);
    const { size, ratio, url, alt, title } = element;
    const align = useAlign(element);
    const [showBigImage, setShowBigImage] = useState(false);
    const [loaded, setLoaded] = useState(false);

    return (
        <Image.Container id={element.id} align={align}>
            {!url && (
                <EditableImage.FileInput
                    selected={false}
                    disabled={true}
                    hasError={false}
                    onChange={() => {}}
                    elementId={element.id}
                    onEmbedClick={() => {}}
                />
            )}
            {showBigImage && url && (
                <Image.BigImage
                    imageRef={imageRef}
                    isOpen={showBigImage}
                    onOpenChange={setShowBigImage}
                    src={url}
                    minPadding={50}
                />
            )}
            {!imageLoadingError && (
                <img
                    className="object-contain w-full"
                    alt={alt}
                    title={title}
                    src={url}
                    ref={imageRef}
                    onError={() => {
                        setImageLoadingError(true);
                    }}
                    onLoad={() => setLoaded(true)}
                    onDoubleClick={() => setShowBigImage(true)}
                    style={{
                        ...(size && ratio
                            ? {
                                  maxWidth: size[0],
                                  maxHeight: size[1],
                                  width: !loaded ? size[0] : undefined,
                                  height: !loaded ? size[1] : undefined,
                              }
                            : {}),
                    }}
                />
            )}
            {imageLoadingError && url && (
                <div className="rounded border-dashed border border-red-700 w-full h-full flex items-center justify-center text-red-700">
                    <div className="flex justify-center items-center space-x-1 ">
                        <div className="flex space-x-2">
                            <AlertTriangle />
                            <div>Image could not be loaded.</div>
                        </div>
                    </div>
                </div>
            )}
            <Image.Void>{children}</Image.Void>
        </Image.Container>
    );
}

type ImageContainerProps = {
    align?: Align;
    children: React.ReactNode;
    onClick?: () => void;
    id: string;
    onPointerEnter?: React.PointerEventHandler;
    onPointerLeave?: React.PointerEventHandler;
};

Image.Container = React.forwardRef<HTMLDivElement, ImageContainerProps>(function ImageContainer(
    { align, children, onClick, id, onPointerEnter, onPointerLeave },
    ref,
) {
    return (
        <div
            ref={ref}
            className={classNames('relative flex flex-row my-1', {
                'justify-start': !align || align === 'left',
                'justify-center': align === 'center',
                'justify-end': align === 'right',
            })}
            onClick={onClick}
            contentEditable={false}
            id={id}
            onPointerEnter={onPointerEnter}
            onPointerLeave={onPointerLeave}
        >
            {children}
        </div>
    );
});

Image.Void = function ImageVoid({ children }: { children: React.ReactNode }) {
    return <div className="hidden select-none">{children}</div>;
};

const AnimatedDialogOverlay = animated(Dialog.Overlay);

Image.BigImage = function ImageBigImage({
    src,
    imageRef,
    isOpen,
    onOpenChange,
    minPadding,
}: {
    src: string;
    imageRef: React.MutableRefObject<HTMLImageElement | null>;
    isOpen: boolean;
    onOpenChange(isOpen: boolean): void;
    minPadding: number;
}) {
    const { t } = useTranslation();
    const [loaded, setLoaded] = React.useState(false);
    const rect = imageRef.current?.getBoundingClientRect() ?? { left: 0, top: 0, height: 0, width: 0 };

    const [{ opacity, ...animation }, api] = useSpring(() => ({
        opacity: 0,
        transform: `translateX(${rect.left}px) translateY(${rect.top}px)`,
        width: rect.width,
        height: rect.height,
        config: {
            friction: 10,
            tension: 170,
            damping: 0.9,
            frequency: 0.3,
        },
    }));

    const bigImageRef = React.useRef<HTMLImageElement>(null);

    React.useEffect(() => {
        const bigImage = bigImageRef.current;
        if (!bigImage || !loaded) return;

        function animate(image: HTMLImageElement) {
            const { width, height } = (() => {
                const diffHeight = image.naturalHeight - window.innerHeight;
                const diffWidth = image.naturalWidth - window.innerWidth;
                const ratio = image.naturalWidth / image.naturalHeight;

                if (diffWidth > diffHeight) {
                    const width = Math.min(window.innerWidth - minPadding, image.naturalWidth);
                    const height = (1 / ratio) * width;
                    return {
                        width,
                        height,
                    };
                } else {
                    const height = Math.min(window.innerHeight - minPadding, image.naturalHeight);
                    const width = ratio * height;
                    return {
                        height,
                        width,
                    };
                }
            })();

            const left = window.innerWidth / 2 - width / 2;
            const top = window.innerHeight / 2 - height / 2;

            api.start({
                opacity: 1,
                transform: `translateX(${left}px) translateY(${top}px)`,
                width,
                height,
            });
        }

        animate(bigImage);

        function onResize() {
            if (bigImage) {
                animate(bigImage);
            }
        }

        window.addEventListener('resize', onResize);

        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, [imageRef, api, minPadding, loaded]);

    function onClose() {
        api.stop();
        api.start({
            transform: `translateX(${rect.left}px) translateY(${rect.top}px)`,
            width: rect.width,
            height: rect.height,
            opacity: 0,
            onRest() {
                onOpenChange(false);
            },
        });
    }

    return (
        <Dialog.Root
            open={isOpen}
            onOpenChange={(open) => {
                if (open) {
                    onOpenChange(open);
                } else {
                    onClose();
                }
            }}
        >
            <Dialog.Portal>
                <AnimatedDialogOverlay style={{ opacity }} className="fixed inset-0 bg-black/60 z-100" />
                <Dialog.Content
                    onCloseAutoFocus={(e) => {
                        e.preventDefault();
                        document.body.style.pointerEvents = 'auto';
                    }}
                    className="fixed flex items-center justify-center inset-0 z-100"
                >
                    <Dialog.Close className="fixed inset-0 z-0" />

                    {loaded && (
                        <animated.div style={{ opacity }} className="absolute top-5 right-5 text-white dark">
                            <Button.XButton label={t('common.close') as string} onClick={onClose} />
                        </animated.div>
                    )}
                    <animated.img
                        onLoad={() => {
                            setLoaded(true);
                        }}
                        style={animation}
                        ref={bigImageRef}
                        className="absolute z-100 left-0 top-0 aspect-auto"
                        src={src}
                    />
                </Dialog.Content>
            </Dialog.Portal>
        </Dialog.Root>
    );
};

export const imageBlockPlugin = SagaEditor.Plugins.createBlockPlugin({
    match: isImage,
    Component(props) {
        const staticElement = usePageStaticElement(props.element);
        return <Image {...props} element={staticElement ?? props.element} />;
    },
});
