import React, { ButtonHTMLAttributes, RefObject } from 'react';
import classNames from 'classnames';

type BasicButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
    variant?: 'primary' | 'secondary' | 'secondary_inverted' | 'secondary_inverted_borderless' | 'tertiary';
    size?: 'medium' | 'small';
    innerRef?: RefObject<HTMLButtonElement>;
};

const BasicButton = ({
    innerRef,
    className,
    children,
    variant = 'primary',
    size = 'medium',
    ...props
}: BasicButtonProps) => {
    return (
        <button
            {...props}
            ref={innerRef}
            className={classNames(
                'block outline-none select-none focus:outline-none leading-none rounded whitespace-nowrap px-2 py-1.5 font-normal',
                {
                    'text-sm': size === 'small',
                    'text-base': size === 'medium',
                    'bg-saga-shade-900 dark:bg-white text-white dark:text-saga-text':
                        variant === 'primary' && !props.disabled,
                    'hover:bg-saga-gray-700 dark:hover:bg-saga-gray-300': variant === 'primary' && !props.disabled,
                    'dark:text-white text-saga-text': variant !== 'primary' && !props.disabled,
                    'bg-transparent': props.disabled,
                    'border border-saga-gray-300 dark:border-saga-gray-800':
                        variant !== ('tertiary' || 'secondary_inverted_borderless') && props.disabled,
                    'bg-saga-gray-150 dark:bg-saga-gray-800': variant === 'secondary' && !props.disabled,
                    'hover:bg-saga-gray-250 dark:hover:bg-saga-gray-700':
                        ['secondary', 'secondary_inverted', 'secondary_inverted_borderless'].includes(variant) &&
                        !props.disabled,
                    'border border-saga-gray-200 hover:border-saga-gray-250 dark:border-saga-gray-700 dark:hover:border-saga-gray-800':
                        variant === 'secondary' && !props.disabled,
                    'border border-saga-gray-100 hover:border-saga-gray-250 dark:border-saga-gray-700 dark:hover:border-saga-gray-800':
                        variant === 'secondary_inverted' && !props.disabled,
                    'bg-white dark:bg-saga-gray-900':
                        variant === ('secondary_inverted' || 'secondary_inverted_borderless') && !props.disabled,
                    'hover:bg-saga-gray-100 hover:dark:bg-saga-gray-700': variant === 'tertiary' && !props.disabled,
                    'shadow-button': variant !== 'secondary_inverted_borderless' && !props.disabled,
                },

                className,
            )}
        >
            {children}
        </button>
    );
};

export default BasicButton;
